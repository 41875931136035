import { useHistory } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const TagMenu = () => {
  const history = useHistory();
  const menus = [{ text: '태그 목록', value: '/tags' }];

  return (
    <Menu fluid secondary vertical>
      {menus.map((menu) => (
        <Menu.Item
          key={menu.value}
          active={menu.value === window.location.pathname}
          onClick={() => history.push(menu.value)}
        >
          {menu.text}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default TagMenu;
