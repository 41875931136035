import { isAfter, startOfDay } from 'date-fns';
import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

export const validate: FormValidator = (
  params: Pick<ChallengeFormType, 'startDate' | 'endDate'>,
) => {
  if (!params.endDate) {
    return { isValid: false, message: '' };
  }

  const startDate = startOfDay(new Date(params.startDate || '1900-01-01'));
  const endDate = startOfDay(new Date(params.endDate));

  if (isAfter(startDate, endDate)) {
    return {
      isValid: false,
      message: '챌린지 종료일은 시작일보다 늦어야 합니다.',
    };
  }

  return { isValid: true, message: '' };
};
