import { ChallengeCustomField, ChallengePurchaseInfoCustomField } from '@types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'purchaseInfo'>
> = (customFields) => {
  const purchaseInfo = customFields.find((c) => c.name === 'purchaseInfo');

  if (!purchaseInfo) return {} as EmptyObj;

  const { values } = purchaseInfo as ChallengePurchaseInfoCustomField;

  return {
    purchaseInfo: values,
  };
};

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'purchaseInfo'>,
  Extract<ChallengeCustomField, { name: 'purchaseInfo' }>
> = ({ purchaseInfo }) => {
  if (!purchaseInfo) return [];

  // requiredText에 대한 trim 처리
  const _purchaseInfo = {
    ...purchaseInfo,
    items: purchaseInfo.items.map((item) => {
      if (item.type === 'REVIEW') {
        const trimmedText = item.requiredText?.trim();
        return trimmedText ? { ...item, requiredText: trimmedText } : item;
      }
      return item;
    }),
  };

  return [
    {
      name: 'purchaseInfo',
      values: _purchaseInfo,
    },
  ];
};
