import _ from 'lodash';
import { DescriptionCollaboNaverSmartStoreModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { CollaboNaverSmartStoreDescriptionModule } from '@chlngers/core/lib/src/types/challenge';
import { DEFAULT_DATA } from './NaverSmartStoreModule.constant';

export const convertData2Form: (
  module: CollaboNaverSmartStoreDescriptionModule,
) => DescriptionCollaboNaverSmartStoreModuleFormType = (module) => {
  const { data } = _.cloneDeep(DEFAULT_DATA);
  module.data.pleaseCheckTextList = data.pleaseCheckTextList.map((item) => ({
    ...item,
    type:
      module.data.pleaseCheckTextList.find((t) => t.text === item.text)?.type ||
      item.type,
    active: !!module.data.pleaseCheckTextList.find((t) => t.text === item.text),
  }));
  module.data.purchaseRequiredTextList = data.purchaseRequiredTextList.map(
    (item) => ({
      ...item,
      type:
        module.data.purchaseRequiredTextList.find((t) => t.text === item.text)
          ?.type || item.type,
      active: !!module.data.purchaseRequiredTextList.find(
        (t) => t.text === item.text,
      ),
    }),
  );
  return module as DescriptionCollaboNaverSmartStoreModuleFormType;
};

export const convertForm2Data: (
  form: DescriptionCollaboNaverSmartStoreModuleFormType,
) => CollaboNaverSmartStoreDescriptionModule = ({ data }) => {
  const purchaseRequiredTextList = data.purchaseRequiredTextList.filter(
    (item) => item.active,
  );

  const pleaseCheckTextList = data.pleaseCheckTextList.filter(
    (item) => item.active,
  );

  return {
    type: 'COLLABO-NAVER-SMART-STORE',
    data: {
      ...data,
      purchaseRequiredTextList,
      pleaseCheckTextList,
    },
  };
};
