import FlexBox from '@component/FlexBox/FlexBox';
import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { Dimmer, Loader } from 'semantic-ui-react';

const LoadingIndicator = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const sub = observable$.subscribe(setIsLoading);
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight,
        zIndex: isLoading ? 9999 : -9999,
      }}
    >
      <Dimmer active={isLoading}>
        <FlexBox.Row
          justifyContent="center"
          alignItems="center"
          style={{ height: '100%', width: '100%' }}
        >
          <Loader />
        </FlexBox.Row>
      </Dimmer>
    </div>
  );
};

const observable$ = new Subject<boolean>();

export default LoadingIndicator;

LoadingIndicator.show = () => observable$.next(true);
LoadingIndicator.hide = () => observable$.next(false);
