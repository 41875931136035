import { useHistory } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const LabelMenu = () => {
  const history = useHistory();
  const menus = [
    { text: '라벨 목록', value: '/labels' },
    { text: '라벨 CRM 템플릿', value: '/labels-crm-template' },
  ];

  return (
    <Menu secondary vertical>
      {menus.map((menu) => (
        <Menu.Item
          key={menu.value}
          active={menu.value === window.location.pathname}
          onClick={() => history.push(menu.value)}
        >
          {menu.text}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default LabelMenu;
