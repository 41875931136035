import React, { useReducer, useState } from 'react';
import { Button, Divider, Form, Header, Modal, Table } from 'semantic-ui-react';
import { apis } from '../../../../../api';
import {
  Settlement,
  SettlementFormAction,
  SettlementFormState,
  SettlementProgressiveOperatingFee,
} from '../../Settlement.types';
import {
  FORM_LABEL,
  immutableSettlementFormKeys,
  mutableSettlementFormKeys,
  progressiveOperatingFeeKeys,
} from '../../Settlement.constants';
import { useCollaboSettlementMutation } from '../../../../../queries/Challenge/useCollaboSettlementMutation';

type SettlementEditModalProps = {
  open: boolean;
  setOpen: (opened: boolean) => void;
  settlement: Settlement;
  onEdit?: () => void;
};

const SettlementEditModal: React.FC<SettlementEditModalProps> = ({
  open,
  setOpen,
  settlement,
  onEdit,
}) => {
  const [revenue, setRevenue] = useState<number>(settlement.revenue);
  const { editCollaboSettlement } = useCollaboSettlementMutation();

  const [settlementForm, dispatch] = useReducer(reducer, {
    bannerPrice: settlement.bannerPrice,
    instagramPrice: settlement.instagramPrice,
    naverBlogPrice: settlement.naverBlogPrice,
    naverAfterMonthReviewPrice: settlement.naverAfterMonthReviewPrice,
    etcPrice: settlement.etcPrice,
    overHundredCount: settlement.overHundredCount,
    rewardPerPerson: settlement.rewardPerPerson,
    totalReward: settlement.totalReward,
    operatingFeePerPerson: settlement.operatingFeePerPerson,
    progressiveOperatingFeeForLte1000:
      settlement.progressiveOperatingFeeForLte1000,
    progressiveOperatingFeeForGt1000Lte2000:
      settlement.progressiveOperatingFeeForGt1000Lte2000,
    progressiveOperatingFeeForGt2000:
      settlement.progressiveOperatingFeeForGt2000,
  });

  const handleConfirmEdit = async () => {
    if (window.confirm('정말 수정하시겠습니까?')) {
      await editCollaboSettlement(settlement.id, {
        ...settlementForm,
      });
      alert('수정 완료!');
      setOpen(false);
      onEdit?.();
    }
  };

  const handleCalculate = async () => {
    const { revenue: calculatedRevenue } =
      await (apis.calculateSettlementRevenue(settlement.id, {
        ...(settlementForm as SettlementFormState),
      }) as Promise<{
        revenue: number;
      }>);
    setRevenue(Number(calculatedRevenue));
  };

  const resetForm = () => {
    dispatch({
      type: 'RESET',
      value: settlement.draft,
    });
  };

  // 서비스 체험인지 아닌지 구분 필요
  const filteredMutableFormKeys = mutableSettlementFormKeys.filter((k) => {
    if (settlement.adsProductType !== '서비스 체험') {
      return !progressiveOperatingFeeKeys.includes(
        k as keyof SettlementProgressiveOperatingFee,
      );
    }
    return !['operatingFeePerPerson'].includes(k);
  });

  return (
    <Modal
      size="small"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Header>수정</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            {/* 고정된 값 폼 */}
            <Header.Subheader as="h3">
              {settlement.challengeTitle}
            </Header.Subheader>
            <Table basic="very" celled collapsing>
              <Table.Body>
                {immutableSettlementFormKeys.map((key) => (
                  <Table.Row key={key}>
                    <Table.Cell>
                      {FORM_LABEL[key as keyof SettlementFormState]}
                    </Table.Cell>
                    <Table.Cell>
                      {(
                        settlementForm[key as keyof SettlementFormState] || 0
                      ).toLocaleString()}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Form.Field>
          <Divider />
          <Form.Field>
            <Header.Subheader as="h3">값 수정</Header.Subheader>
            {/* 숫자 관련 폼 */}
            {filteredMutableFormKeys.map((key) => (
              <Form.Input
                key={key}
                label={FORM_LABEL[key as keyof SettlementFormState]}
                value={settlementForm[key as keyof SettlementFormState]}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;

                  // 이 값이 숫자인지 확인하는 함수
                  const isNumeric = (val: string) => /^-?\d*\.?\d*$/.test(val);

                  // -만 입력된 경우 또는 값이 없는 경우에 대한 처리
                  if (value === '-' || !value) {
                    dispatch({
                      type: 'SET_FORM',
                      value: {
                        [key]: value,
                      },
                    });
                    return; // 이 경우 추가적인 처리를 중지
                  }

                  // 값이 숫자인 경우만 처리
                  if (isNumeric(value)) {
                    const isNegative = value.includes('-');
                    dispatch({
                      type: 'SET_FORM',
                      value: {
                        [key]: isNegative
                          ? -1 * Number(value.replace('-', ''))
                          : Number(value),
                      },
                    });
                  }
                }}
              />
            ))}
          </Form.Field>
        </Form>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 20,
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Button color="grey" content="초기화" onClick={resetForm} />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              color="green"
              content="총 매출 계산"
              onClick={handleCalculate}
            />
            <div
              style={{
                marginLeft: 20,
              }}
            >
              {revenue.toLocaleString()}원
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" content="수정 완료" onClick={handleConfirmEdit} />
        <Button
          color="black"
          content="닫기"
          onClick={() => {
            setOpen(false);
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

function reducer(state: SettlementFormState, action: SettlementFormAction) {
  switch (action.type) {
    case 'RESET':
      return {
        ...action.value,
      };

    case 'SET_FORM':
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
}

export default SettlementEditModal;
