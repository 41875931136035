import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { Button, Modal, Table } from 'semantic-ui-react';
import { card, moduleLabel, modules } from '../../../../../constant/race';
import { generateId } from '../../../../../utils/number';
import * as S from '../../../Style';
import DescriptionModuleField from '../DescriptionModuleField/DescriptionModuleField';
import { RaceFormHomeModule, RaceFormType } from '../../RaceForm.types';
import { Race } from '@types';

const HomeDescription = ({
  race,
  setRace,
}: {
  race: RaceFormType;
  setRace: React.Dispatch<React.SetStateAction<RaceFormType>>;
}) => {
  const { homeModule } = race.modules;
  const [modalOpen, setModalOpen] = useState(false);

  const showModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const selectModule = (moduleType: RaceFormHomeModule['type']) => {
    const module = _.cloneDeep(modules[moduleType]) as RaceFormHomeModule;
    module.id = generateId();
    addModule(module);
    closeModal();
  };

  const addModule = (module: RaceFormHomeModule) => {
    const _race = _.cloneDeep(race);
    _race.modules.homeModule.push(module);
    setRace(_race);
  };

  const deleteModule = (idx: number) => {
    const _race = _.cloneDeep(race);
    _race.modules.homeModule.splice(idx, 1);
    setRace(_race);
  };

  const handleOrderChange = async (idx: number, direction: string) => {
    const _race = { ...race };

    const removed = _race.modules.homeModule.splice(idx, 1);
    if (direction === 'up') {
      _race.modules.homeModule.splice(idx - 1, 0, removed[0]);
    } else {
      _race.modules.homeModule.splice(idx + 1, 0, removed[0]);
    }

    setRace(_race);
  };

  const handleChange = <K extends keyof RaceFormHomeModule>(
    idx: number,
    key: K,
    value: RaceFormHomeModule[K],
  ) => {
    const _race = _.cloneDeep(race);
    _race.modules.homeModule[idx][key] = value;
    setRace(_race);
  };

  const handleCardListChange = <
    K extends keyof Race.RaceHomeContentsCardModuleCard,
  >(
    moduleIdx: number,
    cardIdx: number,
    key: K,
    value: Race.RaceHomeContentsCardModuleCard[K],
  ) => {
    const _race = _.cloneDeep(race);
    (
      _race.modules.homeModule[moduleIdx] as Race.RaceHomeContentsCardModule
    ).cards[cardIdx][key] = value;
    setRace(_race);
  };

  const addCard = (idx: number) => {
    const _race = _.cloneDeep(race);
    const _card = _.cloneDeep(card) as Race.RaceHomeContentsCardModuleCard;
    _card.id = generateId();
    (
      _race.modules.homeModule[idx] as Race.RaceHomeContentsCardModule
    ).cards.push(_card);
    setRace(_race);
  };

  const deleteCard = (moduleIdx: number, cardIdx: number) => {
    const _race = _.cloneDeep(race);
    (
      _race.modules.homeModule[moduleIdx] as Race.RaceHomeContentsCardModule
    ).cards.splice(cardIdx, 1);
    setRace(_race);
  };

  return (
    <>
      <h3>인증탭 모듈 - 대회 진행 화면</h3>
      <S.ButtonInForm content={'추가'} onClick={showModal} />
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>no</Table.HeaderCell>
            <Table.HeaderCell>모듈</Table.HeaderCell>
            <Table.HeaderCell>이동/삭제</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {homeModule?.map((module, idx) => (
            <Table.Row key={`${module.id}-${idx}`}>
              <Table.Cell>{idx + 1}</Table.Cell>
              <Table.Cell>
                <DescriptionModuleField
                  idx={idx}
                  module={module}
                  moduleKey={'homeModule'}
                  handleChange={handleChange}
                  handleCardListChange={handleCardListChange}
                  addCard={addCard}
                  deleteCard={deleteCard}
                  race={race}
                  setRace={setRace}
                />
              </Table.Cell>
              <Table.Cell>
                <div>
                  <S.ButtonInForm
                    content={'삭제'}
                    onClick={() => deleteModule(idx)}
                  />
                </div>
                <div>
                  <S.ButtonInForm
                    icon={'angle up'}
                    onClick={() => handleOrderChange(idx, 'up')}
                  />
                </div>
                <div>
                  <S.ButtonInForm
                    icon={'angle down'}
                    onClick={() => handleOrderChange(idx, 'down')}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Modal size="small" open={modalOpen} onClose={closeModal}>
        <Modal.Header>템플릿 추가</Modal.Header>
        <Modal.Content>
          {Object.values(modules)
            .filter((m) =>
              [
                'LEAGUE-IMAGE-RECORDS',
                'CONTENTS-CARDS',
                // 'ORIGIN-IMAGE',
              ].includes(m.type),
            )
            .map((module) => (
              <S.ButtonInForm
                key={module.type}
                content={moduleLabel[module.type]}
                onClick={() =>
                  selectModule(module.type as RaceFormHomeModule['type'])
                }
              />
            ))}
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" content="닫기" onClick={closeModal} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default HomeDescription;
