import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useAuthenticationCheckList = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      authenticationChecklist: s.challenge.authenticationChecklist,
    })),
  );
};

export const useAuthenticationCheckListActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setAuthenticationCheckList = (authenticationChecklist: string[]) =>
    updateChallengeForm({ authenticationChecklist });

  return { setAuthenticationCheckList };
};
