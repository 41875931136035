import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

export const validate: FormValidator<
  Pick<ChallengeFormType, 'postConnectPrize'>
> = ({ postConnectPrize }) => {
  if (postConnectPrize?.amount === 0) {
    return {
      isValid: false,
      message: '사후연동 보상을 입력해주세요',
    };
  }
  return {
    isValid: true,
  };
};
