import { ChallengeCustomField } from '@chlngers/core/lib/src/types/challenge';
import Label from '@component/Label';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import ErrorMsg from '@component/ErrorMsg';
import FlexBox from '@component/FlexBox/FlexBox';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
  PublicTypeCustomFieldFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './PublicTypeForm.serializer';
import { usePublicType, usePublicTypeActions } from './PublicTypeForm.store';
import { validate } from './PublicTypeForm.validator';

const options: {
  [Key in PublicTypeCustomFieldFormType['publicType']]: {
    label: string;
    value: Key;
  };
} = {
  PARTICIPANT: { label: '참가자만(전체공개)', value: 'PARTICIPANT' },
  PARTIAL: { label: '참가자만(부분공개)', value: 'PARTIAL' },
  SECRET: { label: '나만(유저 본인만)', value: 'SECRET' },
};
const PublicTypeForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'publicType'>,
  Extract<ChallengeCustomField, { name: 'publicType' }>
> = () => {
  const { publicType, achievementInfoList } = usePublicType();
  const { setPublicType } = usePublicTypeActions();

  const validated = validate({ publicType, achievementInfoList });

  return (
    <FlexBox.Column>
      <Label>인증샷 공개 설정</Label>
      <RadioSelector
        style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
        options={Object.values(options)}
        onChange={({ value }) => setPublicType(value)}
        selectedOption={options[publicType]}
      />
      <ErrorMsg text={!validated.isValid ? validated.message : ''} />
    </FlexBox.Column>
  );
};

export default PublicTypeForm;

PublicTypeForm.validate = validate;
PublicTypeForm.convertData2Form = convertData2Form;
PublicTypeForm.convertForm2Data = convertForm2Data;
