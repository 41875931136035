import Label from '@component/Label';
import { GoalDetailInfo } from '@types';
import { FC } from 'react';

type Props = {
  goal: GoalDetailInfo | null;
};
export const NoCustomGoalView: FC<Props> = ({ goal }) => {
  return (
    <>
      <Label>회차별 인증 시간 설정을 사용하지 않는 goal입니다. </Label>
      {goal ? (
        <span>{`goal이 "상시"가 아닙니다. [${goal.title}(${goal.id})]`}</span>
      ) : (
        <span>Goal이 설정되지 않았습니다.</span>
      )}
    </>
  );
};
