import React from 'react';
import { Button, Checkbox, Grid, Image } from 'semantic-ui-react';
import moment from 'moment/moment';

const ChallengeCategoryRowItem = (props) => {
  const {
    b,
    getBackgroundColor,
    onChangeDisplay,
    onChangePedometerBanner,
    showModal,
    addBanner,
    deleteBanner,
  } = props;

  return (
    <Grid
      key={b.id}
      style={{
        backgroundColor: getBackgroundColor(b),
        paddingBottom: 10,
        paddingTop: 10,
      }}
    >
      <Grid.Column width={1}>{b.id}</Grid.Column>
      <Grid.Column width={2}>{b.landingType}</Grid.Column>
      <Grid.Column width={2}>{b.info.categoryType}</Grid.Column>
      <Grid.Column width={2}>{b.title}</Grid.Column>
      <Grid.Column width={2}>
        <Image src={b.imageUrl} size="tiny" />
      </Grid.Column>
      <Grid.Column width={1}>
        {moment(b.startDatetime).format('YYYY-MM-DD HH:mm:ss')}
        {moment().isBefore(b.startDatetime) && (
          <div style={{ color: 'red' }}>
            <strong>노출 예정</strong>
          </div>
        )}
      </Grid.Column>
      <Grid.Column width={1}>
        {moment(b.endDatetime).format('YYYY-MM-DD HH:mm:ss')}
      </Grid.Column>
      <Grid.Column width={1}>{b.displayType}</Grid.Column>
      <Grid.Column width={1}>
        <Checkbox
          name="isDisplayed"
          checked={b.isDisplayed}
          value={b.id}
          onChange={onChangeDisplay}
        />
      </Grid.Column>
      <Grid.Column width={1}>
        <Checkbox
          name="pedometerBanner"
          checked={b.info.pedometerBanner}
          value={b.id}
          onChange={onChangePedometerBanner}
        />
      </Grid.Column>
      <Grid.Column width={1}>
        <Button
          size="mini"
          name="EDIT"
          value={b}
          content="수정"
          onClick={showModal}
        />
        <Button size="mini" content="복사" onClick={() => addBanner(b)} />
        <Button
          size="mini"
          value={b.id}
          content="삭제"
          onClick={() => deleteBanner(b.id)}
        />
      </Grid.Column>
    </Grid>
  );
};

export default ChallengeCategoryRowItem;
