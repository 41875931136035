import React, { Component } from 'react';
import {
  Container,
  Grid,
  Header,
  Table,
  Loader,
  Button,
} from 'semantic-ui-react';
import _ from 'lodash';
import api from '../../../api';
import DateSelect from '../DateSelect';
import moment from 'moment';

class ChallengeSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userKeywords: [],
      isMounted: false,
      keywords: [],
      filterStartDatetime: moment().subtract(7, 'd'),
      filterEndDatetime: moment(),
    };
  }

  componentDidMount() {
    this.getSearchKeywords();
  }

  getSearchKeywords = () => {
    this.setState({ isMounted: false });
    const { filterStartDatetime, filterEndDatetime } = this.state;
    api
      .get('/challenge-search-users', {
        params: {
          filterStartDatetime: filterStartDatetime.format(
            'YYYY-MM-DD HH:mm:ss',
          ),
          filterEndDatetime: filterEndDatetime.format('YYYY-MM-DD HH:mm:ss'),
        },
      })
      .then((response) => {
        const userKeywords = response.data.data.userKeywords;
        let keywords = [];
        _.forEach(userKeywords, (uk) => {
          if (uk.source !== 'ADMIN') {
            _.forEach(uk.keywords, (k) => {
              let keyword = _.find(keywords, (obj) => obj.keyword === k);
              if (keyword) keyword.count += 1;
              else keywords.push({ keyword: k, count: 1 });
            });
          }
        });
        this.setState({
          userKeywords,
          keywords: _.orderBy(keywords, ['count'], ['desc']),
          isMounted: true,
        });
      });
  };

  handleDate = (name, date) => {
    if (name === 'filterStartDatetime')
      this.setState({ filterStartDatetime: date });
    else this.setState({ filterEndDatetime: date });
  };

  render() {
    const {
      keywords,
      userKeywords,
      isMounted,
      filterStartDatetime,
      filterEndDatetime,
    } = this.state;
    if (!isMounted) return <Loader active inline="centered" />;
    const countList = keywords.map((k, idx) => (
      <Table.Row key={idx}>
        <Table.Cell>{k.keyword}</Table.Cell>
        <Table.Cell>{k.count}</Table.Cell>
      </Table.Row>
    ));
    const list = userKeywords.map((uk) => {
      if (uk.source !== 'ADMIN') {
        return (
          <Table.Row key={uk.id}>
            <Table.Cell>{uk.id}</Table.Cell>
            <Table.Cell>{uk.name}</Table.Cell>
            <Table.Cell>{uk.nickname}</Table.Cell>
            <Table.Cell>{uk.source}</Table.Cell>
            <Table.Cell>{uk.keywords.map((k) => k + ', ')}</Table.Cell>
          </Table.Row>
        );
      } else return null;
    });
    return (
      <Container>
        <Grid>
          <Grid.Column width={3}>
            <Header as="h3">검색 조건</Header>
            <DateSelect
              filterStartDatetime={filterStartDatetime}
              filterEndDatetime={filterEndDatetime}
              handleDate={this.handleDate}
            />
            <Button onClick={this.getSearchKeywords} style={styles.button}>
              조회
            </Button>
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={4}>
            <Header as="h3">키워드 별 검색량</Header>
            <Table basic="very" collapsing>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>키워드</Table.HeaderCell>
                  <Table.HeaderCell>회수</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{countList}</Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h3">유저별 검색 키워드</Header>
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>id</Table.HeaderCell>
                  <Table.HeaderCell>이름</Table.HeaderCell>
                  <Table.HeaderCell>닉네임</Table.HeaderCell>
                  <Table.HeaderCell>가입경로</Table.HeaderCell>
                  <Table.HeaderCell>키워드</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{list}</Table.Body>
            </Table>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default ChallengeSearch;

const styles = {
  button: {
    marginTop: 5,
  },
};
