import {
  RaceFormDescriptionModule,
  RaceFormHomeModule,
} from '@container/Race/RaceForm/RaceForm.types';
import { Race, ServiceCategory } from '@types';
import { addDays, format } from 'date-fns';
import { RaceImageFilter, RaceType } from 'src/types/race.types';

export const raceTypeOptions: { key: number; text: string; value: RaceType }[] =
  [
    { key: 1, text: '걷기', value: 'walk' },
    { key: 2, text: '헬스', value: 'gym' },
    { key: 3, text: '꿀잠', value: 'sleep' },
    { key: 4, text: '홈트레이닝', value: 'home_training' },
    { key: 5, text: '꿀피부', value: 'skin' },
    { key: 6, text: '요리', value: 'cook' },
    { key: 7, text: '플로깅', value: 'plogging' },
    { key: 8, text: '미라클모닝', value: 'miracle_morning' },
    { key: 9, text: '오운완', value: 'ohunwan' },
    { key: 10, text: '응원(커스텀)', value: 'cheer' },
    { key: 11, text: '기록(커스텀)', value: 'record' },
  ];

export const sponsorTypeOptions = [
  { key: 1, text: '메인', value: 'main' },
  { key: 2, text: '서브', value: 'sub' },
];

export const landingTypeOptions = [
  { key: 1, text: '[앱]이벤트 페이지', value: 'APP_EVENT_SCREEN' },
  { key: 2, text: '웹', value: 'WEB' },
  { key: 3, text: '[앱]대회 신청 화면', value: 'RaceRegisterScreen' },
];

export const modules: {
  [key: string]: RaceFormDescriptionModule | RaceFormHomeModule;
} = {
  'ORIGIN-IMAGE': {
    type: 'ORIGIN-IMAGE',
    urls: [''],
  },
  'TEMPORARY-ORIGIN-IMAGE': {
    type: 'TEMPORARY-ORIGIN-IMAGE',
    urls: [''],
    dueStartDate: format(new Date(), 'yyyy-MM-dd HH:mm'),
    dueEndDate: format(addDays(new Date(), 1), 'yyyy-MM-dd HH:mm'),
  },
  TOP_RANKS: {
    type: 'TOP_RANKS',
  },
  REVIEW: { type: 'REVIEW' },
  README: {
    type: 'README',
  },
  USER_ACHIEVEMENTS: {
    type: 'USER_ACHIEVEMENTS',
  },
  'REGISTER-INFO': {
    type: 'REGISTER-INFO',
    bgColor: '#F5F5F5',
    textColor: '#111111',
  },
  HYPERLINK: {
    type: 'HYPERLINK',
    text: '랜선대회 더 알아보기',
    buttonBgColor: '#FFFFFF',
    buttonTextColor: '#111111',
    buttonBorderColor: '#111111',
    route: {
      type: 'APP_EVENT_SCREEN',
      params: {
        eventName: 'race',
        url: '',
        raceId: 0,
      },
    },
  },
  GOODS: {
    type: 'GOODS',
    style: {
      card: {
        bgColor: '#111111',
        textColor: '#FFFFFF',
      },
      label: {
        bgColor: '#FFDD26',
        textColor: '#111111',
      },
    },
    goodsList: [],
  },
  'SHARE-BOARD': {
    type: 'SHARE-BOARD',
    title: '친구와 함께 대회에 참가해보세요!',
    bgColor: '#FFFFFF',
    textColor: '#111111',
  },
  'LEAGUE-IMAGE-RECORDS': {
    type: 'LEAGUE-IMAGE-RECORDS',
  },
  'CONTENTS-CARDS': {
    type: 'CONTENTS-CARDS',
    title: '',
    cards: [],
  },
  ONBOARDING: {
    type: 'ONBOARDING',
  },
  SUB_BANNER: {
    type: 'SUB_BANNER',
    imageUrl: '',
    screenName: 'RaceCategoryScreen',
  },
  VIDEO: {
    type: 'VIDEO',
    youtubeId: '',
  },
  COUPON: {
    type: 'COUPON',
  },
  'COUPON-WITH-PRICE': {
    type: 'COUPON-WITH-PRICE',
  },
  'COUPON-LIST': {
    type: 'COUPON-LIST',
    couponIds: [],
    bgColor: '#FFFFFF',
  },
};

export const defaultGoods: Race.Goods = {
  labelText: '기념팩',
  title: '상품명',
  subtitle: '선착순 N명',
  imageUrl: '',
  additionalItems: [
    {
      title: '메인 상품명',
      imageUrl: '',
    },
    {
      title: '서브 상품명',
      imageUrl: '',
    },
    {
      title: '양말',
      imageUrl: '',
    },
    {
      title: '스티커',
      imageUrl: '',
    },
  ],
  button: {
    url: '',
    text: '자세히 보기',
  },
};

export const card = {
  emoji: '',
  title: '',
  body: '',
  bgColor: '#FAFAFA',
  titleColor: '#111111',
  bodyColor: '#555555',
};

export const moduleLabel: Record<
  RaceFormDescriptionModule['type'] | RaceFormHomeModule['type'],
  string
> = {
  'ORIGIN-IMAGE': '이미지',
  'TEMPORARY-ORIGIN-IMAGE': '기간 한정 프로모션 이미지',
  TOP_RANKS: '랭킹정보',
  USER_ACHIEVEMENTS: '유저 리그 기념샷',
  'REGISTER-INFO': '대회 신청 정보',
  HYPERLINK: '화면 이동',
  GOODS: '상품 정보',
  'SHARE-BOARD': '공유 버튼',
  'LEAGUE-IMAGE-RECORDS': '참가자 기념샷',
  'CONTENTS-CARDS': '카드형 컨텐츠',
  ONBOARDING: '온보딩',
  SUB_BANNER: '서브배너',
  REVIEW: '리뷰영역',
  README: '대회설명',
  VIDEO: '유튜브',
  COUPON: '쿠폰 모듈',
  'COUPON-WITH-PRICE': '쿠폰+가격',
  'COUPON-LIST': '쿠폰 리스트 모듈',
};

export const imageFilters: RaceImageFilter[] = [
  {
    id: 0,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYYnMMDDnW',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl: null,
    thumbnailImageUrl: null,
    isCustom: false,
  },
  {
    id: 1,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYYnMMDDnW',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter1.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter1_thumbnail.png',
    isCustom: false,
  },
  {
    id: 2,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYYnMMDDnW',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter2.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter2_thumbnail.png',
    isCustom: false,
  },
  {
    id: 3,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYYnMMDDnW',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter3.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter3_thumbnail.png',
    isCustom: false,
  },
  {
    id: 4,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYYnMMDDnW',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter4.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter4_thumbnail.png',
    isCustom: false,
  },
  {
    id: 5,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYYnMMDDnW',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter5.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter5_thumbnail.png',
    isCustom: false,
  },
  {
    id: 6,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter6.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter6_thumbnail.png',
    isCustom: false,
  },
  {
    id: 7,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter7.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter7_thumbnail.png',
    isCustom: false,
  },
  {
    id: 8,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter8.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter8_thumbnail.png',
    isCustom: false,
  },
  {
    id: 9,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter9.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter9_thumbnail.png',
    isCustom: false,
  },
  {
    id: 10,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter10.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter10_thumbnail.png',
    isCustom: false,
  },
  {
    id: 11,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter11.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter11_thumbnail.png',
    isCustom: false,
  },
  {
    id: 12,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter12.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter12_thumbnail.png',
    isCustom: false,
  },
  {
    id: 13,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter13.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/filter13_thumbnail.png',
    isCustom: false,
  },
  {
    id: 14,
    stamps: [
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/%E1%84%80%E1%85%A5%E1%86%AE%E1%84%80%E1%85%B51.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/%E1%84%80%E1%85%A5%E1%86%AE%E1%84%80%E1%85%B51_%E1%84%8A%E1%85%A5%E1%86%B7%E1%84%82%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AF.png',
    isCustom: false,
  },
  {
    id: 15,
    stamps: [
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/%E1%84%80%E1%85%A5%E1%86%AE%E1%84%80%E1%85%B52.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/%E1%84%80%E1%85%A5%E1%86%AE%E1%84%80%E1%85%B52_%E1%84%8A%E1%85%A5%E1%86%B7%E1%84%82%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AF.png',
    isCustom: false,
  },
  {
    id: 16,
    stamps: [
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/%E1%84%80%E1%85%A5%E1%86%AE%E1%84%80%E1%85%B53.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/%E1%84%80%E1%85%A5%E1%86%AE%E1%84%80%E1%85%B53_%E1%84%8A%E1%85%A5%E1%86%B7%E1%84%82%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AF.png',
    isCustom: false,
  },

  {
    id: 17,
    stamps: [
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/%E1%84%81%E1%85%AE%E1%86%AF%E1%84%91%E1%85%B5%E1%84%87%E1%85%AE1.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/%E1%84%81%E1%85%AE%E1%86%AF%E1%84%91%E1%85%B5%E1%84%87%E1%85%AE1_%E1%84%8A%E1%85%A5%E1%86%B7%E1%84%82%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AF.png',
    isCustom: false,
  },
  {
    id: 18,
    stamps: [
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/%E1%84%81%E1%85%AE%E1%86%AF%E1%84%91%E1%85%B5%E1%84%87%E1%85%AE2.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/%E1%84%81%E1%85%AE%E1%86%AF%E1%84%91%E1%85%B5%E1%84%87%E1%85%AE2_%E1%84%8A%E1%85%A5%E1%86%B7%E1%84%82%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AF.png',
    isCustom: false,
  },
  {
    id: 19,
    stamps: [
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
    ],
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/%E1%84%81%E1%85%AE%E1%86%AF%E1%84%91%E1%85%B5%E1%84%87%E1%85%AE3.png',
    thumbnailImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/race/%E1%84%81%E1%85%AE%E1%86%AF%E1%84%91%E1%85%B5%E1%84%87%E1%85%AE3_%E1%84%8A%E1%85%A5%E1%86%B7%E1%84%82%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AF.png',
    isCustom: false,
  },

  // 커스텀 필터
  // id는 알아서 앞선 이미지 필터에 이어 1씩 increment 될 것
  {
    id: -1,
    stamps: [
      {
        type: 'LOGO_STAMP',
        width: 168,
        height: 15,
        imageUrl: '',
      },
      {
        type: 'TIMESTAMP',
        format: 'YYYY.MMDD.W',
      },
      {
        type: 'RECORD_STAMP',
      },
    ],
    imageUrl: null,
    thumbnailImageUrl: null,
    isCustom: true,
  },
];

export const skinTodoList = [
  { id: 1, text: '기초 화장품 바르기' },
  { id: 2, text: '자외선 차단제 바르기' },
  { id: 3, text: '클렌징 꼼꼼히 하기' },
  { id: 4, text: '1일 1팩 하기' },
  { id: 5, text: '얼굴 마사지(괄사, 롤러) 하기' },
  { id: 6, text: '하루 물 2L 마시기' },
  { id: 7, text: '뷰티 건강식품(비타민, 콜라겐) 섭취하기' },
  { id: 8, text: '뷰티 디바이스(탄력, 미백) 사용하기' },
  { id: 9, text: '립밤 바르기' },
  { id: 10, text: '10분 이상 운동하기' },
];

// 응원 이모지 기본 값들
export const reactionDefaultList = [
  {
    id: 1,
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/admin/1687247351468-0.png',
  },
  {
    id: 2,
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/admin/1687247370046-0.png',
  },
  {
    id: 3,
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/admin/1687247388053-0.png',
  },
  {
    id: 4,
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/admin/1687247397509-0.png',
  },
  {
    id: 5,
    imageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/admin/1687247407532-0.png',
  },
];
