import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './PurchaseChannelForm.serializer';
import {
  usePurchaseChannel,
  usePurchaseChannelActions,
} from './PurchaseChannelForm.store';
import PurchaseChannelSelector from '@component/PurchaseChannelSelector';
import { validate } from './PurchaseChannelForm.validator';

const PurchaseChannelForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'purchaseChannel'>,
  Pick<ChallengeFormType, 'purchaseChannel'>,
  Pick<ReqCreateOrUpdateChallenge, 'purchaseChannel'>
> = () => {
  const { purchaseChannel } = usePurchaseChannel();

  const { setPurchaseChannel } = usePurchaseChannelActions();

  return (
    <PurchaseChannelSelector
      value={purchaseChannel}
      onChange={(value) => setPurchaseChannel(value)}
    />
  );
};

export default PurchaseChannelForm;

PurchaseChannelForm.validate = validate;
PurchaseChannelForm.convertData2Form = convertData2Form;
PurchaseChannelForm.convertForm2Data = convertForm2Data;
