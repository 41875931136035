import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Container, Grid, Form, Button, Input } from 'semantic-ui-react';
import LoginThunk from './Login.thunk';
import getLocal, { setLocal } from '../../global/local';
import moment from 'moment';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      user: '',
      token: getLocal('newToken') || '',
      loginPending: false,
      errorMessage: '',
      isAuthenticated: false,
      sampleFile: {},
    };
  }

  logout = () => {
    this.setState({ isAuthenticated: false, token: '', user: null });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submit = () => {
    const { email, password } = this.state;
    this.props
      .signIn(email, password)
      .then((data) => {
        const user = data[0];
        const token = data[1];
        this.setState({ user, token });
      })
      .catch((e) => {
        console.log(e);
        alert('어드민 계정만 로그인 가능합니다.');
      });
  };

  render() {
    const { user, token } = this.state;
    if (token) {
      return (
        <Redirect
          to={{
            pathname: moment(user.updatedAt).isBefore(
              moment().subtract(180, 'd'),
            )
              ? '/password'
              : '/home',
            state: { from: 'expired', token },
          }}
        />
      );
    }

    return (
      <Container style={styles.container}>
        <Grid columns={3}>
          <Grid.Column />
          <Grid.Column>
            <Form onSubmit={this.submit}>
              <Form.Field>
                <label>email</label>
                <Input
                  icon="at"
                  iconPosition="left"
                  placeholder="예) example@whitecube.co.kr"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>password</label>
                <Input
                  icon="user secret"
                  iconPosition="left"
                  type="password"
                  placeholder="영문/숫자/특수문자를 조합한 8자리 이상"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Button fluid type="submit">
                Submit
              </Button>
            </Form>
          </Grid.Column>
          <Grid.Column />
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const token = state.app.get('token') ? state.app.get('token') : null;
  return {
    token,
  };
};

const mapDispatchToProps = LoginThunk;

export default connect(mapStateToProps, mapDispatchToProps)(Login);

const styles = {
  container: {
    marginTop: 50,
  },
};
