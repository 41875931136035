import { useInfiniteQuery } from '@tanstack/react-query';
import { getNextPageParam, withTypedInfiniteLoad } from '@utils/query.utils';
import getQueryKey from './getQueryKey';
import { apis } from '@api';

type UseGetPreAlarmsParams = {
  offset?: number;
  limit?: number;
  keyword?: string;
  startDate?: number;
  endDate?: number;
  enabled?: boolean;
};

export const useGetPurchaseChallengeList = ({
  limit = 20,
  enabled = true,
  ...params
}: UseGetPreAlarmsParams) => {
  return useInfiniteQuery({
    queryKey: getQueryKey('GET_PURCHASE_CHALLENGE_LIST', { limit, ...params }),
    queryFn: withTypedInfiniteLoad(
      apis.purchase.getPurchaseChallenges,
      params,
      limit,
    ),
    getNextPageParam,
    initialPageParam: 0,
    enabled,
  });
};
