import { ChallengeSticker } from '@types';
import { Header, Image, Table } from 'semantic-ui-react';

export const ChallengeStickerInfo = ({
  challengeStickers,
}: {
  challengeStickers?: ChallengeSticker[];
}) => {
  if (!challengeStickers) return <></>;
  return (
    <>
      <Header>챌린지 스티커 목록</Header>
      <Table basic="very" size="small" sortable structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>회사id</Table.HeaderCell>
            <Table.HeaderCell>회사명</Table.HeaderCell>
            <Table.HeaderCell>스티커id</Table.HeaderCell>
            <Table.HeaderCell>스티커</Table.HeaderCell>
            <Table.HeaderCell>x좌표</Table.HeaderCell>
            <Table.HeaderCell>y좌표</Table.HeaderCell>
            <Table.HeaderCell>폭</Table.HeaderCell>
            <Table.HeaderCell>고정여부</Table.HeaderCell>
            <Table.HeaderCell>순서</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {challengeStickers.map((s) => {
            return (
              <Table.Row key={s.id}>
                <Table.Cell collapsing>{s.companyId}</Table.Cell>
                <Table.Cell collapsing>{s.companyName}</Table.Cell>
                <Table.Cell collapsing>{s.id}</Table.Cell>
                <Table.Cell collapsing>
                  <Image size="tiny" src={s.imageUrl} />
                </Table.Cell>
                <Table.Cell collapsing>{s.positionX}</Table.Cell>
                <Table.Cell collapsing>{s.positionY}</Table.Cell>
                <Table.Cell collapsing>{s.width}</Table.Cell>
                <Table.Cell collapsing>
                  {s.isFixed === 'true' || s.isFixed === true
                    ? '고정'
                    : '미고정'}
                </Table.Cell>
                <Table.Cell collapsing>{s.order}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default ChallengeStickerInfo;
