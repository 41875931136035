import React from 'react';
import { Button, Checkbox, Grid, Image } from 'semantic-ui-react';
import { Banner } from 'src/types/banner.types';
import { getBackgroundColor, hasHighestPriorityNow } from '../../Banner.utils';
import { format, isBefore } from 'date-fns';
import { ColorSample } from '@component/ColorSample';

const BannerItem = (props: BannerItemProps) => {
  const { banner, handleChangeDisplay, addBanner, deleteBanner, showModal } =
    props;

  return (
    <Grid
      style={{
        backgroundColor: getBackgroundColor(banner),
        paddingBottom: 10,
        paddingTop: 10,
      }}
    >
      <Grid.Column width={1}>{banner.id}</Grid.Column>
      <Grid.Column width={2}>{banner.landingType}</Grid.Column>
      <Grid.Column width={2}>
        {banner.info.collaborationBadgeText && (
          <div>[{banner.info.collaborationBadgeText}x챌린저스]</div>
        )}
        {banner.title}
      </Grid.Column>
      <Grid.Column width={2}>
        <Image src={banner.imageUrl} size="tiny" />
      </Grid.Column>
      <Grid.Column width={1}>
        {banner.color}
        <ColorSample color={banner.color} />
      </Grid.Column>
      <Grid.Column width={1}>
        {format(new Date(banner.startDatetime), 'yyyy-MM-dd HH:mm:ss')}
        {isBefore(new Date(), new Date(banner.startDatetime)) && (
          <div style={{ color: 'red' }}>
            <strong>노출 예정</strong>
          </div>
        )}
      </Grid.Column>
      <Grid.Column width={1}>
        {format(new Date(banner.endDatetime), 'yyyy-MM-dd HH:mm:ss')}
      </Grid.Column>
      {!!banner.info.reservationStartDatetime ||
      !!banner.info.reservationEndDatetime ? (
        <Grid.Column width={2} textAlign={'center'}>
          <Grid.Row>
            <Grid.Column>
              {banner.info.reservationStartDatetime
                ? format(
                    new Date(banner.info.reservationStartDatetime),
                    'yyyy-MM-dd HH:mm:ss',
                  )
                : '없음'}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>~</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {banner.info.reservationEndDatetime
                ? format(
                    new Date(banner.info.reservationEndDatetime),
                    'yyyy-MM-dd HH:mm:ss',
                  )
                : '없음'}
            </Grid.Column>
          </Grid.Row>
          {hasHighestPriorityNow(banner) && (
            <Grid.Row>
              <Grid.Column>
                <div
                  style={{
                    color: 'blue',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <strong>현재 우선 노출 중</strong>
                </div>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid.Column>
      ) : (
        <Grid.Column width={2} textAlign={'center'} verticalAlign={'middle'}>
          해당없음
        </Grid.Column>
      )}
      <Grid.Column width={1}>{banner.displayType}</Grid.Column>
      <Grid.Column width={1}>
        <Checkbox
          name="isDisplayed"
          checked={banner.isDisplayed}
          value={banner.id}
          onChange={() => handleChangeDisplay(banner.id)}
        />
      </Grid.Column>
      <Grid.Column width={1}>
        <Button
          size="mini"
          name="EDIT"
          value={banner}
          content="수정"
          onClick={() => showModal(banner)}
        />
        <Button size="mini" content="복사" onClick={() => addBanner(banner)} />
        <Button
          size="mini"
          value={banner.id}
          content="삭제"
          onClick={() => deleteBanner(banner.id)}
        />
      </Grid.Column>
    </Grid>
  );
};

export default BannerItem;

type BannerItemProps = {
  banner: Banner;
  handleChangeDisplay: (bannerId: number) => void;
  addBanner: (banner: Banner) => void;
  deleteBanner: (bannerId: number) => void;
  showModal: (banner: Banner) => void;
};
