import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Grid,
  Header,
  Label,
} from 'semantic-ui-react';
import { useHistory, useParams } from 'react-router-dom';
import LabelMenu from '../LabelMenu/LabelMenu';
import * as S from './LabelCrmDetail.styles';
import { Row } from '../../../component/Row';
import { useCrmLabelDetail } from '@queries/Label/useCrmLabelDetail';
import { CrmSendType, CrmTemplateInfo } from '@types';
import FlexBox from '@component/FlexBox/FlexBox';
import { apis } from '@api/index';
import { TIME_POINT_VALUES } from '../LabelTab.constants';

export const LabelCrmDetail: React.FC = () => {
  const history = useHistory();

  const { id: labelId } = useParams<{ id: string }>();

  const { data } = useCrmLabelDetail(Number(labelId));
  const labels = data?.labels;

  const [templateInfoList, setTemplateInfoList] = useState<CrmTemplateInfo[]>(
    [],
  );

  const handleEditTemplate = async () => {
    apis
      .updateCrmLabel(labelId, {
        templateInfo: templateInfoList,
        isActive: labels?.isActive,
      })
      .then(({ success }) => {
        if (success) history.push(`/labels-crm-template`);
      });
  };

  const handleChangeTemplateInfo = <K extends keyof CrmTemplateInfo>(
    index: number,
    key: K,
    value: CrmTemplateInfo[K],
  ) => {
    setTemplateInfoList((templateInfo) => {
      const newTemplateInfo = [...templateInfo];
      newTemplateInfo[index][key] = value;
      return newTemplateInfo;
    });
  };

  useEffect(() => {
    if (!labels?.templateInfo || !labels.labelId) return;
    setTemplateInfoList(labels.templateInfo);
  }, [labels]);

  if (!data || !labels) return <></>;

  return (
    <div style={{ margin: 40 }}>
      <Grid columns="equal">
        <Grid.Column width={2}>
          <LabelMenu />
        </Grid.Column>

        <Grid.Column>
          <Header.Subheader as="h3">템플릿 상세 조회</Header.Subheader>
          <Divider />
          {templateInfoList.map((info, index) => (
            <div key={`TEMPLATE_INFO_${info.timePoint}`}>
              <FlexBox.Column gap={8} style={{ marginBottom: 20 }}>
                {/* time point */}
                <Row
                  style={{
                    justifyContent: 'flex-start',
                    gap: 40,
                    alignItems: 'flex-start',
                  }}
                >
                  <Form.Field>
                    <Header.Subheader as="h4">시점</Header.Subheader>
                    <Label>
                      {TIME_POINT_VALUES.find(
                        ({ value }) => value === info.timePoint,
                      )?.text || ''}
                    </Label>
                  </Form.Field>
                  {/* CRM 타입 */}
                  <Form.Field>
                    <Header.Subheader as="h4">CRM 타입</Header.Subheader>
                    <Row
                      style={{
                        gap: 20,
                      }}
                    >
                      <Form.Field>
                        <Checkbox
                          radio
                          label="문자"
                          name="sendType"
                          value="SMS"
                          checked={info.sendType === 'SMS'}
                          onChange={(e, changedData) =>
                            handleChangeTemplateInfo(
                              index,
                              'sendType',
                              changedData.value as CrmSendType,
                            )
                          }
                        />
                      </Form.Field>
                      <Form.Field>
                        <Checkbox
                          radio
                          label="푸시"
                          name="sendType"
                          value="PUSH"
                          checked={info.sendType === 'PUSH'}
                          onChange={(e, changedData) =>
                            handleChangeTemplateInfo(
                              index,
                              'sendType',
                              changedData.value as CrmSendType,
                            )
                          }
                        />
                      </Form.Field>
                    </Row>
                  </Form.Field>
                </Row>
                {/* 제목 */}
                <Form.Field>
                  <Header.Subheader as="h4">제목</Header.Subheader>
                  <S.TemplateContentInput
                    name="title"
                    value={info.title}
                    placeholder="제목"
                    onChange={(e) => {
                      handleChangeTemplateInfo(index, 'title', e.target.value);
                    }}
                  />
                </Form.Field>
                {/* 내용 */}
                <Form.Field>
                  <Header.Subheader as="h4">내용</Header.Subheader>
                  <S.TemplateContentInput
                    rows={10}
                    name="template"
                    placeholder="내용"
                    value={info.template}
                    onChange={(e) => {
                      handleChangeTemplateInfo(
                        index,
                        'template',
                        e.target.value,
                      );
                    }}
                  />
                </Form.Field>
              </FlexBox.Column>
            </div>
          ))}

          <Divider />
          <Button
            color="blue"
            content="수정 완료"
            type="submit"
            fluid
            onClick={handleEditTemplate}
          />
        </Grid.Column>
      </Grid>
    </div>
  );
};
