export const awsConfig = {
  bucketName: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
  bucketRegion: process.env.REACT_APP_AWS_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  bucketKey: process.env.REACT_APP_AWS_S3_BUCKET_KEY,
  url: process.env.REACT_APP_AWS_URL,
  fileUrl: process.env.REACT_APP_AWS_FILE_URL,
};

export const KEY = process.env.REACT_APP_SALT_KEY;
export const API_KEY = process.env.REACT_APP_API_KEY;
