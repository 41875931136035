import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { Button, Modal, Table } from 'semantic-ui-react';
import {
  defaultGoods,
  moduleLabel,
  modules,
} from '../../../../../constant/race';
import { generateId } from '../../../../../utils/number';
import * as S from '../../../Style';
import DescriptionModuleField from '../DescriptionModuleField/DescriptionModuleField';
import { RaceFormDescriptionModule, RaceFormType } from '../../RaceForm.types';
import { Race } from '@types';

const ProgressInfoDescription = ({
  race,
  setRace,
}: {
  race: RaceFormType;
  setRace: React.Dispatch<React.SetStateAction<RaceFormType>>;
}) => {
  const { progressInfoDescriptionModule } = race.modules;
  const [modalOpen, setModalOpen] = useState(false);

  const showModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const selectModule = (moduleType: RaceFormDescriptionModule['type']) => {
    const module = _.cloneDeep(
      modules[moduleType],
    ) as RaceFormDescriptionModule;

    module.id = generateId();
    if (module.type === 'GOODS') {
      const _goods = _.cloneDeep(defaultGoods);

      _goods.id = generateId();
      _goods.additionalItems.forEach((item) => {
        item.id = generateId();
      });

      module.goodsList.push(_goods);
    }

    addModule(module);
    closeModal();
  };

  const addModule = (module: RaceFormDescriptionModule) => {
    const _race = _.cloneDeep(race);
    _race.modules.progressInfoDescriptionModule.push(module);
    setRace(_race);
  };

  const deleteModule = (idx: number) => {
    const _race = _.cloneDeep(race);
    _race.modules.progressInfoDescriptionModule.splice(idx, 1);
    setRace(_race);
  };

  const handleOrderChange = async (idx: number, direction: string) => {
    const _race = { ...race };

    const removed = _race.modules.progressInfoDescriptionModule.splice(idx, 1);
    if (direction === 'up') {
      _race.modules.progressInfoDescriptionModule.splice(
        idx - 1,
        0,
        removed[0],
      );
    } else {
      _race.modules.progressInfoDescriptionModule.splice(
        idx + 1,
        0,
        removed[0],
      );
    }

    setRace(_race);
  };

  const handleChange = <K extends keyof RaceFormDescriptionModule>(
    idx: number,
    key: K,
    value: RaceFormDescriptionModule[K],
  ) => {
    const _race = _.cloneDeep(race);
    _race.modules.progressInfoDescriptionModule[idx][key] = value;
    setRace(_race);
  };

  const handleRouteChange = <
    K extends keyof Race.RaceHyperlinkDescriptionModule['route']['params'],
  >(
    idx: number,
    {
      key,
      value,
    }:
      | {
          key: 'type';
          value: 'APP_EVENT_SCREEN' | 'WEB' | 'RaceRegisterScreen';
        }
      | {
          key: K;
          value: Race.RaceHyperlinkDescriptionModule['route']['params'][K];
        },
  ) => {
    const _race = _.cloneDeep(race);
    if (key === 'type') {
      (
        _race.modules.progressInfoDescriptionModule[
          idx
        ] as Race.RaceHyperlinkDescriptionModule
      ).route.type = value;
    } else {
      (
        _race.modules.progressInfoDescriptionModule[
          idx
        ] as Race.RaceHyperlinkDescriptionModule
      ).route.params[key] = value as (
        | Race.AppEventScreenRouteParams
        | Race.WebRouteParams
        | Race.RaceRegisterScreenRouteParams
      )[K];
    }
    setRace(_race);
  };

  return (
    <>
      <h3>둘러보기 화면</h3>
      <S.ButtonInForm content={'추가'} onClick={showModal} />
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>no</Table.HeaderCell>
            <Table.HeaderCell>모듈</Table.HeaderCell>
            <Table.HeaderCell>이동/삭제</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {progressInfoDescriptionModule?.map((module, idx) => (
            <Table.Row key={`${module.id}-${idx}`}>
              <Table.Cell>{idx + 1}</Table.Cell>
              <Table.Cell>
                <DescriptionModuleField
                  idx={idx}
                  module={module}
                  moduleKey="progressInfoDescriptionModule"
                  handleChange={handleChange}
                  handleRouteChange={handleRouteChange}
                  race={race}
                  setRace={setRace}
                />
              </Table.Cell>
              <Table.Cell>
                <div>
                  <S.ButtonInForm
                    content={'삭제'}
                    onClick={() => deleteModule(idx)}
                  />
                </div>
                <div>
                  <S.ButtonInForm
                    icon={'angle up'}
                    onClick={() => handleOrderChange(idx, 'up')}
                  />
                </div>
                <div>
                  <S.ButtonInForm
                    icon={'angle down'}
                    onClick={() => handleOrderChange(idx, 'down')}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Modal size="small" open={modalOpen} onClose={closeModal}>
        <Modal.Header>템플릿 추가</Modal.Header>
        <Modal.Content>
          {Object.values(modules)
            .filter(
              (m) =>
                ![
                  'LEAGUE-IMAGE-RECORDS',
                  'CONTENTS-CARDS',
                  'ORIGIN-IMAGE',
                  'TEMPORARY-ORIGIN-IMAGE',
                ].includes(m.type),
            )
            .map((module) => (
              <S.ButtonInForm
                key={module.type}
                content={moduleLabel[module.type]}
                onClick={() =>
                  selectModule(module.type as RaceFormDescriptionModule['type'])
                }
              />
            ))}
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" content="닫기" onClick={closeModal} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ProgressInfoDescription;
