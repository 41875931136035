import {
  ChallengeCollaboField,
  ChallengeCustomField,
} from 'src/types/customField.types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'collaboration'>,
  Extract<ChallengeCustomField, { name: 'collaboration' }>
> = ({ collaboration }) => {
  const {
    reviewRewardText,
    collaboProductLink,
    collaboProductName,
    reviewQuestionList,
  } = collaboration;

  if (
    !collaboProductLink &&
    !collaboProductName &&
    !reviewRewardText &&
    !reviewQuestionList.length
  )
    return [];

  return [
    {
      name: 'collaboration',
      values: {
        collaboProductLink: collaboProductLink || '',
        collaboProductName: collaboProductName || '',
        reviewRewardText: reviewRewardText || '',
        reviewQuestionList: reviewRewardText ? reviewQuestionList : [],
        reviewRequired: !!reviewQuestionList?.length,
      },
    },
  ];
};

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'collaboration'>
> = (customFields) => {
  const collaboration = customFields.find(
    ({ name }) => name === 'collaboration',
  );
  if (!collaboration) return {} as EmptyObj;

  const { values } = collaboration as ChallengeCollaboField;

  return {
    collaboration: {
      active:
        !!values.reviewRewardText ||
        !!values.collaboProductName ||
        !!values.collaboProductLink,
      reviewRewardText: values.reviewRewardText,
      collaboProductName: values.collaboProductName,
      collaboProductLink: values.collaboProductLink,
      reviewQuestionList: values.reviewQuestionList,
    },
  };
};
