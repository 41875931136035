import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'adminUserIds'>;

export const validate: FormValidator<Params> = ({ adminUserIds }) => {
  if (adminUserIds.some((id) => id === 0)) {
    return {
      isValid: false,
      message: 'ID는 1 이상이어야 합니다.',
    };
  }

  if (adminUserIds.some((id) => !id || Number.isNaN(id))) {
    return {
      isValid: false,
      message: 'ID는 숫자만 가능합니다.',
    };
  }

  if (adminUserIds.length === 0) {
    return {
      isValid: false,
      message: '1개 이상의 관리자 유저 id를 추가해주세요',
    };
  }

  return { isValid: true };
};
