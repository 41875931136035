type CustomField = {
  name: string;
  values: any;
};

export const getInstagramUrlAchievementIndexList = (
  customFields: CustomField[],
) => {
  const instagramCustomField = customFields.find(
    (customField) => customField.name === 'instagramInfoList',
  );
  if (!instagramCustomField) return [];
  return instagramCustomField.values.reduce(
    (acc: number[], { achievementIndex }: { achievementIndex: number[] }) => {
      return [...acc, ...achievementIndex];
    },
    [],
  );
};

export const getNaverBlogUrlAchievementIndexList = (
  customFields: CustomField[],
) => {
  const naverBlogCustomField = customFields.find(
    (customField) => customField.name === 'naverBlogInfoList',
  );
  if (!naverBlogCustomField) return [];
  return naverBlogCustomField.values.reduce(
    (
      acc: number[],
      { achievementIndexList }: { achievementIndexList: number[] },
    ) => {
      return [...acc, ...achievementIndexList];
    },
    [],
  );
};

export const checkIsUrlCard = (
  currentIndex: number,
  achievementIndexList: number[],
) => {
  return achievementIndexList.includes(currentIndex + 1);
};
