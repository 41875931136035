import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Radio,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { apis } from '../../../api';
import {
  getChallengeOptions,
  getRaceOptions,
} from '../../../utils/dropdownOptions';
import * as S from '../Style';
import {
  defaultRaceOfferWallTitle,
  filterModuleListForRace,
  getRaceInstagramHashTagList,
  getRacePackageName,
  getScreenInfo,
  handleDescriptionModuleFromServer,
  handleFixedModuleFromServer,
  handleModuleForServer,
  isChallengeOfferWall,
  isExternalUrlSetRequired,
  isMissionOfferWall,
  isOfferWallInRace,
  isResultDateSetRequired,
} from '../utils';
import {
  moduleType,
  settingInfoList as offerWallTypeList,
} from '../offerWallType';
import {
  INSTA_ICON,
  RACE_INSTA_DEFAULT_IMAGES,
} from './OfferWallCreate.constants';
import RaceInstagramTypeForm from './RaceInstagramTypeForm';
import SelectRaceDropdown from './SelectRaceDropdown';
import OfferWallGuide from './OfferWallGuide/OfferWallGuide';
import OfferWallDate from './OfferWallDate/OfferWallDate';
import OfferWallImage from './OfferWallImage/OfferWallImage';
import OfferWallUser from './OfferWallUser/OfferWallUser';
import OfferWallPoint from './OfferWallPoint/OfferWallPoint';
import OfferWallMission from './OfferWallMission/OfferWallMission';
import OfferWallModule from './OfferWallModule/OfferWallModule';

// 오퍼월 처음 만들 떄 기본 값
const initialOfferWall = {
  startDateTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
  endDateTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
  maxRegisterCount: null,
  resultDate: null,
  type: offerWallTypeList[0].offerWallType,
  brand: '',
  thumbnailImageUrl: '',
  title: offerWallTypeList[0].title,
  reward: {
    storePointAmount: 0,
    bestStorePointAmount: 0,
    prizePointAmount: 0,
    bestPrizePointAmount: 0,
  },
  filterInfo: {
    userType: 'ALL',
  },
  userFilter: {
    id: undefined,
    name: undefined,
  },
  moduleList: [],
  screen: {
    screenName: '',
    challengeId: 0,
    externalUrl: '',
    offerWallId: 0,
    raceId: 0,
    pillProductId: 0,
  },
  // 랜선 대회 관련 정보 (homeButton)
  race: {
    homeButton: {
      title: '',
      subtitle: '',
      buttonText: '',
      imageUrl: '',
    },
    goodsName: '',
  },
  // 우수 참여자 수
  bestParticipantCount: null,
  hashTagList: [],
};

const OfferWallCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [offerWall, setOfferWall] = useState(initialOfferWall);
  const [challengeOptions, setChallengeOptions] = useState([]);
  const [raceOptions, setRaceOptions] = useState([]);
  const [selectedOfferWallId, setSelectedOfferWallId] = useState(null);
  const [descriptionList, setDescriptionList] = useState([]);

  // 고정 모듈 위치 관련 데이터
  const [mainImageUrlList, setMainImageUrlList] = useState([]);

  // 모듈 복사 관련
  const [offerWallList, setOfferWallList] = useState([]);

  const history = useHistory();
  const { id } = useParams();

  const addOfferWall = async () => {
    if (isOfferWallInRace(offerWall.type)) {
      if (
        !offerWall.reward.prizePointAmount &&
        !offerWall.reward.storePointAmount
      ) {
        alert('리워드 관련 정보를 기입해주세요');
        return;
      }

      if (!offerWall.screen?.raceId) {
        alert('랜선 대회 ID를 꼭 연결해주세요');
        return;
      }

      if (offerWall.type !== 'RACE_SURVEY' && !offerWall.resultDate) {
        alert('리워드 지급일을 적어주세요');
        return;
      }
    }
    const _offerWall = _.cloneDeep(offerWall);

    _offerWall.isDisplayed = true;

    _offerWall.screen = getScreenInfo(_offerWall);

    _offerWall.userFilterId = _offerWall.userFilter.id;
    delete _offerWall.userFilter;

    // RACE_INSTAGRAM_MISSION, RACE_SURVEY인 경우 해당 모듈 타입에 대해서만 수정함
    if (isOfferWallInRace(_offerWall.type)) {
      _offerWall.moduleList = _offerWall.moduleList.filter(
        (m) =>
          m.type === moduleType.imageList ||
          m.type === moduleType.cautionModule ||
          m.type === moduleType.achievementExampleModule,
      );
    }

    // 수정
    try {
      setIsLoading(true);
      if (id) {
        const res = await apis.editOfferWall(
          id,
          handleModuleForServer(
            _offerWall,
            mainImageUrlList,
            descriptionList,
            challengeOptions,
            raceOptions,
            [],
          ),
        );

        if (res.success) {
          alert('오퍼월이 수정되었어요.');
          history.push('/offerwall');
        }
      } else {
        // 새로 생성
        const res = await apis.addOfferWall(
          handleModuleForServer(
            _offerWall,
            mainImageUrlList,
            descriptionList,
            challengeOptions,
            raceOptions,
            [],
          ),
        );

        if (res.offerWallId) {
          alert('오퍼월이 추가되었어요.');
          history.push('/offerwall');
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddModule = (module) => {
    const _module = { ...module };
    delete _module.label;

    setOfferWall((prev) => {
      return {
        ...prev,
        moduleList: [...prev.moduleList, { ..._module }],
      };
    });
  };

  const handleOfferWallTypeChange = (e, { name, value }) => {
    setSelectedOfferWallId(null);

    const target = offerWallTypeList.find(
      ({ offerWallType }) => offerWallType === value,
    );

    setOfferWall((prev) => ({
      ...prev,
      title: target.title,
      [name]: value,
    }));

    if (name === 'type' && value === 'RACE_INSTAGRAM_MISSION') {
      setOfferWall((prev) => ({
        ...prev,
        [name]: value,
        moduleList: [
          { type: 'ImageListModule', imageUrlList: RACE_INSTA_DEFAULT_IMAGES },
        ],
        race: {
          homeButton: { imageUrl: INSTA_ICON },
        },
      }));
    }
  };

  const handleChangeFilterInfoValue = (key, value) => {
    setOfferWall((o) => ({
      ...o,
      filterInfo: {
        ...o.filterInfo,
        [key]: value,
      },
    }));
  };

  const handleChangeUserFilterValue = (id) => {
    setOfferWall((o) => ({
      ...o,
      userFilter: {
        ...o.userFilter,
        id,
      },
    }));
  };

  const handleChangeScreen = (key, value) => {
    setOfferWall((_offerWall) => ({
      ..._offerWall,
      screen: { ...offerWall.screen, [key]: value },
    }));
  };

  // 메인 이미지 수정
  const handleMainImageUrlList = (urls) => {
    setMainImageUrlList([...urls]);
  };

  // 오퍼월 정보 수정
  const handleChangeValue = ({ name, value }) => {
    setOfferWall((_offerWall) => ({ ..._offerWall, [name]: value }));
  };

  // 오퍼월 reward 정보 수정
  const handleChangeRewardValue = ({ name, value }) => {
    setOfferWall((_offerWall) => ({
      ..._offerWall,
      reward: {
        ..._offerWall.reward,
        [name]: value,
      },
    }));
  };

  // 오퍼월 랜선대회 정보 수정
  const handleChangeRaceInfoValue = ({ name, value }) => {
    setOfferWall((_offerWall) => ({
      ..._offerWall,
      race: {
        ..._offerWall.race,
        [name]: value,
      },
    }));
  };

  const handleChangeModuleValue = (moduleIdx, name, value) => {
    const _offerWall = { ...offerWall };
    console.log(_offerWall.moduleList, moduleIdx, name, value);
    _offerWall.moduleList[moduleIdx][name] = value;
    setOfferWall(_offerWall);
  };

  const _getChallengeOptions = async () => {
    const challengeOptions = await getChallengeOptions({
      gteRegisterEndDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      isOfficial: true,
      isPublic: true,
      isDeleted: false,
    });
    setChallengeOptions(challengeOptions);
  };

  const _getRaceOptions = async () => {
    const raceOptions = await getRaceOptions();
    setRaceOptions(raceOptions);
  };

  const onMoveModule = (moduleIdx, direction) => {
    const _offerWall = { ...offerWall };

    const removed = _offerWall.moduleList.splice(moduleIdx, 1);

    direction === 'up'
      ? _offerWall.moduleList.splice(moduleIdx - 1, 0, removed[0])
      : _offerWall.moduleList.splice(moduleIdx + 1, 0, removed[0]);

    setOfferWall(_offerWall);
  };

  const removeModule = (moduleIdx) => {
    const _offerWall = { ...offerWall };
    _offerWall.moduleList.splice(moduleIdx, 1);
    setOfferWall(_offerWall);
  };

  const getOfferWallList = async () => {
    const res = await apis.getOfferWalls('result_date');
    const { offerWallInfoList } = res;
    setOfferWallList(offerWallInfoList);
  };

  const handleSelectCopyOfferWall = (id) => {
    setSelectedOfferWallId(id);
  };

  const initBestParticipantInfo = useCallback(() => {
    setOfferWall((_offerWall) => ({
      ..._offerWall,
      bestParticipantCount: 0,
      reward: {
        ..._offerWall.reward,
        bestPrizePointAmount: 0,
        bestStorePointAmount: 0,
      },
    }));
  }, []);

  const getOfferWallDetail = async (id, isCopy) => {
    const res = await apis.getOfferWallDetailInfo(id);
    let newOfferWall = _.cloneDeep(res.offerWallInfo);
    newOfferWall = handleFixedModuleFromServer(res.offerWallInfo);

    setMainImageUrlList(
      res.offerWallInfo.moduleList.find((m) => m.type === 'MainImageListModule')
        ?.imageUrlList || [],
    );

    setDescriptionList(handleDescriptionModuleFromServer(res.offerWallInfo));

    if (
      ['RACE_INSTAGRAM_MISSION', 'RACE_SURVEY'].includes(res.offerWallInfo.type)
    ) {
      if (res.offerWallInfo.type === 'RACE_INSTAGRAM_MISSION') {
        newOfferWall = {
          ...newOfferWall,
          race: {
            goodsName: getRacePackageName(res.offerWallInfo),
          },
        };
        newOfferWall.hashTagList = getRaceInstagramHashTagList(
          res.offerWallInfo,
        );
      }

      newOfferWall.moduleList = filterModuleListForRace(
        res.offerWallInfo.moduleList,
      );
    }

    // 복사하는 경우
    if (isCopy) {
      // offerWall의 id는 기본적으로 제외
      delete newOfferWall.id;

      // 리워드 관련 정보 제외 (처음부터 다시 셋팅 요청)
      delete newOfferWall.reward.storePointAmount;
      delete newOfferWall.reward.bestPrizePointAmount;
      delete newOfferWall.reward.prizePointAmount;
      delete newOfferWall.reward.bestStorePointAmount;

      // 스크린 정보 제외 (처음부터 다시 셋팅 요청)
      delete newOfferWall.screen;

      // 랜선대회 인스타그램/설문조사 및 의 포인트 지급일은 종료일시에 하루 더하기
      newOfferWall.resultDate = null;
      if (isResultDateSetRequired(newOfferWall.type)) {
        let addDays = 1;
        if (newOfferWall.type === 'RACE_INSTAGRAM_MISSION') {
          addDays = 2;
        }
        newOfferWall.resultDate = moment(newOfferWall.endDateTime)
          .add(addDays, 'd')
          .format('YYYY-MM-DD');
      }
    }

    setOfferWall(newOfferWall);
  };

  useEffect(() => {
    _getChallengeOptions();
    _getRaceOptions();
    getOfferWallList();
  }, []);

  useEffect(() => {
    if (!id) return;
    // id가 있으면 어드민 offerWall 타입에 맞게 컨버팅 (수정)
    async function getOfferwallEditInfo(id) {
      await getOfferWallDetail(id, false);
    }

    getOfferwallEditInfo(id);
  }, [id]);

  // 복사할 오퍼월의 유무에 따라서 offerWall 상태 fetch
  useEffect(() => {
    if (id) return;
    async function getOfferwalCopyInfo(id) {
      await getOfferWallDetail(id, true);
    }

    // 복사할 오퍼월이 존재할 때
    if (selectedOfferWallId) {
      getOfferwalCopyInfo(selectedOfferWallId, true);
    } else {
      // 복사할 오퍼월이 존재하지 않을때 모든 것을 초기화 시켜야 함
      setOfferWall({
        ...initialOfferWall,
      });
      setDescriptionList([]);
    }
  }, [selectedOfferWallId, id]);

  if (!offerWall) return null;
  return (
    <Container>
      <OfferWallGuide />
      <div style={{ margin: 20 }}>
        <Grid.Column>
          <Dimmer active={isLoading} page>
            <Loader />
          </Dimmer>
          <Header as="h3">오퍼월 추가</Header>
          <Form>
            {/* 공통 */}
            <Form.Group grouped>
              <label>오퍼월 미션 타입</label>
              {offerWallTypeList.map((offerWallType) => (
                <Form.Field
                  key={offerWallType.offerWallType}
                  control={Radio}
                  label={offerWallType.title}
                  value={offerWallType.offerWallType}
                  name="type"
                  checked={offerWall.type === offerWallType.offerWallType}
                  onChange={handleOfferWallTypeChange}
                />
              ))}
            </Form.Group>

            {!id && (
              <>
                <h2>오퍼월 복사</h2>
                <Dropdown
                  placeholder="복사할 오퍼월을 선택하세요"
                  search
                  fluid
                  selection
                  value={selectedOfferWallId || null}
                  options={offerWallList.map((o) => {
                    return {
                      value: o.id,
                      text: `${o.id}-${o.brand}-${o.title}`,
                    };
                  })}
                  onChange={(e, { value }) => {
                    handleSelectCopyOfferWall(value);
                  }}
                />
              </>
            )}

            <Divider />

            {/* 오퍼월 유저 관련 설정 */}
            <OfferWallUser
              offerWallInfo={offerWall}
              onChangeValue={handleChangeValue}
              onChangeFilterInfoValue={handleChangeFilterInfoValue}
              onChangeUserFilterValue={handleChangeUserFilterValue}
            />

            <Divider />

            {/* 오퍼월 이미지 설정 */}
            <OfferWallImage
              offerWallInfo={offerWall}
              onChangeValue={handleChangeValue}
              mainImageUrlList={mainImageUrlList}
              onChangeMainImageUrlList={handleMainImageUrlList}
            />

            <Divider hidden />

            {isOfferWallInRace(offerWall.type) && (
              <RaceInstagramTypeForm
                onChangeValue={handleChangeValue}
                onChangeRewardValue={handleChangeRewardValue}
                onChangeRaceInfo={handleChangeRaceInfoValue}
                offerWallInfo={offerWall}
              />
            )}

            <Divider hidden />

            {/* 오퍼월 진행기간 */}
            <OfferWallDate
              offerWallInfo={offerWall}
              onChangeValue={handleChangeValue}
            />

            <Divider hidden />

            <OfferWallPoint
              id={id}
              offerWallInfo={offerWall}
              onChangeValue={handleChangeValue}
              onChangeRewardValue={handleChangeRewardValue}
              initBestParticipantInfo={initBestParticipantInfo}
            />

            <Divider hidden />

            {/* 오퍼월 미션 설정 */}
            <OfferWallMission
              offerWallInfo={offerWall}
              descriptionList={descriptionList}
              setDescriptionList={(list) => setDescriptionList(list)}
            />

            <Divider hidden />

            <h2>추가 설정값</h2>
            {isOfferWallInRace(offerWall.type) && (
              <SelectRaceDropdown
                selectedRaceId={Number(offerWall.screen?.raceId) ?? undefined}
                onChange={(race) => {
                  handleChangeScreen('raceId', race?.id || undefined);
                  if (race) {
                    let addDays = 1;
                    if (offerWall.type === 'RACE_INSTAGRAM_MISSION')
                      addDays = 5;
                    setOfferWall((prev) => ({
                      ...prev,
                      brand: race.brand,
                      endDateTime: moment(race.endDate).format(
                        'YYYY-MM-DDTHH:mm:ss',
                      ),
                      resultDate: isResultDateSetRequired(offerWall.type)
                        ? moment(race.endDate)
                            .add(addDays, 'd')
                            .format('YYYY-MM-DD')
                        : null,
                    }));
                  }
                }}
              />
            )}
            <S.RowContainer>
              {/* 앱 내부 미션 (챌린지 참가) */}
              {isChallengeOfferWall(offerWall.type) && (
                <S.RowFormField>
                  <label>챌린지 ID 검색</label>
                  <Dropdown
                    placeholder="챌린지를 선택하세요."
                    fluid
                    search
                    selection
                    name="screenName"
                    options={challengeOptions}
                    value={Number(offerWall.screen?.challengeId)}
                    onChange={(_, { value }) => {
                      handleChangeScreen('challengeId', value);
                    }}
                  />
                </S.RowFormField>
              )}
              {isExternalUrlSetRequired(offerWall.type) && (
                <S.RowFormField>
                  <label>외부 링크(즉시 적립)</label>
                  <input
                    name="externalUrl"
                    defaultValue={offerWall.screen?.externalUrl}
                    onChange={(e) => {
                      handleChangeScreen('externalUrl', e.target.value);
                    }}
                  />
                </S.RowFormField>
              )}
              {(isMissionOfferWall(offerWall.type) ||
                isOfferWallInRace(offerWall.type)) && (
                <S.RowFormField margin="0 0 0 10px">
                  <label>
                    타이틀
                    {isOfferWallInRace(offerWall.type) &&
                      ' (오퍼월 리스트 타이틀, 랜선 상세 화면 롤링 배너 서브타이틀에 세팅됩니다)'}
                  </label>
                  <input
                    name="title"
                    value={
                      offerWall.title.includes('[랜선대회]')
                        ? defaultRaceOfferWallTitle(offerWall.type)
                        : offerWall.title
                    }
                    onChange={(e) => {
                      setOfferWall((prev) => {
                        return {
                          ...prev,
                          title: e.target.value,
                        };
                      });
                    }}
                  />
                </S.RowFormField>
              )}
              <S.RowFormField margin="0 0 0 10px">
                <label>
                  브랜드명 (최대 8자까지 입력, 넘어가면 화면에 이상하게 나옴)
                </label>
                <input
                  name="brand"
                  value={offerWall.brand}
                  onChange={(e) => {
                    handleChangeValue({
                      name: 'brand',
                      value: e.target.value,
                    });
                  }}
                />
              </S.RowFormField>
            </S.RowContainer>

            {/* 오퍼월 모듈 설정 */}
            <OfferWallModule
              offerWallInfo={offerWall}
              onMoveModule={onMoveModule}
              removeModule={removeModule}
              addModule={handleAddModule}
              handleChangeModuleValue={handleChangeModuleValue}
            />
          </Form>
          <S.ButtonWrapper>
            <Button fluid color="blue" content="확인" onClick={addOfferWall} />
          </S.ButtonWrapper>
        </Grid.Column>
      </div>
    </Container>
  );
};

export default OfferWallCreate;
