import { InfiniteData } from '@tanstack/react-query';
import { KeysMatching } from '../types/util.types';

export const withTypedInfiniteLoad =
  <
    QueryFn extends (...args: any) => Promise<any>,
    Params = Parameters<QueryFn>,
  >(
    api: QueryFn,
    params: Params,
    pageSize: number,
  ) =>
  async ({
    pageParam: offset = 0,
  }): Promise<
    Awaited<QueryFn extends (...args: any) => infer R ? R : never>
  > => {
    return {
      nextOffset: offset + pageSize,
      ...(await api({ ...params, offset, limit: pageSize })),
    };
  };

export const getNextPageParam = <
  T extends { isEnd: boolean; nextOffset?: number },
>(
  lastPage: T,
) => {
  return !lastPage.isEnd ? lastPage.nextOffset : undefined;
};

/**
 *
 * @param data infinite data
 * @param key infinite data 중에서 pluck 하고자 하는 array 형태 data
 * @param identifier array 데이터 중 uniqueness 구분자
 */
export const flatMapInfiniteData = <
  Data extends InfiniteData<any> | undefined,
  Response extends Data extends InfiniteData<infer V> ? V : never,
  Key extends KeysMatching<Response, Array<any>>,
  Identifier extends keyof Response[Key][number],
  Result extends Response[Key],
>(
  data: Data,
  key: Key,
  identifier: Identifier,
): Result => {
  return (
    data?.pages?.reduce((acc, curr) => {
      const nextPage = curr[key];

      if (!nextPage) return acc;

      const filtered = nextPage.filter(
        (v: Result) =>
          !acc.find((p: Result) => p[identifier] === v[identifier]),
      );

      return [...acc, ...filtered];
    }, []) || []
  );
};
