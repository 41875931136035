import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'isDeleted'>,
  Pick<ReqCreateOrUpdateChallenge, 'isDeleted'>
> = ({ isDeleted }) => {
  return { isDeleted: isDeleted || false };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'isDeleted'>,
  Pick<ChallengeFormType, 'isDeleted'>
> = ({ isDeleted }) => ({ isDeleted: isDeleted || false });
