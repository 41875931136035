import moment from 'moment';
import { RaceFormType } from './RaceForm.types';

export const cheerRaceParameterInitialValue = {
  cheerType: '',
  cheerRecordText: '',
  cheerRecordUnit: '점',
};

export const recordRaceParameterInitialValue = {
  recordType: '',
  recordText: '',
  scorePerItem: 0,
};

export const initialValue: RaceFormType = {
  title: '',
  raceType: 'walk',
  category: undefined,
  seasonTerm: `${moment().month() + 1}월`,
  maxRegisterCount: 1000,
  defaultPrice: 30000,
  shippingFee: 0,
  thumbnailImageUrl: '',
  cardImageUrl: '',
  medalImageUrl: '',
  registerStartDate: moment().format('YYYY-MM-DDTHH:mm'),
  registerEndDate: moment().format('YYYY-MM-DDTHH:mm'),
  startDate: moment().format('YYYY-MM-DDTHH:mm'),
  endDate: moment().format('YYYY-MM-DDTHH:mm'),
  sponsors: [],
  leagues: [],
  displayType: 'ADMIN',
  reviewInfo: {
    description: '',
    questions: [],
  },
  info: {
    ogTitle: '',
    ogDescription: '',
    ogImage: '',
    shareMessage: '',
    productDetailImageUrls: [],
    promise: {
      textColor: '#111111',
      bgImageUrl: '',
      defaultPromise: '이번 대회도 화이팅!',
      eventImageUrls: [],
    },
    hubCardInfo: {
      labelBackgroundColor: 'GRAY_900',
      labelTextColor: 'BASIC_WHITE',
      backgroundImageUrl: '',
    },
    instagramHashtag: '',
    cardInfo: {
      backgroundImageUrl: '',
      goodsImageUrl: '',
      thumbnailImageUrl: '',
      hookingText: '',
      hookingTextColor: '',
      badges: [],
      cardBackgroundImageUrlA: '',
      cardBackgroundImageUrlB: '',
      goodsImageUrls: [''],
      thumbnailTitle: '',
      originalPrice: 0,
    },
  },
  modules: {
    progressInfoDescriptionModule: [],
    registerInfoDescriptionModule: [],
    homeModule: [],
    showShippingGuide: false,
  },
  userFilter: {
    id: undefined,
    name: undefined,
    estimatedUserCount: undefined,
  },
  authenticationMethod: {
    title: '',
    description: '',
    image: undefined,
  },
  cheerRaceParameter: cheerRaceParameterInitialValue,
  recordRaceParameter: recordRaceParameterInitialValue,
  stickers: [],
  reactions: [],
  productDescriptions: [],
};
