import React, { Component } from 'react';
import {
  Button,
  Container,
  Grid,
  Loader,
  Header,
  Divider,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import api from '../../api';
import _ from 'lodash';
import DateSelect from './DateSelect';
import TotalStatistics from './TotalStatistics';
import SexAgeStatistics from './SexAgeStatistics';
import SexAgeFbStatistics from './SexAgeFbStatistics';
import DailyStatistics from './DailyStatistics';
import RegisterDepositCard from './RegisterDepositCard';
import RegisterDist from './RegisterDist';
import RegisterCount from './RegisterCount';
import LoseUserDist from './LoseUserDist';
import LoseUserRegister from './LoseUserRegister';
// import RegisterDistRange from "./RegisterDistRange";
// import RegisterCountRange from "./RegisterCountRange";
// import LoseUserRegisterRange from "./LoseUserRegisterRange";
// import LoseUserDistRange from "./LoseUserDistRange";
import AWS from 'aws-sdk';
import { awsConfig } from '../../config';

AWS.config.update({
  region: awsConfig.bucketRegion,
  credentials: new AWS.Credentials({
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
  }),
  dynamoDbCrc32: false,
});
const ddb = new AWS.DynamoDB({
  apiVersion: '2012-08-10',
});

class Statistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalStatistics: {
        total: {
          registerUserCount: 0,
          totalRegisterCount: 0,
          totalRegisterDeposit: 0,
          cardRegisterCount: 0,
          transferRegisterCount: 0,
          cancelUserCount: 0,
          totalCancelCount: 0,
          totalCancelDeposit: 0,
          cardCancelCount: 0,
          transferCancelCount: 0,
          netUser: {},
          userCount: 0,
          beginnerUserIds: [],
          eventUserIds: [],
          loseUserIds: [],
          outUserCount: 0,
          userSexAgeObj: {},
          userSexAgeRegisterObj: {},
          newUserSexAgeRegisterObj: {},
        },
        retention: {},
      },
      dailyStatistics: [
        {
          achievementFiles: 0,
          challengeCancelCount: 0,
          challengeCancelDeposit: 0,
          challengeInterestCount: 0,
          challengeRegisterCount: 0,
          challengeRegisterDeposit: 0,
          commentCount: 0,
          date: moment(),
          followCount: 0,
          likeCount: 0,
          userCount: 0,
          challengeRegisterUserCount: 0,
          netChallengeRegisterUserCount: 0,
          challengeCancelUserCount: 0,
          netChallengeCancelUserCount: 0,
          resultChallengeCount: 0,
          resultUserCount: 0,
          failUserCount: 0,
          successCount: 0,
          hundredCount: 0,
          nonReturnDeposit: 0,
          totalDeposit: 0,
          margin: 0,
          extraMargin: 0,
          deletedUserCount: 0,
          cardWithdrawUserCount: 0,
          transferWithdrawUserCount: 0,
          cardWithdrawAmount: 0,
          transferWithdrawAmount: 0,
          specialPrize: 0,
        },
      ],
      registerDepositStatistics: [
        {
          date: '',
          card: 0,
          cash: 0,
          count: 0,
        },
      ],
      statistics: {
        total: {
          registerUserCount: 0,
          totalRegisterCount: 0,
          totalRegisterDeposit: 0,
          cardRegisterCount: 0,
          transferRegisterCount: 0,
          cancelUserCount: 0,
          totalCancelCount: 0,
          totalCancelDeposit: 0,
          cardCancelCount: 0,
          transferCancelCount: 0,
          netUser: {},
          userCount: 0,
          beginnerUserIds: [],
          eventUserIds: [],
          loseUserIds: [],
          outUserCount: 0,
        },
        daily: [
          {
            achievementFiles: 0,
            challengeCancelCount: 0,
            challengeCancelDeposit: 0,
            challengeInterestCount: 0,
            challengeRegisterCount: 0,
            challengeRegisterDeposit: 0,
            commentCount: 0,
            date: moment(),
            followCount: 0,
            likeCount: 0,
            userCount: 0,
            challengeRegisterUserCount: 0,
            netChallengeRegisterUserCount: 0,
            challengeCancelUserCount: 0,
            netChallengeCancelUserCount: 0,
            resultChallengeCount: 0,
            resultUserCount: 0,
            failUserCount: 0,
            nonReturnDeposit: 0,
            totalDeposit: 0,
            margin: 0,
            deletedUserCount: 0,
          },
        ],
      },
      isMounted: false,
      isTotalMounted: false,
      retentionStatisticsByRange: {
        rangeRetentionUnderEightyFive: { next: 0, no: 0, after: 0 },
      },
      retentionDatetime: '',
    };
    this.date = {
      filterStartDatetime: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      filterEndDatetime: moment().format('YYYY-MM-DD'),
    };
  }

  getRetentionStatisticsDdb = (datetime) => {
    ddb.getItem(
      {
        TableName: 'RetentionStatistics',
        Key: { datetime: { S: datetime } },
      },
      (err, data) => {
        if (err) console.log(err);
        else {
          console.log(data.Item);
          // undefined인 경우 이전 시간대로 재요청하기
          if (!data.Item) {
            const now = moment().subtract(2, 'h');
            if (now.hours() === 0) {
              let date = now.subtract(1, 'h').format('YYYY-MM-DD');
              datetime = date + 'T24:00:00';
            } else if (now.hours() % 2 === 1) {
              datetime = now
                .subtract(1, 'h')
                .minutes(0)
                .seconds(0)
                .format('YYYY-MM-DDTHH:mm:ss');
            } else {
              datetime = now
                .minutes(0)
                .seconds(0)
                .format('YYYY-MM-DDTHH:mm:ss');
            }

            ddb.getItem(
              {
                TableName: 'RetentionStatistics',
                Key: { datetime: { S: datetime } },
              },
              (err, data) => {
                if (err) console.log(err);
                else {
                  console.log(data.Item);
                  this.setState({
                    retentionStatistics: data.Item,
                    retentionDatetime:
                      moment(datetime).format('YYYY-MM-DD HH:mm'),
                  });
                }
              },
            );
          }
          this.setState({ retentionStatistics: data.Item });
        }
      },
    );
  };

  // getRetentionStatisticsRange = (filterStartDatetime, filterEndDatetime) => {
  //   api.get('/admin/statistics/retention', {
  //     params: {
  //       filterStartDatetime: moment(filterStartDatetime).format('YYYY-MM-DD HH:mm:ss'),
  //       filterEndDatetime: moment(filterEndDatetime).format('YYYY-MM-DD HH:mm:ss')
  //     }
  //   }).then(response => {
  //     const retentionStatisticsByRange = response.data.data.retentionStatisticsByRange;
  //     console.log(retentionStatisticsByRange);
  //     this.setState({retentionStatisticsByRange});
  //   })
  // };

  getDailyStatisticsDdb = (dateRange) => {
    return _.map(
      dateRange,
      (date) =>
        new Promise((resolve, reject) => {
          let time = moment(date).format('HH:mm:ss');
          time = time === '00:00:00' ? '24:00:00' : time;
          ddb.getItem(
            {
              TableName: 'Statistics',
              Key: { datetime: { S: date }, time: { S: time } },
            },
            (err, data) => {
              if (err) reject(err);
              else resolve(data.Item);
            },
          );
        }),
    );
    // return _.map(dateRange, async (date) => {
    //   let time = moment(date).format('HH:mm:ss');
    //   time = (time === '00:00:00') ? '24:00:00' : time;
    //   const stat = await ddb.getItem({
    //     TableName: 'Statistics',
    //     Key: {datetime: {S: date}, time: {S: time}}
    //   }, async (err, data) => {
    //     if (err) {
    //       alert(err)
    //     } else {
    //       const item = data.Item;
    //       console.log(item);
    //       // console.log(date, time);
    //       if (item) return item
    //     }
    //   });
    //   console.log(stat);
    //   return stat;
    // });
  };

  getRegisterDepositCard = (dateRange) => {
    return _.map(
      dateRange,
      (date) =>
        new Promise((resolve, reject) => {
          ddb.getItem(
            {
              TableName: 'RegisterDepositStatistics',
              Key: { date: { S: date } },
            },
            (err, data) => {
              if (err) reject(err);
              else resolve(data.Item);
            },
          );
        }),
    );
  };

  getStatisticsByDay = (dateRange) => {
    return _.map(dateRange, (startDate) =>
      api.get('/admin/statistics/daily', {
        params: {
          filterStartDatetime: startDate.format('YYYY-MM-DD HH:mm:ss'),
          filterEndDatetime: startDate.format('YYYY-MM-DD HH:mm:ss'),
        },
      }),
    );
  };
  getTotalStatistics = (filterStartDatetime, filterEndDatetime) => {
    api
      .get('/admin/statistics/total', {
        params: {
          filterStartDatetime: moment(filterStartDatetime).format(
            'YYYY-MM-DD HH:mm:ss',
          ),
          filterEndDatetime: moment(filterEndDatetime).format(
            'YYYY-MM-DD HH:mm:ss',
          ),
        },
      })
      .then((response) => {
        const totalStatistics = response.data.data.totalStatistics;
        console.log(totalStatistics);
        this.setState({ totalStatistics, isTotalMounted: true });
      });
  };
  getStatistics = async () => {
    this.setState({ isMounted: false, isTotalMounted: false });
    const { filterStartDatetime, filterEndDatetime } = this.date;
    this.getTotalStatistics(filterStartDatetime, filterEndDatetime);

    //리텐션 통계
    let datetime = null;
    if (moment().hours() === 0) {
      let date = moment().subtract(1, 'h').format('YYYY-MM-DD');
      datetime = date + 'T24:00:00';
    } else
      datetime = moment().minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss');
    this.getRetentionStatisticsDdb(datetime);

    //데일리 통계
    const nowDate = moment().format('YYYY-MM-DD');
    const diff = moment(filterEndDatetime).diff(filterStartDatetime, 'd') + 1;
    const dateRange = _.map(_.range(diff), (i) => {
      const date = moment(filterStartDatetime).add(i, 'd').format('YYYY-MM-DD');
      return date < nowDate
        ? date + 'T24:00:00'
        : moment().minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss');
    });
    const promises = this.getDailyStatisticsDdb(dateRange);
    const dailyStatistics = _.compact(_.map(await Promise.all(promises)));
    this.setState({ dailyStatistics });

    // this.getRetentionStatisticsRange(filterStartDatetime, filterEndDatetime);

    // 데일리 카드신청금액 조회
    const resultDateRange = _.map(_.range(30), (i) => {
      return moment(filterStartDatetime).add(i, 'd').format('YYYY-MM-DD');
    });
    const resultPromises = this.getRegisterDepositCard(resultDateRange);
    const registerDepositStatistics = _.compact(
      _.map(await Promise.all(resultPromises)),
    );
    this.setState({ registerDepositStatistics, isMounted: true }, () =>
      console.log(registerDepositStatistics),
    );
  };

  handleDate = (name, date) => {
    this.date[name] = date;
    console.log(name, date, this.date);
  };

  render() {
    const {
      isMounted,
      isTotalMounted,
      totalStatistics,
      dailyStatistics,
      retentionStatistics,
      registerDepositStatistics,
      retentionDatetime,
    } = this.state;
    return (
      <Container>
        <Grid>
          <Grid.Column width={3}>
            <Header as="h3">검색 조건</Header>
            <DateSelect handleDate={this.handleDate} />
            <Button onClick={this.getStatistics} style={styles.button}>
              조회
            </Button>
            <Button
              fluid
              as={Link}
              to={{ pathname: '/challenge-search' }}
              style={styles.button}
            >
              챌린지 검색 키워드
            </Button>
            <Button
              fluid
              as={Link}
              to={{
                pathname: '/statistics/total',
                state: { totalStatistics: totalStatistics },
              }}
              style={styles.button}
            >
              전체 통계보기
            </Button>
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={12}>
            {!isMounted && <Loader active inline="centered" />}
            {isMounted && (
              <Grid>
                <Grid.Row>
                  <Header as="h3">전체 통계</Header>
                  {isTotalMounted ? (
                    <>
                      <TotalStatistics totalStatistics={totalStatistics} />
                      <SexAgeStatistics totalStatistics={totalStatistics} />
                      <SexAgeFbStatistics totalStatistics={totalStatistics} />
                    </>
                  ) : (
                    <Loader active inline="centered" />
                  )}
                </Grid.Row>
                <Grid.Row>
                  <Header as="h3">일자별</Header>
                  <DailyStatistics dailyStatistics={dailyStatistics} />

                  {registerDepositStatistics.length > 0 && (
                    <>
                      <Header as="h3">종료일자별 결제금액</Header>
                      <RegisterDepositCard
                        registerDepositStatistics={registerDepositStatistics}
                      />
                    </>
                  )}
                </Grid.Row>
                {/*<Grid.Row>*/}
                {/*<Header as='h3'>리텐션 정보</Header>*/}
                {/*<RegisterRetention totalStatistics={totalStatistics}/>*/}
                {/*</Grid.Row>*/}
                {/*<Grid.Row>*/}
                {/*<Header as='h3'>비기너 챌린지 신청자들의 챌린지 등록 현황</Header>*/}
                {/*<BeginnerUser totalStatistics={totalStatistics}/>*/}
                {/*</Grid.Row>*/}
                {/*<Grid.Row>*/}
                {/*<Header as='h3'>이벤트 챌린지 신청자들의 챌린지 등록 현황</Header>*/}
                {/*<EventUser totalStatistics={totalStatistics}/>*/}
                {/*</Grid.Row>*/}
                {retentionStatistics ? (
                  <>
                    <Grid.Row>
                      <Header as="h3">
                        [전체]챌린지 참가 수에 따른 유저 분포 (기준:{' '}
                        {retentionDatetime})
                      </Header>
                      <RegisterDist retentionStatistics={retentionStatistics} />
                    </Grid.Row>
                    <Grid.Row>
                      <Header as="h3">
                        [전체]몇번을 신청하는가 (기준: {retentionDatetime})
                      </Header>
                      <Container
                        style={{
                          overflowX: 'auto',
                          height: 700,
                          marginTop: 50,
                        }}
                      >
                        <RegisterCount
                          retentionStatistics={retentionStatistics}
                        />
                      </Container>
                    </Grid.Row>
                    <Grid.Row>
                      <Header as="h3">
                        [전체]돈 잃은 사람들의 신청 현황 (기준:{' '}
                        {retentionDatetime})
                      </Header>
                      <LoseUserRegister
                        retentionStatistics={retentionStatistics}
                      />
                    </Grid.Row>
                    <Grid.Row>
                      <Header as="h3">
                        [전체]돈 잃은 회수에 따른 유저 분포 (기준:{' '}
                        {retentionDatetime})
                      </Header>
                      <LoseUserDist retentionStatistics={retentionStatistics} />
                    </Grid.Row>
                  </>
                ) : (
                  <h3>리텐션 데이터 조회 과정 중 오류가 발생하였습니다.</h3>
                )}
                <Divider hidden />

                {/*<Grid.Row>*/}
                {/*  <Header as='h3'>[기간]챌린지 참가 수에 따른 유저 분포</Header>*/}
                {/*  <RegisterDistRange retentionStatistics={retentionStatisticsByRange}/>*/}
                {/*</Grid.Row>*/}
                {/*<Grid.Row>*/}
                {/*  <Header as='h3'>[기간]몇번을 신청하는가</Header>*/}
                {/*  <Container style={{overflowX: 'auto', height: 700, marginTop: 50}}>*/}
                {/*    <RegisterCountRange retentionStatistics={retentionStatisticsByRange}/>*/}
                {/*  </Container>*/}
                {/*</Grid.Row>*/}
                {/*<Grid.Row>*/}
                {/*  <Header as='h3'>[기간]돈 잃은 사람들의 신청 현황</Header>*/}
                {/*  <LoseUserRegisterRange retentionStatistics={retentionStatisticsByRange}/>*/}
                {/*</Grid.Row>*/}
                {/*<Grid.Row>*/}
                {/*  <Header as='h3'>[기간]돈 잃은 회수에 따른 유저 분포</Header>*/}
                {/*  <LoseUserDistRange retentionStatistics={retentionStatisticsByRange}/>*/}
                {/*</Grid.Row>*/}
              </Grid>
            )}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default Statistics;

const styles = {
  button: {
    marginTop: 5,
  },
};
