import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  Form,
  Image,
  Table,
  Dimmer,
  Loader,
  Header,
  Icon,
  SemanticSIZES,
} from 'semantic-ui-react';
import { apis } from '../../../../../api';
import { wireframeUrl } from '../../../../../constant/imgUrls';
import { RaceUserWithImage } from 'src/types/race.types';

const RaceImage = ({ raceId }: { raceId: number }) => {
  const [users, setUsers] = useState<RaceUserWithImage[]>([]);
  const [size, setSize] = useState<SemanticSIZES>('small');
  const [isDownloading, setIsDownloading] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    _getRaceImages();
  }, []);

  const _getRaceImages = async () => {
    const { users: _users } = await apis.getRaceImages(raceId);
    setUsers(_users);
  };

  const changeImageSize = (e: {
    target: { value: React.SetStateAction<SemanticSIZES> };
  }) => {
    setSize(e.target.value);
  };

  const _downloadImage = async () => {
    setIsDownloading(true);
    const blob = await apis.downloadRaceImages(raceId);
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = `${raceId}_images.zip`;
    link.click();
    setIsDownloading(false);
  };

  const handleClick = (curr: boolean) => {
    setCollapsed(!curr);
  };

  return (
    <Accordion>
      <Accordion.Title
        active={!collapsed}
        onClick={() => handleClick(collapsed)}
      >
        <Header as={'h3'}>
          <Icon name="dropdown" /> 이미지 목록
        </Header>
      </Accordion.Title>
      <Accordion.Content active={!collapsed}>
        <div style={{ margin: 40 }}>
          <Dimmer active={isDownloading}>
            <Loader />
          </Dimmer>
          <Form>
            <Form.Group inline>
              <label>사이즈</label>
              <Form.Field
                label="짱크게"
                control="input"
                type="radio"
                name="size"
                value={'huge'}
                checked={size === 'huge'}
                onChange={changeImageSize}
              />
              <Form.Field
                label="크게"
                control="input"
                type="radio"
                name="size"
                value={'large'}
                checked={size === 'large'}
                onChange={changeImageSize}
              />
              <Form.Field
                label="중간"
                control="input"
                type="radio"
                name="size"
                value={'medium'}
                checked={size === 'medium'}
                onChange={changeImageSize}
              />
              <Form.Field
                label="작게"
                control="input"
                type="radio"
                name="size"
                value={'small'}
                checked={size === 'small'}
                onChange={changeImageSize}
              />
            </Form.Group>
          </Form>

          <Button
            floated="right"
            size="small"
            content={'전체 다운로드 (최대 10분 소요)'}
            onClick={_downloadImage}
          />

          <h3>유저별 이미지 목록</h3>
          <Table basic="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>유저id</Table.HeaderCell>
                <Table.HeaderCell>이름</Table.HeaderCell>
                <Table.HeaderCell>닉네임</Table.HeaderCell>
                <Table.HeaderCell>성별</Table.HeaderCell>
                <Table.HeaderCell>생년월일</Table.HeaderCell>
                <Table.HeaderCell>이미지 목록</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {users.map((user) => {
                return (
                  <Table.Row key={user.id}>
                    <Table.Cell collapsing>{user.id}</Table.Cell>
                    <Table.Cell collapsing>{user.name}</Table.Cell>
                    <Table.Cell collapsing>{user.nickname}</Table.Cell>
                    <Table.Cell collapsing>{user.gender}</Table.Cell>
                    <Table.Cell collapsing>{user.birthday}</Table.Cell>
                    <Table.Cell>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {user.images.map((image) => (
                          <div style={{ margin: 10 }} key={image.id}>
                            {!image.imageUrl ? (
                              <div>
                                <Image
                                  size={size as SemanticSIZES}
                                  src={wireframeUrl}
                                />
                              </div>
                            ) : (
                              <a
                                href={image.imageUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Image
                                  size={size as SemanticSIZES}
                                  src={image.imageUrl}
                                />
                              </a>
                            )}
                            <div>
                              <div>
                                [{image.type}] {image.unitValue}
                              </div>
                              <div>
                                {moment(image.updatedAt).format(
                                  'YY.MM.DD HH:mm:ss',
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </Accordion.Content>
    </Accordion>
  );
};

export default RaceImage;
