import React from 'react';
import { Image, Table } from 'semantic-ui-react';
import * as S from './Style';

const EventNewOgGuide = () => {
  return (
    <S.RowContainer>
      <div>
        <h5>용어 정리</h5>
        <Table basic="very" size="small">
          <Table.Body>
            <Table.Row>
              <Table.Cell>이벤트명</Table.Cell>
              <Table.Cell>
                챌린지 관련: web.chlngers.com/events/challenge/뒤에붙는url{' '}
                <br />
                스토어/영양제 관련
                <br />
                - 홈탭/팝업 배너에서 진입할 때 url:
                store.chlngers.com/events/chlngers-store/뒤에붙는url/h
                <br />
                - 스토어탭 배너에서 진입할 때 url:
                store.chlngers.com/events/chlngers-store/뒤에붙는url/s
                <br />* 현재 앱 내 설정차이로 인해 두 url을 따로 쓰셔야 하는 점
                양해 부탁드립니다 🙏🏻
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>노출시작일~노출종료일</Table.Cell>
              <Table.Cell>
                이벤트 페이지에 진입했을 때 노출기간에 해당하지 않을 경우
                데이터를 불러올 수 없음
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>og image</Table.Cell>
              <Table.Cell>링크 공유시 노출되는 이미지</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>og title</Table.Cell>
              <Table.Cell>링크 공유시 노출되는 제목</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>og description</Table.Cell>
              <Table.Cell>링크 공유시 노출되는 설명글</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <div>
        <Image
          src={
            'https://d246jgzr1jye8u.cloudfront.net/development/admin/1627001189271.png'
          }
          size={'medium'}
        />
      </div>
    </S.RowContainer>
  );
};

export default EventNewOgGuide;
