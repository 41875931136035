import { useEffect, useState } from 'react';
import { apis } from '../../../../api';
import { Divider, Table, Modal, Form, Button } from 'semantic-ui-react';
import moment from 'moment';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';
import _ from 'lodash';

const transactionTypeLabel = {
  PURCHASE_PRODUCT: '상품 구매',
  REFUND_PRODUCT: '상품 환불',
  EXCHANGE_FROM_CASH: '캐시>스토어 전환',
  EXCHANGE_FROM_CASH_CANCEL: '캐시>스토어 전환 취소',
  EXCHANGE_FROM_PRIZE: '상금>스토어 전환',
  ACHIEVEMENT: '인증',
  TEXT_REVIEW: '텍스트 리뷰 작성',
  PHOTO_REVIEW: '포토 리뷰 작성',
  EVENT: '이벤트(수동지급)',
  DELETE: '포인트 소멸',
  PEDOMETER: '만보기',
  OFFER_WALL: '오퍼월',
  OFFER_WALL_CANCEL: '오퍼월 취소',
};

const StorePointTransaction = ({ userId }) => {
  const [storeBalance, setStoreBalance] = useState(0);
  const [storePointTransactions, setStorePointTransactions] = useState([]);
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getStorePointTransactions();
  }, []);

  const getStorePointTransactions = async () => {
    const res = await apis.user.getUserStoreBalance(userId);
    const { storeBalance, storePointTransactionList } = res;

    const _storePointTransactionList = _.orderBy(
      storePointTransactionList,
      ['createdAt'],
      ['desc'],
    );

    setStoreBalance(storeBalance);
    setStorePointTransactions(_storePointTransactionList);
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const giveEventStorePoint = async () => {
    const res = await apis.user.giveEventStorePoint({
      userIds: [userId],
      description,
      amount: Number(amount),
    });
    const { ok } = res;
    if (ok) {
      alert('포인트 지급이 완료되었어요');
      setAmount('');
      setDescription('');
      await getStorePointTransactions();
    }
    closeModal();
  };

  let [
    prizeExchangeAmount,
    cashExchangeAmount,
    achievementAmount,
    productAmount,
    eventAmount,
    deleteAmount,
  ] = [0, 0, 0, 0, 0, 0];

  storePointTransactions.forEach((t) => {
    switch (t.type) {
      case 'PURCHASE_PRODUCT':
      case 'REFUND_PRODUCT':
        productAmount += t.amount;
        break;
      case 'EXCHANGE_FROM_CASH':
      case 'EXCHANGE_FROM_CASH_CANCEL':
        cashExchangeAmount += t.amount;
        break;
      case 'EXCHANGE_FROM_PRIZE':
        prizeExchangeAmount += t.amount;
        break;
      case 'ACHIEVEMENT':
        achievementAmount += t.amount;
        break;
      case 'EVENT':
        eventAmount += t.amount;
        break;
      case 'DELETE':
        deleteAmount += t.amount;
        break;
    }
  });

  return (
    <div style={{ marginTop: 100 }}>
      <Button size="small" onClick={showModal}>
        포인트 지급
      </Button>
      <Table basic="very" size="small" collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>스토어포인트 잔액</Table.HeaderCell>
            <Table.HeaderCell>캐시포인트 누적 전환액</Table.HeaderCell>
            <Table.HeaderCell>상금포인트 누적 전환액</Table.HeaderCell>
            <Table.HeaderCell>이벤트 누적액</Table.HeaderCell>
            <Table.HeaderCell>상품 구매 누적액</Table.HeaderCell>
            <Table.HeaderCell>인증 누적액</Table.HeaderCell>
            <Table.HeaderCell>삭제 누적액</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{setCurrencyFormat(storeBalance)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(cashExchangeAmount)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(prizeExchangeAmount)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(eventAmount)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(productAmount)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(achievementAmount)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(deleteAmount)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Divider hidden />
      <Divider hidden />

      <h5>스토어 포인트 거래내역</h5>
      <Table basic="very" size="small" collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>id</Table.HeaderCell>
            <Table.HeaderCell>날짜(한국시간)</Table.HeaderCell>
            <Table.HeaderCell>구분</Table.HeaderCell>
            <Table.HeaderCell>금액</Table.HeaderCell>
            <Table.HeaderCell>설명</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {storePointTransactions.map((t) => {
            return (
              <Table.Row key={t.id}>
                <Table.Cell>{t.id}</Table.Cell>
                <Table.Cell>
                  {moment(t.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </Table.Cell>
                <Table.Cell>{transactionTypeLabel[t.type]}</Table.Cell>
                <Table.Cell>{t.amount}</Table.Cell>
                <Table.Cell>
                  {t.type === 'EVENT'
                    ? t.eventName
                    : t.productName || t.description}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      <Modal size="tiny" open={modalOpen} onClose={closeModal}>
        <Modal.Header>이벤트 스토어 포인트 지급</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>스토어 포인트 지급액 (음수일 경우 차감)</label>
              <input
                name="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>스토어 포인트 지급사유</label>
              <input
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="지급" onClick={giveEventStorePoint} />
          <Button color="black" content="닫기" onClick={closeModal} />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default StorePointTransaction;
