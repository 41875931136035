import Button from '@component/Button';
import {
  ChallengeAchievementSetting,
  ReqCreateOrUpdateChallenge,
} from '@types';
import { FC } from 'react';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { getChallengeId } from '../../NewChallengeForm.util';
import {
  convertAchievementSetting,
  convertForm2Data,
} from './SubmitButton.serializer';
import { validate } from './SubmitButton.validator';
import { useEditedChecker } from './SubmitButton.checker';

type Props = {
  onSubmit: (
    challenge: ReqCreateOrUpdateChallenge,
    setting: ChallengeAchievementSetting,
  ) => void;
};
const SubmitButton: FC<Props> = ({ onSubmit }) => {
  const challengeId = getChallengeId();
  const challenge = useChallengeFormStore((s) => s.challenge);
  const allEdited = useEditedChecker();

  const handleSubmit = () => {
    if (!validate(challenge)) return;

    if (
      !window.confirm(
        allEdited
          ? '챌린지를 수정하시겠습니까?'
          : '수정되지 않은 항목이 있습니다.\n그대로 수정하시겠습니까?',
      )
    ) {
      return;
    }

    onSubmit(convertForm2Data(challenge), convertAchievementSetting(challenge));
  };

  return (
    <Button
      size="large"
      onClick={handleSubmit}
      style={{
        position: 'sticky',
        bottom: 20,
        background: '#FF4343',
        color: 'white',
      }}
    >
      {`챌린지 ${challengeId ? '수정' : '생성'}하기`}
    </Button>
  );
};

export default SubmitButton;
