import React, { useCallback, useEffect, useState } from 'react';
import { GoalCategoryWithRestrict } from '@types';
import {
  Button,
  Input,
  Modal,
  Form,
  Dropdown,
  DropdownProps,
} from 'semantic-ui-react';
import {
  getGoalCategoryFromDropdownOption,
  getOptionsFromGoalCategories,
} from '../Goal.utils';
import _ from 'lodash';
import ChipList from '@component/ChipList';
import { apis } from '@api/index';
import { DropdownOption } from 'src/types/common.types';

const INIT_GOAL_CATEGORY_RESTRICT = {
  id: 0,
  level1: '',
  level2: '',
  level3: '',
  level4: '',
  restrictGoalCategories: [],
};

const GoalCategoryCreateEditModal = (
  props: GoalCategoryCreateOrEditModalProps,
) => {
  const { handleOnClose, goalCategory: _goalCategory, handleOnSubmit } = props;
  const [goalCategory, setGoalCategory] = useState(
    _goalCategory || INIT_GOAL_CATEGORY_RESTRICT,
  );
  const [keyword, setKeyword] = useState<string>('');
  const [options, setOptions] = useState<DropdownOption[]>([]);

  const handleChange = (key: keyof GoalCategoryWithRestrict, value: any) => {
    const newGoalCategory = { ...goalCategory, [key]: value };
    setGoalCategory(newGoalCategory);
  };

  useEffect(() => {
    apis.challenge
      .getGoalCategories({
        offset: 0,
        limit: 100,
        keyword,
      })
      .then((res) => {
        if (res?.response?.data && 'err' in res?.response?.data) {
          setOptions([]);
        }

        setOptions(getOptionsFromGoalCategories(res.goalCategories));
      });
  }, [keyword]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchKeywordChange = useCallback(
    _.debounce((e, { searchQuery }) => {
      setKeyword(searchQuery);
    }, 300),
    [],
  );

  const handleRemoveChip = (index: number) => {
    const _restrictedGoalCategories =
      goalCategory.restrictGoalCategories.filter((o, idx) => idx !== index);
    const newGoalCategory = {
      ...goalCategory,
      restrictGoalCategories: _restrictedGoalCategories,
    };
    setGoalCategory(newGoalCategory);
  };

  const handleChangeRestrictGoalCategories = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps,
  ) => {
    const goalCategoryId = (value as Array<unknown>).at(-1) as number;
    const gc = getGoalCategoryFromDropdownOption(goalCategoryId, options);
    if (gc) {
      const _restrictedGoalCategories = [
        ...goalCategory.restrictGoalCategories,
      ];
      _restrictedGoalCategories.push(gc);
      handleChange('restrictGoalCategories', _restrictedGoalCategories);
    }
    setKeyword('');
  };

  return (
    <Modal size="tiny" onClose={handleOnClose} open>
      <Modal.Header>
        목표 카테고리 {goalCategory.id > 0 ? '편집' : '생성'}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>level1</label>
            <Input
              value={goalCategory.level1}
              onChange={(e) => handleChange('level1', e.target.value.trim())}
            />
          </Form.Field>
          <Form.Field>
            <label>level2</label>
            <Input
              value={goalCategory.level2}
              onChange={(e) => handleChange('level2', e.target.value.trim())}
            />
          </Form.Field>
          <Form.Field>
            <label>level3</label>
            <Input
              value={goalCategory.level3}
              onChange={(e) => handleChange('level3', e.target.value.trim())}
            />
          </Form.Field>
          <Form.Field>
            <label>level4</label>
            <Input
              value={goalCategory.level4}
              onChange={(e) => handleChange('level4', e.target.value.trim())}
            />
          </Form.Field>
          <Form.Field>
            <label>동시 참여 제한 카테고리</label>
            <ChipList
              style={{ marginBottom: 6 }}
              chipList={goalCategory.restrictGoalCategories.map(
                ({ id, level1, level2, level3, level4 }) =>
                  `[${id}]${level1}|${level2}|${level3}|${level4}`,
              )}
              removable
              onRemovePress={handleRemoveChip}
            />
            <Dropdown
              search
              selection
              multiple
              options={options}
              value={[keyword]}
              closeOnChange
              onSearchChange={handleSearchKeywordChange}
              onChange={handleChangeRestrictGoalCategories}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="blue"
          content="제출"
          onClick={() => handleOnSubmit(goalCategory)}
        />
        <Button color="black" content="닫기" onClick={handleOnClose} />
      </Modal.Actions>
    </Modal>
  );
};

export default GoalCategoryCreateEditModal;

type GoalCategoryCreateOrEditModalProps = {
  goalCategory: GoalCategoryWithRestrict | null;
  handleOnSubmit: (gc: GoalCategoryWithRestrict) => void;
  handleOnClose: () => void;
};
