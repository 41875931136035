import React, { useDeferredValue, useMemo, useState } from 'react';
import ChipList from '@component/ChipList';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import TextInput from '@component/TextInput';
import {
  DropdownOnSearchChangeData,
  Dropdown as SemanticDropdown,
} from 'semantic-ui-react';
import Button from '@component/Button';
import _ from 'lodash';
import { LabelMiniInfo } from '@types';
import { useCreateLabels, useGetLabels } from '@queries/common/useLabels';

type Props = {
  labelIds: number[];
  setLabelIds: React.Dispatch<React.SetStateAction<number[]>>;
};

const LabelField = ({ labelIds, setLabelIds }: Props) => {
  const [labelName, setLabelName] = useState('');
  const [keyword, setKeyword] = useState<string>('');
  const defferedKeyword = useDeferredValue(keyword);

  const { data, refetch } = useGetLabels();

  const { mutate } = useCreateLabels({
    onSuccess: async ({ label }) => {
      const { id } = label;
      if (id) {
        setLabelIds([...labelIds, id]);
        setLabelName('');
        refetch();
      }
    },
  });

  const selectedLabels = useMemo(() => {
    if (!data) return [];
    return data.labels.filter(({ id }) => labelIds.includes(id));
  }, [data, labelIds]);

  const options = useMemo(() => {
    if (!data) return [];
    return data.labels
      .filter(
        ({ name, id }) =>
          String(id).includes(defferedKeyword) ||
          name.includes(defferedKeyword),
      )
      .map(({ id, name }, index) => ({
        key: `${id}-${index}`,
        text: `[${id}]${name}`,
        value: id,
      }));
  }, [data, defferedKeyword]);

  const handleSearchKeywordChange = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    { searchQuery }: DropdownOnSearchChangeData,
  ) => {
    setKeyword(searchQuery);
  };

  const handleRemoveChip = (index: number) => {
    const updatedLabelIds = [...labelIds];
    updatedLabelIds.splice(index, 1);
    setLabelIds(updatedLabelIds);
  };

  const handleCreateLabel = () => {
    mutate(labelName);
  };

  return (
    <FlexBox.Column gap={5} style={{ marginTop: 14 }}>
      <FlexBox.Row justifyContent="space-between" alignItems="flex-end">
        <Label>라벨 (내부 유저필터용)</Label>

        <FlexBox.Row gap={4}>
          <TextInput
            value={labelName}
            onChange={setLabelName}
            placeholder="새로운 라벨 생성하기"
            style={{ width: 240 }}
          />
          <Button onClick={handleCreateLabel}>생성</Button>
        </FlexBox.Row>
      </FlexBox.Row>

      <ChipList
        style={{ marginBottom: 6 }}
        chipList={selectedLabels.map(({ name, id }) => `[${id}] ${name}`)}
        removable
        onRemovePress={handleRemoveChip}
      />

      <SemanticDropdown
        search
        selection
        multiple
        placeholder="기존 라벨 추가하기"
        options={options}
        closeOnChange
        onSearchChange={handleSearchKeywordChange}
        onChange={(e, { value }) => {
          const newId = (value as Array<unknown>).at(-1) as number;
          setLabelIds((prev) =>
            prev.includes(newId) ? prev : [...prev, newId],
          );
        }}
      />
    </FlexBox.Column>
  );
};

export default LabelField;
