import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'password'>;

export const validate: FormValidator<Params> = ({ password }) => {
  if (typeof password !== 'string')
    return { isValid: false, message: '참가코드를 확인해주세요' };

  return { isValid: true };
};
