import { FC, useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { colors } from '../../../../../constant/colors';
import * as S from './RaceOptionProductsForm.styles';
import { generateId } from '../../../../../utils/number';
import { Race } from '@types';
import { checkIsNewOption } from '@utils/race';

const RaceOptionProductsForm: FC<RaceOptionProductsFormProps> = ({
  optionValues,
  handleOptionValues,
}) => {
  const [purchaseCountMap, setPurchaseCountMap] = useState<Map<number, number>>(
    new Map(),
  );

  useEffect(() => {
    const map = new Map<number, number>();
    optionValues.forEach((option) => {
      option.options?.values.forEach((v) => {
        map.set(v.id!, v.totalCount - v.remainCount);
      });
    });
    setPurchaseCountMap(map);
  }, [optionValues]);

  const handleClickAddProduct = () => {
    handleOptionValues([
      ...optionValues,
      {
        id: generateId(),
        name: '',
        options: {
          values: [],
        },
      },
    ]);
  };

  const handleClickAddProductOption = (id: number) => {
    handleOptionValues(
      optionValues.map((o) =>
        o.id === id
          ? {
              ...o,
              options: {
                values: [
                  ...(o.options?.values ?? []),
                  {
                    id: generateId(),
                    name: '',
                    remainCount: 1000,
                    totalCount: 1000,
                    isDisplayed: true,
                  },
                ],
              },
            }
          : o,
      ),
    );
  };

  const handleClickDeleteProductOption = (id: number) => {
    handleOptionValues(
      optionValues.map((o) => ({
        ...o,
        options: {
          values: o.options?.values.filter((v) => v.id !== id) ?? [],
        },
      })),
    );
  };

  const handleChangeName = (idx: number, text: string) => {
    handleOptionValues(
      optionValues.map((o, i) => (i === idx ? { ...o, name: text } : o)),
    );
  };

  const handleChangeProductOptionSubName = (id: number, subName: string) => {
    handleOptionValues(
      optionValues.map((o) => ({
        ...o,
        options: {
          values:
            o.options?.values.map((v) =>
              v.id === id ? { ...v, name: subName } : v,
            ) ?? [],
        },
      })),
    );
  };

  const handleChangeProductOptionRemainCount = (
    id: number,
    remainCount: number,
  ) => {
    handleOptionValues(
      optionValues.map((o) => ({
        ...o,
        options: {
          values:
            o.options?.values.map((v) => {
              if (v.id === id) {
                return {
                  ...v,
                  totalCount: remainCount + (purchaseCountMap.get(v.id) ?? 0),
                  remainCount,
                };
              }
              return v;
            }) ?? [],
        },
      })),
    );
  };

  const handleDeleteProduct = (id: number) => {
    handleOptionValues(optionValues.filter((o) => o.id !== id));
  };

  const toggleProductOptionIsDisplayed = (id: number, isDisplayed: boolean) => {
    handleOptionValues(
      optionValues.map((o) => ({
        ...o,
        options: {
          values:
            o.options?.values.map((v) =>
              v.id === id ? { ...v, isDisplayed } : v,
            ) ?? [],
        },
      })),
    );
  };

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
      {optionValues.map(({ id, name, options }, i) => (
        <div
          style={{
            display: 'flex',
            marginTop: 10,
            gap: 8,
            height: 'fit-content',
            width: 'fit-content',
          }}
          key={i}
        >
          <div
            style={{
              border: `1px solid ${colors.GRAY_500}`,
              borderRadius: 4,
              padding: 10,
              minWidth: 300,
              maxWidth: 500,
            }}
          >
            <label>상품명</label>
            <input
              value={name}
              onChange={(e) => handleChangeName(i, e.target.value)}
            />
            {options?.values.map((subOption) => {
              return (
                <div
                  key={subOption.id}
                  style={{
                    display: 'flex',
                    marginTop: 8,
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <label>상품부제(사이즈)</label>
                    <input
                      value={subOption.name}
                      style={{ width: 120 }}
                      onChange={(e) =>
                        handleChangeProductOptionSubName(
                          subOption.id ?? 0,
                          e.target.value,
                        )
                      }
                    />
                  </div>
                  <div style={{ marginLeft: 8 }}>
                    <label>
                      남은수량 (기존전체수량: {subOption.totalCount} / 판매수량:{' '}
                      {purchaseCountMap.get(subOption.id!) ?? 0})
                    </label>
                    <input
                      value={subOption.remainCount}
                      style={{ width: 120 }}
                      onChange={(e) => {
                        const remainCount = parseInt(e.target.value, 10);
                        handleChangeProductOptionRemainCount(
                          subOption.id!,
                          Number.isNaN(remainCount) ? 0 : remainCount,
                        );
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: 8 }}>
                    <label>노출 중지</label>
                    <Checkbox
                      toggle
                      checked={subOption.isDisplayed === false}
                      name="isDisplayed"
                      onChange={(_, { checked }) => {
                        toggleProductOptionIsDisplayed(subOption.id!, !checked);
                      }}
                    />
                  </div>
                  {options?.values.length > 1 && (
                    <div style={{ marginLeft: 8 }}>
                      <S.OptionDeleteButton
                        onClick={() =>
                          handleClickDeleteProductOption(subOption.id!)
                        }
                      >
                        삭제
                      </S.OptionDeleteButton>
                    </div>
                  )}
                </div>
              );
            })}
            <div style={{ marginTop: 8 }}>
              <S.AddButton onClick={() => handleClickAddProductOption(id!)}>
                상품 옵션 추가
              </S.AddButton>
            </div>
          </div>
          <S.DeleteButton onClick={() => handleDeleteProduct(id!)}>
            상품 삭제
          </S.DeleteButton>
        </div>
      ))}

      <div style={{ marginTop: 8 }}>
        <S.AddButton onClick={handleClickAddProduct}>상품 추가</S.AddButton>
      </div>
    </>
  );
};

type RaceOptionProductsFormProps = {
  optionValues: Race.ProductGroupOptionValue[];
  handleOptionValues: (products: Race.ProductGroupOptionValue[]) => void;
};

export default RaceOptionProductsForm;
