import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'userFilterId'>,
  Pick<ChallengeFormType, 'userFilter'>
> = ({ userFilterId }) => ({
  userFilter: {
    id: userFilterId || null,
    name: null,
  },
});

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'userFilter'>,
  Pick<ReqCreateOrUpdateChallenge, 'userFilterId'>
> = ({ userFilter }) => ({
  userFilterId: userFilter.id || undefined,
});
