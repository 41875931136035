import React from 'react';
import {
  DescriptionModuleFormType,
  SupportersInstagramModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import Label from '@component/Label';
import FlexBox from '@component/FlexBox/FlexBox';
import { cropAndUploadImage } from '@utils/image.utils';
import { DEFAULT_DATA } from './SupportersInstagramModule.constant';
import {
  convertForm2Data,
  convertData2Form,
} from './SupportersInstagramModule.serializer';
import CheckListForm from './components/CheckListForm/CheckListForm';
import ExampleImagesForm from './components/ExampleImagesForm/ExampleImagesForm';
import MainExampleImageForm from './components/MainExampleImageForm/MainExampleImageForm';
import {
  DescriptionModule,
  DescriptionModuleConvertData2Form,
  DescriptionModuleConvertForm2Data,
} from '../../DescriptionModuleForm.type';
import { validate } from './SupportersInstagramModule.validator';

type SupportersInstagramModuleProps = {
  type: 'COLLABO-SUPPORTERS-INSTAGRAM';
  data: SupportersInstagramModuleFormType;
  moduleIndex: number;
  onUpdate: (index: number, item: DescriptionModuleFormType) => void;
};
const SupportersInstagramModule: DescriptionModule<
  SupportersInstagramModuleProps
> = (props: SupportersInstagramModuleProps) => {
  const { type, data, moduleIndex, onUpdate } = props;
  const handleChangeChecklist = (
    checkList: SupportersInstagramModuleFormType['checkList'],
  ) => {
    onUpdate(moduleIndex, { type, data: { ...data, checkList } });
  };

  const handleChangeExampleImageUrls = (exampleImageUrls: string[]) => {
    onUpdate(moduleIndex, { type, data: { ...data, exampleImageUrls } });
  };

  const handleChangeImage: React.ChangeEventHandler<HTMLInputElement> = async (
    e,
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const image = document.createElement('img');
    image.src = URL.createObjectURL(file);

    const urls = await cropAndUploadImage(file);
    onUpdate(moduleIndex, {
      type,
      data: {
        ...data,
        collaboImageUrls: urls,
      },
    });
    e.target.value = '';
  };

  return (
    <FlexBox.Column
      style={{ border: '1px solid black', maxWidth: 720, padding: 12 }}
    >
      <Label>{type}</Label>
      <CheckListForm
        checkList={data.checkList}
        onChange={handleChangeChecklist}
      />

      <hr />

      <Label size="large" style={{ marginTop: 20 }}>
        제품 상세이미지(이미지첨부)
      </Label>
      <FlexBox.Row gap={8} alignItems="center" style={{ padding: '12px 0px' }}>
        <Label style={{ width: 120 }}>제휴사 이미지</Label>
        <input type="file" onChange={handleChangeImage} />
      </FlexBox.Row>
      <FlexBox.Row style={{ flexWrap: 'wrap' }}>
        {data.collaboImageUrls.map((url, i) => (
          <img key={i} src={url} style={{ width: 100 }} alt={`제품상세 ${i}`} />
        ))}
      </FlexBox.Row>

      <hr />

      <MainExampleImageForm
        imageUrl={data.mainExampleImageUrl}
        onChange={(url) =>
          onUpdate(moduleIndex, {
            type,
            data: {
              ...data,
              mainExampleImageUrl: url,
            },
          })
        }
      />

      <hr />

      <ExampleImagesForm
        exampleImageUrls={data.exampleImageUrls}
        onChange={handleChangeExampleImageUrls}
      />
    </FlexBox.Column>
  );
};

export default SupportersInstagramModule;

SupportersInstagramModule.moduleName = '제품체험(인스타그램)';
SupportersInstagramModule.defaultData = DEFAULT_DATA;
SupportersInstagramModule.validate = validate;
SupportersInstagramModule.convertForm2Data =
  convertForm2Data as DescriptionModuleConvertForm2Data;
SupportersInstagramModule.convertData2Form =
  convertData2Form as DescriptionModuleConvertData2Form;
