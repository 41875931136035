import FlexBox from '@component/FlexBox/FlexBox';
import React, { useEffect, useState } from 'react';
import { Button, Input, TextArea } from 'semantic-ui-react';
import {
  useCreateRaceCRMMutation,
  useDeleteRaceCRMMutation,
  useGetRaceCRM,
  useUpdateRaceCRMMutation,
} from '@queries/Race/useRaceCRM';
import { Crm, Race } from '@types';
import { OptionType } from '../RaceCRMPopUp';
import { getDefaultBody } from './CrmForm.utils';

function CrmForm(props: CrmFormProps) {
  const { race, activeMenu } = props;

  const getOfferWallTypes = (
    value: Crm.CrmOfferWallType | 'RACE_INSTAGRAM_AND_SURVEY',
  ): Crm.CrmOfferWallType[] => {
    return value === 'RACE_INSTAGRAM_AND_SURVEY'
      ? ['RACE_INSTAGRAM_MISSION', 'RACE_SURVEY']
      : [value];
  };

  const offerWallTypes = getOfferWallTypes(activeMenu.value);

  const crmInitialValue: Race.RaceCrmInfo = {
    title: '',
    body: getDefaultBody(offerWallTypes),
    deepLink: '',
    serviceType: 'RACE',
    serviceId: race.id,
    offerWallTypes,
  };
  const [crmInfo, setCrmInfo] = useState<Race.RaceCrmInfo>(crmInitialValue);
  const isEdit = !!crmInfo.id;

  const { data, isFetching, refetch } = useGetRaceCRM(race.id, offerWallTypes);

  useEffect(() => {
    setCrmInfo((prev) => ({
      ...prev,
      offerWallTypes: getOfferWallTypes(activeMenu.value),
    }));
  }, [activeMenu.value]);

  useEffect(() => {
    if (!data) return;

    const template = data.templates[0];
    if (!template) {
      setCrmInfo(crmInitialValue);
      return;
    }

    setCrmInfo((prev) => ({
      ...prev,
      id: template.id,
      offerWallTypes: template.offerWallTypes,
      ...template.info,
    }));
  }, [isFetching, data]);

  const { mutateAsync: createMutateAsync } = useCreateRaceCRMMutation({
    onSuccess: (res) => {
      if (!res) return;
      const { success } = res;
      if (success) {
        alert('CRM 설정 완료');
      }
    },
  });

  const { mutateAsync: updateMutateAsync } = useUpdateRaceCRMMutation({
    onSuccess: (res) => {
      if (!res) return;
      const { success } = res;
      if (success) {
        alert('CRM 수정 완료');
      }
    },
  });

  const { mutate: deleteMutate } = useDeleteRaceCRMMutation({
    onSuccess: (res) => {
      if (res.success) {
        setCrmInfo(crmInitialValue);
        alert('삭제 완료!');
      }
    },
  });

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setCrmInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleCrmBodyChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const body = e.currentTarget.value;
    setCrmInfo((prev) => ({ ...prev, body }));
  };

  const handleDeleteClick = () => {
    if (window.confirm('정말 CRM을 삭제하시겠습니까?')) {
      if (crmInfo.id) {
        deleteMutate(crmInfo.id);
      }
    }
  };

  const handleInitialClick = () => {
    if (window.confirm('정말 초기화 하시겠습니까?')) {
      setCrmInfo({
        ...crmInitialValue,
        id: isEdit ? crmInfo.id : undefined,
      });
    }
  };

  const handleSubmitClick = async () => {
    if (!crmInfo.title || !crmInfo.body) {
      alert('제목과 메세지를 입력해주세요.');
      return;
    }

    try {
      const { id } = crmInfo;
      const res = await (id
        ? updateMutateAsync({ id, ...crmInfo })
        : createMutateAsync(crmInfo));

      if (res.success) {
        refetch();
      }
    } catch (err) {
      console.log('CRM submit error:', err);
    }
  };

  return (
    <>
      <FlexBox.Column gap={10}>
        <FlexBox.Column>
          <h3 style={{ color: 'blue' }}>
            *CRM 대상: {activeMenu.targetText} 미참여 유저
          </h3>
        </FlexBox.Column>

        <FlexBox.Column>
          <span>{'유저 실제 이름: {{.Name}}'}</span>
          <span>{'유저 닉네임: {{.Nickname}}'}</span>
          <span>
            {'⚠️ {{.Name}}, {{.Nickname}}은 동시에 사용할 수 없습니다!'}
          </span>
        </FlexBox.Column>
      </FlexBox.Column>

      <FlexBox.Column gap={10} style={{ marginTop: 20, marginBottom: 40 }}>
        <strong>CRM 제목</strong>
        <Input
          type="text"
          name="title"
          value={crmInfo.title}
          onChange={handleTextChange}
        />

        <strong>CRM 메세지</strong>
        <TextArea
          rows={15}
          onChange={handleCrmBodyChange}
          value={crmInfo.body}
        />

        <strong>딥링크</strong>
        <span>
          문자 내용에 딥링크가 포함된다면, 해당 딥링크를 여기에 꼭 입력해주세요!
        </span>
        <Input
          name="deepLink"
          value={crmInfo.deepLink}
          onChange={handleTextChange}
        />
      </FlexBox.Column>

      <FlexBox.Row justifyContent="space-between">
        <Button onClick={handleInitialClick}>입력 내용 초기화</Button>

        <div>
          <Button onClick={handleSubmitClick}>
            {isEdit ? '수정하기' : '저장하기'}
          </Button>
          <Button disabled={!isEdit} onClick={handleDeleteClick}>
            삭제하기
          </Button>
        </div>
      </FlexBox.Row>
    </>
  );
}

type CrmFormProps = {
  race: Race.RaceForAdmin;
  activeMenu: OptionType;
};

export default CrmForm;
