import Button from '@component/Button';
import ChipList from '@component/ChipList';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import TextInput from '@component/TextInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './LabelIdsForm.serializer';
import { useLabelIds, useLabelIdsActions } from './LabelIdsForm.store';
import {
  getOptionsFromLabels,
  getSelectedLabelsFromId,
} from './LabelIdsForm.utils';
import { validate } from './LabelIdsForm.validator';
import { useCreateLabels, useGetLabels } from '@queries/common/useLabels';

const LabelIdsForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'labels'>,
  Pick<ChallengeFormType, 'labelIds'>,
  Pick<ReqCreateOrUpdateChallenge, 'labelIds'>
> = () => {
  const { labelIds } = useLabelIds();
  const { setLabelIds } = useLabelIdsActions();

  const [keyword, setKeyword] = useState<string>('');

  const [labelName, setLabelName] = useState('');

  const { data, refetch } = useGetLabels();

  const { mutate } = useCreateLabels({
    onSuccess: async ({ label }) => {
      const { id } = label;
      if (id) {
        setLabelIds([...labelIds, id]);
        setLabelName('');
        refetch();
      }
    },
  });

  const selectedLabels = useMemo(() => {
    if (!data) return [];
    return getSelectedLabelsFromId(data.labels, labelIds);
  }, [data, labelIds]);

  const options = useMemo(() => {
    if (!data) return [];
    return getOptionsFromLabels(data.labels, keyword);
  }, [data, keyword]);

  const handleSearchKeywordChange = useCallback(
    _.debounce((e, { searchQuery }) => {
      setKeyword(searchQuery);
    }, 300),
    [],
  );

  const handleRemoveChip = (index: number) => {
    const updatedLabelIds = [...labelIds];
    updatedLabelIds.splice(index, 1);
    setLabelIds(updatedLabelIds);
  };

  const handleCreateLabel = () => {
    mutate(labelName);
  };

  return (
    <FlexBox.Column gap={5} style={{ marginTop: 14 }}>
      <FlexBox.Row justifyContent="space-between" alignItems="flex-end">
        <Label>라벨 (내부 유저필터용)</Label>

        <FlexBox.Row gap={4}>
          <TextInput
            value={labelName}
            onChange={setLabelName}
            placeholder="새로운 라벨 생성하기"
            style={{ width: 240 }}
          />
          <Button onClick={handleCreateLabel}>생성</Button>
        </FlexBox.Row>
      </FlexBox.Row>

      <ChipList
        style={{ marginBottom: 6 }}
        chipList={selectedLabels.map(({ name, id }) => `[${id}] ${name}`)}
        removable
        onRemovePress={handleRemoveChip}
      />

      <SemanticDropdown
        search
        selection
        multiple
        placeholder="기존 라벨 추가하기"
        options={options}
        value={[keyword]}
        closeOnChange
        onSearchChange={handleSearchKeywordChange}
        onChange={(e, { value }) => {
          const newId = (value as Array<unknown>).at(-1) as number;
          setLabelIds(
            labelIds.includes(newId) ? labelIds : [...labelIds, newId],
          );
        }}
      />
    </FlexBox.Column>
  );
};

export default LabelIdsForm;

LabelIdsForm.validate = validate;
LabelIdsForm.convertData2Form = convertData2Form;
LabelIdsForm.convertForm2Data = convertForm2Data;
