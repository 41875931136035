import React, { useState, useEffect } from 'react';
import { newEventType } from '../../../constant/eventType';
import { apis } from '../../../api';
import { Container, Button, Header, Divider, Grid } from 'semantic-ui-react';
import _ from 'lodash';
import EventList from './EventList';
import EventNewOgGuide from './EventNewOgGuide';
import { useHistory } from 'react-router-dom';
import MarketingMenu from '../MarketingMenu';

const EventNew = () => {
  const [events, setEvents] = useState([]);
  const history = useHistory();

  useEffect(() => {
    _getEvents();
  }, []);

  const _getEvents = async () => {
    let { events: _events } = await apis.getEventDataList();
    _events = _events
      .filter((e) => Object.keys(newEventType).includes(e.data.type))
      .reverse();
    setEvents(_events);
  };

  const _deleteEvent = async (eventId) => {
    const { ok } = await apis.deleteEventData(eventId);

    if (ok) {
      alert('이벤트 페이지 데이터가 삭제되었어요.');
      const _events = _.filter(events, (item) => item.id !== value);
      this.setState({ events: _events });
    } else {
      alert('이벤트 페이지 데이터 삭제에 실패했어요. 다시 시도해 주세요.');
    }
  };

  const _copyEvent = async (eventId) => {
    const { event } = await apis.copyEventData(eventId);

    if (event) {
      alert('복사 성공!');
      setEvents((events) => [event, ...events]);
    } else {
      alert('복사 실패!');
    }
  };

  const _goToEventAddPage = () => {
    history.push(`/events/create`);
  };

  const _goToEventEditPage = (eventId) => {
    history.push(`/events/${eventId}/edit`);
  };

  return (
    <div style={{ margin: 40 }}>
      <Grid columns="equal">
        <Grid.Column width={2}>
          <MarketingMenu />
        </Grid.Column>
        <Grid.Column>
          <Container>
            <Header as="h3">
              이벤트 페이지 관리{' '}
              <Button size="tiny" onClick={_goToEventAddPage}>
                추가
              </Button>
            </Header>

            <EventNewOgGuide />

            <Divider hidden />
            <Divider hidden />
            <Divider hidden />

            <EventList
              events={events}
              goToEventEditPage={_goToEventEditPage}
              deleteEvent={_deleteEvent}
              copyEvent={_copyEvent}
            />
          </Container>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default EventNew;
