import { Button, Divider, Grid, Menu, MenuItemProps } from 'semantic-ui-react';
import DateSelect from '../DateSelect';
import QuestionExcelDownloadButton from '../QuestionExcelDownloadButton';
import { Question } from '@types';
import React, { useEffect } from 'react';

type QuestionSideBarProps = {
  selectedChallengeType: string;
  handleItemClick: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: MenuItemProps,
  ) => void;
  filterStartDatetime: string;
  filterEndDatetime: string;
  setFilterStartDatetime: React.Dispatch<React.SetStateAction<string>>;
  setFilterEndDatetime: React.Dispatch<React.SetStateAction<string>>;
  questions: Question[];
  questionCount: {
    collaboQuestion: number;
    storeQuestion: number;
    raceQuestion: number;
  };
  questionsForDownload: any;
  showAddTemplateModal: () => void;
  showOrderTemplateModal: () => void;
  showEditTemplateModal: () => void;
  showValidationModal: () => void;
  validated: boolean;
};

function QuestionSideBar({
  selectedChallengeType,
  handleItemClick,
  filterStartDatetime,
  filterEndDatetime,
  setFilterStartDatetime,
  setFilterEndDatetime,
  questions,
  questionCount,
  questionsForDownload,
  showAddTemplateModal,
  showOrderTemplateModal,
  showEditTemplateModal,
  showValidationModal,
  validated,
}: QuestionSideBarProps) {
  const [tempStartDatetime, setTempStartDatetime] = React.useState<string>('');
  const [tempEndDatetime, setTempEndDatetime] = React.useState<string>('');

  useEffect(() => {
    setTempStartDatetime(filterStartDatetime);
    setTempEndDatetime(filterEndDatetime);
  }, [filterStartDatetime, filterEndDatetime]);

  const handleButtonClick = () => {
    setFilterStartDatetime(tempStartDatetime);
    setFilterEndDatetime(tempEndDatetime);
  };
  return (
    <Grid.Column width={3}>
      <Menu fluid secondary vertical>
        <Menu.Item
          name="CHALLENGE"
          active={selectedChallengeType === 'CHALLENGE'}
          onClick={handleItemClick}
        >
          챌린지 1:1문의 (
          {questions &&
            questions.length -
              questionCount.collaboQuestion -
              questionCount.raceQuestion}
          )
        </Menu.Item>
        <Menu.Item
          name="COLLABO"
          active={selectedChallengeType === 'COLLABO'}
          onClick={handleItemClick}
        >
          제휴 챌린지 1:1문의 ({questionCount.collaboQuestion})
        </Menu.Item>
        <Menu.Item
          name="STORE"
          active={selectedChallengeType === 'STORE'}
          onClick={handleItemClick}
        >
          스토어 1:1문의 ({questionCount.storeQuestion})
        </Menu.Item>
        <Menu.Item
          name="RACE"
          active={selectedChallengeType === 'RACE'}
          onClick={handleItemClick}
        >
          랜선대회 1:1문의 ({questionCount.raceQuestion})
        </Menu.Item>
      </Menu>
      <Grid.Row>
        <DateSelect
          tempStartDatetime={tempStartDatetime}
          tempEndDatetime={tempEndDatetime}
          setTempStartDatetime={setTempStartDatetime}
          setTempEndDatetime={setTempEndDatetime}
        />
      </Grid.Row>

      <Button fluid style={styles.button} onClick={handleButtonClick}>
        조회
      </Button>
      <QuestionExcelDownloadButton
        style={styles.button}
        questionExcelData={questionsForDownload}
        downloadable={validated}
        startDatetime={filterStartDatetime}
        endDatetime={filterEndDatetime}
        onRequestDownloadPress={showValidationModal}
      />
      <Divider hidden />
      <Button fluid onClick={showAddTemplateModal} style={styles.button}>
        1:1문의 템플릿추가
      </Button>
      <Button fluid onClick={showOrderTemplateModal} style={styles.button}>
        1:1문의 템플릿순서변경/삭제
      </Button>
      <Button fluid onClick={showEditTemplateModal} style={styles.button}>
        1:1문의 템플릿수정
      </Button>
    </Grid.Column>
  );
}

export default QuestionSideBar;

const styles = {
  button: {
    marginTop: 5,
  },
};
