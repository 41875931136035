type Props = {
  color: string;
};

const DownloadIcon = ({ color }: Props) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4914 17.1708V12.5874C17.4914 12.0833 17.9039 11.6708 18.408 11.6708C18.9122 11.6708 19.3247 12.0833 19.3247 12.5874V18.0874C19.3247 19.0958 18.4997 19.9208 17.4914 19.9208H4.65804C3.64971 19.9208 2.82471 19.0958 2.82471 18.0874V12.5874C2.82471 12.0833 3.23721 11.6708 3.74137 11.6708C4.24554 11.6708 4.65804 12.0833 4.65804 12.5874V17.1708C4.65804 17.6749 5.07054 18.0874 5.57471 18.0874H16.5747C17.0789 18.0874 17.4914 17.6749 17.4914 17.1708Z"
        fill={color}
      />
      <path
        d="M10.1487 11.3683L8.42537 9.645C8.06787 9.2875 7.49037 9.2875 7.13287 9.645C6.77537 10.0025 6.77537 10.58 7.13287 10.9375L10.4237 14.2283C10.7812 14.5858 11.3587 14.5858 11.7162 14.2283L15.007 10.9375C15.3645 10.58 15.3645 10.0025 15.007 9.645C14.6495 9.2875 14.072 9.2875 13.7145 9.645L11.982 11.3683V3.42083C11.982 2.91667 11.5695 2.50417 11.0654 2.50417C10.5612 2.50417 10.1487 2.91667 10.1487 3.42083V11.3683Z"
        fill={color}
      />
    </svg>
  );
};

export default DownloadIcon;
