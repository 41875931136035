import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'minRegisterDeposit'>,
  Pick<ReqCreateOrUpdateChallenge, 'minRegisterDeposit'>
> = ({ minRegisterDeposit }) => {
  if (minRegisterDeposit < 0)
    throw Error("[MinRegisterDepositForm] this shouldn't be happened");

  return {
    minRegisterDeposit,
  };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'minRegisterDeposit'>,
  Pick<ChallengeFormType, 'minRegisterDeposit'>
> = ({ minRegisterDeposit }) => ({
  minRegisterDeposit,
});
