import addDays from 'date-fns/addDays';
import isWeekend from 'date-fns/isWeekend';
import format from 'date-fns/format';
import ko from 'date-fns/locale/ko';
import {
  SpecialPrizeModuleFormType,
  SpecialPrizeTitle,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

const TOTAL_DEFAULT_TITLE = SpecialPrizeTitle.TOTAL;
const INDIVIDUAL_DEFAULT_TITLE = SpecialPrizeTitle.INDIVIDUAL_PRIZE;
const TOTAL_DEFAULT_NOTICE = '챌린지 100% 인증 달성자 대상 1/N 지급';

const isKRWeekendOrHoliday = (
  addedDate: Date,
  restDays: { date: string }[],
) => {
  if (isWeekend(addedDate)) return true;
  if (
    restDays.find((restDay) => format(addedDate, 'yyyy-MM-dd') === restDay.date)
  )
    return true;

  return false;
};

export const getDefault = (
  prizeType: SpecialPrizeModuleFormType['type'],
  resultDate: Date,
  // date: yyyy-MM-dd
  restDays: { date: string }[],
): SpecialPrizeModuleFormType => {
  let addedDate = addDays(resultDate, 10);
  while (isKRWeekendOrHoliday(addedDate, restDays)) {
    addedDate = addDays(addedDate, 1);
  }

  const noticeList = [
    `지급 예정일 : ${format(addedDate, 'M/d (E)', { locale: ko })}`,
  ];
  if (prizeType === 'total') {
    noticeList.unshift(TOTAL_DEFAULT_NOTICE);
  }
  return {
    type: prizeType,
    title:
      prizeType === 'total' ? TOTAL_DEFAULT_TITLE : INDIVIDUAL_DEFAULT_TITLE,
    noticeList,
  };
};
