import React from 'react';
import { Image } from 'semantic-ui-react';
import { colors } from '../../../../../constant/colors';
import { Race } from '@types';

const RaceDescriptionModule = ({
  module,
}: {
  module: Race.RaceDescriptionModule | Race.RaceHomeModule;
}) => {
  switch (module.type) {
    case 'FULL-IMAGE':
      return <FullImageModule module={module} />;
    case 'TEMPORARY-IMAGE':
      return <TemporaryImageModule module={module} />;
    case 'TOP_RANKS':
      return <TopRanksModule />;
    case 'USER_ACHIEVEMENTS':
      return <UserAchievementsModule />;
    case 'REGISTER-INFO':
      return <RegisterInfoModule module={module} />;
    case 'HYPERLINK':
      return <HyperlinkModule module={module} />;
    case 'GOODS':
      return <GoodsModule module={module} />;
    case 'SHARE-BOARD':
      return <ShareBoardModule module={module} />;
    case 'LEAGUE-IMAGE-RECORDS':
      return <LeagueImageRecordModule module={module} />;
    case 'CONTENTS-CARDS':
      return <ContensCardsModule module={module} />;
    case 'ONBOARDING':
      return <OnboardingModule />;
    case 'SUB_BANNER':
      return <SubBannerModule />;
    default:
      return null;
  }
};

export default RaceDescriptionModule;

const FullImageModule = ({
  module,
}: {
  module: Race.RaceFullImageDescriptionModule;
}) => {
  return <Image size="small" src={module.url} />;
};

const TemporaryImageModule = ({
  module,
}: {
  module: Race.RaceTemporaryImageDescriptionModule;
}) => {
  return (
    <>
      <div>기간 한정 프로모션 이미지</div>
      <Image size="small" src={module.url} />
      <div
        style={{
          padding: 10,
        }}
      >
        기간: {module.dueStartDate} ~ {module.dueEndDate}
      </div>
    </>
  );
};

const TopRanksModule = () => {
  return (
    <div
      style={{
        width: 150,
        padding: 10,
        textAlign: 'center',
      }}
    >
      (TOP3 노출)
    </div>
  );
};

const UserAchievementsModule = () => {
  return (
    <div
      style={{
        width: 150,
        padding: 10,
        textAlign: 'center',
      }}
    >
      (리그 기념샷 목록 노출)
    </div>
  );
};

const RegisterInfoModule = ({
  module,
}: {
  module: Race.RaceRegisterInfoDescriptionModule;
}) => {
  return (
    <div
      style={{
        backgroundColor: module.bgColor,
        color: module.textColor,
        padding: 10,
        width: 150,
      }}
    >
      선착순, 신청인원, 모집마감기한 등 표기
    </div>
  );
};

const HyperlinkModule = ({
  module,
}: {
  module: Race.RaceHyperlinkDescriptionModule;
}) => {
  return (
    <div style={{ width: 150, padding: 10 }}>
      <div
        style={{
          backgroundColor: module.buttonBgColor,
          color: module.buttonTextColor,
          border: `1px solid ${module.buttonBorderColor}`,
          borderRadius: 4,
          textAlign: 'center',
        }}
      >
        {module.text}
        <br />({module.route.type})
      </div>
    </div>
  );
};

const GoodsModule = ({
  module,
}: {
  module: Race.RaceGoodsDescriptionModule;
}) => {
  return (
    <>
      {module.goodsList.map((goods, idx) => (
        <div
          key={idx}
          style={{
            width: 150,
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: module.style.card.bgColor,
          }}
        >
          <div
            style={{
              borderRadius: 40,
              backgroundColor: module.style.label.bgColor,
              color: module.style.label.textColor,
              textAlign: 'center',
            }}
          >
            {goods.labelText}
          </div>
          <div
            style={{
              color: module.style.card.textColor,
              textAlign: 'center',
            }}
          >
            {goods.title}
            <br />
            {goods.subtitle}
          </div>
          <div
            style={{
              borderRadius: 10,
              padding: 10,
              backgroundColor: 'white',
            }}
          >
            <Image
              size="small"
              src={goods.imageUrl}
              style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
            />
            <div>
              {goods.additionalItems.map((item, i) => (
                <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
                  <Image size="mini" src={item.imageUrl} />
                  <div>{item.title}</div>
                </div>
              ))}
            </div>
            <a
              href={goods.button.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                style={{
                  borderRadius: 4,
                  backgroundColor: colors.GRAY_900,
                  color: colors.BASIC_WHITE,
                  textAlign: 'center',
                }}
              >
                {goods.button.text}
              </div>
            </a>
          </div>
        </div>
      ))}
    </>
  );
};

const ShareBoardModule = ({
  module,
}: {
  module: Race.RaceShareBoardDescriptionModule;
}) => {
  return (
    <div
      style={{
        width: 150,
        padding: 10,
        backgroundColor: module.bgColor,
        color: module.textColor,
        textAlign: 'center',
      }}
    >
      {module.title}
      <div
        style={{
          backgroundColor: colors.GRAY_900,
          color: colors.BASIC_WHITE,
          borderRadius: 4,
          padding: 4,
        }}
      >
        공유하기
      </div>
    </div>
  );
};

const LeagueImageRecordModule = ({
  module,
}: {
  module: Race.RaceHomeLeagueImageRecordsModule;
}) => {
  return (
    <div
      style={{
        backgroundColor: colors.GRAY_300,
        padding: 10,
        width: 150,
      }}
    >
      (참가자 기념샷 노출)
    </div>
  );
};

const ContensCardsModule = ({
  module,
}: {
  module: Race.RaceHomeContentsCardModule;
}) => {
  return (
    <div
      style={{
        backgroundColor: colors.GRAY_300,
        padding: 10,
        width: 150,
      }}
    >
      (컨텐츠 카드)
    </div>
  );
};

const OnboardingModule = () => {
  return (
    <div
      style={{
        backgroundColor: colors.GRAY_300,
        padding: 10,
        width: 150,
        fontWeight: 'bold',
      }}
    >
      온보딩 모듈
      <br />
      꿀피부, 다이어트, 요리, 플로깅 대회만 정상 노출됨
    </div>
  );
};

const SubBannerModule = () => {
  return (
    <div
      style={{
        backgroundColor: colors.GRAY_300,
        padding: 10,
        width: 150,
        fontWeight: 'bold',
      }}
    >
      서브배너 모듈
    </div>
  );
};
