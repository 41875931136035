import { ChallengeFullImageDescriptionModule } from '@chlngers/core/lib/src/types/challenge';
import { DescriptionFullImageModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const convertData2Form: (
  module: ChallengeFullImageDescriptionModule,
) => DescriptionFullImageModuleFormType = (module) => {
  return {
    type: module.type,
    imageList: module.imageList.map(({ url }) => url),
  };
};

export const convertForm2Data: (
  module: DescriptionFullImageModuleFormType,
) => ChallengeFullImageDescriptionModule = (module) => {
  if (!module) throw Error("this shouldn't be happened");
  return {
    type: module.type,
    imageList: module.imageList.map((url) =>
      // NOTE: 서브모듈에서 url 객체화가 두 번 처리되는 경우 예외처리
      typeof url === 'string' ? { url } : url,
    ),
  };
};
