import { DescriptionCollaboTopSummaryModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const DEFAULT_DATA: {
  type: 'COLLABO-TOP-SUMMARY';
  data: DescriptionCollaboTopSummaryModuleFormType['data'];
} = {
  type: 'COLLABO-TOP-SUMMARY',
  data: {
    mainHeader: '',
    subHeader: '',
    logoImage: '',
    image: '',
  },
};
