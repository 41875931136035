import Accordion from '@component/Accordion/Accordion';
import Button from '@component/Button';
import ErrorMsg from '@component/ErrorMsg';
import { ChallengeCustomField } from '@types';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './NaverBlogInfoListForm.serializer';
import {
  useNaverBlogInfoList,
  useUpdateNaverBlogInfoActions,
  useValidationData,
} from './NaverBlogInfoListForm.store';
import { validate } from './NaverBlogInfoListForm.validator';
import NaverBlogInfo from './components/NaverBlogInfo/NaverBlogInfo';

type Props = {
  open: boolean;
};

export const NaverBlogInfoListForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'naverBlogInfoList'>,
  Extract<ChallengeCustomField, { name: 'naverBlogInfoList' }>,
  Props
> = ({ open }) => {
  const naverBlogInfoList = useNaverBlogInfoList();
  const { add } = useUpdateNaverBlogInfoActions();

  const validationData = useValidationData();

  const validated = validate({
    naverBlogInfoList,
    ...validationData,
  });

  return (
    <Accordion
      title="네이버 블로그 인증 관련 정보 (회차 지정)"
      open={open}
      as="h5"
    >
      <ErrorMsg text={validated.isValid ? '' : validated.message} />
      {naverBlogInfoList.map((info, index) => (
        <NaverBlogInfo key={index} info={info} index={index} />
      ))}

      <Button
        text="회차 추가"
        onClick={add}
        fontColor="#fff"
        bgColor="#2185d0"
      />
    </Accordion>
  );
};

NaverBlogInfoListForm.validate = validate;
NaverBlogInfoListForm.convertData2Form = convertData2Form;
NaverBlogInfoListForm.convertForm2Data = convertForm2Data;
