import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useRegisterDateTime = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      registerStartDate: s.challenge.registerStartDate,
      registerEndDate: s.challenge.registerEndDate,
    })),
  );
};

export const useRegisterStartDateTimeActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setRegisterStartDateTime = (registerStartDate: string) =>
    updateChallengeForm({ registerStartDate });

  return { setRegisterStartDateTime };
};
