import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import api, { apiMedia, apiGo, setAuthToken, apiV3 } from '../../api';
import {
  setToken,
  setGoals,
  setActiveGoals,
  setChallenges,
  setActiveChallenges,
  setAchievements,
  setActiveAchievements,
  setBoards,
  setActiveBoards,
  setPushes,
  setActivePushes,
} from './Navigation.action';
import { InitStore } from '../../store';

const logout =
  (): ThunkAction<void, InitStore, void, Action<unknown>> => (dispatch) => {
    dispatch(setToken(''));
    dispatch(setGoals([]));
    dispatch(setActiveGoals([]));
    dispatch(setChallenges([]));
    dispatch(setActiveChallenges([]));
    dispatch(setAchievements([]));
    dispatch(setActiveAchievements([]));
    dispatch(setBoards([]));
    dispatch(setActiveBoards([]));
    dispatch(setPushes([]));
    dispatch(setActivePushes([]));
    setAuthToken(api, '');
    setAuthToken(apiMedia, '');
    setAuthToken(apiGo, '');
    setAuthToken(apiV3, '');
    localStorage.clear();
  };

export default (dispatch: ThunkDispatch<InitStore, void, Action<unknown>>) => ({
  logout: () => dispatch(logout()),
});
