import moment from 'moment';
import React, { useCallback, useState } from 'react';
// @ts-ignore
import { Workbook } from 'react-excel-workbook';
import { Accordion, Button, Header, Icon, Table } from 'semantic-ui-react';
import ValidationRequestModal from '../../../../ValidationRequestModal';
import { Race } from '@types';

const RaceReviewTable = ({
  race,
  reviews,
}: {
  race: Race.RaceAllInfo;
  reviews: Race.RaceReviewForAdmin[];
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleClick = useCallback((curr: boolean) => {
    setCollapsed(!curr);
  }, []);

  const showModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const _setValidated = useCallback((_validated: boolean) => {
    setValidated(_validated);
    setModalOpen(false);
  }, []);

  const keys: string[] = [];
  reviews.forEach((review) => {
    Object.keys(review.comments).forEach((k) => {
      if (!keys.includes(k)) {
        keys.push(k);
      }
    });
  });

  return (
    <>
      <Accordion>
        <Accordion.Title
          active={!collapsed}
          onClick={() => handleClick(collapsed)}
        >
          <Header as={'h3'}>
            <Icon name="dropdown" /> 리뷰 목록
          </Header>
        </Accordion.Title>
        <Accordion.Content active={!collapsed}>
          {validated ? (
            <Workbook
              filename={`${moment().format('YYYYMMDD')}_${
                race.title
              }_리뷰목록.xlsx`}
              element={
                <Button basic color="green" floated="right" size="mini">
                  Excel
                </Button>
              }
            >
              <Workbook.Sheet data={reviews} name="리뷰 목록">
                <Workbook.Column
                  label="리뷰id"
                  value={(review: Race.RaceReviewForAdmin) => review.id}
                />
                <Workbook.Column
                  label="작성일"
                  value={(review: Race.RaceReviewForAdmin) =>
                    moment(review.createdAt).format('YYYY.MM.DD HH:mm:ss')
                  }
                />
                <Workbook.Column
                  label="유저id"
                  value={(review: Race.RaceReviewForAdmin) => review.userId}
                />
                <Workbook.Column
                  label="이름"
                  value={(review: Race.RaceReviewForAdmin) => review.userName}
                />
                <Workbook.Column
                  label="닉네임"
                  value={(review: Race.RaceReviewForAdmin) =>
                    review.userNickname
                  }
                />
                <Workbook.Column
                  label="성별"
                  value={(review: Race.RaceReviewForAdmin) => review.gender}
                />
                <Workbook.Column
                  label="생년월일"
                  value={(review: Race.RaceReviewForAdmin) =>
                    moment(review.birthday).format('YYYY-MM-DD')
                  }
                />
                <Workbook.Column
                  label="평점"
                  value={(review: Race.RaceReviewForAdmin) => review.rate}
                />
                {keys.map((key) => (
                  <Workbook.Column
                    key={key}
                    label={key}
                    value={(review: Race.RaceReviewForAdmin) =>
                      review.comments[key] ?? ''
                    }
                  />
                ))}
              </Workbook.Sheet>
            </Workbook>
          ) : (
            <Button
              basic
              color="green"
              floated="right"
              size="mini"
              onClick={showModal}
            >
              엑셀 요청하기
            </Button>
          )}
          <Table basic="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>id</Table.HeaderCell>
                <Table.HeaderCell>작성일</Table.HeaderCell>
                <Table.HeaderCell>유저id</Table.HeaderCell>
                <Table.HeaderCell>닉네임</Table.HeaderCell>
                <Table.HeaderCell>성별</Table.HeaderCell>
                <Table.HeaderCell>생년월일</Table.HeaderCell>
                <Table.HeaderCell>평점</Table.HeaderCell>
                {keys.map((key) => (
                  <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {reviews.map((review) => (
                <Table.Row key={review.id}>
                  <Table.Cell>{review.id}</Table.Cell>
                  <Table.Cell>
                    {moment(review.createdAt).format('YY.MM.DD HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>{review.userId}</Table.Cell>
                  <Table.Cell>{review.userNickname}</Table.Cell>
                  <Table.Cell>{review.gender}</Table.Cell>
                  <Table.Cell>
                    {moment(review.birthday).format('YY.MM.DD')}
                  </Table.Cell>
                  <Table.Cell>{review.rate}</Table.Cell>
                  {keys.map((key) => (
                    <Table.Cell key={key}>
                      {review.comments[key] ?? ''}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Accordion.Content>
      </Accordion>

      <ValidationRequestModal
        validationModalOpen={modalOpen}
        closeValidationModal={closeModal}
        setValidated={_setValidated}
        location={'대회 정보'}
      />
    </>
  );
};

export default RaceReviewTable;
