import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { ChallengeFormType } from '../../NewChallengeForm.types';

export const useSpecialPrizeForm = () =>
  useChallengeFormStore((s) => s.challenge.specialPrize);

export const useSpecialPrizeFormSetter = () => {
  const update = useChallengeFormStore((s) => s.updateChallengeForm);
  const setSpecialPrizeForm = (
    specialPrize: ChallengeFormType['specialPrize'],
  ) => {
    update({ specialPrize });
  };

  return { setSpecialPrizeForm };
};
