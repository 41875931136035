import React from 'react';
import { Button, Icon, Input, Label } from 'semantic-ui-react';
import * as S from '../../Style';
import { isDescriptionListSetRequired } from '../../utils';

const OfferWallMission: React.FC<OfferWallMissionProps> = (props) => {
  const { offerWallInfo, descriptionList, setDescriptionList } = props;
  return (
    <>
      {isDescriptionListSetRequired(offerWallInfo.type) && (
        <S.Container>
          <h2>미션 설정</h2>
          <S.RowFormField>
            {descriptionList.map((description, idx) => {
              return (
                <S.RowContainer>
                  <Input
                    value={description.label}
                    onChange={(e) => {
                      const { value } = e.target;

                      const tempList = [...descriptionList];
                      tempList[idx] = {
                        description: descriptionList[idx].description,
                        label: value,
                      };

                      return setDescriptionList(tempList);
                    }}
                  />
                  <Input
                    value={description.description}
                    onChange={(e) => {
                      const { value } = e.target;

                      const tempList = [...descriptionList];
                      tempList[idx] = {
                        label: descriptionList[idx].label,
                        description: value,
                      };

                      return setDescriptionList(tempList);
                    }}
                  />
                  <Label
                    color="grey"
                    name="deleteDescription"
                    value={idx}
                    onClick={() => {
                      return setDescriptionList(
                        descriptionList.filter((_, index) => index !== idx),
                      );
                    }}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Icon name="delete" />
                  </Label>
                </S.RowContainer>
              );
            })}
          </S.RowFormField>
          <Button
            fluid
            color="grey"
            content="미션 추가하기"
            onClick={() => {
              setDescriptionList([
                ...descriptionList,
                {
                  label: `STEP ${descriptionList.length + 1}`,
                  description: '',
                },
              ]);
            }}
          />
        </S.Container>
      )}
    </>
  );
};

type OfferWallMissionProps = {
  offerWallInfo: any;
  descriptionList: { label: string; description: string }[];
  setDescriptionList: (
    descriptionList: { label: string; description: string }[],
  ) => void;
};

export default OfferWallMission;
