import React from 'react';
import { Header, Table } from 'semantic-ui-react';
import _ from 'lodash';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';

export const ChallengeResultInfo = ({ result, registerInfo }) => {
  const totalDeposit =
    result.cardDeposit +
    result.prizeDeposit +
    result.cashDeposit +
    result.transferDeposit;
  const totalReturn =
    result.cardReturn +
    result.prizeReturn +
    result.cashReturn +
    result.transferReturn;
  const pgFee = (result.cardDeposit - result.cardReturn) * 0.0286;
  return (
    <React.Fragment>
      <Header as="h3">챌린지 결과</Header>
      {!result && <div>챌린지 결과가 없습니다.</div>}
      {!!result && (
        <React.Fragment>
          <Table basic="very" size="small" celled textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>총참가비</Table.HeaderCell>
                <Table.HeaderCell>환급금</Table.HeaderCell>
                <Table.HeaderCell>미환급금</Table.HeaderCell>
                <Table.HeaderCell>수수료</Table.HeaderCell>
                <Table.HeaderCell>총상금</Table.HeaderCell>
                <Table.HeaderCell>PG fee</Table.HeaderCell>
                <Table.HeaderCell>부가가치세</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{setCurrencyFormat(totalDeposit)}</Table.Cell>
                <Table.Cell>{setCurrencyFormat(totalReturn)}</Table.Cell>
                <Table.Cell>
                  {setCurrencyFormat(totalDeposit - totalReturn)}
                </Table.Cell>
                <Table.Cell>{setCurrencyFormat(result.margin)}</Table.Cell>
                <Table.Cell>
                  {setCurrencyFormat(result.overHundredPrizeReward)}
                </Table.Cell>
                <Table.Cell>{setCurrencyFormat(pgFee)}</Table.Cell>
                <Table.Cell>
                  {setCurrencyFormat((totalDeposit - totalReturn) / 11)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Table basic="very" celled textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>100%</Table.HeaderCell>
                <Table.HeaderCell>85%~99%</Table.HeaderCell>
                <Table.HeaderCell>85%미만</Table.HeaderCell>
                <Table.HeaderCell>인증패스</Table.HeaderCell>
                <Table.HeaderCell>레드</Table.HeaderCell>
                <Table.HeaderCell>평균달성율</Table.HeaderCell>
                <Table.HeaderCell>수상자비율</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {setCurrencyFormat(registerInfo.overHundredCount)}
                </Table.Cell>
                <Table.Cell>
                  {setCurrencyFormat(registerInfo.overEightyFiveCount)}
                </Table.Cell>
                <Table.Cell>
                  {setCurrencyFormat(registerInfo.underEightyFiveCount)}
                </Table.Cell>
                <Table.Cell>
                  {setCurrencyFormat(registerInfo.useItemCount)}
                </Table.Cell>
                <Table.Cell>
                  {setCurrencyFormat(registerInfo.redCardCount)}
                </Table.Cell>
                <Table.Cell>
                  {(100 * registerInfo.avgAchievementRate).toFixed(3) + '%'}
                </Table.Cell>
                <Table.Cell>
                  {(
                    (100 * result.overHundredCount) /
                    result.registerCount
                  ).toFixed(3) + '%'}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ChallengeResultInfo;
