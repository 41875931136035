import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  Table,
  Button,
  TableBody,
  Pagination,
  Dropdown,
} from 'semantic-ui-react';
import moment from 'moment';
import { apis } from '@api/index';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import { UserWithdrawInfo } from '@types';
import WithdrawHeader from './WithdrawHeader';
import DateSelect, { DateInfo } from '../DateSelect/DateSelect';
import WithdrawCashExcel from './WithdrawCashExcel';
import WithdrawPrizeExcel from './WithdrawPrizeExcel';
import { defaultWithdrawAnswer } from '../User.constant';
import WithdrawTableRow from './WithdrawTableRow';
import WithdrawModal from './WithdrawModal';
import SearchWithdraw, { WithdrawSearchType } from './SearchWithdraw';
import DurationPagination from '@component/DurationPagination/DurationPagination';

const Withdraw = () => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchType, setSearchType] =
    useState<WithdrawSearchType>('accountName');

  const [searchText, setSearchText] = useState('');

  const [withdraws, setWithdraws] = useState<UserWithdrawInfo[]>([]);
  const [filteredWithdraws, setFilteredWithdraws] = useState<
    UserWithdrawInfo[]
  >([]);
  const [searchedWithdraws, setSearchedWithdraws] = useState<
    UserWithdrawInfo[]
  >([]);
  const [activeTab, setActiveTab] = useState<'NOT_COMPLETED' | 'COMPLETED'>(
    'NOT_COMPLETED',
  );
  const [dateInfo, setDateInfo] = useState<DateInfo>({
    startDate: moment().add(-7, 'd').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });
  const [checkedAll, setCheckedAll] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [withdraw, setWithdraw] = useState<UserWithdrawInfo>();

  const getWithdraws = useCallback(async () => {
    LoadingIndicator.show();
    const { withdraws: _withdraws } = await apis.common.getWithdrawTransactions(
      {
        gteCreatedAt: moment(dateInfo.startDate)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(-9, 'h')
          .format('YYYY-MM-DD HH:mm:ss'),
        ltCreatedAt: moment(dateInfo.endDate)
          .add(1, 'd')
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(-9, 'h')
          .format('YYYY-MM-DD HH:mm:ss'),
      },
    );
    setWithdraws(_withdraws);
    LoadingIndicator.hide();
  }, [dateInfo.startDate, dateInfo.endDate]);

  useEffect(() => {
    getWithdraws();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isCompleted = activeTab === 'COMPLETED';
    const _filteredWithdraws = withdraws.filter(
      (o) => o.isCompleted === isCompleted,
    );
    setFilteredWithdraws(_filteredWithdraws);
    setSearchedWithdraws(_filteredWithdraws);
    setSearchText('');
  }, [activeTab, withdraws]);

  const handleClickTab = (tab: 'NOT_COMPLETED' | 'COMPLETED') => {
    setActiveTab(tab);
  };

  const handleChangeCheckAll = useCallback(() => {
    if (checkedAll) {
      setCheckedAll(false);
      setWithdraws((prev) => {
        return prev.map((o) => ({ ...o, isChecked: false }));
      });
    } else {
      setWithdraws((prev) => {
        return prev.map((o) => {
          if (o.answer) return { ...o, isChecked: false };
          return { ...o, isChecked: true };
        });
      });
      setCheckedAll(true);
    }
  }, [checkedAll, setWithdraws]);

  const handleChangeCheckbox = useCallback(
    (id: number) => {
      setWithdraws((prev) => {
        return prev.map((o) => {
          if (o.id === id) return { ...o, isChecked: !o.isChecked };
          return o;
        });
      });
    },
    [setWithdraws],
  );

  const showModal = useCallback((_withdraw: UserWithdrawInfo) => {
    setModalOpen(true);
    setWithdraw({
      ..._withdraw,
      answer: _withdraw.answer ?? defaultWithdrawAnswer,
    });
  }, []);

  const closeModal = useCallback(() => setModalOpen(false), []);

  const handleWithdrawChange = useCallback(
    (key: keyof UserWithdrawInfo, value: any) => {
      if (!withdraw) return;
      setWithdraw((prev) => {
        if (!prev) return prev;
        return { ...prev, [key]: value };
      });
    },
    [withdraw, setWithdraw],
  );

  const handleChangeWithdrawIsCompleted = useCallback(
    async (transactionTransferId: number) => {
      await apis.common.updateTransactionTransferIsCompleted(
        transactionTransferId,
      );
      setWithdraws((prev) => {
        return prev.map((o) => {
          if (o.transactionTransferId === transactionTransferId)
            return { ...o, isCompleted: !o.isCompleted };
          return o;
        });
      });
    },
    [setWithdraws],
  );

  const submitWithdrawAnswer = useCallback(async () => {
    if (!withdraw || !withdraw.answer) return;
    await apis.updateTransactionTransfers(withdraw.transactionTransferId, {
      answer: withdraw.answer,
    });
    setWithdraws((prev) => {
      return prev.map((o) => {
        if (o.id === withdraw.id) return { ...o, answer: withdraw.answer };
        return o;
      });
    });
    closeModal();
  }, [withdraw, setWithdraws, closeModal]);

  const handleChangeDate = useCallback((key: keyof DateInfo, value: any) => {
    setDateInfo((prev) => {
      return { ...prev, [key]: value };
    });
  }, []);

  const completeWithdraws = useCallback(async () => {
    LoadingIndicator.show();
    const transactionTransferIds: number[] = [];
    withdraws.forEach((o) => {
      if (o.isChecked) transactionTransferIds.push(o.transactionTransferId);
    });

    await apis.completeTransactionTransfers({ transactionTransferIds });

    setWithdraws((prev) => {
      return prev.map((o) => ({
        ...o,
        isCompleted: o.isChecked === undefined ? o.isCompleted : o.isChecked,
        isChecked: false,
      }));
    });
    setCheckedAll(false);

    LoadingIndicator.hide();
    alert('출금처리가 완료되었습니다.');
  }, [withdraws, setWithdraws, setCheckedAll]);

  const handleChangeSearchType = useCallback(
    (type: WithdrawSearchType) => {
      setSearchType(type);
    },
    [setSearchType],
  );

  const searchUser = useCallback(async () => {
    setSearchedWithdraws(filterKeyword(filteredWithdraws));
  }, [searchType, searchText, filteredWithdraws]);

  const filterKeyword = (wd: UserWithdrawInfo[]) => {
    if (!searchText) return wd;
    return wd.filter((o) => {
      if (searchType === 'userId') {
        return String(o.user.id).includes(searchText);
      }
      if (searchType === 'accountNo') {
        return o.accountNo.includes(searchText);
      }
      if (searchType === 'bankName') {
        return o.bankName.includes(searchText);
      }
      return o.accountName.includes(searchText);
    });
  };

  return (
    <>
      <WithdrawHeader activeTab={activeTab} handleClick={handleClickTab} />
      <DateSelect handleChangeDate={handleChangeDate} fetch={getWithdraws} />
      <Button size="tiny" onClick={completeWithdraws} content="처리완료" />
      <WithdrawCashExcel withdraws={filteredWithdraws} />
      <WithdrawPrizeExcel withdraws={filteredWithdraws} />

      <SearchWithdraw
        searchText={searchText}
        searchUser={searchUser}
        setSearchKeyword={setSearchText}
        handleChangeSearchType={handleChangeSearchType}
      />

      <MemoizationTable
        filteredWithdraws={searchedWithdraws.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize,
        )}
        handleChangeCheckbox={handleChangeCheckbox}
        showModal={showModal}
        handleChangeWithdrawIsCompleted={handleChangeWithdrawIsCompleted}
        checkedAll={checkedAll}
        handleChangeCheckAll={handleChangeCheckAll}
      />

      <DurationPagination
        currentPage={currentPage}
        pageSize={pageSize}
        totalPages={Math.floor(searchedWithdraws.length / pageSize) + 1}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
      />

      <WithdrawModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        withdraw={withdraw}
        handleWithdrawChange={handleWithdrawChange}
        submitWithdrawAnswer={submitWithdrawAnswer}
      />
    </>
  );
};

export default Withdraw;

const MemoizationTable = React.memo(
  ({
    filteredWithdraws,
    handleChangeCheckbox,
    showModal,
    handleChangeWithdrawIsCompleted,
    checkedAll,
    handleChangeCheckAll,
  }: {
    filteredWithdraws: UserWithdrawInfo[];
    handleChangeCheckbox: (id: number) => void;
    showModal: (wd: UserWithdrawInfo) => void;
    handleChangeWithdrawIsCompleted: (transactionTransferId: number) => void;
    checkedAll: boolean;
    handleChangeCheckAll: () => void;
  }) => {
    return (
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Checkbox checked={checkedAll} onChange={handleChangeCheckAll} />
            </Table.HeaderCell>
            <Table.HeaderCell>거래id</Table.HeaderCell>
            <Table.HeaderCell>유저id</Table.HeaderCell>
            <Table.HeaderCell>이름</Table.HeaderCell>
            <Table.HeaderCell>닉네임</Table.HeaderCell>
            <Table.HeaderCell>요청시기</Table.HeaderCell>
            <Table.HeaderCell>포인트종류</Table.HeaderCell>
            <Table.HeaderCell>은행</Table.HeaderCell>
            <Table.HeaderCell>계좌번호</Table.HeaderCell>
            <Table.HeaderCell>금액</Table.HeaderCell>
            <Table.HeaderCell>예금주</Table.HeaderCell>
            <Table.HeaderCell>답변</Table.HeaderCell>
            <Table.HeaderCell>처리여부</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filteredWithdraws.map((wd) => (
            <WithdrawTableRow
              wd={wd}
              handleChangeCheckbox={handleChangeCheckbox}
              showModal={showModal}
              handleChangeWithdrawIsCompleted={handleChangeWithdrawIsCompleted}
            />
          ))}
        </Table.Body>
      </Table>
    );
  },
);
