import { apis } from '@api/index';
import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

type PassCancelModalProps = {
  achievementId: number;
  userId: number;
  achievementRegisterInfo: any;
  passCancelModalOpen: boolean;
  closePassCancelModal: () => void;
};

const PassCancelModal = ({
  achievementId,
  userId,
  achievementRegisterInfo,
  passCancelModalOpen,
  closePassCancelModal,
}: PassCancelModalProps) => {
  const cancelHeart = async () => {
    const res = await apis.deleteTransactionItem(achievementId, {
      userId,
    });
    if ((res as any)?.response?.data?.err) {
      closePassCancelModal();
      return;
    }
    alert('인증패스 사용 취소가 완료되었습니다.');
    closePassCancelModal();
  };
  return (
    <Modal
      size="tiny"
      open={passCancelModalOpen}
      onClose={closePassCancelModal}
    >
      <Modal.Header>인증패스 사용 취소하기</Modal.Header>
      <Modal.Content>
        <p>
          [{achievementRegisterInfo.id}] 인증카드에 사용한 인증패스를
          취소하시겠습니까?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" content="취소하기" onClick={cancelHeart} />
        <Button color="black" content="닫기" onClick={closePassCancelModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default PassCancelModal;
