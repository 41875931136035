import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getQueryKey } from './getQueryKey';
import { apis } from '../../../api';
import { QuerySelectHookOptions } from '../../query.types';
import { Item } from '../../../container/Benefit/PedometerGiftiShop/PedometerGiftiShopBuyers/PedometerGiftiShopBuyers.types';

export const useGiftiShopItemList = (
  options?: QuerySelectHookOptions<typeof apis.getPedometerGiftiShopItemList>,
): UseQueryResult<Item[]> =>
  useQuery({
    queryKey: getQueryKey('GIFTI_SHOP_ITEM_LIST'),
    queryFn: () => apis.getPedometerGiftiShopItemList(),
    select: (data) => {
      if (!data) return [];
      return data.items;
    },
    ...options,
  });
