import DateTimeInput from '@component/DateTimeInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { formatLocalDateTime, formatTimeWithSeconds } from '@utils/date.utils';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './RegisterEndDateForm.serializer';
import {
  useRegisterDateTime,
  useRegisterEndDateTimeActions,
} from './RegisterEndDateForm.store';
import { validate } from './RegisterEndDateForm.validator';
import { useEditCheck } from '../../hooks/useEditCheck';

const RegisterEndDateForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'registerEndDate'>,
  Pick<ChallengeFormType, 'registerEndDate'>,
  Pick<ReqCreateOrUpdateChallenge, 'registerEndDate'>
> = () => {
  const { registerEndDate, registerStartDate } = useRegisterDateTime();
  const { setRegisterEndDateTime } = useRegisterEndDateTimeActions();

  const validated = validate({ registerStartDate, registerEndDate });

  const { triggerEdited, getBorder } = useEditCheck();

  const handleChange = (value: string) => {
    if (!value.length) return;

    triggerEdited('registerEndDate');
    setRegisterEndDateTime(formatTimeWithSeconds(new Date(value)));
  };

  return (
    <DateTimeInput
      label="모집종료일"
      value={registerEndDate || formatLocalDateTime(new Date())}
      errorMsg={validated.isValid ? '' : validated.message}
      onChange={handleChange}
      style={{ border: getBorder('registerEndDate') }}
    />
  );
};

export default RegisterEndDateForm;

RegisterEndDateForm.validate = validate;
RegisterEndDateForm.convertData2Form = convertData2Form;
RegisterEndDateForm.convertForm2Data = convertForm2Data;
