import { ApiType } from '../constant/apiType';
import {
  CompanyWithProduct,
  CompanyWithoutProduct,
} from '../types/commerce.types';
import { PaginationParams, PaginationResponse } from '../types/common.types';
import { _request } from './factory';

type GetCompaniesResponse = {
  companies: CompanyWithoutProduct[];
} & PaginationResponse;

const getCompanies = async (
  params: PaginationParams,
): Promise<GetCompaniesResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/companies`,
    params,
  });
};

type GetAllCompaniesResponse = {
  companies: CompanyWithoutProduct[];
};
const getAllCompanies = (): Promise<GetAllCompaniesResponse> =>
  _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/companies`,
  });

type GetCompaniesProductsResponse = {
  companies: CompanyWithProduct[];
} & PaginationResponse;

const getCompaniesProducts = async (
  params: PaginationParams,
): Promise<GetCompaniesProductsResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/companies/products`,
    params,
  });
};

type GetAllCompaniesProductsResponse = {
  companies: CompanyWithProduct[];
};
const getAllCompaniesProducts = (): Promise<GetAllCompaniesProductsResponse> =>
  _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/companies/products`,
  });

export default {
  getCompanies,
  getAllCompanies,
  getCompaniesProducts,
  getAllCompaniesProducts,
};
