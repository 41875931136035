import { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

const Preview = () => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Accordion style={{ width: '100%', marginBottom: 20 }}>
      <Accordion.Title
        active={!collapsed}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Icon name="dropdown" />
        미리보기
      </Accordion.Title>
      <Accordion.Content active={!collapsed}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <strong>카메라필터 우상단</strong>
          <img
            src="/race/랜선대회-인증방법.jpg"
            width={320}
            alt="랜선대회-인증방법"
          />
        </div>
      </Accordion.Content>
    </Accordion>
  );
};

export default Preview;
