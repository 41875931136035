import React from 'react';
import { Table } from 'semantic-ui-react';
import setCurrencyFormat from '../../../global/setCurrencyFormat';
import moment from 'moment';

const ChallengeUser = ({ totalStatistics }) => {
  let list = [];
  for (const [key, value] of Object.entries(totalStatistics.total.netUser)) {
    if (value.challenges.length > 0) {
      const challengeList = value.challenges.map((c, idx) => (
        <span key={c.title + idx}>
          {c.title +
            ' (' +
            setCurrencyFormat(c.deposit) +
            ') (' +
            moment(c.registerDate)
              .add(9, 'hours')
              .format('YYYY-MM-DD HH:mm:ss') +
            ')'}
          <br />
        </span>
      ));
      list.push(
        <Table.Row key={key}>
          <Table.Cell>
            {Object.keys(totalStatistics.total.netUser).indexOf(key) + 1}
          </Table.Cell>
          <Table.Cell>{key}</Table.Cell>
          <Table.Cell>{value.name}</Table.Cell>
          <Table.Cell>{value.nickname}</Table.Cell>
          <Table.Cell>{setCurrencyFormat(value.deposit)}</Table.Cell>
          <Table.Cell>{setCurrencyFormat(value.cardDeposit)}</Table.Cell>
          <Table.Cell>{setCurrencyFormat(value.transferDeposit)}</Table.Cell>
          <Table.Cell>{setCurrencyFormat(value.cashDeposit)}</Table.Cell>
          <Table.Cell>{setCurrencyFormat(value.prizeDeposit)}</Table.Cell>
          <Table.Cell textAlign="left">{challengeList}</Table.Cell>
        </Table.Row>,
      );
    }
  }
  return (
    <Table basic="very" textAlign="center" size="small" collapsing>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>번호</Table.HeaderCell>
          <Table.HeaderCell>id</Table.HeaderCell>
          <Table.HeaderCell>이름</Table.HeaderCell>
          <Table.HeaderCell>닉네임</Table.HeaderCell>
          <Table.HeaderCell>총금액</Table.HeaderCell>
          <Table.HeaderCell>카드</Table.HeaderCell>
          <Table.HeaderCell>계좌</Table.HeaderCell>
          <Table.HeaderCell>캐시</Table.HeaderCell>
          <Table.HeaderCell>상금</Table.HeaderCell>
          <Table.HeaderCell textAlign="left">챌린지목록</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{list}</Table.Body>
    </Table>
  );
};

export default ChallengeUser;
