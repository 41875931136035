import React, { Component } from 'react';
import { Form, Input } from 'semantic-ui-react';
import moment from 'moment';

class DateSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterStartDatetime: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      filterEndDatetime: moment().format('YYYY-MM-DD'),
    };
  }

  handleDate = (e, { name, value }) => {
    if (name === 'filterStartDatetime')
      this.setState({ filterStartDatetime: value });
    else this.setState({ filterEndDatetime: value });
    this.props.handleDate(name, value);
  };

  render() {
    const { filterStartDatetime, filterEndDatetime } = this.state;
    return (
      <Form>
        <Form.Field>
          <label>시작일</label>
          <Input
            type="date"
            style={{ marginLeft: 10 }}
            name="filterStartDatetime"
            value={filterStartDatetime}
            onChange={this.handleDate}
          />
        </Form.Field>
        <Form.Field>
          <label>종료일</label>
          <Input
            type="date"
            style={{ marginLeft: 10 }}
            name="filterEndDatetime"
            value={filterEndDatetime}
            onChange={this.handleDate}
          />
        </Form.Field>
      </Form>
    );
  }
}

export default DateSelect;
