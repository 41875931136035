import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getQueryKey } from './getQueryKey';
import { apis } from '../../../api';
import { QuerySelectHookOptions } from '../../query.types';
import { Item } from '../../../container/Benefit/PedometerGiftiShop/PedometerGiftiShopBuyers/PedometerGiftiShopBuyers.types';

export const useGiftiShopItem = (
  id: number,
  options?: QuerySelectHookOptions<typeof apis.getPedometerGiftiShopItem>,
): UseQueryResult<Item, unknown> =>
  useQuery({
    queryKey: getQueryKey('GIFTI_SHOP_ITEM', { id }),
    queryFn: () => apis.getPedometerGiftiShopItem(id),
    select: (data) => {
      if (!data) return undefined;
      return data.item;
    },
    ...options,
  });
