import {
  ChallengeInfoForAdmin,
  ServiceCategory,
  ReqCreateOrUpdateChallenge,
} from '@types';
import ServiceCategorySelector from '@component/ServiceCategorySelector';
import _ from 'lodash';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './CategoryForm.serializer';
import { useCategory, useCategoryActions } from './CategoryForm.store';
import { validate } from './CategoryForm.validator';

const CategoryForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'category'>,
  Pick<ChallengeFormType, 'category'>,
  Pick<ReqCreateOrUpdateChallenge, 'category'>
> = () => {
  const { category } = useCategory();
  const { setCategory } = useCategoryActions();

  return (
    <ServiceCategorySelector
      value={category}
      onChange={(value) => setCategory(value as ServiceCategory)}
    />
  );
};

export default CategoryForm;
CategoryForm.validate = validate;
CategoryForm.convertData2Form = convertData2Form;
CategoryForm.convertForm2Data = convertForm2Data;
