import React, { FC, useCallback } from 'react';
import Label from '@component/Label';
import FlexBox from '@component/FlexBox/FlexBox';
import TextInput from '@component/TextInput';
import Button from '@component/Button';
import { ModuleContainer } from '../../../../DescriptionModuleForm.style';

type Props = {
  noticeList: string[];
  onChange: (data: string[]) => void;
};
const NoticeListForm: FC<Props> = (props) => {
  const { noticeList, onChange } = props;

  const handleEditText = useCallback(
    (index: number, text: string) => {
      onChange(noticeList.map((v, i) => (i === index ? text : v)));
    },
    [noticeList, onChange],
  );

  const handleAddItem = () => {
    onChange([...noticeList, '']);
  };

  const handleRemoveItem = (index: number) => {
    onChange(noticeList.filter((_, i) => i !== index));
  };

  return (
    <ModuleContainer>
      <Label style={{ marginTop: 20, marginBottom: 6 }}>
        특별상금 알림리스트
      </Label>
      <FlexBox.Column gap={6}>
        {noticeList.map((item, index) => (
          <FlexBox.Row key={`${index}`} gap={6}>
            <TextInput
              containerStyle={{ flex: 1 }}
              value={item}
              onChange={(text) => handleEditText(index, text)}
            />
            <Button
              text="삭제"
              style={{ width: 72 }}
              onClick={() => handleRemoveItem(index)}
            />
          </FlexBox.Row>
        ))}
      </FlexBox.Column>

      <Button text="추가" onClick={handleAddItem} style={{ marginTop: 6 }} />
    </ModuleContainer>
  );
};

export default NoticeListForm;
