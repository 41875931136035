// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import AWS from 'aws-sdk';
import _ from 'lodash';
import { awsConfig } from '../config';

AWS.config.update({
  region: awsConfig.bucketRegion,
  credentials: new AWS.Credentials({
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
  }),
  dynamoDbCrc32: false,
});

const s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: awsConfig.bucketName },
});

const uploadSingleFileToS3 = async (file) => {
  const splits = file.name.split('.');
  const fileName = `${new Date().getTime()}.${splits[splits.length - 1]}`;
  const { bucketKey } = awsConfig;
  const key = bucketKey + fileName;
  const data = await s3
    .upload(
      { Key: key, Body: file, ACL: 'public-read' },
      { partSize: 10 * 1024 * 1024, queueSize: 1 },
    )
    .promise();
  return awsConfig.url + data.key;
};

const handleChangeSingleFile = async (e, object) => {
  const { name, files } = e.target;
  const file = files[0];
  const splits = file.name.split('.');
  const ext = splits[splits.length - 1];
  const fileName = `${new Date().getTime()}.${ext}`;
  const { bucketKey } = awsConfig;
  const key = bucketKey + fileName;
  const data = await s3
    .upload(
      { Key: key, Body: file, ACL: 'public-read' },
      { partSize: 10 * 1024 * 1024, queueSize: 1 },
    )
    .promise();
  // eslint-disable-next-line no-param-reassign
  object[name] = `${ext === 'gif' ? awsConfig.fileUrl : awsConfig.url}${
    data.key
  }`;
  return object as { [key: string]: string };
};

const handleChangeMultiFiles = async (e, object) => {
  const { name, files } = e.target;
  const promises = _.map(files, (file, idx) => {
    const splits = file.name.split('.');
    const fileName = `${new Date().getTime()}-${idx}.${
      splits[splits.length - 1]
    }`;
    const { bucketKey } = awsConfig;
    const key = bucketKey + fileName;
    return s3.upload({ Key: key, Body: file, ACL: 'public-read' }).promise();
  });
  // eslint-disable-next-line no-param-reassign
  object[name] = _.map(
    await Promise.all(promises),
    (item) => awsConfig.url + item.key,
  );
  return object;
};

const handleChangeMultiFilesWithExtension = async (e, object, folder) => {
  const { name, files } = e.target;
  const promises = _.map(files, (file) => {
    const { bucketKey } = awsConfig;
    const key = bucketKey + folder + file.name;
    return s3
      .upload({
        Key: key,
        Body: file,
        ACL: 'public-read',
      })
      .promise();
  });
  // eslint-disable-next-line no-param-reassign
  object[name] = _.map(await Promise.all(promises), (item) => item.Location);
  return object;
};

const cropImage = async (image, imageWidth, imageHeight, cropSize) => {
  const files = [];
  let cropY = 0;
  let cropHeight = cropSize;
  const cropX = 0;
  const cropWidth = cropSize;
  const count = Math.ceil(imageHeight / imageWidth);
  for (let i = 0; i < count; i++) {
    const canvas = document.createElement('canvas');
    canvas.width = cropWidth;
    cropHeight = Math.min(cropHeight, imageHeight - cropY);
    canvas.height = cropHeight;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight,
    );
    // eslint-disable-next-line no-await-in-loop
    const url = await new Promise((resolve) => {
      resolve(canvas.toDataURL('image/jpeg'));
    });
    const file = dataURItoBlob(url);
    file.name = `${i}.jpg`;
    files.push(file);
    cropY += cropHeight;
  }
  return files;
};

const dataURItoBlob = (dataURI) => {
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
};

const cropAndUploadFile = async (name, file) => {
  const urls = [];
  // 파일 업로드
  const image = document.createElement('img');
  image.src = URL.createObjectURL(file);
  await new Promise((resolve) => {
    image.onload = async () => {
      const { width, height } = image;
      const files = await cropImage(image, width, height, width);
      const temp = await handleChangeMultiFiles(
        { target: { files, name } },
        {},
      );
      temp[name].forEach((url) => urls.push(url));
      resolve();
    };
  });
  return urls;
};

export {
  handleChangeSingleFile,
  handleChangeMultiFiles,
  cropImage,
  cropAndUploadFile,
  handleChangeMultiFilesWithExtension,
  uploadSingleFileToS3,
};
