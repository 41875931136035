import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Label = styled.label`
  font-weight: bold;
`;

export const InputContainer = styled.div`
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Input = styled.input`
  outline: none;
`;

export const PreviewContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const PreviewImg = styled.img<{ size: number }>`
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `}

  border: 1px solid #999;
  border-radius: 4px;
`;
