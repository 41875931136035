import ErrorMsg from '@component/ErrorMsg';
import FlexBox from '@component/FlexBox/FlexBox';
import ImageInput from '@component/ImageInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './ThumbnailImageUrlForm.serializer';
import {
  useThumbnailImageUrl,
  useUpdateThumbnailImageUrl,
} from './ThumbnailImageUrlForm.store';
import { validate } from './ThumbnailImageUrlForm.validator';

export const ThumbnailImageUrlForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'thumbnailImageUrls'>,
  Pick<ChallengeFormType, 'thumbnailImageUrl'>,
  Pick<ReqCreateOrUpdateChallenge, 'thumbnailImageUrls'>
> = () => {
  const thumbnailImageUrl = useThumbnailImageUrl();
  const { update } = useUpdateThumbnailImageUrl();

  const validated = validate({ thumbnailImageUrl });

  return (
    <FlexBox.Column>
      <ImageInput
        uploadOnChange
        showPreview
        previewSize={120}
        label="챌린지 썸네일"
        value={thumbnailImageUrl || undefined}
        onChange={(url) => update(url || null)}
      />
      <ErrorMsg text={validated.isValid ? '' : validated.message} />
    </FlexBox.Column>
  );
};

ThumbnailImageUrlForm.validate = validate;
ThumbnailImageUrlForm.convertData2Form = convertData2Form;
ThumbnailImageUrlForm.convertForm2Data = convertForm2Data;
