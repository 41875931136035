import React, { useState } from 'react';
import {
  Table,
  Header,
  Grid,
  Button,
  Form,
  Input,
  Accordion,
  Icon,
  Modal,
  Dropdown,
} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment-timezone';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';
import {
  publicTypes,
  publicTypeOptions,
} from '../../../../constant/publicType';
import { apis } from '../../../../api';
import { colors } from '../../../../constant/colors';
import {
  getDocs,
  collection,
  query,
  where,
  serverTimestamp,
  setDoc,
  doc,
  addDoc,
} from 'firebase/firestore/lite';
import {
  checkIsUrlCard,
  getInstagramUrlAchievementIndexList,
  getNaverBlogUrlAchievementIndexList,
} from './ChallengeInfo.utils';
import AchievementCaptureCard from './AchievementCaptureCard';
import AchievementUrlCard from './AchievementUrlCard';
import AchievementBeforeCard from './AchievementBeforeCard';
import { firestore } from 'src/firebase';

export const ChallengeInfo = ({
  user,
  title,
  showCancelModal,
  showReviewModal,
  showDeleteModal,
  showCommentModal,
  showUploadModal,
  showUploadUrlModal,
  getCompletedChallenges,
  showAdminCommentModal,
  showReportModal,
  handleDate,
  filterStartDatetime,
  filterEndDatetime,
  showHeartModal,
  showHeartCancelModal,
  showAllAchievementFilesModal,
  showAllAchievementUrlsModal,
  handleCheckbox,
  showDeleteAndPassCheckedModal,
  challengeAccordion,
  handleChallengeAccordion,
  challengeAccordionAllOpen,
  changeChallengeAccordionAllOpen,
  setDragItem,
  setDropItem,
}) => {
  const [publicTypeModalOpen, setPublicTypeModalOpen] = useState(false);
  const [publicType, setPublicType] = useState('');
  const [relationUserChallengeId, setRelationUserChallengeId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const showPublicTypeModal = (ruc) => {
    setRelationUserChallengeId(ruc.id);
    setPublicTypeModalOpen(true);
    setPublicType(ruc.publicType);
  };
  const closePublicTypeModal = () => setPublicTypeModalOpen(false);
  const updatePublicType = async () => {
    await apis.updateRucPublicType(relationUserChallengeId, { publicType });
    user.relationUserChallenges.forEach((ruc) => {
      if (ruc.id === relationUserChallengeId) {
        ruc.publicType = publicType;
      }
    });
    closePublicTypeModal();
  };
  const handleRadioChange = (e, { value }) => {
    setPublicType(value);
  };

  const addChattingUser = async (challenge) => {
    if (isLoading) return;
    setIsLoading(true);
    if (confirm('채팅방에 유저를 추가하시겠습니까?')) {
      const challengeId = Number(challenge.id);
      const chatQ = query(
        collection(firestore, 'chatting'),
        where('challengeId', '==', challengeId),
      );
      const chatSnapshot = await getDocs(chatQ);

      let chatId;
      if (chatSnapshot.docs.length > 0) {
        chatId = chatSnapshot.docs[0].id;
      } else {
        const chatDoc = await addDoc(collection(firestore, 'chatting'), {
          challengeId,
          memberCount: 0,
          createdAt: serverTimestamp(),
          lastChatAt: serverTimestamp(),
          ownerId: challenge.userId,
          noticeId: null,
          type: 'group',
        });
        chatId = chatDoc.id;
      }

      // 유저 존재 여부 확인
      const chatUserQ = query(
        collection(firestore, 'chatting_users'),
        where('challengeId', '==', challengeId),
        where('userId', '==', user.id),
      );
      const chatUserSnapshot = await getDocs(chatUserQ);
      if (chatUserSnapshot.docs.length === 0) {
        const data = {
          chattingId: chatId,
          challengeId,
          chattingType: 'group',
          userId: user.id,
          useNotification: true,
          lastChatAt: serverTimestamp(),
          readAt: serverTimestamp(),
        };

        setDoc(doc(firestore, 'chatting_users', `${chatId}${user.id}`), data);
      }

      alert('채팅 추가가 완료되었어요.');
      setIsLoading(false);
    }
  };
  const relationUserChallengeList = user.relationUserChallenges.map(
    (ruc, idx) => {
      const { customFields } = ruc.challenge;
      const instagramUrlAchievementIndexList =
        getInstagramUrlAchievementIndexList(customFields);
      const naverBlogUrlAchievementIndexList =
        getNaverBlogUrlAchievementIndexList(customFields);
      let achievements = _.filter(user.achievements, (achievement) => {
        return achievement.relationUserChallengeId === ruc.id;
      });
      achievements = _.sortBy(achievements, ['dueStartDate', 'nthTerm']);
      const achievementList = achievements.map((achievement, idx) => {
        const isInstagramRelated = checkIsUrlCard(
          idx,
          instagramUrlAchievementIndexList,
        );
        const isNaverBlogRelated = checkIsUrlCard(
          idx,
          naverBlogUrlAchievementIndexList,
        );
        const isUrlCard = isInstagramRelated || isNaverBlogRelated;
        const isItemUsed = !!achievement.transactionItem;

        if (
          'url' in achievement.achievementFile &&
          achievement.achievementFile.url !== ''
        ) {
          return (
            <Grid.Column key={idx}>
              {isUrlCard ? (
                <AchievementUrlCard
                  achievement={achievement}
                  setDropItem={setDropItem}
                  setDragItem={setDragItem}
                  onCheckboxPress={handleCheckbox}
                  onAdminCommentPress={showAdminCommentModal}
                  onReportPress={showReportModal}
                  onReviewPress={showReviewModal}
                  onDeletePress={showDeleteModal}
                  onCommentPress={showCommentModal}
                  onAllAchievementUrlsPress={showAllAchievementUrlsModal}
                  onHeartPress={showHeartModal}
                  onHeartCancelPress={showHeartCancelModal}
                />
              ) : (
                <AchievementCaptureCard
                  achievement={achievement}
                  setDropItem={setDropItem}
                  setDragItem={setDragItem}
                  onCheckboxPress={handleCheckbox}
                  onAdminCommentPress={showAdminCommentModal}
                  onReportPress={showReportModal}
                  onReviewPress={showReviewModal}
                  onDeletePress={showDeleteModal}
                  onCommentPress={showCommentModal}
                  onAllAchievementFilesPress={showAllAchievementFilesModal}
                  onHeartPress={showHeartModal}
                  onHeartCancelPress={showHeartCancelModal}
                />
              )}
            </Grid.Column>
          );
        } else {
          return (
            <Grid.Column key={idx}>
              <AchievementBeforeCard
                achievement={achievement}
                achievementIndex={idx + 1}
                snsType={
                  isInstagramRelated
                    ? 'instagram'
                    : isNaverBlogRelated
                      ? 'naverBlog'
                      : undefined
                }
                isItemUsed={isItemUsed}
                setDropItem={setDropItem}
                setDragItem={setDragItem}
                onUploadPress={isUrlCard ? showUploadUrlModal : showUploadModal}
                onAllAchievementPress={
                  isUrlCard
                    ? showAllAchievementUrlsModal
                    : showAllAchievementFilesModal
                }
                onHeartPress={showHeartModal}
                onHeartCancelPress={showHeartCancelModal}
              />
            </Grid.Column>
          );
        }
      });
      return (
        <Table.Row key={idx}>
          <Table.Cell textAlign="center" collapsing>
            {ruc.id}
            <br />
            (신청시간(한국시간기준)
            <br />
            {moment(ruc.createdAt).format('YYYY-MM-DD HH:mm:ss')})
            <br />
            {moment(ruc.challenge.resultDate).isBefore(moment()) && (
              <div style={{ color: colors.RED_PRIMARY_1_400 }}>결과발표됨</div>
            )}
            <Button size="tiny" value={ruc.id} onClick={showCancelModal}>
              신청취소
            </Button>
            <Button
              size="tiny"
              value={ruc.challenge}
              onClick={() => addChattingUser(ruc.challenge)}
            >
              채팅 추가
            </Button>
          </Table.Cell>
          <Table.Cell>
            [{ruc.challenge.userId === 1 ? '공식' : '개설'}] [{ruc.challenge.id}
            ] [
            <a
              href={`/challenges/${ruc.challenge.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              상세
            </a>
            {' / '}
            <a
              href={`/challenges/${ruc.challenge.id}/edit`}
              target="_blank"
              rel="noopener noreferrer"
            >
              편집
            </a>
            ]<h5 style={{ marginTop: 0 }}>{ruc.challenge.title}</h5>
            <Button
              size="mini"
              value={ruc.id}
              onClick={showDeleteAndPassCheckedModal}
            >
              선택 일괄삭제 및 인증처리
            </Button>
            <br />
            <p>
              현재까지 달성수: {ruc.achievementInfo.ok} <br />
              현재까지 총 수: {ruc.achievementInfo.present}
              <br />
              전체 수: {ruc.achievementInfo.total}
            </p>
          </Table.Cell>
          <Table.Cell>
            {publicTypes[ruc.publicType]}
            <Button
              basic
              size="mini"
              content="변경"
              onClick={() => showPublicTypeModal(ruc)}
            />
          </Table.Cell>
          <Table.Cell textAlign="center" collapsing>
            카드 {setCurrencyFormat(ruc.cardDeposit)}
            <br />
            계좌 {setCurrencyFormat(ruc.transferDeposit)}
            <br />
            캐시 {setCurrencyFormat(ruc.cashDeposit)}
            <br />
            상금 {setCurrencyFormat(ruc.prizeDeposit)}
            <br />
          </Table.Cell>
          <Table.Cell textAlign="center" collapsing>
            {moment(ruc.challenge.startDate).format('YYYY-MM-DD')}
          </Table.Cell>
          <Table.Cell textAlign="center" collapsing>
            {moment(ruc.challenge.endDate).format('YYYY-MM-DD')}
          </Table.Cell>
          <Table.Cell width={7}>
            <Accordion>
              <Accordion.Title
                active={challengeAccordion[ruc.challenge.id]}
                index={ruc.challenge.id}
                onClick={handleChallengeAccordion}
              >
                <Icon name="dropdown" />
                인증카드 목록보기
              </Accordion.Title>
              <Accordion.Content active={challengeAccordion[ruc.challenge.id]}>
                <Grid columns={3}>{achievementList}</Grid>
              </Accordion.Content>
            </Accordion>
          </Table.Cell>
        </Table.Row>
      );
    },
  );
  return (
    <React.Fragment>
      <Header as="h5">{title}</Header>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ruc_id</Table.HeaderCell>
            <Table.HeaderCell>챌린지명</Table.HeaderCell>
            <Table.HeaderCell>공개설정</Table.HeaderCell>
            <Table.HeaderCell>참가금액</Table.HeaderCell>
            <Table.HeaderCell>시작일</Table.HeaderCell>
            <Table.HeaderCell>종료일</Table.HeaderCell>
            <Table.HeaderCell>
              인증샷
              <Button
                style={{ marginLeft: 10 }}
                size="tiny"
                onClick={changeChallengeAccordionAllOpen}
              >
                {challengeAccordionAllOpen ? '전체 접기' : '전체 펼치기'}
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {relationUserChallengeList.length !== 0 && relationUserChallengeList}
          {relationUserChallengeList.length === 0 && (
            <Table.Row>
              <Table.Cell>챌린지 참가내역이 없습니다.</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {title === '현재 진행중인 챌린지 참가이력' && (
        <Form>
          <Form.Group inline>
            <Form.Field>
              <label>시작일</label>
              <Input
                type="date"
                style={{ marginLeft: 10 }}
                name="filterStartDatetime"
                defaultValue={filterStartDatetime}
                onChange={handleDate}
              />
            </Form.Field>
            <Form.Field>
              <label>종료일</label>
              <Input
                type="date"
                style={{ marginLeft: 10 }}
                name="filterEndDatetime"
                defaultValue={filterEndDatetime}
                onChange={handleDate}
              />
            </Form.Field>
            <Button onClick={getCompletedChallenges}>
              지난 챌린지 참여 내역 불러오기
            </Button>
          </Form.Group>
        </Form>
      )}

      <Modal
        size="tiny"
        open={publicTypeModalOpen}
        onClose={closePublicTypeModal}
      >
        <Modal.Header>공개설정 변경</Modal.Header>
        <Modal.Content>
          <Dropdown
            placeholder="인증샷 공개옵션을 선택하세요"
            name="review"
            selection
            options={publicTypeOptions}
            value={publicType}
            onChange={handleRadioChange}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="업데이트" onClick={updatePublicType} />
          <Button color="black" content="닫기" onClick={closePublicTypeModal} />
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

export default ChallengeInfo;
