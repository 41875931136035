import React from 'react';
import { Table, Header, Image } from 'semantic-ui-react';
import moment from 'moment-timezone';

export const AbusingInfo = ({ user }) => {
  const abusingAchievementFiles = user.abusingAchievementFiles.map(
    (achievementFile, idx) => {
      const updatedAt = moment(achievementFile.updatedAt).tz(user.timezone);
      return (
        <Table.Row key={idx}>
          <Table.Cell>{achievementFile.id}</Table.Cell>
          <Table.Cell>{achievementFile.achievementId}</Table.Cell>
          <Table.Cell>{achievementFile.challenge.id}</Table.Cell>
          <Table.Cell>{achievementFile.challenge.title}</Table.Cell>
          <Table.Cell>
            {achievementFile.challenge.authenticationMethod}
          </Table.Cell>
          <Table.Cell>
            <a
              href={achievementFile.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image size={'tiny'} src={achievementFile.url} />
            </a>
          </Table.Cell>
          <Table.Cell>
            {achievementFile.isDeleted ? '삭제' : '미삭제'}
          </Table.Cell>
          <Table.Cell>{achievementFile.reviewResult}</Table.Cell>
          <Table.Cell>{achievementFile.reviewComment}</Table.Cell>
          <Table.Cell>
            {achievementFile.comments
              ? achievementFile.comments.map((c) => c.comment).join('\n')
              : ''}
          </Table.Cell>
          <Table.Cell>
            {updatedAt.add(9, 'h').format('YYYY-MM-DD HH:mm:ss')}
          </Table.Cell>
        </Table.Row>
      );
    },
  );

  const commentAchievementFiles = user.commentAchievementFiles.map(
    (achievementFile, idx) => {
      const updatedAt = moment(achievementFile.comments[0].updatedAt).tz(
        user.timezone,
      );
      return (
        <Table.Row key={idx}>
          <Table.Cell>{achievementFile.id}</Table.Cell>
          <Table.Cell>{achievementFile.achievementId}</Table.Cell>
          <Table.Cell>{achievementFile.challenge.id}</Table.Cell>
          <Table.Cell>{achievementFile.challenge.title}</Table.Cell>
          <Table.Cell>
            {achievementFile.challenge.authenticationMethod}
          </Table.Cell>
          <Table.Cell>
            <a
              href={achievementFile.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image size={'small'} src={achievementFile.url} />
            </a>
          </Table.Cell>
          <Table.Cell>{achievementFile.comments[0].comment}</Table.Cell>
          <Table.Cell>
            {updatedAt
              .add(updatedAt.utcOffset(), 'minutes')
              .format('YYYY-MM-DD HH:mm:ss')}
          </Table.Cell>
        </Table.Row>
      );
    },
  );

  return (
    <React.Fragment>
      <Header as="h5">옐로/미인증/레드카드 내역</Header>
      <Table basic="very">
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell>인증샷id</Table.HeaderCell>
            <Table.HeaderCell>인증카드id</Table.HeaderCell>
            <Table.HeaderCell>챌린지id</Table.HeaderCell>
            <Table.HeaderCell>챌린지명</Table.HeaderCell>
            <Table.HeaderCell>챌린지인증방법</Table.HeaderCell>
            <Table.HeaderCell>인증샷</Table.HeaderCell>
            <Table.HeaderCell>삭제</Table.HeaderCell>
            <Table.HeaderCell>리뷰결과</Table.HeaderCell>
            <Table.HeaderCell>리뷰코멘트</Table.HeaderCell>
            <Table.HeaderCell>어드민댓글</Table.HeaderCell>
            <Table.HeaderCell>리뷰시간</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {abusingAchievementFiles.length !== 0 && abusingAchievementFiles}
          {abusingAchievementFiles.length === 0 && (
            <Table.Row>
              <Table.Cell collapsing>카드내역이 없습니다.</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      <Header as="h5">코멘트 내역</Header>
      <Table basic="very">
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell>인증샷id</Table.HeaderCell>
            <Table.HeaderCell>인증카드id</Table.HeaderCell>
            <Table.HeaderCell>챌린지id</Table.HeaderCell>
            <Table.HeaderCell>챌린지명</Table.HeaderCell>
            <Table.HeaderCell>챌린지인증방법</Table.HeaderCell>
            <Table.HeaderCell>인증샷</Table.HeaderCell>
            <Table.HeaderCell>댓글내용</Table.HeaderCell>
            <Table.HeaderCell>댓글시간</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {commentAchievementFiles.length !== 0 && commentAchievementFiles}
          {commentAchievementFiles.length === 0 && (
            <Table.Row>
              <Table.Cell collapsing>댓글 내역이 없습니다..</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default AbusingInfo;
