import FlexBox from '@component/FlexBox/FlexBox';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

export const CouponUI = styled(FlexBox.Row)<{ bgColor: string; color: string }>`
  width: 335px;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  background-color: ${({ bgColor }) => bgColor ?? '#ffeded'};
  color: ${({ color }) => color ?? '#ff4343'};
  border-radius: 12px;
`;

export const RowContainer = styled.div<{
  gap?: string;
}>`
  display: flex;
  gap: ${(props) => props.gap && props.gap};
`;

export const RowFormField = styled(Form.Field)<{
  margin?: string;
}>`
  flex: 1;
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const CouponWrapper = styled(FlexBox.Column)<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor ?? '#FF4343'};
  width: 375px;
  padding: 20px;
`;
