import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import { ChallengeCustomField } from '@types';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  useUpdateUseItemImpossible,
  useUseItemImpossible,
} from './UseItemImpossibleForm.store';
import {
  convertData2Form,
  convertForm2Data,
} from './UseItemImpossibleForm.serializer';
import { validate } from './UseItemImpossibleForm.validator';

export const UseItemImpossibleForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'useItemImpossible'>,
  Extract<ChallengeCustomField, { name: 'useItemImpossible' }>
> = () => {
  const useItemImpossible = useUseItemImpossible();
  const { update } = useUpdateUseItemImpossible();

  const selectedOption =
    OPTIONS.find((o) => o.value === useItemImpossible) || OPTIONS[0];

  return (
    <FlexBox.Column gap={12}>
      <FlexBox.Column>
        <Label>인증패스 사용 불가 처리</Label>
        <Label style={{ color: '#999' }}>
          사용불가 처리시 인증패스 관련 문구 노출 안됨
        </Label>
      </FlexBox.Column>

      <RadioSelector
        selectedOption={selectedOption}
        options={OPTIONS}
        onChange={(option) => update(option.value)}
        style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
      />
    </FlexBox.Column>
  );
};

const OPTIONS = [
  { label: '사용불가', value: true },
  { label: '사용가능', value: false },
];

UseItemImpossibleForm.validate = validate;
UseItemImpossibleForm.convertData2Form = convertData2Form;
UseItemImpossibleForm.convertForm2Data = convertForm2Data;
