import _ from 'lodash';
import moment from 'moment';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Header,
  Image,
  Input,
  Modal,
  Radio,
  TextArea,
} from 'semantic-ui-react';
import { UserFilterSelector } from '../../../../component/UserFilterSelector';
import {
  etcScreensForLandingDetail,
  landingTypeOptions,
  myOrderDeliveryScreenOptions,
  benefitActionTypeOptions,
} from '../../../../constant/bannerOptions';
import { bannerTypeObj, bannerTypes } from '../../../../constant/bannerTypes';
import { colors } from '../../../../constant/colors';
import { useGetChallengeAppCategories } from '../../../../queries/Challenge';
import { getBannerTextLineCount } from '../../../../utils/banner';
import { handleChangeSingleFile } from '../../../../utils/uploadImage';
import { getDisplayTypeOptions } from './BannerModal.utils';
import * as S from './Style';
import ChallengeCategoryForm from './components/ChallengeCategoryForm';
import PopUpBannerPreview from './components/PopupBannerPreview/PopUpBannerPreview';
import PreviewImage from './components/PreviewImage/PreviewImage';
import { Banner } from '@types';
import {
  ChallengeOption,
  EventOption,
  NoticeOption,
  OfferWallOption,
  RaceOption,
} from 'src/types/marketing.types';
import { ColorSample } from '@component/ColorSample';
import { useGetPurchaseChallengeList } from '@queries/common/useGetPurchaseChallengeList';

const BannerModal = ({
  modalOpen,
  closeModal,
  addOrEditBanner,
  initBanner,
  challengeOptions,
  noticeOptions,
  contentOptions,
  eventOptions,
  raceOptions,
  offerWallOptions,
  additionalBannerTypes = [],
  setAdditionalBannerTypes,
}: {
  modalOpen: boolean;
  closeModal: () => void;
  addOrEditBanner: (banner: any) => void;
  initBanner: Partial<Banner> | undefined;
  challengeOptions: ChallengeOption[];
  noticeOptions: {
    key: number;
    text: string;
    value: number;
  }[];
  contentOptions: any[];
  eventOptions: EventOption[];
  raceOptions: RaceOption[];
  offerWallOptions: OfferWallOption[];
  additionalBannerTypes?: any[];
  setAdditionalBannerTypes: (value: any) => void;
}) => {
  const [banner, setBanner] = useState<Partial<Banner> | null>(null);

  const { data } = useGetChallengeAppCategories();
  const now = useMemo(() => Date.now(), []);
  const { data: purchaseChallengeData } = useGetPurchaseChallengeList({
    offset: 0,
    limit: 1000,
    startDate: now,
    enabled: banner?.landingType === 'PURCHASE_CHALLENGE',
  });
  const purchaseChallengeList = useMemo(() => {
    return purchaseChallengeData?.pages.flatMap((page) => page.challenges);
  }, [purchaseChallengeData]);

  useEffect(() => {
    let _banner: any;
    if (initBanner?.id && initBanner.info) {
      if (typeof initBanner.info.hideHeader !== 'string') {
        initBanner.info.hideHeader = !!initBanner.info.hideHeader;
      }
      if (
        initBanner.info.reservationStartDatetime &&
        initBanner.info.reservationEndDatetime
      ) {
        initBanner.info.isReserved = true;
      }
      _banner = initBanner;
    } else {
      _banner = initializeBanner();
    }

    setBanner(_banner);
  }, [initBanner]);

  const initializeBanner = useCallback(() => {
    return {
      title: '',
      subtitle: '',
      color: '#FFFFFF',
      imageUrl: '',
      bannerType: '',

      displayType: 'ALL',
      landingType: '',
      landingDetail: '',
      isDisplayed: 'false',
      startDatetime: '',
      endDatetime: '',
      info: {
        rewardType: null,
        rewardTypeTextColor: null,
        rewardAmount: null,
        rewardAmountTextColor: null,
        categoryType: null,
        pedometerBanner: false,
        buttonType: '',
        buttonTypeId: '',
        buttonText: '',
        buttonTypeColorKey: '',
        buttonColor: '#FFFFFF',
        buttonTextColor: '#FFFFFF',
        buttonBackgroundColor: '#000000',
        buttonBgColor: '#000000',
        specialBadgeText: '',
        specialBadgeTextColor: '#000000',
        specialBadgeBackgroundColor: '#FFFFFF',
        collaborationBadgeText: '',
        collaborationBadgeTextColor: '#000000',
        closedImageUrl: '',
        minVersionSupport: '',
        params: {},
        isReserved: false,
        reservationStartDatetime: moment()
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0),
        reservationEndDatetime: moment()
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0),
        benefitActionType: benefitActionTypeOptions[0].value,
        benefitPointAmount: 1,
      },
      additionalUserIds: [],
      userFilter: {
        id: undefined,
        name: undefined,
      },
    };
  }, []);

  const onChangeRadio = (e: any, { name, value }: any) => {
    if (!banner) return;

    const _banner = _.cloneDeep(banner);
    if (name.includes('button')) {
      (_banner.info as any)[name] = value;
      if (name === 'buttonType' && value === 'MAGAZINE' && _banner.info) {
        _banner.info.buttonTypeId = 'MAGAZINE';
      }
    } else if (name.includes('Datetime')) {
      (_banner as any)[name] = moment(value).format('YYYY-MM-DDTHH:mm');
    } else if (name === 'additionalUserIds') {
      (_banner as any)[name] = value.map((o: any) => Number(o));
    } else if (
      name.includes('bannerType') &&
      value !== bannerTypeObj.challengeCategory.value
    ) {
      (_banner as any)[name] = value;
      (_banner.info as any).buttonTextColor = '#FFFFFF';
      (_banner.info as any).buttonBackgroundColor = '#000000';
      (_banner.info as any).categoryType = null;
    } else if (name.includes('categoryType')) {
      (_banner.info as any).categoryType = value;
      (_banner.info as any).buttonTextColor = '#000000';
      (_banner.info as any).buttonBackgroundColor = '#FFFFFF';
    } else {
      (_banner as any)[name] = value;
      if (name === 'landingDetail') {
        (_banner.info as any).buttonTypeId = value;
      }
      if (name === 'landingDetail' && _banner.landingType === 'CHALLENGE') {
        const userFilter = challengeOptions.find(
          (o) => o.key === _banner.landingDetail,
        )?.userFilter;
        if (userFilter) {
          _banner.userFilter = {
            id: userFilter.id,
            name: userFilter.name,
          };
          _banner.userFilterId = userFilter.id;
        } else {
          _banner.userFilter = {
            id: undefined,
            name: undefined,
          };
          _banner.userFilterId = undefined;
        }
      }
      if (name === 'landingType') {
        _banner.landingDetail = undefined;
        _banner.userFilter = undefined;
        _banner.userFilterId = undefined;
        (_banner.info as any).buttonType = value;
        (_banner.info as any).buttonTypeId = undefined;
      }
    }
    setBanner(_banner);
  };

  const onChangeText = (e: any) => {
    if (!banner) return;
    const _banner = _.cloneDeep(banner);
    const { name, value: _value } = e.target;
    const value = _value.replace(/[\u2028]/g, '\n'); // NOTE: 피그마에서 복붙 시 unicode 2028 문자가 포함되는 경우에, 그 문자를 개행 문자로 변경
    const textLineCount = getBannerTextLineCount(_banner);
    const newLineCount = (value.match(/\n/g) || []).length;
    if (name === 'title') {
      const limit =
        _banner.bannerType === bannerTypeObj.challengeCategory.value ? 3 : 2;
      if (textLineCount.title >= limit && newLineCount >= limit) {
        return alert(
          `최대 줄 수를 초과했어요. 제목은 최대 ${limit}줄까지만 가능합니다.`,
        );
      }
      if (
        textLineCount.subtitle >= 2 &&
        textLineCount.title >= 1 &&
        newLineCount >= 1
      ) {
        return alert(
          '최대 줄 수를 초과했어요. 부제가 2줄 이상인 경우 제목은 최대 1줄까지만 가능합니다.',
        );
      }
    } else if (name === 'subtitle') {
      if (textLineCount.subtitle >= 2 && newLineCount >= 2) {
        return alert(
          '최대 줄 수를 초과했어요. 부제는 최대 2줄까지만 가능합니다.',
        );
      }
      // 상단 배지 영역 있을 때
      if (textLineCount.topBadge >= 1) {
        if (textLineCount.title >= 2) {
          return alert(
            '최대 줄 수를 초과했어요. 제목 상단 배지 영역 & 제목 2줄 이상일 경우 부제를 입력할 수 없어요.',
          );
        } else if (
          textLineCount.title === 1 &&
          textLineCount.subtitle === 1 &&
          newLineCount >= 1
        ) {
          return alert(
            '최대 줄 수를 초과했어요. 제목 상단 배지 영역 & 제목 1줄일 경우 부제는 1줄만 입력 가능해요.',
          );
        }
      } else {
        if (
          textLineCount.title >= 2 &&
          textLineCount.subtitle === 1 &&
          newLineCount >= 1
        ) {
          return alert(
            '최대 줄 수를 초과했어요. 제목이 2줄 이상일 경우 부제는 1줄만 입력 가능해요.',
          );
        } else if (
          textLineCount.title === 1 &&
          textLineCount.subtitle === 2 &&
          newLineCount >= 2
        ) {
          return alert(
            '최대 줄 수를 초과했어요. 제목이 1줄일 경우 부제는 2줄까지만 입력 가능해요.',
          );
        }
      }
    }
    (_banner as any)[name] = value;
    e.target.value = value;

    delaySetBanner(_banner);
  };

  const onChangeInfo = (key: string, value: any) => {
    if (!banner) return;
    if (typeof value === 'string') {
      value = value.trim();
    }
    const _banner = _.cloneDeep(banner);
    if (['specialBadgeText', 'collaborationBadgeText'].includes(key)) {
      const textLineCount = getBannerTextLineCount(_banner);
      if (textLineCount.title + textLineCount.subtitle >= 3) {
        return alert(
          '제목과 부제의 줄 길이수가 3줄 이상인 경우 배지 텍스트를 입력할 수 없어요.',
        );
      }
    }

    if (['reservationStartDatetime', 'reservationEndDatetime'].includes(key)) {
      if (!value) return;
      if (!moment(value).isBetween(banner.startDatetime, banner.endDatetime))
        return alert(
          '우선 노출 시작일시는 반드시 시작일시와 종료일시 사이여야합니다.',
        );
    }

    if (key === 'paramsRaceId') {
      _banner.info!.params.raceId = value;
    } else if (key === 'paramsGotchaId') {
      _banner.info!.params.paramsGotchaId = value;
    } else if (key === 'paramsOfferWallId') {
      _banner.info!.params.offerWallId = value;
    } else {
      (_banner.info as any)[key] = value;
    }

    delaySetBanner(_banner);
  };

  const delaySetBanner = useRef(
    _.debounce((value) => {
      setBanner(value);
    }, 400),
  ).current;

  const onChangeButtonColor = (e: any, { value }: any) => {
    if (!banner) return;

    const _banner = _.cloneDeep(banner);

    _banner.info!.buttonTypeColorKey = value;
    if (value === 'gray_900_fill') {
      _banner.info!.buttonBackgroundColor = colors.GRAY_900 as `#${string}`;
      _banner.info!.buttonColor = colors.BASIC_WHITE as `#${string}`;
    } else {
      _banner.info!.buttonBackgroundColor = colors.BASIC_WHITE as `#${string}`;
      _banner.info!.buttonColor = colors.GRAY_900 as `#${string}`;
    }

    setBanner(_banner);
  };

  const onChangeSingleFile = async (e: any) => {
    if (!banner) return;

    const _banner = _.cloneDeep(banner);
    const { name } = e.target;
    const temp = await handleChangeSingleFile(e, {});

    if (name === 'closedImageUrl' || name === 'imageUrlForB') {
      (_banner.info as any)[name] = temp[name];
    } else {
      (_banner as any)[name] = temp[name];
    }

    setBanner(_banner);
  };

  const handleChangeFilterId = (id: number | null) => {
    setBanner({
      ...banner,
      userFilter: { ...banner?.userFilter, id },
      userFilterId: id,
    });
  };

  const submit = () => {
    if (!banner || !banner.info) return;
    if (!banner.startDatetime) return alert('시작일시를 지정해야합니다.');
    if (!banner.endDatetime) return alert('종료일시를 지정해야합니다.');

    if (
      [bannerTypeObj.challengeCategory.value].includes(
        String(banner.bannerType),
      )
    ) {
      if (!banner.info?.categoryType)
        return alert('노출 카테고리를 지정해야합니다');
      if (!banner.landingType) return alert('타입을 지정해야합니다');
      if (!banner.landingDetail) return alert('타입id를 지정해야합니다');
    }

    // 최소지원버전 비어있을 경우 입력 안함
    if (banner.info?.minVersionSupport?.trim() === '') {
      delete banner.info?.minVersionSupport;
    }

    // reservationStartDatetime, reservationEndDatetime validation 필요
    if (banner.info?.isReserved) {
      if (
        !moment(banner.info?.reservationStartDatetime).isBetween(
          banner.startDatetime,
          banner.endDatetime,
        ) ||
        !moment(banner.info?.reservationEndDatetime).isBetween(
          banner.startDatetime,
          banner.endDatetime,
        )
      ) {
        return alert(
          '우선 노출 시작일시는 반드시 시작일시와 종료일시 사이여야합니다.',
        );
      }
    }

    if (banner.info && 'hideHeader' in banner.info) {
      banner.info.hideHeader = banner.info.hideHeader === true;
    }

    if (
      [
        bannerTypeObj.challengeSub.value,
        bannerTypeObj.mypageSub.value,
        bannerTypeObj.invite.value,
      ].includes(String(banner.bannerType))
    ) {
      // 서브 배너는 아래값만 살려놓기
      banner.info = {
        hideHeader: banner.info?.hideHeader,
        params: banner.info?.params,
        minVersionSupport: banner.info?.minVersionSupport,
      } as any;
    } else if (
      banner.bannerType === bannerTypeObj.popup.value &&
      banner.landingType === 'WEB'
    ) {
      banner.info.link = banner.landingDetail as string;
    }

    if (banner.info?.params?.raceId) {
      banner.info.params.raceId = Number(banner.info.params.raceId);
    }

    if (banner.info?.viewWeight) {
      banner.info.viewWeight = Number(banner.info.viewWeight);
    }

    if (banner.info?.benefitPointAmount) {
      banner.info.benefitPointAmount = Number(banner.info.benefitPointAmount);
    }

    // isReserved 값이 false면 굳이 reservationStartDatetime, reservationEndDatetime 저장 안함
    if (banner.info && !banner.info.isReserved) {
      delete banner.info.reservationStartDatetime;
      delete banner.info.reservationEndDatetime;
    }

    delete banner.info!.isReserved;

    banner.userFilterId = banner.userFilter?.id;
    delete banner.userFilter;

    addOrEditBanner(banner);
  };

  if (!banner) return null;

  return (
    <Modal size="large" open={modalOpen} onClose={closeModal}>
      <Modal.Header>배너 {!banner.id ? '추가' : '수정'}</Modal.Header>
      <Modal.Content>
        <Form>
          <S.RowContainer>
            <S.RowFormField>
              <label>시작일시</label>
              <Input
                type="datetime-local"
                max="9999-12-31T23:59"
                name="startDatetime"
                value={moment(banner.startDatetime).format('YYYY-MM-DDTHH:mm')}
                onChange={onChangeRadio}
              />
            </S.RowFormField>
            <S.RowFormField margin={'0 0 0 10px'}>
              <label>종료일시</label>
              <Input
                type="datetime-local"
                max="9999-12-31T23:59"
                name="endDatetime"
                value={moment(banner.endDatetime).format('YYYY-MM-DDTHH:mm')}
                onChange={onChangeRadio}
              />
            </S.RowFormField>
            <S.RowFormField margin={'0 0 0 10px'}>
              <label>노출방식</label>
              <Dropdown
                placeholder="원하는 배너 공개 방식을 선택하세요."
                fluid
                selection
                name="displayType"
                options={getDisplayTypeOptions(banner.displayType)}
                value={banner.displayType}
                onChange={onChangeRadio}
              />
            </S.RowFormField>
          </S.RowContainer>
          {banner.bannerType === bannerTypeObj.challengeMain.value && (
            <>
              <Divider />
              <Header as={'h3'} style={{ flex: 1 }}>
                우선 노출 예약 코너
              </Header>
              <S.RowContainer>
                <Form.Group grouped>
                  <label>우선 노출 여부</label>
                  <S.RowFormField
                    control={Radio}
                    label="우선 노출 필요"
                    checked={banner.info?.isReserved}
                    onChange={() => {
                      onChangeInfo('isReserved', true);
                    }}
                  />
                  <S.RowFormField
                    control={Radio}
                    label="필요없음"
                    checked={!banner.info?.isReserved}
                    onChange={() => {
                      onChangeInfo('reservationStartDatetime', null);
                      onChangeInfo('reservationEndDatetime', null);
                      onChangeInfo('isReserved', false);
                    }}
                  />
                </Form.Group>
              </S.RowContainer>
              {!!banner.info?.isReserved && (
                <>
                  <S.RowContainer>
                    <S.RowFormField>
                      <label>우선 노출 시작일시</label>
                      <Input
                        type="datetime-local"
                        max="9999-12-31T23:59"
                        value={moment(
                          banner.info.reservationStartDatetime,
                        ).format('YYYY-MM-DDTHH:mm')}
                        onChange={(_e, { value }) => {
                          onChangeInfo('reservationStartDatetime', value);
                        }}
                      />
                    </S.RowFormField>
                    <S.RowFormField margin={'0 0 0 10px'}>
                      <label>우선 노출 종료일시</label>
                      <Input
                        type="datetime-local"
                        max="9999-12-31T23:59"
                        value={moment(
                          banner.info.reservationEndDatetime,
                        ).format('YYYY-MM-DDTHH:mm')}
                        onChange={(_e, { value }) => {
                          onChangeInfo('reservationEndDatetime', value);
                        }}
                      />
                    </S.RowFormField>
                  </S.RowContainer>
                  <div style={{ color: 'blue', marginLeft: '10px' }}>
                    <div>
                      * 우선 노출 해당 시간에 해당 배너가 무조건 1순위로
                      노출됩니다.
                    </div>
                    <div>
                      * 우선 노출 시작일시와 종료일시는 <strong>반드시</strong>{' '}
                      배너의 시작일시와 종료일시 사이로 설정해주세요 🙏
                    </div>
                  </div>
                </>
              )}
              <Divider />
            </>
          )}

          <S.RowContainer>
            <S.RowFormField>
              <label>배너 노출 위치</label>
              <Dropdown
                placeholder="원하는 배너 공개 방식을 선택하세요."
                fluid
                selection
                name="bannerType"
                options={bannerTypes}
                value={banner.bannerType}
                onChange={onChangeRadio}
              />
            </S.RowFormField>
            {banner.bannerType &&
              [bannerTypeObj.challengeCategory.value].includes(
                banner.bannerType,
              ) && (
                <S.RowFormField>
                  <label>노출 카테고리</label>
                  <Dropdown
                    placeholder="원하는 카테고리를 선택하세요."
                    fluid
                    selection
                    name="categoryType"
                    options={(data?.categories || []).map((v) => ({
                      text: v.text,
                      value: v.text,
                      key: v.text,
                    }))}
                    value={banner.info?.categoryType ?? ''}
                    onChange={onChangeRadio}
                  />
                </S.RowFormField>
              )}
          </S.RowContainer>
          {banner.bannerType &&
            [bannerTypeObj.challengeCategory.value].includes(
              banner.bannerType,
            ) && (
              <Checkbox
                label={'만보기에 노출하기'}
                name={'pedometerBanner'}
                checked={banner.info?.pedometerBanner}
                onChange={(e) => {
                  setBanner((prev) =>
                    prev && prev.info
                      ? {
                          ...prev,
                          info: {
                            ...prev.info,
                            pedometerBanner: !prev.info?.pedometerBanner,
                          },
                        }
                      : null,
                  );
                }}
              />
            )}
          <Divider hidden />
          <Divider />
          <Divider hidden />

          {!!banner.bannerType && (
            <S.RowContainer>
              <S.RowFormField>
                <label>타입</label>
                <Dropdown
                  placeholder="랜딩 타입을 선택하세요."
                  fluid
                  selection
                  name="landingType"
                  options={landingTypeOptions}
                  value={banner.landingType}
                  onChange={onChangeRadio}
                />
              </S.RowFormField>

              <S.RowFormField margin={'0 0 0 10px'}>
                <label>타입id</label>
                {banner.landingType === 'CHALLENGE' && (
                  <Dropdown
                    placeholder="챌린지를 선택하세요."
                    fluid
                    search
                    selection
                    name="landingDetail"
                    options={challengeOptions}
                    value={Number(banner.landingDetail)}
                    onChange={onChangeRadio}
                  />
                )}
                {banner.landingType === 'PURCHASE_CHALLENGE' && (
                  <Dropdown
                    placeholder="챌린지를 선택하세요."
                    fluid
                    search
                    selection
                    name="landingDetail"
                    options={purchaseChallengeList?.map((challenge) => {
                      const text = [
                        challenge.id,
                        challenge.isSecret ? '비공개' : '공개',
                        moment(challenge.purchaseStartDate).format('M.D a h시'),
                        challenge.title,
                      ].join(' / ');

                      return {
                        text,
                        value: challenge.id,
                      };
                    })}
                    value={Number(banner.landingDetail)}
                    onChange={onChangeRadio}
                  />
                )}
                {banner.landingType === 'CONTENT' && (
                  <Dropdown
                    placeholder="컨텐츠를 선택하세요."
                    fluid
                    search
                    selection
                    name="landingDetail"
                    options={contentOptions}
                    value={Number(banner.landingDetail)}
                    onChange={onChangeRadio}
                  />
                )}
                {banner.landingType === 'NOTICE' && (
                  <Dropdown
                    placeholder="공지사항을 선택하세요."
                    fluid
                    selection
                    name="landingDetail"
                    options={noticeOptions}
                    value={Number(banner.landingDetail)}
                    onChange={onChangeRadio}
                  />
                )}
                {(banner.landingType === 'WEB' ||
                  banner.landingType === 'EXTERNAL_WEB') && (
                  <input
                    name="landingDetail"
                    value={banner.landingDetail}
                    onChange={onChangeText}
                  />
                )}
                {banner.landingType === 'MyOrderDeliveryScreen' && (
                  <>
                    <Dropdown
                      placeholder="이동할 화면을 선택하세요."
                      fluid
                      selection
                      name="landingDetail"
                      options={myOrderDeliveryScreenOptions}
                      value={banner.landingDetail}
                      onChange={onChangeRadio}
                    />
                    <div>
                      * 리스트에 없는 화면으로 이동을 원하실 경우 개발그룹에
                      요청 부탁드리겠습니다.
                    </div>
                  </>
                )}
                {banner.landingType === 'APP_EVENT_SCREEN' && (
                  <Dropdown
                    placeholder="원하는 이벤트 페이지를 선택해주세요."
                    fluid
                    search
                    selection
                    name="landingDetail"
                    options={eventOptions}
                    value={banner.landingDetail}
                    onChange={onChangeRadio}
                  />
                )}
                {banner.landingType === 'APP_ROUTING' && (
                  <Dropdown
                    placeholder="원하는 앱 화면을 선택해주세요."
                    fluid
                    selection
                    name="landingDetail"
                    options={etcScreensForLandingDetail}
                    value={banner.landingDetail}
                    onChange={onChangeRadio}
                  />
                )}
              </S.RowFormField>
              {[
                bannerTypeObj.popup.value,
                bannerTypeObj.mypageSub.value,
              ].includes(banner.bannerType) &&
                banner.landingType === 'WEB' && (
                  <S.RowFormField margin={'0 0 0 10px'}>
                    <label>헤더 노출여부</label>
                    <Form.Field
                      label="노출"
                      control="input"
                      type="radio"
                      checked={banner.info?.hideHeader === false}
                      onChange={() => onChangeInfo('hideHeader', 'false')}
                    />
                    <Form.Field
                      label="미노출"
                      control="input"
                      type="radio"
                      checked={banner.info?.hideHeader === true}
                      onChange={() => onChangeInfo('hideHeader', 'true')}
                    />
                    <S.RowContainer>
                      <div>
                        헤더노출
                        <Image
                          src="/banners/showHeader.png"
                          size="small"
                          centered
                        />
                      </div>
                      <div>
                        헤더미노출
                        <Image
                          src="/banners/hideHeader.png"
                          size="small"
                          centered
                        />
                      </div>
                    </S.RowContainer>
                  </S.RowFormField>
                )}
              {banner.landingDetail &&
                ['RaceRegisterScreen', 'RaceProgressLookAroundScreen'].includes(
                  String(banner.landingDetail),
                ) && (
                  <S.RowFormField margin={'0 0 0 10px'}>
                    <label>대회 id</label>
                    <Dropdown
                      fluid
                      selection
                      options={raceOptions}
                      value={banner.info?.params.raceId}
                      onChange={(__, { value }) =>
                        onChangeInfo('paramsRaceId', value)
                      }
                    />
                  </S.RowFormField>
                )}
              {['OfferWallBridgeScreen'].includes(
                String(banner.landingDetail),
              ) && (
                <S.RowFormField margin={'0 0 0 10px'}>
                  <label>오퍼월 id</label>
                  <Dropdown
                    search
                    fluid
                    selection
                    options={offerWallOptions}
                    value={banner.info?.params.offerWallId}
                    onChange={(__, { value }) =>
                      onChangeInfo('paramsOfferWallId', value)
                    }
                  />
                </S.RowFormField>
              )}
            </S.RowContainer>
          )}

          <S.RowContainer>
            <S.RowFormField>
              <label>최소 버전 지원 (빈 값이면 특별히 지원 안함)</label>
              <input
                name="minVersionSupport"
                value={banner.info?.minVersionSupport || ''}
                onChange={(e) =>
                  onChangeInfo('minVersionSupport', e.target.value)
                }
              />
            </S.RowFormField>

            <Unmountable
              onUnmount={() =>
                setBanner((prev) => ({
                  ...prev,
                  userFilter: undefined,
                  userFilterId: undefined,
                }))
              }
            >
              <S.RowFormField>
                <label>유저 필터 목록</label>
                <UserFilterSelector
                  filterId={banner.userFilter?.id}
                  onChange={handleChangeFilterId}
                  disabled={banner.landingType === 'CHALLENGE'}
                />
              </S.RowFormField>
            </Unmountable>
          </S.RowContainer>

          <Divider />

          {banner.bannerType &&
            [bannerTypeObj.challengeCategory.value].includes(
              banner.bannerType,
            ) && (
              <ChallengeCategoryForm
                banner={banner}
                onChangeText={onChangeText}
                onChangeInfo={onChangeInfo}
                onChangeSingleFile={onChangeSingleFile}
              />
            )}

          {banner.bannerType &&
            [bannerTypeObj.challengeMain.value].includes(banner.bannerType) &&
            banner && (
              <MainBannerForm
                banner={banner}
                onChangeText={onChangeText}
                onChangeInfo={onChangeInfo}
                onChangeSingleFile={onChangeSingleFile}
                onChangeRadio={onChangeRadio}
                onChangeButtonColor={onChangeButtonColor}
                challengeOptions={challengeOptions}
                noticeOptions={noticeOptions}
                contentOptions={contentOptions}
                raceOptions={raceOptions}
                offerWallOptions={offerWallOptions}
                eventOptions={eventOptions}
              />
            )}
          {banner.bannerType &&
            [bannerTypeObj.challengeSub.value].includes(banner.bannerType) && (
              <ChallengeSubBannerForm
                banner={banner}
                onChangeText={onChangeText}
                onChangeSingleFile={onChangeSingleFile}
              />
            )}
          {banner.bannerType &&
            [bannerTypeObj.raceSub.value].includes(banner.bannerType) && (
              <RaceSubBannerForm
                banner={banner}
                onChangeText={onChangeText}
                onChangeSingleFile={onChangeSingleFile}
                additionalBannerTypes={additionalBannerTypes}
                setAdditionalBannerTypes={setAdditionalBannerTypes}
              />
            )}
          {banner.bannerType &&
            [bannerTypeObj.popup.value].includes(banner.bannerType) && (
              <PopupBannerForm
                banner={banner}
                onChangeInfo={onChangeInfo}
                onChangeSingleFile={onChangeSingleFile}
                onChangeText={onChangeText}
              />
            )}
          {banner.bannerType &&
            [bannerTypeObj.benefitAdPopup.value].includes(
              banner.bannerType,
            ) && (
              <BenefitAdPopupBannerForm
                banner={banner}
                onChangeInfo={onChangeInfo}
                onChangeSingleFile={onChangeSingleFile}
                onChangeText={onChangeText}
              />
            )}
          {banner.bannerType &&
            [
              bannerTypeObj.mypageSub.value,
              bannerTypeObj.invite.value,
            ].includes(banner.bannerType) && (
              <SubBannerForm
                banner={banner}
                onChangeText={onChangeText}
                onChangeSingleFile={onChangeSingleFile}
              />
            )}
          {banner.bannerType === bannerTypeObj.raceSponseSub.value && (
            <RaceSponseSubBannerForm
              banner={banner}
              raceOptions={raceOptions}
              onChangeSingleFile={onChangeSingleFile}
              onChangeInfo={onChangeInfo}
              onChangeText={onChangeText}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="blue"
          content={!banner.id ? '추가' : '수정'}
          onClick={submit}
        />
        <Button color="black" content="닫기" onClick={closeModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default BannerModal;

const MainBannerForm = ({
  banner,
  onChangeText,
  onChangeInfo,
  onChangeSingleFile,
  onChangeRadio,
  onChangeButtonColor,
  challengeOptions,
  noticeOptions,
  contentOptions,
  raceOptions,
  offerWallOptions,
  eventOptions,
}: {
  banner: Partial<Banner>;
  onChangeText: (e: any) => void;
  onChangeInfo: (key: string, value: any) => void;
  onChangeSingleFile: (e: any) => void;
  onChangeRadio: (e: any, { name, value }: any) => void;
  onChangeButtonColor: (e: any, { value }: any) => void;
  challengeOptions: ChallengeOption[];
  noticeOptions: NoticeOption[];
  contentOptions: any[];
  raceOptions: RaceOption[];
  offerWallOptions: OfferWallOption[];
  eventOptions: EventOption[];
}) => {
  return (
    <>
      <h4>띠배너형태 / 사이즈 1125 * 528 (v1.9.0부터 적용)</h4>
      <Image src="/banners/main.png" size="large" centered />
      <div style={{ marginLeft: 20 }}>
        <S.RowContainer margin={'10px 0 0 0'}>
          <S.RowFormField>
            <label>스페셜 배지 텍스트 (최대 1줄)</label>
            <input
              name="specialBadgeText"
              defaultValue={banner.info?.specialBadgeText}
              onChange={(e) => onChangeInfo('specialBadgeText', e.target.value)}
            />
          </S.RowFormField>
          <S.RowFormField margin={'0 0 0 10px'}>
            <label>스페셜 배지 텍스트 배경색 (최대 1줄)</label>
            <input
              name="specialBadgeBackgroundColor"
              defaultValue={banner.info?.specialBadgeBackgroundColor}
              onChange={(e) =>
                onChangeInfo('specialBadgeBackgroundColor', e.target.value)
              }
            />
            <ColorSample color={banner.info?.specialBadgeBackgroundColor} />
          </S.RowFormField>
          <S.RowFormField margin={'0 0 0 10px'}>
            <label>스페셜 배지 텍스트 글씨색 (최대 1줄)</label>
            <input
              name="specialBadgeTextColor"
              defaultValue={banner.info?.specialBadgeTextColor}
              onChange={(e) =>
                onChangeInfo('specialBadgeTextColor', e.target.value)
              }
            />
            <ColorSample color={banner.info?.specialBadgeTextColor} />
          </S.RowFormField>
        </S.RowContainer>

        <div style={{ display: 'flex', marginTop: 10 }}>
          <S.RowFormField>
            <label>제휴 배지 텍스트 (최대 1줄)</label>
            <input
              name="collaborationBadgeText"
              defaultValue={banner.info?.collaborationBadgeText}
              onChange={(e) =>
                onChangeInfo('collaborationBadgeText', e.target.value)
              }
            />
          </S.RowFormField>
          <S.RowFormField margin={'0 0 0 10px'}>
            <label>제휴 배지 텍스트 글씨색 (최대 1줄)</label>
            <input
              name="collaborationBadgeTextColor"
              defaultValue={banner.info?.collaborationBadgeTextColor}
              onChange={(e) =>
                onChangeInfo('collaborationBadgeTextColor', e.target.value)
              }
            />
            <ColorSample color={banner.info?.collaborationBadgeTextColor} />
          </S.RowFormField>
        </div>

        <S.RowContainer margin={'20px 0 0 0'}>
          <S.RowFormField>
            <label>제목 (최대 2줄, 총 28자 이내)</label>
            <TextArea
              name="title"
              rows={2}
              defaultValue={banner.title}
              onChange={onChangeText}
            />
          </S.RowFormField>
          <S.RowFormField margin={'0 0 0 10px'}>
            <label>제목 글자색</label>
            <input
              name="color"
              defaultValue={banner.color}
              onChange={onChangeText}
            />
            <ColorSample color={banner.color} />
          </S.RowFormField>
        </S.RowContainer>
        <S.RowContainer margin={'20px 0 0 0'}>
          <S.RowFormField>
            <label>* 진행중일 때 노출되는 이미지 (사이즈 1125 * 528)</label>
            <input name="imageUrl" type="file" onChange={onChangeSingleFile} />
            <div style={{ position: 'relative' }}>
              <Image src={banner.imageUrl} size="medium" />
              <PreviewImage.OnGoing
                title={banner.title!}
                color={banner.color!}
                imageUrl={banner.imageUrl!}
                info={banner.info!}
              />
            </div>
          </S.RowFormField>
          <S.RowFormField margin={'0 0 0 10px'}>
            <label>* 종료됐을 때 노출되는 이미지 (사이즈 1125 * 288)</label>
            <input
              name="closedImageUrl"
              type="file"
              onChange={onChangeSingleFile}
            />
            <div style={{ position: 'relative' }}>
              <Image src={banner.info?.closedImageUrl} size="medium" />
              <PreviewImage.Closed
                title={banner.title!}
                color={banner.color!}
                info={banner.info!}
              />
            </div>
          </S.RowFormField>
        </S.RowContainer>
      </div>
      <Divider />

      <S.RowContainer>
        <S.RowFormField>
          <label>버튼타입 (상단의 타입과 동기화)</label>
          <Dropdown
            placeholder="버튼 타입을 선택하세요."
            disabled
            fluid
            selection
            name="buttonType"
            options={landingTypeOptions}
            value={banner.landingType} // NOTE: 배너 타입과 동기화시키지 않으려면 [banner.langinType] 대신에 [banner.info?.buttonType]을 사용해주세요.
            onChange={onChangeRadio}
          />
        </S.RowFormField>
        <S.RowFormField margin={'0 0 0 10px'}>
          <label>버튼타입id (상단의 타입id와 동기화)</label>
          {banner.info?.buttonType === 'CHALLENGE' && (
            <Dropdown
              placeholder="챌린지를 선택하세요."
              disabled
              fluid
              search
              selection
              name="buttonTypeId"
              options={challengeOptions}
              value={Number(banner.landingDetail)} // NOTE: 배너 타입과 동기화시키지 않으려면 [banner.langinType] 대신에 [banner.info?.buttonType]을 사용해주세요.
              onChange={onChangeRadio}
            />
          )}
          {banner.info?.buttonType === 'CONTENT' && (
            <Dropdown
              placeholder="컨텐츠를 선택하세요."
              disabled
              fluid
              search
              selection
              name="buttonTypeId"
              options={contentOptions}
              value={Number(banner.landingDetail)} // NOTE: 배너 타입과 동기화시키지 않으려면 [banner.langinType] 대신에 [banner.info?.buttonType]을 사용해주세요.
              onChange={onChangeRadio}
            />
          )}
          {banner.info?.buttonType === 'NOTICE' && (
            <Dropdown
              placeholder="공지사항을 선택하세요."
              disabled
              fluid
              selection
              name="buttonTypeId"
              options={noticeOptions}
              value={Number(banner.landingDetail)} // NOTE: 배너 타입과 동기화시키지 않으려면 [banner.langinType] 대신에 [banner.info?.buttonType]을 사용해주세요.
              onChange={onChangeRadio}
            />
          )}
          {(banner.info?.buttonType === 'WEB' ||
            banner.info?.buttonType === 'EXTERNAL_WEB') && (
            <input
              name="buttonTypeId"
              disabled
              value={banner.landingDetail} // NOTE: 배너 타입과 동기화시키지 않으려면 [banner.langinType] 대신에 [banner.info?.buttonType]을 사용해주세요.
              onChange={(e) => onChangeInfo('buttonTypeId', e.target.value)}
            />
          )}
          {banner.info?.buttonType === 'APP_ROUTING' && (
            <Dropdown
              placeholder="원하는 앱 화면을 선택해주세요."
              disabled
              fluid
              selection
              name="buttonTypeId"
              options={etcScreensForLandingDetail}
              value={banner.landingDetail} // NOTE: 배너 타입과 동기화시키지 않으려면 [banner.langinType] 대신에 [banner.info?.buttonType]을 사용해주세요.
              onChange={(__, { value }) => onChangeInfo('buttonTypeId', value)}
            />
          )}
          {banner.info?.buttonType === 'MyOrderDeliveryScreen' && (
            <>
              <Dropdown
                placeholder="이동할 화면을 선택하세요."
                disabled
                fluid
                selection
                options={myOrderDeliveryScreenOptions}
                value={banner.landingDetail} // NOTE: 배너 타입과 동기화시키지 않으려면 [banner.langinType] 대신에 [banner.info?.buttonType]을 사용해주세요.
                onChange={(__, { value }) =>
                  onChangeInfo('buttonTypeId', value)
                }
              />
              <div>
                * 리스트에 없는 화면으로 이동을 원하실 경우 개발그룹에 요청
                부탁드리겠습니다.
              </div>
            </>
          )}
          {banner.info?.buttonType === 'APP_EVENT_SCREEN' && (
            <Dropdown
              placeholder="원하는 이벤트 페이지를 선택해주세요."
              disabled
              fluid
              selection
              options={eventOptions}
              value={banner.landingDetail} // NOTE: 배너 타입과 동기화시키지 않으려면 [banner.langinType] 대신에 [banner.info?.buttonType]을 사용해주세요.
              onChange={(__, { value }) => onChangeInfo('buttonTypeId', value)}
            />
          )}
        </S.RowFormField>
        {banner.info?.buttonTypeId &&
          ['RaceRegisterScreen', 'RaceProgressLookAroundScreen'].includes(
            String(banner.info?.buttonTypeId),
          ) && (
            <S.RowFormField margin={'0 0 0 10px'}>
              <label>대회 id (상단의 대회 id와 동기화)</label>
              <Dropdown
                fluid
                disabled
                selection
                options={raceOptions}
                value={banner.info.params.raceId}
                onChange={(__, { value }) =>
                  onChangeInfo('paramsRaceId', value)
                }
              />
            </S.RowFormField>
          )}
        {banner.info?.buttonTypeId &&
          ['OfferWallBridgeScreen'].includes(
            String(banner.info.buttonTypeId),
          ) && (
            <S.RowFormField margin={'0 0 0 10px'}>
              <label>오퍼월 id (상단의 오퍼월 id와 동기화)</label>
              <Dropdown
                search
                fluid
                disabled
                selection
                options={offerWallOptions}
                value={banner.info.params.offerWallId}
                onChange={(__, { value }) =>
                  onChangeInfo('paramsOfferWallId', value)
                }
              />
            </S.RowFormField>
          )}
      </S.RowContainer>

      <S.RowContainer>
        <S.RowFormField>
          <label>버튼글씨</label>
          <input
            name="buttonText"
            value={banner.info?.buttonText}
            onChange={(e) => onChangeInfo('buttonText', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField>
          <label>버튼 디자인 종류</label>
          <Dropdown
            placeholder="버튼 디자인 종류를 선택하세요"
            fluid
            selection
            options={[
              { key: 0, text: '검정배경버튼', value: 'gray_900_fill' },
              { key: 1, text: '흰색배경버튼', value: 'white_fill' },
            ]}
            value={banner.info?.buttonTypeColorKey}
            onChange={onChangeButtonColor}
          />
        </S.RowFormField>
      </S.RowContainer>
    </>
  );
};

const ChallengeSubBannerForm = ({
  banner,
  onChangeText,
  onChangeSingleFile,
}: {
  banner: Partial<Banner>;
  onChangeText: (e: any) => void;
  onChangeSingleFile: (e: any) => void;
}) => {
  return (
    <>
      <h4>
        띠배너형태 / 사이즈 1000 * 200 (v1: 우선순위 높은 1개만 노출됨 / v2:
        전체 노출됨)
      </h4>
      <Form.Field>
        <label>제목 (웹페이지 헤더 노출 설정시 상단 제목으로 활용)</label>
        <TextArea
          name="title"
          rows={2}
          defaultValue={banner.title}
          onChange={onChangeText}
        />
      </Form.Field>

      <Image src="/banners/challenge-sub.png" size="medium" centered />
      <Form.Field>
        <label>이미지</label>
        <input name="imageUrl" type="file" onChange={onChangeSingleFile} />
        <Image src={banner.imageUrl} size="medium" />
      </Form.Field>
    </>
  );
};

const RaceSubBannerForm = ({
  banner,
  onChangeText,
  onChangeSingleFile,
  additionalBannerTypes,
  setAdditionalBannerTypes,
}: {
  banner: Partial<Banner>;
  onChangeText: (e: any) => void;
  onChangeSingleFile: (e: any) => void;
  additionalBannerTypes: string[];
  setAdditionalBannerTypes: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  return (
    <>
      <h4>
        띠배너형태 / 사이즈 1000 * 200 (v1: 우선순위 높은 1개만 노출됨 / v2:
        전체 노출됨)
      </h4>
      <Form.Field>
        <label>제목 (웹페이지 헤더 노출 설정시 상단 제목으로 활용)</label>
        <TextArea
          name="title"
          rows={2}
          defaultValue={banner.title}
          onChange={onChangeText}
        />
      </Form.Field>

      <Image src="/banners/challenge-sub.png" size="medium" centered />
      <Form.Field>
        <label>이미지</label>
        <input name="imageUrl" type="file" onChange={onChangeSingleFile} />
        <Image src={banner.imageUrl} size="medium" />
      </Form.Field>

      <Divider />
      <h4>
        추가 배너로 생성하기(해당 체크버튼을 누르고 수정 또는 생성 하면 동일한
        내용으로 복사됩니다)
      </h4>
      <Form.Field style={{ display: 'flex', gap: '20px' }}>
        <Checkbox
          label={'챌랜지 서브'}
          name={'additionalBanner'}
          checked={additionalBannerTypes.includes('CHLLANGE_SUB')}
          onChange={(e, { checked }) => {
            if (checked) {
              setAdditionalBannerTypes((prev) => [...prev, 'CHLLANGE_SUB']);
            } else {
              setAdditionalBannerTypes((prev) =>
                prev.filter((v) => v !== 'CHLLANGE_SUB'),
              );
            }
          }}
        />
        <Checkbox
          label={'마이페이지 서브'}
          name={'additionalBanner'}
          checked={additionalBannerTypes.includes('MYPAGE_SUB')}
          onChange={(e, { checked }) => {
            if (checked) {
              setAdditionalBannerTypes((prev) => [...prev, 'MYPAGE_SUB']);
            } else {
              setAdditionalBannerTypes((prev) =>
                prev.filter((v) => v !== 'MYPAGE_SUB'),
              );
            }
          }}
        />
      </Form.Field>
    </>
  );
};

const SubBannerForm = ({
  banner,
  onChangeText,
  onChangeSingleFile,
}: {
  banner: Partial<Banner>;
  onChangeText: (e: any) => void;
  onChangeSingleFile: (e: any) => void;
}) => {
  return (
    <>
      <h4>띠배너형태 / 사이즈 1000 * 200</h4>
      <Form.Field>
        <label>제목 (웹페이지 이동시 상단 제목으로 활용)</label>
        <input
          name="title"
          defaultValue={banner.title}
          onChange={onChangeText}
        />
      </Form.Field>

      <Image src="/banners/challenge-sub.png" size="medium" centered />
      <Form.Field>
        <label>이미지</label>
        <input name="imageUrl" type="file" onChange={onChangeSingleFile} />
        <Image src={banner.imageUrl} size="medium" />
      </Form.Field>
    </>
  );
};

const StoreHomeFloatingButtonForm = ({
  banner,
  onChangeText,
}: {
  banner: Partial<Banner>;
  onChangeText: (e: any) => void;
}) => {
  return (
    <>
      <h4>플로팅 버튼 노출 문구 (띄어쓰기 포함 12자)</h4>
      <Form.Field>
        <label>제목</label>
        <input
          name="title"
          defaultValue={banner.title}
          onChange={onChangeText}
        />
      </Form.Field>
    </>
  );
};

const StoreSubBannerLargeForm = ({
  banner,
  onChangeText,
  onChangeSingleFile,
}: {
  banner: Partial<Banner>;
  onChangeText: (e: any) => void;
  onChangeSingleFile: (e: any) => void;
}) => {
  return (
    <>
      <h4>띠배너형태 / 사이즈 1125 * 360</h4>
      <Form.Field>
        <label>제목 (웹페이지 이동시 상단 제목으로 활용)</label>
        <input
          name="title"
          defaultValue={banner.title}
          onChange={onChangeText}
        />
      </Form.Field>

      <Image src="/banners/challenge-sub.png" size="medium" centered />
      <Form.Field>
        <label>이미지</label>
        <input name="imageUrl" type="file" onChange={onChangeSingleFile} />
        <Image src={banner.imageUrl} size="medium" />
      </Form.Field>
    </>
  );
};

const StoreSubBannerMediumForm = ({
  banner,
  onChangeText,
  onChangeSingleFile,
}: {
  banner: Partial<Banner>;
  onChangeText: (e: any) => void;
  onChangeSingleFile: (e: any) => void;
}) => {
  return (
    <>
      <h4>띠배너형태 / 사이즈 1000 * 200</h4>
      <Form.Field>
        <label>제목 (웹페이지 이동시 상단 제목으로 활용)</label>
        <input
          name="title"
          defaultValue={banner.title}
          onChange={onChangeText}
        />
      </Form.Field>

      <Image src="/banners/challenge-sub.png" size="medium" centered />
      <Form.Field>
        <label>이미지</label>
        <input name="imageUrl" type="file" onChange={onChangeSingleFile} />
        <Image src={banner.imageUrl} size="medium" />
      </Form.Field>
    </>
  );
};

const BenefitAdPopupBannerForm = ({
  banner,
  onChangeSingleFile,
  onChangeInfo,
  onChangeText,
}: {
  banner: Partial<Banner>;
  onChangeSingleFile: (e: any) => void;
  onChangeInfo: (key: string, value: any) => void;
  onChangeText: (e: any) => void;
}) => {
  return (
    <>
      <h4>사이즈 900 * 1200</h4>
      <S.RowContainer>
        <div>
          <Image src="/banners/benefit-ad-popup-example.png" size="small" />
        </div>
      </S.RowContainer>
      아래의 &quot;바로가기 {'>'}&quot; 버튼까지 이미지입니다~
      <S.Container margin={'20px 0 0 0'}>
        <Form.Field>
          <label>이미지 (900 * 1200)</label>
          <input name="imageUrl" type="file" onChange={onChangeSingleFile} />
          <Image src={banner.imageUrl} size="medium" />
        </Form.Field>
      </S.Container>
      <Divider />
      <Form.Field>
        <label>제목 (웹페이지 이동시 상단 제목으로 활용)</label>
        <input
          name="title"
          defaultValue={banner.title}
          onChange={onChangeText}
        />
      </Form.Field>
      <Divider />
      <S.RowContainer>
        <S.RowFormField>
          <label>노출 가중치</label>
          <input
            name="viewWeight"
            defaultValue={banner.info?.viewWeight ?? undefined}
            onChange={(e) => onChangeInfo('viewWeight', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField>
          <label>지급 기준</label>
          <Dropdown
            placeholder="원하는 지급 기준."
            fluid
            selection
            options={benefitActionTypeOptions}
            value={banner.info?.benefitActionType ?? undefined}
            onChange={(__, { value }) =>
              onChangeInfo('benefitActionType', value)
            }
          />
        </S.RowFormField>
        <S.RowFormField>
          <label>지급 포인트</label>
          <input
            defaultValue={banner.info?.benefitPointAmount ?? undefined}
            onChange={(e) => onChangeInfo('benefitPointAmount', e.target.value)}
          />
        </S.RowFormField>
      </S.RowContainer>
    </>
  );
};

const PopupBannerForm = ({
  banner,
  onChangeSingleFile,
  onChangeInfo,
  onChangeText,
}: {
  banner: Partial<Banner>;
  onChangeSingleFile: (e: any) => void;
  onChangeInfo: (key: string, value: any) => void;
  onChangeText: (e: any) => void;
}) => {
  return (
    <>
      <h4>이미지 세팅</h4>
      <PopUpBannerPreview />

      <S.RowContainer margin={'0 0 0 20px'} gap={'5px'}>
        <Form.Field>
          <label>이미지 [기존] (915 * 1326)</label>
          <input name="imageUrl" type="file" onChange={onChangeSingleFile} />
          <Image src={banner.imageUrl} size="medium" />
        </Form.Field>
        <Form.Field>
          <label>이미지 (1200 * 900)</label>
          <input
            name="imageUrlForB"
            type="file"
            onChange={onChangeSingleFile}
          />
          <Image src={banner.info?.imageUrlForB} size="medium" />
        </Form.Field>
      </S.RowContainer>
      <Divider />
      <Form.Field>
        <label>제목 (웹페이지 이동시 상단 제목으로 활용)</label>
        <input
          name="title"
          defaultValue={banner.title}
          onChange={onChangeText}
        />
      </Form.Field>
      <Divider />
      <S.RowContainer>
        <S.RowFormField>
          <label>버튼글씨</label>
          <input
            name="buttonText"
            defaultValue={banner.info?.buttonText}
            onChange={(e) => onChangeInfo('buttonText', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField margin={'0 0 0 10px'}>
          <label>버튼글자색</label>
          <input
            name="buttonTextColor"
            defaultValue={banner.info?.buttonTextColor}
            onChange={(e) => onChangeInfo('buttonTextColor', e.target.value)}
          />
          <ColorSample color={banner.info?.buttonTextColor} />
        </S.RowFormField>
        <S.RowFormField margin={'0 0 0 10px'}>
          <label>버튼배경색</label>
          <input
            name="buttonBgColor"
            defaultValue={banner.info?.buttonBgColor}
            onChange={(e) => onChangeInfo('buttonBgColor', e.target.value)}
          />
          <ColorSample color={banner.info?.buttonBgColor} />
        </S.RowFormField>
      </S.RowContainer>
    </>
  );
};

const RaceSponseSubBannerForm = ({
  banner,
  raceOptions,
  onChangeSingleFile,
  onChangeInfo,
  onChangeText,
}: {
  banner: Partial<Banner>;
  raceOptions: RaceOption[];
  onChangeSingleFile: (e: any) => void;
  onChangeInfo: (key: string, value: any) => void;
  onChangeText: (e: any) => void;
}) => {
  return (
    <>
      <h4>
        인증 대회 상세 - 서브배너 / 사이즈 1000 * 200 (우선순위 높은 1개만
        노출됨)
      </h4>
      <S.RowContainer>
        <S.RowFormField>
          <label>제목</label>
          <input
            name="title"
            defaultValue={banner.title}
            onChange={onChangeText}
          />
        </S.RowFormField>
      </S.RowContainer>
      <S.RowContainer
        style={{
          marginTop: 20,
        }}
      >
        <S.RowFormField>
          <label>버튼글씨</label>
          <input name="imageUrl" type="file" onChange={onChangeSingleFile} />
          <Image src={banner.imageUrl} size="medium" />
        </S.RowFormField>
        <S.RowFormField>
          <label>노출 될 대회 id</label>
          <Dropdown
            fluid
            selection
            options={raceOptions}
            value={banner.info?.raceId ?? undefined}
            onChange={(__, { value }) => onChangeInfo('raceId', value)}
          />
        </S.RowFormField>
      </S.RowContainer>
    </>
  );
};

type UnmountableProps = PropsWithChildren<{
  onUnmount: () => void;
}>;
function Unmountable({ onUnmount, children }: UnmountableProps) {
  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, []);

  return <>{children}</>;
}
