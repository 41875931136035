import { ChallengeGoalCardDescriptionModule } from '@chlngers/core/lib/src/types/challenge';
import { DescriptionGoalModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const convertData2Form: (
  module: ChallengeGoalCardDescriptionModule,
) => DescriptionGoalModuleFormType = (module) => {
  return {
    type: module.type,
    mainHeader: module.mainHeader || '',
    subHeader: module.subHeader || '',
    ids: module.ids || [],
  };
};

export const convertForm2Data: (
  module: DescriptionGoalModuleFormType,
) => ChallengeGoalCardDescriptionModule = (module) => {
  if (!module) throw Error("this shouldn't be happened");
  return module;
};
