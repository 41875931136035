import { apis } from '@api/index';
import { Banner } from '@types';
import {
  endOfDay,
  isAfter,
  isBefore,
  startOfDay,
  startOfSecond,
} from 'date-fns';

type Challenge = {
  value: number;
  isPublic: boolean;
  title: string;
};

export const confirmOnChangeDisplay = (
  banner: Banner,
  challenges: Challenge[],
): boolean => {
  // 비공개로 바꿀 때는 확인 불필요
  if (!banner.isDisplayed) return true;

  if (!['CHALLENGE'].includes(banner.landingType)) return true;

  const challengeId = Number(banner.landingDetail);
  const challenge = challenges.find((c) => c.value === challengeId);
  if (!challenge?.isPublic)
    return window.confirm(
      '챌린지가 비공개 상태입니다. 그래도 노출하시겠습니까? ',
    );

  return true;
};

export const checkBannerTargetFullRegistered = async (banner: Banner) => {
  let fullRegistered = false;
  if (banner.isDisplayed && checkBannerForChallengeOrRace(banner)) {
    const res = await apis.checkBannerTargetFullRegistered(banner.id);
    fullRegistered = res.fullRegistered;
  }

  return fullRegistered;
};

export const checkBannerForChallengeOrRace = (banner: Banner) => {
  return (
    banner.landingType === 'CHALLENGE' ||
    banner.landingDetail === 'RaceRegisterScreen'
  );
};

export const hasHighestPriorityNow = (banner: Banner) => {
  if (
    !banner.info.reservationStartDatetime ||
    !banner.info.reservationEndDatetime
  )
    return false;

  const now = new Date();

  return (
    isAfter(now, new Date(banner.info.reservationStartDatetime)) &&
    isBefore(now, new Date(banner.info.reservationEndDatetime))
  );
};

export const getBackgroundColor = (banner: Banner) => {
  if (isBefore(new Date(), new Date(banner.startDatetime))) return 'lightGray';
  if (hasHighestPriorityNow(banner)) return 'lightBlue';
  return 'transparent';
};
