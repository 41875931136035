import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const usePurchaseDateTime = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      purchaseStartDate: s.challenge.purchaseStartDate,
      purchaseEndDate: s.challenge.purchaseEndDate,
    })),
  );
};

export const usePurchaseEndDateTimeActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setPurchaseEndDateTime = (purchaseEndDate: string) =>
    updateChallengeForm({ purchaseEndDate });

  return { setPurchaseEndDateTime };
};
