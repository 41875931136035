import Button from '@component/Button';
import FlexBox from '@component/FlexBox/FlexBox';
import NumberInput from '@component/NumberInput';
import { FC, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { Rccp } from '../../../../NewChallengeForm.types';
import { useAllProducts } from '../../hooks/useAllProducts';
import CommerceManagement from '../CommerceManagement/CommerceManagement';
import CompanySelector from '../CompanySelector/CompanySelector';
import ProductSelector from '../ProductSelector/ProductSelector';

type Props = {
  isOpened: boolean;
  onClose: () => void;
  onAdd: (data: Rccp) => void;
};
const ProductAddModal: FC<Props> = ({ isOpened, onClose, onAdd }) => {
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [productId, setProductId] = useState<number | null>(null);
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const { products } = useAllProducts();

  const selectedProduct = products.find((p) => p.id === productId);

  const handleAddProduct = () => {
    if (!selectedProduct || !productId) {
      alert('상품을 선택해주세요.');
      return;
    }

    if (!totalCount) {
      alert('전체수량을 입력해주세요.');
      return;
    }

    if (!companyId) {
      alert('회사를 선택해주세요.');
      return;
    }

    const rccp: Rccp = {
      companyId,
      productId,
      companyName: selectedProduct.companyName,
      isAddressNeeded: selectedProduct.isAddressNeeded,
      price: selectedProduct.price,
      shippingPrice: selectedProduct.shippingPrice,
      name: selectedProduct.name,
      unit: 1,
      fee: 0,
      totalCount,
    };

    onAdd(rccp);
    onClose();
  };

  return (
    <Modal size="small" open={isOpened} onClose={onClose}>
      <Modal.Header>판매 상품 추가</Modal.Header>

      <CommerceManagement />
      <Modal.Content>
        <FlexBox.Column gap={12}>
          <CompanySelector
            companyId={companyId || undefined}
            onChange={setCompanyId}
          />
          <ProductSelector
            productId={productId || undefined}
            onChange={setProductId}
          />
          <NumberInput
            label="전체수량"
            value={totalCount || undefined}
            onChange={setTotalCount}
          />
        </FlexBox.Column>
      </Modal.Content>
      <Modal.Actions>
        <FlexBox.Row
          justifyContent="flex-end"
          alignItems="center"
          gap={12}
          style={{ paddingBottom: 12 }}
        >
          <Button text="추가" size="medium" onClick={handleAddProduct} />
          <Button text="닫기" size="medium" onClick={onClose} />
        </FlexBox.Row>
      </Modal.Actions>
    </Modal>
  );
};

export default ProductAddModal;
