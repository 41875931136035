import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  useIsRegisterDepositFixed,
  useUpdateIsRegisterDepositFixed,
} from './IsRegisterDepositFixedForm.store';
import { validate } from './IsRegisterDepositFixedForm.validator';
import {
  convertData2Form,
  convertForm2Data,
} from './IsRegisterDepositFixedForm.serializer';

export const IsRegisterDepositFixedForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'isRegisterDepositFixed'>,
  Pick<ChallengeFormType, 'isRegisterDepositFixed'>,
  Pick<ReqCreateOrUpdateChallenge, 'isRegisterDepositFixed'>
> = () => {
  const isRegisterDepositFixed = useIsRegisterDepositFixed();
  const { update } = useUpdateIsRegisterDepositFixed();

  const selectedOption =
    OPTIONS.find((o) => o.value === isRegisterDepositFixed) || OPTIONS[0];

  return (
    <FlexBox.Column gap={12}>
      <Label>참가비 고정여부</Label>
      <RadioSelector
        selectedOption={selectedOption}
        options={OPTIONS}
        onChange={(option) => update(option.value)}
        style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
      />
    </FlexBox.Column>
  );
};

const OPTIONS = [
  { label: '고정', value: true },
  { label: '변동', value: false },
];

IsRegisterDepositFixedForm.validate = validate;
IsRegisterDepositFixedForm.convertData2Form = convertData2Form;
IsRegisterDepositFixedForm.convertForm2Data = convertForm2Data;
