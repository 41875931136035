import { Race } from '@types';
import { RaceFormType } from './RaceForm.types';
import { isAfter } from 'date-fns';

export const validate = async (
  _race: RaceFormType,
  _productPackages: Race.RaceProductPackageFormType[],
  _stampStickers: Race.RaceSticker[],
  _imageFilters: Race.RaceImageFilter[],
) => {
  if (_race.sponsors.length === 0) {
    return { isValid: false, message: '후원사를 추가해주세요.' };
  }

  if (_race.reviewInfo?.questions?.length === 0) {
    return { isValid: false, message: '리뷰 질문 목록을 추가해주세요.' };
  }

  if (_race.reactions.length === 0) {
    return { isValid: false, message: '리엑션을 추가해주세요.' };
  }

  if (_race.productDescriptions.length === 0) {
    return { isValid: false, message: '후원 물품을 추가해주세요.' };
  }

  if (_race.leagues.length === 0) {
    return { isValid: false, message: '리그를 추가해주세요.' };
  }

  let result = {
    isValid: true,
    message: '',
  };
  _race.modules.progressInfoDescriptionModule.forEach((module) => {
    if (module.type === 'TEMPORARY-ORIGIN-IMAGE') {
      if (isAfter(new Date(module.dueStartDate), new Date(module.dueEndDate))) {
        result = {
          isValid: false,
          message:
            '둘러보기 화면 기간한정 이미지의 기간이 잘못 설정되었습니다.',
        };
      }
    }
  });
  if (!result.isValid) {
    return result;
  }

  _race.modules.registerInfoDescriptionModule.forEach((module) => {
    if (module.type === 'TEMPORARY-ORIGIN-IMAGE') {
      if (isAfter(new Date(module.dueStartDate), new Date(module.dueEndDate))) {
        result = {
          isValid: false,
          message: '신청화면 기간한정 이미지의 기간이 잘못 설정되었습니다.',
        };
      }
    }
  });

  if (!result.isValid) {
    return result;
  }

  return {
    isValid: true,
    message: '',
  };
};
