import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import thunk from 'redux-thunk';

import appReducer from '../container/App/App.reducer';
import challengeReducer from '../container/Challenge/Challenge.reducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const middleWares = [thunk];
const composeEnhancers =
  process.env.REACT_APP_ENV === 'PROD'
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const combinedReducer = combineReducers({
  app: appReducer,
  challenge: challengeReducer,
});

export const initStore = (initialStore = {}) => {
  const store = createStore(
    combinedReducer,
    initialStore,
    composeEnhancers(applyMiddleware(...middleWares)),
  );
  return store;
};

export default initStore;
export type InitStore = ReturnType<typeof combinedReducer>;
