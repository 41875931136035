import { isNull } from '@utils/js.utils';
import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'qna' | 'goalId'> & {
  maxLength?: number;
};

let qnaMaxLength: number;
export const validate: FormValidator<Params> = ({ qna, goalId, maxLength }) => {
  if (!qna.questions.length) return { isValid: true };
  if (isNull(goalId))
    return { isValid: false, message: '[질의응답] 골을 설정해주세요' };
  if (typeof maxLength === 'number') qnaMaxLength = maxLength;

  if (typeof qnaMaxLength === 'number' && qna.questions.length > qnaMaxLength)
    return {
      isValid: false,
      message: '[질의응답] 회차별 질의응답 수는 골의 회차 수보다 작아야합니다',
    };

  // TODO: 질의응답 갯수 == 회차 수를 만족해야하는지 확인

  return { isValid: true };
};
