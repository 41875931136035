import Button from '@component/Button';
import Dropdown from '@component/Dropdown';
import FlexBox from '@component/FlexBox/FlexBox';
import ImageInput from '@component/ImageInput';
import Label from '@component/Label';
import { ChallengeSticker } from '@types';
import { isNull } from '@utils/js.utils';
import { Image, Modal } from 'semantic-ui-react';
import { CompanyWithoutProduct } from 'src/types/commerce.types';
import {
  useChallengeStickersActions,
  useChallengeStickersStore,
} from '../../StickerForm.store';

type Props = {
  sticker: ChallengeSticker & { index?: number };
  setSticker: (sticker: ChallengeSticker) => void;
  companyOptions: CompanyWithoutProduct[];
  isOpened: boolean;
  onClose: () => void;
};

const StickerModal = ({
  sticker,
  setSticker,
  companyOptions,
  isOpened,
  onClose,
}: Props) => {
  const { challengeStickers = [] } = useChallengeStickersStore();
  const { setChallengeStickers } = useChallengeStickersActions();

  const addSticker = () => {
    setChallengeStickers([...challengeStickers, sticker]);
    onClose();
  };

  const editSticker = () => {
    const { index, ...rest } = sticker;
    setChallengeStickers(
      challengeStickers.map((d, idx) => (idx === sticker.index ? rest : d)),
    );
    onClose();
  };

  const handleChangeCompany = (companyId: number | null) => {
    if (isNull(companyId)) return;
    setSticker({ ...sticker, companyId });
  };

  const handleChangeStickerImage = (imageUrl: string | undefined) => {
    if (!imageUrl) return;
    setSticker({ ...sticker, imageUrl });
  };

  return (
    <Modal size="small" open={isOpened} onClose={onClose}>
      <Modal.Header>스티커 추가</Modal.Header>
      <FlexBox.Column gap={10} style={{ padding: 20 }}>
        <FlexBox.Column gap={5}>
          <Label>회사</Label>
          <Dropdown
            placeholder="회사를 선택하세요."
            search
            options={companyOptions.map(({ id, name }) => ({
              text: name,
              value: id,
            }))}
            value={sticker?.companyId}
            onChange={(o) => handleChangeCompany(o)}
            keyExtractor={(item) => item}
          />
        </FlexBox.Column>
        <FlexBox.Column gap={5}>
          <Label>스티커 이미지</Label>
          <ImageInput
            multiSelect={false}
            uploadOnChange
            onChange={handleChangeStickerImage}
          />
          <Image src={sticker?.imageUrl} size="medium" />
        </FlexBox.Column>
      </FlexBox.Column>
      <FlexBox.Row gap={10} justifyContent="flex-end" style={{ padding: 10 }}>
        <Button
          bgColor="black"
          fontColor="white"
          text={typeof sticker.index === 'number' ? '편집' : '추가'}
          onClick={typeof sticker.index === 'number' ? editSticker : addSticker}
        />
        <Button
          bgColor="lightgray"
          fontColor="black"
          text="닫기"
          onClick={onClose}
        />
      </FlexBox.Row>
    </Modal>
  );
};

export default StickerModal;
