import React from 'react';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import { DescriptionModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import TextInput from '@component/TextInput';
import {
  DescriptionModule,
  DescriptionModuleConvertData2Form,
  DescriptionModuleConvertForm2Data,
} from '../../DescriptionModuleForm.type';
import { validate } from './VideoModule.validator';
import { convertData2Form, convertForm2Data } from './VideoModule.serializer';

type VideoModuleProps = {
  type: 'VIDEO';
  youtubeId: string;
  moduleIndex: number;
  onUpdate: (index: number, item: DescriptionModuleFormType) => void;
};
const VideoModule: DescriptionModule<VideoModuleProps> = (
  props: VideoModuleProps,
) => {
  const { type, youtubeId, moduleIndex, onUpdate } = props;

  const handleUpdate = (updated: Partial<Omit<VideoModuleProps, 'onUpdate'>>) =>
    onUpdate(moduleIndex, {
      type,
      youtubeId,
      ...updated,
    });

  return (
    <FlexBox.Column gap={6}>
      <Label>{type}</Label>
      <Label>유튜브ID (https://www.youtube.com/watch?v= 뒤에 붙는 것)</Label>
      <TextInput
        value={youtubeId}
        onChange={(text) => handleUpdate({ youtubeId: text.trim() })}
      />
    </FlexBox.Column>
  );
};

VideoModule.validate = validate;
VideoModule.convertForm2Data =
  convertForm2Data as DescriptionModuleConvertForm2Data;
VideoModule.convertData2Form =
  convertData2Form as DescriptionModuleConvertData2Form;
VideoModule.moduleName = '영상';
VideoModule.defaultData = {
  type: 'VIDEO',
  youtubeId: '',
};
export default VideoModule;
