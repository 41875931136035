import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Input,
  Label,
  Menu,
  Modal,
  Table,
} from 'semantic-ui-react';
import moment from 'moment';
import { apis } from '../../../api';
import * as S from '../CollaboForm/Style';
import CollaboMenu from '../CollaboMenu';
import readXlsxFile from 'read-excel-file';
import BlacklistFromExcel from './components/BlacklistFromExcel/BlacklistFromExcel';

const menuList = [
  { text: '유저ID 기준', value: 'userId' },
  { text: 'device ID 기준', value: 'deviceId' },
  { text: '엑셀파일로 등록하기 유저ID', value: 'excel' },
];
const bannedType = 'supportersRegister';

const Blacklist = () => {
  const [blacklist, setBlacklist] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState(-1);
  const [userIds, setUserIds] = useState([]);
  const [deviceIds, setDeviceIds] = useState([]);
  const [nickname, setUserNickname] = useState('');
  const [banStartDate, setBanStartDate] = useState(
    moment().format('YYYY-MM-DDTHH:mm'),
  );
  const [banEndDate, setBanEndDate] = useState('2999-12-31T23:59');
  const [reason, setReason] = useState('');
  const [mode, setMode] = useState('ADD');
  const [activeMenu, setActiveMenu] = useState(menuList[0].value);
  const [bannedUsers, setBannedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dupUserIds, setDupUserIds] = useState([]);
  const [alreadyDupUserIds, setAlreadyDupUserIds] = useState([]);

  let userId;
  const userIdRef = React.createRef();

  let deviceId;
  const deviceIdRef = React.createRef();

  useEffect(() => {
    if (activeMenu === 'excel') {
      _getBlacklist('userId');
    } else {
      _getBlacklist(activeMenu);
    }
  }, [activeMenu]);

  const _getBlacklist = async (targetType) => {
    const { blacklist: _blacklist } = await apis.getBlacklist(
      targetType,
      bannedType,
    );
    setBlacklist(_blacklist);
  };

  const onClickMenu = useCallback((e, { value }) => {
    setActiveMenu(value);
  }, []);

  const _showModal = (item) => {
    if (item) {
      setId(item.id);
      setMode('EDIT');
      setUserNickname(item.nickname);
      setBanStartDate(moment(item.banStartDate).format('YYYY-MM-DDTHH:mm'));
      setBanEndDate(moment(item.banEndDate).format('YYYY-MM-DDTHH:mm'));
      setReason(item.reason);

      if (activeMenu === 'userId') {
        setUserIds([item.targetId]);
      } else {
        setDeviceIds([item.targetId]);
      }
    } else {
      setMode('ADD');
    }

    setModalOpen(true);
  };

  const _closeModal = () => {
    setModalOpen(false);
    setUserIds([]);
    setDeviceIds([]);
    setBanStartDate(moment().format('YYYY-MM-DDTHH:mm'));
    setBanEndDate('2999-12-31T23:59');
    setReason('');
    setMode('');
    setId(-1);
    userId = '';
    deviceId = '';
  };

  const _setUserId = (e) => (userId = e.target.value);
  const _setDeviceId = (e) => (deviceId = e.target.value);

  const _handleKeyPress = (e) => {
    const { key } = e;
    if (key !== 'Enter') return;
    if (activeMenu === 'userId') {
      _addUserId();
    } else {
      _addDeviceId();
    }
  };

  const _addUserId = () => {
    const _userIds = [...userIds];
    _userIds.push(Number(userId));
    setUserIds(_userIds);
    userIdRef.current.inputRef.current.value = '';
    userId = '';
  };

  const _deleteUserId = (e, { value }) => {
    const _userIds = [...userIds];
    _userIds.splice(value, 1);
    setUserIds(_userIds);
  };

  const _addDeviceId = () => {
    const _deviceIds = [...deviceIds];
    _deviceIds.push(deviceId);
    setDeviceIds(_deviceIds);
    deviceIdRef.current.inputRef.current.value = '';
    deviceId = '';
  };

  const _deleteDeviceId = (e, { value }) => {
    const _deviceIds = [...deviceIds];
    _deviceIds.splice(value, 1);
    setDeviceIds(_deviceIds);
  };

  const _handleRadioChange = (e, { name, value }) => {
    if (name === 'banStartDate') setBanStartDate(value);
    else if (name === 'banEndDate') setBanEndDate(value);
  };

  const _handleChange = (e) => {
    setReason(e.target.value);
  };

  const _addOrUpdate = async () => {
    let _bannedUsers = [];
    if (activeMenu === 'userId') {
      _bannedUsers = userIds.map((userId) => ({
        targetType: activeMenu,
        targetId: String(userId),
        banStartDate: moment(banStartDate),
        banEndDate: moment(banEndDate),
        reason,
        bannedType,
      }));
    } else {
      _bannedUsers = deviceIds.map((deviceId) => ({
        targetType: activeMenu,
        targetId: String(deviceId),
        banStartDate: moment(banStartDate),
        banEndDate: moment(banEndDate),
        reason,
        bannedType,
      }));
    }

    let res;
    if (mode === 'ADD') {
      res = await apis.addBlacklist({ bannedUsers: _bannedUsers });
    } else {
      res = await apis.updateBlacklist(id, _bannedUsers[0]);
    }

    if (res?.response?.data?.err) {
      return;
    }

    _getBlacklist(activeMenu);

    alert(`${mode === 'ADD' ? '추가' : '수정'} 완료되었어요`);
    _closeModal();
  };

  const _delete = async (item) => {
    let msg;
    if (activeMenu === 'userId') {
      msg = `[${item.userId}]${item.nickname}님을 블랙리스트에서 삭제하시겠습니까?`;
    } else {
      msg = `기기정보 [${item.targetId}]를 블랙리스트에서 삭제하시겠습니까?`;
    }
    if (confirm(msg)) {
      const res = await apis.deleteBlacklist(item.id);
      if (res?.response?.data && 'err' in res?.response?.data) {
        return;
      }

      alert('삭제가 완료되었어요.');

      const _blacklist = blacklist.filter((o) => o.id !== item.id);
      setBlacklist(_blacklist);
    }
  };

  const _readExcelFile = async (e) => {
    if (isLoading) return;
    setIsLoading(true);

    const data = e.target.files[0];
    const targets = await readXlsxFile(data);
    const userIds = [];
    const _dupUserIds = [];
    const _alreadyDupUserIds = [];
    const alreadyRegisteredUserIds = blacklist.map((o) => Number(o.targetId));
    const _targets = targets
      .slice(1)
      .filter((row) => !!row[0])
      .map((row) => {
        const userId = Number(row[0]);
        if (userIds.includes(userId)) {
          _dupUserIds.push(userId);
        } else {
          userIds.push(userId);
        }

        if (alreadyRegisteredUserIds.includes(userId)) {
          _alreadyDupUserIds.push(userId);
        }

        return {
          targetType: 'userId',
          targetId: String(userId),
          banStartDate: moment(row[1])
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0),
          banEndDate: moment(row[2])
            .set('hour', 23)
            .set('minute', 59)
            .set('second', 59),
          reason: row[3].trim(),
          bannedType,
        };
      });

    if (_dupUserIds.length > 0) {
      alert(
        `중복된 유저 아이디가 있습니다. 확인 후 재 업로드 부탁드립니다.\n${_dupUserIds.join(
          ', ',
        )}`,
      );
    } else {
      setBannedUsers(_targets);
    }

    setIsLoading(false);
    setDupUserIds(_dupUserIds);
    setAlreadyDupUserIds(_alreadyDupUserIds);
  };

  const _update = async () => {
    if (alreadyDupUserIds.length > 0) {
      await apis.deleteBlacklistByUserIds({ userIds: alreadyDupUserIds });
    }
    const res = await apis.addBlacklist({ bannedUsers });

    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    alert(`추가가 완료되었어요`);
    setBannedUsers([]);
    setDupUserIds([]);
    setAlreadyDupUserIds([]);
  };

  return (
    <Grid columns={'equal'} style={{ margin: 40 }}>
      <Grid.Column width={2}>
        <CollaboMenu />
      </Grid.Column>
      <Grid.Column>
        <Header as="h3">
          체험단 참가제한
          <Button size="mini" onClick={() => _showModal()}>
            추가
          </Button>
        </Header>

        <Menu pointing secondary>
          {menuList.map((menu) => (
            <Menu.Item
              key={menu.value}
              name={menu.text}
              value={menu.value}
              active={activeMenu === menu.value}
              onClick={onClickMenu}
            />
          ))}
        </Menu>

        {activeMenu === 'excel' ? (
          <BlacklistFromExcel
            bannedUsers={bannedUsers}
            alreadyDupUserIds={alreadyDupUserIds}
            dupUserIds={dupUserIds}
            readExcelFile={_readExcelFile}
            update={_update}
          />
        ) : (
          <Table basic="very" size="small" celled textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {activeMenu === 'userId' ? '유저id' : '디바이스id'}
                </Table.HeaderCell>
                <Table.HeaderCell>닉네임</Table.HeaderCell>
                <Table.HeaderCell>생성일</Table.HeaderCell>
                <Table.HeaderCell>제한시작일시</Table.HeaderCell>
                <Table.HeaderCell>제한종료일시</Table.HeaderCell>
                <Table.HeaderCell>사유</Table.HeaderCell>
                <Table.HeaderCell>수정/삭제</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {blacklist.length === 0 && (
                <Table.Row>
                  <Table.Cell colSpan={7}>참가제한 정보가 없습니다</Table.Cell>
                </Table.Row>
              )}
              {blacklist.map((item) => (
                <Table.Row
                  key={`${item.targetType}-${item.id}-${item.nickname}`}
                >
                  <Table.Cell>{item.targetId}</Table.Cell>
                  <Table.Cell>{item.nickname}</Table.Cell>
                  <Table.Cell>
                    {moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.banStartDate).format('YYYY-MM-DD HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.banEndDate).format('YYYY-MM-DD HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>{item.reason}</Table.Cell>
                  <Table.Cell>
                    <Button size="mini" onClick={() => _showModal(item)}>
                      수정
                    </Button>
                    <Button size="mini" onClick={() => _delete(item)}>
                      삭제
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}

        <Modal size="tiny" open={modalOpen} onClose={_closeModal}>
          <Modal.Header>
            블랙리스트 {mode === 'ADD' ? '추가' : '수정'}
          </Modal.Header>
          <Modal.Content>
            <Form>
              {activeMenu === 'userId' ? (
                <Form.Field>
                  <label>유저</label>
                  {mode === 'ADD' ? (
                    <>
                      <S.RowContainer>
                        <Input
                          disabled={mode === 'EDIT'}
                          ref={userIdRef}
                          name="userId"
                          onChange={_setUserId}
                          onKeyPress={_handleKeyPress}
                        />
                        <Label
                          style={{ cursor: 'pointer' }}
                          onClick={_addUserId}
                        >
                          추가
                        </Label>
                      </S.RowContainer>

                      <Table basic="very" collapsing>
                        <Table.Body>
                          {userIds.map((userId, idx) => (
                            <Table.Row key={userId}>
                              <Table.Cell>{userId}</Table.Cell>
                              <Table.Cell>
                                <Label
                                  style={{ cursor: 'pointer' }}
                                  name="delete"
                                  value={idx}
                                  onClick={_deleteUserId}
                                >
                                  삭제
                                </Label>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </>
                  ) : (
                    <div>
                      [{userIds}] {nickname}
                    </div>
                  )}
                </Form.Field>
              ) : (
                <Form.Field>
                  <label>디바이스 id</label>
                  {mode === 'ADD' ? (
                    <>
                      <S.RowContainer>
                        <Input
                          disabled={mode === 'EDIT'}
                          ref={deviceIdRef}
                          name="deviceId"
                          onChange={_setDeviceId}
                          onKeyPress={_handleKeyPress}
                        />
                        <Label
                          style={{ cursor: 'pointer' }}
                          onClick={_addDeviceId}
                        >
                          추가
                        </Label>
                      </S.RowContainer>

                      <Table basic="very" collapsing>
                        <Table.Body>
                          {deviceIds.map((deviceId, idx) => (
                            <Table.Row key={deviceId}>
                              <Table.Cell>{deviceId}</Table.Cell>
                              <Table.Cell>
                                <Label
                                  style={{ cursor: 'pointer' }}
                                  name="delete"
                                  value={idx}
                                  onClick={_deleteDeviceId}
                                >
                                  삭제
                                </Label>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </>
                  ) : (
                    <S.RowContainer>
                      <div>{deviceIds}</div>
                      <div style={{ marginLeft: 20 }}>
                        {blacklist
                          .filter((o) => deviceIds.includes(o.targetId))
                          .map((item) => (
                            <div key={item.userId}>
                              [{item.userId}] {item.nickname}
                            </div>
                          ))}
                      </div>
                    </S.RowContainer>
                  )}
                </Form.Field>
              )}

              <Form.Field>
                <label>제한 시작일</label>
                <Input
                  type="datetime-local"
                  max="9999-12-31T23:59"
                  name="banStartDate"
                  value={banStartDate}
                  onChange={_handleRadioChange}
                />
              </Form.Field>

              <Form.Field>
                <label>제한 종료일</label>
                <Input
                  type="datetime-local"
                  max="9999-12-31T23:59"
                  name="banEndDate"
                  value={banEndDate}
                  onChange={_handleRadioChange}
                />
              </Form.Field>

              <Form.Field>
                <label>제한 사유</label>
                <Input name="reason" value={reason} onChange={_handleChange} />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content={mode === 'ADD' ? '추가' : '수정'}
              onClick={_addOrUpdate}
            />
            <Button color="black" content="닫기" onClick={_closeModal} />
          </Modal.Actions>
        </Modal>
      </Grid.Column>
    </Grid>
  );
};

export default Blacklist;
