import { useState } from 'react';

export const useEditCheck = <T extends object>({
  editCheckValue,
  label,
}: Props<T>) => {
  const original = editCheckValue;
  const [edited, setEdited] = useState<{
    [key in keyof T]: boolean;
  }>(
    Object.keys(original).reduce(
      (acc, key) => {
        acc[key as keyof T] = false;
        return acc;
      },
      {} as { [key in keyof T]: boolean },
    ),
  );

  const getBorder = (key: keyof T) => {
    return edited[key]
      ? '1px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133))'
      : `3px solid rgb(255, 128, 0)`;
  };

  const triggerEdited = (key: keyof T) => {
    setEdited({
      ...edited,
      [key]: true,
    });
  };

  const checkRecommendEditedAndAlert = () => {
    const allEditedInfo = Object.keys(edited);
    for (const key of allEditedInfo) {
      if (!edited[key as keyof T]) {
        if (
          !window.confirm(
            `${
              label[key as keyof T]
            }을(를) 변경하지 않았는데 이대로 저장하시겠습니까?`,
          )
        ) {
          return false;
        }
      }
    }
    return true;
  };

  return { getBorder, triggerEdited, checkRecommendEditedAndAlert };
};

type Props<T> = {
  editCheckValue: T;
  label: Record<keyof T, string>;
};
