import {
  DescriptionGoalModuleFormType,
  DescriptionModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const validate = (
  descriptionModule: DescriptionModuleFormType[],
): { isValid: true } | { isValid: false; message: string } => {
  const target = descriptionModule.find(({ type }) => type === 'GOAL');
  if (!target) return { isValid: true };

  const { mainHeader, subHeader, ids } =
    target as DescriptionGoalModuleFormType;

  if (
    typeof mainHeader !== 'string' ||
    typeof subHeader !== 'string' ||
    !Array.isArray(ids)
  ) {
    return {
      isValid: false,
      message: '[상세모듈] GOAL 모듈을 확인해주세요',
    };
  }

  if (!mainHeader.length)
    return {
      isValid: false,
      message: '[상세모듈] GOAL 메인헤더 값을 채워주세요',
    };

  if (!ids.length)
    return {
      isValid: false,
      message: '[상세모듈] GOAL 목표를 선택해 주세요',
    };
  return { isValid: true };
};
