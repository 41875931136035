import { QuestionTemplate } from '@types';
import React from 'react';
import DraggableList from 'react-draggable-list';
import { Button, ButtonProps, Divider, Modal } from 'semantic-ui-react';

type OrderTemplateModalProps = {
  template: any;
  deleteTemplate: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    { value }: ButtonProps,
  ) => void;
};

const DraggableItem = ({
  template,
  deleteTemplate,
}: OrderTemplateModalProps) => {
  const { onMouseDown, onTouchStart } = template.dragHandleProps;
  return (
    <div
      style={{
        width: '100%',
        height: 50,
      }}
      key={template.id}
      onTouchStart={onTouchStart}
      onMouseDown={onMouseDown}
    >
      {template.item.title}
      <Button
        size="mini"
        floated="right"
        content="삭제"
        value={template.item}
        onClick={deleteTemplate}
      />
      <Divider />
    </div>
  );
};

function OrderTemplateModal({
  orderTemplateModalOpen,
  closeOrderTemplateModal,
  questionTemplateList,
  handleUpdate,
  deleteTemplate,
}: {
  orderTemplateModalOpen: boolean;
  closeOrderTemplateModal: () => void;
  questionTemplateList: QuestionTemplate[];
  handleUpdate: (newList: any) => void;
  deleteTemplate: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    { value }: ButtonProps,
  ) => void;
}) {
  return (
    <Modal
      size="small"
      open={orderTemplateModalOpen}
      onClose={closeOrderTemplateModal}
    >
      <Modal.Header>템플릿 순서 변경/삭제</Modal.Header>
      <Modal.Content>
        <DraggableList
          itemKey="id"
          list={questionTemplateList}
          onMoveEnd={handleUpdate}
          // @ts-ignore
          template={(template: any) => {
            return (
              <DraggableItem
                template={template}
                deleteTemplate={deleteTemplate}
              />
            );
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          content="닫기"
          onClick={closeOrderTemplateModal}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default OrderTemplateModal;
