import { useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { AchievementInfoItem } from '../../NewChallengeForm.types';

export const useAchievementInfoList = () =>
  useChallengeFormStore((s) => s.challenge.achievementInfoList);

export const useSpecialPrizeType = () =>
  useChallengeFormStore((s) => s.challenge.specialPrize.prizeType);

export const useChallengeDate = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      startDate: s.challenge.startDate,
      endDate: s.challenge.endDate,
    })),
  );

export const useUpdateAchievementInfoList = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const update = useCallback(
    (achievementInfoList: AchievementInfoItem[]) => {
      updateChallengeForm({ achievementInfoList });
    },
    [updateChallengeForm],
  );

  return { update };
};
