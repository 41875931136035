import React from 'react';
import { Table } from 'semantic-ui-react';
import setCurrencyFormat from '../../../global/setCurrencyFormat';

const LoseUserRegister = ({ retentionStatistics }) => {
  const retentionUnderEightyFive =
    retentionStatistics.retentionUnderEightyFive.M;
  const next = parseInt(retentionUnderEightyFive.next.S, 10);
  const after = parseInt(retentionUnderEightyFive.after.S, 10);
  const no = parseInt(retentionUnderEightyFive.no.S, 10);
  return (
    <Table basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>구분</Table.HeaderCell>
          <Table.HeaderCell>유저수</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>돈 잃었을때 다음구간에 재신청</Table.Cell>
          <Table.Cell>{setCurrencyFormat(next)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>돈 잃었을때 쉬었다가 돌아옴</Table.Cell>
          <Table.Cell>{setCurrencyFormat(after)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>돈 잃은 이후로 신청안함</Table.Cell>
          <Table.Cell>{setCurrencyFormat(no)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>총합</Table.Cell>
          <Table.Cell>{setCurrencyFormat(next + after + no)}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default LoseUserRegister;
