import DateTimeInput from '@component/DateTimeInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { format } from 'date-fns';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './ResultDateForm.serializer';
import {
  useResultDateStore,
  useUpdateResultDate,
} from './ResultDateForm.store';
import { validate } from './ResultDateForm.validator';
import { useEditCheck } from '../../hooks/useEditCheck';

export const ResultDateForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'resultDate'>,
  Pick<ChallengeFormType, 'resultDate'>,
  Pick<ReqCreateOrUpdateChallenge, 'resultDate'>
> = () => {
  const { resultDate, endDate } = useResultDateStore();
  const { updateResultDate } = useUpdateResultDate();

  const validated = validate({ resultDate, endDate });

  const { triggerEdited, getBorder } = useEditCheck();

  const handleChange = (value: string) => {
    if (!value.length) return;

    triggerEdited('resultDate');
    updateResultDate(value);
  };

  return (
    <DateTimeInput
      label="챌린지 결과발표일"
      dateOnly
      value={resultDate || format(new Date(), 'yyyy-MM-dd')}
      onChange={handleChange}
      errorMsg={validated.isValid ? '' : validated.message}
      style={{ border: getBorder('resultDate') }}
    />
  );
};

ResultDateForm.validate = validate;
ResultDateForm.convertData2Form = convertData2Form;
ResultDateForm.convertForm2Data = convertForm2Data;
