import React, { FC } from 'react';
import { Button, Grid, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import BenefitMenu from '../../BenefitMenu';
import * as S from './PedometerGiftiShopItemList.styles';
import { BenefitQueries } from '../../../../queries';
import GiftiItem from './components/GiftiItem/GiftiItem';

const PedometerGiftiShopItemList: FC = () => {
  const { data: items = [], isLoading } = BenefitQueries.useGiftiShopItemList({
    refetchOnMount: true,
  });

  return (
    <div style={{ margin: 40 }}>
      <Grid columns="equal">
        <Grid.Column width={2}>
          <BenefitMenu />
        </Grid.Column>
        <Grid.Column>
          <Grid.Row
            style={{
              marginBottom: 20,
              justifyContent: 'space-between',
              flexDirection: 'row',
              display: 'flex',
            }}
          >
            <h1>기프티샵 아이템 목록</h1>
            <Button
              as={Link}
              size="large"
              color="green"
              content="추가하기"
              to="/pedometer-gifti-shop/item/create"
            />
          </Grid.Row>
          <S.Item isHeader>
            <S.Row>상품 ID</S.Row>
            <S.Row>브랜드</S.Row>
            <S.Row>상품명</S.Row>
            <S.Row>가격</S.Row>
            <S.Row>이미지</S.Row>
            <S.Row>공개</S.Row>
            <S.Row>수정</S.Row>
          </S.Item>
          {isLoading && <Loader />}
          {!isLoading && !items?.length && (
            <S.Row style={{ padding: 40, color: '#aaaaaa', fontSize: 40 }}>
              <div>기프티샵 아이템이 없어요 🥲</div>
            </S.Row>
          )}
          {!isLoading && items?.map((v) => <GiftiItem key={v.id} {...v} />)}
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default PedometerGiftiShopItemList;
