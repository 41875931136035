import { swap } from '@utils/js.utils';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { AchievementMissionItem } from '../../NewChallengeForm.types';

export const useAchievementMission = () =>
  useChallengeFormStore((s) => s.challenge.achievementMission);

export const useAchievementMissionActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const add = (images: string[]) => {
    updateChallengeForm((prev) => ({
      ...prev,
      achievementMission: [
        ...prev.achievementMission,
        ...images.map((image) => {
          const item: AchievementMissionItem = {
            type: 'good',
            title: '',
            text: '',
            image,
            achievementIndexList: [],
          };
          return item;
        }),
      ],
    }));
  };

  const remove = (image: string) => {
    updateChallengeForm((prev) => ({
      ...prev,
      achievementMission: prev.achievementMission.filter(
        (item) => item.image !== image,
      ),
    }));
  };

  const update = (image: string, updated: Partial<AchievementMissionItem>) => {
    updateChallengeForm((prev) => ({
      ...prev,
      achievementMission: prev.achievementMission.map((item) => {
        if (item.image !== image) return item;
        return {
          ...item,
          ...updated,
        };
      }),
    }));
  };

  const up = (image: string) => {
    updateChallengeForm((prev) => {
      const index = prev.achievementMission.findIndex(
        (item) => item.image === image,
      );

      if (index < 1) return prev;

      return {
        ...prev,
        achievementMission: swap(prev.achievementMission, index, index - 1),
      };
    });
  };

  const down = (image: string) => {
    updateChallengeForm((prev) => {
      const index = prev.achievementMission.findIndex(
        (item) => item.image === image,
      );

      if (index === prev.achievementMission.length - 1) return prev;

      return {
        ...prev,
        achievementMission: swap(prev.achievementMission, index, index + 1),
      };
    });
  };

  return { add, remove, update, up, down };
};
