import React, { FC, useCallback } from 'react';
import Label from '@component/Label';
import FlexBox from '@component/FlexBox/FlexBox';
import Button from '@component/Button';
import TextInput from '@component/TextInput';
import { ModuleContainer } from '../../../../DescriptionModuleForm.style';

type Props = {
  checkList: string[];
  onChange: (data: string[]) => void;
};
const CheckListForm: FC<Props> = (props) => {
  const { checkList, onChange } = props;

  const handleAddItem = () => {
    onChange([...checkList, '']);
  };

  const handleRemoveItem = (index: number) => {
    onChange(checkList.filter((_, i) => i !== index));
  };

  const handleEditText = useCallback(
    (index: number, text: string) => {
      onChange(checkList.map((v, i) => (i === index ? text : v)));
    },
    [checkList, onChange],
  );

  return (
    <ModuleContainer>
      <Label size="large" style={{ marginTop: 20, marginBottom: 6 }}>
        인증 체크리스트
      </Label>
      <FlexBox.Column gap={8}>
        {checkList.map((item, index) => (
          <FlexBox.Row key={`${index}`} gap={8}>
            <TextInput
              containerStyle={{ flex: 1 }}
              value={item}
              onChange={(text) => handleEditText(index, text)}
            />
            <Button
              text="삭제"
              style={{ width: 72 }}
              onClick={() => handleRemoveItem(index)}
            />
          </FlexBox.Row>
        ))}
      </FlexBox.Column>

      <Button
        text="추가"
        size="large"
        style={{ marginTop: 12 }}
        onClick={handleAddItem}
      />
    </ModuleContainer>
  );
};

export default CheckListForm;
