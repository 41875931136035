import React, { useState } from 'react';
import SettingMenu from '../SettingMenu';
import readXlsxFile from 'read-excel-file';
import { Button, Dimmer, Form, Grid, Loader, Table } from 'semantic-ui-react';
import { apis } from '../../../api';

const EventPoint = () => {
  const [targets, setTargets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const readExcelFile = async (e) => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      const data = e.target.files[0];
      const targets = await readXlsxFile(data);
      const userIds = [];
      const dupUserIds = [];
      const _targets = targets
        .slice(1)
        .filter((row) => !!row[0])
        .map((row) => {
          const userId = Number(row[0]);
          if (userIds.includes(userId)) {
            dupUserIds.push(userId);
          } else {
            userIds.push(userId);
          }

          return {
            userId,
            pointType: row[1],
            amount: Number(row[2]),
            description: row[3],
          };
        });

      if (dupUserIds.length > 0) {
        alert(
          `중복된 유저 아이디가 있습니다. 확인 후 재 업로드 부탁드립니다.\n${dupUserIds.join(
            ', ',
          )}`,
        );
      } else {
        setTargets(_targets);
      }
    } catch (error) {
      alert('파일 확장자를 확인해주세요. (.xlsx)');
    } finally {
      setIsLoading(false);
    }
  };

  const _giveEventPrize = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const res = await apis.giveEventPoint({ targets });
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    alert(`총 ${targets.length}명에게 포인트 지급이 완료되었어요.`);

    setTargets([]);
    setIsLoading(false);
  };

  return (
    <>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <Grid columns={'equal'} style={{ margin: 40 }}>
        <Grid.Column width={2}>
          <SettingMenu />
        </Grid.Column>
        <Grid.Column>
          <h3>
            포인트 지급
            <a
              href="https://d1cu54ww3s1tp1.cloudfront.net/development/admin/230314_%E1%84%91%E1%85%A9%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%90%E1%85%B3+%E1%84%8C%E1%85%B5%E1%84%80%E1%85%B3%E1%86%B8+%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.xlsx"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button basic color="green" size="mini">
                포인트 지급 양식 다운로드
              </Button>
            </a>
          </h3>
          <Form>
            <Form.Field>
              <label>엑셀 파일을 업로드하세요.</label>
              <input type="file" id="input" onChange={readExcelFile} />
            </Form.Field>
          </Form>

          {targets.length > 0 && (
            <div style={{ marginTop: 12 }}>
              <h5>총 {targets.length}건</h5>
              <Button onClick={_giveEventPrize} content={'지급하기'} />

              <Table basic="very" size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>No</Table.HeaderCell>
                    <Table.HeaderCell>유저id</Table.HeaderCell>
                    <Table.HeaderCell>포인트 종류</Table.HeaderCell>
                    <Table.HeaderCell>지급액</Table.HeaderCell>
                    <Table.HeaderCell>지급사유</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {targets.map((target, idx) => (
                    <Table.Row key={target.userId}>
                      <Table.Cell>{idx + 1}</Table.Cell>
                      <Table.Cell>{target.userId}</Table.Cell>
                      <Table.Cell>{target.pointType}</Table.Cell>
                      <Table.Cell>{target.amount}</Table.Cell>
                      <Table.Cell>{target.description}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          )}
        </Grid.Column>
      </Grid>
    </>
  );
};

export default EventPoint;
