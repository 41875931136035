import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'authenticationChecklist'>;

export const validate: FormValidator<Params> = ({
  authenticationChecklist,
}) => {
  if (!Array.isArray(authenticationChecklist))
    return { isValid: false, message: '인증 주의사항을 확인해주세요' };

  return { isValid: true };
};
