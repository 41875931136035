import { DescriptionCollaboSpecialRewardModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

const SPECIAL_REWARD_DEFAULT_NOTICE = [
  '회원가입 후 무료체험권을 등록하여 무료로 사용 가능',
  '무료 체험 신청 이력이 있으신 분은 본 챌린지 참가 불가',
];

export const DEFAULT_DATA = {
  type: 'COLLABO-SPECIAL-REWARD',
  data: {
    from: null,
    to: '',
    rewardText: '',
    noticeList: SPECIAL_REWARD_DEFAULT_NOTICE,
  },
} as unknown as DescriptionCollaboSpecialRewardModuleFormType;
