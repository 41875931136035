import React, { FC, useCallback } from 'react';
import { uploadSingleFileToS3 } from '@utils/uploadImage';
import { OliveYoungReviewModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import Label from '@component/Label';
import FlexBox from '@component/FlexBox/FlexBox';
import NumberInput from '@component/NumberInput';
import { ModuleContainer } from '../../../../DescriptionModuleForm.style';

type ProductInfoType = Pick<
  OliveYoungReviewModuleFormType,
  'originalPrice' | 'price' | 'productThumbnailImageUrl'
>;
type Props = {
  data: ProductInfoType;
  onChange: (data: ProductInfoType) => void;
};

const ProductInfoForm: FC<Props> = ({ data, onChange }) => {
  const handleChangePrice = useCallback(
    (value: number | null) => {
      onChange({
        ...data,
        price: Number(value),
      });
    },
    [data, onChange],
  );

  const handleChangeOriginalPrice = useCallback(
    (value: number | null) => {
      onChange({
        ...data,
        originalPrice: Number(value),
      });
    },
    [data, onChange],
  );

  const handleChangeImage: React.ChangeEventHandler<HTMLInputElement> = async (
    e,
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const url = await uploadSingleFileToS3(file);
    onChange({ ...data, productThumbnailImageUrl: url });
  };

  return (
    <ModuleContainer style={{ gap: 8 }}>
      <Label size="large" style={{ marginTop: 20 }}>
        상품 정보
      </Label>
      <FlexBox.Row gap={8} alignItems="center">
        <Label style={{ width: 40 }}>가격</Label>
        <NumberInput value={data.price} onChange={handleChangePrice} />
      </FlexBox.Row>
      <FlexBox.Row gap={8} alignItems="center">
        <Label style={{ width: 40 }}>정가</Label>
        <NumberInput
          value={data.originalPrice}
          onChange={handleChangeOriginalPrice}
          min={data.price || 0}
        />
      </FlexBox.Row>

      <FlexBox.Row gap={8} alignItems="center" style={{ margin: '10px 0px' }}>
        <Label style={{ width: 120 }}>상품 이미지</Label>
        <input type="file" onChange={handleChangeImage} />
      </FlexBox.Row>
      {!!data.productThumbnailImageUrl && (
        <img
          src={data.productThumbnailImageUrl}
          style={{ width: 120, height: 120 }}
          alt="제품 섬네일 이미지"
        />
      )}
    </ModuleContainer>
  );
};

export default ProductInfoForm;
