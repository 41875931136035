import React, { FC, useCallback } from 'react';
import Label from '@component/Label';
import TextArea from '@component/TextArea';
import { ModuleContainer } from '../../../../DescriptionModuleForm.style';

type Props = {
  rewardText: string;
  onChange: (rewardText: string) => void;
};
const RewardTextForm: FC<Props> = (props) => {
  const { rewardText, onChange } = props;

  const handleText = useCallback((text: string) => onChange(text), [onChange]);

  return (
    <ModuleContainer>
      <Label style={{ marginTop: 20 }}>특별리워드 텍스트</Label>
      <TextArea value={rewardText} onChange={handleText} />
    </ModuleContainer>
  );
};

export default RewardTextForm;
