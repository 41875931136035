import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { QnAQuestionItem } from '../../NewChallengeForm.types';

export const useQna = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      qna: s.challenge.qna,
      goalId: s.challenge.goalId,
      startDate: s.challenge.startDate,
      endDate: s.challenge.endDate,
    })),
  );

export const useQnaAction = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setQna = (questions: QnAQuestionItem[]) => {
    updateChallengeForm((prev) => ({
      ...prev,
      qna: { questions },
    }));
  };
  return { setQna };
};
