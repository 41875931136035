import React from 'react';

const Spacing = ({
  vertical,
  horizontal,
}: {
  vertical?: number;
  horizontal?: number;
}) => {
  return <div style={{ margin: `${vertical || 0}px ${horizontal || 0}px` }} />;
};

export default Spacing;
