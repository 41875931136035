import Label from '@component/Label';
import { OliveYoungOnlyBuyModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import React, { FC, useCallback } from 'react';
import FlexBox from '@component/FlexBox/FlexBox';
import CheckBox from '@component/CheckBox';
import TextInput from '@component/TextInput';
import { ModuleContainer } from '../../../../DescriptionModuleForm.style';

type Props = {
  data: OliveYoungOnlyBuyModuleFormType['purchaseRequiredTextList'];
  onChange: (
    data: OliveYoungOnlyBuyModuleFormType['purchaseRequiredTextList'],
  ) => void;
};
const PurchaseRequiredTextListForm: FC<Props> = (props) => {
  const { data, onChange } = props;

  const handleClick = (index: number) => {
    onChange(
      data.map((item, i) =>
        index === i ? { ...item, active: !item.active } : item,
      ),
    );
  };

  const handleToggleType = (index: number) => {
    onChange(
      data.map((item, i) =>
        index === i
          ? {
              ...item,
              type: item.type === 'highlight' ? 'normal' : 'highlight',
            }
          : item,
      ),
    );
  };

  const handleChange = useCallback(
    (index: number, text: string) => {
      onChange(data.map((d, i) => (i !== index ? d : { ...d, text })));
    },
    [data, onChange],
  );

  return (
    <ModuleContainer>
      <Label size="large" style={{ marginTop: 20 }}>
        [상품 구매는 필수!] 영역
      </Label>
      {data.map((item, index) => (
        <React.Fragment key={index}>
          <FlexBox.Row
            style={{
              padding: '8px 0',
              gap: 12,
              borderBottom:
                index < data.length - 1 ? '1px solid black' : undefined,
            }}
          >
            <CheckBox
              checked={item.active}
              onClick={() => handleClick(index)}
            />
            <FlexBox.Column alignItems="center" style={{ padding: '0px 12px' }}>
              <Label>강조</Label>
              <CheckBox
                checked={item.type === 'highlight'}
                onClick={() => handleToggleType(index)}
              />
            </FlexBox.Column>
            <TextInput
              containerStyle={{ flex: 1 }}
              value={item.text}
              onChange={(text) => handleChange(index, text)}
            />
          </FlexBox.Row>
        </React.Fragment>
      ))}
    </ModuleContainer>
  );
};

export default PurchaseRequiredTextListForm;
