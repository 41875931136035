import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'title'>;

export const validate: FormValidator<Params> = ({ title }) => {
  if (!title?.length) {
    return { isValid: false, message: '챌린지명을 설정해주세요' };
  }

  return { isValid: true };
};
