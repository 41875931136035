import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import {
  PurchaseInfoCustomFieldFormType,
  PurchaseInfoListItem,
} from '../../NewChallengeForm.types';
import { DEFAULT_PURCHASE_ITEM } from './PurchaseInfoForm.constant';

export const usePurchaseInfo = () =>
  useChallengeFormStore((s) => s.challenge.purchaseInfo);

export const useValidationData = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      purchaseInfo: s.challenge.purchaseInfo,
      instagramInfoList: s.challenge.instagramInfoList,
      naverBlogInfoList: s.challenge.naverBlogInfoList,
      achievementCount: s.challenge.achievementCount,
    })),
  );

export const useUpdatePurchaseInfoListActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const add = () =>
    updateChallengeForm((prev) => ({
      ...prev,
      purchaseInfo: {
        ...prev.purchaseInfo!,
        items: [...prev.purchaseInfo!.items, DEFAULT_PURCHASE_ITEM],
      },
    }));

  const update = (index: number, data: PurchaseInfoListItem) =>
    updateChallengeForm((prev) => ({
      ...prev,
      purchaseInfo: {
        ...prev.purchaseInfo!,
        items: prev.purchaseInfo!.items.map((item, i) =>
          i === index ? data : item,
        ),
      },
    }));

  const remove = (index: number) =>
    updateChallengeForm((prev) => ({
      ...prev,
      purchaseInfo: {
        ...prev.purchaseInfo!,
        items: prev.purchaseInfo!.items.filter((_, i) => i !== index),
      },
    }));

  return { update, add, remove, updateChallengeForm };
};

export const useUpdateBrandInfoActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const update = (
    data: Omit<PurchaseInfoCustomFieldFormType['purchaseInfo'], 'items'>,
  ) =>
    updateChallengeForm((prev) => ({
      ...prev,
      purchaseInfo: {
        ...prev.purchaseInfo!,
        ...data,
      },
    }));

  return { update };
};

export const useUpdatePurchaseInfoSetting = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const init = () =>
    updateChallengeForm((prev) => ({
      ...prev,
      purchaseInfo: {
        platform: 'oliveYoung',
        platformName: '올리브영',
        connectionRequired: true,
        items: [],
      },
    }));

  const reset = () =>
    updateChallengeForm((prev) => ({
      ...prev,
      purchaseInfo: null,
    }));

  return { init, reset };
};
