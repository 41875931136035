import { isNull } from '@utils/js.utils';
import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'thirdTermUrl' | 'companyId'>;

export const validate: FormValidator<Params> = ({
  thirdTermUrl,
  companyId,
}) => {
  if (isNull(companyId) && thirdTermUrl.length)
    return {
      isValid: false,
      message: '제3자 이용약관이 있는 경우 제휴사를 설정해야합니다.',
    };

  return { isValid: true };
};
