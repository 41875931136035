export const reviewResult = {
  red: 'RED',
  pass: 'PASS',
  before: 'BEFORE',
  fail: 'FAIL',
  comment: 'COMMENT',
  ban: 'BAN',
};

export const reviewOptions = [
  { key: 1, text: reviewResult.red, value: reviewResult.red },
  { key: 2, text: '성공처리', value: reviewResult.comment },
  { key: 3, text: '성공처리', value: reviewResult.pass },
];

export const chatReviewOptions = [
  { key: 1, text: reviewResult.ban, value: reviewResult.ban },
  { key: 2, text: reviewResult.pass, value: reviewResult.pass },
];

export const reportReasonObj = {
  1: '[기존]인증샷',
  2: '[기존]이전 인증샷과 함께',
  3: '[신규]인증샷',
  4: '[신규]악성 유저',
};

export const reviewMessageOptions = [
  { key: 0, text: '정상적으로 촬영되지 않음', value: 0 },
  { key: 1, text: '인증방법이 다름', value: 1 },
  { key: 2, text: '미인증 처리', value: 2 },
  { key: 3, text: '레드카드', value: 3 },
  { key: 4, text: '경고 댓글 공식 템플릿', value: 4 },
];
