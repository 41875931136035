import React from 'react';
import { Table, Header, Image, Grid, Button } from 'semantic-ui-react';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';
import moment from 'moment';
import ChallengeDetailInfo from './ChallengeDetailInfo';
import {
  ChallengeAchievementInfoListCustomField,
  ChallengeAchievementMissionCustomField,
  ChallengeDetailInfoForAdmin,
  ChallengeSpecialPrizeCustomField,
} from '@types';
import Column from '@component/FlexBox/Column';
import Row from '@component/FlexBox/Row';

export const ChallengeInfo = ({
  challenge,
  tagList,
  thumbnailImageUrlF,
  authenticationGoodExamples,
  authenticationBadExamples,
}: {
  challenge: ChallengeDetailInfoForAdmin;
  tagList: JSX.Element[];
  thumbnailImageUrlF: string;
  authenticationGoodExamples: JSX.Element[];
  authenticationBadExamples: JSX.Element[];
}) => {
  const faqField = challenge.customFields.find((field) => field.name === 'faq');
  const specialPrize = (
    challenge.customFields.find(
      (field) => field.name === 'specialPrize',
    ) as ChallengeSpecialPrizeCustomField
  )?.values?.amount;
  const achievementMission = (
    challenge.customFields.find(
      (c) => c.name === 'achievementMission',
    ) as ChallengeAchievementMissionCustomField
  )?.values;
  const achievementInfoList = (
    challenge.customFields.find(
      (field) => field.name === 'achievementInfoList',
    ) as ChallengeAchievementInfoListCustomField
  )?.values;

  return (
    <Grid.Column width={16}>
      <Header as="h3" textAlign="center">
        챌린지 정보
      </Header>
      <Table basic="very" celled collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>챌린지명</Table.Cell>
            <Table.Cell>{challenge.title}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>유저 id</Table.Cell>
            <Table.Cell>{challenge.userId}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>관리자 유저 id 목록</Table.Cell>
            <Table.Cell>{challenge.adminUserIds.join(', ')}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>썸네일이미지</Table.Cell>
            <Table.Cell>
              <Image src={thumbnailImageUrlF} size="small" />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>목표</Table.Cell>
            <Table.Cell>
              <a
                href={`/goals/${challenge.goal.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                [{challenge.goal.id}]{challenge.goal.title}
              </a>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>라벨</Table.Cell>
            <Table.Cell>
              {challenge.labels.map((label, idx) => (
                <Button style={{ marginTop: 5 }} active key={idx} size="tiny">
                  [{label.id}] {label.name}
                </Button>
              ))}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>태그</Table.Cell>
            <Table.Cell>{tagList}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>챌린지 설명 모듈</Table.Cell>
            <Table.Cell>
              {challenge.descriptionModule.map((object, idx) => (
                <ChallengeDetailInfo key={idx} object={object} />
              ))}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>챌린지 신청 후 노출 모듈</Table.Cell>
            <Table.Cell>
              {challenge.descriptionExtra &&
                challenge.descriptionExtra.map((object, idx) => (
                  <ChallengeDetailInfo key={idx} object={object} />
                ))}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증방법</Table.Cell>
            <Table.Cell>
              {challenge.authenticationMethod.split('\n').map((line, idx) => (
                <span key={idx}>
                  {line}
                  <br />
                </span>
              ))}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증 미션</Table.Cell>
            <Table.Cell>
              <Column gap={20}>
                {achievementMission?.map((mission, idx) => (
                  <Column key={idx} gap={10}>
                    <b>
                      {idx + 1}회자 인증 ({mission.title})
                    </b>
                    {mission.image && (
                      <img src={mission.image} alt="achievement" width={200} />
                    )}
                  </Column>
                ))}
              </Column>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증 좋은 예</Table.Cell>
            <Table.Cell>{authenticationGoodExamples}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증 안좋은 예</Table.Cell>
            <Table.Cell>{authenticationBadExamples}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증 주의사항</Table.Cell>
            <Table.Cell>
              {challenge.authenticationChecklist.map((line, idx) => {
                return (
                  <span key={idx}>
                    {idx > 0 && <br />}
                    {line.split('\n').map((l, i) => {
                      return (
                        <span key={i}>
                          {l}
                          <br />
                        </span>
                      );
                    })}
                  </span>
                );
              })}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>최대참가인원</Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(challenge.maxRegisterCount)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>특별 상금</Table.Cell>
            <Table.Cell>{specialPrize}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>모집시작일</Table.Cell>
            <Table.Cell>
              {moment(challenge.registerStartDate).format(
                'YYYY-MM-DD HH:mm:ss',
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>모집종료일</Table.Cell>
            <Table.Cell>
              {moment(challenge.registerEndDate).format('YYYY-MM-DD HH:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>시작일</Table.Cell>
            <Table.Cell>
              {moment(challenge.startDate).format('YYYY-MM-DD HH:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>종료일</Table.Cell>
            <Table.Cell>
              {moment(challenge.endDate).format('YYYY-MM-DD HH:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>결과발표일</Table.Cell>
            <Table.Cell>
              {moment(challenge.resultDate).format('YYYY-MM-DD HH:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증카드</Table.Cell>
            <Table.Cell>
              <Column gap={12}>
                {achievementInfoList?.map((info, idx) => (
                  <Row key={idx} gap={8}>
                    <b>{idx + 1}회자</b>
                    <div>
                      {info.dueStartDateTime}~{info.dueEndDateTime}
                    </div>
                  </Row>
                ))}
              </Column>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>유저필터</Table.Cell>
            <Table.Cell>
              {challenge.userFilterId ? (
                <>
                  {challenge.userFilter?.name} (id:{challenge.userFilterId})
                </>
              ) : (
                '-'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>FAQ 모듈</Table.Cell>
            <Table.Cell>{faqField ? (faqField as any).values : '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>제3자 이용약관</Table.Cell>
            <Table.Cell>{challenge.thirdTermUrl}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>경험치</Table.Cell>
            <Table.Cell>{challenge.exp}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>업데이트일</Table.Cell>
            <Table.Cell>
              {moment(challenge.updatedAt)
                .add(9, 'h')
                .format('YYYY-MM-DD HH:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>생성일</Table.Cell>
            <Table.Cell>
              {moment(challenge.createdAt)
                .add(9, 'h')
                .format('YYYY-MM-DD HH:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>제휴 회사 정보</Table.Cell>
            <Table.Cell>
              {challenge.companyId
                ? `[${challenge.company?.id}] ${challenge.company?.name}`
                : '-'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>지원금액범위</Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(challenge.minRegisterDeposit)} ~{' '}
              {setCurrencyFormat(challenge.maxRegisterDeposit)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>삭제여부</Table.Cell>
            {challenge.isDeleted && <Table.Cell>삭제됨</Table.Cell>}
            {!challenge.isDeleted && <Table.Cell>미삭제</Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>공개여부</Table.Cell>
            {challenge.isPublic && <Table.Cell>공개</Table.Cell>}
            {!challenge.isPublic && <Table.Cell>비공개</Table.Cell>}
          </Table.Row>
        </Table.Body>
      </Table>
    </Grid.Column>
  );
};

export default ChallengeInfo;
