import ErrorMsg from '@component/ErrorMsg';
import React, {
  ChangeEventHandler,
  WheelEventHandler,
  forwardRef,
} from 'react';
import * as S from './NumberInput.style';

type NumberInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'type' | 'value'
> & {
  value?: number | null;
  onChange?: (value: number | null) => void;
  label?: string;
  placeholderTextColor?: React.CSSProperties['color'];
  containerStyle?: React.CSSProperties;
  errorMsg?: string;
};

export type NumberInputRef = HTMLInputElement;

const NumberInput = forwardRef<NumberInputRef, NumberInputProps>(
  (props, ref) => {
    const {
      label,
      value,
      placeholderTextColor,
      containerStyle,
      errorMsg,
      onChange,
      ...restProps
    } = props;

    const handleNumberChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange?.(!e.target.value.length ? null : Number(e.target.value));
    };

    const handleOnWheel: WheelEventHandler<HTMLInputElement> = (e) => {
      e.preventDefault();
      (e.target as HTMLElement).blur();
    };

    return (
      <S.Container style={containerStyle}>
        {label ? <S.Label>{label}</S.Label> : <></>}
        <S.Input
          ref={ref}
          type="number"
          value={typeof value === 'number' ? value : ''}
          placeholderTextColor={placeholderTextColor}
          onChange={handleNumberChange}
          onWheel={handleOnWheel}
          {...restProps}
        />
        <ErrorMsg text={errorMsg} />
      </S.Container>
    );
  },
);

export default NumberInput;
