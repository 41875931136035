import React from 'react';
import { Table, Header, Label } from 'semantic-ui-react';

export const CartInfo = ({ carts, deleteCart }) => {
  return (
    <React.Fragment>
      <Header as="h5">장바구니 내역</Header>
      <Table basic={'very'}>
        <Table.Header>
          <Table.Row>
            {[
              '챌린지id',
              '챌린지명',
              '목표id',
              '목표카테고리id',
              '공식/개설',
              '선택여부',
              '함께참가불가능한챌린지',
              '삭제',
            ].map((title) => (
              <Table.HeaderCell key={title}>{title}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {carts.map((cart) => {
            return (
              <Table.Row key={cart.id}>
                <Table.Cell>{cart.challengeId}</Table.Cell>
                <Table.Cell>{cart.challengeTitle}</Table.Cell>
                <Table.Cell>{cart.goalId}</Table.Cell>
                <Table.Cell>{cart.goalCategoryId}</Table.Cell>
                <Table.Cell>{cart.userId === 1 ? '공식' : '비공식'}</Table.Cell>
                <Table.Cell>{cart.isSelected ? '선택' : '미선택'}</Table.Cell>
                <Table.Cell>
                  {cart.restrictChallenges.map((item) => {
                    return (
                      <span key={item.id}>
                        {item.id} / {item.title}
                        <br />
                      </span>
                    );
                  })}
                </Table.Cell>
                <Table.Cell>
                  <Label
                    size={'mini'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => deleteCart(cart.id)}
                  >
                    삭제
                  </Label>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default CartInfo;
