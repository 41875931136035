import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useMinRegisterDeposit = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      minRegisterDeposit: s.challenge.minRegisterDeposit,
      maxRegisterDeposit: s.challenge.maxRegisterDeposit,
    })),
  );
};

export const useMinRegisterDepositActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setMinRegisterDeposit = (minRegisterDeposit: number) =>
    updateChallengeForm({ minRegisterDeposit });

  return { setMinRegisterDeposit };
};
