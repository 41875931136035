import {
  UserAllInfoForAdmin,
  UserMiniInfo,
  UserRestrictInfo,
  UserSearchInfo,
} from 'src/types/user.types';
import { ApiType } from '../constant/apiType';
import { _request } from './factory';

type SearchUsersResponse = {
  users: UserSearchInfo[];
};

const searchUsers = async (params: {
  searchType: string;
  keyword: string;
}): Promise<SearchUsersResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/users/search`,
    params,
  });
};

const registerRecommend = async (data: {
  recommenderUserId: number;
  recommendeeNickname: string;
}) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/users/recommends`,
    data,
  });
};

const getRedCardAppliedUsers = async () => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/users/red-card`,
  });
};

type GetRestrictedUsersResponse = {
  users: UserRestrictInfo[];
};

const getRestrictedUsers = async (): Promise<GetRestrictedUsersResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: '/admin/users/restrict',
  });
};

const createOrUpdateUserRestrict = async (data: {
  id?: number;
  userId: number;
  reportBanEndDatetime?: string;
  permanentBanStartTime?: string;
  reason?: string;
}) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: '/admin/users/restrict',
    data,
  });
};

const deleteUserRestrict = async (params: {
  userId: number;
}): Promise<boolean> => {
  return _request({
    apiType: ApiType.go,
    method: 'DELETE',
    url: `/admin/users/restrict`,
    params,
  });
};

const getRewardTaxUserList = async (params: {
  gteCreatedAt: string;
  ltCreatedAt: string;
}) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/users/reward-tax`,
    params,
  });
};

const completeRewardTaxUser = async (id: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: `/admin/users/reward-tax/${id}`,
  });
};

type CreateUserResponse = {
  user: UserMiniInfo;
};

const createUser = async (data: {
  email: string;
  password: string;
  name: string;
  phone: string;
  birthday: string;
  sex: string;
}): Promise<CreateUserResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/users`,
    data,
  });
};

type GetRecommendeeInfo = {
  user: {
    id: number;
    createdAt: Date;
    userId: number;
    nickname: string;
  };
};

const getRecommendeeInfo = async (params: {
  recommenderId: number;
}): Promise<GetRecommendeeInfo> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/users/recommendee`,
    params,
  });
};

const giveEventStorePoint = async (data: {
  userIds: number[];
  description: string;
  amount: number;
}) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/users/give-event-store`,
    data,
  });
};

const getUser = async (userId: number): Promise<UserAllInfoForAdmin> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/users/${userId}`,
  });
};

const getUserCashBalance = async (userId: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/users/${userId}/balance/cash`,
  });
};

const getUserPrizeBalance = async (userId: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/users/${userId}/balance/prize`,
  });
};

const getUserAddressList = async (userId: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/users/${userId}/user-address`,
  });
};

const getUserStoreBalance = async (userId: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/users/${userId}/balance/store`,
  });
};

const getPillOrders = async (userId: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/users/${userId}/pill-orders`,
  });
};

const getRaceOrders = async (userId: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/users/${userId}/race-orders`,
  });
};

const cancelRaceOrder = async (userId: number, data: any) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/users/${userId}/race-cancel`,
    data,
  });
};

const getActivatedRups = async (userId: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/users/${userId}/activated-rups`,
  });
};

const getRecommendInfoByUser = async (userId: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/users/${userId}/recommends`,
  });
};

const updateUserInfoSocials = async (userId: number, data: any) => {
  return _request({
    apiType: ApiType.go,
    method: 'PUT',
    url: `/admin/users/${userId}/user-info/socials`,
    data,
  });
};

// 유저의 챌린지 재도전 내역 조회
const getUserRetryChallenges = (userId: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/users/${userId}/challenges/retry`,
  });
};

const resetUserPassword = (data: { email: string }) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/users/password-reset`,
    data,
  });
};

const updateUser = (
  userId: number,
  data: {
    email?: string;
    name?: string;
    nickname?: string;
    sex?: string;
    source?: string;
    itemCount?: number;
    phone?: string;
    birthday?: string;
    isDeleted?: boolean;
    zipcode?: string;
    addressBase?: string;
    addressDetail?: string;
  },
) => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: `/admin/users/${userId}`,
    data,
  });
};

export default {
  searchUsers,
  registerRecommend,
  getRecommendeeInfo,
  getRedCardAppliedUsers,
  getRestrictedUsers,
  createOrUpdateUserRestrict,
  deleteUserRestrict,
  getRewardTaxUserList,
  completeRewardTaxUser,
  createUser,
  giveEventStorePoint,
  getUser,
  getUserCashBalance,
  getUserPrizeBalance,
  getUserStoreBalance,
  getUserAddressList,
  getPillOrders,
  getRaceOrders,
  cancelRaceOrder,
  getActivatedRups,
  getRecommendInfoByUser,
  updateUserInfoSocials,
  getUserRetryChallenges,
  resetUserPassword,
  updateUser,
};
