import { ChallengeCustomField, ChallengePublicTypeCustomField } from '@types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'publicType'>,
  Extract<ChallengeCustomField, { name: 'publicType' }>
> = ({ publicType }) => {
  if (typeof publicType !== 'string')
    throw Error("[PublicTypeForm] this shouldn't be happened");

  return [
    {
      name: 'publicType',
      values: publicType,
    },
  ];
};

const DEFAULT_VALUE = 'PARTICIPANT';
export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'publicType'>
> = (customFields) => {
  const publicType = customFields.find(({ name }) => name === 'publicType');
  if (!publicType) return { publicType: DEFAULT_VALUE };

  const { values } = publicType as ChallengePublicTypeCustomField;

  return { publicType: values };
};
