export const types = {
  SET_TOKEN__NAVIGATION: 'SET_TOKEN__NAVIGATION',
  SET_GOALS__NAVIGATION: 'SET_GOALS__NAVIGATION',
  SET_ACTIVE_GOALS__NAVIGATION: 'SET_ACTIVE_GOALS__NAVIGATION',
  SET_CHALLENGES__NAVIGATION: 'SET_CHALLENGES__NAVIGATION',
  SET_ACTIVE_CHALLENGES__NAVIGATION: 'SET_ACTIVE_CHALLENGES__NAVIGATION',
  SET_ACHIEVEMENTS__NAVIGATION: 'SET_ACHIEVEMENTS__NAVIGATION',
  SET_ACTIVE_ACHIEVEMENTS__NAVIGATION: 'SET_ACTIVE_ACHIEVEMENTS__NAVIGATION',
  SET_BOARDS__NAVIGATION: 'SET_BOARD__NAVIGATION',
  SET_ACTIVE_BOARDS__NAVIGATION: 'SET_ACTIVE_BOARDS__NAVIGATION',
  SET_PUSHES__NAVIGATION: 'SET_PUSH__NAVIGATION',
  SET_ACTIVE_PUSHES__NAVIGATION: 'SET_ACTIVE_PUSH__NAVIGATION',
};

export const setToken = (token) => ({
  type: types.SET_TOKEN__NAVIGATION,
  token,
});

export const setGoals = (goals) => ({
  type: types.SET_GOALS__NAVIGATION,
  goals,
});

export const setActiveGoals = (activeGoals) => ({
  type: types.SET_ACTIVE_GOALS__NAVIGATION,
  activeGoals,
});

export const setChallenges = (challenges) => ({
  type: types.SET_CHALLENGES__NAVIGATION,
  challenges,
});

export const setActiveChallenges = (activeChallenges) => ({
  type: types.SET_ACTIVE_CHALLENGES__NAVIGATION,
  activeChallenges,
});

export const setAchievements = (achievements) => ({
  type: types.SET_ACHIEVEMENTS__NAVIGATION,
  achievements,
});

export const setActiveAchievements = (activeAchievements) => ({
  type: types.SET_ACTIVE_ACHIEVEMENTS__NAVIGATION,
  activeAchievements,
});

export const setBoards = (boards) => ({
  type: types.SET_BOARDS__NAVIGATION,
  boards,
});

export const setActiveBoards = (boards) => ({
  type: types.SET_ACTIVE_BOARDS__NAVIGATION,
  boards,
});

export const setPushes = (pushes) => ({
  type: types.SET_PUSHES__NAVIGATION,
  pushes,
});

export const setActivePushes = (activePushes) => ({
  type: types.SET_ACTIVE_PUSHES__NAVIGATION,
  activePushes,
});
