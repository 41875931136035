import { NaverSmartStoreModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const DEFAULT_DATA: {
  type: 'COLLABO-NAVER-SMART-STORE';
  data: NaverSmartStoreModuleFormType;
} = {
  type: 'COLLABO-NAVER-SMART-STORE',
  data: {
    topGuideImageUrl:
      'https://d246jgzr1jye8u.cloudfront.net/development/admin/1707289712745.png',
    collaboImageUrls: [],
    purchaseRequiredTextList: [
      {
        text: '안내된 구매 기한 내 상품을 구매하지 않으시면 정상적인 챌린지 참가 및 인증이 어려우며, 상금을 받으실 수 없습니다. 이로 인해 발생하는 불이익은 챌린저스 및 제휴사에서 책임지지 않으니 동의하시는 분만 신청해주세요.',
        type: 'normal',
        active: true,
      },
      {
        text: '상금은 레드카드 발급 이력이 없고, 인증패스 사용 없이 100% 달성하신 분들에게 지급됩니다.',
        type: 'normal',
        active: true,
      },
      {
        text: '챌린저스에서 인증샷을 전수 검사하며, 인증샷 도용과 조작 및 인증 규정과 무관한 인증샷을 올리실 경우 챌린지 참여가 예고없이 취소 및 레드카드가 발급되며, 추후 제휴 챌린지 신청 시 불이익을 받으실 수 있습니다.',
        type: 'highlight',
        active: true,
      },
      {
        text: '리뷰 작성 후 반품이 확인될 시 포인트 회수 및 블랙리스트로 등록될 수 있습니다.',
        type: 'highlight',
        active: true,
      },
      {
        text: '',
        type: 'normal',
        active: false,
      },
      {
        text: '',
        type: 'normal',
        active: false,
      },
    ],
    productThumbnailImageUrl: '',
    price: 0,
    originalPrice: 0,
    reviewText: '',
    reviewImageUrls: [],
    reviewGuide: `리뷰 사진은 '반드시 제품을'\n'사용한 사진을 첨부'해 주세요!`,
    pleaseCheckTextList: [
      {
        text: '제품 구매/배송/환불 등은 <네이버스토어>에  문의해 주세요.',
        type: 'normal',
        active: true,
      },
      {
        text: '챌린지 신청 시 연동한 네이버 id로 구매하셔야하며, 챌린지 인증기간 내 네이버 id는 변경할 수 없습니다. ',
        type: 'normal',
        active: true,
      },
      {
        text: '상금은 레드카드 발급 이력이 없고, 인증패스 사용 없이 100% 달성하신 분들에게 지급됩니다.',
        type: 'normal',
        active: true,
      },
      {
        text: '챌린저스에서 인증샷을 전수 검사하며, 인증샷 도용과 조작 및 인증 규정과 무관한 인증샷을 올리실 경우 챌린지 참여가 예고없이 취소 및 레드카드가 발급되며, 추후 제휴 챌린지 신청 시 불이익을 받으실 수 있습니다.',
        type: 'normal',
        active: true,
      },
      {
        text: '제휴 챌린지 모집 기간 종료 후 취소 요청을 1달 내 3회 이상 하시는 분들은 추후 제휴챌린지 참여에 제한이 있을 수 있습니다.',
        type: 'normal',
        active: true,
      },
      {
        text: '우수 후기자 경품 발송을 위하여 챌린지 신청 시 배송정보를 수집합니다.',
        type: 'normal',
        active: true,
      },
      {
        text: '재판매 등 부정한 목적 및 영리 추구를 위한 챌린지 참여가 확인 될 경우 서비스 이용 정지 및 손해배상 청구가 진행 될 수 있습니다. [서비스 이용 약관 참고]',
        type: 'normal',
        active: true,
      },
      {
        text: '',
        type: 'normal',
        active: false,
      },
    ],
  },
};
