import React, { Component } from 'react';
import { createHashHistory } from 'history';
import { Button, Checkbox, Container, Table } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
import _ from 'lodash';
import Workbook from 'react-excel-workbook';
import api, { apis } from '../../../api';

class GoalItemTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goals: [],
      checkedIds: [],
      redirect: false,
      column: null,
      direction: null,
      goalId: null,
      checkedAll: false,
      redirectEdit: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const propsGoalIds = nextProps.goals.map((c) => c.id).sort();
    const stateGoalIds = prevState.goals.map((c) => c.id).sort();
    const propsGoalDeletes = nextProps.goals.map((c) => c.isDeleted).sort();
    const stateGoalDeletes = prevState.goals.map((c) => c.isDeleted).sort();
    const propsGoalPublics = nextProps.goals.map((c) => c.isPublic).sort();
    const stateGoalPublics = prevState.goals.map((c) => c.isPublic).sort();
    if (
      _.isEqual(propsGoalIds, stateGoalIds) &&
      _.isEqual(propsGoalDeletes, stateGoalDeletes) &&
      _.isEqual(propsGoalPublics, stateGoalPublics)
    ) {
      return null;
    }
    const direction = prevState.direction === 'ascending' ? 'asc' : 'desc';
    return {
      goals: _.orderBy(nextProps.goals, [prevState.column], [direction]),
    };
  }

  handleSort = (clickedColumn) => () => {
    const { column, goals, direction } = this.state;
    if (column !== clickedColumn) {
      const newGoals = _.sortBy(goals, [clickedColumn]);
      this.setState({
        column: clickedColumn,
        goals: newGoals,
        direction: 'ascending',
      });
      return;
    }

    this.setState({
      goals: goals.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };

  handleChange = async (e, { value }) => {
    const { goals, checkedIds, checkedAll } = this.state;

    if (value === 'all') {
      await this.setState({ checkedIds: [] });
      if (checkedAll) {
        _.map(goals, (g) => (g.isChecked = !checkedAll));
        await this.setState({ checkedIds: [], checkedAll: !checkedAll });
      } else {
        _.map(goals, (g) => {
          g.isChecked = !checkedAll;
          checkedIds.push(g.id);
        });
        await this.setState({ checkedIds, checkedAll: !checkedAll });
      }
      return;
    }

    const goal = _.find(goals, (c) => c.id === value);
    goal.isChecked = goal.isChecked ? !goal.isChecked : true;

    if (goal.isChecked && !checkedIds.includes(value)) checkedIds.push(value);
    else if (!goal.isChecked && checkedIds.includes(value)) {
      const index = checkedIds.indexOf(value);
      checkedIds.splice(index, 1);
    }
    this.setState({ goals, checkedIds });
  };

  deleteGoals = () => {
    const { checkedIds } = this.state;
    api
      .delete('/goals', { params: { goalIds: JSON.stringify(checkedIds) } })
      .then((response) => {
        const goalIds = response.data.data.goalIds;
        this.props.delete(goalIds);
        this.setState({ checkedIds: [] });
      });
  };
  copyGoals = async () => {
    const { checkedIds } = this.state;
    const res = await apis.challenge.copyGoals({ goalIds: checkedIds });
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    const { goals } = res;
    this.props.copy(goals, checkedIds);
    this.setState({ checkedIds: [] });
  };

  goGoalDetail = (goalId) => {
    const history = createHashHistory();
    history.push('/goals');
    this.setState({ goalId }, () => {
      this.setState({ redirect: true });
    });
  };

  render() {
    const {
      goals,
      goalId,
      redirect,
      column,
      direction,
      checkedAll,
      redirectEdit,
    } = this.state;

    if (redirect) return <Redirect to={{ pathname: '/goals/' + goalId }} />;
    if (redirectEdit)
      return <Redirect to={{ pathname: `/goals/${goalId}/edit` }} />;
    return (
      <Container
        fluid
        style={{
          overflowX: 'auto',
          maxWidth: 1400,
          maxHeight: 1000,
          marginTop: 20,
        }}
      >
        <Button
          color="black"
          // floated="right"
          size="mini"
          onClick={this.deleteGoals}
        >
          삭제
        </Button>
        <Button
          basic
          color="blue"
          // floated="right"
          size="mini"
          onClick={this.copyGoals}
        >
          복사
        </Button>
        <Workbook
          filename="goals.xlsx"
          element={
            <Button
              basic
              color="green"
              // floated="right"
              size="mini"
            >
              Excel
            </Button>
          }
        >
          <Workbook.Sheet data={goals} name="Sheet A">
            <Workbook.Column label="id" value={(g) => g.id} />
            <Workbook.Column label="목표" value={(g) => g.title} />
            <Workbook.Column label="카테고리1" value={(g) => g.goalCategory1} />
            <Workbook.Column label="카테고리2" value={(g) => g.goalCategory2} />
            <Workbook.Column label="카테고리3" value={(g) => g.goalCategory3} />
            <Workbook.Column label="카테고리4" value={(g) => g.goalCategory4} />
            <Workbook.Column label="주기" value={(g) => g.cycle.toString()} />
            <Workbook.Column label="매일/기간" value={(g) => g.dayOrPeriod} />
            <Workbook.Column
              label="주기당총회수"
              value={(g) => g.totalAchieveCountPerCycle}
            />
            <Workbook.Column
              label="하루최대회수"
              value={(g) => g.maxAchieveCountPerDay}
            />
            <Workbook.Column
              label="최소간격(분)"
              value={(g) => g.achievementIntervalMinute}
            />
            <Workbook.Column
              label="카운트다운"
              value={(g) => (g.countDown ? '적용' : '미적용')}
            />
            <Workbook.Column label="목표기간" value={(g) => g.totalDays} />
            <Workbook.Column label="인증시작" value={(g) => g.dueStartTime} />
            <Workbook.Column label="인증종료" value={(g) => g.dueEndTime} />
            <Workbook.Column
              label="점수/달성여부"
              value={(g) => (g.scoreOrBinary === 'score' ? '점수' : '달성여부')}
            />
            <Workbook.Column
              label="갤러리"
              value={(g) => (g.isGalleryPossible ? '가능' : '불가')}
            />
            <Workbook.Column
              label="인증샷"
              value={(g) => (g.isAchievementPublic ? '공개' : '비공개')}
            />
            <Workbook.Column
              label="삭제"
              value={(g) => (g.isDeleted ? '삭제' : '미삭제')}
            />
          </Workbook.Sheet>
        </Workbook>
        <Table basic="very" size="small" sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Checkbox
                  name="all"
                  checked={checkedAll}
                  value="all"
                  onChange={this.handleChange}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>편집</Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'id' ? direction : null}
                onClick={this.handleSort('id')}
              >
                id
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'title' ? direction : null}
                onClick={this.handleSort('title')}
              >
                목표
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'goalCategory1' ? direction : null}
                onClick={this.handleSort('goalCategory1')}
              >
                카테고리1
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'goalCategory2' ? direction : null}
                onClick={this.handleSort('goalCategory2')}
              >
                카테고리2
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'goalCategory3' ? direction : null}
                onClick={this.handleSort('goalCategory3')}
              >
                카테고리3
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'goalCategory4' ? direction : null}
                onClick={this.handleSort('goalCategory4')}
              >
                카테고리4
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'cycle' ? direction : null}
                onClick={this.handleSort('cycle')}
              >
                주기
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'dayOrPeriod' ? direction : null}
                onClick={this.handleSort('dayOrPeriod')}
              >
                매일/기간
              </Table.HeaderCell>
              <Table.HeaderCell>주기당총회수</Table.HeaderCell>
              <Table.HeaderCell>하루최대회수</Table.HeaderCell>
              <Table.HeaderCell>최소간격(분)</Table.HeaderCell>
              <Table.HeaderCell>카운트다운</Table.HeaderCell>
              <Table.HeaderCell>목표기간</Table.HeaderCell>
              <Table.HeaderCell>인증시작</Table.HeaderCell>
              <Table.HeaderCell>인증종료</Table.HeaderCell>
              <Table.HeaderCell>점수/달성여부</Table.HeaderCell>
              <Table.HeaderCell>갤러리가능여부</Table.HeaderCell>
              <Table.HeaderCell>인증샷공개여부</Table.HeaderCell>
              <Table.HeaderCell>삭제</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {goals.map((g) => {
              return (
                <Table.Row key={g.id}>
                  <Table.Cell collapsing>
                    <Checkbox
                      checked={g.isChecked}
                      value={g.id}
                      onChange={this.handleChange}
                    />
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      size="mini"
                      as={Link}
                      to={{ pathname: `/goals/${g.id}/edit` }}
                      target="_blank"
                      rel="noopener noreferrer"
                      content="편집"
                    />
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.id}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.title}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.goalCategory1}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.goalCategory2}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.goalCategory3}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.goalCategory4}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.cycle.map((c, idx) => (
                      <span key={idx}>{c},</span>
                    ))}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.dayOrPeriod}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.totalAchieveCountPerCycle}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.maxAchieveCountPerDay}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.achievementIntervalMinute}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.countDown ? '적용' : '미적용'}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.totalDays}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.dueStartTime}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.dueEndTime}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.scoreOrBinary}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.isGalleryPossible ? '가능' : '불가'}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.isAchievementPublic ? '공개' : '비공개'}
                  </Table.Cell>
                  <Table.Cell
                    collapsing
                    onClick={this.goGoalDetail.bind(this, g.id)}
                  >
                    {g.isDeleted ? '삭제' : '정상'}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

export default GoalItemTable;
