import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: 1px solid #eee;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 12px;
  &:hover {
    background-color: #eeeeee44;
  }
`;

export const Thumb = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 12px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.span`
  font-size: 15px;
  flex: 1;
  display: flex;
  align-items: center;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  padding-left: 12px;
`;
