import React from 'react';
import { Image, Label, Grid, Header, Divider } from 'semantic-ui-react';
import resizeImage from '../../../../../global/resizeImage';
import { splitNewLine } from '../../../../../utils/string';

const ChallengeDetailInfo = ({ object }) => {
  return (
    <React.Fragment>
      <Label color="black">{object.type}</Label>
      {object.type === 'TITLE' && (
        <React.Fragment>
          <Header as="h4">제목</Header>
          <p>{object.title === '' ? '(내용이 없습니다.)' : object.title}</p>
          {renderSubHeader(object)}
        </React.Fragment>
      )}
      {['HORIZONTAL', 'VERTICAL', 'SQUARE'].includes(object.type) && (
        <React.Fragment>
          {renderMainHeader(object)}
          {renderSubHeader(object)}
          {renderImage(object)}
          {renderBody(object)}
        </React.Fragment>
      )}
      {object.type === 'INTERVIEW' && (
        <React.Fragment>
          {renderImage(object)}
          {renderSubHeader(object)}
          {renderBody(object)}
        </React.Fragment>
      )}
      {object.type === 'NUMBER' && (
        <React.Fragment>
          {renderMainHeader(object)}

          {object.numbers.length > 0 && (
            <>
              <Header as="h4">번호 목록</Header>
              {object.numbers.map((number, i) => (
                <p key={i}>{number}</p>
              ))}
            </>
          )}
        </React.Fragment>
      )}
      {object.type === 'SLOGAN' && (
        <React.Fragment>{renderSubHeader(object)}</React.Fragment>
      )}
      {['LEFT-ALIGN-TEXT', 'TEXT-ONLY'].includes(object.type) && (
        <React.Fragment>
          {renderMainHeader(object)}
          {renderSubHeader(object)}
          {renderBody(object)}
        </React.Fragment>
      )}
      {object.type === 'CARD-TITLE' && (
        <React.Fragment>
          {renderMainHeader(object)}

          {renderSubHeader(object)}
        </React.Fragment>
      )}
      {object.type === 'MULTI-IMAGES' && (
        <React.Fragment>
          {renderMainHeader(object)}

          {renderSubHeader(object)}

          <Header as="h4">이미지</Header>
          {object.images.map((url, i) => (
            <div key={i}>
              <Image key={url} src={resizeImage(url, 300)} />
            </div>
          ))}
        </React.Fragment>
      )}
      {object.type === 'NEW-INTERVIEW' && (
        <React.Fragment>
          {renderMainHeader(object)}

          <Header as="h4">인터뷰 목록</Header>
          <Grid>
            {object.interviewee?.map((o, i) => (
              <div key={i}>
                <Image src={resizeImage(o.image, 300)} />
                <p>{o.name}</p>
                <p>{o.content}</p>
              </div>
            ))}
          </Grid>
        </React.Fragment>
      )}
      {object.type === 'VIDEO' && (
        <React.Fragment>
          {renderMainHeader(object)}
          {renderSubHeader(object)}

          {object.video !== '' && (
            <>
              <Header as="h4">영상</Header>
              <p>{object.video}</p>
            </>
          )}

          {object.youtubeId !== '' && (
            <>
              <Header as="h4">유튜브 id</Header>
              <p>{object.youtubeId}</p>
            </>
          )}
          {renderBody(object)}
        </React.Fragment>
      )}
      {['HYPERLINK', 'FAQ'].includes(object.type) && (
        <React.Fragment>
          {renderMainHeader(object)}

          {object.url !== '' && (
            <>
              <Header as="h4">url</Header>
              <p>{object.url}</p>
            </>
          )}

          {object.urlText !== '' && (
            <>
              <Header as="h4">urlText</Header>
              <p>{object.urlText}</p>
            </>
          )}

          {renderImage(object)}
          {renderBody(object)}
        </React.Fragment>
      )}
      {object.type === 'FULL-PHOTO' && (
        <React.Fragment>
          {renderMainHeader(object)}
          {renderImage(object)}
          {renderBody(object)}
        </React.Fragment>
      )}
      {object.type === 'FULL-PHOTO-NO-GAP' && (
        <React.Fragment>
          {renderMainHeader(object)}
          {renderImage(object)}
          {renderBody(object)}
        </React.Fragment>
      )}
      {object.type === 'FULL-PHOTO-WITH-GAP' && (
        <React.Fragment>
          {renderMainHeader(object)}
          {renderImage(object)}
          {renderBody(object)}
        </React.Fragment>
      )}
      {object.type === 'FULL-IMAGE' && (
        <React.Fragment>
          {object.imageList.map((o) => (
            <Image key={o.url} src={resizeImage(o.url, 300)} />
          ))}
        </React.Fragment>
      )}
      {object.type === 'COLLABO-SPECIAL-PRIZE' && (
        <React.Fragment>
          <Header as="h4">상금 지급방식</Header>
          <p>{object.data.title}</p>
          <p>{object.data.noticeList.map((notice) => ` - ${notice}`)}</p>
        </React.Fragment>
      )}
      {object.type === 'COLLABO-TOP-SUMMARY' && (
        <React.Fragment>
          {renderMainHeader({ mainHeader: object.data.mainHeader })}
          {renderSubHeader({ subHeader: object.data.subHeader })}
          <Header as="h4">로고이미지</Header>
          <Image src={resizeImage(object.data.logoImage, 300)} />
          <Header as="h4">메인이미지</Header>
          <Image src={resizeImage(object.data.image, 300)} />
        </React.Fragment>
      )}
      {object.type === 'COLLABO-SPECIAL-REWARD' && (
        <React.Fragment>
          <Header as="h4">회사</Header>
          <p>{object.data.from}</p>
          <Header as="h4">대상</Header>
          <p>{object.data.to}</p>
          <Header as="h4">리워드문구</Header>
          <p>{object.data.rewardText}</p>
          <p>
            {object.data.noticeList.map((notice) => (
              <div key={notice}>{` - ${notice}`}</div>
            ))}
          </p>
        </React.Fragment>
      )}
      {object.type === 'COLLABO-ADDITIONAL-REWARD' && (
        <React.Fragment>
          <Header as="h4">대상</Header>
          <p>{object.data.to}</p>
          <Header as="h4">상품이름</Header>
          <p>{object.data.itemName}</p>
          <Header as="h4">상품 상세</Header>
          <p>{object.data.itemDetailName}</p>
          <p>{object.data.itemDetailDescription}</p>
          <Header as="h4">메인이미지</Header>
          <Image src={resizeImage(object.data.image, 300)} />
          <p>
            {object.data.noticeList.map((notice) => (
              <div key={notice}>{` - ${notice}`}</div>
            ))}
          </p>
        </React.Fragment>
      )}
      <Divider />
    </React.Fragment>
  );
};

export default ChallengeDetailInfo;

const renderMainHeader = (object) => {
  return (
    <>
      {!!object.mainHeader && (
        <>
          <Header as="h4">메인헤더</Header>
          <p>{object.mainHeader}</p>
        </>
      )}
    </>
  );
};

const renderSubHeader = (object) => {
  return (
    <>
      {!!object.subHeader && (
        <>
          <Header as="h4">서브헤더</Header>
          <p>{object.subHeader}</p>
        </>
      )}
    </>
  );
};

const renderBody = (object) => {
  return (
    <>
      {!!object.body && (
        <>
          <Header as="h4">바디</Header>
          {splitNewLine(object.body)}
        </>
      )}
    </>
  );
};

const renderImage = (object) => {
  return (
    <>
      {!!object.image && (
        <>
          <Header as="h4">이미지</Header>
          <Image src={resizeImage(object.image, 300)} />
        </>
      )}
    </>
  );
};
