import { apis } from '@api/index';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import { ReportedAchievement } from 'src/types/achievement.types';
import { DELETE_COMMENT_OPTIONS } from './Achievement.constants';
import { UserIdNameNickname } from 'src/types/user.types';
import { ChallengeInfoForReportedAchievement } from '@types';

export const deleteAchievementFile = async (
  achievement: ReportedAchievement,
  deleteType: 'DELETE' | 'PUSH' | 'DELETE_AND_PASS',
  reviewComment: string,
  removeAchievement: (achievement: ReportedAchievement) => void,
  close?: () => void,
) => {
  LoadingIndicator.show();
  try {
    const res = await apis.deleteAchievementFile(
      achievement.achievementFile.id,
      {
        deleteType,
        reviewComment,
        userId: achievement.user.id,
      },
    );
    if ((res as any)?.response?.data && 'err' in (res as any)?.response?.data) {
      return;
    }
    removeAchievement(achievement);
  } finally {
    LoadingIndicator.hide();
    if (close) close();
  }
};

export const getReviewComment = (
  optionValue: number,
  user: UserIdNameNickname,
  challenge: ChallengeInfoForReportedAchievement,
): string => {
  let comment = DELETE_COMMENT_OPTIONS[optionValue].message;
  comment = comment.replace(/\${nickname}/g, user.nickname);
  let authenticationMethodText = `${challenge.authenticationMethod}\n`;
  if (challenge.authenticationChecklist) {
    authenticationMethodText += challenge.authenticationChecklist
      ?.map((item) => '* ' + item)
      .join('\n');
  }
  comment = comment.replace(
    /\${authenticationMethod}/g,
    authenticationMethodText,
  );

  return comment;
};
