import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { Col } from '../Style';

const LogoPreview = () => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Accordion style={{ width: '100%' }}>
      <Accordion.Title
        active={!collapsed}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Icon name="dropdown" />
        미리보기
      </Accordion.Title>
      <Accordion.Content active={!collapsed}>
        <Col>
          <strong>온보딩 1번 화면</strong>
          <img src="/race/후원사-로고1.png" width={220} alt="후원사-로고1" />
        </Col>
        <Col>
          <strong>완주기록증 화면</strong>
          <img src="/race/후원사-로고2.png" width={220} alt="후원사-로고2" />
        </Col>
      </Accordion.Content>
    </Accordion>
  );
};

export default LogoPreview;
