import { Dimmer, Loader, Modal, Button } from 'semantic-ui-react';
import { ReportedAchievement } from 'src/types/achievement.types';

type AllAchievementUrlModalProps = {
  isOpened: boolean;
  isLoading?: boolean;
  onClosePress: () => void;
  allAchievementFiles: ReportedAchievement[];
  authentications: {
    authenticationMethod: string;
  };
};

const AllAchievementUrlsModal = (props: AllAchievementUrlModalProps) => {
  const {
    isOpened,
    isLoading,
    authentications,
    allAchievementFiles,
    onClosePress,
  } = props;

  return (
    <Modal size="tiny" open={isOpened} onClose={onClosePress}>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <Modal.Header>전체 인증 URL 보기</Modal.Header>
      <Modal.Content>
        {authentications ? (
          <>
            <h5>인증방법</h5>
            <p>{authentications.authenticationMethod}</p>
            {allAchievementFiles.map((o, index) => {
              if (!o.achievementFile.memo) return null;
              return (
                <div key={`${o.achievementFile.id}-${index}`}>
                  <a
                    href={o.achievementFile.memo ?? ''}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${index + 1} : ${o.achievementFile.memo}`}
                  </a>
                  <p>
                    {o.achievementFile.isDeleted && '[삭제됨] '}
                    {o.achievementFile.id} / 제출시간:{' '}
                    {o.achievementFile.submitDatetime} / 신고건수{' '}
                    {o.reportUsers.length} / 경고댓글수 {o.adminComments.length}
                  </p>
                  {o.reportUsers.length > 0 && (
                    <>
                      <h5>신고사유</h5>
                      {o.reportUsers.map((ru) => {
                        return <p>{ru.comment}</p>;
                      })}
                    </>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <p>인증 URL이 없어요.</p>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" content="닫기" onClick={onClosePress} />
      </Modal.Actions>
    </Modal>
  );
};

export default AllAchievementUrlsModal;
