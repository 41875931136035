import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'authenticationMethod'>,
  Pick<ReqCreateOrUpdateChallenge, 'authenticationMethod'>
> = ({ authenticationMethod }) => {
  if (typeof authenticationMethod !== 'string')
    throw Error("[AuthenticationMethodForm] this shouldn't be happened");

  return {
    authenticationMethod,
  };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'authenticationMethod'>,
  Pick<ChallengeFormType, 'authenticationMethod'>
> = ({ authenticationMethod }) => ({
  authenticationMethod,
});
