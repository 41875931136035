import ErrorMsg from '@component/ErrorMsg';
import { InputHTMLAttributes, forwardRef } from 'react';
import * as S from './TextInput.styles';

type Props = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'onChange'
> & {
  label?: string;
  errorMsg?: string;
  onChange?: (text: string) => void;
  containerStyle?: React.CSSProperties;
};

const TextInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { onChange, label, errorMsg, containerStyle, value, ...inputProps } =
    props;

  return (
    <S.Container style={containerStyle}>
      {label && <S.Label>{label}</S.Label>}
      <S.Input
        {...inputProps}
        ref={ref}
        type="text"
        value={value || ''}
        onChange={(e) => onChange && onChange(e.target.value)}
      />
      <ErrorMsg text={errorMsg} />
    </S.Container>
  );
});

export default TextInput;
