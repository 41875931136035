import React, { Component } from 'react';
import {
  Table,
  Header,
  Button,
  Accordion,
  Icon,
  Dropdown,
  Dimmer,
  Loader,
  Modal,
} from 'semantic-ui-react';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';
import Workbook from 'react-excel-workbook';
import moment from 'moment';
import ValidationRequestModal from '../../../ValidationRequestModal';
import { apiGo } from '../../../../api';
import { publicTypes } from '../../../../constant/publicType';

class ChallengeUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationModalOpen: false,
      validated: false,
      isAccordionOpen: false,
      cancelRegisterModalOpen: false,
      progressing: false,
    };
    this.messageEl = React.createRef();
  }

  showValidationModal = () => this.setState({ validationModalOpen: true });
  closeValidationModal = () => this.setState({ validationModalOpen: false });
  setValidated = (validated) => {
    this.setState({ validated });
    this.closeValidationModal();
  };

  showCancelRegisterModal = () =>
    this.setState({ cancelRegisterModalOpen: true });
  closeCancelRegisterModal = () => {
    if (!this.state.progressing) {
      this.setState({ cancelRegisterModalOpen: false });
    }
  };
  cancelRegister = () => {
    this.setState({ progressing: true });
    const userIDs = this.props.relationUserChallenges.map((ruc) => ruc.userId);
    apiGo
      .post('/challenges/cancel/forced', {
        userIDs,
        challengeIDs: [this.props.challenge.id],
      })
      .then(() => {
        this.setState(
          {
            relationUserChallenges: [],
            transactionProducts: [],
            progressing: false,
          },
          () => {
            alert('신청 취소가 완료되었습니다!');
            this.closeCancelRegisterModal();
          },
        );
      })
      .catch((error) => {
        alert(
          '[' + error.response.status + '] ' + error.response.data.userMessage,
        );
      });
  };

  render() {
    const { isAccordionOpen, cancelRegisterModalOpen, progressing } =
      this.state;
    const {
      challenge,
      relationUserChallenges,
      challengeCommerce,
      getAllRucs,
      rucsLoading,
    } = this.props;

    const isCollaborationChallenge = !!challenge.customFields.find(
      (f) => f.name === 'collaboration',
    );
    const isSupportersChallenge = !!challenge.customFields.find(
      (f) => f.name === 'supporters',
    );

    if (
      challenge.registerInfo.registerCount > 0 &&
      relationUserChallenges.length === 0
    ) {
      return (
        <>
          <Header>챌린지 참가자 정보</Header>
          {rucsLoading ? (
            <Loader active inline="centered" />
          ) : (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                getAllRucs();
                this.setState({ isAccordionOpen: true });
              }}
            >
              참가자가 1,000명이 넘어서 별도 조회가 필요합니다. <br />
              참가자 조회를 원하실 경우 클릭해주세요.
            </div>
          )}
        </>
      );
    }

    const productOptions = [{ key: 0, text: '전체', value: 0 }];
    const messageTemplateByProduct = { 0: '' };
    challengeCommerce.rccps.forEach((rccp) => {
      productOptions.push({ key: rccp.id, text: rccp.name, value: rccp.id });
      messageTemplateByProduct[rccp.id] = rccp.messageTemplate;
    });

    // console.log(relationUserChallenges);

    const headerRow = [
      '신청id',
      '신청날짜',
      '유저id',
      '닉네임',
      '공개설정',
      '신청금(캐시)',
      '신청금(카드)',
      '신청금(이체)',
      '달성률',
      '반환금(캐시)',
      '반환금(카드)',
      '반환금(이체)',
      '상금',
      '결과',
      '이체내역',
    ];
    const renderBodyRow = (
      {
        id,
        createdAt,
        userId,
        userNickname,
        publicType,
        cashDeposit,
        cardDeposit,
        transferDeposit,
        achievementCountInfo,
        rucResult,
        transactionChallenges,
        result,
      },
      index,
    ) => ({
      key: `row-${userId}-${index}` || `row-${index}`,
      cells: [
        id ? { key: `row-${userId}-${id}-0`, content: id } : 'id',
        createdAt
          ? {
              key: `row-${userId}-${id}-1`,
              content: moment(createdAt).format('YYYY-MM-DD'),
            }
          : '-',
        userId ? { key: `row-${userId}-${id}-2`, content: userId } : 'userId',
        userNickname
          ? { key: `row-${userId}-${id}-5`, content: userNickname }
          : 'Null',
        publicType
          ? { key: `row-${userId}-${id}-6`, content: publicTypes[publicType] }
          : 'Null',
        cashDeposit
          ? {
              key: `row-${userId}-${id}-9`,
              content: setCurrencyFormat(cashDeposit),
            }
          : { key: `row-${userId}-${id}-9`, content: 0 },
        cardDeposit
          ? {
              key: `row-${userId}-${id}-10`,
              content: setCurrencyFormat(cardDeposit),
            }
          : { key: `row-${userId}-${id}-10`, content: 0 },
        transferDeposit
          ? {
              key: `row-${userId}-${id}-11`,
              content: setCurrencyFormat(transferDeposit),
            }
          : { key: `row-${userId}-${id}-11`, content: 0 },
        achievementCountInfo
          ? {
              key: `row-${userId}-${id}-12`,
              content: `${achievementCountInfo.ok} / ${achievementCountInfo.present} (총 : ${achievementCountInfo.total} 개)`,
            }
          : { key: `row-${userId}-${id}-12`, content: 0 },
        rucResult
          ? {
              key: `row-${userId}-${id}-13`,
              content: rucResult.returnCashPoint,
            }
          : { key: `row-${userId}-${id}-13`, content: 0 },
        // rucResult ? {key: `row-${userId}-${id}-13`, content: rucResult.returnPrizePoint} : {key: `row-${userId}-${id}-13`, content: 0},
        rucResult
          ? {
              key: `row-${userId}-${id}-14`,
              content: rucResult.returnCardPoint,
            }
          : { key: `row-${userId}-${id}-14`, content: 0 },
        rucResult
          ? {
              key: `row-${userId}-${id}-15`,
              content: rucResult.returnTransferPoint,
            }
          : { key: `row-${userId}-${id}-15`, content: 0 },
        rucResult
          ? {
              key: `row-${userId}-${id}-16`,
              content: Math.ceil(rucResult.rewardPrizePoint),
            }
          : { key: `row-${userId}-${id}-16`, content: 0 },
        rucResult
          ? { key: `row-${userId}-${id}-17`, content: result }
          : {
              key: `row-${userId}-${id}-17`,
              content: '',
            },
        rucResult
          ? {
              key: `row-${userId}-${id}-18`,
              content: transactionChallenges?.map((transaction, idx) => (
                <div key={`tc-${userId}-${idx}`}>
                  {transaction.transactionType} / {transaction.pointType} /{' '}
                  {setCurrencyFormat(transaction.amount)}
                </div>
              )),
            }
          : { key: `row-${userId}-${id}-18`, content: '' },
      ],
    });

    return (
      <React.Fragment>
        <Accordion>
          <Accordion.Title
            active={isAccordionOpen}
            index={1}
            onClick={() => this.setState({ isAccordionOpen: !isAccordionOpen })}
          >
            <Header>
              <Icon name="dropdown" />
              챌린지 참가자 정보
            </Header>
          </Accordion.Title>
          <Accordion.Content active={isAccordionOpen}>
            <Button
              size="mini"
              style={{ marginLeft: 10 }}
              content="전체신청취소"
              onClick={this.showCancelRegisterModal}
            />
            {this.state.validated ? (
              isSupportersChallenge ? (
                <SupportersChallengeWorkbook
                  challenge={challenge}
                  relationUserChallenges={relationUserChallenges}
                />
              ) : isCollaborationChallenge ? (
                <CollaborationChallengeWorkbook
                  challenge={challenge}
                  relationUserChallenges={relationUserChallenges}
                />
              ) : (
                <NormalChallengeWorkbook
                  challenge={challenge}
                  relationUserChallenges={relationUserChallenges}
                />
              )
            ) : (
              <Button
                basic
                color="green"
                floated="right"
                size="mini"
                onClick={this.showValidationModal}
              >
                엑셀 요청하기
              </Button>
            )}
            <Table
              basic="very"
              size="small"
              headerRow={headerRow}
              renderBodyRow={renderBodyRow}
              textAlign="center"
              tableData={relationUserChallenges}
            />
          </Accordion.Content>
        </Accordion>

        <ValidationRequestModal
          validationModalOpen={this.state.validationModalOpen}
          closeValidationModal={this.closeValidationModal}
          setValidated={this.setValidated}
          location={'챌린지 참가자 정보'}
        />

        <Modal
          size="small"
          open={cancelRegisterModalOpen}
          onClose={this.closeCancelRegisterModal}
        >
          <Dimmer active={progressing}>
            <Loader />
          </Dimmer>
          <Modal.Header>챌린지 신청 전체 취소</Modal.Header>
          <Modal.Content>
            총 {relationUserChallenges.length}명의 챌린지 신청을 취소합니다.
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="신청취소"
              onClick={this.cancelRegister}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeCancelRegisterModal}
            />
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ChallengeUser;

const NormalChallengeWorkbook = ({ challenge, relationUserChallenges }) => {
  return (
    <Workbook
      filename={`${challenge.title}_참가자.xlsx`}
      element={
        <Button basic color="green" floated="right" size="mini">
          Excel
        </Button>
      }
    >
      <Workbook.Sheet data={relationUserChallenges} name="Sheet A">
        <Workbook.Column label="신청id" value={(ruc) => ruc.id} />
        <Workbook.Column
          label="신청시간"
          value={(ruc) => moment(ruc.createdAt).format('YYYY-MM-DD HH:mm:ss')}
        />
        <Workbook.Column label="유저id" value={(ruc) => ruc.userId} />
        <Workbook.Column label="email" value={(ruc) => ruc.userEmail} />
        <Workbook.Column label="이름" value={(ruc) => ruc.userName} />
        <Workbook.Column label="닉네임" value={(ruc) => ruc.userNickname} />
        <Workbook.Column
          label="핸드폰"
          value={(ruc) =>
            ruc.userPhone[0] !== '0'
              ? '0' + ruc.userPhone.substring(2)
              : ruc.userPhone
          }
        />
        <Workbook.Column label="디바이스id" value={(ruc) => ruc.userDeviceId} />
        <Workbook.Column label="생년월일" value={(ruc) => ruc.userBirthday} />
        <Workbook.Column label="성별" value={(ruc) => ruc.userSex} />
        <Workbook.Column
          label="탈퇴여부"
          value={(ruc) => (ruc.userIsDeleted ? '탈퇴' : '-')}
        />
        <Workbook.Column
          label="마케팅수신동의여부"
          value={(ruc) => (ruc.userIsMarketingPossible ? '동의' : '-')}
        />
        <Workbook.Column
          label="신청금(캐시)"
          value={(ruc) =>
            ruc.cashDeposit > 0 ? setCurrencyFormat(ruc.cashDeposit) : '0'
          }
        />
        <Workbook.Column
          label="신청금(상금)"
          value={(ruc) =>
            ruc.prizeDeposit > 0 ? setCurrencyFormat(ruc.prizeDeposit) : '0'
          }
        />
        <Workbook.Column
          label="신청금(카드)"
          value={(ruc) =>
            ruc.cardDeposit > 0 ? setCurrencyFormat(ruc.cardDeposit) : '0'
          }
        />
        <Workbook.Column
          label="신청금(이체)"
          value={(ruc) =>
            ruc.transferDeposit > 0
              ? setCurrencyFormat(ruc.transferDeposit)
              : '0'
          }
        />
        <Workbook.Column
          label="달성률"
          value={(ruc) =>
            `${ruc.achievementCountInfo.ok} / ${ruc.achievementCountInfo.present} (총 : ${ruc.achievementCountInfo.total} 개)`
          }
        />
        <Workbook.Column
          label="반환금(캐시)"
          value={(ruc) =>
            ruc.rucResult.returnCashPoint > 0
              ? setCurrencyFormat(ruc.rucResult.returnCashPoint)
              : '0'
          }
        />
        <Workbook.Column
          label="반환금(상금)"
          value={(ruc) =>
            ruc.rucResult.returnPrizePoint > 0
              ? setCurrencyFormat(ruc.rucResult.returnPrizePoint)
              : '0'
          }
        />
        <Workbook.Column
          label="반환금(카드)"
          value={(ruc) =>
            ruc.rucResult.returnCardPoint > 0
              ? setCurrencyFormat(ruc.rucResult.returnCardPoint)
              : '0'
          }
        />
        <Workbook.Column
          label="반환금(이체)"
          value={(ruc) =>
            ruc.rucResult.returnTransferPoint > 0
              ? setCurrencyFormat(ruc.rucResult.returnTransferPoint)
              : '0'
          }
        />
        <Workbook.Column
          label="상금"
          value={(ruc) =>
            ruc.rucResult.rewardPrizePoint > 0
              ? setCurrencyFormat(Math.ceil(ruc.rucResult.rewardPrizePoint))
              : '0'
          }
        />
        <Workbook.Column label="결과" value={(ruc) => ruc.result} />
      </Workbook.Sheet>
    </Workbook>
  );
};

const CollaborationChallengeWorkbook = ({
  challenge,
  relationUserChallenges,
}) => {
  return (
    <Workbook
      filename={`${challenge.title}_참가자.xlsx`}
      element={
        <Button basic color="green" floated="right" size="mini">
          Excel
        </Button>
      }
    >
      <Workbook.Sheet data={relationUserChallenges} name="Sheet A">
        <Workbook.Column label="신청id" value={(ruc) => ruc.id} />
        <Workbook.Column
          label="신청시간"
          value={(ruc) => moment(ruc.createdAt).format('YYYY-MM-DD HH:mm:ss')}
        />
        <Workbook.Column label="유저id" value={(ruc) => ruc.userId} />
        <Workbook.Column label="email" value={(ruc) => ruc.userEmail} />
        <Workbook.Column label="이름" value={(ruc) => ruc.userName} />
        <Workbook.Column label="닉네임" value={(ruc) => ruc.userNickname} />
        <Workbook.Column
          label="핸드폰"
          value={(ruc) =>
            ruc.userPhone[0] !== '0'
              ? '0' + ruc.userPhone.substring(2)
              : ruc.userPhone
          }
        />
        <Workbook.Column label="디바이스id" value={(ruc) => ruc.userDeviceId} />
        <Workbook.Column label="생년월일" value={(ruc) => ruc.userBirthday} />
        <Workbook.Column label="성별" value={(ruc) => ruc.userSex} />
        <Workbook.Column
          label="탈퇴여부"
          value={(ruc) => (ruc.userIsDeleted ? '탈퇴' : '-')}
        />
        <Workbook.Column
          label="마케팅수신동의여부"
          value={(ruc) => (ruc.userIsMarketingPossible ? '동의' : '-')}
        />
        <Workbook.Column
          label="신청금(캐시)"
          value={(ruc) =>
            ruc.cashDeposit > 0 ? setCurrencyFormat(ruc.cashDeposit) : '0'
          }
        />
        <Workbook.Column
          label="신청금(상금)"
          value={(ruc) =>
            ruc.prizeDeposit > 0 ? setCurrencyFormat(ruc.prizeDeposit) : '0'
          }
        />
        <Workbook.Column
          label="신청금(카드)"
          value={(ruc) =>
            ruc.cardDeposit > 0 ? setCurrencyFormat(ruc.cardDeposit) : '0'
          }
        />
        <Workbook.Column
          label="신청금(이체)"
          value={(ruc) =>
            ruc.transferDeposit > 0
              ? setCurrencyFormat(ruc.transferDeposit)
              : '0'
          }
        />
        <Workbook.Column
          label="달성률"
          value={(ruc) =>
            `${ruc.achievementCountInfo.ok} / ${ruc.achievementCountInfo.present} (총 : ${ruc.achievementCountInfo.total} 개)`
          }
        />
        <Workbook.Column
          label="반환금(캐시)"
          value={(ruc) =>
            ruc.rucResult.returnCashPoint > 0
              ? setCurrencyFormat(ruc.rucResult.returnCashPoint)
              : '0'
          }
        />
        <Workbook.Column
          label="반환금(상금)"
          value={(ruc) =>
            ruc.rucResult.returnPrizePoint > 0
              ? setCurrencyFormat(ruc.rucResult.returnPrizePoint)
              : '0'
          }
        />
        <Workbook.Column
          label="반환금(카드)"
          value={(ruc) =>
            ruc.rucResult.returnCardPoint > 0
              ? setCurrencyFormat(ruc.rucResult.returnCardPoint)
              : '0'
          }
        />
        <Workbook.Column
          label="반환금(이체)"
          value={(ruc) =>
            ruc.rucResult.returnTransferPoint > 0
              ? setCurrencyFormat(ruc.rucResult.returnTransferPoint)
              : '0'
          }
        />
        <Workbook.Column
          label="상금"
          value={(ruc) =>
            ruc.rucResult.rewardPrizePoint > 0
              ? setCurrencyFormat(Math.ceil(ruc.rucResult.rewardPrizePoint))
              : '0'
          }
        />
        <Workbook.Column label="결과" value={(ruc) => ruc.result} />
        <Workbook.Column
          label="챌린지 참가 수"
          value={(ruc) => ruc.registerCount}
        />
        <Workbook.Column
          label="인스타 계정"
          value={(ruc) => ruc.instagramInfo?.username}
        />
        <Workbook.Column
          label="팔로워 수"
          value={(ruc) => ruc.instagramInfo?.followerCount}
        />
        <Workbook.Column
          label="게시글 수"
          value={(ruc) => ruc.instagramInfo?.postCount}
        />
      </Workbook.Sheet>
    </Workbook>
  );
};

const SupportersChallengeWorkbook = ({ challenge, relationUserChallenges }) => {
  const isSupportersResultPublic = moment(challenge.registerEndDate)
    .add(10, 'm')
    .isBefore(moment());
  return (
    <Workbook
      filename={`${challenge.title}_참가자.xlsx`}
      element={
        <Button basic color="green" floated="right" size="mini">
          Excel
        </Button>
      }
    >
      <Workbook.Sheet data={relationUserChallenges} name="Sheet A">
        <Workbook.Column label="신청id" value={(ruc) => ruc.id} />
        <Workbook.Column
          label="신청시간"
          value={(ruc) => moment(ruc.createdAt).format('YYYY-MM-DD HH:mm:ss')}
        />
        <Workbook.Column label="유저id" value={(ruc) => ruc.userId} />
        <Workbook.Column label="email" value={(ruc) => ruc.userEmail} />
        <Workbook.Column label="이름" value={(ruc) => ruc.userName} />
        <Workbook.Column label="닉네임" value={(ruc) => ruc.userNickname} />
        <Workbook.Column
          label="핸드폰"
          value={(ruc) =>
            ruc.userPhone[0] !== '0'
              ? '0' + ruc.userPhone.substring(2)
              : ruc.userPhone
          }
        />
        <Workbook.Column label="디바이스id" value={(ruc) => ruc.userDeviceId} />
        <Workbook.Column label="생년월일" value={(ruc) => ruc.userBirthday} />
        <Workbook.Column label="성별" value={(ruc) => ruc.userSex} />
        <Workbook.Column
          label="탈퇴여부"
          value={(ruc) => (ruc.userIsDeleted ? '탈퇴' : '-')}
        />
        <Workbook.Column
          label="마케팅수신동의여부"
          value={(ruc) => (ruc.userIsMarketingPossible ? '동의' : '-')}
        />
        <Workbook.Column
          label="신청금(캐시)"
          value={(ruc) =>
            ruc.cashDeposit > 0 ? setCurrencyFormat(ruc.cashDeposit) : '0'
          }
        />
        <Workbook.Column
          label="신청금(상금)"
          value={(ruc) =>
            ruc.prizeDeposit > 0 ? setCurrencyFormat(ruc.prizeDeposit) : '0'
          }
        />
        <Workbook.Column
          label="신청금(카드)"
          value={(ruc) =>
            ruc.cardDeposit > 0 ? setCurrencyFormat(ruc.cardDeposit) : '0'
          }
        />
        <Workbook.Column
          label="신청금(이체)"
          value={(ruc) =>
            ruc.transferDeposit > 0
              ? setCurrencyFormat(ruc.transferDeposit)
              : '0'
          }
        />
        <Workbook.Column
          label="달성률"
          value={(ruc) =>
            `${ruc.achievementCountInfo.ok} / ${ruc.achievementCountInfo.present} (총 : ${ruc.achievementCountInfo.total} 개)`
          }
        />
        <Workbook.Column
          label="반환금(캐시)"
          value={(ruc) =>
            ruc.rucResult.returnCashPoint > 0
              ? setCurrencyFormat(ruc.rucResult.returnCashPoint)
              : '0'
          }
        />
        <Workbook.Column
          label="반환금(상금)"
          value={(ruc) =>
            ruc.rucResult.returnPrizePoint > 0
              ? setCurrencyFormat(ruc.rucResult.returnPrizePoint)
              : '0'
          }
        />
        <Workbook.Column
          label="반환금(카드)"
          value={(ruc) =>
            ruc.rucResult.returnCardPoint > 0
              ? setCurrencyFormat(ruc.rucResult.returnCardPoint)
              : '0'
          }
        />
        <Workbook.Column
          label="반환금(이체)"
          value={(ruc) =>
            ruc.rucResult.returnTransferPoint > 0
              ? setCurrencyFormat(ruc.rucResult.returnTransferPoint)
              : '0'
          }
        />
        <Workbook.Column
          label="상금"
          value={(ruc) =>
            ruc.rucResult.rewardPrizePoint > 0
              ? setCurrencyFormat(Math.ceil(ruc.rucResult.rewardPrizePoint))
              : '0'
          }
        />
        <Workbook.Column label="결과" value={(ruc) => ruc.result} />
        <Workbook.Column
          label="챌린지 참가 수"
          value={(ruc) => ruc.registerCount}
        />
        <Workbook.Column
          label="인스타 계정"
          value={(ruc) => ruc.instagramInfo?.username}
        />
        <Workbook.Column
          label="팔로워 수"
          value={(ruc) => ruc.instagramInfo?.followerCount}
        />
        <Workbook.Column
          label="게시글 수"
          value={(ruc) => ruc.instagramInfo?.postCount}
        />
        <Workbook.Column
          label="선정여부"
          value={(ruc) =>
            !isSupportersResultPublic
              ? '발표전'
              : ruc.customFields?.supporters?.isSelected
                ? '선정'
                : '미선정'
          }
        />
      </Workbook.Sheet>
    </Workbook>
  );
};
