import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getQueryKey } from './getQueryKey';
import { apis } from '../../../api';
import { QuerySelectHookOptions } from '../../query.types';
import { Payment } from '../../../container/Benefit/PedometerGiftiShop/PedometerGiftiShopBuyers/PedometerGiftiShopBuyers.types';

export const useGiftiShopPayments = (
  date: string,
  options?: QuerySelectHookOptions<typeof apis.getPedometerGiftiShopPayments>,
): UseQueryResult<Payment[]> =>
  useQuery({
    queryKey: getQueryKey('GIFTI_SHOP_PAYMENTS', { date }),
    queryFn: () => apis.getPedometerGiftiShopPayments(date),
    select: (data) => {
      if (!data) return [];
      return data.payments;
    },
    ...options,
  });
