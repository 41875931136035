import { displayTypeOptions } from '../../../../constant/bannerOptions';

export const getDisplayTypeOptions = (value?: string) => {
  const current = displayTypeOptions.find((o) => o.value === value);
  const supportedOptionList = displayTypeOptions.filter((o) =>
    ['ALL', 'ADMIN'].includes(o.value),
  );

  if (!current || supportedOptionList.find((o) => o.value === current.value))
    return supportedOptionList;
  return [...supportedOptionList, current];
};
