import { apis } from '@api/index';
import { reviewMessageOptions } from 'src/constant/reviewResult';
import React, { useState } from 'react';
import {
  Button,
  Dimmer,
  Dropdown,
  Form,
  Loader,
  Modal,
  TextArea,
} from 'semantic-ui-react';
import { UserAchievementInfo } from 'src/types/user.types';

const WarningModal = ({
  achievement,
  warningModalOpen,
  closeWarningModal,
}: {
  achievement: UserAchievementInfo['achievements'][0];
  warningModalOpen: boolean;
  closeWarningModal: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [comment, setComment] = useState('');

  const handleChangeCommentTemplate = (e: any, { value }: any) => {
    setMessage(value);
  };

  const handleChangeComment = (e: any) => {
    setComment(e.target.value);
  };

  const commentAchievement = async (e: any, { name }: any) => {
    setIsLoading(true);
    const res = await apis.commentAchievementFile(
      achievement.achievementFile.id,
      {
        reviewComment: comment,
        sendType: name,
      },
    );
    if ((res as any)?.response?.data?.err) {
      return;
    }
    closeWarningModal();
    setIsLoading(false);
    alert('댓글 및 문자 발송이 완료되었습니다.');
  };

  return (
    <Modal size="tiny" open={warningModalOpen} onClose={closeWarningModal}>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <Modal.Header>인증샷 경고 댓글</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>메세지 템플릿</label>
            <Dropdown
              placeholder="템플릿을 선택하세요."
              fluid
              search
              selection
              name="messageId"
              value={message}
              options={reviewMessageOptions}
              onChange={handleChangeCommentTemplate}
            />
          </Form.Field>
          <Form.Field>
            <label>댓글 내용을 작성해주세요.</label>
            <TextArea
              value={comment}
              rows={20}
              onChange={handleChangeComment}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="yellow"
          content="경고처리(푸시)"
          name="WARN"
          onClick={commentAchievement}
        />
        <Button
          color="blue"
          content="댓글작성(푸시)"
          name="PUSH"
          onClick={commentAchievement}
        />
        <Button
          color="blue"
          content="댓글작성(문자)"
          name="SMS"
          onClick={commentAchievement}
        />
        <Button color="black" content="닫기" onClick={closeWarningModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default WarningModal;
