import React, { useMemo } from 'react';
import moment from 'moment';
import { Button, Checkbox, Image, Label } from 'semantic-ui-react';
import { colors } from '../../../../constant/colors';
import { reviewResult } from '../../../../constant/reviewResult';
import FlexBox from '@component/FlexBox/FlexBox';

type Achievement = any;
type AchievementUrlCardProps = {
  achievement: Achievement;
  onCheckboxPress?: (
    event: React.FormEvent<HTMLInputElement>,
    data: any,
  ) => void;
  onAdminCommentPress: (
    event: React.MouseEvent<HTMLButtonElement>,
    data: any,
  ) => void;
  onReportPress: (
    event: React.MouseEvent<HTMLButtonElement>,
    data: any,
  ) => void;
  onReviewPress: (
    event: React.MouseEvent<HTMLButtonElement>,
    data: any,
  ) => void;
  onDeletePress: (
    event: React.MouseEvent<HTMLButtonElement>,
    data: any,
  ) => void;
  onCommentPress: (
    event: React.MouseEvent<HTMLButtonElement>,
    data: any,
  ) => void;
  onAllAchievementUrlsPress: (
    event: React.MouseEvent<HTMLButtonElement>,
    data: any,
  ) => void;
  onPassPress: (event: React.MouseEvent<HTMLButtonElement>, data: any) => void;
  onPassCancelPress: (
    event: React.MouseEvent<HTMLButtonElement>,
    data: any,
  ) => void;
  size?: 'small' | 'medium' | 'large';
};

const AchievementUrlCard = (props: AchievementUrlCardProps) => {
  const {
    achievement,
    onCheckboxPress,
    onAdminCommentPress,
    onReportPress,
    onReviewPress,
    onDeletePress,
    onCommentPress,
    onAllAchievementUrlsPress,
    onPassPress,
    onPassCancelPress,
    size,
  } = props;

  const url = useMemo(() => {
    return achievement.achievementFile.memo;
  }, [achievement]);
  return (
    <div>
      <Image fluid src={achievement.achievementFile.url} size={size} />
      <a href={url} target="_blank" rel="noopener noreferrer">
        링크 확인하기
      </a>
      <div style={{ position: 'absolute', top: 20, left: 20, padding: 5 }}>
        <Label color="blue" size="tiny">
          서버시간{' '}
          {moment(achievement.achievementFile.createdAt).format(
            'YYYY-MM-DD HH:mm:ss',
          )}
        </Label>
        <Label color="teal" size="tiny">
          제출시간{' '}
          {moment(achievement.achievementFile.submitDatetime).format(
            'YYYY-MM-DD HH:mm:ss',
          )}
        </Label>
      </div>
      {onCheckboxPress && (
        <div style={{ position: 'absolute', top: 20, right: 20, padding: 5 }}>
          <Checkbox
            checked={achievement.isChecked}
            value={achievement.achievementFile.id}
            onChange={onCheckboxPress}
          />
        </div>
      )}
      <div>
        {achievement.id} ({achievement.achievementFile.id})
      </div>
      <div>
        ({moment(achievement.dueStartDate).format('YY.MM.DD')}{' '}
        {achievement.dueStartTime}
        <br />~{moment(achievement.dueEndDate).format('YY.MM.DD')}{' '}
        {achievement.dueEndTime})
      </div>
      <FlexBox.Row pb={2}>
        <div
          style={{
            paddingRight: 4,
          }}
        >
          {achievement.achievementFile.reviewResult === reviewResult.before && (
            <Label>리뷰전</Label>
          )}
          {achievement.achievementFile.reviewResult === reviewResult.pass && (
            <Label>통과</Label>
          )}
          {achievement.achievementFile.reviewResult === reviewResult.red && (
            <Label>레드</Label>
          )}
          {achievement.achievementFile.reviewResult === reviewResult.fail && (
            <Label>미인증</Label>
          )}
          {achievement.achievementFile.reviewResult ===
            reviewResult.comment && <Label>댓글</Label>}
          {onAdminCommentPress && (
            <Button
              icon="comment"
              size="mini"
              content={achievement.achievementFile.comments.length}
              value={achievement.achievementFile.comments}
              onClick={onAdminCommentPress}
            />
          )}
          {onReportPress && (
            <Button
              icon="warning sign"
              size="mini"
              content={achievement.achievementFile.reports.length}
              value={achievement.achievementFile.reports}
              onClick={onReportPress}
            />
          )}
        </div>
        <Button
          size="mini"
          color="red"
          name={reviewResult.red}
          value={achievement}
          floated="left"
          onClick={onReviewPress}
        >
          Red
        </Button>
        <Button
          size="mini"
          color="blue"
          name={reviewResult.pass}
          value={achievement}
          floated="left"
          onClick={onReviewPress}
        >
          성공처리
        </Button>
        <Button
          basic
          size="mini"
          floated="left"
          value={achievement}
          onClick={onDeletePress}
        >
          인증샷삭제
        </Button>
      </FlexBox.Row>
      <FlexBox.Row>
        <Button
          basic
          size="mini"
          floated="left"
          value={achievement}
          onClick={onCommentPress}
        >
          경고댓글
        </Button>
        <Button
          basic
          size="mini"
          floated="left"
          value={achievement.id}
          onClick={onAllAchievementUrlsPress}
        >
          모든인증샷보기
        </Button>
        {!achievement.transactionItem ? (
          <Button
            basic
            size="mini"
            floated="left"
            value={achievement}
            onClick={onPassPress}
          >
            인증패스용
          </Button>
        ) : (
          <p style={{ color: colors.PRIMARY_500 }}>
            <br />
            {moment(achievement.transactionItem.createdAt).format(
              'YYYY-MM-DD HH:mm:ss',
            )}
            <Button
              basic
              size="mini"
              floated="left"
              value={achievement}
              onClick={onPassCancelPress}
            >
              인증패스취소
            </Button>
          </p>
        )}
      </FlexBox.Row>
    </div>
  );
};

export default AchievementUrlCard;
