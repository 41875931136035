import Button from '@component/Button';
import CheckBox from '@component/CheckBox';
import ErrorMsg from '@component/ErrorMsg';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import NumberInput from '@component/NumberInput';
import TextInput from '@component/TextInput';
import { Divider, Icon } from 'semantic-ui-react';
import { ChallengeCustomField } from 'src/types/customField.types';
import {
  ChallengeCollaboQuestion,
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { DEFAULT_REVIEW_QUESTION } from './CollaborationForm.constant';
import {
  convertData2Form,
  convertForm2Data,
} from './CollaborationForm.serializer';
import {
  useCollaboration,
  useCollaborationAction,
} from './CollaborationForm.store';
import * as S from './CollaborationForm.style';
import { validate } from './CollaborationForm.validator';
import { useEditCheck } from '../../hooks/useEditCheck';

const CollaborationForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'collaboration'>,
  Extract<ChallengeCustomField, { name: 'collaboration' }>
> = () => {
  const { collaboration, thirdTermUrl, companyId } = useCollaboration();
  const {
    setReviewRewardText,
    setCollaboProductName,
    setCollaboProductLink,
    setReviewQuestionList,
  } = useCollaborationAction();

  const validated = validate({ collaboration, thirdTermUrl, companyId });
  const { getBorder, triggerEdited } = useEditCheck();

  const updateReviewQuestion = (
    index: number,
    reviewQuestion: ChallengeCollaboQuestion,
  ) => {
    const updatedReviewQuestionList = [...collaboration.reviewQuestionList];
    updatedReviewQuestionList[index] = reviewQuestion;
    setReviewQuestionList(updatedReviewQuestionList);
  };

  const addReviewQuestion = () => {
    setReviewQuestionList([
      ...collaboration.reviewQuestionList,
      DEFAULT_REVIEW_QUESTION,
    ]);
  };

  const removeReviewQuestion = (index: number) => {
    setReviewQuestionList(
      collaboration.reviewQuestionList.filter((_, i) => i !== index),
    );
  };

  const swapReviewQuestion = (aIndex: number, bIndex: number) => {
    if (aIndex < 0 || aIndex >= collaboration.reviewQuestionList.length) return;
    if (bIndex < 0 || bIndex >= collaboration.reviewQuestionList.length) return;

    const updatedList = [...collaboration.reviewQuestionList];
    // eslint-disable-next-line prefer-destructuring
    updatedList[aIndex] = updatedList.splice(bIndex, 1, updatedList[aIndex])[0];
    setReviewQuestionList(updatedList);
  };

  return (
    <FlexBox.Column gap={12}>
      <h4>태그 CRM 탬플릿 관련 내용</h4>

      <TextInput
        label="리뷰 제품명"
        value={collaboration.collaboProductName || undefined}
        onChange={(e) => {
          triggerEdited('collaboProductName');
          setCollaboProductName(e);
        }}
        style={{ border: getBorder('collaboProductName') }}
      />
      <TextInput
        label="제품 구매 주소"
        value={collaboration.collaboProductLink || undefined}
        onChange={(e) => {
          triggerEdited('collaboProductLink');
          setCollaboProductLink(e);
        }}
        style={{ border: getBorder('collaboProductLink') }}
      />

      <Divider />

      <FlexBox.Column gap={12}>
        <h4>제휴 챌린지 리뷰 세팅</h4>

        <ErrorMsg text={!validated.isValid ? validated.message : ''} />

        <TextInput
          label="리뷰 리워드 안내 문구(리뷰 세팅시 필수 값)"
          value={collaboration.reviewRewardText || undefined}
          onChange={setReviewRewardText}
        />
        {collaboration.reviewQuestionList.map((reviewQuestion, index) => {
          const { placeholder, question, reviewMinLength, isDisplayed } =
            reviewQuestion;
          return (
            <S.ReviewQuestionItemContainer key={index}>
              <FlexBox.Row gap={6} alignItems="center">
                <Label>{`리뷰${index + 1}`}</Label>
                <S.IconButton
                  onClick={() => swapReviewQuestion(index - 1, index)}
                >
                  <Icon name="angle up" />
                </S.IconButton>
                <S.IconButton
                  onClick={() => swapReviewQuestion(index, index + 1)}
                >
                  <Icon name="angle down" />
                </S.IconButton>
                <S.IconButton onClick={() => removeReviewQuestion(index)}>
                  <Icon name="delete" size="small" />
                </S.IconButton>
              </FlexBox.Row>
              <TextInput
                label="질문"
                value={question}
                onChange={(text) =>
                  updateReviewQuestion(index, {
                    ...reviewQuestion,
                    question: text,
                  })
                }
              />
              <TextInput
                label="Placeholder"
                value={placeholder}
                onChange={(text) =>
                  updateReviewQuestion(index, {
                    ...reviewQuestion,
                    placeholder: text,
                  })
                }
              />
              <NumberInput
                label="답변 최소 글자 수"
                value={reviewMinLength}
                onChange={(minLength) =>
                  updateReviewQuestion(index, {
                    ...reviewQuestion,
                    reviewMinLength: Number(minLength),
                  })
                }
              />
              <CheckBox
                label="리뷰 노출 여부"
                checked={isDisplayed}
                onChange={(value) =>
                  updateReviewQuestion(index, {
                    ...reviewQuestion,
                    isDisplayed: value,
                  })
                }
              />
            </S.ReviewQuestionItemContainer>
          );
        })}
        <Button text="모듈 추가" onClick={addReviewQuestion} />
      </FlexBox.Column>
    </FlexBox.Column>
  );
};

export default CollaborationForm;
CollaborationForm.validate = validate;
CollaborationForm.convertData2Form = convertData2Form;
CollaborationForm.convertForm2Data = convertForm2Data;
