import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { setRoles, setToken, setUser } from './App.action';
import api, { apiMedia, apiGo, setAuthToken, apiV3 } from '../../api';
import { setLocal } from '../../global/local';
import parseToken from '../../global/parseToken';
import { InitStore } from '../../store';

const AppDidMount = (
  token: string | null,
): ThunkAction<Promise<boolean>, InitStore, void, Action<unknown>> => {
  return async (dispatch) => {
    try {
      if (!token) throw new Error();
      setAuthToken(api, token);
      setAuthToken(apiMedia, token);
      setAuthToken(apiGo, token);
      setAuthToken(apiV3, token);
      const response = await api.get('/admin/users/me');
      const { user, token: newToken } = response.data.data;
      const tokenData = parseToken(newToken);
      if (new Date() > new Date(Math.floor(tokenData.exp * 1000)))
        throw new Error();

      setAuthToken(api, newToken);
      setAuthToken(apiMedia, newToken);
      setAuthToken(apiGo, newToken);
      setAuthToken(apiV3, newToken);
      setLocal('newToken', newToken);
      dispatch(setToken(newToken));
      dispatch(setRoles(user.roles));
      dispatch(setUser(user));
      return await Promise.resolve(true);
    } catch (e) {
      dispatch(setToken(''));
      dispatch(setRoles([]));
      localStorage.clear();
      return Promise.resolve(false);
    }
  };
};

const AppThunk = (
  dispatch: ThunkDispatch<InitStore, void, Action<unknown>>,
) => ({
  AppDidMount: (token: string | null) => dispatch(AppDidMount(token)),
});

export default AppThunk;
