import React from 'react';
import Spinner from '@component/Spinner';
import * as S from './Button.style';
import { BUTTON_HEIGHT } from './Button.constant';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  text?: string;
  loading?: boolean;
  size?: keyof typeof BUTTON_HEIGHT | number;
  fontSize?: number;
  fontColor?: string;
  bgColor?: string;
  width?: number;
  height?: number;
  rounded?: number;
};

const Button = (props: ButtonProps) => {
  const {
    text,
    loading,
    disabled,
    style,
    size = 'medium',
    fontSize,
    fontColor = '#222',
    bgColor = '#eee',
    children,
    width,
    height,
    rounded,
    ...restProps
  } = props;
  return (
    <S.Container
      disabled={disabled}
      style={{
        height: height
          ? `${height}px`
          : typeof size === 'number'
            ? size
            : BUTTON_HEIGHT[size],
        fontSize,
        color: fontColor,
        backgroundColor: bgColor,
        pointerEvents: loading ? 'none' : 'auto',
        width: width ? `${width}px` : 'auto',
        borderRadius: rounded ?? '6px',
        ...style,
      }}
      {...restProps}
    >
      {loading ? <Spinner size={30} /> : text || children}
    </S.Container>
  );
};

export default Button;
