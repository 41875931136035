import Button from '@component/Button';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import ChipList from '@component/ChipList';
import NumberInput from '@component/NumberInput';
import { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { Question } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { QuestionType } from 'src/types/achievement.types';
import * as S from './QnaQuestionItem.style';
import * as CONSTS from '../../QnaForm.constant';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import ShortAnswerQuestion from './ShortAnswerQuestion';
import EssayAnswerQuestion from './EssayAnswerQuestion';

type QnaQuestionItemProps = {
  achievementIndex: number;
  questions: Question[];
  onAchievementIndexChange: (achievementIndex: number) => void;
  onQuestionAdd: <T extends Question>(question: Omit<T, 'id'>) => void;
  onQuestionUpdate: <T extends keyof Question>(input: {
    questionIndex: number;
    fieldName: T;
    value: Question[T];
  }) => void;
  onRemove: () => void;
};

const QnaQuestionItem = (props: QnaQuestionItemProps) => {
  const {
    achievementIndex,
    questions,
    onAchievementIndexChange,
    onQuestionAdd,
    onQuestionUpdate,
    onRemove,
  } = props;
  const [newIndex, setNewIndex] = useState<number>(0);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const achievementIndexExists = achievementIndex !== 0;

  const handleEnter: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key !== 'Enter') return;
    onAchievementIndexChange(newIndex);
    setNewIndex(0);
  };

  const handleSetQuestion = (questionType: QuestionType) => {
    switch (questionType) {
      case QuestionType.MULTIPLE_CHOICE_ANSWER_EXIST:
        onQuestionAdd(CONSTS.multipleChoiceAnswerExistTemplate as Question);
        break;
      case QuestionType.MULTIPLE_CHOICE_NO_ANSWER:
        onQuestionAdd(CONSTS.multipleChoiceNoAnswerTemplate as Question);
        break;
      case QuestionType.SHORT_ANSWER:
        onQuestionAdd(CONSTS.shortAnswerTemplate as Question);
        break;
      case QuestionType.ESSAY_ANSWER:
        onQuestionAdd(CONSTS.essayAnswerTemplate as Question);
        break;
      default:
    }
    hideModal();
  };

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);

  return (
    <>
      <S.QuestionContainer>
        <Button text="삭제" size="small" onClick={onRemove} />
        <FlexBox.Row style={{ gap: 12, alignItems: 'center', marginTop: 12 }}>
          <Label>인증회차</Label>
          {achievementIndexExists ? (
            <ChipList
              chipList={[String(achievementIndex)]}
              removable
              onRemovePress={() => onAchievementIndexChange(0)}
            />
          ) : (
            <NumberInput
              style={{ width: 200 }}
              value={newIndex}
              onChange={(value) => setNewIndex(Number(value))}
              placeholder="번호 입력 후 Enter"
              onKeyDown={handleEnter}
              min={0}
            />
          )}
        </FlexBox.Row>
        <Button
          text="질문 추가"
          onClick={showModal}
          style={{ marginTop: 12 }}
        />
        {questions.map((question, index) => {
          switch (question.type) {
            case QuestionType.MULTIPLE_CHOICE:
              return (
                <MultipleChoiceQuestion
                  key={question.id}
                  question={question}
                  questionIndex={index}
                  onQuestionChange={({ fieldName, value }) =>
                    onQuestionUpdate({
                      questionIndex: index,
                      fieldName: fieldName as keyof Question,
                      value: value as string | number,
                    })
                  }
                />
              );
            case QuestionType.SHORT_ANSWER:
              return (
                <ShortAnswerQuestion
                  key={question.id}
                  question={question}
                  questionIndex={index}
                  onQuestionChange={({ fieldName, value }) =>
                    onQuestionUpdate({
                      questionIndex: index,
                      fieldName: fieldName as keyof Question,
                      value: value as string | number,
                    })
                  }
                />
              );
            case QuestionType.ESSAY_ANSWER:
              return (
                <EssayAnswerQuestion
                  key={question.id}
                  question={question}
                  questionIndex={index}
                  onQuestionChange={({ fieldName, value }) =>
                    onQuestionUpdate({
                      questionIndex: index,
                      fieldName: fieldName as keyof Question,
                      value: value as string | number,
                    })
                  }
                />
              );
            default:
              return null;
          }
        })}
      </S.QuestionContainer>

      <Modal size="small" open={modalVisible} onClose={hideModal}>
        <Modal.Header>질문 유형 선택</Modal.Header>
        <Modal.Content>
          <FlexBox.Row gap={12}>
            {CONSTS.questionOptions.map((option, index) => (
              <Button
                text={option.text}
                key={index}
                onClick={() => handleSetQuestion(option.value)}
              />
            ))}
          </FlexBox.Row>
        </Modal.Content>
        <Modal.Actions>
          <Button
            bgColor="#000000"
            fontColor="#ffffff"
            text="닫기"
            onClick={hideModal}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
export default QnaQuestionItem;
