import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Input,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { apis } from '../../../api';
import BenefitMenu from '../BenefitMenu';
import { copyToClipboard } from '../../../utils/js.utils';

const GotchaWinners = () => {
  const [date, setDate] = useState(() => new Date());

  const [loading, setLoading] = useState(true);

  const [winners, setWinners] = useState([]);

  const [filter, setFilter] = useState(null);

  const [checkedUserIds, setCheckedUserIds] = useState([]);

  const loadData = (date) => {
    setLoading(true);
    apis.getGotchaWinners(date).then((response) => {
      setWinners(response.winners);
      setLoading(false);
    });
  };

  const filteredWinners = filter
    ? winners.filter((v) => v.reward === filter)
    : winners;

  useEffect(() => {
    loadData(date);
  }, [date]);

  const allChecked = filteredWinners
    .filter((v) => !v.isReceived)
    .every((w) => checkedUserIds.includes(w.id));

  const filters = [...new Set(winners.map((v) => v.reward))]
    .map((reward) => ({
      text: reward,
      value: reward,
    }))
    .reduce(
      (acc, curr) => {
        acc.push(curr);
        return acc;
      },
      [{ text: '필터없음', value: null }],
    );

  return (
    <div style={{ margin: 40 }}>
      <Grid columns="equal">
        <Grid.Column width={2}>
          <BenefitMenu />
        </Grid.Column>
        <Grid.Column>
          <Grid.Row style={{ paddingBottom: 20 }}>
            <h1>행운뽑기 당첨자 목록</h1>
          </Grid.Row>
          <Grid.Row style={{ paddingBottom: 20 }}>
            <Form.Field>
              <label>날짜</label>
              <Input
                type="date"
                name="date"
                value={date}
                style={{ marginLeft: 10 }}
                onChange={(_, { value }) => {
                  setCheckedUserIds([]);
                  setFilter(null);
                  setDate(value);
                }}
              />
            </Form.Field>
          </Grid.Row>

          <Item header>
            <Checkbox
              checked={allChecked}
              onClick={() => {
                if (allChecked) return setCheckedUserIds([]);
                setCheckedUserIds(
                  filteredWinners.filter((v) => !v.isReceived).map((v) => v.id),
                );
              }}
            />
            <Row>{`ID`}</Row>
            <Row>{`이름`}</Row>
            <Row>
              {`리워드`}
              <Dropdown
                selection
                placeholder={'필터'}
                style={{ backgroundColor: '#eeeeee', marginLeft: 8 }}
                options={filters}
                value={filter}
                onChange={(e, { value }) => setFilter(value)}
              />
            </Row>

            <Row>{`핸드폰`}</Row>
            <Row>{`유저 ID`}</Row>
          </Item>

          {!loading && filteredWinners.length === 0 && (
            <Row style={{ padding: 40, color: '#aaaaaa', fontSize: 40 }}>
              <div>{`당첨자가 없어요 🤓😛`}</div>
            </Row>
          )}

          {filteredWinners.map((v, index) => {
            return (
              <WinnerItem
                {...v}
                checked={checkedUserIds.includes(v.id)}
                onClick={() => {
                  if (v.isReceived) return;

                  return setCheckedUserIds((prev) => {
                    return prev.includes(v.id)
                      ? prev.filter((i) => i !== v.id)
                      : [...prev, v.id];
                  });
                }}
                key={`${index}-${v.id}`}
              />
            );
          })}
          <Row style={{ padding: 20 }}>
            <Button
              color={'black'}
              disabled={!checkedUserIds.length}
              content={
                checkedUserIds.length
                  ? `${checkedUserIds.length}개 항목 지급처리👏`
                  : '항목을 선택 해주세요🙏'
              }
              size={'large'}
              onClick={async () => {
                const agree = window.confirm(
                  `정말로 ${checkedUserIds.length}개 항목을 일괄 처리 하시겠어요?\n이 동작은 다시 취소할 수 없어요`,
                );
                if (!agree) return window.alert('취소 했어요');
                await apis.checkGotchaWinners(checkedUserIds);
                window.alert(`${checkedUserIds.length}개 처리 완료 ✌️`);
                loadData(date);
                setCheckedUserIds([]);
              }}
            />
            <Button
              color={'facebook'}
              disabled={!checkedUserIds.length}
              content={
                checkedUserIds.length
                  ? `${checkedUserIds.length}개 핸드폰 번호 복사`
                  : '항목을 선택 해주세요🙏'
              }
              size={'large'}
              onClick={async () => {
                const phones = checkedUserIds
                  .map((id) => winners.find((v) => v.id === id).phone)
                  .filter((v) => !!v);

                const isAllCopied = checkedUserIds.length === phones.length;

                const phoneNumberValid = phones.every((v) => v.length === 11);

                copyToClipboard(phones.join('\n')).then(() => {
                  window.alert(
                    (isAllCopied
                      ? '👍 전체 복사 완료'
                      : `🚨 ${
                          checkedUserIds.length - phones.length
                        }개 누락된 핸드폰 번호가 있어요`) +
                      (phoneNumberValid
                        ? '\n👍 폰번호 모두 정상'
                        : '\n🚨 이상한 핸드폰 번호가 포함되어 있어요‼️'),
                  );
                });
              }}
            />
          </Row>
        </Grid.Column>
      </Grid>
    </div>
  );
};

const WinnerItem = ({
  checked,
  onClick,
  id,
  name,
  reward,
  userId,
  phone,
  isReceived,
  disabled,
}) => {
  return (
    <Item onClick={onClick} isReceived={isReceived}>
      <Checkbox checked={checked} disabled={disabled} />
      <Row>{`${id}`}</Row>
      <Row>{`${name}`}</Row>
      <Row>{`${reward}`}</Row>
      <Row>{`${phone}`}</Row>
      <Row>{`${userId}`}</Row>
    </Item>
  );
};

const Item = styled.div`
  width: 100%;
  height: 58px;
  flex: 1;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  background-color: ${({ header }) => (header ? '#eeeeee' : '#ffffff')};
  font-weight: ${({ header }) => (header ? 'bold' : 'normal')};
  color: ${({ isReceived }) => (isReceived ? '#00000088' : '#000000')};
  padding: 8px;
  text-decoration: ${({ isReceived }) =>
    isReceived ? 'line-through' : 'none'};

  &:hover {
    background-color: #eeeeee44;
  }
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default GotchaWinners;
