import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const WeightContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-grow: 1;
`;

export const SelectionDoneDim = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  background: #00000030;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
`;
