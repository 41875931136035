import { ServiceCategory, PurchaseChannel } from '@types';

export const enum ServiceType {
  challenge = 'CHALLENGE',
  store = 'STORE',
  race = 'RACE',
}

export const fileUrl = {
  eventPointFormat:
    'https://d1cu54ww3s1tp1.cloudfront.net/development/admin/230314_%E1%84%91%E1%85%A9%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%90%E1%85%B3+%E1%84%8C%E1%85%B5%E1%84%80%E1%85%B3%E1%86%B8+%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.xlsx',
  temporaryCashFormat:
    'https://d1cu54ww3s1tp1.cloudfront.net/development/admin/240122_%E1%84%80%E1%85%B5%E1%84%80%E1%85%A1%E1%86%AB%E1%84%92%E1%85%A1%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%8F%E1%85%A2%E1%84%89%E1%85%B5_%E1%84%8C%E1%85%B5%E1%84%80%E1%85%B3%E1%86%B8%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.xlsx',
};

export const serviceCategoryOptions: {
  key: number;
  text: string;
  value: ServiceCategory;
}[] = [
  { key: 0, text: '선택 안함', value: undefined },
  { key: 1, text: '뷰티', value: 'BEAUTY' },
  { key: 2, text: '헬스/푸드', value: 'HEALTH_FOOD' },
  { key: 3, text: '라이프', value: 'LIFE' },
];

export const serviceChannelOptions: {
  key: number;
  text: string;
  value: PurchaseChannel;
}[] = [
  { key: 0, text: '선택 안함', value: undefined },
  { key: 1, text: '올리브영', value: 'OLIVE_YOUNG' },
  { key: 2, text: '스마트 스토어', value: 'NAVER_SMART_STORE' },
  { key: 3, text: '쿠팡', value: 'COUPANG' },
  { key: 5, text: '마켓컬리', value: 'KURLY' },
  { key: 6, text: '카카오 선물하기', value: 'KAKAO_GIFT' },
  { key: 7, text: '카카오 쇼핑', value: 'KAKAO_SHOPPING' },
  { key: 8, text: '무신사', value: 'MUSINSA' },
  { key: 9, text: '29cm', value: '29CM' },
  { key: 10, text: '에이블리', value: 'ABLY' },
  { key: 11, text: 'SSG', value: 'SSG' },
  { key: 12, text: '오늘의집', value: 'O_HOUSE' },
  { key: 13, text: '지마켓', value: 'G_MARKET' },
  { key: 14, text: '롯데온', value: 'LOTTE_ON' },
  { key: 15, text: '롯데홈쇼핑', value: 'LOTTE_HOME_SHOPPING' },
  { key: 16, text: '홈플러스', value: 'HOMEPLUS' },
  { key: 17, text: '그외 (자사몰)', value: 'ETC' },
];
