import { format } from 'date-fns';

/**
 * 한국 date time을 Z 없는 ISO 8601 형식으로 변환
 */
export const formatLocalDateTime = (date: Date) =>
  format(date, 'yyyy-MM-dd HH:mm:ss').split(' ').join('T');
// date-fns ISO 형식으로 바꿔주는 format 찾아서 적용해보기

/**
 * 한국 date time의 format을 Date 객체로 변환
 */
export const parseLocalDateTime = (date: string) => new Date(date);

export const formatTimeExcludeSeconds = (date: Date) =>
  date.toISOString().slice(0, 19) + 'Z';

export const formatTimeWithSeconds = (date: Date) =>
  format(date, `yyyy-MM-dd'T'HH:mm:ss`);
