import React, { Component, useEffect, useState } from 'react';
import { Button, Container, Grid } from 'semantic-ui-react';
import BoardItem from './BoardItem';
import { Link } from 'react-router-dom';
import { apis } from '../../api';
import Faq from './Faq';
import BoardMenu from './BoardMenu/BoardMenu';

const Board = ({ location }) => {
  const [state, setState] = useState({
    activeItem: '전체',
    boards: [],
    activeBoards: [],
  });

  const filterBoards = async (activeItem) => {
    const _state = _.cloneDeep(state);

    const { boards } = _state;

    if (activeItem === '전체') {
      let activeBoards = boards.filter((board) => {
        return board.isDeleted === false;
      });
      setState({ ..._state, activeBoards });
    } else if (activeItem === '공지사항') {
      let activeBoards = boards.filter((board) => {
        return board.boardCategory.id === 1 && board.isDeleted === false;
      });
      setState({ ..._state, activeBoards });
    } else if (activeItem === '이벤트') {
      let activeBoards = boards.filter((board) => {
        return board.boardCategory.id === 2 && board.isDeleted === false;
      });
      setState({ ..._state, activeBoards });
    } else if (activeItem === '삭제') {
      let activeBoards = boards.filter((board) => {
        return board.isDeleted === true;
      });
      setState({ ..._state, activeBoards });
    }
  };

  const handleItemClick = (e, { name }) => {
    const _state = _.cloneDeep(state);
    setState({ ..._state, activeItem: name });
  };

  const filterDeletedBoard = (boardId) => {
    const _state = _.cloneDeep(state);

    const { boards, activeBoards } = _state;
    const _boards = boards.filter((b) => b.id !== boardId);
    const _activeBoards = activeBoards.filter((b) => b.id !== boardId);
    setState({ ..._state, boards: _boards, activeBoards: _activeBoards });
  };

  const list = state.activeBoards.map((board, idx) => (
    <BoardItem
      key={idx}
      board={board}
      filterDeletedBoard={filterDeletedBoard}
    />
  ));

  useEffect(() => {
    const _state = _.cloneDeep(state);
    if (location.state) {
      setState({ ..._state, activeItem: location.state });
    }
    apis.getAllBoards().then(({ boards }) => {
      setState({ ..._state, boards, activeBoards: boards });
    });
  }, []);

  useEffect(() => {
    (async () => {
      await filterBoards(state.activeItem);
    })();
  }, [state.activeItem]);

  return (
    <Container>
      <Grid columns="equal">
        <Grid.Column width={2}>
          <BoardMenu />
        </Grid.Column>
        <Grid.Column width={2}>
          <Grid.Row>
            <Grid.Column verticalAlign="bottom">
              공지글 개수 : {state.boards.length}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button as={Link} to={{ pathname: 'create/boards' }} fluid>
                게시글 추가
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column>{list}</Grid.Column>
      </Grid>
    </Container>
  );
};

export default Board;

const styles = {
  cardSummary: {
    marginBottom: 5,
  },
  breadcrumb: {
    marginBottom: 10,
  },
};
