import FlexBox from '@component/FlexBox/FlexBox';
import Preview from './Preview/Preview';
import Label from '@component/Label';
import { Race } from '@types';

const RecordSettingField = (props: RecordSettingFieldProps) => {
  const { recordRaceParameter, handleChange } = props;

  return (
    <>
      <h3>기록 대회 세팅</h3>
      <Preview />
      <FlexBox.Row gap={4} style={{ marginBottom: 20 }}>
        <FlexBox.Column style={{ flex: 1 }}>
          <Label>기록 대회 종류</Label>
          <input
            value={recordRaceParameter?.recordType}
            placeholder="기록 대회 종류를 입력하세요"
            onChange={(e) => handleChange('recordType', e.target.value)}
          />
        </FlexBox.Column>

        <FlexBox.Column style={{ flex: 1 }}>
          <Label>기록 행위명(4글자 이하 권장)</Label>
          <input
            value={recordRaceParameter?.recordText}
            placeholder="기록 행위명을 입력하세요"
            onChange={(e) => handleChange('recordText', e.target.value)}
          />
        </FlexBox.Column>
      </FlexBox.Row>
    </>
  );
};

type RecordRaceParameter = {
  recordType: string;
  recordText: string;
};

type RecordSettingFieldProps = {
  recordRaceParameter?: RecordRaceParameter;
  handleChange: <K extends keyof Race.RecordRaceParameter>(
    key: K,
    value: Race.RecordRaceParameter[K],
  ) => void;
};

export default RecordSettingField;
