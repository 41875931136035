import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'startDate'>,
  Pick<ChallengeFormType, 'startDate'>
> = (data) => ({ startDate: data.startDate });

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'startDate'>,
  Pick<ReqCreateOrUpdateChallenge, 'startDate'>
> = (form) => {
  if (!form.startDate)
    throw Error("[StartDateForm] this shouldn't be happened");
  return { startDate: form.startDate };
};
