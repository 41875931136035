import { apis } from '@api/index';
import ConfirmPopup from '@component/ConfirmPopup';
import FlexBox from '@component/FlexBox/FlexBox';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import { memo, useMemo } from 'react';
import { Radio } from 'semantic-ui-react';

const DisplaySetting = ({ preAlaramId, isAdmin, refetch, isSent }: Props) => {
  const handleChangeDisplay = (_isAdmin: boolean) => {
    if (_isAdmin === isAdmin) return;
    if (isSent) {
      return alert('이미 발송된 사전 알림은 노출 설정을 변경할 수 없습니다.');
    }
    ConfirmPopup.show({
      title: `사전 알림 ${_isAdmin ? '어드민 ' : ''}노출`,
      description: <Description isAdmin={_isAdmin} />,
      onConfirm: async () => {
        LoadingIndicator.show();
        const res = await apis.preAlarm.updatePreAlarmIsAdmin(preAlaramId, {
          isAdmin: _isAdmin,
        });
        if (res?.success) {
          refetch();
          alert(`${_isAdmin ? '어드민 ' : ''}노출 되었습니다.`);
        } else {
          alert('노출 실패했습니다.');
        }
        LoadingIndicator.hide();
        ConfirmPopup.hide();
      },
    });
  };
  return (
    <>
      <FlexBox.Row gap={20} alignItems="center">
        <FlexBox.Row gap={12}>
          <Radio
            label="어드민"
            checked={isAdmin === true}
            disabled={isSent}
            onClick={() => handleChangeDisplay(true)}
          />
          <Radio
            label="전체"
            checked={isAdmin === false}
            disabled={isSent}
            onClick={() => handleChangeDisplay(false)}
          />
        </FlexBox.Row>
      </FlexBox.Row>
    </>
  );
};

type Props = {
  preAlaramId: number;
  isAdmin: boolean;
  isSent: boolean;
  refetch: () => void;
};

export default DisplaySetting;

const Description = memo(({ isAdmin }: { isAdmin: boolean }) => {
  return isAdmin ? (
    <div>
      어드민 상태로 변경하면
      <br />
      이미 신청한 유저들에게{' '}
      <span
        style={{
          color: 'red',
        }}
      >
        사전알림이 노출되지 않고 알림도 가지 않아요
      </span>
      <br />
      어드민으로 변경하시겠어요?
    </div>
  ) : (
    <>사전 알림 챌린지를 유저들에게 노출하시겠어요?</>
  );
});
