export const moduleType = {
  imageList: 'ImageListModule',
  cautionModule: 'CautionModule',
  descriptionButtonModule: 'DescriptionButtonModule',
  descriptionListModule: 'DescriptionListModule',
  descriptionModule: 'DescriptionModule',
  titleModule: 'TitleModule',
  mainImageListModule: 'MainImageListModule',
  achievementExampleModule: 'AchievementExampleModule',
  jsxObjectTitleModule: 'JsxObjectTitleModule',
  hashTagDescriptionModule: 'HashTagDescriptionModule',
  missionReAchievementModule: 'MissionReAchievementModule',
  homeButtonInRaceDetailModule: 'HomeButtonInRaceDetailModule',
};

export const modules = [
  {
    type: moduleType.descriptionModule,
    text: '',
    label: '설명',
  },
  {
    type: moduleType.descriptionButtonModule,
    url: '',
    label: '외부 링크 (검정색 CTA 버튼 이동 URL)',
  },
  { type: moduleType.imageList, imageUrlList: [], label: '이미지 모듈' },
  {
    type: moduleType.achievementExampleModule,
    imageUrl: '',
    label: '인증샷 예시 모듈',
  },
  {
    type: moduleType.cautionModule,
    cautionList: [
      '미션은 회원별로 다르게 노출됩니다.',
      '무관한 인증 사진으로 회사가 판단하는 경우, 미인증 처리 될 수 있습니다.',
      '챌린지 미션의 경우 챌린지 참여 이후 취소할 경우 혜택탭 내에서 재신청 불가합니다.',
      '만 19세 이상 참여 가능한 미션의 경우 미성년자는 미션 참여에 제한이 있을 수 있습니다.',
      '지급된 포인트는 [혜택탭 → 상단 포인트]에서 확인할 수 있으며, 미션 달성 후 포인트 지급까지 시간이 다소 지연될 수 있습니다.',
      '부적절한 방법으로 미션 참여했거나 정한 기준을 위배했다고 회사가 판단하는 경우, 회사는 해당 회원에 대한 포인트 지급을 거부 및 회수 하거나, 서비스 이용을 제한할 수 있습니다.',
      '본 서비스는 제휴사 또는 회사의 사정에 따라 사전 공지 없이 변경되거나 종료될 수 있습니다.',
    ],
    label: '(필수)유의사항',
  },
];

export const fixedModules = [
  {
    type: moduleType.mainImageListModule,
    imageUrlList: [],
    label: '메인 이미지 리스트 모듈',
  },
  {
    type: moduleType.titleModule,
    label: '타이틀',
  },
  { type: moduleType.missionReAchievementModule, label: '재인증 모듈' },
];

export const settingInfoList = [
  // { title: '이 챌린지 구경하면', offerWallType: 'CHALLENGE_INSTANCE' },
  // { title: '무료 체험하면', offerWallType: 'EXTERNAL_EXPERIENCE' },
  // { title: '이 상품 구매하면', offerWallType: 'CHALSTORE_PAYMENT' },

  { title: '이 챌린지 참가하면', offerWallType: 'CHALLENGE_PARTICIPATE' },
  { title: '페이지 방문하면', offerWallType: 'EXTERNAL_INSTANCE' },
  { title: '신규 가입하면', offerWallType: 'EXTERNAL_SIGN_UP' },
  { title: '미션 수행하면', offerWallType: 'EXTERNAL_MISSION' },
  { title: '랜선대회 참가하면', offerWallType: 'RACE_PARTICIPATE' },
  {
    title: '[랜선대회] 인스타그램(게시글/스토리/릴스)에 올리면',
    offerWallType: 'RACE_INSTAGRAM_MISSION',
  },
  { title: '[랜선대회] 설문조사에 참여하면', offerWallType: 'RACE_SURVEY' },
  {
    title: '[랜선대회] 미션 수행하면',
    offerWallType: 'RACE_EXTERNAL_MISSION',
  },
];
