type CommonQueryKeys = {
  GET_REST_DAYS: undefined;
  USER_FILTER: { id: number };
  GET_LABELS: undefined;
  CREATE_LABELS: undefined;
  GET_PRE_ALARM_LIST: {
    offset?: number;
    limit?: number;
  };
  GET_PURCHASE_CHALLENGE_LIST: {
    offset?: number;
    limit?: number;
    keyword?: string;
    startDate?: number;
    endDate?: number;
  };
  GET_PURCHASE_CHALLENGE_DETAIL: { id: number };
};

export const getQueryKey = <T extends keyof CommonQueryKeys>(
  ...[key, params]: undefined extends CommonQueryKeys[T]
    ? [T]
    : [T, CommonQueryKeys[T] | 'KEY_ONLY']
) => {
  if (params === 'KEY_ONLY') return [key];
  return params ? [key, params] : [key];
};

export default getQueryKey;
