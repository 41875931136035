import React, { Component } from 'react';
import {
  Accordion,
  Button,
  Checkbox,
  Dimmer,
  Divider,
  Form,
  Header,
  Icon,
  Loader,
  Modal,
  Table,
} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';
import Workbook from 'react-excel-workbook';
import readXlsxFile from 'read-excel-file';
import ValidationRequestModal from '../../../ValidationRequestModal';
import api, { apis } from '../../../../api';

class TransactionProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionProducts: [],
      relationUserChallenges: [],
      column: '',
      direction: null,
      validationModalOpen: false,
      validated: false,
      isAccordionOpen: false,
      editTransactionModalOpen: false,
      progressing: false,
      waybillNumberModalOpen: false,
      waybillNumbers: [],
    };
    this.transactionProduct = {};
    this.initializeTransactionProduct();
  }

  readExcelFile = (e) => {
    const data = e.target.files[0];
    readXlsxFile(data).then((rows) => {
      const waybillNumbers = {};
      rows.slice(1).forEach((row) => {
        waybillNumbers[row[0]] = `${row[2]} ${row[1]}`;
      });
      this.setState({ waybillNumbers });
    });
  };

  initializeTransactionProduct = () => {
    this.transactionProduct = {
      userName: '',
      user: { phone: '' },
      addressBase: '',
      addressDetail: '',
      isChecked: false,
    };
    this.sendingPush = false;
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      transactionProducts: nextProps.transactionProducts,
      relationUserChallenges: nextProps.relationUserChallenges,
      isMounted: true,
    };
  }

  showValidationModal = () => this.setState({ validationModalOpen: true });
  closeValidationModal = () => this.setState({ validationModalOpen: false });
  setValidated = (validated) => {
    this.setState({ validated });
    this.closeValidationModal();
  };

  handleSort = (clickedColumn) => () => {
    const { column, transactionProducts, direction } = this.state;
    if (column !== clickedColumn) {
      const newTransactionProducts = _.sortBy(transactionProducts, [
        clickedColumn,
      ]);
      this.setState({
        column: clickedColumn,
        transactionProducts: newTransactionProducts,
        direction: 'ascending',
      });
      return;
    }

    this.setState({
      transactionProducts: transactionProducts.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };

  showEditTransactionModal = (transactionProduct) => {
    this.setState({ editTransactionModalOpen: true });
    transactionProduct.isChecked = false;
    this.transactionProduct = transactionProduct;
  };
  closeEditTransactionModal = () => {
    if (!this.state.progressing) {
      this.setState({ editTransactionModalOpen: false });
      this.initializeTransactionProduct();
    }
  };
  handleChangeTransactionProduct = (e) => {
    this.transactionProduct[e.target.name] = e.target.value;
  };
  handleCheckboxTransactionProduct = (e, { name }) => {
    this.transactionProduct[name] = !this.transactionProduct[name];
  };
  editTransaction = async () => {
    this.setState({ progressing: true });
    const {
      id,
      userName,
      phone,
      zipcode,
      addressBase,
      addressDetail,
      isChecked,
      user,
    } = this.transactionProduct;
    await apis.updateTransactionProduct(id, {
      userName,
      phone,
      zipcode,
      addressBase,
      addressDetail,
    });
    if (isChecked) {
      await apis.user.updateUser(user.id, {
        name: userName,
        phone,
        zipcode,
        addressBase,
        addressDetail,
      });
    }
    this.setState({ progressing: false }, () => {
      this.closeEditTransactionModal();
    });
  };

  render() {
    const {
      column,
      direction,
      transactionProducts,
      relationUserChallenges,
      isAccordionOpen,
      editTransactionModalOpen,
      progressing,
    } = this.state;
    const addressCount = {};
    const deviceIdCount = {};
    transactionProducts.forEach((tp) => {
      if (!!tp.user.deviceId) {
        if (!(tp.user.deviceId in deviceIdCount)) {
          deviceIdCount[tp.user.deviceId] = 0;
        }
        deviceIdCount[tp.user.deviceId] += 1;
      }
      if (!!tp.zipcode) {
        const address = `(${tp.zipcode}) ${tp.addressBase} ${tp.addressDetail}`;
        if (!(address in addressCount)) {
          addressCount[address] = 0;
        }
        addressCount[address] += 1;
      }
    });

    let excelData = [];
    if (transactionProducts.length > 0) {
      relationUserChallenges.forEach((ruc) => {
        const tp = _.find(
          transactionProducts,
          (tp) => ruc.id === tp.relationUserChallengeId,
        );
        if (!!tp) {
          excelData.push({
            ...tp,
            relationUserChallenge: ruc,
            addressUnique: !!tp?.zipcode
              ? addressCount[
                  `(${tp.zipcode}) ${tp.addressBase} ${tp.addressDetail}`
                ] === 1
              : false,
            deviceIdUnique: !!tp?.user.deviceId
              ? deviceIdCount[tp.user.deviceId] === 1
              : false,
          });
        }
      });
    }
    const { challenge } = this.props;
    return (
      <React.Fragment>
        <Accordion>
          <Accordion.Title
            active={isAccordionOpen}
            index={1}
            onClick={() => this.setState({ isAccordionOpen: !isAccordionOpen })}
          >
            <Header>
              <Icon name="dropdown" />
              판매 정보
            </Header>
          </Accordion.Title>
          <Accordion.Content active={isAccordionOpen}>
            {this.state.validated ? (
              <Workbook
                filename={`${challenge.title}_배송지.xlsx`}
                element={
                  <Button basic color="green" floated="right" size="mini">
                    Excel
                  </Button>
                }
              >
                <Workbook.Sheet data={excelData} name="Sheet A">
                  <Workbook.Column label="구매id" value={(tp) => tp.id} />
                  <Workbook.Column
                    label="구매시점"
                    value={(tp) =>
                      moment(tp.createdAt)
                        .add(9, 'h')
                        .format('YYYY-MM-DD HH:mm:ss')
                    }
                  />
                  <Workbook.Column label="유저id" value={(tp) => tp.user.id} />
                  <Workbook.Column
                    label="email"
                    value={(tp) =>
                      tp.relationUserChallenge
                        ? tp.relationUserChallenge.userEmail
                        : ''
                    }
                  />
                  <Workbook.Column
                    label="닉네임"
                    value={(tp) => tp.user.nickname}
                  />
                  <Workbook.Column label="이름" value={(tp) => tp.userName} />
                  <Workbook.Column
                    label="핸드폰"
                    value={(tp) =>
                      tp.phone[0] !== '0'
                        ? '0' + tp.phone.substring(2)
                        : tp.phone
                    }
                  />
                  <Workbook.Column
                    label="디바이스id"
                    value={(tp) => tp.user.deviceId}
                  />
                  <Workbook.Column
                    label="생년월일"
                    value={(tp) => tp.user.birthday}
                  />
                  <Workbook.Column label="성별" value={(tp) => tp.user.sex} />
                  <Workbook.Column
                    label="배송지"
                    value={(tp) =>
                      `(${tp.zipcode}) ${tp.addressBase} ${tp.addressDetail}`
                    }
                  />
                  <Workbook.Column
                    label="운송장번호"
                    value={(tp) => tp.waybillNumber}
                  />
                  <Workbook.Column
                    label="상품id"
                    value={(tp) => tp.product.id}
                  />
                  <Workbook.Column
                    label="상품명"
                    value={(tp) => tp.product.name}
                  />
                  <Workbook.Column
                    label="상품가격"
                    value={(tp) =>
                      tp.product.price > 0
                        ? setCurrencyFormat(tp.product.price)
                        : '0'
                    }
                  />
                  <Workbook.Column
                    label="상품배송비"
                    value={(tp) =>
                      tp.product.shippingPrice > 0
                        ? setCurrencyFormat(tp.product.shippingPrice)
                        : '0'
                    }
                  />
                  <Workbook.Column label="구매수량" value={(tp) => tp.count} />
                  <Workbook.Column
                    label="구매금액(캐시)"
                    value={(tp) =>
                      tp.cashAmount > 0 ? setCurrencyFormat(tp.cashAmount) : '0'
                    }
                  />
                  <Workbook.Column
                    label="구매금액(카드)"
                    value={(tp) =>
                      tp.cardAmount > 0 ? setCurrencyFormat(tp.cardAmount) : '0'
                    }
                  />
                  <Workbook.Column
                    label="구매금액(이체)"
                    value={(tp) =>
                      tp.transferAmount > 0
                        ? setCurrencyFormat(tp.transferAmount)
                        : '0'
                    }
                  />
                  <Workbook.Column
                    label="신청금(캐시)"
                    value={(tp) =>
                      tp.relationUserChallenge.cashDeposit > 0
                        ? setCurrencyFormat(
                            tp.relationUserChallenge.cashDeposit,
                          )
                        : '0'
                    }
                  />
                  <Workbook.Column
                    label="신청금(상금)"
                    value={(tp) =>
                      tp.relationUserChallenge.prizeDeposit > 0
                        ? setCurrencyFormat(
                            tp.relationUserChallenge.prizeDeposit,
                          )
                        : '0'
                    }
                  />
                  <Workbook.Column
                    label="신청금(카드)"
                    value={(tp) =>
                      tp.relationUserChallenge.cardDeposit > 0
                        ? setCurrencyFormat(
                            tp.relationUserChallenge.cardDeposit,
                          )
                        : '0'
                    }
                  />
                  <Workbook.Column
                    label="신청금(이체)"
                    value={(tp) =>
                      tp.relationUserChallenge.transferDeposit > 0
                        ? setCurrencyFormat(
                            tp.relationUserChallenge.transferDeposit,
                          )
                        : '0'
                    }
                  />
                  <Workbook.Column
                    label="달성률"
                    value={(tp) =>
                      `${tp.relationUserChallenge.achievementCountInfo.ok} / ${tp.relationUserChallenge.achievementCountInfo.present} (총 : ${tp.relationUserChallenge.achievementCountInfo.total} 개)`
                    }
                  />
                  <Workbook.Column
                    label="반환금(캐시)"
                    value={(tp) =>
                      tp.relationUserChallenge.rucResult.returnCashPoint > 0
                        ? setCurrencyFormat(
                            tp.relationUserChallenge.rucResult.returnCashPoint,
                          )
                        : '0'
                    }
                  />
                  <Workbook.Column
                    label="반환금(상금)"
                    value={(tp) =>
                      tp.relationUserChallenge.rucResult.returnPrizePoint > 0
                        ? setCurrencyFormat(
                            tp.relationUserChallenge.rucResult.returnPrizePoint,
                          )
                        : '0'
                    }
                  />
                  <Workbook.Column
                    label="반환금(카드)"
                    value={(tp) =>
                      tp.relationUserChallenge.rucResult.returnCardPoint > 0
                        ? setCurrencyFormat(
                            tp.relationUserChallenge.rucResult.returnCardPoint,
                          )
                        : '0'
                    }
                  />
                  <Workbook.Column
                    label="반환금(이체)"
                    value={(tp) =>
                      tp.relationUserChallenge.rucResult.returnTransferPoint > 0
                        ? setCurrencyFormat(
                            tp.relationUserChallenge.rucResult
                              .returnTransferPoint,
                          )
                        : '0'
                    }
                  />
                  <Workbook.Column
                    label="상금"
                    value={(tp) =>
                      tp.relationUserChallenge.rucResult.rewardPrizePoint > 0
                        ? setCurrencyFormat(
                            Math.ceil(
                              tp.relationUserChallenge.rucResult
                                .rewardPrizePoint,
                            ),
                          )
                        : '0'
                    }
                  />
                  <Workbook.Column
                    label="결과"
                    value={(tp) => tp.relationUserChallenge.rucResult.result}
                  />
                  <Workbook.Column
                    label="번호이상"
                    value={(tp) => {
                      const phone =
                        tp.phone[0] !== '0'
                          ? '0' + tp.phone.substring(2)
                          : tp.phone;
                      return phone.length === 11 &&
                        phone.substring(0, 3) === '010'
                        ? '정상'
                        : '이상';
                    }}
                  />
                  <Workbook.Column
                    label="주소중복"
                    value={(tp) => (tp.addressUnique ? '유일' : '중복')}
                  />
                  <Workbook.Column
                    label="디바이스id중복"
                    value={(tp) => (tp.deviceIdUnique ? '유일' : '중복')}
                  />
                </Workbook.Sheet>
              </Workbook>
            ) : (
              <Button
                basic
                color="green"
                floated="right"
                size="mini"
                onClick={this.showValidationModal}
              >
                엑셀 요청하기
              </Button>
            )}
            <Table basic="very" size="small" sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>no</Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'id' ? direction : null}
                    onClick={this.handleSort('id')}
                  >
                    구매id
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'createdAt' ? direction : null}
                    onClick={this.handleSort('createdAt')}
                  >
                    구매시점
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'userId' ? direction : null}
                    onClick={this.handleSort('userId')}
                  >
                    유저id
                  </Table.HeaderCell>
                  <Table.HeaderCell>닉네임</Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'productId' ? direction : null}
                    onClick={this.handleSort('productId')}
                  >
                    상품id
                  </Table.HeaderCell>
                  <Table.HeaderCell>상품명</Table.HeaderCell>
                  <Table.HeaderCell>상품가격</Table.HeaderCell>
                  <Table.HeaderCell>상품배송비</Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'count' ? direction : null}
                    onClick={this.handleSort('count')}
                  >
                    구매수량
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'cashAmount' ? direction : null}
                    onClick={this.handleSort('cashAmount')}
                  >
                    구매금액(캐시)
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'cardAmount' ? direction : null}
                    onClick={this.handleSort('cardAmount')}
                  >
                    구매금액(카드)
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'transferAmount' ? direction : null}
                    onClick={this.handleSort('transferAmount')}
                  >
                    구매금액(계좌이체)
                  </Table.HeaderCell>
                  <Table.HeaderCell>수정</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.map(transactionProducts, (tp, idx) => (
                  <Table.Row key={tp.id}>
                    <Table.Cell>{idx + 1}</Table.Cell>
                    <Table.Cell>{tp.id}</Table.Cell>
                    <Table.Cell>
                      {moment(tp.createdAt)
                        .add(9, 'h')
                        .format('YYYY-MM-DD HH:mm:ss')}
                    </Table.Cell>
                    <Table.Cell>{tp.user.id}</Table.Cell>
                    <Table.Cell>{tp.user.nickname}</Table.Cell>
                    <Table.Cell>{tp.product.id}</Table.Cell>
                    <Table.Cell>{tp.product.name}</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(tp.product.price)}
                    </Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(tp.product.shippingPrice)}
                    </Table.Cell>
                    <Table.Cell>{tp.count}</Table.Cell>
                    <Table.Cell>{setCurrencyFormat(tp.cashAmount)}</Table.Cell>
                    <Table.Cell>{setCurrencyFormat(tp.cardAmount)}</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(tp.transferAmount)}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        basic
                        size={'mini'}
                        content="수정"
                        onClick={() => this.showEditTransactionModal(tp)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Accordion.Content>
        </Accordion>

        <ValidationRequestModal
          validationModalOpen={this.state.validationModalOpen}
          closeValidationModal={this.closeValidationModal}
          setValidated={this.setValidated}
          location={'판매 정보'}
        />

        <Modal
          size="small"
          open={editTransactionModalOpen}
          onClose={this.closeEditTransactionModal}
        >
          <Dimmer active={progressing}>
            <Loader />
          </Dimmer>
          <Modal.Header>주문 정보 수정하기</Modal.Header>
          <Modal.Content>
            <h5>
              {this.transactionProduct.user.id} /{' '}
              {this.transactionProduct.user.nickname}
            </h5>
            <Divider />
            <Form>
              <Form.Field>
                <label>주문자명</label>
                <input
                  name="userName"
                  defaultValue={this.transactionProduct.userName}
                  onChange={this.handleChangeTransactionProduct}
                />
              </Form.Field>
              <Form.Field>
                <label>핸드폰번호</label>
                <input
                  name="phone"
                  defaultValue={this.transactionProduct.phone}
                  onChange={this.handleChangeTransactionProduct}
                />
              </Form.Field>
              <Form.Field>
                <label>우편번호</label>
                <input
                  name="zipcode"
                  defaultValue={this.transactionProduct.zipcode}
                  onChange={this.handleChangeTransactionProduct}
                />
              </Form.Field>
              <Form.Field>
                <label>기본주소</label>
                <input
                  name="addressBase"
                  defaultValue={this.transactionProduct.addressBase}
                  onChange={this.handleChangeTransactionProduct}
                />
              </Form.Field>
              <Form.Field>
                <label>상세주소</label>
                <input
                  name="addressDetail"
                  defaultValue={this.transactionProduct.addressDetail}
                  onChange={this.handleChangeTransactionProduct}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  name={'isChecked'}
                  label={'기본 배송지 수정'}
                  onChange={this.handleCheckboxTransactionProduct}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="수정"
              onClick={this.editTransaction}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeEditTransactionModal}
            />
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

export default TransactionProduct;
