import moment from 'moment';
import { Button, Modal } from 'semantic-ui-react';
import { ReportedAchievement } from 'src/types/achievement.types';

const WarnCommentHistoryModal = ({
  achievement,
  modalOpen,
  close,
}: WarnCommentHistoryModalProps) => {
  return (
    <Modal size="tiny" open={modalOpen} onClose={close}>
      <Modal.Header>경고 내역</Modal.Header>
      <Modal.Content>
        {achievement.comments.map((c) => {
          return (
            <div key={c.achievementFileId}>
              {moment(c.updatedAt).format('YYYY-MM-DD HH:mm:ss')} / 인증카드id{' '}
              <a
                href={`/achievements/${c.achievementId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {c.achievementId}
              </a>{' '}
              / 인증샷 id {c.achievementFileId}
              <br />
            </div>
          );
        })}
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" content="닫기" onClick={close} />
      </Modal.Actions>
    </Modal>
  );
};

export default WarnCommentHistoryModal;

type WarnCommentHistoryModalProps = {
  achievement: ReportedAchievement;
  modalOpen: boolean;
  close: () => void;
};
