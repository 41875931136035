import { useInfiniteQuery } from '@tanstack/react-query';
import { GetChallengeOptionsParams } from '../../api/challenge';
import { getQueryKey } from './getQueryKey';
import { apis } from '../../api';
import {
  getNextPageParam,
  withTypedInfiniteLoad,
} from '../../utils/query.utils';
import { InfiniteQueryHookOptions } from '../query.types';

export const useGetChallengeOptions = (
  params: GetChallengeOptionsParams,
  options?: InfiniteQueryHookOptions<typeof apis.challenge.getChallengeOptions>,
) => {
  return useInfiniteQuery({
    queryKey: getQueryKey('CHALLENGE_OPTIONS'),
    queryFn: withTypedInfiniteLoad(
      apis.challenge.getChallengeOptions,
      params,
      40,
    ),
    initialPageParam: 0,
    getNextPageParam,
    ...options,
  });
};
