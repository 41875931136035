import { useInfiniteQuery } from '@tanstack/react-query';
import { apis } from '../../api';
import { GetGoalsParams } from '../../api/challenge';
import { getQueryKey } from './getQueryKey';
import {
  getNextPageParam,
  withTypedInfiniteLoad,
} from '../../utils/query.utils';
import { InfiniteQueryHookOptions } from '../query.types';

export const useGetGoals = (
  params: GetGoalsParams,
  options?: InfiniteQueryHookOptions<typeof apis.challenge.getGoals>,
) => {
  return useInfiniteQuery({
    queryKey: getQueryKey('GET_GOALS', {
      keyword: params.keyword,
      isOfficial: params.isOfficial,
    }),
    queryFn: withTypedInfiniteLoad(apis.challenge.getGoals, params, 100),
    initialPageParam: 0,
    getNextPageParam,
    ...options,
  });
};
