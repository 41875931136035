import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useLabelIds = () => {
  return useChallengeFormStore(
    useShallow((s) => ({ labelIds: s.challenge.labelIds || [] })),
  );
};

export const useLabelIdsActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setLabelIds = (labelIds: number[]) => updateChallengeForm({ labelIds });

  return { setLabelIds };
};
