import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { Col } from '../Style';

const FilterPreview = () => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Accordion style={{ width: '100%' }}>
      <Accordion.Title
        active={!collapsed}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Icon name="dropdown" />
        미리보기
      </Accordion.Title>
      <Accordion.Content active={!collapsed}>
        <Col>
          <strong>카메라필터 우상단</strong>
          <img
            src="/race/후원사-카메라필터.png"
            width={220}
            alt="후원사-카메라필터"
          />
        </Col>
      </Accordion.Content>
    </Accordion>
  );
};

export default FilterPreview;
