import React, { useState } from 'react';
import Button from '@component/Button';
import TextArea from '@component/TextArea';
import * as S from './MultilineChipInput.style';
import ReactDragList from 'react-drag-list';

type MultilineChipInputProps = {
  chipList: string[];
  placeholder?: string;
  onChange?: (changedData: string[]) => void;
  label?: string;
  disabled?: boolean;
  errorMsg?: string;
  style?: React.CSSProperties;
  draggable?: boolean;
};

const REMOVE_TEXT = '[지우기]';
const MultilineChipInput = (props: MultilineChipInputProps) => {
  const {
    label,
    placeholder,
    chipList,
    onChange,
    style,
    disabled = false,
    errorMsg,
    draggable = false,
  } = props;
  const [newText, setNewText] = useState<string>('');

  const add = () => {
    const updatedMultilineChipList = [...chipList, newText];
    onChange?.(updatedMultilineChipList);
    setNewText('');
  };

  const remove = (index: number) => {
    const updatedData = [...chipList];
    updatedData.splice(index, 1);
    onChange?.(updatedData);
  };

  const handleUpdate = (_: any, updated: any) => {
    onChange?.(updated.map(({ item }: any) => item));
  };

  const renderItem = ({ item }: any, index: number) => {
    return (
      <S.ItemContainer>
        {item}
        {!disabled ? (
          <S.RemoveButton onClick={() => remove(index)} role="button">
            {REMOVE_TEXT}
          </S.RemoveButton>
        ) : (
          <></>
        )}
      </S.ItemContainer>
    );
  };

  return (
    <S.Container style={style}>
      {label ? <S.Label>{label}</S.Label> : <></>}
      <ReactDragList
        rowKey="item"
        dataSource={chipList.map((item) => ({ item }))}
        handles={false}
        onUpdate={handleUpdate}
        disabled={!draggable}
        row={renderItem}
      />
      <TextArea
        value={newText}
        placeholder={placeholder}
        disabled={disabled}
        onChange={setNewText}
        errorMsg={errorMsg}
      />
      <Button
        text="추가"
        onClick={add}
        disabled={!newText.length || disabled}
      />
    </S.Container>
  );
};

export default MultilineChipInput;
