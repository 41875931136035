import FlexBox from '@component/FlexBox/FlexBox';
import React, { useEffect, useState } from 'react';
import { Accordion as A, Header, Icon } from 'semantic-ui-react';

type Props = {
  title: string;
  open?: boolean;
  onChange?: (opened: boolean) => void;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  children: React.ReactNode;
};

const Accordion = ({
  open = false,
  as = 'h5',
  title,
  onChange,
  children,
}: Props) => {
  const [collapsed, setCollapsed] = useState(!open);

  const handleTitleClick = () => {
    setCollapsed(!collapsed);
    onChange?.(collapsed);
  };

  useEffect(() => {
    setCollapsed(!open);
  }, [open]);

  return (
    <A collapsed={`${collapsed}`}>
      <A.Title active={!collapsed} onClick={handleTitleClick}>
        <Header.Content as={as}>
          <Icon name="dropdown" />
          {title}
        </Header.Content>
      </A.Title>
      <A.Content active={!collapsed}>
        <FlexBox.Column style={{ paddingLeft: 20 }}>{children}</FlexBox.Column>
      </A.Content>
    </A>
  );
};

export default Accordion;
