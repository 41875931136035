import { useEffect, useState } from 'react';
import { Menu } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';

const CollaboMenu = ({ menus }) => {
  const [pathname, setPathname] = useState('');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const _click = (menu) => {
    history.push(menu.value);
  };

  return (
    <Menu fluid secondary vertical>
      {menus.map((menu) => (
        <Menu.Item
          key={menu.value}
          active={menu.value === pathname}
          onClick={() => _click(menu)}
        >
          {menu.text}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default CollaboMenu;
