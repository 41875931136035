import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';
import { hasDuplicatedAchievementIndex } from '../../NewChallengeForm.util';

type Params = Pick<
  ChallengeFormType,
  'instagramInfoList' | 'naverBlogInfoList' | 'purchaseInfo'
> & { achievementCount: number };

export const validate: FormValidator<Params> = ({
  achievementCount,
  instagramInfoList,
  naverBlogInfoList,
  purchaseInfo,
}) => {
  for (let i = 0; i < instagramInfoList.length; i++) {
    const info = instagramInfoList[i];
    if (info.achievementIndex.length === 0) {
      return {
        isValid: false,
        message: `[인스타그램] 인증 회차 정보는 필수입니다.`,
      };
    }

    if (info.hashTagText.length === 0) {
      return {
        isValid: false,
        message: `[인스타그램] 해시태그 정보는 필수입니다.`,
      };
    }

    if (info.checklist.length === 0) {
      return {
        isValid: false,
        message: `[인스타그램] 체크리스트 정보는 필수입니다.`,
      };
    }

    if (info.achievementIndex.find((index) => index > achievementCount)) {
      return {
        isValid: false,
        message: `[인스타그램] 인증회차는 인증횟수보다 클 수 없습니다.`,
      };
    }
  }

  if (
    hasDuplicatedAchievementIndex({
      instagramInfoList,
      naverBlogInfoList,
      purchaseInfo,
    })
  )
    return {
      isValid: false,
      message: `[인스타그램] 인증회차 번호는 중복되면 안됩니다.`,
    };

  return { isValid: true };
};
