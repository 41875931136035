import React, { useCallback, useState } from 'react';
import FlexBox from '@component/FlexBox/FlexBox';
import { Button, Divider, Dropdown, Form, Modal } from 'semantic-ui-react';
import ReactDragList from 'react-drag-list';
import { ReviewTemplate } from '../Achievement.types';

const ReviewTemplateMenu = ({
  reviewTemplates,
  addReviewTemplate,
  updateReviewTemplate,
  handleUpdateReviewTemplateOrder,
  deleteReviewTemplate,
}: ReviewTemplateMenuProps) => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editContentModalOpen, setEditContentModalOpen] = useState(false);
  const [editOrderModalOpen, setEditOrderModalOpen] = useState(false);
  const [text, setText] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState<ReviewTemplate>();
  const [message, setMessage] = useState('');

  const showAddModal = useCallback(() => setAddModalOpen(true), []);
  const closeAddModal = useCallback(() => {
    setText('');
    setMessage('');
    setAddModalOpen(false);
  }, []);

  const showEditContentModal = useCallback(
    () => setEditContentModalOpen(true),
    [],
  );
  const closeEditContentModal = useCallback(() => {
    setText('');
    setMessage('');
    setEditContentModalOpen(false);
  }, []);

  const showEditOrderModal = useCallback(() => setEditOrderModalOpen(true), []);
  const closeEditOrderModal = useCallback(
    () => setEditOrderModalOpen(false),
    [],
  );

  const handleChangeReviewTemplate = (value: number) => {
    const template = reviewTemplates.find((o) => o.value === value);
    if (!template) return;

    setMessage(template.message);
    setText(template.text);
    setSelectedTemplate(template);
  };

  const _addTemplate = () => {
    addReviewTemplate(text, message);
    closeAddModal();
  };

  const _updateTemplate = () => {
    if (!selectedTemplate) return;
    updateReviewTemplate(selectedTemplate, text, message);
    closeEditContentModal();
  };

  return (
    <FlexBox.Column gap={4} style={{ marginBottom: 12 }}>
      <Button fluid onClick={showAddModal}>
        리뷰 템플릿 추가
      </Button>
      <Button fluid onClick={showEditOrderModal}>
        리뷰 템플릿 순서변경/삭제
      </Button>
      <Button fluid onClick={showEditContentModal}>
        리뷰 템플릿 수정
      </Button>

      <Modal size="small" open={addModalOpen} onClose={closeAddModal}>
        <Modal.Header>템플릿 추가</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>템플릿명</label>
              <input
                placeholder="템플릿명"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>내용</label>
              <textarea
                rows={20}
                placeholder="내용"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="추가" onClick={_addTemplate} />
          <Button color="black" content="닫기" onClick={closeAddModal} />
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={editOrderModalOpen}
        onClose={closeEditOrderModal}
      >
        <Modal.Header>템플릿 순서 변경/삭제</Modal.Header>
        <Modal.Content>
          <ReactDragList
            rowKey="1"
            dataSource={reviewTemplates}
            handles={false}
            onUpdate={(e, data) => {
              const templates = data as ReviewTemplate[];
              handleUpdateReviewTemplateOrder(templates);
            }}
            row={(o) => {
              const template = o as ReviewTemplate;
              return (
                <React.Fragment key={template.key}>
                  {template.text}
                  <Button
                    size="mini"
                    floated="right"
                    content="삭제"
                    onClick={() => deleteReviewTemplate(template)}
                  />
                  <Divider />
                </React.Fragment>
              );
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" content="닫기" onClick={closeEditOrderModal} />
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={editContentModalOpen}
        onClose={closeEditContentModal}
      >
        <Modal.Header>템플릿 수정</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>메세지 템플릿</label>
              <Dropdown
                placeholder="템플릿을 선택하세요."
                fluid
                search
                selection
                name="messageId"
                value={selectedTemplate?.value}
                options={reviewTemplates}
                onChange={(e, { value }) => {
                  handleChangeReviewTemplate(value as number);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>템플릿명</label>
              <input
                placeholder="템플릿명"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>내용</label>
              <textarea
                rows={20}
                placeholder="내용"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="변경" onClick={_updateTemplate} />
          <Button
            color="black"
            content="닫기"
            onClick={closeEditContentModal}
          />
        </Modal.Actions>
      </Modal>
    </FlexBox.Column>
  );
};

export default ReviewTemplateMenu;

type ReviewTemplateMenuProps = {
  reviewTemplates: ReviewTemplate[];
  addReviewTemplate: (text: string, message: string) => void;
  updateReviewTemplate: (
    template: ReviewTemplate,
    text: string,
    message: string,
  ) => void;
  handleUpdateReviewTemplateOrder: (templates: ReviewTemplate[]) => void;
  deleteReviewTemplate: (template: ReviewTemplate) => void;
};
