import {
  DescriptionCollaboTopSummaryModuleFormType,
  DescriptionModuleFormType,
} from '../../../../NewChallengeForm.types';

export const validate = (
  descriptionModule: DescriptionModuleFormType[],
): { isValid: true } | { isValid: false; message: string } => {
  const target = descriptionModule.find(
    ({ type }) => type === 'COLLABO-TOP-SUMMARY',
  );

  if (!target) return { isValid: true };

  const { data } = target as DescriptionCollaboTopSummaryModuleFormType;
  const { mainHeader, subHeader, logoImage, image } = data;

  if (!mainHeader)
    return {
      isValid: false,
      message: '[상세모듈 | 최상단 요약] 메인 헤더를 입력해주세요.',
    };

  if (mainHeader.split('\n').length > 2) {
    return {
      isValid: false,
      message:
        '[상세모듈 | 최상단 요약] 메인 헤더는 2줄 넘게 입력하실 수 없습니다.',
    };
  }

  if (!subHeader) {
    return {
      isValid: false,
      message: '[상세모듈 | 최상단 요약] 서브 헤더를 입력해주세요.',
    };
  }

  if (subHeader.split('\n').length > 2) {
    return {
      isValid: false,
      message:
        '[상세모듈 | 최상단 요약] 서브 헤더는 2줄 넘게 입력하실 수 없습니다.',
    };
  }

  if (!logoImage) {
    return {
      isValid: false,
      message:
        '[상세모듈 | 최상단 요약] 챌린지 요약 로고 이미지를 첨부해주세요.',
    };
  }

  if (!image) {
    return {
      isValid: false,
      message: '[상세모듈 | 최상단 요약] 챌린지 요약 이미지를 첨부해주세요.',
    };
  }

  return { isValid: true };
};
