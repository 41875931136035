import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';
import { hasDuplicatedAchievementIndex } from '../../NewChallengeForm.util';

export const validate: FormValidator<
  Pick<
    ChallengeFormType,
    'purchaseInfo' | 'naverBlogInfoList' | 'instagramInfoList'
  > & { achievementCount: number }
> = ({
  purchaseInfo,
  naverBlogInfoList,
  instagramInfoList,
  achievementCount,
}) => {
  if (purchaseInfo?.items && purchaseInfo.items.length > 0) {
    for (let i = 0; i < purchaseInfo.items.length; i++) {
      const info = purchaseInfo.items[i];
      if (info.achievementIndex.length === 0) {
        return {
          isValid: false,
          message: `인증 회차 정보는 필수입니다.`,
        };
      }

      if (!info.type) {
        return {
          isValid: false,
          message: `인증 타입 정보는 필수입니다.`,
        };
      }

      if (info.checklist.length === 0) {
        return {
          isValid: false,
          message: `체크리스트 정보는 필수입니다.`,
        };
      }
      if (info.achievementIndex.find((index) => index > achievementCount)) {
        return {
          isValid: false,
          message: `인증회차는 인증횟수보다 클 수 없습니다.`,
        };
      }
    }
  }

  if (
    hasDuplicatedAchievementIndex({
      purchaseInfo,
      naverBlogInfoList,
      instagramInfoList,
    })
  )
    return {
      isValid: false,
      message: `인증회차 번호는 중복되면 안됩니다.`,
    };

  return { isValid: true };
};
