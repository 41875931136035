import { useState, useEffect, useMemo, useCallback } from 'react';
import Button from '@component/Button';
import FlexBox from '@component/FlexBox/FlexBox';
import {
  DescriptionModuleFormType,
  CommonDescriptionModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { swap as jsSwap } from '@utils/js.utils';
import { descriptionModules } from '../DescriptionModuleForm.constant';

interface useSubModuleProps {
  data: CommonDescriptionModuleFormType[];
  onChange: (data: any) => void;
}

const useSubModule = ({ data, onChange }: useSubModuleProps) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [list, setList] = useState<DescriptionModuleFormType[]>(data);

  const convertForm2Data = useCallback(() => {
    return list.map((module) => {
      return descriptionModules[module.type].convertForm2Data(module);
    });
  }, [list]);

  const add = (module: DescriptionModuleFormType) => {
    setList([...list, module]);
  };

  const remove = (index: number) => {
    const newSubModuleList = [...list];

    newSubModuleList.splice(index, 1);

    setList(newSubModuleList);
  };

  const update = (
    index: number,
    module:
      | DescriptionModuleFormType
      | ((prev: DescriptionModuleFormType[]) => DescriptionModuleFormType[]),
  ) => {
    let newSubModuleList;

    if (typeof module === 'function') {
      newSubModuleList = module(list);
    } else {
      newSubModuleList = [...list];
      newSubModuleList[index] = module;
    }

    if (JSON.stringify(list) === JSON.stringify(newSubModuleList)) return;

    setList(newSubModuleList);
  };

  const swap = (index1: number, index2: number) => {
    if (!list[index1] || !list[index2]) return;

    setList(jsSwap(list, index1, index2));
  };

  const List = useMemo(
    () =>
      list.map((module, index) => {
        const Module = descriptionModules[module.type];
        if (!Module) return null;
        return (
          <FlexBox.Column
            key={`${module.type}-${index}`}
            style={{ marginBottom: 12 }}
          >
            <FlexBox.Row gap={6}>
              <Button
                text={`${module.type} [지우기]`}
                bgColor="black"
                fontColor="white"
                onClick={() => remove(index)}
              />
              <Button text="위로" onClick={() => swap(index - 1, index)} />
              <Button text="아래로" onClick={() => swap(index, index + 1)} />
            </FlexBox.Row>
            <Module
              {...module}
              moduleIndex={index}
              onUpdate={update}
              onRemove={() => remove(index)}
            />
          </FlexBox.Column>
        );
      }),
    [list],
  );

  useEffect(() => {
    if (initialized) {
      onChange(convertForm2Data());
    } else {
      setInitialized(true);
    }
  }, [initialized, list]);

  return {
    list,
    List,
    add,
    remove,
    update,
    swap,
  };
};

export default useSubModule;
