import Button from '@component/Button';
import FlexBox from '@component/FlexBox/FlexBox';
import { useGetAllCompanies } from '@queries/Commerce/useGetCompanies';
import { useGetAllCompaniesProducts } from '@queries/Commerce/useGetCompaniesProducts';
import React, { FC } from 'react';

const CommerceManagement: FC = () => {
  const { refetch: refetchCompanies } = useGetAllCompanies();
  const { refetch: refetchProducts } = useGetAllCompaniesProducts();

  const handleClickCommerceModify = () => {
    window.open('/commerces', '_blank');
  };

  const handleRefresh = async () => {
    await refetchCompanies();
    await refetchProducts();
  };

  return (
    <FlexBox.Row
      gap={8}
      style={{
        marginTop: 8,
        marginLeft: 20,
      }}
    >
      <Button
        size="medium"
        text="커머스 관리"
        onClick={handleClickCommerceModify}
      />
      <Button size="medium" text="커머스 새로고침" onClick={handleRefresh} />
    </FlexBox.Row>
  );
};

export default CommerceManagement;
