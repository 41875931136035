import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Item } from '../../../container/Benefit/PedometerGiftiShop/PedometerGiftiShopBuyers/PedometerGiftiShopBuyers.types';
import { apis } from '../../../api';
import { getQueryKey } from './getQueryKey';
import { changeIndexItemInArr } from '../../../utils/js.utils';

export const useGiftiShopItemMutation = () => {
  const queryClient = useQueryClient();
  const create = useCallback(
    async (data: Omit<Item, 'id'>) => {
      const res: { id: number } =
        await apis.createPedometerGiftiShopItems(data);
      if (!res?.id) return false;
      queryClient.setQueryData<{ items: Item[] } | undefined>(
        getQueryKey('GIFTI_SHOP_ITEM_LIST'),
        (prev) => {
          if (!prev) return prev;
          return {
            items: [...prev.items, { id: res.id, ...data }],
          };
        },
      );
      return true;
    },
    [queryClient],
  );

  const edit = useCallback(
    async (data: Item) => {
      const res: { success: boolean } = await apis.editPedometerGiftiShopItems(
        data.id,
        data,
      );
      if (!res?.success) return false;
      queryClient.setQueryData<{ items: Item[] } | undefined>(
        getQueryKey('GIFTI_SHOP_ITEM_LIST'),
        (prev) => {
          if (!prev) return prev;
          const index = prev.items.findIndex((v) => v.id === data.id);
          if (index === -1) return prev;
          return { items: changeIndexItemInArr(prev.items, index, data) };
        },
      );
      queryClient.setQueryData<{ item: Item }>(
        getQueryKey('GIFTI_SHOP_ITEM', { id: data.id }),
        () => {
          return { item: data };
        },
      );
      return true;
    },
    [queryClient],
  );

  const changeEnableState = useCallback(
    async (data: Pick<Item, 'id' | 'isDisplayed'>) => {
      const res: { success: boolean } = await apis.editPedometerGiftiShopItems(
        data.id,
        data,
      );
      if (!res?.success) return false;
      queryClient.setQueryData<{ items: Item[] } | undefined>(
        getQueryKey('GIFTI_SHOP_ITEM_LIST'),
        (prev) => {
          if (!prev) return prev;
          const index = prev.items.findIndex((v) => v.id === data.id);
          if (index === -1) return prev;
          const prevItem = prev.items[index];
          return {
            items: changeIndexItemInArr(prev.items, index, {
              ...prevItem,
              ...data,
            }),
          };
        },
      );
      queryClient.setQueryData<{ item: Item } | undefined>(
        getQueryKey('GIFTI_SHOP_ITEM', { id: data.id }),
        (prev) => {
          if (!prev) return prev;
          return { item: { ...prev.item, ...data } };
        },
      );
      return true;
    },
    [queryClient],
  );

  return {
    create,
    edit,
    changeEnableState,
  };
};
