import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { AuthenticationExample } from '../../NewChallengeForm.types';

export const useAuthenticationGoodExamples = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      authenticationGoodExampleUrls: s.challenge.authenticationGoodExamples,
    })),
  );
};

export const useAuthenticationGoodExamplesActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setAuthenticationGoodExamples = (
    authenticationGoodExamples: AuthenticationExample[],
  ) => updateChallengeForm({ authenticationGoodExamples });

  return { setAuthenticationGoodExamples };
};
