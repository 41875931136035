import {
  SettlementFilter,
  SettlementFormState,
  SettlementImmutableFormState,
  SettlementMutableFormState,
  SettlementProgressiveOperatingFee,
  SettlementSearch,
} from './Settlement.types';

export const FILTER_LABELS: Record<keyof SettlementFilter, string> = {
  gteChallengeResultDate: '결과 발표일 시작',
  ltChallengeResultDate: '결과 발표일 끝',
};

export const PAGE_LIMIT = 100;

export const SEARCH_DROPDOWN_LABEL: Record<keyof SettlementSearch, string> = {
  challengeTitle: '챌린지 제목',
  companyName: '회사명',
};

export const SEARCH_DROPDOWN_OPTIONS: {
  key: keyof SettlementSearch;
  text: string;
  value: keyof SettlementSearch;
}[] = [
  {
    key: 'challengeTitle',
    text: SEARCH_DROPDOWN_LABEL.challengeTitle,
    value: 'challengeTitle',
  },
  {
    key: 'companyName',
    text: SEARCH_DROPDOWN_LABEL.companyName,
    value: 'companyName',
  },
];

export const FORM_LABEL: Record<keyof SettlementFormState, string> = {
  bannerPrice: '고정배너비',
  instagramPrice: '인스타그램',
  naverBlogPrice: '네이버블로그',
  naverAfterMonthReviewPrice: '네이버한달리뷰',
  etcPrice: '기타',
  overHundredCount: '100% 달성자 수',
  rewardPerPerson: '인당 상금',
  totalReward: '총 상금',
  operatingFeePerPerson: '인당 운영수수료',
  progressiveOperatingFeeForLte1000: '1,000명 이하 인당 운영수수료',
  progressiveOperatingFeeForGt1000Lte2000: '1,001명-2,000명 인당 운영수수료',
  progressiveOperatingFeeForGt2000: '2,001명 이상 인당 운영수수료',
};

export const mutableSettlementFormKeys: (keyof SettlementMutableFormState)[] = [
  'bannerPrice',
  'instagramPrice',
  'naverBlogPrice',
  'naverAfterMonthReviewPrice',
  'etcPrice',
  'operatingFeePerPerson',
  'progressiveOperatingFeeForLte1000',
  'progressiveOperatingFeeForGt1000Lte2000',
  'progressiveOperatingFeeForGt2000',
];

export const immutableSettlementFormKeys: (keyof SettlementImmutableFormState)[] =
  ['overHundredCount', 'rewardPerPerson', 'totalReward'];

export const PROGRESSIVE_OPERATING_FEE_LABEL: Record<
  keyof SettlementProgressiveOperatingFee,
  string
> = {
  progressiveOperatingFeeForLte1000: '1,000명 이하',
  progressiveOperatingFeeForGt1000Lte2000: '1,001명-2,000명',
  progressiveOperatingFeeForGt2000: '2,001명 이상',
};

export const progressiveOperatingFeeKeys: (keyof SettlementProgressiveOperatingFee)[] =
  [
    'progressiveOperatingFeeForLte1000',
    'progressiveOperatingFeeForGt1000Lte2000',
    'progressiveOperatingFeeForGt2000',
  ];
