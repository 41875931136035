import FlexBox from '@component/FlexBox/FlexBox';
import { Header } from 'semantic-ui-react';
import DisplaySetting from './components/DisplaySetting';
import AlarmRegisterSetting from './components/AlarmRegisterSetting';
import AlarmBasicInfo from './components/AlarmBasicInfo';
import AlarmBeautyHotDealInfo from './components/AlarmBeautyHotDealInfo';
import Spacing from '@component/Spacing';
import AlarmDetailInfo from './components/AlarmDetailInfo';
import Button from '@component/Button';
import { useEffect, useState } from 'react';
import { preAlarmSchema } from './PreAlarmForm.validator';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import ConfirmPopup from '@component/ConfirmPopup';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { apis } from '@api/index';
import { CreateOrUpdatePreAlarmParams } from '@api/preAlarm';
import {
  PreAlarmAdditionalInfo,
  PreAlramForm,
  RecommentEditType,
} from './PreAlarmForm.types';
import UserFilterSelectorForm from './components/UserFilterSelector';
import { useEditCheck } from './PreAlarmForm.hook';
import { DEFAULT_PRE_ALARM } from './PreAlarmForm.constant';
import { formatTimeExcludeSeconds } from '@utils/date.utils';

const PreAlarmForm = () => {
  const { id } = useParams<{ id: string }>();
  const challengeId = Number(id);

  const {
    data: preAlarmData,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['preAlarm', challengeId],
    queryFn: () =>
      apis.preAlarm.getPreAlarmByServiceInfo({
        serviceType: 'CHALLENGE',
        serviceId: challengeId,
      }),
    enabled: !!challengeId,
    select: (data) => data.preAlarm,
  });

  const { data: challenge, isFetching: isChallengeFetching } = useQuery({
    queryKey: ['challenge', challengeId],
    queryFn: () => apis.challenge.getChallenge(challengeId),
    enabled: !!challengeId,
    select: (data) => data.challenge,
  });

  const [preAlarm, setPreAlarm] = useState<CreateOrUpdatePreAlarmParams>({
    ...DEFAULT_PRE_ALARM,
    serviceId: challengeId,
    serviceType: 'CHALLENGE',
  });

  const { triggerEdited, getBorder, checkRecommendEditedAndAlert } =
    useEditCheck<RecommentEditType>({
      editCheckValue: {
        purchaseStartDate: preAlarm.additionalInfo.purchaseStartDate,
        purchaseEndDate: preAlarm.additionalInfo.purchaseEndDate,
        maxRegisterCount: preAlarm.maxRegisterCount,
        userFilterId: preAlarm.userFilterId,
      },
      label: {
        purchaseStartDate: '구매일시 (시작)',
        purchaseEndDate: '구매일시 (종료)',
        maxRegisterCount: '모집최대인원',
        userFilterId: '유저 필터',
      },
    });

  const handleChange = <T extends keyof PreAlramForm>(
    key: T,
    value?: PreAlramForm[T],
  ) => {
    setPreAlarm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleChangeAdditionalInfo = <T extends keyof PreAlarmAdditionalInfo>(
    key: T,
    value?: PreAlarmAdditionalInfo[T],
  ) => {
    setPreAlarm((prev) => ({
      ...prev,
      additionalInfo: {
        ...prev.additionalInfo,
        [key]: value,
      },
    }));
  };

  const onDelete = () => {
    if (!preAlarmData) return;
    ConfirmPopup.show({
      title: '사전 알림 삭제',
      description: `사전 알림 세팅을 삭제하시겠어요?\n삭제하시면 알림 신청한 유저들에게 푸시가 가지 않아요`,
      confirmText: '삭제',
      onConfirm: async () => {
        LoadingIndicator.show();
        const res = await apis.preAlarm.deletePreAlarm(preAlarmData?.id);

        if (!res.success) {
          LoadingIndicator.hide();
          return alert('삭제에 실패했습니다');
        }

        refetch();
        alert('삭제되었습니다');
        ConfirmPopup.hide();
        LoadingIndicator.hide();
      },
    });
  };

  const onSubmit = async () => {
    if (isLoading) return;
    try {
      preAlarmSchema?.validateSync(preAlarm);
    } catch (e: any) {
      return alert(e.message);
    }

    const isEditedCheck = checkRecommendEditedAndAlert();

    if (!isEditedCheck) return;

    LoadingIndicator.show();
    if (!preAlarmData) {
      const res = await apis.preAlarm.createPreAlarm({
        ...preAlarm,
        serviceId: challengeId,
        serviceType: 'CHALLENGE',
      });

      if (!res?.preAlarm) {
        alert('저장에 실패했습니다');
        return LoadingIndicator.hide();
      }
    } else {
      const res = await apis.preAlarm.updatePreAlarm(preAlarmData.id, {
        ...preAlarm,
      });

      if (!res?.preAlarm) {
        alert('저장에 실패했습니다');
        return LoadingIndicator.hide();
      }
    }
    refetch();
    alert('저장되었습니다');
    LoadingIndicator.hide();
  };

  useEffect(() => {
    if (isFetching || isChallengeFetching) {
      LoadingIndicator.show();
    } else {
      if (challenge) {
        setPreAlarm({
          ...DEFAULT_PRE_ALARM,
          ...preAlarmData,
          maxRegisterCount: preAlarmData?.maxRegisterCount
            ? preAlarmData.maxRegisterCount
            : challenge.maxRegisterCount,
          additionalInfo: {
            ...DEFAULT_PRE_ALARM.additionalInfo,
            ...(preAlarmData?.additionalInfo ?? {}),
            purchaseStartDate: preAlarmData?.additionalInfo.purchaseStartDate
              ? preAlarmData.additionalInfo.purchaseStartDate
              : formatTimeExcludeSeconds(new Date(challenge.purchaseStartDate)),
            purchaseEndDate: preAlarmData?.additionalInfo.purchaseEndDate
              ? preAlarmData.additionalInfo.purchaseEndDate
              : formatTimeExcludeSeconds(new Date(challenge.purchaseEndDate)),
          },
          serviceId: challengeId,
          serviceType: 'CHALLENGE',
        });
      } else {
        setPreAlarm({
          ...DEFAULT_PRE_ALARM,
          ...preAlarmData,
          additionalInfo: {
            ...DEFAULT_PRE_ALARM.additionalInfo,
            ...(preAlarmData?.additionalInfo ?? {}),
          },
          serviceId: challengeId,
          serviceType: 'CHALLENGE',
        });
      }
      LoadingIndicator.hide();
    }
  }, [isFetching, preAlarmData, isChallengeFetching]);

  return (
    <>
      <FlexBox.Column pv={20} ph={20}>
        <FlexBox.Row justifyContent="space-between">
          <Header as="h2" textAlign="center">
            사전 알림 세팅
          </Header>
          {preAlarmData && (
            <DisplaySetting
              preAlaramId={preAlarmData?.id}
              isAdmin={preAlarmData?.isAdmin}
              isSent={preAlarmData?.isSent}
              refetch={refetch}
            />
          )}
        </FlexBox.Row>

        <Spacing vertical={10} />

        <AlarmBasicInfo
          title={preAlarm.title}
          brand={preAlarm.brand}
          handleChange={handleChange}
          disabled={!!preAlarmData?.isSent}
        />

        <Spacing vertical={20} />

        <AlarmBeautyHotDealInfo
          category={preAlarm.additionalInfo.category}
          purchaseChannel={preAlarm.additionalInfo.purchaseChannel}
          purchaseStartDate={preAlarm.additionalInfo.purchaseStartDate}
          purchaseEndDate={preAlarm.additionalInfo.purchaseEndDate}
          originalPrice={preAlarm.additionalInfo.originalPrice}
          price={preAlarm.additionalInfo.price}
          paybackAmount={preAlarm.additionalInfo.paybackAmount}
          button={preAlarm.additionalInfo.button}
          disabled={!!preAlarmData?.isSent}
          onChange={handleChangeAdditionalInfo}
          triggerEdited={triggerEdited}
          getBorder={getBorder}
        />

        <Spacing vertical={20} />

        <AlarmRegisterSetting
          maxRegisterCount={preAlarm.maxRegisterCount}
          handleChange={handleChange}
          triggerEdited={triggerEdited}
          getBorder={getBorder}
          disabled={!!preAlarmData?.isSent}
        />

        <Spacing vertical={20} />

        <AlarmDetailInfo
          thumbnailImageUrl={preAlarm.thumbnailImageUrl}
          descriptionImageUrlList={preAlarm.descriptionImageUrlList}
          handleChange={handleChange}
          disabled={!!preAlarmData?.isSent}
        />

        <Spacing vertical={10} />

        <UserFilterSelectorForm
          userFilterId={preAlarm.userFilterId}
          handleChange={handleChange}
          triggerEdited={triggerEdited}
          getBorder={getBorder}
          disabled={!!preAlarmData?.isSent}
        />

        <Spacing vertical={20} />

        <FlexBox.Row justifyContent="center" gap={12}>
          <Button
            disabled={preAlarmData === null || preAlarmData?.isSent}
            width={320}
            height={54}
            rounded={12}
            onClick={onDelete}
          >
            삭제
          </Button>
          <Button
            disabled={!!(preAlarmData && preAlarmData?.isSent)}
            bgColor={
              !(preAlarmData && preAlarmData?.isSent) ? 'black' : undefined
            }
            fontColor="white"
            width={320}
            height={54}
            rounded={12}
            onClick={onSubmit}
          >
            저장
          </Button>
        </FlexBox.Row>
      </FlexBox.Column>
    </>
  );
};

export default PreAlarmForm;
