import {
  gymReviewCategoryList,
  skyReviewCategoryList,
} from './DataLabel.constants';
import { ChallengeType } from './DataLabel.types';

export const getBackgroundColor = (
  isOdd: boolean,
  isActive: boolean,
): string => {
  if (isActive) return '#1e88e5';
  if (isOdd) return '#eee';
  return '#ddd';
};

export const getReviewCategoryList = (cType: ChallengeType) => {
  switch (cType) {
    case 'gym':
      return gymReviewCategoryList;
    case 'sky':
      return skyReviewCategoryList;
    default:
      return [];
  }
};
