import FlexBox from '@component/FlexBox/FlexBox';
import { useGetLabels } from '@queries/common/useLabels';
import { LabelMiniInfo } from '@types';
import { useEffect, useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import LabelMenu from './LabelMenu/LabelMenu';
import LabelCreateModal from './LabelCreateModal/LabelCreateModal';

const LabelTab = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [labels, setLabels] = useState<LabelMiniInfo[]>([]);

  const { data } = useGetLabels();

  useEffect(() => {
    if (!data?.labels) return;
    setLabels(data?.labels);
  }, [data?.labels]);

  return (
    <FlexBox.Row style={{ margin: 40, gap: 30 }}>
      <LabelMenu />

      <FlexBox.Column style={{ flex: 1 }}>
        <FlexBox.Row justifyContent="flex-end" style={{ marginBottom: 15 }}>
          <Button
            size="mini"
            floated="right"
            onClick={() => setIsOpenModal(true)}
          >
            추가
          </Button>
        </FlexBox.Row>

        <div style={{ flex: 1, overflowY: 'auto', maxHeight: '800px' }}>
          <Table basic="very">
            <Table.Header
              fullWidth
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: 'white',
              }}
            >
              <Table.Row>
                <Table.HeaderCell>NO</Table.HeaderCell>
                <Table.HeaderCell>id</Table.HeaderCell>
                <Table.HeaderCell>라벨명</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {labels.map((item, idx) => (
                <Table.Row key={item.id}>
                  <Table.Cell>{idx + 1}</Table.Cell>
                  <Table.Cell>{item.id}</Table.Cell>
                  <Table.Cell>{item.name}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </FlexBox.Column>

      <LabelCreateModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
    </FlexBox.Row>
  );
};

export default LabelTab;

const PAGE_LIMIT = 20;
