import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Header, Pagination, Table } from 'semantic-ui-react';
import LabelMenu from '../LabelMenu/LabelMenu';
import FlexBox from '@component/FlexBox/FlexBox';
import { CrmTemplate } from '@types';
import { apis } from '@api/index';

const LabelCrmTemplate = () => {
  const [templateList, setTemplateList] = useState<CrmTemplate[]>([]);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const handleUpdateActive = async (id: number, active: boolean) => {
    await apis.patchCrmLabel(id, {
      isActive: active,
    });

    setTemplateList((templates) => {
      return templates.map((template) => {
        if (template.labelId === id) {
          return { ...template, isActive: active };
        }
        return template;
      });
    });
  };

  const fetchCrmTagList = async (startOffset: number) => {
    return apis.getCrmLabels({ offset: startOffset, limit: 15 });
  };

  useEffect(() => {
    fetchCrmTagList(offset).then((data) => {
      setTotalCount(data.totalCount);
      setTemplateList([...(data.labels || [])]);
    });
  }, [offset]);

  return (
    <FlexBox.Row style={{ margin: 40, gap: 30 }}>
      <LabelMenu />

      <div>
        <Header.Subheader as="h3">
          라벨별 CRM 템플릿 관리
          <Button
            size="large"
            style={{
              marginLeft: 20,
            }}
            to={{ pathname: '/labels-crm-template/create' }}
            as={Link}
          >
            템플릿 추가하러 가기 ➕
          </Button>
        </Header.Subheader>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>라벨 id</Table.HeaderCell>
              <Table.HeaderCell>라벨 이름</Table.HeaderCell>
              <Table.HeaderCell>CRM 자동 생성 여부</Table.HeaderCell>
              <Table.HeaderCell>생성 시간</Table.HeaderCell>
              <Table.HeaderCell>수정 시간</Table.HeaderCell>
              <Table.HeaderCell>마지막 체크 시간</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {templateList.map((template, idx) => (
              <Table.Row key={`${template.labelId}-${idx}`}>
                <Table.Cell>
                  <a href={`/labels-crm-template/${template.labelId}`}>
                    {template.labelId}
                  </a>
                </Table.Cell>
                <Table.Cell>{template.labelName}</Table.Cell>
                <Table.Cell>
                  <Checkbox
                    toggle
                    checked={template.isActive}
                    value={template.labelId}
                    onChange={(_, { value }) =>
                      handleUpdateActive(Number(value), !template.isActive)
                    }
                  />
                </Table.Cell>
                <Table.Cell>{template.createdAt}</Table.Cell>
                <Table.Cell>{template.updatedAt}</Table.Cell>
                <Table.Cell>{template.lastUpdatedAt || '없음'}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pagination
          defaultActivePage={1}
          totalPages={Math.ceil(totalCount / PAGE_LIMIT)}
          onPageChange={(_, { value }) => {
            setOffset(value);
          }}
        />
      </div>
    </FlexBox.Row>
  );
};

export default LabelCrmTemplate;

const PAGE_LIMIT = 15;
