import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'commerce'>,
  Pick<ChallengeFormType, 'commerce'>
> = ({ commerce }) => {
  return {
    commerce: {
      productHeader: commerce.productHeader || null,
      isMandatory: commerce.isMandatory || true,
      selectType: commerce.selectType || 'RADIO',
      rccps: commerce.rccps,
    },
  };
};

export const convenrtForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'commerce'>,
  Pick<
    ReqCreateOrUpdateChallenge,
    'productHeader' | 'isMandatory' | 'selectType' | 'rccps'
  >
> = ({ commerce }) => {
  return {
    productHeader: commerce.productHeader || '',
    selectType: commerce.selectType,
    isMandatory: !!commerce.isMandatory,
    rccps: commerce.rccps.map((rccp, i) => ({ ...rccp, order: i })),
  };
};
