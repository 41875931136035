import DateTimeInput from '@component/DateTimeInput';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import NumberInput from '@component/NumberInput';
import TimeInput from '@component/TimeInput';
import { ToggleSwitch } from '@component/ToggleSwitch';
import { FC } from 'react';
import { Table } from 'semantic-ui-react';
import { AchievementInfoItem } from '../../../../NewChallengeForm.types';
import {
  useAchievementInfoList,
  useSpecialPrizeType,
  useUpdateAchievementInfoList,
} from '../../AchievementInfoListForm.store';
import DueStartTime from './DueStartTime';
import DueStartDate from './DueStartDate';
import DueEndDate from './DueEndDate';
import DueEndTime from './DueEndTime';

type Props = {
  info: AchievementInfoItem;
  index: number;
};
const AchievmentInfoItem: FC<Props> = ({ info, index }) => {
  const specialPrizeType = useSpecialPrizeType();
  const infoList = useAchievementInfoList();
  const { update } = useUpdateAchievementInfoList();

  const handleChange = <T extends keyof AchievementInfoItem>(
    name: T,
    value: AchievementInfoItem[T],
  ) => {
    update(
      infoList.map((cur, i) => (i === index ? { ...cur, [name]: value } : cur)),
    );
  };

  return (
    <Table.Row>
      <Table.Cell>{index + 1}회차</Table.Cell>
      <Table.Cell textAlign="center">
        <FlexBox.Row gap={4} alignItems="center">
          <Label>시작일시</Label>
          <DueStartDate
            index={index}
            value={info.dueStartDate}
            onChange={handleChange}
          />
          <DueStartTime
            index={index}
            value={info.dueStartTime}
            onChange={handleChange}
          />
        </FlexBox.Row>
        <FlexBox.Row gap={4} alignItems="center">
          <Label>종료일시</Label>
          <DueEndDate
            index={index}
            value={info.dueEndDate}
            onChange={handleChange}
          />
          <DueEndTime
            index={index}
            value={info.dueEndTime}
            onChange={handleChange}
          />
        </FlexBox.Row>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <ToggleSwitch
          value={!!info.isPublic}
          onChange={(v) => handleChange('isPublic', v)}
        />
      </Table.Cell>
      {specialPrizeType === 'achievement' && (
        <Table.Cell textAlign="center">
          <FlexBox.Row>
            <NumberInput
              value={info.prizeAmount}
              onChange={(n) => handleChange('prizeAmount', n)}
            />
            <FlexBox.Row
              justifyContent="center"
              alignItems="center"
              style={{
                background: '#eee',
                width: 32,
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
              }}
            >
              원
            </FlexBox.Row>
          </FlexBox.Row>
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default AchievmentInfoItem;
