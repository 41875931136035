import DateTimeInput from '@component/DateTimeInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './StartDateForm.serializer';
import { useStartDateStore, useUpdateStartDate } from './StartDateForm.store';
import { validate } from './StartDateForm.validator';
import { useEditCheck } from '../../hooks/useEditCheck';

export const StartDateForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'startDate'>,
  Pick<ChallengeFormType, 'startDate'>,
  Pick<ReqCreateOrUpdateChallenge, 'startDate'>
> = () => {
  const { startDate, endDate } = useStartDateStore();
  const { updateStartDate } = useUpdateStartDate();
  const { triggerEdited, getBorder } = useEditCheck();

  const validated = validate({ startDate, endDate });

  const handleChange = (value: string) => {
    if (!value.length) return;

    triggerEdited('startDate');
    updateStartDate(value);
  };

  return (
    <DateTimeInput
      label="챌린지 시작일"
      dateOnly
      value={startDate}
      onChange={handleChange}
      errorMsg={validated.isValid ? '' : validated.message}
      style={{ border: getBorder('startDate') }}
    />
  );
};

StartDateForm.validate = validate;
StartDateForm.convertData2Form = convertData2Form;
StartDateForm.convertForm2Data = convertForm2Data;
