import { COLLABO_TAGS } from '../../NewChallengeForm.constants';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useCurrentPlatform = () => {
  const tagIds = useChallengeFormStore((s) => s.challenge.tagIds);
  const getPlatform = () => {
    if (tagIds.includes(COLLABO_TAGS.OLIVEYOUNG_ID)) {
      return {
        platform: 'oliveYoung',
        platformName: '올리브영',
      };
    }
    if (tagIds.includes(COLLABO_TAGS.NAVER_ID)) {
      return {
        platform: 'naver',
        platformName: '네이버',
      };
    }
    return {
      platform: '',
      platformName: '',
    };
  };

  return { getPlatform };
};

export const usePostConnectPrize = () =>
  useChallengeFormStore((s) => s.challenge.postConnectPrize);

export const useChallengeTagIds = () =>
  useChallengeFormStore((s) => s.challenge.tagIds);

export const useUpdatePostConnectInfo = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const updateAmount = (amount: number) => {
    updateChallengeForm((prev) => {
      return {
        ...prev,
        postConnectPrize: {
          ...prev.postConnectPrize!,
          amount,
        },
      };
    });
  };

  const currentAmount = useChallengeFormStore(
    (s) => s.challenge.postConnectPrize?.amount,
  );

  return { updateChallengeForm, updateAmount, currentAmount };
};
