import React from 'react';
import { Image, Table } from 'semantic-ui-react';
import { colors } from '../../../../../constant/colors';
import { RaceSponsor } from 'src/types/race.types';

const RaceSponsorInfo = ({ sponsors }: { sponsors: RaceSponsor[] }) => {
  if (!sponsors) return null;
  return (
    <>
      <h3>스폰서</h3>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>이름</Table.HeaderCell>
            <Table.HeaderCell>구분</Table.HeaderCell>
            <Table.HeaderCell>로고</Table.HeaderCell>
            <Table.HeaderCell>화이트로고 (회색은 배경색)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sponsors.map((sponsor, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{sponsor.name}</Table.Cell>
              <Table.Cell>{sponsor.type}</Table.Cell>
              <Table.Cell>
                <Image size="tiny" src={sponsor.logoImageUrl} />
              </Table.Cell>
              <Table.Cell>
                <div style={{ backgroundColor: colors.GRAY_500, padding: 10 }}>
                  <Image size="tiny" src={sponsor.whiteLogoImageUrl} />
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default RaceSponsorInfo;
