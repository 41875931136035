import { CustomImageFilterInfoType } from '@types';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { useShallow } from 'zustand/react/shallow';
import { Achievements } from 'src/types/achievement.types';

export const useChallengeImageFilterStore = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      imageFilter: s.challenge.imageFilter,
      goalId: s.challenge.goalId,
      startDate: s.challenge.startDate,
      endDate: s.challenge.endDate,
      distance: s.challenge.distance,
    })),
  );
};

export const useChallengeImageFilterActions = (
  achievements: Achievements[],
) => {
  const { imageFilter } = useChallengeImageFilterStore();
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setChallengeImageFilter = (
    achievementIndex: number,
    info: CustomImageFilterInfoType[],
  ) => {
    const targetImageFilter = imageFilter.find(
      (v) => v.achievementIndex === achievementIndex,
    );

    const newImageFilter = targetImageFilter
      ? imageFilter.map((v) =>
          v.achievementIndex === achievementIndex
            ? { achievementIndex, info }
            : v,
        )
      : [...imageFilter, { achievementIndex, info }];
    updateChallengeForm((prev) => ({ ...prev, imageFilter: newImageFilter }));
  };

  const setAllSame = () => {
    const newImageFilter = achievements.map((_, idx) => ({
      ...imageFilter[0],
      achievementIndex: idx + 1,
    }));
    updateChallengeForm((prev) => ({ ...prev, imageFilter: newImageFilter }));
  };

  const clear = () => {
    updateChallengeForm((prev) => ({ ...prev, imageFilter: [] }));
  };

  const clearByAchievementIndex = (achievementIndex: number) => {
    const newImageFilter = imageFilter.filter(
      (v) => v.achievementIndex !== achievementIndex,
    );
    updateChallengeForm((prev) => ({ ...prev, imageFilter: newImageFilter }));
  };

  return {
    setChallengeImageFilter,
    setAllSame,
    clear,
    clearByAchievementIndex,
  };
};
