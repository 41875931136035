import {
  RecommendedUpdates,
  useChallengeEditedStore,
} from '../NewChallengeForm.store';

export const useEditCheck = () => {
  const { recommendedUpdates, updateEdited } = useChallengeEditedStore((s) => ({
    recommendedUpdates: s.recommendedUpdates,
    updateEdited: s.updateEdited,
  }));

  const triggerEdited = (key: keyof RecommendedUpdates) => {
    if (recommendedUpdates[key] === true) return;
    updateEdited({ [key]: true });
  };

  const getBorder = (key: keyof RecommendedUpdates) => {
    return recommendedUpdates[key]
      ? '1px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133))'
      : `3px solid rgb(255, 128, 0)`;
  };

  return { getBorder, triggerEdited };
};
