import { apis } from '@api/index';
import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

const PassModal = ({
  achievement,
  passModalOpen,
  closePassModal,
}: {
  achievement: any;
  passModalOpen: boolean;
  closePassModal: () => void;
}) => {
  const useHeart = async () => {
    const res = await apis.useAchievementItem(achievement.id);
    if ((res as any)?.response?.data?.err) {
      return;
    }
    closePassModal();
    alert('인증패스 사용이 완료되었습니다.');
  };
  return (
    <Modal size="tiny" open={passModalOpen} onClose={closePassModal}>
      <Modal.Header>인증패스 처리하기</Modal.Header>
      <Modal.Content>
        <p>[{achievement.id}] 인증카드에 인증패스 처리를 하시겠습니까?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" content="사용" onClick={useHeart} />
        <Button color="black" content="닫기" onClick={closePassModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default PassModal;
