export const maskString = (s) => {
  let masked = '';

  for (let i = 0; i < s.length; i++) {
    if (i < 3) {
      masked += s[i];
    } else {
      masked += '*';
    }
  }

  return masked;
};

export const splitNewLine = (s) => {
  return s.split('\n').map((line, idx) => (
    <span key={idx}>
      {line}
      <br />
    </span>
  ));
};

export const arrTostring = (arr) => {
  return arr.map((line, idx) => {
    return (
      <span key={idx}>
        {line.split('\n').map((l, i) => {
          return (
            <span key={i}>
              {l}
              <br />
            </span>
          );
        })}
      </span>
    );
  });
};
