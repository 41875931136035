import styled from 'styled-components';
import { Form } from 'semantic-ui-react';

export const RowContainer = styled.div`
  display: flex;
`;

export const RowFormField = styled(Form.Field)`
  flex: 1;
  margin: ${(props) => props.margin && props.margin} !important;
`;
