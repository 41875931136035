import { RaceTemporaryOriginImageDescriptionModule } from '@container/Race/RaceForm/RaceForm.types';
import { isGifFile } from '@utils/node.utils';
import { cropAndUploadFile, uploadSingleFileToS3 } from '@utils/uploadImage';
import { Form, Image } from 'semantic-ui-react';
import FlexBox from '@component/FlexBox/FlexBox';

const TemporaryImageModule = ({
  idx,
  module,
  handleChange,
}: {
  idx: number;
  module: RaceTemporaryOriginImageDescriptionModule;
  handleChange: any;
}) => {
  const handleTemporaryMultiImageChange = async (e: {
    target: { name: string; files: FileList | null };
  }) => {
    if (!e.target.files) return;
    const { name, files } = e.target;
    const file = files[0];

    const urls: string[] = isGifFile(file)
      ? [await uploadSingleFileToS3(file)]
      : await cropAndUploadFile(name, file);

    handleChange(idx, 'urls', urls);
  };
  return (
    <>
      <div>
        <h4>기간 한정 프로모션 이미지</h4>
        <Form.Field>
          <input
            type="file"
            name="url"
            accept="image/*"
            onChange={handleTemporaryMultiImageChange}
          />
          <FlexBox.Row style={{ width: '100%', overflow: 'auto' }}>
            {module.urls.map((url, _idx) => (
              <Image key={`${url}-${_idx}`} size={'small'} src={url} />
            ))}
          </FlexBox.Row>
        </Form.Field>
      </div>
      <br />
      <div>
        <h4>노출 시작일</h4>
        <Form.Field>
          <input
            type="datetime-local"
            max="9999-12-31T23:59"
            value={module.dueStartDate}
            onChange={(e) => handleChange(idx, 'dueStartDate', e.target.value)}
          />
        </Form.Field>
      </div>
      <br />
      <div>
        <h4>노출 종료일</h4>
        <Form.Field>
          <input
            type="datetime-local"
            max="9999-12-31T23:59"
            value={module.dueEndDate}
            onChange={(e) => handleChange(idx, 'dueEndDate', e.target.value)}
          />
        </Form.Field>
      </div>
    </>
  );
};

export default TemporaryImageModule;
