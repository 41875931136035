import { Step } from 'semantic-ui-react';

const Count = ({ totalCount, filteredCount }: CountProps) => {
  return (
    <>
      <Step.Group fluid>
        <Step>
          <Step.Content>
            <Step.Title>전체 개수</Step.Title>
            <Step.Description>
              {String(totalCount).toLocaleString()} 개
            </Step.Description>
          </Step.Content>
        </Step>
        <Step>
          <Step.Content>
            <Step.Title>필터 개수</Step.Title>
            <Step.Description>
              {String(filteredCount).toLocaleString()} 개
            </Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    </>
  );
};

export default Count;

type CountProps = {
  totalCount: number;
  filteredCount: number;
};
