import styled from 'styled-components';
import { Form } from 'semantic-ui-react';

export const Section = styled.div`
  margin: 20px 0;
`;

export const RowContainer = styled.div`
  display: flex;
  margin: ${(props) => props.margin && props.margin} !important;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
`;

export const Wrapper = styled.div`
  flex: 1;
`;

export const RowFormGroup = styled(Form.Group)`
  flex: 1;
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const RowFormField = styled(Form.Field)`
  flex: 1;
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const ButtonWrapper = styled.div`
  padding: 40px 0;
`;
