import { Race } from '@types';
import { Form, Table } from 'semantic-ui-react';
import * as S from '../../../../Style';
import { ColorSample } from '@component/ColorSample';

const ContentsCardModule = ({
  idx,
  module,
  handleChange,
  handleCardListChange,
  addCard,
  deleteCard,
}: {
  idx: number;
  module: Race.RaceHomeContentsCardModule;
  handleChange: any;
  handleCardListChange: <K extends keyof Race.RaceHomeContentsCardModuleCard>(
    moduleIdx: number,
    cardIdx: number,
    key: K,
    value: Race.RaceHomeContentsCardModuleCard[K],
  ) => void;
  addCard: (idx: number) => void;
  deleteCard: (moduleIdx: number, cardIdx: number) => void;
}) => {
  return (
    <>
      <h4>컨텐츠 카드 모듈</h4>
      <Form.Field>
        <label>제목</label>
        <input
          value={module.title}
          onChange={(e) => handleChange(idx, 'title', e.target.value)}
        />
      </Form.Field>
      <div style={{ padding: 10, borderRadius: 10, border: '1px solid gray' }}>
        <h5>컨텐츠 카드</h5>
        <Table basic="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>no</Table.HeaderCell>
              <Table.HeaderCell>이모지</Table.HeaderCell>
              <Table.HeaderCell>제목</Table.HeaderCell>
              <Table.HeaderCell>내용</Table.HeaderCell>
              <Table.HeaderCell>카드배경색</Table.HeaderCell>
              <Table.HeaderCell>카드제목색</Table.HeaderCell>
              <Table.HeaderCell>카드글씨색</Table.HeaderCell>
              <Table.HeaderCell>삭제</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {module.cards.map((card, i) => (
              <Table.Row key={card.title + i}>
                <Table.Cell>{i + 1}</Table.Cell>
                <Table.Cell>
                  <input
                    value={card.emoji}
                    onChange={(e) =>
                      handleCardListChange(idx, i, 'emoji', e.target.value)
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <input
                    value={card.title}
                    onChange={(e) =>
                      handleCardListChange(idx, i, 'title', e.target.value)
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <textarea
                    rows={3}
                    value={card.body}
                    onChange={(e) =>
                      handleCardListChange(idx, i, 'body', e.target.value)
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <input
                    value={card.bgColor}
                    onChange={(e) =>
                      handleCardListChange(idx, i, 'bgColor', e.target.value)
                    }
                  />
                  <ColorSample color={card.bgColor} />
                </Table.Cell>
                <Table.Cell>
                  <input
                    value={card.titleColor}
                    onChange={(e) =>
                      handleCardListChange(idx, i, 'titleColor', e.target.value)
                    }
                  />
                  <ColorSample color={card.titleColor} />
                </Table.Cell>
                <Table.Cell>
                  <input
                    value={card.bodyColor}
                    onChange={(e) =>
                      handleCardListChange(idx, i, 'bodyColor', e.target.value)
                    }
                  />
                  <ColorSample color={card.bodyColor} />
                </Table.Cell>
                <Table.Cell>
                  <S.ButtonInForm
                    content={'삭제'}
                    onClick={() => deleteCard(idx, i)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
            <Table.Row>
              <Table.Cell colSpan={6}>
                <S.ButtonInForm
                  content={'카드 추가'}
                  onClick={() => addCard(idx)}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </>
  );
};

export default ContentsCardModule;
