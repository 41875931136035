import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convenrtForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'challengeStickers'>,
  Pick<ReqCreateOrUpdateChallenge, 'challengeStickers'>
> = ({ challengeStickers }) => {
  if (!challengeStickers)
    throw Error("[challengeStickersForm]this shouldn't be happened");

  if (challengeStickers.length === 0) return {};

  return {
    challengeStickers,
  };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'challengeStickers'>,
  Pick<ChallengeFormType, 'challengeStickers'>
> = ({ challengeStickers }) => ({
  challengeStickers: challengeStickers || [],
});
