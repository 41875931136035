import { Checkbox } from 'semantic-ui-react';
import React from 'react';
import * as S from '../../PedometerGiftiShopBuyers.styles';

type BuyerItemProps = {
  checked: boolean;
  onClick: () => void;
  id: number;
  name: string | undefined;
  product: string;
  userId: number | undefined;
  phone: string | undefined;
  isReceived: boolean;
  disabled: boolean;
};

export const BuyerItem: React.FC<BuyerItemProps> = ({
  checked,
  onClick,
  id,
  name,
  product,
  userId,
  phone,
  isReceived,
  disabled,
}) => {
  return (
    <S.Item onClick={onClick} isReceived={isReceived} isHeader={false}>
      <Checkbox checked={checked} disabled={disabled} />
      <S.Row>{`${id}`}</S.Row>
      <S.Row>{`${name ?? '-'}`}</S.Row>
      <S.Row>{`${product}`}</S.Row>
      <S.Row>{`${phone ?? '-'}`}</S.Row>
      <S.Row>{`${userId ?? '-'}`}</S.Row>
    </S.Item>
  );
};
