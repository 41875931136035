import React, { useCallback, useState } from 'react';
import { Accordion, Table, Image, Header, Icon } from 'semantic-ui-react';
import { colors } from '../../../../../constant/colors';
import {
  RaceImageFilterLogoStamp,
  RaceImageFilterWithLeagueId,
  RaceLeague,
} from 'src/types/race.types';

const RaceLeageInfo = ({
  leagues,
  filters,
}: {
  leagues: RaceLeague[];
  filters: RaceImageFilterWithLeagueId[];
}) => {
  const [collapsed, setCollapsed] = useState(
    leagues.reduce(
      (acc, cur, idx) => {
        acc[idx] = true;
        return acc;
      },
      {} as { [key: number]: boolean },
    ),
  );

  const handleClick = useCallback(
    (curr: { [key: number]: boolean }, idx: number) => {
      const _curr = { ...curr };
      _curr[idx] = !_curr[idx];
      setCollapsed(_curr);
    },
    [],
  );

  return (
    <>
      <h3>리그</h3>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>리그명</Table.HeaderCell>
            <Table.HeaderCell>최소값</Table.HeaderCell>
            <Table.HeaderCell>최대값</Table.HeaderCell>
            <Table.HeaderCell>
              기념샷스탬프(~v2.2.12)
              <br />
              (좌측상단, 화이트로고)
            </Table.HeaderCell>
            <Table.HeaderCell>
              필터(v2.2.13~)
              <br />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {leagues?.map((league, idx) => {
            const filtersWithLeague = filters.find(
              (o) => o.leagueId === league.id,
            );
            const imageFilters = filtersWithLeague
              ? filtersWithLeague.filters
              : [];

            return (
              <Table.Row key={idx}>
                <Table.Cell>{league.title}</Table.Cell>
                <Table.Cell>{league.minUnitValue}</Table.Cell>
                <Table.Cell>{league.maxUnitValue}</Table.Cell>
                <Table.Cell>
                  {league.stickers?.map((sticker) => (
                    <div
                      key={sticker.imageUrl}
                      style={{ backgroundColor: colors.GRAY_500, padding: 10 }}
                    >
                      <Image size="tiny" src={sticker.imageUrl} />
                    </div>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  <Accordion>
                    <Accordion.Title
                      active={!collapsed[idx]}
                      onClick={() => handleClick(collapsed, idx)}
                    >
                      <Header as={'h5'}>
                        <Icon name="dropdown" /> 펼쳐보기
                      </Header>
                    </Accordion.Title>
                    <Accordion.Content active={!collapsed[idx]}>
                      {imageFilters?.map((filter) => {
                        const logoStamp = filter.stamps.find(
                          ({ type }) => type === 'LOGO_STAMP',
                        ) as RaceImageFilterLogoStamp | undefined;

                        return (
                          <div
                            key={filter.id}
                            style={{
                              border: `1px solid ${colors.GRAY_500}`,
                              borderRadius: 4,
                              marginTop: 10,
                              padding: 10,
                            }}
                          >
                            {filter.imageUrl ? (
                              <div
                                style={{
                                  backgroundColor: colors.GRAY_200,
                                  border: `1px solid ${colors.GRAY_500}`,
                                  width: 375,
                                  height: 375,
                                  position: 'relative',
                                }}
                              >
                                <Image
                                  style={{ width: 375, height: 375 }}
                                  src={filter.imageUrl}
                                />
                                {!!logoStamp && (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: 20,
                                      left: 20,
                                      padding: 5,
                                    }}
                                  >
                                    <Image
                                      style={{
                                        width: logoStamp.width,
                                        height: logoStamp.height,
                                      }}
                                      src={logoStamp.imageUrl}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                style={{
                                  backgroundColor: colors.GRAY_200,
                                  border: `1px solid ${colors.GRAY_500}`,
                                  width: 375,
                                  height: 375,
                                  position: 'relative',
                                }}
                              >
                                {!!logoStamp && (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: 20,
                                      left: 20,
                                      padding: 5,
                                    }}
                                  >
                                    <Image
                                      style={{
                                        width: logoStamp.width,
                                        height: logoStamp.height,
                                      }}
                                      src={logoStamp.imageUrl}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </Accordion.Content>
                  </Accordion>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

export default RaceLeageInfo;
