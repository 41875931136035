import { OldCardTransactionWithdrawInfo } from '@types';
import moment from 'moment';
import { Button, Checkbox, Table } from 'semantic-ui-react';
import setCurrencyFormat from '../../../global/setCurrencyFormat';
import React from 'react';

const TransactionTransferOldCardCashTableRow = ({
  wd,
  handleChangeCheckbox,
  showModal,
  handleChangeWithdrawIsCompleted,
}: {
  wd: OldCardTransactionWithdrawInfo;
  handleChangeCheckbox: (id: number) => void;
  showModal: (wd: OldCardTransactionWithdrawInfo) => void;
  handleChangeWithdrawIsCompleted: (id: number) => void;
}) => {
  const multiple = moment(wd.createdAt).isSameOrAfter(moment('2020-07-01'))
    ? 0.97
    : 1;
  return (
    <Table.Row key={wd.id}>
      <Table.Cell collapsing>
        <Checkbox
          checked={wd.isChecked}
          value={wd.id}
          onChange={() => handleChangeCheckbox(wd.id)}
        />
      </Table.Cell>
      <Table.Cell>{wd.id}</Table.Cell>
      <Table.Cell>{wd.userId}</Table.Cell>
      <Table.Cell>
        {moment.utc(wd.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}
      </Table.Cell>
      <Table.Cell>{wd.bankName}</Table.Cell>
      <Table.Cell>{wd.accountNo}</Table.Cell>
      <Table.Cell>
        {setCurrencyFormat(wd.originalTransactionPg.amount)}
      </Table.Cell>
      <Table.Cell>
        {moment(wd.originalTransactionPg.transactionDate).format(
          'YYYY-MM-DD HH:mm:ss',
        )}
      </Table.Cell>
      <Table.Cell>
        {setCurrencyFormat(Math.ceil(-wd.amount * multiple))}
      </Table.Cell>
      <Table.Cell>{wd.accountName}</Table.Cell>
      <Table.Cell>
        {wd.answer}
        <br />
        <Button size="mini" onClick={() => showModal(wd)} value={wd}>
          답변하기
        </Button>
      </Table.Cell>
      <Table.Cell>
        <Checkbox
          checked={wd.isCompleted}
          name="isCompleted"
          value={wd.id}
          onChange={() => handleChangeWithdrawIsCompleted(wd.id)}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(TransactionTransferOldCardCashTableRow);
