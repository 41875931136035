import { GoalCycle } from '@types';

export const convertCycleOptionValueFromCycle = (
  cycle: GoalCycle[],
  dayOrPeriod: 'DAY' | 'PERIOD',
) => {
  if (dayOrPeriod === 'DAY') {
    if (cycle.length === 2) {
      return 'EVERY_WEEKEND';
    }
    if (cycle.length === 5) {
      return 'EVERY_DAY_OF_WEEK';
    }
    return 'EVERYDAY';
  }

  if (cycle.includes('ALL')) {
    return 'ALL';
  }
  if (cycle.includes('BIWEEKLY')) {
    return 'BIWEEKLY';
  }
  if (cycle.includes('MONTHLY')) {
    return 'MONTHLY';
  }
  return 'WEEKLY';
};
