import {
  ChallengeFormType,
  DescriptionModuleFormType,
  FormValidator,
} from '../../NewChallengeForm.types';
import { descriptionModules } from './DescriptionModuleForm.constant';

type DescriptionName = 'descriptionModule' | 'descriptionExtra';

export const validate: <T extends DescriptionName>(
  descriptionName: T,
) => FormValidator<Pick<ChallengeFormType, T>> =
  (descriptionName) => (form) => {
    const description = form[descriptionName];
    if (!description) return { isValid: true };

    for (let index = 0; index < description.length; index++) {
      const { type } = description[index];
      const module = descriptionModules[type];
      const validationResult = module.validate(description);
      if (!validationResult.isValid) return validationResult;
    }

    if (isDuplicated(description, 'COLLABO-SPECIAL-PRIZE')) {
      return {
        isValid: false,
        message: '[상세모듈] 특별상금 모듈은 1개만 등록할 수 있습니다',
      };
    }

    if (isDuplicated(description, 'COLLABO-SPECIAL-REWARD')) {
      return {
        isValid: false,
        message: '[상세모듈] 특별리워드 모듈은 1개만 등록할 수 있습니다',
      };
    }

    if (isDuplicated(description, 'COLLABO-ADDITIONAL-REWARD')) {
      return {
        isValid: false,
        message: '[상세모듈] 추가리워드 모듈은 1개만 등록할 수 있습니다',
      };
    }

    if (isDuplicated(description, 'COLLABO-TOP-SUMMARY')) {
      return {
        isValid: false,
        message: '[상세모듈] 최상단 요약 모듈은 1개만 등록할 수 있습니다',
      };
    }

    return { isValid: true };
  };

const isDuplicated = (
  descriptionModule: DescriptionModuleFormType[],
  key: DescriptionModuleFormType['type'],
) => {
  return descriptionModule.filter(({ type }) => type === key).length > 1;
};
