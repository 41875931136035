import { Crm } from '@types';

export const getDefaultBody = (offerWalltypes: Crm.CrmOfferWallType[]) => {
  if (
    offerWalltypes.length === 2 &&
    offerWalltypes.includes('RACE_INSTAGRAM_MISSION') &&
    offerWalltypes.includes('RACE_SURVEY')
  ) {
    return `(광고)


자세한 내용은 아래 링크에서 확인해 주세요!
───────
① 인스타 이벤트 URL :
② 설문조사 URL : 
───────
* 인스타 이벤트 상금은 대회 종료 후 5일 뒤 일괄 지급됩니다 *
무료수신거부 0808772939`;
  }

  if (offerWalltypes.includes('RACE_INSTAGRAM_MISSION')) {
    return `(광고)


자세한 내용은 아래 링크에서 확인해 주세요!
───────
▶ 인스타 이벤트 URL : 
───────
* 인스타 이벤트 상금은 대회 종료 후 5일 뒤 일괄 지급됩니다 *
무료수신거부 0808772939`;
  }

  if (offerWalltypes.includes('RACE_SURVEY')) {
    return `(광고)


자세한 내용은 아래 링크에서 확인해 주세요!
───────
▶ 설문조사 URL : 
───────
무료수신거부 0808772939`;
  }

  return '';
};
