import { apis } from '@api/index';
import { format, subHours } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  Form,
  Header,
  Icon,
  Input,
  Modal,
  Table,
} from 'semantic-ui-react';
import { UserRestrictInfo } from 'src/types/user.types';

const INIT_USER = {
  id: 0,
  nickname: '',
  name: '',
};

const UserRestrict = () => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [restrictModalOpen, setRestrictModalOpen] = useState(false);
  const [restrictModalType, setRestrictModalType] = useState<'ADD' | 'EDIT'>(
    'ADD',
  );
  const [restrictUsers, setRestrictUsers] = useState<UserRestrictInfo[]>([]);
  const [user, setUser] = useState<UserRestrictInfo>(INIT_USER);

  const showRestrictModal = useCallback(() => setRestrictModalOpen(true), []);
  const closeRestrictModal = useCallback(() => setRestrictModalOpen(false), []);

  useEffect(() => {
    getRestrictUsers();
  }, []);

  const getRestrictUsers = async () => {
    const { users } = await apis.user.getRestrictedUsers();
    setRestrictUsers(users);
  };

  const handleChangeRestrict = (
    key: keyof UserRestrictInfo,
    value: number | string | Date,
  ) => {
    if (user) {
      const _user = { ...user, [key]: value };
      setUser(_user);
    }
  };

  const addRestrictUser = async () => {
    if (!user) return;
    await apis.user.createOrUpdateUserRestrict({
      userId: Number(user.id),
      reportBanEndDatetime: user.reportBanEndDatetime
        ? format(
            subHours(new Date(user.reportBanEndDatetime), 9),
            'yyyy-MM-dd HH:mm:ss',
          )
        : undefined,
      permanentBanStartTime: user.permanentBanStartTime
        ? format(
            subHours(new Date(user.permanentBanStartTime), 9),
            'yyyy-MM-dd HH:mm:ss',
          )
        : undefined,
      reason: user.reason,
    });

    alert('유저 제한 정보 추가가 완료되었습니다.');
    getRestrictUsers();
    closeRestrictModal();
  };

  const editRestrictUser = async () => {
    if (!user) return;
    await apis.user.createOrUpdateUserRestrict({
      userId: Number(user.id),
      reportBanEndDatetime: user.reportBanEndDatetime
        ? format(
            subHours(new Date(user.reportBanEndDatetime), 9),
            'yyyy-MM-dd HH:mm:ss',
          )
        : undefined,
      permanentBanStartTime: user.permanentBanStartTime
        ? format(
            subHours(new Date(user.permanentBanStartTime), 9),
            'yyyy-MM-dd HH:mm:ss',
          )
        : undefined,
      reason: user.reason,
    });

    alert('유저 제한 정보 수정이 완료되었습니다.');
    getRestrictUsers();
    closeRestrictModal();
  };

  const deleteRestrictUser = async (restrictUser: UserRestrictInfo) => {
    if (!restrictUser) return;
    if (
      window.confirm(
        `[${restrictUser.id} / ${restrictUser.nickname}]의 제한 정보를 삭제하시겠습니까?`,
      )
    ) {
      await apis.user.deleteUserRestrict({ userId: restrictUser.id });
      alert('유저 제한 정보 삭제가 완료되었습니다.');
      const _restrictUsers = restrictUsers.filter(
        (u) => u.id !== restrictUser.id,
      );
      setRestrictUsers(_restrictUsers);
      closeRestrictModal();
    }
  };

  return (
    <Accordion>
      <Accordion.Title
        active={isAccordionOpen}
        index={1}
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
      >
        <Header as="h3" style={{ display: 'inline-block' }}>
          <Icon name="dropdown" />
          유저 제한 정보
        </Header>
        <Button
          size="mini"
          style={{ marginLeft: 10, display: 'inline' }}
          onClick={() => {
            showRestrictModal();
            setRestrictModalType('ADD');
            setUser(INIT_USER);
          }}
          content="추가"
        />
      </Accordion.Title>
      <Accordion.Content active={isAccordionOpen}>
        <Table basic="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>유저id</Table.HeaderCell>
              <Table.HeaderCell>이름</Table.HeaderCell>
              <Table.HeaderCell>닉네임</Table.HeaderCell>
              <Table.HeaderCell>신고금지종료시점</Table.HeaderCell>
              <Table.HeaderCell>접속차단시작시점</Table.HeaderCell>
              <Table.HeaderCell>사유</Table.HeaderCell>
              <Table.HeaderCell>수정/삭제</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {restrictUsers.map((o) => {
              return (
                <Table.Row key={o.id}>
                  <Table.Cell>{o.id}</Table.Cell>
                  <Table.Cell>{o.name}</Table.Cell>
                  <Table.Cell>{o.nickname}</Table.Cell>
                  <Table.Cell>
                    {o.reportBanEndDatetime
                      ? format(
                          new Date(o.reportBanEndDatetime),
                          'yyyy-MM-dd HH:mm:ss',
                        )
                      : '-'}
                  </Table.Cell>
                  <Table.Cell>
                    {o.permanentBanStartTime
                      ? format(
                          new Date(o.permanentBanStartTime),
                          'yyyy-MM-dd HH:mm:ss',
                        )
                      : '-'}
                  </Table.Cell>
                  <Table.Cell>{o.reason ?? '-'}</Table.Cell>
                  <Table.Cell>
                    <Button
                      size="mini"
                      value={o}
                      onClick={() => {
                        showRestrictModal();
                        setRestrictModalType('EDIT');
                        setUser(o);
                      }}
                      content="수정"
                    />
                    <Button
                      size="mini"
                      value={o}
                      onClick={() => deleteRestrictUser(o)}
                      content="삭제"
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        <Modal
          size="small"
          open={restrictModalOpen}
          onClose={closeRestrictModal}
        >
          <Modal.Header>유저 제한</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>user id</label>
                <Input
                  name="id"
                  value={user?.id}
                  onChange={(e) =>
                    handleChangeRestrict('id', Number(e.target.value))
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>신고금지 종료시점</label>
                <Input
                  type="datetime-local"
                  step="1"
                  max="9999-12-31T23:59:59"
                  value={format(
                    user?.reportBanEndDatetime
                      ? new Date(user?.reportBanEndDatetime)
                      : new Date(),
                    "yyyy-MM-dd'T'HH:mm:ss",
                  )}
                  onChange={(e) =>
                    handleChangeRestrict('reportBanEndDatetime', e.target.value)
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>접속불가 시작시점</label>
                <Input
                  type="datetime-local"
                  step="1"
                  max="9999-12-31T23:59:59"
                  value={format(
                    user?.permanentBanStartTime
                      ? new Date(user?.permanentBanStartTime)
                      : new Date(),
                    "yyyy-MM-dd'T'HH:mm:ss",
                  )}
                  onChange={(e) =>
                    handleChangeRestrict(
                      'permanentBanStartTime',
                      e.target.value,
                    )
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>제한 사유</label>
                <Input
                  onChange={(e) =>
                    handleChangeRestrict('reason', e.target.value)
                  }
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="제출"
              onClick={
                restrictModalType === 'ADD' ? addRestrictUser : editRestrictUser
              }
            />
            <Button color="black" content="닫기" onClick={closeRestrictModal} />
          </Modal.Actions>
        </Modal>
      </Accordion.Content>
    </Accordion>
  );
};

export default UserRestrict;
