import { isNull } from '@utils/js.utils';
import {
  ChallengeFormType,
  FormValidator,
  SupportersWeight,
} from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'supporters' | 'registerEndDate'>;

/**
 * validation 항목
 * 0. 값이 전부 없을 경우 추가되지 않는 경우 체험단을 설정하지 않는 것이므로 통과처리
 * 1. 가중치 총합 == 1
 * 2. 추첨 인원수 최소 1명 이상
 * 3. 체험단 선정시 제공되는 혜택 텍스트 무조건 존재
 * 4. 성별 선택 최소 1개 이상
 * 5. 나이대 선택 최소 1개 이상
 * 6. 모집 선정일 필수설정
 * 7. 모집 선정일은 무조건 모집 종료일 이후
 */
export const validate: FormValidator<Params> = ({
  supporters,
  registerEndDate,
}) => {
  if (isDefault(supporters)) return { isValid: true };
  if (!isTotalWeightOne(supporters))
    return {
      isValid: false,
      message: '[체험단] 가중치 총 합은 1이어야 합니다',
    };
  if (isNull(supporters.userLimit) || supporters.userLimit < 1)
    return {
      isValid: false,
      message: '[체험단] 추첨 인원수는 최소 1명 이상이어야 합니다',
    };
  if (!supporters.benefit)
    return { isValid: false, message: '[체험단] 체험단 선정 혜택이 없습니다' };
  if (!supporters.gender.length)
    return { isValid: false, message: '[체험단] 성별이 설정되지 않았습니다' };
  if (!supporters.age.length)
    return { isValid: false, message: '[체험단] 나이대가 설정되지 않았습니다' };
  if (!supporters.selectionResultDate)
    return {
      isValid: false,
      message: '[체험단] 모집 선정일이 설정되지 않았습니다',
    };
  if (
    !isAfterRegisterEndDate(
      new Date(supporters.selectionResultDate),
      registerEndDate,
    )
  )
    return {
      isValid: false,
      message: '[체험단] 모집 선정일이 모집 종료일보다 이전입니다',
    };
  return { isValid: true };
};

const isDefault = (supporters: ChallengeFormType['supporters']) => {
  const { userLimit, benefit, category, gender, age, selectionResultDate } =
    supporters;
  if (!isNull(userLimit)) return false;
  if (benefit) return false;
  if (category.length) return false;
  if (!isNull(selectionResultDate)) return false;
  if (gender.length) return false;
  if (age.length) return false;

  return true;
};

const WEIGHT_POSTFIX = 'Weight';
const isTotalWeightOne = (supporters: ChallengeFormType['supporters']) => {
  const weightSum = Object.keys(supporters)
    .filter((key) => key.includes(WEIGHT_POSTFIX))
    .reduce((acc: number, key) => {
      return (
        acc + 10 * (Number(supporters[key as keyof SupportersWeight]) as number)
      );
    }, 0);
  return weightSum / 10 === 1;
};

const isAfterRegisterEndDate = (
  selectionResultDate: Date,
  registerEndDate: string | null,
) => {
  if (!registerEndDate) return false;
  if (selectionResultDate.getTime() < new Date(registerEndDate).getTime())
    return false;
  return true;
};
