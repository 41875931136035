import React, { useState } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import FlexBox from '../FlexBox/FlexBox';
import Label from '../Label';
import DropDownUIComponent from './components/DropDownUIComponent/DropDownUIComponent';

/**
 * @props label 이름표
 * @props placeholder placeholder
 * @props search 검색 가능여부
 * @props options 드롭다운 옵션 목록, text 값 기준으로 드롭다운 보여줌
 * @props value 드롭다운 선택 값
 * @props onChange 드롭다운 선택 값 변경 이벤트
 * @props onSearchChange 드롭다운 검색 텍스트 변경 이벤트
 * @props onReachEnd 드롭다운 스크롤 끝에 도달 이벤트
 */
type Props<T> = {
  label?: string;
  placeholder?: string;
  search?: boolean;
  value?: T;
  disabled?: boolean;
  options: { text: string; value: T }[];
  upward?: boolean;
  disableNoneSelect?: boolean;
  keyExtractor: (item: T) => string | number;
  onChange?: (data: T | null) => void;
  onSearchChange?: (text: string) => void;
  onReachEnd?: () => void;
  style?: React.CSSProperties;
};

const Dropdown = <T extends string | number>({
  label,
  options,
  value,
  placeholder = '',
  search = false,
  keyExtractor,
  disabled = false,
  upward,
  disableNoneSelect,
  onChange,
  onSearchChange,
  onReachEnd,
  style,
}: Props<T>) => {
  const [searchText, setSearchText] = useState('');

  const selectedOption = value
    ? options.find((o) => keyExtractor(o.value) === keyExtractor(value))
    : undefined;

  const filteredOptions = options.filter((o) =>
    o.text.replace(/ /g, '').includes(searchText.replace(/ /g, '')),
  );

  const handleChange = (data?: DropdownItemProps) => {
    if (onChange) {
      onChange((data?.value ?? null) as T | null);
    }
  };

  const handleSearchChange = (e: React.SyntheticEvent<HTMLElement, Event>) => {
    if ('value' in e.target) {
      setSearchText(e.target.value as string);
      if (onSearchChange) {
        onSearchChange(e.target.value as string);
      }
    }
  };

  return (
    <FlexBox.Column gap={4}>
      {label && <Label>{label}</Label>}
      <DropDownUIComponent
        placeholder={placeholder}
        search={search}
        value={selectedOption}
        disabled={disabled}
        upward={upward}
        disableNoneSelect={disableNoneSelect}
        options={filteredOptions.map((o) => o as DropdownItemProps)}
        onChange={handleChange}
        onSearchChange={handleSearchChange}
        onReachEnd={onReachEnd}
        style={style}
      />
    </FlexBox.Column>
  );
};

export default Dropdown;
