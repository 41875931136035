import React, { FC } from 'react';
import { Checkbox } from 'semantic-ui-react';

type Props = {
  value: boolean;
  disabled?: boolean;
  onChange?: (valie: boolean) => void;
};
export const ToggleSwitch: FC<Props> = ({ value, disabled, onChange }) => {
  return (
    <Checkbox
      toggle
      disabled={disabled}
      checked={value}
      onChange={(_, { checked }) => {
        onChange?.(!!checked);
      }}
    />
  );
};
