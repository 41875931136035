import { UserWithdrawInfo } from '@types';
import React from 'react';
import { Button, Form, Modal, TextArea } from 'semantic-ui-react';

const WithdrawModal = ({
  modalOpen,
  closeModal,
  withdraw,
  handleWithdrawChange,
  submitWithdrawAnswer,
}: {
  modalOpen: boolean;
  closeModal: () => void;
  withdraw: UserWithdrawInfo | undefined;
  handleWithdrawChange: (key: keyof UserWithdrawInfo, value: any) => void;
  submitWithdrawAnswer: () => void;
}) => {
  return (
    <Modal size="tiny" open={modalOpen} onClose={closeModal}>
      <Modal.Header>계좌이체 답변</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <TextArea
              fluid
              value={withdraw?.answer}
              onChange={(e) => handleWithdrawChange('answer', e.target.value)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" content="제출" onClick={submitWithdrawAnswer} />
        <Button color="black" content="닫기" onClick={closeModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default React.memo(WithdrawModal);
