export const generateId = () => {
  return Math.round(Math.random() * 12345678);
};

export const removeFromArray = (arr: any[], el: any) => {
  return arr.filter((e) => e !== el);
};

export const isNumber = (s: any) => {
  return /^\d*$/.test(s);
};
