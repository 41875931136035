import {
  DescriptionModuleFormType,
  DescriptionNewInterviewModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const validate = (
  descriptionModule: DescriptionModuleFormType[],
): { isValid: true } | { isValid: false; message: string } => {
  const target = descriptionModule.find(({ type }) => type === 'NEW-INTERVIEW');
  if (!target) return { isValid: true };

  const { interviewee } = target as DescriptionNewInterviewModuleFormType;

  for (let i = 0; i < interviewee.length; i++) {
    const o = interviewee[i];
    if (
      typeof o.name !== 'string' ||
      typeof o.image !== 'string' ||
      typeof o.content !== 'string'
    ) {
      return {
        isValid: false,
        message: '[상세모듈] NEW-INTERVIEW 모듈을 확인해주세요',
      };
    }

    if (!o.name.length) {
      return {
        isValid: false,
        message: '[상세모듈] NEW-INTERVIEW 모듈의 이름 영역을 채워주세요.',
      };
    }

    if (!o.image.length) {
      return {
        isValid: false,
        message: '[상세모듈] NEW-INTERVIEW 모듈의 이미지 영역을 채워주세요.',
      };
    }

    if (!o.content.length) {
      return {
        isValid: false,
        message: '[상세모듈] NEW-INTERVIEW 모듈의 내용 영역을 채워주세요.',
      };
    }
  }

  return { isValid: true };
};
