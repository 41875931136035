import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'price'>,
  Pick<ReqCreateOrUpdateChallenge, 'price'>
> = ({ price }) => {
  if (price !== null && typeof price !== 'number')
    throw Error("[PriceForm] this shouldn't be happened");

  return { price };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'price'>,
  Pick<ChallengeFormType, 'price'>
> = ({ price }) => ({ price });
