import Button from '@component/Button';
import React, { FC, useEffect, useRef, useState } from 'react';
import { uploadSingleFileToS3 } from '../../utils/uploadImage';
import Spinner from '../Spinner';
import * as S from './ImageInput.styles';

type Props = {
  label?: string;
  showPreview?: boolean;
  previewSize?: number;
  disabled?: boolean;
} & (
  | {
      multiSelect: true;
      uploadOnChange: true;
      value?: string[];
      onChange?: (value: string[] | undefined) => void;
    }
  | {
      multiSelect: true;
      uploadOnChange?: false;
      value?: File[];
      onChange?: (value: File[] | undefined) => void;
    }
  | {
      multiSelect?: false | undefined;
      uploadOnChange: true;
      value?: string;
      onChange?: (value: string | undefined) => void;
    }
  | {
      multiSelect?: false | undefined;
      uploadOnChange?: false;
      value?: File;
      onChange?: (value: File | undefined) => void;
    }
);

const ImageInput: FC<Props> = (props) => {
  const {
    label,
    showPreview,
    previewSize,
    multiSelect,
    uploadOnChange,
    value,
    onChange,
    disabled,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange || !e.target.files || e.target.files.length === 0) return;
    setIsLoading(true);
    const files = Array.from(e.target.files);

    try {
      if (!uploadOnChange) {
        if (multiSelect) onChange(files);
        else onChange(files[0]);
        return;
      }

      if (!multiSelect) {
        onChange(await uploadSingleFileToS3(files[0]));
        return;
      }

      onChange(await Promise.all(files.map(uploadSingleFileToS3)));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
      if (inputRef.current) inputRef.current.value = '';
    }
  };

  const handleDelete = (index?: number) => {
    if (!onChange) return;

    if (!multiSelect) {
      onChange(undefined);
      return;
    }

    if (uploadOnChange) {
      onChange(value?.filter((_, i) => i !== index));
      return;
    }

    // type error 로 나눠서 수행
    onChange(value?.filter((_, i) => i !== index));
  };

  return (
    <S.Container>
      {!!label && <S.Label>{label}</S.Label>}

      <S.InputContainer>
        {isLoading ? (
          <Spinner size={40} />
        ) : (
          <S.Input
            ref={inputRef}
            type="file"
            multiple={multiSelect}
            onChange={handleChange}
            disabled={disabled}
          />
        )}

        {!!value && showPreview && (
          <S.PreviewContainer>
            {Array.isArray(value) ? (
              value.map((v, i) => (
                <PreviewItem
                  key={i}
                  value={v}
                  size={previewSize}
                  onDelete={() => !disabled && handleDelete(i)}
                />
              ))
            ) : (
              <PreviewItem
                value={value}
                size={previewSize}
                onDelete={() => !disabled && handleDelete()}
              />
            )}
          </S.PreviewContainer>
        )}
      </S.InputContainer>
    </S.Container>
  );
};

export default ImageInput;

type PreviewItemProps = {
  value: string | File;
  size?: number;
  onDelete?: () => void;
};
const PreviewItem: FC<PreviewItemProps> = ({ value, size = 64, onDelete }) => {
  const [src, setSrc] = useState<string>();

  useEffect(() => {
    if (typeof value === 'string') {
      setSrc(value);
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) =>
      !!e.target?.result && setSrc(e.target.result.toString());
    reader.readAsDataURL(value);
  }, [value]);

  if (!src) return <></>;
  return (
    <div
      style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}
    >
      <S.PreviewImg size={size} src={src} alt="미리보기 이미지" />
      <Button text="삭제" size="small" onClick={onDelete} />
    </div>
  );
};
