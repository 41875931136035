import { Grid, Tab } from 'semantic-ui-react';
import React, { useReducer } from 'react';
import moment from 'moment';
import CollaboMenu from '../CollaboMenu';
import TableSection from './components/TableSection/TableSection';
import TemplateSection from './components/TemplateSection/TemplateSection';
import { SettlementListAction, SettlementListState } from './Settlement.types';

const Settlement = () => {
  // settlement 테이블 필터링, 검색 상태
  const [settlementTable, dispatch] = useReducer(tableReducer, {
    filter: {
      gteChallengeResultDate: moment().add(-14, 'days').format('YYYY-MM-DD'),
      ltChallengeResultDate: moment().format('YYYY-MM-DD'),
    },
    search: {},
  });

  const SETTLEMENT_TABS = [
    {
      menuItem: '확정',
      render: () => (
        <Tab.Pane attached={false}>
          <TableSection
            status="DONE"
            tableState={settlementTable}
            setTableState={dispatch}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: '대기',
      render: () => (
        <Tab.Pane attached={false}>
          <TableSection
            status="PENDING"
            tableState={settlementTable}
            setTableState={dispatch}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: '템플릿',
      render: () => (
        <Tab.Pane attached={false}>
          <TemplateSection />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div>
      <Grid columns="equal" style={{ margin: 40 }}>
        <Grid.Column width={2}>
          <CollaboMenu />
        </Grid.Column>
        <Grid.Column>
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={SETTLEMENT_TABS}
            defaultActiveIndex={SETTLEMENT_TABS.findIndex(
              (t) => t.menuItem === '대기',
            )}
          />
        </Grid.Column>
      </Grid>
    </div>
  );
};

function tableReducer(
  state: SettlementListState,
  action: SettlementListAction,
) {
  switch (action.type) {
    case 'SET_FILTER':
      return {
        ...state,
        filter: { ...state.filter, ...action.value },
      };
    case 'SET_SEARCH':
      return {
        ...state,
        search: { ...action.value },
      };

    default:
      return state;
  }
}

export default Settlement;
