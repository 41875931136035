import ReactDragList from 'react-drag-list';
import { Form, Grid } from 'semantic-ui-react';
import { generateId, isNumber } from '../../../../../utils/number';
import * as S from '../../../Style';
import RaceOptionProductsForm from './RaceOptionProductsForm';
import { uploadSingleFileToS3 } from '@utils/uploadImage';
import { cropAndUploadImage } from '@utils/image.utils';
import { Race } from '@types';

type RaceOptionProductFieldProps = {
  productPackages: Race.RaceProductPackageFormType[];
  setProductPackages: React.Dispatch<
    React.SetStateAction<Race.RaceProductPackageFormType[]>
  >;
};

const RaceOptionProductField = ({
  productPackages,
  setProductPackages,
}: RaceOptionProductFieldProps) => {
  const addOption = () => {
    const _productPackages = [...productPackages];
    _productPackages.push({
      id: generateId(),
      name: '',
      price: 3000,
      totalCount: 0,
      shippingFee: 0,
      isAddressNeeded: true,
      is참가권: false,
      originalPrice: 3000,
      thumbnailUrl: '',
      detailImageUrlList: [],
      isPromotion: false,
      products: [
        {
          options: {
            values: [],
          },
        },
      ],
      isPhoneNeeded: false,
      priority: 0,
    });
    setProductPackages(_productPackages);
  };

  const deleteOption = (idx: number) => {
    const _productPackages = [...productPackages];
    _productPackages.splice(idx, 1);
    setProductPackages(_productPackages);
  };

  const handleOptionChange = <K extends keyof Race.RaceProductPackageFormType>(
    idx: number,
    key: K,
    value: Race.RaceProductPackageFormType[K],
  ) => {
    const _productPackages = [...productPackages];
    _productPackages[idx][key] = value;
    setProductPackages(_productPackages);
  };

  const handleMultipleOptionChange = <
    K extends keyof Race.RaceProductPackageFormType,
  >(
    idx: number,
    keyValueArray: Array<[key: K, value: Race.RaceProductPackageFormType[K]]>,
  ) => {
    const _productPackages = [...productPackages];
    keyValueArray.forEach(([key, value]) => {
      _productPackages[idx][key] = value;
    });
    setProductPackages(_productPackages);
  };

  const handleShippingFeeChange = <
    K extends keyof Race.RaceProductPackageFormType,
  >(
    idx: number,
    key: K,
    value: Race.RaceProductPackageFormType[K],
  ) => {
    const _productPackages = [...productPackages];
    _productPackages[idx][key] = value;
    setProductPackages(_productPackages);
  };

  const handleChangeOptionProducts = (
    optionIdx: number,
    productGroupOptionValue: Race.ProductGroupOptionValue[],
  ) => {
    setProductPackages((options) =>
      options.map((o, i) =>
        i !== optionIdx
          ? o
          : {
              ...o,
              products: [
                {
                  ...o.products[0],
                  options: {
                    values: productGroupOptionValue,
                  },
                },
              ],
            },
      ),
    );
  };

  const handleUpdateOrder = async (e: unknown, updated: unknown[]) => {
    setProductPackages(
      updated as React.SetStateAction<Race.RaceProductPackageFormType[]>,
    );
  };

  const handle참가권 = (
    idx: number,
    nextValue: Race.RaceProductPackageFormType['is참가권'],
  ) => {
    if (nextValue) {
      handleMultipleOptionChange(idx, [
        ['is참가권', true],
        ['name', ''],
        ['price', null],
        ['originalPrice', null],
        ['totalCount', 0],
        ['thumbnailUrl', null],
        ['detailImageUrlList', null],
        ['isPromotion', false],
        ['products', []],
        ['isAddressNeeded', false],
        ['shippingFee', 0],
      ]);
    } else {
      handleMultipleOptionChange(idx, [
        ['is참가권', false],
        ['name', '기념팩 없이 참가'],
        ['price', null],
        ['originalPrice', null],
        ['totalCount', null],
        ['thumbnailUrl', null],
        ['detailImageUrlList', null],
        ['isPromotion', false],
        [
          'products',
          [
            {
              options: {
                values: [],
              },
            },
          ],
        ],
        ['isAddressNeeded', true],
        ['shippingFee', 0],
      ]);
    }
  };

  return (
    <>
      <h3>
        판매 상품
        <S.ButtonInForm content={'추가'} onClick={addOption} />
      </h3>
      <Grid style={{ fontWeight: 700 }}>
        <Grid.Row style={{ flexWrap: 'nowrap' }}>
          <Grid.Column width={1}>no</Grid.Column>
          <Grid.Column width={3}>상품명 / 썸네일</Grid.Column>
          <Grid.Column width={3}>기념팩 구성</Grid.Column>
          <Grid.Column width={3}>가격</Grid.Column>
          <Grid.Column width={2}>
            총 개수 (제한 없을시 제한 없음 체크)
          </Grid.Column>
          <Grid.Column width={2}>배송필요여부</Grid.Column>
          <Grid.Column width={1}>첫 참가자한테만 노출</Grid.Column>
          <Grid.Column width={6}>
            상품 옵션 목록 <br />
            (모든 구성품이 단일 옵션일 시 작성 x)
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ReactDragList
        rowKey="id"
        dataSource={[...productPackages]}
        handles={false}
        onUpdate={handleUpdateOrder}
        row={(_option, idx) => {
          const option = _option as Race.RaceProductPackageFormType;
          return (
            <Grid key={`${option.id}-${idx}`} style={{ flexWrap: 'nowrap' }}>
              <Grid.Column width={1}>
                {idx + 1}
                <S.ButtonInForm
                  content={'삭제'}
                  onClick={() => deleteOption(idx)}
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <div
                  style={{
                    fontWeight: 600,
                    marginBottom: 8,
                  }}
                >
                  상품명
                </div>
                <input
                  value={option.name}
                  onChange={(e) =>
                    handleOptionChange(idx, 'name', e.target.value)
                  }
                />
                <Form.Group grouped>
                  <Form.Field
                    label="참가권임"
                    control="input"
                    type="checkbox"
                    checked={option.is참가권}
                    onChange={() => handle참가권(idx, !option.is참가권)}
                  />
                </Form.Group>
                {!option.is참가권 && (
                  <>
                    <div
                      style={{
                        fontWeight: 600,
                        marginBottom: 8,
                      }}
                    >
                      기념품 썸네일 이미지
                    </div>
                    <input
                      type="file"
                      onChange={async (e) => {
                        if (!e.target.files) return;
                        const _file = e.target.files[0];
                        const _imageLink = await uploadSingleFileToS3(_file);
                        handleOptionChange(idx, 'thumbnailUrl', _imageLink);
                      }}
                    />
                    <div style={{ height: 10, width: '100%' }} />
                    {option.thumbnailUrl && (
                      <img
                        src={option.thumbnailUrl}
                        style={{ width: 100, height: 100, objectFit: 'cover' }}
                      />
                    )}
                  </>
                )}
              </Grid.Column>
              {!option.is참가권 && (
                <>
                  <Grid.Column width={3}>
                    <div
                      style={{
                        fontWeight: 600,
                        marginBottom: 8,
                      }}
                    >
                      기념품 구성 상세 이미지
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={async (e) => {
                        if (!e.target.files) return;
                        const _file = e.target.files[0];
                        const _imageLinks = await cropAndUploadImage(_file);
                        handleOptionChange(
                          idx,
                          'detailImageUrlList',
                          _imageLinks,
                        );
                      }}
                    />
                    <div
                      style={{
                        marginTop: 8,
                        fontSize: 12,
                        color: '#434343',
                      }}
                    >
                      * [기념팩 구성 보기] 버튼 클릭 시 나오는 바텀시트에 보여질
                      이미지
                    </div>
                    <div style={{ height: 10, width: '100%' }} />
                    {option.detailImageUrlList &&
                      option.detailImageUrlList.length !== 0 && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 0,
                          }}
                        >
                          {option.detailImageUrlList.map((url, idx_) => (
                            <img
                              key={`${url}-${idx_}`}
                              src={url}
                              style={{
                                width: 100,
                                objectFit: 'contain',
                              }}
                            />
                          ))}
                        </div>
                      )}
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <div
                      style={{
                        fontWeight: 600,
                        marginBottom: 8,
                      }}
                    >
                      판매가
                    </div>
                    <input
                      value={option.price}
                      onChange={(e) => {
                        if (!isNumber(e.target.value)) return;
                        handleOptionChange(
                          idx,
                          'price',
                          Number(e.target.value),
                        );
                      }}
                    />
                    <div>
                      <div
                        style={{
                          marginTop: 8,
                          fontWeight: 600,
                          marginBottom: 8,
                        }}
                      >
                        원가
                      </div>
                      <input
                        value={option.originalPrice ?? undefined}
                        onChange={(e) => {
                          if (!isNumber(e.target.value)) return;
                          handleOptionChange(
                            idx,
                            'originalPrice',
                            Number(e.target.value),
                          );
                        }}
                      />
                    </div>
                    <div>
                      = 할인률 :{' '}
                      {option.originalPrice && option.price && (
                        <span
                          style={{
                            color: '#3972FD',
                          }}
                        >
                          {Math.floor(
                            ((option.originalPrice - option.price) * 100) /
                              option.originalPrice,
                          )}{' '}
                          %
                        </span>
                      )}
                    </div>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <input
                      value={option.totalCount ?? undefined}
                      onChange={(e) => {
                        if (!isNumber(e.target.value)) return;
                        handleOptionChange(
                          idx,
                          'totalCount',
                          Number(e.target.value),
                        );
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 4,
                        marginTop: 8,
                      }}
                    >
                      <input
                        id={'unlimit_checkbox' + idx}
                        type="checkbox"
                        checked={!option.totalCount}
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleOptionChange(idx, 'totalCount', 0);
                          } else {
                            handleOptionChange(idx, 'totalCount', 1000);
                          }
                        }}
                      />
                      <label htmlFor={'unlimit_checkbox' + idx}>
                        제한 없음
                      </label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Form.Group grouped>
                      <Form.Field
                        label="배송 필요"
                        control="input"
                        type="radio"
                        checked={option.isAddressNeeded}
                        onChange={(e: { target: { value: boolean } }) =>
                          handleOptionChange(idx, 'isAddressNeeded', true)
                        }
                      />
                      <Form.Field
                        label="배송 불필요"
                        control="input"
                        type="radio"
                        checked={!option.isAddressNeeded}
                        onChange={(e: { target: { value: unknown } }) =>
                          handleOptionChange(idx, 'isAddressNeeded', false)
                        }
                      />
                      {option.isAddressNeeded && (
                        <Form.Field
                          control="input"
                          label="배송비"
                          type="text"
                          value={option.shippingFee}
                          onChange={(e: { target: { value: string } }) => {
                            if (!isNumber(e.target.value)) return;
                            handleShippingFeeChange(
                              idx,
                              'shippingFee',
                              Number(e.target.value),
                            );
                          }}
                        />
                      )}
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <Form.Group grouped>
                      <Form.Field
                        label="노출"
                        control="input"
                        type="checkbox"
                        checked={option.isPromotion}
                        onChange={() =>
                          handleOptionChange(
                            idx,
                            'isPromotion',
                            !option.isPromotion,
                          )
                        }
                      />
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <RaceOptionProductsForm
                      optionValues={option.products[0].options?.values || []}
                      handleOptionValues={(productGroupOptionValue) =>
                        handleChangeOptionProducts(idx, productGroupOptionValue)
                      }
                    />
                  </Grid.Column>
                </>
              )}
            </Grid>
          );
        }}
      />
    </>
  );
};

export default RaceOptionProductField;
