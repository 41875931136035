import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import api from '../../api';

interface Props {
  validationModalOpen: boolean;
  closeValidationModal: () => void;
  setValidated: (validated: boolean) => void;
  defaultText?: string;
  location: string;
}

const ValidationRequestModal: React.FC<Props> = ({
  validationModalOpen,
  closeValidationModal,
  setValidated,
  defaultText,
  location,
}) => {
  const user = useSelector((state: { app: Map<string, { name: string }> }) =>
    state.app.get('user'),
  );

  const [purpose, setPurpose] = useState(
    defaultText || '챌린지 어뷰징 유저 및 배송지 오류 검토',
  );

  const handleValidationInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPurpose(e.target.value);
  };

  const requestValidation = async () => {
    if (purpose === '') {
      alert('자료 이용목적을 입력해주세요.');
      return;
    }
    const url = window.location.href;
    try {
      const response = await api.post('/admin/request-validate', {
        name: user?.name ?? '',
        purpose,
        url,
        location,
      });
      if (response.data.message === 'Admin authorization required') {
        alert('관리자 권한이 필요합니다.');
        return;
      }
      const { validated } = response.data.data;
      setValidated(validated);
    } catch (error) {
      console.error('Error occurred while requesting validation:', error);
    }
  };

  useEffect(() => {
    setPurpose(defaultText || '챌린지 어뷰징 유저 및 배송지 오류 검토');
  }, [defaultText]);

  return (
    <Modal
      size="small"
      open={validationModalOpen}
      onClose={closeValidationModal}
    >
      <Modal.Header>엑셀 요청하기</Modal.Header>
      <Modal.Content>
        <p>엑셀 자료를 요청하시겠습니까?</p>
        <Form>
          <Form.Field>
            <label>이용목적</label>
            <input
              name="purpose"
              value={purpose}
              onChange={handleValidationInputChange}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          content="엑셀 요청하기"
          onClick={requestValidation}
        />
        <Button color="black" content="닫기" onClick={closeValidationModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default ValidationRequestModal;
