import CheckBox from '@component/CheckBox';
import { ChallengeCustomField } from 'src/types/customField.types';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './CancelImpossibleForm.serializer';
import {
  useCancelImpossible,
  useCancelImpossibleAction,
} from './CancelImpossibleForm.store';
import { validate } from './CancelImpossibleForm.validator';

const CancelImpossibleForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'cancelImpossible'>,
  Extract<ChallengeCustomField, { name: 'cancelImpossible' }>
> = () => {
  const { cancelImpossible } = useCancelImpossible();
  const { setCancelImpossible } = useCancelImpossibleAction();

  return (
    <FlexBox.Column gap={5}>
      <Label>챌린지 신청 후 취소 불가능</Label>
      <CheckBox
        label="취소 불가능"
        checked={!!cancelImpossible}
        onChange={setCancelImpossible}
      />
    </FlexBox.Column>
  );
};

export default CancelImpossibleForm;

CancelImpossibleForm.validate = validate;
CancelImpossibleForm.convertData2Form = convertData2Form;
CancelImpossibleForm.convertForm2Data = convertForm2Data;
