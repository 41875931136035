import React from 'react';
import { Button, Header, Table, Image } from 'semantic-ui-react';
import moment from 'moment';

const GoalInfo = ({ goal }) => {
  const list = goal.cycle.map((item, idx) => (
    <Button key={idx} style={{ marginTop: 5 }} active size="tiny">
      {item}
    </Button>
  ));
  return (
    <React.Fragment>
      <Header as="h3" textAlign="center">
        목표 정보
      </Header>
      <Table basic="very" celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>목표명</Table.Cell>
            <Table.Cell>{goal.title}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>목표 카테고리</Table.Cell>
            <Table.Cell>
              {goal.goalCategory1} | {goal.goalCategory2} |{goal.goalCategory3}{' '}
              | {goal.goalCategory4}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>주기</Table.Cell>
            <Table.Cell>{list}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>매일 / 정해진 회수 / 상시</Table.Cell>
            {goal.cycle[0] === 'CUSTOM' ? (
              <Table.Cell>상시</Table.Cell>
            ) : (
              <>
                {goal.dayOrPeriod === 'DAY' && <Table.Cell>매일</Table.Cell>}
                {goal.dayOrPeriod === 'PERIOD' && (
                  <Table.Cell>정해진 회수</Table.Cell>
                )}
              </>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell>주기 당 총수</Table.Cell>
            <Table.Cell>{goal.totalAchieveCountPerCycle}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>하루 최대 인증 가능 회수</Table.Cell>
            <Table.Cell>{goal.maxAchieveCountPerDay}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증샷간 최소 간격 (분)</Table.Cell>
            <Table.Cell>{goal.achievementIntervalMinute}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>카운트다운 적용 여부</Table.Cell>
            {goal.countDown && <Table.Cell>적용</Table.Cell>}
            {!goal.isAchievementPublic && <Table.Cell>미적용</Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>목표 기간(일)</Table.Cell>
            <Table.Cell>{goal.totalDays}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증가능 시작 시간</Table.Cell>
            <Table.Cell>
              {moment(goal.dueStartTime, 'HH:mm:ss').format('HH:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증가능 종료 시간</Table.Cell>
            <Table.Cell>
              {moment(goal.dueEndTime, 'HH:mm:ss').format('HH:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>이미지</Table.Cell>
            <Table.Cell>
              <Image.Group size="small">
                <Image src={goal.imageUrls.c} />
                <Image src={goal.imageUrls.b} />
              </Image.Group>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>목표 강도</Table.Cell>
            <Table.Cell>{goal.target}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>타임스탬프 크기</Table.Cell>
            <Table.Cell>{goal.timePrintSize}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>목표 기간 난이도 기준</Table.Cell>
            {goal.totalDaysOrder === 'ASC' && <Table.Cell>오름차순</Table.Cell>}
            {goal.totalDaysOrder === 'DESC' && (
              <Table.Cell>내림차순</Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell>목표 강도 난이도 기준</Table.Cell>
            {goal.targetOrder === 'ASC' && <Table.Cell>오름차순</Table.Cell>}
            {goal.targetOrder === 'DESC' && <Table.Cell>내림차순</Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>점수 / 달성여부</Table.Cell>
            {goal.scoreOrBinary === 'score' && <Table.Cell>점수</Table.Cell>}
            {goal.scoreOrBinary === 'binary' && (
              <Table.Cell>달성여부</Table.Cell>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell>갤러리 활용 가능 여부</Table.Cell>
            {goal.isGalleryPossible && <Table.Cell>가능</Table.Cell>}
            {!goal.isGalleryPossible && <Table.Cell>불가능</Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증샷 전체 공개 여부</Table.Cell>
            {goal.isAchievementPublic && <Table.Cell>가능</Table.Cell>}
            {!goal.isAchievementPublic && <Table.Cell>불가능</Table.Cell>}
          </Table.Row>
          <Table.Row>
            <Table.Cell>업데이트일시</Table.Cell>
            <Table.Cell>
              {moment(goal.updatedAt).format('YYYY-MM-DD / hh:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>생성일시</Table.Cell>
            <Table.Cell>
              {moment(goal.createdAt).format('YYYY-MM-DD / hh:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>삭제여부</Table.Cell>
            {goal.isDeleted && <Table.Cell>삭제</Table.Cell>}
            {!goal.isDeleted && <Table.Cell>공개</Table.Cell>}
          </Table.Row>
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default GoalInfo;
