import React from 'react';
import { Grid } from 'semantic-ui-react';
import SettingMenu from './SettingMenu';
import SmsProvider from './SmsProvider';

const SettingNew = () => {
  return (
    <Grid columns={'equal'} style={{ margin: 40 }}>
      <Grid.Column width={2}>
        <SettingMenu />
      </Grid.Column>
      <Grid.Column>
        <SmsProvider />
      </Grid.Column>
    </Grid>
  );
};

export default SettingNew;
