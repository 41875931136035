import { Button, Form, Grid, Label, Table } from 'semantic-ui-react';
import SettingMenu from '../SettingMenu';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import readXlsxFile from 'read-excel-file';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { fileUrl } from '@constant/common';
import { apis } from '@api/index';
import { InitStore } from '@store/initStore';
import { connect } from 'react-redux';
import { TemporaryCashTarget, TemporaryCashTargetUserInfo } from '@types';

const TemporaryCash = ({ user }: { user: { id: number } }) => {
  const [targets, setTargets] = useState<TemporaryCashTarget[]>([]);
  const [users, setUsers] = useState<TemporaryCashTargetUserInfo[]>([]);

  useEffect(() => {
    _getTemporaryCashTarget();
  }, []);

  const _getTemporaryCashTarget = async () => {
    const res = await apis.common.getTemporaryCashTarget();
    const { users: _users } = res;
    setUsers(_users);
  };

  const readExcelFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    LoadingIndicator.show();

    const data = await readXlsxFile(e.target.files[0]);
    const userIds: number[] = [];
    const dupUserIds: number[] = [];
    const _targets: TemporaryCashTarget[] = data
      .slice(1)
      .filter((row: any) => !!row[0])
      .map((row: any) => {
        const userId = Number(row[0]);
        if (userIds.includes(userId)) {
          dupUserIds.push(userId);
        } else {
          userIds.push(userId);
        }

        return {
          userId,
          amount: row[1],
          dueDate: `${moment(row[2]).format('YYYY-MM-DD')} 14:59:59`, // UTC기준 23시59분59초 처리
        };
      });

    if (dupUserIds.length > 0) {
      alert(
        `중복된 유저 아이디가 있습니다. 확인 후 재 업로드 부탁드립니다.\n${dupUserIds.join(
          ', ',
        )}`,
      );
    } else {
      setTargets(_targets);
    }

    LoadingIndicator.hide();
  };

  const _giveTemporaryCash = async () => {
    LoadingIndicator.show();

    const res = await apis.common.giveTemporaryCash({ targetUsers: targets });
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    alert(`총 ${targets.length}명에게 포인트 지급이 완료되었어요.`);

    setTargets([]);

    LoadingIndicator.hide();

    _getTemporaryCashTarget();
  };

  const disabled = ![1, 2, 11].includes(user.id);

  return (
    <Grid columns={'equal'} style={{ margin: 40 }}>
      <Grid.Column width={2}>
        <SettingMenu />
      </Grid.Column>
      <Grid.Column>
        <h3>
          기간한정 캐시 지급
          <a
            href={fileUrl.temporaryCashFormat}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button basic color="green" size="mini">
              양식 다운로드
            </Button>
          </a>
        </h3>
        <Form>
          <Form.Field>
            <label>엑셀 파일을 업로드하세요.</label>
            <input type="file" disabled={disabled} onChange={readExcelFile} />
            {disabled && (
              <Label basic color="red" pointing>
                해당 기능은 젬마만 이용 가능 🤭
              </Label>
            )}
          </Form.Field>
        </Form>

        {targets.length === 0 && users.length > 0 && (
          <>
            <h3>현재 지급된 기간한정 포인트 목록</h3>
            <Table basic="very" size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>No</Table.HeaderCell>
                  <Table.HeaderCell>유저id</Table.HeaderCell>
                  <Table.HeaderCell>지급액</Table.HeaderCell>
                  <Table.HeaderCell>지급시점</Table.HeaderCell>
                  <Table.HeaderCell>유효기간</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {users.map((o, idx) => (
                  <Table.Row key={idx}>
                    <Table.Cell>{idx + 1}</Table.Cell>
                    <Table.Cell>{o.userId}</Table.Cell>
                    <Table.Cell>{o.amount.toLocaleString()}</Table.Cell>
                    <Table.Cell>
                      {moment(o.receivedAt).format('YYYY-MM-DD HH:mm:ss')}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(o.dueDate).format('YYYY-MM-DD HH:mm:ss')}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
        )}

        {targets.length > 0 && (
          <div style={{ marginTop: 12 }}>
            <h3>지급 대상 총 {targets.length}명</h3>
            <Button onClick={_giveTemporaryCash} content={'지급하기'} />

            <Table basic="very" size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>No</Table.HeaderCell>
                  <Table.HeaderCell>유저id</Table.HeaderCell>
                  <Table.HeaderCell>지급액</Table.HeaderCell>
                  <Table.HeaderCell>유효기간</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {targets.map((target, idx) => (
                  <Table.Row key={target.userId}>
                    <Table.Cell>{idx + 1}</Table.Cell>
                    <Table.Cell>{target.userId}</Table.Cell>
                    <Table.Cell>{target.amount.toLocaleString()}</Table.Cell>
                    <Table.Cell>
                      {moment(target.dueDate).format('YYYY-MM-DD')}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        )}
      </Grid.Column>
    </Grid>
  );
};

const mapStateToProps = (state: InitStore) => {
  const user = state.app.get('user');
  return {
    user,
  };
};

export default connect(mapStateToProps, null)(TemporaryCash);
