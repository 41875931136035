import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useCompanyId = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      thirdTermUrl: s.challenge.thirdTermUrl,
      companyId: s.challenge.companyId,
    })),
  );
};

export const useCompanyIdActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setCompanyId = (companyId: number | null) =>
    updateChallengeForm({ companyId });

  return { setCompanyId };
};
