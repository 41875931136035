import {
  DescriptionCollaboSpecialPrizeModuleFormType,
  DescriptionModuleFormType,
  SpecialPrizeTitle,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const validate = (
  descriptionModule: DescriptionModuleFormType[],
): { isValid: true } | { isValid: false; message: string } => {
  const target = descriptionModule.find(
    ({ type }) => type === 'COLLABO-SPECIAL-PRIZE',
  );
  if (!target) return { isValid: true };

  const {
    data: { type, title, noticeList },
  } = target as DescriptionCollaboSpecialPrizeModuleFormType;

  if (type === 'total' && title !== SpecialPrizeTitle.TOTAL)
    return {
      isValid: false,
      message:
        "[상세모듈] 특별상금 지급 방식이 총상금일 경우 특별상금 텍스트는 '특별상금' 이어야 합니다",
    };
  if (type !== 'total' && title === SpecialPrizeTitle.TOTAL)
    return {
      isValid: false,
      message:
        "[상세모듈] 특별상금 지급 방식이 총상금이 아닐경우 특별상금 텍스트는 '인당상금' 또는 '회차별 인당상금' 이어야 합니다",
    };

  if (!noticeList.length)
    return {
      isValid: false,
      message: '[상세모듈] 특별상금 알림리스트는 최소 1개 이상이어야 합니다',
    };

  return { isValid: true };
};
