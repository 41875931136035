import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useHideTimestamp = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      hideTimestamp: s.challenge.timestamp.hideTimeStamp,
    })),
  );

export const useHideTimestampAction = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setHideTimestamp = (timestamp: boolean | null) =>
    updateChallengeForm((prev) => ({
      ...prev,
      timestamp: { hideTimeStamp: timestamp },
    }));
  return { setHideTimestamp };
};
