import React, { FC, useCallback } from 'react';
import TextInput from '@component/TextInput';
import Label from '@component/Label';
import { ModuleContainer } from '../../../../DescriptionModuleForm.style';

type Props = {
  to: string;
  onChange: (to: string) => void;
};
const ReceiverForm: FC<Props> = (props) => {
  const { to, onChange } = props;

  const handleText = useCallback((text: string) => onChange(text), [onChange]);

  return (
    <ModuleContainer>
      <Label style={{ marginTop: 20 }}>특별리워드 받는 대상</Label>
      <TextInput value={to} onChange={handleText} />
    </ModuleContainer>
  );
};

export default ReceiverForm;
