import {
  DescriptionModuleFormType,
  DescriptionVideoModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const validate = (
  descriptionModule: DescriptionModuleFormType[],
): { isValid: true } | { isValid: false; message: string } => {
  const target = descriptionModule.find(({ type }) => type === 'VIDEO');
  if (!target) return { isValid: true };

  const { youtubeId } = target as DescriptionVideoModuleFormType;

  if (typeof youtubeId !== 'string') {
    return {
      isValid: false,
      message: '[상세모듈] VIDEO 모듈을 확인해주세요',
    };
  }

  if (!youtubeId.length)
    return {
      isValid: false,
      message: '[상세모듈] VIDEO youtubeId 값을 채워주세요',
    };
  return { isValid: true };
};
