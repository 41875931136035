import Dropdown from '@component/Dropdown';
import { useGetAllCompanies } from '@queries/Commerce/useGetCompanies';
import { FC } from 'react';

type Props = {
  companyId?: number;
  onChange: (companyId: number | null) => void;
};
const CompanySelector: FC<Props> = ({ companyId, onChange }) => {
  const { data: companies } = useGetAllCompanies();

  if (!companies) return <></>;
  return (
    <Dropdown
      label="회사"
      options={companies.map((c) => ({ text: c.name, value: c.id }))}
      value={companyId}
      search
      placeholder="회사를 선택해주세요."
      onChange={onChange}
      keyExtractor={(item) => item}
    />
  );
};

export default CompanySelector;
