import ChipList from '@component/ChipList';
import ErrorMsg from '@component/ErrorMsg';
import TextInput from '@component/TextInput';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import * as S from './ChipInput.style';

type SearchableProps = {
  searchable: true;
  searchDataList: string[];
  searchDataAddable: boolean;
  onSearchChange?: (text: string) => void;
  onReachEnd?: () => void;
  isLoading?: boolean;
};

type UnsearchableProps = {
  searchable?: false;
  searchDataList?: undefined;
  searchDataAddable?: undefined;
  onSearchChange?: undefined;
  onReachEnd?: undefined;
  isLoading?: undefined;
};

type ChipInputBaseProps = {
  chipList: string[];
  onChange?: (changedData: string[]) => void;
  label?: string;
  disabled?: boolean;
  errorMsg?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
};

type ChipInputProps =
  | (ChipInputBaseProps & UnsearchableProps)
  | (ChipInputBaseProps & SearchableProps);

const ChipInput = (props: ChipInputProps) => {
  const {
    label,
    chipList,
    onChange,
    style,
    contentStyle,
    disabled = false,
    errorMsg,
    placeholder,
    searchable,
    searchDataList: searchDataListProp = [],
    searchDataAddable = false,
    isLoading = false,
    onSearchChange,
    onReachEnd,
  } = props;
  const [newText, setNewText] = useState<string>('');
  const searchDataList = useMemo(() => {
    return searchDataListProp.filter((data) => !chipList.includes(data));
  }, [chipList, searchDataListProp]);

  const checkAddable = (item: unknown): item is string => {
    if (typeof item !== 'string') return false;
    // check nextText is already exists
    if (chipList.includes(item)) return false;
    return true;
  };

  const add = (addedItem: string) => {
    const updatedChipList = [...chipList, addedItem];
    onChange?.(updatedChipList);
  };

  const remove = (index: number) => {
    const updatedData = [...chipList];
    updatedData.splice(index, 1);
    onChange?.(updatedData);
  };

  const handleEnterPress: React.KeyboardEventHandler<HTMLInputElement> = (
    e,
  ) => {
    if (e.key !== 'Enter') return;
    // XXX: 한글의 경우 isComposing 상태일 경우 한번 더 onKeyDown이 호출된다(글자 완성 도중 enter press)
    // 해당 이벤트 호출에 대한 처리
    if (e.nativeEvent.isComposing) return;

    setNewText('');
    if (!checkAddable(newText)) return;
    add(newText);
  };

  const handleDropdownChange: (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) => void = (_, { value }) => {
    if (!checkAddable(value)) return;
    add(value);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll: EventListenerOrEventListenerObject = () => {
      if (dropdownRef.current) {
        const { scrollTop, clientHeight, scrollHeight } =
          dropdownRef.current.querySelector('.menu') as HTMLDivElement;

        if (scrollHeight - scrollTop <= clientHeight + 1) {
          if (onReachEnd) onReachEnd();
        }
      }
    };

    const dropdownMenu = dropdownRef.current?.querySelector('.menu');

    if (dropdownMenu) {
      dropdownMenu.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (dropdownMenu) {
        dropdownMenu.removeEventListener('scroll', handleScroll);
      }
    };
  }, [onReachEnd]);

  return (
    <S.Container style={style}>
      {label ? <S.Label>{label}</S.Label> : <></>}
      <ChipList
        chipList={chipList}
        removable={!disabled}
        onRemovePress={remove}
      />
      {searchable ? (
        <div ref={dropdownRef}>
          <Dropdown
            disabled={disabled}
            search={searchable}
            selection
            options={searchDataList.map((data, index) => ({
              key: `${data}-${index}`,
              value: data,
              text: data,
            }))}
            value=""
            allowAdditions={searchDataAddable}
            onChange={handleDropdownChange}
            onSearchChange={(_, { searchQuery }) =>
              onSearchChange && onSearchChange(searchQuery)
            }
            placeholder={placeholder}
            scrolling
            loading={isLoading}
            style={contentStyle}
          />

          <ErrorMsg text={errorMsg} />
        </div>
      ) : (
        <TextInput
          value={newText}
          disabled={disabled}
          onChange={setNewText}
          onKeyDown={handleEnterPress}
          errorMsg={errorMsg}
          placeholder={placeholder}
        />
      )}
    </S.Container>
  );
};

export { ChipInput };
