import React, { useEffect, useState } from 'react';
import { apiGo, apis } from '../../../../api';
import { Table } from 'semantic-ui-react';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';
import moment from 'moment';

const ActivatedRupInfo = ({ userId }) => {
  const [rups, setRups] = useState([]);

  useEffect(() => {
    getUserActivatedRups();
  }, []);

  const getUserActivatedRups = async () => {
    const res = await apis.user.getActivatedRups(userId);
    const { rups } = res.data.data;

    setRups(rups);
  };

  return (
    <div style={{ marginTop: 100 }}>
      <h5>내 약통 목록</h5>
      <Table basic="very" size="small" collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>id</Table.HeaderCell>
            <Table.HeaderCell>약 이름</Table.HeaderCell>
            <Table.HeaderCell>구매여부</Table.HeaderCell>
            <Table.HeaderCell>약 구매시점</Table.HeaderCell>
            <Table.HeaderCell>첫 인증시점</Table.HeaderCell>
            <Table.HeaderCell>마지막 인증시점</Table.HeaderCell>
            <Table.HeaderCell>인증시 환급 받는 포인트</Table.HeaderCell>
            <Table.HeaderCell>총 횟수</Table.HeaderCell>
            <Table.HeaderCell>성공 횟수</Table.HeaderCell>
            <Table.HeaderCell>실패 횟수</Table.HeaderCell>
            <Table.HeaderCell>남은 횟수</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rups.map((rup) => (
            <Table.Row key={rup.myPill.id}>
              <Table.Cell>{rup.myPill.id}</Table.Cell>
              <Table.Cell>{rup.myPill.pill.name}</Table.Cell>
              <Table.Cell>
                {rup.myPill.isPurchasedInStore ? '구매' : '자체등록'}
              </Table.Cell>
              <Table.Cell>
                {moment(rup.myPill.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </Table.Cell>
              <Table.Cell>
                {rup.myPill.achievementStatus
                  ? moment(
                      rup.myPill.achievementStatus.firstAchievementDate,
                    ).format('YYYY-MM-DD HH:mm:ss')
                  : '-'}
              </Table.Cell>
              <Table.Cell>
                {rup.myPill.achievementStatus
                  ? moment(
                      rup.myPill.achievementStatus?.lastAchievementDate,
                    ).format('YYYY-MM-DD HH:mm:ss')
                  : '-'}
              </Table.Cell>
              <Table.Cell>
                {setCurrencyFormat(rup.myPill.rewardAmountPerConfirm)}
              </Table.Cell>
              <Table.Cell>
                {setCurrencyFormat(rup.myPill.totalCount)}
              </Table.Cell>
              <Table.Cell>
                {setCurrencyFormat(rup.myPill.achievementStatus?.success)}
              </Table.Cell>
              <Table.Cell>
                {setCurrencyFormat(rup.myPill.achievementStatus?.fail)}
              </Table.Cell>
              <Table.Cell>
                {setCurrencyFormat(rup.myPill.achievementStatus?.remain)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default ActivatedRupInfo;
