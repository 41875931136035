import { useInfiniteQuery } from '@tanstack/react-query';
import {
  getNextPageParam,
  withTypedInfiniteLoad,
} from '../../utils/query.utils';
import { apis } from '../../api';
import { SettlementCollaboProduct } from '../../container/Collabo/Settlement/Settlement.types';
import { PAGE_LIMIT } from '../../container/Collabo/Settlement/Settlement.constants';

type Response = {
  isEnd: boolean;
  collaboProducts: SettlementCollaboProduct[];
};

export const useCollaboSettlementProductList = () => {
  return useInfiniteQuery({
    queryKey: ['COLLABO-SETTLEMENT-PRODUCT-LIST'],
    queryFn: withTypedInfiniteLoad<() => Promise<Response>, object>(
      apis.getCollaboProductList as () => Promise<Response>,
      {},
      PAGE_LIMIT,
    ),
    initialPageParam: 0,
    getNextPageParam,
  });
};
