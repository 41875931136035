import setCurrencyFormat from '../../../global/setCurrencyFormat';
import { UserWithdrawInfo } from '@types';
import moment from 'moment';
import React from 'react';
import { Button, Checkbox, Table } from 'semantic-ui-react';

const WithdrawTableRow = ({
  wd,
  handleChangeCheckbox,
  showModal,
  handleChangeWithdrawIsCompleted,
}: {
  wd: UserWithdrawInfo;
  handleChangeCheckbox: (id: number) => void;
  showModal: (wd: UserWithdrawInfo) => void;
  handleChangeWithdrawIsCompleted: (transactionTransferId: number) => void;
}) => {
  return (
    <Table.Row key={wd.id}>
      <Table.Cell collapsing>
        <Checkbox
          checked={wd.isChecked}
          value={wd.id}
          onChange={() => handleChangeCheckbox(wd.id)}
        />
      </Table.Cell>
      <Table.Cell>{wd.id}</Table.Cell>
      <Table.Cell>{wd.user.id}</Table.Cell>
      <Table.Cell>{wd.user.name}</Table.Cell>
      <Table.Cell>{wd.user.nickname}</Table.Cell>
      <Table.Cell>
        {moment.utc(wd.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}
      </Table.Cell>
      <Table.Cell>{wd.pointType}</Table.Cell>
      <Table.Cell>{wd.bankName}</Table.Cell>
      <Table.Cell>{wd.accountNo}</Table.Cell>
      <Table.Cell>{setCurrencyFormat(-wd.amount)}</Table.Cell>
      <Table.Cell>{wd.accountName}</Table.Cell>
      <Table.Cell>
        {wd.answer}
        <br />
        <Button size="mini" onClick={() => showModal(wd)} value={wd}>
          답변하기
        </Button>
      </Table.Cell>
      <Table.Cell>
        <Checkbox
          checked={wd.isCompleted}
          onChange={() =>
            handleChangeWithdrawIsCompleted(wd.transactionTransferId)
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(WithdrawTableRow);
