import AchievementMenu from '@container/Achievement/AchievementMenu';
import Search from './Search/Search';
import AchievementTable from './AchievementTable/AchievementTable';
import FlexBox from '@component/FlexBox/FlexBox';

const CollaboAchievement = () => {
  return (
    <FlexBox.Row gap={30} mt={0} mb={40} ml={40} mr={40}>
      <div
        style={{
          minWidth: '150px',
        }}
      >
        <AchievementMenu />
      </div>
      <FlexBox.Column
        gap={40}
        style={{
          width: '100%',
        }}
      >
        <Search />
        <AchievementTable />
      </FlexBox.Column>
    </FlexBox.Row>
  );
};

export default CollaboAchievement;
