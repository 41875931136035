import ErrorMsg from '@component/ErrorMsg';
import FlexBox from '@component/FlexBox/FlexBox';
import ImageInput from '@component/ImageInput';
import { ChallengeCustomField } from '@types';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './AchievementMissionForm.serializer';
import {
  useAchievementMission,
  useAchievementMissionActions,
} from './AchievementMissionForm.store';
import { validate } from './AchievementMissionForm.validator';
import AchievementMissionListItem from './components/AchievementMissionListItem/AchievementMissionListItem';

export const AchievementMissionForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'achievementMission'>,
  Extract<ChallengeCustomField, { name: 'achievementMission' }>
> = () => {
  const achievementMission = useAchievementMission();
  const { add } = useAchievementMissionActions();

  const validated = validate({ achievementMission });

  return (
    <FlexBox.Column gap={20}>
      <ErrorMsg text={validated.isValid ? '' : validated.message} />

      <ImageInput
        uploadOnChange
        multiSelect
        label="인증샷 예시(최대 3:4비율)"
        onChange={(images) => images && add(images)}
      />

      <FlexBox.Column gap={12}>
        {achievementMission.map((item, index) => (
          <AchievementMissionListItem
            key={item.image}
            item={item}
            index={index}
          />
        ))}
      </FlexBox.Column>
    </FlexBox.Column>
  );
};

AchievementMissionForm.validate = validate;
AchievementMissionForm.convertData2Form = convertData2Form;
AchievementMissionForm.convertForm2Data = convertForm2Data;
