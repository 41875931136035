import { DistanceForm } from './components/DistanceForm/DistanceForm';
import { ChallengeInfoForAdmin } from '@types';
import { useEffect, useState } from 'react';
import { useChallengeFormStore } from './NewChallengeForm.store';
import AuthenticationCheckListForm from './components/AuthenticationCheckListForm/AuthenticationCheckListForm';
import { CommerceForm } from './components/CommerceForm';
import { EndDateForm } from './components/EndDateForm';
import ExpForm from './components/ExpForm/ExpForm';
import { FaqForm } from './components/FaqForm';
import { GoalIdForm } from './components/GoalIdForm';
import IsPublicForm from './components/IsPublicForm/IsPublicForm';
import { IsRegisterDepositFixedForm } from './components/IsRegisterDepositFixedForm';
import { NaverBlogInfoListForm } from './components/NaverBlogInfoListForm';
import PasswordForm from './components/PasswordForm/PasswordForm';
import RegisterEndDateForm from './components/RegisterEndDateForm/RegisterEndDateForm';
import RegisterStartDateForm from './components/RegisterStartDateForm/RegisterStartDateForm';
import { ResultDateForm } from './components/ResultDateForm';
import { SpecialPrizeForm } from './components/SpecialPrizeForm';
import { StartDateForm } from './components/StartDateForm';
import SupportersForm from './components/SupportersForm/SupportersForm';
import TitleForm from './components/TitleForm/TitleForm';
import { ThumbnailImageUrlForm } from './components/ThumbnailImageUrlForm';
import InstagramInfoListForm from './components/InstagramInfoListForm/InstagramInfoListForm';
import { UseItemImpossibleForm } from './components/UseItemImpossibleForm';
import TimestampForm from './components/TimestampForm/TimestampForm';
import PurchaseInfoForm from './components/PurchaseInfoForm/PurchaseInfoForm';
import { PostConnectPrizeForm } from './components/PostConnectPrizeForm/PostConnectPrizeForm';
import NaverIdRequiredForm from './components/NaverIdRequiredForm/NaverIdRequiredForm';
import CollaborationForm from './components/CollaborationForm/CollaborationForm';
import ThirdTermUrlForm from './components/ThirdTermUrlForm/ThirdTermUrlForm';
import CompanyIdForm from './components/CompanyIdForm/CompanyIdForm';
import CreatorForm from './components/CreatorForm/CreatorForm';
import TagIdsForm from './components/TagIdsForm/TagIdsForm';
import PublicTypeForm from './components/PublicTypeForm/PublicTypeForm';
import QnaForm from './components/QnaForm/QnaForm';
import { UserFilterForm } from './components/UserFilterForm';
import AdminUserIdsForm from './components/AdminUserIdsForm/AdminUserIdsForm';
import CollaboAdminUserIdsForm from './components/CollaboAdminUserIdsForm/CollaboAdminUserIdsForm';
import AuthenticationMethodForm from './components/AuthenticationMethodForm/AuthenticationMethodForm';
import { AchievementMissionForm } from './components/AchievementMissionForm';
import StickerForm from './components/StickerForm/StickerForm';
import DescriptionModuleForm from './components/DescriptionModuleForm';
import CancelImpossibleForm from './components/CancelImpossibleForm/CancelImpossibleForm';
import AuthenticationGoodExamplesForm from './components/AuthenticationGoodExamplesForm/AuthenticationGoodExamplesForm';
import AuthenticationBadExamplesForm from './components/AuthenticationBadExamplesForm/AuthenticationBadExamplesForm';
import DescriptionExtraForm from './components/DescriptionExtraForm';
import IsDeletedForm from './components/IsDeletedForm/IsDeletedForm';
import UserIdForm from './components/UserIdForm/UserIdForm';
import MinRegisterDepositForm from './components/MinRegisterDepositForm/MinRegisterDepositForm';
import MaxRegisterCountForm from './components/MaxRegisterCountForm/MaxRegisterCountForm';
import MaxRegisterDepositForm from './components/MaxRegisterDepositForm/MaxRegisterDepositForm';
import { AchievementInfoListForm } from './components/AchievementInfoListForm';
import { ImageFilterForm } from './components/ImageFilterForm';
import { ViralLoopInviteeRewardForm } from './components/ViralLoopInviteeRewardForm';
import { ViralLoopAuthenticationMethodForm } from './components/ViralLoopAuthenticationMethodForm';
import LabelIdsForm from './components/LabelIdsForm/LabelIdsForm';
import CategoryForm from './components/CategoryForm/CategoryForm';
import PurchaseChannelForm from './components/PurchaseChannelForm/PurchaseChannelForm';
import PurchaseStartDateForm from './components/PurchaseStartDateForm/PurchaseStartDateForm';
import PurchaseEndDateForm from './components/PurchaseEndDateForm/PurchaseEndDateForm';
import PriceForm from './components/PriceForm/PriceForm';
import OriginalPriceForm from './components/OriginalPriceForm/OriginalPriceForm';

/**
 * converter를 이용한 form data initialize
 */
export const useFormInitialize = (challenge?: ChallengeInfoForAdmin) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );
  const reset = useChallengeFormStore((s) => s.reset);

  useEffect(() => {
    if (isInitialized) return;
    reset();
    setIsInitialized(true);
  }, [isInitialized, reset]);

  useEffect(() => {
    if (!isInitialized || !challenge) return;
    const { customFields = [] } = challenge;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore ts(2590)
    updateChallengeForm({
      ...GoalIdForm.convertData2Form(challenge),
      ...TitleForm.convertData2Form(challenge),
      ...UserIdForm.convertData2Form(challenge),
      ...PasswordForm.convertData2Form(challenge),
      ...AdminUserIdsForm.convertData2Form(challenge),
      ...CollaboAdminUserIdsForm.convertData2Form(challenge),
      ...TagIdsForm.convertData2Form(challenge),
      ...LabelIdsForm.convertData2Form(challenge),
      ...IsRegisterDepositFixedForm.convertData2Form(challenge),
      ...MinRegisterDepositForm.convertData2Form(challenge),
      ...MaxRegisterDepositForm.convertData2Form(challenge),
      ...MaxRegisterCountForm.convertData2Form(challenge),
      ...RegisterStartDateForm.convertData2Form(challenge),
      ...RegisterEndDateForm.convertData2Form(challenge),
      ...StartDateForm.convertData2Form(challenge),
      ...EndDateForm.convertData2Form(challenge),
      ...ResultDateForm.convertData2Form(challenge),
      ...ExpForm.convertData2Form(challenge),
      ...IsPublicForm.convertData2Form(challenge),
      ...IsDeletedForm.convertData2Form(challenge),
      ...ThumbnailImageUrlForm.convertData2Form(challenge),
      ...AuthenticationMethodForm.convertData2Form(challenge),
      ...AuthenticationGoodExamplesForm.convertData2Form(challenge),
      ...AuthenticationBadExamplesForm.convertData2Form(challenge),
      ...AuthenticationCheckListForm.convertData2Form(challenge),
      ...ThirdTermUrlForm.convertData2Form(challenge),
      ...CompanyIdForm.convertData2Form(challenge),
      ...CommerceForm.convertData2Form(challenge),
      ...UserFilterForm.convertData2Form(challenge),
      ...DescriptionModuleForm.convertData2Form(challenge),
      ...DescriptionExtraForm.convertData2Form(challenge),
      ...IsDeletedForm.convertData2Form(challenge),
      ...StickerForm.convertData2Form(challenge),
      ...CategoryForm.convertData2Form(challenge),
      ...PurchaseChannelForm.convertData2Form(challenge),
      ...PurchaseStartDateForm.convertData2Form(challenge),
      ...PurchaseEndDateForm.convertData2Form(challenge),
      ...PriceForm.convertData2Form(challenge),
      ...OriginalPriceForm.convertData2Form(challenge),

      // XXX: customField의 경우 param으로 customFields

      ...SpecialPrizeForm.convertData2Form(customFields),
      ...UseItemImpossibleForm.convertData2Form(customFields),
      ...CancelImpossibleForm.convertData2Form(customFields),
      ...PublicTypeForm.convertData2Form(customFields),
      ...AchievementInfoListForm.convertData2Form(customFields),
      ...FaqForm.convertData2Form(customFields),
      ...InstagramInfoListForm.convertData2Form(customFields),
      ...NaverBlogInfoListForm.convertData2Form(customFields),
      ...PurchaseInfoForm.convertData2Form(customFields),
      ...PostConnectPrizeForm.convertData2Form(customFields),
      ...NaverIdRequiredForm.convertData2Form(customFields),
      ...CollaborationForm.convertData2Form(customFields),
      ...SupportersForm.convertData2Form(customFields),
      ...QnaForm.convertData2Form(customFields),
      ...CreatorForm.convertData2Form(customFields),
      ...TimestampForm.convertData2Form(customFields),
      ...AchievementMissionForm.convertData2Form(customFields),
      ...ImageFilterForm.convertData2Form(customFields),
      ...DistanceForm.convertData2Form(customFields),
      ...ViralLoopInviteeRewardForm.convertData2Form(customFields),
      ...ViralLoopAuthenticationMethodForm.convertData2Form(customFields),
    });
  }, [challenge, updateChallengeForm, isInitialized]);

  return { isInitialized };
};
