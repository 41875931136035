import {
  ChallengeInfoForAdmin,
  ChallengeSticker,
  ReqCreateOrUpdateChallenge,
  ServiceCategory,
  PurchaseChannel,
} from '@types';
import { FC } from 'react';
import { ChoiceAnswerType, QuestionType } from 'src/types/achievement.types';
import {
  ChallengeCustomField,
  DistanceCustomFieldValue,
  ImageFilterCustomFieldValue,
} from 'src/types/customField.types';

/**
 *
 *
 * form 컴포넌트 구현 강제 타입
 *
 *
 */
export type ChallengeFormComponent<
  CA extends Partial<ChallengeInfoForAdmin>,
  F extends Partial<ChallengeFormType>,
  CD extends Partial<ReqCreateOrUpdateChallenge>,
  T = object,
> = FC<T> & {
  validate: FormValidator;
  convertForm2Data: ConverterForm2Data<F, CD>;
  convertData2Form: ConverterData2Form<CA, F>;
};

export type FormValidator<P extends Partial<ChallengeFormType> = any> = (
  params: P,
) => { isValid: true } | { isValid: false; message: string };

export type ConverterForm2Data<
  F extends Partial<ChallengeFormType>,
  D extends Partial<ReqCreateOrUpdateChallenge>,
> = (form: F) => D;

export type ConverterData2Form<
  D extends Partial<ChallengeInfoForAdmin>,
  F extends Partial<ChallengeFormType>,
> = (data: D) => F;

/**
 * Custom Field Form 컴포넌트 Type
 */
export type ChallengeCustomFieldFormComponent<
  F extends Partial<ChallengeFormType>,
  CFD extends Partial<ChallengeCustomField>,
  T = object,
> = FC<T> & {
  validate: FormValidator;
  convertForm2Data: ConverterForm2CustomFieldData<F, CFD>;
  convertData2Form: ConverterCustomFieldData2Form<F>;
};

export type ConverterForm2CustomFieldData<
  F extends Partial<ChallengeFormType>,
  CFD extends Partial<ChallengeCustomField>,
> = (form: F) => CFD[] | [];

export type ConverterCustomFieldData2Form<
  F extends Partial<ChallengeFormType>,
> = (data: ChallengeInfoForAdmin['customFields']) => F | EmptyObj;

/**
 *
 *
 *  아래는 Form Type
 *
 *
 * */
export type ChallengeFormType = {
  title: string;
  userId: number;
  password: string;
  adminUserIds: number[];
  collaboAdminUserIds: number[];
  descriptionModule: DescriptionModuleFormType[];
  descriptionExtra: DescriptionModuleFormType[];
  goalId: number | null;
  thumbnailImageUrl: string | null; // thumbnailUrls.f/m 에 각각 넣어주자
  authenticationMethod: string;
  authenticationGoodExamples: AuthenticationExample[];
  authenticationBadExamples: AuthenticationExample[];
  authenticationChecklist: string[];
  floatingChecklist: string;
  minRegisterDeposit: number;
  maxRegisterDeposit: number;
  isRegisterDepositFixed: boolean;
  minCollectDeposit: number;
  maxRegisterCount: number;

  // persist를 사용하기 위해 string으로 관리함
  registerStartDate: string;
  registerEndDate: string;
  startDate: string;
  endDate: string;
  resultDate: string | null;

  tagIds: number[];
  labelIds: number[];

  isPublic: boolean;
  isDeleted: boolean;
  isMandatory: boolean;
  selectType: 'RADIO' | 'CHECKBOX';
  adType: 'SMALL_BANNER'; // 없어도 되는듯..?
  adImage: File | null;
  thirdTermUrl: string;
  isUsualFrequencyDisplayed: boolean;
  exp: number;
  companyId: number | null;
  challengeStickers: ChallengeSticker[];

  // NOTE: 뷰티 득템 전환
  category: ServiceCategory;
  purchaseChannel: PurchaseChannel;
  purchaseStartDate: string;
  purchaseEndDate: string;
  price: number | null;
  originalPrice: number | null;
  // ----------------------

  // challenge정보와 관련 없이 validation을 위한 값들은 아래에 추가
  achievementCount: number;
} & CustomFieldFormType;

export type AuthenticationExample = {
  image: string;
  text: string;
};

export type CollaboDescriptionModuleFormType =
  | DescriptionCollaboOliveYoungReviewModuleFormType
  | DescriptionCollaboOliveYoungOnlyBuyModuleFormType
  | DescriptionCollaboSupportersInstagramModuleFormType
  // | DescriptionCollaboKakaoGiftModuleFormType
  | DescriptionCollaboNaverSmartStoreModuleFormType;

export type CommonDescriptionModuleFormType =
  | DescriptionNewInterviewModuleFormType
  | DescriptionTextOnlyModuleFormType
  | DescriptionVideoModuleFormType
  | DescriptionHyperlinkModuleFormType
  | DescriptionChallengeModuleFormType
  | DescriptionGoalModuleFormType
  | DescriptionFullImageModuleFormType
  | DescriptionCollaboSpecialPrizeModuleFormType
  | DescriptionCollaboSpecialRewardModuleFormType
  | DescriptionCollaboAdditionalRewardModuleFormType
  | DescriptionCollaboTopSummaryModuleFormType;

export type SubModuleFormType =
  | DescriptionVideoModuleFormType
  | DescriptionHyperlinkModuleFormType
  | DescriptionFullImageModuleFormType
  | DescriptionCollaboSpecialPrizeModuleFormType
  | DescriptionCollaboSpecialRewardModuleFormType
  | DescriptionCollaboAdditionalRewardModuleFormType
  | DescriptionCollaboTopSummaryModuleFormType;

export type DescriptionModuleFormType =
  | CollaboDescriptionModuleFormType
  | CommonDescriptionModuleFormType;

export type DescriptionNewInterviewModuleFormType = {
  type: 'NEW-INTERVIEW';
  interviewee: NewInterviewInterviewee[];
};

export type NewInterviewInterviewee = {
  id?: string;
  image: string;
  name: string;
  content: string;
};

export type DescriptionTextOnlyModuleFormType = {
  type: 'TEXT-ONLY';
  mainHeader: string;
  subHeader: string;
  body: string;
};

export type DescriptionVideoModuleFormType = {
  type: 'VIDEO';
  youtubeId: string;
};

export type DescriptionHyperlinkModuleFormType = {
  type: 'HYPERLINK';
  backgroundColor: string;
  buttonColor: string;
  textColor: string;
  url: string;
  urlText: string;
};

export type DescriptionChallengeModuleFormType = {
  type: 'CHALLENGE';
  mainHeader: string;
  subHeader: string;
  ids: number[];
};

export type DescriptionGoalModuleFormType = {
  type: 'GOAL';
  mainHeader: string;
  subHeader: string;
  ids: number[];
};

export type DescriptionFullImageModuleFormType = {
  type: 'FULL-IMAGE';
  imageList: string[];
};

export type DescriptionCollaboOliveYoungReviewModuleFormType = {
  type: 'COLLABO-OLIVEYOUNG-REVIEW';
  data: OliveYoungReviewModuleFormType;
};

export type OliveYoungReviewModuleFormType = {
  purchaseRequiredTextList: {
    text: string;
    type: 'normal' | 'highlight';
    active: boolean;
  }[];
  subModule: CommonDescriptionModuleFormType[];
  productThumbnailImageUrl: string;
  price: number;
  originalPrice: number;
  tipTextList: { text: string; type: 'normal' | 'highlight' }[];
  reviewGuide: string;
  reviewSubGuide: string;
  reviewText: string;
  reviewImageUrls: string[];
  pleaseCheckTextList: {
    text: string;
    type: 'normal' | 'highlight';
    active: boolean;
  }[];
};

export type DescriptionCollaboSupportersInstagramModuleFormType = {
  type: 'COLLABO-SUPPORTERS-INSTAGRAM';
  data: SupportersInstagramModuleFormType;
};

export type SupportersInstagramModuleFormType = {
  instagramNoticeList: string[];
  collaboImageUrls: string[];
  checkList: string[];
  mainExampleImageUrl: string;
  exampleImageUrls: string[];
  tipTextList: { text: string; type: 'normal' | 'highlight' }[];
};

export type DescriptionCollaboSpecialPrizeModuleFormType = {
  type: 'COLLABO-SPECIAL-PRIZE';
  data: {
    type: PrizeType;
    title: SpecialPrizeTitle;
    noticeList: string[];
  };
};

type PrizeType = 'total' | 'individual' | 'achievement';
export const enum SpecialPrizeTitle {
  TOTAL = '특별상금',
  INDIVIDUAL_PRIZE = '인당 상금',
  INDIVIDUAL_PAYBACK = '인당 페이백',
}
export type SpecialPrizeModuleFormType = {
  type: PrizeType;
  title: SpecialPrizeTitle;
  noticeList: string[];
};

export type DescriptionCollaboSpecialRewardModuleFormType = {
  type: 'COLLABO-SPECIAL-REWARD';
  data: SpecialRewardModuleFormType;
};

export type SpecialRewardModuleFormType = {
  from: string;
  to: string;
  rewardText: string;
  noticeList: string[];
};
export type DescriptionCollaboAdditionalRewardModuleFormType = {
  type: 'COLLABO-ADDITIONAL-REWARD';
  data: {
    to: string;
    itemName: string;
    image: string;
    itemDetailName: string;
    itemDetailDescription: string;
    noticeList: string[];
  };
};

export type DescriptionCollaboTopSummaryModuleFormType = {
  type: 'COLLABO-TOP-SUMMARY';
  data: {
    mainHeader: string;
    subHeader: string;
    logoImage: string;
    image: string;
  };
};

export type DescriptionCollaboNaverSmartStoreModuleFormType = {
  type: 'COLLABO-NAVER-SMART-STORE';
  data: NaverSmartStoreModuleFormType;
};

export type NaverSmartStoreModuleFormType = {
  topGuideImageUrl: string;
  collaboImageUrls: string[];
  purchaseRequiredTextList: {
    text: string;
    type: 'normal' | 'highlight';
    active: boolean;
  }[];
  productThumbnailImageUrl: string;
  price: number;
  originalPrice: number;
  reviewText: string;
  reviewImageUrls: string[];
  reviewGuide: string;
  pleaseCheckTextList: {
    text: string;
    type: 'normal' | 'highlight';
    active: boolean;
  }[];
};

export type DescriptionCollaboKakaoGiftModuleFormType = {
  type: 'COLLABO-KAKAO-GIFT';
  data: KakaoGiftModuleFormType;
};

export type KakaoGiftModuleFormType = {
  collaboImageUrls: string[];
  productThumbnailImageUrl: string;
  price: number;
  originalPrice: number;
  hashTag: {
    receiveFrom: string;
    category: string;
  };
  reviewGuide: string;
  reviewText: string;
  reviewImageUrls: string[];
  pleaseCheckTextList: { text: string; type: 'normal' | 'highlight' }[];
};

export type DescriptionCollaboOliveYoungOnlyBuyModuleFormType = {
  type: 'COLLABO-OLIVEYOUNG-ONLY-BUY';
  data: OliveYoungOnlyBuyModuleFormType;
};

export type OliveYoungOnlyBuyModuleFormType = Omit<
  OliveYoungReviewModuleFormType,
  'reviewText' | 'reviewImageUrls'
> & {
  reviewExampleImageUrls: string[];
};

export type CustomFieldFormType = PublicTypeCustomFieldFormType &
  SpecialPrizeCustomFieldFormType &
  UseItemImpossibleCustomFieldFormType &
  CancelImpossibleCustomFieldFormType &
  AchievementInfoListCustomFieldFormType &
  AchievementMissionCustomFieldFormType &
  FAQCustomFieldFormType &
  InstagramInfoListCustomFieldFormType &
  NaverBlogInfoListCustomFieldFormType &
  PurchaseInfoCustomFieldFormType &
  NaverIdRequiredCustomFieldFormType &
  CollaborationCustomFieldFormType &
  SupportersCustomFieldFormType &
  QnACustomFieldFormType &
  CreatorCustomFieldFormType &
  CommerceFormType &
  UserFilterFormType &
  TimestampCustomFieldFormType &
  ImageFilterCustomFieldFormType &
  PostConnectPrizeCustomFieldFormType &
  DistanceCustomFieldFormType &
  ViralLoopInviteeRewardCustomFieldFormType &
  ViralLoopAuthenticationMethodCustomFieldFormType;

// 참가자만, 나만, 일부 인증 회차만 인증샷 공개
export type PublicTypeCustomFieldFormType = {
  publicType: 'PARTICIPANT' | 'SECRET' | 'PARTIAL';
};

/* 특별 상금 설정 */
export type SpecialPrizeCustomFieldFormType = {
  specialPrize: {
    prizeType: 'total' | 'individual' | 'achievement' | null;
    amount: number;
  };
};

/* 인증패스 사용 불가 설정 */
export type UseItemImpossibleCustomFieldFormType = {
  useItemImpossible: boolean;
};

/* 취소 불가능 챌린지로 설정 */
export type CancelImpossibleCustomFieldFormType = {
  cancelImpossible: boolean | null;
};

/* 챌린지 인증회차별 정보 설정 */
export type AchievementInfoListCustomFieldFormType = {
  achievementInfoList: AchievementInfoItem[];
};

export type AchievementInfoItem = {
  isPublic: boolean;
  prizeAmount: number | null;
  dueStartDate: string | null;
  dueEndDate: string | null;
  dueStartTime: string | null;
  dueEndTime: string | null;
};

/* 좋은/나쁜 인증샷 (제휴) */
export type AchievementMissionCustomFieldFormType = {
  achievementMission: AchievementMissionItem[];
};

export type AchievementMissionItem = {
  type: 'good' | 'bad';
  title: string;
  text: string;
  image: string;
  achievementIndexList: number[];
};

/* FAQ 모듈, 없음으로 설정하면 undefined로 설정한다 */
export type FAQCustomFieldFormType = {
  faq: string | null;
};

/* 인스타 인증 관련 정보, 이걸로 instagramRequired, instagram 커스텀 필드도 이 값으로 만든다 */
export type InstagramInfoListCustomFieldFormType = {
  instagramInfoList: InstagramInfoListItem[];
};

export type InstagramInfoListItem = {
  achievementIndex: number[];
  checklist: string[];
  hashTagText: string[];
  isAccountExposed: boolean;
  isAccountPublic: boolean;
};

/* 네이버 블로그 인증 관련 정보 */
export type NaverBlogInfoListCustomFieldFormType = {
  naverBlogInfoList: NaverBlogInfoListItem[];
};

export type NaverBlogInfoListItem = {
  hashTagList: string[];
  checklist: string[];
  achievementIndexList: number[];
};

/* 회차별 구매 리뷰 인증 관련 정보 */
export type PurchaseInfoCustomFieldFormType = {
  purchaseInfo: {
    platform: string;
    platformName: string;
    connectionRequired: boolean;
    items: PurchaseInfoListItem[];
  } | null;
};

export type PurchaseInfoListItem =
  | {
      type: 'PURCHASE';
      confirmTitle: string;
      checklist: string[];
      achievementIndex: number[];
    }
  | {
      type: 'REVIEW';
      confirmTitle: string;
      checklist: string[];
      achievementIndex: number[];
      requiredText: string;
    };

/* 네이버 계정 연동 필수 여부 */
export type NaverIdRequiredCustomFieldFormType = {
  naverIdRequired: boolean;
};

/* 제휴챌린지 관련 정보 */
export type CollaborationCustomFieldFormType = {
  collaboration: {
    reviewRewardText: string | null;
    collaboProductName: string | null;
    collaboProductLink: string | null;
    reviewQuestionList: ChallengeCollaboQuestion[];
  };
};

export type ChallengeCollaboQuestion = {
  placeholder: string;
  question: string;
  reviewMinLength: number;
  isDisplayed: boolean;
};

/* 체험단 관련 정보 */
export type SupportersCustomFieldFormType = {
  supporters: {
    hasDelivery: boolean | null;
    benefit: string | null;
    userLimit: number | null;
    selectionResultDate: string | null;
    isSelectionDone: boolean | null;
    gender: ('F' | 'M')[];
    age: SupportersAge[];
    category: string[];
  } & SupportersWeight;
};

/* 체험단 가중치 */
export type SupportersWeight = {
  challengeWeight: number | null;
  instagramWeight: number | null;
  naverBlogBuddyCountWeight: number | null;
  naverVisitorCountWeight: number | null;
  oliveYoungBabyWeight: number | null;
  oliveYoungPinkWeight: number | null;
  oliveYoungGreenWeight: number | null;
  oliveYoungBlackWeight: number | null;
  oliveYoungGoldWeight: number | null;
  oliveYoungTroubleWeight: number | null;
  oliveYoungWrinkleWeight: number | null;
};

export type SupportersAge =
  | '19-24'
  | '25-29'
  | '30-34'
  | '35-39'
  | '40-44'
  | '45-49'
  | '50-54'
  | '55-59'
  | '60-64'
  | '65-69'
  | '70+';

/* QnA 관련 정보 관리하기 */
export type QnACustomFieldFormType = {
  qna: {
    questions: QnAQuestionItem[];
  };
};

export type QnAQuestionItem = {
  achievementIndex: number;
  question: Question[];
};

export type Question =
  | MultipleChoiceQuestion
  | ShortAnswerQuestion
  | EssayAnswerQuestion;

// 답변 제출 전 | 후 는 데이터 생겨먹은 구조는 똑같다.
// 근데 null 값들은 optional 이여도 될 것 같다.
export type MultipleChoiceQuestion = {
  id: number;
  type: QuestionType.MULTIPLE_CHOICE;
  title: string;
  multipleSelection: boolean; // true: 복수, false: 단수
  choices: ChoiceType[];
};

export type ChoiceType = {
  id: number;
  type: ChoiceAnswerType;
  text: string; // 기타 (직접 입력)
};

export type ShortAnswerQuestion = {
  id: number;
  type: QuestionType.SHORT_ANSWER;
  title: string;
  placeholder: string | null;
};

export type EssayAnswerQuestion = {
  id: number;
  type: QuestionType.ESSAY_ANSWER;
  title: string;

  answerTitle: {
    minLength: number | null;
    maxLength: number | null;
  };

  answerContent: {
    minLength: number | null;
    maxLength: number | null;
  };
};

/* 리더스(크리에이터) 챌린지 관련 정보 관리하기 */
export type CreatorCustomFieldFormType = {
  creator: {
    type: 'COACHING' | 'TOGETHER' | null;
    defaultCommercePrice: number | null;
  };
};

/* 커머스 관련 정보 관리하기 */
/* 커스텀 필드 아님 */
export type CommerceFormType = {
  commerce: {
    productHeader: string | null;
    selectType: 'RADIO' | 'CHECKBOX';
    isMandatory: boolean | null;
    rccps: Rccp[];
  };
};

export type Rccp = {
  // product를 relation challenge challenge_product에 맞는 형태로 만들어서 보내줌
  name: string;
  productId: number;
  companyId: number;
  companyName: string;
  price: number;
  isAddressNeeded: boolean;
  shippingPrice: number;
  fee: number;
  unit: number; // 항상 1..;;
  totalCount: number;
};

/* 유저필터 관련 정보 관리하기 */
/* 커스텀 필드 아님 */
export type UserFilterFormType = {
  userFilter: {
    id: number | null;
    name: string | null;
  };
};

/* 타임스탬프 관련 정보 관리하기 */
export type TimestampCustomFieldFormType = {
  timestamp: {
    hideTimeStamp: boolean | null;
  };
};

/* 이미지 필터 정보 관리하기 */
export type ImageFilterCustomFieldFormType = {
  imageFilter: ImageFilterCustomFieldValue[];
};

// 사후 연동 관련 커스텀 필드
export type PostConnectPrizeCustomFieldFormType = {
  postConnectPrize: {
    platform: string;
    platformName: string;
    amount: number;
  } | null;
};

/* 거리 정보 관리하기 */
export type DistanceCustomFieldFormType = {
  distance: DistanceCustomFieldValue[];
};

/* 바이럴루프 피초대자 보상 관리하기 */
export type ViralLoopInviteeRewardCustomFieldFormType = {
  viralLoopInviteeReward?: number;
};

/* 바이럴루프 인증 방법 관리하기 */
export type ViralLoopAuthenticationMethodCustomFieldFormType = {
  viralLoopAuthenticationMethod?: {
    title: string;
    text: string;
  }[];
};
