import Spacing from '@component/Spacing';
import TextInput from '@component/TextInput';
import { PreAlramForm } from '../PreAlarmForm.types';

const AlarmBasicInfo = ({ title, brand, handleChange, disabled }: Props) => {
  return (
    <>
      <TextInput
        label="챌린지명"
        value={title}
        onChange={(e) => handleChange('title', e)}
        style={{ maxWidth: '800px' }}
        disabled={disabled}
      />

      <Spacing vertical={10} />

      <TextInput
        label="회사"
        value={brand}
        onChange={(e) => handleChange('brand', e)}
        style={{ maxWidth: '800px' }}
        disabled={disabled}
      />
    </>
  );
};

type Props = {
  title: string;
  brand: string;
  handleChange: <T extends keyof PreAlramForm>(
    key: T,
    value?: PreAlramForm[T],
  ) => void;
  disabled: boolean;
};

export default AlarmBasicInfo;
