import AchievementCaptureCard from '@container/User/UserDetail/ChallengeInfo/AchievementCaptureCard';
import AchievementUrlCard from '@container/User/UserDetail/ChallengeInfo/AchievementUrlCard';
import { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import {
  AchievementAllInfoForAdmin,
  ReportedAchievement,
} from 'src/types/achievement.types';
import ReviewModal from './ReviewModal/ReviewModal';
import WarningModal from './WarningModal/WarningModal';
import PassModal from './PassModal/PassModal';
import { apis } from '@api/index';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import AllAchievementModal from '../AllAchievementModal';
import AllAchievementUrlsModal from '@container/User/UserDetail/AllAchievementUrlsModal';
import PassCancelModal from './PassCancelModal/PassCancelModal';

type Props = {
  size: 'small' | 'medium' | 'large' | undefined;
  userId: number;
  achievement: AchievementAllInfoForAdmin;
  showAdminCommentModal: () => void;
  showReportModal: () => void;
  showDeleteModal: () => void;
};

const AchievementCard = ({
  size,
  userId,
  achievement,
  showAdminCommentModal,
  showReportModal,
  showDeleteModal,
}: Props) => {
  const [allAchievementFiles, setAllAchievementFiles] = useState<
    ReportedAchievement[]
  >([]);
  const [authentications, setAuthentications] = useState<{
    authenticationMethod: string;
  }>({
    authenticationMethod: '',
  });
  const [achievementRegisterInfo, setAchievementRegisterInfo] = useState<any>(
    {},
  );

  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [passModalOpen, setPassModalOpen] = useState(false);
  const [allAchievementFilesModalOpen, setAllAchievementFilesModalOpen] =
    useState(false);
  const [allAchievementUrlsModalOpen, setAllAchievementUrlsModalOpen] =
    useState(false);
  const [passCancelModalOpen, setPassCancelModalOpen] = useState(false);

  const showReviewModal = () => {
    setReviewModalOpen(true);
  };

  const showWarningModal = () => {
    setWarningModalOpen(true);
  };

  const showPassModal = () => {
    setPassModalOpen(true);
  };

  const showPassCancelModal = (e: any, { value }: any) => {
    setPassCancelModalOpen(true);
    setAchievementRegisterInfo(value);
  };

  const showAllAchievementFilesModal = async (e: any, { value }: any) => {
    e.preventDefault();
    e.stopPropagation();
    LoadingIndicator.show();

    const res =
      await apis.achievement.getAchievementFilesByAchievementId(value);
    if ((res as any)?.response?.data && 'err' in (res as any)?.response?.data) {
      return;
    }
    const { achievementFiles: _allAchievementFiles } = res;

    if (_allAchievementFiles.length === 0) {
      return;
    }
    setAllAchievementFilesModalOpen(true);
    setAllAchievementFiles(_allAchievementFiles);
    setAuthentications({
      authenticationMethod:
        _allAchievementFiles[0]?.challenge?.authenticationMethod,
    });
    LoadingIndicator.hide();
  };

  const showAllAchievementUrlsModal = async (e: any, { value }: any) => {
    LoadingIndicator.show();

    const res =
      await apis.achievement.getAchievementFilesByAchievementId(value);

    if ((res as any)?.response?.data && 'err' in (res as any)?.response?.data) {
      return;
    }
    const { achievementFiles: _allAchievementFiles } = res;

    if (_allAchievementFiles.length === 0) {
      return;
    }

    setAllAchievementUrlsModalOpen(true);
    setAllAchievementFiles(_allAchievementFiles);
    setAuthentications({
      authenticationMethod:
        _allAchievementFiles[0]?.challenge?.authenticationMethod,
    });
    LoadingIndicator.hide();
  };

  return (
    <>
      <Grid.Column>
        <>
          {achievement.achievementFile.isUrlCard ? (
            <AchievementUrlCard
              size={size}
              achievement={achievement}
              onAdminCommentPress={showAdminCommentModal}
              onReportPress={showReportModal}
              onReviewPress={showReviewModal}
              onDeletePress={showDeleteModal}
              onCommentPress={showWarningModal}
              onAllAchievementUrlsPress={showAllAchievementUrlsModal}
              onPassPress={showPassModal}
              onPassCancelPress={showPassCancelModal}
            />
          ) : (
            <AchievementCaptureCard
              size={size}
              achievement={achievement}
              onAdminCommentPress={showAdminCommentModal}
              onReportPress={showReportModal}
              onReviewPress={showReviewModal}
              onDeletePress={showDeleteModal}
              onCommentPress={showWarningModal}
              onAllAchievementFilesPress={showAllAchievementFilesModal}
              onPassPress={showPassModal}
              onPassCancelPress={showPassCancelModal}
            />
          )}
        </>
      </Grid.Column>

      <ReviewModal
        achievement={achievement}
        reviewModalOpen={reviewModalOpen}
        closeReviewModal={() => setReviewModalOpen(false)}
      />

      <WarningModal
        achievement={achievement}
        warningModalOpen={warningModalOpen}
        closeWarningModal={() => setWarningModalOpen(false)}
      />

      <PassModal
        achievement={achievement}
        passModalOpen={passModalOpen}
        closePassModal={() => setPassModalOpen(false)}
      />

      <AllAchievementUrlsModal
        isOpened={allAchievementUrlsModalOpen}
        onClosePress={() => setAllAchievementUrlsModalOpen(false)}
        authentications={authentications}
        allAchievementFiles={allAchievementFiles}
      />

      <AllAchievementModal
        isOpened={allAchievementFilesModalOpen}
        onClosePress={() => setAllAchievementFilesModalOpen(false)}
        allAchievementFiles={allAchievementFiles}
        authentications={authentications}
      />

      <PassCancelModal
        achievementRegisterInfo={achievementRegisterInfo}
        passCancelModalOpen={passCancelModalOpen}
        achievementId={achievement.id}
        userId={userId}
        closePassCancelModal={() => setPassCancelModalOpen(false)}
      />
    </>
  );
};

export default AchievementCard;
