import {
  ChallengeCustomField,
  ChallengeInstaCustomField,
  ChallengeInstaInfoListCustomField,
} from 'src/types/customField.types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'instagramInfoList'>,
  Extract<
    ChallengeCustomField,
    | { name: 'instagramInfoList' }
    | { name: 'instagram' }
    | { name: 'instagramRequired' }
  >
> = ({ instagramInfoList }) => {
  if (instagramInfoList.length === 0) return [];

  const { hashTagText, ...rest } = instagramInfoList[0];

  const instagram = {
    ...rest,
  } as ChallengeInstaCustomField['values'];

  instagram.hashTagText = instagram.hashTagText?.[0] || '';

  return [
    {
      name: 'instagramInfoList',
      values: instagramInfoList,
    },
    {
      name: 'instagram',
      values: instagram,
    },
    {
      name: 'instagramRequired',
      values: true,
    },
  ];
};

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'instagramInfoList'>
> = (customFields) => {
  const instagramInfoList = customFields.find(
    ({ name }) => name === 'instagramInfoList',
  );

  if (!instagramInfoList) return {} as EmptyObj;

  const { values } = instagramInfoList as ChallengeInstaInfoListCustomField;

  return {
    instagramInfoList: values,
  };
};
