import {
  DescriptionCollaboSpecialRewardModuleFormType,
  DescriptionModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const validate = (
  descriptionModule: DescriptionModuleFormType[],
): { isValid: true } | { isValid: false; message: string } => {
  const target = descriptionModule.find(
    ({ type }) => type === 'COLLABO-SPECIAL-REWARD',
  );
  if (!target) return { isValid: true };

  const {
    data: { to, from, rewardText },
  } = target as DescriptionCollaboSpecialRewardModuleFormType;

  if (!from)
    return {
      isValid: false,
      message: '[상세모듈] 특별리워드는 제휴챌린지->회사선택 후 설정해야합니다',
    };
  if (!to?.length)
    return {
      isValid: false,
      message: '[상세모듈] 특별리워드 받는이 설정이 필요합니다',
    };

  if (!rewardText?.length)
    return {
      isValid: false,
      message: '[상세모듈] 특별리워드 리워드 설정이 필요합니다',
    };

  return { isValid: true };
};
