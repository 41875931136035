import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'authenticationBadExamples'>;

export const validate: FormValidator<Params> = ({
  authenticationBadExamples,
}) => {
  if (!Array.isArray(authenticationBadExamples))
    return { isValid: false, message: '인증예시를 확인해주세요' };

  return { isValid: true };
};
