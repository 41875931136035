import React from 'react';
import {
  Grid,
  Divider,
  Form,
  Icon,
  Label,
  TextArea,
  Dropdown,
  Table,
  Button,
  Image,
} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';

const ChallengeCurationForm = ({
  object,
  idx,
  collectionTypes,
  spliceCuration,
  curationChangeOrder,
  curationHandleChange,
  curationHandleRadioChange,
  curationHandleChangeSingleFile,
}) => {
  return (
    <Form.Field>
      <Label
        style={{ padding: 10, cursor: 'pointer' }}
        color="black"
        value={idx}
        onClick={spliceCuration}
      >
        {collectionTypes[object.type].text}
        <Icon name="delete" />
      </Label>
      <Button
        icon
        size="mini"
        name="doubleUp"
        value={idx}
        onClick={curationChangeOrder}
      >
        <Icon name="angle double up" />
      </Button>
      <Button
        icon
        size="mini"
        name="up"
        value={idx}
        onClick={curationChangeOrder}
      >
        <Icon name="angle up" />
      </Button>
      <Button
        icon
        size="mini"
        name="down"
        value={idx}
        onClick={curationChangeOrder}
      >
        <Icon name="angle down" />
      </Button>
      <Button
        icon
        size="mini"
        name="doubleDown"
        value={idx}
        onClick={curationChangeOrder}
      >
        <Icon name="angle double down" />
      </Button>
      <Grid columns={2}>
        <Grid.Column>
          {object.type === 'GOAL' ? (
            <>
              <label>목표 목록 (순서 대로 노출됨)</label>
              <Dropdown
                placeholder="노출할 목표를 선택하세요."
                fluid
                search
                selection
                multiple={object.type === 'GOAL'}
                name={'goalIds-' + idx}
                options={collectionTypes[object.type].options}
                value={object.goalIds}
                onChange={curationHandleRadioChange}
              />
            </>
          ) : (
            <>
              <label>서브타입</label>
              <Dropdown
                placeholder="서브타입을 선택하세요."
                fluid
                search
                selection
                multiple={object.type === 'GOAL'}
                name={'subType-' + idx}
                options={collectionTypes[object.type].options}
                value={object.subType}
                onChange={curationHandleRadioChange}
              />
            </>
          )}

          <label>제목</label>
          <TextArea
            name={'title-' + idx}
            value={object.title}
            onChange={curationHandleChange}
          />

          <label>서브제목</label>
          <TextArea
            name={'subTitle-' + idx}
            value={object.subTitle}
            onChange={curationHandleChange}
          />

          <label>이미지</label>
          <input
            type="file"
            name={'imageUrl-' + idx}
            onChange={curationHandleChangeSingleFile}
          />
          <Image src={object.imageUrl} size="small" />
        </Grid.Column>

        <Grid.Column>
          {object.type !== 'CUSTOM' && (
            <Table basic="very">
              <Table.Body>
                {_.map(object.challenges, (c) => (
                  <Table.Row key={c.id}>
                    <Table.Cell>{c.id}</Table.Cell>
                    <Table.Cell>{c.title}</Table.Cell>
                    <Table.Cell>
                      {moment(c.startDate).format('YYYY-MM-DD')}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(c.endDate).format('YYYY-MM-DD')}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Grid.Column>
      </Grid>

      <Divider />
    </Form.Field>
  );
};

export default ChallengeCurationForm;
