import React from 'react';
import * as S from './style';
import { Label } from './style';
import { Row } from '../../../../component/Row';

const TITLE = 'TITLE';
const INTERVIEW = 'INTERVIEW';
const LEFT_ALIGN_TEXT = 'LEFT-ALIGN-TEXT';
const NUMBER = 'NUMBER';
const HORIZONTAL = 'HORIZONTAL';
const SQUARE = 'SQUARE';
const VERTICAL = 'VERTICAL';
const SLOGAN = 'SLOGAN';

const CARD_TITLE = 'CARD-TITLE';
const MULTI_IMAGES = 'MULTI-IMAGES';
const NEW_INTERVIEW = 'NEW-INTERVIEW';
const TEXT_ONLY = 'TEXT-ONLY';
const VIDEO = 'VIDEO';
const HYPERLINK = 'HYPERLINK';
const FULL_PHOTO = 'FULL-PHOTO';

const FULL_PHOTO_NO_GAP = 'FULL-PHOTO-NO-GAP';
const FULL_PHOTO_WITH_GAP = 'FULL-PHOTO-WITH-GAP';

const _renderItem = (module: any) => {
  switch (module.type) {
    case TITLE:
      return <TitleModuleOld module={module} />;
    case INTERVIEW:
      return <InterviewModuleOld module={module} />;
    case LEFT_ALIGN_TEXT:
      return <LeftAlignTextModuleOld module={module} />;
    case NUMBER:
      return <NumberModuleOld module={module} />;
    case HORIZONTAL:
      return <HorizontalModuleOld module={module} />;
    case SQUARE:
      return <SquareModuleOld module={module} />;
    case VERTICAL:
      return <VerticalModuleOld module={module} />;
    case SLOGAN:
      return <SloganModuleOld module={module} />;

    // 새로운
    case CARD_TITLE:
      return <CardTitleModule module={module} />;
    case MULTI_IMAGES:
      return <MultiImagesModule module={module} />;
    case NEW_INTERVIEW:
      return <NewInterviewModule module={module} />;
    case TEXT_ONLY:
      return <TextOnlyModule module={module} />;
    case VIDEO:
      return <VideoModule module={module} />;
    case HYPERLINK:
      return <HyperlinkModule module={module} />;
    case FULL_PHOTO:
      return <FullPhotoModule module={module} />;
    case FULL_PHOTO_WITH_GAP:
    case FULL_PHOTO_NO_GAP:
      return <FullPhotoNoGapModule module={module} />;
    default:
      return null;
  }
};

export const ChallengeDescription = ({ challenge }: any) => {
  const { descriptionModule } = challenge;

  if (!descriptionModule || descriptionModule.length === 0) {
    return null;
  }

  return (
    <S.Container>
      {descriptionModule.map((module: any, idx: number) => (
        <div key={idx}>{_renderItem(module)}</div>
      ))}
    </S.Container>
  );
};

const TitleModuleOld = ({ module }: any) => {
  return (
    <S.ModuleContainer margin="0 0 20px 0">
      {module.title && (
        <Label size={18} weight={700} margin="0 0 4px 0">
          {module.title}
        </Label>
      )}
      {module.subHeader && (
        <Label size={16} weight={500} margin="0 0 4px 0">
          {module.subHeader}
        </Label>
      )}
    </S.ModuleContainer>
  );
};

const HorizontalModuleOld = ({ module }: any) => {
  return (
    <S.ModuleContainer margin="0 0 20px 0">
      {module.mainHeader && (
        <Label size={18} margin="0 0 20px 0">
          {module.mainHeader}
        </Label>
      )}
      {module.subHeader && (
        <Label size={16} weight={500} margin="0 0 4px 0">
          {module.subHeader}
        </Label>
      )}
      {module.image && (
        <S.ImgCardResponsive alt="image-card" src={module.image} />
      )}
      {module.body && (
        <Label size={14} margin="0 0 4px 0">
          {module.body}
        </Label>
      )}
    </S.ModuleContainer>
  );
};

const VerticalModuleOld = ({ module }: any) => {
  return (
    <S.ModuleContainer margin="0 0 20px 0">
      {module.mainHeader && (
        <Label size={18} margin="0 0 20px 0">
          {module.mainHeader}
        </Label>
      )}
      {module.subHeader && (
        <Label size={16} weight={500} margin="0 0 4px 0">
          {module.subHeader}
        </Label>
      )}
      {module.image && <img src={module.image} width={100} height={100} />}
      {module.body && (
        <Label size={14} margin="0 0 4px 0">
          {module.body}
        </Label>
      )}
    </S.ModuleContainer>
  );
};

const SquareModuleOld = ({ module }: any) => {
  return (
    <S.ModuleContainer margin="0 0 20px 0">
      {module.mainHeader && (
        <Label size={18} margin="0 0 20px 0">
          {module.mainHeader}
        </Label>
      )}
      {module.subHeader && (
        <Label size={16} weight={500} margin="0 0 4px 0">
          {module.subHeader}
        </Label>
      )}
      {module.image && <img src={module.image} width={100} height={100} />}
      {module.body && (
        <Label size={14} margin="0 0 4px 0">
          {module.body}
        </Label>
      )}
    </S.ModuleContainer>
  );
};

const InterviewModuleOld = ({ module }: any) => {
  return (
    <S.ModuleContainer margin="0 0 20px 0">
      {module.mainHeader && (
        <Label size={18} margin="0 0 20px 0">
          {module.mainHeader}
        </Label>
      )}
      {module.image && (
        <S.InterviewContainer>
          <S.ImageWithRadius>
            <img src={module.image} width={100} height={100} />
          </S.ImageWithRadius>
          {module.subHeader && (
            <Label size={14} weight={700} margin="0 0 4px 0">
              {module.subHeader}
            </Label>
          )}
          {module.body && (
            <Label size={14} margin="0 0 4px 0">
              {module.body}
            </Label>
          )}
        </S.InterviewContainer>
      )}
    </S.ModuleContainer>
  );
};

const NumberModuleOld = ({ module }: any) => {
  return (
    <S.ModuleContainer margin="0 0 20px 0">
      {module.mainHeader && (
        <Label size={18} margin="0 0 20px 0">
          {module.mainHeader}
        </Label>
      )}
      {module.numbers && (
        <div>
          {module.numbers.map((item: any, idx: number) => (
            <Row key={idx}>
              <div>
                <Label size={14}>{item}</Label>
              </div>
            </Row>
          ))}
        </div>
      )}
    </S.ModuleContainer>
  );
};

const SloganModuleOld = ({ module }: any) => {
  return (
    <S.ModuleContainer margin="0 0 20px 0">
      {module.subHeader && (
        <Label size={16} weight={500} margin="0 0 4px 0">
          {module.subHeader}
        </Label>
      )}
    </S.ModuleContainer>
  );
};

const LeftAlignTextModuleOld = ({ module }: any) => {
  return (
    <S.ModuleContainer margin="0 0 20px 0">
      {module.mainHeader && (
        <Label size={18} margin="0 0 20px 0">
          {module.mainHeader}
        </Label>
      )}
      {module.subHeader && (
        <Label size={16} weight={500} margin="0 0 4px 0">
          {module.subHeader}
        </Label>
      )}
      {module.body && (
        <Label size={14} margin="0 0 4px 0">
          {module.body}
        </Label>
      )}
    </S.ModuleContainer>
  );
};

const CardTitleModule = ({ module }: any) => {
  return (
    <S.ModuleContainer margin="0 0 20px 0">
      {module.mainHeader && (
        <Label size={18} margin="0 0 20px 0">
          {module.mainHeader}
        </Label>
      )}
      {module.subHeader && (
        <Label size={16} weight={500} margin="0 0 4px 0">
          {module.subHeader}
        </Label>
      )}
    </S.ModuleContainer>
  );
};

const MultiImagesModule = ({ module }: any) => {
  return (
    <S.ModuleContainer margin="0 0 20px 0">
      {module.mainHeader && (
        <Label size={18} margin="0 0 20px 0">
          {module.mainHeader}
        </Label>
      )}
      {module.subHeader && (
        <Label size={16} weight={500} margin="0 0 4px 0">
          {module.subHeader}
        </Label>
      )}
      {module.images && module.images.length > 0 && (
        <S.ImgHorizontalContainer>
          {module.images.map((image: any) => (
            <S.ImgCardSquared
              key={image}
              style={{
                backgroundImage: `url(${image})`,
              }}
            />
          ))}
        </S.ImgHorizontalContainer>
      )}
    </S.ModuleContainer>
  );
};

const NewInterviewModule = ({ module }: any) => {
  return (
    <S.ModuleContainer margin="0 0 20px 0">
      {module.mainHeader && (
        <Label size={18} margin="0 0 20px 0">
          {module.mainHeader}
        </Label>
      )}
      {module.subHeader && (
        <Label size={16} weight={500} margin="0 0 4px 0">
          {module.subHeader}
        </Label>
      )}
      {module.interviewee &&
        module.interviewee.length > 0 &&
        module.interviewee.map((o: any) => (
          <S.InterviewContainer key={o.image}>
            <S.ImageWithRadius>
              <img src={o.image} width={100} height={100} alt="interview" />
            </S.ImageWithRadius>
            {o.name && (
              <Label size={14} weight={700} textAlign="center" margin="4px 0">
                {o.name}
              </Label>
            )}
            {o.content && (
              <Label size={14} textAlign={'center'} margin="0 0 4px 0">
                {o.content}
              </Label>
            )}
          </S.InterviewContainer>
        ))}
    </S.ModuleContainer>
  );
};

const TextOnlyModule = ({ module }: any) => {
  return (
    <S.ModuleContainer margin="0 0 20px 0">
      {module.mainHeader && (
        <Label size={18} margin="0 0 20px 0">
          {module.mainHeader}
        </Label>
      )}
      {module.subHeader && (
        <Label size={16} weight={500} margin="0 0 4px 0">
          {module.subHeader}
        </Label>
      )}
      {module.body && (
        <Label size={14} margin="0 0 4px 0">
          {module.body}
        </Label>
      )}
    </S.ModuleContainer>
  );
};

const VideoModule = ({ module }: any) => {
  return (
    <S.ModuleContainer>
      {module.mainHeader && (
        <Label size={18} margin="0 0 20px 0">
          {module.mainHeader}
        </Label>
      )}
      {module.subHeader && (
        <Label size={16} weight={500} margin="0 0 4px 0">
          {module.subHeader}
        </Label>
      )}
      <iframe
        src={`https://www.youtube.com/embed/${module.youtubeId}`}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        // allowFullScreen
        title="video"
      />
      {module.body && (
        <Label size={14} margin="0 0 4px 0">
          {module.body}
        </Label>
      )}
    </S.ModuleContainer>
  );
};

const HyperlinkModule = ({ module }: any) => {
  return (
    <S.ModuleContainer margin="0 0 20px 0">
      {module.mainHeader && (
        <Label size={18} margin="0 0 20px 0">
          {module.mainHeader}
        </Label>
      )}
      {module.url && (
        <a href={module.url} target="_blank">
          <Label color="red" textDecoration="underline">
            {module.urlText}
          </Label>
        </a>
      )}
      {module.body && (
        <Label size={14} margin="0 0 4px 0">
          {module.body}
        </Label>
      )}
    </S.ModuleContainer>
  );
};

const FullPhotoModule = ({ module }: any) => {
  return (
    <S.ModuleContainer margin="0 0 20px 0">
      {module.mainHeader && (
        <Label size={18} margin="0 0 20px 0">
          {module.mainHeader}
        </Label>
      )}
      {module.image && (
        <S.ImgCardResponsive src={module.image} alt="image-card" />
      )}
      {module.body && (
        <Label size={14} margin="0 0 4px 0">
          {module.body}
        </Label>
      )}
    </S.ModuleContainer>
  );
};

const FullPhotoNoGapModule = ({ module }: any) => {
  return (
    <S.ModuleContainer>
      {module.mainHeader && (
        <Label size={18} margin="0 0 20px 0">
          {module.mainHeader}
        </Label>
      )}
      {module.image && (
        <S.ImgCardResponsive src={module.image} alt="image-card" />
      )}
      {module.body && (
        <Label size={14} margin="0 0 4px 0">
          {module.body}
        </Label>
      )}
    </S.ModuleContainer>
  );
};
