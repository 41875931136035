import React, { FC } from 'react';
import { Button } from 'semantic-ui-react';
// @ts-expect-error react-excel-workbook
import Workbook from 'react-excel-workbook';
import moment from 'moment';
import { OldCardTransactionWithdrawInfo } from '@types';
import setCurrencyFormat from '../../../global/setCurrencyFormat';

const TransactionTransferOldCardCashExcel: FC<
  TransactionTransferOldCardCashExcelProps
> = ({ withdraws }) => {
  return (
    <Workbook
      filename={`${moment().format('YYYY-MM-DD-HH-mm-ss_')}withdraw.xlsx`}
      element={
        <Button basic color="green" size="mini">
          Excel
        </Button>
      }
    >
      <Workbook.Sheet data={withdraws} name="Sheet A">
        <Workbook.Column
          label=""
          value={(wd: OldCardTransactionWithdrawInfo) => wd.bankName}
        />
        <Workbook.Column
          label=""
          value={(wd: OldCardTransactionWithdrawInfo) => wd.accountNo}
        />
        <Workbook.Column
          label=""
          value={(wd: OldCardTransactionWithdrawInfo) =>
            setCurrencyFormat(
              Math.ceil(
                -wd.amount *
                  (moment(wd.createdAt)
                    .add(9, 'hours')
                    .isSameOrAfter(moment('2020-07-01'))
                    ? 0.97
                    : 1),
              ),
            )
          }
        />
        <Workbook.Column
          label=""
          value={(wd: OldCardTransactionWithdrawInfo) =>
            `${wd.accountName} 환불`
          }
        />
        <Workbook.Column label="" value={() => '챌린저스'} />
      </Workbook.Sheet>
    </Workbook>
  );
};

export default TransactionTransferOldCardCashExcel;

type TransactionTransferOldCardCashExcelProps = {
  withdraws: OldCardTransactionWithdrawInfo[];
};
