import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

export const validate: FormValidator<
  Pick<ChallengeFormType, 'specialPrize'>
> = ({ specialPrize }) => {
  switch (specialPrize.prizeType) {
    case null:
    case 'achievement':
      return { isValid: true };
    case 'individual':
    case 'total':
    default:
      if (specialPrize.amount <= 0) {
        return { isValid: false, message: '특별상금 금액을 입력해주세요.' };
      }
  }

  return { isValid: true };
};
