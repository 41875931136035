import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'adminUserIds'>,
  Pick<ReqCreateOrUpdateChallenge, 'adminUserIds'>
> = ({ adminUserIds }) => {
  if (!adminUserIds)
    throw Error("[AdminUserIdsForm] this shouldn't be happened");

  return {
    adminUserIds: adminUserIds.map((id) => Number(id)),
  };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'adminUserIds'>,
  Pick<ChallengeFormType, 'adminUserIds'>
> = ({ adminUserIds }) => ({
  adminUserIds,
});
