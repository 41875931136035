import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'goalId'>,
  Pick<ChallengeFormType, 'goalId'>
> = ({ goalId }) => ({ goalId });

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'goalId'>,
  Pick<ReqCreateOrUpdateChallenge, 'goalId'>
> = ({ goalId }) => {
  if (!goalId) throw new Error("[GoalIdForm] this shouldn't be happened");

  return { goalId };
};
