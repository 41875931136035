import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { apis } from '../../../api';
import RaceForm from '../RaceForm';
import { Race } from '@types';

const RaceCreate = () => {
  const history = useHistory();

  const submit = useCallback(
    async (
      race: Race.ReqCreateOrUpdateRace,
      packages: Race.ReqCreateOrUpdateProductPackage[],
      raceImageFilters: Race.RaceImageFilterWithLeagueId[],
      labelIds: number[],
      sendSlack: boolean,
      autoGenerateOfferWall: boolean,
    ) => {
      const res = await apis.createRace({
        race,
        packages,
        raceImageFilters,
        labelIds,
        sendSlack,
        autoGenerateOfferWall,
      });
      const { raceId } = res;
      const url = `https://admin.whitecube.co.kr/race/${raceId}`;
      if (
        window.confirm(
          `대회 추가가 완료되었어요. 페이지를 확인해보시겠어요?\n${url}`,
        )
      ) {
        window.open(url, '_blank');
      }
      history.goBack();
    },
    [],
  );

  return (
    <Container>
      <RaceForm submit={submit} />
    </Container>
  );
};

export default RaceCreate;
