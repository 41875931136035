import { flatMapInfiniteData } from '@chlngers/core/lib/src/utils/queries';
import ChipInput from '@component/ChipInput';
import Label from '@component/Label';
import TextArea from '@component/TextArea';
import { DescriptionModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { useGetChallengeOptions } from '@queries/Challenge/useGetChallengeOptions';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import {
  DescriptionModule,
  DescriptionModuleConvertData2Form,
  DescriptionModuleConvertForm2Data,
} from '../../DescriptionModuleForm.type';
import {
  convertData2Form,
  convertForm2Data,
} from './ChallengeModule.serializer';
import { validate } from './ChallengeModule.validator';

type ChallengeModuleProps = {
  type: 'CHALLENGE';
  mainHeader: string;
  subHeader: string;
  ids: number[];
  moduleIndex: number;
  onUpdate: (index: number, item: DescriptionModuleFormType) => void;
};
const ChallengeModule: DescriptionModule<ChallengeModuleProps> = (
  props: ChallengeModuleProps,
) => {
  const { type, mainHeader, subHeader, ids, moduleIndex, onUpdate } = props;

  const [keyword, setKeyword] = useState('');

  const { data, fetchNextPage, hasNextPage } = useGetChallengeOptions(
    {
      keyword,
    },
    { enabled: keyword.length > 0 },
  );

  const challengeOptions = flatMapInfiniteData(data, 'challenges', 'id');

  const chipTitleGenerator = (id: number, title: string) => `${id}-${title}`;

  const handleMainHeaderChange = (value: string) => {
    onUpdate(moduleIndex, {
      type,
      subHeader,
      ids,
      ...{ mainHeader: value },
    });
  };

  const handleSubHeaderChange = (value: string) => {
    onUpdate(moduleIndex, {
      type,
      mainHeader,
      ids,
      ...{ subHeader: value },
    });
  };

  const handleChallengeChange = (changedData: string[]) => {
    const options = challengeOptions.filter(({ id }) =>
      changedData.find((t) => t.split('-')[0] === String(id)),
    );
    onUpdate(moduleIndex, {
      type,
      mainHeader,
      subHeader,
      ids: options.map(({ id }) => Number(id)),
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeKeyword = useCallback(_.debounce(setKeyword, 500), []);

  const handleReachEnd = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  return (
    <>
      <Label>메인헤더</Label>
      <TextArea value={mainHeader} onChange={handleMainHeaderChange} />
      <Label>서브헤더</Label>
      <TextArea value={subHeader} onChange={handleSubHeaderChange} />

      <Label>챌린지 목록</Label>

      <ChipInput
        placeholder="챌린지를 선택하세요."
        chipList={challengeOptions
          .filter(({ id }) => ids.includes(id))
          .map(({ id, title }) => chipTitleGenerator(id, title))}
        searchable
        searchDataList={challengeOptions.map(({ id, title }) =>
          chipTitleGenerator(id, title),
        )}
        searchDataAddable
        onChange={handleChallengeChange}
        onSearchChange={handleChangeKeyword}
        onReachEnd={handleReachEnd}
      />
    </>
  );
};

ChallengeModule.validate = validate;
ChallengeModule.convertForm2Data =
  convertForm2Data as DescriptionModuleConvertForm2Data;
ChallengeModule.convertData2Form =
  convertData2Form as DescriptionModuleConvertData2Form;
ChallengeModule.moduleName = '챌린지 목록';
ChallengeModule.defaultData = {
  type: 'CHALLENGE',
  mainHeader: '',
  subHeader: '',
  ids: [],
};
export default ChallengeModule;
