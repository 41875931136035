import React, { FC } from 'react';
import { uploadSingleFileToS3 } from '@utils/uploadImage';
import Label from '@component/Label';
import FlexBox from '@component/FlexBox/FlexBox';
import { ModuleContainer } from '../../../../DescriptionModuleForm.style';

type Props = {
  imageUrl: string;
  onChange: (imageUrl: string) => void;
};
const MainExampleImageForm: FC<Props> = ({ imageUrl, onChange }) => {
  const handleChangeImage: React.ChangeEventHandler<HTMLInputElement> = async (
    e,
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const url = await uploadSingleFileToS3(file);
    onChange(url);
  };

  return (
    <ModuleContainer>
      <Label size="large" style={{ marginTop: 20 }}>
        인스타그램 인증 예시 이미지
      </Label>
      <FlexBox.Row gap={8} alignItems="center">
        <Label style={{ width: 120 }}>예시 이미지</Label>
        <input
          type="file"
          style={{ margin: '12px 0px' }}
          onChange={handleChangeImage}
        />
      </FlexBox.Row>
      {!!imageUrl && (
        <img
          src={imageUrl}
          style={{ width: 120, height: 120 }}
          alt="인스타그램 인증 예시 이미지"
        />
      )}
    </ModuleContainer>
  );
};

export default MainExampleImageForm;
