import React from 'react';
import { Form, Image, TextArea } from 'semantic-ui-react';
import * as S from '../Style';
import { ColorSample } from '@component/ColorSample';

const ChallengeCategoryForm = ({
  banner,
  onChangeText,
  onChangeInfo,
  onChangeSingleFile,
}) => {
  return (
    <>
      <h4>띠배너형태 / 사이즈 375 * 120 (비율만 맞으면 됨)</h4>
      <Image src="/banners/category-banner.png" size="large" centered />
      <div style={{ marginLeft: 20 }}>
        <S.RowContainer margin={'20px 0 0 0'}>
          <S.RowFormField>
            <label>제목 (최대 3줄, 총 33자 이내)</label>
            <TextArea
              name="title"
              rows={2}
              defaultValue={banner.title}
              onChange={onChangeText}
            />
          </S.RowFormField>
          <S.RowFormField margin={'0 0 0 10px'}>
            <label>버튼명 (제목 3줄일 때 작성 금지. 총 10자 이내)</label>
            <input
              name="buttonText"
              defaultValue={banner.info.buttonText}
              onChange={(e) => onChangeInfo('buttonText', e.target.value)}
            />
          </S.RowFormField>
        </S.RowContainer>
        <S.RowContainer>
          <Form.Field>
            <label>제목 글자색</label>
            <input
              name="color"
              defaultValue={banner.color}
              onChange={onChangeText}
            />
            <ColorSample color={banner.color} />
          </Form.Field>
          <Form.Field style={{ marginLeft: 10 }}>
            <label>버튼명 글자색</label>
            <input
              name="buttonTextColor"
              defaultValue={banner.info.buttonTextColor}
              onChange={(e) => onChangeInfo('buttonTextColor', e.target.value)}
            />
            <ColorSample color={banner.info.buttonTextColor} />
          </Form.Field>
          <Form.Field style={{ marginLeft: 10 }}>
            <label>버튼색</label>
            <input
              name="buttonBackgroundColor"
              defaultValue={banner.info.buttonBackgroundColor}
              onChange={(e) =>
                onChangeInfo('buttonBackgroundColor', e.target.value)
              }
            />
            <ColorSample color={banner.info.buttonBackgroundColor} />
          </Form.Field>
        </S.RowContainer>
        <S.RowContainer margin={'20px 0 0 0'}>
          <S.RowFormField>
            <label>
              리워드 종류 (인당 페이백 / 페이백 / 인당 특별상금 / 특별상금 중 택
              1)
            </label>
            <input
              name="rewardType"
              defaultValue={banner.info.rewardType}
              onChange={(e) => onChangeInfo('rewardType', e.target.value)}
            />
          </S.RowFormField>
          <S.RowFormField margin={'0 0 0 10px'}>
            <label>리워드 금액 (0.0만원 / 000만원)</label>
            <input
              name="rewardAmount"
              defaultValue={banner.info.rewardAmount}
              onChange={(e) => onChangeInfo('rewardAmount', e.target.value)}
            />
          </S.RowFormField>
        </S.RowContainer>
        <S.RowContainer>
          <Form.Field>
            <label>리워드 종류 글자색</label>
            <input
              name="rewardTypeTextColor"
              defaultValue={banner.info.rewardTypeTextColor}
              onChange={(e) =>
                onChangeInfo('rewardTypeTextColor', e.target.value)
              }
            />
            <ColorSample color={banner.info.rewardTypeTextColor} />
          </Form.Field>
          <Form.Field style={{ marginLeft: 10 }}>
            <label>리워드 금액 글자색</label>
            <input
              name="rewardAmountTextColor"
              defaultValue={banner.info.rewardAmountTextColor}
              onChange={(e) =>
                onChangeInfo('rewardAmountTextColor', e.target.value)
              }
            />
            <ColorSample color={banner.info.rewardAmountTextColor} />
          </Form.Field>
        </S.RowContainer>

        <S.RowFormField>
          <label>* 이미지 (사이즈 375 * 120) (비율만 맞으면 됨)</label>
          <input name="imageUrl" type="file" onChange={onChangeSingleFile} />
          <Image src={banner.imageUrl} size="medium" />
        </S.RowFormField>
      </div>
    </>
  );
};

export default ChallengeCategoryForm;
