import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { handleChangeMultiFiles } from './uploadImage';

const dataURItoBlob = (dataURI: string) => {
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
};

const cropImage = async (image: HTMLImageElement, cropSize: number) => {
  const { width: imageWidth, height: imageHeight } = image;
  const files = [];
  let cropY = 0;
  let cropHeight = cropSize;
  const cropX = 0;
  const cropWidth = cropSize;
  const count = Math.ceil(imageHeight / imageWidth);
  for (let i = 0; i < count; i++) {
    const canvas = document.createElement('canvas');
    canvas.width = cropWidth;
    cropHeight = Math.min(cropHeight, imageHeight - cropY);
    canvas.height = cropHeight;

    const ctx = canvas.getContext('2d');
    if (!ctx) continue;
    ctx.drawImage(
      image,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight,
    );
    const url = canvas.toDataURL('image/jpeg');
    const file = dataURItoBlob(url);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    file.name = `${i}.jpg`;
    files.push(file);
    cropY += cropHeight;
  }
  return files;
};

export const cropAndUploadImage = async (file: File) => {
  const name = 'temp';
  const urls: string[] = [];
  // 파일 업로드
  const image = document.createElement('img');
  image.src = URL.createObjectURL(file);
  try {
    LoadingIndicator.show();
    await new Promise<void>((resolve) => {
      image.onload = async () => {
        const files = await cropImage(image, image.width);
        const temp = await handleChangeMultiFiles(
          { target: { files, name } },
          {},
        );
        temp[name].forEach((url: string) => urls.push(url));
        resolve();
      };
    });
  } catch (e) {
    console.log(e);
  } finally {
    LoadingIndicator.hide();
  }

  return urls;
};
