import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Button,
  Input,
  Loader,
  Pagination,
} from 'semantic-ui-react';
import GoalItemTable from './GoalItemTable';
import { apis } from '@api/index';
import GoalMenu from './GoalMenu';
import { GoalDetailInfo } from '@types';
import FlexBox from '@component/FlexBox/FlexBox';

const PAGE_LIMIT = 15;

const Goal = () => {
  const [goals, setGoals] = useState<GoalDetailInfo[]>([]);
  const [isMounted, setIsMounted] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');

  const _getGoals = async () => {
    apis.challenge
      .getGoals({
        offset: PAGE_LIMIT * (page - 1),
        limit: PAGE_LIMIT,
        keyword: searchKeyword,
      })
      .then(({ goals: _goals, total }) => {
        setGoals(_goals);
        setTotalCount(total);
      })
      .finally(() => {
        setIsMounted(true);
      });
  };

  // page, searchKeyword 변경시, 필요한 데이터
  useEffect(() => {
    _getGoals();
  }, [page]);

  const deleteGoal = (goalIds: number[]) => {
    const newGoals = goals.map((g) => {
      if (goalIds.includes(g.id)) {
        return { ...g, isDeleted: true, isChecked: false };
      }
      return g;
    });
    setGoals(newGoals);
  };

  const copyGoal = (copyGoals: GoalDetailInfo[], checkedIds: number[]) => {
    const _goals = goals.map((g) => {
      if (checkedIds.includes(g.id)) {
        return { ...g, isChecked: false };
      }
      return g;
    });
    copyGoals.forEach((c) => {
      _goals.unshift(c);
    });

    setGoals(_goals);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      _getGoals();
    }
  };

  const searchGoals = async () => {
    setIsMounted(false);
    _getGoals();
  };

  if (!goals) return <Container>No goals</Container>;

  return (
    <div style={{ margin: 40 }}>
      <Grid columns="equal">
        <Grid.Column width={2}>
          <GoalMenu />
        </Grid.Column>
        <Grid.Column>
          {!isMounted && <Loader active inline={'centered'} />}
          <Grid.Row>
            <div>전체 목표 개수 : {totalCount.toLocaleString()}</div>
            <Input
              size="small"
              name="keyword"
              placeholder="목표명을 입력하세요"
              onChange={(_, { value }) => setSearchKeyword(value)}
              onKeyPress={handleKeyPress}
            />
            <Button size="small" onClick={searchGoals}>
              검색
            </Button>
            <Button as={Link} to={{ pathname: '/goals/create' }}>
              목표 추가 ➕
            </Button>
          </Grid.Row>
          <FlexBox.Column style={{ overflow: 'auto' }}>
            <GoalItemTable goals={goals} copy={copyGoal} delete={deleteGoal} />
            <Pagination
              defaultActivePage={1}
              totalPages={Math.ceil(totalCount / PAGE_LIMIT)}
              onPageChange={(_, { activePage }) => {
                setPage(activePage as number);
              }}
            />
          </FlexBox.Column>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Goal;
