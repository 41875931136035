import React, { useState } from 'react';
import {
  Accordion,
  Button,
  Checkbox,
  Header,
  Icon,
  Table,
} from 'semantic-ui-react';
import moment from 'moment';
import Workbook from 'react-excel-workbook';

const ChallengeReview = ({
  reviews,
  handleDisplayChange,
  refreshCacheForReviews,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const reviewList = reviews.map((r) => (
    <Table.Row key={r.id}>
      <Table.Cell collapsing>{r.userId}</Table.Cell>
      <Table.Cell collapsing>{r.nickname}</Table.Cell>
      <Table.Cell collapsing>
        {moment(r.createdAt).format('YYYY-MM-DD HH:mm:ss')}
      </Table.Cell>
      <Table.Cell collapsing>{r.rate}</Table.Cell>
      <Table.Cell>{r.comment}</Table.Cell>
      <Table.Cell>
        <Checkbox
          checked={r.isDisplayed}
          value={r.id}
          onChange={handleDisplayChange}
        />
      </Table.Cell>
    </Table.Row>
  ));

  return (
    <Accordion>
      <Accordion.Title
        active={!collapsed}
        index={4}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Header as="h3">
          <Icon name="dropdown" />
          챌린지 리뷰
          <Button
            floated={'right'}
            size={'mini'}
            content={'리뷰 캐시 초기화'}
            onClick={refreshCacheForReviews}
          />
          <Workbook
            filename="ChallengeUser.xlsx"
            element={
              <Button basic color="green" floated="right" size="mini">
                Excel
              </Button>
            }
          >
            <Workbook.Sheet data={reviews} name="Sheet A">
              <Workbook.Column label="user_id" value={(r) => r.userId} />
              <Workbook.Column label="닉네임" value={(r) => r.nickname} />
              <Workbook.Column
                label="작성일"
                value={(r) => moment(r.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              />
              <Workbook.Column
                label="점수"
                value={(r) => (r.rate > 0 ? r.rate : '0')}
              />
              <Workbook.Column label="작성내용" value={(r) => r.comment} />
            </Workbook.Sheet>
          </Workbook>
        </Header>
      </Accordion.Title>
      <Accordion.Content active={!collapsed} style={{ marginLeft: 20 }}>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>user_id</Table.HeaderCell>
              <Table.HeaderCell>닉네임</Table.HeaderCell>
              <Table.HeaderCell>작성일</Table.HeaderCell>
              <Table.HeaderCell>점수</Table.HeaderCell>
              <Table.HeaderCell>작성내용</Table.HeaderCell>
              <Table.HeaderCell>노출여부</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{reviewList}</Table.Body>
        </Table>
      </Accordion.Content>
    </Accordion>
  );
};

export default ChallengeReview;
