import NumberInput from '@component/NumberInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { useUserId, useUserIdActions } from './UserIdForm.store';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './UserIdForm.serializer';
import { validate } from './UserIdForm.validator';

const UserIdForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'userId'>,
  Pick<ChallengeFormType, 'userId'>,
  Pick<ReqCreateOrUpdateChallenge, 'userId'>
> = () => {
  const { userId } = useUserId();
  const { setUserId } = useUserIdActions();
  const validated = validate({ userId });

  return (
    <NumberInput
      label="유저 id (공식챌린지일 경우 1, 개설챌린지의 경우 개설자의 user id 입력)"
      name="userId"
      value={userId}
      onChange={(v) => setUserId(v ?? 1)}
      errorMsg={validated.isValid ? '' : validated.message}
    />
  );
};

export default UserIdForm;

UserIdForm.validate = validate;
UserIdForm.convertData2Form = convertData2Form;
UserIdForm.convertForm2Data = convertForm2Data;
