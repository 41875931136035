import FlexBox from '@component/FlexBox/FlexBox';
import ImageInput from '@component/ImageInput';
import TextArea from '@component/TextArea';
import { Divider } from 'semantic-ui-react';
import {
  DescriptionCollaboTopSummaryModuleFormType,
  DescriptionModuleFormType,
} from '../../../../NewChallengeForm.types';
import {
  DescriptionModule,
  DescriptionModuleConvertData2Form,
  DescriptionModuleConvertForm2Data,
} from '../../DescriptionModuleForm.type';
import { DEFAULT_DATA } from './CollaboTopSummary.constants';
import {
  convertData2Form,
  convertForm2Data,
} from './CollaboTopSummary.serializer';
import { validate } from './CollaboTopSummary.validator';

type Props = {
  type: 'COLLABO-TOP-SUMMARY';
  data: DescriptionCollaboTopSummaryModuleFormType['data'];
  moduleIndex: number;
  onUpdate: (index: number, item: DescriptionModuleFormType) => void;
};
export const CollaboTopSummary: DescriptionModule<Props> = ({
  type,
  data,
  moduleIndex,
  onUpdate,
}) => {
  const handleUpdate = (
    updated: Partial<DescriptionCollaboTopSummaryModuleFormType['data']>,
  ) => {
    onUpdate(moduleIndex, {
      type,
      data: { ...data, ...updated },
    });
  };

  return (
    <FlexBox.Column style={{ padding: 20, border: '1px solid #999' }}>
      <TextArea
        label="챌린지 요약 메인헤더"
        placeholder="메인 헤더(최대 2줄, 1줄당 20자 이내)"
        value={data.mainHeader}
        onChange={(mainHeader) => handleUpdate({ mainHeader })}
      />
      <Divider />
      <TextArea
        label="챌린지 요약 서브헤더"
        placeholder="서브 헤더(최대 2줄, 1줄당 20자 이내)"
        value={data.subHeader}
        onChange={(subHeader) => handleUpdate({ subHeader })}
      />
      <Divider />
      <ImageInput
        label="챌린지 요약 로고이미지"
        uploadOnChange
        value={data.logoImage}
        onChange={(logoImage) => handleUpdate({ logoImage })}
        showPreview
        previewSize={140}
      />
      <Divider />
      <ImageInput
        label="챌린지 요약 이미지"
        uploadOnChange
        value={data.image}
        onChange={(image) => handleUpdate({ image })}
        showPreview
        previewSize={140}
      />
    </FlexBox.Column>
  );
};

CollaboTopSummary.moduleName = '최상단 요약';
CollaboTopSummary.defaultData = DEFAULT_DATA;
CollaboTopSummary.validate = validate;
CollaboTopSummary.convertData2Form =
  convertData2Form as DescriptionModuleConvertData2Form;
CollaboTopSummary.convertForm2Data =
  convertForm2Data as DescriptionModuleConvertForm2Data;
