import React from 'react';
import { Button, Image, Table } from 'semantic-ui-react';
import { newEventType } from '../../../constant/eventType';
import moment from 'moment';

const EventList = ({ events, goToEventEditPage, deleteEvent, copyEvent }) => {
  return (
    <Table basic="very" size="small">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>id</Table.HeaderCell>
          <Table.HeaderCell>종류</Table.HeaderCell>
          <Table.HeaderCell>이벤트명</Table.HeaderCell>
          <Table.HeaderCell>노출시작일</Table.HeaderCell>
          <Table.HeaderCell>노출종료일</Table.HeaderCell>
          <Table.HeaderCell>og image</Table.HeaderCell>
          <Table.HeaderCell>og title</Table.HeaderCell>
          <Table.HeaderCell>og description</Table.HeaderCell>
          <Table.HeaderCell>수정/삭제</Table.HeaderCell>
          <Table.HeaderCell>복사</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {events?.map((item) => {
          return (
            <Table.Row key={item.id}>
              <Table.Cell>{item.id}</Table.Cell>
              <Table.Cell collapsing>
                {item.data.type ? newEventType[item.data.type] : ''}
              </Table.Cell>
              <Table.Cell>{item.eventName}</Table.Cell>
              <Table.Cell collapsing>
                {moment(item.startTime).format('YYYY-MM-DD')}
              </Table.Cell>
              <Table.Cell collapsing>
                {moment(item.endTime).format('YYYY-MM-DD')}
              </Table.Cell>
              <Table.Cell>
                {!!item.data?.og?.image ? (
                  <Image src={item.data?.og?.image} size="small" />
                ) : (
                  '등록된 이미지가 없어요'
                )}
              </Table.Cell>
              <Table.Cell>{item.data?.og?.title}</Table.Cell>
              <Table.Cell>{item.data?.og?.description}</Table.Cell>
              <Table.Cell>
                <Button
                  size="mini"
                  color="blue"
                  content="수정"
                  value={item}
                  onClick={() => goToEventEditPage(item.id)}
                />
                <Button
                  size="mini"
                  color="black"
                  content="삭제"
                  value={item.id}
                  onClick={() => deleteEvent(item.id)}
                />
              </Table.Cell>
              <Table.Cell>
                <Button
                  size="mini"
                  color="grey"
                  content="복사"
                  value={item}
                  onClick={() => copyEvent(item.id)}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default EventList;
