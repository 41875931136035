import React from 'react';
import { Button, Feed } from 'semantic-ui-react';
import moment from 'moment';
import resizeImage from '../../../../global/resizeImage';
import { chlngersUrl } from '../../../../constant/imgUrls';

export const AchievementComment = ({ comment, deleteComment }) => {
  let userUrl =
    comment.user.pictureUrl === '' ? chlngersUrl : comment.user.pictureUrl;
  userUrl = resizeImage(userUrl, 100);
  const list = comment.subComments.map((subComment) => {
    let subCommentUserUrl =
      subComment.user.pictureUrl === ''
        ? chlngersUrl
        : subComment.user.pictureUrl;
    subCommentUserUrl = resizeImage(subCommentUserUrl, 100);
    return (
      <Feed.Event key={subComment.id}>
        <Feed.Label image={subCommentUserUrl} />
        <Feed.Content>
          <Feed.Summary>
            <Feed.User>{subComment.user.nickname}</Feed.User>
            <Feed.Date>
              {moment(subComment.createdAt)
                .add(9, 'hours')
                .format('YYYY-MM-DD HH:mm:ss')}
            </Feed.Date>
            {subComment.isDeleted && (
              <Button floated="right" size="mini" disabled>
                삭제됨
              </Button>
            )}
            {!subComment.isDeleted && (
              <Button
                floated="right"
                size="mini"
                value={subComment.id}
                onClick={deleteComment}
              >
                삭제
              </Button>
            )}
          </Feed.Summary>
          <Feed.Extra text>{subComment.comment}</Feed.Extra>
        </Feed.Content>
      </Feed.Event>
    );
  });
  return (
    <Feed.Event>
      <Feed.Label image={userUrl} />
      <Feed.Content>
        <Feed.Summary>
          <Feed.User>{comment.user.nickname}</Feed.User>
          <Feed.Date>
            {moment(comment.createdAt)
              .add(9, 'hours')
              .format('YYYY-MM-DD HH:mm:ss')}
          </Feed.Date>
          {comment.isDeleted && (
            <Button floated="right" size="mini" disabled>
              삭제됨
            </Button>
          )}
          {!comment.isDeleted && (
            <Button
              floated="right"
              size="mini"
              value={comment.id}
              onClick={deleteComment}
            >
              삭제
            </Button>
          )}
        </Feed.Summary>
        <Feed.Extra text>{comment.comment}</Feed.Extra>
        <Feed>{list}</Feed>
      </Feed.Content>
    </Feed.Event>
  );
};

export default AchievementComment;
