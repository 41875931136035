import ChipInput from '@component/ChipInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './AdminUserIdsForm.serializer';
import {
  useAdminUserIds,
  useAdminUserIdsActions,
} from './AdminUserIdsForm.store';
import { validate } from './AdminUserIdsForm.validator';

const AdminUserIdsForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'adminUserIds'>,
  Pick<ChallengeFormType, 'adminUserIds'>,
  Pick<ReqCreateOrUpdateChallenge, 'adminUserIds'>
> = () => {
  const { adminUserIds } = useAdminUserIds();
  const { setAdminUserIds } = useAdminUserIdsActions();
  const validated = validate({ adminUserIds });

  return (
    <ChipInput
      label="관리자 유저 id 목록"
      placeholder="유저를 추가/삭제해주세요."
      chipList={adminUserIds.map((id) => String(id))}
      onChange={(data) => setAdminUserIds(data.map((v) => Number(v)))}
      errorMsg={validated.isValid ? '' : validated.message}
    />
  );
};

export default AdminUserIdsForm;

AdminUserIdsForm.validate = validate;
AdminUserIdsForm.convertData2Form = convertData2Form;
AdminUserIdsForm.convertForm2Data = convertForm2Data;
