import { apis } from '@api/index';
import { reviewMessageOptions, reviewOptions } from 'src/constant/reviewResult';
import React, { useState } from 'react';
import {
  Button,
  Dimmer,
  Dropdown,
  Form,
  Loader,
  Modal,
  TextArea,
} from 'semantic-ui-react';
import { UserAchievementInfo } from 'src/types/user.types';

type ReviewModalProps = {
  achievement: UserAchievementInfo['achievements'][0];
  reviewModalOpen: boolean;
  closeReviewModal: () => void;
};

const ReviewModal = ({
  achievement,
  reviewModalOpen,
  closeReviewModal,
}: ReviewModalProps) => {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [review, setReview] = useState('');
  const [reviewComment, setReviewComment] = useState('');

  const handleReviewResult = async (isPushNeeded: boolean) => {
    if (isLoading) return;
    setIsLoading(true);

    await apis.reviewAchievementFile(achievement.achievementFile.id, {
      reviewResult: review,
      reviewComment,
      isPushNeeded,
    });
    setIsLoading(false);
    closeReviewModal();
  };

  const handleChangeMessage = (e: any, { value }: any) => {
    setMessage(value);
  };

  const handleReviewOptionChange = (e: any, { value }: any) => {
    setReview(value);
  };

  const handleChangeReviewComment = (e: any) => {
    setReviewComment(e.target.value);
  };

  return (
    <Modal size="tiny" open={reviewModalOpen} onClose={closeReviewModal}>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>

      <Modal.Header>인증샷 리뷰하기</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>메세지 템플릿</label>
            <Dropdown
              placeholder="템플릿을 선택하세요."
              fluid
              search
              selection
              name="messageId"
              value={message}
              options={reviewMessageOptions}
              onChange={handleChangeMessage}
            />
          </Form.Field>
          <Form.Field>
            <label>리뷰 결과</label>
            <Dropdown
              placeholder="리뷰 결과를 선택하세요.."
              name="review"
              selection
              options={reviewOptions}
              value={review}
              onChange={handleReviewOptionChange}
            />
          </Form.Field>
          <Form.Field>
            <label>코멘트</label>
            <TextArea
              rows={6}
              name="reviewComment"
              value={reviewComment}
              onChange={handleChangeReviewComment}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="blue"
          content="제출"
          onClick={() => handleReviewResult(false)}
        />
        <Button
          color="blue"
          content="제출(푸시)"
          onClick={() => handleReviewResult(true)}
        />
        <Button color="black" content="닫기" onClick={closeReviewModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default ReviewModal;
