import { useShallow } from 'zustand/react/shallow';
import { swap } from '@utils/js.utils';
import { useCallback } from 'react';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { DescriptionModuleFormType } from '../../NewChallengeForm.types';

export const useDescriptionModule = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      descriptionModule: s.challenge.descriptionModule || [],
    })),
  );

export const useDescriptionModuleAction = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const addDescriptionModule = useCallback(
    (newItem: DescriptionModuleFormType) => {
      updateChallengeForm((prev) => {
        return {
          ...prev,
          descriptionModule: [...prev.descriptionModule, newItem],
        };
      });
    },
    [updateChallengeForm],
  );

  const removeDescriptionModule = useCallback(
    (index: number) => {
      updateChallengeForm((prev) => {
        return {
          ...prev,
          descriptionModule: prev.descriptionModule.filter(
            (_, i) => i !== index,
          ),
        };
      });
    },
    [updateChallengeForm],
  );

  const swapDescriptionModule = useCallback(
    (aIndex: number, bIndex: number) => {
      updateChallengeForm((prev) => {
        const totalLength = prev.descriptionModule.length;
        if (aIndex < 0 || aIndex >= totalLength) return prev;
        if (bIndex < 0 || bIndex >= totalLength) return prev;

        return {
          ...prev,
          descriptionModule: swap(prev.descriptionModule, aIndex, bIndex),
        };
      });
    },
    [updateChallengeForm],
  );

  const updateDescriptionModule = useCallback(
    (
      index: number,
      item:
        | ((prev: DescriptionModuleFormType[]) => DescriptionModuleFormType[])
        | DescriptionModuleFormType,
    ) => {
      if (typeof item === 'function') {
        updateChallengeForm((prev) => {
          const result = item(prev.descriptionModule);
          return {
            ...prev,
            descriptionModule: result,
          };
        });
        return;
      }
      updateChallengeForm((prev) => {
        const updatedDescriptionModule = [...prev.descriptionModule];
        updatedDescriptionModule[index] = item;
        return {
          ...prev,
          descriptionModule: updatedDescriptionModule,
        };
      });
    },
    [updateChallengeForm],
  );

  return {
    addDescriptionModule,
    removeDescriptionModule,
    swapDescriptionModule,
    updateDescriptionModule,
  };
};
