import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'publicType' | 'achievementInfoList'>;

/**
 * 1. achievementInfoList.length == 0 -> publicType : PARTICIPANT || SECRET
 * 2. achievementInfoList의 아이템에 isPublic field 가 전부 false | undefined => publicType : SECRET
 * 3. achievementInfoList의 아이템에 isPublic field가 전부 true => publicType : PARTICIPANT
 * 4. 나머지 publicType : PARTIAL
 */
export const validate: FormValidator<Params> = ({
  publicType,
  achievementInfoList,
}) => {
  if (!achievementInfoList.length) {
    if (publicType === 'PARTIAL')
      return {
        isValid: false,
        message:
          "[인증샷 공개설정] 회차인증이 없는 경우, '참가자만(전체공개)' 혹은 '나만(유저 본인만)' 으로 설정되어야합니다",
      };
    return { isValid: true };
  }
  if (
    achievementInfoList.every((info) => Boolean(info.isPublic)) &&
    publicType !== 'PARTICIPANT'
  )
    return {
      isValid: false,
      message:
        "[인증샷 공개설정] 회차인증이 전부 공개 설정일 경우, '참가자만(전체공개)'으로 설정되어야 합니다",
    };
  if (
    achievementInfoList.every((info) => Boolean(!info.isPublic)) &&
    publicType !== 'SECRET'
  )
    return {
      isValid: false,
      message:
        "[인증샷 공개설정] 회차인증이 전부 비공개 설정일 경우, '나만(유저 본인만)'으로 설정되어야 합니다",
    };

  return { isValid: true };
};
