import React from 'react';
import { Race } from '@types';
import {
  RaceFormDescriptionModule,
  RaceFormHomeModule,
  RaceFormType,
} from '../../RaceForm.types';
import _ from 'lodash';
import VideoModule from './modules/VideoModule';
import CouponModule from './modules/CouponModule';
import CouponWithPriceModule from './modules/CouponWithPriceModule';
import FullImageModule from './modules/FullImageModule';
import TemporaryImageModule from './modules/TemporarayImageModule';
import TopRanksModule from './modules/TopRanksModule';
import UserAchievementsModule from './modules/UserAchievementsModule';
import RegisterInfoModule from './modules/RegisterInfoModule';
import HyperlinkModule from './modules/HyperlinkModule';
import GoodsModule from './modules/GoodsModule';
import LeagueImageRecordModule from './modules/LeagueImageRecordModule';
import ContentsCardModule from './modules/ContentsCardModule';
import OnboardingModule from './modules/OnboradingModule';
import SubBannerModule from './modules/SubBannerModule';
import ShareBoardModule from './modules/ShareBoardModule';
import ReviewModule from './modules/ReviewModule';
import ReadMeModule from './modules/ReadMeModule';
import CouponListModule from './modules/CouponListModule';

const DescriptionModuleField = ({
  idx,
  race,
  setRace,
  module,
  moduleKey,
  handleChange,
  handleRouteChange,
  handleCardListChange,
  addCard,
  deleteCard,
  handleSubBannerImageChange,
}: {
  idx: number;
  race: RaceFormType;
  setRace: React.Dispatch<React.SetStateAction<RaceFormType>>;
  module: RaceFormDescriptionModule | Race.RaceHomeModule;
  moduleKey:
    | 'homeModule'
    | 'registerInfoDescriptionModule'
    | 'progressInfoDescriptionModule';
  handleChange:
    | (<K extends keyof RaceFormDescriptionModule>(
        idx: number,
        key: K,
        value: RaceFormDescriptionModule[K],
      ) => void)
    | (<K extends keyof RaceFormHomeModule>(
        idx: number,
        key: K,
        value: RaceFormHomeModule[K],
      ) => void);
  handleRouteChange?: <K extends never>(
    idx: number,
    {
      key,
      value,
    }:
      | {
          key: 'type';
          value: 'APP_EVENT_SCREEN' | 'WEB' | 'RaceRegisterScreen';
        }
      | {
          key: K;
          value: (
            | Race.AppEventScreenRouteParams
            | Race.WebRouteParams
            | Race.RaceRegisterScreenRouteParams
          )[K];
        },
  ) => void;
  handleCardListChange?: <K extends keyof Race.RaceHomeContentsCardModuleCard>(
    moduleIdx: number,
    cardIdx: number,
    key: K,
    value: Race.RaceHomeContentsCardModuleCard[K],
  ) => void;
  addCard?: (idx: number) => void;
  deleteCard?: (moduleIdx: number, cardIdx: number) => void;
  handleSubBannerImageChange?: (idx: number, e: any) => Promise<void>;
}) => {
  switch (module.type) {
    case 'ORIGIN-IMAGE':
      return (
        <FullImageModule
          idx={idx}
          module={module}
          handleChange={handleChange}
        />
      );
    case 'TEMPORARY-ORIGIN-IMAGE':
      return (
        <TemporaryImageModule
          idx={idx}
          module={module}
          handleChange={handleChange}
        />
      );
    case 'TOP_RANKS':
      return <TopRanksModule />;
    case 'USER_ACHIEVEMENTS':
      return <UserAchievementsModule />;
    case 'REGISTER-INFO':
      return (
        <RegisterInfoModule
          idx={idx}
          module={module}
          handleChange={handleChange}
        />
      );
    case 'HYPERLINK':
      return (
        <HyperlinkModule
          idx={idx}
          module={module}
          handleChange={handleChange}
          handleRouteChange={handleRouteChange}
        />
      );
    case 'GOODS':
      return (
        <GoodsModule
          idx={idx}
          module={module}
          race={race}
          setRace={setRace}
          moduleKey={moduleKey}
        />
      );
    case 'SHARE-BOARD':
      return (
        <ShareBoardModule
          idx={idx}
          module={module}
          handleChange={handleChange}
        />
      );
    case 'LEAGUE-IMAGE-RECORDS':
      return <LeagueImageRecordModule />;
    case 'CONTENTS-CARDS':
      return (
        <ContentsCardModule
          idx={idx}
          module={module}
          handleChange={handleChange}
          handleCardListChange={handleCardListChange!}
          addCard={addCard!}
          deleteCard={deleteCard!}
        />
      );
    case 'ONBOARDING':
      return <OnboardingModule />;
    case 'SUB_BANNER':
      return (
        <SubBannerModule
          idx={idx}
          module={module}
          handleSubBannerImageChange={handleSubBannerImageChange!}
        />
      );
    case 'REVIEW':
      return <ReviewModule />;
    case 'README':
      return <ReadMeModule />;
    case 'VIDEO':
      return (
        <VideoModule idx={idx} module={module} handleChange={handleChange} />
      );
    case 'COUPON':
      return <CouponModule />;
    case 'COUPON-WITH-PRICE':
      return <CouponWithPriceModule />;
    case 'COUPON-LIST':
      return (
        <CouponListModule
          idx={idx}
          module={module}
          handleChangeCouponIds={handleChange}
          handleChangeBgColor={handleChange}
        />
      );
    default:
      return null;
  }
};

export default DescriptionModuleField;
