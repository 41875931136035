export const MAX_REGISTER_DEPOSIT = 200000;

export const COLLABO_TAGS = {
  OLIVEYOUNG_ID: 13539,
  NAVER_ID: 13748,
};

export const initialAuthenticationChecklist = [
  '앱 (접속) 오류 등과 같은 사유로 기간 내 인증샷 등록이 어려운 경우 인증 패스 처리되며, 페이백 지급되지 않습니다. 이 경우 건강한 챌린지 경험을 제공해 드리기 위해 오류 없는 기기 사용을 권장 드립니다.',
];
