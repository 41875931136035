import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

export const validate: FormValidator<
  Pick<ChallengeFormType, 'imageFilter' | 'distance'>
> = ({ imageFilter, distance }) => {
  const findImageIndex =
    imageFilter.find((v) =>
      v.info.some((d) => d.type === 'accumulated_distance'),
    )?.achievementIndex ?? -1;

  if (imageFilter.length && findImageIndex >= 0 && !distance.length) {
    return {
      isValid: false,
      message: `${findImageIndex}회차에 누적 거리 필터를 사용하고 싶다면 커스텀 이미지 필터 설정 위의 거리 정보 설정을 먼저 세팅해주세요.`,
    };
  }

  const findTextIndex =
    imageFilter.find((v) =>
      v.info.some((d) => d.type === 'text' && d.data.text === ''),
    )?.achievementIndex ?? -1;

  if (imageFilter.length && findTextIndex >= 0) {
    return {
      isValid: false,
      message: `${findTextIndex}회차 내에 텍스트 필터 선택 후 텍스트를 입력하지 않았습니다.`,
    };
  }

  const findTimeStampIndex2 =
    imageFilter.find((v) =>
      v.info.some(
        (d) =>
          d.type === 'timestamp' &&
          d.data.backgroundColor &&
          d.data.backgroundColor.length < 9,
      ),
    )?.achievementIndex ?? -1;

  if (imageFilter.length && findTimeStampIndex2 >= 0) {
    return {
      isValid: false,
      message: `${findTimeStampIndex2}회차 내 타임스탬프 필터의 배경 컬러에 투명도가 들어가야 합니다.`,
    };
  }

  return { isValid: true };
};
