import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'labels'>,
  Pick<ChallengeFormType, 'labelIds'>
> = ({ labels }) => ({ labelIds: labels?.map(({ id }) => id) || [] });

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'labelIds'>,
  Pick<ReqCreateOrUpdateChallenge, 'labelIds'>
> = ({ labelIds }) => ({ labelIds });
