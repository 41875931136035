import React, { Component } from 'react';
import { Container, Button, Table, Checkbox, Header } from 'semantic-ui-react';
import { apis } from '../../../api';
import _ from 'lodash';
import { Link } from 'react-router-dom';

class ChallengeCuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chllengeCuration: {},
      challengeCurations: [],
    };
  }

  async componentDidMount() {
    const res = await apis.getChallengeCurations();
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    const { challengeCurations } = res;
    const challengeCuration = _.filter(
      challengeCurations,
      (cm) => cm.isDisplayed === true,
    );
    this.setState({ challengeCurations, challengeCuration });
  }

  handleDisplayChange = async (e, { value }) => {
    const { challengeCurations } = this.state;
    const newChallengeCurations = _.map(challengeCurations, (c) => {
      c.isDisplayed = c.id === value;
      return c;
    });
    const res = await apis.updateChallengeCuration(value, {
      isDisplayed: true,
    });
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    this.setState({ challengeCurations: newChallengeCurations });
  };
  copyChallengeCuration = async (e) => {
    const { challengeCurations } = this.state;
    const res = await apis.copyChallengeCuration(e.target.value);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    const { challengeCuration } = res;
    challengeCurations.push(challengeCuration);
    this.setState({ challengeCurations });
  };
  deleteChallengeCuration = async (e) => {
    const { challengeCurations } = this.state;
    const challengeCuration = _.find(
      challengeCurations,
      (c) => c.id === parseInt(e.target.value, 10),
    );
    if (challengeCuration.isDisplayed === true)
      alert('공개된 큐레이션 템플릿은 제거할 수 없습니다.');
    else {
      const res = await apis.deleteChallengeCuration(e.target.value);
      if (res?.response?.data && 'err' in res?.response?.data) {
        return;
      }

      const _challengeCurations = challengeCurations.filter(
        (o) => o.id !== Number(e.target.value),
      );
      this.setState({ challengeCurations: _challengeCurations });
    }
  };

  render() {
    const { challengeCurations } = this.state;
    return (
      <Container>
        <Header as="h3" style={{ marginBottom: 30 }}>
          챌린지 큐레이션
          <Button
            floated="right"
            as={Link}
            to={{ pathname: '/challenges/curations/create' }}
          >
            큐레이션 추가
          </Button>
        </Header>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>타이틀</Table.HeaderCell>
              <Table.HeaderCell>서브타이틀</Table.HeaderCell>
              <Table.HeaderCell>노출여부</Table.HeaderCell>
              <Table.HeaderCell>편집</Table.HeaderCell>
              <Table.HeaderCell>복사</Table.HeaderCell>
              <Table.HeaderCell>삭제</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {challengeCurations.map((c) => (
              <Table.Row key={c.id}>
                <Table.Cell>{c.id}</Table.Cell>
                <Table.Cell>{c.title}</Table.Cell>
                <Table.Cell>{c.subtitle}</Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={c.isDisplayed}
                    value={c.id}
                    onChange={this.handleDisplayChange}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Button
                    size="tiny"
                    as={Link}
                    to={{
                      pathname: '/challenges/curations/' + c.id + '/edit',
                      state: { challengeCuration: c },
                    }}
                  >
                    편집
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button
                    size="tiny"
                    value={c.id}
                    onClick={this.copyChallengeCuration}
                  >
                    복사
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button
                    size="tiny"
                    value={c.id}
                    onClick={this.deleteChallengeCuration}
                  >
                    삭제
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

export default ChallengeCuration;
