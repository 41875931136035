import { ChallengeCustomField } from 'src/types/customField.types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'cancelImpossible'>,
  Extract<ChallengeCustomField, { name: 'cancelImpossible' }>
> = ({ cancelImpossible }) => {
  if (!cancelImpossible) return [];

  return [{ name: 'cancelImpossible', values: !!cancelImpossible }];
};

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'cancelImpossible'>
> = (customFields) => {
  const cancelImpossible = customFields.find(
    ({ name }) => name === 'cancelImpossible',
  );

  return { cancelImpossible: !!cancelImpossible };
};
