import { useEffect, useMemo, useRef, useState } from 'react';
import {
  DescriptionModuleFormType,
  SpecialPrizeModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import FlexBox from '@component/FlexBox/FlexBox';
import { isNull } from '@utils/js.utils';
import { useGetRestDays } from '@queries/common/useGetRestDays';
import { getDefault } from './SpecialPrizeModule.utils';
import TitleForm from './components/TitleForm/TitleForm';
import NoticeListForm from './components/NoticeListForm/NoticeListForm';
import {
  convertData2Form,
  convertForm2Data,
} from './SpecialPrizeModule.serializer';
import {
  DescriptionModule,
  DescriptionModuleConvertData2Form,
  DescriptionModuleConvertForm2Data,
} from '../../DescriptionModuleForm.type';
import { validate } from './SpecialPrizeModule.validator';
import { DEFAULT_DATA } from './SpecialPrizeModule.constant';
import { useSpecialPrizeModuleStore } from './SpecialPrizeModule.store';

type SpecialPrizeModuleProps = {
  type: 'COLLABO-SPECIAL-PRIZE';
  data: SpecialPrizeModuleFormType;
  moduleIndex: number;
  onUpdate: (index: number, item: DescriptionModuleFormType) => void;
};
const SpecialPrizeModule: DescriptionModule<SpecialPrizeModuleProps> = (
  props: SpecialPrizeModuleProps,
) => {
  const { type, data, moduleIndex, onUpdate } = props;
  const [initialNoticeList] = useState<string[]>(data.noticeList);
  const { specialPrize, resultDate } = useSpecialPrizeModuleStore();
  const { data: totalRestDays, isLoading: isRestDaysLoading } =
    useGetRestDays();
  const isInitialized = useRef<boolean>(false);
  const isLoading = !specialPrize.prizeType || !resultDate || isRestDaysLoading;
  const restDays = useMemo(() => {
    const now = new Date();
    return totalRestDays?.restDays.filter(
      (restDay) => now < new Date(restDay.date),
    );
  }, [totalRestDays]);

  useEffect(() => {
    if (!restDays) return;
    if (isNull(specialPrize.prizeType) || isNull(resultDate)) return;
    if (data.title !== null && data.type !== null) return;

    if (
      !isInitialized.current &&
      initialNoticeList.some((notice) => Boolean(notice))
    ) {
      isInitialized.current = true;
    } else
      onUpdate(moduleIndex, {
        type,
        data: getDefault(
          specialPrize.prizeType,
          new Date(resultDate),
          restDays,
        ),
      });
  }, [
    initialNoticeList,
    moduleIndex,
    onUpdate,
    restDays,
    resultDate,
    specialPrize.prizeType,
    type,
  ]);

  const handleTitle = (title: SpecialPrizeModuleFormType['title']) => {
    onUpdate(moduleIndex, { type, data: { ...data, title } });
  };

  const handleNoticeList = (noticeList: string[]) => {
    onUpdate(moduleIndex, { type, data: { ...data, noticeList } });
  };

  return (
    <FlexBox.Column
      style={{ border: '1px solid black', maxWidth: 720, padding: 12 }}
    >
      {isLoading ? null : (
        <>
          <TitleForm
            type={data.type}
            title={data.title}
            onChange={handleTitle}
          />

          <hr />

          <NoticeListForm
            noticeList={data.noticeList}
            onChange={handleNoticeList}
          />
        </>
      )}
    </FlexBox.Column>
  );
};

export default SpecialPrizeModule;

SpecialPrizeModule.moduleName = '특별상금';
SpecialPrizeModule.defaultData = DEFAULT_DATA;
SpecialPrizeModule.validate = validate;
SpecialPrizeModule.convertForm2Data =
  convertForm2Data as DescriptionModuleConvertForm2Data;
SpecialPrizeModule.convertData2Form =
  convertData2Form as DescriptionModuleConvertData2Form;
