import {
  ChallengeCustomField,
  ChallengeNaverBlogInfoListCustomField,
} from '@types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'naverBlogInfoList'>
> = (customFields) => {
  const naverBlogInfoList = customFields.find(
    (c) => c.name === 'naverBlogInfoList',
  );

  if (!naverBlogInfoList) return {} as EmptyObj;

  const { values } = naverBlogInfoList as ChallengeNaverBlogInfoListCustomField;

  return {
    naverBlogInfoList: values,
  };
};

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'naverBlogInfoList'>,
  Extract<ChallengeCustomField, { name: 'naverBlogInfoList' }>
> = ({ naverBlogInfoList }) => {
  if (naverBlogInfoList.length === 0) return [];

  return [
    {
      name: 'naverBlogInfoList',
      values: naverBlogInfoList,
    },
  ];
};
