import { Banner } from 'src/types/banner.types';

type OnGoingProps = Pick<Banner, 'title' | 'color' | 'imageUrl' | 'info'>;
type ClosedProps = Pick<Banner, 'title' | 'color' | 'info'>;

const OnGoing = ({ title, color, imageUrl, info }: OnGoingProps) => {
  if (imageUrl === '') {
    return <></>;
  }

  const {
    specialBadgeText,
    specialBadgeTextColor,
    specialBadgeBackgroundColor,
    collaborationBadgeText,
    collaborationBadgeTextColor,
  } = info;

  return (
    <div
      style={{
        height: '100%',
        padding: 15,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <div>
        <div
          style={{
            marginBottom: (specialBadgeText || collaborationBadgeText).length
              ? 4
              : 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {specialBadgeText && (
            <div
              style={{
                padding: 4,
                marginRight: 6,
                color: specialBadgeTextColor,
                fontSize: 12,
                borderRadius: 4,
                backgroundColor: specialBadgeBackgroundColor,
              }}
            >
              {specialBadgeText}
            </div>
          )}
          {collaborationBadgeText && (
            <div
              style={{
                inlineSize: 'max-content',
                color: collaborationBadgeTextColor,
                fontSize: 14,
              }}
            >
              {`${collaborationBadgeText} X 챌린저스`}
            </div>
          )}
        </div>
        {title && (
          <div
            style={{
              color,
              lineHeight: '20px',
              fontSize: 18,
              fontWeight: 700,
              whiteSpace: 'pre',
            }}
          >
            {title}
          </div>
        )}
      </div>
      {!!(title || specialBadgeText || collaborationBadgeText).length && (
        <div
          style={{
            width: 'fit-content',
            padding: '8px 8px 8px 12px',
            color: 'white',
            fontWeight: 600,
            borderRadius: '8px',
            backgroundColor: 'black',
          }}
        >
          {'바로가기 >'}
        </div>
      )}
    </div>
  );
};

const Closed = ({ title, color, info }: ClosedProps) => {
  const {
    closedImageUrl,
    specialBadgeText,
    specialBadgeTextColor,
    specialBadgeBackgroundColor,
    collaborationBadgeText,
    collaborationBadgeTextColor,
  } = info;

  if (closedImageUrl === '') {
    return <></>;
  }

  return (
    <div
      style={{
        padding: '6px 15px',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <div
        style={{
          marginBottom: (specialBadgeText || collaborationBadgeText).length
            ? 4
            : 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {specialBadgeText && (
          <div
            style={{
              padding: 4,
              marginRight: 6,
              color: specialBadgeTextColor,
              fontSize: 10,
              borderRadius: 4,
              backgroundColor: specialBadgeBackgroundColor,
            }}
          >
            {specialBadgeText}
          </div>
        )}
        {collaborationBadgeText && (
          <div
            style={{
              inlineSize: 'max-content',
              color: collaborationBadgeTextColor,
              fontSize: 12,
            }}
          >
            {`${collaborationBadgeText} X 챌린저스`}
          </div>
        )}
      </div>
      {title && (
        <div
          style={{
            color,
            lineHeight: '20px',
            fontSize: 14,
            fontWeight: 500,
            whiteSpace: 'pre',
          }}
        >
          {title}
        </div>
      )}
    </div>
  );
};

export default {
  OnGoing,
  Closed,
};
