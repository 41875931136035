import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Link,
  NavLink,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import { Button, Icon, Menu, MenuItemProps, Sidebar } from 'semantic-ui-react';
import { colors } from '../../constant/colors';
import NavigationThunk from './Navigation.thunk';
import { InitStore } from '../../store';

const menus = [
  {
    name: '데이터 라벨링',
    path: '/data-label',
    role: 'DATA-LABEL',
  },
  {
    name: '목표',
    path: '/goals',
    role: 'GOAL',
  },
  {
    name: '챌린지',
    path: '/challenges',
    role: 'GOAL',
  },
  {
    name: '커머스',
    path: '/commerces',
    role: 'COMMERCE',
  },
  {
    name: '게시판',
    path: '/boards',
    role: 'BOARD',
  },
  {
    name: '마케팅',
    path: '/banner',
    role: 'MARKETING',
  },
  {
    name: '랜선대회',
    path: '/race?orderBy=homecard',
    role: 'RACE',
  },
  {
    name: '제휴',
    path: '/collabo/blacklist',
    role: 'COLLABO',
  },
  {
    name: '혜택',
    path: '/offerwall',
    role: 'BENEFIT',
  },
  {
    name: '챌스토어',
    path: '/store/home-module',
    role: 'STORE',
  },
  {
    name: '세팅',
    path: '/sms-provider',
    role: 'SETTING',
  },
  {
    name: '태그',
    path: '/tags',
    role: 'TAG',
  },
  {
    name: '라벨',
    path: '/labels',
    role: 'LABEL',
  },
  {
    name: '통계',
    path: '/statistics',
    role: 'STATISTICS',
  },
  {
    name: '인증샷',
    path: '/achievements',
    role: 'ACHIEVEMENT',
  },
  {
    name: '문의대응',
    path: '/questions',
    role: 'QUESTION',
  },
  {
    name: '유저',
    path: '/users',
    role: 'USER',
  },
];

type Props = RouteComponentProps<object> & {
  logout: () => void;
  roles: string[];
  token: string;
  isNarrow: boolean;
};

type States = {
  logoutPending: boolean;
  pathname: string;
  visible: boolean;
};

const headerColor =
  process.env.REACT_APP_ENV_PHASE === 'STAGE'
    ? colors.BASIC_BLACK
    : colors.RD02;

class Navigation extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pathname: 'home',
      visible: false,
      logoutPending: false,
    };
  }

  componentDidMount() {
    const { pathname } = window.location;
    this.setState({ pathname });
  }

  handleItemClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { name }: MenuItemProps,
  ) => {
    if (!name) return;
    this.setState({ pathname: name });
  };

  onClickLogoutButton = () => {
    const { logoutPending } = this.state;
    const { logout } = this.props;
    if (logoutPending) return;
    this.setState({ logoutPending: true });
    logout();
    this.setState({ logoutPending: false });
  };

  toggleVisibility = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  render() {
    const { pathname, visible } = this.state;
    const { roles, token, isNarrow } = this.props;
    if (pathname === '/password') return null;

    if (isNarrow) {
      return (
        <Menu fixed="top" secondary style={styles.menuBar}>
          <Menu.Item
            style={{ position: 'absolute', margin: 0, cursor: 'pointer' }}
          >
            <Icon name="sidebar" onClick={this.toggleVisibility} />
          </Menu.Item>
          <div style={{ margin: 'auto' }}>
            CHLNGERS - ADMIN - {process.env.REACT_APP_ENV_PHASE}
          </div>
          <Sidebar
            as={Menu}
            animation="overlay"
            width="wide"
            visible={visible}
            icon="labeled"
            vertical
          >
            <Menu.Item
              as={Link}
              to="/home"
              style={{
                color: headerColor,
                fontWeight: '800',
                fontSize: '1.5em',
              }}
              onClick={this.toggleVisibility}
            >
              CHLNGERS - ADMIN - {process.env.REACT_APP_ENV_PHASE}
            </Menu.Item>
            {[
              menus.map((menu) => {
                if (roles.includes(menu.role)) {
                  return (
                    <Menu.Item
                      key={menu.path}
                      as={NavLink}
                      to={menu.path}
                      className="topMenuItem"
                      name={menu.path}
                      active={pathname === menu.path}
                      onClick={this.toggleVisibility}
                    >
                      {menu.name}
                    </Menu.Item>
                  );
                }
                return null;
              }),
            ]}
            {token ? (
              <Menu.Item
                as={NavLink}
                to="/logout"
                className="topMenuItem"
                name="logout"
                active={pathname === 'logout'}
                onClick={this.onClickLogoutButton}
              >
                로그아웃
              </Menu.Item>
            ) : (
              <Menu.Item
                as={NavLink}
                to="/login"
                className="topMenuItem"
                name="login"
                active={pathname === 'login'}
                onClick={this.toggleVisibility}
              >
                로그인
              </Menu.Item>
            )}
            <Menu.Item
              key="close"
              as={Button}
              onClick={this.toggleVisibility}
              className="topMenuItem"
              style={styles.closeButton}
            >
              메뉴 닫기
            </Menu.Item>
          </Sidebar>
        </Menu>
      );
    }

    return (
      <Menu fixed="top" secondary style={styles.menuBar}>
        <Menu.Item
          as={Link}
          to="/home"
          style={{
            color: headerColor,
            fontWeight: '800',
            fontSize: '1.5em',
          }}
          onClick={this.handleItemClick}
        >
          CHLNGERS - ADMIN - {process.env.REACT_APP_ENV_PHASE}
        </Menu.Item>
        <Menu.Menu position="right">
          {token ? (
            <>
              {[
                menus.map((menu) => {
                  if (roles.includes(menu.role)) {
                    return (
                      <Menu.Item
                        key={menu.path}
                        as={Link}
                        to={`${menu.path}`}
                        className="topMenuItem"
                        name={menu.path}
                        active={pathname === menu.path}
                        onClick={this.handleItemClick}
                      >
                        {menu.name}
                      </Menu.Item>
                    );
                  }
                  return null;
                }),
              ]}
              <Menu.Item
                as={Link}
                to="/password"
                className="topMenuItem"
                name="password"
                active={pathname === 'password'}
                onClick={this.handleItemClick}
              >
                비밀번호 변경
              </Menu.Item>
              <Menu.Item
                className="topMenuItem"
                name="logout"
                active={pathname === 'logout'}
                onClick={this.onClickLogoutButton}
              >
                로그아웃
              </Menu.Item>
            </>
          ) : (
            <Menu.Item
              as={Link}
              to="/login"
              className="topMenuItem"
              name="login"
              active={pathname === 'login'}
              onClick={this.handleItemClick}
            >
              로그인
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
    );
  }
}

const mapStateToProps = (state: InitStore) => {
  const token = state.app.get('token');
  const roles = state.app.get('roles');
  return {
    token: token as string,
    roles: roles as string[],
  };
};

const mapDispatchToProps = NavigationThunk;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Navigation),
);

const styles = {
  menuBar: {
    backgroundColor: 'white',
    margin: 0,
    width: '100%',
    paddingBottom: 10,
  },
  closeButton: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #1b1c1d',
    width: '100%',
  },
};
