import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'thumbnailImageUrls'>,
  Pick<ChallengeFormType, 'thumbnailImageUrl'>
> = ({ thumbnailImageUrls }) => ({
  thumbnailImageUrl: thumbnailImageUrls.f,
});

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'thumbnailImageUrl'>,
  Pick<ReqCreateOrUpdateChallenge, 'thumbnailImageUrls'>
> = ({ thumbnailImageUrl }) => {
  if (!thumbnailImageUrl) throw Error('this should not be happened');

  return {
    thumbnailImageUrls: {
      f: thumbnailImageUrl,
      m: thumbnailImageUrl,
    },
  };
};
