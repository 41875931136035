import React from 'react';
import { Table, Header, Image, Grid } from 'semantic-ui-react';
import moment from 'moment';
import setCurrencyFormat from '../../../global/setCurrencyFormat';
import resizeImage from '../../../global/resizeImage';
import { ChallengeDescription } from './ChallengeDescription';
import { QuestionChallenge } from '@types';
import ChallengeDetailInfo from '../../Challenge/ChallengeItemDetail/ChallengeInfo/ChallengeDetailInfo';

type ChallengeDetailInfoProps = {
  challenge: QuestionChallenge;
};

export const ChallengeGoalSummary = ({
  challenge,
}: ChallengeDetailInfoProps) => {
  if (!challenge || challenge.id <= 0) return null;
  return (
    <Grid.Column width={16}>
      <Header as="h3" textAlign="center">
        챌린지 정보
      </Header>
      <Table basic="very" celled collapsing>
        <Table.Body>
          <Table.Row>
            <Table.Cell>유저 id</Table.Cell>
            <Table.Cell>{challenge.userId}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>관리자 유저 id 목록</Table.Cell>
            <Table.Cell>{challenge.adminUserIds?.join(', ')}</Table.Cell>
          </Table.Row>
          {challenge.descriptionModule &&
            challenge.descriptionModule?.length > 0 && (
              <Table.Row>
                <Table.Cell>챌린지 설명 모듈</Table.Cell>
                <Table.Cell>
                  {challenge.descriptionModule.map((object, idx) => (
                    <ChallengeDetailInfo key={idx} object={object} />
                  ))}
                </Table.Cell>
              </Table.Row>
            )}
          {challenge.descriptionExtra &&
            challenge.descriptionExtra?.length > 0 && (
              <Table.Row>
                <Table.Cell>챌린지 신청 후 노출 모듈</Table.Cell>
                <Table.Cell>
                  {challenge.descriptionExtra.map((object, idx) => (
                    <ChallengeDetailInfo key={idx} object={object} />
                  ))}
                </Table.Cell>
              </Table.Row>
            )}
          <Table.Row>
            <Table.Cell>목표</Table.Cell>
            <Table.Cell>
              <a
                href={`/goals/${challenge.goal.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                [{challenge.goal.id}]{challenge.goal.title}
              </a>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>상세설명</Table.Cell>
            <Table.Cell>
              <ChallengeDescription challenge={challenge} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증방법</Table.Cell>
            <Table.Cell>
              {challenge.authenticationMethod
                .split('\n')
                .map((line: string, idx: number) => (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                ))}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증 좋은 예</Table.Cell>
            <Table.Cell>
              <div style={{ overflowX: 'scroll', display: 'flex' }}>
                {challenge.authenticationGoodExamples.map(
                  (
                    example: {
                      image: string;
                      text: string;
                    },
                    idx: number,
                  ) => (
                    <div key={idx} style={{ width: '30%' }}>
                      <Image
                        src={resizeImage(example.image, 300)}
                        size="small"
                      />
                      <p>
                        {example.text
                          .split('\n')
                          .map((line: string, innerIdx: number) => (
                            <span key={innerIdx}>
                              {line}
                              <br />
                            </span>
                          ))}
                      </p>
                    </div>
                  ),
                )}
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증 안좋은 예</Table.Cell>
            <Table.Cell>
              <div style={{ overflowX: 'scroll', display: 'flex' }}>
                {challenge.authenticationBadExamples.map(
                  (
                    example: {
                      image: string;
                      text: string;
                    },
                    idx: number,
                  ) => (
                    <div key={idx} style={{ width: '30%' }}>
                      <Image
                        src={resizeImage(example.image, 300)}
                        size="small"
                      />
                      <p>
                        {example.text
                          .split('\n')
                          .map((line: string, innerIdx: number) => (
                            <span key={innerIdx}>
                              {line}
                              <br />
                            </span>
                          ))}
                      </p>
                    </div>
                  ),
                )}
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증 주의사항</Table.Cell>
            <Table.Cell>
              {challenge.authenticationChecklist.map(
                (line: string, idx: number) => {
                  return (
                    <span key={idx}>
                      {idx > 0 && <br />}
                      {line.split('\n').map((l: string, i: number) => {
                        return (
                          <span key={i}>
                            {l}
                            <br />
                          </span>
                        );
                      })}
                    </span>
                  );
                },
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>지원금액범위</Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(challenge.minRegisterDeposit)} ~{' '}
              {setCurrencyFormat(challenge.maxRegisterDeposit)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>최대참가인원</Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(challenge.maxRegisterCount)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>모집일</Table.Cell>
            <Table.Cell>
              {moment(challenge.registerStartDate).format(
                'YYYY-MM-DD HH:mm:ss',
              )}{' '}
              ~{' '}
              {moment(challenge.registerEndDate).format('YYYY-MM-DD HH:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>진행기간</Table.Cell>
            <Table.Cell>
              {moment(challenge.startDate).format('YYYY-MM-DD HH:mm:ss')} ~{' '}
              {moment(challenge.endDate).format('YYYY-MM-DD HH:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>결과발표일</Table.Cell>
            <Table.Cell>
              {moment(challenge.resultDate).format('YYYY-MM-DD HH:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>경험치</Table.Cell>
            <Table.Cell>{challenge.exp}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증가능요일</Table.Cell>
            <Table.Cell>
              {challenge.goal.cycle.map((item: string) => item).join(', ')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증 빈도</Table.Cell>
            <Table.Cell>
              {challenge.goal.dayOrPeriod === 'DAY'
                ? '매일'
                : challenge.goal.cycle.includes('ALL')
                  ? '전체 기간'
                  : challenge.goal.cycle.includes('MONTHLY')
                    ? '월'
                    : challenge.goal.cycle.includes('BIWEEKLY')
                      ? '2주동안'
                      : '주'}
              {challenge.goal.dayOrPeriod === 'DAY'
                ? `${challenge.goal.totalAchieveCountPerCycle}회`
                : `${
                    challenge.goal.totalAchieveCountPerCycle /
                    challenge.goal.maxAchieveCountPerDay
                  }회`}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>하루 최대</Table.Cell>
            <Table.Cell>{challenge.goal.maxAchieveCountPerDay}회</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>카운트 다운</Table.Cell>
            <Table.Cell>
              {challenge.goal.countDown ? '적용' : '미적용'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증시간</Table.Cell>
            <Table.Cell>
              {challenge.goal.dueStartTime}~{challenge.goal.dueEndTime}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>갤러리</Table.Cell>
            <Table.Cell>
              {challenge.goal.isGalleryPossible ? '사용가능' : '사용불가'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>공휴일</Table.Cell>
            <Table.Cell>
              {challenge.goal.restInHoliday ? '인증없음' : '인증필요'}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Grid.Column>
  );
};

export default ChallengeGoalSummary;
