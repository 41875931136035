import ErrorMsg from '@component/ErrorMsg';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import { FC, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import { useInitializeEffect } from '../../AchievementInfoListForm.hooks';
import {
  useAchievementInfoList,
  useChallengeDate,
  useSpecialPrizeType,
} from '../../AchievementInfoListForm.store';
import { validate } from '../../AchievementInfoListForm.validator';
import AchievmentInfoItem from '../AchievmentInfoItem/AchievmentInfoItem';
import IsPublicCheckBox from '../IsPublicCheckBox/IsPublicCheckBox';
import {
  useSpecialPrizeForm,
  useSpecialPrizeFormSetter,
} from '../../../SpecialPrizeForm/SpecialPrizeForm.store';

const AchivementInfoListSetting: FC = () => {
  const infoList = useAchievementInfoList();
  const specialPrizeType = useSpecialPrizeType();
  const { startDate, endDate } = useChallengeDate();
  const specialPrize = useSpecialPrizeForm();
  const { setSpecialPrizeForm } = useSpecialPrizeFormSetter();

  const validated = validate({
    achievementInfoList: infoList,
    startDate,
    endDate,
  });

  useInitializeEffect();

  useEffect(() => {
    if (specialPrizeType === 'achievement') {
      setSpecialPrizeForm({
        ...specialPrize,
        amount: infoList.reduce(
          (total, { prizeAmount = 0 }) => total + (prizeAmount ?? 0),
          0,
        ),
      });
    }
  }, [infoList]);

  return (
    <FlexBox.Column gap={8}>
      <Label>챌린지 인증회차별 정보 설정</Label>
      <IsPublicCheckBox />

      <Table collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>인증 회차</Table.HeaderCell>
            <Table.HeaderCell>인증 시간 설정</Table.HeaderCell>
            <Table.HeaderCell>인증샷 공개 여부</Table.HeaderCell>
            {specialPrizeType === 'achievement' && (
              <Table.HeaderCell>특별 상금</Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {infoList?.map((info, i) => (
            <AchievmentInfoItem key={i} info={info} index={i} />
          ))}
        </Table.Body>
      </Table>
      <ErrorMsg text={validated.isValid ? '' : validated.message} />
    </FlexBox.Column>
  );
};

export default AchivementInfoListSetting;
