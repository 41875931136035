import {
  ChallengeAchievementSetting,
  ReqCreateOrUpdateChallenge,
} from '@types';
import { ChallengeFormType } from '../../NewChallengeForm.types';
import { AchievementInfoListForm } from '../AchievementInfoListForm';
import { AchievementMissionForm } from '../AchievementMissionForm';
import AdminUserIdsForm from '../AdminUserIdsForm/AdminUserIdsForm';
import CollaboAdminUserIdsForm from '../CollaboAdminUserIdsForm/CollaboAdminUserIdsForm';
import AuthenticationBadExamplesForm from '../AuthenticationBadExamplesForm/AuthenticationBadExamplesForm';
import AuthenticationCheckListForm from '../AuthenticationCheckListForm/AuthenticationCheckListForm';
import AuthenticationGoodExamplesForm from '../AuthenticationGoodExamplesForm/AuthenticationGoodExamplesForm';
import AuthenticationMethodForm from '../AuthenticationMethodForm/AuthenticationMethodForm';
import CancelImpossibleForm from '../CancelImpossibleForm/CancelImpossibleForm';
import CollaborationForm from '../CollaborationForm/CollaborationForm';
import { CommerceForm } from '../CommerceForm';
import CompanyIdForm from '../CompanyIdForm/CompanyIdForm';
import CreatorForm from '../CreatorForm/CreatorForm';
import { EndDateForm } from '../EndDateForm';
import ExpForm from '../ExpForm/ExpForm';
import { FaqForm } from '../FaqForm';
import { GoalIdForm } from '../GoalIdForm';
import InstagramInfoListForm from '../InstagramInfoListForm/InstagramInfoListForm';
import IsPublicForm from '../IsPublicForm/IsPublicForm';
import { IsRegisterDepositFixedForm } from '../IsRegisterDepositFixedForm';
import MaxRegisterCountForm from '../MaxRegisterCountForm/MaxRegisterCountForm';
import MaxRegisterDepositForm from '../MaxRegisterDepositForm/MaxRegisterDepositForm';
import MinRegisterDepositForm from '../MinRegisterDepositForm/MinRegisterDepositForm';
import { NaverBlogInfoListForm } from '../NaverBlogInfoListForm';
import NaverIdRequiredForm from '../NaverIdRequiredForm/NaverIdRequiredForm';
import PurchaseInfoListForm from '../PurchaseInfoForm/PurchaseInfoForm';
import PasswordForm from '../PasswordForm/PasswordForm';
import PublicTypeForm from '../PublicTypeForm/PublicTypeForm';
import QnaForm from '../QnaForm/QnaForm';
import RegisterEndDateForm from '../RegisterEndDateForm/RegisterEndDateForm';
import RegisterStartDateForm from '../RegisterStartDateForm/RegisterStartDateForm';
import { ResultDateForm } from '../ResultDateForm';
import { SpecialPrizeForm } from '../SpecialPrizeForm';
import { StartDateForm } from '../StartDateForm';
import SupportersForm from '../SupportersForm/SupportersForm';
import TagIdsForm from '../TagIdsForm/TagIdsForm';
import ThirdTermUrlForm from '../ThirdTermUrlForm/ThirdTermUrlForm';
import { ThumbnailImageUrlForm } from '../ThumbnailImageUrlForm';
import TimestampForm from '../TimestampForm/TimestampForm';
import TitleForm from '../TitleForm/TitleForm';
import { UseItemImpossibleForm } from '../UseItemImpossibleForm';
import { UserFilterForm } from '../UserFilterForm';
import UserIdForm from '../UserIdForm/UserIdForm';
import DescriptionModuleForm from '../DescriptionModuleForm';
import DescriptionExtraForm from '../DescriptionExtraForm';
import IsDeletedForm from '../IsDeletedForm/IsDeletedForm';
import StickerForm from '../StickerForm/StickerForm';
import { ImageFilterForm } from '../ImageFilterForm';
import { PostConnectPrizeForm } from '../PostConnectPrizeForm';
import { DistanceForm } from '../DistanceForm';
import { ViralLoopInviteeRewardForm } from '../ViralLoopInviteeRewardForm';
import { ViralLoopAuthenticationMethodForm } from '../ViralLoopAuthenticationMethodForm';
import LabelIdsForm from '../LabelIdsForm/LabelIdsForm';
import CategoryForm from '../CategoryForm/CategoryForm';
import PurchaseChannelForm from '../PurchaseChannelForm/PurchaseChannelForm';
import PurchaseStartDateForm from '../PurchaseStartDateForm/PurchaseStartDateForm';
import PurchaseEndDateForm from '../PurchaseEndDateForm/PurchaseEndDateForm';
import PriceForm from '../PriceForm/PriceForm';
import OriginalPriceForm from '../OriginalPriceForm/OriginalPriceForm';

export const convertAchievementSetting = (
  challenge: ChallengeFormType,
): ChallengeAchievementSetting => {
  return {
    infoList: challenge.achievementInfoList.map((info) => {
      if (
        !info.dueStartDate ||
        !info.dueStartTime ||
        !info.dueEndDate ||
        !info.dueEndTime
      ) {
        throw Error("this shouldn't happened");
      }
      return {
        dueStartDate: info.dueStartDate,
        dueStartTime: info.dueStartTime,
        dueEndDate: info.dueEndDate,
        dueEndTime: info.dueEndTime,
        isPublic: Boolean(info.isPublic),
        prizeAmount: info.prizeAmount || undefined,
      };
    }),
  };
};

export const convertForm2Data = (
  challenge: ChallengeFormType,
): ReqCreateOrUpdateChallenge => ({
  ...GoalIdForm.convertForm2Data(challenge),
  ...TitleForm.convertForm2Data(challenge),
  ...UserIdForm.convertForm2Data(challenge),
  ...PasswordForm.convertForm2Data(challenge),
  ...AdminUserIdsForm.convertForm2Data(challenge),
  ...CollaboAdminUserIdsForm.convertForm2Data(challenge),
  ...TagIdsForm.convertForm2Data(challenge),
  ...LabelIdsForm.convertForm2Data(challenge),
  ...IsRegisterDepositFixedForm.convertForm2Data(challenge),
  ...MinRegisterDepositForm.convertForm2Data(challenge),
  ...MaxRegisterDepositForm.convertForm2Data(challenge),
  ...MaxRegisterCountForm.convertForm2Data(challenge),
  ...RegisterStartDateForm.convertForm2Data(challenge),
  ...RegisterEndDateForm.convertForm2Data(challenge),
  ...StartDateForm.convertForm2Data(challenge),
  ...EndDateForm.convertForm2Data(challenge),
  ...ResultDateForm.convertForm2Data(challenge),
  ...ExpForm.convertForm2Data(challenge),
  ...IsPublicForm.convertForm2Data(challenge),
  ...IsDeletedForm.convertForm2Data(challenge),
  ...ThumbnailImageUrlForm.convertForm2Data(challenge),
  ...AuthenticationMethodForm.convertForm2Data(challenge),
  ...AuthenticationGoodExamplesForm.convertForm2Data(challenge),
  ...AuthenticationBadExamplesForm.convertForm2Data(challenge),
  ...AuthenticationCheckListForm.convertForm2Data(challenge),
  ...ThirdTermUrlForm.convertForm2Data(challenge),
  ...CompanyIdForm.convertForm2Data(challenge),
  ...CommerceForm.convertForm2Data(challenge),
  ...UserFilterForm.convertForm2Data(challenge),
  ...DescriptionModuleForm.convertForm2Data(challenge),
  ...DescriptionExtraForm.convertForm2Data(challenge),
  ...IsDeletedForm.convertForm2Data(challenge),
  ...StickerForm.convertForm2Data(challenge),
  ...CategoryForm.convertForm2Data(challenge),
  ...PurchaseChannelForm.convertForm2Data(challenge),
  ...PurchaseStartDateForm.convertForm2Data(challenge),
  ...PurchaseEndDateForm.convertForm2Data(challenge),
  ...PriceForm.convertForm2Data(challenge),
  ...OriginalPriceForm.convertForm2Data(challenge),

  // XXX: customField 관련 form은 아래의 array 안에 spread 형식으로 추가
  customFields: [
    ...SpecialPrizeForm.convertForm2Data(challenge),
    ...UseItemImpossibleForm.convertForm2Data(challenge),
    ...CancelImpossibleForm.convertForm2Data(challenge),
    ...PublicTypeForm.convertForm2Data(challenge),
    ...AchievementInfoListForm.convertForm2Data(challenge),
    ...FaqForm.convertForm2Data(challenge),
    ...InstagramInfoListForm.convertForm2Data(challenge),
    ...NaverBlogInfoListForm.convertForm2Data(challenge),
    ...PurchaseInfoListForm.convertForm2Data(challenge),
    ...PostConnectPrizeForm.convertForm2Data(challenge),
    ...NaverIdRequiredForm.convertForm2Data(challenge),
    ...CollaborationForm.convertForm2Data(challenge),
    ...SupportersForm.convertForm2Data(challenge),
    ...QnaForm.convertForm2Data(challenge),
    ...CreatorForm.convertForm2Data(challenge),
    ...TimestampForm.convertForm2Data(challenge),
    ...AchievementMissionForm.convertForm2Data(challenge),
    ...ImageFilterForm.convertForm2Data(challenge),
    ...DistanceForm.convertForm2Data(challenge),
    ...ViralLoopInviteeRewardForm.convertForm2Data(challenge),
    ...ViralLoopAuthenticationMethodForm.convertForm2Data(challenge),
  ],
});
