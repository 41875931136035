import NumberInput from '@component/NumberInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './MaxRegisterDepositForm.serializer';
import {
  useMaxRegisterDepositStore,
  useMaxRegisterDepositActions,
} from './MaxRegisterDepositForm.store';
import { validate } from './MaxRegisterDepositForm.validator';
import { MAX_REGISTER_DEPOSIT } from '../../NewChallengeForm.constants';

const MaxRegisterDepositForm: ChallengeFormComponent<
  Pick<ReqCreateOrUpdateChallenge, 'maxRegisterDeposit'>,
  Pick<ChallengeFormType, 'maxRegisterDeposit'>,
  Pick<ChallengeInfoForAdmin, 'maxRegisterDeposit'>
> = () => {
  const { maxRegisterDeposit, minRegisterDeposit, isRegisterDepositFixed } =
    useMaxRegisterDepositStore();
  const { setMaxRegisterDeposit } = useMaxRegisterDepositActions();
  const validated = validate({ maxRegisterDeposit, minRegisterDeposit });

  return (
    <NumberInput
      label="최대참가비"
      name="maxRegisterDeposit"
      value={maxRegisterDeposit}
      max={MAX_REGISTER_DEPOSIT}
      onChange={(v) =>
        setMaxRegisterDeposit(Math.min(Number(v), MAX_REGISTER_DEPOSIT))
      }
      errorMsg={
        !validated.isValid && !isRegisterDepositFixed ? validated.message : ''
      }
      disabled={isRegisterDepositFixed}
    />
  );
};

export default MaxRegisterDepositForm;

MaxRegisterDepositForm.validate = validate;
MaxRegisterDepositForm.convertData2Form = convertData2Form;
MaxRegisterDepositForm.convertForm2Data = convertForm2Data;
