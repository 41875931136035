const CouponWithPriceModule = () => {
  return (
    <div
      style={{
        padding: 10,
        width: 150,
        fontWeight: 'bold',
        fontSize: 15,
      }}
    >
      쿠폰+가격 모듈
    </div>
  );
};

export default CouponWithPriceModule;
