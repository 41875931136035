import { useShallow } from 'zustand/react/shallow';
import { ServiceCategory } from '@types';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useCategory = () => {
  return useChallengeFormStore(
    useShallow((s) => ({ category: s.challenge.category })),
  );
};

export const useCategoryActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setCategory = (category: ServiceCategory) =>
    updateChallengeForm({ category });

  return { setCategory };
};
