import { QuestionType } from 'src/types/achievement.types';

export const REMOVE_ALL_TEXT =
  '모든 회차의 질의응답 세팅 내용을 삭제하시겠습니까?';

export const SET_DEFAULT_TEXT =
  '모든 내용을 초기화 하고 인증회차별 기본 세팅을 하시겠습니까?';

export const ADD_AFTER_GOAL_SET_TEXT = '목표를 세팅한 뒤 시도해주세요.';

export const questionOptions = [
  { text: '객관식 정답있음', value: QuestionType.MULTIPLE_CHOICE_ANSWER_EXIST },
  { text: '객관식 정답없음', value: QuestionType.MULTIPLE_CHOICE_NO_ANSWER },
  { text: '주관식 단답형', value: QuestionType.SHORT_ANSWER },
  { text: '주관식 서술형', value: QuestionType.ESSAY_ANSWER },
];

export const multipleChoiceAnswerExistTemplate = {
  type: QuestionType.MULTIPLE_CHOICE,
  title: '',
  choices: [
    {
      id: 1,
      text: '옵션1',
      type: 'incorrect',
    },
    {
      id: 2,
      text: '옵션2',
      type: 'incorrect',
    },
    {
      id: 3,
      text: '옵션3',
      type: 'correct',
    },
    {
      id: 4,
      text: '옵션4',
      type: 'incorrect',
    },
    {
      id: 5,
      text: '옵션5',
      type: 'incorrect',
    },
  ],
  multipleSelection: false,
};

export const multipleChoiceNoAnswerTemplate = {
  type: QuestionType.MULTIPLE_CHOICE,
  title: '',
  choices: [
    {
      id: 1,
      text: '옵션1',
      type: 'none',
    },
    {
      id: 2,
      text: '옵션2',
      type: 'none',
    },
    {
      id: 3,
      text: '옵션3',
      type: 'none',
    },
    {
      id: 4,
      text: '옵션4',
      type: 'none',
    },
    {
      id: 5,
      text: '기타',
      type: 'custom',
    },
  ],
  multipleSelection: false,
};

export const shortAnswerTemplate = {
  type: QuestionType.SHORT_ANSWER,
  title: '',
  placeholder: '예시 답변',
};

export const essayAnswerTemplate = {
  type: QuestionType.ESSAY_ANSWER,
  title: '',
  answerTitle: {
    maxLength: 30,
    minLength: 5,
  },
  answerContent: {
    maxLength: 300,
    minLength: 10,
  },
};
