import { cloneDeep } from 'lodash';

export const changeIndexItemInArr = <T>(
  arr: T[],
  index: number,
  newItem: T,
) => {
  const newArr = arr.slice();
  newArr.splice(index, 1, newItem);
  return newArr;
};

export const copyToClipboard = async (textToCopy: string) => {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = 'absolute';
    textArea.style.left = '-999999px';

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
};

/**
 * 특정 key로 Object[]를 groupBy 하는 함수
 */
export const groupBy = <T, V>(
  data: T[],
  by: (item: T) => V,
  groupsAreEqual?: (a: V, b: V) => boolean,
) => {
  const groupList: V[] = [];

  const grouped = data.reduce(
    (prev, curr) => {
      const group = by(curr);
      const idx = groupList.findIndex((g) =>
        groupsAreEqual ? groupsAreEqual(g, group) : g === group,
      );

      if (idx === undefined || !prev[idx]?.dataList) {
        groupList.push(group);
        return [
          ...prev,
          {
            group,
            dataList: [curr],
          },
        ];
      }
      return [
        ...prev.slice(0, idx),
        {
          ...prev[idx],
          dataList: [...prev[idx].dataList, curr],
        },
        ...prev.slice(idx + 1),
      ];
    },
    [] as {
      group: V;
      dataList: T[];
    }[],
  );

  return grouped;
};

export const removeDuplicates = <T>(arr: Array<T>) => [...new Set(arr)] as T[];

export const extractNumbers = (str: string) => {
  return str.replace(/[^0-9]/g, '');
};

export const pushWithoutDuplicated = <T>(arr: T[], ...items: T[]) => {
  return Array.from(new Set([...arr, ...items]));
};

export const mergeArrWithoutDuplicated = <T>(...arr: T[][]) => {
  return Array.from(new Set(arr.flat()));
};

export const downloadUrl = (url: string, title?: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = title ? `${title}.zip` : 'images.zip';
  link.click();
};

export const isNull = (value: unknown): value is null => value === null;

export const swap = <T>(array: T[], aIndex: number, bIndex: number) => {
  const _array = cloneDeep(array);

  const aValue = _array[aIndex];
  const bValue = _array[bIndex];

  _array.splice(aIndex, 1);
  _array.splice(aIndex, 0, bValue);
  _array.splice(bIndex, 1);
  _array.splice(bIndex, 0, aValue);

  return _array;
};
