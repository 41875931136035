import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  Table,
  Button,
  Modal,
  TextArea,
  Form,
  Pagination,
  Dropdown,
} from 'semantic-ui-react';
import moment from 'moment';
import { OldCardTransactionWithdrawInfo } from '@types';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import { apis } from '@api/index';
import WithdrawOldCardCashHeader from './WithdrawOldCardCashHeader';
import DateSelect from '../DateSelect';
import { DateInfo } from '../DateSelect/DateSelect';
import TransactionTransferOldCardCashExcel from './TransactionTransferOldCardCashExcel';
import { defaultWithdrawAnswer } from '../User.constant';
import TransactionTransferOldCardCashRow from './TransactionTransferOldCardCashRow';
import SearchWithdraw, { WithdrawSearchType } from '../Withdraw/SearchWithdraw';
import DurationPagination from '@component/DurationPagination/DurationPagination';

const TransactionTransferOldCardCash = () => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchType, setSearchType] =
    useState<WithdrawSearchType>('accountName');
  const [searchText, setSearchText] = useState('');

  const [withdraws, setWithdraws] = useState<OldCardTransactionWithdrawInfo[]>(
    [],
  );
  const [filteredWithdraws, setFilteredWithdraws] = useState<
    OldCardTransactionWithdrawInfo[]
  >([]);
  const [searchedWithdraws, setSearchedWithdraws] = useState<
    OldCardTransactionWithdrawInfo[]
  >([]);
  const [activeTab, setActiveTab] = useState<'NOT_COMPLETED' | 'COMPLETED'>(
    'NOT_COMPLETED',
  );
  const [dateInfo, setDateInfo] = useState<DateInfo>({
    startDate: moment().add(-7, 'd').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });
  const [checkedAll, setCheckedAll] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [withdraw, setWithdraw] = useState<OldCardTransactionWithdrawInfo>();

  const handleClickTab = (tab: 'NOT_COMPLETED' | 'COMPLETED') => {
    setActiveTab(tab);
  };

  const handleChangeDate = (key: keyof DateInfo, value: any) => {
    setDateInfo((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const getWithdraws = async () => {
    LoadingIndicator.show();
    const { transactions: _withdraws } =
      await apis.common.getOldCardTransactions({
        gteCreatedAt: moment(dateInfo.startDate)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(-9, 'h')
          .format('YYYY-MM-DD HH:mm:ss'),
        ltCreatedAt: moment(dateInfo.endDate)
          .add(1, 'd')
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(-9, 'h')
          .format('YYYY-MM-DD HH:mm:ss'),
      });
    setWithdraws(_withdraws);
    LoadingIndicator.hide();
  };

  useEffect(() => {
    getWithdraws();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isCompleted = activeTab === 'COMPLETED';
    const _filteredWithdraws = withdraws.filter(
      (o) => o.isCompleted === isCompleted,
    );
    setFilteredWithdraws(_filteredWithdraws);
    setSearchedWithdraws(_filteredWithdraws);
    setSearchText('');
  }, [activeTab, withdraws]);

  const handleChangeCheckAll = () => {
    if (checkedAll) {
      setCheckedAll(false);
      setWithdraws((prev) => {
        return prev.map((o) => ({ ...o, isChecked: false }));
      });
    } else {
      setWithdraws((prev) => {
        return prev.map((o) => {
          if (o.answer) return { ...o, isChecked: false };
          return { ...o, isChecked: true };
        });
      });
      setCheckedAll(true);
    }
  };

  const handleChangeCheckbox = useCallback(
    (id: number) => {
      setWithdraws((prev) => {
        return prev.map((o) => {
          if (o.id === id) {
            return { ...o, isChecked: !o.isChecked };
          }
          return o;
        });
      });
    },
    [setWithdraws],
  );

  const showModal = useCallback((_withdraw: OldCardTransactionWithdrawInfo) => {
    setModalOpen(true);
    setWithdraw({
      ..._withdraw,
      answer: _withdraw.answer ?? defaultWithdrawAnswer,
    });
  }, []);

  const closeModal = useCallback(() => setModalOpen(false), []);

  const handleWithdrawChange = (
    key: keyof OldCardTransactionWithdrawInfo,
    value: any,
  ) => {
    if (!withdraw) return;
    setWithdraw((prev) => {
      if (!prev) return prev;
      return { ...prev, [key]: value };
    });
  };

  const handleChangeWithdrawIsCompleted = useCallback(
    async (id: number) => {
      await apis.common.updateOldCardTransactionTransferIsCompleted({
        ids: [id],
      });
      setWithdraws((prev) => {
        return prev.map((o) => {
          if (o.id === id) return { ...o, isCompleted: !o.isCompleted };
          return o;
        });
      });
    },
    [setWithdraws],
  );

  const completeWithdrawOldCardCashes = async () => {
    LoadingIndicator.show();
    const ids: number[] = [];
    withdraws.forEach((o) => {
      if (o.isChecked) ids.push(o.id);
    });
    await apis.common.updateOldCardTransactionTransferIsCompleted({
      ids,
    });
    setWithdraws((prev) => {
      return prev.map((o) => ({
        ...o,
        isCompleted: o.isChecked === undefined ? false : o.isChecked,
        isChecked: false,
      }));
    });
    setCheckedAll(false);

    LoadingIndicator.hide();
    alert('출금처리가 완료되었습니다.');
  };

  const submitWithdrawAnswer = async () => {
    if (!withdraw || !withdraw.answer) return;
    await apis.common.answerOldCardTransactions({
      ids: [withdraw.id],
      answer: withdraw.answer,
    });
    setWithdraws((prev) => {
      return prev.map((o) => {
        if (o.id === withdraw.id) return { ...o, answer: withdraw.answer };
        return o;
      });
    });
    closeModal();
  };

  const handleChangeSearchType = useCallback(
    (type: WithdrawSearchType) => {
      setSearchType(type);
    },
    [setSearchType],
  );

  const searchUser = useCallback(async () => {
    setSearchedWithdraws(filterKeyword(filteredWithdraws));
  }, [searchType, searchText, filteredWithdraws]);

  const filterKeyword = (wd: OldCardTransactionWithdrawInfo[]) => {
    if (!searchText) return wd;
    return wd.filter((o) => {
      if (searchType === 'userId') {
        return String(o.userId).includes(searchText);
      }
      if (searchType === 'accountNo') {
        return o.accountNo.includes(searchText);
      }
      if (searchType === 'bankName') {
        return o.bankName.includes(searchText);
      }

      return o.accountName.includes(searchText);
    });
  };

  return (
    <>
      <WithdrawOldCardCashHeader
        activeTab={activeTab}
        handleClick={handleClickTab}
      />
      <DateSelect handleChangeDate={handleChangeDate} fetch={getWithdraws} />
      <TransactionTransferOldCardCashExcel withdraws={filteredWithdraws} />
      <Button
        size="tiny"
        onClick={completeWithdrawOldCardCashes}
        content="처리완료"
      />

      <SearchWithdraw
        searchText={searchText}
        searchUser={searchUser}
        setSearchKeyword={setSearchText}
        handleChangeSearchType={handleChangeSearchType}
      />

      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Checkbox checked={checkedAll} onChange={handleChangeCheckAll} />
            </Table.HeaderCell>
            <Table.HeaderCell>거래id</Table.HeaderCell>
            <Table.HeaderCell>유저id</Table.HeaderCell>
            <Table.HeaderCell>요청시기</Table.HeaderCell>
            <Table.HeaderCell>은행</Table.HeaderCell>
            <Table.HeaderCell>계좌번호</Table.HeaderCell>
            <Table.HeaderCell>결제금액</Table.HeaderCell>
            <Table.HeaderCell>결제일</Table.HeaderCell>
            <Table.HeaderCell>금액</Table.HeaderCell>
            <Table.HeaderCell>예금주</Table.HeaderCell>
            <Table.HeaderCell>답변</Table.HeaderCell>
            <Table.HeaderCell>처리여부</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {searchedWithdraws
            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
            .map((wd) => (
              <TransactionTransferOldCardCashRow
                wd={wd}
                handleChangeCheckbox={handleChangeCheckbox}
                showModal={showModal}
                handleChangeWithdrawIsCompleted={
                  handleChangeWithdrawIsCompleted
                }
              />
            ))}
        </Table.Body>
      </Table>
      <DurationPagination
        currentPage={currentPage}
        pageSize={pageSize}
        totalPages={Math.floor(searchedWithdraws.length / pageSize) + 1}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
      />
      <Modal size="tiny" open={modalOpen} onClose={closeModal}>
        <Modal.Header>계좌이체 답변</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <TextArea
                fluid
                name="answer"
                value={withdraw?.answer}
                onChange={(e) => handleWithdrawChange('answer', e.target.value)}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="제출" onClick={submitWithdrawAnswer} />
          <Button color="black" content="닫기" onClick={closeModal} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default TransactionTransferOldCardCash;
