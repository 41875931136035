import { Achievements } from 'src/types/achievement.types';
import { GoalDetailInfo } from '@types';
import { ApiType } from '../constant/apiType';
import {
  ChallengeAchievementSetting,
  ChallengeInfoForAdmin,
  ReqCreateOrUpdateChallenge,
  ChallengeForOption,
  TagDetailInfo,
  AchievementInfo,
  ChallengeSticker,
  LabelMiniInfo,
  CollaboChallenge,
} from '../types/challenge.types';
import { PaginationParams, PaginationResponse } from '../types/common.types';
import { _request } from './factory';

export type GetChallengeOptionsParams = {
  gteRegisterStartDate?: string; // "YYYY-MM-DD HH:MM:SS"
  gteRegisterEndDate?: string;
  ltRegisterStartDate?: string;
  ltRegisterEndDate?: string;
  gteStartDate?: string;
  gteEndDate?: string;
  ltStartDate?: string;
  ltEndDate?: string;
  gteResultDate?: string;
  ltResultDate?: string;
  isOfficial?: boolean;
  isPublic?: boolean;
  isDeleted?: boolean;
  offset?: number;
  limit?: number;
  keyword?: string;
};

type GetChallengeOptionsResponse = {
  challenges: ChallengeForOption[];
} & PaginationResponse;

const getChallengeOptions = (
  params: GetChallengeOptionsParams,
): Promise<GetChallengeOptionsResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: '/admin/challenges/options',
    params,
  });
};

export type CreateChallengeData = {
  challenge: ReqCreateOrUpdateChallenge;
  achievementSetting: ChallengeAchievementSetting;
};

type CreateChallengeResponse = {
  challenge: ChallengeInfoForAdmin;
};

const createChallenge = (
  data: CreateChallengeData,
): Promise<CreateChallengeResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: '/admin/challenges',
    data,
  });
};

export type UpdateChallengeParams = {
  challenge: ReqCreateOrUpdateChallenge;
  achievementSetting: ChallengeAchievementSetting;
};

type UpdateChallengeResponse = {
  challenge: ChallengeInfoForAdmin;
};

const updateChallenge = (
  challengeId: number,
  data: UpdateChallengeParams,
): Promise<UpdateChallengeResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'PUT',
    url: `/admin/challenges/${challengeId}`,
    data,
  });
};

type GetChallengeResponse = {
  challenge: ChallengeInfoForAdmin;
};

const getChallenge = async (
  challengeId: number,
): Promise<GetChallengeResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/challenges/${challengeId}`,
  });
};

const createGoal = async (data: any) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/goals`,
    data,
  });
};

const getGoalOptions = async (params: any) => {
  return _request({
    method: 'GET',
    url: '/admin/goals/options',
    params,
  });
};

const updateGoal = async (id: number, data: any) => {
  return _request({
    apiType: ApiType.go,
    method: 'PUT',
    url: `/admin/goals/${id}`,
    data,
  });
};

type GetGoalDetailResponse = {
  goal: GoalDetailInfo;
};

const getGoalDetail = async (
  goalId: number,
): Promise<GetGoalDetailResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/goals/${goalId}`,
  });
};

const deleteGoal = async (id: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'DELETE',
    url: `/admin/goals/${id}`,
  });
};

export type GetGoalsParams = {
  isOfficial?: boolean;
} & Pick<PaginationParams, 'keyword'>;

type GetGoalsResponse = {
  goals: GoalDetailInfo[];
} & PaginationResponse;

const getGoals = async (
  params: GetGoalsParams & Omit<PaginationParams, 'keyword'>,
): Promise<GetGoalsResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/goals`,
    params,
  });
};

const getGoalRestricts = (id: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/goals/${id}/restricts`,
  });
};

const getGoalReviews = (id: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/goals/${id}/reviews`,
  });
};

const copyGoals = async (data: { goalIds: number[] }) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/goals/copy`,
    data,
  });
};

export type GetTagsParams = {
  category?: string;
};

type GetTagsResponse = {
  tags: TagDetailInfo[];
  total: number;
} & PaginationResponse;

const getTags = (
  params: GetTagsParams & Partial<PaginationParams>,
): Promise<GetTagsResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/tags`,
    params,
  });
};

/**
 * 라벨 조회
 */
type GetLabelsResponse = {
  labels: LabelMiniInfo[];
  total: number;
} & PaginationResponse;

const getLabels = (
  params: Partial<PaginationParams>,
): Promise<GetLabelsResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/label`,
    params,
  });
};

/**
 * 라벨 생성
 */
type CreateLabelsData = {
  name: string;
};
type CreateLabelsResponse = {
  label: {
    id: number;
    name: string;
  };
};

const createLabels = (
  data: CreateLabelsData,
): Promise<CreateLabelsResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/label`,
    data,
  });
};

type GetChallengeAppCategoriesResponse = {
  categories: {
    text: string;
    url: string;
    subCategories: {
      text: string;
      criteria: `level${1 | 2 | 3 | 4}`;
      value: string;
      goalCategoryIds: number[];
    }[];
  }[];
};

const getChallengeAppCategories =
  async (): Promise<GetChallengeAppCategoriesResponse> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: '/admin/categories',
    });
  };

type GetChallengeAchievementSettingResponse = {
  achievementSetting: { infoList: AchievementInfo[] };
};
const getChallengeAchievementSetting = (
  challengeId: number,
): Promise<GetChallengeAchievementSettingResponse> =>
  _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/challenges/${challengeId}/achievement-setting`,
  });

type CheckAchievementCardListParams = {
  goalId: number;
  startDate: string;
  endDate: string;
};

type CheckAchievementCardListResponse = {
  achievements: Achievements[];
};

const getAchievementCardList = async (
  data: CheckAchievementCardListParams,
): Promise<CheckAchievementCardListResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/achievements/check-card-list`,
    data,
  });
};

type ReqGetChallengeStickers = {
  challengeId?: number;
  companyId?: number;
};

const getChallengeStickers = async (
  params: ReqGetChallengeStickers,
): Promise<ChallengeSticker> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/challenge-stickers`,
    params,
  });
};

type ReqCreateOrUpdateChallengeSticker = {
  challengeId: number;
  companyId: number;
  ImageUrl: string;
  Order?: number;
};

const createChallengeSticker = async (
  data: ReqCreateOrUpdateChallengeSticker,
) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/challenge-stickers`,
    data,
  });
};

const updateChallengeSticker = async (
  id: number,
  data: ReqCreateOrUpdateChallenge,
) => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: `/admin/challenge-stickers/${id}`,
    data,
  });
};

const deleteChallengeSticker = async (id: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'DELETE',
    url: `/admin/challenge-stickers/${id}`,
  });
};

type ReqCreateOrUpdateGoalCategory = {
  level1: string;
  level2: string;
  level3: string;
  level4: string;
  restrictGoalCategoryIds: number[];
};

const createGoalCategory = async (data: ReqCreateOrUpdateGoalCategory) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/goal-categories`,
    data,
  });
};

const getGoalCategories = async (params: any) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: '/admin/goal-categories',
    params,
  });
};

const updateGoalCategory = async (
  id: number,
  data: ReqCreateOrUpdateGoalCategory,
) => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: `/admin/goal-categories/${id}`,
    data,
  });
};

const deleteGoalCategory = async (id: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'DELETE',
    url: `/admin/goal-categories/${id}`,
  });
};

const getGoalCategoryLevelOptions = async () => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/goal-categories/options`,
  });
};

const updateChallengeReview = async (id: number, data: any) => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: `/admin/challenge-reviews/${id}`,
    data,
  });
};

const getCollaboChallenge = async (params: {
  offset: number;
  limit: number;
}): Promise<{
  challenges: CollaboChallenge[];
  isEnd: boolean;
}> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: '/admin/challenges/collabo',
    params,
  });
};

export default {
  getCollaboChallenge,
  getChallengeOptions,
  createChallenge,
  updateChallenge,
  getChallenge,
  createGoal,
  updateGoal,
  getGoalDetail,
  deleteGoal,
  getGoals,
  getGoalOptions,
  getGoalRestricts,
  getGoalReviews,
  copyGoals,
  getTags,
  getLabels,
  createLabels,
  getChallengeAppCategories,
  getChallengeAchievementSetting,
  getAchievementCardList,
  getChallengeStickers,
  createChallengeSticker,
  updateChallengeSticker,
  deleteChallengeSticker,
  createGoalCategory,
  getGoalCategories,
  updateGoalCategory,
  deleteGoalCategory,
  getGoalCategoryLevelOptions,
  updateChallengeReview,
};
