import ChipList from '@component/ChipList';
import Dropdown from '@component/Dropdown';
import FlexBox from '@component/FlexBox/FlexBox';
import useGetCouponList from '@queries/Race/useGetCouponList';
import { flatMapInfiniteData } from '@utils/query.utils';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import ReactDragList from 'react-drag-list';
import { Button, Grid } from 'semantic-ui-react';
import * as S from './RaceCouponAllDownloadModule.styles';
import { ColorSample } from '@component/ColorSample';
import DownloadIcon from './DownloadIcon';

type RaceCouponAllDownloadModuleProps = {
  sectionIdx: number;
  moduleIdx: number;
  module: any;
  handleChange: (e: { target: { name: string; value: any } }) => void;
};

const RaceCouponAllDownloadModule = ({
  sectionIdx,
  moduleIdx,
  module,
  handleChange,
}: RaceCouponAllDownloadModuleProps) => {
  const couponIds = (module.data.couponIds || []) as number[];
  const { buttonBgColor, buttonTextColor, bgColor } = module.data;

  const [keyword, setKeyword] = useState('');

  // ids로 쿠폰 정보 불러오는 api
  const { data } = useGetCouponList({
    ids: couponIds,
  });

  const couponList = flatMapInfiniteData(data, 'coupons', 'id');

  // 드롭다운에서 쿠폰 리스트 불러오는 api
  const {
    data: _allCoupons,
    hasNextPage,
    fetchNextPage,
  } = useGetCouponList({
    keyword,
  });

  const allCouponList = flatMapInfiniteData(_allCoupons, 'coupons', 'id');

  const getDropdownCouponName = (couponId: number) => {
    const coupon = allCouponList.find(({ id }) => id === couponId);
    if (!coupon) return `[${couponId}]`;
    const { id, name, description, discountAmount } = coupon;
    return `[${id}] ${name} / ${discountAmount.toLocaleString()}원 (${description})`;
  };

  const getCouponName = (couponId: number) => {
    const coupon = couponList.find(({ id }) => id === couponId);
    if (!coupon) return `[${couponId}]`;
    const { id, name, description, discountAmount } = coupon;
    return `[${id}] ${name} / ${discountAmount.toLocaleString()}원 (${description})`;
  };

  const handleOrderChange = async (idx: number, direction: string) => {
    const _couponIds = [...couponIds];

    const removed = _couponIds.splice(idx, 1);
    if (direction === 'up') {
      if (idx === 0) return;
      _couponIds.splice(idx - 1, 0, removed[0]);
    } else {
      if (idx === couponIds.length - 1) return;
      _couponIds.splice(idx + 1, 0, removed[0]);
    }
    handleModuleChange('couponIds', _couponIds);
  };

  type CouponAllType = {
    buttonTextColor: string;
    buttonBgColor: string;
    bgColor: string;
    couponIds: number[];
  };

  const handleModuleChange = <T extends keyof CouponAllType>(
    key: T,
    value: CouponAllType[T],
  ) => {
    handleChange({
      target: {
        name: `${sectionIdx}-${moduleIdx}-${key}`,
        value,
      },
    });
  };

  const handleUpdateOrder = async (e: unknown, updated: any[]) => {
    handleModuleChange(
      'couponIds',
      updated.map(({ id }) => id),
    );
  };

  const handleSearchKeyword = useCallback(
    _.debounce((text) => {
      setKeyword(text);
    }, 500),
    [],
  );

  const handleRemove = (idx: number) => {
    const updatedIds = [...couponIds];
    updatedIds.splice(idx, 1);
    handleModuleChange('couponIds', updatedIds);
  };

  const handleColorChange = (
    key: 'buttonTextColor' | 'buttonBgColor' | 'bgColor',
    value: string,
  ) => {
    const isHexColor = value.match(/^#/g);
    if (!isHexColor) {
      return alert('색상은 #로 시작해야 합니다.');
    }

    handleModuleChange(key, value);
  };

  const chipList = couponIds.map((id) => getCouponName(id));

  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 랜선대회 쿠폰 전체 받기</h4>

      <CouponUISetting
        buttonBgColor={buttonBgColor}
        buttonTextColor={buttonTextColor}
        bgColor={bgColor}
        onChange={handleColorChange}
      />

      <ChipList
        style={{ marginBottom: 6 }}
        chipList={chipList}
        removable
        onRemovePress={handleRemove}
      />

      <Dropdown
        search
        label="쿠폰 목록"
        placeholder="쿠폰을 선택하세요."
        options={allCouponList.map(({ id }) => ({
          text: getDropdownCouponName(id),
          value: id,
        }))}
        keyExtractor={(item) => item}
        onSearchChange={handleSearchKeyword}
        onReachEnd={hasNextPage ? fetchNextPage : undefined}
        onChange={(id) => {
          if (!id || couponIds.includes(id)) return;
          handleModuleChange('couponIds', [...couponIds, id]);
        }}
      />

      <h5>노출 순서</h5>

      <Grid>
        <Grid.Row>
          <Grid.Column width={12}>쿠폰명</Grid.Column>
          <Grid.Column width={2}>순서변경</Grid.Column>
          <Grid.Column width={2}>삭제</Grid.Column>
        </Grid.Row>
      </Grid>

      <ReactDragList
        rowKey="id"
        dataSource={couponIds.map((id) => ({
          id,
        }))}
        handles={false}
        onUpdate={handleUpdateOrder}
        row={(option: any, idx) => {
          const couponId = option.id as number;
          return (
            <Grid
              key={`${couponId}-${idx}`}
              style={{
                flexWrap: 'nowrap',
                borderBottom: '1px solid #eee',
              }}
            >
              <Grid.Column width={12}>
                <div>{getCouponName(couponId)}</div>
              </Grid.Column>
              <Grid.Column width={2}>
                <Button
                  size="mini"
                  icon="angle up"
                  onClick={() => handleOrderChange(idx, 'up')}
                />
                <Button
                  size="mini"
                  icon="angle down"
                  onClick={() => handleOrderChange(idx, 'down')}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Button
                  size="mini"
                  icon="delete"
                  onClick={() => handleRemove(idx)}
                />
              </Grid.Column>
            </Grid>
          );
        }}
      />
    </>
  );
};

export default RaceCouponAllDownloadModule;

type CouponUISettingProps = {
  buttonTextColor: string;
  buttonBgColor: string;
  bgColor: string;
  onChange: (
    key: 'buttonTextColor' | 'buttonBgColor' | 'bgColor',
    value: string,
  ) => void;
};

const CouponUISetting = ({
  buttonTextColor: _buttonTextColor,
  buttonBgColor: _buttonBgColor,
  bgColor: _bgColor,
  onChange,
}: CouponUISettingProps) => {
  const buttonTextColor = _buttonTextColor ?? DEFAULT_BUTTON_TEXT_COLOR;
  const buttonBgColor = _buttonBgColor ?? DEFAULT_BUTTON_BG_COLOR;
  const bgColor = _bgColor ?? DEFAULT_BG_COLOR;

  return (
    <FlexBox.Column gap={10}>
      <S.CouponWrapper bgColor={bgColor}>
        <S.CouponUI bgColor={buttonBgColor} color={buttonTextColor}>
          쿠폰 한 번에 받기
          <DownloadIcon color={buttonTextColor} />
        </S.CouponUI>
      </S.CouponWrapper>

      <S.RowContainer>
        <S.RowFormField>
          <label>버튼글자색</label>
          <input
            name="buttonText"
            value={buttonTextColor}
            onChange={(e) => onChange('buttonTextColor', e.target.value)}
          />
          <ColorSample color={buttonTextColor} />
        </S.RowFormField>
        <S.RowFormField margin={'0 0 0 10px'}>
          <label>버튼배경색</label>
          <input
            name="buttonTextColor"
            value={buttonBgColor}
            onChange={(e) => onChange('buttonBgColor', e.target.value)}
          />
          <ColorSample color={buttonBgColor} />
        </S.RowFormField>
        <S.RowFormField margin={'0 0 0 10px'}>
          <label>배경색</label>
          <input
            name="buttonBgColor"
            value={bgColor}
            onChange={(e) => onChange('bgColor', e.target.value)}
          />
          <ColorSample color={bgColor} />
        </S.RowFormField>
      </S.RowContainer>
    </FlexBox.Column>
  );
};

const DEFAULT_BUTTON_TEXT_COLOR = '#FF4343';
const DEFAULT_BUTTON_BG_COLOR = '#FFEDED';
const DEFAULT_BG_COLOR = '#FFFFFF';
