import { useInfiniteQuery } from '@tanstack/react-query';
import {
  getNextPageParam,
  withTypedInfiniteLoad,
} from '../../utils/query.utils';
import { apis } from '../../api';
import {
  Settlement,
  SettlementFilter,
  SettlementSearch,
} from '../../container/Collabo/Settlement/Settlement.types';
import { PAGE_LIMIT } from '../../container/Collabo/Settlement/Settlement.constants';

export type UseCollaboSettlementListParam = {
  isConfirmed: boolean;
  companyId?: number;
} & SettlementFilter &
  SettlementSearch;

export type UseCollaboSettlementListResponse = {
  isEnd: boolean;
  totalCount: number;
  settlements: Settlement[];
};

const typedGetSettlementList: (
  r: UseCollaboSettlementListParam,
) => Promise<UseCollaboSettlementListResponse> = apis.getSettlementList;

export const useCollaboSettlementList = (
  params: UseCollaboSettlementListParam,
) => {
  return useInfiniteQuery({
    queryKey: ['COLLABO_SETTLEMENT_LIST', { ...params }],
    queryFn: withTypedInfiniteLoad(typedGetSettlementList, params, PAGE_LIMIT),
    initialPageParam: 0,
    getNextPageParam,
  });
};
