export const INITIAL_STICKER = {
  challengeId: 0,
  companyId: 0,
  imageUrl: '',
  positionX: 0.1,
  positionY: 0.1,
  width: 0.1,
  isFixed: true,
  order: 0,
};
