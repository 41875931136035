import React from 'react';
import { Container } from 'semantic-ui-react';
import EventForm from '../EventForm';
import { apis } from '../../../../api';
import { useHistory } from 'react-router-dom';

const EventCreate = () => {
  const history = useHistory();

  const initEventData = {
    eventName: '',
    startTime: '',
    endTime: '',
    data: {
      og: {
        image: '',
        title: '',
        description: '',
      },
      useCTAButton: 'false',
      ctaButton: {
        text: '',
        action: '',
        data: {},
      },
      type: 'challenge',
      useNavigation: 'true',
      sections: [],
    },
  };

  const addEvent = async (eventData) => {
    const res = await apis.addEventData(eventData);
    // 이벤트 정보 없으면 에러
    if (res.event) {
      const url = `https://web${
        process.env.REACT_APP_ENV_PHASE === 'STAGE' ? '-stage' : ''
      }.chlngers.com/events/challenge/${eventData.eventName}`;
      const message = `이벤트 추가가 완료되었어요. 페이지를 확인해보시겠어요?\n${url}`;
      if (confirm(message)) {
        window.open(url, '_blank');
      }
      history.goBack();
    }
  };

  return (
    <Container>
      <EventForm
        initEventData={initEventData}
        addOrEditEvent={(eventData) => addEvent(eventData)}
      />
    </Container>
  );
};

export default EventCreate;
