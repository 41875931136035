import { ChallengeCustomField } from '@types';
import { format } from 'date-fns';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

// achievementInfoList는 별도 api로 데이터를 가져와서 관리한다..
export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'achievementInfoList'>
> = () => {
  return {};
};

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'achievementInfoList'>,
  Extract<ChallengeCustomField, { name: 'achievementInfoList' }>
> = ({ achievementInfoList }) => {
  if (achievementInfoList.length === 0) return [];

  return [
    {
      name: 'achievementInfoList',
      values: achievementInfoList.map((info) => {
        if (
          !info.dueStartDate ||
          !info.dueStartTime ||
          !info.dueEndDate ||
          !info.dueEndTime
        ) {
          throw Error("this shouldn't happened");
        }
        return {
          dueStartDateTime: formatDate(
            createDate(info.dueStartDate, info.dueStartTime),
          ),
          dueEndDateTime: formatDate(
            createDate(info.dueEndDate, info.dueEndTime),
          ),
        };
      }),
    },
  ];
};

const createDate = (date: string, time: string) => new Date(`${date} ${time}`);
const formatDate = (date: Date) => format(date, "yyyy-MM-dd'T'HH:mm:ssXXX");
