import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import TextInput from '@component/TextInput';
import { ShortAnswerQuestion as ShortAnswerQuestionType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

type MultipleChoiceQuestionProps = {
  question: ShortAnswerQuestionType;
  questionIndex: number;
  onQuestionChange: <T extends keyof ShortAnswerQuestionType>(input: {
    fieldName: T;
    value: ShortAnswerQuestionType[T];
  }) => void;
};

const ShortAnswerQuestion = (props: MultipleChoiceQuestionProps) => {
  const { question, questionIndex, onQuestionChange } = props;
  return (
    <FlexBox.Column style={{ marginTop: 20 }}>
      <h4>질문 {questionIndex + 1}</h4>
      <FlexBox.Column style={{ marginLeft: 12 }}>
        <Label>질문 내용</Label>
        <TextInput
          style={{ marginBottom: 12 }}
          value={question.title}
          placeholder="질문 내용"
          onChange={(text) =>
            onQuestionChange({
              fieldName: 'title',
              value: text,
            })
          }
        />
        <Label>Placeholder</Label>
        <TextInput
          value={question.placeholder || ''}
          placeholder="예시 답변"
          onChange={(text) =>
            onQuestionChange({
              fieldName: 'placeholder',
              value: text,
            })
          }
        />
      </FlexBox.Column>
    </FlexBox.Column>
  );
};

export default ShortAnswerQuestion;
