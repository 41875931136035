import { Modal, Button, Image } from 'semantic-ui-react';
import { ReportedAchievement } from 'src/types/achievement.types';

type AllAchievementModalProps = {
  isOpened: boolean;
  onClosePress: () => void;
  allAchievementFiles: ReportedAchievement[];
  authentications: {
    authenticationBadExamples: {
      image: string;
      title: string;
      text: string;
    }[];
    authenticationGoodExamples: {
      image: string;
      title: string;
      text: string;
    }[];
    authenticationMethod: string;
  };
};

const AllAchievementModal = (props: AllAchievementModalProps) => {
  const { isOpened, authentications, allAchievementFiles, onClosePress } =
    props;

  return (
    <Modal size="tiny" open={isOpened} onClose={onClosePress}>
      <Modal.Header>전체 인증샷 보기</Modal.Header>
      <Modal.Content>
        {authentications ? (
          <>
            <h5>인증방법</h5>
            <p>{authentications.authenticationMethod}</p>
            {allAchievementFiles.map((o) => {
              return (
                <div key={o.achievementFile.id}>
                  <Image size={'small'} src={o.achievementFile.url} />
                  <p>
                    {o.achievementFile.isDeleted && '[삭제됨] '}
                    {o.achievementFile.id} / 제출시간:{' '}
                    {o.achievementFile.submitDatetime} / 신고건수{' '}
                    {o.reportUsers.length} / 경고댓글수 {o.adminComments.length}
                  </p>
                  {o.reportUsers.length > 0 && (
                    <>
                      <h5>신고사유</h5>
                      {o.reportUsers.map((ru) => {
                        return <p>{ru.comment}</p>;
                      })}
                    </>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <p>인증샷이 없어요.</p>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" content="닫기" onClick={onClosePress} />
      </Modal.Actions>
    </Modal>
  );
};

export default AllAchievementModal;
