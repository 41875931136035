import React from 'react';
import { Icon } from 'semantic-ui-react';
import * as S from './ChipList.style';

type ChipListProps = {
  chipList: string[];
  style?: React.CSSProperties;
  removable?: boolean;
  onRemovePress?: (index: number) => void;
};

const ChipList = (props: ChipListProps) => {
  const { chipList, onRemovePress, style, removable = false } = props;

  return (
    <S.ChipListContainer style={style}>
      {chipList.map((item, index) => (
        <React.Fragment key={`${item}-${index}`}>
          <S.Chip>
            {item}
            {removable ? (
              <S.RemoveButton
                onClick={() => onRemovePress?.(index)}
                role="button"
              >
                <Icon name="delete" size="small" />
              </S.RemoveButton>
            ) : (
              <></>
            )}
          </S.Chip>
        </React.Fragment>
      ))}
    </S.ChipListContainer>
  );
};

export { ChipList };
