import React, { useCallback, useState } from 'react';
import { Accordion, Header, Icon } from 'semantic-ui-react';
import RaceDescriptionModule from '../RaceDescriptionModule/RaceDescriptionModule';
import { RaceHomeModule } from 'src/types/race.types';

const RaceHomeDescription = ({
  homeModule,
}: {
  homeModule: RaceHomeModule[];
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const handleClick = useCallback((curr: boolean) => {
    setCollapsed(!curr);
  }, []);

  return (
    <Accordion>
      <Accordion.Title
        active={!collapsed}
        onClick={() => handleClick(collapsed)}
      >
        <Header as={'h3'}>
          <Icon name="dropdown" /> 인증탭 - 대회상세
        </Header>
      </Accordion.Title>
      <Accordion.Content active={!collapsed}>
        {homeModule?.map((module, idx) => (
          <RaceDescriptionModule key={idx} module={module} />
        ))}
      </Accordion.Content>
    </Accordion>
  );
};

export default RaceHomeDescription;
