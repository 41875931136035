import React, { useCallback, useState } from 'react';
import {
  Card,
  Button,
  Image,
  Label,
  Grid,
  Checkbox,
  Icon,
  Divider,
} from 'semantic-ui-react';
import { apis } from '../../../api';
import moment from 'moment';
import { ReportedAchievement, ReviewResult } from 'src/types/achievement.types';
import ItemModal from './ItemModal';
import { ReviewTemplate } from '../Achievement.types';
import ReviewModal from './ReviewModal';
import DeleteModal from './DeleteModal';
import FlexBox from '@component/FlexBox/FlexBox';
import ReportReasonAccordion from './ReportReasonAccordion';
import WarnCommentHistoryModal from './WarnCommentHistoryModal';
import {
  REVIEW_RESULT_COLOR,
  REVIEW_RESULT_LABEL,
} from '../Achievement.constants';
import { deleteAchievementFile, getReviewComment } from '../Achievement.utils';
import AuthenticationAccordion from './AuthenticationAccordion';

const AchievementItem = ({
  achievement,
  reviewTemplates,
  defaultAuthenticationAccordionCollpased,
  defaultReportReasonAccordionCollpased,
  removeAchievement,
  updateAchievement,
}: AchievementItemProps) => {
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [reviewResult, setReviewResult] = useState<ReviewResult>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [
    reviewResultCommentHistoryModalOpen,
    setReviewResultCommentHistoryModalOpen,
  ] = useState(false);

  const showReviewModal = (_reviewResult: ReviewResult) => {
    setReviewModalOpen(true);
    setReviewResult(_reviewResult);
  };
  const closeReviewModal = useCallback(() => {
    setReviewModalOpen(false);
    setReviewResult(undefined);
  }, []);

  const showReviewResultCommentHistoryModalOpen = useCallback(
    () => setReviewResultCommentHistoryModalOpen(true),
    [],
  );
  const closeReviewResultCommentHistoryModalOpen = useCallback(
    () => setReviewResultCommentHistoryModalOpen(false),
    [],
  );

  const showDeleteModal = useCallback(() => setDeleteModalOpen(true), []);
  const closeDeleteModal = useCallback(() => setDeleteModalOpen(false), []);

  const showItemModal = useCallback(() => setItemModalOpen(true), []);
  const closeItemModal = useCallback(() => setItemModalOpen(false), []);

  const useItem = async () => {
    const res = await apis.useAchievementItem(achievement.id);
    if (res?.response?.data?.err) {
      return;
    }
    closeItemModal();
    removeAchievement(achievement);
  };

  const _deleteAchievementFile = async (
    deleteType: 'DELETE' | 'PUSH' | 'DELETE_AND_PASS',
  ) => {
    const { challenge, user } = achievement;
    // DELETE_COMMENT_OPTIONS의 value 0을 활용함
    const reviewComment = getReviewComment(0, user, challenge);

    deleteAchievementFile(
      achievement,
      deleteType,
      reviewComment,
      removeAchievement,
    );
  };

  const handleCheckbox = () => {
    updateAchievement({ ...achievement, isChecked: !achievement.isChecked });
  };

  const openImage = useCallback(() => {
    window.open(achievement.achievementFile.url, '_blank');
  }, [achievement]);

  const openAchievementDetailPage = useCallback(() => {
    window.open(`/achievements/${achievement.id}`, '_blank');
  }, [achievement]);

  const openUserDetailPage = useCallback(() => {
    window.open(`/users/${achievement.user.id}`, '_blank');
  }, [achievement]);

  const openChallengeDetailPage = useCallback(() => {
    window.open(`/challenges/${achievement.challenge.id}`, '_blank');
  }, [achievement]);

  return (
    <Grid.Column>
      <Card fluid>
        <Image
          as="a"
          src={achievement.achievementFile.url}
          size="large"
          centered
          onClick={openImage}
        />
        <div
          style={{
            position: 'absolute',
            top: 10,
            left: 5,
            right: 5,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Checkbox
            style={{
              transform: 'scale(1.5)',
              marginRight: 12,
              paddingLeft: 4,
              paddingTop: 4,
            }}
            checked={achievement.isChecked}
            value={achievement.achievementFile.id}
            onChange={handleCheckbox}
          />
          <Label
            size="small"
            color={
              REVIEW_RESULT_COLOR[achievement.achievementFile.reviewResult]
            }
            content={
              REVIEW_RESULT_LABEL[achievement.achievementFile.reviewResult]
            }
          />
        </div>
        <Card.Content>
          <Card.Header>
            <Label size="tiny" content={achievement.challenge.id} />
            <Label
              size="tiny"
              color="black"
              content={achievement.challenge.isOfficial ? '공식' : '개설'}
            />
            <Label
              size="tiny"
              color="black"
              content={`종료일: ${moment(achievement.challenge.endDate).format(
                'YY.MM.DD',
              )}`}
            />
            <div
              style={{ cursor: 'pointer', marginTop: 4, marginBottom: 4 }}
              onClick={openChallengeDetailPage}
              onKeyUp={() => {}}
            >
              <h5>
                {achievement.challenge.title} <Icon name="linkify" />
              </h5>
            </div>
          </Card.Header>

          <Divider fitted />

          <Card.Description>
            <AuthenticationAccordion
              challenge={achievement.challenge}
              defaultCollapsed={defaultAuthenticationAccordionCollpased}
            />
            <ReportReasonAccordion
              achievement={achievement}
              defaultCollapsed={defaultReportReasonAccordionCollpased}
            />
            <FlexBox.Column gap={4}>
              <FlexBox.Row>
                <Label
                  size="small"
                  content={`인증카드 id: ${achievement.id}`}
                />
                <Label
                  size="small"
                  content={`인증샷 id: ${achievement.achievementFile.id}`}
                />
              </FlexBox.Row>
              <FlexBox.Row>
                <Label
                  size="small"
                  as="a"
                  icon="user"
                  content={achievement.user.id}
                  onClick={openUserDetailPage}
                />
              </FlexBox.Row>
              {achievement.reportUsers.filter((ru) => ru.reportReason === 4)
                .length > 0 && (
                <FlexBox.Row>
                  <Label size="small" color="red" basic>
                    악성유저 신고{' '}
                    {
                      achievement.reportUsers.filter(
                        (ru) => ru.reportReason === 4,
                      ).length
                    }
                    건
                  </Label>
                </FlexBox.Row>
              )}
              <div>{achievement.achievementFile.memo}</div>
              <FlexBox.Row>
                <div
                  style={{ marginTop: 4, cursor: 'pointer' }}
                  onClick={openAchievementDetailPage}
                  onKeyUp={() => {}}
                >
                  <h5>
                    인증 상세 페이지로 이동 <Icon name="linkify" />
                  </h5>
                </div>
              </FlexBox.Row>
            </FlexBox.Column>

            <Divider />

            <FlexBox.Column gap={4}>
              <h5 style={{ margin: 0 }}>동일 챌린지 인증 처리 결과</h5>
              <FlexBox.Row>
                <Label
                  size="tiny"
                  content={`레드 ${achievement.redCount}건`}
                  color="red"
                />
                <Label
                  size="tiny"
                  content={`실패 ${achievement.failCount}건`}
                  color="black"
                />
                <Label
                  as="a"
                  size="tiny"
                  color="grey"
                  onClick={showReviewResultCommentHistoryModalOpen}
                  content={`경고댓글 ${achievement.comments.length}건`}
                />
              </FlexBox.Row>
            </FlexBox.Column>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <FlexBox.Column gap={4}>
            <FlexBox.Row>
              <Button
                size="mini"
                color="red"
                onClick={() => showReviewModal('RED')}
              >
                Red발급
              </Button>
              <Button
                size="mini"
                color="black"
                onClick={() => _deleteAchievementFile('PUSH')}
              >
                실패(푸시)
              </Button>
              <Button size="mini" onClick={showDeleteModal}>
                실패처리
              </Button>
              <Button
                size="mini"
                color="grey"
                onClick={() => showReviewModal('COMMENT')}
              >
                경고댓글
              </Button>
            </FlexBox.Row>
            <FlexBox.Row>
              <Button
                size="mini"
                color="blue"
                floated="left"
                onClick={() => showReviewModal('PASS')}
              >
                성공처리
              </Button>
              <Button
                size="mini"
                color="teal"
                floated="left"
                onClick={showItemModal}
              >
                인증패스
              </Button>
            </FlexBox.Row>
          </FlexBox.Column>
        </Card.Content>
      </Card>

      <WarnCommentHistoryModal
        achievement={achievement}
        modalOpen={reviewResultCommentHistoryModalOpen}
        close={closeReviewResultCommentHistoryModalOpen}
      />

      <ReviewModal
        achievement={achievement}
        reviewResult={reviewResult}
        modalOpen={reviewModalOpen}
        templates={reviewTemplates}
        close={closeReviewModal}
        removeAchievement={removeAchievement}
      />

      <DeleteModal
        achievement={achievement}
        modalOpen={deleteModalOpen}
        close={closeDeleteModal}
        removeAchievement={removeAchievement}
      />

      <ItemModal
        modalOpen={itemModalOpen}
        useItem={useItem}
        close={closeItemModal}
      />
    </Grid.Column>
  );
};

export default AchievementItem;

type AchievementItemProps = {
  achievement: ReportedAchievement;
  reviewTemplates: ReviewTemplate[];
  defaultAuthenticationAccordionCollpased: boolean;
  defaultReportReasonAccordionCollpased: boolean;
  removeAchievement: (achievement: ReportedAchievement) => void;
  updateAchievement: (achievement: ReportedAchievement) => void;
};
