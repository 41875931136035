import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import TextInput from '@component/TextInput';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './ThirdTermUrlForm.serializer';
import {
  useThirdTermUrl,
  useThirdTermUrlActions,
} from './ThirdTermUrlForm.store';
import { validate } from './ThirdTermUrlForm.validator';

const ThirdTermUrlForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'thirdTermUrl'>,
  Pick<ChallengeFormType, 'thirdTermUrl'>,
  Pick<ReqCreateOrUpdateChallenge, 'thirdTermUrl'>
> = () => {
  const { thirdTermUrl, companyId } = useThirdTermUrl();

  const { setThirdTermUrl } = useThirdTermUrlActions();

  const validated = validate({ thirdTermUrl, companyId });

  return (
    <TextInput
      label="제 3자 이용약관"
      value={thirdTermUrl}
      onChange={setThirdTermUrl}
      errorMsg={!validated.isValid ? validated.message : ''}
    />
  );
};

export default ThirdTermUrlForm;

ThirdTermUrlForm.validate = validate;
ThirdTermUrlForm.convertData2Form = convertData2Form;
ThirdTermUrlForm.convertForm2Data = convertForm2Data;
