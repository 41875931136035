import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import TextInput from '@component/TextInput';
import { Race } from '@types';

type VideoModuleProps = {
  idx: number;
  module: Race.RaceYouTubeDescriptionModule;
  handleChange: (idx: number, key: any, youtubeId: string) => void;
};

const VideoModule = ({ idx, module, handleChange }: VideoModuleProps) => {
  return (
    <FlexBox.Column gap={6}>
      <Label>유튜브 ID (https://www.youtube.com/watch?v= 뒤에 붙는 것)</Label>
      <TextInput
        value={module.youtubeId}
        onChange={(text) => handleChange(idx, 'youtubeId', text.trim())}
      />
    </FlexBox.Column>
  );
};

export default VideoModule;
