import {
  ChallengeCustomField,
  ChallengeQnaCustomField,
} from 'src/types/customField.types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'qna'>,
  Extract<ChallengeCustomField, { name: 'qna' }>
> = ({ qna }) => {
  if (!qna.questions.length) return [];

  return [
    {
      name: 'qna',
      values: { questions: qna.questions || [] },
    },
  ];
};

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'qna'>
> = (customFields) => {
  const qna = customFields.find(({ name }) => name === 'qna');
  if (!qna) return {} as EmptyObj;

  return {
    qna: {
      questions:
        (qna.values as ChallengeQnaCustomField['values'])?.questions || [],
    },
  };
};
