import { colors } from '@constant/colors';
import styled from 'styled-components';

export const ChipListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
`;

export const Chip = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  background: ${colors.GRAY_200};
  white-space: pre-wrap;
  border-radius: 6px;
`;

export const RemoveButton = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
`;
