export const types = {
  SET_CHALLENGES__CHALLENGE: 'SET_CHALLENGES__CHALLENGE',
  SET_ACTIVE_CHALLENGES__CHALLENGE: 'SET_ACTIVE_CHALLENGES__CHALLENGE',
};

export const setChallenges = (challenges) => ({
  type: types.SET_CHALLENGES__CHALLENGE,
  challenges,
});

export const setActiveChallenges = (activeChallenges) => ({
  type: types.SET_ACTIVE_CHALLENGES__CHALLENGE,
  activeChallenges,
});
