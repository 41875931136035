export const getBannerTextLineCount = (banner) => {
  const textLineCount = {
    topBadge: 0,
    title: 0,
    subtitle: 0,
  };
  if (
    banner.info.specialBadgeText?.length > 0 ||
    banner.info.collaborationBadgeText?.length > 0
  )
    textLineCount.topBadge += 1;
  if (banner.title.length > 0)
    textLineCount.title += (banner.title.match(/\n/g) || []).length + 1;
  if (banner.subtitle.length > 0)
    textLineCount.subtitle += (banner.subtitle.match(/\n/g) || []).length + 1;

  return textLineCount;
};
