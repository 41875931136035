import { useQuery } from '@tanstack/react-query';
import getQueryKey from './getQueryKey';
import { apis } from '@api';

export function useGetPurchaseChallenge(id: number) {
  return useQuery({
    queryKey: getQueryKey('GET_PURCHASE_CHALLENGE_DETAIL', { id }),
    queryFn: () => apis.purchase.getPurchaseChallengeDetail(id),
  });
}
