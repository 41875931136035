import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { NaverBlogInfoListItem } from '../../NewChallengeForm.types';
import { DEFAULT_ITEM } from './NaverBlogInfoListForm.constants';

export const useNaverBlogInfoList = () =>
  useChallengeFormStore((s) => s.challenge.naverBlogInfoList);

export const useValidationData = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      purchaseInfo: s.challenge.purchaseInfo,
      instagramInfoList: s.challenge.instagramInfoList,
      achievementCount: s.challenge.achievementCount,
    })),
  );

export const useUpdateNaverBlogInfoActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const add = () =>
    updateChallengeForm((prev) => ({
      ...prev,
      naverBlogInfoList: [...prev.naverBlogInfoList, DEFAULT_ITEM],
    }));

  const update = (index: number, data: NaverBlogInfoListItem) =>
    updateChallengeForm((prev) => ({
      ...prev,
      naverBlogInfoList: prev.naverBlogInfoList.map((item, i) =>
        i === index ? data : item,
      ),
    }));

  const remove = (index: number) =>
    updateChallengeForm((prev) => ({
      ...prev,
      naverBlogInfoList: prev.naverBlogInfoList.filter(
        (item, i) => i !== index,
      ),
    }));

  return { update, add, remove };
};
