import Accordion from '@component/Accordion/Accordion';
import {
  useChallengeDistanceActions,
  useChallengeDistanceStore,
} from './DistanceForm.store';
import { ChallengeQueries } from '@queries/index';
import { validate } from './DistanceForm.validator';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { ChallengeCustomField, ChallengeDistanceCustomField } from '@types';
import { useGetGoalDetail } from '@queries/Challenge/useGetGoalDetail';
import ErrorMsg from '@component/ErrorMsg';
import { convertData2Form, convertForm2Data } from './DistanceForm.serializer';
import Dropdown from '@component/Dropdown';
import NumberInput from '@component/NumberInput';
import Button from '@component/Button';

export const DistanceForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'distance'>,
  Extract<ChallengeCustomField, { name: 'distance' }>
> = () => {
  const { distance, goalId, startDate, endDate } = useChallengeDistanceStore();
  const validated = validate({
    distance,
  });

  const { data } = ChallengeQueries.useGetAchievementCardList(
    {
      goalId: goalId || undefined,
      startDate,
      endDate,
    },
    {
      enabled: !!goalId,
    },
  );

  const achievements = data?.achievements ?? [];

  const { data: goalDetail } = useGetGoalDetail(goalId || 0);

  const { setDistance, clear } = useChallengeDistanceActions(achievements);

  const handleChangeGoal = (goal: number | null) => {
    if (goal === null) return;
    setDistance({ goal, unit: distance[0]?.unit ?? 'KM' });
  };

  const handleChangeUnit = (option?: (typeof OPTIONS)[0]) => {
    if (!option) return;
    setDistance({ goal: distance[0]?.goal ?? 0, unit: option.value });
  };

  const handleClear = () => {
    if (window.confirm('거리 정보를 초기화 하시겠습니까?')) {
      clear();
    }
  };

  if (goalDetail?.goalType !== 'DISTANCE') return <></>;

  return (
    <Accordion title="거리 정보 설정" as="h5">
      <ErrorMsg text={validated.isValid ? '' : validated.message} />

      <Button onClick={handleClear} style={{ width: 100 }}>
        모두 초기화
      </Button>

      <NumberInput
        label="목표 거리 (숫자만 입력 가능)"
        value={distance[0]?.goal ?? 0}
        onChange={handleChangeGoal}
      />
      <Dropdown
        label="단위 선택"
        value={distance[0]?.unit}
        options={OPTIONS}
        onChange={(value) => {
          handleChangeUnit(OPTIONS.find((v) => v.value === value));
        }}
        keyExtractor={(item) => item}
      />
    </Accordion>
  );
};

DistanceForm.validate = validate;
DistanceForm.convertData2Form = convertData2Form;
DistanceForm.convertForm2Data = convertForm2Data;

const OPTIONS: {
  text: ChallengeDistanceCustomField['values'][0]['unit'];
  value: ChallengeDistanceCustomField['values'][0]['unit'];
}[] = [
  { text: 'M', value: 'M' },
  { text: 'KM', value: 'KM' },
];
