import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import readXlsxFile from 'read-excel-file';
import { QuestionAnswer } from '../../../types';

type BAtchAnswerModalProps = {
  batchAnswerModalOpen: boolean;
  onAnswerQuestionList: (param: {
    values: { questionId: number; answer: QuestionAnswer }[];
  }) => Promise<void>;
  closeBatchAnswerModal: () => void;
};

const BatchAnswerModal = ({
  batchAnswerModalOpen,
  onAnswerQuestionList,
  closeBatchAnswerModal,
}: BAtchAnswerModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [excelInfoList, setExcelInfoList] = useState<
    {
      questionId: number;
      questionCategoryId: number;
      userId: number;
      userNickname: string;
      answer: string;
      tags: string[];
    }[]
  >([]);

  const readExcelFile: React.ChangeEventHandler<HTMLInputElement> = async (
    e,
  ) => {
    const data = e.target.files?.[0];
    if (!data) return;
    const rows = await readXlsxFile(data);
    const excelInfo = rows
      .slice(1)
      .filter((row) => {
        // 발송할 답변 내용이 존재하는지 체크
        return !!row[6];
      })
      .map((row) => {
        const userNickname = String(row[2]);
        return {
          questionId: Number(row[0]),
          questionCategoryId: Number(row[23]),
          userId: Number(row[1]),
          userNickname,
          answer: replaceNicknameTemplate(String(row[6]), userNickname),
          tags: JSON.parse(String(row[24])),
        };
      });
    setExcelInfoList(excelInfo);
  };

  const submit = useCallback(
    async (answerType: QuestionAnswer['answerType']) => {
      const answerList = excelInfoList.map((excelInfo) => {
        const { questionId, answer, questionCategoryId, tags } = excelInfo;
        return {
          questionId,
          answer: {
            answer,
            answerImageUrls: [],
            answerType,
            tags,
            questionCategoryId,
          },
        };
      });
      setLoading(true);
      try {
        await onAnswerQuestionList({ values: answerList });
        closeBatchAnswerModal();
      } finally {
        setLoading(false);
      }
    },
    [closeBatchAnswerModal, excelInfoList, onAnswerQuestionList],
  );

  return (
    <Modal
      size="small"
      open={batchAnswerModalOpen}
      onClose={closeBatchAnswerModal}
    >
      <Modal.Header>일괄답변</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>
              문의id, 유저id, 유저닉네임, 답변이 포함된 엑셀 파일 업로드
            </label>
            <label>
              {'엑셀 내 ${' + 'nickname' + '} 부분은 유저닉네임으로 치환됩니다'}
            </label>
            <input
              style={{ marginBottom: 12 }}
              type="file"
              id="input"
              onChange={readExcelFile}
            />
          </Form.Field>
        </Form>
        {excelInfoList.length ? (
          <div style={styles.answerList}>
            {excelInfoList.map(({ userId, userNickname, answer }) => {
              return (
                <>
                  {`유저: ${userNickname}(${userId})`}
                  <div style={styles.answer}>{answer}</div>
                </>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="blue"
          loading={loading}
          content="일괄답변(푸시)"
          onClick={() => submit('PUSH')}
        />
        <Button color="black" content="닫기" onClick={closeBatchAnswerModal} />
      </Modal.Actions>
    </Modal>
  );
};

const styles: Record<string, React.CSSProperties> = {
  answerList: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    overflowY: 'scroll',
    marginBottom: 12,
    maxHeight: 500,
  },
  answer: {
    whiteSpace: 'pre-line',
    border: '1px solid black',
    marginRight: 12,
    marginBottom: 6,
  },
};

const replaceNicknameTemplate = (answer: string, nickname: string) => {
  return answer.replace(/\${nickname}/g, nickname);
};

export default BatchAnswerModal;
