import React, { FC, useCallback, useState } from 'react';
import { Table, Image, Button, Modal, Radio, Form } from 'semantic-ui-react';
import moment from 'moment';
import { UserSearchInfo } from 'src/types/user.types';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import setCurrencyFormat from '../../../global/setCurrencyFormat';
import { apis } from '../../../api';

const UserItem: FC<UserItemProps> = ({ user, goUserDetail, updateUser }) => {
  const [activeUser, setActiveUser] = useState<UserSearchInfo>(user);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [resetPwModalOpen, setResetPwModalOpen] = useState(false);
  const [restoreModalOpen, setRestoreModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const showEditUserModal = useCallback(() => {
    setEditUserModalOpen(true);
    setActiveUser(user);
  }, [user]);

  const closeEditUserModal = useCallback(() => {
    setEditUserModalOpen(false);
  }, []);

  const handleChangeActiveUser = (key: keyof UserSearchInfo, value: any) => {
    if (!activeUser) return;
    const val = typeof value === 'string' ? value.trim() : value;
    const _activeUser = { ...activeUser, [key]: val };
    setActiveUser(_activeUser);
  };

  const update = async () => {
    if (!activeUser) return;
    const res = await apis.user.updateUser(activeUser.id, {
      email: activeUser.email,
      name: activeUser.name,
      sex: activeUser.sex,
      source: activeUser.source,
      itemCount: activeUser.itemCount,
      phone: activeUser.phone,
      birthday: activeUser.birthday,
    });

    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    updateUser(activeUser);
    closeEditUserModal();
  };

  const showResetPwModal = useCallback(() => {
    setResetPwModalOpen(true);
    setActiveUser(user);
  }, [user]);

  const closeResetPwModal = useCallback(() => setResetPwModalOpen(false), []);

  const resetPw = async () => {
    LoadingIndicator.show();
    // TODO(Mason): 포팅 후 제거
    await apis.user.resetUserPassword({ email: activeUser.email });
    alert(`${activeUser.email} 로 임시 비밀번호 발송이 완료되었습니다.`);
    closeResetPwModal();
    LoadingIndicator.hide();
  };

  const showRestoreUserModal = useCallback(() => {
    setRestoreModalOpen(true);
    setActiveUser(user);
  }, [user]);

  const closeRestoreUserModal = useCallback(
    () => setRestoreModalOpen(false),
    [],
  );

  const restoreUser = async () => {
    LoadingIndicator.show();
    // TODO(Mason): 포팅 후 제거
    await apis.user.updateUser(activeUser.id, { isDeleted: false });
    const _user = {
      ...activeUser,
      isDeleted: false,
      deletedDatetime: undefined,
    };
    updateUser(_user);
    closeRestoreUserModal();
    LoadingIndicator.hide();
    alert('계정 복구 완료!');
  };

  const showDeleteUserModal = useCallback(() => {
    setDeleteModalOpen(true);
    setActiveUser(user);
  }, [user]);

  const closeDeleteUserModal = useCallback(() => setDeleteModalOpen(false), []);

  const deleteUser = async () => {
    // TODO(Mason): 포팅 후 제거
    await apis.user.updateUser(activeUser.id, {
      email: `${activeUser.email}_old`,
      phone: '',
      name: '',
      nickname: `${activeUser.nickname}_old`,
    });
    const { user: _user } = await apis.user.createUser({
      email: activeUser.email,
      password: '12341234',
      name: activeUser.name,
      phone: activeUser.phone,
      birthday: moment(activeUser.birthday).format('YYYY-MM-DD'),
      sex: activeUser.sex,
    });

    closeDeleteUserModal();
    alert(
      `${_user.id} / ${_user.email} / ${_user.nickname} 계정 생성이 완료되었습니다.`,
    );
    updateUser(user);
  };

  return (
    <>
      <Table.Row>
        <Table.Cell>{user.id ? user.id : 0}</Table.Cell>
        <Table.Cell>
          <Image size="tiny" src={user.pictureUrl} />
        </Table.Cell>
        <Table.Cell>{user.name}</Table.Cell>
        <Table.Cell>{user.nickname}</Table.Cell>
        <Table.Cell>{user.email}</Table.Cell>
        <Table.Cell>{user.phone}</Table.Cell>
        <Table.Cell>
          {user.sex}
          <br />
          <Button size="mini" compact onClick={showEditUserModal}>
            편집
          </Button>
        </Table.Cell>
        <Table.Cell>
          {user.source}
          <br />
          <Button size="mini" compact onClick={showEditUserModal}>
            편집
          </Button>
        </Table.Cell>
        <Table.Cell>
          {user.itemCount}
          <br />
          <Button size="mini" compact onClick={showEditUserModal}>
            편집
          </Button>
        </Table.Cell>
        <Table.Cell>
          <Button size="mini" compact onClick={showResetPwModal}>
            초기화
          </Button>
        </Table.Cell>
        <Table.Cell>
          {Object.keys(user.account).length > 0
            ? setCurrencyFormat(user.account.cashPoint)
            : 0}
        </Table.Cell>
        <Table.Cell>
          {Object.keys(user.account).length > 0
            ? setCurrencyFormat(user.account.prizePoint)
            : 0}
        </Table.Cell>
        <Table.Cell>
          {Object.keys(user.account).length > 0
            ? setCurrencyFormat(user.account.storePoint)
            : 0}
        </Table.Cell>
        <Table.Cell>
          {user.registerInfo.totalRegisterCount} <br />
        </Table.Cell>
        <Table.Cell>
          {user.deletedDatetime
            ? moment(user.deletedDatetime).format('YYYY-MM-DD HH:mm:ss')
            : '-'}
          {user.isDeleted ? (
            <Button size="mini" compact onClick={showRestoreUserModal}>
              복구
            </Button>
          ) : null}
          {user.isDeleted ? (
            <Button size="mini" compact onClick={showDeleteUserModal}>
              삭제/재가입
            </Button>
          ) : null}
        </Table.Cell>
        <Table.Cell>
          {user.banEndDatetime
            ? moment(user.banEndDatetime).format('YYYY-MM-DD HH:mm:ss')
            : '-'}
        </Table.Cell>
        <Table.Cell>
          <Button
            size="mini"
            content="상세페이지"
            onClick={() => goUserDetail(user.id)}
          />
        </Table.Cell>
      </Table.Row>
      <Modal size="tiny" open={editUserModalOpen} onClose={closeEditUserModal}>
        <Modal.Header>유저 정보 변경</Modal.Header>
        <Modal.Content>
          <div style={{ marginBottom: 10 }}>
            {activeUser.id} / {activeUser.name} / {activeUser.nickname}
          </div>
          <Form>
            <Form.Field>
              <label>이메일</label>
              <input
                value={activeUser.email}
                onChange={(e) =>
                  handleChangeActiveUser('email', e.target.value)
                }
              />
            </Form.Field>
            <Form.Field>
              <label>이름</label>
              <input
                name="name"
                value={activeUser.name}
                onChange={(e) => handleChangeActiveUser('name', e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>핸드폰번호</label>
              <input
                name="phone"
                value={activeUser.phone}
                onChange={(e) =>
                  handleChangeActiveUser('phone', e.target.value)
                }
              />
            </Form.Field>
            <Form.Group grouped>
              <label>성별</label>
              <Form.Field
                control={Radio}
                label="M"
                checked={activeUser.sex === 'M'}
                onChange={() => handleChangeActiveUser('sex', 'M')}
              />
              <Form.Field
                control={Radio}
                label="F"
                checked={activeUser.sex === 'F'}
                onChange={() => handleChangeActiveUser('sex', 'F')}
              />
              <Form.Field
                control={Radio}
                label="UNKNOWN"
                checked={activeUser.sex === 'UNKNOWN'}
                onChange={() => handleChangeActiveUser('sex', 'UNKNOWN')}
              />
            </Form.Group>
            <Form.Field>
              <label>생년월일</label>
              <input
                name="birthday"
                value={activeUser.birthday}
                onChange={(e) =>
                  handleChangeActiveUser('birthday', e.target.value)
                }
              />
            </Form.Field>
            <Form.Group grouped>
              <label>가입경로</label>
              <Form.Field
                control={Radio}
                label="ADMIN"
                checked={activeUser.source === 'ADMIN'}
                onChange={() => handleChangeActiveUser('source', 'ADMIN')}
              />
              <Form.Field
                control={Radio}
                label="RECOMMEND"
                checked={activeUser.source === 'RECOMMEND'}
                onChange={() => handleChangeActiveUser('source', 'RECOMMEND')}
              />
              <Form.Field
                control={Radio}
                label="ORGANIC"
                checked={activeUser.source === 'ORGANIC'}
                onChange={() => handleChangeActiveUser('source', 'ORGANIC')}
              />
              <Form.Field
                control={Radio}
                label="MARKETING"
                checked={activeUser.source === 'MARKETING'}
                onChange={() => handleChangeActiveUser('source', 'MARKETING')}
              />
            </Form.Group>
            <Form.Field>
              <label>아이템 수</label>
              <input
                value={activeUser.itemCount}
                onChange={(e) =>
                  handleChangeActiveUser('itemCount', Number(e.target.value))
                }
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="변경" onClick={update} />
          <Button color="black" content="닫기" onClick={closeEditUserModal} />
        </Modal.Actions>
      </Modal>
      <Modal size="tiny" open={resetPwModalOpen} onClose={closeResetPwModal}>
        <Modal.Header>비밀번호 초기화</Modal.Header>
        <Modal.Content>
          <div style={{ marginBottom: 10 }}>
            {user.id} / {user.email} / {user.name} / {user.nickname}
          </div>
          <p>
            비밀번호 초기화를 진행하시겠습니까? 초기화된 비밀번호는 유저분의
            이메일로 발송되며 유저분께서 직접 확인을 하셔야 합니다.
          </p>
          <p>
            만약 이메일 주소를 더이상 사용하지 않는다고 말씀하신다면, 이메일
            주소 변경 후 비밀번호 초기화를 시도해주세요.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="초기화" onClick={resetPw} />
          <Button color="black" content="닫기" onClick={closeResetPwModal} />
        </Modal.Actions>
      </Modal>

      <Modal
        size="tiny"
        open={restoreModalOpen}
        onClose={closeRestoreUserModal}
      >
        <Modal.Header>탈퇴유저 복구</Modal.Header>
        <Modal.Content>
          <div style={{ marginBottom: 10 }}>
            {activeUser.id} / {activeUser.name} / {activeUser.nickname} /{' '}
            {activeUser.deletedDatetime
              ? activeUser.deletedDatetime.toLocaleString()
              : '-'}
          </div>
          <p>
            탈퇴처리를 철회하시겠습니까? 탈퇴한지 3개월이 지난 경우에는 직접
            철회하지 말고 서버 개발자에게 복구 처리 요청해주세요.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="탈퇴철회" onClick={restoreUser} />
          <Button
            color="black"
            content="닫기"
            onClick={closeRestoreUserModal}
          />
        </Modal.Actions>
      </Modal>

      <Modal size="tiny" open={deleteModalOpen} onClose={closeDeleteUserModal}>
        <Modal.Header>탈퇴유저 삭제 및 재가입</Modal.Header>
        <Modal.Content>
          <div style={{ marginBottom: 10 }}>
            {activeUser.id} / {activeUser.name} / {activeUser.nickname} /{' '}
            {activeUser.deletedDatetime
              ? activeUser.deletedDatetime.toLocaleString()
              : '-'}
          </div>
          <p>
            탈퇴한 유저의 정보를 삭제하시겠습니까? 탈퇴한지 3개월이 지난 경우
            삭제한 뒤 새로 계정이 생성됩니다.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="삭제/재가입" onClick={deleteUser} />
          <Button color="black" content="닫기" onClick={closeDeleteUserModal} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default UserItem;

type UserItemProps = {
  user: UserSearchInfo;
  goUserDetail: (userId: number) => void;
  updateUser: (user: UserSearchInfo) => void;
};
