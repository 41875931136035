import { useState } from 'react';
import Button from '@component/Button';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import {
  DescriptionModuleFormType,
  OliveYoungReviewModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { DEFAULT_DATA } from './OliveYoungReviewModule.constant';
import ProductInfoForm from './components/ProductInfoForm/ProductInfoForm';
import PurchaseRequiredTextListForm from './components/PurchaseRequiredTextListForm/PurchaseRequiredTextListForm';
import ReviewInfoForm from './components/ReviewInfoForm/ReviewInfoForm';
import {
  DescriptionModule,
  DescriptionModuleConvertData2Form,
  DescriptionModuleConvertForm2Data,
} from '../../DescriptionModuleForm.type';
import { validate } from './OliveYoungReviewModule.validator';
import {
  convertData2Form,
  convertForm2Data,
} from './OliveYoungReviewModule.serializer';
import AddModuleModal from '../AddModuleModal/AddModuleModal';
import useSubModule from '../../hooks/useSubModule';
import PurchaseCheckTextListForm from './components/PurchaseCheckTextListForm/PurchaseCheckTextListForm';

type OliveYoungReviewModuleProps = {
  type: 'COLLABO-OLIVEYOUNG-REVIEW';
  data: OliveYoungReviewModuleFormType;
  moduleIndex: number;
  onUpdate: (index: number, item: DescriptionModuleFormType) => void;
};
const OliveYoungReviewModule: DescriptionModule<OliveYoungReviewModuleProps> = (
  props: OliveYoungReviewModuleProps,
) => {
  const { type, data, moduleIndex, onUpdate } = props;

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { List: SubModuleList, add: addSubModule } = useSubModule({
    data: data.subModule,
    onChange: (subModuleListData) => {
      onUpdate(moduleIndex, {
        type,
        data: {
          ...data,
          subModule: subModuleListData,
        },
      });
    },
  });

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);

  const handleChangePurchaseRequiredTextList = (
    purchaseRequiredTextList: OliveYoungReviewModuleFormType['purchaseRequiredTextList'],
  ) => {
    onUpdate(moduleIndex, {
      type,
      data: {
        ...data,
        purchaseRequiredTextList,
      },
    });
  };

  const handleChangePleaseCheckTextList = (
    pleaseCheckTextList: OliveYoungReviewModuleFormType['pleaseCheckTextList'],
  ) => {
    onUpdate(moduleIndex, {
      type,
      data: {
        ...data,
        pleaseCheckTextList,
      },
    });
  };

  const handleChange = (updated: Partial<OliveYoungReviewModuleFormType>) =>
    onUpdate(moduleIndex, {
      type,
      data: {
        ...data,
        ...updated,
      },
    });

  return (
    <FlexBox.Column
      style={{ border: '1px solid black', maxWidth: 720, padding: 12 }}
    >
      <Label>{type}</Label>
      <PurchaseRequiredTextListForm
        data={data.purchaseRequiredTextList}
        onChange={handleChangePurchaseRequiredTextList}
      />
      <hr />
      {SubModuleList}
      <hr />
      <Button
        text="서브 모듈 추가"
        bgColor="black"
        size="large"
        fontColor="white"
        onClick={showModal}
      />
      <hr />
      <ProductInfoForm data={data} onChange={handleChange} />
      <hr />
      <ReviewInfoForm data={data} onChange={handleChange} />
      <hr />
      <PurchaseCheckTextListForm
        data={data.pleaseCheckTextList}
        onChange={handleChangePleaseCheckTextList}
      />
      <hr />
      <AddModuleModal
        isSubModule
        open={modalVisible}
        onClose={hideModal}
        onAdd={addSubModule}
      />
    </FlexBox.Column>
  );
};

export default OliveYoungReviewModule;

OliveYoungReviewModule.moduleName = '올영리뷰';
OliveYoungReviewModule.defaultData = DEFAULT_DATA;
OliveYoungReviewModule.validate = validate;
OliveYoungReviewModule.convertForm2Data =
  convertForm2Data as DescriptionModuleConvertForm2Data;
OliveYoungReviewModule.convertData2Form =
  convertData2Form as DescriptionModuleConvertData2Form;
