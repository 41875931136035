import FlexBox from '@component/FlexBox/FlexBox';
import React, { useCallback, useState } from 'react';
import { Button, Grid, Input } from 'semantic-ui-react';

const Search = ({ getAchievements }: SearchProps) => {
  const [challengeId, setChallengeId] = useState(0);
  const [reportUserId, setReportUserId] = useState(0);

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    searchType: SearchType,
  ) => {
    if (e.key !== 'Enter') return;
    switch (searchType) {
      case 'CHALLENGE':
        getAchievements({ challengeId });
        break;
      case 'REPORT_USER':
        getAchievements({ reportUserId });
        break;
      default:
        break;
    }
  };

  const handleChallengeId = useCallback((value: string) => {
    if (Number.isNaN(Number(value))) return;
    setChallengeId(Number(value));
  }, []);

  const handleReportUserId = useCallback((value: string) => {
    if (Number.isNaN(Number(value))) return;
    setReportUserId(Number(value));
  }, []);

  return (
    <FlexBox.Column gap={4}>
      <FlexBox.Row gap={4}>
        <Input
          size="small"
          placeholder="챌린지 id로 인증샷 조회"
          value={challengeId > 0 ? challengeId : ''}
          onChange={(e) => handleChallengeId(e.target.value)}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
            handleKeyPress(e, 'CHALLENGE')
          }
        />
        <Button
          content="검색"
          size="small"
          onClick={() => getAchievements({ challengeId })}
        />
      </FlexBox.Row>
      <FlexBox.Row gap={4}>
        <Input
          size="small"
          placeholder="유저id로 신고 인증샷 조회"
          value={reportUserId > 0 ? reportUserId : ''}
          onChange={(e) => handleReportUserId(e.target.value)}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
            handleKeyPress(e, 'REPORT_USER')
          }
        />
        <Button
          content="검색"
          size="small"
          onClick={() => getAchievements({ reportUserId })}
        />
      </FlexBox.Row>
    </FlexBox.Column>
  );
};

export default Search;

type SearchType = 'CHALLENGE' | 'REPORT_USER';

type SearchProps = {
  getAchievements: ({
    challengeId,
    achievementId,
    reportUserId,
  }: {
    challengeId?: number;
    achievementId?: number;
    reportUserId?: number;
  }) => void;
};
