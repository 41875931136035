import { Image } from 'semantic-ui-react';
import { Race } from '@types';
import Preview from './Preview/Preview';
import * as S from '../../../Style';
import { RaceAuthenticationMethod } from 'src/types/race.types';

interface RaceAuthenticationMethodFieldProps {
  authenticationMethod: Race.RaceAuthenticationMethod;
  handleChange: <K extends keyof RaceAuthenticationMethod>(
    key: K,
    value: RaceAuthenticationMethod[K],
  ) => void;
  handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RaceAuthenticationMethodField = ({
  authenticationMethod,
  handleChange,
  handleImageChange,
}: RaceAuthenticationMethodFieldProps) => {
  return (
    <>
      <h3>인증 권장 방법</h3>
      <S.RowContainer>
        <S.Flex2>
          <Preview />
          <S.RowFormField>
            <label>인증샷 예시 (최대 3:4비율)</label>
            <input type="file" name="image" onChange={handleImageChange} />
            <Image src={authenticationMethod.image} size="small" />
          </S.RowFormField>
        </S.Flex2>
        <S.Flex2>
          <S.RowFormField>
            <label>인증 방법 타이틀 (1줄 권장)</label>
            <textarea
              rows={2}
              value={authenticationMethod.title}
              onChange={(e) => handleChange('title', e.target.value)}
            />
          </S.RowFormField>
          <S.RowFormField>
            <label>인증 방법 상세 (2줄 권장)</label>
            <textarea
              rows={2}
              value={authenticationMethod.description}
              onChange={(e) => handleChange('description', e.target.value)}
            />
          </S.RowFormField>
        </S.Flex2>
      </S.RowContainer>
    </>
  );
};

export default RaceAuthenticationMethodField;
