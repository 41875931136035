import { Filter, Payment } from './PedometerGiftiShopBuyers.types';

export const getFilterList = (buyers: Payment[]): Filter[] => {
  const filter: Filter[] = [...new Set(buyers.map((v) => v.itemInfo.name))].map(
    (itemName) => ({
      text: itemName,
      value: itemName,
    }),
  );
  return filter.reduce(
    (acc: Filter[], curr) => {
      acc.push(curr);
      return acc;
    },
    [{ text: '필터없음', value: '' }],
  );
};
export const isPayment = (payment: Payment | undefined): payment is Payment =>
  !!payment?.id;
