import React, { Component } from 'react';
import { Button, Container, Grid, Loader, Divider } from 'semantic-ui-react';
import api, { apiGo, apis } from '../../../api/index';
import GoalInfo from './GoalInfo';
import GoalReview from './GoalReview';
import _ from 'lodash';
import getLocal from '../../../global/local';
import { Link } from 'react-router-dom';

class GoalItemDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goal: {},
      isMounted: false,
      reviews: [],
      avgRate: 0,
      activeGoals: JSON.parse(getLocal('activeGoals')) || [],
      prevGoal: {},
      nextGoal: {},
    };
  }

  componentDidMount() {
    this.getGoal(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isMounted: false });
    this.getGoal(nextProps.match.params.id);
  }

  getGoal = async (id) => {
    const { activeGoals } = this.state;
    const { goal } = await apis.challenge.getGoalDetail(id);
    this.setState({ goal, isMounted: true });
    this.getGoalReview(goal);

    const a = _.find(activeGoals, (g) => g.id === parseInt(id, 10));
    const index = activeGoals.indexOf(a);
    const prevGoal = activeGoals[index - 1];
    const nextGoal = activeGoals[index + 1];
    this.setState({ prevGoal, nextGoal });
  };

  getGoalReview = async (goal) => {
    const res = await apis.challenge.getGoalReviews(goal.id);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    const { reviews } = res;
    this.setState({ reviews });
  };

  deleteGoalItem = async () => {
    const { goal } = this.state;
    apis.challenge.deleteGoal(goal.id);
    this.setState({ goal: { ...goal, isDeleted: true } });
  };

  handleDisplayChange = (e, { value }) => {
    const { reviews } = this.state;
    const review = _.find(reviews, (r) => r.comment.id === value);
    review.comment.isDisplayed = !review.comment.isDisplayed;
    this.setState({ reviews });
    api
      .put('/challenge-reviews/' + value, {
        isDisplayed: review.comment.isDisplayed,
      })
      .then((response) => {
        const review = response.data.data.challengeReview;
        console.log(review);
      });
  };

  refreshCacheForReviews = () => {
    apiGo
      .post('/cache/refresh/reviews', { goalIds: [this.state.goal.id] })
      .then(() => {
        alert('캐싱된 리뷰 데이터가 초기화 되었어요.');
      });
  };

  render() {
    const { avgRate, reviews, activeGoals, prevGoal, nextGoal, isMounted } =
      this.state;

    const { goal } = this.props.goal ? this.props : this.state;
    if (!isMounted) return <Loader active inline="centered" />;
    return (
      <Container style={styles.container}>
        <Grid columns="equal">
          <Grid.Column>
            {!_.isEmpty(prevGoal) && (
              <Button
                style={{ margin: 10 }}
                as={Link}
                to={{ pathname: '/goals/' + prevGoal.id }}
                fluid
              >
                {'이전 : ' + prevGoal.id + ' ' + prevGoal.title}
              </Button>
            )}
            {!_.isEmpty(nextGoal) && (
              <Button
                style={{ margin: 10 }}
                as={Link}
                to={{ pathname: '/goals/' + nextGoal.id }}
                fluid
              >
                {'다음 : ' + nextGoal.id + ' ' + nextGoal.title}
              </Button>
            )}
          </Grid.Column>
          <Grid.Column width={10}>
            <GoalInfo goal={goal} deleteGoalItem={this.deleteGoalItem} />
            <GoalReview
              reviews={reviews}
              handleDisplayChange={this.handleDisplayChange}
              refreshCacheForReviews={this.refreshCacheForReviews}
            />
            <Divider style={{ marginBottom: 100 }} hidden />
          </Grid.Column>
          <Grid.Column>
            {activeGoals.length > 0 &&
              activeGoals.map((g) => {
                if (g.id === parseInt(this.props.match.params.id, 10)) {
                  return (
                    <Button
                      color="blue"
                      key={g.id}
                      style={{ margin: 10 }}
                      as={Link}
                      to={{ pathname: '/goals/' + g.id }}
                      fluid
                    >
                      {g.id + ' ' + g.title}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      key={g.id}
                      style={{ margin: 10 }}
                      as={Link}
                      to={{ pathname: '/goals/' + g.id }}
                      fluid
                    >
                      {g.id + ' ' + g.title}
                    </Button>
                  );
                }
              })}
          </Grid.Column>
        </Grid>
        <Container style={styles.float}>
          <div className="ui two buttons">
            <Button
              as={Link}
              to={{ pathname: `/goals/${goal.id}/edit` }}
              target="_blank"
              rel="noopener noreferrer"
              basic
              color="blue"
            >
              편집
            </Button>
            <Button color="black" onClick={this.deleteGoalItem}>
              삭제
            </Button>
          </div>
        </Container>
      </Container>
    );
  }
}

export default GoalItemDetail;

const styles = {
  container: {
    marginTop: 20,
  },
  float: {
    width: '50%',
    bottom: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    borderTop: '1px solid #f6f6f6',
    backgroundColor: 'white',
    zIndex: 100,
  },
};
