import { Table } from 'semantic-ui-react';
import { imageFilters as defaultImageFilters } from '../../../../../constant/race';
import * as S from '../../../Style';
import _ from 'lodash';
import { RaceLeague } from 'src/types/race.types';
import { generateId } from '@utils/number';
import { RaceFormType } from '../../RaceForm.types';

type Props = {
  race: RaceFormType;
  setRace: React.Dispatch<React.SetStateAction<RaceFormType>>;
};

const LeagueField = ({ race, setRace }: Props) => {
  const addLeague = () => {
    const _race = _.cloneDeep(race);
    _race.leagues.push({
      id: generateId(),
      title: '',
      minUnitValue: '',
      maxUnitValue: '',
      stickers: [],
      imageFilters: [...defaultImageFilters],
    });
    setRace(_race);
  };

  const deleteLeague = (idx: number) => {
    const _race = _.cloneDeep(race);
    _race.leagues.splice(idx, 1);
    setRace(_race);
  };

  const handleLeagueChange: <K extends keyof RaceLeague>(
    idx: number,
    key: K,
    value: RaceLeague[K],
  ) => void = (idx, key, value) => {
    const _race = _.cloneDeep(race);
    _race.leagues[idx][key] = value;
    setRace(_race);
  };

  return (
    <>
      <h3>
        리그 정보
        <S.ButtonInForm content={'추가'} onClick={addLeague} />
      </h3>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>no</Table.HeaderCell>
            <Table.HeaderCell>리그명</Table.HeaderCell>
            <Table.HeaderCell>최소값</Table.HeaderCell>
            <Table.HeaderCell>최대값</Table.HeaderCell>

            <Table.HeaderCell>삭제</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {race.leagues.map((league, idx: number) => (
            <Table.Row key={league.id}>
              <Table.Cell>{idx + 1}</Table.Cell>
              <Table.Cell>
                <input
                  value={league.title}
                  onChange={(e) =>
                    handleLeagueChange(idx, 'title', e.target.value)
                  }
                />
              </Table.Cell>
              <Table.Cell>
                <input
                  value={String(league.minUnitValue)}
                  onChange={(e) =>
                    handleLeagueChange(
                      idx,
                      'minUnitValue',
                      Number.isNaN(Number(e.target.value))
                        ? league.minUnitValue
                        : Number(e.target.value),
                    )
                  }
                />
              </Table.Cell>
              <Table.Cell>
                <input
                  value={String(league.maxUnitValue)}
                  onChange={(e) =>
                    handleLeagueChange(
                      idx,
                      'maxUnitValue',
                      Number.isNaN(Number(e.target.value))
                        ? league.maxUnitValue
                        : Number(e.target.value),
                    )
                  }
                />
              </Table.Cell>
              <Table.Cell>
                <S.ButtonInForm
                  content={'삭제'}
                  onClick={() => deleteLeague(idx)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default LeagueField;
