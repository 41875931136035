import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { formatLocalDateTime } from '@utils/date.utils';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'registerStartDate'>,
  Pick<ReqCreateOrUpdateChallenge, 'registerStartDate'>
> = ({ registerStartDate }) => {
  if (!registerStartDate)
    throw Error("[RegisterStartDateForm]this shouldn't be happened");

  return { registerStartDate };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'registerStartDate'>,
  Pick<ChallengeFormType, 'registerStartDate'>
> = ({ registerStartDate }) => ({
  registerStartDate: formatLocalDateTime(new Date(registerStartDate)),
});
