import React from 'react';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import TextArea from '@component/TextArea';
import { DescriptionModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import {
  DescriptionModule,
  DescriptionModuleConvertData2Form,
  DescriptionModuleConvertForm2Data,
} from '../../DescriptionModuleForm.type';
import { validate } from './TextOnlyModule.validator';
import {
  convertData2Form,
  convertForm2Data,
} from './TextOnlyModule.serializer';

type TextOnlyModuleProps = {
  type: 'TEXT-ONLY';
  mainHeader: string;
  subHeader: string;
  body: string;
  moduleIndex: number;
  onUpdate: (index: number, item: DescriptionModuleFormType) => void;
};
const TextOnlyModule: DescriptionModule<TextOnlyModuleProps> = (
  props: TextOnlyModuleProps,
) => {
  const { type, mainHeader, subHeader, body, moduleIndex, onUpdate } = props;

  const handleUpdate = (fieldName: string, value: string) => {
    return onUpdate(moduleIndex, {
      type,
      mainHeader,
      subHeader,
      body,
      ...{ [fieldName]: value },
    });
  };

  return (
    <FlexBox.Column gap={6}>
      <Label>{type}</Label>
      <Label>메인헤더</Label>
      <TextArea
        value={mainHeader}
        onChange={(text) => handleUpdate('mainHeader', text)}
      />

      <Label>서브헤더</Label>
      <TextArea
        value={subHeader}
        onChange={(text) => handleUpdate('subHeader', text)}
      />

      <Label>바디</Label>
      <TextArea value={body} onChange={(text) => handleUpdate('body', text)} />
    </FlexBox.Column>
  );
};

TextOnlyModule.validate = validate;
TextOnlyModule.convertForm2Data =
  convertForm2Data as DescriptionModuleConvertForm2Data;
TextOnlyModule.convertData2Form =
  convertData2Form as DescriptionModuleConvertData2Form;
TextOnlyModule.moduleName = '글씨만';
TextOnlyModule.defaultData = {
  type: 'TEXT-ONLY',
  mainHeader: '',
  subHeader: '',
  body: '',
};
export default TextOnlyModule;
