import { useQuery } from '@tanstack/react-query';
import { getQueryKey } from './getQueryKey';
import { QueryHookOptions } from '../query.types';
import { apis } from '../../api';

export const useCrmLabelDetail = (
  id: number,
  params: UseCrmTagDetailParams = {},
) => {
  const { options = {} } = params;

  return useQuery({
    queryKey: getQueryKey('CRM_LABEL_DETAIL', { id }),
    queryFn: () => apis.getCrmLabelDetail(id),
    ...options,
  });
};

type UseCrmTagDetailParams = {
  options?: QueryHookOptions<typeof apis.getCrmLabelDetail>;
};
