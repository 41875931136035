import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useNaverIdRequired = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      naverIdRequired: s.challenge.naverIdRequired,
    })),
  );

export const useNaverIdRequiredAction = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setNaverIdRequired = (naverIdRequired: boolean) =>
    updateChallengeForm((prev) => ({
      ...prev,
      naverIdRequired,
    }));
  return { setNaverIdRequired };
};
