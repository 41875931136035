import { apis } from '@api/index';
import { GoalDetailInfo } from '@types';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import moment from 'moment';
import {
  useChallengeEditedStore,
  useChallengeFormStore,
} from '../../NewChallengeForm.store';
import { AchievementInfoItem } from '../../NewChallengeForm.types';
import { getChallengeId } from '../../NewChallengeForm.util';
import { useIsCustomGoal } from '../../hooks/useIsCustomGoal';
import { useUpdateAchievementInfoList } from './AchievementInfoListForm.store';

export const useInitializeEffect = () => {
  const { isCustomGoal, goal } = useIsCustomGoal();
  const { update } = useUpdateAchievementInfoList();
  const initializeAchievementInfoList = useChallengeEditedStore(
    (s) => s.initializeAchievementInfoList,
  );
  const { startDate } = useChallengeFormStore(
    useShallow((s) => ({
      startDate: s.challenge.startDate,
    })),
  );

  useEffect(() => {
    if (!isCustomGoal || !goal) return;

    const challengeId = getChallengeId();

    if (!challengeId) {
      // create인 경우
      update(getInitialInfoList(goal, startDate));
      return;
    }

    // edit인 경우
    apis.challenge
      .getChallengeAchievementSetting(challengeId)
      .then(({ achievementSetting: { infoList } }) => {
        // 현재 서버에 저장된 것이 없는 경우에는 기본값이라도 설정해야 함
        update(
          getInitialInfoList(goal, moment(startDate).format('YYYY-MM-DD')).map(
            (initialInfo, index) => {
              const info = infoList[index];
              if (!info) return initialInfo;
              return {
                ...initialInfo,
                ...info,
                isPublic: !!info.isPublic,
                prizeAmount: info.prizeAmount || null,
              };
            },
          ),
        );
        initializeAchievementInfoList(infoList.length);
      });
  }, [isCustomGoal, startDate, goal, update]);
};

export const getInitialInfoList = (
  goal: GoalDetailInfo,
  startDate: string,
): AchievementInfoItem[] => {
  return Array(goal.totalAchieveCountPerCycle)
    .fill(0)
    .map((_, i) => {
      switch (i) {
        case 0:
        case 1:
          return {
            dueStartDate: startDate,
            dueStartTime: '08:00:00',
            dueEndDate: startDate,
            dueEndTime: '14:00:59',
            isPublic: false,
            prizeAmount: null,
          };
        default:
          return {
            dueStartDate: null,
            dueStartTime: '00:00:00',
            dueEndDate: null,
            dueEndTime: '23:59:59',
            isPublic: false,
            prizeAmount: null,
          };
      }
    });
};
