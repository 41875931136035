import Button from '@component/Button';
import ChipInput from '@component/ChipInput';
import FlexBox from '@component/FlexBox/FlexBox';
import MultilineChipInput from '@component/MultilineChipInput';
import { FC } from 'react';
import { Divider, TextArea, TextAreaProps } from 'semantic-ui-react';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import { PurchaseInfoListItem } from '../../../NewChallengeForm.types';
import {
  DEFAULT_PURCHASE_ITEM,
  DEFAULT_REVIEW_ITEM,
} from '../PurchaseInfoForm.constant';
import TextInput from '@component/TextInput';

type Props = {
  info: PurchaseInfoListItem;
  index: number;
  onRemove: (index: number) => void;
  onUpdate: (index: number, data: PurchaseInfoListItem) => void;
};

const options: {
  [key in PurchaseInfoListItem['type']]: { label: string; value: key };
} = {
  PURCHASE: { label: '구매', value: 'PURCHASE' },
  REVIEW: { label: '리뷰', value: 'REVIEW' },
};

const PurchaseInfo: FC<Props> = ({ info, index, onRemove, onUpdate }) => {
  const handleChangeAchievementIndexList = (chipList: string[]) => {
    if (chipList.some((v) => Number.isNaN(Number(v)))) return;
    onUpdate(index, {
      ...info,
      achievementIndex: chipList.map(Number),
    });
  };

  const handleChangeType = ({
    value,
  }: {
    value: PurchaseInfoListItem['type'];
  }) => {
    if (info.type === value) return;
    switch (value) {
      case 'PURCHASE':
        onUpdate(index, DEFAULT_PURCHASE_ITEM);
        break;
      case 'REVIEW':
        onUpdate(index, DEFAULT_REVIEW_ITEM);
        break;
      default:
    }
  };

  const handleChangeChecklist = (checklist: string[]) => {
    onUpdate(index, { ...info, checklist });
  };

  const handleChangeConfirmTitle = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    { value }: TextAreaProps,
  ) => {
    onUpdate(index, { ...info, confirmTitle: value as string });
  };

  const handleChangeRequiredText = (requiredText: string) => {
    if (info.type !== 'REVIEW') return;
    onUpdate(index, { ...info, requiredText });
  };

  return (
    <FlexBox.Column>
      <FlexBox.Column gap={12}>
        <ChipInput
          label="[인증 회차]"
          placeholder="인증 회차 입력 후 Enter"
          chipList={info.achievementIndex.map(String)}
          onChange={handleChangeAchievementIndexList}
        />
        <RadioSelector
          options={Object.values(options)}
          onChange={handleChangeType}
          selectedOption={options[info.type]}
          style={{ display: 'flex', flexDirection: 'row', gap: 8 }}
        />
        <b>인증 안내화면 제목</b>
        <TextArea
          value={info.confirmTitle}
          onChange={handleChangeConfirmTitle}
          style={{ padding: '10px', width: '100%' }}
        />
        <MultilineChipInput
          label="인증 체크리스트"
          chipList={info.checklist}
          onChange={handleChangeChecklist}
        />
        {info.type === 'REVIEW' && (
          <TextInput
            label="최상단 필수 작성 문구"
            value={info.requiredText}
            onChange={handleChangeRequiredText}
          />
        )}
        <Button text="해당 회차 삭제" onClick={() => onRemove(index)} />
      </FlexBox.Column>
      <Divider />
    </FlexBox.Column>
  );
};

export default PurchaseInfo;
