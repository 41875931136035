import { ChallengeCustomField, ChallengeDistanceCustomField } from '@types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'distance'>,
  Extract<ChallengeCustomField, { name: 'distance' }>
> = ({ distance }) => {
  if (distance && !distance.length) return [];
  return [{ name: 'distance', values: distance }];
};

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'distance'>
> = (customFields) => {
  const distance = customFields.find(({ name }) => name === 'distance');

  if (!distance) return {} as EmptyObj;

  const { values } = distance as ChallengeDistanceCustomField;

  return { distance: values };
};
