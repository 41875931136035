import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'exp'>;

export const validate: FormValidator<Params> = ({ exp }) => {
  if (typeof exp !== 'number') {
    return { isValid: false, message: '챌린지 경험치를 확인해주세요' };
  }

  return { isValid: true };
};
