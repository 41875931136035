import {
  ChallengeCustomField,
  ChallengePostConnectPrizeCustomField,
} from '@types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'postConnectPrize'>
> = (customFields) => {
  const postConnectPrize = customFields.find(
    (c) => c.name === 'postConnectPrize',
  );

  if (!postConnectPrize) return {} as EmptyObj;

  const { values } = postConnectPrize as ChallengePostConnectPrizeCustomField;

  return { postConnectPrize: values };
};

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'postConnectPrize'>,
  Extract<ChallengeCustomField, { name: 'postConnectPrize' }>
> = ({ postConnectPrize }) => {
  // 빈문자열이더라도 postConnectPrize 없는걸로 간주
  if (!postConnectPrize) return [];
  return [{ name: 'postConnectPrize', values: postConnectPrize }];
};
