import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { ChallengeInfoForReportedAchievement } from '@types';
import { arrTostring, splitNewLine } from '../../../utils/string';

const AuthenticationAccordion = ({
  challenge,
  defaultCollapsed,
}: AuthenticationAccordionProps) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const handleClick = useCallback(() => setCollapsed((prev) => !prev), []);

  useEffect(() => setCollapsed(defaultCollapsed), [defaultCollapsed]);

  return (
    <Accordion>
      <Accordion.Title active={collapsed} onClick={handleClick}>
        <h5 style={{ marginBottom: 0 }}>
          <Icon name="dropdown" />
          인증 규정 보기
        </h5>
      </Accordion.Title>
      <Accordion.Content active={collapsed}>
        <div style={{ marginLeft: 12 }}>
          <h5 style={{ marginBottom: 0 }}>인증방법</h5>
          {splitNewLine(challenge.authenticationMethod)}

          <h5 style={{ marginTop: 12, marginBottom: 0 }}>인증 유의사항</h5>
          {arrTostring(challenge.authenticationChecklist)}
        </div>
      </Accordion.Content>
    </Accordion>
  );
};

export default AuthenticationAccordion;

type AuthenticationAccordionProps = {
  challenge: ChallengeInfoForReportedAchievement;
  defaultCollapsed: boolean;
};
