import React from 'react';
import {
  Table,
  Header,
  Grid,
  Image,
  Button,
  Label,
  Form,
  Input,
  Checkbox,
  Accordion,
  Icon,
} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment-timezone';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';
import { reviewResult } from '../../../../constant/reviewResult';
import { itemUrl, wireframeUrl } from '../../../../constant/imgUrls';

export const RegisterInfo = ({
  user,
  title,
  showCancelModal,
  showReviewModal,
  showDeleteModal,
  showCommentModal,
  showUploadModal,
  getCompletedChallenges,
  showAdminCommentModal,
  showAdminReportModal,
  handleDate,
  filterStartDatetime,
  filterEndDatetime,
  showHeartModal,
  showHeartCancelModal,
  handleCheckbox,
  showDeleteAndPassCheckedModal,
  showAllAchievementFilesModal,
  challengeAccordion,
  handleChallengeAccordion,
  challengeAccordionAllOpen,
  changeChallengeAccordionAllOpen,
  setDragItem,
  setDropItem,
}) => {
  const relationUserChallengeList = user.relationUserChallenges.map(
    (ruc, idx) => {
      let achievements = _.filter(
        user.achievements,
        (achievement) => achievement.relationUserChallengeId === ruc.id,
      );
      achievements = _.sortBy(achievements, ['dueStartDate', 'nthTerm']);
      let achievementList = achievements.map((achievement, idx) => {
        const isItemUsed = !!achievement.transactionItem;
        if (
          'url' in achievement.achievementFile &&
          achievement.achievementFile.url !== ''
        ) {
          const createdAt = moment(achievement.achievementFile.createdAt).tz(
            user.timezone,
          );
          const offset = createdAt.utcOffset();
          return (
            <Grid.Column key={idx}>
              <div
                onDragOver={(e) => e.preventDefault()}
                onDrop={() => setDropItem(achievement)}
              >
                <div
                  draggable={'true'}
                  onDragStart={() => setDragItem(achievement)}
                >
                  <a
                    href={achievement.achievementFile.url}
                    key={idx}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image fluid src={achievement.achievementFile.url} />
                  </a>
                </div>
              </div>
              <div
                style={{ position: 'absolute', top: 20, left: 20, padding: 5 }}
              >
                <Label color="blue" size={'tiny'}>
                  서버시간{' '}
                  {moment(achievement.achievementFile.createdAt).format(
                    'YYYY-MM-DD HH:mm:ss',
                  )}
                </Label>
                <Label color="teal" size={'tiny'}>
                  제출시간{' '}
                  {moment(achievement.achievementFile.submitDatetime).format(
                    'YYYY-MM-DD HH:mm:ss',
                  )}
                </Label>
              </div>
              <div
                style={{ position: 'absolute', top: 20, right: 20, padding: 5 }}
              >
                <Checkbox
                  checked={achievement.isChecked}
                  value={achievement.achievementFile.id}
                  onChange={handleCheckbox}
                />
              </div>
              <div>
                {achievement.id} ({achievement.achievementFile.id})
              </div>
              <div>
                ({moment(achievement.dueStartDate).format('YY.MM.DD')}{' '}
                {achievement.dueStartTime}
                <br />~{moment(achievement.dueEndDate).format('YY.MM.DD')}{' '}
                {achievement.dueEndTime})
              </div>
              <div>
                {achievement.achievementFile.reviewResult ===
                  reviewResult.before && <Label>리뷰전</Label>}
                {achievement.achievementFile.reviewResult ===
                  reviewResult.pass && <Label>통과</Label>}
                {achievement.achievementFile.reviewResult ===
                  reviewResult.red && <Label>레드</Label>}
                {achievement.achievementFile.reviewResult ===
                  reviewResult.fail && <Label>미인증</Label>}
                {achievement.achievementFile.reviewResult ===
                  reviewResult.comment && <Label>댓글</Label>}
                <Button
                  icon="comment"
                  size="mini"
                  content={achievement.achievementFile.comments.length}
                  value={achievement.achievementFile.comments}
                  onClick={showAdminCommentModal}
                />
                <Button
                  icon="warning sign"
                  size="mini"
                  content={achievement.achievementFile.reports.length}
                  value={achievement.achievementFile.reports}
                  onClick={showAdminReportModal}
                />
              </div>
              <Button
                size="mini"
                color="red"
                name={reviewResult.red}
                value={achievement}
                floated="left"
                onClick={showReviewModal}
              >
                Red
              </Button>
              <Button
                size="mini"
                color="blue"
                name={reviewResult.pass}
                value={achievement}
                floated="left"
                onClick={showReviewModal}
              >
                성공처리
              </Button>
              <Button
                basic
                size="mini"
                floated="left"
                value={achievement}
                onClick={showDeleteModal}
              >
                인증샷삭제
              </Button>
              <Button
                basic
                size="mini"
                floated="left"
                value={achievement}
                onClick={showCommentModal}
              >
                경고댓글
              </Button>
              <Button
                basic
                size="mini"
                floated="left"
                value={achievement.id}
                onClick={showAllAchievementFilesModal}
              >
                모든인증샷보기
              </Button>
              {!achievement.transactionItem ? (
                <Button
                  size="mini"
                  floated="left"
                  value={achievement}
                  onClick={showHeartModal}
                >
                  인증패스
                </Button>
              ) : (
                <p>
                  <br />
                  {achievement.transactionItem.createdAt}
                  <Button
                    basic
                    size="mini"
                    floated="left"
                    value={achievement}
                    onClick={showHeartCancelModal}
                  >
                    하트취소
                  </Button>
                </p>
              )}
            </Grid.Column>
          );
        } else {
          return (
            <Grid.Column key={idx}>
              <div
                onDragOver={(e) => e.preventDefault()}
                onDrop={() =>
                  setDropItem({
                    id: achievement.id,
                    dueStartDate: achievement.dueStartDate,
                    dueStartTime: achievement.dueStartTime,
                    achievementFile: {
                      id: 0,
                      url: isItemUsed ? itemUrl : wireframeUrl,
                    },
                    challenge: { title: achievement.challenge.title },
                  })
                }
              >
                <div
                  draggable={'true'}
                  onDragStart={() =>
                    setDragItem({
                      id: achievement.id,
                      dueStartDate: achievement.dueStartDate,
                      dueStartTime: achievement.dueStartTime,
                      achievementFile: {
                        id: 0,
                        url: isItemUsed ? itemUrl : wireframeUrl,
                      },
                      challenge: { title: achievement.challenge.title },
                    })
                  }
                >
                  <Image fluid src={isItemUsed ? itemUrl : wireframeUrl} />
                </div>
              </div>
              <div
                style={{ position: 'absolute', top: 20, left: 20, padding: 5 }}
              >
                {isItemUsed ? (
                  <Label color="teal" size={'tiny'}>
                    인증패스{' '}
                    {moment(achievement.transactionItem.createdAt)
                      .add(9, 'h')
                      .format('YYYY-MM-DD HH:mm:ss')}
                  </Label>
                ) : (
                  <Label color="black" size={'tiny'}>
                    No image
                  </Label>
                )}
              </div>
              <div>{achievement.id}</div>
              <div>
                ({moment(achievement.dueStartDate).format('YY.MM.DD')}{' '}
                {achievement.dueStartTime}-
                {moment(achievement.dueEndDate).format('YY.MM.DD')}{' '}
                {achievement.dueEndTime})
              </div>
              <Button
                basic
                size="mini"
                floated="left"
                value={achievement}
                onClick={showUploadModal}
              >
                인증샷등록
              </Button>
              <Button
                basic
                size="mini"
                floated="left"
                value={achievement.id}
                onClick={showAllAchievementFilesModal}
              >
                모든인증샷보기
              </Button>
              {achievement.transactionItem && (
                <Button
                  basic
                  size="mini"
                  floated="left"
                  value={achievement}
                  onClick={showHeartCancelModal}
                >
                  인증패스취소
                </Button>
              )}
              {!achievement.transactionItem && (
                <Button
                  basic
                  size="mini"
                  floated="left"
                  value={achievement}
                  onClick={showHeartModal}
                >
                  인증패스
                </Button>
              )}
            </Grid.Column>
          );
        }
      });
      return (
        <Table.Row key={idx}>
          <Table.Cell textAlign="center" collapsing>
            {ruc.id}
            <br />
            <Button size="mini" value={ruc.id} onClick={showCancelModal}>
              신청취소
            </Button>
          </Table.Cell>
          <Table.Cell>
            [{ruc.challenge.userId === 1 ? '공식' : '개설'}] [{ruc.challenge.id}
            ] [
            <a
              href={`/challenges/${ruc.challenge.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              상세
            </a>
            {' / '}
            <a
              href={`/challenges/${ruc.challenge.id}/edit`}
              target="_blank"
              rel="noopener noreferrer"
            >
              편집
            </a>
            ]<h5 style={{ marginTop: 0 }}>{ruc.challenge.title}</h5>
            <Button
              size="mini"
              value={ruc.id}
              onClick={showDeleteAndPassCheckedModal}
            >
              선택 일괄삭제 및 인증처리
            </Button>
            <br />
            <p>
              현재까지 달성수: {ruc.achievementInfo.ok} <br />
              현재까지 총 수: {ruc.achievementInfo.present}
              <br />
              전체 수: {ruc.achievementInfo.total}
            </p>
          </Table.Cell>
          <Table.Cell textAlign="center" collapsing>
            카드 {setCurrencyFormat(ruc.cardDeposit)}
            <br />
            계좌 {setCurrencyFormat(ruc.transferDeposit)}
            <br />
            캐시 {setCurrencyFormat(ruc.cashDeposit)}
            <br />
            상금 {setCurrencyFormat(ruc.prizeDeposit)}
            <br />
          </Table.Cell>
          <Table.Cell textAlign="center" collapsing>
            {moment(ruc.challenge.startDate).format('YYYY-MM-DD')}
          </Table.Cell>
          <Table.Cell textAlign="center" collapsing>
            {moment(ruc.challenge.endDate).format('YYYY-MM-DD')}
          </Table.Cell>
          <Table.Cell width={7}>
            <Accordion>
              <Accordion.Title
                active={challengeAccordion[ruc.challenge.id]}
                index={ruc.challenge.id}
                onClick={handleChallengeAccordion}
              >
                <Icon name="dropdown" />
                인증카드 목록보기
              </Accordion.Title>
              <Accordion.Content active={challengeAccordion[ruc.challenge.id]}>
                <Grid columns={3}>{achievementList}</Grid>
              </Accordion.Content>
            </Accordion>
          </Table.Cell>
        </Table.Row>
      );
    },
  );
  return (
    <React.Fragment>
      <Header as="h5">{title}</Header>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ruc_id</Table.HeaderCell>
            <Table.HeaderCell>챌린지명</Table.HeaderCell>
            <Table.HeaderCell>참가금액</Table.HeaderCell>
            <Table.HeaderCell>시작일</Table.HeaderCell>
            <Table.HeaderCell>종료일</Table.HeaderCell>
            <Table.HeaderCell>
              인증샷
              <Button
                style={{ marginLeft: 10 }}
                size="tiny"
                onClick={changeChallengeAccordionAllOpen}
              >
                {challengeAccordionAllOpen ? '전체 접기' : '전체 펼치기'}
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {relationUserChallengeList.length !== 0 && relationUserChallengeList}
          {relationUserChallengeList.length === 0 && (
            <Table.Row>
              <Table.Cell collapsing>
                진행중인 챌린지 참가내역이 없습니다.
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {title === '현재 진행중인 챌린지 참가이력' && (
        <Form>
          <Form.Group inline>
            <Form.Field>
              <label>시작일</label>
              <Input
                type="date"
                style={{ marginLeft: 10 }}
                name="filterStartDatetime"
                defaultValue={filterStartDatetime}
                onChange={handleDate}
              />
            </Form.Field>
            <Form.Field>
              <label>종료일</label>
              <Input
                type="date"
                style={{ marginLeft: 10 }}
                name="filterEndDatetime"
                defaultValue={filterEndDatetime}
                onChange={handleDate}
              />
            </Form.Field>
            <Button onClick={getCompletedChallenges}>
              지난 챌린지 참여 내역 불러오기
            </Button>
          </Form.Group>
        </Form>
      )}
    </React.Fragment>
  );
};

export default RegisterInfo;
