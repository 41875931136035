import React from 'react';
import { Table } from 'semantic-ui-react';
import { RaceReviewInfo } from 'src/types/race.types';

const RaceReviewQuestion = ({ reviewInfo }: { reviewInfo: RaceReviewInfo }) => {
  return (
    <>
      <h3>리뷰 질문 목록</h3>
      <div>리뷰 설명글: {reviewInfo?.description || '-'}</div>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>no</Table.HeaderCell>
            <Table.HeaderCell>리뷰 질문</Table.HeaderCell>
            <Table.HeaderCell>필수 여부</Table.HeaderCell>
            <Table.HeaderCell>최소 길이</Table.HeaderCell>
            <Table.HeaderCell>placeholder</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {reviewInfo?.questions?.map((question, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{idx + 1}</Table.Cell>
              <Table.Cell>{question.question}</Table.Cell>
              <Table.Cell>{question.required ? '필수' : '-'}</Table.Cell>
              <Table.Cell>{question.minLength}</Table.Cell>
              <Table.Cell>{question.placeholder}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default RaceReviewQuestion;
