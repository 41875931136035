import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Header } from 'semantic-ui-react';
import { BenefitQueries } from '../../../../queries';
import FormComponent from './components/FormComponent/FormComponent';

const PedometerGiftiShopItemCreate: FC = () => {
  const { id: idParam } = useParams<{ id?: string | undefined }>();
  const id = Number(idParam);

  const isCreateMode = !id;

  const { data: item } = BenefitQueries.useGiftiShopItem(id, {
    refetchOnMount: true,
    enabled: !isCreateMode,
  });

  return (
    <Container style={{ paddingBottom: 50 }}>
      <Grid.Column>
        <Header as="h1">
          {`뽑기 ${isCreateMode ? '새로 생성 🚀' : '수정 ✍️'}`}
        </Header>
        <FormComponent id={id} item={item} />
      </Grid.Column>
    </Container>
  );
};

export default PedometerGiftiShopItemCreate;
