import React from 'react';
import { Header, Table } from 'semantic-ui-react';

export const RestrictGoal = ({ restrictGoals }) => {
  return (
    <React.Fragment>
      <Header>동시 진행 불가능한 목표</Header>
      <Table basic="very" size="small" sortable structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>번호</Table.HeaderCell>
            <Table.HeaderCell>목표id</Table.HeaderCell>
            <Table.HeaderCell>목표명</Table.HeaderCell>
            <Table.HeaderCell>주기</Table.HeaderCell>
            <Table.HeaderCell>매일/기간</Table.HeaderCell>
            <Table.HeaderCell>하루최대인증</Table.HeaderCell>
            <Table.HeaderCell>갤러리가능</Table.HeaderCell>
            <Table.HeaderCell>공휴일인증</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {restrictGoals.map((goal, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell collapsing>{idx + 1}</Table.Cell>
                <Table.Cell collapsing>{goal.id}</Table.Cell>
                <Table.Cell collapsing>{goal.title}</Table.Cell>
                <Table.Cell collapsing>{goal.cycle.join(', ')}</Table.Cell>
                <Table.Cell collapsing>
                  {goal.dayOrPeriod === 'DAY' ? '매일' : '기간'}
                </Table.Cell>
                <Table.Cell collapsing>{goal.maxAchieveCountPerDay}</Table.Cell>
                <Table.Cell collapsing>
                  {goal.isGalleryPossible ? '가능' : '불가'}
                </Table.Cell>
                <Table.Cell collapsing>
                  {!goal.restInHoliday ? '함' : '안함'}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default RestrictGoal;
