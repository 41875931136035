import { useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'semantic-ui-react';
import { ReportedAchievement } from 'src/types/achievement.types';
import { DELETE_COMMENT_OPTIONS } from '../Achievement.constants';
import { deleteAchievementFile, getReviewComment } from '../Achievement.utils';

const DeleteModal = ({
  achievement,
  modalOpen,
  close,
  removeAchievement,
}: DeleteModalProps) => {
  const [reviewComment, setReviewComment] = useState('');

  const handleChangeTemplate = (optionValue: number) => {
    const { challenge, user } = achievement;
    const _comment = getReviewComment(optionValue, user, challenge);
    setReviewComment(_comment);
  };

  const _deleteAchievementFile = async (
    deleteType: 'DELETE' | 'PUSH' | 'DELETE_AND_PASS',
  ) => {
    deleteAchievementFile(
      achievement,
      deleteType,
      reviewComment,
      removeAchievement,
      close,
    );
  };

  return (
    <Modal size="tiny" open={modalOpen} onClose={close}>
      <Modal.Header>인증샷 삭제</Modal.Header>
      <Modal.Content>
        인증샷 삭제를 하시겠습니까?
        <br />
        인증기간 내 인증샷 삭제의 경우 재인증이 가능합니다.
        <Form>
          <Form.Field>
            <label>메세지 템플릿</label>
            <Dropdown
              placeholder="템플릿을 선택하세요."
              fluid
              search
              selection
              name="messageId"
              options={DELETE_COMMENT_OPTIONS}
              onChange={(_, { value }) => {
                const idx = value as number;
                handleChangeTemplate(idx);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>댓글 내용을 작성해주세요.</label>
            <textarea
              rows={20}
              value={reviewComment}
              onChange={(e) => setReviewComment(e.target.value)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="blue"
          content="삭제"
          onClick={() => _deleteAchievementFile('DELETE')}
        />
        <Button
          color="blue"
          content="삭제(푸시발송)"
          onClick={() => _deleteAchievementFile('PUSH')}
        />
        <Button
          color="blue"
          content="삭제(성공처리)"
          onClick={() => _deleteAchievementFile('DELETE_AND_PASS')}
        />
        <Button color="black" content="닫기" onClick={close} />
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteModal;

type DeleteModalProps = {
  achievement: ReportedAchievement;
  modalOpen: boolean;
  close: () => void;
  removeAchievement: (achievement: ReportedAchievement) => void;
};
