import { isNull } from '@utils/js.utils';
import {
  ChallengeCreatorCustomField,
  ChallengeCustomField,
} from 'src/types/customField.types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'creator'>,
  Extract<ChallengeCustomField, { name: 'creator' }>
> = ({ creator }) => {
  const { type, defaultCommercePrice } = creator;
  if (isNull(type) || isNull(defaultCommercePrice)) return [];

  return [
    {
      name: 'creator',
      values: {
        type,
        defaultCommercePrice,
      },
    },
  ];
};

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'creator'>
> = (customFields) => {
  const creator = customFields.find(({ name }) => name === 'creator');
  if (!creator) return {} as EmptyObj;

  const { values } = creator as ChallengeCreatorCustomField;

  return {
    creator: values,
  };
};
