import styled from 'styled-components';

export const FlexBox = styled.div<{
  flexDirection?: React.CSSProperties['flexDirection'];
  gap?: React.CSSProperties['gap'];
  margin?: React.CSSProperties['margin'];
}>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection && props.flexDirection};
  gap: ${(props) => props.gap && props.gap};
  margin: ${(props) => props.margin && props.margin};
`;
