import { FC } from 'react';
import TextInput from '@component/TextInput';

type Props = {
  itemName: string;
  onChange: (itemName: string) => void;
};
const ItemName: FC<Props> = (props) => {
  const { itemName, onChange } = props;

  return (
    <TextInput
      label="추가리워드 아이템 이름"
      value={itemName}
      onChange={onChange}
    />
  );
};

export default ItemName;
