import React, { Component } from 'react';
import {
  Container,
  Table,
  Loader,
  Button,
  Grid,
  Form,
  Input,
} from 'semantic-ui-react';
import moment from 'moment';
import api from '../../../api';
import setCurrencyFormat from '../../../global/setCurrencyFormat';
import PointInfoExcel from './PointInfoExcel';

const keyName = {
  totalPrizeReward: '  상금액',
  totalPrizeRewardCarryOver: '  이월액',
  totalPrizeReturn: '  환급액(상금)',
  totalPrizeRecommend: '   추천가입',
  totalPrizeWithdraw: '  출금이체(상금)',
  totalPrizeRegister: '  참가비로 사용(상금)',
  totalPrizeRed: '   레드카드2번',
  totalPrizeDelete: '   1년',
  totalCashReturn: '  환급액(현금)',
  totalCashDeposit: '  입금이체',
  totalCashWithdraw: '  출금이체(현금)',
  totalCashRegister: '  참가비로 사용(현금)',
};

const sortOrder = [
  '미환급 상금포인트',
  '총 상금포인트',
  ' 획득 상금포인트',
  '  상금액',
  '  이월액',
  '  환급액(상금)',
  '  이벤트',
  '   추천가입',
  ' 삭제 상금포인트',
  '  출금이체(상금)',
  '  참가비로 사용(상금)',
  '  소멸 상금포인트',
  '   레드카드2번',
  '   1년',
  '   탈퇴',
  '총 현금포인트',
  ' 획득 현금포인트',
  '  환급액(현금)',
  '  입금이체',
  ' 삭제 현금포인트',
  '  출금이체(현금)',
  '  참가비로 사용(현금)',
];

class PointInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pointInfo: {
        prize: {},
        cash: {},
        challenge: {},
      },
      isMounted: true,
      startDate: moment(),
      endDate: moment().format('YYYY-MM-DD'),
      excelData: [],
    };
  }

  componentDidMount() {
    const quarter = Math.floor(moment().month() / 3);
    let month = 0;
    if (quarter === 0) month = 0;
    else if (quarter === 1) month = 3;
    else if (quarter === 2) month = 6;
    else month = 9;
    const startDate = moment().set({
      year: moment().year(),
      month: month,
      date: 1,
    });
    this.setState({
      startDate: moment(startDate).format('YYYY-MM-DD'),
    });
  }

  handleDate = (e, { name, value }) => {
    if (name === 'startDate') this.setState({ startDate: value });
    else this.setState({ endDate: value });
  };

  getPointInfos = () => {
    const { startDate, endDate } = this.state;
    this.setState({ isMounted: false });
    api
      .get('/admin/point-infos', {
        params: {
          startDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        },
      })
      .then((response) => {
        const pointInfo = response.data.data.pointInfo;
        console.log(pointInfo);
        const totalPrizePlus =
          pointInfo.prize.totalPrizeReturn +
          pointInfo.prize.totalPrizeReward +
          pointInfo.prize.totalPrizeRewardCarryOver +
          pointInfo.prize.totalPrizeRecommend;
        const totalPrizeMinus =
          pointInfo.prize.totalPrizeRegister +
          pointInfo.prize.totalPrizeWithdraw +
          pointInfo.prize.totalPrizeDelete +
          pointInfo.prize.totalPrizeRed;
        const totalPrize = totalPrizePlus + totalPrizeMinus;
        const nonReturnPrize =
          pointInfo.prize.totalPrizeRegister + pointInfo.prize.totalPrizeReturn;
        const totalCashPlus =
          pointInfo.cash.totalCashReturn + pointInfo.cash.totalCashDeposit;
        const totalCashMinus =
          pointInfo.cash.totalCashRegister + pointInfo.cash.totalCashWithdraw;
        const totalCash = totalCashPlus + totalCashMinus;

        const excelData = [];
        for (const [key, value] of Object.entries(pointInfo)) {
          for (const [k, v] of Object.entries(value)) {
            if (!['totalCashCancel', 'totalPrizeCancel'].includes(k)) {
              excelData.push({ pointType: key, name: keyName[k], value: v });
            }
          }
        }
        excelData.push({
          pointType: 'prize',
          name: '총 상금포인트',
          value: totalPrize,
        });
        excelData.push({
          pointType: 'prize',
          name: ' 획득 상금포인트',
          value: totalPrizePlus,
        });
        excelData.push({
          pointType: 'prize',
          name: '  이벤트',
          value: pointInfo.prize.totalPrizeRecommend,
        });
        excelData.push({
          pointType: 'prize',
          name: ' 삭제 상금포인트',
          value: totalPrizeMinus,
        });
        excelData.push({
          pointType: 'prize',
          name: '  소멸 상금포인트',
          value:
            pointInfo.prize.totalPrizeRed + pointInfo.prize.totalPrizeDelete,
        });
        excelData.push({
          pointType: 'prize',
          name: '미환급 상금포인트',
          value: nonReturnPrize,
        });
        excelData.push({
          pointType: 'cash',
          name: '총 현금포인트',
          value: totalCash,
        });
        excelData.push({
          pointType: 'cash',
          name: ' 획득 현금포인트',
          value: totalCashPlus,
        });
        excelData.push({
          pointType: 'cash',
          name: ' 삭제 현금포인트',
          value: totalCashMinus,
        });
        const sorted = excelData.sort(function (a, b) {
          return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name);
        });

        this.setState({ excelData: sorted, pointInfo, isMounted: true });
      });
  };

  render() {
    const { pointInfo, isMounted, startDate, endDate, excelData } = this.state;
    const totalPrizePlus =
      pointInfo.prize.totalPrizeReturn +
      pointInfo.prize.totalPrizeReward +
      pointInfo.prize.totalPrizeRewardCarryOver +
      pointInfo.prize.totalPrizeRecommend;
    const totalPrizeMinus =
      pointInfo.prize.totalPrizeRegister +
      pointInfo.prize.totalPrizeWithdraw +
      pointInfo.prize.totalPrizeDelete +
      pointInfo.prize.totalPrizeRed;
    const totalPrizeDelete =
      pointInfo.prize.totalPrizeDelete +
      pointInfo.prize.totalPrizeRed +
      pointInfo.prize.totalPrizeDeleteUser;
    const totalPrize = totalPrizePlus + totalPrizeMinus;
    const nonReturnPrize =
      pointInfo.prize.totalPrizeRegister + pointInfo.prize.totalPrizeReturn;
    const totalCashPlus =
      pointInfo.cash.totalCashReturn + pointInfo.cash.totalCashDeposit;
    const totalCashMinus =
      pointInfo.cash.totalCashRegister + pointInfo.cash.totalCashWithdraw;
    const totalCash = totalCashPlus + totalCashMinus;
    return (
      <Container>
        <Grid columns={2}>
          <Grid.Column width={4}>
            <Form>
              <Form.Field>
                <label>시작일</label>
                <Input
                  type="date"
                  name="startDate"
                  value={startDate}
                  onChange={this.handleDate}
                />
              </Form.Field>
              <Form.Field>
                <label>종료일</label>
                <Input
                  type="date"
                  name="endDate"
                  value={endDate}
                  onChange={this.handleDate}
                />
              </Form.Field>
            </Form>
            <Button onClick={this.getPointInfos} style={styles.button}>
              조회
            </Button>
            <PointInfoExcel
              excelData={excelData}
              startDate={startDate}
              endDate={endDate}
            />
          </Grid.Column>
          <Grid.Column>
            {!isMounted && <Loader active inline="centered" />}
            {isMounted && (
              <Table structured collapsing basic="very" textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>포인트종류</Table.HeaderCell>
                    <Table.HeaderCell>항목명</Table.HeaderCell>
                    <Table.HeaderCell>값</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell rowSpan="15">상금포인트</Table.Cell>
                    <Table.Cell>미환급 상금포인트</Table.Cell>
                    <Table.Cell>{setCurrencyFormat(nonReturnPrize)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>총 상금포인트</Table.Cell>
                    <Table.Cell>{setCurrencyFormat(totalPrize)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>획득 상금포인트</Table.Cell>
                    <Table.Cell>{setCurrencyFormat(totalPrizePlus)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>상금액</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(pointInfo.prize.totalPrizeReward)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>이월액</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(
                        pointInfo.prize.totalPrizeRewardCarryOver,
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>환급액</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(pointInfo.prize.totalPrizeReturn)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>이벤트</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(pointInfo.prize.totalPrizeRecommend)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>추천가입</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(pointInfo.prize.totalPrizeRecommend)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>삭제 포인트</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(
                        pointInfo.prize.totalPrizeDelete +
                          pointInfo.prize.totalPrizeRed,
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>출금이체</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(pointInfo.prize.totalPrizeWithdraw)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>참가비로 사용</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(pointInfo.prize.totalPrizeRegister)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>소멸포인트</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(totalPrizeDelete)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>레드카드2번</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(pointInfo.prize.totalPrizeRed)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>1년</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(pointInfo.prize.totalPrizeDelete)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>탈퇴</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(pointInfo.prize.totalPrizeDeleteUser)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell rowSpan="7">현금포인트</Table.Cell>
                    <Table.Cell>총 현금포인트</Table.Cell>
                    <Table.Cell>{setCurrencyFormat(totalCash)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>획득포인트</Table.Cell>
                    <Table.Cell>{setCurrencyFormat(totalCashPlus)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>환급액</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(pointInfo.cash.totalCashReturn)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>입금이체</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(pointInfo.cash.totalCashDeposit)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>삭제 포인트</Table.Cell>
                    <Table.Cell>{setCurrencyFormat(totalCashMinus)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>출금이체</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(pointInfo.cash.totalCashWithdraw)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>신청</Table.Cell>
                    <Table.Cell>
                      {setCurrencyFormat(pointInfo.cash.totalCashRegister)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            )}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default PointInfo;

const styles = {
  button: {
    marginTop: 5,
  },
};
