import { DescriptionCollaboAdditionalRewardModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const DEFAULT_DATA = {
  type: 'COLLABO-ADDITIONAL-REWARD',
  data: {
    to: '',
    itemName: '',
    image: '',
    itemDetailName: '',
    itemDetailDescription: '',
    noticeList: [],
  },
} as unknown as DescriptionCollaboAdditionalRewardModuleFormType;
