import React from 'react';
import {
  Table,
  Header,
  Checkbox,
  Input,
  Grid,
  Button,
  Dropdown,
  Form,
} from 'semantic-ui-react';
import _ from 'lodash';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';

export const AccountInfo = ({
  user,
  handleCheckbox,
  handleCardAmount,
  handleTransferAmount,
  cancelCard,
  withdraw,
  checkPgIds,
  checkTransferIds,
  bankOptions,
  handleBankChange,
  handleAccountChange,
  withdrawPrize,
  withdrawPrizeSum,
}) => {
  const cardRegisterTransactions = _.filter(
    user.account.cashTransactions,
    (ct) =>
      ['REGISTER_CARD', 'CHARGE_CARD'].includes(ct.type) && ct.deposit > 0,
  );
  const transferRegisterTransactions = _.filter(
    user.account.cashTransactions,
    (ct) =>
      ['REGISTER_TRANSFER', 'CHARGE_TRANSFER', 'EVENT'].includes(ct.type) &&
      ct.deposit > 0,
  );
  return (
    <React.Fragment>
      <Header as="h5">계좌 정보</Header>
      <Table basic="very" textAlign="center" collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>총결제금액</Table.HeaderCell>
            <Table.HeaderCell>상품구입액</Table.HeaderCell>
            <Table.HeaderCell>미환급금(벌금)</Table.HeaderCell>
            <Table.HeaderCell>현금화 완료</Table.HeaderCell>
            <Table.HeaderCell>챌린지 참가중</Table.HeaderCell>
            <Table.HeaderCell>스토어포인트 교환</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {setCurrencyFormat(user.account.cashAmounts.cashEarn)}
            </Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(user.account.cashAmounts.cashProduct)}
            </Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(user.account.cashAmounts.cashNonReturn)}
            </Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(user.account.cashAmounts.cashRefund)}
            </Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(user.account.cashAmounts.cashChallenge)}
            </Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(user.account.cashAmounts.cashExchange)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Table basic="very" textAlign="center" collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>캐시포인트 잔액</Table.HeaderCell>
            <Table.HeaderCell>캐시포인트 잔액(카드)</Table.HeaderCell>
            <Table.HeaderCell>캐시포인트 잔액(이체)</Table.HeaderCell>
            <Table.HeaderCell>상금포인트 잔액</Table.HeaderCell>
            <Table.HeaderCell>상금포인트 출금 총액</Table.HeaderCell>
            <Table.HeaderCell>아이템</Table.HeaderCell>
            <Table.HeaderCell>스토어포인트 잔액</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{setCurrencyFormat(user.account.cashPoint)}</Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(user.account.cardCashBalance)}
            </Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(user.account.transferCashBalance)}
            </Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(user.account.prizePoint)}
            </Table.Cell>
            <Table.Cell>{setCurrencyFormat(withdrawPrizeSum)}</Table.Cell>
            <Table.Cell>{user.itemCount}</Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(user.account.storePoint)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Grid columns={2}>
        <Grid.Column width={9}>
          <Header as="h5">카드 결제 내역</Header>
          <Table basic="very" size="small" textAlign="center" collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>선택</Table.HeaderCell>
                <Table.HeaderCell>결제 id</Table.HeaderCell>
                <Table.HeaderCell>거래 id</Table.HeaderCell>
                <Table.HeaderCell>결제 총액</Table.HeaderCell>
                <Table.HeaderCell>잔액</Table.HeaderCell>
                <Table.HeaderCell>취소금액</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {cardRegisterTransactions.length > 0 &&
                _.map(cardRegisterTransactions, (ct, index) => (
                  <Table.Row key={`${ct.transactionPgId}_${index}`}>
                    <Table.Cell>
                      <Checkbox
                        checked={ct.isChecked}
                        name="transactionPg"
                        value={ct.transactionPgId}
                        onChange={handleCheckbox}
                      />
                    </Table.Cell>
                    <Table.Cell>{ct.transactionPgId}</Table.Cell>
                    <Table.Cell>{ct.tid}</Table.Cell>
                    <Table.Cell>{setCurrencyFormat(ct.total)}</Table.Cell>
                    <Table.Cell>{setCurrencyFormat(ct.deposit)}</Table.Cell>
                    <Table.Cell>
                      <Input
                        name={ct.transactionPgId}
                        value={ct.amount}
                        disabled={!checkPgIds.includes(ct.transactionPgId)}
                        onChange={handleCardAmount}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <Button
            fluid
            color="blue"
            onClick={() => cancelCard(true)}
            content="카드취소"
          />
          <Button
            fluid
            color="black"
            onClick={() => cancelCard(false)}
            content="카드취소 (별도 출금처리한 경우)"
          />
        </Grid.Column>

        <Grid.Column width={7}>
          <Header as="h5">계좌이체 내역</Header>
          <Table basic="very" size="small" textAlign="center" collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>선택</Table.HeaderCell>
                <Table.HeaderCell>결제 id</Table.HeaderCell>
                <Table.HeaderCell>결제 총액</Table.HeaderCell>
                <Table.HeaderCell>잔액</Table.HeaderCell>
                <Table.HeaderCell>취소금액</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {transferRegisterTransactions.length > 0 &&
                _.map(transferRegisterTransactions, (ct, index) => (
                  <Table.Row key={`${ct.transactionTransferId}_${index}`}>
                    <Table.Cell>
                      <Checkbox
                        checked={ct.isChecked}
                        name="transactionTransfer"
                        value={ct.transactionTransferId}
                        onChange={handleCheckbox}
                      />
                    </Table.Cell>
                    <Table.Cell>{ct.transactionTransferId}</Table.Cell>
                    <Table.Cell>{setCurrencyFormat(ct.total)}</Table.Cell>
                    <Table.Cell>{setCurrencyFormat(ct.deposit)}</Table.Cell>
                    <Table.Cell>
                      <Input
                        name={ct.transactionTransferId}
                        value={ct.amount}
                        disabled={
                          !checkTransferIds.includes(ct.transactionTransferId)
                        }
                        onChange={handleTransferAmount}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <Form>
            <Form.Field>
              <label>은행</label>
              <Dropdown
                placeholder="은행을 선택하세요."
                search
                selection
                name="bankId"
                options={bankOptions}
                onChange={handleBankChange}
              />
            </Form.Field>
            <Form.Field>
              <label>예금주명</label>
              <Input name="accountName" onChange={handleAccountChange} />
            </Form.Field>
            <Form.Field>
              <label>계좌번호</label>
              <Input name="accountNo" onChange={handleAccountChange} />
            </Form.Field>
          </Form>
          <Button fluid color="blue" onClick={withdraw} content="출금" />

          <Header as="h5">상금포인트 출금</Header>
          <Form>
            <Form.Field>
              <label>은행</label>
              <Dropdown
                placeholder="은행을 선택하세요."
                search
                selection
                name="bankId"
                options={bankOptions}
                onChange={handleBankChange}
              />
            </Form.Field>
            <Form.Field>
              <label>예금주명</label>
              <Input name="accountName" onChange={handleAccountChange} />
            </Form.Field>
            <Form.Field>
              <label>계좌번호</label>
              <Input name="accountNo" onChange={handleAccountChange} />
            </Form.Field>
            <Form.Field>
              <label>금액</label>
              <Input name="prizePoint" onChange={handleAccountChange} />
            </Form.Field>
            <Form.Field>
              <label>실명</label>
              <Input name="declareUserName" onChange={handleAccountChange} />
            </Form.Field>
            <Form.Field>
              <label>주민등록번호</label>
              <Input name="declareUserSsn" onChange={handleAccountChange} />
            </Form.Field>
          </Form>
          <Button fluid color="blue" onClick={withdrawPrize} content="출금" />
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};

export default AccountInfo;
