import React from 'react';
import { Form, Image, Table } from 'semantic-ui-react';
import { ColorSample } from '@component/ColorSample';
import * as S from '../../../../Style';
import { Race } from '@types';
import _ from 'lodash';
import { RaceFormType } from '@container/Race/RaceForm/RaceForm.types';
import { generateId } from '@utils/number';
import { defaultGoods } from '@constant/race';
import { handleChangeSingleFile } from '@utils/uploadImage';

const GoodsModule = ({
  idx,
  module,
  moduleKey,
  race,
  setRace,
}: {
  idx: number;
  module: Race.RaceGoodsDescriptionModule;
  moduleKey:
    | 'homeModule'
    | 'registerInfoDescriptionModule'
    | 'progressInfoDescriptionModule';
  race: RaceFormType;
  setRace: React.Dispatch<React.SetStateAction<RaceFormType>>;
}) => {
  const handleGoodsLabelStyleChange = (
    moduleIdx: number,
    key: 'textColor' | 'bgColor',
    value: string,
  ) => {
    const _race = _.cloneDeep(race);

    (
      _race.modules[moduleKey][moduleIdx] as Race.RaceGoodsDescriptionModule
    ).style.label[key] = value;
    setRace(_race);
  };

  const handleGoodsCardStyleChange = (
    moduleIdx: number,
    key: 'textColor' | 'bgColor',
    value: string,
  ) => {
    const _race = { ...race };
    (
      _race.modules[moduleKey][moduleIdx] as Race.RaceGoodsDescriptionModule
    ).style.card[key] = value;
    setRace(_race);
  };

  const handleGoodsListChange = <K extends keyof Race.Goods>(
    moduleIdx: number,
    goodsIdx: number,
    key: K,
    value: Race.Goods[K],
  ) => {
    const _race = _.cloneDeep(race);
    (
      _race.modules[moduleKey][moduleIdx] as Race.RaceGoodsDescriptionModule
    ).goodsList[goodsIdx][key] = value;
    setRace(_race);
  };

  const handleGoodsListImageChange = async (
    moduleIdx: number,
    goodsIdx: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const _race = _.cloneDeep(race);
    const { imageUrl } = await handleChangeSingleFile(e, {});
    (
      _race.modules[moduleKey][moduleIdx] as Race.RaceGoodsDescriptionModule
    ).goodsList[goodsIdx].imageUrl = imageUrl;
    setRace(_race);
  };

  const handleGoodsListButtonChange = (
    moduleIdx: number,
    goodsIdx: number,
    key: 'text' | 'url',
    value: string,
  ) => {
    const _race = _.cloneDeep(race);
    (
      _race.modules[moduleKey][moduleIdx] as Race.RaceGoodsDescriptionModule
    ).goodsList[goodsIdx].button[key] = value;
    setRace(_race);
  };

  const handleGoodsListAdditionalItemsChange = (
    moduleIdx: number,
    goodsIdx: number,
    additionalItemIdx: number,
    key: 'imageUrl' | 'title',
    value: string,
  ) => {
    const _race = _.cloneDeep(race);
    (
      _race.modules[moduleKey][moduleIdx] as Race.RaceGoodsDescriptionModule
    ).goodsList[goodsIdx].additionalItems[additionalItemIdx][key] = value;
    setRace(_race);
  };

  const handleGoodsListAdditionalItemsImageChange = async (
    moduleIdx: number,
    goodsIdx: number,
    additionalItemIdx: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const _race = _.cloneDeep(race);
    const { imageUrl } = await handleChangeSingleFile(e, {});
    (
      _race.modules[moduleKey][moduleIdx] as Race.RaceGoodsDescriptionModule
    ).goodsList[goodsIdx].additionalItems[additionalItemIdx].imageUrl =
      imageUrl;
    setRace(_race);
  };

  const addGoods = (moduleIdx: number) => {
    const _race = _.cloneDeep(race);
    const _goods = _.cloneDeep(defaultGoods);
    _goods.id = generateId();
    _goods.additionalItems.forEach((item) => {
      item.id = generateId();
    });
    (
      _race.modules[moduleKey][moduleIdx] as Race.RaceGoodsDescriptionModule
    ).goodsList.push(_goods);
    setRace(_race);
  };

  const deleteGoods = (moduleIdx: number, goodsIdx: number) => {
    const _race = _.cloneDeep(race);
    (
      _race.modules[moduleKey][moduleIdx] as Race.RaceGoodsDescriptionModule
    ).goodsList.splice(goodsIdx, 1);
    setRace(_race);
  };

  const addAdditionalItem = (moduleIdx: number, goodsIdx: number) => {
    const _race = _.cloneDeep(race);
    (
      _race.modules[moduleKey][moduleIdx] as Race.RaceGoodsDescriptionModule
    ).goodsList[goodsIdx].additionalItems.push({
      id: generateId(),
      title: '',
      imageUrl: '',
    });
    setRace(_race);
  };

  const deleteAdditionalItem = (
    moduleIdx: number,
    goodsIdx: number,
    additionalItemIdx: number,
  ) => {
    const _race = _.cloneDeep(race);
    (
      _race.modules[moduleKey][moduleIdx] as Race.RaceGoodsDescriptionModule
    ).goodsList[goodsIdx].additionalItems.splice(additionalItemIdx, 1);
    setRace(_race);
  };
  return (
    <>
      <h4>상품 모듈</h4>
      <S.RowContainer>
        <S.RowFormField>
          <label>라벨 배경색</label>
          <input
            value={module.style.label.bgColor}
            onChange={(e) =>
              handleGoodsLabelStyleChange(idx, 'bgColor', e.target.value)
            }
          />
          <ColorSample color={module.style.label.bgColor} />
        </S.RowFormField>
        <S.RowFormField>
          <label>라벨 글씨색</label>
          <input
            value={module.style.label.textColor}
            onChange={(e) =>
              handleGoodsLabelStyleChange(idx, 'textColor', e.target.value)
            }
          />
          <ColorSample color={module.style.label.textColor} />
        </S.RowFormField>
        <S.RowFormField>
          <label>카드 배경색</label>
          <input
            value={module.style.card.bgColor}
            onChange={(e) =>
              handleGoodsCardStyleChange(idx, 'bgColor', e.target.value)
            }
          />
          <ColorSample color={module.style.card.bgColor} />
        </S.RowFormField>
        <S.RowFormField>
          <label>카드 글씨색</label>
          <input
            value={module.style.card.textColor}
            onChange={(e) =>
              handleGoodsCardStyleChange(idx, 'textColor', e.target.value)
            }
          />
          <ColorSample color={module.style.card.textColor} />
        </S.RowFormField>
      </S.RowContainer>
      <div style={{ padding: 10, borderRadius: 10, border: '1px solid gray' }}>
        <h5>굿즈</h5>
        <Table basic="very" size="small">
          {module.goodsList.map((goods, i) => (
            <React.Fragment key={`${goods.id}-${i}`}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>no</Table.HeaderCell>
                  <Table.HeaderCell>라벨명</Table.HeaderCell>
                  <Table.HeaderCell>상품명</Table.HeaderCell>
                  <Table.HeaderCell>부제</Table.HeaderCell>
                  <Table.HeaderCell>대표이미지</Table.HeaderCell>
                  <Table.HeaderCell>버튼</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row key={goods.id}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>
                    <input
                      value={goods.labelText}
                      onChange={(e) =>
                        handleGoodsListChange(
                          idx,
                          i,
                          'labelText',
                          e.target.value,
                        )
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <input
                      value={goods.title}
                      onChange={(e) =>
                        handleGoodsListChange(idx, i, 'title', e.target.value)
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <input
                      value={goods.subtitle}
                      onChange={(e) =>
                        handleGoodsListChange(
                          idx,
                          i,
                          'subtitle',
                          e.target.value,
                        )
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <input
                      type="file"
                      name="imageUrl"
                      onChange={(e) => handleGoodsListImageChange(idx, i, e)}
                    />
                    <Image size={'small'} src={goods.imageUrl} />
                  </Table.Cell>
                  <Table.Cell>
                    <Form.Field>
                      <label>버튼 글씨</label>
                      <input
                        value={goods.button.text}
                        onChange={(e) =>
                          handleGoodsListButtonChange(
                            idx,
                            i,
                            'text',
                            e.target.value,
                          )
                        }
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>연결 URL</label>
                      <input
                        value={goods.button.url}
                        onChange={(e) =>
                          handleGoodsListButtonChange(
                            idx,
                            i,
                            'url',
                            e.target.value,
                          )
                        }
                      />
                    </Form.Field>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell colSpan={4}>상품목록</Table.HeaderCell>
                  <Table.HeaderCell>이동/삭제</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row key={goods.id}>
                  <Table.Cell />
                  <Table.Cell colSpan={4}>
                    {goods.additionalItems.map((additionalItem, j) => (
                      <S.RowContainer
                        key={`${additionalItem.id}-${idx}`}
                        alignItems={'center'}
                        margin={'10px'}
                      >
                        <div>
                          <h5>no</h5>
                          <div>{j + 1}</div>
                        </div>
                        <S.RowFormField margin={'0 0 0 10px'}>
                          <label>상품명</label>
                          <input
                            value={additionalItem.title}
                            onChange={(e) =>
                              handleGoodsListAdditionalItemsChange(
                                idx,
                                i,
                                j,
                                'title',
                                e.target.value,
                              )
                            }
                          />
                        </S.RowFormField>
                        <S.RowFormField margin={'0 0 0 10px'}>
                          <label>상품이미지</label>
                          <input
                            type="file"
                            name="imageUrl"
                            onChange={(e) =>
                              handleGoodsListAdditionalItemsImageChange(
                                idx,
                                i,
                                j,
                                e,
                              )
                            }
                          />
                          <Image size={'small'} src={additionalItem.imageUrl} />
                        </S.RowFormField>
                        <S.RowFormField margin={'0 0 0 10px'}>
                          <div style={{ marginTop: 30 }}>
                            <S.ButtonInForm
                              content={'상세 상품 삭제'}
                              onClick={() => deleteAdditionalItem(idx, i, j)}
                            />
                          </div>
                        </S.RowFormField>
                      </S.RowContainer>
                    ))}
                    <S.ButtonInForm
                      content={'상세 상품 추가'}
                      onClick={() => addAdditionalItem(idx, i)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <S.RowContainer margin={'0 10px'}>
                      <div>
                        <S.ButtonInForm
                          content={'삭제'}
                          onClick={() => deleteGoods(idx, i)}
                        />
                      </div>
                      {/* <div><S.ButtonInForm icon={'angle up'} onClick={() => handleOrderChange(idx, 'up')} /></div>
                          <div><S.ButtonInForm icon={'angle down'} onClick={() => handleOrderChange(idx, 'down')}/></div> */}
                    </S.RowContainer>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </React.Fragment>
          ))}
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan={6}>
                <S.ButtonInForm
                  content={'굿즈 추가'}
                  onClick={() => addGoods(idx)}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </>
  );
};

export default GoodsModule;
