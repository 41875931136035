import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apis } from '../../api';
import { QUERY_KEY } from './queryKey';

export const useAnswerQuestionListMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: apis.question.answerQuestionList,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.GET_QUESTION_LIST],
      });
    },
  });

  return mutation;
};
