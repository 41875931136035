import { InstagramInfoListItem } from '../../NewChallengeForm.types';

export const EMPTY_INFO_ITEM: InstagramInfoListItem = {
  achievementIndex: [],
  hashTagText: [],
  checklist: [
    '캡쳐 시 인스타그램 계정명과 올바른 필수 해시태그가 잘 보이도록 해주세요.',
    '인스타그램 게시글은 챌린지 인증기간 및 종료 후에도 최소 3개월간 공개유지해주세요',
    '인스타그램 게시글은 챌린저스에서 지속적으로 모니터링하며, 규정에 어긋난 게시글 확인 시 향후 브랜드 제휴 챌린지 선정에서 제외됩니다.',
  ],
  isAccountExposed: true,
  isAccountPublic: true,
};
