import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, Input, Modal, Table } from 'semantic-ui-react';
import { apis } from '../../../api';
import { weekDay } from '../../../constant/datetime';
import { generateId } from '../../../utils/number';
import SettingMenu from '../SettingMenu';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import { connect } from 'react-redux';

const RestDay = ({ user }) => {
  const [restDays, setRestDays] = useState([]);
  const [restDay, setRestDay] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    _getRestDays();
  }, []);

  const _getRestDays = useCallback(async () => {
    const res = await apis.getRestDays();
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    const { restDays } = res;
    setRestDays(restDays);
  }, []);

  const initializeRestDay = useCallback(() => {
    return {
      date: moment().format('YYYY-MM-DD'),
      dateName: '',
    };
  });

  const showModal = useCallback((_restDay) => {
    setModalOpen(true);

    if (!_restDay) {
      setRestDay(initializeRestDay());
    } else {
      setRestDay(_restDay);
    }
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
    setRestDay({});
  }, []);

  const _createRestDay = async () => {
    const _restDay = { ...restDay };
    const res = await apis.createRestDay(_restDay);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    const _restDays = [...restDays];
    _restDays.push({ ..._restDay, id: generateId() });
    setRestDays(_.orderBy(_restDays, 'date'));
    setRestDay({});
    closeModal();
    alert('공휴일 추가가 완료되었어요.');
  };

  const _updateRestDay = async () => {
    const _restDay = { ...restDay };
    const res = await apis.updateRestDay(restDay.id, _restDay);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    const _restDays = [...restDays].map((day) => {
      if (day.id === _restDay.id) {
        return _restDay;
      } else {
        return day;
      }
    });
    setRestDays(_restDays);
    setRestDay({});
    closeModal();
    alert('공휴일 변경이 완료되었어요.');
  };

  const _deleteRestDay = async (day) => {
    if (confirm(`[${day.date}]${day.dateName}를 삭제하시겠습니까?`)) {
      const res = await apis.deleteRestDay(day.id);
      if (res?.response?.data && 'err' in res?.response?.data) {
        return;
      }

      const _restDays = [...restDays].filter((o) => o.id !== day.id);
      setRestDays(_restDays);

      alert('삭제되었어요.');
    }
  };

  const handleChange = (key, value) => {
    const _restDay = { ...restDay };
    _restDay[key] = value;
    setRestDay(_restDay);
  };

  const getRestDaysFromOpenApi = async (year) => {
    if (confirm(`${year} 공휴일을 공공데이터 포털에서 불러오시겠습니까?`)) {
      LoadingIndicator.show();
      try {
        const res = await apis.common.syncRestDaysWithOpenData({ year });
        if (res?.response?.data && 'err' in res?.response?.data) {
          return;
        }
        const { restDays } = res;
        setRestDays(restDays);
      } finally {
        LoadingIndicator.hide();
      }
    }
  };

  const _submit = () => {
    if (!restDay.id) {
      _createRestDay();
    } else {
      _updateRestDay();
    }
  };

  const hidden = ![1, 2].includes(user.id);

  return (
    <Grid columns={'equal'} style={{ margin: 40 }}>
      <Grid.Column width={2}>
        <SettingMenu />
      </Grid.Column>
      <Grid.Column>
        <h3>
          공휴일 관리
          {!hidden && (
            <>
              <Button
                size="tiny"
                style={{ marginLeft: 10 }}
                onClick={() => getRestDaysFromOpenApi(new Date().getFullYear())}
              >
                올해 공휴일 불러오기 (공공데이터)
              </Button>
              <Button
                size="tiny"
                style={{ marginRight: 10 }}
                onClick={() =>
                  getRestDaysFromOpenApi(new Date().getFullYear() + 1)
                }
              >
                내년 공휴일 불러오기 (공공데이터)
              </Button>
            </>
          )}
          <Button size="tiny" onClick={() => showModal()} content={'추가'} />
        </h3>

        <Table basic="very" collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>날짜</Table.HeaderCell>
              <Table.HeaderCell>요일</Table.HeaderCell>
              <Table.HeaderCell>휴일명</Table.HeaderCell>
              <Table.HeaderCell>수정</Table.HeaderCell>
              <Table.HeaderCell>삭제</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {restDays.map((day) => (
              <Table.Row key={day.id}>
                <Table.Cell>{day.date}</Table.Cell>
                <Table.Cell>
                  {weekDay[moment(day.date).isoWeekday()]}
                </Table.Cell>
                <Table.Cell>{day.dateName}</Table.Cell>
                <Table.Cell>
                  <Button
                    size="tiny"
                    onClick={() => showModal(day)}
                    content={'수정'}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Button
                    size="tiny"
                    onClick={() => _deleteRestDay(day)}
                    content={'삭제'}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Modal size="tiny" open={modalOpen} onClose={closeModal}>
          <Modal.Header>휴일 {!restDay.id ? '추가' : '수정'}</Modal.Header>
          <Modal.Content>
            <Input
              type="date"
              value={restDay.date}
              onChange={(_, { value }) => handleChange('date', value)}
            />
            <Input
              fluid
              name="dateName"
              value={restDay.dateName}
              onChange={(e) => handleChange('dateName', e.target.value)}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content={!restDay.id ? '추가' : '수정'}
              onClick={_submit}
            />
            <Button color="black" content="닫기" onClick={closeModal} />
          </Modal.Actions>
        </Modal>
      </Grid.Column>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const user = state.app.get('user');
  return {
    user,
  };
};

export default connect(mapStateToProps, null)(RestDay);
