import React from 'react';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import { DescriptionModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import TextInput from '@component/TextInput';
import {
  DescriptionModule,
  DescriptionModuleConvertData2Form,
  DescriptionModuleConvertForm2Data,
} from '../../DescriptionModuleForm.type';
import { validate } from './HyperlinkModule.validator';
import {
  convertData2Form,
  convertForm2Data,
} from './HyperlinkModule.serializer';

type HyperlinkModuleProps = {
  type: 'HYPERLINK';
  urlText: string;
  url: string;
  backgroundColor: string;
  buttonColor: string;
  textColor: string;
  moduleIndex: number;
  onUpdate: (index: number, item: DescriptionModuleFormType) => void;
};
const HyperlinkModule: DescriptionModule<HyperlinkModuleProps> = (
  props: HyperlinkModuleProps,
) => {
  const {
    type,
    urlText,
    url,
    backgroundColor,
    buttonColor,
    textColor,
    moduleIndex,
    onUpdate,
  } = props;

  const handleUpdate = (
    updated: Partial<Omit<HyperlinkModuleProps, 'onUpdate'>>,
  ) =>
    onUpdate(moduleIndex, {
      type,
      urlText,
      url,
      backgroundColor,
      buttonColor,
      textColor,
      ...updated,
    });

  return (
    <FlexBox.Column gap={6}>
      <Label>{type}</Label>
      <Label>URL</Label>
      <TextInput value={url} onChange={(text) => handleUpdate({ url: text })} />

      <Label>URL 문구 (1줄, 최대 20자)</Label>
      <TextInput
        value={urlText}
        onChange={(text) => handleUpdate({ urlText: text })}
      />

      <Label>배경색</Label>
      <TextInput
        value={backgroundColor}
        onChange={(text) => handleUpdate({ backgroundColor: text })}
      />

      <Label>버튼색</Label>
      <TextInput
        value={buttonColor}
        onChange={(text) => handleUpdate({ buttonColor: text })}
      />

      <Label>버튼 글씨색</Label>
      <TextInput
        value={textColor}
        onChange={(text) => handleUpdate({ textColor: text })}
      />
    </FlexBox.Column>
  );
};

HyperlinkModule.validate = validate;
HyperlinkModule.convertForm2Data =
  convertForm2Data as DescriptionModuleConvertForm2Data;
HyperlinkModule.convertData2Form =
  convertData2Form as DescriptionModuleConvertData2Form;
HyperlinkModule.moduleName = '하이퍼링크';
HyperlinkModule.defaultData = {
  type: 'HYPERLINK',
  urlText: '',
  url: '',
  backgroundColor: '#FFFFFF',
  buttonColor: '#353535',
  textColor: '#FFFFFF',
};
export default HyperlinkModule;
