import React from 'react';
import { Table } from 'semantic-ui-react';
import { Race } from '../../../../../types';

type Props = {
  offerWallInfo: Race.OfferWall[];
};

const OfferWallInfo = ({ offerWallInfo }: Props) => {
  return (
    <>
      <h3>오퍼월 정보</h3>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>id</Table.HeaderCell>
            <Table.HeaderCell width={2}>제목</Table.HeaderCell>
            <Table.HeaderCell width={2}>오퍼월 성공 인원</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {offerWallInfo.map(({ id, title, successCount }) => (
            <Table.Row key={id}>
              <Table.Cell width={1}>{id}</Table.Cell>
              <Table.Cell width={2}>{title}</Table.Cell>
              <Table.Cell width={2}>{successCount}명</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default OfferWallInfo;
