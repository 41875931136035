import FlexBox from '@component/FlexBox/FlexBox';
import { isNaN } from 'lodash';
import React, { useState } from 'react';
import { Button, Input } from 'semantic-ui-react';

const Search = () => {
  const [search, setSearch] = useState<string>('');

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) return;
    setSearch(e.target.value);
  };

  const handleRoute = () => {
    window.open(`/challenges/${search}/achievements`, '_blank');
  };
  return (
    <FlexBox.Row gap={4}>
      <Input
        placeholder="챌린지 id로 인증샷 조회"
        value={search}
        onChange={handleInput}
      />
      <Button
        disabled={!search}
        style={{ height: '38px' }}
        onClick={handleRoute}
      >
        이동
      </Button>
    </FlexBox.Row>
  );
};

export default Search;
