import { useQueryClient } from '@tanstack/react-query';
import { apis } from '../../../api';
import { getQueryKey } from './getQueryKey';
import { Payment } from '../../../container/Benefit/PedometerGiftiShop/PedometerGiftiShopBuyers/PedometerGiftiShopBuyers.types';

export const useGiftiShopPaymentsMutation = () => {
  const queryClient = useQueryClient();

  const checkPaymentIds = async (date: string, paymentIds: number[]) => {
    const res: { success: boolean } =
      await apis.checkPedometerGiftiShopPayments(paymentIds);
    if (!res?.success) return false;
    queryClient.setQueryData<{ payments: Payment[] } | undefined>(
      getQueryKey('GIFTI_SHOP_PAYMENTS', { date }),
      (data) => {
        if (!data) return data;

        return {
          payments: data.payments.map((v) => {
            if (paymentIds.includes(v.id)) {
              return { ...v, isReceived: true };
            }
            return v;
          }),
        };
      },
    );
    return true;
  };

  return {
    checkPaymentIds,
  };
};
