import { ApiType } from '@constant/apiType';
import { _request } from './factory';
import { Race } from '@types';

type GetCouponListParams = {
  offset: number;
  limit: number;
  keyword: string;
  ids?: number[];
};

type GetCouponListResponse = {
  isEnd: boolean;
  coupons: Race.CouponMin[];
};

const getCouponList = async (
  params: GetCouponListParams,
): Promise<GetCouponListResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: '/admin/race/coupons/min',
    params,
  });
};

export default {
  getCouponList,
};
