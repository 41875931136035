import { SupportersInstagramModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const DEFAULT_DATA: {
  type: 'COLLABO-SUPPORTERS-INSTAGRAM';
  data: SupportersInstagramModuleFormType;
} = {
  type: 'COLLABO-SUPPORTERS-INSTAGRAM',
  data: {
    instagramNoticeList: [
      '챌린지에 참가하시기 전, 공개 계정 연동과 포스팅 및 계정명 노출에 동의해 주세요.',
      '인스타그램 팔로워 수 10명, 게시글 3개 이상을 권장합니다.',
      '본 챌린지를 통해 업로드하신 인스타그램 포스팅은 제휴사에서 마케팅 컨텐츠로 활용될 수 있습니다. ',
      '체험단 선정 후 미인증 시, 향후 체험단 선발에 불이익이 있을 수 있습니다. 챌린지 기간 중 계정 연동을 해제 하거나 연동 계정이 아닌 다른 계정으로 인증하면 레드카드가 발급되고 향후 제품 증정 챌린지에 참가할 수 없습니다.',
    ],
    collaboImageUrls: [],
    checkList: [
      '공개 계정에 첫 번째 이미지로 올리기',
      '제품 전면이 잘 보이도록 업로드하기',
      '하단의 필수 해시태그 기입하기',
    ],
    mainExampleImageUrl: '',
    exampleImageUrls: [],
    tipTextList: [
      {
        text: '체험단 제품은 별도 안내가 없어도 인증 시작 전 주 토요일까지 도착할 예정입니다.',
        type: 'normal',
      },
      {
        text: '체험단 확정 후에는 챌린지 취소 및 주소지 변경이 절대 불가합니다.',
        type: 'normal',
      },
      {
        text: '당첨 물품은 신청 시 등록하신 주소로 발송되며 부정확한 정보기입으로 생긴 문제에 대해서는 재발송과 참가비 환불이 불가합니다.',
        type: 'normal',
      },
      {
        text: '챌린저스는 이벤트 진행 및 당첨자 선정까지 진행하며, 배송 및 물품 제공은 해당 브랜드사에서 직접 담당하고 있습니다. 배송 및 물품 관련 문의 사항의 경우 답변이 지연될 수 있습니다.',
        type: 'normal',
      },
      {
        text: '하나의 제품 제공 챌린지에만 최종 선정될 수 있습니다. 선정된 후엔 챌린지 변경을 할 수 없으니 선발을 원하는 챌린지에 신중하게 참가해 주세요.',
        type: 'normal',
      },
      {
        text: '챌린지 신청 시 연동한 인스타그램 id로 포스팅하셔야하며, 챌린지 인증기간 내 인스타그램 id는 변경할 수 없습니다.',
        type: 'normal',
      },
      {
        text: '디바이스 당 1명만 신청할 수 있습니다. 디바이스에서 다중 신청 시 신청 순서가 늦은 참가자는 자동으로 신청이 취소될 수 있습니다.',
        type: 'normal',
      },
      {
        text: '증정받은 제품을 재판매한 것이 확인될 경우, 향후 제품 증정 챌린지 참가에서 영구 제외됩니다.',
        type: 'normal',
      },
    ],
  },
};
