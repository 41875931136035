import {
  DescriptionCollaboAdditionalRewardModuleFormType,
  DescriptionModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const validate = (
  descriptionModule: DescriptionModuleFormType[],
): { isValid: true } | { isValid: false; message: string } => {
  const target = descriptionModule.find(
    ({ type }) => type === 'COLLABO-ADDITIONAL-REWARD',
  );
  if (!target) return { isValid: true };

  const {
    data: { to, itemName, itemDetailName, itemDetailDescription, image },
  } = target as DescriptionCollaboAdditionalRewardModuleFormType;

  if (!to.length)
    return {
      isValid: false,
      message: '[상세모듈] 추가 리워드 받는 대상을 입력해주세요',
    };
  if (!itemName.length)
    return {
      isValid: false,
      message: '[상세모듈] 추가 리워드 아이템 이름을 입력해주세요',
    };
  if (!itemDetailName.length)
    return {
      isValid: false,
      message: '[상세모듈] 추가 리워드 아이템 상세 이름을 입력해주세요',
    };
  if (!itemDetailDescription.length)
    return {
      isValid: false,
      message: '[상세모듈] 추가 리워드 아이템 상세 추가정보를 입력해주세요',
    };
  if (!image.length)
    return {
      isValid: false,
      message: '[상세모듈] 추가 리워드 이미지를 입력해주세요',
    };

  return { isValid: true };
};
