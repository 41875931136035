import React, { Component } from 'react';
import { Button, Container, Grid, Header, Loader } from 'semantic-ui-react';
import moment from 'moment';
import api from '../../../api';
import DateSelect from '../DateSelect';
import ChallengeUser from '../ChallengeUser';
import LoseUser from '../LoseUser';
import OutUser from '../OutUser';
import { Link } from 'react-router-dom';

class Total extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalStatistics: {
        total: {
          registerUserCount: 0,
          totalRegisterCount: 0,
          totalRegisterDeposit: 0,
          cardRegisterCount: 0,
          transferRegisterCount: 0,
          cancelUserCount: 0,
          totalCancelCount: 0,
          totalCancelDeposit: 0,
          cardCancelCount: 0,
          transferCancelCount: 0,
          netUser: {},
          userCount: 0,
          beginnerUserIds: [],
          eventUserIds: [],
          loseUserIds: [],
          outUserCount: 0,
          userSexAgeObj: {},
          userSexAgeRegisterObj: {},
          newUserSexAgeRegisterObj: {},
        },
        retention: {},
      },
    };
    this.date = {
      filterStartDatetime: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      filterEndDatetime: moment().format('YYYY-MM-DD'),
    };
  }

  async componentDidMount() {
    // if (this.props.totalStatistics) {
    //   const totalStatistics = await this.props.totalStatistics;
    //   console.log(totalStatistics);
    //   this.setState({totalStatistics, isMounted: true});
    // } else if (this.props.location.state) {
    //   const totalStatistics = await this.props.location.state.totalStatistics;
    //   this.setState({totalStatistics, isMounted: true});
    //   console.log(totalStatistics);
    // } else {
    //
    // }
    const { filterStartDatetime, filterEndDatetime } = this.date;
    this.getTotalStatistics(filterStartDatetime, filterEndDatetime);
  }

  getTotalStatistics = (filterStartDatetime, filterEndDatetime) => {
    api
      .get('/admin/statistics/total', {
        params: {
          filterStartDatetime: moment(filterStartDatetime).format(
            'YYYY-MM-DD HH:mm:ss',
          ),
          filterEndDatetime: moment(filterEndDatetime).format(
            'YYYY-MM-DD HH:mm:ss',
          ),
        },
      })
      .then((response) => {
        const totalStatistics = response.data.data.totalStatistics;
        console.log(totalStatistics);
        this.setState({ totalStatistics, isMounted: true });
      });
  };

  handleDate = (name, date) => {
    this.date[name] = date;
    console.log(name, date, this.date);
  };

  render() {
    const { isMounted, totalStatistics } = this.state;
    return (
      <Container>
        <Grid>
          <Grid.Column width={3}>
            <Header as="h3">검색 조건</Header>
            <DateSelect handleDate={this.handleDate} />
            <Button onClick={this.getTotalStatistics} style={styles.button}>
              조회
            </Button>
            <Button
              fluid
              as={Link}
              to={{ pathname: '/challenge-search' }}
              style={styles.button}
            >
              챌린지 검색 키워드
            </Button>
            <Button
              fluid
              as={Link}
              to={{ pathname: '/statistics' }}
              style={styles.button}
            >
              일별/리텐션 통계
            </Button>
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={12}>
            {!isMounted && <Loader active inline="centered" />}
            {isMounted && (
              <Grid>
                <Grid.Row>
                  <Header as="h3">
                    85% 미만 달성 경험이 있는 유저들의 챌린지 등록 현황
                  </Header>
                  <LoseUser totalStatistics={totalStatistics} />
                </Grid.Row>
                <Grid.Row>
                  <Header as="h3">탈퇴 유저들의 챌린지 등록 현황</Header>
                  <OutUser totalStatistics={totalStatistics} />
                </Grid.Row>
                <Grid.Row>
                  <Header as="h3">챌린지 신청/취소 유저 목록</Header>
                  <ChallengeUser totalStatistics={totalStatistics} />
                </Grid.Row>
              </Grid>
            )}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default Total;

const styles = {
  button: {
    marginTop: 5,
  },
};
