import { Form, Input, Image, Button } from 'semantic-ui-react';
import { handleChangeSingleFile } from '../../../utils/uploadImage';
import * as S from '../Style';
import { moduleType } from '../offerWallType';

const OfferWallModule = ({
  moduleIdx,
  module,
  handleChangeFile,
  handleChangeModuleValue,
}) => {
  switch (module.type) {
    case moduleType.imageList:
      return (
        <ImageListModule
          moduleIdx={moduleIdx}
          module={module}
          handleChangeFile={handleChangeFile}
          handleChangeModuleValue={handleChangeModuleValue}
        />
      );
    case moduleType.mainImageListModule:
      return (
        <MainImageListModule
          moduleIdx={moduleIdx}
          module={module}
          handleChangeFile={handleChangeFile}
          handleChangeModuleValue={handleChangeModuleValue}
        />
      );
    case moduleType.cautionModule:
      return (
        <CautionModule
          moduleIdx={moduleIdx}
          module={module}
          handleChangeModuleValue={handleChangeModuleValue}
        />
      );
    case moduleType.descriptionModule:
      return (
        <DescriptionModule
          moduleIdx={moduleIdx}
          module={module}
          handleChangeModuleValue={handleChangeModuleValue}
        />
      );
    case moduleType.descriptionButtonModule:
      return (
        <DescriptionButtonModule
          moduleIdx={moduleIdx}
          module={module}
          handleChangeModuleValue={handleChangeModuleValue}
        />
      );
    case moduleType.descriptionListModule:
      return (
        <DescriptionListModule
          moduleIdx={moduleIdx}
          module={module}
          handleChangeModuleValue={handleChangeModuleValue}
        />
      );
    case moduleType.titleModule:
      return <TitleModule moduleIdx={moduleIdx} />;
    case 'AchievementExampleModule':
      return (
        <AchievementExampleModule
          module={module}
          moduleIdx={moduleIdx}
          handleChangeModuleValue={handleChangeModuleValue}
        />
      );
    case moduleType.missionReAchievementModule:
      return <MissionReAchievementModule moduleIdx={moduleIdx} />;
    case moduleType.homeButtonInRaceDetailModule:
      return <HomeButtonInRaceDetailModule moduleIdx={moduleIdx} />;
    default:
      return <></>;
  }
};

export default OfferWallModule;

const MainImageListModule = ({
  moduleIdx,
  module,
  handleChangeFile,
  handleChangeModuleValue,
}) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 메인 이미지 모듈</h4>
      <Form.Field>
        <label>이미지를 업로드하세요</label>
        <Input
          type="file"
          name={`${moduleIdx}-imageUrlList`}
          onChange={handleChangeFile}
        />
        {module.imageUrlList?.length > 0 && (
          <S.RowContainer>
            {module.imageUrlList.map((url, idx) => (
              <S.ImageWrapper key={url}>
                <Image src={url} size="small" />
                <S.ImageRemoveIcon
                  top={'0px'}
                  left={'0px'}
                  onClick={() => {
                    const imageUrlList = [...module.imageUrlList];
                    imageUrlList.splice(idx, 1);
                    handleChangeModuleValue(
                      moduleIdx,
                      'imageUrlList',
                      imageUrlList,
                    );
                  }}
                />
              </S.ImageWrapper>
            ))}
          </S.RowContainer>
        )}
      </Form.Field>
    </>
  );
};

const ImageListModule = ({
  moduleIdx,
  module,
  handleChangeFile,
  handleChangeModuleValue,
}) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 이미지 모듈</h4>
      <Form.Field>
        <label>이미지를 업로드하세요</label>
        <Input
          type="file"
          name={`${moduleIdx}-imageUrlList`}
          onChange={handleChangeFile}
        />
        {module.imageUrlList?.length > 0 && (
          <S.RowContainer>
            {module.imageUrlList.map((url, idx) => (
              <S.ImageWrapper key={url}>
                <Image src={url} size="small" />
                <S.ImageRemoveIcon
                  top={'0px'}
                  left={'0px'}
                  onClick={() => {
                    const imageUrlList = [...module.imageUrlList];
                    imageUrlList.splice(idx, 1);
                    handleChangeModuleValue(
                      moduleIdx,
                      'imageUrlList',
                      imageUrlList,
                    );
                  }}
                />
              </S.ImageWrapper>
            ))}
          </S.RowContainer>
        )}
      </Form.Field>
    </>
  );
};

const AchievementExampleModule = ({
  moduleIdx,
  module,
  handleChangeModuleValue,
}) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 인증삿 예시 모듈</h4>
      <Form.Field>
        <label>인증샷 예시를 올려주세요</label>
        <Input
          type="file"
          name={`${moduleIdx}-imageUrl`}
          onChange={async (e) => {
            const temp = await handleChangeSingleFile(e, {});
            const imageUrl = temp[`${moduleIdx}-imageUrl`];
            handleChangeModuleValue(moduleIdx, 'imageUrl', imageUrl);
          }}
        />
        <Image src={module.imageUrl} size="medium" />
      </Form.Field>
    </>
  );
};

const CautionModule = ({ moduleIdx, module, handleChangeModuleValue }) => {
  const onMoveModule = (index, direction) => {
    if (direction === 'up' && index === 0) return;
    if (direction === 'down' && index === module.cautionList.length - 1)
      return m;
    const cautionList = [...module.cautionList];
    const removed = cautionList.splice(index, 1);
    direction === 'up'
      ? cautionList.splice(index - 1, 0, removed[0])
      : cautionList.splice(index + 1, 0, removed[0]);
    handleChangeModuleValue(moduleIdx, 'cautionList', cautionList);
  };

  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] (필수)유의사항</h4>
      <S.LabelButton
        onClick={() => {
          const cautionList = [...module.cautionList, ''];
          handleChangeModuleValue(moduleIdx, 'cautionList', cautionList);
        }}
      >
        추가
      </S.LabelButton>
      <Form.Field>
        {(module?.cautionList || []).map((caution, idx) => {
          return (
            <S.RowContainer key={`caution-${idx}`}>
              <Input
                value={caution}
                name={`${moduleIdx}-${idx}`}
                onChange={(e) => {
                  const cautionList = [...module.cautionList];
                  cautionList[idx] = e.target.value;
                  handleChangeModuleValue(
                    moduleIdx,
                    'cautionList',
                    cautionList,
                  );
                }}
              />
              <Button
                size="mini"
                icon={'angle up'}
                onClick={() => onMoveModule(idx, 'up')}
              />
              <Button
                size="mini"
                icon={'angle down'}
                onClick={() => {
                  onMoveModule(idx, 'down');
                }}
              />
              <Button
                size="mini"
                icon={'delete'}
                value={0}
                onClick={() => {
                  const cautionList = [...module.cautionList].filter(
                    (_, j) => j !== idx,
                  );
                  handleChangeModuleValue(
                    moduleIdx,
                    'cautionList',
                    cautionList,
                  );
                }}
              />
            </S.RowContainer>
          );
        })}
      </Form.Field>
    </>
  );
};

const DescriptionButtonModule = ({
  moduleIdx,
  module,
  handleChangeModuleValue,
}) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 설명 버튼 모듈</h4>
      <Form.Field>
        <label>모듈 설명 버튼을 작성해주세영</label>
        <label>이것은 버튼의 텍스트</label>
        <Input
          value={module.text}
          onChange={(e) =>
            handleChangeModuleValue(moduleIdx, 'text', e.target.value)
          }
        />
        <label>이것은 버튼의 url</label>
        <Input
          value={module.url}
          onChange={(e) =>
            handleChangeModuleValue(moduleIdx, 'url', e.target.value)
          }
        />
      </Form.Field>
    </>
  );
};

const DescriptionModule = ({ module, moduleIdx, handleChangeModuleValue }) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] </h4>
      <Form.Field>
        <label>설명 모듈이에여</label>
        <Input
          value={module.text}
          onChange={(e) =>
            handleChangeModuleValue(moduleIdx, 'text', e.target.value)
          }
        />
      </Form.Field>
    </>
  );
};

const TitleModule = ({ moduleIdx }) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 타이틀 모듈</h4>
    </>
  );
};

const DescriptionListModule = ({
  moduleIdx,
  module,
  handleChangeModuleValue,
}) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 디스크립션 모듈</h4>
      <S.LabelButton
        onClick={() => {
          const descriptionList = [
            ...module.descriptionList,
            {
              label: `STEP ${module.descriptionList.length + 1}`,
              description: '',
            },
          ];
          handleChangeModuleValue(moduleIdx, 'desciprionList', descriptionList);
        }}
      >
        추가
      </S.LabelButton>
      <Form.Field>
        <label>혜택, 혜택 지급일은 자동으로 채워진답니다</label>
        <label>라벨, 텍스트</label>
        {(module.descriptionList || []).map((description, idx) => {
          return (
            <S.RowContainer>
              <Input
                value={description.label}
                onChange={(e) => {
                  const descriptionList = [...module.descriptionList];
                  descriptionList[idx] = {
                    description: descriptionList[idx].description,
                    label: e.target.value,
                  };
                  handleChangeModuleValue(
                    moduleIdx,
                    'desciprionList',
                    descriptionList,
                  );
                }}
              />
              <Input
                value={description.description}
                onChange={(e) => {
                  const descriptionList = [...module.descriptionList];
                  descriptionList[idx] = {
                    label: descriptionList[idx].label,
                    description: value,
                  };
                  handleChangeModuleValue(
                    moduleIdx,
                    'desciprionList',
                    descriptionList,
                  );
                }}
              />
            </S.RowContainer>
          );
        })}
      </Form.Field>
    </>
  );
};

const MissionReAchievementModule = ({ moduleIdx }) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 재인증 모듈</h4>
      <div>(인증샷 실패처리 됐을 때 노출되는 모듈)</div>
    </>
  );
};

const HomeButtonInRaceDetailModule = ({ moduleIdx }) => {
  return (
    <>
      <h4>[모듈 {moduleIdx + 1}] 홈 버튼 모듈</h4>
      <div>(랜선 상세 롤링 배너에 노출되는 모듈)</div>
    </>
  );
};
