import { useCallback } from 'react';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useGoalId = () => useChallengeFormStore((s) => s.challenge.goalId);

export const useUpdateGoalId = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const updateGoalId = (goalId: number | null) =>
    updateChallengeForm({ goalId });

  return { updateGoalId };
};

export const useUpdateAchievementCount = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const updateAchievementCount = useCallback(
    (achievementCount: number) => updateChallengeForm({ achievementCount }),
    [updateChallengeForm],
  );

  return { updateAchievementCount };
};
