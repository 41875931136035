const bannerTypeObj = {
  challengeMain: {
    text: '챌린지 메인',
    value: 'CHALLENGE_MAIN',
    key: 'CHALLENGE_MAIN',
  },
  challengeSub: {
    text: '챌린지 서브',
    value: 'CHALLENGE_SUB',
    key: 'CHALLENGE_SUB',
  },
  raceSub: {
    text: '랜선탭 배너',
    value: 'RACE_SUB',
    key: 'RACE_SUB',
  },
  challengeCategory: {
    text: '챌린지 카테고리',
    value: 'CHALLENGE_CATEGORY',
    key: 'CHALLENGE_CATEGORY',
  },
  storeMain: { text: '스토어 메인', value: 'STORE_MAIN', key: 'STORE_MAIN' },
  storeSubLarge: {
    text: '스토어 서브(라지)',
    value: 'STORE_SUB_LARGE',
    key: 'STORE_SUB_LARGE',
  },
  storeSubMedium: {
    text: '스토어 서브(미디엄)',
    value: 'STORE_SUB_MEDIUM',
    key: 'STORE_SUB_MEDIUM',
  },
  storeHomeFloatingButton: {
    text: '플로팅 버튼',
    value: 'STORE_HOME_FLOATING_BUTTON',
    key: 'STORE_HOME_FLOATING_BUTTON',
  },
  popup: { text: '팝업', value: 'POPUP', key: 'POPUP' },
  storePopup: { text: '스토어 팝업', value: 'STORE_POPUP', key: 'STORE_POPUP' },
  benefitAdPopup: {
    text: '혜택 스퀘어',
    value: 'BENEFIT_AD_POPUP',
    key: 'BENEFIT_AD_POPUP',
  },
  mypageSub: {
    text: '마이페이지 서브',
    value: 'MYPAGE_SUB',
    key: 'MYPAGE_SUB',
  },
  invite: { text: '친구초대 화면', value: 'INVITE', key: '' },
  raceSponseSub: {
    text: '대회상세 서브',
    value: 'RACE_SPONSOR_SUB',
    key: 'INVITE',
  },
};

const bannerTypes = [
  bannerTypeObj.challengeMain,
  bannerTypeObj.challengeSub,
  bannerTypeObj.raceSub,
  bannerTypeObj.challengeCategory,
  bannerTypeObj.popup,
  bannerTypeObj.benefitAdPopup,
  bannerTypeObj.mypageSub,
  bannerTypeObj.invite,
  bannerTypeObj.raceSponseSub,
];

export { bannerTypeObj, bannerTypes };
