import { isNull } from '@utils/js.utils';
import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'timestamp'>;

export const validate: FormValidator<Params> = ({ timestamp }) => {
  if (isNull(timestamp))
    return {
      isValid: false,
      message: '[타임 스탬프 설정] 인증샷 타임스탬프를 확인해주세요',
    };
  return { isValid: true };
};
