import React from 'react';
import { transactionType } from '../../../../constant/transaction';
import { Table } from 'semantic-ui-react';
import moment from 'moment';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';
import _ from 'lodash';

const RemainBalancePerTransaction = ({ cashTransactions, cashAmounts }) => {
  let accumulatedSubtractedValue = 0;
  const transactionList = [...cashTransactions]
    .reverse()
    .map((transaction, idx) => {
      if (
        ![transactionType.CHARGE_CARD, transactionType.REGISTER_CARD].includes(
          transaction.type,
        )
      )
        return null;

      const withdrawAmount = _.sumBy(
        transaction.withdrawTransactions,
        'amount',
      );
      const subtractedValue =
        transaction.amount + withdrawAmount - transaction.deposit;
      accumulatedSubtractedValue += subtractedValue;
      const newAccumulatedSubtractedValue = accumulatedSubtractedValue;
      return (
        <Table.Row key={idx}>
          <Table.Cell>
            {moment(transaction.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          </Table.Cell>
          <Table.Cell>{setCurrencyFormat(transaction.amount)}</Table.Cell>
          <Table.Cell>{setCurrencyFormat(withdrawAmount)}</Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(transaction.amount + withdrawAmount)}
          </Table.Cell>
          <Table.Cell>{setCurrencyFormat(subtractedValue)}</Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(newAccumulatedSubtractedValue)}
          </Table.Cell>
          <Table.Cell>
            {transaction.payInfo} {transaction.tid}
          </Table.Cell>
        </Table.Row>
      );
    });

  return (
    <Table basic={'very'}>
      <Table.Header>
        <Table.Row>
          {[
            '날짜',
            '결제금액',
            '출금액',
            '결제잔액',
            '미환급금 차감액',
            '미환급금 누적액',
            '비고',
          ].map((title) => (
            <Table.HeaderCell key={title}>{title}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>{transactionList}</Table.Body>
    </Table>
  );
};

export default RemainBalancePerTransaction;
