import {
  DescriptionModuleFormType,
  DescriptionChallengeModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const validate = (
  descriptionModule: DescriptionModuleFormType[],
): { isValid: true } | { isValid: false; message: string } => {
  const target = descriptionModule.find(({ type }) => type === 'CHALLENGE');
  if (!target) return { isValid: true };

  const { mainHeader, subHeader, ids } =
    target as DescriptionChallengeModuleFormType;

  if (
    typeof mainHeader !== 'string' ||
    typeof subHeader !== 'string' ||
    !Array.isArray(ids)
  ) {
    return {
      isValid: false,
      message: '[상세모듈] CHALLENGE 모듈을 확인해주세요',
    };
  }

  if (!mainHeader.length)
    return {
      isValid: false,
      message: '[상세모듈] CHALLENGE 메인헤더 값을 채워주세요',
    };

  if (!ids.length)
    return {
      isValid: false,
      message: '[상세모듈] 챌린지 목록을 선택해 주세요',
    };
  return { isValid: true };
};
