import styled from 'styled-components';

export const DeleteButton = styled.button`
  border: none;
  background: #eeeeee;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  flex: 1;
  cursor: pointer;
`;

export const OptionDeleteButton = styled.button`
  border: none;
  background: #eeeeee;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 44px;
  padding: 12px;
  cursor: pointer;
`;

export const AddButton = styled.button`
  border: none;
  background: #eeeeee;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 44px;
  padding: 12px;
  flex: 1;
  cursor: pointer;
`;
