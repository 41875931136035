import {
  DescriptionCollaboNaverSmartStoreModuleFormType,
  DescriptionModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const validate = (
  descriptionModule: DescriptionModuleFormType[],
): { isValid: true } | { isValid: false; message: string } => {
  const target = descriptionModule.find(
    ({ type }) => type === 'COLLABO-NAVER-SMART-STORE',
  );
  if (!target) return { isValid: true };

  const {
    data: {
      price,
      originalPrice,
      productThumbnailImageUrl,
      reviewText,
      reviewImageUrls,
    },
  } = target as DescriptionCollaboNaverSmartStoreModuleFormType;

  if (price > originalPrice)
    return {
      isValid: false,
      message: '[상세모듈] 가격이 정가보다 비쌉니다.',
    };

  if (!productThumbnailImageUrl)
    return {
      isValid: false,
      message: '상품 썸네일 이미지를 등록해주세요.',
    };

  if (!reviewText)
    return {
      isValid: false,
      message: '리뷰를 등록해주세요.',
    };

  if (reviewImageUrls.length === 0) {
    return {
      isValid: false,
      message: '리뷰 이미지를 등록해주세요.',
    };
  }

  if (reviewImageUrls.length > 3) {
    return {
      isValid: false,
      message: '리뷰 이미지는 3개까지만 등록 가능합니다.',
    };
  }

  return { isValid: true };
};
