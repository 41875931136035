import FlexBox from '@component/FlexBox/FlexBox';
import ImageInput from '@component/ImageInput';
import Label from '@component/Label';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import TextInput from '@component/TextInput';
import { CustomImageFilterInfoType } from '@types';
import { type Option } from '../../ImageFilterForm';

type Props = {
  label: string;
  targetFilterInfo?: CustomImageFilterInfoType;
  filterOptions: Option[];
  selectedOption?: Option;
  onRadioChange: (option: Option) => void;
  onUploadImage: (image: string | undefined) => void;
  onTextChange: (text: string | undefined) => void;
  onColorChange: (color: string | undefined) => void;
};

const FilterByAchievement = ({
  label,
  targetFilterInfo,
  filterOptions,
  selectedOption,
  onRadioChange,
  onUploadImage,
  onTextChange,
  onColorChange,
}: Props) => {
  return (
    <FlexBox.Column gap={8} style={{ padding: 10 }}>
      <Label style={{ width: 80, fontSize: 14 }}>{label}</Label>

      <RadioSelector
        selectedOption={selectedOption}
        options={filterOptions}
        onChange={onRadioChange}
        style={{
          display: 'flex',
          gap: 15,
          marginRight: 20,
        }}
      />

      {targetFilterInfo?.type === 'image' && (
        <>
          <ImageInput
            label="이미지 업로드"
            uploadOnChange
            value={targetFilterInfo?.data.imageUrl}
            onChange={onUploadImage}
          />
          {targetFilterInfo?.data.imageUrl && (
            <img src={targetFilterInfo?.data.imageUrl} width={60} />
          )}
        </>
      )}

      {targetFilterInfo?.type === 'text' && (
        <TextInput
          label="텍스트"
          placeholder="텍스트를 입력해주세요"
          value={targetFilterInfo?.data.text}
          onChange={onTextChange}
        />
      )}

      {targetFilterInfo?.type === 'timestamp' && (
        <TextInput
          label="배경 컬러 ex) #00000066"
          placeholder="배경 컬러를 입력해주세요"
          value={targetFilterInfo?.data.backgroundColor}
          onChange={onColorChange}
        />
      )}
    </FlexBox.Column>
  );
};

export default FilterByAchievement;
