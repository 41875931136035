import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'maxRegisterDeposit'>,
  Pick<ReqCreateOrUpdateChallenge, 'maxRegisterDeposit'>
> = ({ maxRegisterDeposit }) => {
  if (typeof maxRegisterDeposit !== 'number')
    throw Error("[MaxRegisterDepositForm] this shouldn't be happened");

  return {
    maxRegisterDeposit,
  };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'maxRegisterDeposit'>,
  Pick<ChallengeFormType, 'maxRegisterDeposit'>
> = ({ maxRegisterDeposit }) => ({
  maxRegisterDeposit,
});
