import { formatLocalDateTime } from '@utils/date.utils';
import { addDays, endOfDay, format, setDay, startOfDay } from 'date-fns';
import { ChallengeFormType } from './NewChallengeForm.types';

export const getInitialDates = (now: Date) => {
  const registerStartDate = getInitialRegisterStartDate(now);
  const registerEndDate = getInitialRegisterEndDate(registerStartDate);
  const startDate = getInitialStartDate(registerEndDate);
  const endDate = getInitialEndDate(startDate);
  const resultDate = addDays(endDate, 1);
  const purchaseStartDate = startOfDay(now);
  const purchaseEndDate = endOfDay(now);

  return {
    registerStartDate: formatLocalDateTime(registerStartDate),
    registerEndDate: formatLocalDateTime(registerEndDate),
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
    resultDate: format(resultDate, 'yyyy-MM-dd'),
    purchaseStartDate: format(purchaseStartDate, `yyyy-MM-dd'T'HH:mm:ss`),
    purchaseEndDate: format(purchaseEndDate, `yyyy-MM-dd'T'HH:mm:ss`),
  };
};

// 챌린지 등록 가능일은 화요일에 시작
const getInitialRegisterStartDate = (now: Date) => {
  if (now.getDay() > 2) return startOfDay(setDay(addDays(now, 7), 2));
  return startOfDay(setDay(now, 2));
};

// 등록 가능일 다음 주 월요일 11:59:59까지
const getInitialRegisterEndDate = (registerStartDate: Date) => {
  return endOfDay(addDays(registerStartDate, 6));
};

// 등록 가능일 다음 주 월요일 00:00:00부터
const getInitialStartDate = (registerEndDate: Date) => {
  return startOfDay(registerEndDate);
};

// 시작일 다음 주 일요일 23:59:59까지
const getInitialEndDate = (startDate: Date) => {
  return endOfDay(addDays(startDate, 13));
};

export const getChallengeId = () => {
  const id = Number(window.location.pathname.split('/')[2]);
  if (Number.isNaN(id)) return null;
  return id;
};

// 중복되는 인증회차 있는지 확인
export const hasDuplicatedAchievementIndex = (
  params: Pick<
    ChallengeFormType,
    'instagramInfoList' | 'naverBlogInfoList' | 'purchaseInfo'
  >,
) => {
  const { instagramInfoList, naverBlogInfoList, purchaseInfo } = params;

  const purchaseInfoList = purchaseInfo?.items ?? [];

  const allAchievementIndexList = [
    ...instagramInfoList.flatMap((info) => info.achievementIndex),
    ...naverBlogInfoList.flatMap((info) => info.achievementIndexList),
    ...purchaseInfoList.flatMap((info) => info.achievementIndex),
  ];

  return (
    new Set(allAchievementIndexList).size !== allAchievementIndexList.length
  );
};
