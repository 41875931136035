import _ from 'lodash';
import { DescriptionCollaboSupportersInstagramModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { CollaboSupportersInstagramDescriptionModule } from '@chlngers/core/lib/src/types/challenge/challengeDescriptionModule.types';
import { DEFAULT_DATA } from './SupportersInstagramModule.constant';

export const convertData2Form: (
  module: CollaboSupportersInstagramDescriptionModule,
) => DescriptionCollaboSupportersInstagramModuleFormType = ({ modules }) => {
  const { data } = _.cloneDeep(DEFAULT_DATA);

  modules.forEach((m) => {
    switch (m.type) {
      case 'IMAGE':
        data.collaboImageUrls = m.imageUrls;
        break;
      case 'SUPPORTERS-INSTAGRAM-ACHIEVEMENT-STEP1':
        data.checkList = m.checkList;
        data.mainExampleImageUrl = m.exampleImageUrl;
        break;
      case 'SUPPORTERS-INSTAGRAM-ACHIEVEMENT-STEP2':
        data.exampleImageUrls = m.exampleImageUrls;
        break;
      default:
    }
  });

  return {
    type: 'COLLABO-SUPPORTERS-INSTAGRAM',
    data,
  };
};

export const convertForm2Data: (
  module: DescriptionCollaboSupportersInstagramModuleFormType,
) => CollaboSupportersInstagramDescriptionModule = ({ data }) => {
  return {
    type: 'COLLABO-SUPPORTERS-INSTAGRAM',
    modules: [
      { type: 'INSTAGRAM-NOTICE', textList: data.instagramNoticeList },
      { type: 'IMAGE', imageUrls: data.collaboImageUrls },
      {
        type: 'SUPPORTES-INSTAGRAM-HOW-TO-PARTICIPATE',
      },
      {
        type: 'SUPPORTERS-INSTAGRAM-ACHIEVEMENT-STEP1',
        checkList: data.checkList,
        exampleImageUrl: data.mainExampleImageUrl,
      },
      {
        type: 'SUPPORTERS-INSTAGRAM-ACHIEVEMENT-STEP2',
        exampleImageUrls: data.exampleImageUrls,
      },
      {
        type: 'CHALLENGE-TIP',
        textList: data.tipTextList,
      },
    ],
  };
};
