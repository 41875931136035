import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { CustomFieldFormType } from '../../NewChallengeForm.types';

export const useInstagramInfoList = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      instagramInfoList: s.challenge.instagramInfoList,
    })),
  );

export const useValidationData = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      naverBlogInfoList: s.challenge.naverBlogInfoList,
      purchaseInfo: s.challenge.purchaseInfo,
      achievementCount: s.challenge.achievementCount,
    })),
  );

export const useInstagramInfoListAction = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setInstagramInfoList = (
    instagramInfoList: CustomFieldFormType['instagramInfoList'],
  ) => {
    updateChallengeForm((prev) => ({
      ...prev,
      instagramInfoList,
    }));
  };

  return {
    setInstagramInfoList,
  };
};
