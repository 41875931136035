import React from 'react';
import * as S from '../../../Style';
import { Image } from 'semantic-ui-react';
import Preview from './Preview/Preview';
import { RaceInfo } from 'src/types/race.types';

const ShareField = ({
  info,
  handleChange,
  handleImageChange,
}: {
  info: {
    ogTitle: string;
    ogDescription: string;
    ogImage: string;
    shareMessage: string;
  };
  handleChange: <K extends keyof RaceInfo>(key: K, value: RaceInfo[K]) => void;
  handleImageChange: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  return (
    <>
      <h3>공유 정보</h3>
      <S.RowContainer>
        <S.RowFormField>
          <label>og title</label>
          <input
            value={info.ogTitle}
            onChange={(e) => handleChange('ogTitle', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField>
          <label>og description</label>
          <input
            value={info.ogDescription}
            onChange={(e) => handleChange('ogDescription', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField>
          <label>og image</label>
          <input type="file" name="ogImage" onChange={handleImageChange} />
          <Image src={info.ogImage} size="small" />
        </S.RowFormField>
        <S.RowFormField>
          <label>공유 문구</label>
          <textarea
            rows={5}
            value={info.shareMessage}
            onChange={(e) => handleChange('shareMessage', e.target.value)}
          />
        </S.RowFormField>
      </S.RowContainer>
      <Preview />
    </>
  );
};

export default ShareField;
