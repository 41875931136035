import { useHistory, useLocation } from 'react-router-dom';

type QueryStrings = {
  [key: string]: string | null | undefined;
};

export const useQueryString = () => {
  const history = useHistory();
  const location = useLocation();

  const getQueryString = (field: string): string => {
    const query = new URLSearchParams(location.search);
    return query.get(field) || '';
  };

  const setQueryString = (queryObj: QueryStrings) => {
    const fullCurrentURL =
      window.location.origin + location.pathname + location.search;
    const url = new URL(fullCurrentURL);

    Object.entries(queryObj).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        url.searchParams.delete(key);
      } else {
        url.searchParams.set(key, String(value));
      }
    });

    history.push(url.pathname + url.search);
  };

  return { getQueryString, setQueryString };
};
