import FlexBox from '@component/FlexBox/FlexBox';
import { LabelMiniInfo } from '@types';
import { Button } from 'semantic-ui-react';

type Props = {
  labels: LabelMiniInfo[];
};

const LabelInfo = ({ labels }: Props) => {
  return (
    <FlexBox.Column>
      <h3>라벨 정보</h3>
      {labels.length ? (
        <FlexBox.Row>
          {labels.map((label, idx) => (
            <Button
              style={{
                marginTop: 5,
                backgroundColor: '#D9D9D9',
                color: '#777777',
                cursor: 'default',
              }}
              active
              key={idx}
              size="tiny"
            >
              [{label.id}] {label.name}
            </Button>
          ))}
        </FlexBox.Row>
      ) : (
        <span>설정된 라벨 없음</span>
      )}
    </FlexBox.Column>
  );
};

export default LabelInfo;
