import Accordion from '@component/Accordion/Accordion';
import FlexBox from '@component/FlexBox/FlexBox';
import {
  ChallengeAchievementSetting,
  ChallengeInfoForAdmin,
  ReqCreateOrUpdateChallenge,
} from '@types';
import { FC } from 'react';
import { Divider, Header } from 'semantic-ui-react';
import { useFormInitialize } from './NewChallengeForm.hooks';
import { AchievementInfoListForm } from './components/AchievementInfoListForm';
import AdminUserIdsForm from './components/AdminUserIdsForm/AdminUserIdsForm';
import AuthenticationBadExamplesForm from './components/AuthenticationBadExamplesForm/AuthenticationBadExamplesForm';
import AuthenticationCheckListForm from './components/AuthenticationCheckListForm/AuthenticationCheckListForm';
import AuthenticationGoodExamplesForm from './components/AuthenticationGoodExamplesForm/AuthenticationGoodExamplesForm';
import AuthenticationMethodForm from './components/AuthenticationMethodForm/AuthenticationMethodForm';
import CancelImpossibleForm from './components/CancelImpossibleForm/CancelImpossibleForm';
import CollaborationForm from './components/CollaborationForm/CollaborationForm';
import { CommerceForm } from './components/CommerceForm';
import CompanyIdForm from './components/CompanyIdForm/CompanyIdForm';
import CreatorForm from './components/CreatorForm/CreatorForm';
import { EndDateForm } from './components/EndDateForm';
import ExpForm from './components/ExpForm/ExpForm';
import { FaqForm } from './components/FaqForm';
import { GoalIdForm } from './components/GoalIdForm';
import InstagramInfoListForm from './components/InstagramInfoListForm/InstagramInfoListForm';
import IsPublicForm from './components/IsPublicForm/IsPublicForm';
import { IsRegisterDepositFixedForm } from './components/IsRegisterDepositFixedForm';
import MaxRegisterCountForm from './components/MaxRegisterCountForm/MaxRegisterCountForm';
import MaxRegisterDepositForm from './components/MaxRegisterDepositForm/MaxRegisterDepositForm';
import MinRegisterDepositForm from './components/MinRegisterDepositForm/MinRegisterDepositForm';
import { NaverBlogInfoListForm } from './components/NaverBlogInfoListForm';
import PurchaseInfoForm from './components/PurchaseInfoForm/PurchaseInfoForm';
import PasswordForm from './components/PasswordForm/PasswordForm';
import PublicTypeForm from './components/PublicTypeForm/PublicTypeForm';
import QnaForm from './components/QnaForm/QnaForm';
import RegisterEndDateForm from './components/RegisterEndDateForm/RegisterEndDateForm';
import RegisterStartDateForm from './components/RegisterStartDateForm/RegisterStartDateForm';
import { ResultDateForm } from './components/ResultDateForm';
import { SpecialPrizeForm } from './components/SpecialPrizeForm';
import { StartDateForm } from './components/StartDateForm';
import SubmitButton from './components/SubmitButton/SubmitButton';
import SupportersForm from './components/SupportersForm/SupportersForm';
import TagIdsForm from './components/TagIdsForm/TagIdsForm';
import ThirdTermUrlForm from './components/ThirdTermUrlForm/ThirdTermUrlForm';
import { ThumbnailImageUrlForm } from './components/ThumbnailImageUrlForm';
import TimestampForm from './components/TimestampForm/TimestampForm';
import TitleForm from './components/TitleForm/TitleForm';
import { UseItemImpossibleForm } from './components/UseItemImpossibleForm';
import { UserFilterForm } from './components/UserFilterForm';
import UserIdForm from './components/UserIdForm/UserIdForm';
import { AchievementMissionForm } from './components/AchievementMissionForm';
import StickerForm from './components/StickerForm/StickerForm';
import DescriptionModuleForm from './components/DescriptionModuleForm';
import DescriptionExtraForm from './components/DescriptionExtraForm';
import IsDeletedForm from './components/IsDeletedForm/IsDeletedForm';
import { ImageFilterForm } from './components/ImageFilterForm';
import { PostConnectPrizeForm } from './components/PostConnectPrizeForm';
import { DistanceForm } from './components/DistanceForm';
import { useChallengeFormStore } from './NewChallengeForm.store';
import { useGetGoalDetail } from '@queries/Challenge/useGetGoalDetail';
import CollaboAdminUserIdsForm from './components/CollaboAdminUserIdsForm/CollaboAdminUserIdsForm';
import LabelIdsForm from './components/LabelIdsForm/LabelIdsForm';
import CategoryForm from './components/CategoryForm/CategoryForm';
import PurchaseChannelForm from './components/PurchaseChannelForm/PurchaseChannelForm';
import PurchaseStartDateForm from './components/PurchaseStartDateForm/PurchaseStartDateForm';
import PurchaseEndDateForm from './components/PurchaseEndDateForm/PurchaseEndDateForm';
import PriceForm from './components/PriceForm/PriceForm';
import OriginalPriceForm from './components/OriginalPriceForm/OriginalPriceForm';

type Props = {
  challenge?: ChallengeInfoForAdmin;
  onSubmit: (
    challenge: ReqCreateOrUpdateChallenge,
    setting: ChallengeAchievementSetting,
  ) => void;
};

const NewChallengeForm: FC<Props> = ({ challenge, onSubmit }) => {
  const { isInitialized } = useFormInitialize(challenge);

  const { challenge: challengeForm } = useChallengeFormStore();
  const { data: goal } = useGetGoalDetail(challengeForm.goalId || 0);
  const isCollaboChallenge = goal?.goalCategory2 === '제휴';

  if (!isInitialized) return <></>;

  return (
    <FlexBox.Column gap={16}>
      <h3>챌린지 기본 정보</h3>

      <GoalIdForm />
      <TitleForm />

      <FlexBox.Row gap={12} style={{ width: '100%' }}>
        <UserIdForm />
        <PasswordForm />
      </FlexBox.Row>

      <AdminUserIdsForm />
      <CollaboAdminUserIdsForm />

      <LabelIdsForm />

      <TagIdsForm />

      <Divider />

      <h3>뷰티 득템 개편</h3>

      <CategoryForm />
      <PurchaseChannelForm />
      <FlexBox.Row gap={12}>
        <PurchaseStartDateForm />
        <PurchaseEndDateForm />
      </FlexBox.Row>
      <OriginalPriceForm />
      <PriceForm />

      <Divider />

      <FlexBox.Column gap={5}>
        <h3 style={{ margin: 0 }}>챌린지 모집 및 진행 일정</h3>
        <strong style={{ color: '#ff8000' }}>
          📣 챌린지 모집 및 진행 일정을 정확하게 입력해주세요.
        </strong>
      </FlexBox.Column>

      <FlexBox.Row gap={12} style={{ width: '100%' }}>
        <RegisterStartDateForm />
        <RegisterEndDateForm />
      </FlexBox.Row>

      <FlexBox.Row gap={12} style={{ width: '100%' }}>
        <StartDateForm />
        <EndDateForm />
        <ResultDateForm />
      </FlexBox.Row>

      <MaxRegisterCountForm />

      {/* 참가비 */}
      <FlexBox.Row gap={12} style={{ width: '100%' }}>
        <IsRegisterDepositFixedForm />
        <MinRegisterDepositForm />
        <MaxRegisterDepositForm />
      </FlexBox.Row>
      <Divider />

      <AchievementInfoListForm />
      <Divider />

      {/* 특별 상금 */}
      <SpecialPrizeForm />
      <Divider />

      <h3 style={{ margin: 0 }}>챌린지 상태 설정</h3>
      <ExpForm />

      <FlexBox.Row gap={12} style={{ width: '100%' }}>
        <UseItemImpossibleForm />
        <CancelImpossibleForm />
        <IsPublicForm />
        <PublicTypeForm />
      </FlexBox.Row>
      <IsDeletedForm />
      <Divider />

      <h3 style={{ margin: 0 }}>챌린지 상세페이지 관련 정보</h3>
      <ThumbnailImageUrlForm />
      <DescriptionModuleForm />
      <DescriptionExtraForm />
      <Divider />

      <h3 style={{ margin: 0 }}>챌린지 인증 방법 관련 정보</h3>
      <AuthenticationMethodForm />

      <FlexBox.Column gap={8}>
        <Accordion
          open={
            (challenge?.authenticationGoodExamples.length || 0) +
              (challenge?.authenticationBadExamples.length || 0) ===
            0
          }
          title="인증 미션 설정(제휴)"
          as="h5"
        >
          <AchievementMissionForm />
        </Accordion>

        <Accordion
          open={
            (challenge?.authenticationGoodExamples.length || 0) +
              (challenge?.authenticationBadExamples.length || 0) >
            0
          }
          title="인증 미션 설정(공식)"
          as="h5"
        >
          <AuthenticationGoodExamplesForm />
          <AuthenticationBadExamplesForm />
        </Accordion>
      </FlexBox.Column>

      <AuthenticationCheckListForm />
      <Divider />

      <FaqForm />

      <PurchaseInfoForm open={isCollaboChallenge} />

      <InstagramInfoListForm open={isCollaboChallenge} />

      <NaverBlogInfoListForm open={isCollaboChallenge} />

      <Accordion
        open={isCollaboChallenge}
        title="제휴챌린지 관련 정보 관리하기"
        as="h5"
      >
        <FlexBox.Column gap={12}>
          <ThirdTermUrlForm />
          <CompanyIdForm />
          <Divider />
          <CollaborationForm />
        </FlexBox.Column>
      </Accordion>

      <SupportersForm open={isCollaboChallenge} />

      <UserFilterForm open={isCollaboChallenge} />

      <PostConnectPrizeForm />

      <CommerceForm />

      <DistanceForm />

      <ImageFilterForm />

      <TimestampForm />

      <StickerForm />

      <CreatorForm />

      <QnaForm />

      <SubmitButton onSubmit={onSubmit} />
    </FlexBox.Column>
  );
};

export default NewChallengeForm;
