import { ApiType } from '@constant/apiType';
import { _request } from './factory';
import { Purchase } from '@types';

interface GetPurchaseChallengesParams {
  endDate?: number;
  startDate?: number;
  keyowrd?: string;
  limit?: number;
  offset?: number;
  periodCriterion?: string;
}

const getPurchaseChallenges = (
  params: GetPurchaseChallengesParams,
): Promise<{
  challenges: Purchase.Challenge[];
  isEnd: boolean;
  totalCount: number;
}> => {
  return _request({
    apiType: ApiType.V3,
    method: 'GET',
    url: `/compatible/v2/admin/purchase/challenges`,
    params,
  });
};

const getPurchaseChallengeDetail = (
  id: number,
): Promise<{ challenge: Purchase.Challenge }> => {
  return _request({
    apiType: ApiType.V3,
    method: 'GET',
    url: `/compatible/v2/admin/purchase/challenges/${id}`,
  });
};

interface GetPurchaseChallengeParticipateHistory {
  participants: {
    id: number;
    purchaseCampaignId: number;
    purchaseChallengeParticipantId: number;
    title: string;
    cashDeposit: number;
    startDate: number;
    endDate: number;
    registeredAt: number;
  }[];
}

const getPurchaseChallengeParticipants = (
  userId: number,
): Promise<GetPurchaseChallengeParticipateHistory> => {
  return _request({
    apiType: ApiType.V3,
    method: 'GET',
    url: `/compatible/v2/admin/users/${userId}/purchase/challenges/participants`,
  });
};

const unregisterPurchaseChallenge = (participantId: number): Promise<void> => {
  return _request({
    apiType: ApiType.V3,
    method: 'DELETE',
    url: `/compatible/v2/admin/purchase/challenges/participants/${participantId}`,
  });
};

export default {
  getPurchaseChallenges,
  getPurchaseChallengeDetail,
  getPurchaseChallengeParticipants,
  unregisterPurchaseChallenge,
};
