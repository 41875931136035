import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useCollaboAdminUserIds = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      collaboAdminUserIds: s.challenge.collaboAdminUserIds,
    })),
  );
};

export const useCollaboAdminUserIdsActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setCollaboAdminUserIds = (collaboAdminUserIds: number[]) =>
    updateChallengeForm({ collaboAdminUserIds });

  return { setCollaboAdminUserIds };
};
