import { apis } from '@api/index';
import FlexBox from '@component/FlexBox/FlexBox';
import { useCallback, useEffect, useState } from 'react';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import { Divider } from 'semantic-ui-react';
import { ChallengeSelector } from './components/ChallengeSelector';
import { ImageSelector } from './components/ImageSelector';
import { ImageView } from './components/ImageView';
import { Image, ChallengeType } from './DataLabel.types';
import { PAGE_LIMIT } from './DataLabel.constants';

export const DataLabel = () => {
  const [activeChallengeType, setActiveChallengeType] =
    useState<ChallengeType>('gym');
  const [imageList, setImageList] = useState<Image[]>([]);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const [offset, setOffset] = useState(0);
  const [maxOffset, setMaxOffset] = useState(10000000);

  const handleChangeActiveChallengeType = (cType: ChallengeType) => {
    if (cType !== activeChallengeType) {
      setActiveChallengeType(cType);
      setSelectedImage(null);
      setOffset(0);
    }
  };

  const handleSelectedImage = (image: Image) => {
    if (image.id !== selectedImage?.id) {
      setSelectedImage(image);
    }
  };

  const moveImage = (idx: number) => {
    if (!selectedImage) return;
    const targetId = selectedImage.id + idx;
    const targetImage = imageList.find((o) => o.id === targetId);
    if (targetImage) setSelectedImage(targetImage);
  };

  const handleOffset = (isNext: boolean) => {
    const newOffset = isNext ? offset + PAGE_LIMIT : offset - PAGE_LIMIT;
    if (newOffset < 0) return;
    if (newOffset >= maxOffset) {
      alert('마지막 페이지입니다.');
      return;
    }
    setOffset(newOffset);
    setSelectedImage(null);
  };

  const movePage = (page: number) => {
    const newOffset = page * PAGE_LIMIT;
    if (newOffset < 0) return;
    if (newOffset >= maxOffset) {
      alert('해당 페이지는 존재하지 않습니다.');
      return;
    }
    setOffset(newOffset);
    setSelectedImage(null);
  };

  const fetch = useCallback(async () => {
    if (activeChallengeType) {
      if (offset >= maxOffset) {
        alert('더 이상 이미지가 없습니다.');
        return;
      }
      LoadingIndicator.show();
      const res = await apis.common.getMlAchievementData({
        offset,
        limit: PAGE_LIMIT,
        challengeType: activeChallengeType,
      });
      const { dataList, isEnd } = res;
      if (isEnd) {
        if (dataList.length === 0) setMaxOffset(offset);
        else setMaxOffset(offset + PAGE_LIMIT);
      }
      setImageList(dataList);
      LoadingIndicator.hide();
      if (dataList.length === 0) alert('더 이상 이미지가 없습니다.');
    }
  }, [activeChallengeType, offset, maxOffset]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const update = useCallback(
    async (id: number, result: string) => {
      const res = await apis.common.updateMlAchievementData({ id, result });
      if (!res.success) {
        alert('오류가 발생했습니다.');
        return;
      }
      const _imageList = imageList.map((image) => {
        if (image.id === id) {
          const newImage = { ...image, result };
          setSelectedImage(newImage);
          return newImage;
        }
        return image;
      });
      setImageList(_imageList);
    },
    [imageList],
  );

  return (
    <FlexBox.Column style={{ padding: 40 }}>
      <h2>데이터 라벨링</h2>
      <Divider hidden />
      <FlexBox.Row gap={20}>
        <ChallengeSelector
          activeChallengeType={activeChallengeType}
          handleClick={handleChangeActiveChallengeType}
        />
        <ImageSelector
          imageList={imageList}
          selectedImage={selectedImage}
          offset={offset}
          handleClickImage={handleSelectedImage}
          handleClickButton={handleOffset}
          movePage={movePage}
        />
        <ImageView
          activeChallengeType={activeChallengeType}
          imageList={imageList}
          image={selectedImage}
          updateReviewResult={update}
          moveImage={moveImage}
        />
      </FlexBox.Row>
    </FlexBox.Column>
  );
};
