import { ChallengeNewInterviewDescriptionModule } from '@chlngers/core/lib/src/types/challenge';
import {
  DescriptionNewInterviewModuleFormType,
  NewInterviewInterviewee,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { generateId } from '@utils/number';

export const convertData2Form: (
  module: ChallengeNewInterviewDescriptionModule,
) => DescriptionNewInterviewModuleFormType = (module) => {
  const intervieweeList: NewInterviewInterviewee[] = [];
  module.interviewee.forEach((o) => {
    if (o.name && o.image && o.content) {
      intervieweeList.push({
        id: String(generateId()),
        image: o.image,
        name: o.name,
        content: o.content,
      });
    }
  });
  return {
    type: module.type,
    interviewee: intervieweeList,
  };
};

export const convertForm2Data: (
  module: DescriptionNewInterviewModuleFormType,
) => ChallengeNewInterviewDescriptionModule = (module) => {
  if (!module) throw Error("this shouldn't be happened");
  const newModule: DescriptionNewInterviewModuleFormType = {
    type: module.type,
    interviewee: [],
  };
  module.interviewee.forEach((o) => {
    newModule.interviewee.push({
      name: o.name,
      image: o.image,
      content: o.content,
    });
  });
  return newModule;
};
