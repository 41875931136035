import FlexBox from '@component/FlexBox/FlexBox';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import { ChallengeCustomField } from '@types';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './PostConnectPrizeForm.serializer';
import {
  useCurrentPlatform,
  usePostConnectPrize,
  useUpdatePostConnectInfo,
} from './PostConnectPrizeForm.store';
import { validate } from './PostConnectPrizeForm.validator';
import Accordion from '@component/Accordion/Accordion';
import TextInput from '@component/TextInput';
import { isNaN } from 'lodash';
import { useEffect, useState } from 'react';

export const PostConnectPrizeForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'postConnectPrize'>,
  Extract<ChallengeCustomField, { name: 'postConnectPrize' }>
> = () => {
  const postConnectPrize = usePostConnectPrize();
  const { getPlatform } = useCurrentPlatform();
  const { platform, platformName } = getPlatform();
  const [currentPlatformInfo, setCurrentPlatformInfo] = useState(getPlatform());
  const { currentAmount, updateAmount, updateChallengeForm } =
    useUpdatePostConnectInfo();

  const handleOptionChange = (option: boolean) => {
    const platformInfo = getPlatform();
    if (platformInfo.platform === '') {
      alert('구매 챌린지 태그를 먼저 설정해주세요.');
      return;
    }
    updateChallengeForm({
      postConnectPrize: option
        ? {
            ...platformInfo,
            amount: 500,
          }
        : null,
    });
  };

  const handleAmountChange = (amount: string) => {
    if (!isNaN(Number(amount))) {
      updateAmount(Number(amount));
    }
  };

  useEffect(() => {
    if (currentPlatformInfo.platform) {
      if (platform === '') {
        if (postConnectPrize) {
          alert(
            '구매 챌린지 태그를 변경하였습니다. 사후연동 상금 설정을 다시 해주세요.',
          );
        }
        updateChallengeForm({
          postConnectPrize: null,
        });
      } else {
        updateChallengeForm({
          postConnectPrize: {
            platform,
            platformName,
            amount: currentAmount ?? 0,
          },
        });
      }
    }
    setCurrentPlatformInfo(getPlatform());
  }, [platform]);

  return (
    <Accordion title="사후연동 상금 설정" open={false} as="h5">
      <FlexBox.Column gap={12}>
        <FlexBox.Row alignItems="center" gap={20}>
          <RadioSelector
            selectedOption={ACITVE_OPTIONS.find(
              (option) => option.value === !!postConnectPrize,
            )}
            options={ACITVE_OPTIONS}
            onChange={(o) => handleOptionChange(o.value)}
            style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
          />
          {postConnectPrize && (
            <TextInput
              label="사후연동 보상"
              value={postConnectPrize.amount}
              onChange={handleAmountChange}
              style={{ width: 300 }}
            />
          )}
        </FlexBox.Row>
      </FlexBox.Column>
    </Accordion>
  );
};

const ACITVE_OPTIONS = [
  { label: '활성화', value: true },
  { label: '비활성화', value: false },
];

PostConnectPrizeForm.validate = validate;
PostConnectPrizeForm.convertData2Form = convertData2Form;
PostConnectPrizeForm.convertForm2Data = convertForm2Data;
