import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { useAchievementCount } from '../../hooks/useAchievementCount';
import { useUpdateAchievementCount } from './GoalIdForm.store';

export const useAchievementCountSync = (goalId: number | null) => {
  const { updateAchievementCount } = useUpdateAchievementCount();
  const { startDate, endDate } = useChallengeFormStore(
    useShallow((s) => ({
      startDate: s.challenge.startDate,
      endDate: s.challenge.endDate,
    })),
  );

  const { achievementCount } = useAchievementCount({
    goalId,
    startDate,
    endDate,
  });

  useEffect(() => {
    updateAchievementCount(achievementCount);
  }, [achievementCount, updateAchievementCount]);
};
