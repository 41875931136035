import {
  AchievementOrderBy,
  ReportedAchievement,
  ReviewResult,
} from 'src/types/achievement.types';
import { ApiType } from '../constant/apiType';
import { _request } from './factory';

export type GetReportedAchievementsRequest = {
  reviewResult: ReviewResult;
  achievementFileIsDeleted: boolean;
  isOfficial?: boolean;
  isCollaborate?: boolean;
  challengeId?: number;
  achievementId?: number;
  reportUserId?: number;
  orderBy: AchievementOrderBy;
  offset: number;
  limit: number;
};

type GetReportedAchievementsResponse = {
  achievements: ReportedAchievement[];
};

const getReportedAchievements = async (
  params: GetReportedAchievementsRequest,
): Promise<GetReportedAchievementsResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/achievements/reported`,
    params,
  });
};

const bulkUpdateAchievementFileReviewResult = (data: {
  achievementFileIds: number[];
  reviewResult: ReviewResult;
  reviewComment: string;
  isPushNeeded: boolean;
}) => {
  return _request({
    apiType: ApiType.go,
    method: 'PUT',
    url: `/admin/achievement-files/reviews`,
    data,
  });
};

const updateAchievementFileAchievementId = (
  achievementFileId: number,
  achievementId: number,
) => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: `/admin/achievement-files/${achievementFileId}/achievement-id`,
    data: { achievementId },
  });
};

const deleteAchievementFileComment = (
  achievementFileId: number,
  commentId: number,
) => {
  return _request({
    apiType: ApiType.go,
    method: 'DELETE',
    url: `/admin/achievement-files/${achievementFileId}/comments`,
    params: { commentId },
  });
};

type GetAchievementFilesByAchievementIdResponse = {
  achievementFiles: ReportedAchievement[];
};

const getAchievementFilesByAchievementId = (
  achievementId: number,
): Promise<GetAchievementFilesByAchievementIdResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/achievements/${achievementId}/achievement-files`,
  });
};

export default {
  getReportedAchievements,
  bulkUpdateAchievementFileReviewResult,
  updateAchievementFileAchievementId,
  deleteAchievementFileComment,
  getAchievementFilesByAchievementId,
};
