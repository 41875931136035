import ChipInput from '@component/ChipInput';
import FlexBox from '@component/FlexBox/FlexBox';
import ImageInput from '@component/ImageInput';
import Label from '@component/Label';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import TextArea from '@component/TextArea';
import { AchievementMissionItem } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { FC } from 'react';
import { Icon, Image, Label as SURLabel } from 'semantic-ui-react';
import { useAchievementMissionActions } from '../../AchievementMissionForm.store';

type Props = {
  item: AchievementMissionItem;
  index: number;
};
const AchievementMissionListItem: FC<Props> = ({ item, index }) => {
  const { remove, up, down, update } = useAchievementMissionActions();

  return (
    <FlexBox.Column
      gap={20}
      style={{
        padding: 20,
        border: ' 1px solid rgba(34, 36, 38, 0.15)',
        borderRadius: 8,
      }}
    >
      <FlexBox.Row>
        <SURLabel color="black" onClick={() => remove(item.image)}>
          {index + 1}
          <Icon name="delete" />
        </SURLabel>
        <SURLabel name="up" onClick={() => up(item.image)}>
          <Icon name="angle up" style={{ margin: 0 }} />
        </SURLabel>
        <SURLabel name="down" onClick={() => down(item.image)}>
          <Icon name="angle down" style={{ margin: 0 }} />
        </SURLabel>
      </FlexBox.Row>
      <FlexBox.Row gap={20}>
        <FlexBox.Column>
          <FlexBox.Column gap={8}>
            <Image src={item.image} size="medium" />
            <ImageInput
              uploadOnChange
              onChange={(image) => update(item.image, { image })}
            />
          </FlexBox.Column>
        </FlexBox.Column>

        <FlexBox.Column gap={12}>
          <FlexBox.Column gap={8}>
            <Label>인증샷 종류</Label>
            <RadioSelector
              options={TYPE_OPTIONS}
              selectedOption={TYPE_OPTIONS.find((o) => o.value === item.type)}
              onChange={(type) => update(item.image, { type: type.value })}
              style={{ display: 'flex', flexDirection: 'column', gap: 4 }}
            />
          </FlexBox.Column>

          <TextArea
            label="설명 제목"
            value={item.title}
            onChange={(title) => update(item.image, { title })}
          />

          <TextArea
            label="설명 문구"
            value={item.text}
            onChange={(text) => update(item.image, { text })}
          />

          <ChipInput
            label="인증 회차 입력"
            chipList={item.achievementIndexList.map(String)}
            onChange={(achievementIndexList) =>
              update(item.image, {
                achievementIndexList: achievementIndexList
                  .map(Number)
                  .filter((v) => !Number.isNaN(v)),
              })
            }
          />
        </FlexBox.Column>
      </FlexBox.Row>
    </FlexBox.Column>
  );
};

export default AchievementMissionListItem;

const TYPE_OPTIONS: { label: string; value: AchievementMissionItem['type'] }[] =
  [
    { label: '좋은 예시', value: 'good' },
    { label: '나쁜 예시', value: 'bad' },
  ];
