import { apis } from '@api/index';
import { useGetGoalDetail } from '@queries/Challenge/useGetGoalDetail';
import { useEffect, useState } from 'react';

type Params = {
  goalId: number | null;
  startDate: string;
  endDate: string;
};
export const useAchievementCount = (params: Params) => {
  const { goalId, startDate, endDate } = params;
  const { data: goal } = useGetGoalDetail(goalId || 0);
  const [achievementCount, setAchievementCount] = useState(0);

  useEffect(() => {
    if (!goal) return;
    apis
      .checkAchievementCardList({ goalId: goal.id, startDate, endDate })
      .then((res) => setAchievementCount(res.achievements.length));
  }, [goal, startDate, endDate]);

  return { achievementCount };
};
