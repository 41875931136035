import React, { useEffect, useState } from 'react';
import { apis } from '../../../../api';
import { Table } from 'semantic-ui-react';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';
import moment from 'moment';

const PillOrder = ({ userId }) => {
  const [pillOrders, setPillOrders] = useState([]);
  const [firstPurchase, setFirstPurchase] = useState({});

  useEffect(() => {
    getPillOrders();
  }, []);

  const getPillOrders = async () => {
    const res = await apis.user.getPillOrders(userId);
    const { pillOrders, firstPurchase } = res;
    setPillOrders(pillOrders);
    setFirstPurchase(firstPurchase);
  };

  return (
    <>
      <h5>주문 목록</h5>
      <Table basic="very" size="small" sortable structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>id</Table.HeaderCell>
            <Table.HeaderCell>주문일시</Table.HeaderCell>
            <Table.HeaderCell>주문번호</Table.HeaderCell>
            <Table.HeaderCell>주문상품</Table.HeaderCell>
            <Table.HeaderCell>총 주문금액</Table.HeaderCell>
            <Table.HeaderCell>총 실제결제금액</Table.HeaderCell>
            <Table.HeaderCell>결제수단</Table.HeaderCell>
            <Table.HeaderCell>미배송</Table.HeaderCell>
            <Table.HeaderCell>배송중</Table.HeaderCell>
            <Table.HeaderCell>배송완료</Table.HeaderCell>
            <Table.HeaderCell>취소</Table.HeaderCell>
            <Table.HeaderCell>교환</Table.HeaderCell>
            <Table.HeaderCell>반품</Table.HeaderCell>
            <Table.HeaderCell>환불</Table.HeaderCell>
            <Table.HeaderCell>비고</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {pillOrders.map((order) => (
            <Table.Row key={order.id}>
              <Table.Cell collapsing>{order.id}</Table.Cell>
              <Table.Cell collapsing>
                {moment(order.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </Table.Cell>
              <Table.Cell collapsing>{order.orderNo}</Table.Cell>
              <Table.Cell collapsing>{order.productName}</Table.Cell>
              <Table.Cell collapsing>
                {setCurrencyFormat(
                  order.paymentInfo.paymentAmount +
                    order.paymentInfo.storePointAmount,
                )}
              </Table.Cell>
              <Table.Cell collapsing>
                {setCurrencyFormat(order.paymentInfo.paymentAmount)}
              </Table.Cell>
              <Table.Cell collapsing>{order.pgcode}</Table.Cell>
              <Table.Cell collapsing>
                {order.orderStatusCount.preparingCount}
              </Table.Cell>
              <Table.Cell collapsing>
                {order.orderStatusCount.inDeliveryCount}
              </Table.Cell>
              <Table.Cell collapsing>
                {order.orderStatusCount.deliveredCount}
              </Table.Cell>
              <Table.Cell collapsing>
                {order.orderStatusCount.canceledCount}
              </Table.Cell>
              <Table.Cell collapsing>
                {order.orderStatusCount.exchangedCount}
              </Table.Cell>
              <Table.Cell collapsing>
                {order.orderStatusCount.returnedCount}
              </Table.Cell>
              <Table.Cell collapsing>
                {order.orderStatusCount.refundedCount}
              </Table.Cell>
              <Table.Cell collapsing>
                {order.id === firstPurchase.pillOrderId ? '오퍼월 주문' : '-'}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default PillOrder;
