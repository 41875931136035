import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Checkbox,
  Divider,
  Grid,
  Header,
  Image,
} from 'semantic-ui-react';
import { apis, updateOfferwallPriority } from '../../../api';
import { offerWallTypeLabel } from '../../../constant/benefitType';
import BenefitMenu from '../BenefitMenu';

const subMenuList = [
  { text: '현재 노출중', value: 'PRESENT_DISPLAYED' },
  { text: '노출 전체', value: 'OLD_DISPLAYED' },
  { text: '전체', value: 'ALL' },
];

const OfferWall = () => {
  const history = useHistory();

  const [offerWallList, setOfferWallList] = useState([]);
  const [filteredOfferWallList, setFilteredOfferWallList] = useState([]);
  const [activeSubMenu, setActiveSubMenu] = useState(subMenuList[0].value);

  const filterOfferWalls = (offerWalls) => {
    const _filteredOfferWalls = [];
    offerWalls.forEach((offerWall) => {
      if (
        activeSubMenu === subMenuList[0].value &&
        offerWall.isDisplayed &&
        moment().isBetween(
          moment(offerWall.startDateTime),
          moment(offerWall.endDateTime),
        )
      ) {
        _filteredOfferWalls.push(offerWall);
      } else if (
        activeSubMenu === subMenuList[1].value &&
        offerWall.isDisplayed
      ) {
        _filteredOfferWalls.push(offerWall);
      } else if (activeSubMenu === subMenuList[2].value) {
        _filteredOfferWalls.push(offerWall);
      }
    });

    setFilteredOfferWallList(_filteredOfferWalls);
  };

  const getOfferWallList = async () => {
    const res = await apis.getOfferWalls('result_date');
    const { offerWallInfoList } = res;
    setOfferWallList(offerWallInfoList);
    filterOfferWalls(offerWallInfoList);
  };

  const onClickSubMenu = useCallback((e, { value }) => {
    setActiveSubMenu(value);
  }, []);

  const onChangeDisplay = async (e, { value }) => {
    let isDisplayed;
    const _offerWalls = offerWallList.map((ow) => {
      if (ow.id === value) {
        isDisplayed = !ow.isDisplayed;
        ow.isDisplayed = isDisplayed;
      }
      return ow;
    });
    setOfferWallList(_offerWalls);
    await apis.updateOfferWall(value, { isDisplayed });
  };

  // 삭제
  const deleteOfferWall = async (offerWallId) => {
    if (confirm('정말 오퍼월을 삭제하시겠습니까?')) {
      const res = await apis.deleteOfferWall(offerWallId);

      if (res?.response?.data?.err) {
        return;
      }
      setOfferWallList((prev) => prev.filter((ow) => ow.id !== offerWallId));
      setFilteredOfferWallList((prev) =>
        prev.filter((ow) => ow.id !== offerWallId),
      );
      alert('오퍼월 삭제가 완료되었어요.');
    }
  };

  const copyOfferWall = async (offerWall) => {
    if (
      confirm(
        `정말 ${offerWall.brand}-${offerWall.title} 오퍼월을 복사하시겠습니까?`,
      )
    ) {
      const res = await apis.copyOfferWall(offerWall.id);
      const copyedOfferWall = res?.offerWall;
      setOfferWallList((prev) => [copyedOfferWall, ...prev]);
      setFilteredOfferWallList((prev) => [copyedOfferWall, ...prev]);
    }
  };

  const getMainImageUrl = (offerWall) => {
    if (offerWall.moduleList?.length === 0) return '';

    return (
      (offerWall.moduleList || []).find((m) => m.type === 'MainImageListModule')
        ?.imageUrlList?.[0] || ''
    );
  };

  const getUserSexLabel = (userSexTypes) => {
    if (!userSexTypes) return '전체';
    if (userSexTypes.length > 1) return '전체';
    if (userSexTypes[0] === 'F') {
      return '여성';
    }
    if (userSexTypes[0] === 'M') {
      return '남성';
    }
  };

  useEffect(() => {
    filterOfferWalls(offerWallList);
  }, [activeSubMenu]);

  useEffect(() => {
    getOfferWallList();
  }, []);

  const onClickAdd = () => {
    history.push('/offerwall/create');
  };

  const handleChangePriority = async (id, type = 'up') => {
    // id 현재 고른 offerwallList의 id
    const index = filteredOfferWallList.findIndex((r) => r.id === id);
    if (index < 0) return;

    const currentPriority = filteredOfferWallList[index].priority;
    const currentId = filteredOfferWallList[index].id;

    const targetIndex = type === 'up' ? index - 1 : index + 1;
    if (targetIndex < 0 || targetIndex >= filteredOfferWallList.length) return;
    const targetPriority = filteredOfferWallList[targetIndex].priority;
    const targetId = filteredOfferWallList[targetIndex].id;

    // filteredOfferWallList 순서 변경
    console.log('index', index);
    console.log('targetIndex', targetIndex);

    // filteredOfferWallList 순서 변경
    const newItemList = [...filteredOfferWallList];
    const temp = newItemList[index];
    newItemList[index] = newItemList[targetIndex];
    newItemList[targetIndex] = temp;
    setFilteredOfferWallList(newItemList);

    // 실제 offerwallList 순서 변경
    console.log('currentPriority', currentPriority);
    console.log('targetPriority', targetPriority);

    const priority = {
      [currentId]: targetPriority,
      [targetId]: currentPriority,
    };

    await updateOfferwallPriority({ priority });
  };

  return (
    <>
      <div style={{ margin: 40 }}>
        <Grid columns="equal">
          <Grid.Column width={2}>
            <BenefitMenu />
          </Grid.Column>
          <Grid.Column>
            <Header as="h3">
              오퍼월 관리
              <Button
                style={{ marginLeft: 10 }}
                size="tiny"
                value={null}
                onClick={onClickAdd}
              >
                추가
              </Button>
            </Header>
            <Breadcrumb style={{ marginTop: 40 }}>
              {subMenuList.map((subMenu, idx) => (
                <React.Fragment key={idx}>
                  {idx > 0 && <Breadcrumb.Divider> / </Breadcrumb.Divider>}
                  <Breadcrumb.Section
                    style={{ margin: '0 10px', color: '#000000' }}
                    value={subMenu.value}
                    active={activeSubMenu === subMenu.value}
                    onClick={onClickSubMenu}
                    content={subMenu.text}
                  />
                </React.Fragment>
              ))}
            </Breadcrumb>

            <Divider hidden />

            <Grid style={{ fontWeight: 700 }}>
              <Grid.Row>
                <Grid.Column width={1}>id</Grid.Column>
                <Grid.Column width={1}>오퍼월 타입</Grid.Column>
                <Grid.Column width={1}>리스트 이미지</Grid.Column>
                <Grid.Column width={2}>브릿지 이미지</Grid.Column>
                <Grid.Column width={1}>브랜드명</Grid.Column>
                <Grid.Column width={1}>지급 포인트</Grid.Column>
                <Grid.Column width={1}>지급 상금</Grid.Column>
                <Grid.Column width={1}>시작일</Grid.Column>
                <Grid.Column width={1}>종료일</Grid.Column>
                <Grid.Column width={1}>포인트 지급일</Grid.Column>
                <Grid.Column width={1}>공개</Grid.Column>
                <Grid.Column width={1}>타겟유저</Grid.Column>
                <Grid.Column width={1}>노출</Grid.Column>
                <Grid.Column width={1}>수정/삭제</Grid.Column>
                <Grid.Column width={1}>우선순위</Grid.Column>
              </Grid.Row>
            </Grid>
            {filteredOfferWallList.length > 0 ? (
              filteredOfferWallList.map((offerWall) => (
                <Grid key={offerWall.id}>
                  <Grid.Column width={1}>{offerWall.id}</Grid.Column>
                  <Grid.Column width={1}>
                    {offerWallTypeLabel[offerWall.type]}
                  </Grid.Column>
                  <Grid.Column width={1}>
                    {offerWall.thumbnailImageUrl ? (
                      <Image src={offerWall.thumbnailImageUrl} size="tiny" />
                    ) : (
                      '없음'
                    )}
                  </Grid.Column>
                  <Grid.Column width={2}>
                    {getMainImageUrl(offerWall) ? (
                      <Image src={getMainImageUrl(offerWall)} size="tiny" />
                    ) : (
                      '없음'
                    )}
                  </Grid.Column>

                  <Grid.Column width={1}>{offerWall.brand}</Grid.Column>
                  <Grid.Column width={1}>
                    {offerWall.reward.storePointAmount.toLocaleString() + ' P'}
                  </Grid.Column>
                  <Grid.Column width={1}>
                    {offerWall.reward.prizePointAmount.toLocaleString() + ' 원'}
                  </Grid.Column>
                  <Grid.Column width={1}>
                    {offerWall.startDateTime
                      ? moment(offerWall.startDateTime).format(
                          'YYYY-MM-DDTHH:mm',
                        )
                      : '-'}
                  </Grid.Column>
                  <Grid.Column width={1}>
                    {offerWall.endDateTime
                      ? moment(offerWall.endDateTime).format('YYYY-MM-DDTHH:mm')
                      : '-'}
                  </Grid.Column>
                  <Grid.Column width={1}>
                    {offerWall.resultDate
                      ? moment(offerWall.resultDate).format('YYYY-MM-DDTHH:mm')
                      : '-'}
                  </Grid.Column>
                  <Grid.Column width={1}>
                    {offerWall.filterInfo.userType}
                  </Grid.Column>
                  <Grid.Column width={1}>
                    {offerWall.filterInfo.minAge} ~{' '}
                    {offerWall.filterInfo.maxAge} <br />
                    {getUserSexLabel(offerWall.filterInfo.userSexTypes)}
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <Checkbox
                      name="isDisplayed"
                      checked={offerWall.isDisplayed}
                      value={offerWall.id}
                      onChange={onChangeDisplay}
                    />
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <Button
                      size="mini"
                      name="EDIT"
                      content="수정"
                      onClick={() =>
                        history.push(`/offerwall/${offerWall.id}/edit`)
                      }
                    />
                    <Button
                      size="mini"
                      content="삭제"
                      onClick={() => deleteOfferWall(offerWall.id)}
                    />
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <Button
                      size="mini"
                      onClick={() => handleChangePriority(offerWall.id, 'up')}
                    >
                      🔼
                    </Button>
                    <Button
                      size="mini"
                      onClick={() => handleChangePriority(offerWall.id, 'down')}
                    >
                      🔽
                    </Button>
                  </Grid.Column>
                </Grid>
              ))
            ) : (
              <Grid>
                <Grid.Column width={16}>오퍼월이 없습니다</Grid.Column>
              </Grid>
            )}
          </Grid.Column>
        </Grid>
      </div>
    </>
  );
};

export default OfferWall;
