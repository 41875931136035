import React, { useEffect, useState } from 'react';
import { Accordion, Header, Icon, Table } from 'semantic-ui-react';
import moment from 'moment';
import { apis } from '@api/index';
import { UserBanInfo } from 'src/types/user.types';

const Ban = () => {
  const [redCardUsers, setRedCardUsers] = useState<UserBanInfo[]>([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const getRedCardUsers = async () => {
    const { users } = await apis.user.getRedCardAppliedUsers();
    setRedCardUsers(users);
  };

  useEffect(() => {
    getRedCardUsers();
  }, []);

  return (
    <Accordion>
      <Accordion.Title
        active={isAccordionOpen}
        index={1}
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
      >
        <Header as="h3">
          <Icon name="dropdown" />
          챌린지 신청 금지유저
        </Header>
      </Accordion.Title>
      <Accordion.Content active={isAccordionOpen}>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>이름</Table.HeaderCell>
              <Table.HeaderCell>닉네임</Table.HeaderCell>
              <Table.HeaderCell>종료시점</Table.HeaderCell>
              <Table.HeaderCell>레드 받은 챌린지</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {redCardUsers.map((user) => {
              const challengeIds: number[] = [];
              return (
                <Table.Row key={user.id}>
                  <Table.Cell>{user.id}</Table.Cell>
                  <Table.Cell>{user.name}</Table.Cell>
                  <Table.Cell>{user.nickname}</Table.Cell>
                  <Table.Cell>
                    {moment
                      .utc(user.banEndDatetime)
                      .local()
                      .format('YYYY-MM-DD HH:mm:ss')}
                    <br />
                  </Table.Cell>
                  <Table.Cell>
                    {user.challenges.map((challenge) => {
                      if (!challengeIds.includes(challenge.id)) {
                        challengeIds.push(challenge.id);
                        return `[${challenge.id}] ${challenge.title}, `;
                      }
                      return null;
                    })}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Accordion.Content>
    </Accordion>
  );
};

export default Ban;
