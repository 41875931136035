import { Button, Dropdown, Input } from 'semantic-ui-react';

export type WithdrawSearchType =
  | 'userId'
  | 'accountName'
  | 'bankName'
  | 'accountNo';

const SearchWithdraw = ({
  searchText,
  searchUser,
  setSearchKeyword,
  handleChangeSearchType,
}: {
  searchText: string;
  searchUser: () => void;
  setSearchKeyword: (keyword: string) => void;
  handleChangeSearchType: (
    type: 'userId' | 'accountName' | 'bankName' | 'accountNo',
  ) => void;
}) => {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchUser();
      e.currentTarget.blur();
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        marginTop: 12,
        marginBottom: 20,
      }}
    >
      <Dropdown
        placeholder="검색 조건"
        selection
        defaultValue="accountName"
        options={[
          { key: 'accountName', value: 'accountName', text: '예금주' },
          { key: 'userId', value: 'userId', text: '유저id' },
          { key: 'bankName', value: 'bankName', text: '은행' },
          { key: 'accountNo', value: 'accountNo', text: '계좌번호' },
        ]}
        onChange={(e, data) =>
          handleChangeSearchType(data.value as 'userId' | 'accountName')
        }
      />
      <Input
        value={searchText}
        placeholder="검색어 입력"
        onChange={(e) => setSearchKeyword(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <Button size="medium" onClick={searchUser}>
        검색
      </Button>
    </div>
  );
};

export default SearchWithdraw;
