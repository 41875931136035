import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useThirdTermUrl = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      thirdTermUrl: s.challenge.thirdTermUrl,
      companyId: s.challenge.companyId,
    })),
  );
};

export const useThirdTermUrlActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setThirdTermUrl = (thirdTermUrl: string) =>
    updateChallengeForm({ thirdTermUrl });

  return { setThirdTermUrl };
};
