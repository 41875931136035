export const gymReviewCategoryList = [
  { text: '성공 (헬스 기구가 3개 이상)', value: 'PASS' },
  { text: '야외 헬스장 찍은 경우', value: 'OUTDOOR' },
  {
    text: '실내지만 기구가 3개 미만 나왔거나 홈트인 경우',
    value: 'LACK_OF_EQUIPMENT',
  },
  { text: '모니터에 헬스장 사진 띄워놓고 찍은 경우', value: 'MONITOR' },
  { text: '헬스장 불이 전부 꺼져있는 경우', value: 'DARK_GYM' },
  { text: '헬스장과 완전 상관 없는 사진을 찍은 경우', value: 'IRRELAVANT' },
  { text: '50% 이상 검정색 사진', value: 'BLACK' },
  { text: '50% 이상 흰색 사진', value: 'WHITE' },
  { text: '분류가 애매한 경우', value: 'AMBIGUOUS' },
];

export const skyReviewCategoryList = [
  { text: '성공 (하늘이 50% 이상 보이도록)', value: 'PASS' },
  {
    text: '빛반사로 인해 하늘이 잘 보이지 않는 경우',
    value: 'REFLECTION',
  },
  { text: '하늘 비율이 50% 미만', value: 'LACK_OF_SKY' },
  { text: '모니터에 하늘 사진 띄워놓고 찍은 경우', value: 'MONITOR' },
  { text: '사진이 단색으로 뿌옇게 찍은 경우', value: 'BLUR' },
  { text: '하늘과 완전 상관 없는 사진을 찍은 경우', value: 'IRRELAVANT' },
  { text: '50% 이상 검정색 사진', value: 'BLACK' },
  { text: '50% 이상 흰색 사진', value: 'WHITE' },
  { text: '분류가 애매한 경우', value: 'AMBIGUOUS' },
];

export const PAGE_LIMIT = 100;
