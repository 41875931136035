import React from 'react';
import { Button, Image, Label } from 'semantic-ui-react';
import moment from 'moment';
import { itemUrl, wireframeUrl } from '../../../../constant/imgUrls';

type Achievement = any;
type AchievementBeforeCardProps = {
  achievement: Achievement;
  achievementIndex: number;
  snsType?: 'instagram' | 'naverBlog';
  isItemUsed?: boolean;
  setDropItem?: (achievement: Achievement) => void;
  setDragItem?: (achievement: Achievement) => void;
  onUploadPress: (
    event: React.MouseEvent<HTMLButtonElement>,
    data: any,
    additionalInfo?: { achievementIndex: number; snsType?: string },
  ) => void;
  onAllAchievementPress: (
    event: React.MouseEvent<HTMLButtonElement>,
    data: any,
  ) => void;
  onHeartPress: (event: React.MouseEvent<HTMLButtonElement>, data: any) => void;
  onHeartCancelPress: (
    event: React.MouseEvent<HTMLButtonElement>,
    data: any,
  ) => void;
};

const AchievementBeforeCard = (props: AchievementBeforeCardProps) => {
  const {
    achievement,
    achievementIndex,
    snsType,
    isItemUsed,
    setDropItem,
    setDragItem,
    onUploadPress,
    onAllAchievementPress,
    onHeartPress,
    onHeartCancelPress,
  } = props;
  return (
    <div>
      <div
        onDragOver={(e) => e.preventDefault()}
        onDrop={() =>
          setDropItem &&
          setDropItem({
            id: achievement.id,
            dueStartDate: achievement.dueStartDate,
            dueStartTime: achievement.dueStartTime,
            achievementFile: {
              id: 0,
              url: isItemUsed ? itemUrl : wireframeUrl,
            },
            challenge: { title: achievement.challenge.title },
          })
        }
      >
        <div
          draggable="true"
          onDragStart={() =>
            setDragItem &&
            setDragItem({
              id: achievement.id,
              dueStartDate: achievement.dueStartDate,
              dueStartTime: achievement.dueStartTime,
              achievementFile: {
                id: 0,
                url: isItemUsed ? itemUrl : wireframeUrl,
              },
              challenge: { title: achievement.challenge.title },
            })
          }
        >
          <Image fluid src={isItemUsed ? itemUrl : wireframeUrl} />
        </div>
      </div>
      <div style={{ position: 'absolute', top: 20, left: 20, padding: 5 }}>
        {isItemUsed ? (
          <Label color="teal" size="tiny">
            인증패스{' '}
            {moment(achievement.transactionItem.createdAt).format(
              'YYYY-MM-DD HH:mm:ss',
            )}
          </Label>
        ) : (
          <Label color="black" size="tiny">
            No image
          </Label>
        )}
      </div>
      <div>{achievement.id}</div>
      <div>
        ({moment(achievement.dueStartDate).format('YY.MM.DD')}{' '}
        {achievement.dueStartTime}-
        {moment(achievement.dueEndDate).format('YY.MM.DD')}{' '}
        {achievement.dueEndTime})
      </div>
      <Button
        basic
        size="mini"
        floated="left"
        value={achievement}
        onClick={(e, data) => {
          onUploadPress(e, data, { achievementIndex, snsType });
        }}
      >
        인증샷등록
      </Button>
      <Button
        basic
        size="mini"
        floated="left"
        value={achievement.id}
        onClick={onAllAchievementPress}
      >
        모든인증샷보기
      </Button>
      {achievement.transactionItem && (
        <Button
          basic
          size="mini"
          floated="left"
          value={achievement}
          onClick={onHeartCancelPress}
        >
          인증패스취소
        </Button>
      )}
      {!achievement.transactionItem && (
        <Button
          basic
          size="mini"
          floated="left"
          value={achievement}
          onClick={onHeartPress}
        >
          인증패스
        </Button>
      )}
    </div>
  );
};

export default AchievementBeforeCard;
