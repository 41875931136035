import Accordion from '@component/Accordion/Accordion';
import ErrorMsg from '@component/ErrorMsg';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import TextInput from '@component/TextInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormComponent,
  ChallengeFormType,
  CommerceFormType,
} from '../../NewChallengeForm.types';
import { convenrtForm2Data, convertData2Form } from './CommerceForm.serializer';
import {
  useCommerce,
  useCommerceActions,
  useSupporters,
} from './CommerceForm.store';
import { validate } from './CommerceForm.validator';
import ProductList from './components/ProductList/ProductList';

export const CommerceForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'commerce'>,
  Pick<ChallengeFormType, 'commerce'>,
  Pick<
    ReqCreateOrUpdateChallenge,
    'productHeader' | 'isMandatory' | 'selectType' | 'rccps'
  >
> = () => {
  const commerce = useCommerce();
  const supporters = useSupporters();
  const { updateProductHeader, updateSelectType, updateIsMandatory } =
    useCommerceActions();

  const validated = validate({ commerce, supporters });

  const selectedSelectType = SELECT_TYPE_OPTIONS.find(
    (o) => o.value === commerce.selectType,
  );

  const selectedMandatoryOption = MANDATORY_OPTIONS.find(
    (o) => o.value === commerce.isMandatory,
  );

  return (
    <Accordion title="커머스 관련 내용" as="h5" open={false}>
      <ErrorMsg text={validated.isValid ? '' : validated.message} />
      <FlexBox.Column gap={12}>
        <TextInput
          label="상품 옵션명"
          value={commerce.productHeader || undefined}
          onChange={updateProductHeader}
        />

        <FlexBox.Row alignItems="center" gap={8}>
          <Label>상품 선택 방식</Label>
          <RadioSelector
            selectedOption={selectedSelectType}
            options={SELECT_TYPE_OPTIONS}
            onChange={(o) => updateSelectType(o.value)}
            style={{ display: 'flex', gap: 12 }}
          />
        </FlexBox.Row>

        <FlexBox.Row alignItems="center" gap={8}>
          <Label>상품 필수 선택 여부</Label>
          <RadioSelector
            selectedOption={selectedMandatoryOption}
            options={MANDATORY_OPTIONS}
            onChange={(o) => updateIsMandatory(o.value)}
            style={{ display: 'flex', gap: 12 }}
          />
        </FlexBox.Row>

        <ProductList />
      </FlexBox.Column>
    </Accordion>
  );
};

CommerceForm.validate = validate;
CommerceForm.convertData2Form = convertData2Form;
CommerceForm.convertForm2Data = convenrtForm2Data;

const SELECT_TYPE_OPTIONS: {
  label: string;
  value: CommerceFormType['commerce']['selectType'];
}[] = [
  { label: '한개만 선택 가능(라디오)', value: 'RADIO' },
  { label: '여러개 선택 가능(체크박스)', value: 'CHECKBOX' },
];

const MANDATORY_OPTIONS: {
  label: string;
  value: CommerceFormType['commerce']['isMandatory'];
}[] = [
  { label: '필수', value: true },
  { label: '필수아님', value: false },
];
