import FlexBox from '@component/FlexBox/FlexBox';
import ImageInput from '@component/ImageInput';
import TextArea from '@component/TextArea';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { Icon, Image, Label } from 'semantic-ui-react';
import { swap } from '@utils/js.utils';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './AuthenticationBadExamplesForm.serializer';
import {
  useAuthenticationBadExamples,
  useAuthenticationBadExamplesActions,
} from './AuthenticationBadExamplesForm.store';
import * as S from './AuthenticationBadExamplesForm.style';
import { validate } from './AuthenticationBadExamplesForm.validator';

const AuthenticationBadExamplesForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'authenticationBadExamples'>,
  Pick<ChallengeFormType, 'authenticationBadExamples'>,
  Pick<ReqCreateOrUpdateChallenge, 'authenticationBadExampleUrls'>
> = () => {
  const { authenticationBadExampleUrls } = useAuthenticationBadExamples();
  const { setAuthenticationBadExamples } =
    useAuthenticationBadExamplesActions();

  const handleImageUpload = async (files: string[] | undefined) => {
    if (!files) return;
    setAuthenticationBadExamples([
      ...authenticationBadExampleUrls,
      ...files.map((f) => ({ image: f, text: '' })),
    ]);
  };

  const handleImageTextChange = (text: string, index: number) => {
    const _authenticationBadExampleUrls = [...authenticationBadExampleUrls];
    _authenticationBadExampleUrls[index].text = text;
    setAuthenticationBadExamples(_authenticationBadExampleUrls);
  };

  const spliceImage = (index: number) => {
    const _authenticationBadExampleUrls = [...authenticationBadExampleUrls];
    _authenticationBadExampleUrls.splice(index, 1);
    setAuthenticationBadExamples(_authenticationBadExampleUrls);
  };

  const handleImageChange = (image: string | undefined, index: number) => {
    if (!image) return;
    setAuthenticationBadExamples(
      authenticationBadExampleUrls.map((item, idx) =>
        idx === index ? { ...item, image } : item,
      ),
    );
  };

  const changeImageOrder = (name: 'up' | 'down', index: number) => {
    const _authenticationBadExampleUrls = [...authenticationBadExampleUrls];

    let targetIndex;

    if (name === 'up') {
      if (index - 1 < 0) return;
      targetIndex = index - 1;
    } else {
      if (index + 1 > _authenticationBadExampleUrls.length - 1) return;
      targetIndex = index + 1;
    }
    const swapedArray = swap(_authenticationBadExampleUrls, index, targetIndex);

    setAuthenticationBadExamples(swapedArray);
  };

  return (
    <>
      <ImageInput
        label="인증샷 안좋은 예시 (최대 3:4비율)"
        multiSelect
        onChange={handleImageUpload}
        uploadOnChange
      />
      {authenticationBadExampleUrls.map((url, idx) => (
        <S.AuthenticationExampleContainer key={idx}>
          <Label color="black" onClick={() => spliceImage(idx)}>
            {idx + 1}
            <Icon name="delete" />
          </Label>
          <Label name="up" onClick={() => changeImageOrder('up', idx)}>
            <Icon name="angle up" style={{ margin: 0 }} />
          </Label>
          <Label name="down" onClick={() => changeImageOrder('down', idx)}>
            <Icon name="angle down" style={{ margin: 0 }} />
          </Label>
          <FlexBox.Row alignItems="flex-start" gap={20}>
            <div style={{ flex: 1 }}>
              <h5>이미지</h5>
              <FlexBox.Column gap={8}>
                <ImageInput
                  uploadOnChange
                  onChange={(image) => handleImageChange(image, idx)}
                />
                <Image src={url.image} size="medium" />
              </FlexBox.Column>
            </div>
            <div style={{ flex: 1 }}>
              <h5>설명문구</h5>
              <TextArea
                rows={3}
                value={url.text}
                onChange={(text) => handleImageTextChange(text, idx)}
              />
            </div>
          </FlexBox.Row>
        </S.AuthenticationExampleContainer>
      ))}
    </>
  );
};

export default AuthenticationBadExamplesForm;

AuthenticationBadExamplesForm.validate = validate;
AuthenticationBadExamplesForm.convertData2Form = convertData2Form;
AuthenticationBadExamplesForm.convertForm2Data = convertForm2Data;
