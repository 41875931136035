import { Radio } from 'semantic-ui-react';

type Option<T> = T & {
  label: string;
  disabled?: boolean;
};
type Props<T> = {
  selectedOption?: Option<T>;
  options: Option<T>[];
  onChange?: (option: Option<T>) => void;
  style?: React.CSSProperties;
};

const RadioSelector = <T,>({
  selectedOption,
  options,
  onChange,
  style,
}: Props<T>) => {
  return (
    <div style={{ ...style }}>
      {options.map((option, idx) => {
        const { label } = option;
        return (
          <Radio
            key={`${label}-${idx}`}
            type="radio"
            label={label}
            disabled={option.disabled}
            checked={selectedOption?.label === option.label}
            onChange={() => onChange && onChange(option)}
          />
        );
      })}
    </div>
  );
};

export default RadioSelector;
