import { _request } from './factory';
import { ApiType } from '../constant/apiType';
import {
  Question,
  QuestionAnswer,
  QuestionCategory,
  QuestionCategoryInternal,
  QuestionServiceType,
  QuestionTemplate,
} from '../types';

type GetQuestionsParams = {
  deletedIncluded: boolean;
  gteCreatedAt: string; // 'YYYY-MM-DD HH:mm:ss'
  ltCreatedAt: string; // 'YYYY-MM-DD HH:mm:ss'
  userId?: number;
};

type GetQuestionsResponse = {
  questions: Question[];
};

const getQuestions = async (
  params: GetQuestionsParams,
): Promise<GetQuestionsResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: '/admin/questions',
    params,
  });
};

type GetQuestionsForDownloadParams = {
  gteCreatedAt: string; // 'YYYY-MM-DD HH:mm:ss'
  ltCreatedAt: string; // 'YYYY-MM-DD HH:mm:ss'
};

type GetQuestionsForDownloadResponse = {
  questions: Question[];
};

const getQuestionsForDownload = async (
  params: GetQuestionsForDownloadParams,
): Promise<GetQuestionsForDownloadResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: '/admin/questions/download',
    params,
  });
};

type GetQuestionCategoriesResponse = {
  questionCategories: (QuestionCategory & QuestionServiceType)[];
};

const getQuestionCategories =
  async (): Promise<GetQuestionCategoriesResponse> => {
    return _request({
      apiType: ApiType.python,
      method: 'GET',
      url: '/question-categories',
    });
  };

type GetQuestionCategoryInternalsResponse = {
  questionCategoriesInternal: QuestionCategoryInternal[];
};

const getQuestionCategoryInternals =
  async (): Promise<GetQuestionCategoryInternalsResponse> => {
    return _request({
      apiType: ApiType.python,
      method: 'GET',
      url: '/question-categories/internal',
    });
  };

type GetQuestionTagsResponse = {
  tags: string[];
};

const getQuestionTags = async (): Promise<GetQuestionTagsResponse> => {
  return _request({
    apiType: ApiType.python,
    method: 'GET',
    url: '/questions/tags',
  });
};

type AddQuestionCategoryInternalParams = {
  text: string;
};

type AddQuestionCategoryInternalResponse = {
  questionCategoryInternal: QuestionCategoryInternal;
};

const addQuestionCategoryInternal = async (
  params: AddQuestionCategoryInternalParams,
): Promise<AddQuestionCategoryInternalResponse> => {
  const { text } = params;
  return _request({
    apiType: ApiType.python,
    method: 'PUT',
    url: '/question-categories/internal',
    data: { text },
  });
};

type EditQuestionCategoryInternalParams = {
  id: number;
  text: string;
};

type EditQuestionCategoryInternalResponse = {
  questionCategoryInternal: QuestionCategoryInternal;
};

const editQuestionCategoryInternal = async (
  params: EditQuestionCategoryInternalParams,
): Promise<EditQuestionCategoryInternalResponse> => {
  const { id, text } = params;
  return _request({
    apiType: ApiType.python,
    method: 'POST',
    url: `/question-categories-internal/${id}`,
    data: { text },
  });
};

type DeleteQuestionCategoryInternalParams = {
  id: number;
};

const deleteQuestionCategoryInternal = async (
  params: DeleteQuestionCategoryInternalParams,
): Promise<void> => {
  const { id } = params;
  return _request({
    apiType: ApiType.python,
    method: 'DELETE',
    url: `/question-categories-internal/${id}`,
  });
};

type AnswerQuestionAnswerParams = {
  id: number;
  answer: QuestionAnswer;
};

const answerQuestion = async (params: AnswerQuestionAnswerParams) => {
  const { id, answer } = params;
  return _request({
    apiType: ApiType.go,
    method: 'PUT',
    url: `/admin/questions/${id}/answer`,
    data: answer,
  });
};

type AnswerQuestionAnswerListParams = {
  values: { questionId: number; answer: QuestionAnswer }[];
};

const answerQuestionList = (params: AnswerQuestionAnswerListParams) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: 'admin/questions/answer',
    data: params,
  });
};

type DeleteQuestionParams = {
  id: number;
};

type DeleteQuestionResponse = {
  question: Question;
};

const deleteQuestion = async (
  params: DeleteQuestionParams,
): Promise<DeleteQuestionResponse> => {
  return _request({
    apiType: ApiType.python,
    method: 'DELETE',
    url: `/questions/${params.id}`,
  });
};

type GetQuestionTemplateListParams = {
  keyword?: string;
  desc?: boolean;
};

type GetQuestionTemplateListResponse = {
  questionTemplates: QuestionTemplate[];
};

const getQuestionTemplateList = (
  params: GetQuestionTemplateListParams,
): Promise<GetQuestionTemplateListResponse> => {
  const { keyword, desc = false } = params;
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: '/question-templates',
    params: { keyword, desc },
  });
};

type GetQuestionTemplateParams = {
  id: number;
};

type GetQuestionTemplateResponse = {
  questionTemplate: QuestionTemplate;
};

const getQuestionTemplate = (
  params: GetQuestionTemplateParams,
): Promise<GetQuestionTemplateResponse> => {
  const { id } = params;
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/question-templates/${id}`,
  });
};

type AddQuestionTemplateParams = Pick<QuestionTemplate, 'title' | 'content'>;

const addQuestionTemplate = (
  params: AddQuestionTemplateParams,
): Promise<void> => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: '/question-templates',
    data: params,
  });
};

type UpdateQuestionTemplateOrderParams = {
  values: { id: number; order: number }[];
};

const updateQuestionTemplateOrder = (
  params: UpdateQuestionTemplateOrderParams,
): Promise<void> => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: '/question-templates/update-order',
    data: params,
  });
};

type EditQuestionTemplateParams = QuestionTemplate;

const editQuestionTemplate = (
  params: EditQuestionTemplateParams,
): Promise<void> => {
  const { id, title, content } = params;
  return _request({
    apiType: ApiType.go,
    method: 'PUT',
    url: `/question-templates/${id}`,
    data: { title, content },
  });
};

type DeleteQuestionTemplateParams = {
  id: number;
};

const deleteQuestionTemplate = (params: DeleteQuestionTemplateParams) => {
  const { id } = params;
  return _request({
    apiType: ApiType.go,
    method: 'DELETE',
    url: `/question-templates/${id}`,
  });
};

export default {
  getQuestions,
  getQuestionsForDownload,
  getQuestionCategories,
  getQuestionCategoryInternals,
  getQuestionTags,
  addQuestionCategoryInternal,
  editQuestionCategoryInternal,
  deleteQuestionCategoryInternal,
  answerQuestion,
  answerQuestionList,
  deleteQuestion,
  getQuestionTemplateList,
  getQuestionTemplate,
  addQuestionTemplate,
  updateQuestionTemplateOrder,
  editQuestionTemplate,
  deleteQuestionTemplate,
};
