import Accordion from '@component/Accordion/Accordion';
import Button from '@component/Button';
import ErrorMsg from '@component/ErrorMsg';
import FlexBox from '@component/FlexBox/FlexBox';
import { useGetAllCompanies } from '@queries/Commerce/useGetCompanies';
import {
  ChallengeInfoForAdmin,
  ChallengeSticker,
  ReqCreateOrUpdateChallenge,
} from '@types';
import { swap } from '@utils/js.utils';
import { useState } from 'react';
import { Icon, Image, Table } from 'semantic-ui-react';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { INITIAL_STICKER } from './StickerForm.constant';
import { convenrtForm2Data, convertData2Form } from './StickerForm.serializer';
import {
  useChallengeStickersActions,
  useChallengeStickersStore,
} from './StickerForm.store';
import { validate } from './StickerForm.validator';
import StickerModal from './components/StickerModal/StickerModal';

const StickerForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'challengeStickers'>,
  Pick<ChallengeFormType, 'challengeStickers'>,
  Pick<ReqCreateOrUpdateChallenge, 'challengeStickers'>
> = () => {
  const [selectedSticker, setSelectedSticker] = useState<
    ChallengeSticker & { index?: number }
  >(INITIAL_STICKER);
  const [showModal, setShowModal] = useState(false);

  const { data: companyOptions = [] } = useGetAllCompanies();

  const { challengeStickers } = useChallengeStickersStore();
  const { setChallengeStickers } = useChallengeStickersActions();
  const validated = validate({ challengeStickers });

  const changeStickerOrder = (key: 'up' | 'down', index: number) => {
    if (key === 'up') {
      if (index - 1 < 0) return;
      const newSticker = swap(challengeStickers, index, index - 1);
      setChallengeStickers(newSticker);
    } else {
      if (challengeStickers.length <= index + 1) return;
      const newSticker = swap(challengeStickers, index, index + 1);
      setChallengeStickers(newSticker);
    }
  };

  const deleteSticker = (idx: number) => {
    setChallengeStickers(challengeStickers.filter((_, index) => index !== idx));
  };

  const editSticker = (sticker: ChallengeSticker, index: number) => {
    setSelectedSticker({ ...sticker, index });
    setShowModal(true);
  };

  const handleStickerChange = (sticker: ChallengeSticker) => {
    setSelectedSticker(sticker);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedSticker(INITIAL_STICKER);
  };

  return (
    <Accordion title="스티커 추가하기">
      <>
        <ErrorMsg text={validated.isValid ? '' : validated.message} />
        <Button onClick={() => setShowModal(true)}>추가</Button>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>회사id</Table.HeaderCell>
              <Table.HeaderCell>회사명</Table.HeaderCell>
              <Table.HeaderCell>스티커</Table.HeaderCell>
              <Table.HeaderCell>편집/삭제</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {challengeStickers.map((s, idx) => (
              <Table.Row key={idx}>
                <Table.Cell collapsing>{s.companyId}</Table.Cell>
                <Table.Cell collapsing>
                  {companyOptions.find(({ id }) => id === s.companyId)?.name}
                </Table.Cell>
                <Table.Cell collapsing>
                  <Image size="tiny" src={s.imageUrl} />
                </Table.Cell>
                <Table.Cell>
                  <FlexBox.Row>
                    <Button
                      name="up"
                      onClick={() => changeStickerOrder('up', idx)}
                      size="small"
                    >
                      <Icon name="angle up" style={{ margin: 0 }} />
                    </Button>
                    <Button
                      name="down"
                      onClick={() => changeStickerOrder('down', idx)}
                      size="small"
                    >
                      <Icon name="angle down" style={{ margin: 0 }} />
                    </Button>
                    <Button
                      text="편집"
                      onClick={() => editSticker(s, idx)}
                      size="small"
                    />
                    <Button
                      onClick={() => deleteSticker(idx)}
                      text="삭제"
                      size="small"
                    />
                  </FlexBox.Row>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
      <StickerModal
        sticker={selectedSticker}
        setSticker={handleStickerChange}
        companyOptions={companyOptions}
        isOpened={showModal}
        onClose={closeModal}
      />
    </Accordion>
  );
};

export default StickerForm;

StickerForm.validate = validate;
StickerForm.convertData2Form = convertData2Form;
StickerForm.convertForm2Data = convenrtForm2Data;
