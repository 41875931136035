import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useFaq = () => useChallengeFormStore((s) => s.challenge.faq);

export const useUpdateFaq = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const update = (faq: string | null) => {
    updateChallengeForm({ faq });
  };

  return { update };
};
