import React, { FC, useState } from 'react';
import { Button, Input } from 'semantic-ui-react';
import moment from 'moment';

const DateSelect: FC<DateSelectProps> = ({ handleChangeDate, fetch }) => {
  const [dateInfo, setDateInfo] = useState<DateInfo>({
    startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });

  const handleChange = (key: keyof DateInfo, value: string) => {
    const _dateInfo = { ...dateInfo, [key]: value };
    setDateInfo(_dateInfo);
    handleChangeDate(key, value);
  };

  const handleClick = () => {
    fetch();
  };

  return (
    <>
      <Input
        type="date"
        size="mini"
        style={{ marginLeft: 10 }}
        value={dateInfo.startDate}
        onChange={(e, { value }) => handleChange('startDate', value)}
      />
      <Input
        type="date"
        size="mini"
        name="endDate"
        value={dateInfo.endDate}
        onChange={(e, { value }) => handleChange('endDate', value)}
      />
      <Button
        size="tiny"
        style={{ marginLeft: 10, display: 'inline' }}
        onClick={handleClick}
      >
        조회
      </Button>
    </>
  );
};

export default DateSelect;

export type DateInfo = {
  startDate: string;
  endDate: string;
};

type DateSelectProps = {
  handleChangeDate: (key: keyof DateInfo, value: string) => void;
  fetch: () => void;
};
