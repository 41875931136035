import _ from 'lodash';
import { DescriptionCollaboOliveYoungOnlyBuyModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { CollaboOliveYoungOnlyBuyDescriptionModule } from '@chlngers/core/lib/src/types/challenge';
import { DEFAULT_DATA } from './OliveYoungOnlyBuyModule.constant';
import {
  descriptionModules,
  commonDescriptionModules,
} from '../../DescriptionModuleForm.constant';

export const convertData2Form: (
  module: CollaboOliveYoungOnlyBuyDescriptionModule,
) => DescriptionCollaboOliveYoungOnlyBuyModuleFormType = ({ modules }) => {
  const { data } = _.cloneDeep(DEFAULT_DATA);

  modules.forEach((m) => {
    switch (m.type) {
      case 'PRODUCT-PURCHASE-REQUIRED':
        data.purchaseRequiredTextList = data.purchaseRequiredTextList.map(
          (item) => ({
            ...item,
            type:
              m.textList.find((t) => t.text === item.text)?.type || item.type,
            active: !!m.textList.find((t) => t.text === item.text),
          }),
        );
        break;
      case 'COLLABO-SUB-MODULE':
        data.subModule = m.data.map((module) => {
          const { type } = module;

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          return descriptionModules[type].convertData2Form(module);
        });
        break;
      case 'OLIVEYOUNG-HOW-TO-PARTICIPATE':
        data.price = m.price;
        data.originalPrice = m.originalPrice;
        data.productThumbnailImageUrl = m.thumbnailImageUrl;
        break;
      case 'CHALLENGE-TIP':
        data.tipTextList = m.textList;
        break;
      case 'OLIVEYOUNG-ACHIEVEMENT-BUY-REVIEW-GUIDE':
        data.reviewGuide = m.reviewGuide;
        data.reviewSubGuide = m.reviewSubGuide;
        data.reviewExampleImageUrls = m.reviewExampleImageUrls;
        break;
      case 'PLEASE-CHECK':
        data.pleaseCheckTextList = data.pleaseCheckTextList.map((item) => ({
          ...item,
          type: m.textList.find((t) => t.text === item.text)?.type || item.type,
          active: !!m.textList.find((t) => t.text === item.text),
        }));
        break;
      default:
    }
  });

  return {
    type: 'COLLABO-OLIVEYOUNG-ONLY-BUY',
    data,
  };
};

export const convertForm2Data: (
  module: DescriptionCollaboOliveYoungOnlyBuyModuleFormType,
) => CollaboOliveYoungOnlyBuyDescriptionModule = ({ data }) => {
  return {
    type: 'COLLABO-OLIVEYOUNG-ONLY-BUY',
    modules: [
      {
        type: 'PRODUCT-PURCHASE-REQUIRED',
        textList: data.purchaseRequiredTextList
          .filter((item) => item.active)
          .map((item) => ({ text: item.text, type: item.type })),
      },
      {
        type: 'COLLABO-SUB-MODULE',
        data: data.subModule.map((module) =>
          commonDescriptionModules[module.type].convertForm2Data(module),
        ),
      },
      {
        type: 'OLIVEYOUNG-HOW-TO-PARTICIPATE',
        thumbnailImageUrl: data.productThumbnailImageUrl,
        price: data.price,
        originalPrice: data.originalPrice,
      },
      {
        type: 'PAYBACK-INFO',
      },
      { type: 'OLIVEYOUNG-ACHIEVEMENT-START-GUIDE' },
      {
        type: 'OLIVEYOUNG-ACHIEVEMENT-LIKE-GUIDE',
        thumbnailImageUrl: data.productThumbnailImageUrl,
        price: data.price,
        originalPrice: data.originalPrice,
      },
      {
        type: 'OLIVEYOUNG-ACHIEVEMENT-PURCHASE-GUIDE',
        thumbnailImageUrl: data.productThumbnailImageUrl,
        price: data.price,
      },
      {
        type: 'CHALLENGE-TIP',
        textList: data.tipTextList,
      },
      {
        type: 'OLIVEYOUNG-ACHIEVEMENT-BUY-REVIEW-GUIDE',
        thumbnailImageUrl: data.productThumbnailImageUrl,
        reviewGuide: data.reviewGuide,
        reviewSubGuide: data.reviewSubGuide,
        reviewExampleImageUrls: data.reviewExampleImageUrls,
      },
      {
        type: 'PLEASE-CHECK',
        textList: data.pleaseCheckTextList
          .filter((item) => item.active)
          .map((item) => ({ text: item.text, type: item.type })),
      },
    ],
  };
};
