export const categories = [
  { key: 1, value: '챌린지 방식/인증 규정', text: '챌린지 방식/인증 규정' },
  { key: 2, value: '참가비/환급/상금', text: '참가비/환급/상금' },
  { key: 3, value: '인증패스/레드카드', text: '인증패스/레드카드' },
  { key: 4, value: '기능/오류', text: '기능/오류' },
  { key: 5, value: '주제 제안', text: '주제 제안' },
  { key: 6, value: '회원가입 및 탈퇴', text: '회원가입 및 탈퇴' },
  { key: 7, value: '챌린지', text: '챌린지' },
  { key: 8, value: '기타', text: '기타' },
  { key: 9, value: '챌린저스 소개', text: '챌린저스 소개' },
  { key: 10, value: '제품리뷰 챌린지', text: '제품리뷰 챌린지' },
  { key: 11, value: '인증/리뷰', text: '인증/리뷰' },
  { key: 12, value: '참가/결제', text: '참가/결제' },
  { key: 13, value: '배송', text: '배송' },
  { key: 14, value: '취소/교환/환불', text: '취소/교환/환불' },
  { key: 15, value: '대회/기념팩 정보', text: '대회/기념팩 정보' },
  { key: 16, value: '상품정보', text: '상품정보' },
  { key: 17, value: '주문/결제', text: '주문/결제' },
  { key: 18, value: '스토어포인트/기타', text: '스토어포인트/기타' },
];

export const subCategoryObj = {
  '챌린지 방식/인증 규정': [
    { key: 1, value: '기본', text: '기본' },
    { key: 2, value: '취소', text: '취소' },
    { key: 3, value: '인증', text: '인증' },
    { key: 4, value: '레드카드', text: '레드카드' },
    { key: 5, value: '기타', text: '기타' },
  ],
  '참가비/환급/상금': [
    { key: 1, value: '기본', text: '기본' },
    { key: 2, value: '상금', text: '상금' },
    { key: 3, value: '환급', text: '환급' },
    { key: 4, value: '참가비', text: '참가비' },
  ],
  '인증패스/레드카드': [
    { key: 1, value: '기본', text: '기본' },
    { key: 2, value: '레드카드', text: '레드카드' },
  ],
  '기능/오류': [
    { key: 1, value: '기능', text: '기능' },
    { key: 2, value: '오류', text: '오류' },
  ],
  '주제 제안': [{ key: 1, value: '기본', text: '기본' }],
  '회원가입 및 탈퇴': [
    { key: 1, value: '기본', text: '기본' },
    { key: 2, value: '개인정보보호', text: '개인정보보호' },
  ],
  챌린지: [{ key: 1, value: '기본', text: '기본' }],
  기타: [
    { key: 1, value: '기본', text: '기본' },
    { key: 2, value: '해외유저', text: '해외유저' },
  ],
  '챌린저스 소개': [{ key: 1, value: '기본', text: '기본' }],
  '제품리뷰 챌린지': [{ key: 1, value: '기본', text: '기본' }],
  '인증/리뷰': [
    { key: 1, value: '기본', text: '기본' },
    { key: 2, value: '혜택', text: '혜택' },
    { key: 3, value: '인증방법', text: '인증방법' },
    { key: 4, value: '리뷰', text: '리뷰' },
    { key: 5, value: '유통기한 초과', text: '유통기한 초과' },
    { key: 6, value: '유통기한 변경', text: '유통기한 변경' },
    { key: 7, value: '스토어포인트', text: '스토어포인트' },
    { key: 8, value: '인증', text: '인증' },
    { key: 9, value: '신고', text: '신고' },
    { key: 10, value: '검토', text: '검토' },
    { key: 11, value: '인증기간', text: '인증기간' },
    { key: 12, value: '유효기간', text: '유효기간' },
    { key: 13, value: '추가인증', text: '추가인증' },
    { key: 14, value: '다량구매', text: '다량구매' },
    { key: 15, value: '웹구매', text: '웹구매' },
    { key: 16, value: '인증결과', text: '인증결과' },
    { key: 17, value: '인증 수정', text: '인증 수정' },
    { key: 18, value: '리뷰 수정', text: '리뷰 수정' },
    { key: 19, value: '인증 순서', text: '인증 순서' },
    { key: 20, value: '인증 오류', text: '인증 오류' },
  ],
  '참가/결제': [{ key: 1, value: '참가/결제', text: '참가/결제' }],
  배송: [
    { key: 1, value: '배송조회', text: '배송조회' },
    { key: 2, value: '배송지', text: '배송지' },
    { key: 3, value: '배송비', text: '배송비' },
    { key: 4, value: '분실', text: '분실' },
    { key: 5, value: '택배사', text: '택배사' },
    { key: 6, value: '해외', text: '해외' },
    { key: 7, value: '배송', text: '배송' },
  ],
  '취소/교환/환불': [
    { key: 1, value: '규정', text: '규정' },
    { key: 2, value: '비용', text: '비용' },
    { key: 3, value: '반품철회', text: '반품철회' },
    { key: 4, value: '교환철회', text: '교환철회' },
    { key: 5, value: '회수', text: '회수' },
    { key: 6, value: '반품', text: '반품' },
    { key: 7, value: '교환', text: '교환' },
    { key: 8, value: '오배송 교환', text: '오배송 교환' },
    { key: 9, value: '교환정보 변경', text: '교환정보 변경' },
    { key: 10, value: '교환일정', text: '교환일정' },
    { key: 11, value: '교환품 품절', text: '교환품 품절' },
    { key: 12, value: '교환불가', text: '교환불가' },
    { key: 13, value: '취소/교환/환불', text: '취소/교환/환불' },
  ],
  '대회/기념팩 정보': [
    { key: 1, value: '대회/기념팩 정보', text: '대회/기념팩 정보' },
  ],
  상품정보: [
    { key: 1, value: '기본', text: '기본' },
    { key: 2, value: '유통기한', text: '유통기한' },
    { key: 3, value: '교환필요', text: '교환필요' },
  ],
  '주문/결제': [
    { key: 1, value: '기본', text: '기본' },
    { key: 2, value: '주문접수', text: '주문접수' },
    { key: 3, value: '교환', text: '교환' },
    { key: 4, value: '환불', text: '환불' },
    { key: 5, value: '스토어포인트', text: '스토어포인트' },
    { key: 6, value: '결제수단', text: '결제수단' },
    { key: 7, value: '영수증', text: '영수증' },
    { key: 8, value: '스토어 포인트', text: '스토어 포인트' },
  ],
  '스토어포인트/기타': [
    { key: 1, value: '스토어포인트', text: '스토어포인트' },
  ],
};
