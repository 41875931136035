import React from 'react';
import { Form, Input } from 'semantic-ui-react';

type DateSelectProps = {
  tempStartDatetime: string;
  tempEndDatetime: string;
  setTempStartDatetime: React.Dispatch<React.SetStateAction<string>>;
  setTempEndDatetime: React.Dispatch<React.SetStateAction<string>>;
};

const DateSelect = ({
  tempStartDatetime,
  tempEndDatetime,
  setTempStartDatetime,
  setTempEndDatetime,
}: DateSelectProps) => {
  return (
    <Form>
      <Form.Field>
        <label>시작일</label>
        <Input
          type="date"
          name="filterStartDatetime"
          value={tempStartDatetime}
          onChange={(_, { value }) => {
            setTempStartDatetime(value);
          }}
        />
      </Form.Field>
      <Form.Field>
        <label>종료일</label>
        <Input
          type="date"
          name="filterEndDatetime"
          value={tempEndDatetime}
          onChange={(_, { value }) => {
            setTempEndDatetime(value);
          }}
        />
      </Form.Field>
    </Form>
  );
};

export default DateSelect;
