import {
  DescriptionModuleFormType,
  DescriptionTextOnlyModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const validate = (
  descriptionModule: DescriptionModuleFormType[],
): { isValid: true } | { isValid: false; message: string } => {
  const target = descriptionModule.find(({ type }) => type === 'TEXT-ONLY');
  if (!target) return { isValid: true };

  const { mainHeader, subHeader, body } =
    target as DescriptionTextOnlyModuleFormType;

  if (
    typeof mainHeader !== 'string' ||
    typeof subHeader !== 'string' ||
    typeof body !== 'string'
  ) {
    return {
      isValid: false,
      message: '[상세모듈] TEXT-ONLY 모듈을 확인해주세요',
    };
  }

  if (!mainHeader.length)
    return {
      isValid: false,
      message: '[상세모듈] TEXT-ONLY 메인헤더 값을 채워주세요',
    };
  return { isValid: true };
};
