import React from 'react';
import { Input } from 'semantic-ui-react';
import moment from 'moment';
import * as S from '../../Style';

const OfferWallDate: React.FC<OfferWallDateProps> = (props) => {
  const { offerWallInfo, onChangeValue } = props;

  const handleChangeDate = (name: string, value: string) => {
    onChangeValue({
      name,
      value: moment(value).format('YYYY-MM-DDTHH:mm:ss'),
    });
  };

  return (
    <>
      <h2>오퍼월 진행 기간</h2>
      <S.RowContainer>
        <S.RowFormField>
          <label>시작일시</label>
          <Input
            type="datetime-local"
            max="9999-12-31T23:59:59"
            step="1"
            value={moment(offerWallInfo.startDateTime).format(
              'YYYY-MM-DDTHH:mm',
            )}
            onChange={(_, { value }) =>
              handleChangeDate('startDateTime', value)
            }
          />
        </S.RowFormField>
        <S.RowFormField margin="0 0 0 10px">
          <label>종료일시</label>
          <Input
            type="datetime-local"
            max="9999-12-31T23:59:59"
            step="1"
            value={moment(offerWallInfo.endDateTime).format(
              'YYYY-MM-DDTHH:mm:ss',
            )}
            onChange={(_, { value }) => handleChangeDate('endDateTime', value)}
          />
        </S.RowFormField>
      </S.RowContainer>
    </>
  );
};

type OfferWallDateProps = {
  onChangeValue: ({ name, value }: { name: string; value: any }) => void;
  offerWallInfo: any;
};

export default OfferWallDate;
