import _ from 'lodash';
import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Button,
  Divider,
  Loader,
  Input,
  Dropdown,
} from 'semantic-ui-react';
import moment from 'moment';
import ChallengeItemTable from './ChallengeItemTable';
import { apis } from '../../api';
import IntervalCopyModal from './IntervalCopyModal';
import { ChallengeInfoForAdmin } from '@types';

const Challenge = () => {
  const [challenges, setChallenges] = React.useState<ChallengeInfoForAdmin[]>(
    [],
  );
  const [activeChallenges, setActiveChallenges] = React.useState<
    ChallengeInfoForAdmin[]
  >([]);
  const [officialChallenges, setOfficialChallenges] = React.useState<
    ChallengeInfoForAdmin[]
  >([]);
  const [customChallenges, setCustomChallenges] = React.useState<
    ChallengeInfoForAdmin[]
  >([]);
  const [isMounted, setIsMounted] = React.useState<boolean>(false);
  const [isFiltered, setIsFiltered] = React.useState<boolean>(false);
  const [intervalCopyModalOpen, setIntervalCopyModalOpen] =
    React.useState<boolean>(false);
  const [activeItem, setActiveItem] = React.useState<string>('all');
  const [checkedIds, setCheckedIds] = React.useState<number[]>([]);
  const [search, setSearch] = React.useState<{
    keyword: string;
    isOfficial?: boolean | 'ALL';
    userId: string;
  }>({
    keyword: '',
    isOfficial: true,
    userId: '',
  });

  useEffect(() => {
    return () => {
      setChallenges([]);
      setIsMounted(true);
    };
  }, []);

  useEffect(() => {
    setIsMounted(false);
    setIsFiltered(false);
    let activeChallenges__: ChallengeInfoForAdmin[] = [];
    if (activeItem === 'all') {
      activeChallenges__ = _.filter(officialChallenges, (challenge) => {
        return challenge.isDeleted === false;
      });
    } else if (activeItem === 'beforeRegister') {
      activeChallenges__ = _.filter(officialChallenges, (challenge) => {
        return (
          moment(challenge.registerStartDate) > moment(new Date()) &&
          challenge.isDeleted === false
        );
      });
    } else if (activeItem === 'register') {
      activeChallenges__ = _.filter(officialChallenges, (challenge) => {
        return (
          moment(challenge.registerStartDate) < moment(new Date()) &&
          moment(new Date()) < moment(challenge.registerEndDate) &&
          challenge.isDeleted === false
        );
      });
    } else if (activeItem === 'ing') {
      activeChallenges__ = _.filter(officialChallenges, (challenge) => {
        return (
          moment(challenge.startDate) <= moment(new Date()) &&
          moment(new Date()) <= moment(challenge.endDate) &&
          challenge.isDeleted === false
        );
      });
    } else if (activeItem === 'beforeCalculated') {
      activeChallenges__ = _.filter(officialChallenges, (challenge) => {
        return (
          moment(challenge.endDate) < moment(new Date()) &&
          challenge.calculateComplete === false &&
          challenge.isDeleted === false
        );
      });
    } else if (activeItem === 'calculated') {
      activeChallenges__ = _.filter(officialChallenges, (challenge) => {
        return (
          moment(challenge.endDate) < moment(new Date()) &&
          challenge.calculateComplete === true &&
          challenge.isDeleted === false
        );
      });
    } else if (activeItem === 'delete') {
      activeChallenges__ = _.filter(officialChallenges, (challenge) => {
        return challenge.isDeleted === true;
      });
    } else if (activeItem === 'allCustom') {
      activeChallenges__ = _.filter(customChallenges, (challenge) => {
        return challenge.isDeleted === false;
      });
    } else if (activeItem === 'beforeRegisterCustom') {
      activeChallenges__ = _.filter(customChallenges, (challenge) => {
        return (
          moment(challenge.registerStartDate) > moment(new Date()) &&
          challenge.isDeleted === false
        );
      });
    } else if (activeItem === 'registerCustom') {
      activeChallenges__ = _.filter(customChallenges, (challenge) => {
        return (
          moment(challenge.registerStartDate) < moment(new Date()) &&
          moment(new Date()) < moment(challenge.registerEndDate) &&
          challenge.isDeleted === false
        );
      });
    } else if (activeItem === 'ingCustom') {
      activeChallenges__ = _.filter(customChallenges, (challenge) => {
        return (
          moment(challenge.startDate) <= moment(new Date()) &&
          moment(new Date()) <= moment(challenge.endDate) &&
          challenge.isDeleted === false
        );
      });
    } else if (activeItem === 'beforeCalculatedCustom') {
      activeChallenges__ = _.filter(customChallenges, (challenge) => {
        return (
          moment(challenge.endDate) < moment(new Date()) &&
          challenge.calculateComplete === false &&
          challenge.isDeleted === false
        );
      });
    } else if (activeItem === 'calculatedCustom') {
      activeChallenges__ = _.filter(customChallenges, (challenge) => {
        return (
          moment(challenge.endDate) < moment(new Date()) &&
          challenge.calculateComplete === true &&
          challenge.isDeleted === false
        );
      });
    } else if (activeItem === 'deleteCustom') {
      activeChallenges__ = _.filter(customChallenges, (challenge) => {
        return challenge.isDeleted === true;
      });
    }

    setActiveChallenges(activeChallenges__);
    setIsFiltered(true);
    setIsMounted(true);
  }, [activeItem]);

  const deleteChallenge = (challengeIds: number[]) => {
    challenges.forEach((c) => {
      if (challengeIds.includes(c.id)) {
        c.isDeleted = true;
      }
    });
    activeChallenges.forEach((c) => {
      if (challengeIds.includes(c.id)) {
        c.isDeleted = true;
      }
    });
    setCheckedIds(checkedIds.filter((id) => !challengeIds.includes(id)));
    setChallenges(challenges);
    setActiveChallenges(activeChallenges);
  };

  const copyChallenge = (
    copyChallenges: ChallengeInfoForAdmin[],
    _checkedIds: number[],
  ) => {
    copyChallenges.forEach((c) => {
      challenges.unshift(c);
      activeChallenges.unshift(c);
    });
    setCheckedIds(checkedIds.filter((id) => !_checkedIds.includes(id)));
    setChallenges(challenges);
    setActiveChallenges(activeChallenges);
  };

  const publicChallenge = (challengeIds: number[]) => {
    challenges.forEach((c) => {
      if (challengeIds.includes(c.id)) {
        c.isPublic = true;
      }
    });
    activeChallenges.forEach((c) => {
      if (challengeIds.includes(c.id)) {
        c.isPublic = true;
      }
    });
    setCheckedIds(checkedIds.filter((id) => !challengeIds.includes(id)));
    setChallenges(challenges);
    setActiveChallenges(activeChallenges);
  };

  const getCustomChallengesByUser = async () => {
    const { challenges: customChallenges_ } = await apis.getChallenges({
      gteResultDate: moment().subtract(7, 'd').format('YYYY-MM-DD HH:mm:ss'),
      userId: Number(search.userId),
      isOfficial: false,
    });
    setCustomChallenges(customChallenges_);
    setActiveItem('allCustom');
  };

  const searchChallenges = async () => {
    setIsMounted(false);
    const { keyword, isOfficial } = search;
    const concurrent = 2;
    let offset = 0;
    const limit = 100;
    let challenges_: ChallengeInfoForAdmin[] = [];
    while (true) {
      const promises = getChallengesByKeyword(
        concurrent,
        offset,
        limit,
        keyword,
        isOfficial === 'ALL' ? undefined : isOfficial,
      );
      const newChallenges = _.concat(
        // eslint-disable-next-line no-await-in-loop
        ..._.map(await Promise.all(promises), 'challenges'),
      );
      challenges_ = challenges_.concat(newChallenges);
      offset += limit * concurrent;
      if (newChallenges.length < limit * concurrent) break;
    }
    challenges_ = _.orderBy(challenges_, ['id'], ['desc']);
    setActiveChallenges(challenges_);
    setIsMounted(true);
  };

  const getChallengesByKeyword = (
    concurrent: number,
    offset: number,
    limit: number,
    keyword: string,
    isOfficial: boolean | undefined,
  ) => {
    return _.map(_.range(concurrent), (i) =>
      apis.getChallenges({
        keyword,
        offset: offset + i * limit,
        limit,
        isOfficial,
        gteResultDate: moment()
          .subtract(365, 'd')
          .format('YYYY-MM-DD HH:mm:ss'),
      }),
    );
  };

  const handleChange = (e: any, { name, value }: any) => {
    if (name === 'isOfficial' && value === 'ALL') {
      setSearch({ ...search, isOfficial: undefined });
      return;
    }

    if (['userId'].includes(name)) {
      setSearch({ ...search, [name]: Number(value) });
    } else {
      setSearch({ ...search, [name]: value });
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      searchChallenges();
    }
  };

  const handleKeyPressCustomChallenge = (event: any) => {
    if (event.key === 'Enter') {
      getCustomChallengesByUser();
    }
  };

  const showIntervalCopyModal = () => setIntervalCopyModalOpen(true);

  const closeIntervalCopyModal = () => setIntervalCopyModalOpen(false);

  if (!challenges) return <Container>No Challenges</Container>;
  return (
    <Container fluid>
      <Divider hidden />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: 40,
          marginRight: 40,
        }}
      >
        <div
          style={{
            width: 300,
            marginRight: 20,
          }}
        >
          <Grid columns={3} style={styles.cardSummary}>
            <Grid.Column verticalAlign="bottom">
              공식 : {officialChallenges.length}
            </Grid.Column>
            <Grid.Column verticalAlign="bottom">
              개설 : {customChallenges.length}
            </Grid.Column>
            <Grid.Column verticalAlign="bottom">
              현재 : {activeChallenges.length}
            </Grid.Column>
          </Grid>
          <Dropdown
            placeholder="챌린지 구분"
            selection
            name="isOfficial"
            // eslint-disable-next-line react/jsx-boolean-value
            defaultValue={true}
            options={[
              { key: 'OFFICIAL', text: '공식', value: true },
              { key: 'CUSTOM', text: '개설', value: false },
              { key: 'ALL', text: '전체', value: 'ALL' },
            ]}
            onChange={handleChange}
          />
          <Input
            size="small"
            name="keyword"
            placeholder="챌린지명을 입력하세요"
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          />
          <Button size="small" onClick={searchChallenges}>
            검색
          </Button>
          <Input
            size="small"
            name="userId"
            style={styles.button}
            placeholder="개설 user id를 입력하세요"
            onChange={handleChange}
            onKeyPress={handleKeyPressCustomChallenge}
          />
          <Button
            size="small"
            style={styles.button}
            onClick={getCustomChallengesByUser}
          >
            검색
          </Button>
          <div>*챌린지 검색은 최근 1년만 가능합니다.</div>

          <Button
            fluid
            as={Link}
            to={{ pathname: '/challenges/create' }}
            style={styles.button}
          >
            챌린지 추가
          </Button>
          <Button
            fluid
            as={Link}
            to={{ pathname: '/challenges/curations' }}
            style={styles.button}
          >
            큐레이션
          </Button>
          <Button
            fluid
            as={Link}
            to={{ pathname: 'point-infos' }}
            style={styles.button}
          >
            포인트 현황
          </Button>

          <Button
            fluid
            style={styles.button}
            content="상시 챌린지 반복 생성"
            onClick={showIntervalCopyModal}
          />
        </div>

        {(!isMounted || !isFiltered) && <Loader active inline={'centered'} />}
        {isMounted &&
          isFiltered &&
          (activeChallenges.length > 0 ? (
            <ChallengeItemTable
              challenges={activeChallenges}
              delete={deleteChallenge}
              copy={copyChallenge}
              public={publicChallenge}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
            />
          ) : (
            <div>해당하는 챌린지 목록이 없습니다.</div>
          ))}

        {intervalCopyModalOpen && (
          <IntervalCopyModal
            modalOpen={intervalCopyModalOpen}
            closeModal={closeIntervalCopyModal}
          />
        )}
      </div>
    </Container>
  );
};

export default Challenge;

const styles = {
  cardSummary: {
    marginBottom: 5,
  },
  breadcrumb: {
    marginBottom: 10,
  },
  button: {
    marginTop: 5,
  },
};
