import { array, number, object, string } from 'yup';

export const preAlarmSchema = object().shape({
  descriptionImageUrlList: array()
    .of(string())
    .min(1, '상세 설명 이미지는 최소 1개 이상이어야 합니다.')
    .required('상세 설명 이미지는 필수 입력사항입니다.'),
  thumbnailImageUrl: string().required('썸네일 이미지는 필수 입력사항입니다.'),
  maxRegisterCount: number().required('모집 최대 인원은 필수 입력사항입니다.'),
  additionalInfo: object({
    button: object({
      name: string().required(),
      url: string().required(),
    })
      .nullable()
      .test(
        'button',
        '버튼명 또는 버튼 링크는 필수 입력사항입니다.',
        (button) => {
          if (!button) return true;

          return !!button.name.length && !!button.url.length;
        },
      ),
    purchaseStartDate: string().test(
      'purchaseStartDate',
      '구매일시 (시작)은 구매일시 (종료)보다 이전이어야 합니다.',
      function () {
        const { purchaseStartDate, purchaseEndDate } = this.parent;

        return new Date(purchaseStartDate) <= new Date(purchaseEndDate);
      },
    ),
    purchaseChannel: string().required('구매장소는 필수 입력사항입니다.'),
  }),
  brand: string().required('회사는 필수 입력사항입니다.'),
  title: string().required('기존 챌린지명은 필수 입력사항입니다.'),
});
