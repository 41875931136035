import React, { useState } from 'react';
import { Accordion, Icon, Image } from 'semantic-ui-react';
import * as S from './Style';

const PopUpBannerPreview = () => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Accordion style={{ width: '100%' }}>
      <Accordion.Title
        active={!collapsed}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Icon name="dropdown" />
        미리보기
      </Accordion.Title>
      <Accordion.Content active={!collapsed}>
        <S.FlexBox margin="0 0 0 20px" gap="5px">
          <S.FlexBox flexDirection="column">
            <Image src="/banners/popup-example.jpg" size="small" />
          </S.FlexBox>
        </S.FlexBox>
      </Accordion.Content>
    </Accordion>
  );
};

export default PopUpBannerPreview;
