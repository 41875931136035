import styled from 'styled-components';
import { Form } from 'semantic-ui-react';

export const RowContainer = styled.div<{
  gap?: string;
  margin?: string;
  height?: string;
}>`
  display: flex;
  gap: ${(props) => props.gap && props.gap};
  margin: ${(props) => props.margin && props.margin} !important;
  height: ${(props) => props.height && props.height};
`;

export const RowFormField = styled(Form.Field)<{
  margin?: string;
}>`
  flex: 1;
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const Container = styled.div<{
  gap?: string;
  margin?: string;
}>`
  margin: ${(props) => props.margin && props.margin} !important;
`;
