import ErrorMsg from '@component/ErrorMsg';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import NumberInput from '@component/NumberInput';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import { ChallengeCustomField } from '@types';
import { useRef } from 'react';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { useIsCustomGoal } from '../../hooks/useIsCustomGoal';
import {
  convertData2Form,
  convertForm2Data,
} from './SpecialPrizeForm.serializer';
import {
  useSpecialPrizeForm,
  useSpecialPrizeFormSetter,
} from './SpecialPrizeForm.store';
import {
  formatSpecialPrize,
  getSpecialPrizeTypeOptions,
} from './SpecialPrizeForm.utils';
import { validate } from './SpecialPrizeForm.validator';
import { useEditCheck } from '../../hooks/useEditCheck';

export const SpecialPrizeForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'specialPrize'>,
  Extract<ChallengeCustomField, { name: 'specialPrize' }>
> = () => {
  const specialPrize = useSpecialPrizeForm();
  const { setSpecialPrizeForm } = useSpecialPrizeFormSetter();
  const inputRef = useRef<HTMLInputElement>(null);
  const amountInputVisible =
    specialPrize.prizeType === 'individual' ||
    specialPrize.prizeType === 'total';
  const { isCustomGoal } = useIsCustomGoal();
  const specialPrizeTypeOptions = getSpecialPrizeTypeOptions(isCustomGoal);

  const validated = validate({ specialPrize });

  const { triggerEdited, getBorder } = useEditCheck();

  const selectedOption =
    specialPrizeTypeOptions.find((d) => d.type === specialPrize.prizeType) ||
    specialPrizeTypeOptions[0];

  const handleChangeType = (option: (typeof specialPrizeTypeOptions)[0]) => {
    const notNeededAmount =
      option.type === null || option.type === 'achievement';
    setSpecialPrizeForm({
      ...specialPrize,
      prizeType: option.type,
      amount: notNeededAmount ? 0 : specialPrize.amount,
    });
    // 상금 amount를 입력하지 않아도 될 때는 수정한걸로 처리
    if (notNeededAmount) {
      triggerEdited('specialPrizeAmount');
    }
  };

  const handleChangeAmount = (amount: number | null) => {
    if (specialPrize.prizeType === null || amount === null) return;
    triggerEdited('specialPrizeAmount');
    setSpecialPrizeForm({ ...specialPrize, amount });
  };

  return (
    <div>
      <FlexBox.Column gap={12}>
        <FlexBox.Column gap={5}>
          <h3 style={{ margin: 0 }}>특별상금 관련 필드</h3>
          <strong style={{ color: '#ff8000' }}>
            📣 특별상금의 지급 방식과 금액을 정확하게 입력해주세요.
          </strong>
        </FlexBox.Column>

        <ErrorMsg text={validated.isValid ? '' : validated.message} />

        <FlexBox.Row gap={100}>
          <FlexBox.Column>
            <h5>특별상금 지급 방식</h5>
            <RadioSelector
              options={specialPrizeTypeOptions}
              selectedOption={selectedOption}
              style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
              onChange={handleChangeType}
            />
          </FlexBox.Column>

          {amountInputVisible && (
            <FlexBox.Column>
              <NumberInput
                ref={inputRef}
                label="특별상금(원)"
                value={specialPrize.amount || 0}
                onChange={handleChangeAmount}
                style={{ border: getBorder('specialPrizeAmount') }}
              />
              <Label style={{ color: '#2222ff', marginLeft: 4 }}>
                {formatSpecialPrize({ specialPrize })}
              </Label>
            </FlexBox.Column>
          )}
        </FlexBox.Row>
      </FlexBox.Column>
    </div>
  );
};

SpecialPrizeForm.validate = validate;
SpecialPrizeForm.convertData2Form = convertData2Form;
SpecialPrizeForm.convertForm2Data = convertForm2Data;
