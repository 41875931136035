import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

export const validate: FormValidator<
  Pick<ChallengeFormType, 'commerce' | 'supporters'>
> = ({ commerce, supporters }) => {
  const { productHeader, rccps } = commerce;

  if (rccps.length > 0 && !productHeader) {
    return {
      isValid: false,
      message: '[커머스] 상품 옵션명을 입력해주세요.',
    };
  }

  if (productHeader && rccps.length === 0) {
    return {
      isValid: false,
      message: '[커머스] 상품 옵션은 하나 이상 필요합니다.',
    };
  }

  if (supporters.hasDelivery && (!rccps.length || !productHeader)) {
    return {
      isValid: false,
      message:
        '[커머스] 체험단 배송상품이 존재하면 커머스는 필수로 세팅되어야 합니다.',
    };
  }

  return { isValid: true };
};
