import { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, Form, Grid, Modal, Table } from 'semantic-ui-react';
import { apis } from '../../../api';
import { roleOptions } from '../../../utils/dropdownOptions';
import { generateId } from '../../../utils/number';
import SettingMenu from '../SettingMenu';

const UserAdmin = () => {
  const [userAdmin, setUserAdmin] = useState({});
  const [userAdminList, setUserAdminList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    _getUserAdminList();
  }, []);

  const initialize = useCallback(() => {
    return {
      email: '',
      name: '',
      roles: [],
      phone: '',
    };
  }, []);

  const _getUserAdminList = async () => {
    const res = await apis.getUserAdminList();
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    const { userAdminList } = res;

    setUserAdminList(userAdminList);
  };

  const _createUserAdmin = async () => {
    const _userAdmin = { ...userAdmin };
    const res = await apis.createUserAdmin(_userAdmin);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    const _userAdminList = [...userAdminList];
    _userAdminList.splice(0, 0, { id: generateId(), ..._userAdmin });

    closeModal();
    setUserAdminList(_userAdminList);
    setUserAdmin({});

    alert('계정이 추가되었어요');
  };

  const _updateUserAdmin = async () => {
    const _userAdmin = { ...userAdmin };
    const res = await apis.updateUserAdmin(_userAdmin.id, _userAdmin);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    const _userAdminList = [...userAdminList].map((o) => {
      if (o.id === _userAdmin.id) return _userAdmin;
      else return o;
    });

    closeModal();
    setUserAdminList(_userAdminList);
    setUserAdmin({});

    alert('계정이 수정되었어요');
  };

  const _deleteUserAdmin = async (_userAdmin) => {
    if (
      confirm(`[${_userAdmin.id}] ${_userAdmin.email} 계정을 삭제하시겠어요?`)
    ) {
      const res = await apis.deleteUserAdmin(_userAdmin.id);
      if (res?.response?.data && 'err' in res?.response?.data) {
        return;
      }

      const _userAdminList = [...userAdminList].filter(
        (o) => o.id !== _userAdmin.id,
      );
      setUserAdminList(_userAdminList);

      alert('삭제되었어요');
    }
  };

  const showModal = useCallback((_userAdmin) => {
    if (!_userAdmin) {
      setUserAdmin(initialize());
    } else {
      setUserAdmin(_userAdmin);
    }
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  });

  const handleChange = (key, value) => {
    const _userAdmin = { ...userAdmin };
    _userAdmin[key] = value;
    setUserAdmin(_userAdmin);
  };

  const _submit = () => {
    if (
      !!userAdmin.outlookPassword &&
      userAdmin.outlookPassword !== userAdmin.outlookPasswordCheck
    ) {
      alert('아웃룩 계정 비밀번호를 다시 확인해주세요!');
      return;
    }

    delete userAdmin.outlookPasswordCheck;

    if (!userAdmin.id) {
      _createUserAdmin();
    } else {
      _updateUserAdmin();
    }
  };

  return (
    <Grid columns={'equal'} style={{ margin: 40 }}>
      <Grid.Column width={2}>
        <SettingMenu />
      </Grid.Column>
      <Grid.Column>
        <h3>
          화이트큐브 어드민 계정 관리
          <Button size={'mini'} content={'추가'} onClick={() => showModal()} />
        </h3>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>이메일</Table.HeaderCell>
              <Table.HeaderCell>범위</Table.HeaderCell>
              <Table.HeaderCell>이름</Table.HeaderCell>
              <Table.HeaderCell>수정/삭제</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {userAdminList.map((userAdmin) => (
              <Table.Row key={userAdmin.id}>
                <Table.Cell>{userAdmin.id}</Table.Cell>
                <Table.Cell>{userAdmin.email}</Table.Cell>
                <Table.Cell>
                  {userAdmin.roles.map((role) => (
                    <div key={role}>
                      {roleOptions.find((o) => o.value === role)?.text ??
                        '_잘못된 권한_'}
                    </div>
                  ))}
                </Table.Cell>
                <Table.Cell>{userAdmin.name}</Table.Cell>
                <Table.Cell>
                  <Button
                    size={'mini'}
                    content={'수정'}
                    onClick={() => showModal(userAdmin)}
                  />
                  <Button
                    size={'mini'}
                    content={'삭제'}
                    onClick={() => _deleteUserAdmin(userAdmin)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Grid.Column>

      <Modal size="tiny" open={modalOpen} onClose={closeModal}>
        <Modal.Header>계정 {!userAdmin.id ? '추가' : '수정'}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>이메일</label>
              <input
                value={userAdmin.email}
                onChange={(e) => handleChange('email', e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>이름</label>
              <input
                value={userAdmin.name}
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>핸드폰 번호</label>
              <input
                value={userAdmin.phone}
                onChange={(e) => handleChange('phone', e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>노출 범위</label>
              <Dropdown
                placeholder="범위를 선택하세요"
                fluid
                multiple
                search
                selection
                name="typeId"
                options={roleOptions}
                value={userAdmin.roles}
                onChange={(_, { value }) => handleChange('roles', value)}
              />
            </Form.Field>
            <Form.Field>
              <label>아웃룩 계정 비밀번호</label>
              <input
                type={'password'}
                onChange={(e) =>
                  handleChange('outlookPassword', e.target.value)
                }
              />
            </Form.Field>
            <Form.Field>
              <label>아웃룩 계정 비밀번호 (재입력)</label>
              <input
                type={'password'}
                onChange={(e) =>
                  handleChange('outlookPasswordCheck', e.target.value)
                }
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            content={!userAdmin.id ? '추가' : '수정'}
            onClick={_submit}
          />
          <Button color="black" content="닫기" onClick={closeModal} />
        </Modal.Actions>
      </Modal>
    </Grid>
  );
};

export default UserAdmin;
