import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

export const validate: FormValidator<Pick<ChallengeFormType, 'userFilter'>> = ({
  userFilter,
}) => {
  if (!(typeof userFilter.id === 'number' || userFilter.id === null))
    return { isValid: false, message: '[유저필터]개발자에게 문의해주세요' };
  return {
    isValid: true,
  };
};
