import { GoalCategoryWithRestrict, GoalDetailInfo } from '@types';
import { DropdownOption } from 'src/types/common.types';

export const getOptionsFromGoalCategories = (
  goalCategories: GoalCategoryWithRestrict[],
) => {
  return goalCategories.map(({ id, level1, level2, level3, level4 }) =>
    makeGoalCategoryOption(id, level1, level2, level3, level4),
  );
};

export const getGoalCategoryFromDropdownOption = (
  goalCategoryId: number,
  options: DropdownOption[],
) => {
  const option = options.find((o) => o.value === goalCategoryId);
  if (!option) return null;
  // const splits = option.text.split(']');
  // const [level1, level2, level3, level4] = splits[1].split('|');
  const [_, id, level1, level2, level3, level4] = option.text.split(/[[\]|]/g);
  return {
    id: goalCategoryId,
    level1,
    level2,
    level3,
    level4,
  };
};

export const makeGoalCategoryOption = (
  id: number,
  level1: string,
  level2: string,
  level3: string,
  level4: string,
) => {
  return {
    key: id,
    text: `[${id}]${level1}|${level2}|${level3}|${level4}`,
    value: id,
  };
};

export const validateGoal = (goal: GoalDetailInfo): string => {
  if (goal.cycle.length === 0) return '요일 영역을 선택해주세요.';
  if (goal.goalCategoryId <= 0) return '목표카테고리를 선택해주세요.';
  return '';
};
