import { useQuery } from '@tanstack/react-query';
import { apis } from '../../api';
import { getQueryKey } from './getQueryKey';

export const useGetRestDays = () => {
  return useQuery({
    queryKey: getQueryKey('GET_REST_DAYS'),
    queryFn: apis.common.getRestDays,
  });
};
