export const getGotchaType = (type) => {
  return {
    instant: '즉시지급',
    delivery: '택배배송',
    gifticon: '기프티콘',
  }[type];
};

export const getGotchaRewardType = (type) => {
  return {
    STORE: '스토어포인트',
    PRIZE: '상금',
    CASH: '예치금',
    ITEM: '인증패스',
  }[type];
};

export const getGotchaColor = (type) => {
  return {
    instant: 'pink',
    delivery: 'green',
    gifticon: 'black',
  }[type];
};
