import styled from 'styled-components';

export const Button = styled.div`
  padding: 4px 8px;
  cursor: pointer;
  background-color: #eee;
  border-radius: 4px;
  margin-left: 4px;
  font-weight: bold;
  font-size: 12px;
`;

export const Wrapper = styled.div<{
  backgroundColor: React.CSSProperties['backgroundColor'];
}>`
  width: 100%;
  padding: 10px 8px;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
`;

export const Span = styled.span<{ isActive: boolean }>`
  font-weight: bold;
  color: ${(props) => (props.isActive ? '#fff' : '#000')};
`;
