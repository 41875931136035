import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckboxProps,
  Dimmer,
  Divider,
  Dropdown,
  Form,
  Image,
  Loader,
} from 'semantic-ui-react';
import { UserFilterSelector } from '../../../component/UserFilterSelector';
import ServiceCategorySelector from '@component/ServiceCategorySelector';
import { raceTypeOptions } from '../../../constant/race';
import { handleChangeSingleFile } from '../../../utils/uploadImage';
import * as S from '../Style';
import ExtraInfoField from './components/ExtraInfoField';
import FilterField from './components/FilterField';
import HomeDescription from './components/HomeDescription';
import LeagueField from './components/LeagueField';
import ProductDetailImageField from './components/ProductDetailImageField';
import ProgressInfoDescription from './components/ProgressInfoDescription';
import PromiseField from './components/PromiseField';
import RaceCardInfoField from './components/RaceCardInfoField';
import RaceOptionProductField from './components/RaceOptionProductField';
import RegisterInfoDescription from './components/RegisterInfoDescription';
import ReviewField from './components/ReviewField';
import ShareField from './components/ShareField';
import SponsorField from './components/SponsorField';
import StampField from './components/StampField';
import RaceThumbnailPreview from './components/RaceThumbnailPreview/RaceThumbnailPreview';
import CheerSettingField from './components/CheerSettingField/CheerSettingField';
import RaceAuthenticationMethodField from './components/RaceAuthenticationMethodField';
import { RaceFormType } from './RaceForm.types';
import { LabelMiniInfo, Race, ServiceCategory } from '@types';
import {
  cheerRaceParameterInitialValue,
  initialValue,
} from './RaceForm.constants';
import {
  formattingBeforeSet,
  formattingBeforeSubmit,
} from './RaceForm.serializer';
import { format } from 'date-fns';
import RaceProductDescriptionForm from './components/RaceProductDescriptionForm/RaceProductDescriptionForm';
import RecordSettingField from './components/RecordSettingField/RecordSettingField';
import FlexBox from '@component/FlexBox/FlexBox';
import _ from 'lodash';
import { validate } from './RaceForm.validator';
import LabelField from './components/LabelField/LabelField';

const RaceForm = ({
  race: _race,
  productPackages: _productPackages,
  raceImageFilters: _raceImageFilters,
  labels: _labels,
  submit,
}: {
  race?: Race.ReqCreateOrUpdateRace;
  productPackages?: Race.RaceProductPackageFormType[];
  raceImageFilters?: Race.RaceImageFilterWithLeagueId[];
  labels?: LabelMiniInfo[];
  submit: (
    race: Race.ReqCreateOrUpdateRace,
    productPackages: Race.RaceProductPackageFormType[],
    raceImageFilters: Race.RaceImageFilterWithLeagueId[],
    labelIds: number[],
    sendSlack: boolean,
    autoGenerateOfferWall: boolean,
  ) => Promise<void>;
}) => {
  const [race, setRace] = useState<RaceFormType>(initialValue);
  const [productPackages, setProductPackages] = useState<
    Race.RaceProductPackageFormType[]
  >([]);
  const [labelIds, setLabelIds] = useState<number[]>([]);

  // 필터 정보 (스티커, 이미지 필터)
  const [stampStickers, setStampStickers] = useState<Race.RaceSticker[]>([]);
  const [imageFilters, setImageFilters] = useState<Race.RaceImageFilter[]>([]);
  const [sendSlack, setSendSlack] = useState<boolean>(false);
  const [autoGenerateOfferWall, setAutoGenerateOfferWall] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);

  const mode = !_race?.id ? '추가' : '수정';

  useEffect(() => {
    // 새로 생성
    if (!_race?.id) {
      setRace(initialValue);
    } else {
      // 수정
      const { race: formattedRace, productPackages: formattedProductPacakge } =
        formattingBeforeSet(_race, _productPackages!, _raceImageFilters!);
      setRace(formattedRace as RaceFormType);
      setProductPackages(
        (formattedProductPacakge as Race.RaceProductPackageFormType[]) ?? [],
      );
      setLabelIds((_labels || []).map(({ id }) => id));
      // 리그별로 stickers, imageFilters는 무조건 똑같다고 가정
      setStampStickers(
        (formattedRace as Race.ReqCreateOrUpdateRace).leagues[0]?.stickers ??
          [],
      );
      setImageFilters(
        (formattedRace as Race.ReqCreateOrUpdateRace).leagues[0]
          ?.imageFilters ?? [],
      );
    }
  }, [_race, _productPackages, _raceImageFilters, _labels]);

  const handleChange: <K extends keyof RaceFormType>(
    key: K,
    value: RaceFormType[K],
  ) => void = (key, value) => {
    if (!race) return;

    const race_ = { ...race };

    if (
      key === 'raceType' &&
      value === 'cheer' &&
      (!race_.cheerRaceParameter?.cheerType ||
        !race_.cheerRaceParameter?.cheerRecordText ||
        !race_.cheerRaceParameter?.cheerRecordUnit)
    ) {
      race_.cheerRaceParameter = cheerRaceParameterInitialValue;
    }

    setRace({
      ...race_,
      [key]: value,
    });
  };

  const handleChangeUserFilterId = (id: number | null) => {
    if (!race) return;
    setRace({
      ...race,
      userFilter: {
        ...race.userFilter,
        id: id ?? undefined,
      },
    });
  };

  const handleImageChange: React.ChangeEventHandler<HTMLInputElement> = async (
    e,
  ) => {
    const { name } = e.target;
    const { [name]: value } = await handleChangeSingleFile(e, { ...race });
    setRace({
      ...race,
      [name]: value,
    });
  };

  const handleAuthenticationMethodChange: <
    K extends keyof Race.RaceAuthenticationMethod,
  >(
    key: K,
    value: Race.RaceAuthenticationMethod[K],
  ) => void = (key, value) => {
    const race_ = { ...race };
    if (!race_.authenticationMethod)
      race_.authenticationMethod = {} as Race.RaceAuthenticationMethod;
    race_.authenticationMethod[key] = value;
    setRace(race_);
  };

  const handleAuthenticationMethodImageChange: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void = async (e) => {
    const race_ = { ...race };
    const name = e.target.name as keyof Race.RaceAuthenticationMethod;
    const temp = await handleChangeSingleFile(e, {});
    if (!race_.authenticationMethod) {
      race_.authenticationMethod = {} as Race.RaceAuthenticationMethod;
    }
    race_.authenticationMethod[name] = temp[name];
    setRace(race_);
  };

  const handleInfoChange: <K extends keyof Race.RaceInfo>(
    key: K,
    value: Race.RaceInfo[K],
  ) => void = (key, value) => {
    if (!race) return;
    const race_ = { ...race };
    setRace({
      ...race_,
      info: {
        ...race_.info,
        [key]: value,
      },
    });
  };

  const handleCheerCustomChange = <K extends keyof Race.CheerRaceParameter>(
    key: K,
    value: Race.CheerRaceParameter[K],
  ) => {
    if (!race) return;
    const race_ = { ...race };
    if (!race_.cheerRaceParameter) return;
    setRace({
      ...race_,
      cheerRaceParameter: {
        ...race_.cheerRaceParameter,
        [key]: value,
      },
    });
  };

  const handleRecordCustomChange = <K extends keyof Race.RecordRaceParameter>(
    key: K,
    value: Race.RecordRaceParameter[K],
  ) => {
    if (!race) return;
    const race_ = { ...race };
    if (!race_.recordRaceParameter) return;
    setRace({
      ...race_,
      recordRaceParameter: {
        ...race_.recordRaceParameter,
        [key]: value,
      },
    });
  };

  const handleInfoImageChange: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e) => {
    if (!race) return;
    const race_ = { ...race };
    const { name } = e.target;
    const temp = await handleChangeSingleFile(e, {});
    setRace({
      ...race_,
      info: {
        ...race_.info,
        [name]: temp[name],
      },
    });
  };

  const _submit = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const { isValid, message } = await validate(
      race,
      productPackages,
      stampStickers,
      imageFilters,
    );
    if (!isValid) {
      setIsLoading(false);
      alert(message);
      return;
    }
    const {
      race: formattedRace,
      productPackages: formatttedProductPackages,
      raceImageFilters: formattedRaceImageFilters,
    } = formattingBeforeSubmit(
      { ..._.cloneDeep(race) },
      [..._.cloneDeep(productPackages)],
      [..._.cloneDeep(stampStickers)],
      [..._.cloneDeep(imageFilters)],
    );

    await submit(
      formattedRace as Race.ReqCreateOrUpdateRace,
      formatttedProductPackages as Race.RaceProductPackageFormType[],
      formattedRaceImageFilters as Race.RaceImageFilterWithLeagueId[],
      labelIds,
      sendSlack as boolean,
      autoGenerateOfferWall,
    );
    setIsLoading(false);
  };

  const handleSendSlack: (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps,
  ) => void = (__, { checked }) => {
    setSendSlack(checked ?? false);
  };

  const handleShippingGuideClick: (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps,
  ) => void = (__, { checked }) => {
    if (checked === undefined) return;
    const race_ = { ...race };
    race_.modules.showShippingGuide = checked;
    setRace(race_);
  };

  const handleAutoOfferWallClick: (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps,
  ) => void = (__, { checked }) => {
    setAutoGenerateOfferWall(checked ?? false);
  };

  if (!race) return <Loader />;
  return (
    <Form>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <h2>대회 {mode}</h2>

      <S.RowContainer>
        <S.RowFormField>
          <h2>기본 정보</h2>
        </S.RowFormField>
        <FlexBox.Row gap={10}>
          <Checkbox label="대회 컨펌 슬랙 발송" onChange={handleSendSlack} />
          <Checkbox
            label="결제 완료 후 배송 안내 문구 표시"
            checked={race.modules.showShippingGuide}
            onChange={handleShippingGuideClick}
          />
          <Checkbox
            label="오퍼월 자동 생성"
            checked={autoGenerateOfferWall}
            onChange={handleAutoOfferWallClick}
          />
        </FlexBox.Row>
      </S.RowContainer>
      <S.RowContainer>
        <S.RowFormField>
          <label>대회명</label>
          <input
            value={race.title}
            onChange={(e) => handleChange('title', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField>
          <label>대회 종류</label>
          <Dropdown
            placeholder="대회 종류를 선택하세요"
            fluid
            search
            selection
            options={raceTypeOptions}
            value={race.raceType}
            onChange={(__, { value }) =>
              handleChange('raceType', value as Race.RaceType)
            }
          />
        </S.RowFormField>
        <ServiceCategorySelector
          value={race.category}
          onChange={(value) =>
            handleChange('category', value as ServiceCategory)
          }
        />
      </S.RowContainer>

      <S.RowContainer>
        <S.RowFormField>
          <label>시즌명</label>
          <input
            value={race.seasonTerm}
            onChange={(e) => handleChange('seasonTerm', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField>
          <label>최대인원수</label>
          <input
            value={race.maxRegisterCount}
            onChange={(e) => {
              handleChange(
                'maxRegisterCount',
                Number.isNaN(Number(e.target.value))
                  ? race.maxRegisterCount
                  : Number(e.target.value),
              );
            }}
          />
        </S.RowFormField>
      </S.RowContainer>

      <S.RowContainer>
        <S.RowFormField>
          <label>모집시작일</label>
          <input
            type="datetime-local"
            max="9999-12-31T23:59"
            value={format(
              new Date(race.registerStartDate),
              "yyyy-MM-dd'T'HH:mm",
            )}
            onChange={(e) => handleChange('registerStartDate', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField>
          <label>모집종료일</label>
          <input
            type="datetime-local"
            max="9999-12-31T23:59"
            value={format(new Date(race.registerEndDate), "yyyy-MM-dd'T'HH:mm")}
            onChange={(e) => handleChange('registerEndDate', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField>
          <label>대회시작일</label>
          <input
            type="datetime-local"
            max="9999-12-31T23:59"
            value={format(new Date(race.startDate), "yyyy-MM-dd'T'HH:mm")}
            onChange={(e) => handleChange('startDate', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField>
          <label>대회종료일</label>
          <input
            type="datetime-local"
            max="9999-12-31T23:59"
            value={format(new Date(race.endDate), "yyyy-MM-dd'T'HH:mm")}
            onChange={(e) => handleChange('endDate', e.target.value)}
          />
        </S.RowFormField>
      </S.RowContainer>

      {race.raceType === 'cheer' && (
        <CheerSettingField
          cheerRaceParameter={race.cheerRaceParameter}
          handleChange={handleCheerCustomChange}
        />
      )}

      {race.raceType === 'record' && (
        <RecordSettingField
          recordRaceParameter={race.recordRaceParameter}
          handleChange={handleRecordCustomChange}
        />
      )}

      <RaceProductDescriptionForm
        descriptions={race.productDescriptions || []}
        onChange={(descriptions) => {
          setRace((r: RaceFormType) => ({
            ...r,
            productDescriptions: descriptions,
          }));
        }}
      />
      <Divider hidden />

      <LabelField labelIds={labelIds} setLabelIds={setLabelIds} />
      <Divider hidden />

      <RaceOptionProductField
        productPackages={productPackages}
        setProductPackages={setProductPackages}
      />
      <Divider hidden />

      <RaceCardInfoField race={race} setRace={setRace} />
      <Divider hidden />

      <h3>대회 이미지 세팅</h3>
      <S.RowContainer>
        <S.RowFormField>
          <label>썸네일</label>
          <input
            name="thumbnailImageUrl"
            type="file"
            onChange={handleImageChange}
          />
          <Image src={race.thumbnailImageUrl} size="small" />
        </S.RowFormField>
        <S.RowFormField>
          <label>인증탭 카드</label>
          <input name="cardImageUrl" type="file" onChange={handleImageChange} />
          <Image src={race.cardImageUrl} size="small" />
        </S.RowFormField>
        <S.RowFormField>
          <label>완주기록증 이미지</label>
          <input
            name="medalImageUrl"
            type="file"
            onChange={handleImageChange}
          />
          <Image src={race.medalImageUrl} size="small" />
        </S.RowFormField>
      </S.RowContainer>
      <RaceThumbnailPreview />

      <RegisterInfoDescription race={race} setRace={setRace} />
      <Divider hidden />

      <ProductDetailImageField race={race} setRace={setRace} />
      <Divider hidden />

      <SponsorField
        race={race}
        setRace={setRace}
        imageFilters={imageFilters}
        setImageFilters={setImageFilters}
      />
      <Divider hidden />

      <StampField
        stampStickers={stampStickers}
        setStampStickers={setStampStickers}
      />
      <Divider hidden />

      <LeagueField race={race} setRace={setRace} />
      <Divider hidden />

      <RaceAuthenticationMethodField
        authenticationMethod={
          race.authenticationMethod ?? {
            title: '',
            description: '',
            image: undefined,
          }
        }
        handleChange={handleAuthenticationMethodChange}
        handleImageChange={handleAuthenticationMethodImageChange}
      />

      <ShareField
        info={race.info}
        handleChange={handleInfoChange}
        handleImageChange={handleInfoImageChange}
      />
      <Divider hidden />

      <ReviewField race={race} setRace={setRace} />
      <Divider hidden />

      <ExtraInfoField race={race} setRace={setRace} />
      <Divider hidden />

      {race.raceType === 'sleep' && (
        <>
          <PromiseField race={race} setRace={setRace} />
          <Divider hidden />
        </>
      )}

      <FilterField
        imageFilters={imageFilters}
        setImageFilters={setImageFilters}
      />
      <Divider hidden />

      <ProgressInfoDescription race={race} setRace={setRace} />
      <Divider hidden />

      <HomeDescription race={race} setRace={setRace} />
      <Divider hidden />

      <h3>유저 필터 목록</h3>
      <UserFilterSelector
        filterId={race.userFilter?.id}
        upward
        onChange={handleChangeUserFilterId}
      />
      <Divider hidden style={{ marginTop: 40 }} />

      <Divider hidden style={{ marginTop: 40, paddingBottom: 200 }} />

      <div
        style={{
          width: '80%',
          left: '10%',
          bottom: 10,
          position: 'fixed',
          borderTop: '1px solid #f6f6f6',
          backgroundColor: 'white',
          zIndex: 100,
          display: 'flex',
          justifyContent: 'center',
          height: 60,
        }}
      >
        <Button type="submit" fluid onClick={_submit}>
          제출하기
        </Button>
      </div>
    </Form>
  );
};

export default RaceForm;
