import { useQuery } from '@tanstack/react-query';
import { apis } from '../../api';
import { getQueryKey } from './getQueryKey';

export const useGetTags = () => {
  return useQuery({
    queryKey: getQueryKey('GET_TAGS'),
    queryFn: () => apis.challenge.getTags({}),
  });
};
