import {
  ChallengeCustomField,
  ChallengeNaverIdRequiredCustomField,
} from 'src/types/customField.types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'naverIdRequired'>,
  Extract<ChallengeCustomField, { name: 'naverIdRequired' }>
> = ({ naverIdRequired }) => {
  if (!naverIdRequired) return [];

  return [
    {
      name: 'naverIdRequired',
      values: naverIdRequired,
    },
  ];
};

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'naverIdRequired'>
> = (customFields) => {
  const naverIdRequired = customFields.find(
    ({ name }) => name === 'naverIdRequired',
  );

  return {
    naverIdRequired: Boolean(
      naverIdRequired?.values as
        | ChallengeNaverIdRequiredCustomField
        | undefined,
    ),
  };
};
