import _ from 'lodash';
import React, { useEffect } from 'react';
import { Table, Grid, Button } from 'semantic-ui-react';
// @ts-ignore
import { Workbook } from 'react-excel-workbook';
import moment from 'moment';
import { Race } from '@types';
import { flatRaceProductGroup } from '@utils/race';

type ExcelData = {
  optionId: number;
  optionName: string;
  optionPrice: string;
  optionShippingFee: string;
  isAddressNeeded: string;
  productId: string;
  productName: string;
  mappingName: string;
  totalCount: string;
  remainCount: string;
};

const RaceOptionTable = ({
  race,
  productPackages,
}: {
  race: Race.RaceAllInfoForAdmin;
  productPackages?: Race.ReqCreateOrUpdateProductPackage[];
}) => {
  const [excelData, setExcelData] = React.useState<ExcelData[]>([]);

  useEffect(() => {
    const _excelData: ExcelData[] = [];
    productPackages?.forEach((option) => {
      const flatProducts = flatRaceProductGroup(option.products[0]);

      const item = {
        optionId: option.id!,
        optionName: option.name,
        optionPrice: option.price.toLocaleString(),
        optionShippingFee: option.shippingFee.toLocaleString(),
        isAddressNeeded: option.isAddressNeeded ? '필수' : '-',
      };

      if (flatProducts.length > 0) {
        flatProducts.forEach((p) => {
          const mappingName = getMappingName(race, option, p);
          _excelData.push({
            ...item,
            productId: String(p.id),
            productName: `${p.name} ${p.subName}`,
            mappingName,
            totalCount: String(p.totalCount),
            remainCount: String(p.remainCount),
          });
        });
      } else {
        _excelData.push({
          ...item,
          productId: '-',
          productName: '-',
          mappingName: '-',
          totalCount: '-',
          remainCount: '-',
        });
      }
    });
    setExcelData(_excelData);
  }, [productPackages && productPackages.length]);

  return (
    <>
      <h3>상품 판매 목록</h3>
      <Grid columns={2}>
        <Grid.Column width={12}>
          <Grid.Row>
            <Workbook
              filename={`${moment().format('YYYYMMDD')}_${
                race.title
              }_상품판매목록목록.xlsx`}
              element={
                <Button basic color="green" size="mini">
                  다운로드
                </Button>
              }
            >
              <Workbook.Sheet data={excelData} name="주문 목록">
                <Workbook.Column
                  label="옵션id"
                  value={(o: ExcelData) => o.optionId}
                />
                <Workbook.Column
                  label="옵션명"
                  value={(o: ExcelData) => o.optionName}
                />
                <Workbook.Column
                  label="가격"
                  value={(o: ExcelData) => o.optionPrice}
                />
                <Workbook.Column
                  label="배송비"
                  value={(o: ExcelData) => o.optionShippingFee}
                />
                <Workbook.Column
                  label="주소지 필수여부"
                  value={(o: ExcelData) => o.isAddressNeeded}
                />
                <Workbook.Column
                  label="상품id"
                  value={(o: ExcelData) => o.productId}
                />
                <Workbook.Column
                  label="상품명"
                  value={(o: ExcelData) => o.productName}
                />
                <Workbook.Column
                  label="매핑이름"
                  value={(o: ExcelData) => o.mappingName}
                />
                <Workbook.Column
                  label="전체개수"
                  value={(o: ExcelData) => o.totalCount}
                />
                <Workbook.Column
                  label="남은개수"
                  value={(o: ExcelData) => o.remainCount}
                />
              </Workbook.Sheet>
            </Workbook>
          </Grid.Row>
          <h3>상품 옵션별 정보</h3>
          <Grid.Row>
            <div style={{ maxHeight: 500, overflowY: 'auto' }}>
              <Table basic="very" size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>썸네일</Table.HeaderCell>
                    <Table.HeaderCell>옵션id</Table.HeaderCell>
                    <Table.HeaderCell>옵션명</Table.HeaderCell>
                    <Table.HeaderCell>가격</Table.HeaderCell>
                    <Table.HeaderCell>배송비</Table.HeaderCell>
                    <Table.HeaderCell>주소지 필요여부</Table.HeaderCell>
                    <Table.HeaderCell>상품id</Table.HeaderCell>
                    <Table.HeaderCell>상품명</Table.HeaderCell>
                    <Table.HeaderCell>매핑 이름</Table.HeaderCell>
                    <Table.HeaderCell>전체개수</Table.HeaderCell>
                    <Table.HeaderCell>남은개수</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {productPackages?.map((productPackage) => {
                    const flatProducts = flatRaceProductGroup(
                      productPackage.products[0],
                    );
                    if (flatProducts.length > 0) {
                      return flatProducts?.map((product, idx) => {
                        const mappingName = getMappingName(
                          race,
                          productPackage,
                          product,
                        );
                        return (
                          <Table.Row key={`${productPackage.id}-${product.id}`}>
                            {idx === 0 ? (
                              <Table.Cell
                                rowSpan={productPackage.products.length}
                                verticalAlign="top"
                              >
                                <img
                                  src={
                                    productPackage.thumbnailUrl
                                      ? productPackage.thumbnailUrl
                                      : '/svgs/no-image.svg'
                                  }
                                  style={{
                                    width: 50,
                                    height: 50,
                                    objectFit: 'cover',
                                    marginRight: 10,
                                  }}
                                  alt={product.name}
                                />
                              </Table.Cell>
                            ) : (
                              <Table.Cell> </Table.Cell>
                            )}
                            <Table.Cell>{productPackage.id}</Table.Cell>
                            <Table.Cell>{productPackage.name}</Table.Cell>
                            <Table.Cell>
                              {productPackage.price.toLocaleString()}
                            </Table.Cell>
                            <Table.Cell>
                              {productPackage.shippingFee}
                            </Table.Cell>
                            <Table.Cell>
                              {productPackage.isAddressNeeded ? '필수' : '-'}
                            </Table.Cell>
                            <Table.Cell>{product.id}</Table.Cell>
                            <Table.Cell>
                              {product.name} {product.subName}
                            </Table.Cell>
                            <Table.Cell>{mappingName}</Table.Cell>
                            <Table.Cell>{product.totalCount ?? 0}</Table.Cell>
                            <Table.Cell>{product.remainCount ?? 0}</Table.Cell>
                          </Table.Row>
                        );
                      });
                    }
                    return (
                      <Table.Row key={productPackage.id}>
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>{productPackage.id}</Table.Cell>
                        <Table.Cell>{productPackage.name}</Table.Cell>
                        <Table.Cell>{productPackage.price}</Table.Cell>
                        <Table.Cell>{productPackage.shippingFee}</Table.Cell>
                        <Table.Cell>
                          {productPackage.isAddressNeeded ? '필수' : '-'}
                        </Table.Cell>
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={4}>
          <h3>패키지별 정보</h3>
          <Table basic="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>옵션명</Table.HeaderCell>
                <Table.HeaderCell>설정개수</Table.HeaderCell>
                <Table.HeaderCell>판매개수</Table.HeaderCell>
                <Table.HeaderCell>남은개수</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {productPackages?.map((productPackage) => {
                const flatProducts = flatRaceProductGroup(
                  productPackage.products[0],
                );
                let remainCount = 0;
                if (flatProducts.length > 0) {
                  remainCount = _.sumBy(flatProducts, 'remainCount');
                }

                return (
                  <Table.Row key={productPackage.id}>
                    <Table.Cell>{productPackage.name}</Table.Cell>
                    <Table.Cell>
                      {productPackage.totalCount !== 99999
                        ? productPackage.totalCount
                        : '-'}
                    </Table.Cell>
                    <Table.Cell>{productPackage.purchaseCount}</Table.Cell>
                    <Table.Cell>
                      {productPackage.products.length > 0 ? remainCount : '-'}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
              <Table.Row>
                <Table.Cell>Total</Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>{race.registerCount}</Table.Cell>
                <Table.Cell>-</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default RaceOptionTable;

const getMappingName = (
  race: Race.RaceAllInfoForAdmin,
  productPackage: Race.ReqCreateOrUpdateProductPackage,
  product: Race.FlatProduct,
) => {
  const mappingDefaultName =
    race.id >= 167
      ? `${race.title}_${productPackage.name}`
      : `${race.seasonTerm}_${race.title}_${race.sponsors.find(
          ({ type }) => type === 'main',
        )?.name}_${productPackage.name}_${productPackage.name}`;
  return `${mappingDefaultName}_${product.name}_${product.subName}`;
};
