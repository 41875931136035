import { DescriptionCollaboSpecialRewardModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { CollaboSpecialRewardModule } from '@chlngers/core/lib/src/types/challenge/challengeDescriptionModule.types';

export const convertData2Form: (
  module: CollaboSpecialRewardModule,
) => DescriptionCollaboSpecialRewardModuleFormType = ({ data }) => {
  return {
    type: 'COLLABO-SPECIAL-REWARD',
    data,
  };
};

export const convertForm2Data: (
  module: DescriptionCollaboSpecialRewardModuleFormType,
) => CollaboSpecialRewardModule = ({ data }) => {
  return {
    type: 'COLLABO-SPECIAL-REWARD',
    data,
  };
};
