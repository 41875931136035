import React, { useState } from 'react';
import { Button, Form, Label } from 'semantic-ui-react';
import { CollaboProductOption } from '../../../../Settlement.types';
import { apis } from '../../../../../../../api';
import { useCollaboSettlementProductList } from '../../../../../../../queries/Challenge';

type TemplateFormProps = {
  options: CollaboProductOption[];
};

const TemplateForm: React.FC<TemplateFormProps> = ({ options }) => {
  const [targetValue, setTargetValue] = useState<number>(0);
  const { refetch } = useCollaboSettlementProductList();

  const handleEditPrice = async (option: CollaboProductOption) => {
    if (window.confirm('정말로 수정하시겠습니까?')) {
      await apis.editCollaboProductPrice(option.id, {
        price: targetValue,
      });
      alert('수정 완료!');
      refetch();
    }
  };

  return (
    <Form.Field>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 12,
        }}
      >
        {options.map((option) => {
          return (
            <Form.Group key={option.id}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                }}
              >
                <Label>{option.optionName}</Label>
                <Form.Input
                  name={option.optionName}
                  defaultValue={option.price}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTargetValue(Number(event.target.value));
                  }}
                />
                <Button
                  basic
                  size="small"
                  color="blue"
                  onClick={() => handleEditPrice(option)}
                >
                  수정
                </Button>
              </div>
            </Form.Group>
          );
        })}
      </div>
    </Form.Field>
  );
};

export default TemplateForm;
