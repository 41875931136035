import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { REPORT_REASON_MAP } from '../Achievement.constants';
import { ReportedAchievement } from 'src/types/achievement.types';

const ReportReasonAccordion = ({
  achievement,
  defaultCollapsed,
}: ReportUserModalProps) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const handleClick = useCallback(() => setCollapsed((prev) => !prev), []);

  useEffect(() => setCollapsed(defaultCollapsed), [defaultCollapsed]);

  return (
    <Accordion>
      <Accordion.Title active={collapsed} onClick={handleClick}>
        <h5 style={{ marginTop: 0, marginBottom: 0 }}>
          <Icon name="dropdown" />
          신고 사유 보기 ({achievement.reportUsers.length}건)
        </h5>
      </Accordion.Title>
      <Accordion.Content active={collapsed}>
        <div style={{ marginLeft: 12 }}>
          {achievement.reportUsers.map((o) => {
            return (
              <div key={o.user.id} style={{ marginBottom: 4 }}>
                [{o.user.id}] {REPORT_REASON_MAP[o.reportReason]} 신고
                <br />
                {o.comment}
                <br />
              </div>
            );
          })}
        </div>
      </Accordion.Content>
    </Accordion>
  );
};

export default ReportReasonAccordion;

type ReportUserModalProps = {
  achievement: ReportedAchievement;
  defaultCollapsed: boolean;
};
