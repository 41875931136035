import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'isRegisterDepositFixed'>,
  Pick<ReqCreateOrUpdateChallenge, 'isRegisterDepositFixed'>
> = ({ isRegisterDepositFixed }) => ({ isRegisterDepositFixed });

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'isRegisterDepositFixed'>,
  Pick<ChallengeFormType, 'isRegisterDepositFixed'>
> = ({ isRegisterDepositFixed }) => ({ isRegisterDepositFixed });
