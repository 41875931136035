import { useQuery } from '@tanstack/react-query';
import { apis } from '../../api';
import { getQueryKey } from './getQueryKey';

export const useGetGoalDetail = (goalId: number | 0) => {
  return useQuery({
    queryKey: getQueryKey('GET_GOAL_DETAIL', goalId),
    queryFn: () => apis.challenge.getGoalDetail(goalId),
    enabled: !!goalId,
    select: (res) => res.goal,
  });
};
