import React, { ChangeEvent, FC, Suspense, useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Input,
  Loader,
} from 'semantic-ui-react';
import { format } from 'date-fns';
import { InputOnChangeData } from 'semantic-ui-react/dist/commonjs/elements/Input/Input';
import BenefitMenu from '../../BenefitMenu';
import * as S from './PedometerGiftiShopBuyers.styles';
import { Item, Payment } from './PedometerGiftiShopBuyers.types';
import { getFilterList, isPayment } from './PedometerGiftiShopBuyers.utils';
import { BenefitQueries } from '../../../../queries';
import ErrorBoundary from '../../../ErrorBoundaries/ErrorBoundary';
import { BuyerItem } from './components/BuyerItem/BuyerItem';
import { copyToClipboard } from '../../../../utils/js.utils';

const PedometerGiftiShopBuyers: FC = () => {
  const [date, setDate] = useState(() => format(new Date(), 'yyyy-MM-dd'));
  const [checkedPaymentIds, setCheckedPaymentIds] = useState<number[]>([]);
  const [filter, setFilter] = useState<Item['name']>('');

  const { data: buyers = [], isLoading } = BenefitQueries.useGiftiShopPayments(
    date,
    {
      // suspense: true,
      throwOnError: true,
      refetchOnMount: true,
    },
  );
  const { checkPaymentIds } = BenefitQueries.useGiftiShopPaymentsMutation();

  const handleChangeDate = useCallback(
    (_: ChangeEvent, { value }: InputOnChangeData) => {
      setCheckedPaymentIds([]);
      setFilter('');
      setDate(value);
    },
    [],
  );

  const filteredBuyers: Payment[] = filter
    ? buyers.filter((v) => v.itemInfo.name === filter)
    : buyers;

  const allChecked = filteredBuyers
    .filter((v) => !v.isReceived && !!v.userInfo)
    .every((w) => checkedPaymentIds.includes(w.id));

  const filterList = getFilterList(buyers);

  const handleCheckIds = async () => {
    const agree = window.confirm(
      `정말로 ${checkedPaymentIds.length}개 항목을 일괄 처리 하시겠어요?\n이 동작은 다시 취소할 수 없어요`,
    );
    if (!agree) {
      window.alert('취소 했어요');
      return;
    }
    const res = await checkPaymentIds(date, checkedPaymentIds);
    if (!res) return;
    window.alert(`${checkedPaymentIds.length}개 처리 완료 ✌️`);
    setCheckedPaymentIds([]);
  };

  const handleCopyPhoneNumbers = async () => {
    const phones = checkedPaymentIds
      .map(
        (id) => filteredBuyers.find((v) => v.id === id)?.userInfo?.phoneNumber,
      )
      .filter((v) => !!v);

    const isAllCopied = checkedPaymentIds.length === phones.length;

    const phoneNumberValid = phones.every((v) => v?.length === 11);

    copyToClipboard(phones.join('\n')).then(() => {
      if (isAllCopied) window.alert('👍 전체 복사 완료');
      else
        window.alert(
          `🚨 ${
            checkedPaymentIds.length - phones.length
          }개 누락된 핸드폰 번호가 있어요.
${
  phoneNumberValid
    ? '👍 폰번호 모두 정상'
    : '🚨 이상한 핸드폰 번호가 포함되어 있어요‼️'
}`,
        );
    });
  };

  const handleClickItemCheck = useCallback((payment: Payment) => {
    if (payment.isReceived) return;
    if (!payment.userInfo) return;

    setCheckedPaymentIds((prev) =>
      prev.includes(payment.id)
        ? prev.filter((i) => i !== payment.id)
        : [...prev, payment.id],
    );
  }, []);

  const ErrorFallback = useCallback((errorFallbackProps: { error: Error }) => {
    const { error } = errorFallbackProps;
    return <div>{error.message}</div>;
  }, []);

  return (
    <div style={{ margin: 40 }}>
      <Grid columns="equal">
        <Grid.Column width={2}>
          <BenefitMenu />
        </Grid.Column>
        <Grid.Column>
          <Grid.Row style={{ paddingBottom: 20 }}>
            <h1>기프티샵 구매자 목록</h1>
          </Grid.Row>
          <Grid.Row style={{ paddingBottom: 20 }}>
            <Form.Field>
              <label>날짜</label>
              <Input
                type="date"
                name="date"
                value={date}
                style={{ marginLeft: 10 }}
                onChange={handleChangeDate}
              />
            </Form.Field>
          </Grid.Row>

          <S.Item isHeader isReceived={false}>
            <Checkbox
              checked={allChecked}
              onClick={() => {
                if (allChecked) {
                  setCheckedPaymentIds([]);
                  return;
                }
                setCheckedPaymentIds(
                  filteredBuyers
                    .filter((v) => !v.isReceived && !!v.userInfo)
                    .map((v) => v.id),
                );
              }}
            />
            <S.Row>구매 ID</S.Row>
            <S.Row>이름</S.Row>
            <S.Row>
              상품
              <Dropdown
                selection
                placeholder="필터"
                style={{ backgroundColor: '#eeeeee', marginLeft: 8 }}
                options={filterList}
                value={filter || ''}
                onChange={(e, { value }) => {
                  setFilter(value as string);
                  if (!value) return;
                  setCheckedPaymentIds((prev) => {
                    const payments = prev
                      .map((v) => buyers.find((buyer) => buyer.id === v))
                      .filter((v) => isPayment(v)) as Payment[];
                    return payments
                      .filter((payment) => payment.itemInfo.name === value)
                      .map((v) => v.id);
                  });
                }}
              />
            </S.Row>

            <S.Row>핸드폰</S.Row>
            <S.Row>유저 ID</S.Row>
          </S.Item>
          <Suspense fallback={<Loader />}>
            <ErrorBoundary fallback={ErrorFallback}>
              {!isLoading && filteredBuyers.length === 0 && (
                <S.Row style={{ padding: 40, color: '#aaaaaa', fontSize: 40 }}>
                  <div>구매자가 없어요 🤓😛</div>
                </S.Row>
              )}

              {filteredBuyers.map((buyer) => (
                <BuyerItem
                  key={buyer.id}
                  checked={checkedPaymentIds.includes(buyer.id)}
                  onClick={() => handleClickItemCheck(buyer)}
                  id={buyer.id}
                  name={buyer.userInfo?.name}
                  product={buyer.itemInfo.name}
                  userId={buyer.userInfo?.id}
                  phone={buyer.userInfo?.phoneNumber}
                  isReceived={buyer.isReceived || !buyer.userInfo}
                  disabled={!buyer.userInfo}
                />
              ))}
            </ErrorBoundary>
          </Suspense>
          <S.Row style={{ padding: 20 }}>
            <Button
              color="black"
              disabled={!checkedPaymentIds.length}
              content={
                checkedPaymentIds.length
                  ? `${checkedPaymentIds.length}개 항목 지급처리👏`
                  : '항목을 선택 해주세요🙏'
              }
              size="large"
              onClick={handleCheckIds}
            />
            <Button
              color="facebook"
              disabled={!checkedPaymentIds.length}
              content={
                checkedPaymentIds.length
                  ? `${checkedPaymentIds.length}개 핸드폰 번호 복사`
                  : '항목을 선택 해주세요🙏'
              }
              size="large"
              onClick={handleCopyPhoneNumbers}
            />
          </S.Row>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default PedometerGiftiShopBuyers;
