import FlexBox from '@component/FlexBox/FlexBox';
import { ChallengeCustomField } from '@types';
import { useEffect } from 'react';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { useIsCustomGoal } from '../../hooks/useIsCustomGoal';
import {
  convertData2Form,
  convertForm2Data,
} from './AchievementInfoListForm.serializer';
import { useUpdateAchievementInfoList } from './AchievementInfoListForm.store';
import { validate } from './AchievementInfoListForm.validator';
import AchivementInfoListSetting from './components/AchivementInfoListSetting/AchivementInfoListSetting';
import { NoCustomGoalView } from './components/NoCustomGoalView';

export const AchievementInfoListForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'achievementInfoList'>,
  Extract<ChallengeCustomField, { name: 'achievementInfoList' }>
> = () => {
  const { isCustomGoal, goal } = useIsCustomGoal();
  const { update } = useUpdateAchievementInfoList();

  useEffect(() => {
    if (!isCustomGoal) update([]);
  }, [isCustomGoal, update]);

  return (
    <FlexBox.Column gap={0}>
      <h3>챌린지 인증회차별 정보 설정</h3>
      {isCustomGoal ? (
        <AchivementInfoListSetting />
      ) : (
        <NoCustomGoalView goal={goal} />
      )}
    </FlexBox.Column>
  );
};

AchievementInfoListForm.validate = validate;
AchievementInfoListForm.convertData2Form = convertData2Form;
AchievementInfoListForm.convertForm2Data = convertForm2Data;
