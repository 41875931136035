import {
  DescriptionFullImageModuleFormType,
  DescriptionModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const validate = (
  descriptionModule: DescriptionModuleFormType[],
): { isValid: true } | { isValid: false; message: string } => {
  const target = descriptionModule.find(({ type }) => type === 'FULL-IMAGE');
  if (!target) return { isValid: true };

  const { imageList } = target as DescriptionFullImageModuleFormType;

  if (!Array.isArray(imageList)) {
    return {
      isValid: false,
      message: '[상세모듈] FULL-IMAGE 모듈을 확인해주세요',
    };
  }

  if (!imageList.length)
    return {
      isValid: false,
      message: '[상세모듈] FULL-IMAGE 모듈의 이미지를 등록해주세요.',
    };
  return { isValid: true };
};
