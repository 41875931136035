import React, { FC, useRef } from 'react';
import { uploadSingleFileToS3 } from '@utils/uploadImage';
import Label from '@component/Label';
import FlexBox from '@component/FlexBox/FlexBox';
import Button from '@component/Button';
import { ModuleContainer } from '../../../../DescriptionModuleForm.style';

type Props = {
  exampleImageUrls: string[];
  onChange: (exampleImageUrls: string[]) => void;
};
const ExampleImagesForm: FC<Props> = ({ exampleImageUrls, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleAddItem = () => {
    inputRef.current?.click();
  };

  const handleChangeImgInput: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e) => {
    const files = e.target.files ? Array.from(e.target.files) : [];

    const urls = await Promise.all(files.map((f) => uploadSingleFileToS3(f)));
    onChange([...exampleImageUrls, ...urls]);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };
  const handleRemoveItem = (index: number) => {
    onChange(exampleImageUrls.filter((_, i) => i !== index));
  };

  return (
    <ModuleContainer>
      <Label size="large" style={{ marginTop: 20, marginBottom: 6 }}>
        앱 내 인증 예시 이미지
      </Label>
      <Button text="추가" onClick={handleAddItem} />
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        multiple
        onChange={handleChangeImgInput}
      />
      <FlexBox.Row gap={12}>
        {exampleImageUrls.map((url, index) => (
          <FlexBox.Column key={url} style={{ width: 120 }}>
            <img
              src={url}
              style={{ width: '100%', aspectRatio: 1, margin: '6px 0px' }}
              alt={`앱 내 인증 예시 이미지 ${index}`}
            />
            <Button text="삭제" onClick={() => handleRemoveItem(index)} />
          </FlexBox.Column>
        ))}
      </FlexBox.Row>
    </ModuleContainer>
  );
};

export default ExampleImagesForm;
