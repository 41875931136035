export const OLIVEYOUNG_MEMBERSHIP = ['baby', 'pink', 'green', 'black', 'gold'];

export const SKIN_CATEGORIES = [
  '지성',
  '건성',
  '복합성',
  '민감성',
  '약건성',
  '트러블성',
  '중성',
];

export const SKIN_TONES = [
  '쿨톤',
  '웜톤',
  '봄웜톤',
  '여름쿨톤',
  '가을웜톤',
  '겨울쿨톤',
];

export const SKIN_CONCERNS = [
  '잡티',
  '미백',
  '주름',
  '각질',
  '트러블',
  '블랙헤드',
  '피지과다',
  '민감성',
  '모공',
  '탄력',
  '홍조',
  '아토피',
  '다크서클',
];
