import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { getQueryKey } from './getQueryKey';
import { apis } from '../../api';
import {
  getNextPageParam,
  withTypedInfiniteLoad,
} from '../../utils/query.utils';

const useGetCouponList = ({
  keyword = '',
  ids,
}: {
  keyword?: string;
  ids?: number[];
}) => {
  return useInfiniteQuery({
    queryKey: getQueryKey('COUPON_LIST', { keyword, ids }),
    queryFn: withTypedInfiniteLoad(
      apis.race.getCouponList,
      { keyword, ids },
      ids?.length || 40,
    ),
    initialPageParam: 0,
    getNextPageParam,
    placeholderData: keepPreviousData,
  });
};

export default useGetCouponList;
