import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px solid transparent;
  font-weight: bold;
  min-width: 80px;
  cursor: pointer;
  &:active {
    filter: brightness(90%);
  }
`;
