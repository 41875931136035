import FlexBox from '@component/FlexBox/FlexBox';
import ImageInput from '@component/ImageInput';
import Label from '@component/Label';
import { DescriptionModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { Image } from 'semantic-ui-react';
import { cropAndUploadFile } from '@utils/uploadImage';
import {
  DescriptionModule,
  DescriptionModuleConvertData2Form,
  DescriptionModuleConvertForm2Data,
} from '../../DescriptionModuleForm.type';
import {
  convertData2Form,
  convertForm2Data,
} from './FullImageModule.serializer';
import { validate } from './FullImageModule.validator';

type FullImageModuleProps = {
  type: 'FULL-IMAGE';
  imageList: string[];
  moduleIndex: number;
  onUpdate: (index: number, item: DescriptionModuleFormType) => void;
};

const FullImageModule: DescriptionModule<FullImageModuleProps> = (
  props: FullImageModuleProps,
) => {
  const { type, imageList, moduleIndex, onUpdate } = props;
  const handleFullImageChange = async (e: File | undefined) => {
    const urls = await cropAndUploadFile(`image-${e?.name}`, e);
    return onUpdate(moduleIndex, {
      type,
      imageList: urls,
    });
  };

  return (
    <>
      <Label>이미지 섹션</Label>
      <ImageInput onChange={handleFullImageChange} />

      <FlexBox.Row alignItems="flex-start" justifyContent="flex-start">
        {imageList.map((url) => (
          <div key={url}>
            <Image src={url} size="small" />
          </div>
        ))}
      </FlexBox.Row>
    </>
  );
};

FullImageModule.validate = validate;
FullImageModule.convertForm2Data =
  convertForm2Data as DescriptionModuleConvertForm2Data;
FullImageModule.convertData2Form =
  convertData2Form as DescriptionModuleConvertData2Form;
FullImageModule.moduleName = '이미지 섹션';
FullImageModule.defaultData = {
  type: 'FULL-IMAGE',
  imageList: [],
};

export default FullImageModule;
