import React from 'react';
import { Image, Label } from 'semantic-ui-react';
import { ColorSample } from '@component/ColorSample';
import {
  cropAndUploadFile,
  handleChangeSingleFile,
} from '../../../../../utils/uploadImage';
import * as S from '../../../Style';

import { RacePromiseForForm } from 'src/types/race.types';
import _ from 'lodash';
import { RaceFormType } from '../../RaceForm.types';

type Props = {
  race: RaceFormType;
  setRace: React.Dispatch<React.SetStateAction<RaceFormType>>;
};

const PromiseField = ({ race, setRace }: Props) => {
  const handleChange: <K extends keyof RacePromiseForForm>(
    key: K,
    value: RacePromiseForForm[K],
  ) => void = (key, value) => {
    const _race = _.cloneDeep(race);
    _race.info.promise[key] = value;
    setRace(_race);
  };

  const handleBgImageChange: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e) => {
    const _race = _.cloneDeep(race);
    const { bgImageUrl } = await handleChangeSingleFile(e, {});
    _race.info.promise.bgImageUrl = bgImageUrl;
    setRace(_race);
  };

  const handleMultiImageChange: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e) => {
    const _race = _.cloneDeep(race);
    const { files } = e.target;

    if (files === null) return;

    const urls = await cropAndUploadFile('eventImageUrls', files[0]);
    _race.info.promise.eventImageUrls = urls;

    setRace(_race);
  };

  const deleteEventImage = async (idx: number) => {
    const _race = _.cloneDeep(race);
    const _urls = [..._race.info.promise.eventImageUrls];
    _urls.splice(idx, 1);
    _race.info.promise.eventImageUrls = _urls;
    setRace(_race);
  };

  return (
    <>
      <h3>각오 설정</h3>
      <S.RowContainer>
        <S.RowFormField>
          <label>배경 이미지</label>
          <input type="file" name="bgImageUrl" onChange={handleBgImageChange} />
          <Image src={race.info.promise.bgImageUrl} size="small" />
        </S.RowFormField>
        <S.RowFormField margin={'0 0 0 10px'}>
          <label>각오 기본 문구</label>
          <input
            value={race.info.promise.defaultPromise}
            onChange={(e) => handleChange('defaultPromise', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField margin={'0 0 0 10px'}>
          <label>문구 색상</label>
          <input
            value={race.info.promise.textColor}
            onChange={(e) => handleChange('textColor', e.target.value)}
          />
          <ColorSample color={race.info.promise.textColor} />
        </S.RowFormField>
        <S.RowFormField margin={'0 0 0 10px'}>
          <label>이벤트 이미지</label>
          <input
            type="file"
            name="eventImageUrls"
            onChange={handleMultiImageChange}
          />
          {race.info.promise.eventImageUrls.map((url, idx) => (
            <S.RowContainer key={url}>
              <Image size={'small'} src={url} />
              <div>
                <Label
                  style={{ cursor: 'pointer' }}
                  onClick={() => deleteEventImage(idx)}
                  content={'삭제'}
                />
              </div>
            </S.RowContainer>
          ))}
        </S.RowFormField>
      </S.RowContainer>
    </>
  );
};

export default PromiseField;
