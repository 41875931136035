import { convertWonUnit } from '@utils/common.utils';
import { MAX_REGISTER_DEPOSIT } from '../../NewChallengeForm.constants';
import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<
  ChallengeFormType,
  'maxRegisterDeposit' | 'minRegisterDeposit'
>;

export const validate: FormValidator<Params> = ({
  maxRegisterDeposit,
  minRegisterDeposit,
}) => {
  if (maxRegisterDeposit < minRegisterDeposit) {
    return {
      isValid: false,
      message: '최대 참가비는 최소 참가비보다 작을 수 없습니다.',
    };
  }

  if (maxRegisterDeposit > MAX_REGISTER_DEPOSIT) {
    return {
      isValid: false,
      message: `최대 참가비는 ${convertWonUnit(
        MAX_REGISTER_DEPOSIT,
      )}만원을 넘을 수 없습니다.`,
    };
  }

  return { isValid: true };
};
