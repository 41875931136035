import {
  ChallengeAchievementMissionCustomField,
  ChallengeCustomField,
} from '@types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'achievementMission'>
> = (customFields) => {
  const achievementMission = customFields.find(
    (c) => c.name === 'achievementMission',
  );

  if (!achievementMission) return {} as EmptyObj;

  const { values } =
    achievementMission as ChallengeAchievementMissionCustomField;

  return { achievementMission: values };
};

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'achievementMission'>,
  Extract<ChallengeCustomField, { name: 'achievementMission' }>
> = ({ achievementMission }) => {
  if (achievementMission.length === 0) return [];

  return [
    {
      name: 'achievementMission',
      values: achievementMission,
    },
  ];
};
