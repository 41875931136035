import { FC, FormEvent, useEffect, useState } from 'react';
import { Container, Table, Checkbox, Button } from 'semantic-ui-react';
import moment from 'moment';
import 'moment/locale/ko';
import { Link } from 'react-router-dom';
import setCurrencyFormat from '../../../global/setCurrencyFormat';
import _ from 'lodash';
// @ts-ignore
import Workbook from 'react-excel-workbook';
import { apis } from '../../../api';
import { ChallengeInfoForAdmin } from '@types';

type Props = {
  challenges: ChallengeInfoForAdmin[];
  delete: (challengeIds: number[]) => void;
  copy: (challenges: ChallengeInfoForAdmin[], challengeIds: number[]) => void;
  public: (challengeIds: number[]) => void;
  checkedIds: number[];
  setCheckedIds: (checkedIds: number[]) => void;
};

const ChallengeItemTable: FC<Props> = ({
  challenges: _challenges,
  delete: _delete,
  copy,
  public: _public,
  checkedIds,
  setCheckedIds,
}) => {
  const [challenges, setChallenges] =
    useState<ChallengeInfoForAdmin[]>(_challenges);
  const [column, setColumn] = useState<string | null>(null);
  const [direction, setDirection] = useState<
    'ascending' | 'descending' | undefined
  >(undefined);

  useEffect(() => {
    const propsChallengeIds = challenges
      .map((c: ChallengeInfoForAdmin) => c.id)
      .sort();
    const stateChallengeIds = challenges
      .map((c: ChallengeInfoForAdmin) => c.id)
      .sort();

    const propsChallengeDeletes = challenges
      .map((c: ChallengeInfoForAdmin) => c.isDeleted)
      .sort();
    const stateChallengeDeletes = challenges
      .map((c: ChallengeInfoForAdmin) => c.isDeleted)
      .sort();
    const propsChallengePublics = challenges
      .map((c: ChallengeInfoForAdmin) => c.isPublic)
      .sort();
    const stateChallengePublics = challenges
      .map((c: ChallengeInfoForAdmin) => c.isPublic)
      .sort();
    if (
      _.isEqual(propsChallengeIds, stateChallengeIds) &&
      _.isEqual(propsChallengeDeletes, stateChallengeDeletes) &&
      _.isEqual(propsChallengePublics, stateChallengePublics)
    ) {
      return;
    }
    const _direction = direction === 'ascending' ? 'asc' : 'desc';
    setChallenges(
      _.orderBy(challenges, [column], [_direction]) as ChallengeInfoForAdmin[],
    );
  }, [challenges]);

  const handleSort = (clickedColumn: string) => () => {
    if (column !== clickedColumn) {
      const newChallenges = _.sortBy(challenges, [clickedColumn]);
      setColumn(clickedColumn);
      setChallenges(newChallenges as ChallengeInfoForAdmin[]);
      setDirection('ascending');
      return;
    }

    setChallenges(challenges.reverse());
    setDirection(direction === 'ascending' ? 'descending' : 'ascending');
  };

  const handleChange = async (
    e: FormEvent<HTMLInputElement>,
    {
      value,
    }: {
      value?: string | number | undefined;
    },
  ) => {
    if (!value) return;
    if (value === 'all') {
      if (challenges.length === checkedIds.length) {
        setCheckedIds([]);
      } else {
        setCheckedIds(challenges.map((c) => c.id));
      }
      return;
    }

    const _checkedIds = [...checkedIds];

    if (!_checkedIds.includes(value as number))
      _checkedIds.push(value as number);
    else if (_checkedIds.includes(value as number)) {
      const index = _checkedIds.indexOf(value as number);
      _checkedIds.splice(index, 1);
    }
    setCheckedIds(_checkedIds);
  };

  const deleteChallenges = async () => {
    await apis.deleteChallenges({ challengeIds: checkedIds });
    _delete(checkedIds);
    setCheckedIds([]);
    alert(`${checkedIds.length}개의 챌린지가 삭제되었어요.`);
  };

  const copyChallenges = async () => {
    const res = await apis.copyChallenges({ challengeIds: checkedIds });
    const { challenges: challenges_ } = res;
    copy(challenges_, checkedIds);
    setCheckedIds([]);
    if (
      window.confirm(
        `총 ${challenges_.length}개의 챌린지 복사가 완료되었어요. 챌린지 편집 페이지를 여시겠습니까?`,
      )
    ) {
      challenges_.forEach((c: ChallengeInfoForAdmin) => {
        const url = `${window.location.origin}/challenges/${c.id}/edit`;
        window.open(url, '_blank');
      });
    }
  };

  const publicChallenges = async () => {
    await apis.setChallengesPublic({ challengeIds: checkedIds });
    _public(checkedIds);
    setCheckedIds([]);
    alert(`${checkedIds.length}개의 챌린지가 공개 상태로 변경되었어요.`);
  };

  return (
    <Container style={{ width: 'calc(100% - 320px)' }}>
      <Button color="black" size="mini" onClick={deleteChallenges}>
        삭제
      </Button>
      <Button basic color="red" size="mini" onClick={publicChallenges}>
        공개
      </Button>
      <Button basic color="blue" size="mini" onClick={copyChallenges}>
        복사
      </Button>
      <Workbook
        filename={`${moment().format('YYMMDD')}_챌린지목록.xlsx`}
        element={
          <Button basic color="green" size="mini">
            Excel
          </Button>
        }
      >
        <Workbook.Sheet
          data={
            checkedIds.length > 0
              ? challenges.filter(({ id }) => checkedIds.includes(id))
              : challenges
          }
          name="Sheet A"
        >
          <Workbook.Column
            label="id"
            value={(c: ChallengeInfoForAdmin) => c.id}
          />
          <Workbook.Column
            label="목표"
            value={(c: ChallengeInfoForAdmin) =>
              `[${c.goal.id}]${c.goal.title}`
            }
          />
          <Workbook.Column
            label="주기당회수"
            value={(c: ChallengeInfoForAdmin) =>
              c.goal.totalAchieveCountPerCycle
            }
          />
          <Workbook.Column
            label="챌린지"
            value={(c: ChallengeInfoForAdmin) => c.title}
          />
          <Workbook.Column
            label="모집금액"
            value={(c: ChallengeInfoForAdmin) =>
              c.registerInfo.cardDepositSum +
              c.registerInfo.cashDepositSum +
              c.registerInfo.prizeDepositSum +
              c.registerInfo.transferDepositSum
            }
          />
          <Workbook.Column
            label="참가인원수"
            value={(c: ChallengeInfoForAdmin) => c.registerInfo.registerCount}
          />
          <Workbook.Column
            label="모집시작일"
            value={(c: ChallengeInfoForAdmin) =>
              moment(c.registerStartDate).format('YYYY-MM-DD')
            }
          />
          <Workbook.Column
            label="모집종료일"
            value={(c: ChallengeInfoForAdmin) =>
              moment(c.registerEndDate).format('YYYY-MM-DD')
            }
          />
          <Workbook.Column
            label="시작일"
            value={(c: ChallengeInfoForAdmin) =>
              moment(c.startDate).format('YYYY-MM-DD')
            }
          />
          <Workbook.Column
            label="종료일"
            value={(c: ChallengeInfoForAdmin) =>
              moment(c.endDate).format('YYYY-MM-DD')
            }
          />
          <Workbook.Column
            label="결과발표일"
            value={(c: ChallengeInfoForAdmin) =>
              moment(c.resultDate).format('YYYY-MM-DD')
            }
          />
          <Workbook.Column
            label="공개"
            value={(c: ChallengeInfoForAdmin) =>
              c.isPublic ? '공개' : '비공개'
            }
          />
          <Workbook.Column
            label="목표인원수"
            value={(c: ChallengeInfoForAdmin) => c.maxRegisterCount}
          />
          <Workbook.Column
            label="썸네일"
            value={(c: ChallengeInfoForAdmin) => c.thumbnailImageUrls.f}
          />
          <Workbook.Column
            label="태그"
            value={(c: ChallengeInfoForAdmin) =>
              c.tags?.map((o) => o.tag).join(', ')
            }
          />
          <Workbook.Column
            label="인증방법"
            value={(c: ChallengeInfoForAdmin) => c.authenticationMethod}
          />
          <Workbook.Column
            label="지원최소금액"
            value={(c: ChallengeInfoForAdmin) => c.minRegisterDeposit}
          />
          <Workbook.Column
            label="모집최소금액"
            value={(c: ChallengeInfoForAdmin) => c.minCollectDeposit}
          />
          <Workbook.Column
            label="삭제"
            value={(c: ChallengeInfoForAdmin) => (c.isDeleted ? '삭제' : '-')}
          />
        </Workbook.Sheet>
      </Workbook>
      <Container
        fluid
        style={{
          overflowX: 'auto',
          maxHeight: 750,
          marginTop: 20,
        }}
      >
        <Table basic="very" size="small" sortable>
          <Table.Header
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: 'white',
            }}
          >
            <Table.Row>
              <Table.HeaderCell>
                <Checkbox
                  name="all"
                  checked={checkedIds.length === challenges.length}
                  value="all"
                  onChange={handleChange}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>편집</Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'id' ? direction : undefined}
                onClick={handleSort('id')}
              >
                id
              </Table.HeaderCell>
              <Table.HeaderCell>챌린지명</Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'purchaseStartDate' ? direction : undefined}
                onClick={handleSort('purchaseStartDate')}
              >
                구매 시작일
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'purchaseEndDate' ? direction : undefined}
                onClick={handleSort('purchaseEndDate')}
              >
                구매 종료일
              </Table.HeaderCell>
              <Table.HeaderCell>목표</Table.HeaderCell>
              <Table.HeaderCell>회사명</Table.HeaderCell>
              <Table.HeaderCell>주기당회수</Table.HeaderCell>
              <Table.HeaderCell>삭제</Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'isPublic' ? direction : undefined}
                onClick={handleSort('isPublic')}
              >
                공개
              </Table.HeaderCell>
              <Table.HeaderCell>목표인원수</Table.HeaderCell>
              <Table.HeaderCell>모집금액</Table.HeaderCell>
              <Table.HeaderCell>참가인원수</Table.HeaderCell>
              <Table.HeaderCell>상금</Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'registerStartDate' ? direction : undefined}
                onClick={handleSort('registerStartDate')}
              >
                모집시작일
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'registerEndDate' ? direction : undefined}
                onClick={handleSort('registerEndDate')}
              >
                모집종료일
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'startDate' ? direction : undefined}
                onClick={handleSort('startDate')}
              >
                시작일
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'endDate' ? direction : undefined}
                onClick={handleSort('endDate')}
              >
                종료일
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'resultDate' ? direction : undefined}
                onClick={handleSort('resultDate')}
              >
                결과발표일
              </Table.HeaderCell>
              <Table.HeaderCell>지원최소금액</Table.HeaderCell>
              <Table.HeaderCell>지원최대금액</Table.HeaderCell>
              <Table.HeaderCell>유저id</Table.HeaderCell>
              <Table.HeaderCell>참가코드</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body
            style={{ overflowX: 'auto', maxHeight: 700, marginTop: 20 }}
          >
            {challenges.map((c: ChallengeInfoForAdmin) => {
              return (
                <Table.Row key={c.id}>
                  <Table.Cell collapsing>
                    <Checkbox
                      checked={checkedIds.includes(c.id)}
                      value={c.id}
                      onChange={handleChange}
                    />
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      size="mini"
                      as={Link}
                      to={{ pathname: `/challenges/${c.id}/edit` }}
                      target="_blank"
                      rel="noopener noreferrer"
                      content="편집"
                    />
                    <Button
                      size="mini"
                      as={Link}
                      to={{ pathname: `/challenges/${c.id}` }}
                      target="_blank"
                      rel="noopener noreferrer"
                      content="상세"
                    />
                  </Table.Cell>
                  <Table.Cell collapsing>{c.id}</Table.Cell>
                  <Table.Cell collapsing>{c.title}</Table.Cell>
                  <Table.Cell collapsing>
                    {c.purchaseStartDate
                      ? moment(c.purchaseStartDate).format('YY. MM. DD a hh:mm')
                      : '-'}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {c.purchaseEndDate
                      ? moment(c.purchaseEndDate).format('YY. MM. DD a hh:mm')
                      : '-'}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    [{c.goal.id}]{c.goal.title}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {c.company ? `[${c.company.id}]${c.company.name}` : '-'}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {c.goal.totalAchieveCountPerCycle}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {c.isDeleted ? '삭제' : '-'}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {c.isPublic ? '공개' : '비공개'}
                  </Table.Cell>
                  <Table.Cell collapsing>{c.maxRegisterCount}</Table.Cell>
                  <Table.Cell collapsing>
                    {setCurrencyFormat(
                      c.registerInfo.cardDepositSum +
                        c.registerInfo.cashDepositSum +
                        c.registerInfo.prizeDepositSum +
                        c.registerInfo.transferDepositSum,
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {setCurrencyFormat(c.registerInfo.registerCount)}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {setCurrencyFormat(c.result?.overHundredPrizeReward ?? 0)}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {moment(c.registerStartDate).format('YYYY-MM-DD')}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {moment(c.registerEndDate).format('YYYY-MM-DD')}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {moment(c.startDate).format('YYYY-MM-DD')}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {moment(c.endDate).format('YYYY-MM-DD')}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {moment(c.resultDate).format('YYYY-MM-DD')}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {setCurrencyFormat(c.minRegisterDeposit)}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {setCurrencyFormat(c.maxRegisterDeposit)}
                  </Table.Cell>
                  <Table.Cell collapsing>{c.userId}</Table.Cell>
                  <Table.Cell collapsing>
                    {c.password.length > 0 ? c.password : '-'}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Container>
    </Container>
  );
};

export default ChallengeItemTable;
