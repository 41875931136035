import React from 'react';
import { Grid } from 'semantic-ui-react';

const ChallengeCategoryHeader = (props) => {
  const {} = props;
  return (
    <Grid style={{ fontWeight: 700 }}>
      <Grid.Row>
        <Grid.Column width={1}>id</Grid.Column>
        <Grid.Column width={2}>랜딩타입</Grid.Column>
        <Grid.Column width={2}>카테고리 상세</Grid.Column>
        <Grid.Column width={2}>제목</Grid.Column>
        <Grid.Column width={2}>이미지</Grid.Column>
        <Grid.Column width={1}>시작일</Grid.Column>
        <Grid.Column width={1}>종료일</Grid.Column>
        <Grid.Column width={1}>공개방식</Grid.Column>
        <Grid.Column width={1}>노출</Grid.Column>
        <Grid.Column width={1}>만보기노출</Grid.Column>
        <Grid.Column width={1}>수정/복사/삭제</Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ChallengeCategoryHeader;
