import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';
import setCurrencyFormat from '../../../global/setCurrencyFormat';

const DailyStatistics = ({ dailyStatistics }) => {
  console.log(dailyStatistics);
  let registerList = [];
  let newRegisterList = [];
  let ab147RegisterList = [];
  let cancelList = [];
  let newCancelList = [];
  let resultList = [];
  let activationList = [];
  let customChallengeList = [];
  dailyStatistics.forEach((d, idx) => {
    registerList.push(
      <Table.Row key={idx}>
        <Table.Cell>{moment(d.date.S).format('YYYY-MM-DD')}</Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.userCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.challengeRegisterUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.cardRegisterUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.transferRegisterUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.challengeRegisterCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.cardRegisterCountDay.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.transferRegisterCountDay.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.challengeRegisterDeposit.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.netChallengeRegisterUserCount.S, 10))} (
          {setCurrencyFormat(d.netCardRegisterUserCount.S)}/
          {setCurrencyFormat(d.netTransferRegisterUserCount.S)})
        </Table.Cell>
      </Table.Row>,
    );
    newRegisterList.push(
      <Table.Row key={idx}>
        <Table.Cell>{moment(d.date.S).format('YYYY-MM-DD')}</Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.userCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.newChallengeRegisterUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.newCardRegisterUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.newTransferRegisterUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.newChallengeRegisterCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.newCardRegisterCountDay.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.newTransferRegisterCountDay.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.newChallengeRegisterDeposit.S, 10))}
        </Table.Cell>
      </Table.Row>,
    );
    if ('ab149UserCount' in d) {
      ab147RegisterList.push(
        <Table.Row key={idx}>
          <Table.Cell>{moment(d.date.S).format('YYYY-MM-DD')}</Table.Cell>
          {/*<Table.Cell>{setCurrencyFormat(parseInt(d.abUserCount.S, 10))}</Table.Cell>*/}
          <Table.Cell>
            {setCurrencyFormat(parseInt(d.ab147UserCount.S, 10))}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(
              parseInt(d.netNew147ChallengeRegisterUserCount.S, 10),
            )}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(
              parseInt(d.netNew147ChallengeCancelUserCount.S, 10),
            )}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(
              parseInt(d.newNew147ChallengeRegisterUserCount.S, 10),
            )}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(
              parseInt(d.newNew147ChallengeCancelUserCount.S, 10),
            )}
          </Table.Cell>
          <Table.Cell>
            {d.ab149UserCount
              ? setCurrencyFormat(parseInt(d.ab149UserCount.S, 10))
              : 0}
          </Table.Cell>
          <Table.Cell>
            {d.netNew149ChallengeRegisterUserCount
              ? setCurrencyFormat(
                  parseInt(d.netNew149ChallengeRegisterUserCount.S, 10),
                )
              : 0}
          </Table.Cell>
          <Table.Cell>
            {d.netNew149ChallengeCancelUserCount
              ? setCurrencyFormat(
                  parseInt(d.netNew149ChallengeCancelUserCount.S, 10),
                )
              : 0}
          </Table.Cell>
          <Table.Cell>
            {d.newNew149ChallengeRegisterUserCount
              ? setCurrencyFormat(
                  parseInt(d.newNew149ChallengeRegisterUserCount.S, 10),
                )
              : 0}
          </Table.Cell>
          <Table.Cell>
            {d.newNew149ChallengeCancelUserCount
              ? setCurrencyFormat(
                  parseInt(d.newNew149ChallengeCancelUserCount.S, 10),
                )
              : 0}
          </Table.Cell>
          <Table.Cell>
            {d.ab1414UserCount
              ? setCurrencyFormat(parseInt(d.ab1414UserCount.S, 10))
              : 0}
          </Table.Cell>
          <Table.Cell>
            {d.netNew1414ChallengeRegisterUserCount
              ? setCurrencyFormat(
                  parseInt(d.netNew1414ChallengeRegisterUserCount.S, 10),
                )
              : 0}
          </Table.Cell>
          <Table.Cell>
            {d.netNew1414ChallengeCancelUserCount
              ? setCurrencyFormat(
                  parseInt(d.netNew1414ChallengeCancelUserCount.S, 10),
                )
              : 0}
          </Table.Cell>
          <Table.Cell>
            {d.newNew1414ChallengeRegisterUserCount
              ? setCurrencyFormat(
                  parseInt(d.newNew1414ChallengeRegisterUserCount.S, 10),
                )
              : 0}
          </Table.Cell>
          <Table.Cell>
            {d.newNew1414ChallengeCancelUserCount
              ? setCurrencyFormat(
                  parseInt(d.newNew1414ChallengeCancelUserCount.S, 10),
                )
              : 0}
          </Table.Cell>
        </Table.Row>,
      );
    }
    cancelList.push(
      <Table.Row key={idx}>
        <Table.Cell>{moment(d.date.S).format('YYYY-MM-DD')}</Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.userCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.challengeCancelUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.cardCancelUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.transferCancelUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.challengeCancelCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.cardCancelCountDay.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.transferCancelCountDay.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.challengeCancelDeposit.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.netChallengeCancelUserCount.S, 10))} (
          {setCurrencyFormat(parseInt(d.netCardCancelUserCount.S, 10))}/
          {setCurrencyFormat(parseInt(d.netTransferCancelUserCount.S, 10))})
        </Table.Cell>
      </Table.Row>,
    );
    newCancelList.push(
      <Table.Row key={idx}>
        <Table.Cell>{moment(d.date.S).format('YYYY-MM-DD')}</Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.userCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.newChallengeCancelUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.newCardCancelUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.newTransferCancelUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.newChallengeCancelCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.newCardCancelCountDay.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.newTransferCancelCountDay.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.newChallengeCancelDeposit.S, 10))}
        </Table.Cell>
      </Table.Row>,
    );
    resultList.push(
      <Table.Row key={idx}>
        <Table.Cell>{moment(d.date.S).format('YYYY-MM-DD')}</Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.resultChallengeCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.resultUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.failUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.hundredCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.successCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.nonReturnDeposit.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.totalDeposit.S, 10))}
        </Table.Cell>
        <Table.Cell>{setCurrencyFormat(parseInt(d.margin.S, 10))}</Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.extraMargin.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.cardWithdrawUserCount.S, 10))}
          <br />/
          {setCurrencyFormat(parseInt(d.transferWithdrawUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.cardWithdrawAmount.S, 10))}
          <br />/{setCurrencyFormat(parseInt(d.transferWithdrawAmount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.specialPrize.S, 10))}
        </Table.Cell>
      </Table.Row>,
    );
    activationList.push(
      <Table.Row key={idx}>
        <Table.Cell>{moment(d.date.S).format('YYYY-MM-DD')}</Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.userCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.deletedUserCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.challengeInterestCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.achievementFiles.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.likeCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.commentCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {setCurrencyFormat(parseInt(d.followCount.S, 10))}
        </Table.Cell>
        <Table.Cell>
          {d.reporterCount
            ? setCurrencyFormat(parseInt(d.reporterCount.S, 10))
            : 0}
        </Table.Cell>
        <Table.Cell>
          {d.reporteeCount
            ? setCurrencyFormat(parseInt(d.reporteeCount.S, 10))
            : 0}
        </Table.Cell>
        <Table.Cell>
          {d.reportCount ? setCurrencyFormat(parseInt(d.reportCount.S, 10)) : 0}
        </Table.Cell>
        <Table.Cell>
          {d.achievementFailCount
            ? setCurrencyFormat(parseInt(d.achievementFailCount.S, 10))
            : 0}
        </Table.Cell>
        <Table.Cell>
          {d.achievementDeleteAndPassCount
            ? setCurrencyFormat(parseInt(d.achievementDeleteAndPassCount.S, 10))
            : 0}
        </Table.Cell>
      </Table.Row>,
    );
    if ('publicCustomChallengeCount' in d) {
      customChallengeList.push(
        <Table.Row key={idx}>
          <Table.Cell>{moment(d.date.S).format('YYYY-MM-DD')}</Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(parseInt(d.userCount.S, 10))}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(parseInt(d.publicCustomChallengeCount.S, 10))}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(parseInt(d.secretCustomChallengeCount.S, 10))}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(
              parseInt(d.publicCustomChallengeCreateUserCount.S, 10),
            )}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(
              parseInt(d.secretCustomChallengeCreateUserCount.S, 10),
            )}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(
              parseInt(d.publicCustomChallengeRegisterCount.S, 10),
            )}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(
              parseInt(d.secretCustomChallengeRegisterCount.S, 10),
            )}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(
              parseInt(d.publicCustomChallengeRegisterUserCount.S, 10),
            )}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(
              parseInt(d.secretCustomChallengeRegisterUserCount.S, 10),
            )}
          </Table.Cell>
        </Table.Row>,
      );
    }
  });

  return (
    <Table basic="very" size="small" textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>날짜</Table.HeaderCell>
          <Table.HeaderCell>가입자수</Table.HeaderCell>
          <Table.HeaderCell>
            신청자수
            <br />
            (전체)
          </Table.HeaderCell>
          <Table.HeaderCell>
            가입자수
            <br />
            (기업)
          </Table.HeaderCell>
          <Table.HeaderCell>
            신청자수
            <br />
            (기업)
          </Table.HeaderCell>
          <Table.HeaderCell>
            신청자수
            <br />
            (카드)
          </Table.HeaderCell>
          <Table.HeaderCell>
            신청자수
            <br />
            (계좌)
          </Table.HeaderCell>
          <Table.HeaderCell>
            신청건수
            <br />
            (전체)
          </Table.HeaderCell>
          <Table.HeaderCell>
            신청건수
            <br />
            (카드)
          </Table.HeaderCell>
          <Table.HeaderCell>
            신청건수
            <br />
            (계좌)
          </Table.HeaderCell>
          <Table.HeaderCell>신청금액</Table.HeaderCell>
          <Table.HeaderCell>
            순 신청자수
            <br />
            (카드/계좌)
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{registerList}</Table.Body>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>날짜</Table.HeaderCell>
          <Table.HeaderCell>가입자수</Table.HeaderCell>
          <Table.HeaderCell>
            NU신청자수
            <br />
            (전체)
          </Table.HeaderCell>
          <Table.HeaderCell>가입자수(기업)</Table.HeaderCell>
          <Table.HeaderCell>
            NU신청자수
            <br />
            (기업)
          </Table.HeaderCell>
          <Table.HeaderCell>
            NU신청자수
            <br />
            (카드)
          </Table.HeaderCell>
          <Table.HeaderCell>
            NU신청자수
            <br />
            (계좌)
          </Table.HeaderCell>
          <Table.HeaderCell>
            NU신청건수
            <br />
            (전체)
          </Table.HeaderCell>
          <Table.HeaderCell>
            NU신청건수
            <br />
            (카드)
          </Table.HeaderCell>
          <Table.HeaderCell>
            NU신청건수
            <br />
            (계좌)
          </Table.HeaderCell>
          <Table.HeaderCell>NU신청금액</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{newRegisterList}</Table.Body>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>날짜</Table.HeaderCell>
          <Table.HeaderCell>1.4.7가입자수</Table.HeaderCell>
          <Table.HeaderCell>1.4.7누적신청자수</Table.HeaderCell>
          <Table.HeaderCell>1.4.7누적취소자수</Table.HeaderCell>
          <Table.HeaderCell>1.4.7NU신청자수</Table.HeaderCell>
          <Table.HeaderCell>1.4.7NU취소자수</Table.HeaderCell>
          <Table.HeaderCell>1.4.9가입자수</Table.HeaderCell>
          <Table.HeaderCell>1.4.9누적신청자수</Table.HeaderCell>
          <Table.HeaderCell>1.4.9누적취소자수</Table.HeaderCell>
          <Table.HeaderCell>1.4.9NU신청자수</Table.HeaderCell>
          <Table.HeaderCell>1.4.9NU취소자수</Table.HeaderCell>
          <Table.HeaderCell>1.4.14가입자수</Table.HeaderCell>
          <Table.HeaderCell>1.4.14누적신청자수</Table.HeaderCell>
          <Table.HeaderCell>1.4.14누적취소자수</Table.HeaderCell>
          <Table.HeaderCell>1.4.14NU신청자수</Table.HeaderCell>
          <Table.HeaderCell>1.4.14NU취소자수</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{ab147RegisterList}</Table.Body>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>날짜</Table.HeaderCell>
          <Table.HeaderCell>가입자수</Table.HeaderCell>
          <Table.HeaderCell>
            취소자수
            <br />
            (전체)
          </Table.HeaderCell>
          <Table.HeaderCell>
            취소자수
            <br />
            (카드)
          </Table.HeaderCell>
          <Table.HeaderCell>
            취소자수
            <br />
            (계좌)
          </Table.HeaderCell>
          <Table.HeaderCell>
            취소건수
            <br />
            (전체)
          </Table.HeaderCell>
          <Table.HeaderCell>
            취소건수
            <br />
            (카드)
          </Table.HeaderCell>
          <Table.HeaderCell>
            취소건수
            <br />
            (계좌)
          </Table.HeaderCell>
          <Table.HeaderCell>취소금액</Table.HeaderCell>
          <Table.HeaderCell>
            과거건 취소자수
            <br />
            (카드/계좌)
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{cancelList}</Table.Body>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>날짜</Table.HeaderCell>
          <Table.HeaderCell>가입자수</Table.HeaderCell>
          <Table.HeaderCell>
            NU취소자수
            <br />
            (전체)
          </Table.HeaderCell>
          <Table.HeaderCell>
            NU취소자수
            <br />
            (카드)
          </Table.HeaderCell>
          <Table.HeaderCell>
            NU취소자수
            <br />
            (계좌)
          </Table.HeaderCell>
          <Table.HeaderCell>
            NU취소건수
            <br />
            (전체)
          </Table.HeaderCell>
          <Table.HeaderCell>
            NU취소건수
            <br />
            (카드)
          </Table.HeaderCell>
          <Table.HeaderCell>
            NU취소건수
            <br />
            (계좌)
          </Table.HeaderCell>
          <Table.HeaderCell>NU취소금액</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{newCancelList}</Table.Body>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>날짜</Table.HeaderCell>
          <Table.HeaderCell>결과발표 챌린지수</Table.HeaderCell>
          <Table.HeaderCell>결과발표 유저수</Table.HeaderCell>
          <Table.HeaderCell>실패 유저수</Table.HeaderCell>
          <Table.HeaderCell>100% 유저수</Table.HeaderCell>
          <Table.HeaderCell>85-99% 유저수</Table.HeaderCell>
          <Table.HeaderCell>비환급금</Table.HeaderCell>
          <Table.HeaderCell>전체신청금</Table.HeaderCell>
          <Table.HeaderCell>매출</Table.HeaderCell>
          <Table.HeaderCell>추가매출</Table.HeaderCell>
          <Table.HeaderCell>
            출금자수
            <br />
            (카드/계좌)
          </Table.HeaderCell>
          <Table.HeaderCell>
            출금액
            <br />
            (카드/계좌)
          </Table.HeaderCell>
          <Table.HeaderCell>특별상금</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{resultList}</Table.Body>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>날짜</Table.HeaderCell>
          <Table.HeaderCell>가입자수</Table.HeaderCell>
          <Table.HeaderCell>탈퇴자수</Table.HeaderCell>
          <Table.HeaderCell>관심등록</Table.HeaderCell>
          <Table.HeaderCell>인증샷수</Table.HeaderCell>
          <Table.HeaderCell>좋아요수</Table.HeaderCell>
          <Table.HeaderCell>댓글수</Table.HeaderCell>
          <Table.HeaderCell>팔로우신청수</Table.HeaderCell>
          <Table.HeaderCell>신고자수</Table.HeaderCell>
          <Table.HeaderCell>신고받은사람수</Table.HeaderCell>
          <Table.HeaderCell>신고받은인증샷수</Table.HeaderCell>
          <Table.HeaderCell>인증샷삭제(푸시발송)수</Table.HeaderCell>
          <Table.HeaderCell>인증샷삭제(성공처리)수</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{activationList}</Table.Body>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>날짜</Table.HeaderCell>
          <Table.HeaderCell>가입자수</Table.HeaderCell>
          <Table.HeaderCell>공개개설챌린지수</Table.HeaderCell>
          <Table.HeaderCell>비공개개설챌린지수</Table.HeaderCell>
          <Table.HeaderCell>공개개설챌린지 개설유저수</Table.HeaderCell>
          <Table.HeaderCell>비공개개설챌린지 개설유저수</Table.HeaderCell>
          <Table.HeaderCell>공개개설챌린지 참가건수</Table.HeaderCell>
          <Table.HeaderCell>비공개개설챌린지 참가건수</Table.HeaderCell>
          <Table.HeaderCell>공개개설챌린지 참가유저수</Table.HeaderCell>
          <Table.HeaderCell>비공개개설챌린지 참가유저수</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{customChallengeList}</Table.Body>
    </Table>
  );
};

export default DailyStatistics;
