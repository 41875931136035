import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import Button from '@component/Button';
import ErrorMsg from '@component/ErrorMsg';
import { useState } from 'react';
import {
  DescriptionModuleFormType,
  FormValidator,
} from '../../NewChallengeForm.types';
import { descriptionModules } from './DescriptionModuleForm.constant';
import AddModuleModal from './components/AddModuleModal/AddModuleModal';

type DescriptionModuleFormProps = {
  validator: FormValidator;
  label: string;
  list: DescriptionModuleFormType[];
  onAdd: (newItem: DescriptionModuleFormType) => void;
  onRemove: (index: number) => void;
  onSwap: (aIndex: number, bIndex: number) => void;
  onUpdate: (index: number, item: DescriptionModuleFormType) => void;
};

export const DescriptionModuleForm = (props: DescriptionModuleFormProps) => {
  const { validator, label, list, onAdd, onRemove, onSwap, onUpdate } = props;
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);

  const validated = validator({ descriptionModule: list });

  return (
    <>
      <FlexBox.Column>
        <Label>{label}</Label>
        <ErrorMsg text={!validated.isValid ? validated.message : ''} />
        {list.map((module, index) => {
          const Module = descriptionModules[module.type];
          if (!Module) return null;
          return (
            <FlexBox.Column
              key={`${module.type}-${index}`}
              style={{ marginBottom: 12 }}
            >
              <FlexBox.Row gap={6}>
                <Button
                  text={`${module.type} [지우기]`}
                  bgColor="black"
                  fontColor="white"
                  onClick={() => onRemove(index)}
                />
                <Button text="위로" onClick={() => onSwap(index - 1, index)} />
                <Button
                  text="아래로"
                  onClick={() => onSwap(index, index + 1)}
                />
              </FlexBox.Row>
              <Module
                {...module}
                moduleIndex={index}
                onUpdate={onUpdate}
                onRemove={onRemove}
              />
            </FlexBox.Column>
          );
        })}
        <Button
          text="모듈 추가"
          bgColor="black"
          size="large"
          fontColor="white"
          onClick={showModal}
        />
      </FlexBox.Column>
      <AddModuleModal open={modalVisible} onClose={hideModal} onAdd={onAdd} />
    </>
  );
};
