import React from 'react';
import { Table } from 'semantic-ui-react';
import setCurrencyFormat from '../../../global/setCurrencyFormat';

const TotalStatistics = ({ totalStatistics }) => {
  const {
    totalRegisterCount,
    cardRegisterCount,
    transferRegisterCount,
    totalCancelCount,
    cardCancelCount,
    transferCancelCount,
    newTotalRegisterCount,
    newCardRegisterCount,
    newTransferRegisterCount,
    newTotalCancelCount,
    newCardCancelCount,
    newTransferCancelCount,
    cardWithdrawAmount,
    cardWithdrawUserCount,
    transferWithdrawAmount,
    transferWithdrawUserCount,
  } = totalStatistics.total;
  return (
    <Table basic="very" textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>가입자수</Table.HeaderCell>
          <Table.HeaderCell>탈퇴자수</Table.HeaderCell>
          <Table.HeaderCell>신청자수</Table.HeaderCell>
          <Table.HeaderCell>신청건수(카드/계좌)</Table.HeaderCell>
          <Table.HeaderCell>신청금액</Table.HeaderCell>
          <Table.HeaderCell>NU신청건수(카드/계좌)</Table.HeaderCell>
          <Table.HeaderCell>NU신청금액</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            {setCurrencyFormat(totalStatistics.total.userCount)}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(totalStatistics.total.outUserCount)}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(totalStatistics.total.registerUserCount)}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(totalRegisterCount)} (
            {setCurrencyFormat(cardRegisterCount)} /{' '}
            {setCurrencyFormat(transferRegisterCount)})
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(totalStatistics.total.totalRegisterDeposit)}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(newTotalRegisterCount)} (
            {setCurrencyFormat(newCardRegisterCount)} /{' '}
            {setCurrencyFormat(newTransferRegisterCount)})
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(totalStatistics.total.newTotalRegisterDeposit)}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>취소자수</Table.HeaderCell>
          <Table.HeaderCell>취소건수(카드/계좌)</Table.HeaderCell>
          <Table.HeaderCell>취소금액</Table.HeaderCell>
          <Table.HeaderCell>NU취소건수(카드/계좌)</Table.HeaderCell>
          <Table.HeaderCell>NU취소금액</Table.HeaderCell>
          <Table.HeaderCell>출금자수(카드/계좌)</Table.HeaderCell>
          <Table.HeaderCell>출금액(카드/계좌)</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            {setCurrencyFormat(totalStatistics.total.cancelUserCount)}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(totalCancelCount)} (
            {setCurrencyFormat(cardCancelCount)} /{' '}
            {setCurrencyFormat(transferCancelCount)})
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(totalStatistics.total.totalCancelDeposit)}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(newTotalCancelCount)} (
            {setCurrencyFormat(newCardCancelCount)} /{' '}
            {setCurrencyFormat(newTransferCancelCount)})
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(totalStatistics.total.newTotalCancelDeposit)}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(cardWithdrawUserCount)} /{' '}
            {setCurrencyFormat(transferWithdrawUserCount)}
          </Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(cardWithdrawAmount)} /{' '}
            {setCurrencyFormat(transferWithdrawAmount)}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default TotalStatistics;
