import { ChallengeCustomField, ChallengeFaqCustomField } from '@types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'faq'>
> = (customFields) => {
  const faq = customFields.find((c) => c.name === 'faq');

  if (!faq) return {} as EmptyObj;

  const { values } = faq as ChallengeFaqCustomField;

  return { faq: values };
};

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'faq'>,
  Extract<ChallengeCustomField, { name: 'faq' }>
> = ({ faq }) => {
  // 빈문자열이더라도 faq 없는걸로 간주
  if (!faq) return [];
  return [{ name: 'faq', values: faq }];
};
