import Checkbox from '@component/CheckBox';
import Spacing from '@component/Spacing';
import TextInput from '@component/TextInput';
import { PreAlarmButton, PreAlarmAdditionalInfo } from '../PreAlarmForm.types';

interface Props {
  button: PreAlarmButton;
  onChange: <T extends keyof PreAlarmAdditionalInfo>(
    key: T,
    value?: PreAlarmAdditionalInfo[T],
  ) => void;
}

const ButtonSetting = ({ button, onChange }: Props) => {
  const checked = !!button;

  const handleClickCheckbox = () => {
    if (checked) {
      onChange('button', null);
    } else {
      onChange('button', { name: '', url: '' });
    }
  };

  return (
    <>
      <Checkbox
        label="버튼 사용"
        checked={checked}
        onClick={handleClickCheckbox}
      />
      {button && (
        <>
          <Spacing vertical={10} />

          <TextInput
            label="버튼명 (예시. 올리브영 바로가기, 뷰티컬리 바로가기, 쿠팡 바로가기)"
            value={button.name}
            onChange={(e) => onChange('button', { ...button, name: e })}
            style={{ maxWidth: '800px' }}
            disabled={!checked}
          />

          <Spacing vertical={10} />

          <TextInput
            label="버튼 링크"
            value={button.url}
            onChange={(e) => onChange('button', { ...button, url: e })}
            style={{ maxWidth: '800px' }}
            disabled={!checked}
          />
        </>
      )}
    </>
  );
};

export default ButtonSetting;
