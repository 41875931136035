import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';
import { AchievementInfoListForm } from '../AchievementInfoListForm';
import AuthenticationCheckListForm from '../AuthenticationCheckListForm/AuthenticationCheckListForm';
import { CommerceForm } from '../CommerceForm';
import { EndDateForm } from '../EndDateForm';
import ExpForm from '../ExpForm/ExpForm';
import { FaqForm } from '../FaqForm';
import { GoalIdForm } from '../GoalIdForm';
import IsPublicForm from '../IsPublicForm/IsPublicForm';
import { IsRegisterDepositFixedForm } from '../IsRegisterDepositFixedForm';
import { NaverBlogInfoListForm } from '../NaverBlogInfoListForm';
import PasswordForm from '../PasswordForm/PasswordForm';
import RegisterEndDateForm from '../RegisterEndDateForm/RegisterEndDateForm';
import RegisterStartDateForm from '../RegisterStartDateForm/RegisterStartDateForm';
import { ResultDateForm } from '../ResultDateForm';
import { SpecialPrizeForm } from '../SpecialPrizeForm';
import { StartDateForm } from '../StartDateForm';
import SupportersForm from '../SupportersForm/SupportersForm';
import { ThumbnailImageUrlForm } from '../ThumbnailImageUrlForm';
import TitleForm from '../TitleForm/TitleForm';
import { UseItemImpossibleForm } from '../UseItemImpossibleForm';
import TimestampForm from '../TimestampForm/TimestampForm';
import PurchaseInfoForm from '../PurchaseInfoForm/PurchaseInfoForm';
import NaverIdRequiredForm from '../NaverIdRequiredForm/NaverIdRequiredForm';
import CollaborationForm from '../CollaborationForm/CollaborationForm';
import ThirdTermUrlForm from '../ThirdTermUrlForm/ThirdTermUrlForm';
import CompanyIdForm from '../CompanyIdForm/CompanyIdForm';
import TagIdsForm from '../TagIdsForm/TagIdsForm';
import PublicTypeForm from '../PublicTypeForm/PublicTypeForm';
import QnaForm from '../QnaForm/QnaForm';
import { UserFilterForm } from '../UserFilterForm';
import { AchievementMissionForm } from '../AchievementMissionForm';
import DescriptionModuleForm from '../DescriptionModuleForm';
import DescriptionExtraForm from '../DescriptionExtraForm';
import IsDeletedForm from '../IsDeletedForm/IsDeletedForm';
import StickerForm from '../StickerForm/StickerForm';
import InstagramInfoListForm from '../InstagramInfoListForm/InstagramInfoListForm';
import UserIdForm from '../UserIdForm/UserIdForm';
import AdminUserIdsForm from '../AdminUserIdsForm/AdminUserIdsForm';
import MinRegisterDepositForm from '../MinRegisterDepositForm/MinRegisterDepositForm';
import MaxRegisterDepositForm from '../MaxRegisterDepositForm/MaxRegisterDepositForm';
import MaxRegisterCountForm from '../MaxRegisterCountForm/MaxRegisterCountForm';
import CancelImpossibleForm from '../CancelImpossibleForm/CancelImpossibleForm';
import AuthenticationMethodForm from '../AuthenticationMethodForm/AuthenticationMethodForm';
import AuthenticationGoodExamplesForm from '../AuthenticationGoodExamplesForm/AuthenticationGoodExamplesForm';
import CreatorForm from '../CreatorForm/CreatorForm';
import { ImageFilterForm } from '../ImageFilterForm';
import { DistanceForm } from '../DistanceForm';
import LabelIdsForm from '../LabelIdsForm/LabelIdsForm';
import PurchaseStartDateForm from '../PurchaseStartDateForm/PurchaseStartDateForm';
import PurchaseEndDateForm from '../PurchaseEndDateForm/PurchaseEndDateForm';
import PriceForm from '../PriceForm/PriceForm';
import OriginalPriceForm from '../OriginalPriceForm/OriginalPriceForm';

const validateWithAlert = <P extends Partial<ChallengeFormType>>(
  validator: FormValidator<P>,
  data: P,
): boolean => {
  const validated = validator(data);
  if (!validated.isValid) alert(validated.message);
  return validated.isValid;
};

/**
 * 순차적으로 테스트 후 하나라도 문제 있으면 alert 후 false 반환
 */
export const validate = (challenge: ChallengeFormType): boolean => {
  return (
    validateWithAlert(GoalIdForm.validate, challenge) &&
    validateWithAlert(RegisterStartDateForm.validate, challenge) &&
    validateWithAlert(RegisterEndDateForm.validate, challenge) &&
    validateWithAlert(StartDateForm.validate, challenge) &&
    validateWithAlert(EndDateForm.validate, challenge) &&
    validateWithAlert(ResultDateForm.validate, challenge) &&
    validateWithAlert(SupportersForm.validate, challenge) &&
    validateWithAlert(UseItemImpossibleForm.validate, challenge) &&
    validateWithAlert(IsRegisterDepositFixedForm.validate, challenge) &&
    validateWithAlert(SpecialPrizeForm.validate, challenge) &&
    validateWithAlert(AuthenticationCheckListForm.validate, challenge) &&
    validateWithAlert(TitleForm.validate, challenge) &&
    validateWithAlert(ExpForm.validate, challenge) &&
    validateWithAlert(ThumbnailImageUrlForm.validate, challenge) &&
    validateWithAlert(PasswordForm.validate, challenge) &&
    validateWithAlert(IsPublicForm.validate, challenge) &&
    validateWithAlert(IsDeletedForm.validate, challenge) &&
    validateWithAlert(FaqForm.validate, challenge) &&
    validateWithAlert(CommerceForm.validate, challenge) &&
    validateWithAlert(AchievementInfoListForm.validate, challenge) &&
    validateWithAlert(InstagramInfoListForm.validate, challenge) &&
    validateWithAlert(NaverBlogInfoListForm.validate, challenge) &&
    validateWithAlert(PurchaseInfoForm.validate, challenge) &&
    validateWithAlert(FaqForm.validate, challenge) &&
    validateWithAlert(TimestampForm.validate, challenge) &&
    validateWithAlert(NaverIdRequiredForm.validate, challenge) &&
    validateWithAlert(CollaborationForm.validate, challenge) &&
    validateWithAlert(ThirdTermUrlForm.validate, challenge) &&
    validateWithAlert(CompanyIdForm.validate, challenge) &&
    validateWithAlert(TagIdsForm.validate, challenge) &&
    validateWithAlert(LabelIdsForm.validate, challenge) &&
    validateWithAlert(PublicTypeForm.validate, challenge) &&
    validateWithAlert(QnaForm.validate, challenge) &&
    validateWithAlert(UserFilterForm.validate, challenge) &&
    validateWithAlert(AchievementMissionForm.validate, challenge) &&
    validateWithAlert(DescriptionModuleForm.validate, challenge) &&
    validateWithAlert(DescriptionExtraForm.validate, challenge) &&
    validateWithAlert(StickerForm.validate, challenge) &&
    validateWithAlert(UserIdForm.validate, challenge) &&
    validateWithAlert(AdminUserIdsForm.validate, challenge) &&
    validateWithAlert(MinRegisterDepositForm.validate, challenge) &&
    validateWithAlert(MaxRegisterDepositForm.validate, challenge) &&
    validateWithAlert(MaxRegisterCountForm.validate, challenge) &&
    validateWithAlert(CancelImpossibleForm.validate, challenge) &&
    validateWithAlert(AuthenticationMethodForm.validate, challenge) &&
    validateWithAlert(AuthenticationGoodExamplesForm.validate, challenge) &&
    validateWithAlert(CreatorForm.validate, challenge) &&
    validateWithAlert(DistanceForm.validate, challenge) &&
    validateWithAlert(ImageFilterForm.validate, challenge) &&
    validateWithAlert(PurchaseStartDateForm.validate, challenge) &&
    validateWithAlert(PurchaseEndDateForm.validate, challenge) &&
    validateWithAlert(PriceForm.validate, challenge) &&
    validateWithAlert(OriginalPriceForm.validate, challenge)
  );
};
