import styled from 'styled-components';

export const Row = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Item = styled.div<{ isHeader: boolean }>`
  width: 100%;
  height: 58px;
  flex: 1;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  background-color: ${({ isHeader }) => (isHeader ? '#eeeeee' : '#ffffff')};
  font-weight: ${({ isHeader }) => (isHeader ? 'bold' : 'normal')};
  color: #000000;
  padding: 8px;

  &:hover {
    background-color: #eeeeee44;
  }
`;
