import { useShallow } from 'zustand/react/shallow';
import {
  useChallengeEditedStore,
  useChallengeFormStore,
} from '../../NewChallengeForm.store';

export const useMaxRegisterCount = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      maxRegisterCount: s.challenge.maxRegisterCount,
    })),
  );
};

export const useMaxRegisterCountActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setMaxRegisterCount = (maxRegisterCount: number) =>
    updateChallengeForm({ maxRegisterCount });

  return { setMaxRegisterCount };
};
