import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<
  ChallengeFormType,
  'collaboration' | 'thirdTermUrl' | 'companyId'
>;

/**
 * validation 항목
 * 1. collaboration이 설정되어있으면 thirdTermUrl, companyId가 무조건 설정돼있어야함
 * 2. reviewRewardText값이 비어있다면 reviewQuestionList 역시 비어있어야 함
 * 3. collaboProductName, collaboProductLink값은 default 상태가 아닐 경우 무조건 있어야 함
 */
export const validate: FormValidator<Params> = ({ collaboration }) => {
  if (
    !collaboration.reviewRewardText?.length &&
    collaboration.reviewQuestionList.length
  ) {
    return {
      isValid: false,
      message:
        '[제휴챌린지] 리뷰 질문을 추가하려면 "리뷰 리워드 안내 문구"가 설정되어야 합니다',
    };
  }

  if (
    collaboration.reviewRewardText?.length &&
    !collaboration.reviewQuestionList.length
  ) {
    return {
      isValid: false,
      message:
        '[제휴챌린지] 제휴챌린지 설정을 하실 경우 리뷰 질문은 필수 입니다.',
    };
  }

  return { isValid: true };
};
