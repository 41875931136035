import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useIsPublic = () => {
  return useChallengeFormStore(
    useShallow((s) => ({ isPublic: s.challenge.isPublic })),
  );
};

export const useIsPublicActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setIsPublic = (isPublic: boolean) => updateChallengeForm({ isPublic });

  return { setIsPublic };
};
