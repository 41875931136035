import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'isPublic'>,
  Pick<ReqCreateOrUpdateChallenge, 'isPublic'>
> = ({ isPublic }) => {
  return { isPublic };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'isPublic'>,
  Pick<ChallengeFormType, 'isPublic'>
> = ({ isPublic }) => ({ isPublic });
