import React, { ChangeEvent } from 'react';
import {
  Form,
  Divider,
  Label,
  Icon,
  TextArea,
  Grid,
  Image,
  Dropdown,
} from 'semantic-ui-react';

const ContentDescriptionModuleForm = ({
  object,
  idx,
  goalOptions,
  spliceDescriptionModule,
  changeOrder,
  handleChangeDescriptionModule,
  handleChangeDescriptionModuleImage,
  cropAndUploadFile,
  addIntervieweeModule,
  deleteIntervieweeModule,
  handleChangeIntervieweeModule,
  handleChangeIntervieweeSingleFile,
}: ContentDescriptionModuleFormProps) => {
  return (
    <Form.Field style={styles.formField}>
      <Label
        color="black"
        value={idx}
        onClick={() => spliceDescriptionModule(idx)}
      >
        {object.type}
        <Icon name="delete" />
      </Label>
      <Label onClick={() => changeOrder('top', idx)}>
        <Icon name="angle double up" style={{ margin: 0 }} />
      </Label>
      <Label onClick={() => changeOrder('up', idx)}>
        <Icon name="angle up" style={{ margin: 0 }} />
      </Label>
      <Label onClick={() => changeOrder('down', idx)}>
        <Icon name="angle down" style={{ margin: 0 }} />
      </Label>
      <Label onClick={() => changeOrder('bottom', idx)}>
        <Icon name="angle double down" style={{ margin: 0 }} />
      </Label>
      {object.type === 'TEXT' && (
        <>
          <label>헤더</label>
          <TextArea
            value={object.header}
            onChange={(e) =>
              handleChangeDescriptionModule(idx, 'header', e.target.value)
            }
          />

          <label>바디</label>
          <TextArea
            value={object.body}
            onChange={(e) =>
              handleChangeDescriptionModule(idx, 'body', e.target.value)
            }
          />
        </>
      )}
      {object.type === 'VIDEO' && (
        <>
          <label>url</label>
          <input
            value={object.video}
            onChange={(e) =>
              handleChangeDescriptionModule(idx, 'video', e.target.value.trim())
            }
          />
        </>
      )}
      {object.type === 'IMAGE' && (
        <>
          <label>이미지</label>
          <input
            name={'image'}
            type="file"
            onChange={(e) => handleChangeDescriptionModuleImage(idx, e)}
          />
          <Image src={object.image} size="small" />
        </>
      )}
      {object.type === 'IMAGE_NO_GAP' && (
        <>
          <label>이미지</label>
          <input
            name={'image'}
            type="file"
            onChange={(e) => cropAndUploadFile(idx, e)}
          />
          <Image src={object.image} size="small" />
        </>
      )}
      {object.type === 'GOAL' && (
        <>
          <label>메인헤더</label>
          <TextArea
            value={object.mainHeader}
            onChange={(e) =>
              handleChangeDescriptionModule(idx, 'mainHeader', e.target.value)
            }
          />
          <label>서브헤더</label>
          <TextArea
            value={object.subHeader}
            onChange={(e) =>
              handleChangeDescriptionModule(idx, 'subHeader', e.target.value)
            }
          />

          <label>목표 목록</label>
          <Dropdown
            placeholder="목표를 선택하세요."
            fluid
            selection
            multiple
            search
            options={goalOptions}
            value={object.ids}
            onChange={(_, { value }) =>
              handleChangeDescriptionModule(idx, 'ids', value)
            }
          />
        </>
      )}
      {object.type === 'INTERVIEW' && (
        <>
          <label>인터뷰 목록</label>
          <Grid>
            {object.interviewees.map((interviewee: any, i: number) => (
              <React.Fragment key={i}>
                <Grid.Column width={12}>
                  <TextArea
                    value={interviewee.header}
                    placeholder="헤더"
                    onChange={(e) =>
                      handleChangeIntervieweeModule(
                        idx,
                        i,
                        'header',
                        e.target.value,
                      )
                    }
                  />
                  <input
                    name={'url'}
                    type="file"
                    onChange={(e) =>
                      handleChangeIntervieweeSingleFile(idx, i, e)
                    }
                  />
                  <Image src={interviewee.url} size="small" />
                  <input
                    value={interviewee.name}
                    placeholder="이름"
                    onChange={(e) =>
                      handleChangeIntervieweeModule(
                        idx,
                        i,
                        'name',
                        e.target.value.trim(),
                      )
                    }
                  />
                  <TextArea
                    value={interviewee.body}
                    placeholder="바디"
                    onChange={(e) =>
                      handleChangeIntervieweeModule(
                        idx,
                        i,
                        'body',
                        e.target.value,
                      )
                    }
                  />
                  <input
                    value={interviewee.userId ?? ''}
                    placeholder="유저id"
                    onChange={(e) =>
                      handleChangeIntervieweeModule(
                        idx,
                        i,
                        'userId',
                        e.target.value.trim(),
                      )
                    }
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Label onClick={() => deleteIntervieweeModule(idx, i)}>
                    삭제
                  </Label>
                  <Label onClick={() => addIntervieweeModule(idx, i)}>
                    추가
                  </Label>
                </Grid.Column>
              </React.Fragment>
            ))}
          </Grid>
        </>
      )}
      <Divider />
    </Form.Field>
  );
};

export default ContentDescriptionModuleForm;

const styles = {
  formField: {
    marginTop: 10,
    margin: 20,
  },
};

type ContentDescriptionModuleFormProps = {
  object: any;
  idx: number;
  goalOptions: any;
  spliceDescriptionModule: (idx: number) => void;
  changeOrder: (name: string, idx: number) => void;
  handleChangeDescriptionModule: (idx: number, key: string, value: any) => void;
  handleChangeDescriptionModuleImage: (
    idx: number,
    e: ChangeEvent<HTMLInputElement>,
  ) => void;
  cropAndUploadFile: (idx: number, e: ChangeEvent<HTMLInputElement>) => void;
  addIntervieweeModule: (idx: number, i: number) => void;
  deleteIntervieweeModule: (idx: number, i: number) => void;
  handleChangeIntervieweeModule: (
    idx: number,
    i: number,
    key: string,
    value: any,
  ) => void;
  handleChangeIntervieweeSingleFile: (
    idx: number,
    i: number,
    e: ChangeEvent<HTMLInputElement>,
  ) => void;
};
