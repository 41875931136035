import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { apis } from '../../api';
import {
  getNextPageParam,
  withTypedInfiniteLoad,
} from '../../utils/query.utils';
import {
  InfiniteQueryHookOptions,
  QuerySelectHookOptions,
} from '../query.types';
import getQueryKey from './getQueryKey';

export const useGetCompanies = (
  options?: InfiniteQueryHookOptions<typeof apis.commerce.getCompanies>,
) => {
  return useInfiniteQuery({
    queryKey: getQueryKey('COMPANIES'),
    queryFn: withTypedInfiniteLoad(apis.commerce.getCompanies, {}, 40),
    initialPageParam: 0,
    getNextPageParam,
    ...options,
  });
};

export const useGetAllCompanies = (
  options?: QuerySelectHookOptions<typeof apis.commerce.getAllCompanies>,
) => {
  return useQuery({
    queryKey: getQueryKey('ALL_COMPANIES'),
    queryFn: () => apis.commerce.getAllCompanies(),
    ...options,
    select: (res) => res.companies,
  });
};
