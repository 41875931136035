import React from 'react';
import { Image, Table } from 'semantic-ui-react';
import { ColorSample } from '@component/ColorSample';
import { RacePromiseForForm } from 'src/types/race.types';

const RacePromiseInfo = ({ promise }: { promise: RacePromiseForForm }) => {
  return (
    <>
      <h3>각오 설정</h3>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>배경 이미지</Table.HeaderCell>
            <Table.HeaderCell>기본 문구</Table.HeaderCell>
            <Table.HeaderCell>문구 색상</Table.HeaderCell>
            <Table.HeaderCell>이벤트 이미지</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Image size={'small'} src={promise.bgImageUrl} />
            </Table.Cell>
            <Table.Cell>{promise.defaultPromise}</Table.Cell>
            <Table.Cell>
              {promise.textColor}
              <ColorSample color={promise.textColor} />
            </Table.Cell>
            <Table.Cell>
              {promise.eventImageUrls.map((url, idx) => (
                <Image key={idx} size={'small'} src={url} />
              ))}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

export default RacePromiseInfo;
