import Accordion from '@component/Accordion/Accordion';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import NumberInput from '@component/NumberInput';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import { ChallengeCustomField } from 'src/types/customField.types';
import { isNull } from '@utils/js.utils';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { CREATOR_TYPE_OPTIONS } from './CreatorForm.constant';
import { convertData2Form, convertForm2Data } from './CreatorForm.serializer';
import { useCreatorAction, useCreatorStore } from './CreatorForm.store';
import { validate } from './CreatorForm.validator';

const CreatorForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'creator'>,
  Extract<ChallengeCustomField, { name: 'creator' }>
> = () => {
  const { creator } = useCreatorStore();
  const { setType, setDefaultCommercePrice } = useCreatorAction();
  const validated = validate({ creator });

  const selectedCreatorTypeOption = CREATOR_TYPE_OPTIONS.find(
    (option) => creator.type === option.value,
  );

  const handleCreatorTypeChange = (value: 'COACHING' | 'TOGETHER' | null) => {
    setType(value);
    if (isNull(value)) {
      setDefaultCommercePrice(null);
    }
  };

  return (
    <Accordion title="리더스(크리에이터) 챌린지" as="h5">
      <FlexBox.Column gap={10}>
        <FlexBox.Column gap={10}>
          <div>
            <Label>1:1코칭</Label>
            <RadioSelector
              selectedOption={selectedCreatorTypeOption}
              options={CREATOR_TYPE_OPTIONS}
              onChange={(o) => handleCreatorTypeChange(o.value)}
              style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
            />
          </div>

          <div>
            <Label>프로그램 참가비</Label>
            <NumberInput
              value={creator.defaultCommercePrice ?? 0}
              onChange={setDefaultCommercePrice}
              errorMsg={validated.isValid ? '' : validated.message}
              disabled={isNull(creator.type)}
            />
          </div>
        </FlexBox.Column>
      </FlexBox.Column>
    </Accordion>
  );
};

export default CreatorForm;

CreatorForm.validate = validate;
CreatorForm.convertData2Form = convertData2Form;
CreatorForm.convertForm2Data = convertForm2Data;
