import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'resultDate'>,
  Pick<ChallengeFormType, 'resultDate'>
> = (data) => ({ resultDate: data.resultDate });

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'resultDate'>,
  Pick<ReqCreateOrUpdateChallenge, 'resultDate'>
> = (form) => {
  if (!form.resultDate)
    throw Error("[ResultDateForm] this shouldn't be happened");
  return { resultDate: form.resultDate };
};
