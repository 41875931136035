import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { PublicTypeCustomFieldFormType } from '../../NewChallengeForm.types';

export const usePublicType = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      publicType: s.challenge.publicType,
      achievementInfoList: s.challenge.achievementInfoList,
    })),
  );

export const usePublicTypeActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setPublicType = (
    publicType: PublicTypeCustomFieldFormType['publicType'],
  ) =>
    updateChallengeForm((prev) => ({
      ...prev,
      publicType,
    }));

  return { setPublicType };
};
