import { useEffect, useMemo } from 'react';
import {
  DescriptionCollaboSpecialRewardModuleFormType,
  DescriptionModuleFormType,
  SpecialRewardModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import Label from '@component/Label';
import { useGetAllCompanies } from '@queries/Commerce/useGetCompanies';
import FlexBox from '@component/FlexBox/FlexBox';
import ReceiverForm from './components/ReceiverForm/ReceiverForm';
import RewardTextForm from './components/RewardTextForm/RewardTextForm';
import NoticeListForm from './components/NoticeListForm/NoticeListForm';
import {
  DescriptionModule,
  DescriptionModuleConvertData2Form,
  DescriptionModuleConvertForm2Data,
} from '../../DescriptionModuleForm.type';
import { useSpecialRewardModuleStore } from './SpecialRewardModule.store';
import { DEFAULT_DATA } from './SpecialRewardModule.constant';
import { validate } from './SpecialRewardModule.validator';
import {
  convertData2Form,
  convertForm2Data,
} from './SpecialRewardModule.serializer';

type SpecialRewardModuleProps = {
  type: 'COLLABO-SPECIAL-REWARD';
  data: SpecialRewardModuleFormType;
  moduleIndex: number;
  onUpdate: (
    index: number,
    item:
      | ((prev: DescriptionModuleFormType[]) => DescriptionModuleFormType[])
      | DescriptionModuleFormType,
  ) => void;
  onRemove: (index: number) => void;
};
const SpecialRewardModule: DescriptionModule<SpecialRewardModuleProps> = (
  props: SpecialRewardModuleProps,
) => {
  const { type, data, moduleIndex, onUpdate, onRemove } = props;
  const { companyId } = useSpecialRewardModuleStore();
  const { data: companies } = useGetAllCompanies();
  const companyData = useMemo(() => {
    if (!companyId || !companies) return null;
    return companies.find(({ id }) => id === companyId) || null;
  }, [companyId, companies]);

  useEffect(() => {
    if (!companyId) {
      window.alert('제휴챌린지 -> 회사선택 후 특별리워드를 추가해주세요');
      onRemove(moduleIndex);
    }
  }, [companyId, moduleIndex, onRemove]);

  useEffect(() => {
    if (!companyData) return;
    onUpdate(moduleIndex, (prev) => {
      const updated = [
        ...prev,
      ] as DescriptionCollaboSpecialRewardModuleFormType[];
      updated[moduleIndex] = {
        ...updated[moduleIndex],
        data: {
          ...updated[moduleIndex].data,
          from: companyData.name,
        },
      };
      return updated;
    });
  }, [companyData, moduleIndex, onUpdate]);

  const handleReceiver = (to: string) => {
    onUpdate(moduleIndex, { type, data: { ...data, to } });
  };

  const handleRewardText = (rewardText: string) => {
    onUpdate(moduleIndex, { type, data: { ...data, rewardText } });
  };

  const handleNoticeList = (noticeList: string[]) => {
    onUpdate(moduleIndex, { type, data: { ...data, noticeList } });
  };

  return (
    <FlexBox.Column
      style={{ border: '1px solid black', maxWidth: 720, padding: 12 }}
    >
      <Label>{`특별리워드 보내는 회사 : ${companyData?.name}`}</Label>
      <ReceiverForm to={data.to} onChange={handleReceiver} />

      <hr />
      <RewardTextForm
        rewardText={data.rewardText}
        onChange={handleRewardText}
      />

      <hr />

      <NoticeListForm
        noticeList={data.noticeList}
        onChange={handleNoticeList}
      />
    </FlexBox.Column>
  );
};

export default SpecialRewardModule;

SpecialRewardModule.moduleName = '특별리워드';
SpecialRewardModule.defaultData = DEFAULT_DATA;
SpecialRewardModule.validate = validate;
SpecialRewardModule.convertForm2Data =
  convertForm2Data as DescriptionModuleConvertForm2Data;
SpecialRewardModule.convertData2Form =
  convertData2Form as DescriptionModuleConvertData2Form;
