type CommonQueryKeys = {
  COMPANIES: undefined;
  COMPANIES_PRODUCTS: undefined;
  ALL_COMPANIES: undefined;
  ALL_COMPANIES_PRODUCTS: undefined;
};

export const getQueryKey = <T extends keyof CommonQueryKeys>(
  ...[key, params]: undefined extends CommonQueryKeys[T]
    ? [T]
    : [T, CommonQueryKeys[T] | 'KEY_ONLY']
) => {
  if (params === 'KEY_ONLY') return [key];
  return params ? [key, params] : [key];
};

export default getQueryKey;
