import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'thirdTermUrl' | 'companyId'>;

export const validate: FormValidator<Params> = ({
  thirdTermUrl,
  companyId,
}) => {
  if (!thirdTermUrl.length && typeof companyId === 'number')
    return {
      isValid: false,
      message: '제휴사가 설정된 경우 제3자 이용약관을 설정해야합니다.',
    };

  return { isValid: true };
};
