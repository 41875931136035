import React from 'react';
import Workbook from 'react-excel-workbook';
import { Button, Table } from 'semantic-ui-react';
import moment from 'moment';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';
import { transactionTypeLabel } from '../../../../constant/transaction';
import { maskString } from '../../../../utils/string';

const TransactionList = ({
  validated,
  allTransactions,
  showValidationModal,
}) => {
  const transactionList = allTransactions.map((transaction, idx) => {
    if (!transactionTypeLabel[transaction.type]) return null;
    return (
      <Table.Row key={idx}>
        <Table.Cell>
          {moment(transaction.createdAt).format('YYYY-MM-DD HH:mm:ss')}
        </Table.Cell>
        <Table.Cell>{transactionTypeLabel[transaction.type]}</Table.Cell>
        <Table.Cell>
          {transaction.currency === 'CASH' ? '캐시' : '상금'}
        </Table.Cell>
        <Table.Cell>{setCurrencyFormat(transaction.amount)}</Table.Cell>
        <Table.Cell>
          {transaction.type.includes('CHALLENGE_') ||
          transaction.type.includes('PRODUCT_') ||
          transaction.type.includes('REWARD')
            ? `[${transaction.challenge.id}] ${transaction.challenge.title}`
            : ''}
          {transaction.type.includes('PRODUCT_')
            ? ` | ${transaction.transactions
                .map((item) => item.product.name)
                .join(', ')}`
            : ''}
          {transaction.type === 'EVENT' && (
            <>
              <span>
                {transaction.info?.description}{' '}
                {transaction.info?.userId
                  ? ` (초대자id ${transaction.info?.userId})`
                  : ''}
              </span>
            </>
          )}
          {transaction.type === 'WITHDRAW_CARD' &&
            `${transaction.pgcode} / ${transaction.payInfo}`}
          {transaction.type === 'WITHDRAW_TRANSFER' &&
            `${transaction.bankName} / ${
              transaction.accountName
            } / ${maskString(transaction.accountNo)}`}
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <>
      {validated ? (
        <Workbook
          filename={`${user.id}_전체거래내역.xlsx`}
          element={
            <Button basic color="green" style={{ marginTop: 5 }}>
              거래 내역 다운로드
            </Button>
          }
        >
          <Workbook.Sheet data={allTransactions} name="sheet1">
            <Workbook.Column
              label="포인트종류"
              value={(transaction) =>
                moment(transaction.createdAt)
                  .add(offset, 'minutes')
                  .format('YYYY-MM-DD HH:mm:ss')
              }
            />
            <Workbook.Column
              label="항목명"
              value={(transaction) => transactionTypeLabel[transaction.type]}
            />
            <Workbook.Column
              label="값"
              value={(transaction) =>
                transaction.currency === 'CASH' ? '캐시' : '상금'
              }
            />
            <Workbook.Column
              label="포인트종류"
              value={(transaction) => setCurrencyFormat(transaction.amount)}
            />
            <Workbook.Column
              label="항목명"
              value={(transaction) => {
                let text = '';
                if (
                  transaction.type.includes('CHALLENGE_') ||
                  transaction.type.includes('PRODUCT_')
                ) {
                  text += `[${transaction.challenge.id}] ${transaction.challenge.title}`;
                }
                if (transaction.type.includes('PRODUCT_')) {
                  text += ` | ${transaction.transactions
                    .map((item) => item.product.name)
                    .join(', ')}`;
                }
                return text;
              }}
            />
          </Workbook.Sheet>
        </Workbook>
      ) : (
        <Button basic color="green" size="mini" onClick={showValidationModal}>
          엑셀 요청하기
        </Button>
      )}
      <Table basic={'very'}>
        <Table.Header>
          <Table.Row>
            {['날짜', '구분', '포인트종류', '금액', '비고'].map((title) => (
              <Table.HeaderCell key={title}>{title}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>{transactionList}</Table.Body>
      </Table>
    </>
  );
};

export default TransactionList;
