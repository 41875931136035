import React, { useCallback, useState } from 'react';
import { Accordion, Header, Icon } from 'semantic-ui-react';
import RaceDescriptionModuleComponent from '../RaceDescriptionModule/RaceDescriptionModule';
import { RaceDescriptionModule } from 'src/types/race.types';

const RaceProgressInfoDescription = ({
  progressInfoDescriptionModule,
}: {
  progressInfoDescriptionModule: RaceDescriptionModule[];
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const handleClick = useCallback((curr: boolean) => {
    setCollapsed(!curr);
  }, []);

  return (
    <>
      <Accordion>
        <Accordion.Title
          active={!collapsed}
          onClick={() => handleClick(collapsed)}
        >
          <Header as={'h3'}>
            <Icon name="dropdown" /> 대회 구경 화면
          </Header>
        </Accordion.Title>
        <Accordion.Content active={!collapsed}>
          {progressInfoDescriptionModule?.map((module, idx) => (
            <RaceDescriptionModuleComponent key={idx} module={module} />
          ))}
        </Accordion.Content>
      </Accordion>
    </>
  );
};

export default RaceProgressInfoDescription;
