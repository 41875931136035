import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { AuthenticationExample } from '../../NewChallengeForm.types';

export const useAuthenticationBadExamples = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      authenticationBadExampleUrls: s.challenge.authenticationBadExamples,
    })),
  );
};

export const useAuthenticationBadExamplesActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setAuthenticationBadExamples = (
    authenticationBadExamples: AuthenticationExample[],
  ) => updateChallengeForm({ authenticationBadExamples });

  return { setAuthenticationBadExamples };
};
