import { CollaboAdditionalRewardModule } from '@chlngers/core/lib/src/types/challenge/challengeDescriptionModule.types';
import { DescriptionCollaboAdditionalRewardModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const convertData2Form: (
  module: CollaboAdditionalRewardModule,
) => DescriptionCollaboAdditionalRewardModuleFormType = ({ data }) => {
  return {
    type: 'COLLABO-ADDITIONAL-REWARD',
    data,
  };
};

export const convertForm2Data: (
  module: DescriptionCollaboAdditionalRewardModuleFormType,
) => CollaboAdditionalRewardModule = ({ data }) => {
  return {
    type: 'COLLABO-ADDITIONAL-REWARD',
    data,
  };
};
