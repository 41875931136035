import {
  AchievementChallengeType,
  AchievementCommentFilter,
  AchievementOrderBy,
  ReviewResult,
} from 'src/types/achievement.types';

export const COMMENT_FILTER_LIST: {
  value: AchievementCommentFilter;
  text: string;
}[] = [
  {
    value: 'NO_COMMENT',
    text: '경고댓글없음',
  },
  {
    value: 'COMMENT',
    text: '경고댓글있음',
  },
  {
    value: 'ALL',
    text: '전체',
  },
];

export const CHALLENGE_TYPE_LIST: {
  key: AchievementChallengeType;
  value: AchievementChallengeType;
  text: string;
}[] = [
  {
    key: 'NORMAL',
    value: 'NORMAL',
    text: '전체 공식 챌린지',
  },
  {
    key: 'COLLABORATION',
    value: 'COLLABORATION',
    text: '제휴 챌린지',
  },
  {
    key: 'OFFICIAL',
    value: 'OFFICIAL',
    text: '제휴 아닌 공식 챌린지',
  },
  {
    key: 'CUSTOM',
    value: 'CUSTOM',
    text: '개설 챌린지',
  },
];

export const REVIEW_RESULT_LIST: {
  key: ReviewResult;
  value: ReviewResult;
  text: string;
}[] = [
  {
    key: 'BEFORE',
    value: 'BEFORE',
    text: '리뷰전',
  },
  {
    key: 'PASS',
    value: 'PASS',
    text: '성공처리',
  },
  {
    key: 'RED',
    value: 'RED',
    text: '레드카드',
  },
  {
    key: 'COMMENT',
    value: 'COMMENT',
    text: '경고 댓글',
  },
];

export const ORDER_BY_LIST: {
  key: AchievementOrderBy;
  value: AchievementOrderBy;
  text: string;
}[] = [
  {
    key: 'CHALLENGE_END_DATE',
    value: 'CHALLENGE_END_DATE',
    text: '결과 임박순',
  },
  {
    key: 'REPORT_DATE',
    value: 'REPORT_DATE',
    text: '오래된 신고순',
  },
];

export const CARD_SIZE: {
  value: number;
  text: string;
}[] = [
  {
    value: 2,
    text: '크게',
  },
  {
    value: 4,
    text: '보통',
  },
  {
    value: 6,
    text: '작게',
  },
  {
    value: 8,
    text: '최소화',
  },
];
