import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import TextInput from '@component/TextInput';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './PriceForm.serializer';
import { usePrice, usePriceActions } from './PriceForm.store';
import { validate } from './PriceForm.validator';

const PriceForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'price'>,
  Pick<ChallengeFormType, 'price'>,
  Pick<ReqCreateOrUpdateChallenge, 'price'>
> = () => {
  const { price } = usePrice();

  const { setPrice } = usePriceActions();

  const validated = validate({ price });

  return (
    <>
      <TextInput
        label="판매가"
        value={price?.toString()}
        onChange={setPrice}
        errorMsg={!validated.isValid ? validated.message : ''}
      />
    </>
  );
};

export default PriceForm;

PriceForm.validate = validate;
PriceForm.convertData2Form = convertData2Form;
PriceForm.convertForm2Data = convertForm2Data;
