import { useChallengeEditedStore } from '../../NewChallengeForm.store';

export const useEditedChecker = () => {
  const recommendedUpdates = useChallengeEditedStore(
    (s) => s.recommendedUpdates,
  );

  for (const value of Object.values(recommendedUpdates)) {
    if (Array.isArray(value)) {
      const notEditedExist = value.some((v) =>
        typeof v === 'object' ? Object.values(v).some((b) => !b) : !v,
      );
      if (notEditedExist) return false;
    }
    if (!value) {
      return false;
    }
  }

  return true;
};
