import FlexBox from '@component/FlexBox/FlexBox';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import {
  ChallengeAchievementSetting,
  ChallengeInfoForAdmin,
  ReqCreateOrUpdateChallenge,
} from '@types';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Header } from 'semantic-ui-react';
import { apis } from '../../../api/index';
import GoalDetailInfo from '../GoalDetailInfo/GoalDetailInfo';
import NewChallengeForm from '../NewChallengeForm/NewChallengeForm';

const ChallengeEdit = () => {
  const { id } = useParams<{ id: string }>();
  const challengeId = Number(id);

  const [challenge, setChallenge] = useState<
    ChallengeInfoForAdmin | undefined
  >();

  const history = useHistory();

  const submit = async (
    updatedChallenge: ReqCreateOrUpdateChallenge,
    achievementSetting: ChallengeAchievementSetting,
  ) => {
    LoadingIndicator.show();

    try {
      const { challenge: responseChallenge } =
        await apis.challenge.updateChallenge(challengeId, {
          challenge: updatedChallenge,
          achievementSetting,
        });

      if (!responseChallenge) {
        throw new Error('챌린지 수정에 실패했습니다.');
      }

      history.push(`/challenges/${challengeId}`);
    } catch (e) {
      console.log(e);
    } finally {
      LoadingIndicator.hide();
    }
  };

  // 처음 렌더링 시에만 실행
  useEffect(() => {
    LoadingIndicator.show();
    apis.challenge
      .getChallenge(challengeId)
      .then((res) => setChallenge(res.challenge))
      .finally(() => LoadingIndicator.hide());
  }, [challengeId]);

  if (!challenge) return <></>;
  return (
    <FlexBox.Row style={{ padding: 40 }}>
      <FlexBox.Column>
        <FlexBox.Row justifyContent="space-between">
          <Header as="h3" textAlign="center">
            챌린지 수정
          </Header>
          <Button
            onClick={() => {
              window.open(`/challenges/${challengeId}/pre-alarm`, '_blank');
            }}
            style={{
              backgroundColor: 'black',
              color: 'white',
            }}
          >
            사전 알림 세팅
          </Button>
        </FlexBox.Row>
        <NewChallengeForm challenge={challenge} onSubmit={submit} />
      </FlexBox.Column>
      <GoalDetailInfo />
    </FlexBox.Row>
  );
};

export default ChallengeEdit;
