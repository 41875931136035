import TextInput from '@component/TextInput';
import { useCreateLabels, useGetLabels } from '@queries/common/useLabels';
import { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const LabelCreateModal = ({ isOpen, onClose }: Props) => {
  const [labelName, setLabelName] = useState('');

  const { data, refetch } = useGetLabels();
  const labels = data?.labels || [];

  const { mutate } = useCreateLabels({
    onSuccess: async ({ label }) => {
      const { id } = label;
      if (id) {
        refetch();
        setLabelName('');
      }
      onClose();
    },
  });

  const handleClickCreateLabel = () => {
    if (labels.some(({ name }) => name === labelName)) {
      return alert('이미 존재하는 라벨이에요.');
    }
    mutate(labelName);
  };

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>라벨 생성</Modal.Header>
      <Modal.Content>
        <TextInput
          value={labelName}
          onChange={setLabelName}
          placeholder="라벨명을 입력해주세요"
          style={{ width: '100%' }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" onClick={handleClickCreateLabel}>
          생성
        </Button>
        <Button color="black" onClick={onClose}>
          닫기
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default LabelCreateModal;
