import React, { Component } from 'react';
import { Container, Table } from 'semantic-ui-react';
import api from '../../../api';

class AchievementEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }
  componentDidMount() {
    api
      .get('/admin/achievements/event', { params: { challengeId: 2713 } })
      .then((response) => {
        const users = response.data.data.users;
        console.log(users);
        this.setState({ users });
      });
  }

  render() {
    const { users } = this.state;
    const list = users.map((u, idx) => {
      return (
        <Table.Row key={u.id}>
          <Table.Cell>{idx + 1}</Table.Cell>
          <Table.Cell>{u.id}</Table.Cell>
          <Table.Cell>{u.name}</Table.Cell>
          <Table.Cell>{u.nickname}</Table.Cell>
          <Table.Cell>{u.totalLikeCount}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Container>
        <Table basic="very" collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>no</Table.HeaderCell>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>이름</Table.HeaderCell>
              <Table.HeaderCell>닉네임</Table.HeaderCell>
              <Table.HeaderCell>좋아요수</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{list}</Table.Body>
        </Table>
      </Container>
    );
  }
}

export default AchievementEvent;
