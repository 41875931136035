import { ChallengeInfoForAdmin } from '@types';
import { ChallengeDescriptionModule } from '@chlngers/core/lib/src/types/challenge';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
  DescriptionModuleFormType,
} from '../../NewChallengeForm.types';
import { descriptionModules } from './DescriptionModuleForm.constant';

type DescriptionName = 'descriptionModule' | 'descriptionExtra';

export const convertData2Form: <T extends DescriptionName>(
  descriptionName: T,
) => ConverterData2Form<
  Pick<ChallengeInfoForAdmin, T>,
  { [key in T]: DescriptionModuleFormType[] }
> = (descriptionName) => {
  return (challenge) => {
    const description = challenge[descriptionName];
    return {
      [descriptionName]:
        description
          ?.map((module) => {
            const { type } = module;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const targetModule = descriptionModules[type];
            if (!targetModule) return null;

            return targetModule.convertData2Form(module);
          })
          .filter((module) => Boolean(module)) || [],
    } as { [key in DescriptionName]: DescriptionModuleFormType[] };
  };
};

export const convertForm2Data: <T extends DescriptionName>(
  descriptionName: T,
) => ConverterForm2Data<
  Pick<ChallengeFormType, T>,
  { [key in T]: ChallengeDescriptionModule[] }
> = (descriptionName) => {
  return (challenge) => {
    const description = challenge[descriptionName];
    if (!description) throw Error("this shouldn't be happened");

    return {
      [descriptionName]:
        description?.map((module) => {
          const { type } = module;
          return descriptionModules[type].convertForm2Data(module);
        }) || [],
    } as { [key in DescriptionName]: ChallengeDescriptionModule[] };
  };
};
