import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'maxRegisterCount'>;

export const validate: FormValidator<Params> = ({ maxRegisterCount }) => {
  if (maxRegisterCount === 0) {
    return {
      isValid: false,
      message: '모집 인원은 1명 이상이어야 합니다.',
    };
  }

  return { isValid: true };
};
