import { useMemo, useCallback, useEffect, useState } from 'react';
import {
  AchievementChallengeType,
  AchievementCommentFilter,
  AchievementOrderBy,
  ReportedAchievement,
  ReviewResult,
} from 'src/types/achievement.types';
import { Grid, SemanticWIDTHS } from 'semantic-ui-react';
import Search from './Search';
import Count from './Count';
import { GetReportedAchievementsRequest } from '@api/achievement';
import { apis } from '@api/index';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import AchievementControlPanel from './AchievementControlPanel';
import AchievementItem from './AchievementItem';
import AWS from 'aws-sdk';
import { awsConfig } from 'src/config';
import { ReviewTemplate } from './Achievement.types';
import { PAGE_SIZE, REVIEW_RESULT_LABEL } from './Achievement.constants';
import ReviewTemplateMenu from './ReviewTemplateMenu';
import { getReviewComment } from './Achievement.utils';
import { DropdownOption } from 'src/types/common.types';
import AchievementMenu from './AchievementMenu';

AWS.config.update({
  region: awsConfig.bucketRegion,
  credentials: new AWS.Credentials({
    accessKeyId: awsConfig.accessKeyId!,
    secretAccessKey: awsConfig.secretAccessKey!,
  }),
  dynamoDbCrc32: false,
});
const ddb = new AWS.DynamoDB({
  apiVersion: '2012-08-10',
});

const Achievement = () => {
  const [achievements, setAchievements] = useState<ReportedAchievement[]>([]);
  const [challenges, setChallenges] = useState<DropdownOption[]>([]);
  const [selectedChallengeId, setSelectedChallengeId] = useState<number>(0);
  const [offset, setOffset] = useState(0);
  const [selectedChallengeType, setSelectedChallengeType] =
    useState<AchievementChallengeType>('NORMAL');
  const [selectedReviewResult, setSelectedReviewResult] =
    useState<ReviewResult>('BEFORE');
  const [reviewTemplates, setReviewTemplates] = useState<ReviewTemplate[]>([]);
  const [selectedCommentFilter, setSelectedCommentFilter] =
    useState<AchievementCommentFilter>('NO_COMMENT');
  const [selectedOrderBy, setSelectedOrderBy] =
    useState<AchievementOrderBy>('CHALLENGE_END_DATE');
  const [selectedColumnCount, setSelectedColumnCount] = useState(4);
  const [checkedAll, setCheckedAll] = useState(false);
  const [authenticationChecked, setAuthenticationChecked] = useState(false);
  const [reportReasonChecked, setReportReasonChecked] = useState(false);

  useEffect(() => {
    getReviewTemplate();
  }, []);

  useEffect(() => {
    _getAchievements({
      challengeId: selectedChallengeId === 0 ? undefined : selectedChallengeId,
    });
  }, [offset]);

  useEffect(() => {
    setOffset(0);
  }, [selectedChallengeType, selectedReviewResult, selectedOrderBy]);

  useEffect(() => {
    setAchievements((prev) =>
      prev.map((o) => ({
        ...o,
        isChecked: false,
      })),
    );
  }, [selectedChallengeId]);

  useEffect(() => {
    constructChallengeOptions();
  }, [achievements]);

  useEffect(() => {
    setAchievements((prev) =>
      prev.map((o) => ({
        ...o,
        isChecked: checkedAll,
      })),
    );
  }, [checkedAll]);

  const filteredAchievements = useMemo(
    () =>
      achievements.filter((o) => {
        if (selectedCommentFilter === 'COMMENT' && o.comments.length > 0) {
          return true;
        }

        if (selectedCommentFilter === 'NO_COMMENT' && o.comments.length === 0) {
          return true;
        }

        if (selectedCommentFilter === 'ALL') {
          return true;
        }

        return false;
      }),
    [achievements, selectedCommentFilter],
  );

  const getReviewTemplate = () => {
    ddb.scan({ TableName: 'ReviewTemplate' }, (err, data) => {
      if (err) {
        alert(err);
      } else {
        if (!data.Items) return;
        const templates: ReviewTemplate[] = data.Items.map((item) => {
          return {
            key: parseInt(item.key.N!, 10),
            text: item.text.S ?? '',
            value: parseInt(item.value.N!, 10),
            order: parseInt(item.order.N!, 10),
            message: item.message.S ?? '',
          };
        });
        templates.sort((a, b) => {
          if (a.order > b.order) return 1;
          return -1;
        });
        setReviewTemplates(templates);
      }
    });
  };

  const addReviewTemplate = (text: string, message: string) => {
    let max = 0;
    reviewTemplates.forEach((o) => {
      if (o.value > max) max = o.value;
    });

    max++;

    const params = {
      TableName: 'ReviewTemplate',
      Item: {
        key: { N: max.toString() },
        text: { S: text },
        value: { N: max.toString() },
        order: { N: max.toString() },
        message: { S: message },
      },
    };
    ddb.putItem(params, (err) => {
      if (!err) {
        setReviewTemplates((prev) => {
          return [...prev, { key: max, text, value: max, order: max, message }];
        });
      }
    });
  };

  const handleUpdateReviewTemplateOrder = async (updated: any) => {
    const templates = updated as ReviewTemplate[];
    templates.forEach((template, idx) => {
      const params = {
        TableName: 'ReviewTemplate',
        Item: {
          key: { N: template.key.toString() },
          text: { S: template.text },
          value: { N: template.value.toString() },
          order: { N: idx.toString() },
          message: { S: template.message },
        },
      };
      ddb.putItem(params, (err, data) => {
        if (err) {
          console.log('Error', err);
        } else {
          console.log('Success', data);
        }
      });
    });
  };

  const updateReviewTemplate = async (
    template: ReviewTemplate,
    text: string,
    message: string,
  ) => {
    const params = {
      TableName: 'ReviewTemplate',
      Item: {
        key: { N: template.key.toString() },
        text: { S: text },
        value: { N: template.value.toString() },
        order: { N: template.order.toString() },
        message: { S: message },
      },
    };
    ddb.putItem(params, (err) => {
      if (!err) {
        setReviewTemplates((prev) => {
          return prev.map((o) => {
            if (o.key === template.key) {
              return { ...o, text, message };
            }
            return o;
          });
        });
      }
    });
  };

  const deleteReviewTemplate = (template: ReviewTemplate) => {
    ddb.deleteItem(
      {
        TableName: 'ReviewTemplate',
        Key: { key: { N: template.key.toString() } },
      },
      (err, _) => {
        if (err) {
          alert(err);
        } else {
          setReviewTemplates((prev) => {
            return prev.filter((o) => o.key !== template.key);
          });
        }
      },
    );
  };

  const _getAchievements = async ({
    challengeId,
    reportUserId,
  }: {
    challengeId?: number;
    reportUserId?: number;
  }) => {
    LoadingIndicator.show();

    const params: GetReportedAchievementsRequest = {
      reviewResult: selectedReviewResult,
      achievementFileIsDeleted: false,
      isOfficial: true,
      offset,
      orderBy: selectedOrderBy,
      limit: PAGE_SIZE,
    };

    if (challengeId) {
      params.challengeId = challengeId;
    }

    if (reportUserId) {
      params.reportUserId = reportUserId;
    }

    let _achievements: ReportedAchievement[] = [];
    switch (selectedChallengeType) {
      case 'COLLABORATION':
        params.isCollaborate = true;
        break;
      case 'OFFICIAL':
        params.isCollaborate = false;
        break;
      case 'CUSTOM':
        params.isOfficial = false;
        break;
      default:
        break;
    }

    const res = await apis.achievement.getReportedAchievements(params);
    _achievements = res.achievements;

    if (_achievements.length === 0) alert('조회된 인증샷이 없습니다.');

    if (offset > 0) {
      setAchievements((prev) => {
        return [...prev, ..._achievements];
      });
    } else {
      setAchievements(_achievements);
    }

    LoadingIndicator.hide();
  };

  const constructChallengeOptions = () => {
    const existMap: { [key: number]: DropdownOption } = {};
    achievements.forEach((o) => {
      if (!(o.challenge.id in existMap)) {
        existMap[o.challenge.id] = {
          key: o.challenge.id,
          value: o.challenge.id,
          text: `[${o.challenge.id}]${o.challenge.title}(~${o.challenge.endDate})`,
        };
      }
    });
    setChallenges(Object.values(existMap));
  };

  const handleClickCheckAll = useCallback(() => {
    setCheckedAll((prev) => !prev);
  }, []);

  const handleClickAuthencticationCheck = useCallback(() => {
    setAuthenticationChecked((prev) => !prev);
  }, []);

  const handleClickReportReasonCheck = useCallback(() => {
    setReportReasonChecked((prev) => !prev);
  }, []);

  const handleUpdateAchievementsReviewResult = useCallback(
    async (reviewResult: ReviewResult) => {
      if (reviewResult === 'FAIL') {
        alert('구현 준비중이에요.');
        return;
      }

      const checkedIds: number[] = [];
      filteredAchievements.forEach((o) => {
        if (o.isChecked) checkedIds.push(o.achievementFile.id);
      });

      const res = await apis.achievement.bulkUpdateAchievementFileReviewResult({
        achievementFileIds: checkedIds,
        reviewResult,
        reviewComment: '',
        isPushNeeded: false,
      });

      if (res?.response?.data && 'err' in res?.response?.data) {
        return;
      }

      // achievements 업데이트
      setAchievements((prev) => {
        return prev.filter((o) => !checkedIds.includes(o.id));
      });

      alert(
        `선택한 인증샷들의 ${REVIEW_RESULT_LABEL[reviewResult]}가 완료되었어요.`,
      );
    },
    [filteredAchievements],
  );

  const handleDeleteAchievements = useCallback(async () => {
    const targetAchievements: ReportedAchievement[] = [];
    filteredAchievements.forEach((o) => {
      if (o.isChecked) targetAchievements.push(o);
    });

    await Promise.all(
      targetAchievements.map((o) => {
        const reviewComment = getReviewComment(0, o.user, o.challenge);
        return apis.deleteAchievementFile(o.achievementFile.id, {
          deleteType: 'PUSH',
          reviewComment,
          userId: o.user.id,
        });
      }),
    );

    // achievements 업데이트
    setAchievements((prev) => prev.filter((o) => !o.isChecked));

    alert('선택한 인증샷들의 삭제처리가 완료되었어요.');
  }, [filteredAchievements]);

  const handleBulkUseItem = useCallback(async () => {
    const targetAchievements: ReportedAchievement[] = [];
    filteredAchievements.forEach((o) => {
      if (o.isChecked) targetAchievements.push(o);
    });

    await Promise.all(
      targetAchievements.map((o) => {
        return apis.useAchievementItem(o.id);
      }),
    );

    // achievements 업데이트
    setAchievements((prev) => prev.filter((o) => !o.isChecked));

    alert('선택한 인증샷들의 삭제처리가 완료되었어요.');
  }, [filteredAchievements]);

  const removeAchievement = (_achievement: ReportedAchievement) => {
    setAchievements((prev) => prev.filter((o) => o.id !== _achievement.id));
  };

  const updateAchievement = (_achievement: ReportedAchievement) => {
    setAchievements((prev) =>
      prev.map((o) => {
        if (o.id === _achievement.id) return { ..._achievement };
        return o;
      }),
    );
  };

  const fetch = () => {
    if (offset > 0) setOffset(0);
    else
      _getAchievements({
        challengeId:
          selectedChallengeId === 0 ? undefined : selectedChallengeId,
      });
  };

  const fetchMore = useCallback(() => {
    setOffset((prev) => prev + PAGE_SIZE);
  }, []);

  return (
    <div style={{ margin: 40, marginTop: 0, display: 'flex' }}>
      <div
        style={{
          minWidth: '150px',
        }}
      >
        <AchievementMenu />
      </div>
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column width={3}>
            <Count
              totalCount={achievements.length}
              filteredCount={filteredAchievements.length}
            />
            <ReviewTemplateMenu
              reviewTemplates={reviewTemplates}
              addReviewTemplate={addReviewTemplate}
              updateReviewTemplate={updateReviewTemplate}
              handleUpdateReviewTemplateOrder={handleUpdateReviewTemplateOrder}
              deleteReviewTemplate={deleteReviewTemplate}
            />
            <Search getAchievements={_getAchievements} />
          </Grid.Column>
          <Grid.Column>
            <AchievementControlPanel
              selectedChallengeType={selectedChallengeType}
              selectedReviewResult={selectedReviewResult}
              selectedCommentFilter={selectedCommentFilter}
              selectedOrderBy={selectedOrderBy}
              selectedColumnCount={selectedColumnCount}
              challenges={challenges}
              selectedChallengeId={selectedChallengeId}
              checked={checkedAll}
              authenticationChecked={authenticationChecked}
              reportReasonChecked={reportReasonChecked}
              handleClickChallengeType={(challengeType) =>
                setSelectedChallengeType(challengeType)
              }
              handleClickReviewResult={(reviewResult) =>
                setSelectedReviewResult(reviewResult)
              }
              handleClickCommentFilter={(commentFilter) =>
                setSelectedCommentFilter(commentFilter)
              }
              handleClickChallengeFilter={(challengeId) =>
                setSelectedChallengeId(challengeId)
              }
              handleClickOrderBy={(orderBy) => setSelectedOrderBy(orderBy)}
              handleClickColumnCount={(count) => setSelectedColumnCount(count)}
              handleClickCheckAll={handleClickCheckAll}
              handleClickAuthenticationCheck={handleClickAuthencticationCheck}
              handleClickReportReasonCheck={handleClickReportReasonCheck}
              handleUpdateAchievementsReviewResult={
                handleUpdateAchievementsReviewResult
              }
              handleDeleteAchievements={handleDeleteAchievements}
              handleBulkUseItem={handleBulkUseItem}
              fetch={fetch}
              fetchMore={fetchMore}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {filteredAchievements.length > 0 && (
            <Grid columns={selectedColumnCount as SemanticWIDTHS}>
              {filteredAchievements.map((o) => (
                <AchievementItem
                  key={o.id}
                  achievement={o}
                  reviewTemplates={reviewTemplates}
                  defaultAuthenticationAccordionCollpased={
                    authenticationChecked
                  }
                  defaultReportReasonAccordionCollpased={reportReasonChecked}
                  removeAchievement={removeAchievement}
                  updateAchievement={updateAchievement}
                />
              ))}
            </Grid>
          )}
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Achievement;
