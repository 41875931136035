import { useQuery } from '@tanstack/react-query';
import { getQueryKey } from './getQueryKey';
import { apis } from '../../api';

// 앱 카테고리는 한번 fetch해 오면 변경될 일이 없으므로 cache, stale time infinity
export const useGetChallengeAppCategories = () => {
  return useQuery({
    queryKey: getQueryKey('GET_CHALLENGE_APP_CATEGORIES'),
    queryFn: apis.challenge.getChallengeAppCategories,
    gcTime: Infinity,
    staleTime: Infinity,
  });
};
