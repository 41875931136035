import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const usePrice = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      price: s.challenge.price,
    })),
  );
};

export const usePriceActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setPrice = (price: string) =>
    updateChallengeForm({
      price:
        !price.length || Number.isNaN(Number(price)) ? null : Number(price),
    });

  return { setPrice };
};
