import React, { FC } from 'react';
import { Image, Table } from 'semantic-ui-react';
import { Race } from '../../../../../types';
import { ColorSample } from '@component/ColorSample';

type Props = {
  info: Race.RaceCardInfo;
};

export const RaceCardInfo: FC<Props> = ({ info }) => {
  return (
    <>
      <h3>랜선 카드 정보</h3>
      <h4>공통</h4>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>후킹문구</Table.HeaderCell>
            <Table.HeaderCell>후킹 문구 컬러</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>{info.hookingText}</Table.Cell>
            <Table.Cell>
              {info.hookingTextColor}
              <ColorSample color={info.hookingTextColor} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <h4>가로형 대회 카드 (홈탭용)</h4>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>배경 이미지 A</Table.HeaderCell>
            <Table.HeaderCell>굿즈 이미지들</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell style={{ verticalAlign: 'top' }}>
              <Image size="small" src={info.cardBackgroundImageUrlA} />
            </Table.Cell>
            <Table.Cell>
              {info.goodsImageUrls?.map((url) => (
                <Image key={url} size="small" src={url} />
              ))}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <h4>세로형 대회 카드 (랜선탭용)</h4>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>배경 이미지</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell style={{ verticalAlign: 'top' }}>
              <Image size="small" src={info.backgroundImageUrl} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};
