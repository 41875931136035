import { colors } from '@constant/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 5px 10px;
  background: ${colors.GRAY_200};
  white-space: pre-wrap;
  border-radius: 6px;
`;

export const RemoveButton = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  width: 80px;
  cursor: pointer;
`;

export const Label = styled.label`
  font-weight: bold;
`;
