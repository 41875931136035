import React, { useMemo } from 'react';
import { Button } from 'semantic-ui-react';
// @ts-expect-error react-excel-workbook
import Workbook from 'react-excel-workbook';
import moment from 'moment';
import { QuestionDownloadItem, Satisfaction } from '../../types';

type QuestionExcelDownloadButtonProps = {
  style?: React.CSSProperties;
  downloadable: boolean;
  onRequestDownloadPress: () => void;
  startDatetime: string;
  endDatetime: string;
  questionExcelData?: QuestionDownloadItem[];
};

const QuestionExcelDownloadButton = (
  props: QuestionExcelDownloadButtonProps,
) => {
  const {
    style,
    downloadable,
    onRequestDownloadPress,
    startDatetime,
    endDatetime,
    questionExcelData,
  } = props;
  const relatedQuestionCountMap: Record<string, number> = useMemo(() => {
    if (!questionExcelData) return {};
    return questionExcelData.reduce((acc: Record<string, number>, question) => {
      const key = getRelatedQuestionCountKey(question);
      if (!acc[key]) acc[key] = 1;
      else acc[key]++;
      return acc;
    }, {});
  }, [questionExcelData]);

  if (!downloadable || !questionExcelData)
    return (
      <Button fluid style={style} onClick={onRequestDownloadPress}>
        다운로드 요청하기
      </Button>
    );
  return (
    <Workbook
      filename={`문의내용_${moment(startDatetime).format('YYYYMMDD')}-${moment(
        endDatetime,
      ).format('YYYYMMDD')}.xlsx`}
      element={
        <Button basic fluid color="green" style={style}>
          엑셀 다운로드
        </Button>
      }
    >
      <Workbook.Sheet data={questionExcelData} name="sheet1">
        <Workbook.Column
          label="문의id"
          value={(question: QuestionDownloadItem) => question.id}
        />
        <Workbook.Column
          label="유저id"
          value={(question: QuestionDownloadItem) => question.userId}
        />
        <Workbook.Column
          label="유저닉네임"
          value={(question: QuestionDownloadItem) => question.userNickname}
        />
        <Workbook.Column
          label="문의시간"
          value={(question: QuestionDownloadItem) =>
            moment(question.createdAt).format('YYYY-MM-DD HH:mm:ss')
          }
        />
        <Workbook.Column
          label="문의내용"
          value={(question: QuestionDownloadItem) => question.content}
        />
        <Workbook.Column
          label="답변내용(기존)"
          value={(question: QuestionDownloadItem) => question.answer ?? ''}
        />
        <Workbook.Column label="발송할 답변내용" value={() => ''} />
        <Workbook.Column
          label="만족도 점수"
          value={(question: QuestionDownloadItem) =>
            question.satisfaction.score
              ? String(question.satisfaction.score)
              : '-'
          }
        />
        <Workbook.Column
          label="친절 여부"
          value={(question: QuestionDownloadItem) =>
            getKindText(question.satisfaction)
          }
        />
        <Workbook.Column
          label="정확도 여부"
          value={(question: QuestionDownloadItem) =>
            getAccurateText(question.satisfaction)
          }
        />
        <Workbook.Column
          label="답변시간"
          value={(question: QuestionDownloadItem) =>
            moment(question.updatedAt).format('YYYY-MM-DD HH:mm:ss')
          }
        />
        <Workbook.Column
          label="답변소요시간(분)"
          value={(question: QuestionDownloadItem) =>
            question.answer
              ? moment(question.updatedAt).diff(
                  moment(question.createdAt),
                  'minutes',
                )
              : '-'
          }
        />
        <Workbook.Column
          label="답변자"
          value={(question: QuestionDownloadItem) =>
            question.userAdmin ? question.userAdmin.name : '-'
          }
        />
        <Workbook.Column
          label="카테고리"
          value={(question: QuestionDownloadItem) =>
            question.questionCategory
              ? question.questionCategory.subCategory
              : '-'
          }
        />
        <Workbook.Column
          label="태그"
          value={(question: QuestionDownloadItem) =>
            question.tags.length > 0 ? question.tags.join(', ') : '-'
          }
        />
        <Workbook.Column
          label="챌린지id"
          value={(question: QuestionDownloadItem) =>
            question.challenge ? question.challenge.id : '-'
          }
        />
        <Workbook.Column
          label="유저ID&챌린지ID"
          value={(question: QuestionDownloadItem) =>
            getRelatedQuestionCountKey(question)
          }
        />
        <Workbook.Column
          label="연관질문"
          value={(question: QuestionDownloadItem) =>
            relatedQuestionCountMap[getRelatedQuestionCountKey(question)]
          }
        />
        <Workbook.Column
          label="챌린지명"
          value={(question: QuestionDownloadItem) =>
            question.challenge ? question.challenge.title : '-'
          }
        />
        <Workbook.Column
          label="챌린지 시작일"
          value={(question: QuestionDownloadItem) =>
            question.challenge
              ? moment(question.challenge.startDate).format(
                  'YYYY-MM-DD HH:mm:ss',
                )
              : '-'
          }
        />
        <Workbook.Column
          label="챌린지 종료일"
          value={(question: QuestionDownloadItem) =>
            question.challenge
              ? moment(question.challenge.endDate).format('YYYY-MM-DD HH:mm:ss')
              : '-'
          }
        />
        <Workbook.Column
          label="재문의여부"
          value={(question: QuestionDownloadItem) =>
            question.isRetry ? '재문의' : '-'
          }
        />
        <Workbook.Column
          label="삭제여부"
          value={(question: QuestionDownloadItem) =>
            question.isDeleted ? '삭제됨' : '-'
          }
        />
        <Workbook.Column
          label="카테고리ID(개발자용)"
          value={(question: QuestionDownloadItem) =>
            question.questionCategory?.id || 0
          }
        />
        <Workbook.Column
          label="태그(개발자용)"
          value={(question: QuestionDownloadItem) =>
            JSON.stringify(question.tags)
          }
        />
      </Workbook.Sheet>
    </Workbook>
  );
};

const NO_CHALLENGE_TEXT = '(챌린지없음)';
const getRelatedQuestionCountKey = (question: QuestionDownloadItem) => {
  return `${question.userId}${question.challenge?.id || NO_CHALLENGE_TEXT}`;
};

export default QuestionExcelDownloadButton;

const getKindText = (satisfaction: Satisfaction) => {
  if (!('isKind' in satisfaction)) return '-';
  return satisfaction.isKind ? '친절' : '불친절';
};

const getAccurateText = (satisfaction: Satisfaction) => {
  if (!('isKind' in satisfaction)) return '-';
  return satisfaction.isAccurate ? '정확' : '부정확';
};
