import { CollaboTopSummaryModule } from '@chlngers/core/lib/src/types/challenge';
import { DescriptionCollaboTopSummaryModuleFormType } from '../../../../NewChallengeForm.types';
import { DEFAULT_DATA } from './CollaboTopSummary.constants';

export const convertData2Form = (
  module: CollaboTopSummaryModule,
): DescriptionCollaboTopSummaryModuleFormType => {
  return {
    type: 'COLLABO-TOP-SUMMARY',
    data: {
      ...DEFAULT_DATA.data,
      ...module.data,
    },
  };
};

export const convertForm2Data = ({
  data,
}: DescriptionCollaboTopSummaryModuleFormType): CollaboTopSummaryModule => {
  return {
    type: 'COLLABO-TOP-SUMMARY',
    data,
  };
};
