import styled from 'styled-components';
import CommonButton from '@component/Button';

export const ReviewQuestionItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 4px;
  gap: 8px;
`;

export const IconButton = styled(CommonButton)`
  display: flex;
  padding: 0px;
  min-width: 0px;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
`;
