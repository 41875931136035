import { ApiType } from '@constant/apiType';
import { _request } from './factory';
import {
  PreAlarmForAdmin,
  PreAlarmServiceType,
} from 'src/types/preAlarm.types';

export type GetPreAlarmListParams = {
  offset: number;
  limit: number;
  keyword?: string;
};

export const getPreAlarms = async (
  params: GetPreAlarmListParams,
): Promise<{
  preAlarms: Pick<
    PreAlarmForAdmin,
    'id' | 'serviceId' | 'serviceType' | 'title'
  >[];
  isEnd: boolean;
}> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/pre-alarms/list`,
    params,
  });
};

export type GetPreAlarmByIdParams = {
  preAlarmId: number;
};

export const getPreAlarmById = async (
  params: GetPreAlarmByIdParams,
): Promise<{
  preAlarm: PreAlarmForAdmin;
}> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/pre-alarms/${params.preAlarmId}`,
  });
};

export type GetPreAlarmByServiceInfoParams = {
  serviceType: PreAlarmServiceType;
  serviceId: number;
};

export const getPreAlarmByServiceInfo = async (
  params: GetPreAlarmByServiceInfoParams,
): Promise<{
  preAlarm: PreAlarmForAdmin | null;
}> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/pre-alarms`,
    params,
  });
};

export type CreateOrUpdatePreAlarmParams = Pick<
  PreAlarmForAdmin,
  | 'title'
  | 'brand'
  | 'maxRegisterCount'
  | 'thumbnailImageUrl'
  | 'descriptionImageUrlList'
  | 'serviceId'
  | 'serviceType'
  | 'userFilterId'
  | 'additionalInfo'
>;

export const createPreAlarm = async (
  data: CreateOrUpdatePreAlarmParams,
): Promise<{
  preAlarm: PreAlarmForAdmin;
}> => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/pre-alarms`,
    data,
  });
};

export type UpdatePreAlarmIsAdminParams = {
  isAdmin: boolean;
};

export const updatePreAlarmIsAdmin = async (
  preAlarmId: number,
  data: UpdatePreAlarmIsAdminParams,
): Promise<{
  success: boolean;
}> => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: `/admin/pre-alarms/${preAlarmId}`,
    data,
  });
};

export const updatePreAlarm = async (
  preAlarmId: number,
  data: CreateOrUpdatePreAlarmParams,
): Promise<{
  preAlarm: PreAlarmForAdmin;
}> => {
  return _request({
    apiType: ApiType.go,
    method: 'PUT',
    url: `/admin/pre-alarms/${preAlarmId}`,
    data,
  });
};

export const deletePreAlarm = async (
  preAlarmId: number,
): Promise<{
  success: boolean;
}> => {
  return _request({
    apiType: ApiType.go,
    method: 'DELETE',
    url: `/admin/pre-alarms/${preAlarmId}`,
  });
};

export default {
  getPreAlarms,
  getPreAlarmByServiceInfo,
  createPreAlarm,
  updatePreAlarmIsAdmin,
  updatePreAlarm,
  deletePreAlarm,
};
