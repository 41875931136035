import React from 'react';
import { Image, Table } from 'semantic-ui-react';
import { splitNewLine } from '../../../../../utils/string';
import { RaceInfo } from 'src/types/race.types';

const RaceShareInfo = ({ info }: { info: RaceInfo }) => {
  return (
    <>
      <h3>공유 정보</h3>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>og image</Table.HeaderCell>
            <Table.HeaderCell>og title</Table.HeaderCell>
            <Table.HeaderCell>og description</Table.HeaderCell>
            <Table.HeaderCell>공유 문구</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Image size={'small'} src={info.ogImage} />
            </Table.Cell>
            <Table.Cell>{info.ogTitle}</Table.Cell>
            <Table.Cell>{info.ogDescription}</Table.Cell>
            <Table.Cell>{splitNewLine(info.shareMessage)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

export default RaceShareInfo;
