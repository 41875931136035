import FlexBox from '@component/FlexBox/FlexBox';
import React, { useEffect, useState } from 'react';
import * as S from './ImageSelector.style';
import { Image } from '../../DataLabel.types';
import { getBackgroundColor } from '../../DataLabel.utils';

export const ImageSelector: React.FC<ImageSelectorProps> = (props) => {
  const {
    imageList,
    selectedImage,
    offset,
    handleClickImage,
    handleClickButton,
    movePage,
  } = props;

  const [page, setPage] = useState(offset / 100);

  const scrollRef = React.useRef<HTMLDivElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      movePage(page - 1);
    }
  };

  const idx = imageList.findIndex((o) => o.id === selectedImage?.id);

  useEffect(() => {
    scrollRef.current?.scroll(0, idx * 40);
  }, [idx, selectedImage]);

  useEffect(() => {
    setPage(offset / 100 + 1);
  }, [offset]);

  return (
    <FlexBox.Column style={{ flex: 2 }}>
      <FlexBox.Row justifyContent="space-between" alignItems="center">
        <h3>이미지 목록</h3>
        <FlexBox.Row>
          <S.Button onClick={() => handleClickButton(false)}>
            이전 100개
          </S.Button>
          <S.Button onClick={() => handleClickButton(true)}>
            다음 100개
          </S.Button>
        </FlexBox.Row>
      </FlexBox.Row>
      <FlexBox.Row
        justifyContent="space-between"
        alignItems="flex-end"
        style={{ margin: '8px 0' }}
      >
        <S.Span isActive={false}>
          ({selectedImage ? offset + idx + 1 : '-'} /{' '}
          {offset + imageList.length})
        </S.Span>
        <FlexBox.Row gap={4}>
          <input
            style={{ width: 40 }}
            value={page}
            onChange={(e) => setPage(Number(e.target.value))}
            onKeyDown={handleKeyDown}
          />
          <S.Wrapper backgroundColor="#000" onClick={() => movePage(page - 1)}>
            <S.Span isActive>페이지 이동</S.Span>
          </S.Wrapper>
        </FlexBox.Row>
      </FlexBox.Row>
      <div style={{ maxHeight: 500, overflowY: 'auto' }} ref={scrollRef}>
        {imageList.map((image, i) => {
          const isOdd = i % 2 === 1;
          const isActive = selectedImage?.id === image.id;
          return (
            <S.Wrapper
              key={image.id}
              backgroundColor={getBackgroundColor(isOdd, isActive)}
              onClick={() => handleClickImage(image)}
            >
              <S.Span isActive={isActive}>{image.id}.png</S.Span>
            </S.Wrapper>
          );
        })}
      </div>
    </FlexBox.Column>
  );
};

type ImageSelectorProps = {
  imageList: Image[];
  selectedImage: Image | null;
  offset: number;
  handleClickImage: (image: Image) => void;
  handleClickButton: (isNext: boolean) => void;
  movePage: (page: number) => void;
};
