import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { formatLocalDateTime } from '@utils/date.utils';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'registerEndDate'>,
  Pick<ReqCreateOrUpdateChallenge, 'registerEndDate'>
> = ({ registerEndDate }) => {
  if (!registerEndDate)
    throw Error("[RegisterEndDateForm] this shouldn't be happened");

  return { registerEndDate };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'registerEndDate'>,
  Pick<ChallengeFormType, 'registerEndDate'>
> = ({ registerEndDate }) => ({
  registerEndDate: formatLocalDateTime(new Date(registerEndDate)),
});
