import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Input = styled.input`
  cursor: pointer;
  width: 18px;
  height: 18px;
`;

export const Label = styled.span``;
