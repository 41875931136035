import moment from 'moment';

const weekDay = {
  1: '월요일',
  2: '화요일',
  3: '수요일',
  4: '목요일',
  5: '금요일',
  6: '토요일',
  7: '일요일',
};

const weekDayShort = {
  1: '월',
  2: '화',
  3: '수',
  4: '목',
  5: '금',
  6: '토',
  7: '일',
};

const getDateStringWithDay = (date) => {
  return `${moment(date).format('YY.MM.DD')}(${getShortWeekDay(date)})`;
};

const getShortWeekDay = (date) => {
  return weekDayShort[moment(date).isoWeekday()];
};

export { weekDay, getShortWeekDay, getDateStringWithDay };


