export const convertWonUnit = (
  won: number,
  unit: '만원' | '천원' = '만원',
): string => {
  switch (unit) {
    case '만원':
      if (won > 10000) return (won / 10000).toLocaleString();
      return (won / 10000).toFixed(2);
    case '천원':
      if (won > 1000) return (won / 1000).toLocaleString();
      return (won / 1000).toFixed(2);
    default:
      return won.toLocaleString();
  }
};
