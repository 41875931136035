import React, { useState, useReducer, useEffect } from 'react';
import { handleChangeSingleFile } from '../../../utils/uploadImage';
import {
  Dropdown,
  Form,
  Grid,
  Header,
  Image,
  Container,
  Checkbox,
  Button,
} from 'semantic-ui-react';
import { useHistory, useParams } from 'react-router-dom';
import * as S from './style';
import { apis } from '../../../api';

const GotchaForm = () => {
  const { id: givenId } = useParams();

  const initialId = Number(givenId);

  const [loading, setLoading] = useState(!!initialId);

  const [initialGotcha, setInitialGotcha] = useState(undefined);

  useEffect(() => {
    if (!initialId) return;

    apis.getGotchas().then(({ gotchas }) => {
      const gotcha = gotchas.find((v) => v.id === initialId);
      if (gotcha) {
        setInitialGotcha(gotcha);
        setLoading(false);
      }
    });
  }, []);

  if (loading) return <></>;

  return (
    <GotchaFormDetail initialId={initialId} initialGotcha={initialGotcha} />
  );
};

function GotchaFormDetail({ initialGotcha, initialId }) {
  const history = useHistory();

  const [gotcha, dispatch] = useReducer(reducer, {
    ...initial,
    ...initialGotcha,
  });

  const [userLimitEnabled, setUserLimitEnabled] = useState(
    !!initialGotcha?.info?.userLimit,
  );

  const {
    price,
    reward,
    thumbnailImageUrl,
    isDisplayed,
    winRate,
    gotchaType,
    info: { description, userLimit, instantReward },
  } = gotcha;

  const handleChangeImage = async (e) => {
    const { thumbnailImageUrl } = await handleChangeSingleFile(e, {});
    dispatch({ key: 'thumbnailImageUrl', value: thumbnailImageUrl });
  };

  const inputFields = (() => {
    const fields = [
      {
        label: '리워드',
        name: 'reward',
        parser: String,
        type: 'text',
        value: reward,
      },
      {
        label: '브랜드',
        name: 'description',
        parser: String,
        type: 'text',
        value: description,
      },
      {
        label: '응모비 (원)',
        name: 'price',
        type: 'number',
        parser: Number,
        value: price,
      },
      {
        label: '당첨확률 (0~100%)',
        name: 'winRate',
        parser: Number,
        type: 'number',
        value: winRate,
      },
    ];

    if (userLimitEnabled) {
      fields.push({
        label: '최대 응모자 수',
        name: 'userLimit',
        parser: Number,
        type: 'number',
        value: userLimit,
      });
    }

    return fields;
  })();

  return (
    <Container style={{ paddingBottom: 50 }}>
      <Grid.Column>
        <Header as="h1">{`뽑기 ${
          initialId ? '수정 ✍️' : '새로 생성 🚀'
        }`}</Header>
        <Form>
          <Form.Field>
            <label>썸네일</label>
            <input
              name="thumbnailImageUrl"
              type="file"
              onChange={handleChangeImage}
            />
            <Image src={thumbnailImageUrl} size="medium" />
          </Form.Field>
          <Form.Field>
            <label>공개 여부</label>
            <S.Row>
              <Checkbox
                toggle
                name="isDisplayed"
                checked={isDisplayed}
                onChange={(_, { checked }) => {
                  dispatch({ key: 'isDisplayed', value: checked });
                }}
              />
              <div style={{ paddingLeft: 12 }}>
                {isDisplayed ? '공개' : '비공개'}
              </div>
            </S.Row>
          </Form.Field>

          <Form.Field>
            <label>유형</label>
            <Dropdown
              placeholder="지급 방식"
              selection
              name="gotchaType"
              options={[
                { text: '즉시지급', value: 'instant' },
                { text: '기프티콘', value: 'gifticon' },
                { text: '택배배송', value: 'delivery' },
              ]}
              value={gotchaType}
              onChange={(e, { value }) =>
                dispatch({ key: 'gotchaType', value })
              }
            />
          </Form.Field>

          {gotchaType === 'instant' && !!instantReward && (
            <React.Fragment>
              <Form.Field>
                <label>{`유형 - [지급내용]`}</label>
                <Dropdown
                  placeholder=""
                  selection
                  name="rewardType"
                  options={[
                    { text: '스토어포인트', value: 'STORE' },
                    { text: '상금', value: 'PRIZE' },
                    { text: '예치금', value: 'CASH' },
                    { text: '인증패스', value: 'ITEM' },
                  ]}
                  value={instantReward.rewardType}
                  onChange={(e, { value }) => {
                    return dispatch({ key: 'rewardType', value });
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>{`유형 - [지급 포인트]`}</label>
                <input
                  name={'rewardAmount'}
                  value={instantReward.rewardAmount}
                  onChange={(e) => {
                    dispatch({
                      key: 'rewardAmount',
                      value: Number(e.target.value),
                    });
                  }}
                  type={'number'}
                />
              </Form.Field>
            </React.Fragment>
          )}
          {inputFields.map((field) => (
            <Form.Field key={field.name}>
              <label>{field.label}</label>
              <input
                name={field.name}
                value={field.value}
                onChange={(e) => {
                  dispatch({
                    key: e.target.name,
                    value: field.parser.call(null, e.target.value),
                  });
                }}
                type={field.type}
              />
            </Form.Field>
          ))}
          <Form.Field>
            <label>{`최대 응모자 제한`}</label>
            <Checkbox
              toggle
              name="userLimitEnabled"
              checked={userLimitEnabled}
              onChange={(_, { checked }) => setUserLimitEnabled(checked)}
            />
          </Form.Field>
        </Form>
      </Grid.Column>
      <S.Row style={{ width: '100%', padding: 20, justifyContent: 'center' }}>
        <Button
          content={initialId ? '수정완료' : '생성완료'}
          size="large"
          color={'black'}
          onClick={async () => {
            const data = {
              ...gotcha,
              info: {
                ...gotcha.info,
                ...(userLimitEnabled
                  ? { userLimit: gotcha.info.userLimit || null }
                  : {}),
              },
            };

            if (initialId) {
              await apis.editGotchas(initialId, data);
            } else {
              await apis.createGotcha(data);
            }

            history.push(`/gotcha`);
            window.alert(`${initialId ? '수정' : '생성'}을 완료 헀어요 😊`);
          }}
        />
      </S.Row>
    </Container>
  );
}

const initial = {
  reward: '샘플 리워드',
  price: 70,
  isDisplayed: false,
  thumbnailImageUrl:
    'https://cdnimg.melon.co.kr/cm2/album/images/105/54/246/10554246_20210325161233_500.jpg?304eb9ed9c07a16ec6d6e000dc0e7d91',
  gotchaType: 'instant',
  winRate: 0.05,
  info: {
    description: '브랜드',
    instantReward: {
      rewardType: 'STORE',
      rewardAmount: 0,
    },
  },
};

function reducer(state, action) {
  if (action.key === 'gotchaType') {
    if (action.value === 'instant') {
      return {
        ...state,
        info: {
          ...state.info,
          instantReward: {
            rewardType: 'STORE',
            rewardAmount: 0,
          },
        },
        [action.key]: action.value,
      };
    }

    return {
      ...state,
      info: { ...state.info, instantReward: null },
      [action.key]: action.value,
    };
  }

  // 2 depth
  if (['description', 'userLimit'].includes(action.key)) {
    return {
      ...state,
      info: {
        ...state.info,
        [action.key]: action.value,
      },
    };
  }

  // 3 depth
  if (['rewardType', 'rewardAmount'].includes(action.key)) {
    return {
      ...state,
      info: {
        ...state.info,
        instantReward: {
          ...state.info.instantReward,
          [action.key]: action.value,
        },
      },
    };
  }

  return {
    ...state,
    [action.key]: action.value,
  };
}

export default GotchaForm;
