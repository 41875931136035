import { ChallengeTextOnlyDescriptionModule } from '@chlngers/core/lib/src/types/challenge';
import { DescriptionTextOnlyModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const convertData2Form: (
  module: ChallengeTextOnlyDescriptionModule,
) => DescriptionTextOnlyModuleFormType = (module) => {
  return {
    type: module.type,
    mainHeader: module.mainHeader || '',
    subHeader: module.subHeader || '',
    body: module.body || '',
  };
};

export const convertForm2Data: (
  module: DescriptionTextOnlyModuleFormType,
) => ChallengeTextOnlyDescriptionModule = (module) => {
  if (!module) throw Error("this shouldn't be happened");
  return module;
};
