import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const BoardMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const [pathname, setPathname] = useState('');

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const menus: MenuItem[] = [
    { text: '공지사항', value: '/boards' },
    { text: 'FAQ', value: '/faqs' },
  ];

  const _click = (menu: MenuItem) => {
    history.push(menu.value);
  };
  return (
    <>
      <Menu fluid secondary vertical>
        {menus.map((menu) => (
          <Menu.Item
            key={menu.value}
            active={menu.value === pathname}
            onClick={() => _click(menu)}
          >
            {menu.text}
          </Menu.Item>
        ))}
      </Menu>
    </>
  );
};

export default BoardMenu;

type MenuItem = { text: string; value: string };
