import { useShallow } from 'zustand/react/shallow';
import { swap } from '@utils/js.utils';
import { useCallback } from 'react';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { DescriptionModuleFormType } from '../../NewChallengeForm.types';

export const useDescriptionExtra = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      descriptionExtra: s.challenge.descriptionExtra || [],
    })),
  );

export const useDescriptionExtraAction = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const addDescriptionExtra = useCallback(
    (newItem: DescriptionModuleFormType) => {
      updateChallengeForm((prev) => {
        return {
          ...prev,
          descriptionExtra: [...prev.descriptionExtra, newItem],
        };
      });
    },
    [updateChallengeForm],
  );

  const removeDescriptionExtra = useCallback(
    (index: number) => {
      updateChallengeForm((prev) => {
        return {
          ...prev,
          descriptionExtra: prev.descriptionExtra.filter((_, i) => i !== index),
        };
      });
    },
    [updateChallengeForm],
  );

  const swapDescriptionExtra = useCallback(
    (aIndex: number, bIndex: number) => {
      updateChallengeForm((prev) => {
        const totalLength = prev.descriptionExtra.length;
        if (aIndex < 0 || aIndex >= totalLength) return prev;
        if (bIndex < 0 || bIndex >= totalLength) return prev;

        return {
          ...prev,
          descriptionExtra: swap(prev.descriptionExtra, aIndex, bIndex),
        };
      });
    },
    [updateChallengeForm],
  );

  const updateDescriptionExtra = useCallback(
    (
      index: number,
      item:
        | ((prev: DescriptionModuleFormType[]) => DescriptionModuleFormType[])
        | DescriptionModuleFormType,
    ) => {
      if (typeof item === 'function') {
        updateChallengeForm((prev) => {
          const result = item(prev.descriptionExtra);
          return {
            ...prev,
            descriptionExtra: result,
          };
        });
        return;
      }
      updateChallengeForm((prev) => {
        const updatedDescriptionExtra = [...prev.descriptionExtra];
        updatedDescriptionExtra[index] = item;
        return {
          ...prev,
          descriptionExtra: updatedDescriptionExtra,
        };
      });
    },
    [updateChallengeForm],
  );

  return {
    addDescriptionExtra,
    removeDescriptionExtra,
    swapDescriptionExtra,
    updateDescriptionExtra,
  };
};
