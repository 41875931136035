import { ChallengeChallengeCardDescriptionModule } from '@chlngers/core/lib/src/types/challenge';
import { DescriptionChallengeModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const convertData2Form: (
  module: ChallengeChallengeCardDescriptionModule,
) => DescriptionChallengeModuleFormType = (module) => {
  return {
    type: module.type,
    mainHeader: module.mainHeader || '',
    subHeader: module.subHeader || '',
    ids: module.ids || [],
  };
};

export const convertForm2Data: (
  module: DescriptionChallengeModuleFormType,
) => ChallengeChallengeCardDescriptionModule = (module) => {
  if (!module) throw Error("this shouldn't be happened");
  return module;
};
