import React from 'react';
import { Button, Form, Input, Modal, TextArea } from 'semantic-ui-react';

function AddTemplateModal({
  addTemplateModalOpen,
  closeAddTemplateModal,
  onAddQuestionTemplate,
}: {
  addTemplateModalOpen: boolean;
  closeAddTemplateModal: () => void;
  onAddQuestionTemplate: any;
}) {
  const [addTemplateTitle, setAddTemplateTitle] = React.useState<string>('');
  const [addTemplateContent, setAddTemplateContent] =
    React.useState<string>('');
  const addQuestionTemplate = async () => {
    if (!addTemplateTitle || !addTemplateContent) return;
    await onAddQuestionTemplate({
      title: addTemplateTitle,
      content: addTemplateContent,
    });
    closeAddTemplateModal();
  };
  return (
    <Modal
      size="small"
      open={addTemplateModalOpen}
      onClose={closeAddTemplateModal}
    >
      <Modal.Header>템플릿 추가</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>템플릿명</label>
            <Input
              value={addTemplateTitle}
              onChange={(_, { value }) => setAddTemplateTitle(value)}
              size="small"
              name="text"
              placeholder="템플릿명"
            />
          </Form.Field>
          <Form.Field>
            <label>내용</label>
            <TextArea
              value={addTemplateContent}
              onChange={(_, { value }) => setAddTemplateContent(String(value))}
              rows={10}
              name="message"
              placeholder="내용"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" content="추가" onClick={addQuestionTemplate} />
        <Button color="black" content="닫기" onClick={closeAddTemplateModal} />
      </Modal.Actions>
    </Modal>
  );
}

export default AddTemplateModal;
