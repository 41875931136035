import { GoalDetailInfo } from '@types';

export const DEFAULT_GOAL: GoalDetailInfo = {
  id: 0,
  title: '',
  goalCategoryId: 0,
  cycle: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
  dayOrPeriod: 'DAY',
  totalAchieveCountPerCycle: 1,
  maxAchieveCountPerDay: 1,
  achievementIntervalMinute: 0,
  countDown: false,
  restInHoliday: false,
  totalDays: 14,
  dueStartTime: '00:00:00',
  dueEndTime: '23:59:59',
  targetQuestion: '',
  targetText: '',
  unit: '',
  targetOrder: 'ASC',
  timePrintSize: 'S',
  totalDaysOrder: 'ASC',
  scoreOrBinary: 'score',
  isGalleryPossible: false,
  isAchievementPublic: true,
  isDeleted: false,
  createdAt: '',
  updatedAt: '',
  goalCategory1: '',
  goalCategory2: '',
  goalCategory3: '',
  goalCategory4: '',
  goalType: 'NORMAL',
};
