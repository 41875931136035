import { Race } from '@types';
import React from 'react';
import { Image, Table } from 'semantic-ui-react';

const RaceImageInfo = ({ race }: { race: Race.RaceAllInfoForAdmin }) => {
  if (!race) return null;
  return (
    <>
      <h3>이미지</h3>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>썸네일</Table.HeaderCell>
            <Table.HeaderCell>대회카드</Table.HeaderCell>
            <Table.HeaderCell>메달</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Image size={'small'} src={race.thumbnailImageUrl} />
            </Table.Cell>
            <Table.Cell style={{ verticalAlign: 'top' }}>
              <Image size={'small'} src={race.cardImageUrl} />
            </Table.Cell>
            <Table.Cell>
              <Image size={'small'} src={race.medalImageUrl} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

export default RaceImageInfo;
