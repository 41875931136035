import { REHYDRATE } from 'redux-persist';
import { Map } from 'immutable';
import { types as appTypes } from './App.action';
import { types as navigationTypes } from '../Navigation/Navigation.action';
import api, { apiMedia, apiGo, setAuthToken } from '../../api';

const initialState = Map({
  token: '',
  roles: [],
});

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case navigationTypes.SET_TOKEN__NAVIGATION:
    case appTypes.SET_TOKEN__APP:
      return state.set('token', action.token);
    case appTypes.SET_ROLES__APP:
      return state.set('roles', action.roles);
    case appTypes.SET_USER__APP:
      return state.set('user', action.user);
    case REHYDRATE:
      if (action.payload) {
        setAuthToken(api, action.payload.app.get('token'));
        setAuthToken(apiMedia, action.payload.app.get('token'));
        setAuthToken(apiGo, action.payload.app.get('token'));
      }
      return state;
    default:
      return state;
  }
};

export default appReducer;
