import moment from 'moment-timezone';
import React from 'react';
import { Button, Header, Table } from 'semantic-ui-react';

const PurchaseChallengeInfo = ({
  title,
  purchaseChallengeParticipants,
  showCancelModal,
}) => {
  const PurchaseChallengeParticipantList = purchaseChallengeParticipants.map(
    (participant, idx) => {
      return (
        <Table.Row key={idx}>
          <Table.Cell textAlign="center" collapsing>
            (신청시간(한국시간기준)
            <br />
            {moment(participant.registeredAt).format('YYYY-MM-DD HH:mm:ss')})
            <Button
              size="tiny"
              value={participant.id}
              onClick={showCancelModal}
            >
              신청취소
            </Button>
          </Table.Cell>
          <Table.Cell>
            [구매] [{participant.id}] [
            <a
              href={`${process.env.REACT_APP_SALES_BOOSTER_URL}/campaigns/${participant.purchaseCampaignId}/challenges/${participant.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              상세
            </a>
            {' / '}
            <a
              href={`${process.env.REACT_APP_SALES_BOOSTER_URL}/campaigns/${participant.purchaseCampaignId}/challenges/${participant.id}/edit`}
              target="_blank"
              rel="noopener noreferrer"
            >
              편집
            </a>
            ]<h5 style={{ marginTop: 0 }}>{participant.title}</h5>
          </Table.Cell>
          <Table.Cell textAlign="center" collapsing>
            {`${participant.cashDeposit.toLocaleString()}원`}
          </Table.Cell>
          <Table.Cell textAlign="center" collapsing>
            {moment(participant.startDate).format('YYYY-MM-DD')}
          </Table.Cell>
          <Table.Cell textAlign="center" collapsing>
            {moment(participant.endDate).format('YYYY-MM-DD')}
          </Table.Cell>
          <Table.Cell width={7}>
            <a
              href={`${process.env.REACT_APP_SALES_BOOSTER_URL}/confirm-photo/${participant.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              인증카드 목록보기
            </a>
          </Table.Cell>
        </Table.Row>
      );
    },
  );
  return (
    <React.Fragment>
      <Header as="h5">{title}</Header>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>신청일</Table.HeaderCell>
            <Table.HeaderCell>챌린지명</Table.HeaderCell>
            <Table.HeaderCell>참가금액</Table.HeaderCell>
            <Table.HeaderCell>시작일</Table.HeaderCell>
            <Table.HeaderCell>종료일</Table.HeaderCell>
            <Table.HeaderCell>인증샷</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {PurchaseChallengeParticipantList.length > 0 ? (
            PurchaseChallengeParticipantList
          ) : (
            <Table.Row>
              <Table.Cell>챌린지 참가내역이 없습니다.</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default PurchaseChallengeInfo;
