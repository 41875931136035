import { FC, useRef, useState } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import ScrollBottomComponent from '../ScrollBottomComponent/ScrollBottomComponent';

type Props = {
  placeholder?: string;
  search?: boolean;
  value?: DropdownItemProps;
  disabled?: boolean;
  upward?: boolean;
  options: DropdownItemProps[];
  disableNoneSelect?: boolean;
  onChange?: (option?: DropdownItemProps) => void;
  onSearchChange?: (event: React.SyntheticEvent<HTMLElement, Event>) => void;
  onReachEnd?: () => void;
  style?: React.CSSProperties;
};
const DropDownUIComponent: FC<Props> = (props) => {
  const {
    placeholder,
    search,
    value,
    disabled,
    upward,
    options,
    disableNoneSelect,
    onChange,
    onSearchChange,
    onReachEnd,
    style,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpened, setIsOpened] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  return (
    // eslint-disable-next-line
    <div
      role="button"
      tabIndex={0}
      className={`ui dropdown selection fluid ${isOpened ? 'active' : ''} ${
        upward ? 'upward' : ''
      } ${search ? 'search' : ''}`}
      onClick={() => !disabled && setIsOpened((v) => !v)}
      style={style}
    >
      {!!search && (
        <input
          ref={inputRef}
          aria-autocomplete="list"
          autoComplete="off"
          className="search"
          tabIndex={0}
          type="text"
          disabled={disabled}
          onChange={onSearchChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
            if (inputRef.current) inputRef.current.value = '';
          }}
        />
      )}

      {!isFocused && <div className="text">{value?.text || placeholder}</div>}

      <i className="dropdown icon" />
      {isOpened && (
        <div className="visible menu transition">
          {/* eslint-disable-next-line */}
          <div
            role="button"
            tabIndex={0}
            className="item"
            style={disableNoneSelect ? { display: 'none' } : {}}
            onClick={() => onChange?.(undefined)}
          >
            <span className="text">선택 없음</span>
          </div>
          {options.map((o, i) => (
            // eslint-disable-next-line
            <div
              role="button"
              tabIndex={0}
              className="item"
              key={i}
              onClick={() => onChange?.(o)}
            >
              <span className="text">{o.text}</span>
            </div>
          ))}
          <ScrollBottomComponent onReachEnd={onReachEnd} />
        </div>
      )}
    </div>
  );
};

export default DropDownUIComponent;
