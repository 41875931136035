import RadioSelector from '@component/RadioSelector/RadioSelector';
import Accordion from '@component/Accordion/Accordion';
import { ChallengeCustomField } from 'src/types/customField.types';
import { validate } from './NaverIdRequiredForm.validator';
import {
  useNaverIdRequired,
  useNaverIdRequiredAction,
} from './NaverIdRequiredForm.store';
import * as S from './NaverIdRequiredForm.style';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './NaverIdRequiredForm.serializer';

const options = [
  { label: '필수', value: true },
  { label: '필요없음', value: false },
];

const NaverIdRequiredForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'naverIdRequired'>,
  Extract<ChallengeCustomField, { name: 'naverIdRequired' }>
> = () => {
  const { naverIdRequired } = useNaverIdRequired();
  const { setNaverIdRequired } = useNaverIdRequiredAction();

  return (
    <Accordion open={false} title="네이버 아이디 계정 연동 필수 여부" as="h5">
      <S.Container>
        <RadioSelector
          style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
          selectedOption={options.find(
            ({ value }) => value === naverIdRequired,
          )}
          options={options}
          onChange={({ value }) => setNaverIdRequired(value)}
        />
      </S.Container>
    </Accordion>
  );
};

export default NaverIdRequiredForm;
NaverIdRequiredForm.validate = validate;
NaverIdRequiredForm.convertData2Form = convertData2Form;
NaverIdRequiredForm.convertForm2Data = convertForm2Data;
