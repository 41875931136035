import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { formatLocalDateTime } from '@utils/date.utils';
import { getInitialDates } from '../../NewChallengeForm.util';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'purchaseStartDate'>,
  Pick<ReqCreateOrUpdateChallenge, 'purchaseStartDate'>
> = ({ purchaseStartDate }) => {
  if (!purchaseStartDate?.length)
    throw Error("[PurchaseStartDateForm] this shouldn't be happened");

  return { purchaseStartDate };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'purchaseStartDate'>,
  Pick<ChallengeFormType, 'purchaseStartDate'>
> = ({ purchaseStartDate }) => ({
  purchaseStartDate:
    purchaseStartDate ?? getInitialDates(new Date()).purchaseStartDate,
});
