import { OliveYoungReviewModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const DEFAULT_DATA: {
  type: 'COLLABO-OLIVEYOUNG-REVIEW';
  data: OliveYoungReviewModuleFormType;
} = {
  type: 'COLLABO-OLIVEYOUNG-REVIEW',
  data: {
    purchaseRequiredTextList: [
      {
        text: '안내된 구매 기한 내 상품을 구매하지 않으시면 정상적인 챌린지 참가 및 인증이 어려우며, 상금을 받으실 수 없습니다. 이로 인해 발생하는 불이익은 챌린저스 및 제휴사에서 책임지지 않으니 동의하시는 분만 신청해주세요.',
        type: 'normal',
        active: true,
      },
      {
        text: '상금은 레드카드 발급 이력이 없고, 인증패스 사용 없이 100% 달성하신 분들에게 지급됩니다.',
        type: 'normal',
        active: true,
      },
      {
        text: '챌린저스에서 인증샷을 전수 검사하며, 인증샷 도용과 조작 및 인증 규정과 무관한 인증샷을 올리실 경우 챌린지 참여가 예고없이 취소 및 레드카드가 발급되며, 추후 제휴 챌린지 신청 시 불이익을 받으실 수 있습니다.',
        type: 'highlight',
        active: true,
      },
      {
        text: '리뷰 작성 후 반품이 확인될 시 포인트 회수 및 블랙리스트로 등록될 수 있습니다.',
        type: 'highlight',
        active: true,
      },
      {
        text: '',
        type: 'normal',
        active: false,
      },
      {
        text: '',
        type: 'normal',
        active: false,
      },
    ],
    subModule: [],
    productThumbnailImageUrl: '',
    price: 0,
    originalPrice: 0,
    tipTextList: [
      {
        text: '구매 일자, 구매 제품, 받는사람, 연락처를 확인할 수 있도록 캡처해 주세요. ',
        type: 'normal',
      },
      {
        text: '제품 구매 기간 내 제품 즉시 발송가능 수량 소진으로 ‘품절’이 일어날 경우 ‘예약 배송’ 으로 구매 부탁드립니다. 제품 구매 기간 ‘품절’ 상태가 지속되어 ‘예약 배송’ 으로 구매하지 못하신 경우, 별도로 문자 안내 및 1&2회차 인증 통과 처리를 도와드립니다.',
        type: 'highlight',
      },
      {
        text: '구매한 제품이 여러개일 경우, 구매 일자와 배송지 정보가 한번에 캡처되지 않을 수 있습니다. 이 경우 [구매일자][구매제품][이름&전화번호]가 보이는 캡쳐본을 이어붙인 후 편집된 이미지를 업로드해 주세요.  ',
        type: 'normal',
      },
    ],
    reviewText: '',
    reviewGuide: `제품을 '사용한 사진을 인증해' 주세요!`,
    reviewSubGuide: 'ex. 제품 개봉 및 사용 컷(손등/얼굴 등)',
    reviewImageUrls: [],
    pleaseCheckTextList: [
      {
        text: '제품 구매/배송/환불 등은 <올리브영 공식 온라인몰>에 문의해 주세요.',
        type: 'normal',
        active: true,
      },
      {
        text: '신청 시 개인정보가 올바르게 기재되지 않은 분은 신청이 취소될 수 있어요. 신청 전 마이페이지에서 확인해 주세요.',
        type: 'normal',
        active: true,
      },
      {
        text: '디바이스 당 1명만 신청할 수 있습니다. 동일한 디바이스에서 다중 신청 시 신청 순서가 늦은 참가자는 자동으로 신청이 취소됩니다.',
        type: 'normal',
        active: true,
      },
      {
        text: '우수 후기자 경품 발송 위하여 챌린지 신청 시 배송정보를 수집합니다.',
        type: 'normal',
        active: true,
      },
      {
        text: '제휴 챌린지 모집 기간 종료 후 취소 요청을 1달 내 3회 이상 하시는 분들은 추후 제휴챌린지 참여에 제한이 있을 수 있습니다.',
        type: 'normal',
        active: true,
      },
      {
        text: '재판매 등 부정한 목적 및 영리 추구를 위한 챌린지 참여가 확인 될 경우 서비스 이용 정지 및 손해배상 청구가 진행 될 수 있습니다. [서비스 이용 약관 참고]',
        type: 'normal',
        active: true,
      },
      {
        text: '',
        type: 'normal',
        active: false,
      },
    ],
  },
};
