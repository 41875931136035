import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Input,
  Loader,
  Menu,
  Modal,
  Table,
  Grid,
  Pagination,
} from 'semantic-ui-react';
import { addTag, deleteTag, getTags, updateTag } from '../../api';
import _ from 'lodash';
import TagMenu from './TagMenu/TagMenu';

const PAGE_LIMIT = 15;

const NewTag = () => {
  const [loaded, setLoaded] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [targetTag, setTargetTag] = useState({});
  const [page, setPage] = useState(1);
  const [modalType, setModalType] = useState('');
  const [addOrEditModalOpen, setAddOrEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [addInput, setAddInput] = useState('');

  const [keyword, setKeyword] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');

  const _getTags = async ({ page, keyword = '' }) => {
    return getTags({
      offset: PAGE_LIMIT * (page - 1),
      limit: PAGE_LIMIT,
      keyword,
    });
  };

  useEffect(() => {
    _getTags({
      page,
      keyword: searchKeyword,
    }).then(({ tags, total }) => {
      setTags(tags);
      setTotalCount(total);

      let _tagOptions = [];
      for (let i = 0; i < tags.length; i++) {
        _tagOptions.push({
          key: tags[i].id,
          text: tags[i].tag,
          value: tags[i].id,
        });
      }
      setTagOptions(_tagOptions);
    });

    setLoaded(true);
  }, [page, searchKeyword]);

  const showAddOrEditModal = (type, tag) => {
    setAddOrEditModalOpen(true);
    setModalType(type);
    if (tag) setTargetTag(tag);
  };

  const closeAddOrEditModal = () => {
    setAddOrEditModalOpen(false);
    setModalType('');
    setTargetTag({});
  };

  const showDeleteModal = (tag) => {
    setDeleteModalOpen(true);
    setTargetTag(tag);
  };

  const closeDeleteModal = () => setDeleteModalOpen(false);

  const handleTagAddition = (e, { value }) => {
    let _tagOptions;
    if (targetTag.id) {
      _tagOptions = tagOptions.map((item) => {
        if (item.value === targetTag.id) item.text = value;
        return item;
      });
    } else {
      _tagOptions = [{ text: value, value }, ...tagOptions];
    }
    setTagOptions(_tagOptions);
    setTargetTag({ id: targetTag.id ?? value, tag: value });
    setAddInput(value);
  };

  const handleChangeKeyword = (e, { name, value }) => {
    setKeyword(value);
  };

  const handleKeyPress = (e) => {
    if (event.key === 'Enter') {
      setSearchKeyword(keyword);
    }
  };

  const searchTags = () => {
    setSearchKeyword(keyword);
  };

  const initializeTags = () => {
    setSearchKeyword('');
    setKeyword('');
    setPage(1);
  };

  const handleChangeTag = (e, { value }) => {
    if (targetTag.id && typeof value === 'number') {
      alert('기존에 존재하는 태그로는 변경할 수 없어요.');
      return;
    }
    setDisabled(typeof value === 'number');
  };

  const _addTag = async () => {
    const { tag } = await addTag({
      tag: addInput,
      interestText: '',
      category: 'WORD',
      imageUrl: '',
    });
    alert(`[${addInput}] 태그 추가가 완료되었어요.`);
    const _tags = [tag, ...tags];

    setTags(_tags);

    closeAddOrEditModal();
  };

  const _editTag = async () => {
    const { tag } = await updateTag(targetTag.id, { tag: addInput });
    alert(`태그 수정이 완료되었어요.`);
    const _tags = tags.map((item) => {
      if (item.id === targetTag.id) item.tag = tag.tag;
      return item;
    });
    const _tagOptions = tagOptions.map((item) => {
      if (item.value === targetTag.id) item.text = tag.tag;
      return item;
    });
    setTags(_tags);
    setTagOptions(_tagOptions);
    setTargetTag({});
    closeAddOrEditModal();
  };

  const _deleteTag = async () => {
    await deleteTag(targetTag.id);
    alert(`[${targetTag.tag}] 태그 삭제가 완료되었어요.`);
    const _tags = tags.filter((item) => item.id !== targetTag.id);
    const _tagOptions = tagOptions.filter(
      (item) => item.value !== targetTag.id,
    );
    setTags(_tags);
    setTagOptions(_tagOptions);
    setTargetTag({});
    closeDeleteModal();
  };

  return (
    <div style={{ margin: 40 }}>
      <Grid columns="equal">
        <Grid.Column width={2}>
          <TagMenu />
        </Grid.Column>
        <Grid.Column>
          {!loaded ? (
            <Loader active inline="centered" />
          ) : (
            <>
              <div>
                <h5>검색</h5>
                <Input
                  size="small"
                  name="keyword"
                  placeholder="태그를 입력하세요"
                  onChange={handleChangeKeyword}
                  onKeyPress={handleKeyPress}
                />
                <Button size="small" onClick={searchTags}>
                  검색
                </Button>
                <Button size="small" onClick={initializeTags}>
                  초기화
                </Button>
                <Button
                  size="mini"
                  floated={'right'}
                  onClick={() => showAddOrEditModal('추가')}
                >
                  추가
                </Button>
              </div>
              <Table basic="very">
                <Table.Header fullWidth>
                  <Table.Row>
                    <Table.HeaderCell>NO</Table.HeaderCell>
                    <Table.HeaderCell>id</Table.HeaderCell>
                    <Table.HeaderCell>태그명</Table.HeaderCell>
                    <Table.HeaderCell>사용개수</Table.HeaderCell>
                    <Table.HeaderCell>수정/삭제</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {tags.map((item, idx) => (
                    <Table.Row key={item.id}>
                      <Table.Cell>
                        {(page - 1) * PAGE_LIMIT + idx + 1}
                      </Table.Cell>
                      <Table.Cell>{item.id}</Table.Cell>
                      <Table.Cell>{item.tag}</Table.Cell>
                      <Table.Cell>{item.count}</Table.Cell>
                      <Table.Cell>
                        <Button
                          size="mini"
                          onClick={() => showAddOrEditModal('수정', item)}
                        >
                          수정
                        </Button>
                        <Button
                          size="mini"
                          disabled={item.count !== 0}
                          onClick={() => showDeleteModal(item)}
                        >
                          삭제
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Pagination
                defaultActivePage={1}
                totalPages={Math.ceil(totalCount / PAGE_LIMIT)}
                onPageChange={(_, { activePage }) => {
                  setPage(activePage);
                }}
              />
              <Modal
                size="tiny"
                open={addOrEditModalOpen}
                onClose={closeAddOrEditModal}
              >
                <Modal.Header>태그 {modalType}</Modal.Header>
                <Modal.Content>
                  <Form>
                    <Form.Field>
                      <label>태그</label>
                      <Dropdown
                        placeholder="태그를 선택하세요."
                        fluid
                        search
                        selection
                        allowAdditions
                        name="tagId"
                        options={tagOptions}
                        value={targetTag.id}
                        onAddItem={handleTagAddition}
                        onChange={handleChangeTag}
                      />
                    </Form.Field>
                  </Form>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    disabled={disabled}
                    color={'blue'}
                    onClick={modalType === '추가' ? _addTag : _editTag}
                  >
                    {modalType}
                  </Button>
                  <Button color={'black'} onClick={closeAddOrEditModal}>
                    닫기
                  </Button>
                </Modal.Actions>
              </Modal>

              <Modal
                size="tiny"
                open={deleteModalOpen}
                onClose={closeDeleteModal}
              >
                <Modal.Header>태그 삭제</Modal.Header>
                <Modal.Content>
                  [{targetTag.tag}] 태그를 삭제하시겠습니까?
                </Modal.Content>
                <Modal.Actions>
                  <Button color={'blue'} onClick={_deleteTag}>
                    삭제
                  </Button>
                  <Button color={'black'} onClick={closeDeleteModal}>
                    닫기
                  </Button>
                </Modal.Actions>
              </Modal>
            </>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default NewTag;
