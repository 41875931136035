import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Table } from 'semantic-ui-react';
import { raceTypeOptions } from '../../../../../constant/race';
import { Race } from '@types';

const RaceInfo = ({ race }: { race: Race.RaceAllInfoForAdmin }) => {
  if (!race) return null;
  return (
    <>
      <h3>대회 기본 정보</h3>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>id</Table.HeaderCell>
            <Table.HeaderCell>제목</Table.HeaderCell>
            <Table.HeaderCell>주제</Table.HeaderCell>
            <Table.HeaderCell>모집기간</Table.HeaderCell>
            <Table.HeaderCell>진행기간</Table.HeaderCell>
            <Table.HeaderCell>대회참가인원</Table.HeaderCell>
            <Table.HeaderCell>시즌명</Table.HeaderCell>
            <Table.HeaderCell>기본가격</Table.HeaderCell>
            <Table.HeaderCell>배송비</Table.HeaderCell>
            <Table.HeaderCell>수정</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>{race.id}</Table.Cell>
            <Table.Cell>{race.title}</Table.Cell>
            <Table.Cell>
              {
                raceTypeOptions.find(({ value }) => value === race.raceType)
                  ?.text
              }
            </Table.Cell>
            <Table.Cell>
              {moment(race.registerStartDate).format('YY.MM.DD')}~
              {moment(race.registerEndDate).format('YY.MM.DD')}
            </Table.Cell>
            <Table.Cell>
              {moment(race.startDate).format('YY.MM.DD')}~
              {moment(race.endDate).format('YY.MM.DD')}
            </Table.Cell>
            <Table.Cell>{race.maxRegisterCount}</Table.Cell>
            <Table.Cell>{race.seasonTerm}</Table.Cell>
            <Table.Cell>{race.defaultPrice}</Table.Cell>
            <Table.Cell>{race.shippingFee}</Table.Cell>
            <Table.Cell>
              <Button
                size={'mini'}
                content={'수정'}
                as={Link}
                to={{ pathname: `/race/${race.id}/edit` }}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

export default RaceInfo;
