import { FC, useEffect, useRef } from 'react';

type Props = {
  onReachEnd?: () => void;
};
const ScrollBottomComponent: FC<Props> = ({ onReachEnd }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    const osbserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        onReachEnd?.();
      }
    });

    osbserver.observe(ref.current);

    // eslint-disable-next-line consistent-return
    return () => {
      osbserver.disconnect();
    };
  }, [onReachEnd]);

  return <div ref={ref} style={{ height: 1 }} />;
};

export default ScrollBottomComponent;
