import { ChallengeHyperlinkDescriptionModule } from '@chlngers/core/lib/src/types/challenge';
import { DescriptionHyperlinkModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const convertData2Form: (
  module: ChallengeHyperlinkDescriptionModule,
) => DescriptionHyperlinkModuleFormType = (module) => {
  return {
    type: module.type,
    urlText: module.urlText || '',
    url: module.url || '',
    backgroundColor: module.backgroundColor || '',
    buttonColor: module.buttonColor || '',
    textColor: module.textColor || '',
  };
};

export const convertForm2Data: (
  module: DescriptionHyperlinkModuleFormType,
) => ChallengeHyperlinkDescriptionModule = (module) => {
  if (!module) throw Error("this shouldn't be happened");
  return module;
};
