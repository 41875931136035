import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const usePassword = () => {
  return useChallengeFormStore(
    useShallow((s) => ({ password: s.challenge.password })),
  );
};

export const usePasswordActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setPassword = (password: string) => updateChallengeForm({ password });

  return { setPassword };
};
