import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { apis } from '../../api';
import {
  getNextPageParam,
  withTypedInfiniteLoad,
} from '../../utils/query.utils';
import {
  InfiniteQueryHookOptions,
  QuerySelectHookOptions,
} from '../query.types';
import getQueryKey from './getQueryKey';

export const useGetCompaniesProducts = (
  options?: InfiniteQueryHookOptions<typeof apis.commerce.getCompaniesProducts>,
) => {
  return useInfiniteQuery({
    queryKey: getQueryKey('COMPANIES_PRODUCTS'),
    queryFn: withTypedInfiniteLoad(apis.commerce.getCompaniesProducts, {}, 40),
    initialPageParam: 0,
    getNextPageParam,
    ...options,
  });
};

export const useGetAllCompaniesProducts = (
  options?: QuerySelectHookOptions<
    typeof apis.commerce.getAllCompaniesProducts
  >,
) => {
  return useQuery({
    queryKey: getQueryKey('ALL_COMPANIES_PRODUCTS'),
    queryFn: () => apis.commerce.getAllCompaniesProducts(),
    ...options,
    select: (res) => res.companies,
  });
};
