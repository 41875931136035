import { Race } from '@types';
import * as S from '../../../../Style';
import { ColorSample } from '@component/ColorSample';

const ShareBoardModule = ({
  idx,
  module,
  handleChange,
}: {
  idx: number;
  module: Race.RaceShareBoardDescriptionModule;
  handleChange: any;
}) => {
  return (
    <>
      <h4>공유 모듈</h4>
      <S.RowContainer>
        <S.RowFormField>
          <label>배경색</label>
          <input
            value={module.bgColor}
            onChange={(e) => handleChange(idx, 'bgColor', e.target.value)}
          />
          <ColorSample color={module.bgColor} />
        </S.RowFormField>
        <S.RowFormField>
          <label>글씨색</label>
          <input
            value={module.textColor}
            onChange={(e) => handleChange(idx, 'textColor', e.target.value)}
          />
          <ColorSample color={module.textColor} />
        </S.RowFormField>
        <S.RowFormField>
          <label>글씨</label>
          <input
            value={module.title}
            onChange={(e) => handleChange(idx, 'title', e.target.value)}
          />
        </S.RowFormField>
      </S.RowContainer>
    </>
  );
};

export default ShareBoardModule;
