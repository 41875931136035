import React, { Component } from 'react';
import {
  Container,
  Button,
  Menu,
  Modal,
  Form,
  Input,
  Dropdown,
  Image,
  Divider,
  Loader,
} from 'semantic-ui-react';
import _ from 'lodash';
import getLocal, { setLocal } from '../../global/local';
import CompanyTable from './CompanyTable';
import ProductTable from './ProductTable';
import { apis } from '../../api';
import { handleChangeSingleFile } from '../../utils/uploadImage';

class Commerce extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
      column: null,
      direction: null,
      activeItem:
        getLocal('commerceActiveItem') === null
          ? '회사'
          : getLocal('commerceActiveItem'),
      companies: [],
      companyOptions: [],
      products: [],
      companyAddModalOpen: false,
      productAddModalOpen: false,
      checkedAll: false,
      checkedIds: [],
      logoImageUrl: '',
    };
    this.input = {
      companyName: '',
      description: '',
      homepageUrl: '',
      companyId: '',
      productName: '',
      price: '',
      fee: '',
      shippingPrice: '',
      isAddressNeeded: 'true',
    };
  }

  async componentDidMount() {
    const res = await Promise.all([
      apis.getCompaniesWithProductsAndPills(),
      apis.getProductsWithChallenges(),
    ]);

    const { companies } = res[0];
    const companyOptions = [];
    companies.forEach((c) => {
      companyOptions.push({ key: c.id, text: c.name, value: c.id });
    });

    const { products } = res[1];

    this.setState({ companies, companyOptions, products, isMounted: true });
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    setLocal('commerceActiveItem', name);
  };
  handleInput = (e) => {
    this.input[e.target.name] = e.target.value;
  };
  handleChangeSingleFile = async (e) => {
    const temp = await handleChangeSingleFile(e, {});
    this.setState({ logoImageUrl: temp.logoImageUrl });
  };

  showCompanyAddModal = () => this.setState({ companyAddModalOpen: true });
  closeCompanyAddModal = () => this.setState({ companyAddModalOpen: false });
  addCompany = async () => {
    const { companyName: name, description, homepageUrl } = this.input;
    const { companies, companyOptions, logoImageUrl } = this.state;
    const res = await apis.addCompany({
      name,
      description,
      logoImageUrl,
      homepageUrl,
    });
    if (res?.response?.data?.err) {
      return;
    }

    const { company } = res;
    company.products = [];
    companies.splice(0, 0, company);
    companyOptions.push({
      key: company.id,
      text: company.name,
      value: company.id,
    });
    this.setState({ companies, companyOptions, companyAddModalOpen: false });
  };

  showProductAddModal = () => this.setState({ productAddModalOpen: true });
  closeProductAddModal = () => this.setState({ productAddModalOpen: false });
  addProduct = async () => {
    const {
      companyId,
      productName: name,
      price,
      fee,
      shippingPrice,
      isAddressNeeded,
    } = this.input;
    const { products, companies } = this.state;
    const { product } = await apis.addProduct({
      companyId: Number(companyId),
      name: name.trim(),
      price: Number(price),
      fee: Number(fee),
      shippingPrice: Number(shippingPrice),
      isAddressNeeded: isAddressNeeded === 'true',
    });

    const company = _.find(companies, (c) => c.id === companyId);
    product.companyName = company.name;
    product.challenges = [];
    product.isAddressNeeded =
      product.isAddressNeeded === true ? 'true' : 'false';
    products.splice(0, 0, product);
    company.products.push(product);
    this.setState({ products, companies, productAddModalOpen: false });
  };
  handleRadioChange = (e, { name, value }) => {
    this.input[name] = value;
  };
  deleteCompany = (companyId) => {
    const { companies } = this.state;
    const newCompanies = _.filter(companies, (c) => c.id !== companyId);
    this.setState({ companies: newCompanies });
  };
  deleteProduct = (productId) => {
    const { products, companies } = this.state;
    let newProducts = [];
    _.forEach(products, (p) => {
      if (p.id === productId) {
        const company = _.find(companies, (c) => c.id === p.companyId);
        company.products = _.filter(
          company.products,
          (p) => p.id !== productId,
        );
      } else newProducts.push(p);
    });
    this.setState({ products: newProducts, companies });
  };
  handleSortCompany = (clickedColumn) => () => {
    const { column, companies, direction } = this.state;
    if (column !== clickedColumn) {
      const newCompanies = _.sortBy(companies, [clickedColumn]);
      this.setState({
        column: clickedColumn,
        companies: newCompanies,
        direction: 'ascending',
        isSorted: true,
      });
      return;
    }

    this.setState({
      companies: companies.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };
  handleSortProduct = (clickedColumn) => () => {
    const { column, products, direction } = this.state;
    if (column !== clickedColumn) {
      const newProducts = _.sortBy(products, [clickedColumn]);
      this.setState({
        column: clickedColumn,
        products: newProducts,
        direction: 'ascending',
        isSorted: true,
      });
      return;
    }
    this.setState({
      products: products.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };
  handleProductCheckbox = async (e, { value }) => {
    const { products, checkedIds, checkedAll } = this.state;
    if (value === 'all') {
      await this.setState({ checkedIds: [] });
      if (checkedAll) {
        _.map(products, (c) => (c.isChecked = !checkedAll));
        await this.setState({ checkedIds: [], checkedAll: !checkedAll });
      } else {
        _.map(products, (c) => {
          c.isChecked = !checkedAll;
          checkedIds.push(c.id);
        });
        await this.setState({ checkedIds, checkedAll: !checkedAll });
      }
      return;
    }
    const product = _.find(products, (c) => c.id === value);
    product.isChecked = product.isChecked ? !product.isChecked : true;
    if (product.isChecked && !checkedIds.includes(value))
      checkedIds.push(value);
    else if (!product.isChecked && checkedIds.includes(value)) {
      const index = checkedIds.indexOf(value);
      checkedIds.splice(index, 1);
    }
    this.setState({ products });
  };
  copyProducts = async () => {
    const { checkedIds, products } = this.state;
    const { products: newProducts } = await apis.common.copyProducts({
      ids: checkedIds,
    });
    this.setProductFormat([...newProducts, ...products]);
    this.setState({ checkedIds: [] });
    alert('복사가 완료되었어요.');
  };

  deleteProducts = async () => {
    const { checkedIds, products } = this.state;
    await apis.common.deleteProducts({ ids: checkedIds });
    const _products = products.filter((o) => !checkedIds.includes(o.id));
    this.setState({ products: _products, checkedIds: [] });
    alert('삭제가 완료되었어요.');
  };

  setProductFormat = (products) => {
    _.map(products, (p) => {
      p.isAddressNeeded = p.isAddressNeeded === true ? 'true' : 'false';
      p.isChecked = false;
    });
    this.setState({ products });
  };

  render() {
    const {
      activeItem,
      companies,
      companyOptions,
      products,
      companyAddModalOpen,
      productAddModalOpen,
      column,
      direction,
      logoImageUrl,
      isMounted,
    } = this.state;
    if (!isMounted) return <Loader active inline="centered" />;
    return (
      <Container fluid>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 40,
            marginRight: 40,
          }}
        >
          <div style={{ minWidth: 200 }}>
            <Menu fluid secondary vertical>
              <Menu.Item
                name="회사"
                active={activeItem === '회사'}
                onClick={this.handleItemClick}
              />
              <Menu.Item
                name="상품"
                active={activeItem === '상품'}
                onClick={this.handleItemClick}
              />
            </Menu>
            <Button
              style={styles.button}
              onClick={this.showCompanyAddModal}
              content="회사 추가"
            />
            <Button
              style={styles.button}
              onClick={this.showProductAddModal}
              content="상품 추가"
            />
          </div>
          <div style={{ margin: '0 20px' }}>
            {activeItem === '회사' && (
              <CompanyTable
                companies={companies}
                deleteCompany={this.deleteCompany}
                handleSort={this.handleSortCompany}
                column={column}
                direction={direction}
              />
            )}
            {activeItem === '상품' && (
              <ProductTable
                products={products}
                deleteProduct={this.deleteProduct}
                handleSort={this.handleSortProduct}
                column={column}
                direction={direction}
                companyOptions={companyOptions}
                handleCheckbox={this.handleProductCheckbox}
                copyProducts={this.copyProducts}
                deleteProducts={this.deleteProducts}
              />
            )}
          </div>
        </div>

        <Modal
          size="small"
          open={companyAddModalOpen}
          onClose={this.closeCompanyAddModal}
        >
          <Modal.Header>회사 추가</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>회사명</label>
                <Input
                  size="small"
                  name="companyName"
                  placeholder="회사명"
                  onChange={this.handleInput}
                />
              </Form.Field>
              <Form.Field>
                <label>설명</label>
                <Input
                  name="description"
                  placeholder="설명"
                  onChange={this.handleInput}
                />
              </Form.Field>
              <Form.Field>
                <label>로고</label>
                <Input
                  type="file"
                  name="logoImageUrl"
                  onChange={this.handleChangeSingleFile}
                />
                {!!logoImageUrl && <Image src={logoImageUrl} size="small" />}
              </Form.Field>
              <Form.Field>
                <label>홈페이지 URL</label>
                <Input
                  name="homepageUrl"
                  placeholder="홈페이지 주소"
                  onChange={this.handleInput}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" content="추가" onClick={this.addCompany} />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeCompanyAddModal}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="small"
          open={productAddModalOpen}
          onClose={this.closeProductAddModal}
        >
          <Modal.Header>상품 추가</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>회사</label>
                <Dropdown
                  placeholder="회사를 선택하세요."
                  fluid
                  search
                  selection
                  name="companyId"
                  options={companyOptions}
                  onChange={this.handleRadioChange}
                />
              </Form.Field>
              <Form.Field>
                <label>상품명</label>
                <Input
                  name="productName"
                  placeholder="상품명"
                  onChange={this.handleInput}
                />
              </Form.Field>
              <Form.Field>
                <label>가격</label>
                <Input
                  name="price"
                  placeholder="가격"
                  onChange={this.handleInput}
                />
              </Form.Field>
              <Form.Field>
                <label>수수료</label>
                <Input
                  name="fee"
                  placeholder="수수료"
                  onChange={this.handleInput}
                />
              </Form.Field>
              <Form.Field>
                <label>배송료</label>
                <Input
                  name="shippingPrice"
                  placeholder="배송료"
                  onChange={this.handleInput}
                />
              </Form.Field>
              <Form.Group grouped>
                <label>배송필요여부</label>
                <Form.Field
                  label="배송"
                  control="input"
                  type="radio"
                  name="isAddressNeeded"
                  value="true"
                  onChange={this.handleInput}
                />
                <Form.Field
                  label="미배송"
                  control="input"
                  type="radio"
                  name="isAddressNeeded"
                  value="false"
                  onChange={this.handleInput}
                />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" content="추가" onClick={this.addProduct} />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeProductAddModal}
            />
          </Modal.Actions>
        </Modal>

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
      </Container>
    );
  }
}

export default Commerce;

const styles = {
  button: {
    marginTop: 5,
  },
};
