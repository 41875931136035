import { TagDetailInfo } from '@types';

export const getSelectedTagsFromId = (
  tags: TagDetailInfo[],
  selectedTagIds: number[],
) => {
  return selectedTagIds
    .map((tagId) => tags.find(({ id }) => id === tagId))
    .filter((tag) => Boolean(tag)) as TagDetailInfo[];
};

export const getOptionsFromTags = (tags: TagDetailInfo[], keyword: string) => {
  if (!keyword.length) return [];
  return tags
    .filter(
      ({ tag, id }) => String(id).includes(keyword) || tag.includes(keyword),
    )
    .map(({ id, tag }, index) => ({
      key: `${id}-${index}`,
      text: `[${id}]${tag}`,
      value: id,
    }));
};

export const getCollaboTags = (tags: TagDetailInfo[]) => {
  return tags
    .filter((t) => {
      const tag = t.tag.replace(/\s/g, '');
      return (
        tag.includes('제품리뷰') ||
        tag.includes('서비스체험') ||
        tag.includes('제품체험') ||
        tag.includes('캠페인')
      );
    })
    .map(({ id, tag }, index) => ({
      text: `[${id}]${tag}`,
      value: id,
    }));
};
