import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Item } from '../../../PedometerGiftiShopBuyers/PedometerGiftiShopBuyers.types';
import { BenefitQueries } from '../../../../../../queries';

type ItemProps = Item;

const GiftiItem: FC<ItemProps> = (props) => {
  const { id, thumbnailImageUrl, isDisplayed, name, brand, price } = props;

  const { changeEnableState } = BenefitQueries.useGiftiShopItemMutation();

  const handleEnableState = useCallback(async () => {
    const confirmed = window.confirm(
      `${isDisplayed ? '비공개' : '공개'}처리를 진행 하시겠어요?`,
    );

    if (!confirmed) return;

    const res = await changeEnableState({ id, isDisplayed: !isDisplayed });
    if (!res) return;
    window.alert('완료했어요😊');
  }, [changeEnableState, id, isDisplayed]);

  return (
    <Container>
      <Row>{id}</Row>
      <Row>{brand}</Row>
      <Row>{name}</Row>
      <Row>{price.toLocaleString('kr-KR')}</Row>
      <Row>
        <Image src={thumbnailImageUrl} />
      </Row>
      <Row>
        <Button
          content={isDisplayed ? '비공개하기' : '공개하기'}
          color={isDisplayed ? 'black' : 'red'}
          onClick={handleEnableState}
        />
      </Row>
      <Row>
        <Button
          as={Link}
          content="수정하기"
          to={`/pedometer-gifti-shop/item/${id}/edit`}
        />
      </Row>
    </Container>
  );
};

export const Container = styled.div`
  width: 100%;
  flex: 1;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  font-weight: normal;
  color: #000000;
  padding: 8px;

  &:hover {
    background-color: #eeeeee44;
  }
`;
export const Row = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Image = styled.img`
  width: 120px;
  height: 120px;
`;
export default GiftiItem;
