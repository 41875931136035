import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { useMemo } from 'react';
import { DescriptionModuleForm } from './DescriptionModuleForm';
import {
  convertData2Form,
  convertForm2Data,
} from './DescriptionModuleForm.serializer';
import {
  useDescriptionModule,
  useDescriptionModuleAction,
} from './DescriptionModuleForm.store';
import { validate } from './DescriptionModuleForm.validator';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';

const DESCRIPTION_MODULE = 'descriptionModule';
const DescriptionModuleFormContainer: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'descriptionModule'>,
  Pick<ChallengeFormType, 'descriptionModule'>,
  Pick<ReqCreateOrUpdateChallenge, 'descriptionModule'>
> = () => {
  const { descriptionModule } = useDescriptionModule();
  const {
    addDescriptionModule,
    removeDescriptionModule,
    swapDescriptionModule,
    updateDescriptionModule,
  } = useDescriptionModuleAction();

  const validator = useMemo(() => {
    return validate(DESCRIPTION_MODULE);
  }, []);

  return (
    <DescriptionModuleForm
      label="챌린지 상세설명 모듈 작업"
      list={descriptionModule}
      validator={validator}
      onAdd={addDescriptionModule}
      onRemove={removeDescriptionModule}
      onSwap={swapDescriptionModule}
      onUpdate={updateDescriptionModule}
    />
  );
};

export default DescriptionModuleFormContainer;

DescriptionModuleFormContainer.validate = validate(DESCRIPTION_MODULE);
DescriptionModuleFormContainer.convertData2Form =
  convertData2Form(DESCRIPTION_MODULE);
DescriptionModuleFormContainer.convertForm2Data =
  convertForm2Data(DESCRIPTION_MODULE);
