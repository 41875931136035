import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Card } from 'semantic-ui-react';
import { apis } from '../../../api';

const BoardItem = ({ board, filterDeletedBoard }) => {
  const deleteBoard = async () => {
    const res = await apis.deleteBoard(board.id);
    if (res?.response?.data?.err) {
      return;
    }
    filterDeletedBoard(board.id);
    alert('삭제가 완료되었어요.');
  };

  return (
    <Container style={styles.container}>
      <Card fluid>
        <Card.Content
          as={Link}
          to={{ pathname: '/boards/' + board.id, state: { board: board } }}
        >
          <Card.Header>{board.title}</Card.Header>
          <Card.Meta>
            {board.boardCategory.name} | 조회수 {board.views} <br />
          </Card.Meta>
          <Card.Description>{board.content}</Card.Description>
        </Card.Content>
        <Card.Content extra>
          {board.isPublic && '공개 | '}
          {!board.isPublic && '비공개 | '}
          {board.isDeleted && '삭제'}
          {!board.isDeleted && 'active'}
          <Button color="black" floated="right" onClick={deleteBoard}>
            삭제
          </Button>
          <Button
            basic
            color="blue"
            floated="right"
            as={Link}
            to={{
              pathname: '/boards/' + board.id + '/edit',
              state: { board: board },
            }}
          >
            편집
          </Button>
        </Card.Content>
      </Card>
    </Container>
  );
};

export default BoardItem;

const styles = {
  container: {
    marginBottom: 40,
  },
};
