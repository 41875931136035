import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { apis } from '../../../../api';
import { Container, Loader } from 'semantic-ui-react';
import EventForm from '../EventForm';
import moment from 'moment';

const EventEdit = ({}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [eventData, setEventData] = useState();
  const history = useHistory();
  const { id: eventId } = useParams();

  useEffect(() => {
    _getEventData();
  }, []);

  const _getEventData = async () => {
    const { event } = await apis.getEventData(eventId);

    const _eventData = _formatEventDataForForm(event);

    setEventData(_eventData);
    setIsLoading(false);
  };

  const _formatEventDataForForm = (eventData) => {
    // section > module bool <-> 문자 처리
    eventData.data.sections.forEach((section) => {
      section.modules.forEach((module) => {
        formatSubData(module.data);
      });
    });

    // event > data 내 true / false
    formatSubData(eventData.data);

    // event > data > ctaButton 내 true / false
    formatSubData(eventData.data.ctaButton);

    // startTime, endTime
    eventData.startTime = moment(eventData.startTime).format('YYYY-MM-DD');
    eventData.endTime = moment(eventData.endTime).format('YYYY-MM-DD');

    return eventData;
  };

  const formatSubData = (data) => {
    Object.keys(data).forEach((key) => {
      if (key === 'curationId') {
        data[key] = String(data[key]);
      }
      if (data[key] === true) {
        data[key] = 'true';
      } else if (data[key] === false) {
        data[key] = 'false';
      }
    });
  };

  const editEvent = async (eventData) => {
    const res = await apis.updateEventData(eventData.id, eventData);
    // 이벤트 정보 없으면 에러
    if (res.event) {
      const url = `https://web${
        process.env.REACT_APP_ENV_PHASE === 'STAGE' ? '-stage' : ''
      }.chlngers.com/events/challenge/${eventData.eventName}`;
      const message = `이벤트 수정이 완료되었어요. 페이지를 확인해보시겠어요?\n${url}`;
      if (confirm(message)) {
        window.open(url, '_blank');
      }
      history.goBack();
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <EventForm
        initEventData={eventData}
        addOrEditEvent={(eventData) => editEvent(eventData)}
      />
    </Container>
  );
};

export default EventEdit;
