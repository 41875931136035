import TextArea from '@component/TextArea';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './AuthenticationMethodForm.serializer';
import {
  useAuthenticationMethod,
  useAuthenticationMethodActions,
} from './AuthenticationMethodForm.store';
import { validate } from './AuthenticationMethodForm.validator';

const AuthenticationMethodForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'authenticationMethod'>,
  Pick<ChallengeFormType, 'authenticationMethod'>,
  Pick<ReqCreateOrUpdateChallenge, 'authenticationMethod'>
> = () => {
  const { authenticationMethod } = useAuthenticationMethod();
  const { setAuthenticationMethod } = useAuthenticationMethodActions();
  const validated = validate({ authenticationMethod });

  return (
    <TextArea
      label="인증 방법"
      name="authenticationMethod"
      value={authenticationMethod}
      onChange={setAuthenticationMethod}
      errorMsg={validated.isValid ? '' : validated.message}
    />
  );
};

export default AuthenticationMethodForm;

AuthenticationMethodForm.validate = validate;
AuthenticationMethodForm.convertData2Form = convertData2Form;
AuthenticationMethodForm.convertForm2Data = convertForm2Data;
