import useIntersectionCallbackRef from '@hooks/useIntersectionCallbackRef';
import React from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import useInfiniteCollaboChallenges from './hooks/useInfiniteCollaboChallenges';

const AchievementTable = () => {
  const {
    data: challenges,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
  } = useInfiniteCollaboChallenges();

  const ref = useIntersectionCallbackRef<HTMLDivElement>({
    callback: (entry) => {
      if (entry.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  const handleRoute = (challengeId: number) => {
    window.open(`/challenges/${challengeId}/achievements`, '_blank');
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
      }}
    >
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>검토</Table.HeaderCell>
            <Table.HeaderCell>결과발표일</Table.HeaderCell>
            <Table.HeaderCell>챌린지 ID</Table.HeaderCell>
            <Table.HeaderCell>챌린지명</Table.HeaderCell>
            <Table.HeaderCell>목표</Table.HeaderCell>
            <Table.HeaderCell>참가인원수</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {challenges ? (
          <Table.Body>
            {challenges.map((challenge, i) => (
              <React.Fragment key={i}>
                <Table.Row>
                  <Table.Cell collapsing>
                    <Button onClick={() => handleRoute(challenge.id)}>
                      검토
                    </Button>
                  </Table.Cell>
                  <Table.Cell collapsing>{challenge.resultDate}</Table.Cell>
                  <Table.Cell collapsing>{challenge.id}</Table.Cell>
                  <Table.Cell>{challenge.title}</Table.Cell>
                  <Table.Cell collapsing>
                    [{challenge.goalId}] {challenge.goalTitle}
                  </Table.Cell>
                  <Table.Cell collapsing>{challenge.registerCount}</Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        ) : isLoading ? (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan={5}>
                <Icon name="spinner" loading />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ) : (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan={5}>데이터가 없습니다.</Table.Cell>
            </Table.Row>
          </Table.Body>
        )}
        {isFetching && (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={5}>
                <Icon name="spinner" loading />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
      <div ref={ref} style={{ height: 1, width: '100%' }} />
    </div>
  );
};

export default AchievementTable;
