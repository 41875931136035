import React from 'react';
import * as S from './Style';

type Props = {
  children: React.ReactNode;
  alignItems?: React.CSSProperties['alignItems'];
  justifyContent?: React.CSSProperties['justifyContent'];
  gap?: React.CSSProperties['gap'];
  style?: React.CSSProperties;
  pv?: number;
  ph?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
};

const Column = ({
  children,
  alignItems,
  justifyContent,
  gap,
  style,
  ...props
}: Props) => {
  return (
    <S.FlexBox
      direction="column"
      alignItems={alignItems}
      justifyContent={justifyContent}
      gap={gap}
      style={style}
      {...props}
    >
      {children}
    </S.FlexBox>
  );
};

export default Column;
