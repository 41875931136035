import ChipList from '@component/ChipList';
import { ColorSample } from '@component/ColorSample';
import Dropdown from '@component/Dropdown';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import useGetCouponList from '@queries/Race/useGetCouponList';
import { Race } from '@types';
import { flatMapInfiniteData } from '@utils/query.utils';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import ReactDragList from 'react-drag-list';
import { Button, Grid } from 'semantic-ui-react';

type CouponListModuleProps = {
  idx: number;
  module: Race.RaceCouponListModule;
  handleChangeCouponIds: (idx: number, key: any, couponIds: number[]) => void;
  handleChangeBgColor: (idx: number, key: any, bgColor: string) => void;
};

const CouponListModule = ({
  idx,
  module,
  handleChangeCouponIds,
  handleChangeBgColor,
}: CouponListModuleProps) => {
  const couponIds = (module.couponIds || []) as number[];
  const bgColor = (module.bgColor || '#FFFFFF') as string;

  const [keyword, setKeyword] = useState('');

  // ids로 쿠폰 정보 불러오는 api
  const { data } = useGetCouponList({
    ids: couponIds,
  });

  const couponList = flatMapInfiniteData(data, 'coupons', 'id');

  // 드롭다운에서 쿠폰 리스트 불러오는 api
  const {
    data: _allCoupons,
    hasNextPage,
    fetchNextPage,
  } = useGetCouponList({
    keyword,
  });

  const allCouponList = flatMapInfiniteData(_allCoupons, 'coupons', 'id');

  const getDropdownCouponName = (couponId: number) => {
    const coupon = allCouponList.find(({ id }) => id === couponId);
    if (!coupon) return `[${couponId}]`;
    const { id, name, description, discountAmount } = coupon;
    return `[${id}] ${name} / ${discountAmount.toLocaleString()}원 (${description})`;
  };

  const getCouponName = (couponId: number) => {
    const coupon = couponList.find(({ id }) => id === couponId);
    if (!coupon) return `[${couponId}]`;
    const { id, name, description, discountAmount } = coupon;
    return `[${id}] ${name} / ${discountAmount.toLocaleString()}원 (${description})`;
  };

  const handleColorChange = (value: string) => {
    const isHexColor = value.match(/^#/g);
    if (!isHexColor) {
      return alert('색상은 #로 시작해야 합니다.');
    }
    handleChangeBgColor(idx, 'bgColor', value);
  };

  const handleOrderChange = async (index: number, direction: string) => {
    const _couponIds = [...couponIds];

    const removed = _couponIds.splice(index, 1);
    if (direction === 'up') {
      if (index === 0) return;
      _couponIds.splice(index - 1, 0, removed[0]);
    } else {
      if (index === couponIds.length - 1) return;
      _couponIds.splice(index + 1, 0, removed[0]);
    }
    handleInputChange(_couponIds);
  };

  const handleInputChange = (values: number[]) => {
    handleChangeCouponIds(idx, 'couponIds', values);
  };

  const handleUpdateOrder = async (e: unknown, updated: any[]) => {
    handleInputChange(updated.map(({ id }) => id));
  };

  const handleSearchKeyword = useCallback(
    _.debounce((text) => {
      setKeyword(text);
    }, 500),
    [],
  );

  const handleRemove = (index: number) => {
    const updatedIds = [...couponIds];
    updatedIds.splice(index, 1);
    handleInputChange(updatedIds);
  };

  const chipList = couponIds.map((id) => getCouponName(id));

  return (
    <div style={{ paddingBottom: 20 }}>
      <h4>쿠폰 리스트 모듈</h4>
      <FlexBox.Row
        style={{
          width: 320,
          padding: '16px 20px',
          marginBottom: 8,
          backgroundColor: bgColor,
        }}
      >
        <img src="/race/쿠폰-미리보기.png" width={280} />
      </FlexBox.Row>

      <ChipList
        style={{ marginBottom: 6 }}
        chipList={chipList}
        removable
        onRemovePress={handleRemove}
      />

      <FlexBox.Row gap={10}>
        <div style={{ flex: 1 }}>
          <Dropdown
            search
            label="쿠폰 목록"
            placeholder="쿠폰을 선택하세요."
            options={allCouponList.map(({ id }) => ({
              text: getDropdownCouponName(id),
              value: id,
            }))}
            keyExtractor={(item) => item}
            onSearchChange={handleSearchKeyword}
            onReachEnd={hasNextPage ? fetchNextPage : undefined}
            onChange={(id) => {
              if (!id || couponIds.includes(id)) return;
              handleInputChange([...couponIds, id]);
            }}
          />
        </div>

        <div>
          <Label>배경색</Label>
          <input
            name="bgColor"
            placeholder="#FFFFFF"
            value={bgColor}
            onChange={(e) => handleColorChange(e.target.value)}
          />
          <ColorSample color={bgColor} />
        </div>
      </FlexBox.Row>
      <h4 style={{ margin: 0, marginTop: 10 }}>노출 순서</h4>
      <Grid>
        <Grid.Row>
          <Grid.Column width={12}>쿠폰명</Grid.Column>
          <Grid.Column width={2}>순서변경</Grid.Column>
          <Grid.Column width={2}>삭제</Grid.Column>
        </Grid.Row>
      </Grid>
      <ReactDragList
        rowKey="id"
        dataSource={couponIds.map((id) => ({
          id,
        }))}
        handles={false}
        onUpdate={handleUpdateOrder}
        row={(option: any, index) => {
          const couponId = option.id as number;
          return (
            <Grid
              key={`${couponId}-${index}`}
              style={{
                flexWrap: 'nowrap',
                paddingTop: 14,
                borderBottom: '1px solid #eee',
              }}
            >
              <Grid.Column width={12}>
                <div>{getCouponName(couponId)}</div>
              </Grid.Column>
              <Grid.Column width={2}>
                <Button
                  size="mini"
                  icon="angle up"
                  onClick={() => handleOrderChange(index, 'up')}
                />
                <Button
                  size="mini"
                  icon="angle down"
                  onClick={() => handleOrderChange(index, 'down')}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Button
                  size="mini"
                  icon="delete"
                  onClick={() => handleRemove(index)}
                />
              </Grid.Column>
            </Grid>
          );
        }}
      />
    </div>
  );
};

export default CouponListModule;
