import { useCallback, useEffect, useMemo, useState } from 'react';
import GoalCategoryTable from '../GoalCategoryTable';
import { GoalCategoryWithRestrict } from '@types';
import { apis } from '@api/index';
import { Button, Grid, Pagination } from 'semantic-ui-react';
import GoalMenu from '../GoalMenu';
import GoalCategoryCreateOrEditModal from '../GoalCategoryCreateOrEditModal/GoalCategoryCreateOrEditModal';
import FlexBox from '@component/FlexBox/FlexBox';

const PAGE_SIZE = 50;

const GoalCategory = () => {
  const [goalCategories, setGoalCategories] = useState<
    GoalCategoryWithRestrict[]
  >([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);

  const _getGoalCategories = async () => {
    const res = await apis.challenge.getGoalCategories({
      offset: PAGE_SIZE * (page - 1),
      limit: PAGE_SIZE,
    });
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    const { goalCategories: _goalCategories, total } = res;

    setGoalCategories(_goalCategories);
    setTotalCount(total);
  };

  useEffect(() => {
    _getGoalCategories();
  }, [page]);

  const remove = (gc: GoalCategoryWithRestrict) => {
    const _goalCategories = goalCategories.filter((o) => o.id !== gc.id);
    setGoalCategories(_goalCategories);
  };

  const update = (gc: GoalCategoryWithRestrict) => {
    const _goalCategories = goalCategories.map((o) => {
      if (o.id === gc.id) return gc;
      return o;
    });
    setGoalCategories(_goalCategories);
  };

  const showModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => setModalOpen(false), []);

  const handleGoalCategorySubmit = async (gc: GoalCategoryWithRestrict) => {
    const res = await apis.challenge.createGoalCategory({
      level1: gc.level1,
      level2: gc.level2,
      level3: gc.level3,
      level4: gc.level4,
      restrictGoalCategoryIds: gc.restrictGoalCategories.map((o) => o.id),
    });

    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    // 생성완료 후 바로 goal에 세팅해주기
    const { id } = res;
    const _goalCategory = { ...gc, id };

    setGoalCategories([_goalCategory, ...goalCategories]);

    alert('목표카테고리 생성이 완료되었어요.');

    closeModal();
  };

  return (
    <div style={{ margin: 40 }}>
      <Grid columns="equal">
        <Grid.Column width={2}>
          <GoalMenu />
        </Grid.Column>
        <Grid.Column>
          <FlexBox.Column
            style={{
              overflow: 'auto',
            }}
          >
            <Grid.Row>
              <div>전체 목표카테고리 개수 : {totalCount.toLocaleString()}</div>
              <Button onClick={showModal}>목표카테고리 추가 ➕</Button>
            </Grid.Row>
            {modalOpen && (
              <GoalCategoryCreateOrEditModal
                goalCategory={null}
                handleOnClose={closeModal}
                handleOnSubmit={handleGoalCategorySubmit}
              />
            )}

            <GoalCategoryTable
              goalCategories={goalCategories}
              remove={remove}
              update={update}
            />
            <Pagination
              defaultActivePage={1}
              totalPages={Math.ceil(totalCount / PAGE_SIZE)}
              onPageChange={(_, { activePage }) => {
                const pageNo = activePage as number;
                setPage(pageNo);
              }}
            />
          </FlexBox.Column>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default GoalCategory;
