import React, { useState } from 'react';
import {
  Button,
  Dimmer,
  Divider,
  Dropdown,
  Form,
  Image,
  Input,
  Loader,
} from 'semantic-ui-react';
import { apis } from '../../../api';
import { handleChangeSingleFile } from '../../../utils/uploadImage';
import * as S from '../Style';
import { RACE_PACKAGE_TEXT_SUFFIX } from '../constant';

const RaceInstagramTypeForm: React.FC<RaceInstagramTypeFormProps> = (props) => {
  const { onChangeValue, offerWallInfo, onChangeRaceInfo } = props;

  const [onboardingSheetUrl, setOnboardingSheetUrl] = useState('');
  const [formAdminLink, setFormAdminLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      const res = await apis.createRaceSurvey({ onboardingSheetUrl });
      if (res?.response?.data && 'err' in res.response.data) {
        return;
      }

      // offerwall 적용하기
      setFormAdminLink(res.formAdminLink);
      onChangeValue({
        name: 'screen',
        value: {
          ...offerWallInfo.screen,
          url: res.formLink,
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getTitle = () => {
    switch (offerWallInfo.type) {
      case 'RACE_INSTAGRAM_MISSION':
        return '랜선대회 인스타그램 인증 전용';
      case 'RACE_SURVEY':
        return '랜선대회 설문 조사 전용';
      case 'RACE_EXTERNAL_MISSION':
        return '랜선대회 미션 전용';
      default:
        return '';
    }
  };

  if (!offerWallInfo) return <></>;
  return (
    <div>
      {offerWallInfo.type !== 'RACE_EXTERNAL_MISSION' && (
        <>
          <Divider />
          <h2>{getTitle()}</h2>
        </>
      )}

      {
        /**
         * 인스타그램 관련 설정
         */
        offerWallInfo.type === 'RACE_INSTAGRAM_MISSION' && (
          <>
            <label
              style={{
                color: 'blue',
              }}
            >
              *랜선대회 상세 화면에 노출되는 롤링 배너에도 동일하게 세팅됩니다.
            </label>
            <div
              style={{
                height: 24,
              }}
            />
            <S.RowContainer>
              <S.RowFormField>
                <label>기념품 이름</label>
                <Input
                  type="string"
                  value={offerWallInfo.race?.goodsName || ''}
                  onChange={(_, { value }) => {
                    onChangeValue({
                      name: 'race',
                      value: { ...offerWallInfo.race, goodsName: value },
                    });
                  }}
                />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <span style={{ whiteSpace: 'pre' }}>
                      {`{{기념품 이름}} ${RACE_PACKAGE_TEXT_SUFFIX}`}
                    </span>
                    <span style={{ whiteSpace: 'pre' }}>
                      {`\n형식으로 노출됩니다!`}
                    </span>
                  </div>
                </div>
              </S.RowFormField>

              <S.RowFormField margin="0 0 0 10px">
                <label>인스타그램 필수 해시태그</label>
                <Dropdown
                  placeholder="해시태그를 입력해주세요"
                  fluid
                  multiple
                  search
                  selection
                  allowAdditions
                  name="hashTagList"
                  options={((offerWallInfo.hashTagList as string[]) || []).map(
                    (h) => ({
                      text: h,
                      value: h,
                    }),
                  )}
                  value={offerWallInfo.hashTagList || []}
                  onChange={(_, { value }) =>
                    onChangeValue({
                      name: 'hashTagList',
                      value: value as string[],
                    })
                  }
                />
              </S.RowFormField>
            </S.RowContainer>
          </>
        )
      }

      {
        /* 설문조사 WebViewScreen */
        offerWallInfo.type === 'RACE_SURVEY' && (
          <Form.Field>
            <h3>설문조사 정보 관련</h3>
            <label
              style={{
                color: 'blue',
              }}
            >
              *랜선대회 상세 화면에 노출되는 롤링 배너에도 동일하게 세팅됩니다.
            </label>
            <div
              style={{
                height: 24,
              }}
            />
            <Dimmer active={isLoading}>
              <Loader />
            </Dimmer>
            <Form.Field>
              <label>설문 생성하기</label>
              <S.RowContainer>
                <Input
                  type="string"
                  placeholder="구글시트 URL을 입력하세요"
                  value={onboardingSheetUrl}
                  onChange={(_, { value }) => {
                    setOnboardingSheetUrl(value);
                  }}
                />
                <Button size="tiny" content="생성" onClick={handleClick} />
              </S.RowContainer>
              {formAdminLink.length > 0 && (
                <>
                  👉🏻
                  <a
                    href={formAdminLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    설문 수정하러 가기
                  </a>
                </>
              )}
            </Form.Field>
            <S.RowContainer style={{ gap: 12 }}>
              <S.RowFormField>
                <label>설문조사 URL</label>
                <Input
                  type="string"
                  value={offerWallInfo.screen?.url || ''}
                  onChange={(_, { value }) => {
                    onChangeValue({
                      name: 'screen',
                      value: {
                        ...offerWallInfo.screen,
                        url: value,
                      },
                    });
                  }}
                />
              </S.RowFormField>
              <S.RowFormField>
                <label>설문조사 스크린 title</label>
                <Input
                  type="string"
                  value={offerWallInfo.screen?.title || ''}
                  onChange={(_, { value }) => {
                    onChangeValue({
                      name: 'screen',
                      value: {
                        ...offerWallInfo.screen,
                        title: value,
                      },
                    });
                  }}
                />
              </S.RowFormField>
            </S.RowContainer>
          </Form.Field>
        )
      }
      <Divider />
    </div>
  );
};

type RaceInstagramTypeFormProps = {
  onChangeValue: ({ name, value }: { name: string; value: any }) => void;
  onChangeRaceInfo: ({ name, value }: { name: string; value: number }) => void;
  offerWallInfo: any;
};

export default RaceInstagramTypeForm;
