import { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Modal, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { apis } from '../../../api';
import { splitNewLine } from '../../../utils/string';
import { getShortWeekDay } from '../../../constant/datetime';
import {
  addWeeks,
  differenceInCalendarDays,
  format,
  nextSunday,
} from 'date-fns';

type Props = {
  modalOpen: boolean;
  closeModal: () => void;
};

const IntervalCopyModal = ({ modalOpen, closeModal }: Props) => {
  const [challenges, setChallenges] = useState<
    { id: number; startDate: string; endDate: string }[]
  >([]);
  const [challengeOptions, setChallengeOptions] = useState<
    {
      key: number;
      text: string;
      value: any;
    }[]
  >([]);
  const [reqData, setReqData] = useState<{
    challengeId?: number;
    adminUserIds: number[];
    totalDays?: number;
    interval?: number;
  }>({
    adminUserIds: [1],
  });
  const [guideString, setGuideString] = useState('');
  const [result, setResult] = useState<
    {
      id: number;
      registerStartDate: string;
      registerEndDate: string;
      startDate: string;
      endDate: string;
    }[]
  >([]);
  const [adminUserOptions, setAdminUserOptions] = useState<
    {
      key: number;
      text: string;
      value: number;
    }[]
  >([]);

  useEffect(() => {
    _getChallengeOptions();
    _getAdminUserList();
  }, []);

  const _getChallengeOptions = async () => {
    const { challenges: _challenges } = await apis.getChallengeOptions({
      gteResultDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      isOfficial: true,
      isPublic: true,
      isDeleted: false,
    });
    const options = _challenges.map((challenge: any) => ({
      key: challenge.id,
      text: `${challenge.id} / ${challenge.isPublic ? '공개' : '비공개'} / ${
        challenge.title
      }`,
      value: challenge.id,
    }));
    setChallenges(_challenges);
    setChallengeOptions(options);
  };

  const _getAdminUserList = async () => {
    const { adminUserList } = await apis.getCollaboAdminUserList({
      offset: 0,
      limit: 1000,
    });
    const options = adminUserList.map((o) => ({
      key: o.id,
      text: `[${o.id}] ${o.email} / ${o.name}`,
      value: o.id,
    }));

    setAdminUserOptions(options);
  };

  const _handleChange = (key: string, value: any) => {
    setReqData({ ...reqData, [key]: value });
  };

  const _submit = async () => {
    if (!_validate()) return;
    const res: any = await apis.intervalCopyChallenges({ ...reqData });
    if (res?.response?.data?.err) {
      return;
    }
    const { challenges: _challenges } = res;
    setResult(_challenges);
    setGuideString('');
  };

  const _validate = () => {
    if (reqData.totalDays !== undefined && reqData.totalDays > 30) {
      alert('복사하는 총 일수는 30일을 초과할 수 없습니다.');
      return false;
    }

    if (reqData.interval !== undefined && reqData.interval > 14) {
      alert('복사 간격은 14일을 초과할 수 없습니다.');
      return false;
    }

    if (reqData.adminUserIds.length < 2) {
      alert('제휴 어드민 유저를 2명 이상 선택해 주세요.');
      return false;
    }

    return true;
  };

  useEffect(() => {
    _makeGuideString();
  }, [reqData]);

  const _makeGuideString = () => {
    const { challengeId, totalDays, interval } = reqData;

    if (!!challengeId && !!totalDays && !!interval) {
      const challenge = challenges.find((c) => c.id === challengeId);
      const periodArr = [];

      if (!challenge) return;

      for (let i = 1; i <= Math.floor(totalDays / interval); i++) {
        const startDate = moment(challenge.startDate)
          .add(interval * i, 'd')
          .format('YYYY.MM.DD');

        const sunday = nextSunday(new Date(startDate));
        const diffDays = differenceInCalendarDays(sunday, new Date(startDate));

        let endDate = sunday;
        if (diffDays < 4) {
          endDate = addWeeks(sunday, 2);
        } else {
          endDate = addWeeks(sunday, 1);
        }

        periodArr.push(
          `${i}) ${startDate}(${getShortWeekDay(startDate)})~${format(
            endDate,
            'yyyy.MM.dd',
          )}(${getShortWeekDay(endDate)})`,
        );
      }

      const startDate = moment(challenge.startDate).format('YYYY.MM.DD');
      const endDate = moment(challenge.endDate).format('YYYY.MM.DD');

      let _guideString = `선택한 챌린지의 인증기간은 ${startDate}(${getShortWeekDay(
        startDate,
      )})~${endDate}(${getShortWeekDay(endDate)}) 입니다.`;
      _guideString += `\n설정한 조건들로 챌린지를 생성할 경우 총 ${periodArr.length}개의 챌린지가 생성되며,\n각 챌린지의 인증기간은\n`;
      _guideString += `${periodArr.join('\n')}\n입니다.`;

      setGuideString(_guideString);
    } else {
      setGuideString('');
    }
  };

  const handleIdCopyClick = () => {
    const textToCopy = result.map(({ id }) => id).join('\n');
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert('복사 완료!');
    });
  };

  return (
    <Modal size="small" open={modalOpen} onClose={closeModal}>
      <Modal.Header>상시 챌린지 반복 생성</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>챌린지 선택</label>
            <Dropdown
              placeholder="챌린지를 선택하세요."
              fluid
              search
              selection
              options={challengeOptions}
              value={reqData.challengeId}
              onChange={(_, { value }) => {
                _handleChange('challengeId', value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>총 일수</label>
            <input
              value={reqData.totalDays}
              onChange={(e) => {
                const { value } = e.target;
                if (Number.isNaN(Number(value))) return;
                _handleChange('totalDays', Number(value));
              }}
            />
          </Form.Field>

          <Form.Field>
            <label>복사 간격</label>
            <input
              value={reqData.interval}
              onChange={(e) => {
                const { value } = e.target;
                if (Number.isNaN(Number(value))) return;
                _handleChange('interval', Number(value));
              }}
            />
          </Form.Field>

          <Form.Field>
            <label>제휴 어드민 유저 선택</label>
            <Dropdown
              placeholder="챌린지를 추가할 제휴 어드민 유저를 선택하세요."
              fluid
              search
              selection
              multiple
              options={adminUserOptions}
              value={reqData.adminUserIds || []}
              onChange={(_, { value }) => {
                _handleChange('adminUserIds', value);
              }}
            />
          </Form.Field>
        </Form>

        <div>{splitNewLine(guideString)}</div>

        {result.length > 0 && (
          <>
            <h3>챌린지 생성 완료!</h3>
            <Button onClick={handleIdCopyClick}>챌린지 id 일괄 복사</Button>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>챌린지id</Table.HeaderCell>
                  <Table.HeaderCell>모집시작일</Table.HeaderCell>
                  <Table.HeaderCell>모집종료일</Table.HeaderCell>
                  <Table.HeaderCell>시작일</Table.HeaderCell>
                  <Table.HeaderCell>종료일</Table.HeaderCell>
                  <Table.HeaderCell>동작</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {result.map((o) => (
                  <Table.Row key={o.id}>
                    <Table.Cell>{o.id}</Table.Cell>
                    <Table.Cell>{o.registerStartDate}</Table.Cell>
                    <Table.Cell>{o.registerEndDate}</Table.Cell>
                    <Table.Cell>{o.startDate}</Table.Cell>
                    <Table.Cell>{o.endDate}</Table.Cell>
                    <Table.Cell>
                      <Button
                        size="mini"
                        as={Link}
                        to={{ pathname: `/challenges/${o.id}/edit` }}
                        target="_blank"
                        rel="noopener noreferrer"
                        content="편집"
                      />
                      <Button
                        size="mini"
                        as={Link}
                        to={{ pathname: `/challenges/${o.id}` }}
                        target="_blank"
                        rel="noopener noreferrer"
                        content="상세"
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" content="생성" onClick={_submit} />
        <Button color="black" content="닫기" onClick={closeModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default IntervalCopyModal;
