import { useMutation, useQuery } from '@tanstack/react-query';
import { getQueryKey } from './getQueryKey';
import { apis } from '../../api';
import { QueryHookOptions, QueryMutationHookOptions } from '../query.types';
import { Crm, Race } from '@types';

export const useGetRaceCRM = (
  serviceId: number,
  offerWallTypes: Crm.CrmOfferWallType[],
  options?: QueryHookOptions<typeof apis.getCrmInfo>,
) => {
  return useQuery({
    queryKey: getQueryKey('GET_CRM', { serviceId, offerWallTypes }),
    queryFn: () => apis.getCrmInfo(serviceId, 'RACE', offerWallTypes),
    ...options,
    enabled: !!serviceId,
  });
};

export const useCreateRaceCRMMutation = (
  options?: QueryMutationHookOptions<typeof apis.createCrmInfo>,
) => {
  return useMutation<CRMResponse, unknown, Race.RaceCrmInfo>({
    mutationFn: (data) => apis.createCrmInfo(data),
    ...options,
  });
};

type CRMResponse = {
  success: boolean;
};

export const useUpdateRaceCRMMutation = (
  options?: QueryMutationHookOptions<typeof apis.updateCrmInfo>,
) => {
  return useMutation<CRMResponse, unknown, Race.RaceCrmInfo & { id: number }>({
    mutationFn: (data) => apis.updateCrmInfo(data.id, data),
    ...options,
  });
};

export const useDeleteRaceCRMMutation = (
  options?: QueryMutationHookOptions<typeof apis.deleteCrmInfo>,
) => {
  return useMutation<CRMResponse, unknown, number>({
    mutationFn: (id) => apis.deleteCrmInfo(id),
    ...options,
  });
};
