import React from 'react';
import FlexBox from '@component/FlexBox/FlexBox';
import * as S from './ChallengeSelector.style';
import { ChallengeType } from '../../DataLabel.types';

const challengeList: { text: string; value: ChallengeType }[] = [
  { text: '헬스장 가기', value: 'gym' },
  { text: '하늘 보기', value: 'sky' },
];

export const ChallengeSelector: React.FC<ChallengeSelectorProps> = (props) => {
  const { activeChallengeType, handleClick } = props;
  return (
    <FlexBox.Column style={{ flex: 1 }}>
      <h3>챌린지 목록</h3>
      {challengeList.map((o) => {
        const isActive = activeChallengeType === o.value;
        return (
          <S.Wrapper
            key={o.value}
            isActive={isActive}
            onClick={() => handleClick(o.value)}
          >
            <S.Label isActive={isActive}>{o.text}</S.Label>
          </S.Wrapper>
        );
      })}
    </FlexBox.Column>
  );
};

type ChallengeSelectorProps = {
  activeChallengeType: ChallengeType;
  handleClick: (challengeType: ChallengeType) => void;
};
