import React, { FC } from 'react';
import { Button } from 'semantic-ui-react';
// @ts-expect-error react-excel-workbook
import Workbook from 'react-excel-workbook';
import moment from 'moment';
import { UserWithdrawInfo } from '@types';
import setCurrencyFormat from '../../../global/setCurrencyFormat';

const WithdrawExcel: FC<WithdrawExcelProps> = ({ withdraws }) => {
  const prizeWithdraws = withdraws.filter((wd) => wd.pointType === 'PRIZE');
  return (
    <Workbook
      filename={`${moment().format('YYYYMMDD_')}상금.xlsx`}
      element={
        <Button basic color="green" size="mini">
          상금 다운로드
        </Button>
      }
    >
      <Workbook.Sheet data={prizeWithdraws} name="상금">
        <Workbook.Column
          label=""
          value={(wd: UserWithdrawInfo) => wd.bankName}
        />
        <Workbook.Column
          label=""
          value={(wd: UserWithdrawInfo) => wd.accountNo}
        />
        <Workbook.Column
          label=""
          value={(wd: UserWithdrawInfo) => setCurrencyFormat(-wd.amount)}
        />
        <Workbook.Column
          label=""
          value={(wd: UserWithdrawInfo) => `${wd.accountName} 상금`}
        />
        <Workbook.Column label="" value={() => '챌린저스'} />
      </Workbook.Sheet>
    </Workbook>
  );
};

export default React.memo(WithdrawExcel);

type WithdrawExcelProps = {
  withdraws: UserWithdrawInfo[];
};
