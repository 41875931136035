import React from 'react';
import { Container, Input } from 'semantic-ui-react';
import { SettlementFilter } from '../../Settlement.types';
import { FILTER_LABELS } from '../../Settlement.constants';

type FilterSectionProps = {
  filter: SettlementFilter;
  onSelectFilter: (value: Partial<SettlementFilter>) => void;
};

const FilterSection: React.FC<FilterSectionProps> = ({
  filter,
  onSelectFilter,
}) => {
  return (
    <Container fluid>
      <div
        style={{
          display: 'flex',
          marginRight: 20,
          marginTop: 20,
          gap: 8,
        }}
      >
        <Input
          label={FILTER_LABELS.gteChallengeResultDate}
          placeholder="YYYY-MM-DD"
          value={filter.gteChallengeResultDate}
          type="date"
          onChange={(_, { value }) => {
            onSelectFilter({ gteChallengeResultDate: String(value) });
          }}
        />
        <Input
          label={FILTER_LABELS.ltChallengeResultDate}
          placeholder="YYYY-MM-DD"
          value={filter.ltChallengeResultDate}
          type="date"
          onChange={(_, { value }) => {
            onSelectFilter({ ltChallengeResultDate: String(value) });
          }}
        />
      </div>
    </Container>
  );
};

export default FilterSection;
