import { QueryMutationHookOptions } from '@queries/query.types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { apis } from '../../api';
import { getQueryKey } from './getQueryKey';

export const useGetLabels = () => {
  return useQuery({
    queryKey: getQueryKey('GET_LABELS'),
    queryFn: () => apis.challenge.getLabels({}),
  });
};

export const useCreateLabels = (
  options?: QueryMutationHookOptions<typeof apis.challenge.createLabels>,
) => {
  return useMutation({
    mutationKey: getQueryKey('CREATE_LABELS'),
    mutationFn: (name: string) => apis.challenge.createLabels({ name }),
    ...options,
  });
};
