import { Fragment } from 'react';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import Button from '@component/Button';
import { Modal } from 'semantic-ui-react';
import {
  collaboDescriptionModuleCompList,
  commonDescriptionModuleCompList,
  subModuleCompList,
} from '../../DescriptionModuleForm.constant';
import { DescriptionModuleFormType } from '../../../../NewChallengeForm.types';

interface AddModuleModalProps {
  open: boolean;
  isSubModule?: boolean;
  onClose: () => void;
  onAdd: (data: DescriptionModuleFormType) => void;
}

const AddModuleModal = ({
  open,
  isSubModule = false,
  onClose,
  onAdd,
}: AddModuleModalProps) => {
  const renderCollaboModuleList = () => {
    if (isSubModule) return <></>;

    return (
      <FlexBox.Column style={{ marginBottom: 18 }}>
        <Label>제휴 모듈</Label>
        <FlexBox.Row gap={6} style={{ flexWrap: 'wrap', marginTop: 6 }}>
          {collaboDescriptionModuleCompList.map((moduleComp) => (
            <Button
              key={moduleComp.moduleName}
              text={moduleComp.moduleName}
              onClick={() => {
                onAdd(moduleComp.defaultData);
                onClose();
              }}
            />
          ))}
        </FlexBox.Row>
      </FlexBox.Column>
    );
  };

  const renderCommonModuleList = () => {
    if (isSubModule) return <></>;

    return (
      <FlexBox.Column>
        <Label>공통 모듈</Label>
        <FlexBox.Row gap={6} style={{ flexWrap: 'wrap', marginTop: 6 }}>
          {commonDescriptionModuleCompList.map((moduleComp) => {
            // 서브 모듈은 '최상단 요약' 모듈을 사용하지 않습니다.
            if (isSubModule && moduleComp.moduleName === '최상단 요약')
              return <Fragment key={moduleComp.moduleName} />;

            return (
              <Button
                key={moduleComp.moduleName}
                text={moduleComp.moduleName}
                onClick={() => {
                  onAdd(moduleComp.defaultData);
                  onClose();
                }}
              />
            );
          })}
        </FlexBox.Row>
      </FlexBox.Column>
    );
  };

  const renderSubModuleList = () => {
    if (!isSubModule) return <></>;

    return (
      <FlexBox.Column>
        <Label>서브 모듈</Label>
        <FlexBox.Row gap={6} style={{ flexWrap: 'wrap', marginTop: 6 }}>
          {subModuleCompList.map((moduleComp) => (
            <Button
              key={moduleComp.moduleName}
              text={moduleComp.moduleName}
              onClick={() => {
                onAdd(moduleComp.defaultData);
                onClose();
              }}
            />
          ))}
        </FlexBox.Row>
      </FlexBox.Column>
    );
  };

  return (
    <Modal size="small" open={open} onClose={onClose}>
      <Modal.Header>모듈 선택</Modal.Header>
      <Modal.Content>
        {renderCollaboModuleList()}
        {renderCommonModuleList()}
        {renderSubModuleList()}
      </Modal.Content>
      <Modal.Actions>
        <Button
          text="취소"
          fontColor="white"
          bgColor="black"
          onClick={onClose}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AddModuleModal;
