import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import NumberInput from '@component/NumberInput';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './ExpForm.serializer';
import { useExp, useExpActions } from './ExpForm.store';
import { validate } from './ExpForm.validator';

const ExpForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'exp'>,
  Pick<ChallengeFormType, 'exp'>,
  Pick<ReqCreateOrUpdateChallenge, 'exp'>
> = () => {
  const { exp } = useExp();
  const { setExp } = useExpActions();

  const validated = validate({ exp });

  return (
    <NumberInput
      label="경험치"
      value={exp}
      onChange={(value) => setExp(Number(value))}
      errorMsg={!validated.isValid ? validated.message : ''}
    />
  );
};

export default ExpForm;

ExpForm.validate = validate;
ExpForm.convertData2Form = convertData2Form;
ExpForm.convertForm2Data = convertForm2Data;
