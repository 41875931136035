import React, { useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Icon,
} from 'semantic-ui-react';

const OfferWallGuide = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Accordion>
      <AccordionTitle
        active={collapsed}
        onClick={() => setCollapsed((prev) => !prev)}
      >
        <Icon name="dropdown" />
        가이드 이미지
      </AccordionTitle>
      <AccordionContent active={collapsed}>
        <img
          src="https://d246jgzr1jye8u.cloudfront.net/development/admin/1677466391651-0.png"
          width="100%"
          alt="가이드이미지"
        />
      </AccordionContent>
    </Accordion>
  );
};

export default OfferWallGuide;
