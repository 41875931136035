import { DescriptionCollaboSpecialPrizeModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const DEFAULT_DATA = {
  type: 'COLLABO-SPECIAL-PRIZE',
  data: {
    type: null,
    title: null,
    noticeList: [],
  },
} as unknown as DescriptionCollaboSpecialPrizeModuleFormType;
