import { isAfter, startOfDay } from 'date-fns';
import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

export const validate: FormValidator = (
  params: Pick<ChallengeFormType, 'endDate' | 'resultDate'>,
) => {
  if (!params.resultDate || !params.endDate) {
    return { isValid: false, message: '' };
  }

  const endDate = startOfDay(new Date(params.endDate));
  const resultDate = startOfDay(new Date(params.resultDate));

  if (!isAfter(resultDate, endDate)) {
    return {
      isValid: false,
      message: '챌린지 결과 발표일은 종료일보다 이후여야 합니다.',
    };
  }

  return { isValid: true, message: '' };
};
