import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useTagIds = () => {
  return useChallengeFormStore(
    useShallow((s) => ({ tagIds: s.challenge.tagIds })),
  );
};

export const useTagIdsActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setTagIds = (tagIds: number[]) =>
    updateChallengeForm({ tagIds: tagIds.filter((tagId) => Boolean(tagId)) });

  return { setTagIds };
};
