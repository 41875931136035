import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import TextInput from '@component/TextInput';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './PasswordForm.serializer';
import { usePassword, usePasswordActions } from './PasswordForm.store';
import { validate } from './PasswordForm.validator';

const PasswordForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'password'>,
  Pick<ChallengeFormType, 'password'>,
  Pick<ReqCreateOrUpdateChallenge, 'password'>
> = () => {
  const { password } = usePassword();

  const { setPassword } = usePasswordActions();

  const validated = validate({ password });

  return (
    <TextInput
      label="참가코드 (설정시 챌린지 공개처리 '공개'로 설정 불가)"
      value={password}
      onChange={setPassword}
      errorMsg={!validated.isValid ? validated.message : ''}
    />
  );
};

export default PasswordForm;

PasswordForm.validate = validate;
PasswordForm.convertData2Form = convertData2Form;
PasswordForm.convertForm2Data = convertForm2Data;
