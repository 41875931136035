import { UserFilterSelector } from '@component/UserFilterSelector';
import { Header } from 'semantic-ui-react';
import { PreAlramForm, RecommentEditType } from '../PreAlarmForm.types';

const UserFilterSelectorForm = ({
  userFilterId,
  handleChange,
  triggerEdited,
  getBorder,
  disabled,
}: Props) => {
  return (
    <>
      <Header as="h3">유저 필터 선택하기</Header>
      <UserFilterSelector
        filterId={userFilterId}
        onChange={(_id) => {
          triggerEdited('userFilterId');
          handleChange('userFilterId', _id);
        }}
        style={{ border: getBorder('userFilterId') }}
        disabled={disabled}
      />
    </>
  );
};

type Props = {
  userFilterId: number | null;
  handleChange: <T extends keyof PreAlramForm>(
    key: T,
    value?: PreAlramForm[T],
  ) => void;
  triggerEdited: (key: keyof RecommentEditType) => void;
  getBorder: (key: keyof RecommentEditType) => string;
  disabled: boolean;
};

export default UserFilterSelectorForm;
