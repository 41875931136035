import React, { FC } from 'react';
import * as S from '../../../Style';
import { Race } from '@types';

type Props = {
  descriptions?: Race.RaceProductDescription[];
  onChange: (descriptions: Race.RaceProductDescription[]) => void;
};

const RaceProductDescriptionForm: FC<Props> = ({
  descriptions = [],
  onChange,
}) => {
  return (
    <S.Container>
      <h3>후원 물품(마감리포트 page3에 보여집니다)</h3>
      {descriptions.map((p, i) => (
        <DescriptionForm
          key={i}
          p={p}
          onChange={(changedPackage) => {
            const updated = [...descriptions];
            updated[i] = changedPackage;
            onChange(updated);
          }}
          onDelete={() => {
            const updated = descriptions.filter((_, index) => index !== i);
            onChange(updated);
          }}
        />
      ))}
      <S.ButtonInForm
        onClick={() => onChange([...descriptions, emptyDescription])}
      >
        추가
      </S.ButtonInForm>
    </S.Container>
  );
};

export default RaceProductDescriptionForm;

const DescriptionForm: FC<{
  p: Race.RaceProductDescription;
  onChange: (p: Race.RaceProductDescription) => void;
  onDelete: () => void;
}> = ({ p, onChange, onDelete }) => {
  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange({ ...p, [e.currentTarget.name]: e.currentTarget.value });
  };

  return (
    <S.RowContainer style={{ marginBottom: 12 }}>
      <S.Container style={{ marginRight: 12 }}>
        <label>이름</label>
        <input
          type="text"
          value={p?.name}
          name="name"
          onChange={handleChange}
        />
      </S.Container>

      <S.Container style={{ marginRight: 12 }}>
        <div>구성</div>
        <textarea
          style={{ width: 500 }}
          rows={3}
          value={p?.description}
          name="description"
          onChange={handleChange}
        />
      </S.Container>

      <S.ButtonInForm
        onClick={onDelete}
        style={{ height: 'fit-content', marginTop: 20 }}
      >
        삭제
      </S.ButtonInForm>
    </S.RowContainer>
  );
};

const emptyDescription: Race.RaceProductDescription = {
  name: '',
  description: '',
};
