import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Container,
  Dropdown,
  Form,
  Input,
  Modal,
  Table,
} from 'semantic-ui-react';
import _ from 'lodash';
import setCurrencyFormat from '../../../global/setCurrencyFormat';
import { apis } from '../../../api';

class ProductTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      column: null,
      direction: null,
      redirect: false,
      redirectEdit: false,
      isSorted: true,
      isMounted: false,
      products: [],
      productId: 0,
      product: {},
      editProductModalOpen: false,
      deleteProductModalOpen: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { products: nextProps.products };
  }

  showEditProductModal = (e, { value }) =>
    this.setState({ editProductModalOpen: true, product: value });
  closeEditProductModal = () => {
    this.setState({ editProductModalOpen: false });
  };
  handleProductInput = (e) => {
    const { product } = this.state;
    product[e.target.name] = (() => {
      switch (e.target.name) {
        case 'price':
        case 'fee':
        case 'shippingPrice':
          return Number.isNaN(Number(e.target.value)) ? 0 : Number(e.target.value);
        default:
          return e.target.value;
      }
    })();
    this.setState({ product });
  };
  handleProductRadioChange = (e, { name, value }) => {
    const { product } = this.state;
    product[name] = value;

    const { companyOptions } = this.props;
    const option = _.find(companyOptions, (o) => o.value === value);
    product.companyName = option.text;
    this.setState({ product });
  };
  editProduct = async () => {
    const { products, product } = this.state;
    const res = await apis.updateProduct(product.id, {
      name: product.name,
      price: product.price,
      fee: product.fee,
      shippingPrice: product.shippingPrice,
      companyId: product.companyId,
      isAddressNeeded: product.isAddressNeeded === 'true',
    });

    const { product: newProduct } = res;
    const newProducts = _.map(products, (p) => {
      if (p.id === product.id) {
        return newProduct;
      }
      return p;
    });

    this.setState({ products: newProducts });
    this.closeEditProductModal();
  };

  showDeleteProductModal = (e, { value }) =>
    this.setState({ deleteProductModalOpen: true, product: value });
  closeDeleteProductModal = () =>
    this.setState({ deleteProductModalOpen: false });
  deleteProduct = async () => {
    const { product } = this.state;
    await apis.deleteProduct(product.id);

    alert('삭제가 완료되었습니다.');
    this.closeDeleteProductModal();
    this.props.deleteProduct(product.id);
  };

  render() {
    const { products, product, editProductModalOpen, deleteProductModalOpen } =
      this.state;
    const {
      column,
      direction,
      handleSort,
      companyOptions,
      handleCheckbox,
      checkedAll,
      copyProducts,
      deleteProducts,
    } = this.props;
    // if (redirect) return <Redirect to={{pathname: '/products/' + productId}}/>;

    return (
      <React.Fragment>
        <div>
          <Button
            color="black"
            floated="right"
            size="mini"
            onClick={deleteProducts}
          >
            삭제
          </Button>
          <Button
            basic
            color="blue"
            floated="right"
            size="mini"
            onClick={copyProducts}
          >
            복사
          </Button>
        </div>
        <Container
          fluid
          style={{
            overflowX: 'auto',
            maxWidth: 1400,
            maxHeight: 700,
            marginTop: 20,
          }}
        >
          <Table basic="very" size="small" sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    name="all"
                    checked={checkedAll}
                    value="all"
                    onChange={handleCheckbox}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>id</Table.HeaderCell>
                <Table.HeaderCell>상품명</Table.HeaderCell>
                <Table.HeaderCell>수정/삭제</Table.HeaderCell>
                <Table.HeaderCell>회사명</Table.HeaderCell>
                <Table.HeaderCell>가격</Table.HeaderCell>
                <Table.HeaderCell>수수료</Table.HeaderCell>
                <Table.HeaderCell>배송비</Table.HeaderCell>
                <Table.HeaderCell>판매중인 챌린지</Table.HeaderCell>
                <Table.HeaderCell>배송 필요여부</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(products, (p) => (
                <Table.Row key={p.id}>
                  <Table.Cell collapsing>
                    <Checkbox
                      checked={p.isChecked}
                      value={p.id}
                      onChange={handleCheckbox}
                    />
                  </Table.Cell>
                  <Table.Cell>{p.id}</Table.Cell>
                  <Table.Cell>{p.name}</Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      size="mini"
                      value={p}
                      onClick={this.showEditProductModal}
                      content="수정"
                    />
                    <Button
                      size="mini"
                      disabled={p.challenges.length > 0}
                      value={p}
                      onClick={this.showDeleteProductModal}
                      content="삭제"
                    />
                  </Table.Cell>
                  <Table.Cell>{p.companyName}</Table.Cell>
                  <Table.Cell>{setCurrencyFormat(p.price)}</Table.Cell>
                  <Table.Cell>{setCurrencyFormat(p.fee)}</Table.Cell>
                  <Table.Cell>{setCurrencyFormat(p.shippingPrice)}</Table.Cell>
                  <Table.Cell>
                    {p.challenges.map((c, idx) => (
                      <div key={idx}>
                        [{c.id}]{c.title}
                      </div>
                    ))}
                  </Table.Cell>
                  <Table.Cell>
                    {p.isAddressNeeded ? '배송' : '미배송'}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Container>
        <Modal
          size="small"
          open={editProductModalOpen}
          onClose={this.closeEditProductModal}
        >
          <Modal.Header>상품 변경</Modal.Header>
          <Modal.Content>
            <Form>
              {product.challenges && product.challenges.length === 0 && (
                <Form.Field>
                  <label>회사명</label>
                  <Dropdown
                    placeholder="회사를 선택하세요."
                    fluid
                    search
                    selection
                    name="companyId"
                    options={companyOptions}
                    value={product.companyId}
                    onChange={this.handleProductRadioChange}
                  />
                </Form.Field>
              )}
              <Form.Field>
                <label>상품명</label>
                <Input
                  name="name"
                  placeholder="상품명"
                  value={product.name}
                  onChange={this.handleProductInput}
                />
              </Form.Field>
              <Form.Field>
                <label>가격</label>
                <Input
                  name="price"
                  placeholder="가격"
                  value={product.price}
                  onChange={this.handleProductInput}
                />
              </Form.Field>
              <Form.Field>
                <label>수수료</label>
                <Input
                  name="fee"
                  placeholder="수수료"
                  value={product.fee}
                  onChange={this.handleProductInput}
                />
              </Form.Field>
              <Form.Field>
                <label>배송료</label>
                <Input
                  name="shippingPrice"
                  placeholder="배송료"
                  value={product.shippingPrice}
                  onChange={this.handleProductInput}
                />
              </Form.Field>
              <Form.Group grouped>
                <label>배송필요여부</label>
                <Form.Field
                  label="배송"
                  control="input"
                  type="radio"
                  name="isAddressNeeded"
                  value="true"
                  checked={product.isAddressNeeded === 'true'}
                  onChange={this.handleProductInput}
                />
                <Form.Field
                  label="미배송"
                  control="input"
                  type="radio"
                  name="isAddressNeeded"
                  value="false"
                  checked={product.isAddressNeeded === 'false'}
                  onChange={this.handleProductInput}
                />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" content="변경" onClick={this.editProduct} />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeEditProductModal}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          size="small"
          open={deleteProductModalOpen}
          onClose={this.closeDeleteProductModal}
        >
          <Modal.Header>상품 삭제</Modal.Header>
          <Modal.Content>
            <p>선택하신 상품 [{product.name}]을(를) 삭제하시겠습니까?</p>
            <p>
              만약 해당 상품이 판매된 이력이 있다면 삭제를 하실 수 없습니다.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="blue" content="삭제" onClick={this.deleteProduct} />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeDeleteProductModal}
            />
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ProductTable;
