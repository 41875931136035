import React from 'react';
import * as S from './ErrorMsg.style';

type ErrorMsgProps = React.HTMLAttributes<HTMLDivElement> & {
  text?: string;
};

const ErrorMsg = (props: ErrorMsgProps) => {
  const { text, ...restProps } = props;
  if (!text) return <></>;

  return <S.ErrorMsg {...restProps}>{text}</S.ErrorMsg>;
};

export default ErrorMsg;
