import React, { FC, useCallback, useRef } from 'react';
import { uploadSingleFileToS3 } from '@utils/uploadImage';
import { NaverSmartStoreModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import Label from '@component/Label';
import FlexBox from '@component/FlexBox/FlexBox';
import TextArea from '@component/TextArea';
import Button from '@component/Button';
import { ModuleContainer } from '../../../../DescriptionModuleForm.style';

type ReviewInfoType = Pick<
  NaverSmartStoreModuleFormType,
  'reviewText' | 'reviewImageUrls'
>;
type Props = {
  data: ReviewInfoType;
  onChange: (data: ReviewInfoType) => void;
};
const ReviewInfoForm: FC<Props> = ({ data, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeReviewText = useCallback(
    (text: string) => {
      onChange({ ...data, reviewText: text });
    },
    [data, onChange],
  );

  const handleAddReviewImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = e.target.files ? Array.from(e.target.files) : [];

    const urls = await Promise.all(files.map((f) => uploadSingleFileToS3(f)));
    onChange({ ...data, reviewImageUrls: [...data.reviewImageUrls, ...urls] });
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleRemoveReviewImage = (index: number) => {
    onChange({
      ...data,
      reviewImageUrls: data.reviewImageUrls.filter((_, i) => i !== index),
    });
  };

  return (
    <ModuleContainer gap={16}>
      <Label size="large" style={{ marginTop: 20 }}>
        리뷰 정보
      </Label>

      <FlexBox.Row gap={8} alignItems="center">
        <Label style={{ width: 60 }}>리뷰내용</Label>
        <TextArea
          value={data.reviewText}
          onChange={handleChangeReviewText}
          containerStyle={{ flex: 1 }}
        />
      </FlexBox.Row>

      <FlexBox.Row gap={8} alignItems="center" style={{ marginTop: 12 }}>
        <Label style={{ width: 80 }}>리뷰 이미지</Label>
        <Button text="추가" onClick={() => inputRef.current?.click()} />
      </FlexBox.Row>
      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        multiple
        onChange={handleAddReviewImage}
      />
      <FlexBox.Row gap={12}>
        {data?.reviewImageUrls?.map((url, index) => (
          <FlexBox.Column style={{ width: 120 }} key={url}>
            <img
              src={url}
              style={{ width: '100%', aspectRatio: 1 }}
              alt={`리뷰 이미지 ${index}`}
            />
            <Button
              text="삭제"
              onClick={() => handleRemoveReviewImage(index)}
            />
          </FlexBox.Column>
        ))}
      </FlexBox.Row>
    </ModuleContainer>
  );
};

export default ReviewInfoForm;
