import axios, { AxiosError, Method } from 'axios';
import { ApiType, apiType } from '../constant/apiType';
import qs from 'qs';

const factory = (app_env?: ApiType) => {
  const api = app_env
    ? axios.create({
        baseURL: `${process.env.REACT_APP_GO_SERVER_URL}/api`,
        headers: {
          'Content-Type': 'application/json',
        },
      })
    : axios.create({
        baseURL: `${process.env.REACT_APP_PYTHON_SERVER_URL}/api`,
        headers: {
          'Content-Type': 'application/json',
        },
      });

  return api;
};

// python 서버
export const api = factory();
// media 서버
export const apiMedia = axios.create({
  baseURL: `${process.env.REACT_APP_GO_SERVER_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'blob',
});

// GO 서버
export const apiGo = factory(ApiType.go);
export const apiV3 = axios.create({
  baseURL: process.env.REACT_APP_V3_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;

type RequestParams = {
  apiType?: ApiType;
  method: Method;
  url: string;
  params?: unknown;
  data?: unknown;
  onSuccess?: (data: unknown) => void;
  onError?: (e: AxiosError) => void;
  headers?: unknown;
  retryCount?: number;
};
export const _request = async ({
  apiType: _apiType = ApiType.python,
  method,
  url,
  params,
  data,
  onSuccess,
  onError,
  headers,
  retryCount = 0,
}: RequestParams) => {
  const apiInstance = getApiInstance(_apiType);
  try {
    const res = await apiInstance({
      url,
      method,
      params,
      paramsSerializer: () => qs.stringify(params, { arrayFormat: 'repeat' }),
      data,
      headers,
    });

    if (onSuccess) {
      if (_apiType === apiType.media) return onSuccess(res.data);
      return onSuccess(res.data.data);
    }

    if (_apiType === apiType.media || _apiType === apiType.V3) {
      return res.data;
    }

    return res.data.data;
  } catch (e) {
    // type narrowing
    if (!axios.isAxiosError(e)) return e;
    if (e.response?.status === 502 && retryCount > 0) {
      setTimeout(
        () =>
          _request({
            // apiInstance,
            apiType: _apiType,
            method,
            url,
            params,
            data,
            onSuccess,
            onError,
            retryCount: retryCount - 1,
          }),
        1000,
      );
      return e;
    }
    if (onError) return onError(e);
    if (_apiType === 'PYTHON') {
      alert(`[${e.response?.status}] ${e.response?.data.userMessage}`);
    } else {
      const msg = e?.response?.data.userMessage ?? e?.response?.data.err;
      alert(`[${e.response?.status}] ${msg}`);
    }
    return e;
  }
};

const getApiInstance = (_apiType: ApiType) => {
  let apiInstance;
  if (_apiType === apiType.go) {
    apiInstance = apiGo;
  } else if (_apiType === apiType.media) {
    apiInstance = apiMedia;
  } else if (_apiType === apiType.V3) {
    apiInstance = apiV3;
  } else {
    apiInstance = api;
  }

  return apiInstance;
};
