import Button from '@component/Button';
import ChipInput from '@component/ChipInput';
import FlexBox from '@component/FlexBox/FlexBox';
import { FC } from 'react';
import { Divider } from 'semantic-ui-react';
import { NaverBlogInfoListItem } from '../../../../NewChallengeForm.types';
import { useUpdateNaverBlogInfoActions } from '../../NaverBlogInfoListForm.store';

type Props = {
  info: NaverBlogInfoListItem;
  index: number;
};
const NaverBlogInfo: FC<Props> = ({ info, index }) => {
  const { update, remove } = useUpdateNaverBlogInfoActions();

  const handleChangeAchievementIndexList = (chipList: string[]) => {
    if (chipList.some((v) => Number.isNaN(Number(v)))) return;
    update(index, {
      ...info,
      achievementIndexList: chipList.map(Number),
    });
  };

  const handleChangeHashTagList = (hashTagList: string[]) => {
    update(index, { ...info, hashTagList });
  };

  const handleChangeChecklist = (checklist: string[]) => {
    update(index, { ...info, checklist });
  };

  return (
    <FlexBox.Column>
      <FlexBox.Column gap={12}>
        <ChipInput
          label="블로그 인증 회차"
          chipList={info.achievementIndexList.map(String)}
          onChange={handleChangeAchievementIndexList}
        />

        <ChipInput
          label="인증 필수 해시태그 (#와 같은 특수기호 포함)"
          chipList={info.hashTagList}
          onChange={handleChangeHashTagList}
        />

        <ChipInput
          label="인증 체크리스트"
          chipList={info.checklist}
          onChange={handleChangeChecklist}
        />

        <Button
          text="해당 회차 삭제"
          onClick={() => remove(index)}
          fontColor="#fff"
          bgColor="#222"
        />
      </FlexBox.Column>
      <Divider />
    </FlexBox.Column>
  );
};

export default NaverBlogInfo;
