import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import FlexBox from '@component/FlexBox/FlexBox';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import ErrorMsg from '@component/ErrorMsg';
import Label from '@component/Label';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './IsPublicForm.serializer';
import { useIsPublic, useIsPublicActions } from './IsPublicForm.store';
import { validate } from './IsPublicForm.validator';

const options = {
  false: { label: '비공개', value: false },
  true: { label: '공개', value: true },
};
const IsPublicForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'isPublic'>,
  Pick<ChallengeFormType, 'isPublic'>,
  Pick<ReqCreateOrUpdateChallenge, 'isPublic'>
> = () => {
  const { isPublic } = useIsPublic();

  const { setIsPublic } = useIsPublicActions();

  const validated = validate({ isPublic });

  return (
    <FlexBox.Column>
      <Label>챌린지 공개 처리</Label>
      <RadioSelector
        style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
        options={Object.values(options)}
        onChange={({ value }) => setIsPublic(value)}
        selectedOption={options[isPublic.toString() as 'true' | 'false']}
      />
      <ErrorMsg text={!validated.isValid ? validated.message : ''} />
    </FlexBox.Column>
  );
};

export default IsPublicForm;

IsPublicForm.validate = validate;
IsPublicForm.convertData2Form = convertData2Form;
IsPublicForm.convertForm2Data = convertForm2Data;
