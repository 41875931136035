import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import MultilineChipInput from '@component/MultilineChipInput';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './AuthenticationCheckListForm.serializer';
import {
  useAuthenticationCheckList,
  useAuthenticationCheckListActions,
} from './AuthenticationCheckListForm.store';
import { validate } from './AuthenticationCheckListForm.validator';

const AuthenticationCheckListForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'authenticationChecklist'>,
  Pick<ChallengeFormType, 'authenticationChecklist'>,
  Pick<ReqCreateOrUpdateChallenge, 'authenticationChecklist'>
> = () => {
  const { authenticationChecklist } = useAuthenticationCheckList();

  const { setAuthenticationCheckList } = useAuthenticationCheckListActions();

  const validated = validate({ authenticationChecklist });

  return (
    <MultilineChipInput
      chipList={authenticationChecklist}
      onChange={setAuthenticationCheckList}
      label="인증 주의사항(인증 규정 영역에 노출)"
      placeholder="주의사항을 입력하세요"
      errorMsg={!validated.isValid ? validated.message : ''}
      draggable
    />
  );
};

export default AuthenticationCheckListForm;

AuthenticationCheckListForm.validate = validate;
AuthenticationCheckListForm.convertData2Form = convertData2Form;
AuthenticationCheckListForm.convertForm2Data = convertForm2Data;
