import { QuestionTemplate } from '@types';
import React, { useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownProps,
  Form,
  Input,
  Modal,
  TextArea,
} from 'semantic-ui-react';

type EditTemplateModalProps = {
  editTemplateModalOpen: boolean;
  closeEditTemplateModal: () => void;
  questionTemplateList: QuestionTemplate[];
  onEditQuestionTemplate: any;
};

function EditTemplateModal({
  editTemplateModalOpen,
  closeEditTemplateModal,
  questionTemplateList,
  onEditQuestionTemplate,
}: EditTemplateModalProps) {
  const [editTemplateId, setEditTemplateId] = useState<number>(0);
  const [editTemplateTitle, setEditTemplateTitle] = useState<string>('');
  const [editTemplateContent, setEditTemplateContent] = useState<string>('');

  const initEditTemplate = () => {
    setEditTemplateId(0);
    setEditTemplateTitle('');
    setEditTemplateContent('');
  };

  const editQuestionTemplate = () => {
    onEditQuestionTemplate({
      id: editTemplateId,
      title: editTemplateTitle,
      content: editTemplateContent,
    });
    initEditTemplate();
    closeEditTemplateModal();
  };

  const handleChangeTemplate = (e: any, data: DropdownProps) => {
    const _template = questionTemplateList.find(
      (__template: any) => __template.title === data.value,
    );
    if (!_template) return;

    setEditTemplateId(_template.id);
    setEditTemplateTitle(_template.title);
    setEditTemplateContent(_template.content);
  };
  return (
    <Modal
      size="small"
      open={editTemplateModalOpen}
      onClose={closeEditTemplateModal}
    >
      <Modal.Header>템플릿 수정</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>메세지 템플릿</label>
            <Dropdown
              placeholder="템플릿을 선택하세요."
              fluid
              search
              selection
              name="messageId"
              options={questionTemplateList.map(
                (template: any, index: number) => ({
                  key: index,
                  text: template.title,
                  value: template.title,
                }),
              )}
              onChange={handleChangeTemplate}
            />
          </Form.Field>
          <Form.Field>
            <label>템플릿명</label>
            <Input
              value={editTemplateTitle}
              onChange={(e) => setEditTemplateTitle(e.target.value)}
              size="small"
              name="text"
              placeholder="템플릿명"
            />
          </Form.Field>
          <Form.Field>
            <label>내용</label>
            <TextArea
              value={editTemplateContent}
              onChange={(e) => setEditTemplateContent(e.target.value)}
              rows={10}
              name="message"
              placeholder="내용"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" content="변경" onClick={editQuestionTemplate} />
        <Button
          color="black"
          content="닫기"
          onClick={() => {
            initEditTemplate();
            closeEditTemplateModal();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default EditTemplateModal;
