import { useShallow } from 'zustand/react/shallow';
import { ChallengeSticker } from '@types';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useChallengeStickersStore = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      challengeStickers: s.challenge.challengeStickers,
    })),
  );
};

export const useChallengeStickersActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setChallengeStickers = (challengeStickers: ChallengeSticker[]) =>
    updateChallengeForm({ challengeStickers });

  return { setChallengeStickers };
};
