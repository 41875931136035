import React, { FC } from 'react';
import { Header, Breadcrumb } from 'semantic-ui-react';

const WithdrawOldCardCashHeader: FC<WithdrawOldCardCashHeaderProps> = ({
  activeTab,
  handleClick,
}) => {
  return (
    <>
      <Header as="h3" style={{ display: 'inline-block' }}>
        1년 경과 출금요청 (일주일전~현재)
      </Header>
      <Breadcrumb style={{ marginLeft: 20 }}>
        <Breadcrumb.Section
          name="처리전"
          active={activeTab === 'NOT_COMPLETED'}
          onClick={() => handleClick('NOT_COMPLETED')}
          style={{ color: 'black' }}
        >
          {' '}
          처리전{' '}
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section
          name="처리완료"
          active={activeTab === 'COMPLETED'}
          onClick={() => handleClick('COMPLETED')}
          style={{ color: 'black' }}
        >
          {' '}
          처리완료{' '}
        </Breadcrumb.Section>
      </Breadcrumb>
    </>
  );
};

export default WithdrawOldCardCashHeader;

type WithdrawOldCardCashHeaderProps = {
  activeTab: 'COMPLETED' | 'NOT_COMPLETED';
  handleClick: (tab: 'COMPLETED' | 'NOT_COMPLETED') => void;
};
