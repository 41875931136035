import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'exp'>,
  Pick<ReqCreateOrUpdateChallenge, 'exp'>
> = ({ exp }) => {
  if (typeof exp !== 'number')
    throw Error("[ExpForm] this shouldn't be happened");

  return { exp };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'exp'>,
  Pick<ChallengeFormType, 'exp'>
> = ({ exp }) => ({ exp });
