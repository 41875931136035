import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const BenefitMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const [pathname, setPathname] = useState('');

  const _click = (menu) => {
    history.push(menu.value);
  };

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const menus = [
    { text: '오퍼월', value: '/offerwall' },
    { text: '오퍼월 인증샷', value: '/offerwall-achievements' },
    { text: '뽑기', value: '/gotcha' },
    { text: '뽑기 당첨자', value: '/gotcha/winners' },
    { text: '친구초대 포인트 지급', value: '/pedometer-invite-reward' },
    { text: '기프티샵 아이템', value: '/pedometer-gifti-shop/item' },
    { text: '기프티샵 구매자', value: '/pedometer-gifti-shop/buyer' },
  ];

  return (
    <>
      <Menu fluid secondary vertical>
        {menus.map((menu) => (
          <Menu.Item
            key={menu.value}
            active={menu.value === pathname}
            onClick={() => _click(menu)}
          >
            {menu.text}
          </Menu.Item>
        ))}
      </Menu>
    </>
  );
};

export default BenefitMenu;
