import { SemanticCOLORS } from 'semantic-ui-react';
import { ReviewResult } from 'src/types/achievement.types';

export const PAGE_SIZE = 300;

export const REVIEW_OPTIONS: {
  key: number;
  text: string;
  value: ReviewResult;
}[] = [
  { key: 1, text: '레드카드', value: 'RED' },
  { key: 2, text: '경고댓글', value: 'COMMENT' },
  { key: 3, text: '성공처리', value: 'PASS' },
];

export const DELETE_COMMENT_OPTIONS = [
  {
    key: 0,
    message:
      // eslint-disable-next-line no-template-curly-in-string
      '[본 내용은 회원님에게만 보입니다. 다른 의견이 있으신 경우 댓글이 아닌 마이페이지탭의 문의하기를 통해 연락주세요]\n\n사유 : \n\n인증 규정과 달라 회원님의 인증샷이 정상처리 되지 않았습니다😥 공정한 진행을 위해 아쉽게도 이번 인증샷은 삭제되오니 다음에는 인증 규정을 꼭 지켜주세요!\n\n[인증 규정]\n${authenticationMethod}',
    order: 0,
    text: '삭제 - 푸시발송용',
    value: 0,
  },
  {
    key: 1,
    message:
      // eslint-disable-next-line no-template-curly-in-string
      '[본 내용은 회원님에게만 보입니다. 다른 의견이 있으신 경우 댓글이 아닌 마이페이지탭의 문의하기를 통해 연락주세요]\n\n[인증 방법]\n${authenticationMethod}',
    order: 1,
    text: '삭제 - 인증처리용',
    value: 1,
  },
];

export const REPORT_REASON_MAP = {
  1: '[구버전]인증샷',
  2: '[구버전]이전 인증샷과 함께',
  3: '인증샷',
  4: '악성 유저',
};

export const REVIEW_RESULT_LABEL: { [key in ReviewResult]: string } = {
  BEFORE: '리뷰전',
  RED: '레드카드',
  PASS: '성공처리',
  COMMENT: '경고댓글',
  FAIL: '실패처리',
  ADMIN_REGISTER: '어드민등록',
  USER_RED: '개설레드',
  DELETE_AND_PASS: '삭제 후 성공 인증처리',
};

export const REVIEW_RESULT_COLOR: {
  [key in ReviewResult]: SemanticCOLORS | undefined;
} = {
  BEFORE: undefined,
  RED: 'red',
  PASS: 'blue',
  COMMENT: 'grey',
  FAIL: 'black',
  ADMIN_REGISTER: 'blue',
  USER_RED: 'red',
  DELETE_AND_PASS: 'blue',
};
