import { Benefit } from '../types';

const offerWallTypeLabel = {
  EXTERNAL_SIGN_UP: '앱 외부 미션 (가입)',
  CHALLENGE_PARTICIPATE: '앱 내부 미션 (챌린지 참가)',
  CHALLENGE_INSTANCE: '앱 내부 링크 방문',
  EXTERNAL_INSTANCE: '앱 외부 미션 (방문)',
  EXTERNAL_EXPERIENCE: '앱 외부 미션 (경험)',
  EXTERNAL_MISSION: '외부 미션(자유도있음)',
  CHALSTORE_PAYMENT: '스토어 상품 구매',
  RACE_PARTICIPATE: '랜선 대회 참가',
  RACE_INSTAGRAM_MISSION: '랜선 대회 인스타그램',
  RACE_SURVEY: '랜선 대회 설문조사',
  RACE_EXTERNAL_MISSION: '랜선 대회 외부 미션',
};

const offerWallTypeList = [
  { key: 'EXTERNAL', label: '앱 외부 미션' },
  { key: 'INTERNAL', label: '앱 내부 미션' },
];

const userTypeOptions = [
  { key: 1, text: '전체', value: 'ALL' },
  { key: 2, text: '어드민', value: 'ADMIN' },
];

const userGenderOptions = [
  { key: 1, text: '전체', value: 'ALL' },
  { key: 2, text: '여성', value: 'F' },
  { key: 3, text: '남성', value: 'M' },
];

const offerWallMissionLabel = {
  EXTERNAL_SIGN_UP: '신규 가입하면',
  CHALLENGE_PARTICIPATE: '이 챌린지 참가하면',
  CHALLENGE_INSTANCE: '이 챌린지 클릭하면',
  EXTERNAL_INSTANCE: '방문하면',
  EXTERNAL_EXPERIENCE: '어떤 경험을 하면',
};

const OFFER_WALL_RACE_INSTAGRAM_DEFAULT_INFO: Benefit.OfferWallInfo = {
  id: -1,
  title: '',
  brand: '',
  thumbnailImageUrl: '',
  type: 'RACE_INSTAGRAM_MISSION',
  isDisplayed: false,
  startDateTime: '',
  endDateTime: '',
  resultDate: '',
  reward: {
    storePointAmount: 0,
    bestStorePointAmount: 0,
    prizePointAmount: 0,
    bestPrizePointAmount: 0,
  },
  bestParticipantCount: null,
  race: null,
};

export {
  offerWallTypeList,
  userTypeOptions,
  userGenderOptions,
  offerWallTypeLabel,
  offerWallMissionLabel,
  OFFER_WALL_RACE_INSTAGRAM_DEFAULT_INFO,
};
