import React from 'react';
import { Dropdown, Pagination } from 'semantic-ui-react';

const DurationPagination = ({
  currentPage,
  totalPages,
  setCurrentPage,
  pageSize,
  setPageSize,
}: {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  pageSize: number;
  setPageSize: (size: number) => void;
}) => {
  return (
    <div style={{ justifyContent: 'center', display: 'flex', gap: 20 }}>
      <Pagination
        defaultActivePage={9}
        activePage={currentPage}
        firstItem={null}
        lastItem={null}
        totalPages={totalPages}
        onPageChange={(e: any) => {
          setCurrentPage(Number(e.target.text));
        }}
      />
      <Dropdown
        selection
        defaultValue="10"
        options={['10', '20', '50', '100', '1000', '5000'].map((o) => ({
          key: o,
          value: o,
          text: o,
        }))}
        onChange={(e, data) => setPageSize(Number(data.value as string) || 10)}
      />
    </div>
  );
};

export default DurationPagination;
