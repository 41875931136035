import { isAfter } from 'date-fns';
import { parseLocalDateTime } from '@utils/date.utils';
import { isNull } from '@utils/js.utils';
import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'registerStartDate' | 'registerEndDate'>;

export const validate: FormValidator<Params> = ({
  registerStartDate,
  registerEndDate,
}) => {
  if (isNull(registerStartDate) || isNull(registerEndDate)) {
    return { isValid: false, message: '' };
  }

  if (
    isAfter(
      parseLocalDateTime(registerStartDate),
      parseLocalDateTime(registerEndDate),
    )
  ) {
    return {
      isValid: false,
      message: '모집 종료일은 모집 시작일보다 이후여야 합니다.',
    };
  }

  return { isValid: true };
};
