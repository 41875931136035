import Accordion from '@component/Accordion/Accordion';
import Button from '@component/Button';
import ChipInput from '@component/ChipInput';
import ErrorMsg from '@component/ErrorMsg';
import FlexBox from '@component/FlexBox/FlexBox';
import { colors } from '@constant/colors';
import { trimTabs } from '@utils/string.utils';
import { Divider } from 'semantic-ui-react';
import { ChallengeCustomField } from 'src/types/customField.types';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { EMPTY_INFO_ITEM } from './InstagramInfoListForm.constants';
import {
  convertData2Form,
  convertForm2Data,
} from './InstagramInfoListForm.serializer';
import {
  useInstagramInfoList,
  useInstagramInfoListAction,
  useValidationData,
} from './InstagramInfoListForm.store';
import { validate } from './InstagramInfoListForm.validator';

type Props = {
  open: boolean;
};

const InstagramInfoListForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'instagramInfoList'>,
  Extract<
    ChallengeCustomField,
    | { name: 'instagramInfoList' }
    | { name: 'instagram' }
    | { name: 'instagramRequired' }
  >,
  Props
> = ({ open }) => {
  const { instagramInfoList } = useInstagramInfoList();
  const { setInstagramInfoList } = useInstagramInfoListAction();
  const validationData = useValidationData();

  const validated = validate({
    instagramInfoList,
    ...validationData,
  });

  const handleDelete = (index: number) => {
    setInstagramInfoList(instagramInfoList.filter((_, idx) => idx !== index));
  };

  const handleAdd = () => {
    setInstagramInfoList([...instagramInfoList, EMPTY_INFO_ITEM]);
  };

  const handleAchievementChange = (values: string[], index: number) => {
    if (values.some((v) => Number.isNaN(Number(v)))) return;

    setInstagramInfoList(
      instagramInfoList.map((d, idx) =>
        idx === index ? { ...d, achievementIndex: values.map(Number) } : d,
      ),
    );
  };

  const handleHashTagTextChange = (values: string[], index: number) => {
    setInstagramInfoList(
      instagramInfoList.map((d, idx) =>
        idx === index
          ? { ...d, hashTagText: values.map(String).map(trimTabs) }
          : d,
      ),
    );
  };

  const handleChecklistChange = (values: string[], index: number) => {
    setInstagramInfoList(
      instagramInfoList.map((d, idx) =>
        idx === index
          ? { ...d, checklist: values.map(String).map(trimTabs) }
          : d,
      ),
    );
  };

  return (
    <Accordion title="인스타그램 인증 관련 정보" open={open}>
      <ErrorMsg text={!validated.isValid ? validated.message : ''} />
      {instagramInfoList.map((info, index) => {
        return (
          <FlexBox.Column key={index} gap={10}>
            <ChipInput
              label="인스타 인증 회차"
              placeholder="인증 회차를 입력해주세요"
              chipList={info.achievementIndex.map(String)}
              onChange={(values) => handleAchievementChange(values, index)}
            />
            <ChipInput
              label="인스타 인증 필수 해시태그 (#태그, @태그와 같은 형태로 특수기호까지 포함시켜야 함)"
              placeholder="해시태그를 입력해주세요"
              chipList={info.hashTagText}
              onChange={(values) => handleHashTagTextChange(values, index)}
            />
            <ChipInput
              label="인스타 인증 체크리스트"
              placeholder="체크리스트를 입력해주세요"
              chipList={info.checklist}
              onChange={(values) => handleChecklistChange(values, index)}
            />
            <Button
              text="해당 회차 삭제"
              onClick={() => handleDelete(index)}
              style={{
                width: 150,
                backgroundColor: colors.BASIC_BLACK,
                color: colors.BASIC_WHITE,
              }}
            />
            <Divider />
          </FlexBox.Column>
        );
      })}
      <Button text="회차 추가" onClick={handleAdd} />
    </Accordion>
  );
};

export default InstagramInfoListForm;

InstagramInfoListForm.validate = validate;
InstagramInfoListForm.convertData2Form = convertData2Form;
InstagramInfoListForm.convertForm2Data = convertForm2Data;
