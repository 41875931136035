import moment from 'moment';
import 'moment/locale/ko';
import { apis } from '../api';
import { colorCode, colors } from '../constant/colors';
import { newEventType } from '../constant/eventType';

export const getGoalOptions = async (params) => {
  const { goals } = await apis.challenge.getGoalOptions(params);
  return goals.map((goal) => ({
    key: goal.id,
    text: `${goal.id} / ${goal.title}`,
    value: goal.id,
  }));
};

export const getChallengeOptions = async (params) => {
  const { challenges } = await apis.getChallengeOptions(params);

  return challenges.map((challenge) => {
    const { id, isPublic, title, userFilter, purchaseStartDate } = challenge;

    return {
      title,
      isPublic,
      userFilter,
      key: id,
      text: `${id} / ${isPublic ? '공개' : '비공개'} / ${
        purchaseStartDate ? moment(purchaseStartDate).format('M.D a h시') : '-'
      } / ${title}`,
      value: id,
    };
  });
};

export const getCollaboChallengeOptions = async () => {
  const { challenges } = await apis.getCollaboChallengeOptions();
  return challenges.map((challenge) => ({
    key: challenge.id,
    text: `${challenge.id} / ${challenge.title}`,
    value: challenge.id,
  }));
};

export const getCompanyOptions = async () => {
  const { companies } = await apis.getCompanyOptions();
  return companies.map((company) => ({
    key: company.id,
    text: `${company.id} / ${company.name}`,
    value: company.id,
  }));
};

export const getTagOptions = async () => {
  const { tags } = await apis.getTagOptions();
  return tags.map((tag) => ({
    key: tag.id,
    text: `[${tag.id}]${tag.tag}`,
    value: tag.id,
  }));
};

export const getChallengeCurationOptions = async () => {
  const { challengeCurations } = await apis.getChallengeCurations();
  return challengeCurations.map((curation) => ({
    key: curation.id,
    text: `${curation.id} / ${curation.title}`,
    value: curation.id,
  }));
};

export const getEventOptions = async () => {
  let { events: _events } = await apis.getEventDataList();
  _events = _events
    .filter((e) => Object.keys(newEventType).includes(e.data.type))
    .reverse();
  return _events.map((item) => ({
    key: item.id,
    text: item.eventName,
    value: item.eventName,
  }));
};

export const getColorOptions = () => {
  return [
    {
      key: colors.PRIMARY_500,
      text: `${colorCode.PRIMARY_500} / ${colors.PRIMARY_500}`,
      value: colorCode.PRIMARY_500,
    },
    {
      key: colors.PRIMARY_500_PRESSED,
      text: `${colorCode.PRIMARY_500_PRESSED} / ${colors.PRIMARY_500_PRESSED}`,
      value: colorCode.PRIMARY_500_PRESSED,
    },
    {
      key: colors.PRIMARY_400,
      text: `${colorCode.PRIMARY_400} / ${colors.PRIMARY_400}`,
      value: colorCode.PRIMARY_400,
    },
    {
      key: colors.PRIMARY_300,
      text: `${colorCode.PRIMARY_300} / ${colors.PRIMARY_300}`,
      value: colorCode.PRIMARY_300,
    },
    {
      key: colors.PRIMARY_200,
      text: `${colorCode.PRIMARY_200} / ${colors.PRIMARY_200}`,
      value: colorCode.PRIMARY_200,
    },
    {
      key: colors.PRIMARY_100,
      text: `${colorCode.PRIMARY_100} / ${colors.PRIMARY_100}`,
      value: colorCode.PRIMARY_100,
    },
    {
      key: colors.PRIMARY_100_PRESSED,
      text: `${colorCode.PRIMARY_100_PRESSED} / ${colors.PRIMARY_100_PRESSED}`,
      value: colorCode.PRIMARY_100_PRESSED,
    },
    {
      key: colors.PRIMARY_50,
      text: `${colorCode.PRIMARY_50} / ${colors.PRIMARY_50}`,
      value: colorCode.PRIMARY_50,
    },
    {
      key: colors.GRAY_900,
      text: `${colorCode.GRAY_900} / ${colors.GRAY_900}`,
      value: colorCode.GRAY_900,
    },
    {
      key: colors.GRAY_800,
      text: `${colorCode.GRAY_800} / ${colors.GRAY_800}`,
      value: colorCode.GRAY_800,
    },
    {
      key: colors.GRAY_700,
      text: `${colorCode.GRAY_700} / ${colors.GRAY_700}`,
      value: colorCode.GRAY_700,
    },
    {
      key: colors.GRAY_600,
      text: `${colorCode.GRAY_600} / ${colors.GRAY_600}`,
      value: colorCode.GRAY_600,
    },
    {
      key: colors.GRAY_500,
      text: `${colorCode.GRAY_500} / ${colors.GRAY_500}`,
      value: colorCode.GRAY_500,
    },
    {
      key: colors.GRAY_400,
      text: `${colorCode.GRAY_400} / ${colors.GRAY_400}`,
      value: colorCode.GRAY_400,
    },
    {
      key: colors.GRAY_300,
      text: `${colorCode.GRAY_300} / ${colors.GRAY_300}`,
      value: colorCode.GRAY_300,
    },
    {
      key: colors.GRAY_200,
      text: `${colorCode.GRAY_200} / ${colors.GRAY_200}`,
      value: colorCode.GRAY_200,
    },
    {
      key: colors.GRAY_100,
      text: `${colorCode.GRAY_100} / ${colors.GRAY_100}`,
      value: colorCode.GRAY_100,
    },
    {
      key: colors.GRAY_50,
      text: `${colorCode.GRAY_50} / ${colors.GRAY_50}`,
      value: colorCode.GRAY_50,
    },
    {
      key: colors.BASIC_BLACK,
      text: `${colorCode.BASIC_BLACK} / ${colors.BASIC_BLACK}`,
      value: colorCode.BASIC_BLACK,
    },
    {
      key: colors.BASIC_WHITE,
      text: `${colorCode.BASIC_WHITE} / ${colors.BASIC_WHITE}`,
      value: colorCode.BASIC_WHITE,
    },
    {
      key: colors.SYSTEM_ERROR,
      text: `${colorCode.SYSTEM_ERROR} / ${colors.SYSTEM_ERROR}`,
      value: colorCode.SYSTEM_ERROR,
    },
    {
      key: colors.SYSTEM_SUCCESS,
      text: `${colorCode.SYSTEM_SUCCESS} / ${colors.SYSTEM_SUCCESS}`,
      value: colorCode.SYSTEM_SUCCESS,
    },
    {
      key: colors.PALETTE_YELLOW,
      text: `${colorCode.PALETTE_YELLOW} / ${colors.PALETTE_YELLOW}`,
      value: colorCode.PALETTE_YELLOW,
    },
    {
      key: colors.PALETTE_BLUE,
      text: `${colorCode.PALETTE_BLUE} / ${colors.PALETTE_BLUE}`,
      value: colorCode.PALETTE_BLUE,
    },
    {
      key: colors.PALETTE_PURPLE,
      text: `${colorCode.PALETTE_PURPLE} / ${colors.PALETTE_PURPLE}`,
      value: colorCode.PALETTE_PURPLE,
    },
  ];
};

export const getBoardOptions = async () => {
  const { boards } = await apis.getAllBoards();
  return boards.map((b) => ({
    key: b.id,
    text: `[${b.id}]${b.title}`,
    value: b.id,
  }));
};

export const getCategoryOptions = async () => {
  const { categories } = await apis.challenge.getChallengeAppCategories();

  const categoryLevel3Options = [];
  categories.forEach((c) => {
    c.subCategories.forEach((sc) => {
      if (sc.criteria === 'level3') {
        categoryLevel3Options.push({
          key: sc.value,
          text: sc.text,
          value: sc.value,
        });
      }
    });
  });

  return categoryLevel3Options;
};

export const genderOptions = [
  { key: 'F', text: '여성', value: 'F' },
  { key: 'M', text: '남성', value: 'M' },
  { key: 'UNKNOWN', text: '비공개', value: 'UNKNOWN' },
];

export const roleOptions = [
  { key: 'GOAL', text: '목표', value: 'GOAL' },
  { key: 'CHALLENGE', text: '챌린지', value: 'CHALLENGE' },
  { key: 'COMMERCE', text: '커머스', value: 'COMMERCE' },
  { key: 'BOARD', text: '게시판', value: 'BOARD' },
  { key: 'MARKETING', text: '마케팅', value: 'MARKETING' },
  { key: 'COLLABO', text: '제휴', value: 'COLLABO' },
  { key: 'RACE', text: '랜선대회', value: 'RACE' },
  { key: 'BENEFIT', text: '혜택', value: 'BENEFIT' },
  { key: 'SETTING', text: '세팅', value: 'SETTING' },
  { key: 'TAG', text: '태그', value: 'TAG' },
  { key: 'LABEL', text: '라벨', value: 'LABEL' },
  { key: 'STATISTICS', text: '통계', value: 'STATISTICS' },
  { key: 'ACHIEVEMENT', text: '인증샷', value: 'ACHIEVEMENT' },
  { key: 'QUESTION', text: '문의대응', value: 'QUESTION' },
  { key: 'USER', text: '유저', value: 'USER' },
  { key: 'DATA-LABEL', text: '데이터 라벨링', value: 'DATA-LABEL' },
];

export const getRaceOptions = async () => {
  const { raceList } = await apis.getRaceListForOption();
  return raceList.map((race) => ({
    key: race.id,
    text: `[${race.id}]${race.title}(${moment(race.startDate).format(
      'YY.MM.DD',
    )}~${moment(race.endDate).format('YY.MM.DD')})`,
    value: race.id,
    title: race.title,
    raceType: race.raceType,
    startDate: race.startDate,
    endDate: race.endDate,
    brand: race.mainSponsor || '',
  }));
};

export const getOfferWallOptions = async () => {
  const { offerWallInfoList } = await apis.getOfferWalls('result_date');
  return offerWallInfoList.map((offerWall) => ({
    key: offerWall.id,
    text: `[${offerWall.id}] ${offerWall.title}(${moment(
      offerWall.startDateTime,
    ).format('YY.MM.DD')}~${moment(offerWall.endDateTime).format('YY.MM.DD')})`,
    value: offerWall.id,
  }));
};

export const goalCycleOptions = [
  { key: 1, text: '매일', value: 'EVERYDAY' },
  { key: 2, text: '주중매일', value: 'EVERY_DAY_OF_WEEK' },
  { key: 3, text: '주말매일', value: 'EVERY_WEEKEND' },
  { key: 4, text: '1주 단위', value: 'WEEKLY' },
  { key: 5, text: '2주단위', value: 'BIWEEKLY' },
  { key: 6, text: '월단위', value: 'MONTHLY' },
  { key: 7, text: '전체기간', value: 'ALL' },
  { key: 8, text: '상시', value: 'CUSTOM' },
];

export const getNoticeOptions = async () => {
  const { boards } = await apis.getAllBoards();
  return boards.map((board) => ({
    key: board.id,
    text: `[${board.id}] ${board.title}`,
    value: board.id,
  }));
};

export const serviceTypeOptions = [
  { key: 1, text: '챌린지', value: 'CHALLENGE' },
  { key: 2, text: '랜선대회', value: 'RACE' },
  { key: 3, text: '스토어', value: 'STORE' },
];

export const getBankOptions = async () => {
  const { bankList } = await apis.common.getBanks();
  return bankList.map((bank) => ({
    key: bank.id,
    text: `[${bank.code}] ${bank.name}`,
    value: bank.code,
  }));
};

export const searchTypeOptions = [
  { key: 0, text: '유저id', value: 'id' },
  { key: 1, text: '이름', value: 'name' },
  { key: 2, text: '닉네임', value: 'nickname' },
  { key: 3, text: '이메일', value: 'email' },
  { key: 4, text: '전화번호', value: 'phone' },
];
