import React from 'react';
import { Button, Checkbox, Header, Table } from 'semantic-ui-react';
import moment from 'moment';

const GoalReview = ({
  reviews,
  handleDisplayChange,
  refreshCacheForReviews,
}) => {
  const reviewList = reviews.map((r) => (
    <Table.Row key={r.id}>
      <Table.Cell collapsing>{r.nickname}</Table.Cell>
      <Table.Cell collapsing>
        {moment(r.createdAt).format('YYYY-MM-DD HH:mm:ss')}
      </Table.Cell>
      <Table.Cell collapsing>{r.rate}</Table.Cell>
      <Table.Cell>{r.comment}</Table.Cell>
      <Table.Cell>
        <Checkbox
          checked={r.isDisplayed}
          value={r.id}
          onChange={handleDisplayChange}
        />
      </Table.Cell>
    </Table.Row>
  ));
  return (
    <React.Fragment>
      <Header as="h3">
        리뷰 내용
        <Button
          floated={'right'}
          size={'mini'}
          content={'리뷰 캐시 초기화'}
          onClick={refreshCacheForReviews}
        />
      </Header>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>닉네임</Table.HeaderCell>
            <Table.HeaderCell>작성일</Table.HeaderCell>
            <Table.HeaderCell>점수</Table.HeaderCell>
            <Table.HeaderCell>작성내용</Table.HeaderCell>
            <Table.HeaderCell>노출여부</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{reviewList}</Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default GoalReview;
