import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import _ from 'lodash';
import moment from 'moment';
import { modules, moduleType } from './offerWallType';
import { RACE_PACKAGE_TEXT_SUFFIX } from './constant';

// 모듈 리스트로 변환하는 유틸
export const convertDataToModuleList = (offerWall) => {
  let moduleList = [];
  const _offerWall = _.cloneDeep(offerWall);

  if (offerWall.moduleList.length !== 0)
    moduleList.push(..._offerWall.moduleList);

  return moduleList;
};

// 오퍼월 타이틀
export const getOfferWallTitle = (offerWall) => {
  let title = '';

  if (offerWall.type === 'CHALLENGE_PARTICIPATE') {
    title = offerWall.title;
  }

  if (offerWall.type === 'EXTERNAL_SIGN_UP') {
    title = offerWall.brand + ' ' + offerWall.title;
  }

  return title;
};

export const getScreenInfo = (offerWall) => {
  if (
    offerWall.type === 'EXTERNAL_EXPERIENCE' ||
    offerWall.type === 'EXTERNAL_SIGN_UP' ||
    offerWall.type === 'EXTERNAL_MISSION'
  ) {
    return {
      screenName: 'OfferWallSelectImageScreen',
    };
  }

  if (
    ['RACE_INSTAGRAM_MISSION', 'RACE_EXTERNAL_MISSION'].includes(offerWall.type)
  ) {
    return {
      screenName: 'OfferWallSelectImageScreen',
      raceId: offerWall.screen.raceId,
    };
  }
  if (
    offerWall.type === 'CHALLENGE_PARTICIPATE' ||
    offerWall.type === 'CHALLENGE_INSTANCE'
  ) {
    return {
      screenName: 'ChallengeDetailScreen',
      challengeId: offerWall.screen.challengeId,
    };
  }
  if (offerWall.type === 'EXTERNAL_INSTANCE') {
    return {
      screenName: 'ExternalWebScreen',
      externalUrl: offerWall.screen.externalUrl,
    };
  }
  if (offerWall.type === 'RACE_PARTICIPATE') {
    return {
      screenName: 'RaceRegisterScreen',
      raceId: offerWall.screen.raceId,
    };
  }
  if (offerWall.type === 'CHALSTORE_PAYMENT') {
    return {
      screenName: 'ChalStoreProductDetailScreen',
      pillProductId: offerWall.screen.pillProductId,
    };
  }
  if (offerWall.type === 'RACE_SURVEY') {
    return {
      screenName: 'WebViewScreen',
      url: offerWall.screen.url,
      title: offerWall.screen.title,
      raceId: offerWall.screen.raceId,
    };
  }
};

export const getModules = (offerWall) => {
  if (
    offerWall.type === 'EXTERNAL_INSTANCE' ||
    offerWall.type === 'CHALLENGE_INSTANCE'
  ) {
    return modules.filter(
      (m) =>
        m.type !== moduleType.descriptionListModule &&
        m.type !== moduleType.descriptionButtonModule,
    );
  }

  if (isOfferWallInRace(offerWall.type)) {
    return modules.filter((m) =>
      [
        moduleType.imageList,
        moduleType.cautionModule,
        moduleType.achievementExampleModule,
      ].includes(m.type),
    );
  }

  return modules;
};

export const handleFixedModuleFromServer = (offerWall) => {
  return {
    ...offerWall,
    moduleList: offerWall.moduleList.filter(
      (m) =>
        ![
          moduleType.mainImageListModule,
          moduleType.titleModule,
          moduleType.jsxObjectTitleModule,
          moduleType.descriptionListModule,
          moduleType.missionReAchievementModule,
        ].includes(m.type),
    ),
  };
};

// 서버에 오퍼월 모듈 변환해서 보내는 유틸
export const handleModuleForServer = (
  offerWall,
  mainImageUrlList,
  descriptionList,
  challengeOptions,
  raceOptions,
  pillProductOptions,
) => {
  if (
    offerWall.type === 'EXTERNAL_INSTANCE' ||
    offerWall.type === 'CHALLENGE_INSTANCE'
  ) {
    return offerWall;
  }

  // 랜선대회 타입
  let raceTitle = '랜선 대회';
  const race = raceOptions?.find(({ key }) => key === offerWall.screen?.raceId);
  if (race) {
    raceTitle = race.title;
  }

  const rewardGivenDate = offerWall.resultDate
    ? format(
        moment(offerWall.resultDate).startOf('d').set('hour', 14).toDate(),
        'M.d (E) b h:mm',
        {
          locale: ko,
        },
      )
    : '즉시 지급';

  if (offerWall.type === 'RACE_PARTICIPATE') {
    offerWall = { ...offerWall, title: `${raceTitle} 참가하면` };
  }

  if (
    offerWall.type === 'RACE_INSTAGRAM_MISSION' ||
    offerWall.type === 'RACE_SURVEY' ||
    offerWall.type === 'RACE_EXTERNAL_MISSION'
  ) {
    const suffix =
      offerWall.type === 'RACE_SURVEY' ? ' 을 즉시 드려요!' : ' 지급!';
    offerWall = {
      ...offerWall,
      race: {
        homeButton: {
          title: getRewardText(offerWall).rewardText + suffix,
          subtitle: offerWall.title,
          buttonText: offerWall.reward.prizePointAmount
            ? '상금받기'
            : '포인트받기',
          imageUrl: offerWall.thumbnailImageUrl,
        },
      },
    };
  }

  // descriptionListModule을 위한 작업
  const _descriptionList = [];

  if (offerWall.type === 'CHALLENGE_PARTICIPATE') {
    if (!!offerWall.brand)
      _descriptionList.push({
        label: '브랜드',
        description: offerWall.brand,
      });

    const challenge = challengeOptions.find(
      (c) => c.key === offerWall.screen.challengeId,
    );

    if (challenge) {
      _descriptionList.push({
        label: '챌린지',
        description: challenge.title,
      });
    }
  }

  if (offerWall.type === 'CHALSTORE_PAYMENT') {
    const product = pillProductOptions.find(
      ({ key }) => key === offerWall.screen.pillProductId,
    );

    if (product) {
      _descriptionList.push({ label: '상품', description: product.title });
    }
  }

  if (!isOfferWallInRace(offerWall.type) && offerWall.reward.storePointAmount) {
    _descriptionList.push({
      label: '혜택',
      description: `${offerWall.reward.storePointAmount.toLocaleString()} P`,
    });

    if (offerWall.resultDate) {
      _descriptionList.push({
        label: '혜택 지급일',
        description: rewardGivenDate,
      });
    }
  }

  if (offerWall.type === 'CHALSTORE_PAYMENT') {
    _descriptionList.push({
      label: '혜택 지급일',
      description: '상품 구매 즉시 지급',
    });
  }

  if (offerWall.type === 'RACE_PARTICIPATE') {
    _descriptionList.push(
      {
        label: '혜택 지급일',
        description: `랜선 대회 참가 즉시 지급`,
      },
      {
        label: '필수 조건',
        description: `참가권&기념품 옵션 선택`,
      },
    );
  }

  const uniqueDescriptionList = getUniqueLabelDescriptionList([
    ..._descriptionList,
    ...(descriptionList || []),
  ]);

  const normalFixedModuleList = [
    {
      type: moduleType.mainImageListModule,
      imageUrlList: mainImageUrlList,
    },
    {
      type: moduleType.titleModule,
    },
    {
      type: moduleType.missionReAchievementModule,
    },
    uniqueDescriptionList.length > 0
      ? {
          type: moduleType.descriptionListModule,
          descriptionList: uniqueDescriptionList,
        }
      : null,
  ];

  // RACE_INSTAGRAM_MISSION, RACE_SURVEY 모듈 리스트
  const raceOfferWallFixedModuleList = [
    {
      type: moduleType.mainImageListModule,
      imageUrlList: mainImageUrlList,
    },
    // 타이틀
    {
      type: moduleType.jsxObjectTitleModule,
      jsxObjectList: getRaceInstagramTitle(offerWall, raceTitle),
    },
    // 재인증 관련 모듈
    {
      type: moduleType.missionReAchievementModule,
    },
    // 기념품 관련 설명
    offerWall.type === 'RACE_INSTAGRAM_MISSION' && !!offerWall?.race?.goodsName
      ? {
          type: moduleType.descriptionModule,
          text: offerWall.race.goodsName + ` ${RACE_PACKAGE_TEXT_SUFFIX}`,
        }
      : null,
    // 이벤트 기간
    {
      type: moduleType.descriptionListModule,
      descriptionList: [
        {
          label: '이벤트 기간',
          description: getEventDate(offerWall),
        },
      ],
    },
    // 인스타그램 해시태그
    offerWall.type === 'RACE_INSTAGRAM_MISSION' &&
    offerWall?.hashTagList &&
    offerWall?.hashTagList.length !== 0
      ? {
          type: moduleType.hashTagDescriptionModule,
          hashTagList: offerWall.hashTagList || [],
        }
      : null,
    // 이벤트 기간 이외의 디스크립션 (참여자 전원, 우수 참여자 등)
    {
      type: moduleType.descriptionListModule,
      descriptionList: getRaceInstagramDescriptionListModule(offerWall),
    },
    {
      type: moduleType.descriptionListModule,
      descriptionList: [
        {
          label: '혜택 지급일',
          description: rewardGivenDate,
        },
      ],
    },
  ].filter(Boolean);

  const fixedModuleList =
    offerWall.type === 'RACE_INSTAGRAM_MISSION' ||
    offerWall.type === 'RACE_SURVEY'
      ? raceOfferWallFixedModuleList
      : normalFixedModuleList;

  // RACE_INSTAGRAM_MISSION
  delete offerWall.hashTagList;

  return {
    ...offerWall,
    moduleList: [...fixedModuleList, ...offerWall.moduleList].filter(Boolean),
  };
};

const getUniqueLabelDescriptionList = (descriptionList) =>
  descriptionList.reduce((previousValue, currentValue) => {
    const labels = previousValue.map((p) => p.label);
    if (labels.includes(currentValue.label)) {
      return previousValue;
    }
    return [...previousValue, currentValue];
  }, []);

export const handleDescriptionModuleFromServer = (offerWall) => {
  const descriptionListModule = offerWall.moduleList.find(
    (m) => m.type === 'DescriptionListModule',
  );

  if (!descriptionListModule) return [];

  return descriptionListModule.descriptionList?.filter(
    (i) =>
      i.label !== '혜택' && i.label !== '혜택 지급일' && i.label !== '챌린지',
  );
};

export const getPointAmountAdditionalText = (offerWallType) => {
  if (offerWallType === 'CHALSTORE_PAYMENT') return `(구매 즉시 지급됨)`;
  return '';
};

// RACE_INSTAGRAM_MISSION 관련 변환
export const getRaceInstagramHashTagList = (offerWall) => {
  // 해시태그 변환
  const hashTagDescriptionModule = offerWall?.moduleList.find(
    (m) => m.type === 'HashTagDescriptionModule',
  );

  return hashTagDescriptionModule?.hashTagList || [];
};

//  dsecription module에서 랜선 기념품 이름으로 변환
export const getRacePackageName = (offerWall) => {
  const DescriptionModule = offerWall?.moduleList.find(
    (m) => m.type === 'DescriptionModule',
  );
  const packageTitle = DescriptionModule?.text;
  if (packageTitle === undefined) return '';
  return packageTitle.includes(RACE_PACKAGE_TEXT_SUFFIX)
    ? packageTitle.split(RACE_PACKAGE_TEXT_SUFFIX)[0].trim()
    : '';
};

export const getEventDate = (offerWall) => {
  return (
    (moment(offerWall.endDateTime).month() + 1).toLocaleString() +
    '월 ' +
    moment(offerWall.endDateTime).date().toLocaleString() +
    '일 까지'
  );
};

export const getRaceInstagramTitle = (offerWall, raceTitle) => {
  const { rewardText, bestRewardText } = getRewardText(offerWall, ', ');

  let titleList = [
    {
      text: `${offerWall.title} `,
      color: '#111111',
    },
    {
      text: rewardText,
      color: '#FF4343',
    },
  ];

  if (bestRewardText !== '' && offerWall.type !== 'RACE_SURVEY') {
    titleList.push(
      ...[
        {
          text: `, 우수 참여자에게는 `,
          color: '#111111',
        },
        {
          text: bestRewardText,
          color: '#FF4343',
        },
      ],
    );
  }

  titleList.push({
    text: `을 드려요.`,
    color: '#111111',
  });

  return titleList;
};

export const getRewardText = (offerWall, joinString) => {
  const {
    storePointAmount,
    prizePointAmount,
    bestStorePointAmount,
    bestPrizePointAmount,
  } = offerWall.reward;

  // 참여자 전원
  const prizePointText =
    prizePointAmount && prizePointAmount !== 0
      ? `상금 ${prizePointAmount?.toLocaleString()}원`
      : null;

  const storePointText =
    storePointAmount && storePointAmount !== 0
      ? `포인트 ${storePointAmount?.toLocaleString()}P`
      : null;

  const rewardText = [prizePointText, storePointText]
    .filter(Boolean)
    .join(joinString);

  // 우수 참여자
  const bestPrizePointText =
    bestPrizePointAmount && bestPrizePointAmount !== 0
      ? `상금 ${bestPrizePointAmount?.toLocaleString()}원`
      : null;

  const bestStorePointText =
    bestStorePointAmount && bestStorePointAmount !== 0
      ? `포인트 ${bestStorePointAmount?.toLocaleString()}P`
      : null;

  const bestRewardText = [bestPrizePointText, bestStorePointText]
    .filter(Boolean)
    .join(joinString);

  return {
    rewardText,
    bestRewardText,
  };
};

export const getRaceInstagramDescriptionListModule = (offerWall) => {
  let descriptionList = [];
  // 리워드 관련 작업

  const { rewardText, bestRewardText } = getRewardText(offerWall, ' + ');

  const rewardTextList = [
    {
      label: '참여자 전원',
      description: rewardText + ' 지급',
    },
    offerWall.type === 'RACE_INSTAGRAM_MISSION' &&
    !!offerWall.bestParticipantCount
      ? {
          label: '우수 참여자',
          description:
            (offerWall.bestParticipantCount || 0).toLocaleString() +
            '명에게 ' +
            bestRewardText +
            ' 지급',
        }
      : null,
  ];

  descriptionList.push(...rewardTextList.filter(Boolean));

  return descriptionList;
};

// RACE_INSTAGRAM_MISSION, RACE_SURVEY의 경우 어드민에서 추가하지 못하는 것들은 자체 필터링
export const filterModuleListForRace = (moduleList) => {
  return moduleList.filter(
    (m) =>
      ![
        moduleType.jsxObjectTitleModule,
        moduleType.hashTagDescriptionModule,
        moduleType.descriptionListModule,
        moduleType.descriptionModule,
        moduleType.mainImageListModule,
      ].includes(m.type),
  );
};

export const getIsModuleDisabled = (offerWallType, _moduleType) => {
  if (['RACE_INSTAGRAM_MISSION', 'RACE_SURVEY'].includes(offerWallType)) {
    return [
      moduleType.descriptionListModule,
      moduleType.descriptionModule,
    ].includes(_moduleType);
  } else return false;
};

export const isChallengeOfferWall = (offerWallType) => {
  return ['CHALLENGE_INSTANCE', 'CHALLENGE_PARTICIPATE'].includes(
    offerWallType,
  );
};

// 랜선대회에서만 노출되는 오퍼월 (RACE_PARTICIPATE는 혜택탭에서 노출)
export const isOfferWallInRace = (offerWallType) => {
  return [
    'RACE_INSTAGRAM_MISSION',
    'RACE_EXTERNAL_MISSION',
    'RACE_SURVEY',
  ].includes(offerWallType);
};

export const isMissionOfferWall = (offerWallType) => {
  return ['EXTERNAL_MISSION', 'RACE_EXTERNAL_MISSION'].includes(offerWallType);
};

export const isResultDateSetRequired = (offerWallType) => {
  return [
    'EXTERNAL_SIGN_UP',
    'EXTERNAL_MISSION',
    'RACE_INSTAGRAM_MISSION',
    'RACE_EXTERNAL_MISSION',
  ].includes(offerWallType);
};

export const isDescriptionListSetRequired = (offerWallType) => {
  return [
    'CHALLENGE_PARTICIPATE',
    'EXTERNAL_SIGN_UP',
    'EXTERNAL_MISSION',
    'RACE_EXTERNAL_MISSION',
  ].includes(offerWallType);
};

export const isExternalUrlSetRequired = (offerWallType) => {
  return ['EXTERNAL_INSTANCE', 'EXTERNAL_SIGN_UP'].includes(offerWallType);
};

export const isExternalRouteOfferWall = (offerWallType) => {
  return ['EXTERNAL_INSTANCE'].includes(offerWallType);
};

export const isDefaultRouteOfferWall = (offerWallType) => {
  return !['EXTERNAL_INSTANCE'].includes(offerWallType);
};

export const defaultRaceOfferWallTitle = (offerWallType) => {
  switch (offerWallType) {
    case 'RACE_INSTAGRAM_MISSION':
      return '인증샷을 인스타그램에 올리면 ';
    case 'RACE_SURVEY':
      return '설문조사에 참여하면 ';
    case 'RACE_EXTERNAL_MISSION':
      return '랜선 대회 미션';
    default:
      return '';
  }
};
