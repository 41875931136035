import Button from '@component/Button';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import TextInput from '@component/TextInput';
import { MultipleChoiceQuestion as MultipleChoiceQuestionType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { swap } from '@utils/js.utils';
import { Table } from 'semantic-ui-react';
import { ChoiceAnswerType } from 'src/types/achievement.types';

type MultipleChoiceQuestionProps = {
  question: MultipleChoiceQuestionType;
  questionIndex: number;
  onQuestionChange: <T extends keyof MultipleChoiceQuestionType>(input: {
    fieldName: T;
    value: MultipleChoiceQuestionType[T];
  }) => void;
};

const multipleSelectOptions = [
  { label: '복수응답 가능', value: true },
  { label: '1개만 선택', value: false },
];
const choiceTypeOptions = [
  { label: '정답', value: ChoiceAnswerType.CORRECT },
  { label: '오답', value: ChoiceAnswerType.INCORRECT },
  { label: '정답없음', value: ChoiceAnswerType.NONE },
  { label: '직접입력', value: ChoiceAnswerType.CUSTOM },
];
const MultipleChoiceQuestion = (props: MultipleChoiceQuestionProps) => {
  const { question, questionIndex, onQuestionChange } = props;
  return (
    <FlexBox.Column style={{ marginTop: 20 }}>
      <h4>질문 {questionIndex + 1}</h4>
      <FlexBox.Column style={{ marginLeft: 12 }}>
        <FlexBox.Column style={{ marginBottom: 12 }}>
          <Label>복수응답 가능여부</Label>
          <RadioSelector
            style={{ display: 'flex', flexDirection: 'column' }}
            options={multipleSelectOptions}
            selectedOption={multipleSelectOptions.find(
              ({ value }) => value === question.multipleSelection,
            )}
            onChange={({ value }) =>
              onQuestionChange({
                fieldName: 'multipleSelection',
                value,
              })
            }
          />
        </FlexBox.Column>
        <Label>질문 내용</Label>
        <TextInput
          value={question.title}
          placeholder="질문 내용"
          onChange={(text) =>
            onQuestionChange({
              fieldName: 'title',
              value: text,
            })
          }
        />
        <Table basic="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>보기</Table.HeaderCell>
              <Table.HeaderCell>구분</Table.HeaderCell>
              <Table.HeaderCell>순서변경/삭제</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {question.choices.map((choice, choiceIndex) => (
              <Table.Row key={choice.id}>
                <Table.Cell>{choice.id}</Table.Cell>
                <Table.Cell>
                  <TextInput
                    value={choice.text}
                    onChange={(value) => {
                      const currentChoice = [...question.choices];
                      currentChoice[choiceIndex] = {
                        ...currentChoice[choiceIndex],
                        text: value,
                      };
                      onQuestionChange({
                        fieldName: 'choices',
                        value: currentChoice,
                      });
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <FlexBox.Row gap={12} alignItems="center">
                    <Label>정답 여부</Label>
                    <RadioSelector
                      style={{ display: 'flex', flexDirection: 'column' }}
                      options={choiceTypeOptions}
                      selectedOption={choiceTypeOptions.find((choiceType) => {
                        return (
                          choiceType.value ===
                          question.choices[choiceIndex].type
                        );
                      })}
                      onChange={(option) => {
                        const currentChoice = [...question.choices];
                        currentChoice[choiceIndex] = {
                          ...currentChoice[choiceIndex],
                          type: option.value,
                        };
                        onQuestionChange({
                          fieldName: 'choices',
                          value: currentChoice,
                        });
                      }}
                    />
                  </FlexBox.Row>
                </Table.Cell>
                <Table.Cell>
                  <FlexBox.Row gap={6}>
                    <Button
                      text="삭제"
                      onClick={() => {
                        onQuestionChange({
                          fieldName: 'choices',
                          value: question.choices.filter(
                            (_, i) => i !== choiceIndex,
                          ),
                        });
                      }}
                    />
                    <Button
                      text="🔼"
                      onClick={() => {
                        if (choiceIndex === 0 || question.choices.length <= 1)
                          return;
                        onQuestionChange({
                          fieldName: 'choices',
                          value: swap(
                            question.choices,
                            choiceIndex,
                            choiceIndex - 1,
                          ),
                        });
                      }}
                    />
                    <Button
                      text="🔽"
                      onClick={() => {
                        if (
                          choiceIndex === question.choices.length - 1 ||
                          question.choices.length <= 1
                        )
                          return;
                        onQuestionChange({
                          fieldName: 'choices',
                          value: swap(
                            question.choices,
                            choiceIndex,
                            choiceIndex + 1,
                          ),
                        });
                      }}
                    />
                  </FlexBox.Row>
                </Table.Cell>
              </Table.Row>
            ))}
            <Table.Row>
              <Table.Cell colSpan={4}>
                <Button
                  text="추가"
                  onClick={() => {
                    const biggestId = question.choices.reduce(
                      (biggest, choice) => {
                        return biggest > choice.id ? biggest : choice.id;
                      },
                      0,
                    );
                    onQuestionChange({
                      fieldName: 'choices',
                      value: [
                        ...question.choices,
                        {
                          id: biggestId + 1,
                          type: ChoiceAnswerType.NONE,
                          text: '',
                        },
                      ],
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </FlexBox.Column>
    </FlexBox.Column>
  );
};

export default MultipleChoiceQuestion;
