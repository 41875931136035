import { ColorSample } from '@component/ColorSample';
import * as S from '../../../../Style';
import { Dropdown } from 'semantic-ui-react';
import { landingTypeOptions } from '@constant/race';
import { Race } from '@types';

const HyperlinkModule = ({
  idx,
  module,
  handleChange,
  handleRouteChange,
}: {
  idx: number;
  module: Race.RaceHyperlinkDescriptionModule;
  handleChange: any;
  handleRouteChange: any;
}) => {
  return (
    <>
      <div>화면 이동 모듈</div>
      <S.RowContainer>
        <S.RowFormField>
          <label>버튼 배경색</label>
          <input
            value={module.buttonBgColor}
            onChange={(e) => handleChange(idx, 'buttonBgColor', e.target.value)}
          />
          <ColorSample color={module.buttonBgColor} />
        </S.RowFormField>
        <S.RowFormField>
          <label>버튼 글씨색</label>
          <input
            value={module.buttonTextColor}
            onChange={(e) =>
              handleChange(idx, 'buttonTextColor', e.target.value)
            }
          />
          <ColorSample color={module.buttonTextColor} />
        </S.RowFormField>
        <S.RowFormField>
          <label>버튼 테두리 색</label>
          <input
            value={module.buttonBorderColor}
            onChange={(e) =>
              handleChange(idx, 'buttonBorderColor', e.target.value)
            }
          />
          <ColorSample color={module.buttonBorderColor} />
        </S.RowFormField>
      </S.RowContainer>
      <S.RowContainer>
        <S.RowFormField>
          <label>버튼 글씨</label>
          <input
            value={module.text}
            onChange={(e) => handleChange(idx, 'text', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField>
          <label>이동 대상</label>
          <Dropdown
            placeholder="이동 대상을 선택해주세요"
            fluid
            search
            selection
            options={landingTypeOptions}
            value={module.route.type}
            onChange={(__, { value }) =>
              handleRouteChange(idx, { key: 'type', value })
            }
          />
        </S.RowFormField>
        <S.RowFormField>
          {module.route.type === 'APP_EVENT_SCREEN' && (
            <>
              <label>이벤트명</label>
              <input
                value={module.route.params.eventName}
                onChange={(e) =>
                  handleRouteChange(idx, {
                    key: 'eventName',
                    value: e.target.value,
                  })
                }
              />
            </>
          )}
          {module.route.type === 'WEB' && (
            <>
              <label>웹페이지 url</label>
              <input
                value={module.route.params.url}
                onChange={(e) =>
                  handleRouteChange(idx, { key: 'url', value: e.target.value })
                }
              />
              <label>웹페이지 title</label>
              <label style={{ color: 'blue', whiteSpace: 'pre' }}>
                {
                  '상단 네비게이션 바에 노출되는 제목\ntitle이 없으면 상단 네비게이션 바 미노출 & 뒤로가기 불가능!'
                }
              </label>
              <input
                value={module.route.params.title}
                onChange={(e) =>
                  handleRouteChange(idx, {
                    key: 'title',
                    value: e.target.value,
                  })
                }
              />
            </>
          )}
          {module.route.type === 'RaceRegisterScreen' && (
            <>
              <label>이벤트명</label>
              <input
                value={module.route.params.raceId}
                onChange={(e) =>
                  handleRouteChange(idx, {
                    key: 'raceId',
                    value: e.target.value,
                  })
                }
              />
            </>
          )}
        </S.RowFormField>
      </S.RowContainer>
    </>
  );
};

export default HyperlinkModule;
