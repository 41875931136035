import React from 'react';
import { Form, Table } from 'semantic-ui-react';
import { generateId } from '../../../../../utils/number';
import * as S from '../../../Style';
import _ from 'lodash';
import { RaceFormType } from '../../RaceForm.types';
import { Race } from '@types';

const ReviewField = ({
  race,
  setRace,
}: {
  race: RaceFormType;
  setRace: React.Dispatch<React.SetStateAction<RaceFormType>>;
}) => {
  const addQuestion = () => {
    setRace({
      ...race,
      reviewInfo: {
        ...race.reviewInfo,
        questions: [
          ...(race.reviewInfo?.questions ?? []),
          {
            id: generateId(),
            question: '',
            required: true,
            minLength: 10,
            placeholder: '',
          },
        ],
      },
    });
  };

  const deleteQuestion = (idx: number) => {
    setRace({
      ...race,
      reviewInfo: {
        ...race.reviewInfo,
        questions: race.reviewInfo?.questions?.filter(
          (q, index) => index !== idx,
        ),
      },
    });
  };

  const handleReviewChange = (key: string, value: string) => {
    setRace({
      ...race,
      reviewInfo: {
        ...race.reviewInfo,
        [key]: value,
      },
    });
  };

  const handleReviewQuestionChange = <K extends keyof Race.RaceReviewQuestion>(
    idx: number,
    key: K,
    value: Race.RaceReviewQuestion[K],
  ) => {
    const _race = { ...race };
    if (!_race?.reviewInfo?.questions?.[idx]) return;
    _race.reviewInfo.questions[idx][key] = value;
    setRace(_race);
  };

  return (
    <>
      <h3>리뷰 질문</h3>
      <S.RowContainer
        style={{
          flexDirection: 'column',
        }}
      >
        <S.RowFormField>
          <label>리뷰 설명글</label>
          <textarea
            rows={10}
            cols={100}
            value={race.reviewInfo?.description}
            onChange={(e) => handleReviewChange('description', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField
          style={{
            marginTop: 20,
          }}
        >
          <label>리뷰 질문 목록</label>
          <Table basic="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>no</Table.HeaderCell>
                <Table.HeaderCell>리뷰 질문</Table.HeaderCell>
                <Table.HeaderCell>필수/선택</Table.HeaderCell>
                <Table.HeaderCell>최소 길이</Table.HeaderCell>
                <Table.HeaderCell>placeholder</Table.HeaderCell>
                <Table.HeaderCell>삭제</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {race.reviewInfo?.questions?.map((question, idx) => (
                <Table.Row key={`${question.id}-${idx}`}>
                  <Table.Cell>{idx + 1}</Table.Cell>
                  <Table.Cell>
                    <input
                      value={question.question}
                      onChange={(e) =>
                        handleReviewQuestionChange(
                          idx,
                          'question',
                          e.target.value,
                        )
                      }
                      style={{ minWidth: 350 }}
                    />
                  </Table.Cell>
                  <Table.Cell style={{ width: 100 }}>
                    <Form.Group grouped>
                      <Form.Field
                        label="필수"
                        control="input"
                        type="radio"
                        checked={question.required}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleReviewQuestionChange(idx, 'required', true)
                        }
                      />
                      <Form.Field
                        label="선택"
                        control="input"
                        type="radio"
                        checked={!question.required}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleReviewQuestionChange(idx, 'required', false)
                        }
                      />
                    </Form.Group>
                  </Table.Cell>
                  <Table.Cell style={{ width: 100 }}>
                    <input
                      value={question.minLength}
                      onChange={(e) => {
                        if (Number.isNaN(Number(e.target.value))) return;
                        handleReviewQuestionChange(
                          idx,
                          'minLength',
                          Number(e.target.value),
                        );
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <input
                      value={question.placeholder}
                      onChange={(e) =>
                        handleReviewQuestionChange(
                          idx,
                          'placeholder',
                          e.target.value,
                        )
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <S.ButtonInForm
                      content={'삭제'}
                      onClick={() => deleteQuestion(idx)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
              <Table.Row>
                <Table.Cell colSpan={6}>
                  <S.ButtonInForm content={'추가'} onClick={addQuestion} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </S.RowFormField>
      </S.RowContainer>
    </>
  );
};

export default ReviewField;
