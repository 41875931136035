import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import TextInput from '@component/TextInput';
import { ChallengeCustomField } from '@types';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './FaqForm.serializer';
import { useFaq, useUpdateFaq } from './FaqForm.store';
import { validate } from './FaqForm.validator';

export const FaqForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'faq'>,
  Extract<ChallengeCustomField, { name: 'faq' }>
> = () => {
  const faq = useFaq();
  const { update } = useUpdateFaq();

  const selectedOption = OPTIONS.find(
    (option) => (typeof faq === 'string') === option.value,
  );

  return (
    <FlexBox.Column gap={12}>
      <FlexBox.Column>
        <Label>FAQ 모듈</Label>
        <div>
          {
            '인증 > 챌린지 상세 > 문의하기 버튼 눌렀을 때 거쳤다가 1:1문의로 이동'
          }
        </div>
      </FlexBox.Column>
      <FlexBox.Row alignItems="center" gap={20}>
        <RadioSelector
          selectedOption={selectedOption}
          options={OPTIONS}
          onChange={(o) => update(o.value ? '' : null)}
          style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
        />
        {typeof faq === 'string' && (
          <TextInput
            label="FAQ 노션링크"
            value={faq}
            onChange={update}
            style={{ width: 300 }}
          />
        )}
      </FlexBox.Row>
    </FlexBox.Column>
  );
};

const OPTIONS = [
  { label: '있음', value: true },
  { label: '없음', value: false },
];

FaqForm.validate = validate;
FaqForm.convertData2Form = convertData2Form;
FaqForm.convertForm2Data = convertForm2Data;
