import { apis } from '@api/index';
import { useInfiniteQuery } from '@tanstack/react-query';
import React from 'react';

const pageSize = 30;

const useCollaboChallenges = () => {
  return useInfiniteQuery({
    queryKey: ['challenges'],
    queryFn: async ({ pageParam }) => {
      const res = await apis.challenge.getCollaboChallenge({
        offset: pageParam,
        limit: pageSize,
      });
      return {
        ...res,
        nextOffset: pageParam + pageSize,
      };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.isEnd ? undefined : lastPage.nextOffset;
    },
    initialPageParam: 0,
    select: (data) => data?.pages.flatMap((page) => page.challenges),
  });
};

export default useCollaboChallenges;
