import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useExp = () =>
  useChallengeFormStore(useShallow((s) => ({ exp: s.challenge.exp })));

export const useExpActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setExp = (exp: number) => updateChallengeForm({ exp });

  return { setExp };
};
