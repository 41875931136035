import { CrmTemplateFieldValue, CrmTemplateInfo, CrmTimePoint } from '@types';

// CRM 텍스트 템플릿 가이드 값
export const TEMPLATE_FIELD_VALUES: {
  key: CrmTemplateFieldValue;
  value: CrmTemplateFieldValue;
  text: string;
}[] = [
  {
    text: '유저 이름 (실명)',
    value: 'Name',
    key: 'Name',
  },
  {
    text: '유저 닉네임',
    value: 'NickName',
    key: 'NickName',
  },
  {
    text: '챌린지명',
    value: 'ChallengeName',
    key: 'ChallengeName',
  },
  {
    text: '제품명',
    value: 'CollaboProductName',
    key: 'CollaboProductName',
  },
  {
    text: '챌린지 인증 시작일',
    value: 'AchievementStartDate',
    key: 'AchievementStartDate',
  },
  {
    text: '인증 시작 시간',
    value: 'AchievementStartTime',
    key: 'AchievementStartTime',
  },
  {
    text: '인증 종료 시간',
    value: 'AchievementEndTime',
    key: 'AchievementEndTime',
  },
  {
    text: '회사 이름',
    value: 'CompanyName',
    key: 'CompanyName',
  },
  {
    text: '구매하러 가기 주소',
    value: 'CollaboProductLink',
    key: 'CollaboProductLink',
  },
  {
    text: '특별 상금',
    value: 'SpecialPrize',
    key: 'SpecialPrize',
  },
  {
    text: '시간 차이 (n시간)',
    value: 'AchievementTimeDiff',
    key: 'AchievementTimeDiff',
  },
];

// CRM 전송 시점 가이드 값
export const TIME_POINT_VALUES: {
  key: CrmTimePoint;
  value: CrmTimePoint;
  text: string;
}[] = [
  {
    value: 'DayBefore',
    text: '구매 전날 오후 7시',
    key: 'DayBefore',
  },
  {
    value: 'PurchaseDate',
    text: '구매 당일',
    key: 'PurchaseDate',
  },
  {
    value: 'PurchaseStart',
    text: '구매 시작',
    key: 'PurchaseStart',
  },
];

export const initialTemplatInfoList: CrmTemplateInfo[] = [
  {
    title: '',
    timePoint: 'DayBefore',
    template: '',
    sendType: 'SMS',
  },
  {
    title: '',
    timePoint: 'PurchaseDate',
    template: '',
    sendType: 'SMS',
  },
  {
    title: '',
    timePoint: 'PurchaseStart',
    template: '',
    sendType: 'SMS',
  },
];
