import { Race } from '@types';

export const flatRaceProductGroup = (productGroup?: Race.ProductGroup) => {
  if (!productGroup) return [];
  const products: {
    id: number;
    name: string;
    subName: string;
    remainCount: number;
    totalCount: number;
  }[] = [];
  productGroup.options?.values.forEach((optionGroup) => {
    optionGroup.options?.values.forEach((option) => {
      products.push({
        id: option.id,
        name: optionGroup.name,
        subName: option.name,
        remainCount: option.remainCount,
        totalCount: option.totalCount,
      });
    });
  });
  return products;
};

export const checkIsNewOption = (id: number) => {
  return id > 100000;
};
