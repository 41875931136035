import styled from 'styled-components';
import { Form, Label, Icon } from 'semantic-ui-react';
import React from 'react';

export const RowContainer = styled.div`
  display: flex;
  margin: ${(props) => props.margin && props.margin} !important;
  height: ${(props) => props.height && props.height};
`;

export const FormGroup = styled(Form.Group)`
  flex: 1;
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const RowFormField = styled(Form.Field)`
  flex: 1;
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const Container = styled.div`
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const ButtonWrapper = styled.div`
  padding: 40px 0;
`;

export const RowWrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const LabelButton = styled(Label)`
  cursor: pointer;
  margin: ${(props) => props.margin && props.margin} !important;
  width: 100%;
  height: 50px;
`;

export const ModuleWrapper = styled.div`
  position: relative;
  margin: 10px 20px;
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const ImageRemoveIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: ${(props) => (props.top ? props.top : 0)};
  bottom: ${(props) => props.bottom && props.bottom};
  left: ${(props) => (props.left ? props.left : 0)};
  right: ${(props) => props.right && props.right};
  padding: ${(props) => (props.padding ? props.padding : '4px')};
  width: ${(props) => (props.size ? props.size : '28px')};
  height: ${(props) => (props.size ? props.size : '28px')};
  background-color: white;
  border: 1px solid gray;
  border-radius: 4px;
  cursor: pointer;
`;

export const ImageRemoveIcon = (props) => {
  return (
    <ImageRemoveIconWrapper {...props}>
      <Icon name="delete" style={{ margin: 0 }} />
    </ImageRemoveIconWrapper>
  );
};
