import { OfferWallReward } from '../../../types/benefit.types';

export const getOfferWallTitle = ({
  brand,
  title,
  reward,
}: {
  brand: string;
  title: string;
  reward: OfferWallReward;
}): string => {
  const {
    storePointAmount,
    prizePointAmount,
    bestStorePointAmount,
    bestPrizePointAmount,
  } = reward;

  // 참여자 전원
  const prizePointText = `상금 ${(prizePointAmount || 0).toLocaleString()}원`;

  const storePointText = `포인트 ${(storePointAmount || 0).toLocaleString()}P`;

  const rewardText = [prizePointText, storePointText]
    .filter(Boolean)
    .join(', ');

  // 우수 참여자
  const bestPrizePointText =
    bestPrizePointAmount !== 0
      ? `상금 ${bestPrizePointAmount.toLocaleString()}원`
      : null;

  const bestStorePointText =
    bestStorePointAmount !== 0
      ? `포인트 ${bestStorePointAmount.toLocaleString()}P`
      : null;

  const bestRewardText = [bestPrizePointText, bestStorePointText]
    .filter(Boolean)
    .join(', ');

  const finalRewardText =
    rewardText + (bestRewardText !== '' ? bestRewardText : '');

  return `${brand} ${title} ${finalRewardText} 을 드려요`;
};
