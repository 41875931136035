import { DescriptionModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

// TODO: 검증 로직 추가
export const validate = (
  descriptionModule: DescriptionModuleFormType[],
): { isValid: true } | { isValid: false; message: string } => {
  const target = descriptionModule.find(
    ({ type }) => type === 'COLLABO-SUPPORTERS-INSTAGRAM',
  );
  if (!target) return { isValid: true };

  // const {
  //   data: {
  //     instagramNoticeList,
  //     collaboImageUrls,
  //     checkList,
  //     mainExampleImageUrl,
  //     exampleImageUrls,
  //     tipTextList
  //   },
  // } = target as DescriptionCollaboSupportersInstagramModuleFormType;

  return { isValid: true };
};
