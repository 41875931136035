import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { Col } from '../Style';

const WhiteLogoPreview = () => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Accordion>
      <Accordion.Title
        active={!collapsed}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Icon name="dropdown" />
        미리보기
      </Accordion.Title>
      <Accordion.Content active={!collapsed}>
        <Col>
          <strong>대회 진행 화면 우상단</strong>
          <img
            src="/race/후원사-화이트로고1.png"
            width={240}
            alt="후원사-화이트로고1"
          />
        </Col>
      </Accordion.Content>
    </Accordion>
  );
};

export default WhiteLogoPreview;
