import { apis } from '@api/index';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useUserFilter = () =>
  useChallengeFormStore((s) => s.challenge.userFilter);

export const useUpdateUserFilter = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const update = async (id: number | null) => {
    if (id === null) {
      updateChallengeForm({
        userFilter: { id: null, name: null },
      });
    } else {
      const { userFilter } = await apis.getUserFilterById(Number(id));

      updateChallengeForm({
        userFilter,
      });
    }
  };

  return { update };
};
