import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Grid,
  Form,
  Radio,
  Dropdown,
  Input,
  DropdownItemProps,
  DropdownProps,
} from 'semantic-ui-react';
import { apis } from '../../../api';
import RaceMenu from '../RaceMenu/RaceMenu';
import RaceTable from './components/RaceTable/RaceTable';
import * as S from '../Style';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useQueryString } from '../../../hooks/useQueryString';
import { raceTypeOptions } from '../../../constant/race';
import { extractNumbers, removeDuplicates } from '../../../utils/js.utils';
import moment from 'moment';
import { RaceForAdmin } from 'src/types/race.types';

type OrderByType = 'homecard' | 'setting';

const initialQueryStr = { orderBy: 'homecard' };
const initialFilterOption = {
  text: '선택안함',
  value: null,
  content: '선택안함',
};
const initialFilter = {
  year: '',
  month: '',
  title: '',
  raceType: '',
};

const RaceList = () => {
  const { setQueryString, getQueryString } = useQueryString();
  const orderByFromQueryStr = getQueryString('orderBy');
  const [raceList, setRaceList] = useState<RaceForAdmin[]>([]);
  const [filter, setFilter] = useState(initialFilter);

  useEffect(() => {
    const _getRaceList = async (orderBy: OrderByType) => {
      const { raceList: _raceList } = await apis.getRaceList({
        orderBy: orderBy || 'homecard',
      });
      setRaceList(_raceList);
    };

    _getRaceList(orderByFromQueryStr as OrderByType);

    if (orderByFromQueryStr === 'homecard') {
      setFilter(initialFilter);
    }
  }, [orderByFromQueryStr]);

  useEffect(() => {
    const scrollY = sessionStorage.getItem('scroll-y');
    if (scrollY !== null) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(scrollY, 10));
        sessionStorage.removeItem('scroll-y');
      }, 500);
    }

    if (!orderByFromQueryStr) {
      setQueryString(initialQueryStr);
    }
  }, []);

  const copyRace = useCallback(
    async (_race: RaceForAdmin) => {
      if (
        window.confirm(`[${_race.id}]${_race.title} 대회를 복사하시겠습니까?`)
      ) {
        const _raceList = _.cloneDeep(raceList);
        const res = await apis.copyRace(_race.id);

        if (res?.response?.data && 'err' in res?.response?.data) {
          return;
        }
        const { race } = res;
        _raceList.splice(0, 0, race);
        setRaceList(_raceList);
        alert('복사가 완료되었어요.');
      }
    },
    [raceList],
  );

  const removeRace = async (_race: RaceForAdmin) => {
    if (!window.confirm(`[${_race.id}]${_race.title} 대회를 삭제하시겠습니까?`))
      return;

    const res = await apis.removeRace(_race.id);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    setRaceList(raceList.filter((r) => r.id !== _race.id));
    alert('삭제가 완료되었어요.');
  };

  const handleSortClick = (orderBy: OrderByType) => {
    setQueryString({ orderBy });
  };

  const handleFilterChange = useCallback(
    (data: { name: string; value: string }) => {
      const { name, value } = data;
      setFilter((prev) => ({ ...prev, [name]: value }));
    },
    [],
  );

  return (
    <Grid columns={'equal'} style={{ margin: '40px 20px', maxWidth: '1800px' }}>
      <Grid.Column width={2}>
        <RaceMenu />
      </Grid.Column>
      <Grid.Column>
        <S.RowContainer margin={'0 0 20px 0'}>
          <S.RowContainer margin={'0 40px 0 0'}>
            <h3>대회 목록</h3>
            <div>
              <Button
                as={Link}
                size="mini"
                floated="right"
                to={{ pathname: '/race/create' }}
                content="대회 추가"
              />
            </div>
          </S.RowContainer>

          <S.RowContainer gap={'5px'}>
            <h4>정렬 기준</h4>
            <Form.Field
              control={Radio}
              label="홈카드순"
              value={orderByFromQueryStr}
              checked={orderByFromQueryStr === 'homecard'}
              onChange={() => handleSortClick('homecard')}
            />
            <Form.Field
              control={Radio}
              label="세팅순"
              value={orderByFromQueryStr}
              checked={orderByFromQueryStr === 'setting'}
              onChange={() => handleSortClick('setting')}
            />
          </S.RowContainer>

          {orderByFromQueryStr === 'setting' && (
            <S.RowContainer margin={'0 0 0 50px'} gap={'10px'}>
              <h4>검색 필터</h4>
              <Dropdown
                placeholder="연도"
                fluid
                selection
                options={
                  [
                    initialFilterOption,
                    ...removeDuplicates(
                      raceList.map(
                        ({ startDate }) =>
                          moment(startDate).format('YYYY-MM-DD').split('-')[0],
                      ),
                    )
                      .map((d) => ({
                        text: `${d}년`,
                        value: d,
                      }))
                      .sort(
                        (a, b) =>
                          Number(extractNumbers(b.value)) -
                          Number(extractNumbers(a.value)),
                      ),
                  ] as DropdownItemProps[]
                }
                name="year"
                onChange={(__, data) =>
                  handleFilterChange(data as { name: string; value: string })
                }
                style={{ width: 100 }}
              />
              <Dropdown
                placeholder="월"
                fluid
                selection
                options={
                  [
                    initialFilterOption,
                    ...removeDuplicates(
                      raceList.map(({ seasonTerm }) => seasonTerm),
                    )
                      .map((d) => ({
                        text: d,
                        value: d,
                      }))
                      .sort(
                        (a, b) =>
                          Number(extractNumbers(b.value)) -
                          Number(extractNumbers(a.value)),
                      ),
                  ] as DropdownItemProps[]
                }
                name="month"
                onChange={(__, data) =>
                  handleFilterChange(data as { name: string; value: string })
                }
                style={{ width: 100 }}
              />
              <Input
                placeholder="대회명"
                name="title"
                onChange={(__, data) =>
                  handleFilterChange(data as { name: string; value: string })
                }
                style={{ width: 150 }}
              />
              <Dropdown
                placeholder="주제"
                fluid
                selection
                options={
                  [
                    initialFilterOption,
                    ...removeDuplicates(
                      raceList.map(
                        ({ raceType }) =>
                          raceTypeOptions.find((o) => o.value === raceType)
                            ?.text,
                      ),
                    ).map((d) => ({
                      text: d,
                      value: raceTypeOptions.find((o) => o.text === d)?.value,
                    })),
                  ] as DropdownItemProps[]
                }
                name="raceType"
                onChange={(__, data) =>
                  handleFilterChange(data as { name: string; value: string })
                }
                style={{ width: 100 }}
              />
            </S.RowContainer>
          )}
        </S.RowContainer>

        <RaceTable
          raceList={raceList.filter(
            ({ startDate, seasonTerm, title, raceType }) =>
              (filter.year ||
                moment(startDate).format('YYYY-MM-DD').split('-')[0]) ===
                moment(startDate).format('YYYY-MM-DD').split('-')[0] &&
              (filter.month || seasonTerm) === seasonTerm &&
              title.includes(filter.title || title) &&
              (filter.raceType || raceType) === raceType,
          )}
          copyRace={copyRace}
          removeRace={removeRace}
          setRaceList={setRaceList}
          orderBy={orderByFromQueryStr}
        />
      </Grid.Column>
    </Grid>
  );
};

export default RaceList;
