import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import Dropdown from '@component/Dropdown';
import { useGetAllCompanies } from '@queries/Commerce/useGetCompanies';
import FlexBox from '@component/FlexBox/FlexBox';
import ErrorMsg from '@component/ErrorMsg';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './CompanyIdForm.serializer';
import { useCompanyId, useCompanyIdActions } from './CompanyIdForm.store';
import { validate } from './CompanyIdForm.validator';

const CompanyIdForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'company'>,
  Pick<ChallengeFormType, 'companyId'>,
  Pick<ReqCreateOrUpdateChallenge, 'companyId'>
> = () => {
  const { companyId, thirdTermUrl } = useCompanyId();
  const { setCompanyId } = useCompanyIdActions();
  const { data: companies } = useGetAllCompanies();

  const validated = validate({ companyId, thirdTermUrl });

  if (!companies) return <></>;

  return (
    <FlexBox.Column>
      <Dropdown
        label="회사"
        options={companies.map((c) => ({ text: c.name, value: c.id }))}
        value={companyId || undefined}
        search
        placeholder="회사를 선택해주세요."
        onChange={setCompanyId}
        keyExtractor={(item) => item}
      />
      <ErrorMsg text={!validated.isValid ? validated.message : ''} />
    </FlexBox.Column>
  );
};
export default CompanyIdForm;

CompanyIdForm.validate = validate;
CompanyIdForm.convertData2Form = convertData2Form;
CompanyIdForm.convertForm2Data = convertForm2Data;
