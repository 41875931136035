import ImageInput from '@component/ImageInput';
import { FC } from 'react';
import { cropAndUploadImage } from '@utils/image.utils';

type Props = {
  image: string;
  onChange: (image: string) => void;
};
const RewardImageForm: FC<Props> = ({ image, onChange }) => {
  const handleChangeImage = async (file?: File) => {
    if (!file) return;
    const urls = await cropAndUploadImage(file);
    onChange(urls[0]);
  };

  return (
    <>
      <ImageInput label="추가리워드 이미지" onChange={handleChangeImage} />
      <img src={image} alt="additional-reward" width={250} />
    </>
  );
};

export default RewardImageForm;
