import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'userId'>,
  Pick<ReqCreateOrUpdateChallenge, 'userId'>
> = ({ userId }) => {
  if (!userId) throw Error("[UserIdForm] this shouldn't be happened");

  return {
    userId,
  };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'userId'>,
  Pick<ChallengeFormType, 'userId'>
> = ({ userId }) => ({
  userId,
});
