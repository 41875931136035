import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useResultDateStore = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      resultDate: s.challenge.resultDate,
      endDate: s.challenge.endDate,
    })),
  );

export const useUpdateResultDate = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const updateResultDate = (resultDate: string) => {
    updateChallengeForm({ resultDate });
  };

  return { updateResultDate };
};
