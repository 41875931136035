import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, Header, Table } from 'semantic-ui-react';
import moment from 'moment';
import { apis } from '../../../api';
import CollaboMenu from '../CollaboMenu';
import CollaboAdminModal from './components/CollaboAdminModal';
import { getCollaboChallengeOptions } from '../../../utils/dropdownOptions';

const CollaboAdmin = () => {
  const [adminUserList, setAdminUserList] = useState([]);
  const [challengeOptions, setChallengeOptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [adminUserInfo, setAdminUserInfo] = useState({});

  useEffect(() => {
    _getCollaboAdminUserList();
    _getCollaboChallengeOptions();
  }, []);

  const _getCollaboAdminUserList = useCallback(async () => {
    const { adminUserList: _adminUserList } =
      await apis.getCollaboAdminUserList({ offset: 0, limit: 1000 });
    setAdminUserList(_adminUserList);
  }, []);

  const _getCollaboChallengeOptions = useCallback(async () => {
    const challengeOptions = await getCollaboChallengeOptions();
    setChallengeOptions(challengeOptions);
  }, []);

  const showModal = useCallback((_adminUserInfo) => {
    if (!!_adminUserInfo) {
      _adminUserInfo.challengeIds =
        Object.keys(_adminUserInfo.challenges).map((cid) => Number(cid)) || [];
      setAdminUserInfo(_adminUserInfo);
    }
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setAdminUserInfo({});
    setModalOpen(false);
  }, []);

  const createAdminUser = async (_adminUserInfo) => {
    const res = await apis.creteCollaboAdminUser(_adminUserInfo);
    if (res?.response?.data?.err) {
      return;
    }
    const { adminUser, isNew } = res;
    if (isNew) {
      alert('생성이 완료되었어요.');
    } else {
      alert(
        '동일한 이메일 계정이 존재하여 계정 복구하였습니다. 비밀번호는 새 비밀번호가 아닌 기존 비밀번호로 복구되었으니 참고 부탁드리겠습니다.',
      );
    }

    const _adminUserList = [...adminUserList];
    _adminUserList.push(adminUser);
    setAdminUserList(_adminUserList);
    closeModal();
  };

  const updateAdminUser = async (_adminUserInfo) => {
    const res = await apis.updateCollaboAdminUser(_adminUserInfo);
    if (res?.response?.data?.err) {
      return;
    }
    alert('업데이트가 완료되었어요.');

    const { adminUser } = res;

    const _adminUserList = [...adminUserList].map((user) => {
      if (user.id === adminUser.id) {
        return adminUser;
      } else return user;
    });

    setAdminUserList(_adminUserList);
    closeModal();
  };

  const deleteAdminUser = useCallback(
    async (_adminInfo) => {
      if (
        confirm(`[${_adminInfo.id}]${_adminInfo.email}님을 삭제하시겠습니까?`)
      ) {
        const { success } = await apis.deleteCollaboAdminUser({
          id: _adminInfo.id,
        });
        if (success) alert('삭제가 완료되었어요.');

        const _adminUserList = [...adminUserList].filter(
          (o) => o.id !== _adminInfo.id,
        );
        setAdminUserList(_adminUserList);
      }
    },
    [adminUserList],
  );

  return (
    <Grid columns={'equal'} style={{ margin: 40 }}>
      <Grid.Column width={2}>
        <CollaboMenu />
      </Grid.Column>
      <Grid.Column>
        <Header as="h3">
          제휴 어드민 계정 관리
          <Button size="mini" onClick={() => showModal()}>
            추가
          </Button>
        </Header>

        <Table basic="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>이메일</Table.HeaderCell>
              <Table.HeaderCell>이름</Table.HeaderCell>
              <Table.HeaderCell>챌린지 목록</Table.HeaderCell>
              <Table.HeaderCell>수정/삭제</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {adminUserList.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan={5}>어드민 정보가 없습니다</Table.Cell>
              </Table.Row>
            )}
            {adminUserList.map((user) => (
              <Table.Row key={user.id}>
                <Table.Cell>{user.id}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>{user.name}</Table.Cell>
                <Table.Cell>
                  {Object.values(user.challenges).map((challenge) => (
                    <div key={challenge.id}>
                      [{challenge.id}] {challenge.title} (
                      {moment(challenge.startDate).format('YYYY-MM-DD')}~
                      {moment(challenge.endDate).format('YYYY-MM-DD')})
                    </div>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  <Button size="mini" onClick={() => showModal(user)}>
                    수정
                  </Button>
                  <Button size="mini" onClick={() => deleteAdminUser(user)}>
                    삭제
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {modalOpen && (
          <CollaboAdminModal
            modalOpen={modalOpen}
            closeModal={closeModal}
            challengeOptions={challengeOptions}
            adminUserInfo={adminUserInfo}
            createAdminUser={createAdminUser}
            updateAdminUser={updateAdminUser}
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

export default CollaboAdmin;
