import {
  ChallengeForQuestion,
  ChallengeInfoForReportedAchievement,
  ChallengeMiniInfoForAdmin,
} from './challenge.types';
import { UserIdNameNickname, UserMiniInfo } from './user.types';

export type ReviewResult =
  | 'BEFORE'
  | 'PASS'
  | 'FAIL'
  | 'COMMENT'
  | 'RED'
  | 'USER_RED'
  | 'ADMIN_REGISTER'
  | 'DELETE_AND_PASS';
export type FeedAchievement = {
  achievementId: number;
  achievementFileId: number;
  url: string;
  createdAt: string;
  submitDatetime: string;
  user: UserMiniInfo;
  memo: string;
  isDeletable: boolean;
  isLiked: boolean;
  isReported: boolean;
  reviewResult: ReviewResult;
  likeUserCount: number;
  commentCount: number;
  questions: Question[];
  challengeId: number;
};
export type Question =
  | MultipleChoiceQuestion
  | ShortAnswerQuestion
  | EssayAnswerQuestion;

export const enum QuestionType {
  MULTIPLE_CHOICE = 'multiple-choice',
  MULTIPLE_CHOICE_ANSWER_EXIST = 'multiple-choice-answer-exist',
  MULTIPLE_CHOICE_NO_ANSWER = 'multiple-choice-no-answer',
  SHORT_ANSWER = 'short-answer',
  ESSAY_ANSWER = 'essay-answer',
}
/**
 * 답변 제출 전 | 후 는 데이터 생겨먹은 구조는 똑같다.
 * 근데 null 값들은 optional 이여도 될 것 같다.
 */
export type MultipleChoiceQuestion = {
  id: number;
  type: QuestionType.MULTIPLE_CHOICE;
  title: string;
  multipleSelection: boolean;
  choices: ChoiceType[];
};

export const enum ChoiceAnswerType {
  CORRECT = 'correct', // 이 choice 가 정답임
  INCORRECT = 'incorrect', // 이 choice 가 답이 아님
  NONE = 'none', // 정해진 답이 없음
  CUSTOM = 'custom', // 유저가 아무값이나 입력하면 정답
}

export type ChoiceType = {
  id: number;
  type: ChoiceAnswerType;
  text: string;
};
export type ShortAnswerQuestion = {
  id: number;
  type: QuestionType.SHORT_ANSWER;
  title: string;
  placeholder: string | null;
};
/**
 * @see https://www.figma.com/file/A7MjaLdrS2YNBlyX8fS8TX/%EC%B1%8C%EB%A6%B0%EC%A7%80%EC%85%80-2022.10~?node-id=8913%3A503067&t=idm0gk2Mb2ouSDXm-4
 */
export type EssayAnswerQuestion = {
  id: number;
  type: QuestionType.ESSAY_ANSWER;
  title: string;

  answerTitle: {
    minLength: number | null;
    maxLength: number | null;
  };

  answerContent: {
    minLength: number | null;
    maxLength: number | null;
  };
};
/**
 * 집계된 데이터 정보
 * achievementFileId or achievementFileId 로 조회
 */
export type AggregatedAnswer = (
  | AggregatedMultipleChoiceAnswer
  | AggregatedShortAnswer
  | AggregatedEssayAnswer
) & {
  data: (
    | AggregatedMultipleChoiceAnswerData
    | AggregatedShortAnswerData
    | AggregatedEssayAnswerData
  )[];
};
export type AggregatedMultipleChoiceAnswer = {
  type: 'multiple-choice';
  id: number;
  title: string;
  subtitle: string | null;
  totalCount: number;
  summary: AggregatedMultipleChoicePercentage[];
  /**
   * customText 는 기타로 집계
   */
  data: AggregatedMultipleChoiceAnswerData[];
};
export type AggregatedMultipleChoiceAnswerData = {
  user: UserMiniInfo;
  achievementFileId: number;
  createdAt: string;
  answerContent: string[];
};
export type AggregatedMultipleChoicePercentage = {
  text: string;
  percentage: number;
  choicedCount: number;
  isCorrect: boolean;
};
export type AggregatedShortAnswer = {
  type: 'short-answer';
  id: number;
  title: string;
  subtitle: string | null;
  data: AggregatedShortAnswerData[];
};
export type AggregatedShortAnswerData = {
  user: UserMiniInfo;
  achievementFileId: number;
  createdAt: string;
  answerContent: string;
};
export type AggregatedEssayAnswer = {
  type: 'essay-answer';
  id: number;
  title: string;
  subtitle: string | null;
  data: AggregatedEssayAnswerData[];
};
export type AggregatedEssayAnswerData = {
  user: UserMiniInfo;
  achievementFileId: number;
  createdAt: string;
  answerTitle: string;
  answerContent: string;
};
export type MultipleChoiceAnswer = {
  questionId: number;
  type: 'multiple-choice';
  choices: {
    id: number;
    customText?: string;
  }[];
};
export type ShortAnswerAnswer = {
  questionId: number;
  type: 'short-answer';
  content: string;
};
export type EssayAnswerAnswer = {
  questionId: number;
  type: 'essay-answer';
  title: string;
  content: string;
};
export type Answer =
  | MultipleChoiceAnswer
  | ShortAnswerAnswer
  | EssayAnswerAnswer;
/**
 * AggregatedAnswer 를 일반화해서 Flat 하게 만든 클라 타입
 */
export type AggregatedListItem = {
  user: UserMiniInfo;
  type: AggregatedAnswer['type'];
  createdAt: string;
  achievementFileId: number;
  answerTitle?: string | null;
  answerContent: string | string[];
};
export type PublicType =
  | 'PARTICIPANT'
  | 'SECRET'
  | 'DELETE'
  | 'COACH'
  | 'PARTIAL';
export type AchievementCommentFilter = 'COMMENT' | 'NO_COMMENT' | 'ALL';
export type AchievementOrderBy =
  | 'CHALLENGE_END_DATE'
  | 'CHALLENGE_TITLE'
  | 'REPORT_DATE';

export type ReportedAchievement = {
  id: number;
  achievementFile: ReportedAchievementFile;
  challenge: ChallengeInfoForReportedAchievement;
  failCount: number;
  redCount: number;
  reportUsers: ReportInfo[];
  user: UserIdNameNickname;
  adminComments: AdminCommentInfo[];
  comments: ReviewResultCommentInfo[];
  isChecked?: boolean;
};

export type ReportedAchievementFile = {
  id: number;
  isDeleted: boolean;
  memo: string | null;
  reportCount: number;
  reviewResult: ReviewResult;
  submitDatetime: string;
  url: string;
};

export type ReportInfo = {
  reportReason: 1 | 2 | 3 | 4;
  comment: string;
  user: UserIdNameNickname;
};

export type AdminCommentInfo = {
  achievementId: number;
  achievementFileId: number;
  createdAt: string;
};

export type ReviewResultCommentInfo = {
  achievementId: number;
  achievementFileId: number;
  updatedAt: string;
};

export type AchievementChallengeType =
  | 'NORMAL'
  | 'BAD_USER'
  | 'MINI'
  | 'CUSTOM'
  | 'COLLABORATION'
  | 'OFFICIAL';

export type Achievements = {
  dueEndDate: string;
  dueEndTime: string;
  dueStartDate: string;
  dueStartTime: string;
  nthTerm: number;
};

export type AchievementAllInfoForAdmin = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  dueStartDate: string;
  dueStartTime: string;
  dueEndDate: string;
  dueEndTime: string;
  nthTerm: number;
  rate?: number;
  relationUserChallengeId: number;
  achievementFile: AchievementFile;
  challenge: ChallengeForQuestion;
  comments: any[];
  likeUsers: UserMiniInfo[];
  reportUsers: ReportUserInfo;
  user: UserMiniInfo;
  warnCount: number;
};

type ReportUserInfo = {
  user: UserMiniInfo;
  comment: string;
  reportReason: number;
};

export type AchievementFile = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  achievementId?: number;
  url?: string;
  submitDatetime: Date;
  memo?: string;
  reviewResult?: string;
  reviewComment?: string;
  reportCount?: number;
  isDeleted?: boolean;
  data: any;
  isRainAchievement: boolean;
  isUrlCard: boolean;
};

export type ReqGetAchievementSetting = {
  infoList: AchievementSetting[];
};

export type AchievementSetting = {
  isPublic?: boolean;
  prizeAmount?: number;
  dueStartDate?: string;
  dueEndDate?: string;
  dueStartTime?: string;
  dueEndTime?: string;
};
