import { ChallengeCustomField, ChallengeImageFilterCustomField } from '@types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'imageFilter'>,
  Extract<ChallengeCustomField, { name: 'imageFilter' }>
> = ({ imageFilter }) => {
  if (imageFilter && !imageFilter.length) return [];
  return [{ name: 'imageFilter', values: imageFilter }];
};

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'imageFilter'>
> = (customFields) => {
  const imageFilter = customFields.find(({ name }) => name === 'imageFilter');

  if (!imageFilter) return {} as EmptyObj;

  const { values } = imageFilter as ChallengeImageFilterCustomField;

  return { imageFilter: values };
};
