import {
  ChallengeCustomField,
  ChallengeViralLoopInviteeRewardCustomField,
} from '@types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'viralLoopInviteeReward'>,
  Extract<ChallengeCustomField, { name: 'viralLoopInviteeReward' }>
> = ({ viralLoopInviteeReward }) => {
  if (!viralLoopInviteeReward) return [];
  return [{ name: 'viralLoopInviteeReward', values: viralLoopInviteeReward }];
};

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'viralLoopInviteeReward'>
> = (customFields) => {
  const viralLoopInviteeReward = customFields.find(
    ({ name }) => name === 'viralLoopInviteeReward',
  );

  if (!viralLoopInviteeReward) return {} as EmptyObj;

  const { values } =
    viralLoopInviteeReward as ChallengeViralLoopInviteeRewardCustomField;

  return { viralLoopInviteeReward: values };
};
