import React from 'react';
import { Grid, Header, Table } from 'semantic-ui-react';
import _ from 'lodash';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';

export const ChallengeProduct = ({ challengeCommerce }) => {
  return (
    <React.Fragment>
      <Header>챌린지 상품 내역</Header>
      <Grid columns={2}>
        <Grid.Column>
          <Table basic="very" size="small" celled textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>상품옵션 텍스트</Table.HeaderCell>
                <Table.HeaderCell>상품 선택방식</Table.HeaderCell>
                <Table.HeaderCell>옵션 필수선택 여부</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{challengeCommerce.productHeader}</Table.Cell>
                <Table.Cell>{challengeCommerce.selectType}</Table.Cell>
                <Table.Cell>
                  {challengeCommerce.isMandatory ? '필수' : '선택사항'}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>

        <Grid.Column>
          <Table basic="very" size="small" celled textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>rccp_id</Table.HeaderCell>
                <Table.HeaderCell>상품id</Table.HeaderCell>
                <Table.HeaderCell>상품명</Table.HeaderCell>
                <Table.HeaderCell>상품가격</Table.HeaderCell>
                <Table.HeaderCell>상품배송비</Table.HeaderCell>
                <Table.HeaderCell>상품수수료</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(challengeCommerce.rccps, (rccp) => (
                <Table.Row key={rccp.id}>
                  <Table.Cell>{rccp.id}</Table.Cell>
                  <Table.Cell>{rccp.productId}</Table.Cell>
                  <Table.Cell>{rccp.name}</Table.Cell>
                  <Table.Cell>{setCurrencyFormat(rccp.price)}</Table.Cell>
                  <Table.Cell>
                    {setCurrencyFormat(rccp.shippingPrice)}
                  </Table.Cell>
                  <Table.Cell>{setCurrencyFormat(rccp.fee)}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};

export default ChallengeProduct;
