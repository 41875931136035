import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import React from 'react';

export const ImageWrapper = styled.div`
  position: relative;
`;

export const ImageRemoveIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: ${(props) => (props.top ? props.top : 0)};
  bottom: ${(props) => props.bottom && props.bottom};
  left: ${(props) => (props.left ? props.left : 0)};
  right: ${(props) => props.right && props.right};
  padding: ${(props) => (props.padding ? props.padding : '4px')};
  width: ${(props) => (props.size ? props.size : '28px')};
  height: ${(props) => (props.size ? props.size : '28px')};
  background-color: white;
  border: 1px solid gray;
  border-radius: 4px;
  cursor: pointer;
`;

export const ImageRemoveIcon = (props) => {
  return (
    <ImageRemoveIconWrapper {...props}>
      <Icon name="delete" style={{ margin: 0 }} />
    </ImageRemoveIconWrapper>
  );
};
