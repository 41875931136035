import React, { Component } from 'react';
import { Button, Container, Grid, Header, Loader } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import DateSelect from '../DateSelect';
import DailyStatistics from '../DailyStatistics';
import AWS from 'aws-sdk';
import { awsConfig } from '../../../config';

AWS.config.update({
  region: awsConfig.bucketRegion,
  credentials: new AWS.Credentials({
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
  }),
  dynamoDbCrc32: false,
});
const ddb = new AWS.DynamoDB({
  apiVersion: '2012-08-10',
});

class Daily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dailyStatistics: [
        {
          achievementFiles: 0,
          challengeCancelCount: 0,
          challengeCancelDeposit: 0,
          challengeInterestCount: 0,
          challengeRegisterCount: 0,
          challengeRegisterDeposit: 0,
          commentCount: 0,
          date: moment(),
          followCount: 0,
          likeCount: 0,
          userCount: 0,
          challengeRegisterUserCount: 0,
          netChallengeRegisterUserCount: 0,
          challengeCancelUserCount: 0,
          netChallengeCancelUserCount: 0,
          resultChallengeCount: 0,
          resultUserCount: 0,
          failUserCount: 0,
          successCount: 0,
          hundredCount: 0,
          nonReturnDeposit: 0,
          totalDeposit: 0,
          margin: 0,
          extraMargin: 0,
          deletedUserCount: 0,
          cardWithdrawUserCount: 0,
          transferWithdrawUserCount: 0,
          cardWithdrawAmount: 0,
          transferWithdrawAmount: 0,
          specialPrize: 0,
        },
      ],
    };
    this.date = {
      filterStartDatetime: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      filterEndDatetime: moment().format('YYYY-MM-DD'),
    };
  }

  async componentDidMount() {
    const { filterStartDatetime, filterEndDatetime } = this.date;
    const nowDate = moment().format('YYYY-MM-DD');
    const diff = moment(filterEndDatetime).diff(filterStartDatetime, 'd') + 1;
    const dateRange = _.map(_.range(diff), (i) => {
      const date = moment(filterStartDatetime).add(i, 'd').format('YYYY-MM-DD');
      return date < nowDate
        ? date + 'T24:00:00'
        : moment().minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss');
    });
    console.log(dateRange);
    const promises = this.getDailyStatisticsDdb(dateRange);
    const dailyStatistics = _.compact(_.map(await Promise.all(promises)));
    this.setState({ dailyStatistics, isMounted: true });
  }

  getDailyStatisticsDdb = (dateRange) => {
    return _.map(
      dateRange,
      (date) =>
        new Promise((resolve, reject) => {
          let time = moment(date).format('HH:mm:ss');
          time = time === '00:00:00' ? '24:00:00' : time;
          ddb.getItem(
            {
              TableName: 'Statistics',
              Key: { datetime: { S: date }, time: { S: time } },
            },
            (err, data) => {
              if (err) reject(err);
              else resolve(data.Item);
            },
          );
        }),
    );
  };

  render() {
    const { isMounted, dailyStatistics } = this.state;
    return (
      <Container>
        <Grid>
          <Grid.Column width={3}>
            <Header as="h3">검색 조건</Header>
            <DateSelect handleDate={this.handleDate} />
            <Button onClick={this.getDailyStatisticsDdb} style={styles.button}>
              조회
            </Button>
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={12}>
            {!isMounted && <Loader active inline="centered" />}
            {isMounted && (
              <Grid>
                <Grid.Row>
                  <Header as="h3">일자별</Header>
                  <DailyStatistics dailyStatistics={dailyStatistics} />
                </Grid.Row>
              </Grid>
            )}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default Daily;

const styles = {
  button: {
    marginTop: 5,
  },
};
