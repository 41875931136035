import { LabelMiniInfo } from '@types';

export const getSelectedLabelsFromId = (
  labels: LabelMiniInfo[],
  selectedLabelIds: number[],
) => {
  return selectedLabelIds
    .map((labelId) => labels.find(({ id }) => id === labelId))
    .filter((label) => Boolean(label)) as LabelMiniInfo[];
};

export const getOptionsFromLabels = (
  labels: LabelMiniInfo[],
  keyword: string,
) => {
  return labels
    .filter(({ name, id }) =>
      keyword ? String(id).includes(keyword) || name.includes(keyword) : true,
    )
    .map(({ id, name }, index) => ({
      key: `${id}-${index}`,
      text: `[${id}]${name}`,
      value: id,
    }));
};
