import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'originalPrice'>,
  Pick<ReqCreateOrUpdateChallenge, 'originalPrice'>
> = ({ originalPrice }) => {
  if (originalPrice !== null && typeof originalPrice !== 'number')
    throw Error("[OriginalPriceForm] this shouldn't be happened");

  return { originalPrice };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'originalPrice'>,
  Pick<ChallengeFormType, 'originalPrice'>
> = ({ originalPrice }) => ({ originalPrice });
