import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useMaxRegisterDepositStore = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      maxRegisterDeposit: s.challenge.maxRegisterDeposit,
      minRegisterDeposit: s.challenge.minRegisterDeposit,
      isRegisterDepositFixed: s.challenge.isRegisterDepositFixed,
    })),
  );
};

export const useMaxRegisterDepositActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setMaxRegisterDeposit = (maxRegisterDeposit: number) =>
    updateChallengeForm({ maxRegisterDeposit });

  return { setMaxRegisterDeposit };
};
