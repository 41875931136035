import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'userId'>;

export const validate: FormValidator<Params> = ({ userId }) => {
  if (userId === 0) {
    return { isValid: false, message: '유저 ID는 1 이상이어야 합니다.' };
  }

  return { isValid: true };
};
