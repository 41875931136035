import React from 'react';
import { Table, Header, Accordion } from 'semantic-ui-react';
import moment from 'moment';
import setCurrencyFormat from '../../global/setCurrencyFormat';
import { Icon } from 'semantic-ui-react';

export const RetryChallengeInfo = ({ retries }) => {
  const [toggleOpen, setToggleOpen] = React.useState(false);
  return (
    <Accordion>
      <Accordion.Title
        active={toggleOpen}
        onClick={() => setToggleOpen(!toggleOpen)}
      >
        <Header as="h5">
          <Icon name="dropdown" />
          챌린지 재도전 내역
        </Header>
      </Accordion.Title>
      <Accordion.Content active={toggleOpen}>
        <Table basic={'very'}>
          <Table.Header>
            <Table.Row>
              {[
                '기존신청id',
                '기존신청 챌린지id',
                '기존신청 챌린지제목',
                '기존신청 챌린지기간',
                '기존신청 달성률',
                '타겟챌린지id',
                '타겟챌린지제목',
                '타겟챌린지기간',
                '타겟상금',
                '타겟챌린지달성률',
                '타겟챌린지결과',
              ].map((title) => (
                <Table.HeaderCell key={title}>{title}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {retries.map((retry) => {
              return (
                <Table.Row key={retry.oldChallenge.id}>
                  <Table.Cell>{retry.oldRelationUserChallengeId}</Table.Cell>
                  <Table.Cell>{retry.oldChallenge.id}</Table.Cell>
                  <Table.Cell>{retry.oldChallenge.title}</Table.Cell>
                  <Table.Cell>
                    {moment(retry.oldChallenge.startDate).format('YYMMDD')}-
                    {moment(retry.oldChallenge.endDate).format('YYMMDD')}
                  </Table.Cell>
                  <Table.Cell>{retry.oldChallenge.achievementRate}</Table.Cell>
                  <Table.Cell>{retry.targetChallenge.id}</Table.Cell>
                  <Table.Cell>{retry.targetChallenge.title}</Table.Cell>
                  <Table.Cell>
                    {retry.targetChallenge.title
                      ? `${moment(retry.targetChallenge.startDate).format(
                          'YYMMDD',
                        )}-${moment(retry.targetChallenge.endDate).format(
                          'YYMMDD',
                        )}`
                      : ''}
                  </Table.Cell>
                  <Table.Cell>
                    {setCurrencyFormat(retry.targetRewardPrize)}
                  </Table.Cell>
                  <Table.Cell>
                    {retry.targetChallenge.achievementRate}
                  </Table.Cell>
                  <Table.Cell>{retry.targetChallenge.result}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Accordion.Content>
    </Accordion>
  );
};

export default RetryChallengeInfo;
