import { Button, Modal } from 'semantic-ui-react';

const ItemModal = ({ modalOpen, useItem, close }: ItemModalProps) => {
  return (
    <Modal size="tiny" open={modalOpen} onClose={close}>
      <Modal.Header>인증패스 사용</Modal.Header>
      <Modal.Content>인증패스 처리를 진행하시겠습니까?</Modal.Content>
      <Modal.Actions>
        <Button color="blue" content="인증패스 처리" onClick={useItem} />
        <Button color="black" content="닫기" onClick={close} />
      </Modal.Actions>
    </Modal>
  );
};

export default ItemModal;

type ItemModalProps = {
  modalOpen: boolean;
  useItem: () => void;
  close: () => void;
};
