import { addDays } from 'date-fns';
import { PreAlramForm } from './PreAlarmForm.types';
import { formatTimeExcludeSeconds } from '@utils/date.utils';

export const DEFAULT_PRE_ALARM: PreAlramForm = {
  title: '',
  brand: '',
  maxRegisterCount: 99999,
  thumbnailImageUrl: '',
  descriptionImageUrlList: [],
  userFilterId: null,
  additionalInfo: {
    category: undefined,
    purchaseChannel: undefined,
    purchaseStartDate: formatTimeExcludeSeconds(addDays(new Date(), 1)),
    purchaseEndDate: formatTimeExcludeSeconds(addDays(new Date(), 1)),
    originalPrice: 0,
    price: 0,
    paybackAmount: 0,
    button: {
      name: '',
      url: '',
    },
  },
};
