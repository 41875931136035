import Accordion from '@component/Accordion/Accordion';
import Button from '@component/Button';
import ErrorMsg from '@component/ErrorMsg';
import FlexBox from '@component/FlexBox/FlexBox';
import { ChallengeCustomField } from '@types';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './PurchaseInfoForm.serializer';

import {
  usePurchaseInfo,
  useUpdateBrandInfoActions,
  useUpdatePurchaseInfoListActions,
  useUpdatePurchaseInfoSetting,
  useValidationData,
} from './PurchaseInfoForm.store';
import { validate } from './PurchaseInfoForm.validator';
import PurchaseInfo from './components/PurchaseInfo';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import { useEffect, useState } from 'react';

type Props = {
  open: boolean;
};

const PurchaseInfoForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'purchaseInfo'>,
  Extract<ChallengeCustomField, { name: 'purchaseInfo' }>,
  Props
> = ({ open }) => {
  const purchaseInfo = usePurchaseInfo();
  const [active, setActive] = useState(!!purchaseInfo);

  const { init, reset } = useUpdatePurchaseInfoSetting();
  const { add, update, remove, updateChallengeForm } =
    useUpdatePurchaseInfoListActions();
  const { update: updateBrand } = useUpdateBrandInfoActions();
  const validationData = useValidationData();

  const validated = validate({ ...validationData });

  const selectedPlatformOption = PLATFORM_OPTIONS.find(
    (option) => purchaseInfo?.platform === option.value,
  );

  const selectedRequiredOption = REQUIRED_OPTIONS.find(
    (option) => purchaseInfo?.connectionRequired === option.value,
  );

  const handleActiveChange = (option: { label: string; value: boolean }) => {
    if (option.value === false) {
      reset();
    } else {
      init();
    }
    setActive(option.value);
  };

  const handlePlatformChange = (option: { label: string; value: string }) => {
    if (!purchaseInfo) return;
    updateBrand({
      ...purchaseInfo,
      platform: option.value,
      platformName: option.label,
    });
  };

  const handleRequiredChange = (option: { label: string; value: boolean }) => {
    if (!purchaseInfo) return;
    updateChallengeForm({
      ...purchaseInfo,
      purchaseInfo: {
        ...purchaseInfo,
        connectionRequired: option.value,
      },
    });
  };

  useEffect(() => {
    if (purchaseInfo) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [purchaseInfo]);

  return (
    <Accordion
      title="구매 챌린지 인증 관련 정보 (플랫폼 설정 및 회차 지정)"
      open={open}
      as="h5"
    >
      <FlexBox.Column gap={6} style={{ paddingLeft: 20 }}>
        <b>[올리브영][스마트스토어][쿠팡][카카오][무신사] 등 -</b>
        <b
          style={{
            color: 'blue',
          }}
        >
          활성화하면 해당 플랫폼 연동이 필수가 됩니다.
        </b>
        <br />
        <b>사용 여부</b>
        <RadioSelector
          selectedOption={ACITVE_OPTIONS.find(
            (option) => option.value === active,
          )}
          options={ACITVE_OPTIONS}
          onChange={handleActiveChange}
          style={{ display: 'flex', flexDirection: 'row', gap: 8 }}
        />
        {purchaseInfo && (
          <>
            <br />
            <b>플랫폼</b>
            <RadioSelector
              selectedOption={selectedPlatformOption}
              options={PLATFORM_OPTIONS}
              onChange={handlePlatformChange}
              style={{ display: 'flex', flexDirection: 'row', gap: 8 }}
            />
            <br />
            <b>계정 연동 필수 여부</b>
            <RadioSelector
              selectedOption={selectedRequiredOption}
              options={REQUIRED_OPTIONS}
              onChange={handleRequiredChange}
              style={{ display: 'flex', flexDirection: 'row', gap: 8 }}
            />
            <br />
            <ErrorMsg text={validated.isValid ? '' : validated.message} />
            {purchaseInfo.items.map((info, index) => (
              <PurchaseInfo
                key={index}
                info={info}
                index={index}
                onRemove={remove}
                onUpdate={update}
              />
            ))}
            <Button text="회차 추가" onClick={add} />
          </>
        )}
      </FlexBox.Column>
    </Accordion>
  );
};

const ACITVE_OPTIONS = [
  { label: '활성화', value: true },
  { label: '비활성화', value: false },
];

const PLATFORM_OPTIONS = [
  { label: '올리브영', value: 'oliveYoung' },
  { label: '네이버', value: 'naver' },
];

const REQUIRED_OPTIONS = [
  { label: '필수', value: true },
  { label: '필수아님', value: false },
];

export default PurchaseInfoForm;
PurchaseInfoForm.validate = validate;
PurchaseInfoForm.convertData2Form = convertData2Form;
PurchaseInfoForm.convertForm2Data = convertForm2Data;
