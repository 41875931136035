import { useCallback, useState } from 'react';
import { Button, Image, Modal, Table } from 'semantic-ui-react';
import { colors } from '../../../../../constant/colors';
import { imageFilters as defaultImageFilters } from '../../../../../constant/race';
import * as S from '../../../Style';
import CustomFilterImage from './CustomFilterImage';
import Preview from './Preview/Preview';
import {
  RaceImageFilter,
  RaceImageFilterLogoStamp,
} from 'src/types/race.types';
import _ from 'lodash';

type FilterFieldProps = {
  imageFilters: RaceImageFilter[];
  setImageFilters: React.Dispatch<React.SetStateAction<RaceImageFilter[]>>;
};

const FilterField = ({ imageFilters, setImageFilters }: FilterFieldProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const showModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const addLeagueImageFilter = (idx: number, isCustom: boolean) => {
    const _imageFilters = _.cloneDeep(imageFilters);
    const _filters = _.cloneDeep(defaultImageFilters);

    const filterIds = _imageFilters?.map((f) => f.id) ?? [];

    // 커스텀 필터의 경우 여러개 추가할 수 있음
    if (filterIds.includes(_filters[idx].id) && !isCustom) {
      alert('이미 추가되어있는 필터에요');
      return;
    }

    const maxIndex = Math.max(...filterIds);

    if (isCustom) {
      // 처음 추가하는 커스텀 필터일때
      if (_imageFilters.length === 0) {
        _imageFilters.unshift({
          ..._filters[idx],
          id: maxIndex + 1,
        });
      } else {
        // 처음 추가하는 커스텀 필터가 아닐때 (imageFilter 중 첫번째 값의 stamp 값으로 대체)
        _imageFilters.unshift({
          ..._filters[idx],
          stamps: _imageFilters[0].stamps,
          id: maxIndex + 1,
        });
      }
    } else {
      // 커스텀 필터가 아닌 경우
      _imageFilters.push(_filters[idx]);
    }

    _.orderBy(_imageFilters, 'id');

    setImageFilters(_imageFilters);
    closeModal();
  };

  const deleteLeagueImageFilter = (filterIdx: number) => {
    const _imageFilters = _.cloneDeep(imageFilters);
    _imageFilters.splice(filterIdx, 1);
    setImageFilters(_imageFilters);
  };

  const handleOrderChangeImageFilter = (
    filterIdx: number,
    direction: string,
  ) => {
    const _imageFilters = _.cloneDeep(imageFilters);

    const removed = _imageFilters.splice(filterIdx, 1);
    if (direction === 'up') {
      _imageFilters.splice(filterIdx - 1, 0, removed[0]);
    } else {
      _imageFilters.splice(filterIdx + 1, 0, removed[0]);
    }

    setImageFilters(_imageFilters);
  };

  const setDefaultImageFilterForAllLeagues = () => {
    if (
      window.confirm(
        '모든 리그의 이미지 필터를 기본 필터 세트로 설정하시겠습니까?',
      )
    ) {
      const _defaultImageFilters = _.cloneDeep(defaultImageFilters);
      setImageFilters(_defaultImageFilters);
    }
  };

  return (
    <>
      <h3>인증샷 카메라 필터 정보</h3>
      <Preview />
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <div>
                <S.ButtonInForm
                  onClick={setDefaultImageFilterForAllLeagues}
                  content={'기본 세팅'}
                />
                <S.ButtonInForm onClick={showModal} content={'필터 추가'} />
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row style={{ display: 'flex', flexWrap: 'wrap' }}>
            {imageFilters?.map((filter, i) => {
              const logoStamp = filter.stamps.find(
                ({ type }) => type === 'LOGO_STAMP',
              ) as RaceImageFilterLogoStamp | undefined;

              return (
                <Table.Cell key={`${filter.id}-${i}`}>
                  <div
                    style={{
                      width: 'fit-content',
                      border: `1px solid ${colors.GRAY_500}`,
                      borderRadius: 4,
                      marginTop: 10,
                      padding: 10,
                    }}
                  >
                    <strong
                      style={{
                        marginRight: 10,
                      }}
                    >
                      {i + 1}번
                    </strong>
                    <S.ButtonInForm
                      content={'삭제'}
                      onClick={() => deleteLeagueImageFilter(i)}
                    />
                    <S.ButtonInForm
                      icon={'angle up'}
                      onClick={() => handleOrderChangeImageFilter(i, 'up')}
                    />
                    <S.ButtonInForm
                      icon={'angle down'}
                      onClick={() => handleOrderChangeImageFilter(i, 'down')}
                    />
                    {filter.isCustom ? (
                      // 커스텀 필터
                      <CustomFilterImage
                        filter={filter}
                        imageFilters={imageFilters}
                        setImageFilters={setImageFilters}
                        filterIndex={i}
                      />
                    ) : filter.imageUrl ? (
                      <>
                        {/* 일반 필터 */}
                        <div style={{ marginBottom: 10 }}>
                          <strong>썸네일 이미지</strong>
                          <Image
                            style={{ width: 50, height: 50 }}
                            src={filter.thumbnailImageUrl}
                          />
                        </div>

                        <div>
                          <strong>필터 이미지</strong>
                          <div
                            style={{
                              backgroundColor: colors.GRAY_200,
                              border: `1px solid ${colors.GRAY_500}`,
                              width: 300,
                              height: 300,
                              position: 'relative',
                            }}
                          >
                            <Image
                              style={{ width: 300, height: 300 }}
                              src={filter.imageUrl}
                            />
                            {logoStamp && (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  padding: 50,
                                }}
                              >
                                <Image
                                  style={{
                                    width: logoStamp.width,
                                    height: logoStamp.height,
                                  }}
                                  src={logoStamp.imageUrl}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* 기본 필터 */}
                        <div
                          style={{
                            backgroundColor: colors.GRAY_200,
                            border: `1px solid ${colors.GRAY_500}`,
                            width: 375,
                            height: 375,
                            position: 'relative',
                          }}
                        >
                          {logoStamp && (
                            <div
                              style={{
                                position: 'absolute',
                                top: 20,
                                left: 20,
                                padding: 5,
                              }}
                            >
                              <Image
                                style={{
                                  width: logoStamp.width,
                                  height: logoStamp.height,
                                }}
                                src={logoStamp.imageUrl}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </Table.Cell>
              );
            })}
          </Table.Row>
        </Table.Body>
      </Table>

      <Modal size="large" open={modalOpen} onClose={closeModal}>
        <Modal.Header>필터 추가</Modal.Header>
        <Modal.Content>
          <div
            style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
          >
            {defaultImageFilters.map((filter, idx) => (
              <div
                key={filter.id}
                style={{ margin: 4 }}
                onClick={() => addLeagueImageFilter(idx, filter.isCustom)}
                onKeyDown={() => addLeagueImageFilter(idx, filter.isCustom)}
              >
                {filter.isCustom ? (
                  <div
                    style={{
                      border: '2px solid blue',
                      width: 150,
                      height: 150,
                      margin: 4,
                      padding: 4,
                    }}
                  >
                    <h4
                      style={{
                        color: 'blue',
                      }}
                    >
                      커스텀 필터 🎨
                    </h4>
                  </div>
                ) : filter.id === 0 ? (
                  <div
                    style={{
                      border: '1px solid black',
                      width: 150,
                      height: 150,
                      margin: 4,
                    }}
                  >
                    (기본 필터)
                  </div>
                ) : (
                  <Image
                    size="medium"
                    style={{
                      width: 150,
                      height: 150,
                      border: '1px solid black',
                      backgroundColor: colors.GRAY_500,
                    }}
                    src={filter.imageUrl}
                  />
                )}
              </div>
            ))}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" content="닫기" onClick={closeModal} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default FilterField;
