import { apis } from '@api/index';
import FlexBox from '@component/FlexBox/FlexBox';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import {
  ChallengeAchievementSetting,
  ReqCreateOrUpdateChallenge,
} from '@types';
import { useHistory } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import GoalDetailInfo from '../GoalDetailInfo/GoalDetailInfo';
import NewChallengeForm from '../NewChallengeForm/NewChallengeForm';

const ChallengeCreate = () => {
  const history = useHistory();

  const submit = async (
    challenge: ReqCreateOrUpdateChallenge,
    achievementSetting: ChallengeAchievementSetting,
  ) => {
    LoadingIndicator.show();

    try {
      const res = await apis.challenge.createChallenge({
        challenge,
        achievementSetting,
      });
      if (!res.challenge) alert('챌린지 생성 오류!');
      history.push(`/challenges/${res.challenge.id}`);
    } catch (e) {
      console.log(e);
    } finally {
      LoadingIndicator.hide();
    }
  };

  return (
    <FlexBox.Row style={{ padding: 40 }}>
      <FlexBox.Column>
        <Header as="h3" textAlign="center">
          챌린지 추가
        </Header>
        <NewChallengeForm onSubmit={submit} />
      </FlexBox.Column>
      <GoalDetailInfo />
    </FlexBox.Row>
  );
};

export default ChallengeCreate;
