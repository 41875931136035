import ChipInput from '@component/ChipInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './CollaboAdminUserIdsForm.serializer';
import {
  useCollaboAdminUserIds,
  useCollaboAdminUserIdsActions,
} from './CollaboAdminUserIdsForm.store';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import Label from '@component/Label';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apis } from '@api/index';
import {
  withTypedInfiniteLoad,
  flatMapInfiniteData,
  getNextPageParam,
} from '@utils/query.utils';
import { parseIdFromTitle } from './CollaboAdminUserIdsForm.utils';

const CollaboAdminUserIdsForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'collaboAdminUserIds'>,
  Pick<ChallengeFormType, 'collaboAdminUserIds'>,
  Pick<ReqCreateOrUpdateChallenge, 'collaboAdminUserIds'>
> = () => {
  const [initialized, setInitialized] = useState(false);
  const { collaboAdminUserIds } = useCollaboAdminUserIds();
  const { setCollaboAdminUserIds } = useCollaboAdminUserIdsActions();

  const [keyword, setKeyword] = useState('');
  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: ['GET_COLLABO_ADMIN_USER_LIST', keyword],
    queryFn: withTypedInfiniteLoad(
      apis.searchCollaboAdminUserList,
      { keyword },
      50,
    ),
    initialPageParam: 0,
    getNextPageParam,
  });
  const adminUserList = flatMapInfiniteData(data, 'collaboAdminList', 'id');

  const [adminUserStringList, setAdminUserStringList] = useState<string[]>([]);

  const handleChangeKeyword = useCallback(_.throttle(setKeyword, 500), []);

  const handleUserListChange = (userList: string[]) => {
    setAdminUserStringList(userList);
    setCollaboAdminUserIds(
      userList.map((title) => parseIdFromTitle(title)) as number[],
    );
  };

  const handleReachEnd = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (initialized) return;
    init();
  }, [collaboAdminUserIds]);

  const init = async () => {
    if (!collaboAdminUserIds.length) return;
    const res = await apis.getCollaboAdminUserListByIds({
      id: collaboAdminUserIds,
    });
    setAdminUserStringList(
      res.collaboAdminList.map(({ id, email, name }) =>
        chipTitleGenerator(id, email, name),
      ),
    );

    setInitialized(true);
  };

  return (
    <>
      <Label>제휴 어드민 유저 선택</Label>

      <ChipInput
        placeholder="챌린지를 추가할 제휴 어드민 유저를 선택하세요"
        chipList={adminUserStringList}
        searchable
        searchDataList={adminUserList
          .filter(({ id }) => !collaboAdminUserIds.includes(id))
          .map(({ id, email, name }) => chipTitleGenerator(id, email, name))}
        searchDataAddable={false}
        onChange={handleUserListChange}
        onSearchChange={handleChangeKeyword}
        onReachEnd={handleReachEnd}
        contentStyle={{ width: '100%' }}
        isLoading={isFetching}
      />
    </>
  );
};

export default CollaboAdminUserIdsForm;

CollaboAdminUserIdsForm.validate = () => {
  return {
    isValid: true,
    message: '',
  };
};
CollaboAdminUserIdsForm.convertData2Form = convertData2Form;
CollaboAdminUserIdsForm.convertForm2Data = convertForm2Data;

const chipTitleGenerator = (id: number, email: string, name: string) => {
  return `[${id}] ${email} / ${name}`;
};
