import React, { useCallback, useState } from 'react';
import {
  Accordion,
  Button,
  Dimmer,
  Divider,
  Form,
  Header,
  Icon,
  Loader,
  Modal,
  Table,
} from 'semantic-ui-react';
import moment from 'moment';
// @ts-ignore
import { Workbook } from 'react-excel-workbook';
import readXlsxFile from 'read-excel-file';
import ValidationRequestModal from '../../../../ValidationRequestModal';
import { apis } from '../../../../../api';
import { raceTypeOptions } from '../../../../../constant/race';
import { Race } from '@types';
import { flatRaceProductGroup } from '@utils/race';

const RaceTransactionTable = ({
  race,
  productPackages,
  transactions,
  getRaceTransactions,
}: {
  race: Race.RaceAllInfoForAdmin;
  productPackages: Race.ReqCreateOrUpdateProductPackage[];
  transactions: Race.TransactionRaceForAdmin[];
  getRaceTransactions: () => void;
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trackingList, setTrackingList] = useState<
    {
      orderNo: string;
      productId: number;
      logisticsCompanyName: string;
      trackingNo: string;
    }[]
  >([]);

  const handleClick = useCallback((curr: boolean) => {
    setCollapsed(!curr);
  }, []);

  const showModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const showUploadModal = useCallback(() => {
    setUploadModalOpen(true);
  }, []);

  const closeUploadModal = useCallback(() => {
    setUploadModalOpen(false);
  }, []);

  const _setValidated = useCallback((_validated: boolean) => {
    setValidated(_validated);
    closeModal();
  }, []);

  const readExcelFile = async (e: any) => {
    if (isLoading) return;
    setIsLoading(true);

    const data = e.target.files[0];
    const rows = await readXlsxFile(data);

    const _trackingList = rows
      .slice(1)
      .filter((row) => !!row[3])
      .map((row) => ({
        orderNo: String(row[4]),
        productId: Number(row[5]),
        logisticsCompanyName: row[2] as string,
        trackingNo: String(row[3]),
      }));

    setTrackingList(_trackingList);
    setIsLoading(false);
  };

  const updateTrackingNo = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const res = await apis.updateRaceTracking(race.id, { trackingList });
    if ((res as any)?.response?.data && 'err' in (res as any)?.response?.data) {
      return;
    }

    getRaceTransactions();
    setIsLoading(false);
    closeUploadModal();

    alert('운송장 번호 업데이트가 완료되었어요');
  };

  const filtered = transactions.filter((t) => !!t.productInfo.userAddress);

  const getProductName = (transaction: Race.TransactionRaceForAdmin) => {
    const mainSponsor = race.sponsors.find((s) => s.type === 'main');

    const option = productPackages.find(
      (o) =>
        o.products[0].options?.values?.find(
          (p) => p.id === transaction.productId,
        ),
    );

    const product = flatRaceProductGroup(option?.products[0]).find(
      (p) => p.id === transaction.productInfo.productPackageId,
    );

    const raceTypeName = raceTypeOptions?.find((o) => o.value === race.raceType)
      ?.text;

    return `${race.seasonTerm}_${mainSponsor?.name}_${raceTypeName}${
      product ? `_${product.name}_${product.subName}` : ''
    }_${option?.name || '참가권'}`;
  };

  return (
    <>
      <Accordion>
        <Accordion.Title
          active={!collapsed}
          onClick={() => handleClick(collapsed)}
        >
          <Header as={'h3'}>
            <Icon name="dropdown" /> 주문 목록
          </Header>
        </Accordion.Title>
        <Accordion.Content active={!collapsed}>
          {validated ? (
            <Workbook
              filename={`${moment().format('YYYYMMDD')}_${
                race.title
              }_주문목록.xlsx`}
              element={
                <Button basic color="green" floated="left" size="mini">
                  운송장목록 다운로드
                </Button>
              }
            >
              <Workbook.Sheet data={filtered} name="주문 목록">
                <Workbook.Column
                  label="주문id"
                  value={(t: Race.TransactionRaceForAdmin) => t.id}
                />
                <Workbook.Column
                  label="주문일자"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    moment(t.transactionDate).format('YYYY.MM.DD HH:mm:ss')
                  }
                />
                <Workbook.Column
                  label="택배사"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.logisticsCompanyName ?? ''
                  }
                />
                <Workbook.Column
                  label="운송장번호"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.trackingNo ?? ''
                  }
                />
                <Workbook.Column
                  label="주문번호"
                  value={(t: Race.TransactionRaceForAdmin) => t.orderNo}
                />
                <Workbook.Column
                  label="상품id"
                  value={(t: Race.TransactionRaceForAdmin) => t.productId}
                />
                <Workbook.Column label="상품명" value={getProductName} />
                <Workbook.Column
                  label="이름"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.productInfo.userAddress?.name
                  }
                />
                <Workbook.Column
                  label="핸드폰"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.productInfo.userAddress?.phone
                  }
                />
                <Workbook.Column
                  label="핸드폰(검증용)"
                  value={(t: Race.TransactionRaceForAdmin) => t.userPhone}
                />
                <Workbook.Column
                  label="우편번호"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.productInfo.userAddress?.zipCode
                  }
                />
                <Workbook.Column
                  label="기본주소"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.productInfo.userAddress?.address1
                  }
                />
                <Workbook.Column
                  label="상세주소"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.productInfo.userAddress?.address2
                  }
                />
                <Workbook.Column
                  label="요청사항"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.productInfo.userAddress?.request
                  }
                />
                <Workbook.Column
                  label="현관문 출입번호"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.productInfo.userAddress?.entranceCode
                  }
                />
              </Workbook.Sheet>
            </Workbook>
          ) : (
            <Button
              basic
              color="green"
              floated="left"
              size="mini"
              onClick={showModal}
            >
              운송장목록 엑셀 요청하기
            </Button>
          )}

          {validated ? (
            <Workbook
              filename={`${moment().format('YYYYMMDD')}_${
                race.title
              }_전체주문내역.xlsx`}
              element={
                <Button basic color="green" floated="left" size="mini">
                  전체 주문내역 다운로드
                </Button>
              }
            >
              <Workbook.Sheet data={transactions} name="주문 목록">
                <Workbook.Column
                  label="주문id"
                  value={(t: Race.TransactionRaceForAdmin) => t.id}
                />
                <Workbook.Column
                  label="주문일자"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    moment(t.transactionDate).format('YYYY.MM.DD HH:mm:ss')
                  }
                />
                <Workbook.Column
                  label="택배사"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.logisticsCompanyName ?? ''
                  }
                />
                <Workbook.Column
                  label="운송장번호"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.trackingNo ?? ''
                  }
                />
                <Workbook.Column
                  label="주문번호"
                  value={(t: Race.TransactionRaceForAdmin) => t.orderNo}
                />
                <Workbook.Column
                  label="상품id"
                  value={(t: Race.TransactionRaceForAdmin) => t.productId}
                />
                <Workbook.Column label="상품명" value={getProductName} />
                <Workbook.Column
                  label="유저id"
                  value={(t: Race.TransactionRaceForAdmin) => t.userId}
                />
                <Workbook.Column
                  label="이름"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.productInfo?.userAddress?.name ?? ''
                  }
                />
                <Workbook.Column
                  label="핸드폰"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.productInfo?.userAddress?.phone ?? ''
                  }
                />
                <Workbook.Column
                  label="핸드폰(검증용)"
                  value={(t: Race.TransactionRaceForAdmin) => t.userPhone}
                />
                <Workbook.Column
                  label="우편번호"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.productInfo?.userAddress?.zipCode ?? ''
                  }
                />
                <Workbook.Column
                  label="기본주소"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.productInfo?.userAddress?.address1 ?? ''
                  }
                />
                <Workbook.Column
                  label="상세주소"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.productInfo?.userAddress?.address2 ?? ''
                  }
                />
                <Workbook.Column
                  label="요청사항"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.productInfo?.userAddress?.request ?? ''
                  }
                />
                <Workbook.Column
                  label="현관문 출입번호"
                  value={(t: Race.TransactionRaceForAdmin) =>
                    t.productInfo?.userAddress?.entranceCode ?? ''
                  }
                />
              </Workbook.Sheet>
            </Workbook>
          ) : (
            <Button
              basic
              color="green"
              floated="left"
              size="mini"
              onClick={showModal}
            >
              전체 주문내역 엑셀 요청하기
            </Button>
          )}

          <Button floated="left" size="mini" onClick={showUploadModal}>
            운송장번호 업데이트
          </Button>

          <Divider hidden />

          <Table basic="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>주문id</Table.HeaderCell>
                <Table.HeaderCell>주문번호</Table.HeaderCell>
                <Table.HeaderCell>거래일</Table.HeaderCell>
                <Table.HeaderCell>결제수단</Table.HeaderCell>
                <Table.HeaderCell>총금액</Table.HeaderCell>
                <Table.HeaderCell>포인트사용금액</Table.HeaderCell>
                <Table.HeaderCell>주문상품명</Table.HeaderCell>
                <Table.HeaderCell>유저id</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {transactions.map((transaction) => (
                <Table.Row key={transaction.id}>
                  <Table.Cell>{transaction.id}</Table.Cell>
                  <Table.Cell>{transaction.orderNo}</Table.Cell>
                  <Table.Cell>
                    {moment(transaction.transactionDate).format(
                      'YY.MM.DD HH:mm:ss',
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {transaction.pgcode} {transaction.payInfo}
                  </Table.Cell>
                  <Table.Cell>{transaction.amount}</Table.Cell>
                  <Table.Cell>{transaction.pointAmount}</Table.Cell>
                  <Table.Cell>{transaction.productName}</Table.Cell>
                  <Table.Cell>{transaction.userId}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Accordion.Content>
      </Accordion>

      <ValidationRequestModal
        validationModalOpen={modalOpen}
        closeValidationModal={closeModal}
        setValidated={_setValidated}
        location={'대회 정보'}
      />

      <Modal size="small" open={uploadModalOpen} onClose={closeUploadModal}>
        <Dimmer active={isLoading}>
          <Loader />
        </Dimmer>
        <Modal.Header>운송장 정보 업데이트</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>운송장 파일을 업로드하세요.</label>
              <input type="file" id="input" onChange={readExcelFile} />
            </Form.Field>
          </Form>
          {trackingList.length > 0 && (
            <div style={{ marginTop: 12 }}>
              <h5>총 {trackingList.length}건</h5>
              {trackingList.map((t, idx) => (
                <div key={idx}>
                  {idx + 1}) {t.orderNo} / {t.productId} /{' '}
                  {t.logisticsCompanyName} / {t.trackingNo}
                </div>
              ))}
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="업데이트" onClick={updateTrackingNo} />
          <Button color="black" content="닫기" onClick={closeUploadModal} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default RaceTransactionTable;
