import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { Col } from './Style';

const RaceThumbnailPreview = () => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Accordion style={{ width: '100%' }}>
      <Accordion.Title
        active={!collapsed}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Icon name="dropdown" />
        썸네일 미리보기
      </Accordion.Title>
      <Accordion.Content active={!collapsed}>
        <div style={{ display: 'flex' }}>
          <Col>
            <strong>결제페이지</strong>
            <img
              src="/race/썸네일-결제페이지.png"
              width={180}
              alt="썸네일-결제페이지"
            />
          </Col>
          <Col>
            <strong>결제완료페이지</strong>
            <img
              src="/race/썸네일-결제완료페이지.png"
              width={180}
              alt="썸네일-결제완료페이지"
            />
          </Col>
          <Col>
            <strong>참가취소페이지</strong>
            <img
              src="/race/썸네일-참가취소페이지.png"
              width={180}
              alt="썸네일-참가취소페이지"
            />
          </Col>
          <Col>
            <strong>대회결과페이지</strong>
            <img
              src="/race/썸네일-대회결과페이지.png"
              width={180}
              alt="썸네일-대회결과페이지"
            />
          </Col>
          <Col>
            <strong>리뷰페이지</strong>
            <img
              src="/race/썸네일-리뷰페이지.png"
              width={180}
              alt="썸네일-리뷰페이지"
            />
          </Col>
        </div>
      </Accordion.Content>
    </Accordion>
  );
};

export default RaceThumbnailPreview;
