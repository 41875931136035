export const CREATOR_FIELD_EXIST_OPTIONS: {
  label: string;
  value: boolean;
}[] = [
  { label: '리더스 챌린지 시스템 사용', value: true },
  { label: '리더스 챌린지 시스템 사용 안함', value: false },
];

export const CREATOR_TYPE_OPTIONS: {
  label: string;
  value: 'COACHING' | 'TOGETHER' | null;
}[] = [
  { label: '1:1코칭', value: 'COACHING' },
  { label: '함께하기', value: 'TOGETHER' },
  { label: '선택안함', value: null },
];
