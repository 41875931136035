import React, { useEffect, useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import moment from 'moment';
import * as S from '../../Style';
import {
  getPointAmountAdditionalText,
  isOfferWallInRace,
  isResultDateSetRequired,
} from '../../utils';

const OfferWallPoint: React.FC<OfferWallPointProps> = (props) => {
  const {
    id,
    offerWallInfo,
    onChangeValue,
    onChangeRewardValue,
    initBestParticipantInfo,
  } = props;

  const [isBestParticipantExist, setIsBestParticipantExist] = useState(false);

  const handleIsBestParticipantExist = () => {
    const changed = !isBestParticipantExist;
    // 초기화
    if (!changed) {
      initBestParticipantInfo();
    }
    setIsBestParticipantExist(changed);
  };

  const handleChangeDate = (name: string, value: string) => {
    if (moment(offerWallInfo.endDateTime).isAfter(moment(value))) {
      alert('포인트 지급일은 종료일시보다 빠를 수 없어요!');
      return;
    }
    onChangeValue({ name, value: moment(value).format('YYYY-MM-DD') });
  };

  useEffect(() => {
    if (offerWallInfo.bestParticipantCount > 0) {
      setIsBestParticipantExist(true);
    } else {
      initBestParticipantInfo();
    }
  }, [offerWallInfo.bestParticipantCount, initBestParticipantInfo]);

  return (
    <>
      <h2>포인트 지급 관련</h2>
      <label
        style={{
          color: 'blue',
        }}
      >
        *오퍼월을 생성하면 지급 포인트를 수정할 수 없습니다.*
      </label>
      <br />
      <label
        style={{
          color: 'blue',
        }}
      >
        *포인트 변경시 기존 오퍼월을 삭제하시고 새로운 오퍼월을 만들어주세요.*
      </label>
      <div style={{ height: 14 }} />
      <S.RowContainer>
        {isOfferWallInRace(offerWallInfo.type) ? (
          <S.Container style={{ flex: 1 }}>
            <S.RowContainer>
              <S.RowFormField>
                <label>참여자 상금</label>
                <Input
                  value={String(offerWallInfo.reward.prizePointAmount || 0)}
                  onChange={(_, { value }) => {
                    onChangeRewardValue({
                      name: 'prizePointAmount',
                      value: value ? Number(value) : 0,
                    });
                  }}
                />
              </S.RowFormField>
              <S.RowFormField margin="0 0 0 10px">
                <label>참여자 스토어 포인트</label>
                <Input
                  value={String(offerWallInfo.reward.storePointAmount || 0)}
                  onChange={(_, { value }) => {
                    onChangeRewardValue({
                      name: 'storePointAmount',
                      value: value ? Number(value) : 0,
                    });
                  }}
                />
              </S.RowFormField>
            </S.RowContainer>
            {offerWallInfo.type === 'RACE_INSTAGRAM_MISSION' && (
              <>
                <Form.Field
                  label="우수 참여자 선발 여부"
                  control="input"
                  type="checkbox"
                  checked={isBestParticipantExist}
                  onChange={handleIsBestParticipantExist}
                />
                {isBestParticipantExist && (
                  <S.RowContainer>
                    <S.RowFormField>
                      <label>우수 참여자 선발 인원</label>
                      <Input
                        value={
                          offerWallInfo.bestParticipantCount
                            ? String(offerWallInfo.bestParticipantCount)
                            : ''
                        }
                        onChange={(_, { value }) => {
                          onChangeValue({
                            name: 'bestParticipantCount',
                            value: value ? Number(value) : 0,
                          });
                        }}
                      />
                    </S.RowFormField>
                    <S.RowFormField margin="0 0 0 10px">
                      <label>우수 참여자 상금</label>
                      <Input
                        value={String(
                          offerWallInfo.reward.bestPrizePointAmount || 0,
                        )}
                        onChange={(_, { value }) => {
                          onChangeRewardValue({
                            name: 'bestPrizePointAmount',
                            value: value ? Number(value) : 0,
                          });
                        }}
                      />
                    </S.RowFormField>
                    <S.RowFormField margin="0 0 0 10px">
                      <label>우수 참여자 스토어 포인트</label>
                      <Input
                        value={String(
                          offerWallInfo.reward.bestStorePointAmount || 0,
                        )}
                        onChange={(_, { value }) => {
                          onChangeRewardValue({
                            name: 'bestStorePointAmount',
                            value: value ? Number(value) : 0,
                          });
                        }}
                      />
                    </S.RowFormField>
                  </S.RowContainer>
                )}
              </>
            )}
          </S.Container>
        ) : (
          <S.RowFormField>
            <label>
              지급 포인트 {getPointAmountAdditionalText(offerWallInfo.type)}
            </label>
            {!!id && (
              <>
                <label style={{ color: 'blue' }}>
                  * 오퍼월을 생성하면 지급 포인트를 수정할 수 없습니다. *
                </label>
                <label style={{ color: 'blue' }}>
                  * 포인트 변경시 기존 오퍼월을 삭제하시고 새로운 오퍼월을
                  만들어주세요. *
                </label>
              </>
            )}
            <input
              name="storePointAmount"
              value={offerWallInfo.reward.storePointAmount}
              onChange={(e) => {
                onChangeRewardValue({
                  name: 'storePointAmount',
                  value: Number(e.target.value) || 0,
                });
              }}
              type="number"
              disabled={!!id}
            />
          </S.RowFormField>
        )}

        {isResultDateSetRequired(offerWallInfo.type) && (
          <S.RowFormField margin="0 0 0 10px">
            <label>포인트 지급일</label>
            <Input
              type="date"
              value={moment(offerWallInfo.resultDate).format('YYYY-MM-DD')}
              onChange={(_, { value }) => handleChangeDate('resultDate', value)}
            />
          </S.RowFormField>
        )}
      </S.RowContainer>
    </>
  );
};

type OfferWallPointProps = {
  onChangeValue: ({ name, value }: { name: string; value: any }) => void;
  onChangeRewardValue: ({ name, value }: { name: string; value: any }) => void;
  initBestParticipantInfo: () => void;
  offerWallInfo: any;
  id: any;
};

export default OfferWallPoint;
