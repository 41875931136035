import { Map, fromJS } from 'immutable';
import { types as challengeTypes } from './Challenge.action';
import { types as navigationTypes } from '../Navigation/Navigation.action';

const initialState = Map({
  challenges: null,
  activeChallenges: null,
});

const challengeReducer = (state = initialState, action) => {
  switch (action.type) {
    case challengeTypes.SET_CHALLENGES__CHALLENGE:
    case navigationTypes.SET_CHALLENGES__NAVIGATION:
      return state.set('challenges', fromJS(action.challenges));
    case challengeTypes.SET_ACTIVE_CHALLENGES__CHALLENGE:
    case navigationTypes.SET_ACTIVE_CHALLENGES__NAVIGATION:
      return state.set('activeChallenges', fromJS(action.activeChallenges));
    default:
      return state;
  }
};

export default challengeReducer;
