import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'isPublic'>;

export const validate: FormValidator<Params> = ({ isPublic }) => {
  if (typeof isPublic !== 'boolean') {
    return { isValid: false, message: '공개여부를 확인해주세요' };
  }

  return { isValid: true };
};
