import Accordion from '@component/Accordion/Accordion';
import ErrorMsg from '@component/ErrorMsg';
import { UserFilterSelector } from '@component/UserFilterSelector';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { useEffect, useRef } from 'react';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './UserFilterForm.serializer';
import { useUpdateUserFilter, useUserFilter } from './UserFilterForm.store';
import { validate } from './UserFilterForm.validator';
import { useEditCheck } from '../../hooks/useEditCheck';

type Props = {
  open: boolean;
};

export const UserFilterForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'userFilterId'>,
  Pick<ChallengeFormType, 'userFilter'>,
  Pick<ReqCreateOrUpdateChallenge, 'userFilterId'>,
  Props
> = ({ open }) => {
  const userFilter = useUserFilter();
  const { update } = useUpdateUserFilter();
  const isFirstRender = useRef(true);
  const { getBorder, triggerEdited } = useEditCheck();

  const validated = validate({ userFilter });

  useEffect(() => {
    if (!userFilter.id || !isFirstRender.current) return;
    isFirstRender.current = false;

    update(userFilter.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilter.id]);

  return (
    <Accordion title="유저 필터 선택하기" open={open}>
      <ErrorMsg text={validated.isValid ? '' : validated.message} />
      <UserFilterSelector
        filterId={userFilter.id || undefined}
        onChange={(filterId) => {
          triggerEdited('userFilterId');
          update(filterId === null ? filterId : Number(filterId));
        }}
        upward
        style={{ border: getBorder('userFilterId') }}
      />
    </Accordion>
  );
};

UserFilterForm.validate = validate;
UserFilterForm.convertData2Form = convertData2Form;
UserFilterForm.convertForm2Data = convertForm2Data;
