import Button from '@component/Button';
import { FC } from 'react';
import { Table } from 'semantic-ui-react';
import { Rccp } from '../../../../NewChallengeForm.types';
import { useCommerceActions } from '../../CommerceForm.store';

type Props = {
  rccp: Rccp;
  index: number;
};
const ProductItem: FC<Props> = ({ rccp, index }) => {
  const { removeProduct } = useCommerceActions();
  return (
    <Table.Row>
      <Table.Cell>{rccp.name}</Table.Cell>
      <Table.Cell>{rccp.companyName}</Table.Cell>
      <Table.Cell>{rccp.price.toLocaleString()}</Table.Cell>
      <Table.Cell>{rccp.shippingPrice.toLocaleString()}</Table.Cell>
      <Table.Cell>{rccp.unit}</Table.Cell>
      <Table.Cell>{rccp.totalCount}</Table.Cell>
      <Table.Cell>
        {/* <Button size="mini" name="up" value={idx} onClick={changeProductOrder}>
          <Icon name="angle up" style={{ margin: 0 }} />
        </Button>
        <Button
          size="mini"
          name="down"
          value={idx}
          onClick={changeProductOrder}
        >
          <Icon name="angle down" style={{ margin: 0 }} />
        </Button> */}
        <Button size="small" text="삭제" onClick={() => removeProduct(index)} />
      </Table.Cell>
    </Table.Row>
  );
};

export default ProductItem;
