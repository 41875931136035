import { useState } from 'react';
import { Menu, Modal } from 'semantic-ui-react';
import { Crm, Race } from '../../../../../types';
import CrmForm from './CrmForm/CrmForm';

export const RaceCRMPopUp = (props: RaceCRMPopUpProps) => {
  const { race, isModalOpen, closeModal } = props;

  const [activeMenu, setActiveMenu] = useState<OptionType>(CRM_TYPE_OPTIONS[0]);

  const handleClickMenu = (menu: OptionType) => {
    setActiveMenu(menu);
  };

  return (
    <Modal size="large" open={isModalOpen} onClose={closeModal}>
      <Modal.Header>
        [{race.id}] - {race.title} CRM 설정
      </Modal.Header>

      <Modal.Content>
        <Menu pointing size="large" secondary>
          {CRM_TYPE_OPTIONS.map((option) => (
            <Menu.Item
              key={option.value}
              name={option.label}
              value={option.value}
              active={activeMenu.value === option.value}
              onClick={() => handleClickMenu(option)}
            />
          ))}
        </Menu>

        <CrmForm race={race} activeMenu={activeMenu} />
      </Modal.Content>
    </Modal>
  );
};

type RaceCRMPopUpProps = {
  race: Race.RaceForAdmin;
  isModalOpen: boolean;
  closeModal: () => void;
};

export type OptionType = {
  label: string;
  value: Crm.CrmOfferWallType | 'RACE_INSTAGRAM_AND_SURVEY';
  targetText: string;
};

export const CRM_TYPE_OPTIONS: OptionType[] = [
  {
    label: '인스타그램 and 설문조사 오퍼월 CRM',
    value: 'RACE_INSTAGRAM_AND_SURVEY',
    targetText: '인스타그램/설문조사',
  },
  {
    label: '인스타그램 오퍼월 CRM',
    value: 'RACE_INSTAGRAM_MISSION',
    targetText: '인스타그램',
  },
  {
    label: '설문조사 오퍼월 CRM',
    value: 'RACE_SURVEY',
    targetText: '설문조사',
  },
];

export default RaceCRMPopUp;
