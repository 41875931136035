import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { useCallback, useMemo, useState } from 'react';
import FlexBox from '@component/FlexBox/FlexBox';
import { useGetTags } from '@queries/Challenge/useGetTags';
import ChipList from '@component/ChipList';
import Label from '@component/Label';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import Dropdown from '@component/Dropdown';
import _ from 'lodash';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './TagIdsForm.serializer';
import { useTagIds, useTagIdsActions } from './TagIdsForm.store';
import { validate } from './TagIdsForm.validator';
import {
  getOptionsFromTags,
  getSelectedTagsFromId,
  getCollaboTags,
} from './TagIdsForm.util';

const TagIdsForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'tags'>,
  Pick<ChallengeFormType, 'tagIds'>,
  Pick<ReqCreateOrUpdateChallenge, 'tagIds'>
> = () => {
  const { tagIds } = useTagIds();
  const { setTagIds } = useTagIdsActions();
  const [keyword, setKeyword] = useState<string>('');
  const { data } = useGetTags();
  const selectedTags = useMemo(() => {
    if (!data) return [];
    return getSelectedTagsFromId(data.tags, tagIds);
  }, [data, tagIds]);
  const options = useMemo(() => {
    if (!data) return [];
    return getOptionsFromTags(data.tags, keyword);
  }, [data, keyword]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchKeywordChange = useCallback(
    _.debounce((e, { searchQuery }) => {
      setKeyword(searchQuery);
    }, 300),
    [],
  );

  const handleRemoveChip = (index: number) => {
    const updatedTagIds = [...tagIds];
    updatedTagIds.splice(index, 1);
    setTagIds(updatedTagIds);
  };

  const collaboTags = getCollaboTags(data?.tags || []);

  return (
    <FlexBox.Column gap={5}>
      <Label>태그</Label>
      <ChipList
        style={{ marginBottom: 6 }}
        chipList={selectedTags.map(({ tag, id }) => `[${id}]${tag}`)}
        removable
        onRemovePress={handleRemoveChip}
      />

      <Dropdown
        placeholder="제휴 챌린지 태그 추가하기"
        options={collaboTags}
        keyExtractor={(item) => item}
        onChange={(value) => {
          if (!value) return;
          setTagIds([...tagIds, value]);
        }}
      />
      <SemanticDropdown
        search
        selection
        multiple
        placeholder="태그 추가하기"
        options={options}
        value={[keyword]}
        closeOnChange
        onSearchChange={handleSearchKeywordChange}
        onChange={(e, { value }) => {
          setTagIds([...tagIds, (value as Array<unknown>).at(-1) as number]);
          setKeyword('');
        }}
      />
    </FlexBox.Column>
  );
};

export default TagIdsForm;
TagIdsForm.validate = validate;
TagIdsForm.convertData2Form = convertData2Form;
TagIdsForm.convertForm2Data = convertForm2Data;
