import Dropdown from '@component/Dropdown';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import { ToggleSwitch } from '@component/ToggleSwitch';
import { useGetGoalDetail } from '@queries/Challenge/useGetGoalDetail';
import { useGetGoals } from '@queries/Challenge/useGetGoals';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { flatMapInfiniteData } from '@utils/query.utils';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { useAchievementCountSync } from './GoalIdForm.hooks';
import { convertData2Form, convertForm2Data } from './GoalIdForm.serializer';
import { useGoalId, useUpdateGoalId } from './GoalIdForm.store';
import { validate } from './GoalIdForm.validator';

export const GoalIdForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'goalId'>,
  Pick<ChallengeFormType, 'goalId'>,
  Pick<ReqCreateOrUpdateChallenge, 'goalId'>
> = () => {
  const goalId = useGoalId();

  const { updateGoalId } = useUpdateGoalId();

  const [keyword, setKeyword] = useState('');

  const [isOfficial, setIsOfficial] = useState(true);

  const { data: selectedGoal } = useGetGoalDetail(goalId || 0);

  const { data, fetchNextPage, hasNextPage } = useGetGoals({
    isOfficial,
    keyword,
  });

  const goals = flatMapInfiniteData(data, 'goals', 'id');

  const options = goals.map((g) => ({ text: g.title, value: g.id }));

  if (selectedGoal && !options.find((o) => o.value === selectedGoal.id)) {
    options.push({ text: selectedGoal.title, value: selectedGoal.id });
  }

  // eslint-disable-next-line
  const handleChangeSearchText = useCallback(_.debounce(setKeyword, 500), []);

  useAchievementCountSync(goalId);

  return (
    <FlexBox.Column gap={8}>
      <Label>목표(Goal)</Label>
      <FlexBox.Row gap={12}>
        <Label>개설 포함</Label>
        <ToggleSwitch value={!isOfficial} onChange={(v) => setIsOfficial(!v)} />
      </FlexBox.Row>
      <Dropdown
        search
        value={goalId || undefined}
        onSearchChange={handleChangeSearchText}
        options={options}
        keyExtractor={(item) => item}
        onChange={updateGoalId}
        placeholder="목표를 선택해주세요."
        onReachEnd={hasNextPage ? fetchNextPage : undefined}
      />
    </FlexBox.Column>
  );
};

GoalIdForm.validate = validate;
GoalIdForm.convertData2Form = convertData2Form;
GoalIdForm.convertForm2Data = convertForm2Data;
