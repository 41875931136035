import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { isNull } from '@utils/js.utils';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'companyId'>,
  Pick<ReqCreateOrUpdateChallenge, 'companyId'>
> = ({ companyId }) => {
  if (isNull(companyId)) return {};
  return { companyId };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'company'>,
  Pick<ChallengeFormType, 'companyId'>
> = ({ company }) => ({ companyId: company?.id || null });
