import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { CustomFieldFormType } from '../../NewChallengeForm.types';

export const useCancelImpossible = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      cancelImpossible: s.challenge.cancelImpossible,
    })),
  );

export const useCancelImpossibleAction = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setCancelImpossible = (
    value: CustomFieldFormType['cancelImpossible'],
  ) => {
    updateChallengeForm((prev) => ({
      ...prev,
      cancelImpossible: value,
    }));
  };

  return { setCancelImpossible };
};
