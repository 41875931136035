import { useQuery } from '@tanstack/react-query';
import { apis } from '../../api';
import { QUERY_KEY } from './queryKey';

export const useGetQuestionTagList = () => {
  const query = useQuery({
    queryKey: [QUERY_KEY.GET_QUESTION_TAG_LIST],
    queryFn: apis.question.getQuestionTags,
  });

  return query;
};
