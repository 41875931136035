import {
  convertData2Form,
  convertForm2Data,
} from './ViralLoopInviteeRewardForm.serializer';

export const ViralLoopInviteeRewardForm = () => {
  return <></>;
};

ViralLoopInviteeRewardForm.convertData2Form = convertData2Form;
ViralLoopInviteeRewardForm.convertForm2Data = convertForm2Data;
