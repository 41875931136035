import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button, Checkbox, Form, Image } from 'semantic-ui-react';
import { Item } from '../../../PedometerGiftiShopBuyers/PedometerGiftiShopBuyers.types';
import { handleChangeSingleFile } from '../../../../../../utils/uploadImage';
import * as S from '../../../../Gotcha/style';
import { BenefitQueries } from '../../../../../../queries';

type FormComponentProps = {
  id: number;
  item: Item | undefined;
};

const FormComponent: FC<FormComponentProps> = (props) => {
  const { id, item: itemProp } = props;

  const { edit, create } = BenefitQueries.useGiftiShopItemMutation();

  const isCreateMode = !id;
  const [item, setItem] = useState(itemProp ?? EMPTY_ITEM);

  const { thumbnailImageUrl, isDisplayed, brand, name, price } = item;

  const handleItemState = <T extends keyof Item>(key: T, value: Item[T]) => {
    setItem((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangeImage = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { thumbnailImageUrl: thumbnail } = await handleChangeSingleFile(
        e,
        {},
      );
      handleItemState('thumbnailImageUrl', thumbnail);
    },
    [],
  );

  useEffect(() => {
    if (itemProp) {
      setItem(itemProp);
    }
  }, [itemProp]);

  const handleClickComplete = async () => {
    const isValidate = Object.entries(item).every(([k, v]) => {
      if (k === 'isDisplayed') return true;
      return !!v;
    });
    if (!isValidate) {
      window.alert(`모든 레이블을 채워주세요.\n가격은 0원 이상이어야합니다.`);
      return;
    }
    if (isCreateMode) {
      const res = await create({
        isDisplayed,
        name,
        brand,
        price,
        thumbnailImageUrl,
      });
      if (res) {
        window.alert(`[${brand}]${name} 상품을 추가하였습니다.`);
        window.history.back();
      }
      return;
    }
    const res = await edit(item);
    if (res) {
      window.alert(`[${brand}]${name} 상품을 수정하였습니다.`);
      window.history.back();
    }
  };

  return (
    <Form>
      <Form.Field>
        <label>브랜드</label>
        <input
          name="brand"
          value={brand}
          onChange={(e) => {
            handleItemState('brand', e.target.value);
          }}
          type="text"
        />
      </Form.Field>
      <Form.Field>
        <label>이름</label>
        <input
          name="name"
          value={name}
          onChange={(e) => {
            handleItemState('name', e.target.value);
          }}
          type="text"
        />
      </Form.Field>
      <Form.Field>
        <label>가격</label>
        <input
          name="price"
          value={price}
          onChange={(e) => {
            handleItemState('price', Number(e.target.value));
          }}
          type="number"
        />
      </Form.Field>
      <Form.Field>
        <label>썸네일 (1:1비율로 넣어주세요)</label>
        <input
          name="thumbnailImageUrl"
          type="file"
          onChange={handleChangeImage}
        />
        <Image id={`image_${id}`} src={thumbnailImageUrl} size="medium" />
      </Form.Field>
      <Form.Field>
        <label>공개 여부</label>
        <S.Row>
          <Checkbox
            toggle
            name="isDisplayed"
            checked={isDisplayed}
            onChange={(_, { checked }) => {
              handleItemState('isDisplayed', !!checked);
            }}
          />
          <div style={{ paddingLeft: 12 }}>
            {isDisplayed ? '공개' : '비공개'}
          </div>
        </S.Row>
      </Form.Field>
      <S.Row style={{ width: '100%', padding: 20, justifyContent: 'center' }}>
        <Button
          content={isCreateMode ? '생성완료' : '수정완료'}
          size="large"
          color="black"
          onClick={handleClickComplete}
        />
      </S.Row>
    </Form>
  );
};

const EMPTY_ITEM: Item = {
  id: -1,
  price: 0,
  name: '',
  brand: '',
  thumbnailImageUrl: '',
  isDisplayed: true,
};

export default FormComponent;
