export const colors = {
  black: '#21242A',
  newBlack: '#474747',
  white: '#fff',
  gray100: '#F8F9FB',
  gray200: '#F6F7F9',
  gray300: '#ECEEF1',
  gray400: '#B5BCC1',
  gray500: '#AEB5BB',
  gray600: '#8A8F95',
  gray700: '#5F656C',

  newGray50: '#CBCED3',
  newGray100: '#EEEFF1',
  newGray150: '#E2E4E6',
  newGray200: '#DDDFE3',
  newGray300: '#BBBFC8',
  newGray350: '#DDDFE3',
  newGray400: '#A9AEBA',
  newGray450: '#CBCED3',
  newGray500: '#878E9E',
  newGray600: '#767E90',
  newGray700: '#59606F',
  newGray800: '#4C515E',
  newGray900: '#30343B',

  orange: '#FF912B',
  blue: '#0075FF',
  blue100: '#D9EAFF',
  blue300: '#90C3FF',
  blue500: '#0075FF',
  blue700: '#0057BD',

  red0: '#FFEEEF',
  red100: '#FE6067',
  red200: '#FF3257',
  red500: '#FF3364',

  // New Color (2021.7~)
  // can be changed after version 2.0
  MN01: '#222222',
  MN02: '#5C5C5C',
  MN03: '#828282',
  MN04: '#E4E4E4',
  MN05: '#EEEEEE',
  MN06: '#FAFAFA',
  RD01: '#A72343',
  RD02: '#F92A3E',
  RD03: '#FFE9EC',
  RD03_1: '#FFDBE0',
  GR02: '#00AB39',

  // NEW RED COLOR
  RED_PRIMARY_1_400: '#FF4343',
  RED_PRIMARY_1_300: '#F46B6A',
  RED_PRIMARY_1_200: '#FB9694',
  RED_PRIMARY_1_100: '#FFCBD0',
  RED_PRIMARY_1_50: '#FFEAED',

  // NEW GRAY COLOR
  GRAY_BASIC_900: '#222222',
  GRAY_BASIC_800: '#353535',
  GRAY_BASIC_700: '#59606F',
  GRAY_BASIC_600: '#777777',
  GRAY_BASIC_500: '#AAAAAA',
  GRAY_BASIC_400: '#CCCCCC',
  GRAY_BASIC_200: '#EEEEEE',
  GRAY_BASIC_100: '#F5F5F5',

  // DESIGN SYSTEM (6/1)
  PRIMARY_500: '#FF4343',
  PRIMARY_500_PRESSED: '#FF3333',
  PRIMARY_400: '#FF6E6E',
  PRIMARY_300: '#FF9999',
  PRIMARY_200: '#FFCCCC',
  PRIMARY_100: '#FFEDED',
  PRIMARY_100_PRESSED: '#FFE8E8',
  PRIMARY_50: '#FFEAED',

  GRAY_900: '#111111',
  GRAY_800: '#333333',
  GRAY_700: '#555555',
  GRAY_600: '#777777',
  GRAY_500: '#AAAAAA',
  GRAY_400: '#CCCCCC',
  GRAY_300: '#DDDDDD',
  GRAY_200: '#EEEEEE',
  GRAY_100: '#F5F5F5',
  GRAY_50: '#FAFAFA',

  BASIC_BLACK: '#000000',
  BASIC_WHITE: '#FFFFFF',

  SYSTEM_ERROR: '#BF0000',
  SYSTEM_SUCCESS: '#1FB881',

  PALETTE_YELLOW: '#FFD337',
  PALETTE_BLUE: '#006FF2',
  PALETTE_PURPLE: '#7520D8',
};

export const colorCode = {
  PRIMARY_500: 'PRIMARY_500',
  PRIMARY_500_PRESSED: 'PRIMARY_500_PRESSED',
  PRIMARY_400: 'PRIMARY_400',
  PRIMARY_300: 'PRIMARY_300',
  PRIMARY_200: 'PRIMARY_200',
  PRIMARY_100: 'PRIMARY_100',
  PRIMARY_100_PRESSED: 'PRIMARY_100_PRESSED',
  PRIMARY_50: 'PRIMARY_50',

  GRAY_900: 'GRAY_900',
  GRAY_800: 'GRAY_800',
  GRAY_700: 'GRAY_700',
  GRAY_600: 'GRAY_600',
  GRAY_500: 'GRAY_500',
  GRAY_400: 'GRAY_400',
  GRAY_300: 'GRAY_300',
  GRAY_200: 'GRAY_200',
  GRAY_100: 'GRAY_100',
  GRAY_50: 'GRAY_50',

  BASIC_BLACK: 'BASIC_BLACK',
  BASIC_WHITE: 'BASIC_WHITE',

  SYSTEM_ERROR: 'SYSTEM_ERROR',
  SYSTEM_SUCCESS: 'SYSTEM_SUCCESS',

  PALETTE_YELLOW: 'PALETTE_YELLOW',
  PALETTE_BLUE: 'PALETTE_BLUE',
  PALETTE_PURPLE: 'PALETTE_PURPLE',
};
