import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useIsDeleted = () => {
  return useChallengeFormStore(
    useShallow((s) => ({ isDeleted: s.challenge.isDeleted })),
  );
};

export const useIsDeletedActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setIsDeleted = (isDeleted: boolean) =>
    updateChallengeForm({ isDeleted });

  return { setIsDeleted };
};
