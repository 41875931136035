import Dropdown from '@component/Dropdown';
import { useUserFilterById } from '@queries/common/useUserFilterById';
import _ from 'lodash';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apis } from '../../api';
import {
  flatMapInfiniteData,
  getNextPageParam,
  withTypedInfiniteLoad,
} from '../../utils/query.utils';
import { UserFilterItem } from 'src/types/userFilter.types';

type Props = {
  filterId?: UserFilterItem['id'] | null;
  upward?: boolean;
  onChange?: (filterId: UserFilterItem['id'] | null) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
};

export const UserFilterSelector: FC<Props> = ({
  filterId,
  upward = false,
  onChange,
  style,
  disabled,
}) => {
  const [keyword, setKeyword] = useState('');
  const { userFilters, fetchNextPage, hasNextPage } =
    useUserFilterQuery(keyword);

  const { data: selectedUserFilter } = useUserFilterById(filterId ?? undefined);

  const options = userFilters.map((f) => ({
    text: getFilterName(f),
    value: f.id,
  }));

  if (
    selectedUserFilter &&
    !options.find((o) => o.value === selectedUserFilter.id)
  ) {
    options.push({
      text: getFilterName(selectedUserFilter),
      value: selectedUserFilter.id,
    });
  }

  const isLoading = useRef(true);

  const handleClick = (id: number | null) => {
    if (onChange) onChange(id);
    if (!id) setKeyword('');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(_.debounce(setKeyword, 500), []);

  useEffect(() => {
    isLoading.current = false;
  }, [userFilters.length]);

  if (disabled) {
    return (
      <div
        style={{
          padding: '10px',
        }}
      >
        {selectedUserFilter ? (
          getFilterName(selectedUserFilter)
        ) : (
          <span
            style={{
              color: 'blue',
            }}
          >
            해당하는 유저 필터가 없습니다.
          </span>
        )}
      </div>
    );
  }

  return (
    <Dropdown
      placeholder="유저 필터를 선택하세요"
      search
      upward={upward}
      value={selectedUserFilter?.id}
      options={options}
      keyExtractor={(item) => item}
      onChange={handleClick}
      onSearchChange={search}
      onReachEnd={hasNextPage ? fetchNextPage : undefined}
      style={style}
    />
  );
};

type Request = {
  keyword: string;
  offset: number;
  limit: number;
};

type Response = {
  isEnd: boolean;
  userFilterList: UserFilterItem[];
};

const getUserFilters: (r: Request) => Promise<Response> = apis.searchUserFilter;

const useUserFilterQuery = (keyword = '') => {
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['USER-FILTERS', keyword],
    queryFn: withTypedInfiniteLoad(getUserFilters, { keyword }, 50),
    initialPageParam: 0,
    getNextPageParam,
  });

  const userFilters = flatMapInfiniteData(data, 'userFilterList', 'id');

  return { fetchNextPage, userFilters, hasNextPage };
};

const getFilterName = (filter?: Pick<UserFilterItem, 'id' | 'name'>) => {
  if (!filter) return '유저 필터 선택';
  return `${filter.name} (id: ${filter.id})`;
};
