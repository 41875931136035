import React, { useState, useCallback } from 'react';
import { Button, Dropdown, Form, Input, Modal } from 'semantic-ui-react';

const CollaboAdminModal = ({
  modalOpen,
  closeModal,
  challengeOptions,
  adminUserInfo: _adminUserInfo,
  createAdminUser,
  updateAdminUser,
}) => {
  const [adminUserInfo, setAdminUserInfo] = useState(_adminUserInfo);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      const _adminUserInfo = { ...adminUserInfo };
      _adminUserInfo[name] = value;
      setAdminUserInfo(_adminUserInfo);
    },
    [adminUserInfo],
  );

  const handleRadioChange = useCallback(
    (e, { name, value }) => {
      const _adminUserInfo = { ...adminUserInfo };
      _adminUserInfo[name] = value;
      setAdminUserInfo(_adminUserInfo);
    },
    [adminUserInfo],
  );

  const mode = !adminUserInfo.id ? 'ADD' : 'EDIT';

  const addOrUpdate = useCallback(() => {
    if (mode === 'ADD') {
      createAdminUser(adminUserInfo);
    } else {
      updateAdminUser(adminUserInfo);
    }
  }, [adminUserInfo]);

  return (
    <Modal size="tiny" open={modalOpen} onClose={closeModal}>
      <Modal.Header>
        제휴 어드민 계정 {mode === 'ADD' ? '추가' : '수정'}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>이메일</label>
            <Input
              name="email"
              defaultValue={adminUserInfo.email}
              onChange={handleChange}
            />
          </Form.Field>
          {!adminUserInfo.id && (
            <Form.Field>
              <label>비밀번호</label>
              <Input
                name="password"
                defaultValue={adminUserInfo.password}
                onChange={handleChange}
              />
            </Form.Field>
          )}
          <Form.Field>
            <label>이름</label>
            <Input
              name="name"
              defaultValue={adminUserInfo.name}
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Field>
            <label>제휴 챌린지 목록</label>
            <div>
              챌린지 편집 화면에서 회사 정보 설정한 챌린지만 노출됩니다.
            </div>
            <Dropdown
              placeholder="챌린지를 선택하세요."
              fluid
              multiple
              search
              selection
              clearable
              name="challengeIds"
              options={challengeOptions}
              value={adminUserInfo.challengeIds}
              onChange={handleRadioChange}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="blue"
          content={mode === 'ADD' ? '추가' : '수정'}
          onClick={addOrUpdate}
        />
        <Button color="black" content="닫기" onClick={closeModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default React.memo(CollaboAdminModal);
