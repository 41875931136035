import styled from 'styled-components';

export const Container = styled.div`
  padding: 80px 20px;
`;

export const ModuleContainer = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${(props) => props.margin && props.margin};
`;

export const InterviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
`;

export const ImageWithRadius = styled.div`
  position: relative;
  border-radius: 50px;
  overflow: hidden;
`;

export const ImgHorizontalContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;
  padding: 20px 0 0 0;
`;

export const ImgCardSquared = styled.div`
  flex-shrink: 0;
  width: 240px;
  height: 240px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 12px;
`;

export const ImgCardResponsive = styled.img<{ margin?: string }>`
  max-width: 100%;
  height: auto;
  margin: ${(props) => props.margin && props.margin};
`;

export const Label = styled.div<{
  position?: string;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  zIndex?: number;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  size?: number;
  weight?: number;
  backgroundColor?: string;
  color?: string;
  textDecoration?: string;
  textAlign?: string;
  letterSpacing?: string;
  lineHeight?: string;
  pointer?: boolean;
  opacity?: number;
}>`
  box-sizing: border-box;
  position: ${(props) => props.position || 'static'};
  top: ${(props) => props.top && `${props.top}px`};
  right: ${(props) => props.right && `${props.right}px`};
  bottom: ${(props) => props.bottom && `${props.bottom}px`};
  left: ${(props) => props.left && `${props.left}px`};
  z-index: ${(props) => props.zIndex && props.zIndex};
  width: ${(props) => props.width && props.width};
  height: ${(props) => props.height && props.height};
  margin: ${(props) => props.margin && props.margin};
  padding: ${(props) => props.padding && props.padding};
  font-size: ${(props) => `${props.size || 16}px`};
  font-weight: ${(props) => props.weight || 400};
  background-color: ${(props) =>
    props.backgroundColor && props.backgroundColor};
  color: ${(props) => props.color || '#21242A'};
  text-decoration: ${(props) => props.textDecoration && props.textDecoration};
  text-align: ${(props) => props.textAlign && props.textAlign};
  letter-spacing: ${(props) => props.letterSpacing && props.letterSpacing};
  line-height: ${(props) => props.lineHeight && props.lineHeight};
  cursor: ${(props) => (props.pointer ? 'pointer' : 'static')};
  opacity: ${(props) => props.opacity && props.opacity};
  white-space: pre-wrap;
`;
