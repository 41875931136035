import FlexBox from '@component/FlexBox/FlexBox';
import ImageInput from '@component/ImageInput';
import TextArea from '@component/TextArea';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { Icon, Image, Label } from 'semantic-ui-react';
import { swap } from '@utils/js.utils';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './AuthenticationGoodExamplesForm.serializer';
import {
  useAuthenticationGoodExamples,
  useAuthenticationGoodExamplesActions,
} from './AuthenticationGoodExamplesForm.store';
import * as S from './AuthenticationGoodExamplesForm.style';
import { validate } from './AuthenticationGoodExamplesForm.validator';

const AuthenticationGoodExamplesForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'authenticationGoodExamples'>,
  Pick<ChallengeFormType, 'authenticationGoodExamples'>,
  Pick<ReqCreateOrUpdateChallenge, 'authenticationGoodExampleUrls'>
> = () => {
  const { authenticationGoodExampleUrls } = useAuthenticationGoodExamples();
  const { setAuthenticationGoodExamples } =
    useAuthenticationGoodExamplesActions();

  const handleImageUpload = async (files: string[] | undefined) => {
    if (!files) return;
    setAuthenticationGoodExamples([
      ...authenticationGoodExampleUrls,
      ...files.map((f) => ({ image: f, text: '' })),
    ]);
  };

  const handleImageTextChange = (text: string, index: number) => {
    const _authenticationGoodExampleUrls = [...authenticationGoodExampleUrls];
    _authenticationGoodExampleUrls[index].text = text;
    setAuthenticationGoodExamples(_authenticationGoodExampleUrls);
  };

  const spliceImage = (index: number) => {
    const _authenticationGoodExampleUrls = [...authenticationGoodExampleUrls];
    _authenticationGoodExampleUrls.splice(index, 1);
    setAuthenticationGoodExamples(_authenticationGoodExampleUrls);
  };

  const handleImageChange = (image: string | undefined, index: number) => {
    if (!image) return;
    setAuthenticationGoodExamples(
      authenticationGoodExampleUrls.map((item, idx) =>
        idx === index ? { ...item, image } : item,
      ),
    );
  };

  const changeImageOrder = (name: 'up' | 'down', index: number) => {
    const _authenticationGoodExampleUrls = [...authenticationGoodExampleUrls];

    let targetIndex;

    if (name === 'up') {
      if (index - 1 < 0) return;
      targetIndex = index - 1;
    } else {
      if (index + 1 > _authenticationGoodExampleUrls.length - 1) return;
      targetIndex = index + 1;
    }
    const swapedArray = swap(
      _authenticationGoodExampleUrls,
      index,
      targetIndex,
    );
    setAuthenticationGoodExamples(swapedArray);
  };

  return (
    <>
      <ImageInput
        label="인증샷 좋은 예시 (최대 3:4비율)"
        multiSelect
        onChange={handleImageUpload}
        uploadOnChange
      />

      {authenticationGoodExampleUrls.map((url, idx) => (
        <S.AuthenticationExampleContainer key={idx}>
          <Label color="black" onClick={() => spliceImage(idx)}>
            {idx + 1}
            <Icon name="delete" />
          </Label>
          <Label name="up" onClick={() => changeImageOrder('up', idx)}>
            <Icon name="angle up" style={{ margin: 0 }} />
          </Label>
          <Label name="down" onClick={() => changeImageOrder('down', idx)}>
            <Icon name="angle down" style={{ margin: 0 }} />
          </Label>
          <FlexBox.Row alignItems="flex-start" gap={20}>
            <div style={{ flex: 1 }}>
              <h5>이미지</h5>
              <FlexBox.Column gap={8}>
                <ImageInput
                  uploadOnChange
                  onChange={(image) => handleImageChange(image, idx)}
                />
                <Image src={url.image} size="medium" />
              </FlexBox.Column>
            </div>
            <div style={{ flex: 1 }}>
              <h5>설명문구</h5>
              <TextArea
                rows={3}
                value={url.text}
                onChange={(text) => handleImageTextChange(text, idx)}
              />
            </div>
          </FlexBox.Row>
        </S.AuthenticationExampleContainer>
      ))}
    </>
  );
};

export default AuthenticationGoodExamplesForm;

AuthenticationGoodExamplesForm.validate = validate;
AuthenticationGoodExamplesForm.convertData2Form = convertData2Form;
AuthenticationGoodExamplesForm.convertForm2Data = convertForm2Data;
