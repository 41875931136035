import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import moment from 'moment';
import * as S from '../Style';
import { getRaceOptions } from '../../../utils/dropdownOptions';

const SelectRaceDropdown = ({
  onChange,
  selectedRaceId,
}: {
  onChange: (
    race: {
      id: number;
      brand: string;
      endDate: string;
    } | null,
  ) => void;
  selectedRaceId: number | undefined;
}) => {
  const [raceOptions, setRaceOptions] = useState<
    { key: number; brand: string; endDate: string; raceType: string }[]
  >([]);

  useEffect(() => {
    (async () => {
      setRaceOptions(await getRaceOptions());
    })();
  }, []);

  return (
    <S.RowFormField>
      <label>랜선대회 ID 검색</label>
      <Dropdown
        placeholder="대회를 선택하세요."
        fluid
        search
        selection
        name="screenName"
        options={raceOptions}
        value={selectedRaceId}
        onChange={(_, data) => {
          const value = data?.value;
          if (!value || typeof value !== 'number') {
            onChange(null);
            return;
          }
          const race = raceOptions.find(({ key }) => key === value) as
            | {
                key: number;
                brand: string;
                endDate: string;
                raceType: string;
              }
            | undefined;
          if (!race) {
            onChange(null);
            return;
          }
          let { endDate } = race;
          if (race.raceType === 'sleep') {
            endDate = moment(race.endDate)
              .hour(23)
              .minute(59)
              .second(59)
              .format('YYYY-MM-DDTHH:mm:ss');
          }
          onChange({
            id: race.key,
            brand: race.brand,
            endDate,
          });
        }}
      />
    </S.RowFormField>
  );
};

export default SelectRaceDropdown;
