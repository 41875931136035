import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { useMemo } from 'react';
import { DescriptionModuleForm } from '../DescriptionModuleForm/DescriptionModuleForm';
import {
  convertData2Form,
  convertForm2Data,
} from '../DescriptionModuleForm/DescriptionModuleForm.serializer';
import { validate } from '../DescriptionModuleForm/DescriptionModuleForm.validator';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  useDescriptionExtra,
  useDescriptionExtraAction,
} from './DescriptionExtraForm.store';

const DESCRIPTION_EXTRA = 'descriptionExtra';
/**
 * DescriptionExtraForm은 DescriptionModuleForm의 완전 의존 관계이므로,
 * store를 제외한 모든 것을 DescriptionModuleForm에서 가져와 사용한다.
 */
const DescriptionExtraFormContainer: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'descriptionExtra'>,
  Pick<ChallengeFormType, 'descriptionExtra'>,
  Pick<ReqCreateOrUpdateChallenge, 'descriptionExtra'>
> = () => {
  const { descriptionExtra } = useDescriptionExtra();
  const {
    addDescriptionExtra,
    removeDescriptionExtra,
    swapDescriptionExtra,
    updateDescriptionExtra,
  } = useDescriptionExtraAction();

  const validator = useMemo(() => {
    return validate(DESCRIPTION_EXTRA);
  }, []);

  return (
    <DescriptionModuleForm
      label="챌린지 신청 후 노출되는 모듈"
      list={descriptionExtra}
      validator={validator}
      onAdd={addDescriptionExtra}
      onRemove={removeDescriptionExtra}
      onSwap={swapDescriptionExtra}
      onUpdate={updateDescriptionExtra}
    />
  );
};

export default DescriptionExtraFormContainer;

DescriptionExtraFormContainer.validate = validate(DESCRIPTION_EXTRA);
DescriptionExtraFormContainer.convertData2Form =
  convertData2Form(DESCRIPTION_EXTRA);
DescriptionExtraFormContainer.convertForm2Data =
  convertForm2Data(DESCRIPTION_EXTRA);
