import { FC, InputHTMLAttributes } from 'react';
import * as S from './CheckBox.styles';

type Props = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'onChange' | 'label'
> & {
  label?: string;
  onChange?: (value: boolean) => void;
};

const CheckBox: FC<Props> = (props) => {
  const { onChange, label, ...inputProps } = props;
  return (
    <S.Container>
      <S.Input
        {...inputProps}
        type="checkbox"
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
      {!!label && <S.Label>{label}</S.Label>}
    </S.Container>
  );
};

export default CheckBox;
