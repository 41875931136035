import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

export const validate: FormValidator<
  Pick<ChallengeFormType, 'achievementMission'>
> = ({ achievementMission }) => {
  for (let i = 0; i < achievementMission.length; i++) {
    const mission = achievementMission[i];
    if (mission.type === 'good' && mission.achievementIndexList.length === 0) {
      return {
        isValid: false,
        message: `[인증샷 예시(제휴)] ${
          i + 1
        }번째 예시 인증회차를 입력해주세요`,
      };
    }

    if (mission.title.length === 0) {
      return {
        isValid: false,
        message: `[인증샷 예시(제휴)] ${i + 1}번째 예시 제목을 입력해주세요`,
      };
    }

    if (mission.type === 'good' && mission.text.length === 0) {
      return {
        isValid: false,
        message: `[인증샷 예시(제휴)] ${i + 1}번째 예시 설명을 입력해주세요`,
      };
    }
  }

  return { isValid: true };
};
