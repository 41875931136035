import React from 'react';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';
import setCurrencyFormat from '../../../global/setCurrencyFormat';

const LoseUserDist = ({ retentionStatistics }) => {
  const userRegisterCountUnderEightyFive =
    retentionStatistics.userRegisterCountUnderEightyFive.M;
  const newUserRegisterCountUnderEightyFive = [];
  let totalUserCount = 0;
  _.forEach(userRegisterCountUnderEightyFive, (value, key) => {
    newUserRegisterCountUnderEightyFive.unshift({
      underEightyFiveCount: key,
      userCount: parseInt(value.S, 10),
    });
    totalUserCount += parseInt(value.S, 10);
  });
  // const newRegisters = _.sortBy(newRegister, ['registerCount']);
  let acc = 0;
  const list = _.map(newUserRegisterCountUnderEightyFive, (r) => {
    acc += r.userCount;
    return (
      <Table.Row key={r.underEightyFiveCount}>
        <Table.Cell>{setCurrencyFormat(r.underEightyFiveCount)}</Table.Cell>
        <Table.Cell>{setCurrencyFormat(r.userCount)}</Table.Cell>
        <Table.Cell>{setCurrencyFormat(acc)}</Table.Cell>
        <Table.Cell>
          {((r.userCount / totalUserCount) * 100).toFixed(2) + '%'}
        </Table.Cell>
        <Table.Cell>
          {((acc / totalUserCount) * 100).toFixed(2) + '%'}
        </Table.Cell>
      </Table.Row>
    );
  });
  return (
    <Table basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>챌린지 실패 회수</Table.HeaderCell>
          <Table.HeaderCell>유저수</Table.HeaderCell>
          <Table.HeaderCell>누적 유저수</Table.HeaderCell>
          <Table.HeaderCell>전체 비율</Table.HeaderCell>
          <Table.HeaderCell>누적 비율</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{list}</Table.Body>
    </Table>
  );
};

export default LoseUserDist;
