export const RACE_INSTA_DEFAULT_IMAGE_0 =
  'https://d246jgzr1jye8u.cloudfront.net/development/admin/1698662660370-0.jpg';
export const RACE_INSTA_DEFAULT_IMAGE_1 =
  'https://d246jgzr1jye8u.cloudfront.net/development/admin/1698664600813-1.jpg';
export const RACE_INSTA_DEFAULT_IMAGE_2 =
  'https://d246jgzr1jye8u.cloudfront.net/development/admin/1698664600814-2.jpg';
export const RACE_INSTA_DEFAULT_IMAGE_3 =
  'https://d246jgzr1jye8u.cloudfront.net/development/admin/1698664600816-3.jpg';
export const RACE_INSTA_DEFAULT_IMAGE_4 =
  'https://d246jgzr1jye8u.cloudfront.net/development/admin/1698664600817-4.jpg';

export const RACE_INSTA_DEFAULT_IMAGES = [
  RACE_INSTA_DEFAULT_IMAGE_0,
  RACE_INSTA_DEFAULT_IMAGE_1,
  RACE_INSTA_DEFAULT_IMAGE_2,
  RACE_INSTA_DEFAULT_IMAGE_3,
  RACE_INSTA_DEFAULT_IMAGE_4,
];

export const INSTA_ICON =
  'https://d246jgzr1jye8u.cloudfront.net/development/admin/1698387336469.png';
