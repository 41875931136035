import { GoalCategoryWithRestrict } from '@types';
import { Container, Table } from 'semantic-ui-react';
import GoalCategoryItem from '../GoalCategoryItem';

const GoalCategoryTable = (props: GoalCategoryTableProps) => {
  const { goalCategories, remove, update } = props;
  return (
    <Container
      fluid
      style={{
        overflowX: 'auto',
        maxWidth: 1400,
        maxHeight: 1000,
        marginTop: 20,
      }}
    >
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>id</Table.HeaderCell>
            <Table.HeaderCell>Level1</Table.HeaderCell>
            <Table.HeaderCell>Level2</Table.HeaderCell>
            <Table.HeaderCell>Level3</Table.HeaderCell>
            <Table.HeaderCell>Level4</Table.HeaderCell>
            <Table.HeaderCell>참여제한 카테고리</Table.HeaderCell>
            <Table.HeaderCell>편집</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {goalCategories.map((gc) => (
            <GoalCategoryItem
              key={gc.id}
              goalCategory={gc}
              remove={remove}
              update={update}
            />
          ))}
        </Table.Body>
      </Table>
    </Container>
  );
};

export default GoalCategoryTable;

type GoalCategoryTableProps = {
  goalCategories: GoalCategoryWithRestrict[];
  remove: (gc: GoalCategoryWithRestrict) => void;
  update: (gc: GoalCategoryWithRestrict) => void;
};
