import React from 'react';
import { Table, Header } from 'semantic-ui-react';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';

export const ChallengeRegisterInfo = ({ registerInfo }) => {
  const registerCount = registerInfo.registerCount
    ? setCurrencyFormat(registerInfo.registerCount)
    : 0;
  const cashDepositSum = registerInfo.cashDepositSum
    ? setCurrencyFormat(registerInfo.cashDepositSum)
    : 0;
  const prizeDepositSum = registerInfo.prizeDepositSum
    ? setCurrencyFormat(registerInfo.prizeDepositSum)
    : 0;
  const cardDepositSum = registerInfo.cardDepositSum
    ? setCurrencyFormat(registerInfo.cardDepositSum)
    : 0;
  const transferDepositSum = registerInfo.transferDepositSum
    ? setCurrencyFormat(registerInfo.transferDepositSum)
    : 0;
  const totalDeposit =
    registerInfo.cashDepositSum +
    registerInfo.prizeDepositSum +
    registerInfo.cardDepositSum +
    registerInfo.transferDepositSum;
  return (
    <React.Fragment>
      <Header>챌린지 참가신청 정보</Header>
      <Table basic="very" size="small" celled textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>총 참가자수</Table.HeaderCell>
            <Table.HeaderCell>신청금(캐시)</Table.HeaderCell>
            <Table.HeaderCell>신청금(상금)</Table.HeaderCell>
            <Table.HeaderCell>신청금(카드)</Table.HeaderCell>
            <Table.HeaderCell>신청금(계좌이체)</Table.HeaderCell>
            <Table.HeaderCell>총참가비</Table.HeaderCell>
            <Table.HeaderCell>평균참가비</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>{setCurrencyFormat(registerCount)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(cashDepositSum)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(prizeDepositSum)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(cardDepositSum)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(transferDepositSum)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(totalDeposit)}</Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(totalDeposit / registerCount)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default ChallengeRegisterInfo;
