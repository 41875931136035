import React, { Component, useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Button,
  Image,
  Loader,
  Divider,
  Header,
  Modal,
  Form,
  Dropdown,
  Dimmer,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import api, { apiGo, apis } from '../../../api/index';
import resizeImage from '../../../global/resizeImage';
import ChallengeInfo from './ChallengeInfo';
import ChallengeRegisterInfo from './ChallengeRegisterInfo';
import ChallengeUser from './ChallengeUser';
import ChallengeResultInfo from './ChallengeResultInfo';
import ChallengeReview from './ChallengeReview';
import ChallengeProduct from './ChallengeProduct';
import ChallengeStickerInfo from './ChallengeStickerInfo';
import TransactionProduct from './TransactionProduct';
import RestrictGoal from './RestrictGoal';
import moment from 'moment';
import {
  ChallengeDetailInfoForAdmin,
  ChallengeReviewInfo,
  RegisterInfoForAdmin,
  TransationProductForAdmin,
} from '@types';

const ChallengeItemDetail = ({
  match,
}: {
  match: { params: { id: string } };
}) => {
  const [challenge, setChallenge] =
    useState<ChallengeDetailInfoForAdmin | null>(null);
  const [isMounted, setIsMounted] = useState(false);
  const [relationUserChallenges, setRelationUserChallenges] = useState<
    RegisterInfoForAdmin[]
  >([]);
  const [rucsLoading, setRucsLoading] = useState(false);
  const [calculateComplete, setCalculateComplete] = useState(false);
  const [reviews, setReviews] = useState<ChallengeReviewInfo[]>([]);
  const [transactionProducts, setTransactionProducts] = useState<
    TransationProductForAdmin[]
  >([]);
  const [phones, setPhones] = useState<string[]>([]);
  const [restrictGoals, setRestrictGoals] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [nthTermOptions, setNthTermOptions] = useState<
    {
      key: number;
      text: number;
      value: number;
    }[]
  >([]);
  const [nthTerms, setNthTerms] = useState<number[]>([]);
  const [nthTermType, setNthTermType] = useState('ALL');
  const [result, setResult] = useState(null);
  const [minAchievementRate, setMinAchievementRate] = useState<number | null>(
    null,
  );
  const [maxAchievementRate, setMaxAchievementRate] = useState<number | null>(
    null,
  );
  const [isMemoRequired, setIsMemoRequired] = useState('false');
  const [isCsvOnly, setIsCsvOnly] = useState('false');

  const init = async () => {
    await getChallenge(Number(match.params.id));
    const { transactionProducts: transactionProducts_ } =
      await apis.getTransactionProducts(Number(match.params.id));
    setTransactionProducts(transactionProducts_);
  };

  useEffect(() => {
    init();
  }, []);

  const getGoalRestricts = async (goalId: number) => {
    const res = await apis.challenge.getGoalRestricts(goalId);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    const { restrictGoals: restrictGoals_ } = res;
    setRestrictGoals(restrictGoals_);
  };

  useEffect(() => {
    setIsMounted(false);
    getChallenge(Number(match.params.id));
  }, [match.params.id]);

  const getChallenge = async (id: number) => {
    const res = await apis.getChallenge(id);
    const { challenge: challenge_ } = res;
    const calculateComplete_ = !!challenge_.result;
    setChallenge(challenge_);
    setReviews(challenge_.reviews.comments);
    setCalculateComplete(calculateComplete_);
    setIsMounted(true);
    getGoalRestricts(challenge_.goalId);
    if (challenge_.registerInfo.registerCount <= 1000) {
      getAllRucs();
    }
  };

  const getAllRucs = async () => {
    if (!challenge) return;
    setRucsLoading(true);
    const offset = 0;
    const limit = 200;
    let rucs: RegisterInfoForAdmin[] = [];
    const concurrent = Math.ceil(challenge.registerInfo.registerCount / limit);
    while (true) {
      if (concurrent === 0) break;
      const promises = getRucs(challenge.id, concurrent, offset, limit);
      const newRucs = _.concat(
        // eslint-disable-next-line no-await-in-loop
        ..._.map(await Promise.all(promises), 'relationUserChallenges'),
      );
      rucs = rucs.concat(newRucs);
      if (newRucs.length <= limit * concurrent) break;
    }
    const phones_ = _.map(rucs, (ruc) => ruc.userPhone);
    setRelationUserChallenges(rucs);
    setPhones(phones_);
    setRucsLoading(false);
  };

  const getRucs = (
    challengeId: number,
    concurrent: number,
    offset: number,
    limit: number,
  ) => {
    return _.map(_.range(concurrent), (i) =>
      apis.getChallengeRegisters(challengeId, offset + i * limit, limit),
    );
  };

  const deleteChallengeItem = async () => {
    if (!challenge) return;
    await apis.deleteChallenges({ challengeIds: [challenge.id] });
    setChallenge({
      ...challenge,
      isDeleted: true,
    });
    alert('챌린지 삭제가 완료되었습니다.');
  };

  const handleDisplayChange = (e: any, { value }: any) => {
    const reviews_ = [...reviews];
    const _index = _.findIndex(reviews_, (r) => r.id === value);
    if (_index === -1) return;
    reviews_[_index].isDisplayed = !reviews_[_index].isDisplayed;
    setReviews(reviews_);
    apis.updateChallengeReview(value, {
      isDisplayed: reviews_[_index].isDisplayed,
    });
  };

  const refreshCacheForReviews = () => {
    if (!challenge) return;
    apiGo
      .post('/cache/refresh/reviews', {
        goalIds: [challenge.goalId],
      })
      .then(() => {
        alert('캐싱된 리뷰 데이터가 초기화 되었어요.');
      });
  };

  const handleChangeNthTerms = (e: any, { name, value }: any) => {
    if (name === 'nthTerms') {
      const nthTerms_ = value.map((item: any) => Number(item));
      setNthTerms(nthTerms_);
    }
  };

  const showDownloadModal = () => setDownloadModalOpen(true);
  const closeDownloadModal = () => setDownloadModalOpen(false);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'result') {
      if (value === 'ALL') {
        setResult(null);
      } else {
        setResult(value);
      }
    } else if (name === 'achievementRate') {
      if (value === 'ALL') {
        setMinAchievementRate(null);
        setMaxAchievementRate(null);
      } else if (value >= 1) {
        setMinAchievementRate(1);
        setMaxAchievementRate(null);
      } else if (value >= 0.85) {
        setMinAchievementRate(0.85);
        setMaxAchievementRate(1);
      } else {
        setMinAchievementRate(null);
        setMaxAchievementRate(0.85);
      }
    } else if (name === 'isMemoRequired') {
      setIsMemoRequired(value);
    } else if (name === 'isCsvOnly') {
      setIsCsvOnly(value);
    } else if (name === 'nthTermType') {
      setNthTermType(value);
    }
  };

  const getChallengeReportDownloadUrl = async () => {
    if (!challenge) return;
    const { url } = await apis.getChallengeResultReport(challenge.id);
    const link = document.createElement('a');
    link.href = url;
    const splits = url.split('/');
    const filename = splits[splits.length - 1];
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const downloadChallengeAchievementStatusExcel = async () => {
    if (!challenge) return;
    const blob = await apis.downloadChallengeAchievementStatusExcel(
      challenge.id,
    );

    if (!blob) alert('엑셀 다운로드에 실패했습니다.');

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${moment().format('YYMMDD_HHmmss')}_${
      challenge.id
    }_회차별인증현황.xlsx`;
    document.body.appendChild(link);
    link.click();
  };

  const handleOptionAddition = (e: any, { value }: any) => {
    setNthTermOptions([
      ...nthTermOptions,
      { key: Number(value), text: Number(value), value: Number(value) },
    ]);
  };

  const downloadAchievementFiles = () => {
    if (!challenge) return;
    setDownloading(true);

    let nthTerms_: number[] | null = nthTerms;
    if (nthTermType === 'instagram') {
      const field = challenge.customFields.find((f) => f.name === 'instagram');
      if (!field) {
        alert('인스타그램 인증 설정이 되어있지 않습니다.');
        return;
      }
      nthTerms_ = (field.values as any).achievementIndex;
    } else if (nthTermType === 'ALL') {
      nthTerms_ = null;
    }
    api
      .post(
        `/admin/challenges/${match.params.id}/achievements/download`,
        {
          isMemoRequired: isMemoRequired === 'true',
          isCsvOnly: isCsvOnly === 'true',
          nthTerms: nthTerms_,
          result,
          minAchievementRate,
          maxAchievementRate,
        },
        { responseType: 'blob' },
      )
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data]),
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        const filename = isCsvOnly
          ? `${challenge.id}_${challenge.title}_list.zip`
          : `${challenge.id}_${challenge.title}_achievements.zip`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
      });
  };

  if (!isMounted) return <Loader active inline="centered" />;

  if (!challenge) return null;

  // 각종 이미지 url 정리
  const thumbnailImageUrlF: string = challenge.thumbnailImageUrls.f
    ? resizeImage(challenge.thumbnailImageUrls.f, 300)
    : '';
  const authenticationGoodExamples: JSX.Element[] = [];
  _.forEach(challenge.authenticationGoodExamples, (object, idx) => {
    authenticationGoodExamples.push(
      <div key={idx}>
        <Image src={resizeImage(object.image, 300)} size="small" />
        <p>{object.text}</p>
      </div>,
    );
  });
  const authenticationBadExamples: JSX.Element[] = [];
  _.forEach(challenge.authenticationBadExamples, (object, idx) => {
    authenticationBadExamples.push(
      <div key={idx}>
        <Image src={resizeImage(object.image, 300)} size="small" />
        <p>{object.text}</p>
      </div>,
    );
  });

  // 태그 정보 정리
  const tagList = challenge.tags.map((tag, idx) => (
    <Button style={{ marginTop: 5 }} active key={idx} size="tiny">
      [{tag.id}] {tag.tag}
    </Button>
  ));

  return (
    <Container style={styles.container}>
      <Dimmer active={downloading}>
        <Loader />
      </Dimmer>
      <div style={{ display: 'flex' }}>
        <Button
          size="tiny"
          onClick={showDownloadModal}
          content={'이미지/CSV 다운로드'}
        />
        <Button
          size="tiny"
          onClick={getChallengeReportDownloadUrl}
          content={'결과 레포트 다운로드'}
        />
        <Button
          size="tiny"
          onClick={downloadChallengeAchievementStatusExcel}
          content={'회차별 인증현황 다운로드'}
        />
      </div>
      <Modal size="large" open={downloadModalOpen} onClose={closeDownloadModal}>
        <Dimmer active={downloading}>
          <Loader />
        </Dimmer>
        <Modal.Header>인증샷/csv 다운로드</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group inline>
              <label>인증샷/CSV 선택</label>
              <Form.Field
                label="인증샷+CSV"
                control="input"
                type="radio"
                name="isCsvOnly"
                value="false"
                checked={isCsvOnly === 'false'}
                onChange={handleChange}
              />
              <Form.Field
                label="CSV만"
                control="input"
                type="radio"
                name="isCsvOnly"
                value="true"
                checked={isCsvOnly === 'true'}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group inline>
              <label>인증 회차 다운로드</label>
              <Form.Field
                label="전체 회차"
                control="input"
                type="radio"
                name="nthTermType"
                value="ALL"
                checked={nthTermType === 'ALL'}
                onChange={handleChange}
              />
              <Form.Field
                label="인스타그램 회차"
                control="input"
                type="radio"
                name="nthTermType"
                value="instagram"
                checked={nthTermType === 'instagram'}
                onChange={handleChange}
              />
              <Form.Field
                label="수동 입력"
                control="input"
                type="radio"
                name="nthTermType"
                value="manual"
                checked={nthTermType === 'manual'}
                onChange={handleChange}
              />
            </Form.Group>
            {nthTermType === 'manual' && (
              <Form.Field>
                <label>인증 회차 입력</label>
                <Dropdown
                  placeholder="인증 회차를 입력해주세요"
                  fluid
                  multiple
                  search
                  selection
                  allowAdditions
                  name="nthTerms"
                  value={nthTerms}
                  options={nthTermOptions}
                  onAddItem={handleOptionAddition}
                  onChange={handleChangeNthTerms}
                />
              </Form.Field>
            )}
            <Form.Group inline>
              <label>결과값</label>
              <Form.Field
                label="전체"
                control="input"
                type="radio"
                name="result"
                value="ALL"
                checked={!result}
                onChange={handleChange}
              />
              <Form.Field
                label="OVER_HUNDRED(하트없이 100%)"
                control="input"
                type="radio"
                name="result"
                checked={result === 'OVER_HUNDRED'}
                value="OVER_HUNDRED"
                onChange={handleChange}
              />
              <Form.Field
                label="OVER_EIGHTY_FIVE(하트없이 85%이상)"
                control="input"
                type="radio"
                name="result"
                checked={result === 'OVER_EIGHTY_FIVE'}
                value="OVER_EIGHTY_FIVE"
                onChange={handleChange}
              />
              <Form.Field
                label="NORMAL(하트를 썼거나 85% 미만)"
                control="input"
                type="radio"
                name="result"
                value="NORMAL"
                checked={result === 'NORMAL'}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group inline>
              <label>달성률</label>
              <Form.Field
                label="전체"
                control="input"
                type="radio"
                name="achievementRate"
                value={'ALL'}
                checked={!minAchievementRate && !maxAchievementRate}
                onChange={handleChange}
              />
              <Form.Field
                label="100%"
                control="input"
                type="radio"
                name="achievementRate"
                value={1}
                checked={minAchievementRate === 1 && !maxAchievementRate}
                onChange={handleChange}
              />
              <Form.Field
                label="85%~99%"
                control="input"
                type="radio"
                name="achievementRate"
                value={0.85}
                checked={
                  minAchievementRate === 0.85 && maxAchievementRate === 1
                }
                onChange={handleChange}
              />
              <Form.Field
                label="0~85%"
                control="input"
                type="radio"
                name="achievementRate"
                value={0}
                checked={!minAchievementRate && maxAchievementRate === 0.85}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group inline>
              <label>메모 여부</label>
              <Form.Field
                label="전체"
                control="input"
                type="radio"
                name="isMemoRequired"
                value="false"
                checked={isMemoRequired === 'false'}
                onChange={handleChange}
              />
              <Form.Field
                label="메모 있는 인증샷"
                control="input"
                type="radio"
                name="isMemoRequired"
                value="true"
                checked={isMemoRequired === 'true'}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            content="다운받기"
            onClick={() => downloadAchievementFiles()}
          />
          <Button color="black" content="닫기" onClick={closeDownloadModal} />
        </Modal.Actions>
      </Modal>

      <Divider hidden />
      <Grid columns="equal">
        <ChallengeInfo
          challenge={challenge}
          tagList={tagList}
          thumbnailImageUrlF={thumbnailImageUrlF}
          authenticationGoodExamples={authenticationGoodExamples}
          authenticationBadExamples={authenticationBadExamples}
        />
      </Grid>
      <Divider hidden />
      <Divider hidden />

      <RestrictGoal restrictGoals={restrictGoals} />
      <Divider hidden />
      <Divider hidden />

      <ChallengeProduct challengeCommerce={challenge.commerce} />
      <Divider hidden />
      <Divider hidden />

      <ChallengeStickerInfo challengeStickers={challenge.challengeStickers} />
      <Divider hidden />
      <Divider hidden />

      <ChallengeRegisterInfo registerInfo={challenge.registerInfo} />
      <Divider hidden />
      <Divider hidden />
      {calculateComplete && (
        <ChallengeResultInfo
          result={challenge.result}
          registerInfo={challenge.registerInfo}
        />
      )}
      {!calculateComplete && (
        <>
          <Header as="h3">챌린지 결과</Header>
          <div>정산이 완료되지 않았습니다.</div>
        </>
      )}
      <Divider hidden />
      <Divider hidden />

      <ChallengeUser
        relationUserChallenges={relationUserChallenges}
        challenge={challenge}
        challengeCommerce={challenge.commerce}
        phones={phones}
        getAllRucs={getAllRucs}
        rucsLoading={rucsLoading}
      />

      <Divider hidden />
      <Divider hidden />

      <TransactionProduct
        challenge={challenge}
        transactionProducts={transactionProducts}
        relationUserChallenges={relationUserChallenges}
      />
      <Divider hidden />
      <Divider hidden />

      <ChallengeReview
        reviews={reviews}
        handleDisplayChange={handleDisplayChange}
        refreshCacheForReviews={refreshCacheForReviews}
      />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Container style={styles.float}>
        <div className="ui two buttons">
          <Button
            as={Link}
            to={{ pathname: `/challenges/${challenge.id}/edit` }}
            target="_blank"
            rel="noopener noreferrer"
            basic
            color="blue"
          >
            편집
          </Button>
          <Button color="black" onClick={deleteChallengeItem}>
            삭제
          </Button>
        </div>
      </Container>
    </Container>
  );
};

export default ChallengeItemDetail;

const styles = {
  container: {
    marginTop: 20,
  },
  float: {
    width: '50%',
    bottom: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    borderTop: '1px solid #f6f6f6',
    backgroundColor: 'white',
    zIndex: 100,
  },
};
