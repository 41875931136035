import React from 'react';
import { Table } from 'semantic-ui-react';
import setCurrencyFormat from '../../../global/setCurrencyFormat';
import _ from 'lodash';

const SexAgeStatistics = ({ totalStatistics }) => {
  const { userSexAgeObj, userSexAgeRegisterObj, newUserSexAgeRegisterObj } =
    totalStatistics.total;
  const list = [];
  if (!_.isEmpty(userSexAgeObj)) {
    for (const [key, value] of Object.entries(userSexAgeObj)) {
      const sex = key[0] === 'm' ? '남' : '여';
      const age = key.slice(1);
      const row = (
        <Table.Row key={key}>
          <Table.Cell>{sex}</Table.Cell>
          <Table.Cell>{age}</Table.Cell>
          <Table.Cell>{setCurrencyFormat(value.signUpCount)}</Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(userSexAgeRegisterObj[key])}
          </Table.Cell>
          <Table.Cell>{setCurrencyFormat(value.registerCount)}</Table.Cell>
          <Table.Cell>{setCurrencyFormat(value.registerDeposit)}</Table.Cell>
          <Table.Cell>
            {setCurrencyFormat(newUserSexAgeRegisterObj[key])}
          </Table.Cell>
          <Table.Cell>{setCurrencyFormat(value.newRegisterCount)}</Table.Cell>
          <Table.Cell>{setCurrencyFormat(value.newRegisterDeposit)}</Table.Cell>
        </Table.Row>
      );
      list.push(row);
    }
  }
  return (
    <Table basic="very" textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>성별</Table.HeaderCell>
          <Table.HeaderCell>연령</Table.HeaderCell>
          <Table.HeaderCell>가입자수</Table.HeaderCell>
          <Table.HeaderCell>신청자수</Table.HeaderCell>
          <Table.HeaderCell>신청건수</Table.HeaderCell>
          <Table.HeaderCell>신청금액</Table.HeaderCell>
          <Table.HeaderCell>NU신청자수</Table.HeaderCell>
          <Table.HeaderCell>NU신청건수</Table.HeaderCell>
          <Table.HeaderCell>NU신청금액</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{list}</Table.Body>
    </Table>
  );
};

export default SexAgeStatistics;
