import NumberInput from '@component/NumberInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './MinRegisterDepositForm.serializer';
import {
  useMinRegisterDeposit,
  useMinRegisterDepositActions,
} from './MinRegisterDepositForm.store';
import { validate } from './MinRegisterDepositForm.validator';

const MinRegisterDepositForm: ChallengeFormComponent<
  Pick<ReqCreateOrUpdateChallenge, 'minRegisterDeposit'>,
  Pick<ChallengeFormType, 'minRegisterDeposit'>,
  Pick<ChallengeInfoForAdmin, 'minRegisterDeposit'>
> = () => {
  const { minRegisterDeposit, maxRegisterDeposit } = useMinRegisterDeposit();
  const { setMinRegisterDeposit } = useMinRegisterDepositActions();
  const validated = validate({ minRegisterDeposit, maxRegisterDeposit });

  return (
    <NumberInput
      label="최소참가비 (참가비 고정 설정 시 최소금액으로 표기)"
      name="minRegisterDeposit"
      value={minRegisterDeposit}
      onChange={(v) => setMinRegisterDeposit(Math.max(0, Number(v)))}
      errorMsg={validated.isValid ? '' : validated.message}
    />
  );
};

export default MinRegisterDepositForm;

MinRegisterDepositForm.validate = validate;
MinRegisterDepositForm.convertData2Form = convertData2Form;
MinRegisterDepositForm.convertForm2Data = convertForm2Data;
