import Dropdown from '@component/Dropdown';
import { FC } from 'react';
import { useAllProducts } from '../../hooks/useAllProducts';

type Props = {
  productId?: number;
  onChange: (productId: number | null) => void;
};
const ProductSelector: FC<Props> = ({ productId, onChange }) => {
  const { products } = useAllProducts();

  if (!products.length) return <></>;
  return (
    <Dropdown
      label="상품명"
      options={products.map((p) => ({
        text: `${p.name} | ${p.price.toLocaleString()}원 | ${p.companyName}`,
        value: p.id,
      }))}
      search
      placeholder="상품을 선택해주세요."
      value={productId}
      onChange={onChange}
      keyExtractor={(item) => item}
    />
  );
};

export default ProductSelector;
