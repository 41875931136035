import React, { Component } from 'react';
import {
  Container,
  Loader,
  Header,
  Divider,
  Label,
  Button,
  Accordion,
  Icon,
  Dropdown,
  Grid,
} from 'semantic-ui-react';
import _ from 'lodash';
import { apis } from '../../../api';
import { Link } from 'react-router-dom';
import ReactDragList from 'react-drag-list';
import 'react-drag-list/assets/index.css';
import { categories, subCategoryObj } from '../../../constant/faqCategories';
import BoardMenu from '../BoardMenu/BoardMenu';

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqs: [],
      filteredFaqs: [],
      isMounted: false,
      activeIndex: 0,
      subCategories: [],
      category: {},
      subCategory: {},
    };
  }

  async componentDidMount() {
    const { faqs } = await apis.common.getFaqList();
    this.setState({ faqs, filteredFaqs: faqs, isMounted: true });
  }

  handleUpdate = async (_, updated) => {
    const priorityData = updated.map((o, idx) => ({ id: o.id, priority: idx }));
    await apis.common.updateFaqPriority({ priorityData });
  };

  deleteFaq = async (e) => {
    const id = Number(e.target.value);
    const { faqs: _faqs, filteredFaqs: _filteredFaqs } = this.state;
    await apis.common.deleteFaq(id);

    const faqs = _faqs.map((o) => {
      if (o.id === id) return { ...o, isDeleted: true };
      else return o;
    });

    const filteredFaqs = _filteredFaqs.map((o) => {
      if (o.id === id) return { ...o, isDeleted: true };
      else return o;
    });

    this.setState({ faqs, filteredFaqs });
  };

  recoverFaq = async (e) => {
    const id = Number(e.target.value);
    const { faqs: _faqs, filteredFaqs: _filteredFaqs } = this.state;
    await apis.common.recoverFaq(id);

    const faqs = _faqs.map((o) => {
      if (o.id === id) return { ...o, isDeleted: false };
      else return o;
    });

    const filteredFaqs = _filteredFaqs.map((o) => {
      if (o.id === id) return { ...o, isDeleted: false };
      else return o;
    });

    this.setState({ faqs, filteredFaqs });
  };

  handleClick = (_, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  changeCategory = (e, { name, value }) => {
    const { faqs } = this.state;
    if (value === '') {
      this.setState({ filteredFaqs: faqs });
      return;
    }
    const category = _.find(categories, (c) => c.value === value);
    const newFaqs = _.filter(faqs, (faq) => faq.category === category.text);
    this.setState({
      subCategories: subCategoryObj[value],
      filteredFaqs: newFaqs,
    });
  };

  changeSubCategory = (e, { value }) => {
    const { filteredFaqs, subCategories } = this.state;
    if (value === '') {
      this.filterFaqs();
      return;
    }
    const subCategory = _.find(subCategories, (c) => c.value === value);
    const newFaqs = _.filter(
      filteredFaqs,
      (faq) => faq.subCategory === subCategory.text,
    );
    this.setState({ filteredFaqs: newFaqs });
  };

  filterFaqs = () => {
    const { category, subCategory, faqs } = this.state;
    let newFaqs = [];
    let filteredFaqs = [];
    if (!_.isEmpty(category))
      newFaqs = _.filter(faqs, (faq) => faq.category === category.text);
    if (!_.isEmpty(subCategory))
      filteredFaqs = _.filter(newFaqs, (faq) => faq.category === category.text);
    this.setState({ filteredFaqs });
  };

  changeCategoryInline = (e, { name, value }) => {
    const { faqs } = this.state;
    const faq = _.find(faqs, (f) => f.id === name);
    const category = _.find(categories, (c) => c.value === value);
    faq.categoryValue = value;
    faq.category = category.text;
    this.setState({ faq });
  };

  changeSubCategoryInline = (id, value) => {
    const faqs = this.state.faqs.map((o) => {
      if (o.id !== id) return o;
      return { ...o, subCategory: value };
    });

    const filteredFaqs = this.state.filteredFaqs.map((o) => {
      if (o.id !== id) return o;
      return { ...o, subCategory: value };
    });

    this.setState({ faqs, filteredFaqs });
  };

  updateFaq = async (faq) => {
    await apis.common.updateFaq(faq.id, { ...faq });
    alert('변경이 완료되었어요.');
  };

  render() {
    const {
      filteredFaqs,
      isMounted,
      activeIndex,
      subCategories,
      category,
      subCategory,
    } = this.state;

    const renderList = () => {
      if (!isMounted) return <Loader active inline="centered" />;
      return (
        <ReactDragList
          dataSource={filteredFaqs}
          handles={false}
          onUpdate={this.handleUpdate}
          row={(faq) => (
            <Accordion>
              <Label color="black">
                {faq.isDeleted === false ? '공개' : '삭제됨'}
              </Label>
              <Label color="black">{faq.serviceType}</Label>
              <Dropdown
                inline
                options={categories}
                name={faq.id}
                value={faq.category}
                onChange={this.changeCategoryInline}
              />
              <Dropdown
                inline
                options={subCategoryObj[faq.category]}
                value={faq.subCategory}
                onChange={(_, { value }) =>
                  this.changeSubCategoryInline(faq.id, value)
                }
              />
              <Button
                size="mini"
                value={faq.id}
                onClick={() => this.updateFaq(faq)}
              >
                변경
              </Button>
              <Button
                basic
                color="blue"
                size="mini"
                as={Link}
                floated="right"
                to={{
                  pathname: '/faqs/' + faq.id + '/edit',
                  state: { faq: faq },
                }}
              >
                편집
              </Button>
              {faq.isDeleted ? (
                <Button
                  color="black"
                  size="mini"
                  floated="right"
                  value={faq.id}
                  onClick={this.recoverFaq}
                >
                  복구
                </Button>
              ) : (
                <Button
                  color="black"
                  size="mini"
                  floated="right"
                  value={faq.id}
                  onClick={this.deleteFaq}
                >
                  삭제
                </Button>
              )}
              <Accordion.Title
                active={activeIndex === faq.id}
                index={faq.id}
                onClick={this.handleClick}
              >
                <Icon name="dropdown" />
                {faq.question}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === faq.id}>
                <p>{faq.answer}</p>
              </Accordion.Content>
              <Divider />
            </Accordion>
          )}
        />
      );
    };

    return (
      <Container>
        <Grid columns="equal">
          <Grid.Column width={2}>
            <BoardMenu />
          </Grid.Column>
          <Grid.Column>
            <Header as="h3">
              FAQ 관리
              <Button
                floated="right"
                as={Link}
                to={{ pathname: '/faqs/create' }}
              >
                FAQ 생성
              </Button>
            </Header>
            <Dropdown
              placeholder="카테고리"
              search
              selection
              clearable
              options={categories}
              value={category.value}
              onChange={this.changeCategory}
            />
            <Dropdown
              placeholder="서브 카테고리"
              search
              selection
              clearable
              options={subCategories}
              value={subCategory.value}
              onChange={this.changeSubCategory}
            />
            <Divider />
            {renderList()}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default Faq;
