import React, { Component } from 'react';
import { Container, Label, Table, Loader, Button } from 'semantic-ui-react';
import api from '../../../../api';
import { Link } from 'react-router-dom';

const typeObj = {
  BANNER: '전광판',
  TAG: '태그 기준',
  SORT: '정렬 기준',
  TAG_COLLECT: '태그 모음',
};

const subTypeObj = {
  CHLNGERS_RULE: '챌린저스 방식',
  CHLNGERS_PURPOSE: '챌린저스 취지',
  CHLNGERS_ENJOY: '챌린저스 즐기기',
  SORT_DUE: '마감 임박',
  SORT_RATE: '만족도 순',
  SORT_POPULAR: '친구 많이 참가한 순',
  SORT_ALL: '전체',
  TAG_WORD: '단어',
  TAG_SENTENCE: '문장',
};

class ChallengeCurationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      challengeCuration: {},
      isMounted: false,
    };
  }

  componentDidMount() {
    if (this.props.challengeCuration) {
      this.setState({
        challengeCuration: this.props.challengeCuration,
        isMounted: true,
      });
    } else if (this.props.location.state) {
      this.setState({
        challengeCuration: this.props.location.state.challengeCuration,
        isMounted: true,
      });
    } else {
      api
        .get('/challengeCuration/' + this.props.match.params.id)
        .then((response) => {
          const challengeCuration = response.data.data.challengeCuration;
          this.setState({ challengeCuration, isMounted: true });
        });
    }
  }

  render() {
    const { isMounted, challengeCuration } = this.state;
    if (!isMounted) return <Loader active inline="centered" />;
    return (
      <Container>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>구분</Table.HeaderCell>
              <Table.HeaderCell>내용</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>템플릿 제목</Table.Cell>
              <Table.Cell>{challengeCuration.title}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>템플릿 모듈</Table.Cell>
              <Table.Cell>
                {challengeCuration.curationModule.map((m, idx) => {
                  if (m.type === 'BANNER') {
                    return (
                      <span key={idx}>
                        <Label style={{ marginTop: 10, marginBottom: 10 }}>
                          {typeObj[m.type]}
                        </Label>
                        <br />
                        서브타입 : {subTypeObj[m.subType]}
                        <br />
                        제목 : {m.title}
                        <br />
                        버튼 문구 : {m.buttonText}
                        <br />
                      </span>
                    );
                  } else if (m.type === 'TAG') {
                    return (
                      <span key={idx}>
                        <Label style={{ marginTop: 10, marginBottom: 10 }}>
                          {typeObj[m.type]}
                        </Label>
                        <br />
                        태그id : {m.subType}
                        <br />
                        제목 : {m.title}
                        <br />
                        부제 : {m.subTitle}
                        <br />
                        버튼 문구 : {m.buttonText}
                        <br />
                      </span>
                    );
                  } else if (m.type === 'SORT') {
                    return (
                      <span key={idx}>
                        <Label style={{ marginTop: 10, marginBottom: 10 }}>
                          {typeObj[m.type]}
                        </Label>
                        <br />
                        서브타입 : {subTypeObj[m.subType]}
                        <br />
                        제목 : {m.title}
                        <br />
                        부제 : {m.subTitle}
                        <br />
                        버튼 문구 : {m.buttonText}
                        <br />
                      </span>
                    );
                  } else if (m.type === 'TAG_COLLECT') {
                    return (
                      <span key={idx}>
                        <Label style={{ marginTop: 10, marginBottom: 10 }}>
                          {typeObj[m.type]}
                        </Label>
                        <br />
                        서브타입 : {subTypeObj[m.subType]}
                        <br />
                        제목 : {m.title}
                        <br />
                      </span>
                    );
                  } else return null;
                })}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>노출 여부</Table.Cell>
              <Table.Cell>
                {challengeCuration.isDisplayed === true ? '노출' : 'X'}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Button as={Link} to={{ pathname: '/challenge/curations' }}>
          목록으로
        </Button>
        <Button as={Link} to={{ pathname: '/challenge/curations' }}>
          삭제
        </Button>
        <Button
          as={Link}
          to={{
            pathname: '/challenge/curations/' + challengeCuration.id + '/edit',
            state: { challengeCuration: challengeCuration },
          }}
        >
          편집
        </Button>
      </Container>
    );
  }
}

export default ChallengeCurationDetail;
