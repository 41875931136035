import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { CommerceFormType, Rccp } from '../../NewChallengeForm.types';

export const useCommerce = () =>
  useChallengeFormStore((s) => s.challenge.commerce);

export const useSupporters = () =>
  useChallengeFormStore((s) => s.challenge.supporters);

export const useCommerceActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const update = (updated: Partial<CommerceFormType['commerce']>) =>
    updateChallengeForm((prev) => ({
      ...prev,
      commerce: {
        ...prev.commerce,
        ...updated,
      },
    }));

  const updateProductHeader = (productHeader: string) =>
    update({ productHeader });

  const updateSelectType = (
    selectType: CommerceFormType['commerce']['selectType'],
  ) => update({ selectType });

  const updateIsMandatory = (
    isMandatory: CommerceFormType['commerce']['isMandatory'],
  ) => update({ isMandatory });

  const addProduct = (rccp: Rccp) => {
    updateChallengeForm((prev) => ({
      ...prev,
      commerce: {
        ...prev.commerce,
        rccps: [...prev.commerce.rccps, rccp],
      },
    }));
  };

  const removeProduct = (index: number) => {
    updateChallengeForm((prev) => ({
      ...prev,
      commerce: {
        ...prev.commerce,
        rccps: prev.commerce.rccps.filter((_, i) => i !== index),
      },
    }));
  };

  const clearProducts = () =>
    updateChallengeForm((prev) => ({
      ...prev,
      commerce: {
        ...prev.commerce,
        rccps: [],
      },
    }));

  return {
    updateProductHeader,
    updateSelectType,
    updateIsMandatory,
    addProduct,
    removeProduct,
    clearProducts,
  };
};
