import { NaverBlogInfoListItem } from '../../NewChallengeForm.types';

export const DEFAULT_ITEM: NaverBlogInfoListItem = {
  achievementIndexList: [],
  hashTagList: [],
  checklist: [
    '네이버 블로그 본문글 공개, 필수 해시태그 등 조건이 충족되지 않으면 미인증 처리됩니다.',
    '연동된 블로그 ID와 동일한 ID의 포스팅만 인정됩니다.',
    '한 게시글은 한 번만 인증 가능합니다.',
  ],
};
