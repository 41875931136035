import { useQuery } from '@tanstack/react-query';
import { apis } from '../../api';
import { getQueryKey } from './getQueryKey';

type Params = {
  goalId?: number;
  startDate: string;
  endDate: string;
};

type Options = {
  enabled?: boolean;
};

export const useGetAchievementCardList = (
  { goalId, startDate, endDate }: Params,
  options: Options = {},
) => {
  return useQuery({
    enabled: options.enabled && Boolean(goalId),
    queryKey: getQueryKey('GET_ACHIEVEMENT_CARD_LIST', {
      goalId: goalId!,
      startDate,
      endDate,
    }),
    queryFn: () =>
      apis.challenge.getAchievementCardList({
        goalId: goalId!,
        startDate,
        endDate,
      }),
  });
};
