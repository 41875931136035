import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'maxRegisterCount'>,
  Pick<ReqCreateOrUpdateChallenge, 'maxRegisterCount'>
> = ({ maxRegisterCount }) => {
  if (!maxRegisterCount)
    throw Error("[MaxRegisterCountForm] this shouldn't be happened");

  return {
    maxRegisterCount,
  };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'maxRegisterCount'>,
  Pick<ChallengeFormType, 'maxRegisterCount'>
> = ({ maxRegisterCount }) => ({
  maxRegisterCount,
});
