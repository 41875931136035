import React from 'react';
import Button from '@component/Button';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import TextArea from '@component/TextArea';
import {
  DescriptionModuleFormType,
  NewInterviewInterviewee,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { swap } from '@utils/js.utils';
import TextInput from '@component/TextInput';
import ImageInput from '@component/ImageInput';
import {
  DescriptionModule,
  DescriptionModuleConvertData2Form,
  DescriptionModuleConvertForm2Data,
} from '../../DescriptionModuleForm.type';
import { validate } from './NewInterviewModule.validator';
import {
  convertData2Form,
  convertForm2Data,
} from './NewInterviewModule.serializer';

type NewInterviewModuleProps = {
  type: 'NEW-INTERVIEW';
  interviewee: NewInterviewInterviewee[];
  moduleIndex: number;
  onUpdate: (index: number, item: DescriptionModuleFormType) => void;
};
const NewInterviewModule: DescriptionModule<NewInterviewModuleProps> = (
  props: NewInterviewModuleProps,
) => {
  const { type, interviewee, moduleIndex, onUpdate } = props;

  const handleUpdate = (
    idx: number,
    fieldName: keyof NewInterviewInterviewee,
    value: string,
  ) => {
    const newInterviewee = interviewee.map((o, i) => {
      if (i === idx) return { ...o, [fieldName]: value };
      return { ...o };
    });
    return onUpdate(moduleIndex, {
      type,
      interviewee: newInterviewee,
    });
  };

  const handleAdd = () => {
    const newInterviewee = [
      ...interviewee,
      { name: '', image: '', content: '' },
    ];
    onUpdate(moduleIndex, {
      type,
      interviewee: newInterviewee,
    });
  };

  const handleRemove = (idx: number) => {
    const newInterviewee = [...interviewee];
    newInterviewee.splice(idx, 1);
    onUpdate(moduleIndex, {
      type,
      interviewee: newInterviewee,
    });
  };

  const handleMove = (aIndex: number, bIndex: number) => {
    const totalLength = interviewee.length;
    if (aIndex < 0 || aIndex >= totalLength) return;
    if (bIndex < 0 || bIndex >= totalLength) return;
    onUpdate(moduleIndex, {
      type,
      interviewee: swap(interviewee, aIndex, bIndex),
    });
  };

  return (
    <FlexBox.Column gap={6}>
      <Label>{type}</Label>
      {interviewee.map((o, idx) => (
        <FlexBox.Column key={o.id}>
          <FlexBox.Row justifyContent="flex-end">
            <Button text="위로" onClick={() => handleMove(idx - 1, idx)} />
            <Button text="아래로" onClick={() => handleMove(idx, idx + 1)} />
            <Button text="삭제" onClick={() => handleRemove(idx)} />
          </FlexBox.Row>
          <Label>이름</Label>
          <TextInput
            value={o.name}
            onChange={(text) => handleUpdate(idx, 'name', text)}
          />

          <Label>이미지</Label>
          <ImageInput
            uploadOnChange
            showPreview
            previewSize={120}
            label="이미지"
            value={o.image || undefined}
            onChange={(url) => handleUpdate(idx, 'image', url || '')}
          />

          <Label>내용</Label>
          <TextArea
            value={o.content}
            onChange={(text) => handleUpdate(idx, 'content', text)}
          />
        </FlexBox.Column>
      ))}
      <Button size="small" text="인터뷰이 추가" onClick={handleAdd} />
    </FlexBox.Column>
  );
};

NewInterviewModule.validate = validate;
NewInterviewModule.convertForm2Data =
  convertForm2Data as DescriptionModuleConvertForm2Data;
NewInterviewModule.convertData2Form =
  convertData2Form as DescriptionModuleConvertData2Form;
NewInterviewModule.moduleName = '신규인터뷰';
NewInterviewModule.defaultData = {
  type: 'NEW-INTERVIEW',
  interviewee: [],
};
export default NewInterviewModule;
