import Label from '@component/Label';
import {
  SpecialPrizeModuleFormType,
  SpecialPrizeTitle,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import React, { FC } from 'react';
import { Radio } from 'semantic-ui-react';
import { ModuleContainer } from '../../../../DescriptionModuleForm.style';

type Props = {
  type: SpecialPrizeModuleFormType['type'];
  title: SpecialPrizeTitle;
  onChange: (title: SpecialPrizeTitle) => void;
};
const TitleForm: FC<Props> = (props) => {
  const { type, title, onChange } = props;
  const isTotal = type === 'total';

  return (
    <ModuleContainer>
      <Label style={{ marginTop: 20, marginBottom: 6 }}>특별상금 텍스트</Label>
      {isTotal ? (
        <Radio label={SpecialPrizeTitle.TOTAL} checked />
      ) : (
        <>
          <Radio
            label="인당 페이백"
            name="prizeTitle"
            checked={title === SpecialPrizeTitle.INDIVIDUAL_PAYBACK}
            onChange={() => onChange(SpecialPrizeTitle.INDIVIDUAL_PAYBACK)}
          />
          <Radio
            label="인당 상금"
            name="prizeTitle"
            checked={title === SpecialPrizeTitle.INDIVIDUAL_PRIZE}
            onChange={() => onChange(SpecialPrizeTitle.INDIVIDUAL_PRIZE)}
          />
        </>
      )}
    </ModuleContainer>
  );
};

export default TitleForm;
