import { CustomImageFilterInfoType, ImageFilterCustomFieldValue } from '@types';

export const findTargetFilter = (
  imageFilter: ImageFilterCustomFieldValue[],
  achievementIndex: number,
) => imageFilter.find((v) => v.achievementIndex === achievementIndex);

export const getInitialInfoItemByType = <
  T extends CustomImageFilterInfoType['type'],
>(
  type: T,
): CustomImageFilterInfoType => {
  switch (type) {
    case 'image':
      return {
        type,
        data: {
          width: '100%',
          height: '100%',
          imageUrl: '',
        },
      };
    case 'text':
      return {
        type,
        data: {
          text: '',
          padding: 20,
        },
      };
    case 'timestamp':
      return {
        type,
        data: {
          padding: 20,
          backgroundColor: '#00000066',
        },
      };
    case 'challenge_name':
      return {
        type,
        data: {
          padding: 20,
        },
      };
    case 'accumulated_distance':
      return {
        type,
        data: {
          padding: 20,
        },
      };
    default:
      return {
        type,
      };
  }
};

export const getInitialInfo = (
  type: CustomImageFilterInfoType['type'],
  filterOrder: number,
) =>
  Array.from({ length: 4 }, (_, idx) =>
    getInitialInfoItemByType(idx === filterOrder ? type : 'image'),
  );
