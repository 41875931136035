export const transactionTypeLabel = {
  CHALLENGE_REGISTER: '챌린지 등록',
  CHALLENGE_CANCEL: '챌린지 취소',
  CHALLENGE_RETURN: '챌린지 환급',
  REWARD: '챌린지 상금',
  WITHDRAW_PRIZE: '상금 출금',
  RED: '레드카드 2회 포인트 회수',
  DELETE: '1년 지난 포인트 삭제',
  RECOMMEND: '추천인 포인트',
  EVENT: '이벤트',
  REGISTER_CARD: '캐시충전',
  CHARGE_CARD: '캐시충전',
  REGISTER_TRANSFER: '캐시충전',
  CHARGE_TRANSFER: '캐시충전',
  WITHDRAW_CARD: '캐시출금',
  WITHDRAW_TRANSFER: '캐시출금',
  PRODUCT_PURCHASE: '상품구입',
  PRODUCT_CANCEL: '상품구입취소',
  EXCHANGE_FROM_CASH: '캐시>스토어 전환',
  EXCHANGE_FROM_CASH_CANCEL: '캐시>스토어 전환 취소',
  EXCHANGE_FROM_PRIZE: '상금>스토어 전환',
  OFFER_WALL: '오퍼월',
  COLLABO_VIRAL_LOOP_INVITER: '획득',
  COLLABO_VIRAL_LOOP_INVITEE: '챌린지환급',
  ETC_USE: '기타',
};

export const transactionType = {
  REGISTER_CARD: 'REGISTER_CARD',
  CHARGE_CARD: 'CHARGE_CARD',
};
