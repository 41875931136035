import React, { ReactNode } from 'react';
import { ErrorBoundaryFallbackProps } from './ErrorBoundaryFallback';

type Props = {
  children?: ReactNode;
  fallback: React.FC<ErrorBoundaryFallbackProps>;
};

type State = {
  error: Error | null;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.setState({ error: null });
    this.resetError = this.resetError.bind(this);
  }

  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.warn('Uncaught error : ', error, errorInfo);
  }

  resetError() {
    this.setState({ error: null });
  }

  public render() {
    const { fallback: Fallback, children } = this.props;
    const error = this.state?.error;

    if (error) {
      return <Fallback error={error} resetError={this.resetError} />;
    }
    return children;
  }
}

export default ErrorBoundary;
