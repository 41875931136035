import { isNull } from 'lodash';
import {
  ChallengeCustomField,
  ChallengeSpecialPrizeCustomField,
} from 'src/types/customField.types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'specialPrize'>
> = (customFields) => {
  const specialPrize = customFields.find(({ name }) => name === 'specialPrize');
  if (!specialPrize) return {} as EmptyObj;

  const { values } = specialPrize as ChallengeSpecialPrizeCustomField;

  return {
    specialPrize: {
      prizeType: values.prizeType,
      amount: values.amount,
    },
  };
};

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'specialPrize'>,
  Extract<ChallengeCustomField, { name: 'specialPrize' }>
> = ({ specialPrize }) => {
  const { prizeType, amount } = specialPrize;
  if (isNull(prizeType) || isNull(amount)) return [];

  return [
    {
      name: 'specialPrize',
      values: {
        prizeType,
        amount,
      },
    },
  ];
};
