import {
  DescriptionModuleFormType,
  DescriptionHyperlinkModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

export const validate = (
  descriptionModule: DescriptionModuleFormType[],
): { isValid: true } | { isValid: false; message: string } => {
  const target = descriptionModule.find(({ type }) => type === 'HYPERLINK');
  if (!target) return { isValid: true };

  const { urlText, url, backgroundColor, buttonColor, textColor } =
    target as DescriptionHyperlinkModuleFormType;

  if (
    typeof urlText !== 'string' ||
    typeof url !== 'string' ||
    typeof backgroundColor !== 'string' ||
    typeof buttonColor !== 'string' ||
    typeof textColor !== 'string'
  ) {
    return {
      isValid: false,
      message: '[상세모듈] HYPERLINK 모듈을 확인해주세요',
    };
  }

  if (!urlText.length)
    return {
      isValid: false,
      message: '[상세모듈] HYPERLINK url문구 값을 채워주세요',
    };

  if (urlText.length > 20)
    return {
      isValid: false,
      message: '[상세모듈] HYPERLINK url문구 값은 최대 20자까지만 가능합니다.',
    };

  if (!url.length)
    return {
      isValid: false,
      message: '[상세모듈] HYPERLINK url 값을 채워주세요',
    };

  if (!backgroundColor.length)
    return {
      isValid: false,
      message: '[상세모듈] HYPERLINK 배경색 값을 채워주세요',
    };

  if (!buttonColor.length)
    return {
      isValid: false,
      message: '[상세모듈] HYPERLINK 버튼색 값을 채워주세요',
    };

  if (!textColor.length)
    return {
      isValid: false,
      message: '[상세모듈] HYPERLINK 글씨색 값을 채워주세요',
    };

  return { isValid: true };
};
