import {
  CollaboDescriptionModuleFormType,
  CommonDescriptionModuleFormType,
  SubModuleFormType,
  DescriptionModuleFormType,
} from '../../NewChallengeForm.types';
import { DescriptionModule } from './DescriptionModuleForm.type';
import HyperlinkModule from './components/HyperlinkModule';
import NewInterviewModule from './components/NewInterviewModule';
import FullImageModule from './components/FullImageModule/FullImageModule';
import { CollaboTopSummary } from './components/CollaboTopSummaryModule';
import AdditionalRewardModule from './components/AdditionalRewardModule/AdditionalRewardModule';
import OliveYoungReviewModule from './components/OliveYoungReviewModule';
import SpecialPrizeModule from './components/SpecialPrizeModule';
import SpecialRewardModule from './components/SpecialRewardModule';
import SupportersInstagramModule from './components/SupportersInstagramModule';
import TextOnlyModule from './components/TextOnlyModule';
import GoalModule from './components/GoalModule/GoalModule';
import ChallengeModule from './components/ChallengeModule/ChallengeModule';
import VideoModule from './components/VideoModule';
import NaverSmartStoreModule from './components/NaverSmartStoreModule';
// import KakaoGiftModule from './components/KakaoGiftModule';
import OliveYoungOnlyBuyModule from './components/OliveYoungOnlyBuyModule';

const collaboDescriptionModules: {
  [key in CollaboDescriptionModuleFormType['type']]: DescriptionModule;
} = {
  'COLLABO-OLIVEYOUNG-REVIEW': OliveYoungReviewModule,
  'COLLABO-OLIVEYOUNG-ONLY-BUY': OliveYoungOnlyBuyModule,
  'COLLABO-SUPPORTERS-INSTAGRAM': SupportersInstagramModule,
  'COLLABO-NAVER-SMART-STORE': NaverSmartStoreModule,
  // 'COLLABO-KAKAO-GIFT': KakaoGiftModule,
};

export const commonDescriptionModules: {
  [key in CommonDescriptionModuleFormType['type']]: DescriptionModule;
} = {
  'FULL-IMAGE': FullImageModule,
  'TEXT-ONLY': TextOnlyModule,
  'NEW-INTERVIEW': NewInterviewModule,
  VIDEO: VideoModule,
  HYPERLINK: HyperlinkModule,
  CHALLENGE: ChallengeModule,
  GOAL: GoalModule,
  'COLLABO-SPECIAL-PRIZE': SpecialPrizeModule,
  'COLLABO-SPECIAL-REWARD': SpecialRewardModule,
  'COLLABO-ADDITIONAL-REWARD': AdditionalRewardModule,
  'COLLABO-TOP-SUMMARY': CollaboTopSummary,
};

const subModules: {
  [key in SubModuleFormType['type']]: DescriptionModule;
} = {
  'FULL-IMAGE': FullImageModule,
  VIDEO: VideoModule,
  HYPERLINK: HyperlinkModule,
  'COLLABO-SPECIAL-PRIZE': SpecialPrizeModule,
  'COLLABO-SPECIAL-REWARD': SpecialRewardModule,
  'COLLABO-ADDITIONAL-REWARD': AdditionalRewardModule,
  'COLLABO-TOP-SUMMARY': CollaboTopSummary,
};

export const descriptionModules: {
  [key in DescriptionModuleFormType['type']]: DescriptionModule;
} = {
  ...collaboDescriptionModules,
  ...commonDescriptionModules,
};

export const collaboDescriptionModuleCompList = Object.values(
  collaboDescriptionModules,
);
export const commonDescriptionModuleCompList = Object.values(
  commonDescriptionModules,
);
export const subModuleCompList = Object.values(subModules);
export const descriptionModuleCompList = Object.values(descriptionModules);
