import DateTimeInput from '@component/DateTimeInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { format } from 'date-fns';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './EndDateForm.serializer';
import { useEndDateStore, useUpdateEndDate } from './EndDateForm.store';
import { validate } from './EndDateForm.validator';
import { useEditCheck } from '../../hooks/useEditCheck';

export const EndDateForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'endDate'>,
  Pick<ChallengeFormType, 'endDate'>,
  Pick<ReqCreateOrUpdateChallenge, 'endDate'>
> = () => {
  const { startDate, endDate } = useEndDateStore();
  const { updateEndDate } = useUpdateEndDate();

  const validated = validate({ startDate, endDate });

  const { getBorder, triggerEdited } = useEditCheck();

  const handleChange = (value: string) => {
    if (!value.length) return;

    triggerEdited('endDate');
    updateEndDate(value);
  };

  return (
    <DateTimeInput
      label="챌린지 종료일"
      dateOnly
      value={endDate || format(new Date(), 'yyyy-MM-dd')}
      onChange={handleChange}
      errorMsg={validated.isValid ? '' : validated.message}
      style={{ border: getBorder('endDate') }}
    />
  );
};

EndDateForm.validate = validate;
EndDateForm.convertData2Form = convertData2Form;
EndDateForm.convertForm2Data = convertForm2Data;
