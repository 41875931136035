import RadioSelector from '@component/RadioSelector/RadioSelector';
import Accordion from '@component/Accordion/Accordion';
import { ChallengeCustomField } from 'src/types/customField.types';
import Label from '@component/Label';
import { validate } from './TimestampForm.validator';
import {
  useHideTimestamp,
  useHideTimestampAction,
} from './TimestampForm.store';
import * as S from './TimestampForm.style';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './TimestampForm.serializer';

const options = [
  { label: '숨기기', value: true },
  { label: '노출', value: false },
];

const TimestampForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'timestamp'>,
  Extract<ChallengeCustomField, { name: 'hideTimestamp' }>
> = () => {
  const { hideTimestamp } = useHideTimestamp();
  const { setHideTimestamp } = useHideTimestampAction();

  return (
    <Accordion open={false} title="타임 스탬프 설정" as="h5">
      <S.Container>
        <Label>인증샷 타임스탬프 노출</Label>
        <RadioSelector
          style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
          selectedOption={options.find(({ value }) => value === hideTimestamp)}
          options={options}
          onChange={({ value }) => setHideTimestamp(value)}
        />
      </S.Container>
    </Accordion>
  );
};

export default TimestampForm;
TimestampForm.validate = validate;
TimestampForm.convertData2Form = convertData2Form;
TimestampForm.convertForm2Data = convertForm2Data;
