import FlexBox from '@component/FlexBox/FlexBox';
import TextArea from '@component/TextArea';
import TextInput from '@component/TextInput';
import { FC } from 'react';

type Props = {
  itemDetailName: string;
  itemDetailDescription: string;
  onChangeItemDetailName: (itemDetailName: string) => void;
  onChangeItemDetailNameDescription: (itemDetailDescription: string) => void;
};
const RewardTextForm: FC<Props> = (props) => {
  const {
    itemDetailName,
    itemDetailDescription,
    onChangeItemDetailName,
    onChangeItemDetailNameDescription,
  } = props;

  return (
    <FlexBox.Column>
      <TextArea
        label="추가리워드 아이템 상세"
        value={itemDetailName}
        placeholder="아이템 상세 이름"
        onChange={onChangeItemDetailName}
      />
      <TextInput
        placeholder="아이템 상세 추가정보"
        value={itemDetailDescription}
        onChange={onChangeItemDetailNameDescription}
      />
    </FlexBox.Column>
  );
};

export default RewardTextForm;
