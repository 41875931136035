import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { Dimmer } from 'semantic-ui-react';

const ConfirmPopupComponent = () => {
  const [props, setProps] = useState<ConfirmPopupProps | null>(null);

  useEffect(() => {
    const sub = observable$.subscribe((value) => {
      if (value) {
        setProps(value);
      } else {
        setProps(null);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);

  const onClose = () => {
    setProps(null);
  };

  if (!props) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight,
        zIndex: 9999,
      }}
    >
      <Dimmer active={true} onClickOutside={onClose}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            backgroundColor: 'white',
            padding: '30px 40px 20px 40px',
            borderRadius: 10,
            color: 'black',
          }}
        >
          <div style={{ fontSize: 20, marginBottom: 16, fontWeight: 'bold' }}>
            {props.title}
          </div>
          {props.description && (
            <div
              style={{
                fontSize: 14,
                marginBottom: 32,
                color: '#555',
                lineHeight: 1.5,
              }}
            >
              {typeof props.description === 'string'
                ? props.description
                    ?.split('\n')
                    .map((line, idx) => <div key={idx}>{line}</div>)
                : props.description}
            </div>
          )}
          <div style={{ display: 'flex', gap: '20px' }}>
            <button
              style={{
                backgroundColor: '#EEE',
                color: 'black',
                padding: '16px 20px',
                borderRadius: 12,
                cursor: 'pointer',
                width: '100%',
                border: 'none',
                fontWeight: 'bold',
              }}
              onClick={onClose}
            >
              취소
            </button>
            <button
              style={{
                backgroundColor: 'black',
                color: 'white',
                padding: '16px 20px',
                borderRadius: 12,
                cursor: 'pointer',
                width: '100%',
                border: 'none',
                fontWeight: 'bold',
              }}
              onClick={props.onConfirm}
            >
              {props.confirmText || '확인'}
            </button>
          </div>
        </div>
      </Dimmer>
    </div>
  );
};

type ConfirmPopupProps = {
  title: string;
  description?: string | React.ReactNode;
  confirmText?: string;
  onConfirm: () => void;
};

const observable$ = new Subject<ConfirmPopupProps | null>();

const ConfirmPopup = {
  show: (props: ConfirmPopupProps) => {
    observable$.next(props);
  },
  hide: () => {
    observable$.next(null);
  },
};

export { ConfirmPopupComponent };

export default ConfirmPopup;
