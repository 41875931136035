import React, { useCallback, useState } from 'react';
import { Table, Button, Modal } from 'semantic-ui-react';
import _ from 'lodash';
import { apis } from '../../../api';
import { GoalCategoryWithRestrict } from '@types';
import GoalCategoryCreateOrEditModal from '../GoalCategoryCreateOrEditModal/GoalCategoryCreateOrEditModal';

const GoalCategoryItem = (props: GoalCategoryItemProps) => {
  const { goalCategory, remove, update } = props;

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const showEditModal = useCallback(() => setEditModalOpen(true), []);
  const closeEditModal = useCallback(() => setEditModalOpen(false), []);

  const showDeleteModal = useCallback(() => setDeleteModalOpen(true), []);
  const closeDeleteModal = useCallback(() => setDeleteModalOpen(false), []);

  const updateGoalCategory = async (gc: GoalCategoryWithRestrict) => {
    const res = await apis.challenge.updateGoalCategory(gc.id, {
      level1: gc.level1,
      level2: gc.level2,
      level3: gc.level3,
      level4: gc.level4,
      restrictGoalCategoryIds: gc.restrictGoalCategories.map((o) => o.id),
    });
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    update(gc);

    closeEditModal();
  };

  const deleteGoalCategory = async () => {
    if (!goalCategory) return;
    const res = await apis.challenge.deleteGoalCategory(goalCategory.id);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    alert('목표 카테고리가 삭제되었어요.');

    remove(goalCategory);

    closeDeleteModal();
  };

  return (
    <>
      <Table.Row>
        <Table.Cell>{goalCategory.id}</Table.Cell>
        <Table.Cell>{goalCategory.level1}</Table.Cell>
        <Table.Cell>{goalCategory.level2}</Table.Cell>
        <Table.Cell>{goalCategory.level3}</Table.Cell>
        <Table.Cell>{goalCategory.level4}</Table.Cell>
        <Table.Cell>
          {_.map(goalCategory.restrictGoalCategories, (gc) => (
            <p key={gc.id}>
              [{gc.id}]{gc.level1} | {gc.level2} | {gc.level3} | {gc.level4}
            </p>
          ))}
        </Table.Cell>
        <Table.Cell>
          <Button size="tiny" onClick={showEditModal}>
            편집
          </Button>
          <Button size="tiny" onClick={showDeleteModal}>
            삭제
          </Button>
        </Table.Cell>
      </Table.Row>

      <Modal size="tiny" open={deleteModalOpen} onClose={closeDeleteModal}>
        <Modal.Header>목표 카테고리 삭제</Modal.Header>
        <Modal.Content>
          <p>
            {goalCategory.level1} / {goalCategory.level2} /{' '}
            {goalCategory.level3} / {goalCategory.level4}
          </p>
          <p>위의 목표 카테고리를 삭제하시겠습니까?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="삭제" onClick={deleteGoalCategory} />
          <Button color="black" content="닫기" onClick={closeDeleteModal} />
        </Modal.Actions>
      </Modal>

      <Modal size="tiny" open={deleteModalOpen} onClose={closeDeleteModal}>
        <Modal.Header>목표 카테고리 삭제</Modal.Header>
        <Modal.Content>
          <p>
            [{goalCategory.id}] {goalCategory.level1} / {goalCategory.level2} /{' '}
            {goalCategory.level3} / {goalCategory.level4}
          </p>
          <p>위의 목표 카테고리를 삭제하시겠습니까?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="삭제" onClick={deleteGoalCategory} />
          <Button color="black" content="닫기" onClick={closeDeleteModal} />
        </Modal.Actions>
      </Modal>

      {editModalOpen && (
        <GoalCategoryCreateOrEditModal
          goalCategory={goalCategory}
          handleOnClose={closeEditModal}
          handleOnSubmit={updateGoalCategory}
        />
      )}
    </>
  );
};

export default GoalCategoryItem;

type GoalCategoryItemProps = {
  goalCategory: GoalCategoryWithRestrict;
  remove: (gc: GoalCategoryWithRestrict) => void;
  update: (gc: GoalCategoryWithRestrict) => void;
};
