import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

const Preview = () => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Accordion style={{ width: '100%' }}>
      <Accordion.Title
        active={!collapsed}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Icon name="dropdown" />
        미리보기
      </Accordion.Title>
      <Accordion.Content active={!collapsed}>
        <img src="/race/스탬프1.png" width={300} alt="스탬프1" />
        <img src="/race/스탬프2.png" width={300} alt="스탬프2" />
      </Accordion.Content>
    </Accordion>
  );
};

export default Preview;
