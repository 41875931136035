import DateTimeInput from '@component/DateTimeInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { formatLocalDateTime, formatTimeWithSeconds } from '@utils/date.utils';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './RegisterStartDateForm.serializer';
import {
  useRegisterDateTime,
  useRegisterStartDateTimeActions,
} from './RegisterStartDateForm.store';
import { validate } from './RegisterStartDateForm.validator';
import { useEditCheck } from '../../hooks/useEditCheck';
import { useQuery } from '@tanstack/react-query';
import { apis } from '@api/index';
import { useParams } from 'react-router-dom';

const RegisterStartDateForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'registerStartDate'>,
  Pick<ChallengeFormType, 'registerStartDate'>,
  Pick<ReqCreateOrUpdateChallenge, 'registerStartDate'>
> = () => {
  const { id } = useParams<{ id: string }>();
  const challengeId = Number(id);

  const { registerStartDate, registerEndDate } = useRegisterDateTime();

  const { setRegisterStartDateTime } = useRegisterStartDateTimeActions();

  const validated = validate({ registerStartDate, registerEndDate });

  const { getBorder, triggerEdited } = useEditCheck();

  const { data: preAlarmData } = useQuery({
    queryKey: ['preAlarm', challengeId],
    queryFn: async () => {
      const res = await apis.preAlarm.getPreAlarmByServiceInfo({
        serviceType: 'CHALLENGE',
        serviceId: challengeId,
      });

      return res.preAlarm ?? null;
    },
    enabled: !!challengeId,
  });

  const handleChange = (value: string) => {
    if (!value.length) return;

    triggerEdited('registerStartDate');
    setRegisterStartDateTime(formatTimeWithSeconds(new Date(value)));
  };

  return (
    <DateTimeInput
      label="모집시작일"
      disabled={preAlarmData?.isSent ?? false}
      value={registerStartDate || formatLocalDateTime(new Date())}
      errorMsg={validated.isValid ? '' : validated.message}
      onChange={handleChange}
      style={{ border: getBorder('registerStartDate') }}
      onClick={() => {
        if (preAlarmData?.isSent) {
          alert('사전알림이 발송되어 수정이 불가능합니다.');
        }
      }}
    />
  );
};

export default RegisterStartDateForm;

RegisterStartDateForm.validate = validate;
RegisterStartDateForm.convertData2Form = convertData2Form;
RegisterStartDateForm.convertForm2Data = convertForm2Data;
