import React from 'react';
import { Button } from 'semantic-ui-react';
import Workbook from 'react-excel-workbook';
import moment from 'moment';

const PointInfoExcel = ({ excelData, startDate, endDate }) => {
  const filename =
    moment(startDate).format('YYYYMMDD') +
    '-' +
    moment(endDate).format('YYYYMMDD') +
    '-point.xlsx';
  return (
    <Workbook
      filename={filename}
      element={
        <Button basic color="green" style={styles.button}>
          포인트 현황 다운로드
        </Button>
      }
    >
      <Workbook.Sheet data={excelData} name="sheet1">
        <Workbook.Column label="포인트종류" value={(p) => p.pointType} />
        <Workbook.Column label="항목명" value={(p) => p.name} />
        <Workbook.Column label="값" value={(p) => p.value} />
      </Workbook.Sheet>
    </Workbook>
  );
};

export default PointInfoExcel;

const styles = {
  button: {
    marginTop: 5,
  },
};
