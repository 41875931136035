import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';
import { hasDuplicatedAchievementIndex } from '../../NewChallengeForm.util';

export const validate: FormValidator<
  Pick<
    ChallengeFormType,
    'naverBlogInfoList' | 'purchaseInfo' | 'instagramInfoList'
  > & { achievementCount: number }
> = ({
  naverBlogInfoList,
  achievementCount,
  purchaseInfo,
  instagramInfoList,
}) => {
  for (let i = 0; i < naverBlogInfoList.length; i++) {
    const info = naverBlogInfoList[i];
    if (info.achievementIndexList.length === 0) {
      return {
        isValid: false,
        message: `[네이버블로그] 인증 회차 정보는 필수입니다.`,
      };
    }

    if (info.hashTagList.length === 0) {
      return {
        isValid: false,
        message: `[네이버블로그] 해시태그 정보는 필수입니다.`,
      };
    }

    if (info.checklist.length === 0) {
      return {
        isValid: false,
        message: `[네이버블로그] 체크리스트 정보는 필수입니다.`,
      };
    }

    if (info.achievementIndexList.find((index) => index > achievementCount)) {
      return {
        isValid: false,
        message: `[네이버블로그] 인증회차는 인증횟수보다 클 수 없습니다.`,
      };
    }
  }

  if (
    hasDuplicatedAchievementIndex({
      instagramInfoList,
      naverBlogInfoList,
      purchaseInfo,
    })
  ) {
    return {
      isValid: false,
      message: `[네이버블로그] 인증 회차 정보는 중복될 수 없습니다.`,
    };
  }

  return { isValid: true };
};
