import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import TextInput from '@component/TextInput';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './OriginalPriceForm.serializer';
import {
  useOriginalPrice,
  useOriginalPriceActions,
} from './OriginalPriceForm.store';
import { validate } from './OriginalPriceForm.validator';

const OriginalPriceForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'originalPrice'>,
  Pick<ChallengeFormType, 'originalPrice'>,
  Pick<ReqCreateOrUpdateChallenge, 'originalPrice'>
> = () => {
  const { originalPrice } = useOriginalPrice();

  const { setOriginalPrice } = useOriginalPriceActions();

  const validated = validate({ originalPrice });

  return (
    <>
      <TextInput
        label="정가"
        value={originalPrice?.toString()}
        onChange={setOriginalPrice}
        errorMsg={!validated.isValid ? validated.message : ''}
      />
    </>
  );
};

export default OriginalPriceForm;

OriginalPriceForm.validate = validate;
OriginalPriceForm.convertData2Form = convertData2Form;
OriginalPriceForm.convertForm2Data = convertForm2Data;
