import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '@container/Challenge/NewChallengeForm/NewChallengeForm.store';

export const useSpecialPrizeModuleStore = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      specialPrize: s.challenge.specialPrize,
      resultDate: s.challenge.resultDate,
    })),
  );
