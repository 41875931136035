import React from 'react';
import {
  Breadcrumb,
  BreadcrumbSectionProps,
  Button,
  Dropdown,
  DropdownProps,
  Grid,
  Select,
} from 'semantic-ui-react';
import _ from 'lodash';
import { DIVIDE_SIZE, QuestionSortType } from '../Question';
import {
  QuestionChallengeOption,
  QuestionMainCategory,
  QuestionSubCategory,
} from '@types';

type FeedControlProps = {
  isAnswerFilter: 'notAnswered' | 'answered';
  handleFilter: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: BreadcrumbSectionProps,
  ) => void;
  filteredQuestionsLength: number;
  mainCategories: QuestionMainCategory[];
  activeMainCategory: string;
  handleCategoryRadioChange: any;
  subCategories: QuestionSubCategory[];
  handleTagRadioChange: (e: any, data: any) => void;
  questionAllTagOptionsWithNumber: any;
  challengeOptions: QuestionChallengeOption[];
  setSelectedChallengeId: React.Dispatch<React.SetStateAction<number | ''>>;
  handleDivideGroup: (o: number) => void;
  divideGroup: number;
  answerQuestionList: () => void;
  onRefreshQuestionPress: () => void;
  selectedSubCategory: string;
  selectedChallengeId: number | '';
  setSortType: React.Dispatch<React.SetStateAction<QuestionSortType>>;
};

function FeedControl({
  isAnswerFilter,
  handleFilter,
  filteredQuestionsLength,
  mainCategories,
  activeMainCategory,
  handleCategoryRadioChange,
  subCategories,
  handleTagRadioChange,
  questionAllTagOptionsWithNumber,
  challengeOptions,
  setSelectedChallengeId,
  handleDivideGroup,
  divideGroup,
  answerQuestionList,
  onRefreshQuestionPress,
  selectedSubCategory,
  selectedChallengeId,
  setSortType,
}: FeedControlProps) {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Breadcrumb style={styles.breadcrumb}>
          <Breadcrumb.Section
            name="notAnswered"
            value="notAnswered"
            active={isAnswerFilter === 'notAnswered'}
            onClick={handleFilter}
          >
            미답변
            {isAnswerFilter === 'notAnswered' && `(${filteredQuestionsLength})`}
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section
            name="answered"
            value="answered"
            active={isAnswerFilter === 'answered'}
            onClick={handleFilter}
          >
            답변완료
          </Breadcrumb.Section>
        </Breadcrumb>
        <Breadcrumb style={styles.breadcrumb}>
          {mainCategories.map(
            (
              category: {
                value: string;
                text: string;
              },
              idx: number,
            ) => (
              <React.Fragment key={category.value}>
                {idx > 0 && <Breadcrumb.Divider />}
                <Breadcrumb.Section
                  name={'mainCategory'}
                  value={category.value}
                  active={activeMainCategory === category.value}
                  onClick={handleCategoryRadioChange}
                >
                  {category.text}
                </Breadcrumb.Section>
              </React.Fragment>
            ),
          )}
        </Breadcrumb>
      </div>
      <Grid columns={2}>
        <Grid.Column>
          <div style={{ display: 'flex', marginTop: 10 }}>
            <Breadcrumb style={styles.breadcrumb}>
              {_.range(-1, DIVIDE_SIZE).map((o) => (
                <React.Fragment key={o}>
                  {o >= 0 && <Breadcrumb.Divider />}
                  <Breadcrumb.Section
                    active={divideGroup === o}
                    onClick={() => handleDivideGroup(o)}
                  >
                    {o < 0 ? '전체' : o + 1}그룹
                  </Breadcrumb.Section>
                </React.Fragment>
              ))}
            </Breadcrumb>
          </div>
        </Grid.Column>
        <Grid.Column>
          <div
            style={{
              display: 'flex',
              textAlign: 'right',
              justifyContent: 'flex-end',
            }}
          >
            <Dropdown
              placeholder={selectedSubCategory || '서브 카테고리'}
              search
              selection
              clearable
              name="subCategory"
              options={subCategories}
              value={selectedSubCategory}
              onChange={handleCategoryRadioChange}
            />
            <Dropdown
              placeholder="태그"
              search
              selection
              clearable
              name="questionTags"
              options={questionAllTagOptionsWithNumber}
              onChange={handleTagRadioChange}
            />
            <Dropdown
              placeholder={
                challengeOptions.find((o) => o.value === selectedChallengeId)
                  ?.text || '챌린지'
              }
              search
              selection
              clearable
              name="challenge"
              options={challengeOptions}
              value={selectedChallengeId}
              onChange={(e, data: DropdownProps) => {
                setSelectedChallengeId(data.value as number);
              }}
            />
          </div>
        </Grid.Column>
      </Grid>
      <div
        style={{
          display: 'flex',
          marginTop: 10,
          justifyContent: 'space-between',
        }}
      >
        <Dropdown
          options={[
            {
              key: QuestionSortType.LATEST,
              value: QuestionSortType.LATEST,
              text: '최신순',
            },
            {
              key: QuestionSortType.OLDEST,
              value: QuestionSortType.OLDEST,
              text: '오래된 순',
            },
            {
              key: QuestionSortType.END_DATE,
              value: QuestionSortType.END_DATE,
              text: '종료 임박순',
            },
            {
              key: QuestionSortType.RESULT_DATE,
              value: QuestionSortType.RESULT_DATE,
              text: '결과 임박순',
            },
          ]}
          selection
          defaultValue={QuestionSortType.LATEST}
          onChange={(e, data) => {
            setSortType(data.value as QuestionSortType);
          }}
        />
        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <Button size="mini" onClick={onRefreshQuestionPress}>
            문의 새로고침
          </Button>
          <Button size="mini" onClick={answerQuestionList}>
            일괄답변
          </Button>
        </div>
      </div>
    </>
  );
}

export default FeedControl;

const styles = {
  cardSummary: {
    marginBottom: 5,
  },
  breadcrumb: {
    marginBottom: 10,
    marginRight: 10,
  },
  feed: {
    marginTop: 10,
  },
  button: {
    marginTop: 5,
  },
};
