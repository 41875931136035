import {
  DescriptionCollaboAdditionalRewardModuleFormType,
  DescriptionModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import {
  DescriptionModule,
  DescriptionModuleConvertData2Form,
  DescriptionModuleConvertForm2Data,
} from '../../DescriptionModuleForm.type';
import { DEFAULT_DATA } from './AdditionalRewardModule.constant';
import {
  convertData2Form,
  convertForm2Data,
} from './AdditionalRewardModule.serializer';
import { validate } from './AdditionalRewardModule.validator';
import ItemDetailForm from './components/ItemDetailForm/ItemDetailForm';
import NoticeListForm from './components/NoticeListForm/NoticeListForm';
import RewardImageForm from './components/RewardImageForm/RewardImageForm';
import ItemForm from './components/ItemName/ItemName';
import ReceiverForm from './components/ReceiverForm/ReceiverForm';

type AdditionalRewardModuleProps = {
  type: 'COLLABO-ADDITIONAL-REWARD';
  data: DescriptionCollaboAdditionalRewardModuleFormType['data'];
  moduleIndex: number;
  onUpdate: (index: number, item: DescriptionModuleFormType) => void;
};
const AdditionalRewardModule: DescriptionModule<AdditionalRewardModuleProps> = (
  props: AdditionalRewardModuleProps,
) => {
  const { type, data, moduleIndex, onUpdate } = props;

  const handleUpdate = (
    updated: Partial<DescriptionCollaboAdditionalRewardModuleFormType['data']>,
  ) => {
    onUpdate(moduleIndex, { type, data: { ...data, ...updated } });
  };

  return (
    <div style={{ border: '1px solid black', maxWidth: 720 }}>
      <ReceiverForm to={data.to} onChange={(to) => handleUpdate({ to })} />
      <hr />

      <ItemForm
        itemName={data.itemName}
        onChange={(itemName) => handleUpdate({ itemName })}
      />
      <hr />

      <RewardImageForm
        image={data.image}
        onChange={(image) => handleUpdate({ image })}
      />
      <hr />

      <ItemDetailForm
        itemDetailName={data.itemDetailName}
        itemDetailDescription={data.itemDetailDescription}
        onChangeItemDetailName={(itemDetailName) =>
          handleUpdate({ itemDetailName })
        }
        onChangeItemDetailNameDescription={(itemDetailDescription) =>
          handleUpdate({ itemDetailDescription })
        }
      />
      <hr />

      <NoticeListForm
        noticeList={data.noticeList}
        onChange={(noticeList) => handleUpdate({ noticeList })}
      />
    </div>
  );
};

export default AdditionalRewardModule;

AdditionalRewardModule.moduleName = '추가 리워드';
AdditionalRewardModule.defaultData = DEFAULT_DATA;
AdditionalRewardModule.validate = validate;
AdditionalRewardModule.convertForm2Data =
  convertForm2Data as DescriptionModuleConvertForm2Data;
AdditionalRewardModule.convertData2Form =
  convertData2Form as DescriptionModuleConvertData2Form;
