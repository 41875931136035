import { isNull } from '@utils/js.utils';
import {
  ChallengeCustomField,
  ChallengeSupportersCustomField,
} from 'src/types/customField.types';
import { format } from 'date-fns';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'supporters'>,
  Extract<ChallengeCustomField, { name: 'supporters' }>
> = ({ supporters }) => {
  const {
    hasDelivery,
    benefit,
    userLimit,
    selectionResultDate,
    isSelectionDone,
    gender,
    age,
    category,
    ...weights
  } = supporters;
  if (
    isNull(hasDelivery) ||
    isNull(benefit) ||
    isNull(userLimit) ||
    isNull(selectionResultDate)
  )
    return [];

  return [
    {
      name: 'supporters',
      values: {
        hasDelivery,
        benefit,
        userLimit,
        selectionResultDate: format(
          new Date(selectionResultDate),
          "yyyy-MM-dd'T'HH:mm:ss",
        ),
        isSelectionDone: isSelectionDone || false,
        gender,
        age,
        category,
        ...weights,
      },
    },
  ];
};

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'supporters'>
> = (customFields) => {
  const supporters = customFields.find(({ name }) => name === 'supporters');
  if (!supporters) return {} as EmptyObj;

  const { values } = supporters as ChallengeSupportersCustomField;

  return {
    supporters: values,
  };
};
