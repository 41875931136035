import React from 'react';
import moment from 'moment';
import { Button, Modal, Table } from 'semantic-ui-react';

export const NotificationModal = ({
  notificationModalOpen,
  closeNotificationModal,
  notifications,
}) => {
  return (
    <Modal
      size="tiny"
      open={notificationModalOpen}
      onClose={closeNotificationModal}
    >
      <Modal.Header>알림 내역</Modal.Header>
      <Modal.Content>
        <Table basic="very" size="small" sortable>
          <Table.Body>
            {notifications.map((notification, idx) => {
              return (
                <Table.Row key={notification.id}>
                  <Table.Cell>{notification.notificationText}</Table.Cell>
                  <Table.Cell>
                    {moment(notification.createdAt)
                      .add(9, 'h')
                      .format('YYYY-MM-DD HH:mm:ss')}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" content="닫기" onClick={closeNotificationModal} />
      </Modal.Actions>
    </Modal>
  );
};

export default NotificationModal;
