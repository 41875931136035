import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { CustomFieldFormType } from '../../NewChallengeForm.types';

export const useCreatorStore = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      creator: s.challenge.creator,
    })),
  );

export const useCreatorAction = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const update = <T extends keyof CustomFieldFormType['creator']>(
    fieldName: T,
    value: CustomFieldFormType['creator'][T],
  ) => {
    updateChallengeForm((prev) => ({
      ...prev,
      creator: {
        ...prev.creator,
        [fieldName]: value,
      },
    }));
  };

  const setType = (type: 'COACHING' | 'TOGETHER' | null) =>
    update('type', type);
  const setDefaultCommercePrice = (defaultCommercePrice: number | null) =>
    update('defaultCommercePrice', defaultCommercePrice);

  return {
    setType,
    setDefaultCommercePrice,
  };
};
