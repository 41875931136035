import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Header,
  Table,
  Checkbox,
  Image,
  Icon,
  Grid,
} from 'semantic-ui-react';
import _ from 'lodash';
import { apis } from '../../../api';
import ContentModal from './ContentModal';
import { getGoalOptions } from '../../../utils/dropdownOptions';
import MarketingMenu from '../MarketingMenu';
import { ContentInfo } from '@types';
import { ModalType } from 'src/types/common.types';

const Content = () => {
  const [contents, setContents] = useState<ContentInfo[]>([]);
  const [content, setContent] = useState<ContentInfo>();
  const [modalType, setModalType] = useState<ModalType>('ADD');
  const [modalOpen, setModalOpen] = useState(false);
  const [goalOptions, setGoalOptions] = useState([]);

  useEffect(() => {
    _getContents();
    _getGoalOptions();
  }, []);

  const _getContents = async () => {
    const { contents: _contents } = await apis.common.getContents({
      offset: 0,
      limit: 100,
    });
    setContents(_contents);
  };

  const _getGoalOptions = async () => {
    const _goalOptions = await getGoalOptions({ isOfficial: 'True' });
    setGoalOptions(_goalOptions);
  };

  const showContentModal = (
    _modalType: ModalType,
    selectedContent: ContentInfo | undefined,
  ) => {
    const _content = _modalType === 'ADD' ? undefined : selectedContent;
    setModalType(_modalType);
    setContent(_content);
    setModalOpen(true);
  };

  const closeContentModal = useCallback(() => {
    setContent(undefined);
    setModalOpen(false);
  }, []);

  const addContent = (newContent: ContentInfo) => {
    setContents([newContent, ...contents]);
    setContent(undefined);
    closeContentModal();
  };

  const editContent = (newContent: ContentInfo) => {
    const newContents = [...contents].map((item) =>
      item.id === newContent.id ? newContent : item,
    );
    setContents(newContents);
    setContent(undefined);
    closeContentModal();
  };

  const handleContentChangeOrder = async (name: string, index: number) => {
    const newContents = [...contents];
    const removed = newContents.splice(index, 1);
    if (name === 'up') newContents.splice(index - 1, 0, removed[0]);
    else newContents.splice(index + 1, 0, removed[0]);

    setContents(newContents);

    const contentIds = _.map(newContents, (c) => c.id);
    await apis.common.updateContentPriority({ ids: contentIds });
  };

  const handleContentChangeDisplay = async (
    contentId: number,
    newValue: boolean,
  ) => {
    const res = await apis.common.updateContent(contentId, {
      isDisplayed: newValue,
    });
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    const newContents = contents.map((c) => {
      if (c.id === contentId) {
        return { ...c, isDisplayed: newValue };
      }
      return { ...c };
    });
    setContents(newContents);
  };

  const deleteContent = async (contentId: number) => {
    const res = await apis.common.deleteContent(contentId);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    const newContents = _.filter(contents, (item) => item.id !== contentId);
    setContents(newContents);

    alert('컨텐츠가 삭제되었어요.');
  };

  const copyContent = async (id: number) => {
    const res = await apis.common.copyContent(id);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    const { content: _content } = res;
    const newContents = [_content, ...contents];
    setContents(newContents);
    alert('복사가 완료되었어요.');
  };

  return (
    <div style={{ margin: 40 }}>
      <Grid columns="equal">
        <Grid.Column width={2}>
          <MarketingMenu />
        </Grid.Column>
        <Grid.Column>
          <Header as="h3">
            컨텐츠 관리
            <Button
              size="tiny"
              onClick={() => showContentModal('ADD', undefined)}
            >
              추가
            </Button>
          </Header>

          <Table basic="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>id</Table.HeaderCell>
                <Table.HeaderCell>제목</Table.HeaderCell>
                <Table.HeaderCell>이미지</Table.HeaderCell>
                <Table.HeaderCell>베스트 제목</Table.HeaderCell>
                <Table.HeaderCell>베스트 부제</Table.HeaderCell>
                <Table.HeaderCell>베스트 골</Table.HeaderCell>
                <Table.HeaderCell>큐레이션 제목</Table.HeaderCell>
                <Table.HeaderCell>큐레이션 부제</Table.HeaderCell>
                <Table.HeaderCell>유저id</Table.HeaderCell>
                <Table.HeaderCell>노출여부</Table.HeaderCell>
                <Table.HeaderCell>수정/복사/삭제</Table.HeaderCell>
                <Table.HeaderCell>순서</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {contents.map((c, idx) => {
                return (
                  <Table.Row key={idx}>
                    <Table.Cell>{c.id}</Table.Cell>
                    <Table.Cell>{c.title}</Table.Cell>
                    <Table.Cell>
                      <Image size="tiny" src={c.imageUrl} />
                    </Table.Cell>
                    <Table.Cell>{c.bestCurationTitle}</Table.Cell>
                    <Table.Cell>{c.bestCurationSubtitle}</Table.Cell>
                    <Table.Cell>{c.bestCurationGoalId}</Table.Cell>
                    <Table.Cell>{c.curationTitle}</Table.Cell>
                    <Table.Cell>{c.curationSubtitle}</Table.Cell>
                    <Table.Cell>{c.userId}</Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={c.isDisplayed}
                        onChange={() =>
                          handleContentChangeDisplay(c.id, !c.isDisplayed)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        size="mini"
                        color="blue"
                        name="EDIT"
                        content="수정"
                        onClick={() => showContentModal('EDIT', c)}
                      />
                      <Button
                        size="mini"
                        content="복사"
                        onClick={() => {
                          copyContent(c.id);
                        }}
                      />
                      <Button
                        size="mini"
                        color="black"
                        content="삭제"
                        onClick={() => deleteContent(c.id)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        icon
                        size="mini"
                        value={idx}
                        onClick={() => handleContentChangeOrder('up', idx)}
                      >
                        <Icon name="angle up" />
                      </Button>
                      <Button
                        icon
                        size="mini"
                        value={idx}
                        onClick={() => handleContentChangeOrder('down', idx)}
                      >
                        <Icon name="angle down" />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>

          <ContentModal
            contentModalOpen={modalOpen}
            content={content}
            modalType={modalType}
            goalOptions={goalOptions}
            closeContentModal={closeContentModal}
            addContent={addContent}
            editContent={editContent}
          />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Content;
