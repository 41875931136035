import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import {
  DescriptionModuleFormType,
  NaverSmartStoreModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { DEFAULT_DATA } from './NaverSmartStoreModule.constant';
import {
  DescriptionModule,
  DescriptionModuleConvertData2Form,
  DescriptionModuleConvertForm2Data,
} from '../../DescriptionModuleForm.type';
import { validate } from './NaverSmartStoreModule.validator';
import {
  convertData2Form,
  convertForm2Data,
} from './NaverSmartStoreModule.serializer';
import PurchaseRequiredTextListForm from './components/PurchaseRequiredTextListForm/PurchaseRequiredTextListForm';
import ProductInfoForm from './components/ProductInfoForm/ProductInfoForm';
import ReviewInfoForm from './components/ReviewInfoForm/ReviewInfoForm';
import TextArea from '@component/TextArea';
import { cropAndUploadImage } from '@utils/image.utils';
import PurchaseCheckTextListForm from './components/PurchaseCheckTextListForm/PurchaseCheckTextListForm';

type NaverSmartStoreModuleProps = {
  type: 'COLLABO-NAVER-SMART-STORE';
  data: NaverSmartStoreModuleFormType;
  moduleIndex: number;
  onUpdate: (index: number, item: DescriptionModuleFormType) => void;
};
const NaverSmartStoreModule: DescriptionModule<NaverSmartStoreModuleProps> = (
  props: NaverSmartStoreModuleProps,
) => {
  const { type, data, moduleIndex, onUpdate } = props;

  const handleChangePurchaseRequiredTextList = (
    purchaseRequiredTextList: NaverSmartStoreModuleFormType['purchaseRequiredTextList'],
  ) => {
    onUpdate(moduleIndex, {
      type,
      data: {
        ...data,
        purchaseRequiredTextList,
      },
    });
  };

  const handlePleaseCheckTextList = (
    pleaseCheckTextList: NaverSmartStoreModuleFormType['pleaseCheckTextList'],
  ) => {
    onUpdate(moduleIndex, {
      type,
      data: {
        ...data,
        pleaseCheckTextList,
      },
    });
  };

  const handleChange = (updated: Partial<NaverSmartStoreModuleFormType>) =>
    onUpdate(moduleIndex, {
      type,
      data: {
        ...data,
        ...updated,
      },
    });

  const handleReviewGuideChange = (value: string) => {
    onUpdate(moduleIndex, {
      type,
      data: {
        ...data,
        reviewGuide: value,
      },
    });
  };

  const handleChangeImage: React.ChangeEventHandler<HTMLInputElement> = async (
    e,
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const image = document.createElement('img');
    image.src = URL.createObjectURL(file);

    const urls = await cropAndUploadImage(file);
    onUpdate(moduleIndex, {
      type,
      data: {
        ...data,
        collaboImageUrls: urls,
      },
    });
    e.target.value = '';
  };

  return (
    <FlexBox.Column
      style={{ border: '1px solid black', maxWidth: 720, padding: 12 }}
    >
      <Label>{type}</Label>
      <hr />
      <Label size="large" style={{ marginTop: 20 }}>
        제품 상세이미지(이미지첨부)
      </Label>
      <FlexBox.Row gap={8} alignItems="center" style={{ padding: '12px 0px' }}>
        <Label style={{ width: 120 }}>제휴사 이미지</Label>
        <input type="file" onChange={handleChangeImage} />
      </FlexBox.Row>
      <FlexBox.Row style={{ flexWrap: 'wrap' }}>
        {data?.collaboImageUrls?.map((url, i) => (
          <img key={i} src={url} style={{ width: 100 }} alt={`제품상세 ${i}`} />
        ))}
      </FlexBox.Row>
      <hr />
      <hr />
      <PurchaseRequiredTextListForm
        data={data.purchaseRequiredTextList}
        onChange={handleChangePurchaseRequiredTextList}
      />
      <hr />
      <ProductInfoForm data={data} onChange={handleChange} />
      <hr />
      <Label size="large" style={{ marginTop: 20 }}>
        리뷰 가이드
      </Label>
      <div style={{ marginTop: 6 }}>
        (강조하고 싶은 부분은 반드시 양쪽을 {"''"}로 묶어주시고 줄바꿈을 하려면
        enter를 해주세요 )
      </div>
      <FlexBox.Row gap={8} alignItems="center" style={{ padding: '12px 0px' }}>
        <Label style={{ width: 80 }}>가이드 내용</Label>
        <TextArea
          value={data.reviewGuide}
          onChange={handleReviewGuideChange}
          containerStyle={{ flex: 1 }}
        />
      </FlexBox.Row>
      <hr />
      <ReviewInfoForm data={data} onChange={handleChange} />
      <hr />
      <PurchaseCheckTextListForm
        data={data.pleaseCheckTextList}
        onChange={handlePleaseCheckTextList}
      />
      <hr />
    </FlexBox.Column>
  );
};

export default NaverSmartStoreModule;

NaverSmartStoreModule.moduleName = '네이버 스마트스토어';
NaverSmartStoreModule.defaultData = DEFAULT_DATA;
NaverSmartStoreModule.validate = validate;
NaverSmartStoreModule.convertForm2Data =
  convertForm2Data as DescriptionModuleConvertForm2Data;
NaverSmartStoreModule.convertData2Form =
  convertData2Form as DescriptionModuleConvertData2Form;
