import React, { Component } from 'react';
import {
  Button,
  Container,
  Form,
  Grid,
  Loader,
  Modal,
  Search,
  Table,
} from 'semantic-ui-react';
import api, { apis } from '../../../api/index';
import GoalInfo from './GoalInfo';
import _ from 'lodash';
import moment from 'moment';

const dayString = ['일', '월', '화', '수', '목', '금', '토'];

class GoalItemDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goal: {},
      isMounted: false,
      reviews: [],
      avgRate: 0,
      achievements: [],
      modalOpen: false,
    };
  }

  async componentDidMount() {
    if (this.props.goal) {
      this.setState({ goal: this.props.goal, isMounted: true });
    } else {
      const { goal } = await apis.challenge.getGoalDetail(
        this.props.match.params.id,
      );
      this.setState({ goal, isMounted: true });
    }
  }

  handleDisplayChange = (e, { value }) => {
    const { reviews } = this.state;
    const review = _.find(reviews, (r) => r.comment.id === value);
    review.comment.isDisplayed = !review.comment.isDisplayed;
    this.setState({ reviews });
    api
      .put('/challenge-reviews/' + value, {
        isDisplayed: review.comment.isDisplayed,
      })
      .then((response) => {
        const review = response.data.data.challengeReview;
        console.log(review);
      });
  };

  _showModal = () =>
    this.setState({ modalOpen: true }, this._checkAchievementMetaInfo);
  _closeModal = () => this.setState({ modalOpen: false });

  _checkAchievementMetaInfo = async () => {
    const { goal } = this.state;
    const { achievementInfoList, challenge } = this.props;
    const { startDate, endDate } = challenge;

    // 상시골인 경우
    if (goal?.cycle?.length === 1 && goal?.cycle[0] === 'CUSTOM') {
      if (
        achievementInfoList.length === 0 ||
        achievementInfoList.every(
          (info) =>
            !info.dueStartDate ||
            !info.dueEndDate ||
            !info.dueStartTime ||
            !info.dueEndTime,
        )
      ) {
        return alert(
          '상시골인데, 회차별 인증 시간 설정이 되어있지 않습니다.😭',
        );
      } else {
        const newAchievements = achievementInfoList.map((info, index) => ({
          nthTerm: index + 1,
          dueStartDate: info.dueStartDate,
          dueStartTime: info.dueStartTime,
          dueEndDate: info.dueEndDate,
          dueEndTime: info.dueEndTime,
        }));
        this.setState({ achievements: newAchievements });
      }
    } else {
      const { achievements } = await apis.checkAchievementCardList({
        goalId: this.props.goal.id,
        startDate,
        endDate,
      });
      this.setState({ achievements });
    }
  };

  render() {
    const { isMounted, modalOpen, achievements } = this.state;
    if (!isMounted) {
      return <Loader active inline="centered" />;
    }
    const { goal } = this.props.goal ? this.props : this.state;
    return (
      <Container style={styles.container}>
        <Grid columns="equal">
          <Grid.Column />
          <Grid.Column width={10}>
            <GoalInfo goal={goal} showModal={this._showModal} />
          </Grid.Column>
          <Grid.Column />
        </Grid>

        <Modal size="tiny" open={modalOpen} onClose={this._closeModal}>
          <Modal.Header>인증카드 목록 미리보기</Modal.Header>
          <Modal.Content>
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>NO</Table.HeaderCell>
                  <Table.HeaderCell>인증시작일</Table.HeaderCell>
                  <Table.HeaderCell>인증시작시간</Table.HeaderCell>
                  <Table.HeaderCell>인증마감일</Table.HeaderCell>
                  <Table.HeaderCell>인증종료시간</Table.HeaderCell>
                  <Table.HeaderCell>회차</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {achievements.map((item, idx) => (
                  <Table.Row key={`achievement-${item.id}-${idx}`}>
                    <Table.Cell>{idx + 1}</Table.Cell>
                    <Table.Cell>
                      {item.dueStartDate}(
                      {dayString[moment(item.dueStartDate).day()]})
                    </Table.Cell>
                    <Table.Cell>{item.dueStartTime}</Table.Cell>
                    <Table.Cell>
                      {item.dueEndDate}(
                      {dayString[moment(item.dueEndDate).day()]})
                    </Table.Cell>
                    <Table.Cell>{item.dueEndTime}</Table.Cell>
                    <Table.Cell>{item.nthTerm}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this._closeModal}>닫기</Button>
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

export default GoalItemDetail;

const styles = {
  container: {
    marginTop: 20,
  },
};
