import React, { Component } from 'react';
import {
  Button,
  Container,
  Dropdown,
  Form,
  Grid,
  Header,
  Loader,
  TextArea,
} from 'semantic-ui-react';
import { apis } from '../../../../api';
import { Redirect } from 'react-router-dom';
import { categories, subCategoryObj } from '../../../../constant/faqCategories';
import _ from 'lodash';
import { serviceTypeOptions } from '../../../../utils/dropdownOptions';

class FaqEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faq: {},
      isMounted: false,
      subCategories: [],
      category: {},
      subCategory: {},
    };
  }

  async componentDidMount() {
    const { faq } = await apis.common.getFaqItem(this.props.match.params.id);
    this.setState({
      faq: faq,
      subCategories: subCategoryObj[faq.category],
      isMounted: true,
    });
  }

  handleChange = (e) => {
    const { faq } = this.state;
    faq[e.target.name] = e.target.value;
    this.setState({ faq });
  };

  handleRadioChange = (_, { name, value }) => {
    const { faq } = this.state;
    faq[name] = value;
    this.setState({ faq });
  };

  changeCategory = (_, { value }) => {
    this.setState({
      faq: { ...this.state.faq, category: value },
      subCategories: subCategoryObj[value],
    });
  };

  changeSubCategory = (_, { value }) => {
    this.setState({ faq: { ...this.state.faq, subCategory: value } });
  };

  changeServiceType = (_, { value }) => {
    this.setState({ faq: { ...this.state.faq, serviceType: value } });
  };

  submit = async () => {
    const { faq } = this.state;
    await apis.common.updateFaq(faq.id, { ...faq });
    this.setState({ redirect: true });
  };

  render() {
    const { faq, subCategories, isMounted } = this.state;
    if (!isMounted) return <Loader active inline="centered" />;
    if (this.state.redirect) return <Redirect to={{ pathname: '/faqs' }} />;

    return (
      <Container>
        <Grid columns="equal">
          <Grid.Column />
          <Grid.Column width={8}>
            <Header as="h3" textAlign="center">
              FAQ 수정
            </Header>
            <Form onSubmit={this.submit}>
              <Form.Field>
                <label>질문</label>
                <input
                  name="question"
                  value={faq.question}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>답변</label>
                <TextArea
                  name="answer"
                  value={faq.answer}
                  onChange={this.handleChange}
                />
              </Form.Field>
              <Form.Field>
                <label>카테고리</label>
                <Dropdown
                  placeholder="카테고리"
                  search
                  selection
                  clearable
                  options={categories}
                  value={faq.category}
                  onChange={this.changeCategory}
                />
              </Form.Field>
              <Form.Field>
                <label>서브 카테고리</label>
                <Dropdown
                  placeholder="서브 카테고리"
                  search
                  selection
                  clearable
                  options={subCategories}
                  value={faq.subCategory}
                  onChange={this.changeSubCategory}
                />
              </Form.Field>
              <Form.Field>
                <label>서비스 타입</label>
                <Dropdown
                  placeholder="서비스 타입"
                  search
                  selection
                  clearable
                  options={serviceTypeOptions}
                  value={faq.serviceType}
                  onChange={this.changeServiceType}
                />
              </Form.Field>
              <Button type="submit" fluid>
                Submit
              </Button>
            </Form>
          </Grid.Column>
          <Grid.Column />
        </Grid>
      </Container>
    );
  }
}

export default FaqEdit;
