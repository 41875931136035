import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  Checkbox,
  Header,
  Button,
  Modal,
  Form,
} from 'semantic-ui-react';
import moment from 'moment';
import { apis } from '@api/index';
import { RewardTaxType } from 'src/types/user.types';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import setCurrencyFormat from '../../../global/setCurrencyFormat';
import DateSelect from '../DateSelect';
import { DateInfo } from '../DateSelect/DateSelect';

const RewardTax = () => {
  const [rewardTaxUserList, setRewardTaxUserList] = useState<RewardTaxType[]>(
    [],
  );
  const [dateInfo, setDateInfo] = useState<DateInfo>({
    startDate: moment().add(-7, 'd').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });
  const [password, setPassword] = useState('');
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);

  const getRewardTaxes = useCallback(async () => {
    LoadingIndicator.show();
    const { rewardTaxUserList: _rewardTaxUserList } =
      await apis.user.getRewardTaxUserList({
        gteCreatedAt: moment(dateInfo?.startDate)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(-9, 'h')
          .format('YYYY-MM-DD HH:mm:ss'),
        ltCreatedAt: moment(dateInfo?.endDate)
          .add(1, 'd')
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(-9, 'h')
          .format('YYYY-MM-DD HH:mm:ss'),
      });

    setRewardTaxUserList(_rewardTaxUserList);
    LoadingIndicator.hide();
  }, [dateInfo.startDate, dateInfo.endDate]);

  useEffect(() => {
    getRewardTaxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDate = (key: keyof DateInfo, value: any) => {
    const _dateInfo = { ...dateInfo, [key]: value };
    setDateInfo(_dateInfo);
  };

  const showPasswordModal = useCallback(() => setPasswordModalOpen(true), []);
  const closePasswordModal = useCallback(() => setPasswordModalOpen(false), []);

  const handleRewardTaxChange = async (id: number) => {
    const { success } = await apis.user.completeRewardTaxUser(id);
    if (!success) return;
    const _rewardTaxUserList = rewardTaxUserList.map((rt) => {
      if (rt.id === id) {
        return { ...rt, isCompleted: !rt.isCompleted };
      }
      return rt;
    });

    setRewardTaxUserList(_rewardTaxUserList);
  };

  return (
    <>
      <Header as="h3" style={{ display: 'inline-block' }}>
        제세공과금 (일주일전~현재)
      </Header>
      <DateSelect handleChangeDate={handleChangeDate} fetch={getRewardTaxes} />
      <Button
        size="mini"
        style={{ marginLeft: 10, display: 'inline' }}
        onClick={showPasswordModal}
        content="보기"
      />
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>id</Table.HeaderCell>
            <Table.HeaderCell>이름</Table.HeaderCell>
            <Table.HeaderCell>닉네임</Table.HeaderCell>
            <Table.HeaderCell>챌린지</Table.HeaderCell>
            <Table.HeaderCell>챌린지 기간</Table.HeaderCell>
            <Table.HeaderCell>상금수령일</Table.HeaderCell>
            <Table.HeaderCell>상금포인트</Table.HeaderCell>
            <Table.HeaderCell>주민등록번호</Table.HeaderCell>
            <Table.HeaderCell>처리여부</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rewardTaxUserList.map((rt) => {
            return (
              <Table.Row key={rt.id}>
                <Table.Cell>{rt.user.id}</Table.Cell>
                <Table.Cell>{rt.user.name}</Table.Cell>
                <Table.Cell>{rt.user.nickname}</Table.Cell>
                <Table.Cell>{rt.challenge.title}</Table.Cell>
                <Table.Cell>
                  {moment(rt.challenge.startDate).format('YYYYMMDD')} <br />
                  {moment(rt.challenge.endDate).format('YYYYMMDD')}
                </Table.Cell>
                <Table.Cell>
                  {moment.utc(rt.createdAt).local().format('YYYYMMDD')}
                </Table.Cell>
                <Table.Cell>{setCurrencyFormat(rt.reward)}</Table.Cell>
                <Table.Cell>
                  {password === '왕수짱짱' && <p>{rt.userSsn}</p>}
                  {password !== '왕수짱짱' && <p>******-*******</p>}
                </Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={rt.isCompleted}
                    value={rt.id}
                    onChange={() => handleRewardTaxChange(rt.id)}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      <Modal size="small" open={passwordModalOpen} onClose={closePasswordModal}>
        <Modal.Header>주문 입력</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>암호화 키를 입력해주세요</label>
              <input
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Field>
            {password === '왕수짱짱' && <p>암호화 키 입력 완료!</p>}
            {password !== '왕수짱짱' && <p>암호화 키가 일치하지 않습니다</p>}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" content="닫기" onClick={closePasswordModal} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default RewardTax;
