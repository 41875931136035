import { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

const Preview = () => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Accordion style={{ width: '100%' }}>
      <Accordion.Title
        active={!collapsed}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Icon name="dropdown" />
        미리보기
      </Accordion.Title>
      <Accordion.Content active={!collapsed}>
        <img
          src="/race/기록대회-미리보기1.jpg"
          alt="기록대회-미리보기1"
          width={290}
        />
        <img
          src="/race/기록대회-미리보기2.jpg"
          alt="기록대회-미리보기2"
          width={350}
        />
      </Accordion.Content>
    </Accordion>
  );
};

export default Preview;
