import React, { forwardRef } from 'react';
import ErrorMsg from '@component/ErrorMsg';
import * as S from './TextArea.style';

type TextAreaProps = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'onChange'
> & {
  label?: string;
  placeholderTextColor?: React.CSSProperties['color'];
  containerStyle?: React.CSSProperties;
  errorMsg?: string;
  onChange?: (value: string) => void;
};

export type TextAreaRef = HTMLTextAreaElement;

const TextArea = forwardRef<TextAreaRef, TextAreaProps>((props, ref) => {
  const {
    label,
    placeholderTextColor,
    containerStyle,
    errorMsg,
    onChange,
    ...restProps
  } = props;

  return (
    <S.Container style={containerStyle}>
      {label ? <S.Label>{label}</S.Label> : <></>}
      <S.TextArea
        ref={ref}
        placeholderTextColor={placeholderTextColor}
        onChange={(e) => onChange?.(e.target.value)}
        {...restProps}
      />
      <ErrorMsg text={errorMsg} />
    </S.Container>
  );
});

export default TextArea;
