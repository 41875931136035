import { Race } from '@types';
import { Form, Image } from 'semantic-ui-react';

const SubBannerModule = ({
  idx,
  module,
  handleSubBannerImageChange,
}: {
  idx: number;
  module: Race.RaceRegisterSubBannerModule;
  handleSubBannerImageChange: (idx: number, e: any) => Promise<void>;
}) => {
  return (
    <div>
      <h4>서브배너 모듈 (** 랜선대회 전체보기 스크린으로 이동)</h4>
      <Form.Field>
        <label>서브배너 이미지 (1000px * 320px)</label>
        <input
          type="file"
          name="imageUrl"
          onChange={(e) => handleSubBannerImageChange(idx, e)}
        />
        <Image size={'small'} src={module.imageUrl} />
      </Form.Field>
    </div>
  );
};

export default SubBannerModule;
