import { parseLocalDateTime } from '@utils/date.utils';
import { isNull } from '@utils/js.utils';
import { isAfter } from 'date-fns';
import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'purchaseStartDate' | 'purchaseEndDate'>;

export const validate: FormValidator<Params> = ({
  purchaseStartDate,
  purchaseEndDate,
}) => {
  if (isNull(purchaseEndDate) || isNull(purchaseStartDate)) {
    return { isValid: false, message: '' };
  }

  if (
    isAfter(
      parseLocalDateTime(purchaseStartDate),
      parseLocalDateTime(purchaseEndDate),
    )
  ) {
    return {
      isValid: false,
      message: '구매 시작일은 구매 종료일보다 이전이어야 합니다.',
    };
  }

  return { isValid: true };
};
