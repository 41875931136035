import { Race } from '@types';
import { RaceFormDescriptionModule, RaceFormType } from './RaceForm.types';
import moment from 'moment';
import { skinTodoList } from '@constant/race';
import { generateId } from '@utils/number';
import { checkIsNewOption } from '@utils/race';

// 날짜 처리, 숫자 처리, 불필요한 id 없애기
export const formattingBeforeSubmit = (
  _race: RaceFormType,
  _productPackages: Race.RaceProductPackageFormType[],
  _stampStickers: Race.RaceSticker[],
  _imageFilters: Race.RaceImageFilter[],
) => {
  _race.maxRegisterCount = Number(_race.maxRegisterCount);

  _race.registerStartDate = moment(_race.registerStartDate)
    .subtract(9, 'h')
    .format('YYYY-MM-DD HH:mm:ss');
  _race.registerEndDate = moment(_race.registerEndDate)
    .subtract(9, 'h')
    .format('YYYY-MM-DD HH:mm:ss');
  _race.startDate = moment(_race.startDate)
    .subtract(9, 'h')
    .format('YYYY-MM-DD HH:mm:ss');
  _race.endDate = moment(_race.endDate)
    .subtract(9, 'h')
    .format('YYYY-MM-DD HH:mm:ss');

  _race.sponsors.forEach((sponsor) => {
    delete sponsor.id;
  });

  // 스탬프 스티커 처리 (리그별)
  const _raceStampStickers: Race.RaceSticker[] = [];

  _stampStickers.forEach((sticker) => {
    if (!sticker.top) delete sticker.top;
    else sticker.top = Number(sticker.top);

    if (!sticker.left) delete sticker.left;
    else sticker.left = Number(sticker.left);

    if (!sticker.right) delete sticker.right;
    else sticker.right = Number(sticker.right);

    if (!sticker.bottom) delete sticker.bottom;
    else sticker.bottom = Number(sticker.bottom);

    sticker.width = Number(sticker.width);
    sticker.height = Number(sticker.height);

    _raceStampStickers.push(sticker);
  });

  // 이미지 필터 처리

  const _raceImageFilters: Race.RaceImageFilterWithLeagueId[] = [];

  _race.leagues.forEach((league, idx) => {
    league.id = idx + 1;
    league.minUnitValue = Number(league.minUnitValue);
    league.maxUnitValue = Number(league.maxUnitValue);

    league.stickers = _raceStampStickers;

    if (!league.maxUnitValue) league.maxUnitValue = null;

    _raceImageFilters.push({
      leagueId: league.id,
      filters: _imageFilters,
    });
  });

  if (_race?.reviewInfo?.questions?.length === 0) {
    _race.reviewInfo = null;
  } else {
    _race.reviewInfo?.questions?.forEach((q) => {
      q.minLength = Number(q.minLength);
      delete q.id;
    });
  }

  _race.modules.progressInfoDescriptionModule?.forEach((module) => {
    if (module.type === 'HYPERLINK') {
      if (module.route.type === 'RaceRegisterScreen') {
        module.route.params.eventName = Number(module.route.params.eventName);
      }
    }
    delete module.id;
  });

  _race.modules.registerInfoDescriptionModule?.forEach((module) => {
    if (module.type === 'HYPERLINK') {
      if (module.route.type === 'RaceRegisterScreen') {
        module.route.params.eventName = Number(module.route.params.eventName);
      }
    } else if (module.type === 'GOODS') {
      module.goodsList.forEach((goods) => {
        goods.additionalItems.forEach((item) => {
          delete item.id;
        });
        delete goods.id;
      });
    }
  });

  // 어드민에서는 ORIGIN-IMAGE 타입만 관리
  const flatedDescriptionModule: Race.RaceDescriptionModule[] =
    _race.modules.registerInfoDescriptionModule?.flatMap<Race.RaceDescriptionModule>(
      (obj) => {
        return obj.type === 'ORIGIN-IMAGE'
          ? obj.urls.map(
              (url) =>
                ({
                  parentId: obj.id,
                  type: 'FULL-IMAGE',
                  url,
                }) as Race.RaceFullImageDescriptionModule,
            )
          : obj.type === 'TEMPORARY-ORIGIN-IMAGE'
            ? obj.urls.map(
                (url) =>
                  ({
                    parentId: obj.id,
                    type: 'TEMPORARY-IMAGE',
                    url,
                    dueStartDate: obj.dueStartDate,
                    dueEndDate: obj.dueEndDate,
                  }) as Race.RaceTemporaryImageDescriptionModule,
              )
            : obj;
      },
    );
  (_race as Race.ReqCreateOrUpdateRace).modules.registerInfoDescriptionModule =
    flatedDescriptionModule;

  _race.userFilterId = _race.userFilter?.id;
  delete _race.userFilter;

  _race.modules.homeModule?.forEach((module) => {
    if (module.type === 'CONTENTS-CARDS') {
      module.cards.forEach((card) => {
        delete card.id;
      });
    }
    delete module.id;
  });

  if (_race.reactions) {
    _race.reactions?.forEach((reaction, idx) => {
      delete reaction.id;
      reaction.id = idx + 1;
    });
  }

  _productPackages?.forEach((_package) => {
    if (_package?.id && checkIsNewOption(_package.id)) _package.id = null;
    _package.price = Number(_package.price);
    _package.shippingFee = Number(_package.shippingFee);
    if (!_package.totalCount) {
      _package.totalCount = null;
    } else {
      _package.totalCount = Number(_package.totalCount);
    }
    if (_package.products?.[0]?.options?.values.length === 0) {
      _package.products = [];
      return;
    }
    _package.products.forEach((_product) => {
      _product.options?.values.forEach(
        (
          option: Omit<Race.ProductGroupOptionValue, 'id'> & { id?: number },
        ) => {
          option.options?.values.forEach(
            (value: Omit<Race.ProductOptionValue, 'id'> & { id?: number }) => {
              if (value?.id && checkIsNewOption(value.id)) {
                delete value.id;
              }
            },
          );
          if (option?.id && checkIsNewOption(option.id)) delete option.id;
        },
      );
    });
  });

  if (_race.authenticationMethod) {
    if (
      _race.authenticationMethod.title.length === 0 &&
      _race.authenticationMethod.description.length === 0 &&
      _race.authenticationMethod.image === undefined
    ) {
      delete _race.authenticationMethod;
    }
  } else {
    delete _race.authenticationMethod;
  }

  // 꿀피부, 다이어트 대회의 경우 기본 todolist가 추가된다.
  switch (_race.raceType) {
    case 'skin':
      _race.skinRaceParameter = { todoList: skinTodoList };
      break;
    default:
      break;
  }

  if (_race.raceType !== 'cheer') {
    delete _race.cheerRaceParameter;
  }

  if (_race.raceType !== 'record') {
    delete _race.recordRaceParameter;
  }

  return {
    race: _race,
    productPackages: _productPackages,
    raceImageFilters: _raceImageFilters,
  };
};

export const formattingBeforeSet = (
  __race: Race.ReqCreateOrUpdateRace,
  __productPackages: Race.RaceProductPackageFormType[],
  __raceImageFilters: Race.RaceImageFilterWithLeagueId[],
) => {
  let race_ = { ...__race };
  // reviewInfo가 null이라면 initial 값 추가
  if (!race_.reviewInfo) {
    race_.reviewInfo = { description: '', questions: [] };
  } else {
    const questionIncludeId: {
      id: number;
      question: string;
      required: boolean;
      minLength: number;
      placeholder: string;
    }[] = [];
    race_.reviewInfo?.questions?.forEach((question) => {
      questionIncludeId.push({
        ...question,
        id: generateId(),
      });
    });
    race_ = {
      ...race_,
      reviewInfo: {
        ...race_.reviewInfo,
        questions: questionIncludeId,
      },
    };
  }

  race_.registerStartDate = moment(race_.registerStartDate).format(
    'YYYY-MM-DDTHH:mm',
  );

  race_.registerEndDate = moment(race_.registerEndDate).format(
    'YYYY-MM-DDTHH:mm',
  );
  race_.startDate = moment(race_.startDate).format('YYYY-MM-DDTHH:mm');
  race_.endDate = moment(race_.endDate).format('YYYY-MM-DDTHH:mm');

  race_.sponsors?.forEach((sponsor) => {
    sponsor.id = generateId();
  });

  race_.leagues?.forEach((league) => {
    if (!league.maxUnitValue) league.maxUnitValue = '';
  });

  race_.modules.progressInfoDescriptionModule?.forEach((module) => {
    (module as Race.RaceDescriptionModule & { id?: number }).id = generateId();
  });

  const newRegisterInfoDescriptionModule: RaceFormDescriptionModule[] = [];
  let prevParentId: number | null = null;
  let originImageBuffer: {
    id?: number;
    type: 'ORIGIN-IMAGE';
    urls: string[];
  } | null = null;

  let tempPrevParentId: number | null = null;
  let temporaryOriginImageBuffer: {
    id?: number;
    type: 'TEMPORARY-ORIGIN-IMAGE';
    urls: string[];
    dueStartDate: string;
    dueEndDate: string;
  } | null = null;
  race_.modules.registerInfoDescriptionModule?.forEach((module) => {
    module.id = generateId();
    if (module.type === 'GOODS') {
      module.goodsList?.forEach((goods) => {
        goods.id = generateId();
        goods.additionalItems?.forEach((item) => {
          item.id = generateId();
        });
      });
    } // 어드민에서는 ORIGIN-IMAGE 타입만 관리
    else if (module.type === 'FULL-IMAGE') {
      if (!originImageBuffer || prevParentId !== module.parentId) {
        newRegisterInfoDescriptionModule.push(
          (originImageBuffer = {
            id: generateId(),
            type: 'ORIGIN-IMAGE',
            urls: [module.url],
          }),
        );
        prevParentId = module?.parentId ?? null;
      } else {
        if (!prevParentId || prevParentId === module.parentId) {
          originImageBuffer.urls.push(module.url);
          prevParentId = module?.parentId ?? null;
          return;
        }
        originImageBuffer = null;
        prevParentId = null;
      }

      return;
    } else if (module.type === 'TEMPORARY-IMAGE') {
      if (!temporaryOriginImageBuffer || tempPrevParentId !== module.parentId) {
        newRegisterInfoDescriptionModule.push(
          (temporaryOriginImageBuffer = {
            id: generateId(),
            type: 'TEMPORARY-ORIGIN-IMAGE',
            urls: [module.url],
            dueStartDate: module.dueStartDate,
            dueEndDate: module.dueEndDate,
          }),
        );
        tempPrevParentId = module?.parentId ?? null;
      } else {
        if (!tempPrevParentId || tempPrevParentId === module.parentId) {
          temporaryOriginImageBuffer.urls.push(module.url);
          tempPrevParentId = module?.parentId ?? null;
          return;
        }
        temporaryOriginImageBuffer = null;
        tempPrevParentId = null;
      }

      return;
    }
    originImageBuffer = null;
    prevParentId = null;

    temporaryOriginImageBuffer = null;
    tempPrevParentId = null;

    newRegisterInfoDescriptionModule.push(module as RaceFormDescriptionModule);
  });

  (race_ as RaceFormType).modules.registerInfoDescriptionModule =
    newRegisterInfoDescriptionModule;

  race_.modules.homeModule?.forEach((module) => {
    (module as { id: number } & Race.RaceHomeModule).id = generateId();
    if (module.type === 'CONTENTS-CARDS') {
      module.cards?.forEach((card) => {
        (
          card as { id: number } & {
            emoji: string;
            title: string;
            body: string;
            bgColor: string;
            titleColor: string;
            bodyColor: string;
          }
        ).id = generateId();
      });
    }
  });
  __productPackages?.forEach((o) => {
    if (!o.id) o.id = generateId();

    o.products[0].options?.values.forEach((p) => {
      if (!p.id) p.id = generateId();
      p.options?.values.forEach((v) => {
        if (!v.id) v.id = generateId();
      });
    });
  });

  __race.leagues?.forEach((league) => {
    const filtersWithLeague = __raceImageFilters.find(
      (o) => o.leagueId === league.id,
    );
    league.imageFilters = filtersWithLeague ? filtersWithLeague.filters : [];
  });

  return { race: __race, productPackages: __productPackages };
};
