import { StateCreator, create } from 'zustand';
import {
  PersistOptions,
  createJSONStorage,
  persist,
  devtools,
} from 'zustand/middleware';
import { ChallengeFormType } from './NewChallengeForm.types';
import { getInitialDates } from './NewChallengeForm.util';
import { initialAuthenticationChecklist } from './NewChallengeForm.constants';

type ChallengeFormState = {
  challenge: ChallengeFormType;
  reset: () => void; // 챌린지 form 아예 초기화
  setChallengeForm: (challenge: ChallengeFormType) => void; // 챌린지 form 전체 업데이트
  updateChallengeForm: (
    updated:
      | ((currentState: ChallengeFormType) => ChallengeFormType)
      | Partial<ChallengeFormType>,
  ) => void; // 챌린지 form 1 depth 부분 업데이트
};

type ChallengeFormPersist = (
  config: StateCreator<ChallengeFormState>,
  options: PersistOptions<ChallengeFormState>,
) => StateCreator<ChallengeFormState>;

export const INITIAL_CHALLENGE_FORM: ChallengeFormType = {
  title: '',
  category: undefined,
  purchaseChannel: undefined,
  purchaseStartDate: getInitialDates(new Date()).purchaseStartDate,
  purchaseEndDate: getInitialDates(new Date()).purchaseEndDate,
  price: null,
  originalPrice: null,
  userId: 1,
  password: '',
  adminUserIds: [1],
  collaboAdminUserIds: [],
  descriptionModule: [],
  descriptionExtra: [],
  goalId: null,
  thumbnailImageUrl: null,
  authenticationMethod: '',
  authenticationGoodExamples: [],
  authenticationBadExamples: [],
  authenticationChecklist: initialAuthenticationChecklist,
  floatingChecklist: '',
  minRegisterDeposit: 10000,
  maxRegisterDeposit: 200000,
  isRegisterDepositFixed: false,
  minCollectDeposit: 1000000,
  maxRegisterCount: 99999,
  registerStartDate: getInitialDates(new Date()).registerStartDate,
  registerEndDate: getInitialDates(new Date()).registerEndDate,
  startDate: getInitialDates(new Date()).startDate,
  endDate: getInitialDates(new Date()).endDate,
  resultDate: getInitialDates(new Date()).resultDate,
  tagIds: [],
  labelIds: [],
  isPublic: false,
  isMandatory: false,
  selectType: 'RADIO',
  adType: 'SMALL_BANNER',
  adImage: null,
  thirdTermUrl: '',
  isUsualFrequencyDisplayed: true,
  exp: 0,
  companyId: null,
  publicType: 'PARTICIPANT',
  specialPrize: { prizeType: null, amount: 0 },
  useItemImpossible: false,
  cancelImpossible: null,
  achievementInfoList: [],
  achievementMission: [],
  faq: null,
  instagramInfoList: [],
  naverBlogInfoList: [],
  purchaseInfo: null,
  postConnectPrize: null,
  naverIdRequired: false,
  isDeleted: false,

  collaboration: {
    reviewRewardText: null,
    collaboProductName: null,
    collaboProductLink: null,
    reviewQuestionList: [],
  },

  supporters: {
    benefit: null,
    userLimit: null,
    gender: [],
    age: [],
    category: [],
    hasDelivery: null,
    challengeWeight: null,
    instagramWeight: null,
    naverBlogBuddyCountWeight: null,
    naverVisitorCountWeight: null,
    oliveYoungBabyWeight: null,
    oliveYoungPinkWeight: null,
    oliveYoungGreenWeight: null,
    oliveYoungBlackWeight: null,
    oliveYoungGoldWeight: null,
    oliveYoungTroubleWeight: null,
    oliveYoungWrinkleWeight: null,
    selectionResultDate: null,
    isSelectionDone: null,
  },

  qna: { questions: [] },

  creator: {
    type: null,
    defaultCommercePrice: null,
  },

  commerce: {
    productHeader: null,
    selectType: 'RADIO',
    isMandatory: null,
    rccps: [],
  },

  userFilter: {
    id: null,
    name: null,
  },

  timestamp: {
    hideTimeStamp: null,
  },

  challengeStickers: [],

  // challenge정보와 관련 없이 validation을 위한 값들은 아래에 추가
  achievementCount: 0,

  imageFilter: [],

  distance: [],
};

export const useChallengeFormStore = create<ChallengeFormState>()(
  devtools(
    (persist as ChallengeFormPersist)(
      (set, get) => {
        return {
          challenge: INITIAL_CHALLENGE_FORM,
          reset: () => set({ challenge: INITIAL_CHALLENGE_FORM }),
          setChallengeForm: (challenge: ChallengeFormType) =>
            set({ challenge }),
          updateChallengeForm: (
            updated:
              | ((currentState: ChallengeFormType) => ChallengeFormType)
              | Partial<ChallengeFormType>,
          ) => {
            if (typeof updated === 'function') {
              // updated == select function
              set((prev) => {
                const newChallengeForm = updated(prev.challenge);
                return {
                  prev,
                  challenge: {
                    ...prev.challenge,
                    ...newChallengeForm,
                  },
                };
              });
              return;
            }
            const { challenge } = get();
            set({
              challenge: {
                ...challenge,
                ...updated,
              },
            });
          },
        };
      },
      {
        name: 'challenge-form',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  ),
);

export type RecommendedUpdates = {
  registerStartDate: boolean;
  registerEndDate: boolean;
  startDate: boolean;
  endDate: boolean;
  resultDate: boolean;
  maxRegisterCount: boolean;
  specialPrizeAmount: boolean;
  collaboProductName: boolean;
  collaboProductLink: boolean;
  userFilterId: boolean;
  achievementInfoList: {
    dueStartDate: boolean;
    dueStartTime: boolean;
    dueEndDate: boolean;
    dueEndTime: boolean;
  }[];
  purchaseStartDate: boolean;
  purchaseEndDate: boolean;
};

const RECOMMENDED_UPDATES: RecommendedUpdates = {
  registerStartDate: false,
  registerEndDate: false,
  startDate: false,
  endDate: false,
  resultDate: false,
  maxRegisterCount: false,
  specialPrizeAmount: false,
  collaboProductName: false,
  collaboProductLink: false,
  userFilterId: false,
  achievementInfoList: [],
  purchaseStartDate: false,
  purchaseEndDate: false,
};

type ChallengeEditedCheckState = {
  recommendedUpdates: RecommendedUpdates;
  updateEdited: (value: Partial<RecommendedUpdates>) => void;
  initializeAchievementInfoList: (length: number) => void;
};

export const useChallengeEditedStore = create<ChallengeEditedCheckState>(
  (set) => {
    return {
      recommendedUpdates: RECOMMENDED_UPDATES,
      updateEdited: (value: Partial<RecommendedUpdates>) => {
        set((prev) => {
          return {
            prev,
            recommendedUpdates: {
              ...prev.recommendedUpdates,
              ...value,
            },
          };
        });
      },
      initializeAchievementInfoList: (length: number) => {
        set((prev) => {
          return {
            prev,
            recommendedUpdates: {
              ...prev.recommendedUpdates,
              achievementInfoList: Array(length).fill({
                dueStartDate: false,
                dueStartTime: false,
                dueEndDate: false,
                dueEndTime: false,
              }),
            },
          };
        });
      },
    };
  },
);
