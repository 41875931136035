import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useTitle = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      title: s.challenge.title,
    })),
  );
};

export const useTitleActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setTitle = (title: string) => updateChallengeForm({ title });

  return { setTitle };
};
