import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const SettingMenu = () => {
  const [pathname, setPathname] = useState('');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const menus = [
    { text: '문자 발송 업체', value: '/sms-provider' },
    { text: '공휴일 정보 관리', value: '/rest-day' },
    { text: '화큐 계정 관리', value: '/user-admin' },
    { text: '포인트 지급', value: '/event-point' },
    { text: '기간한정 캐시 지급', value: '/temporary-cash' },
    { text: '인증패스 지급', value: '/event-item' },
  ];

  const _click = (menu: { text: string; value: string }) => {
    history.push(menu.value);
  };

  return (
    <Menu fluid secondary vertical>
      {menus.map((menu) => (
        <Menu.Item
          key={menu.value}
          active={menu.value === pathname}
          onClick={() => _click(menu)}
        >
          {menu.text}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default SettingMenu;
