import { convertWonUnit } from '@utils/common.utils';
import {
  ChallengeFormType,
  SpecialPrizeCustomFieldFormType,
} from '../../NewChallengeForm.types';

export const formatSpecialPrize = ({
  specialPrize,
}: SpecialPrizeCustomFieldFormType) => {
  const amount = `${convertWonUnit(specialPrize.amount)}만원`;
  switch (specialPrize.prizeType) {
    case 'individual':
      return `인당 ${amount}`;
    case 'total':
      return `총 ${amount}`;
    case 'achievement':
    default:
      return '';
  }
};

export const getSpecialPrizeTypeOptions = (
  isCustomGoal: boolean,
): {
  type: ChallengeFormType['specialPrize']['prizeType'];
  label: string;
  disabled?: boolean;
}[] => [
  { type: null, label: '특별 상금 없음' },
  { type: 'total', label: '총 상금' },
  { type: 'individual', label: '인당 상금' },
  {
    type: 'achievement',
    label: '회차별 인당 상금',
    disabled: !isCustomGoal,
  },
];
