import { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'semantic-ui-react';

type UploadUrlAchievementModalProps = {
  achievement: any;
  isOpened: boolean;
  onClosePress: () => void;
  onUploadPress: (achievement: any) => void;
};

const UploadUrlAchievementModal = (props: UploadUrlAchievementModalProps) => {
  const { achievement, isOpened, onClosePress, onUploadPress } = props;
  const [url, setUrl] = useState<string>('');
  const [submitDatetime, setSubmitDatetime] = useState<string>(
    achievement.submitDatetime,
  );

  useEffect(() => {
    setSubmitDatetime(achievement.submitDatetime);
  }, [achievement.submitDatetime]);

  const handleUploadPress = () => {
    if (!url?.length || !submitDatetime) return;
    onUploadPress({ postUrl: url, submitDatetime });
  };

  const handleDatetimeChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setSubmitDatetime(e.target.value);
  };

  return (
    <Modal size="tiny" open={isOpened} onClose={onClosePress}>
      <Modal.Header>인증샷 업로드</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field />
          <Form.Field>
            <label>인증 게시글 url</label>
            <input
              name="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>제출시간</label>
            <input
              name="submitDatetime"
              value={submitDatetime}
              onChange={handleDatetimeChange}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" content="업로드" onClick={handleUploadPress} />
        <Button color="black" content="닫기" onClick={onClosePress} />
      </Modal.Actions>
    </Modal>
  );
};

export default UploadUrlAchievementModal;
