import { useGetAllCompaniesProducts } from '@queries/Commerce/useGetCompaniesProducts';

export const useAllProducts = () => {
  const { data: companies = [] } = useGetAllCompaniesProducts();

  const products = companies.flatMap((c) =>
    c.products.map((p) => ({ ...p, companyName: c.name, companyId: c.id })),
  );

  return { products };
};
