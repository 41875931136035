import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { getInitialDates } from '../../NewChallengeForm.util';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'purchaseEndDate'>,
  Pick<ReqCreateOrUpdateChallenge, 'purchaseEndDate'>
> = ({ purchaseEndDate }) => {
  if (!purchaseEndDate?.length)
    throw Error("[PurchaseEndDateForm] this shouldn't be happened");

  return { purchaseEndDate };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'purchaseEndDate'>,
  Pick<ChallengeFormType, 'purchaseEndDate'>
> = ({ purchaseEndDate }) => ({
  purchaseEndDate:
    purchaseEndDate ?? getInitialDates(new Date()).purchaseEndDate,
});
