import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import { Race } from '../../../../../types';

type Props = {
  packages: Race.RaceProductDescription[];
};

export const RacePackageInfo: FC<Props> = ({ packages }) => {
  if (!packages.length) return <></>;
  return (
    <>
      <h3>대회 패키지 정보</h3>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>이름</Table.HeaderCell>
            <Table.HeaderCell>내용</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {packages.map((p) => (
            <Table.Row key={p.name}>
              <Table.Cell>{p.name}</Table.Cell>
              <Table.Cell>{p.description}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};
