import React from 'react';
import { Image } from 'semantic-ui-react';
import Image1 from './assets/1.png';
import Wall from './assets/home_50.png';
import Image2 from './assets/2.png';
import Image3 from './assets/3.png';
import Image4 from './assets/4.png';
import Image5 from './assets/5.png';
import Image6 from './assets/6.png';
import Image7 from './assets/7.png';
import Image8 from './assets/8.png';
import Image9 from './assets/9.png';
import Image10 from './assets/10.png';
import Image11 from './assets/11.png';

const Home = () => {
  return (
    <>
      <Image fluid src={Wall} />
      <div
        style={{
          margin: 50,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{ width: 500, textAlign: 'center' }}>
          <h1>챌린저스 툰</h1>
          <h1>⬇️</h1>
          <Image src={Image1} />
          <Image src={Image2} />
          <Image src={Image3} />
          <Image src={Image4} />
          <Image src={Image5} />
          <Image src={Image6} />
          <Image src={Image7} />
          <Image src={Image8} />
          <Image src={Image9} />
          <Image src={Image10} />
          <Image src={Image11} />
        </div>
      </div>
    </>
  );
};

export default Home;
