import React, { useState } from 'react';
import { Button, Container, Form, Grid, Header } from 'semantic-ui-react';
import { apis } from '../../../api';
import BenefitMenu from '../BenefitMenu';
import * as S from '../Style';

const PedometerRewardInvited = () => {
  // 추천자
  const [recommenderUserId, setRecommenderUserId] = useState(null);
  //   추천인
  const [recommendeeUserId, setRecommendeeUserId] = useState(null);

  const onClick = async () => {
    if (!recommendeeUserId || !recommenderUserId) {
      return alert('아이디를 입력해주세요');
    }

    const res = await apis.givePedometerInviteFriendReward(
      parseInt(recommenderUserId, 10),
      parseInt(recommendeeUserId, 10),
    );

    if (res.success) return alert('포인트 지급이 완료되었습니다');
  };

  return (
    <div style={{ margin: 40 }}>
      <Grid columns="equal">
        <Grid.Column width={2}>
          <BenefitMenu />
        </Grid.Column>
        <Grid.Column>
          <Header as="h3">친구초대 리워드 지급</Header>
          <Form>
            <S.RowContainer>
              <S.RowFormField>
                <label>추천자(신규 가입 하는 사람)</label>
                <input
                  value={recommenderUserId || ''}
                  onChange={(e) => {
                    setRecommenderUserId(e.target.value);
                  }}
                  type="number"
                  style={{ width: 300 }}
                />
              </S.RowFormField>
            </S.RowContainer>
            <S.RowContainer>
              <S.RowFormField>
                <label>추천인(친구 초대 한 사람)</label>
                <input
                  value={recommendeeUserId || ''}
                  onChange={(e) => {
                    setRecommendeeUserId(e.target.value);
                  }}
                  style={{ width: 300 }}
                  type="number"
                />
              </S.RowFormField>
            </S.RowContainer>
            <S.ButtonWrapper style={{ width: 300 }}>
              <Button
                fluid
                color="blue"
                content="포인트 지급"
                onClick={onClick}
              />
            </S.ButtonWrapper>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default PedometerRewardInvited;
