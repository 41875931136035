import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apis } from '../../api';
import { QuestionTemplate } from '../../types';

const QUERY_KEY = {
  GET_QUESTION_TEMPLATE_LIST: 'GET_QUESTION_TEMPLATE_LIST',
};
export const useQuestionPage = () => {
  const queryClient = useQueryClient();

  const questionTemplateListQuery = useQuery({
    queryKey: [QUERY_KEY.GET_QUESTION_TEMPLATE_LIST],
    queryFn: () => apis.question.getQuestionTemplateList({}),
  });

  const onMutationSettled = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.GET_QUESTION_TEMPLATE_LIST],
    });
  }, [queryClient]);

  const { mutateAsync: addQuestionTemplate } = useMutation({
    mutationFn: apis.question.addQuestionTemplate,
    onSuccess: onMutationSettled,
  });

  const { mutateAsync: editQuestionTemplate } = useMutation({
    mutationFn: apis.question.editQuestionTemplate,
    onSuccess: onMutationSettled,
  });

  const { mutateAsync: deleteQuestionTemplate } = useMutation({
    mutationFn: apis.question.deleteQuestionTemplate,
    onMutate: async ({ id }) => {
      const key = [QUERY_KEY.GET_QUESTION_TEMPLATE_LIST];
      await queryClient.cancelQueries({ queryKey: key });
      const prevValue = queryClient.getQueryData<{
        questionTemplates: QuestionTemplate[];
      }>(key);
      if (!prevValue) return { prevValue };

      const targetIndex = prevValue.questionTemplates.findIndex(
        (template) => template.id === id,
      );
      const updated = [...prevValue.questionTemplates];
      updated.splice(targetIndex, 1);
      queryClient.setQueryData(key, { questionTemplates: updated });
      return { prevValue };
    },
    onError: (err, _, context) => {
      queryClient.setQueryData(
        [QUERY_KEY.GET_QUESTION_TEMPLATE_LIST],
        context?.prevValue,
      );
    },
    onSettled: onMutationSettled,
  });

  const { mutateAsync: updateQuestionTemplateOrder } = useMutation({
    mutationFn: (params: { values: QuestionTemplate[] }) => {
      const { values } = params;
      return apis.question.updateQuestionTemplateOrder({
        values: values.map((template, index) => ({
          id: template.id,
          order: index,
        })),
      });
    },
    onMutate: async (newQuestionTemplateList) => {
      const key = [QUERY_KEY.GET_QUESTION_TEMPLATE_LIST];
      await queryClient.cancelQueries({ queryKey: key });
      const prevValue = queryClient.getQueryData(key);
      queryClient.setQueryData(key, {
        questionTemplates: newQuestionTemplateList.values,
      });
      return { prevValue };
    },
    onError: (err, _, context) => {
      queryClient.setQueryData(
        [QUERY_KEY.GET_QUESTION_TEMPLATE_LIST],
        context?.prevValue,
      );
    },
    onSettled: onMutationSettled,
  });

  return {
    questionTemplateList:
      questionTemplateListQuery.data?.questionTemplates || [],
    isLoading: questionTemplateListQuery.isLoading,
    addQuestionTemplate,
    editQuestionTemplate,
    updateQuestionTemplateOrder,
    deleteQuestionTemplate,
  };
};
