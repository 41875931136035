import styled from 'styled-components';

export const Wrapper = styled.div<{ isActive: boolean }>`
  width: 100%;
  background-color: ${(props) => (props.isActive ? '#1e88e5' : '#fff')};
  padding: 10px 4px;
`;

export const Label = styled.label<{ isActive: boolean }>`
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: ${(props) => (props.isActive ? '#fff' : '#000')};
`;
