import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { apis } from '../../../api';
import RaceForm from '../RaceForm';
import {
  RaceImageFilterWithLeagueId,
  ReqCreateOrUpdateProductPackage,
  ReqCreateOrUpdateRace,
} from 'src/types/race.types';
import { LabelMiniInfo, Race } from '@types';

const RaceEdit = () => {
  const history = useHistory();
  const { id: raceId } = useParams<{ id: string }>();

  const [race, setRace] = useState<Race.ReqCreateOrUpdateRace | undefined>();
  const [productPackages, setProductPackages] = useState<
    Race.RaceProductPackageFormType[]
  >([]);
  const [filters, setFilters] = useState<RaceImageFilterWithLeagueId[]>([]);
  const [labels, setLabels] = useState<LabelMiniInfo[]>([]);

  useEffect(() => {
    _getRaceDetail();
  }, []);

  /**
   * api: 대회 상세 정보 불러오기
   */
  const _getRaceDetail = async () => {
    const {
      race: _race,
      packages: _productPackages,
      filters: _filters,
      labels: _labels,
    } = await apis.getRaceDetail(Number(raceId));

    setProductPackages(
      _productPackages && _productPackages.length > 0
        ? _productPackages.map((_productPackage) => {
            return {
              ..._productPackage,
              is참가권:
                !_productPackage.price && !_productPackage.originalPrice,
            };
          })
        : [],
    );
    setFilters(_filters);
    setLabels(_labels);
    setRace({
      ..._race,
      stickers: _race.raceStickers,
      reactions: _race.raceReactions,
      productDescriptions: _race.productDescriptions,
    });
  };

  const submit = useCallback(
    async (
      _race: ReqCreateOrUpdateRace,
      _packages: ReqCreateOrUpdateProductPackage[],
      _raceImageFilters: RaceImageFilterWithLeagueId[],
      _labelIds: number[],
      _sendSlack: boolean,
      _autoGenerateOfferWall: boolean,
    ) => {
      if (!_race.reviewInfo) {
        alert('리뷰 질문 목록을 채워주세요!');
        return;
      }

      if (_race.id === undefined) {
        alert('대회 정보가 없습니다.');
        return;
      }

      const res = await apis.updateRace(_race.id, {
        race: _race,
        packages: _packages,
        raceImageFilters: _raceImageFilters,
        labelIds: _labelIds,
        sendSlack: _sendSlack,
        autoGenerateOfferWall: _autoGenerateOfferWall,
      });
      if (res?.response?.data && 'err' in res?.response?.data) {
        alert('대회 수정에 실패했어요.');
        return;
      }

      const isWebOpen = window.confirm(
        `대회 수정이 완료되었어요.\n확인을 누르면 웹페이지로 이동합니다.`,
      );
      if (isWebOpen) {
        window.open(
          `https://web${
            process.env.REACT_APP_ENV_PHASE === 'STAGE' ? '-stage' : ''
          }.chlngers.com/race/${_race.id}`,
        );
        return;
      }
      history.push(`/race/${_race.id}`);
    },
    [],
  );

  if (!race) return null;
  return (
    <Container>
      <RaceForm
        submit={submit}
        race={race}
        labels={labels}
        productPackages={productPackages}
        raceImageFilters={filters}
      />
    </Container>
  );
};

export default RaceEdit;
