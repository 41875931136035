import React from 'react';
import { Grid, Header, Table } from 'semantic-ui-react';
import { useCollaboSettlementProductList } from '../../../../../queries/Challenge';
import { flatMapInfiniteData } from '../../../../../utils/query.utils';
import TemplateForm from './components/TemplateForm/TemplateForm';

// TODO(Gina): pagination 추가?
const TemplateSection = () => {
  const { data } = useCollaboSettlementProductList();

  const collaboProductList =
    flatMapInfiniteData(data, 'collaboProducts', 'tagId') || [];

  return (
    <Grid.Row>
      <Header as="h2">챌린지 태그별 기본값 관리</Header>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>태그</Table.HeaderCell>
            <Table.HeaderCell width={12}>템플릿 값</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {collaboProductList.map((product) => (
            <Table.Row key={product.tagId}>
              <Table.Cell>{`[${product.tagId}] ${product.tagText}`}</Table.Cell>
              <Table.Cell>
                <TemplateForm options={product.collaboProductOptions} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Grid.Row>
  );
};

export default TemplateSection;
