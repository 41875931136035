import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  Form,
  Radio,
  Divider,
  Checkbox,
  DropdownProps,
  Grid,
  Header,
} from 'semantic-ui-react';
import { goalCycleOptions } from '../../../utils/dropdownOptions';
import { GoalCategoryWithRestrict, GoalDetailInfo } from '@types';
import { convertCycleOptionValueFromCycle } from './GoalForm.utils';
import { isNumber } from '../../../utils/number';
import FlexBox from '@component/FlexBox/FlexBox';
import { apis } from '@api/index';
import { DropdownOption } from 'src/types/common.types';
import {
  getGoalCategoryFromDropdownOption,
  getOptionsFromGoalCategories,
  makeGoalCategoryOption,
} from '../Goal.utils';
import _ from 'lodash';
import GoalCategoryCreateOrEditModal from '../GoalCategoryCreateOrEditModal/GoalCategoryCreateOrEditModal';

export const GoalForm = ({
  template,
  handleChangeTemplate,
  goal,
  handleChange,
  submit,
}: GoalFormProps) => {
  // 상시 인증 가능하게 하는 필드 값
  const isGoalCustom = goal
    ? goal.cycle.length === 1 && goal.cycle[0] === 'CUSTOM'
    : false;

  const [always, setAlways] = useState(isGoalCustom);
  const [keyword, setKeyword] = useState<string>('');
  const [goalCategoryOptions, setGoalCategoryOptions] = useState<
    DropdownOption[]
  >([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setAlways(isGoalCustom);
  }, [isGoalCustom]);

  useEffect(() => {
    apis.challenge
      .getGoalCategories({
        offset: 0,
        limit: 100,
        keyword,
      })
      .then((res) => {
        if (res?.response?.data && 'err' in res?.response?.data) {
          setGoalCategoryOptions([]);
        }

        let _goalCategoryOptions = getOptionsFromGoalCategories(
          res.goalCategories,
        );
        if (
          !_goalCategoryOptions.find((o) => o.value === goal.goalCategoryId) &&
          goal.goalCategoryId > 0
        ) {
          _goalCategoryOptions = [
            makeGoalCategoryOption(
              goal.goalCategoryId,
              goal.goalCategory1,
              goal.goalCategory2,
              goal.goalCategory3,
              goal.goalCategory4,
            ),
            ..._goalCategoryOptions,
          ];
        }

        setGoalCategoryOptions(_goalCategoryOptions);
      });
  }, [keyword]);

  const handleSearchKeywordChange = useCallback(
    _.debounce((e, { searchQuery }) => {
      setKeyword(searchQuery);
    }, 300),
    [],
  );

  const handleChangeRestrictGoalCategories = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    { value: goalCategoryId }: DropdownProps,
  ) => {
    const gc = getGoalCategoryFromDropdownOption(
      goalCategoryId as number,
      goalCategoryOptions,
    );
    if (gc) {
      handleChange('goalCategoryId', gc.id);
    }
  };

  const showModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => setModalOpen(false), []);

  const handleGoalCategorySubmit = async (gc: GoalCategoryWithRestrict) => {
    const res = await apis.challenge.createGoalCategory({
      level1: gc.level1,
      level2: gc.level2,
      level3: gc.level3,
      level4: gc.level4,
      restrictGoalCategoryIds: gc.restrictGoalCategories.map((o) => o.id),
    });

    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    // 생성완료 후 바로 goal에 세팅해주기
    const { id: goalCategoryId } = res;

    const _goalCategoryOptions = [
      makeGoalCategoryOption(
        goalCategoryId,
        gc.level1,
        gc.level2,
        gc.level3,
        gc.level4,
      ),
      ...goalCategoryOptions,
    ];

    setGoalCategoryOptions(_goalCategoryOptions);
    handleChange('goalCategoryId', goalCategoryId);

    alert('목표카테고리 생성 및 설정이 완료되었어요.');

    closeModal();
  };

  return (
    <Grid columns="equal">
      <Grid.Column />
      <Grid.Column width={6}>
        <Header as="h3" textAlign="center">
          목표 {goal.id > 0 ? '수정' : '생성'}
        </Header>
        <Form>
          <h5>템플릿 선택</h5>
          <Form.Group inline>
            <Form.Field
              label="직접 설정"
              control="input"
              type="radio"
              checked={template === 'CUSTOM'}
              onChange={() => handleChangeTemplate('CUSTOM')}
            />
            <Form.Field
              label="최저가 구매 (올리브영, 네이버스마트스토어 등)"
              control="input"
              type="radio"
              checked={template === 'PURCHASE'}
              onChange={() => handleChangeTemplate('PURCHASE')}
            />
            <Form.Field
              label="제품/서비스 체험"
              control="input"
              type="radio"
              checked={template === 'EXPERIENCE'}
              onChange={() => handleChangeTemplate('EXPERIENCE')}
            />
          </Form.Group>
          <Divider />

          <Form.Field>
            <label>목표명</label>
            <input
              name="title"
              value={goal.title}
              onChange={(e) => handleChange('title', e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>목표 카테고리</label>
            <Dropdown
              fluid
              search
              selection
              options={goalCategoryOptions}
              value={goal.goalCategoryId}
              closeOnChange
              onSearchChange={handleSearchKeywordChange}
              onChange={handleChangeRestrictGoalCategories}
            />
          </Form.Field>

          <h5>목표 종류</h5>
          <Form.Group inline>
            <Form.Field
              label="기본"
              control="input"
              type="radio"
              checked={goal.goalType === 'NORMAL'}
              onChange={() => handleChange('goalType', 'NORMAL')}
            />
            <Form.Field
              label="걷기(챌린지 상세 > 만보기 노출)"
              control="input"
              type="radio"
              checked={goal.goalType === 'WALK'}
              onChange={() => handleChange('goalType', 'WALK')}
            />
            <Form.Field
              label="거리 측정(챌린지 상세 > 거리 달성)"
              control="input"
              type="radio"
              checked={goal.goalType === 'DISTANCE'}
              onChange={() => handleChange('goalType', 'DISTANCE')}
            />
          </Form.Group>

          <h5>주기</h5>
          <FlexBox.Row>
            <FlexBox.Column>
              <Form.Group grouped>
                <label>일 단위 / 기간 단위</label>
                <Form.Field
                  control={Radio}
                  label="일 단위"
                  checked={goal.dayOrPeriod === 'DAY'}
                  onChange={() => {
                    handleChange('dayOrPeriod', 'DAY');
                  }}
                  disabled={always}
                />
                <Form.Field
                  control={Radio}
                  label="기간 단위"
                  checked={goal.dayOrPeriod === 'PERIOD'}
                  onChange={() => {
                    handleChange('dayOrPeriod', 'PERIOD');
                  }}
                  disabled={always}
                />
                <>
                  <Form.Field
                    control={Checkbox}
                    label="상시"
                    name="always"
                    checked={always}
                    onChange={() => {
                      handleChange('always', !always);
                      setAlways(!always);
                    }}
                  />
                  <label
                    style={{
                      color: 'blue',
                    }}
                  >
                    *상시 옵션은 [회차별 인증 시간 설정]시 필요한 옵션입니다⚠️
                  </label>
                </>
              </Form.Group>
            </FlexBox.Column>
            <FlexBox.Column style={{ flex: 1 }}>
              <label>요일</label>
              <Dropdown
                placeholder="요일을 선택하세요."
                fluid
                search
                selection
                options={
                  always
                    ? [...goalCycleOptions].slice(7)
                    : goal.dayOrPeriod === 'DAY'
                      ? [...goalCycleOptions].slice(0, 3)
                      : [...goalCycleOptions].slice(3, 7)
                }
                value={convertCycleOptionValueFromCycle(
                  goal.cycle,
                  goal.dayOrPeriod,
                )}
                onChange={(e, { value }) => {
                  handleChange('cycle', value);
                }}
                disabled={always}
              />
            </FlexBox.Column>
          </FlexBox.Row>
          <Form.Field>
            <label>{always ? '총 인증 횟수' : '주기당 총 회수'}</label>
            <input
              value={goal.totalAchieveCountPerCycle}
              onChange={(e) => {
                if (!isNumber(e.target.value)) return;
                handleChange(
                  'totalAchieveCountPerCycle',
                  Number(e.target.value),
                );
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>하루 최대 인증 가능 회수</label>
            <input
              value={goal.maxAchieveCountPerDay}
              onChange={(e) => {
                if (!isNumber(e.target.value)) return;
                handleChange('maxAchieveCountPerDay', Number(e.target.value));
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>인증가능 시작 시간</label>
            <input
              value={goal.dueStartTime}
              onChange={(e) =>
                handleChange('dueStartTime', e.target.value.trim())
              }
              disabled={always}
            />
          </Form.Field>
          <Form.Field>
            <label>인증가능 종료 시간</label>
            <input
              value={goal.dueEndTime}
              onChange={(e) =>
                handleChange('dueEndTime', e.target.value.trim())
              }
              disabled={always}
            />
          </Form.Field>
          <Form.Group grouped>
            <label>공휴일 인증 필요 여부</label>
            <Form.Field
              control={Radio}
              label="공휴일 인증 필요"
              disabled={goal.dayOrPeriod === 'PERIOD' || always}
              checked={!goal.restInHoliday}
              onChange={() => handleChange('restInHoliday', false)}
            />
            <Form.Field
              control={Radio}
              label="공휴일 인증 안함"
              disabled={goal.dayOrPeriod === 'PERIOD' || always}
              checked={goal.restInHoliday}
              onChange={() => handleChange('restInHoliday', true)}
            />
          </Form.Group>
          <Form.Field>
            <label>인증샷간 최소 간격 (분)</label>
            <input
              value={goal.achievementIntervalMinute}
              onChange={(e) => {
                if (!isNumber(e.target.value)) return;
                handleChange(
                  'achievementIntervalMinute',
                  Number(e.target.value),
                );
              }}
              disabled={always}
            />
          </Form.Field>
          <Form.Group grouped>
            <label>카운트다운 적용 여부</label>
            <Form.Field
              control={Radio}
              label="적용"
              checked={goal.countDown}
              onChange={() => {
                handleChange('countDown', true);
              }}
              disabled={always}
            />
            <Form.Field
              control={Radio}
              label="미적용"
              checked={!goal.countDown}
              onChange={() => {
                handleChange('countDown', false);
              }}
              disabled={always}
            />
          </Form.Group>
          <Form.Field>
            <label>목표 기간</label>
            <input
              value={goal.totalDays}
              onChange={(e) => {
                if (!isNumber(e.target.value)) return;
                handleChange('totalDays', Number(e.target.value));
              }}
              disabled={always}
            />
          </Form.Field>
          <Form.Group grouped>
            <label>갤러리 활용 가능 여부</label>
            <Form.Field
              control={Radio}
              label="가능"
              checked={goal.isGalleryPossible}
              onChange={() => {
                handleChange('isGalleryPossible', true);
              }}
            />
            <Form.Field
              control={Radio}
              label="불가능"
              checked={!goal.isGalleryPossible}
              onChange={() => {
                handleChange('isGalleryPossible', false);
              }}
            />
          </Form.Group>
          <Form.Group grouped>
            <label>인증샷 전체 공개 여부</label>
            <Form.Field
              control={Radio}
              label="공개"
              checked={goal.isAchievementPublic}
              onChange={() => {
                handleChange('isAchievementPublic', true);
              }}
            />
            <Form.Field
              control={Radio}
              label="비공개"
              checked={!goal.isAchievementPublic}
              onChange={() => {
                handleChange('isAchievementPublic', false);
              }}
            />
          </Form.Group>
          <Form.Field>
            <label>인증 수치 질문</label>
            <input
              value={goal.targetQuestion}
              onChange={(e) => handleChange('targetQuestion', e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>인증 수치 이름</label>
            <input
              value={goal.targetText}
              onChange={(e) => handleChange('targetText', e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>인증 수치 단위</label>
            <input
              value={goal.unit}
              onChange={(e) => handleChange('unit', e.target.value)}
            />
          </Form.Field>

          <Divider style={{ marginBottom: 50 }} hidden />
          <div
            style={{
              width: '50%',
              bottom: 0,
              left: '25%',
              right: 0,
              position: 'fixed',
              borderTop: '1px solid #f6f6f6',
              backgroundColor: 'white',
              zIndex: 100,
            }}
          >
            <Button type="submit" fluid onClick={submit}>
              Submit
            </Button>
          </div>
        </Form>
      </Grid.Column>
      <Grid.Column>
        <Button onClick={showModal}>목표 카테고리 생성</Button>
        <p>
          * 해당 목표와 연결된 챌린지의 참가자가 존재할 경우 아래 항목들을
          변경하실 수 없습니다.
        </p>
        <p>
          * [일 단위/기간 단위], [요일], [주기당 총 횟수], [하루 최대 인증 가능
          횟수], [공휴일 인증 필요 여부], [인증가능시작시간], [인증가능종료시간]
        </p>
        {modalOpen && (
          <GoalCategoryCreateOrEditModal
            goalCategory={null}
            handleOnClose={closeModal}
            handleOnSubmit={handleGoalCategorySubmit}
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

export default GoalForm;

type GoalFormProps = {
  template: 'CUSTOM' | 'PURCHASE' | 'EXPERIENCE';
  handleChangeTemplate: (
    template: 'CUSTOM' | 'PURCHASE' | 'EXPERIENCE',
  ) => void;
  goal: GoalDetailInfo;
  handleChange: (key: keyof GoalDetailInfo | 'always', value: any) => void;
  submit: () => void;
};
