import { useShallow } from 'zustand/react/shallow';
import { ChallengeCollaboQuestion } from '@types';
import {
  RecommendedUpdates,
  useChallengeEditedStore,
  useChallengeFormStore,
} from '../../NewChallengeForm.store';
import { CustomFieldFormType } from '../../NewChallengeForm.types';

export const useCollaboration = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      collaboration: s.challenge.collaboration,
      thirdTermUrl: s.challenge.thirdTermUrl,
      companyId: s.challenge.companyId,
    })),
  );

export const useCollaborationAction = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const update = <T extends keyof CustomFieldFormType['collaboration']>(
    fieldName: T,
    value: CustomFieldFormType['collaboration'][T],
  ) => {
    updateChallengeForm((prev) => ({
      ...prev,
      collaboration: {
        ...prev.collaboration,
        [fieldName]: value,
      },
    }));
  };

  const setReviewRewardText = (text: string | null) =>
    update('reviewRewardText', text);

  const setCollaboProductName = (name: string | null) =>
    update('collaboProductName', name);

  const setCollaboProductLink = (link: string | null) =>
    update('collaboProductLink', link);

  const setReviewQuestionList = (list: ChallengeCollaboQuestion[]) =>
    update('reviewQuestionList', list);

  return {
    setReviewRewardText,
    setCollaboProductName,
    setCollaboProductLink,
    setReviewQuestionList,
  };
};
