import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { Col } from './Style';

const ProductDetailImagePreview = () => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Accordion style={{ width: '100%' }}>
      <Accordion.Title
        active={!collapsed}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Icon name="dropdown" />
        미리보기
      </Accordion.Title>
      <Accordion.Content active={!collapsed}>
        <Col>
          <strong>결제 화면 기념품 정보 보기</strong>
          <img
            src="/race/상품상세이미지1.png"
            width={300}
            alt="상품상세이미지1"
          />
        </Col>
      </Accordion.Content>
    </Accordion>
  );
};

export default ProductDetailImagePreview;
