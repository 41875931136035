import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useAuthenticationMethod = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      authenticationMethod: s.challenge.authenticationMethod,
    })),
  );
};

export const useAuthenticationMethodActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setAuthenticationMethod = (authenticationMethod: string) => {
    updateChallengeForm({ authenticationMethod });
  };

  return { setAuthenticationMethod };
};
