import { Header } from 'semantic-ui-react';
import DateTimeInput from '@component/DateTimeInput';
import Spacing from '@component/Spacing';
import TextInput from '@component/TextInput';
import ServiceCategorySelector from '@component/ServiceCategorySelector';
import FlexBox from '@component/FlexBox/FlexBox';
import PurchaseChannelSelector from '@component/PurchaseChannelSelector';
import { ServiceCategory, PurchaseChannel } from '@types';
import { formatTimeExcludeSeconds } from '@utils/date.utils';
import ButtonSetting from './ButtonSetting';
import {
  PreAlarmButton,
  PreAlarmAdditionalInfo,
  RecommentEditType,
} from '../PreAlarmForm.types';

const AlarmBeautyHotDealInfo = ({
  category,
  purchaseChannel,
  purchaseStartDate,
  purchaseEndDate,
  originalPrice = 0,
  price = 0,
  paybackAmount = 0,
  button,
  disabled,
  onChange,
  triggerEdited,
  getBorder,
}: Props) => {
  return (
    <>
      <Header as="h3">뷰티 득템 전환</Header>

      <Spacing vertical={10} />

      <FlexBox.Column style={{ maxWidth: '800px' }}>
        <ServiceCategorySelector
          value={category}
          onChange={(e) => onChange('category', e)}
        />
      </FlexBox.Column>

      <Spacing vertical={10} />

      <FlexBox.Column style={{ maxWidth: '800px' }}>
        <PurchaseChannelSelector
          value={purchaseChannel}
          onChange={(value) => onChange('purchaseChannel', value)}
        />
      </FlexBox.Column>

      <Spacing vertical={10} />

      <FlexBox.Row>
        <DateTimeInput
          label="구매일시 (시작)"
          value={purchaseStartDate || formatTimeExcludeSeconds(new Date())}
          onChange={(date) => {
            triggerEdited('purchaseStartDate');
            onChange(
              'purchaseStartDate',
              formatTimeExcludeSeconds(new Date(date)),
            );
          }}
          style={{ border: getBorder('purchaseStartDate'), width: '400px' }}
          disabled={disabled}
        />
        <DateTimeInput
          label="구매일시 (종료)"
          value={purchaseEndDate || formatTimeExcludeSeconds(new Date())}
          onChange={(date) => {
            triggerEdited('purchaseEndDate');
            onChange(
              'purchaseEndDate',
              formatTimeExcludeSeconds(new Date(date)),
            );
          }}
          style={{ border: getBorder('purchaseEndDate'), width: '400px' }}
          disabled={disabled}
        />
      </FlexBox.Row>

      <Spacing vertical={10} />

      <TextInput
        label="정가"
        value={originalPrice.toString()}
        onChange={(e) =>
          onChange('originalPrice', Number.isNaN(Number(e)) ? 0 : Number(e))
        }
        style={{ maxWidth: '800px' }}
        disabled={disabled}
      />

      <Spacing vertical={10} />

      <TextInput
        label="판매가"
        value={price.toString()}
        onChange={(e) =>
          onChange('price', Number.isNaN(Number(e)) ? 0 : Number(e))
        }
        style={{ maxWidth: '800px' }}
        disabled={disabled}
      />

      <Spacing vertical={10} />

      <TextInput
        label="페이백"
        value={paybackAmount.toString()}
        onChange={(e) =>
          onChange('paybackAmount', Number.isNaN(Number(e)) ? 0 : Number(e))
        }
        style={{ maxWidth: '800px' }}
        disabled={disabled}
      />

      <Spacing vertical={10} />

      <ButtonSetting button={button} onChange={onChange} />
    </>
  );
};

type Props = {
  category: ServiceCategory;
  purchaseChannel: PurchaseChannel;
  purchaseStartDate: string;
  purchaseEndDate: string;
  originalPrice?: number;
  price?: number;
  paybackAmount?: number;
  button: PreAlarmButton;
  disabled: boolean;
  onChange: <T extends keyof PreAlarmAdditionalInfo>(
    key: T,
    value?: PreAlarmAdditionalInfo[T],
  ) => void;
  triggerEdited: (key: keyof RecommentEditType) => void;
  getBorder: (key: keyof RecommentEditType) => string;
};

export default AlarmBeautyHotDealInfo;
