import styled from 'styled-components';
import { Form, Label } from 'semantic-ui-react';

export const Container = styled.div<{ margin?: string }>`
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const RowContainer = styled.div<{
  margin?: string;
  alignItems?: string;
  justifyContent?: string;
  gap?: string;
}>`
  display: flex;
  margin: ${(props) => props.margin && props.margin} !important;
  align-items: ${(props) => props.alignItems ?? 'flex-start'};
  justify-content: ${(props) => props.justifyContent ?? 'flex-start'};
  gap: ${(props) => props.gap ?? 0};
`;

export const Flex1 = styled.div<{ margin?: string }>`
  flex: 1;
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const Flex2 = styled.div<{ margin?: string }>`
  flex: 2;
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const RowFormField = styled(Form.Field)<{ margin?: string }>`
  flex: 1;
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const ButtonInForm = styled(Label)`
  margin-left: 10px;
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
`;

export const Badge = styled.div`
  border-radius: 4px;
  background-color: #ddd;
  color: #111;
  font-size: 12px;
  padding: 4px 8px;
  cursor: pointer;
  width: fit-content;
`;

export const BlueText = styled.span`
  color: #1400ff;
`;
