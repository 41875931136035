import React from 'react';
import * as S from './Label.style';

type LabelProps = React.HTMLAttributes<HTMLLabelElement> & {
  size?: 'large' | 'medium';
};

const styles = {
  large: { fontSize: 20 },
  medium: {},
};
export const Label = (props: LabelProps) => {
  const { size = 'medium', style } = props;
  return <S.Label {...props} style={{ ...styles[size], ...style }} />;
};
