import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'flex-end')};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'space-between'};
  margin: ${(props) => props.margin && props.margin};
  padding: ${(props) => props.padding && props.padding};
  gap: ${(props) => props.gap && props.gap};
`;
