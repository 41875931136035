import React from 'react';
import { Container, Responsive, Table, Image } from 'semantic-ui-react';
import _ from 'lodash';

const StickerChallengeTable = ({
  column,
  direction,
  stickerChallenges,
  handleSort,
}) => {
  if (!stickerChallenges) {
    return '';
  } else {
    return (
      <React.Fragment>
        <Container>
          <Container style={{ overflowX: 'auto', height: 700 }}>
            <Table basic="very" size="small" sortable structured>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={column === 'challengeId' ? direction : null}
                    onClick={handleSort('challengeId')}
                  >
                    챌린지id
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'challengeTitle' ? direction : null}
                    onClick={handleSort('challengeTitle')}
                  >
                    챌린지명
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'id' ? direction : null}
                    onClick={handleSort('id')}
                  >
                    스티커 목록
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {stickerChallenges.map((s) => {
                  const companies = _.groupBy(s.stickers, (s) => s.companyId);
                  return (
                    <Table.Row key={s.id}>
                      <Table.Cell collapsing>{s.id}</Table.Cell>
                      <Table.Cell collapsing>{s.title}</Table.Cell>
                      <Table.Cell collapsing>
                        {_.map(companies, (value, key) => (
                          <div
                            key={key}
                            style={{ display: 'inlineBlock', margin: 10 }}
                          >
                            <p>{value[0].companyName}</p>
                            <Image.Group size="tiny">
                              {_.map(value, (s) => (
                                <Image key={s.id} src={s.imageUrl} />
                              ))}
                            </Image.Group>
                          </div>
                        ))}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Container>
        </Container>
        <Container style={{ overflowX: 'auto', height: 700, marginTop: 50 }}>
          <Table basic="very" size="small" sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'challengeId' ? direction : null}
                  onClick={handleSort('challengeId')}
                >
                  챌린지id
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'challengeTitle' ? direction : null}
                  onClick={handleSort('challengeTitle')}
                >
                  챌린지명
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'id' ? direction : null}
                  onClick={handleSort('id')}
                >
                  스티커 목록
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {stickerChallenges.map((s) => {
                return (
                  <Table.Row key={s.id}>
                    <Table.Cell collapsing>{s.challengeId}</Table.Cell>
                    <Table.Cell collapsing>{s.challengeTitle}</Table.Cell>
                    <Table.Cell collapsing>
                      <Image.Group size="tiny">
                        {_.map(s.stickers, (s) => (
                          <Image key={s.id} src={s.imageUrl} />
                        ))}
                      </Image.Group>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Container>
      </React.Fragment>
    );
  }
};

export default StickerChallengeTable;
