import SideMenu from '../../../component/SideMenu/SideMenu';

const AchievementMenu = () => {
  const menus = [
    { text: '신고 인증샷', value: '/achievements' },
    { text: '제휴 챌린지 인증샷', value: '/collabo-achievements' },
  ];

  return <SideMenu menus={menus} />;
};

export default AchievementMenu;
