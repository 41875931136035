import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import GoalForm from '../GoalForm';
import { DEFAULT_GOAL } from '../Goal.constants';
import { GoalDetailInfo } from '@types';
import { apis } from '@api/index';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import { validateGoal } from '../Goal.utils';

const GoalCreate = () => {
  const [template, setTemplate] = useState<
    'CUSTOM' | 'PURCHASE' | 'EXPERIENCE'
  >('CUSTOM');
  const [goal, setGoal] = useState(DEFAULT_GOAL);

  const history = useHistory();

  const handleChangeTemplate = (
    _template: 'CUSTOM' | 'PURCHASE' | 'EXPERIENCE',
  ) => {
    const _goal = { ...goal };
    switch (_template) {
      case 'CUSTOM':
        break;
      case 'PURCHASE':
        _goal.cycle = ['CUSTOM'];
        _goal.dayOrPeriod = 'PERIOD';
        _goal.totalAchieveCountPerCycle = 3;
        _goal.maxAchieveCountPerDay = 2;
        _goal.totalDays = 0;
        _goal.isGalleryPossible = true;
        _goal.isAchievementPublic = false;
        break;
      case 'EXPERIENCE':
        _goal.cycle = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
        _goal.dayOrPeriod = 'PERIOD';
        _goal.totalAchieveCountPerCycle = 1;
        _goal.maxAchieveCountPerDay = 1;
        _goal.isGalleryPossible = true;
        _goal.isAchievementPublic = true;
        break;
      default:
        break;
    }

    setGoal(_goal);
    setTemplate(_template);
  };

  const handleChange = (key: keyof GoalDetailInfo | 'always', value: any) => {
    let _goal = { ...goal };
    if (key === 'cycle') {
      switch (value) {
        case 'EVERYDAY':
          _goal.cycle = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
          _goal.dayOrPeriod = 'DAY';
          break;
        case 'EVERY_DAY_OF_WEEK':
          _goal.cycle = ['MON', 'TUE', 'WED', 'THU', 'FRI'];
          _goal.dayOrPeriod = 'DAY';
          break;
        case 'EVERY_WEEKEND':
          _goal.cycle = ['SAT', 'SUN'];
          _goal.dayOrPeriod = 'DAY';
          break;
        case 'WEEKLY':
          _goal.cycle = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
          _goal.dayOrPeriod = 'PERIOD';
          break;
        case 'BIWEEKLY':
          _goal.cycle = ['BIWEEKLY'];
          _goal.dayOrPeriod = 'PERIOD';
          break;
        case 'MONTHLY':
          _goal.cycle = ['MONTHLY'];
          _goal.dayOrPeriod = 'PERIOD';
          break;
        case 'ALL':
          _goal.cycle = ['ALL'];
          _goal.dayOrPeriod = 'PERIOD';
          break;
        case 'CUSTOM':
          _goal.cycle = ['CUSTOM'];
          _goal.dayOrPeriod = 'PERIOD';
          break;
        default:
          break;
      }
    } else if (key === 'always') {
      if (value) {
        _goal.cycle = ['CUSTOM'];
        _goal.dayOrPeriod = 'PERIOD';
        _goal.totalDays = 0;
        _goal.dueStartTime = '00:00:00';
        _goal.dueEndTime = '00:00:00';
        _goal.countDown = false;
        _goal.achievementIntervalMinute = 0;
        _goal.maxAchieveCountPerDay = 2;
      } else {
        _goal.cycle = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
        _goal.dayOrPeriod = 'PERIOD';
        _goal.totalDays = 0;
      }
    } else {
      _goal = { ..._goal, [key]: value };
    }

    setGoal(_goal);
  };

  const submit = async () => {
    const errMsg = validateGoal(goal);
    if (errMsg.length > 0) {
      alert(errMsg);
      return;
    }

    LoadingIndicator.show();

    const res = await apis.challenge.createGoal({
      ...goal,
      dueStartTime: moment(goal.dueStartTime, 'HH:mm:ss').format('HH:mm:ss'),
      dueEndTime: moment(goal.dueEndTime, 'HH:mm:ss').format('HH:mm:ss'),
      targetText: goal.targetText || null,
      unit: goal.unit || null,
      targetQuestion: goal.targetQuestion || null,
    });

    if (res?.response?.data && 'err' in res?.response?.data) {
      LoadingIndicator.hide();
      return;
    }
    LoadingIndicator.hide();

    const { goalId } = res;
    history.push(`/goals/${goalId}`);
  };

  return (
    <GoalForm
      template={template}
      handleChangeTemplate={handleChangeTemplate}
      goal={goal}
      handleChange={(key, value) => handleChange(key, value)}
      submit={submit}
    />
  );
};

export default GoalCreate;
