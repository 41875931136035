import Accordion from '@component/Accordion/Accordion';
import { ChallengeCustomField } from 'src/types/customField.types';
import ErrorMsg from '@component/ErrorMsg';
import { ChallengeQueries } from '@queries/index';
import { useState } from 'react';
import Button from '@component/Button';
import FlexBox from '@component/FlexBox/FlexBox';
import { validate } from './QnaForm.validator';
import { useQna, useQnaAction } from './QnaForm.store';
import * as S from './QnaForm.style';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
  Question,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './QnaForm.serializer';
import {
  ADD_AFTER_GOAL_SET_TEXT,
  REMOVE_ALL_TEXT,
  SET_DEFAULT_TEXT,
} from './QnaForm.constant';
import QnaQuestionItem from './components/QnaQuestionItem';

const QnaForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'qna'>,
  Extract<ChallengeCustomField, { name: 'qna' }>
> = () => {
  const { qna, goalId, startDate, endDate } = useQna();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const { setQna } = useQnaAction();
  const { data } = ChallengeQueries.useGetAchievementCardList(
    {
      goalId: goalId || undefined,
      startDate,
      endDate,
    },
    { enabled: isOpened && Boolean(goalId) },
  );
  const validated = validate({
    qna,
    goalId,
    maxLength: data?.achievements.length,
  });

  const addOne = () => {
    if (!goalId) {
      window.alert(ADD_AFTER_GOAL_SET_TEXT);
      return;
    }
    if (!data) return;
    setQna([
      ...qna.questions,
      {
        achievementIndex: 0,
        question: [],
      },
    ]);
  };

  const setDefault = () => {
    if (!goalId) {
      window.alert(ADD_AFTER_GOAL_SET_TEXT);
      return;
    }
    if (!data) return;
    if (!window.confirm(SET_DEFAULT_TEXT)) return;
    setQna(
      data.achievements.map((_, index) => ({
        achievementIndex: index + 1,
        question: [],
      })),
    );
  };

  const remove = (index: number, achievementIndex: number) => {
    if (
      !window.confirm(
        `${achievementIndex}회차 질의응답 세팅 내용을 삭제하시겠습니까?`,
      )
    )
      return;
    setQna(qna.questions.filter((_, i) => i !== index));
  };

  const removeAll = () => {
    if (!window.confirm(REMOVE_ALL_TEXT)) return;
    setQna([]);
  };

  const updateAchievementIndex = (index: number, achievementIndex: number) => {
    const updatedQuestions = [...qna.questions];
    updatedQuestions[index] = {
      achievementIndex,
      question: updatedQuestions[index].question,
    };
    setQna(updatedQuestions);
  };

  const addQuestion = (index: number, question: Omit<Question, 'id'>) => {
    const updatedQuestions = [...qna.questions];
    updatedQuestions[index] = {
      achievementIndex: updatedQuestions[index].achievementIndex,
      question: [...updatedQuestions[index].question, question].map(
        (questionItem, questionIndex) => ({
          ...questionItem,
          id: questionIndex + 1,
        }),
      ) as Question[],
    };
    setQna(updatedQuestions);
  };

  const updateQuestion = <T extends keyof Question>(
    index: number,
    {
      questionIndex,
      fieldName,
      value,
    }: { questionIndex: number; fieldName: T; value: Question[T] },
  ) => {
    const updatedQuestions = [...qna.questions];
    const questions = [...updatedQuestions[index].question];
    questions[questionIndex] = {
      ...questions[questionIndex],
      [fieldName]: value,
    };
    updatedQuestions[index] = {
      achievementIndex: updatedQuestions[index].achievementIndex,
      question: questions,
    };
    setQna(updatedQuestions);
  };

  return (
    <Accordion
      open={isOpened}
      title="질의응답 세팅 (참가자 있을 경우 수정 불가, 개발그룹 문의 필요)"
      as="h5"
      onChange={setIsOpened}
    >
      <S.Container>
        <ErrorMsg text={!validated.isValid ? validated.message : ''} />
        <FlexBox.Row gap={12}>
          <Button text="인증회차 추가" onClick={addOne} />
          <Button text="인증회차 기본세팅" onClick={setDefault} />
          <Button text="전체 삭제" onClick={removeAll} />
        </FlexBox.Row>
        {qna.questions.map((item, index) => {
          const { achievementIndex, question } = item;
          return (
            <QnaQuestionItem
              achievementIndex={achievementIndex}
              questions={question}
              onAchievementIndexChange={(updatedAchievementIndex: number) =>
                updateAchievementIndex(index, updatedAchievementIndex)
              }
              onQuestionAdd={(q) => addQuestion(index, q)}
              onQuestionUpdate={({ fieldName, value, questionIndex }) =>
                updateQuestion(index, {
                  questionIndex,
                  fieldName,
                  value,
                })
              }
              onRemove={() => remove(index, achievementIndex)}
            />
          );
        })}
      </S.Container>
    </Accordion>
  );
};

export default QnaForm;
QnaForm.validate = validate;
QnaForm.convertData2Form = convertData2Form;
QnaForm.convertForm2Data = convertForm2Data;
