import { DescriptionCollaboSpecialPrizeModuleFormType } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { CollaboSpecialPrizeModule } from '@chlngers/core/lib/src/types/challenge/challengeDescriptionModule.types';

export const convertData2Form: (
  module: CollaboSpecialPrizeModule,
) => DescriptionCollaboSpecialPrizeModuleFormType = ({ data }) => {
  return {
    type: 'COLLABO-SPECIAL-PRIZE',
    data: {
      type: data.type as DescriptionCollaboSpecialPrizeModuleFormType['data']['type'],
      title:
        data.title as DescriptionCollaboSpecialPrizeModuleFormType['data']['title'],
      noticeList: data.noticeList,
    },
  };
};

export const convertForm2Data: (
  module: DescriptionCollaboSpecialPrizeModuleFormType,
) => CollaboSpecialPrizeModule = ({ data }) => {
  return {
    type: 'COLLABO-SPECIAL-PRIZE',
    data,
  };
};
