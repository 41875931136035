import NumberInput from '@component/NumberInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  useMaxRegisterCount,
  useMaxRegisterCountActions,
} from './MaxRegisterCountForm.store';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './MaxRegisterCountForm.serializer';
import { validate } from './MaxRegisterCountForm.validator';
import { useEditCheck } from '../../hooks/useEditCheck';

const INFINITE_REGISTER_COUNT = 99999;

const MaxRegisterCountForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'maxRegisterCount'>,
  Pick<ChallengeFormType, 'maxRegisterCount'>,
  Pick<ReqCreateOrUpdateChallenge, 'maxRegisterCount'>
> = () => {
  const { maxRegisterCount } = useMaxRegisterCount();
  const { setMaxRegisterCount } = useMaxRegisterCountActions();
  const validated = validate({ maxRegisterCount });

  const { triggerEdited, getBorder } = useEditCheck();

  const handleChange = (value: number | null) => {
    triggerEdited('maxRegisterCount');
    setMaxRegisterCount(value ?? INFINITE_REGISTER_COUNT);
  };

  return (
    <NumberInput
      label={`모집최대인원 (${INFINITE_REGISTER_COUNT}로 입력하면 인원 제한 없음)`}
      name="maxRegisterCount"
      value={maxRegisterCount}
      onChange={handleChange}
      errorMsg={validated.isValid ? '' : validated.message}
      style={{ border: getBorder('maxRegisterCount') }}
    />
  );
};

export default MaxRegisterCountForm;

MaxRegisterCountForm.validate = validate;
MaxRegisterCountForm.convertData2Form = convertData2Form;
MaxRegisterCountForm.convertForm2Data = convertForm2Data;
