import ErrorMsg from '@component/ErrorMsg';
import React, { forwardRef } from 'react';
import * as S from './TimeInput.style';

type TimeInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type' | 'placeholder' | 'onChange' | 'value'
> & {
  label?: string;
  value?: string;
  containerStyle?: React.CSSProperties;
  errorMsg?: string;
  onChange?: (value: string) => void;
};

export type TimeInputRef = HTMLInputElement;

const TimeInput = forwardRef<TimeInputRef, TimeInputProps>((props, ref) => {
  const { containerStyle, label, errorMsg, onChange, value, ...restProps } =
    props;

  const handleDateTimeChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    onChange?.(e.target.value);
  };

  return (
    <S.Container style={containerStyle}>
      {label ? <S.Label>{label}</S.Label> : <></>}
      <S.Input
        ref={ref}
        type="time"
        step="1"
        value={value}
        onChange={handleDateTimeChange}
        {...restProps}
      />
      <ErrorMsg text={errorMsg} />
    </S.Container>
  );
});

export default TimeInput;
