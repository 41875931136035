import { Container, Divider } from 'semantic-ui-react';

import RewardTax from './RewardTax';
import UserSearch from './UserSearch';
import UserRestrict from './UserRestrict';
import Withdraw from './Withdraw';
import TransactionTransferOldCardCash from './TransactionTransferOldCardCash';
import Ban from './Ban';

const NewUser = () => {
  return (
    <Container>
      {/* 유저 검색 */}
      <UserSearch />
      <Divider hidden />

      {/* 유저 제한 정보 */}
      <UserRestrict />
      <Divider hidden />

      {/* 제세공과금 */}
      <RewardTax />
      <Divider hidden />

      {/* 출금 요청 */}
      <Withdraw />
      <Divider hidden />

      {/* 1년 경과 출금 요청 */}
      <TransactionTransferOldCardCash />
      <Divider hidden />

      {/* 사용 금지 유저 */}
      <Ban />
      <Divider hidden />
      <Divider hidden />
    </Container>
  );
};

export default NewUser;
