import { useGetGoalDetail } from '@queries/Challenge/useGetGoalDetail';
import { GoalDetailInfo } from '@types';
import { useChallengeFormStore } from '../NewChallengeForm.store';

// 현재 상시 골 챌린지인지 확인하는 hook
export const useIsCustomGoal = ():
  | {
      isCustomGoal: true;
      goal: GoalDetailInfo;
    }
  | {
      isCustomGoal: false;
      goal: GoalDetailInfo | null;
    } => {
  const goalId = useChallengeFormStore((s) => s.challenge.goalId);
  const { data: goal } = useGetGoalDetail(goalId || 0);
  if (!goal) return { isCustomGoal: false, goal: null };

  return { isCustomGoal: goal.cycle.includes('CUSTOM'), goal };
};
