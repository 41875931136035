import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useStartDateStore = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      startDate: s.challenge.startDate,
      endDate: s.challenge.endDate,
    })),
  );

export const useUpdateStartDate = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const updateStartDate = (startDate: string) => {
    updateChallengeForm({ startDate });
  };

  return { updateStartDate };
};
