import { useChallengeFormStore } from '../../NewChallengeForm.store';
import { useShallow } from 'zustand/react/shallow';
import { Achievements } from 'src/types/achievement.types';
import { ChallengeDistanceCustomField } from '@types';

export const useChallengeDistanceStore = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      distance: s.challenge.distance,
      goalId: s.challenge.goalId,
      startDate: s.challenge.startDate,
      endDate: s.challenge.endDate,
    })),
  );
};

export const useChallengeDistanceActions = (achievements: Achievements[]) => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setDistance = (
    value: Pick<ChallengeDistanceCustomField['values'][0], 'goal' | 'unit'>,
  ) => {
    const newDistance: ChallengeDistanceCustomField['values'] =
      achievements.map((_, idx) => ({
        achievementIndex: idx + 1,
        unit: value.unit,
        goal: value.goal,
      }));

    updateChallengeForm((prev) => ({ ...prev, distance: newDistance }));
  };

  const clear = () => {
    updateChallengeForm((prev) => ({ ...prev, distance: [] }));
  };

  return { setDistance, clear };
};
