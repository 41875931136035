import {
  ChallengeCustomField,
  ChallengeUseItemImpossibleCustomField,
} from '@types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'useItemImpossible'>,
  Extract<ChallengeCustomField, { name: 'useItemImpossible' }>
> = ({ useItemImpossible }) => {
  if (!useItemImpossible) return [];
  return [{ name: 'useItemImpossible', values: useItemImpossible }];
};

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'useItemImpossible'>
> = (customFields) => {
  const useItemImpossible = customFields.find(
    ({ name }) => name === 'useItemImpossible',
  );

  if (!useItemImpossible) return {} as EmptyObj;

  const { values } = useItemImpossible as ChallengeUseItemImpossibleCustomField;

  return { useItemImpossible: values };
};
