type ChallengeQueryKeys = {
  GET_CHALLENGE_APP_CATEGORIES: undefined;
  CHALLENGE_OPTIONS: undefined;
  GET_CHALLENGE: number;
  GET_GOAL_DETAIL: number;
  GET_GOALS: { keyword?: string; isOfficial?: boolean };
  GET_TAGS: undefined;
  GET_ACHIEVEMENT_CARD_LIST: {
    goalId: number;
    startDate: string;
    endDate: string;
  };
};

export const getQueryKey = <T extends keyof ChallengeQueryKeys>(
  ...[key, params]: undefined extends ChallengeQueryKeys[T]
    ? [T]
    : [T, ChallengeQueryKeys[T] | 'KEY_ONLY']
) => {
  if (params === 'KEY_ONLY') return [key];
  return params ? [key, params] : [key];
};
