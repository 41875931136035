import FlexBox from '@component/FlexBox/FlexBox';
import { ToggleSwitch } from '@component/ToggleSwitch';
import {
  useAchievementInfoList,
  useUpdateAchievementInfoList,
} from '../../AchievementInfoListForm.store';

const IsPublicCheckBox = () => {
  const infoList = useAchievementInfoList();
  const { update } = useUpdateAchievementInfoList();

  const isAllChecked =
    !!infoList?.length && infoList.every((info) => !!info.isPublic);

  const handleChange = (isPublic: boolean) => {
    if (!infoList) return;
    update(infoList.map((info) => ({ ...info, isPublic })));
  };

  return (
    <FlexBox.Row gap={12}>
      <ToggleSwitch value={isAllChecked} onChange={handleChange} />
      <span>인증 샷 전체 공개 여부</span>
    </FlexBox.Row>
  );
};

export default IsPublicCheckBox;
