import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import TextInput from '@component/TextInput';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import { convertData2Form, convertForm2Data } from './TitleForm.serializer';
import { useTitle, useTitleActions } from './TitleForm.store';
import { validate } from './TitleForm.validator';

const TitleForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'title'>,
  Pick<ChallengeFormType, 'title'>,
  Pick<ReqCreateOrUpdateChallenge, 'title'>
> = () => {
  const { title } = useTitle();

  const { setTitle } = useTitleActions();

  const validated = validate({ title });

  return (
    <>
      <TextInput
        label="챌린지명"
        value={title}
        onChange={setTitle}
        errorMsg={!validated.isValid ? validated.message : ''}
      />
    </>
  );
};

export default TitleForm;

TitleForm.validate = validate;
TitleForm.convertData2Form = convertData2Form;
TitleForm.convertForm2Data = convertForm2Data;
