import Button from '@component/Button';
import FlexBox from '@component/FlexBox/FlexBox';
import { useState } from 'react';
import { Table } from 'semantic-ui-react';
import { useCommerce, useCommerceActions } from '../../CommerceForm.store';
import ProductAddModal from '../ProductAddModal/ProductAddModal';
import ProductItem from '../ProductItem/ProductItem';

const ProductList = () => {
  const { rccps } = useCommerce();
  const { addProduct } = useCommerceActions();
  const [showModal, setShowModal] = useState(false);

  return (
    <FlexBox.Column>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>상품명</Table.HeaderCell>
            <Table.HeaderCell>회사</Table.HeaderCell>
            <Table.HeaderCell>가격</Table.HeaderCell>
            <Table.HeaderCell>배송비</Table.HeaderCell>
            <Table.HeaderCell>단위</Table.HeaderCell>
            <Table.HeaderCell>전체수량</Table.HeaderCell>
            <Table.HeaderCell>수정</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rccps.map((rccp, index) => (
            <ProductItem key={index} rccp={rccp} index={index} />
          ))}
        </Table.Body>
      </Table>

      <Button
        text="상품 추가"
        size="medium"
        style={{ width: 120 }}
        onClick={() => setShowModal(true)}
      />
      <ProductAddModal
        isOpened={showModal}
        onClose={() => setShowModal(false)}
        onAdd={addProduct}
      />
    </FlexBox.Column>
  );
};

export default ProductList;
