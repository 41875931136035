import styled from 'styled-components';

export const CountWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CountRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
