import FlexBox from '@component/FlexBox/FlexBox';
import { useGetLabels } from '@queries/common/useLabels';
import { CrmSendType, CrmTemplateInfo } from '@types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Dimmer,
  Divider,
  Form,
  Grid,
  Header,
  Label,
  Loader,
} from 'semantic-ui-react';
import LabelMenu from '../LabelMenu/LabelMenu';
import {
  TEMPLATE_FIELD_VALUES,
  TIME_POINT_VALUES,
  initialTemplatInfoList,
} from '../LabelTab.constants';
import * as S from './LabelCrmCreate.styles';
import { apis } from '@api/index';

const LabelCrmCreate: React.FC = () => {
  const history = useHistory();
  const [selectedLabelId, setSelectedLabelId] = useState<number | null>(null);
  const { data, isLoading } = useGetLabels();
  const labelList = data?.labels || [];

  const [templateInfoList, setTemplateInfoList] = useState<CrmTemplateInfo[]>([
    ...initialTemplatInfoList,
  ]);

  const handleCreateTemplate = async () => {
    if (!selectedLabelId) {
      alert('라벨을 다시 선택해주세요');
      return;
    }

    apis
      .createCrmLabel({
        templateInfo: templateInfoList,
        labelId: selectedLabelId,
        isActive: true,
      })
      .then(({ success }) => {
        if (success) history.push(`/labels-crm-template`);
      });
  };

  const handleChangeTemplateInfo = <K extends keyof CrmTemplateInfo>(
    index: number,
    key: K,
    value: CrmTemplateInfo[K],
  ) => {
    setTemplateInfoList((templateInfo) => {
      const newTemplateInfo = [...templateInfo];
      newTemplateInfo[index][key] = value;
      return newTemplateInfo;
    });
  };

  if (isLoading) {
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <div style={{ margin: 40 }}>
      <Grid columns="equal">
        <Grid.Column width={2}>
          <LabelMenu />
        </Grid.Column>

        <Grid.Column>
          <Header.Subheader as="h3">템플릿 추가</Header.Subheader>
          <Divider />

          <FlexBox.Column gap={8} style={{ marginBottom: 20 }}>
            <Form.Field>
              <Header.Subheader as="h3">라벨 선택</Header.Subheader>
              <input
                name="selectedLabelId"
                id="selectedLabelId"
                list="label-options"
                placeholder="라벨 선택"
                onChange={(e) => {
                  setSelectedLabelId(Number(e.target.value));
                }}
              />
              <datalist id="label-options">
                {labelList.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </datalist>
            </Form.Field>
          </FlexBox.Column>

          <FlexBox.Column gap={8} style={{ marginBottom: 20 }}>
            <Header.Subheader as="h3">템플릿 가이드</Header.Subheader>
            <Header.Content
              style={{
                color: 'blue',
                marginBottom: 8,
              }}
            >
              {
                '⚠️ 아래의 템플릿에 맞게 작성해주세요.\n추가하고 싶은 템플릿이 있다면 개발 그룹에 따로 알려주세요 ⚠️'
              }
            </Header.Content>
            <div
              style={{
                border: '1px solid black',
                padding: 8,
                borderRadius: 4,
              }}
            >
              {TEMPLATE_FIELD_VALUES.map((field) => (
                <FlexBox.Row
                  key={field.value}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  {field.text} : {`{{.${field.value}}}`}
                </FlexBox.Row>
              ))}
            </div>
            <Header.Content
              style={{
                color: 'red',
                marginTop: 8,
              }}
              as="h4"
            >
              {'{{.Name}}, {{.NickName}}은 동시 사용 불가능🙅‍♀️'}
            </Header.Content>
            <Header.Content
              style={{
                color: 'gray',
              }}
              as="h4"
            >
              {'[사용 예시] {{.NickName}}님 안녕하세요!'}
            </Header.Content>
          </FlexBox.Column>
          <Divider />

          {templateInfoList.map((info, index) => (
            <div key={`TEMPLATE_INFO_${info.timePoint}`}>
              <FlexBox.Column gap={8} style={{ marginBottom: 20 }}>
                {/* time point */}
                <FlexBox.Row gap={40}>
                  <Form.Field>
                    <Header.Subheader as="h4">시점</Header.Subheader>
                    <Label>
                      {TIME_POINT_VALUES.find(
                        ({ value }) => value === info.timePoint,
                      )?.text || ''}
                    </Label>
                  </Form.Field>

                  {/* CRM 타입 */}
                  <Form.Field>
                    <Header.Subheader as="h4">CRM 타입</Header.Subheader>
                    <FlexBox.Row gap={20}>
                      <Form.Field>
                        <Checkbox
                          radio
                          label="문자"
                          name="sendType"
                          value="SMS"
                          checked={info.sendType === 'SMS'}
                          onChange={(e, { value }) =>
                            handleChangeTemplateInfo(
                              index,
                              'sendType',
                              value as CrmSendType,
                            )
                          }
                        />
                      </Form.Field>
                      <Form.Field>
                        <Checkbox
                          radio
                          label="푸시"
                          name="sendType"
                          value="PUSH"
                          checked={info.sendType === 'PUSH'}
                          onChange={(e, { value }) =>
                            handleChangeTemplateInfo(
                              index,
                              'sendType',
                              value as CrmSendType,
                            )
                          }
                        />
                      </Form.Field>
                    </FlexBox.Row>
                  </Form.Field>
                </FlexBox.Row>

                {/* 제목 */}
                <Form.Field>
                  <Header.Subheader as="h4">제목</Header.Subheader>
                  <S.TemplateContentInput
                    name="title"
                    placeholder="제목"
                    onChange={(e) => {
                      handleChangeTemplateInfo(index, 'title', e.target.value);
                    }}
                  />
                </Form.Field>

                {/* 내용 */}
                <Form.Field>
                  <Header.Subheader as="h4">내용</Header.Subheader>
                  <S.TemplateContentInput
                    rows={10}
                    name="template"
                    placeholder="내용"
                    onChange={(e) => {
                      handleChangeTemplateInfo(
                        index,
                        'template',
                        e.target.value,
                      );
                    }}
                  />
                </Form.Field>
              </FlexBox.Column>
            </div>
          ))}

          <Divider />
          <Button
            color="blue"
            content="완료"
            type="submit"
            fluid
            onClick={handleCreateTemplate}
          />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default React.memo(LabelCrmCreate);
