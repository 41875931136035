import { apis } from '@api/index';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';

import { FC, useState, KeyboardEvent, useCallback } from 'react';
import {
  Button,
  Dropdown,
  Form,
  Header,
  Input,
  Modal,
} from 'semantic-ui-react';
import { UserSearchInfo } from 'src/types/user.types';
import { searchTypeOptions } from '../../../utils/dropdownOptions';
import resizeImage from '../../../global/resizeImage';
import { chlngersUrl } from '../../../constant/imgUrls';
import UserAll from '../UserAll';

type UserCreateInfo = {
  email: string;
  name: string;
  phone: string;
};

type RecommendInfo = {
  requestUserId: number;
  recommendeeNickname: string;
};

const UserSearch: FC = () => {
  const [searchType, setSearchType] = useState<string>('nickname');
  const [keyword, setSearchKeyword] = useState<string>('');
  const [users, setUsers] = useState<UserSearchInfo[]>([]);
  const [user, setUser] = useState<UserCreateInfo>({
    email: '',
    name: '',
    phone: '',
  });
  const [recommendInfo, setRecommendInfo] = useState<RecommendInfo>({
    requestUserId: 0,
    recommendeeNickname: '',
  });
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [recommendModalOpen, setRecommendModalOpen] = useState<boolean>(false);

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      searchUser();
    }
  };

  const searchUser = async () => {
    if (!keyword) {
      alert('검색어를 입력해주세요');
      return;
    }
    if (searchType === 'id') {
      window.open(`/users/${keyword}`, '_blank');
    } else {
      LoadingIndicator.show();
      const res = await apis.user.searchUsers({
        searchType,
        keyword,
      });
      const _users = res.users.map((o) => {
        const pictureUrl = o.pictureUrl === '' ? chlngersUrl : o.pictureUrl;
        return { ...o, pictureUrl: resizeImage(pictureUrl, 80) };
      });
      setUsers(_users);
      LoadingIndicator.hide();
    }
  };

  const showCreateModal = useCallback(() => setCreateModalOpen(true), []);
  const closeCreateModal = useCallback(() => setCreateModalOpen(false), []);

  const showRecommendModal = useCallback(() => setRecommendModalOpen(true), []);
  const closeRecommendModal = useCallback(
    () => setRecommendModalOpen(false),
    [],
  );

  const handleChangeUser = (key: keyof UserCreateInfo, value: string) => {
    const _user = { ...user, [key]: value };
    setUser(_user);
  };

  const createUser = async () => {
    const res = await apis.user.createUser({
      email: user.email,
      password: '12341234',
      name: user.name,
      phone: user.phone,
      birthday: '1990-01-01',
      sex: 'UNKNOWN',
    });
    closeCreateModal();
    alert(
      `[${res.user.id}] ${user.email} / ${res.user.nickname} 계정 생성 완료`,
    );
    setUser({ email: '', name: '', phone: '' });
  };

  const handleChangeRecommendInfo = (
    key: keyof RecommendInfo,
    value: string | number,
  ) => {
    const _recommendInfo = { ...recommendInfo, [key]: value };
    setRecommendInfo(_recommendInfo);
  };

  const getUserRecommends = async () => {
    if (!recommendInfo.requestUserId) {
      alert('검색어를 입력해주세요');
      return;
    }
    const res = await apis.user.getRecommendeeInfo({
      recommenderId: recommendInfo.requestUserId,
    });
    if (!res.user.id) {
      alert('추천인으로 입력한 정보가 없습니다.');
      return;
    }
    alert(
      `[${res.user.userId}] ${res.user.nickname} 을(를) 추천인으로 입력하셨어요.`,
    );
  };

  const registerRecommend = async () => {
    const { ok } = await apis.user.registerRecommend({
      recommenderUserId: recommendInfo.requestUserId,
      recommendeeNickname: recommendInfo.recommendeeNickname,
    });
    closeRecommendModal();
    if (ok) {
      alert('추천인 등록 완료되었습니다.');
    } else {
      alert('추천인 등록 실패. 추천인 아이디와 닉네임을 확인해주세요.');
    }
    setRecommendInfo({ requestUserId: 0, recommendeeNickname: '' });
  };

  const syncUsers = (newUsers: UserSearchInfo[]) => {
    setUsers(newUsers);
  };

  return (
    <>
      <Header>유저검색</Header>
      <Dropdown
        placeholder="이름/닉네임/이메일/전화번호"
        selection
        value={searchType}
        options={searchTypeOptions}
        onChange={(_, { value }) => setSearchType(String(value))}
      />
      <Input
        icon="users"
        iconPosition="left"
        placeholder="검색어 입력"
        onChange={(e) => setSearchKeyword(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <Button size="tiny" onClick={searchUser}>
        검색
      </Button>
      <Button size="tiny" onClick={showCreateModal}>
        생성
      </Button>
      <Button size="tiny" onClick={showRecommendModal}>
        추천인
      </Button>

      <UserAll users={users} syncUsers={syncUsers} />

      <Modal size="small" open={createModalOpen} onClose={closeCreateModal}>
        <Modal.Header>유저 생성</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>이메일</label>
              <input
                onChange={(e) => handleChangeUser('email', e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>이름</label>
              <input
                onChange={(e) => handleChangeUser('name', e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>핸드폰</label>
              <input
                onChange={(e) => handleChangeUser('phone', e.target.value)}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="생성" onClick={createUser} />
          <Button color="black" content="닫기" onClick={closeCreateModal} />
        </Modal.Actions>
      </Modal>
      <Modal
        size="small"
        open={recommendModalOpen}
        onClose={closeRecommendModal}
      >
        <Modal.Header>추천인</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>신청자 유저 id</label>
              <input
                onChange={(e) =>
                  handleChangeRecommendInfo(
                    'requestUserId',
                    Number(e.target.value),
                  )
                }
              />
            </Form.Field>
            <Form.Field>
              <label>추천인 닉네임</label>
              <input
                onChange={(e) =>
                  handleChangeRecommendInfo(
                    'recommendeeNickname',
                    e.target.value,
                  )
                }
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="조회" onClick={getUserRecommends} />
          <Button color="blue" content="추가" onClick={registerRecommend} />
          <Button color="black" content="닫기" onClick={closeRecommendModal} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default UserSearch;
