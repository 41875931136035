import TextInput from '@component/TextInput';
import { FC } from 'react';

type Props = {
  to: string;
  onChange: (to: string) => void;
};
const ReceiverForm: FC<Props> = (props) => {
  const { to, onChange } = props;

  return (
    <TextInput label="추가리워드 받는 대상" value={to} onChange={onChange} />
  );
};

export default ReceiverForm;
