import React, { useEffect, useState } from 'react';
import BenefitMenu from '../BenefitMenu';
import { useHistory } from 'react-router-dom';
import { Grid, Button, Label } from 'semantic-ui-react';
import * as S from './style';
import { getGotchaColor, getGotchaRewardType, getGotchaType } from './utils';
import { apis } from '../../../api';

const GotchaList = () => {
  const [gotchas, setGotchas] = useState([]);

  const history = useHistory();

  useEffect(() => {
    apis.getGotchas().then(({ gotchas }) => {
      setGotchas(gotchas);
    });
  }, []);

  return (
    <div style={{ margin: 40 }}>
      <Grid columns="equal">
        <Grid.Column width={2}>
          <BenefitMenu />
        </Grid.Column>
        <Grid.Column>
          {gotchas.map((gotcha) => (
            <GotchaItem gotcha={gotcha} key={gotcha.id} />
          ))}
        </Grid.Column>

        <Grid.Row style={{ width: '100%', justifyContent: 'center' }}>
          <Button
            content="새로 생성"
            color={'black'}
            size={'large'}
            onClick={() => {
              history.push(`/gotcha/create`);
            }}
          />
        </Grid.Row>
      </Grid>
    </div>
  );
};

const GotchaItem = ({ gotcha }) => {
  const history = useHistory();

  return (
    <S.Container>
      <S.Row>
        <S.Thumb src={gotcha.thumbnailImageUrl} />
        <S.Col>
          <S.Title style={{ fontWeight: 'bold' }}>{gotcha.reward}</S.Title>
          <S.Title>ID: {gotcha.id}</S.Title>
          <S.Title>응모비: {`${gotcha.price.toLocaleString()}원`}</S.Title>
          <S.Title>브랜드: {gotcha.info.description}</S.Title>
          <S.Title>당첨확률: {gotcha.winRate}%</S.Title>
          <S.Title>
            당첨 인원제한:{' '}
            {gotcha.info.userLimit ? `${gotcha.info.userLimit}명` : `제한없음`}
          </S.Title>

          <S.Title>
            지급방식:
            <Label
              color={getGotchaColor(gotcha.gotchaType)}
              style={{ marginLeft: 6 }}
            >
              {getGotchaType(gotcha.gotchaType)}
            </Label>
          </S.Title>

          {!!gotcha.info.instantReward && (
            <>
              <S.Title>
                👉 지급종류:{' '}
                {getGotchaRewardType(gotcha.info.instantReward.rewardType)}
              </S.Title>
              <S.Title>
                👉 지급내용:{' '}
                {gotcha.info.instantReward.rewardAmount.toLocaleString()}
                {`(원/개)`}
              </S.Title>
            </>
          )}
          <S.Row style={{ marginTop: 12 }}>
            <Button
              content="수정하기"
              onClick={() => {
                history.push(`/gotcha/${gotcha.id}/edit`);
              }}
            />
            <Button
              content={gotcha.isDisplayed ? '비공개하기' : '공개하기'}
              color={gotcha.isDisplayed ? undefined : 'black'}
              onClick={async () => {
                const confirmed = window.confirm(
                  (gotcha.isDisplayed ? '비공개' : '공개') +
                    '처리를 진행 하시겠어요?',
                );

                if (!confirmed) return;

                await apis.editGotchas(gotcha.id, {
                  ...gotcha,
                  isDisplayed: !gotcha.isDisplayed,
                });

                history.go(0);

                window.alert('완료했어요😊');
              }}
            />
          </S.Row>
        </S.Col>
      </S.Row>
    </S.Container>
  );
};

export default GotchaList;
