export const types = {
  SET_USER__APP: 'SET_USER__APP',
  SET_TOKEN__APP: 'SET_TOKEN__APP',
  SET_ROLES__APP: 'SET_ROLES__APP',
};

export const setToken = (token) => ({
  type: types.SET_TOKEN__APP,
  token,
});

export const setRoles = (roles) => ({
  type: types.SET_ROLES__APP,
  roles,
});

export const setUser = (user) => ({
  type: types.SET_USER__APP,
  user,
});
