import { useGetGoalDetail } from '@queries/Challenge/useGetGoalDetail';
import { useShallow } from 'zustand/react/shallow';
import GoalItemDetail from '../GoalItemDetail';
import { useChallengeFormStore } from '../NewChallengeForm/NewChallengeForm.store';

const GoalDetailInfo = () => {
  const goalId = useChallengeFormStore((s) => s.challenge.goalId);
  const challenge = useChallengeFormStore(useShallow((s) => s.challenge));
  const { isLoading, data: goal } = useGetGoalDetail(goalId || 0);

  if (isLoading || !goal) return <></>;
  return (
    <GoalItemDetail
      goal={goal}
      challenge={challenge}
      achievementInfoList={challenge.achievementInfoList}
    />
  );
};

export default GoalDetailInfo;
