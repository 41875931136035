import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-shrink: 0;
  min-width: 160px;
`;

export const Input = styled.input`
  padding: 8px 12px;
`;

export const Label = styled.label`
  font-weight: bold;
`;
