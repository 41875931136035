import React, { useEffect, useState } from 'react';
import { Button, Form, Radio } from 'semantic-ui-react';
import { apis } from '../../../api';

const SmsProvider = () => {
  const [smsProvider, setSmsProvider] = useState('infobank');

  useEffect(() => {
    _getSmsProvider();
  }, []);

  const _getSmsProvider = async () => {
    const res = await apis.getAppSettingSmsProvider();
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    const { smsProvider } = res;
    setSmsProvider(smsProvider);
  };

  const handleChange = (_, { value }) => {
    setSmsProvider(value);
  };

  const updateSmsProvider = async () => {
    // TODO: api 작업 후 반영 필요
    const res = await apis.updateAppSettingSmsProvider({ smsProvider });
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    alert('변경이 완료되었어요.');
  };

  return (
    <>
      <h3>문자발송 업체 관리</h3>
      <Form>
        <Form.Field>
          <Form.Group inline>
            <Form.Field
              control={Radio}
              label="인포뱅크"
              value="INFOBANK"
              checked={smsProvider === 'INFOBANK'}
              onChange={handleChange}
            />
            <Form.Field
              control={Radio}
              label="비즈엠"
              value="BIZM"
              checked={smsProvider === 'BIZM'}
              onChange={handleChange}
            />
            <Form.Field
              control={Radio}
              label="알림톡미"
              value="ALIMTALKME"
              checked={smsProvider === 'ALIMTALKME'}
              onChange={handleChange}
            />
            <Form.Field>
              <Button size="tiny" content="변경" onClick={updateSmsProvider} />
            </Form.Field>
          </Form.Group>
        </Form.Field>
      </Form>
      <div>* 변경 버튼을 누르지 않을 경우 반영되지 않습니다.</div>
    </>
  );
};

export default SmsProvider;
