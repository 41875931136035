import {
  DescriptionCollaboOliveYoungOnlyBuyModuleFormType,
  DescriptionModuleFormType,
} from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';
import { commonDescriptionModules } from '../../DescriptionModuleForm.constant';

export const validate = (
  descriptionModule: DescriptionModuleFormType[],
): { isValid: true } | { isValid: false; message: string } => {
  const target = descriptionModule.find(
    ({ type }) => type === 'COLLABO-OLIVEYOUNG-ONLY-BUY',
  );
  if (!target) return { isValid: true };

  const {
    data: {
      price,
      originalPrice,
      reviewExampleImageUrls,
      productThumbnailImageUrl,
      subModule,
    },
  } = target as DescriptionCollaboOliveYoungOnlyBuyModuleFormType;

  if (price > originalPrice)
    return {
      isValid: false,
      message: '[상세모듈] 올리브영리뷰 가격이 정가보다 비쌉니다.',
    };

  if (!productThumbnailImageUrl)
    return {
      isValid: false,
      message: '상품 썸네일 이미지를 등록해주세요.',
    };

  if (reviewExampleImageUrls.length === 0) {
    return {
      isValid: false,
      message: '리뷰 이미지를 등록해주세요.',
    };
  }

  if (reviewExampleImageUrls.length > 3) {
    return {
      isValid: false,
      message: '리뷰 이미지는 3개까지만 등록 가능합니다.',
    };
  }

  for (let index = 0; index < subModule.length; index++) {
    const { type } = subModule[index];
    const module = commonDescriptionModules[type];
    const validationResult = module.validate(subModule);
    if (!validationResult.isValid) return validationResult;
  }

  return { isValid: true };
};
