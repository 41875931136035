import { isAfter, startOfDay } from 'date-fns';
import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

export const validate: FormValidator = (
  params: Pick<ChallengeFormType, 'startDate' | 'endDate'>,
) => {
  if (!params.startDate) {
    return { isValid: false, message: '' };
  }

  const startDate = startOfDay(new Date(params.startDate));
  const endDate = startOfDay(new Date(params.endDate || '9999-12-31'));

  if (isAfter(startDate, endDate)) {
    return {
      isValid: false,
      message: '챌린지 시작일은 종료일보다 빨라야 합니다.',
    };
  }

  return { isValid: true, message: '' };
};
