import React, { FC } from 'react';
import { Button } from 'semantic-ui-react';
// @ts-expect-error react-excel-workbook
import Workbook from 'react-excel-workbook';
import moment from 'moment';
import { UserWithdrawInfo } from '@types';
import setCurrencyFormat from '../../../global/setCurrencyFormat';

const WithdrawCashExcel: FC<WithdrawCashExcelProps> = ({ withdraws }) => {
  const cashWithdraws = withdraws.filter((wd) => wd.pointType !== 'PRIZE');
  return (
    <Workbook
      filename={`${moment().format('YYYYMMDD_')}환불.xlsx`}
      element={
        <Button basic color="green" size="mini">
          환불 다운로드
        </Button>
      }
    >
      <Workbook.Sheet data={cashWithdraws} name="환불">
        <Workbook.Column
          label=""
          value={(wd: UserWithdrawInfo) => wd.bankName}
        />
        <Workbook.Column
          label=""
          value={(wd: UserWithdrawInfo) => wd.accountNo}
        />
        <Workbook.Column
          label=""
          value={(wd: UserWithdrawInfo) => setCurrencyFormat(-wd.amount)}
        />
        <Workbook.Column
          label=""
          value={(wd: UserWithdrawInfo) => `${wd.accountName} 환불`}
        />
        <Workbook.Column label="" value={() => '챌린저스'} />
      </Workbook.Sheet>
    </Workbook>
  );
};

export default React.memo(WithdrawCashExcel);

type WithdrawCashExcelProps = {
  withdraws: UserWithdrawInfo[];
};
