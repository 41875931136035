import React from 'react';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';
import setCurrencyFormat from '../../../global/setCurrencyFormat';

const RegisterDepositCard = ({ registerDepositStatistics }) => {
  const rows = _.map(registerDepositStatistics, (s, idx) => (
    <Table.Row key={idx}>
      <Table.Cell>{s.date.S}</Table.Cell>
      <Table.Cell>{setCurrencyFormat(parseInt(s.card.N, 10))}</Table.Cell>
      <Table.Cell>{setCurrencyFormat(parseInt(s.cash.N, 10))}</Table.Cell>
      <Table.Cell>{setCurrencyFormat(parseInt(s.count.N, 10))}</Table.Cell>
    </Table.Row>
  ));

  return (
    <Table basic="very" size="small" textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>날짜</Table.HeaderCell>
          <Table.HeaderCell>카드 결제 금액</Table.HeaderCell>
          <Table.HeaderCell>캐시포인트 금액</Table.HeaderCell>
          <Table.HeaderCell>신청건수</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{rows}</Table.Body>
    </Table>
  );
};

export default RegisterDepositCard;
