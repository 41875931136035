import React from 'react';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import setCurrencyFormat from '../../../global/setCurrencyFormat';

const weekCount = moment().week();
const offset = (moment().year() - 2019) * 52 + 9;
// const today = new Date();
// const diff = today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1);
// const initialMonday = moment(today.setDate(diff)).subtract(7 * 52, 'days').set({hour:0,minute:0,second:0,millisecond:0});
// const initialWeekCount = initialMonday.diff(moment('2018-11-06'), 'week') + 2
const initialDate = ['2018-11-06', '2018-11-12'];
let periodObj = {};
_.forEach(_.range(weekCount + offset), (i) => {
  periodObj[i.toString()] = `신청${i}. ${moment(initialDate[0])
    .add(7 * (i - 1), 'd')
    .format('YY/MM/DD')}~${moment(initialDate[1])
    .add(7 * (i - 1), 'd')
    .format('YY/MM/DD')}`;
});

const RegisterCount = ({ retentionStatistics }) => {
  const retentionByPeriod = retentionStatistics.retentionByPeriod.M;
  const newRegister = [];
  _.forEach(retentionByPeriod, (value, key) => {
    if (key in periodObj && !_.isEmpty(value.M)) {
      const list = [];
      _.forEach(_.range(weekCount + offset), (i) => {
        if (i === 0)
          list.push(<Table.Cell key={i}>{periodObj[key]}</Table.Cell>);
        else if (i === 1)
          list.push(<Table.Cell key={i}>{value.M['users'].S}</Table.Cell>);
        else if ((i - 1).toString() in value.M)
          list.push(
            <Table.Cell key={i}>
              {setCurrencyFormat(parseInt(value.M[(i - 1).toString()].S))}
            </Table.Cell>,
          );
        else list.push(<Table.Cell key={i}>0</Table.Cell>);
      });
      newRegister.push(<Table.Row key={key}>{list}</Table.Row>);
    }
  });
  return (
    <Table basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>기간</Table.HeaderCell>
          <Table.HeaderCell>가입자수</Table.HeaderCell>
          {_.map(_.range(1, weekCount + offset - 1), (i) => (
            <Table.HeaderCell key={i}>{i}구간</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>{newRegister}</Table.Body>
    </Table>
  );
};

export default RegisterCount;
