import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useAdminUserIds = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      adminUserIds: s.challenge.adminUserIds,
    })),
  );
};

export const useAdminUserIdsActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setAdminUserIds = (adminUserIds: number[]) =>
    updateChallengeForm({ adminUserIds });

  return { setAdminUserIds };
};
