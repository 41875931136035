import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'authenticationGoodExamples'>,
  Pick<ReqCreateOrUpdateChallenge, 'authenticationGoodExampleUrls'>
> = ({ authenticationGoodExamples }) => {
  if (!authenticationGoodExamples)
    throw Error("[AuthenticationGoodExamplesForm] this shouldn't be happened");

  return {
    authenticationGoodExampleUrls: authenticationGoodExamples,
  };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'authenticationGoodExamples'>,
  Pick<ChallengeFormType, 'authenticationGoodExamples'>
> = ({ authenticationGoodExamples }) => ({
  authenticationGoodExamples,
});
