import Button from '@component/Button';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import TextInput from '@component/TextInput';
import { FC } from 'react';

type Props = {
  noticeList: string[];
  onChange: (data: string[]) => void;
};
const NoticeListForm: FC<Props> = (props) => {
  const { noticeList, onChange } = props;

  const handleEditText = (text: string, index: number) => {
    onChange(noticeList.map((d, idx) => (idx === index ? text : d)));
  };

  const handleAddItem = () => {
    onChange([...noticeList, '']);
  };

  const handleRemoveItem = (index: number) => {
    onChange(noticeList.filter((_, i) => i !== index));
  };

  return (
    <FlexBox.Column>
      <Label>추가리워드 알림리스트</Label>

      {noticeList.map((item, index) => (
        <div
          style={{ display: 'flex', gap: 12, marginBottom: 6 }}
          key={`${index}`}
        >
          <TextInput
            containerStyle={{ flex: 1 }}
            value={item}
            onChange={(text) => handleEditText(text, index)}
          />
          <Button style={{ width: 72 }} onClick={() => handleRemoveItem(index)}>
            삭제
          </Button>
        </div>
      ))}

      <Button onClick={handleAddItem}>추가</Button>
    </FlexBox.Column>
  );
};

export default NoticeListForm;
