import React, { useEffect, useState } from 'react';
import { apis } from '../../../../api';
import { Button, Table } from 'semantic-ui-react';
import moment from 'moment';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';

const RaceOrder = ({ userId }) => {
  const [raceOrders, setRaceOrders] = useState([]);

  useEffect(() => {
    getRaceOrders();
  }, []);

  const getRaceOrders = async () => {
    const res = await apis.user.getRaceOrders(userId);
    const { orders } = res;
    setRaceOrders(orders);
  };

  const cancelRaceOrder = async (order) => {
    if (confirm(`[${order.race.title}] 대회를 정말 취소하시겠습니까?`)) {
      const res = await apis.user.cancelRaceOrder(userId, {
        orderId: order.id,
      });

      if (res?.response?.data?.err) {
        return;
      }

      const _orders = [...raceOrders].filter((o) => o.id !== order.id);
      setRaceOrders(_orders);

      alert('대회가 취소되었습니다');
    }
  };

  return (
    <>
      <h5>대회 결제 목록</h5>
      <Table basic="very" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>id</Table.HeaderCell>
            <Table.HeaderCell>주문일시</Table.HeaderCell>
            <Table.HeaderCell>주문번호</Table.HeaderCell>
            <Table.HeaderCell>대회명</Table.HeaderCell>
            <Table.HeaderCell>선택상품</Table.HeaderCell>
            <Table.HeaderCell>상품금액</Table.HeaderCell>
            <Table.HeaderCell>배송비</Table.HeaderCell>
            <Table.HeaderCell>결제수단</Table.HeaderCell>
            <Table.HeaderCell>환불여부</Table.HeaderCell>
            <Table.HeaderCell>취소</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {raceOrders.map((order) => (
            <Table.Row key={order.id}>
              <Table.Cell collapsing>{order.id}</Table.Cell>
              <Table.Cell collapsing>
                {moment(order.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </Table.Cell>
              <Table.Cell collapsing>{order.orderNo}</Table.Cell>
              <Table.Cell collapsing>{order.race.title}</Table.Cell>
              <Table.Cell collapsing>{order.productName}</Table.Cell>
              <Table.Cell collapsing>
                {setCurrencyFormat(order.productPrice)}
              </Table.Cell>
              <Table.Cell collapsing>
                {setCurrencyFormat(order.shippingFee)}
              </Table.Cell>
              <Table.Cell collapsing>{order.pgcode}</Table.Cell>
              <Table.Cell collapsing>
                {order.isRefunded ? '환불완료' : '-'}
              </Table.Cell>
              <Table.Cell collapsing>
                {!order.isRefunded && (
                  <Button
                    size="small"
                    content="취소"
                    onClick={() => cancelRaceOrder(order)}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default RaceOrder;
