import {
  convertData2Form,
  convertForm2Data,
} from './ViralLoopAuthenticationMethodForm.serializer';

export const ViralLoopAuthenticationMethodForm = () => {
  return <></>;
};

ViralLoopAuthenticationMethodForm.convertData2Form = convertData2Form;
ViralLoopAuthenticationMethodForm.convertForm2Data = convertForm2Data;
