import styled from 'styled-components';
import { Form, Label } from 'semantic-ui-react';

export const Section = styled.div`
  margin: 20px 0;
`;

export const RowContainer = styled.div`
  display: flex;
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const Wrapper = styled.div`
  flex: 1;
`;

export const RowFormGroup = styled(Form.Group)`
  flex: 1;
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const RowFormField = styled(Form.Field)`
  flex: 1;
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const ButtonWrapper = styled.div`
  padding: 40px 0;
`;

export const LabelButton = styled(Label)`
  cursor: pointer;
  margin: ${(props) => props.margin && props.margin} !important;
`;

export const SectionWrapper = styled.div`
  position: relative;
  border: 1px solid #cccccc;
  border-radius: 10px;
  margin-top: 10px;
  padding: 20px;
`;

export const RowWrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ModuleWrapper = styled.div`
  position: relative;
  margin: 10px 20px;
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
`;

export const StoreSnsImageWrapper = styled.div`
  width: 400px;
  position: relative;
  border: 1px solid black;
`;

export const StoreSnsImageContainer = styled.div`
  flex-direction: row;
  display: flex;
  column-gap: 20px;
  margin-top: 20px;
`;

export const StoreSnsPlusButton = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
`;
