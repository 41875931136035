import Accordion from '@component/Accordion/Accordion';
import CheckBox from '@component/CheckBox';
import ChipInput from '@component/ChipInput';
import DateTimeInput from '@component/DateTimeInput';
import ErrorMsg from '@component/ErrorMsg';
import FlexBox from '@component/FlexBox/FlexBox';
import Label from '@component/Label';
import NumberInput from '@component/NumberInput';
import RadioSelector from '@component/RadioSelector/RadioSelector';
import TextInput from '@component/TextInput';
import { ChallengeQueries } from '@queries';
import { useMemo } from 'react';
import { ChallengeCustomField } from 'src/types/customField.types';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
  SupportersAge,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './SupportersForm.serializer';
import { useSupporters, useSupportersAction } from './SupportersForm.store';
import * as S from './SupportersForm.style';
import { validate } from './SupportersForm.validator';

const deliveryOptionList = [
  { label: '배송상품 존재', value: true },
  { label: '배송상품 없음', value: false },
];

type Props = {
  open: boolean;
};

const SupportersForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'supporters'>,
  Extract<ChallengeCustomField, { name: 'supporters' }>,
  Props
> = ({ open }) => {
  const { supporters, registerEndDate } = useSupporters();
  const {
    setUserLimit,
    setBenefit,
    setGender,
    setAge,
    setCategory,
    setHasDelivery,
    setWeight,
    setSelectionResultDate,
  } = useSupportersAction();
  const { data, isLoading } = ChallengeQueries.useGetChallengeAppCategories();

  const genders = useMemo(() => {
    return {
      F: supporters.gender.includes('F'),
      M: supporters.gender.includes('M'),
    };
  }, [supporters.gender]);

  const ages: Record<SupportersAge, boolean> = useMemo(() => {
    return {
      '19-24': supporters.age.includes('19-24'),
      '25-29': supporters.age.includes('25-29'),
      '30-34': supporters.age.includes('30-34'),
      '35-39': supporters.age.includes('35-39'),
      '40-44': supporters.age.includes('40-44'),
      '45-49': supporters.age.includes('45-49'),
      '50-54': supporters.age.includes('50-54'),
      '55-59': supporters.age.includes('55-59'),
      '60-64': supporters.age.includes('60-64'),
      '65-69': supporters.age.includes('65-69'),
      '70+': supporters.age.includes('70+'),
    };
  }, [supporters.age]);

  const categoryList = useMemo(() => {
    if (isLoading || !data) return [];
    return data.categories
      .map((category) => category.subCategories)
      .flat()
      .filter((category) => category.criteria === 'level3')
      .map((c) => c.text);
  }, [data, isLoading]);

  const validated = validate({ supporters, registerEndDate });

  const handleGenderClick = (value: boolean, name: 'F' | 'M') => {
    setGender(
      Object.entries({ ...genders, [name]: value })
        .filter((entry) => !!entry.at(-1))
        .map(([key]) => key as 'F' | 'M'),
    );
  };

  const handleAgeClick = (value: boolean, name: SupportersAge) => {
    setAge(
      Object.entries({ ...ages, [name]: value })
        .filter((entry) => !!entry.at(-1))
        .map(([key]) => key as SupportersAge),
    );
  };

  return (
    <Accordion open={open} title="체험단 관련 정보 관리하기" as="h5">
      <ErrorMsg text={!validated.isValid ? validated.message : ''} />
      <S.Container
        style={{
          pointerEvents: supporters.isSelectionDone ? 'none' : 'auto',
        }}
      >
        <NumberInput
          label="추첨 인원수"
          value={supporters.userLimit}
          onChange={setUserLimit}
        />
        <TextInput
          label="체험단 선정시 제공되는 혜택"
          value={supporters.benefit || undefined}
          onChange={setBenefit}
        />
        <FlexBox.Column>
          <Label>성별 선택</Label>
          <CheckBox
            label="여성"
            checked={genders.F}
            onChange={(value) => handleGenderClick(value, 'F')}
          />
          <CheckBox
            label="남성"
            checked={genders.M}
            onChange={(value) => handleGenderClick(value, 'M')}
          />
        </FlexBox.Column>
        <FlexBox.Column>
          <Label>나이대 선택</Label>
          <CheckBox
            label="19-24"
            checked={ages['19-24']}
            onChange={(value) => handleAgeClick(value, '19-24')}
          />
          <CheckBox
            label="25-29"
            checked={ages['25-29']}
            onChange={(value) => handleAgeClick(value, '25-29')}
          />
          <CheckBox
            label="30-34"
            checked={ages['30-34']}
            onChange={(value) => handleAgeClick(value, '30-34')}
          />
          <CheckBox
            label="35-39"
            checked={ages['35-39']}
            onChange={(value) => handleAgeClick(value, '35-39')}
          />
          <CheckBox
            label="40-44"
            checked={ages['40-44']}
            onChange={(value) => handleAgeClick(value, '40-44')}
          />
          <CheckBox
            label="45-49"
            checked={ages['45-49']}
            onChange={(value) => handleAgeClick(value, '45-49')}
          />
          <CheckBox
            label="50-54"
            checked={ages['50-54']}
            onChange={(value) => handleAgeClick(value, '50-54')}
          />
          <CheckBox
            label="55-59"
            checked={ages['55-59']}
            onChange={(value) => handleAgeClick(value, '55-59')}
          />
          <CheckBox
            label="60-64"
            checked={ages['60-64']}
            onChange={(value) => handleAgeClick(value, '60-64')}
          />
          <CheckBox
            label="65+"
            checked={ages['65-69']}
            onChange={(value) => handleAgeClick(value, '65-69')}
          />
          <CheckBox
            label="70+"
            checked={ages['70+']}
            onChange={(value) => handleAgeClick(value, '70+')}
          />
        </FlexBox.Column>
        <ChipInput
          chipList={supporters.category}
          searchable
          searchDataAddable={false}
          searchDataList={categoryList}
          onChange={setCategory}
          placeholder="카테고리 선택"
        />
        <FlexBox.Column>
          <Label>배송 상품 여부</Label>
          <RadioSelector
            style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
            selectedOption={
              deliveryOptionList.find(
                ({ value }) => value === supporters.hasDelivery,
              ) || undefined
            }
            options={deliveryOptionList}
            onChange={({ value }) => setHasDelivery(value)}
          />
        </FlexBox.Column>
        <Label>
          선정 가중치 (모든 값을 더해서 1이 되도록 소수점으로 입력해주세요)
        </Label>
        <S.WeightContainer>
          <NumberInput
            label="챌린지 가중치"
            min={0}
            max={1}
            step={0.1}
            value={supporters.challengeWeight}
            onChange={(value) => setWeight('challengeWeight', value)}
          />
          <NumberInput
            label="인스타 가중치"
            min={0}
            max={1}
            step={0.1}
            value={supporters.instagramWeight}
            onChange={(value) => setWeight('instagramWeight', value)}
          />
          <NumberInput
            label="네이버 블로그 이웃 수 가중치"
            min={0}
            max={1}
            step={0.1}
            value={supporters.naverBlogBuddyCountWeight}
            onChange={(value) => setWeight('naverBlogBuddyCountWeight', value)}
          />
          <NumberInput
            label="네이버 방문자 수 가중치"
            min={0}
            max={1}
            step={0.1}
            value={supporters.naverVisitorCountWeight}
            onChange={(value) => setWeight('naverVisitorCountWeight', value)}
          />
        </S.WeightContainer>
        <S.WeightContainer>
          <NumberInput
            label="올리브영 Baby 가중치"
            min={0}
            max={1}
            step={0.1}
            value={supporters.oliveYoungBabyWeight}
            onChange={(value) => setWeight('oliveYoungBabyWeight', value)}
          />
          <NumberInput
            label="올리브영 Pink 가중치"
            min={0}
            max={1}
            step={0.1}
            value={supporters.oliveYoungPinkWeight}
            onChange={(value) => setWeight('oliveYoungPinkWeight', value)}
          />
          <NumberInput
            label="올리브영 Green 가중치"
            min={0}
            max={1}
            step={0.1}
            value={supporters.oliveYoungGreenWeight}
            onChange={(value) => setWeight('oliveYoungGreenWeight', value)}
          />
          <NumberInput
            label="올리브영 Black 가중치"
            min={0}
            max={1}
            step={0.1}
            value={supporters.oliveYoungBlackWeight}
            onChange={(value) => setWeight('oliveYoungBlackWeight', value)}
          />
          <NumberInput
            label="올리브영 Gold 가중치"
            min={0}
            max={1}
            step={0.1}
            value={supporters.oliveYoungGoldWeight}
            onChange={(value) => setWeight('oliveYoungGoldWeight', value)}
          />
        </S.WeightContainer>

        <S.WeightContainer>
          <NumberInput
            label="올리브영 피부트러블 가중치"
            min={0}
            max={1}
            step={0.1}
            value={supporters.oliveYoungTroubleWeight}
            onChange={(value) => setWeight('oliveYoungTroubleWeight', value)}
          />
          <NumberInput
            label="올리브영 주름 가중치"
            min={0}
            max={1}
            step={0.1}
            value={supporters.oliveYoungWrinkleWeight}
            onChange={(value) => setWeight('oliveYoungWrinkleWeight', value)}
          />
        </S.WeightContainer>

        <DateTimeInput
          label="체험단 선정 결과 발표일 (* 체험단 선정은 챌린지 모집종료일 다음날 0시에 이루어짐)"
          value={supporters.selectionResultDate || undefined}
          onChange={setSelectionResultDate}
        />
        {supporters.isSelectionDone ? (
          <S.SelectionDoneDim>체험단 선정 완료 상태입니다.</S.SelectionDoneDim>
        ) : null}
      </S.Container>
    </Accordion>
  );
};

export default SupportersForm;
SupportersForm.validate = validate;
SupportersForm.convertData2Form = convertData2Form;
SupportersForm.convertForm2Data = convertForm2Data;
