import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useUseItemImpossible = () =>
  useChallengeFormStore((s) => s.challenge.useItemImpossible);

export const useUpdateUseItemImpossible = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const update = (useItemImpossible: boolean) =>
    updateChallengeForm({ useItemImpossible });

  return { update };
};
