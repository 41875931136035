import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useEndDateStore = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      startDate: s.challenge.startDate,
      endDate: s.challenge.endDate,
    })),
  );

export const useUpdateEndDate = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const updateEndDate = (endDate: string) => {
    updateChallengeForm({ endDate });
  };

  return { updateEndDate };
};
