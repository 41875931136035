import React from 'react';
import { Button, Divider, Header, Table } from 'semantic-ui-react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const GoalInfo = ({ goal, showModal }) => {
  const list = goal.cycle.map((item, idx) => (
    <Button key={idx} style={{ marginTop: 5 }} active size="tiny">
      {item}
    </Button>
  ));
  return (
    <React.Fragment>
      <Header as="h3" textAlign="center">
        목표 정보
      </Header>
      <Table basic="very" celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>목표명</Table.Cell>
            <Table.Cell>{goal.title}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>목표 카테고리</Table.Cell>
            <Table.Cell>
              {goal.goalCategory1 || goal.goalCategory.level1} |{' '}
              {goal.goalCategory2 || goal.goalCategory.level2} |{' '}
              {goal.goalCategory3 || goal.goalCategory.level3} |{' '}
              {goal.goalCategory4 || goal.goalCategory.level4}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>주기</Table.Cell>
            <Table.Cell>{list}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>매일 / 정해진 회수 / 상시</Table.Cell>
            {goal.cycle[0] === 'CUSTOM' ? (
              <Table.Cell>상시</Table.Cell>
            ) : (
              <>
                {goal.dayOrPeriod === 'DAY' && <Table.Cell>매일</Table.Cell>}
                {goal.dayOrPeriod === 'PERIOD' && (
                  <Table.Cell>정해진 회수</Table.Cell>
                )}
              </>
            )}
          </Table.Row>
          <Table.Row>
            <Table.Cell>주기 당 총수</Table.Cell>
            <Table.Cell>{goal.totalAchieveCountPerCycle}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>하루 최대 인증 가능 회수</Table.Cell>
            <Table.Cell>{goal.maxAchieveCountPerDay}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>카운트다운</Table.Cell>
            <Table.Cell>{goal.countDown ? '적용' : '미적용'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증샷간 최소 간격 (분)</Table.Cell>
            <Table.Cell>{goal.achievementIntervalMinute}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>목표 기간(일)</Table.Cell>
            <Table.Cell>{goal.totalDays}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증가능 시작 시간</Table.Cell>
            <Table.Cell>
              {moment(goal.dueStartTime, 'HH:mm:ss').format('HH:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증가능 종료 시간</Table.Cell>
            <Table.Cell>
              {moment(goal.dueEndTime, 'HH:mm:ss').format('HH:mm:ss')}
            </Table.Cell>
          </Table.Row>
          {goal.goalType === 'LIMITLESS' && (
            <>
              <Table.Row>
                <Table.Cell>[무제한] 목표 문구</Table.Cell>
                <Table.Cell>{goal.targetText}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>[무제한] 목표 수치</Table.Cell>
                <Table.Cell>{goal.target}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>[무제한] 목표 단위</Table.Cell>
                <Table.Cell>{goal.unit}</Table.Cell>
              </Table.Row>
            </>
          )}
          <Table.Row>
            <Table.Cell>갤러리 가능</Table.Cell>
            <Table.Cell>
              {goal.isGalleryPossible ? '가능' : '불가능'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>공휴일 인증 필요</Table.Cell>
            <Table.Cell>
              {goal.restInHoliday ? '인증없음' : '인증필요'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>인증샷 전체 공개</Table.Cell>
            <Table.Cell>
              {goal.isAchievementPublic ? '공개' : '비공개'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>업데이트일시</Table.Cell>
            <Table.Cell>
              {moment(goal.updatedAt).format('YYYY-MM-DD / hh:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>생성일시</Table.Cell>
            <Table.Cell>
              {moment(goal.createdAt).format('YYYY-MM-DD / hh:mm:ss')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>삭제</Table.Cell>
            {goal.isDeleted && <Table.Cell>삭제됨</Table.Cell>}
            {!goal.isDeleted && <Table.Cell>-</Table.Cell>}
          </Table.Row>
        </Table.Body>
      </Table>
      <Button
        as={Link}
        to={{ pathname: '/goals/' + goal.id + '/edit', state: { goal: goal } }}
        fluid
        basic
        color="blue"
        target="_blank"
        rel="noopener noreferrer"
      >
        편집
      </Button>
      <Divider hidden />
      <Button basic fluid color="black" onClick={showModal}>
        인증카드 미리보기
      </Button>
    </React.Fragment>
  );
};

export default GoalInfo;
