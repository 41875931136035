import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'endDate'>,
  Pick<ChallengeFormType, 'endDate'>
> = (data) => {
  return {
    endDate: data.endDate,
  };
};

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'endDate'>,
  Pick<ReqCreateOrUpdateChallenge, 'endDate'>
> = (form) => {
  if (!form.endDate) throw Error("[EndDateForm] this shouldn't be happened");
  return { endDate: form.endDate };
};
