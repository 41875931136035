import { useInfiniteQuery } from '@tanstack/react-query';
import { getNextPageParam, withTypedInfiniteLoad } from '@utils/query.utils';
import getQueryKey from './getQueryKey';
import { apis } from '@api/index';

type UseGetPreAlarmsParams = {
  offset?: number;
  limit?: number;
  keyword?: string;
};

export const useGetPreAlarmList = (
  params: UseGetPreAlarmsParams,
  pageSize = 20,
) => {
  return useInfiniteQuery({
    queryKey: getQueryKey('GET_PRE_ALARM_LIST', params),
    queryFn: withTypedInfiniteLoad(
      apis.preAlarm.getPreAlarms,
      params,
      pageSize,
    ),
    getNextPageParam,
    initialPageParam: 0,
  });
};
