import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';
import {
  CustomFieldFormType,
  SupportersAge,
  SupportersWeight,
} from '../../NewChallengeForm.types';

export const useSupporters = () =>
  useChallengeFormStore(
    useShallow((s) => ({
      supporters: s.challenge.supporters,
      registerEndDate: s.challenge.registerEndDate,
    })),
  );

export const useSupportersAction = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const update = <T extends keyof CustomFieldFormType['supporters']>(
    fieldName: T,
    value: CustomFieldFormType['supporters'][T],
  ) => {
    updateChallengeForm((prev) => ({
      ...prev,
      supporters: {
        ...prev.supporters,
        [fieldName]: value,
      },
    }));
  };

  const setUserLimit = (userLimit: number | null) =>
    update('userLimit', userLimit);
  const setBenefit = (benefit: string | null) => update('benefit', benefit);
  const setGender = (gender: ('F' | 'M')[]) => update('gender', gender);
  const setAge = (age: SupportersAge[]) => update('age', age);
  const setCategory = (category: string[]) => update('category', category);
  const setHasDelivery = (hasDelivery: boolean | null) =>
    update('hasDelivery', hasDelivery);
  const setWeight = (
    weightName: keyof SupportersWeight,
    weight: number | null,
  ) => {
    updateChallengeForm((prev) => ({
      ...prev,
      supporters: {
        ...prev.supporters,
        [weightName]: weight,
      },
    }));
  };
  const setSelectionResultDate = (resultDate: string | null) =>
    update('selectionResultDate', resultDate);

  return {
    setUserLimit,
    setBenefit,
    setGender,
    setAge,
    setCategory,
    setHasDelivery,
    setWeight,
    setSelectionResultDate,
  };
};
