import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Form,
  Radio,
  Dropdown,
  Label,
  Image,
} from 'semantic-ui-react';
import { apis } from '../../../../api';
import ContentDescriptionModuleForm from '../ContentDescriptionModuleForm';
import {
  cropAndUploadFile,
  handleChangeSingleFile,
} from '../../../../utils/uploadImage';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import { ModalType } from 'src/types/common.types';
import { ContentInfo } from '@types';

const initContent: ContentInfo = {
  id: -1,
  title: '',
  description: [],
  imageUrl: '',
  bestCurationTitle: '',
  bestCurationSubtitle: '',
  bestCurationGoalId: 0,
  curationTitle: '',
  curationSubtitle: '',
  userId: null,
  isDisplayed: true,
  order: 0,
  tags: [],
};

const ContentModal = ({
  contentModalOpen,
  modalType,
  content: propsContent,
  goalOptions,
  addContent,
  editContent,
  closeContentModal,
}: ContentModalProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [content, setContent] = useState<ContentInfo>({ ...initContent });

  useEffect(() => {
    if (propsContent) setContent(propsContent);
    else setContent({ ...initContent });
  }, [propsContent]);

  const showModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => setModalOpen(false), []);

  const appendModule = (value: number) => {
    const _content = { ...content };
    if (value === 0)
      _content.description.push({
        type: 'TEXT',
        header: '',
        body: '',
      });
    else if (value === 1)
      _content.description.push({
        type: 'VIDEO',
        url: '',
      });
    else if (value === 2)
      _content.description.push({
        type: 'IMAGE',
        url: '',
      });
    else if (value === 3)
      _content.description.push({
        type: 'IMAGE_NO_GAP',
        url: '',
      });
    else if (value === 4)
      _content.description.push({
        type: 'GOAL',
        mainHeader: '',
        subHeader: '',
        ids: [],
      });
    else if (value === 5)
      _content.description.push({
        type: 'INTERVIEW',
        interviewees: [
          { url: '', header: '', name: '', body: '', userId: null },
        ], //{image, name, content}
      });
    setContent(_content);
    closeModal();
  };

  const handleChange = (key: keyof ContentInfo, value: any) => {
    setContent({ ...content, [key]: value });
  };

  const handleChangeImageUrl = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { imageUrl } = await handleChangeSingleFile(e, {});
      setContent((prev) => ({
        ...prev,
        imageUrl,
      }));
    },
    [],
  );

  const _addContent = async () => {
    content.userId = !content.userId ? null : content.userId;
    const res = await apis.common.addContent(content);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    const { content: _content } = res;
    addContent(_content);
  };

  const _editContent = () => {
    const _content = { ...content, userId: content.userId ?? null };
    content.userId = !content.userId ? null : content.userId;
    apis.common.updateContent(_content.id, _content);
    editContent(_content);
  };

  const spliceDescriptionModule = (index: number) => {
    const _content = { ...content };
    _content.description.splice(index, 1);
    setContent(_content);
  };

  const changeOrder = (name: string, index: number) => {
    const _content = { ...content };
    const removed = _content.description.splice(index, 1);
    if (name === 'up') {
      _content.description.splice(index - 1, 0, removed[0]);
    } else if (name === 'down') {
      _content.description.splice(index + 1, 0, removed[0]);
    } else if (name === 'top') {
      _content.description.splice(0, 0, removed[0]);
    } else {
      _content.description.splice(_content.description.length, 0, removed[0]);
    }
    setContent(_content);
  };

  const handleChangeDescriptionModule = (
    index: number,
    key: string,
    value: any,
  ) => {
    const _content = { ...content };
    _content.description[index][key] = value;
    setContent(_content);
  };

  const handleChangeDescriptionModuleImage = async (
    index: number,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const _content = { ...content };
    //파일 업로드
    const temp = await handleChangeSingleFile(e, {});
    _content.description[index][e.target.name] = temp[e.target.name];
    setContent(_content);
  };

  const _cropAndUploadFile = async (
    index: number,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    //기초세팅
    LoadingIndicator.show();
    const _content = { ...content };
    const { name } = e.target;
    //파일 업로드
    const urls = await cropAndUploadFile(name, e.target.files?.[0]);
    urls.forEach((url, idx) => {
      if (idx === 0) {
        _content.description[Number(index)][name] = url;
      } else {
        _content.description.push({
          type: 'IMAGE_NO_GAP',
          image: url,
          mainHeader: '',
          body: '',
        });
      }
    });
    setContent(_content);
    LoadingIndicator.hide();
  };

  const addIntervieweeModule = (
    descriptionIndex: number,
    intervieweeIndex: number,
  ) => {
    const _content = { ...content };
    _content.description[descriptionIndex].interviewees.splice(
      intervieweeIndex + 1,
      0,
      {
        image: '',
        name: '',
        content: '',
      },
    );
    setContent(_content);
  };

  const deleteIntervieweeModule = (
    descriptionIndex: number,
    intervieweeIndex: number,
  ) => {
    const _content = { ...content };
    _content.description[descriptionIndex].interviewees.splice(
      intervieweeIndex,
      1,
    );
    setContent(_content);
  };

  const handleChangeIntervieweeModule = (
    descriptionIndex: number,
    intervieweeIndex: number,
    key: string,
    value: any,
  ) => {
    const _content = { ...content };
    _content.description[descriptionIndex].interviewees[intervieweeIndex][key] =
      key === 'userId' && value === '' ? null : value;
    setContent(_content);
  };

  const handleChangeIntervieweeSingleFile = async (
    descriptionIndex: number,
    intervieweeIndex: number,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    //기초세팅
    const _content = { ...content };
    //파일 업로드
    const temp = await handleChangeSingleFile(e, {});
    _content.description[descriptionIndex].interviewees[intervieweeIndex][
      e.target.name
    ] = temp[e.target.name];
    setContent(_content);
  };

  const moduleList = content.description?.map((object: any, idx: number) => (
    <ContentDescriptionModuleForm
      key={idx}
      object={object}
      idx={idx}
      goalOptions={goalOptions}
      spliceDescriptionModule={spliceDescriptionModule}
      changeOrder={changeOrder}
      handleChangeDescriptionModule={handleChangeDescriptionModule}
      handleChangeDescriptionModuleImage={handleChangeDescriptionModuleImage}
      cropAndUploadFile={_cropAndUploadFile}
      addIntervieweeModule={addIntervieweeModule}
      deleteIntervieweeModule={deleteIntervieweeModule}
      handleChangeIntervieweeModule={handleChangeIntervieweeModule}
      handleChangeIntervieweeSingleFile={handleChangeIntervieweeSingleFile}
    />
  ));

  return (
    <>
      <Modal size="small" open={contentModalOpen} onClose={closeContentModal}>
        <Modal.Header>
          컨텐츠 {modalType === 'ADD' ? '추가' : '수정'}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>제목</label>
              <input
                value={content.title}
                onChange={(e) => handleChange('title', e.target.value.trim())}
              />
            </Form.Field>

            <Form.Field>
              <label>이미지</label>
              <input
                name={'imageUrl'}
                type="file"
                onChange={handleChangeImageUrl}
              />
              <Image src={content.imageUrl} size="small" />
            </Form.Field>

            <Form.Field>
              <label>
                내용 모듈
                <Label onClick={showModal} style={{ cursor: 'pointer' }}>
                  모듈 추가
                </Label>
              </label>
              {moduleList}
            </Form.Field>

            <Form.Field>
              <label>베스트 큐레이션 제목</label>
              <input
                value={content.bestCurationTitle}
                onChange={(e) =>
                  handleChange('bestCurationTitle', e.target.value.trim())
                }
              />
            </Form.Field>

            <Form.Field>
              <label>베스트 큐레이션 부제</label>
              <input
                value={content.bestCurationSubtitle}
                onChange={(e) =>
                  handleChange('bestCurationSubtitle', e.target.value.trim())
                }
              />
            </Form.Field>

            <Form.Field>
              <label>베스트 큐레이션 목표</label>
              <Dropdown
                placeholder="목표를 선택하세요."
                fluid
                search
                selection
                clearable
                name="bestCurationGoalId"
                options={goalOptions}
                value={content.bestCurationGoalId}
                onChange={(_, { value }) =>
                  handleChange('bestCurationGoalId', value)
                }
              />
            </Form.Field>

            <Form.Field>
              <label>큐레이션 제목</label>
              <input
                name="curationTitle"
                value={content.curationTitle}
                onChange={(e) => handleChange('curationTitle', e.target.value)}
              />
            </Form.Field>

            <Form.Field>
              <label>큐레이션 부제</label>
              <input
                name="curationSubtitle"
                value={content.curationSubtitle}
                onChange={(e) =>
                  handleChange('curationSubtitle', e.target.value)
                }
              />
            </Form.Field>

            <Form.Field>
              <label>유저id</label>
              <input
                name="userId"
                value={content.userId ? String(content.userId) : ''}
                onChange={(e) => handleChange('userId', Number(e.target.value))}
              />
            </Form.Field>

            <Form.Group grouped>
              <label>노출여부</label>
              <Form.Field
                control={Radio}
                label="노출"
                checked={content.isDisplayed}
                onChange={() => handleChange('isDisplayed', true)}
              />
              <Form.Field
                control={Radio}
                label="노출안함"
                checked={!content.isDisplayed}
                onChange={() => handleChange('isDisplayed', false)}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            content={modalType === 'ADD' ? '추가' : '수정'}
            onClick={modalType === 'ADD' ? _addContent : _editContent}
          />
          <Button color="black" content="닫기" onClick={closeContentModal} />
        </Modal.Actions>
      </Modal>

      <Modal size="small" open={modalOpen} onClose={closeModal}>
        <Modal.Header>모듈 선택</Modal.Header>
        <Modal.Content>
          <Button size="tiny" onClick={() => appendModule(0)}>
            글
          </Button>
          <Button size="tiny" onClick={() => appendModule(1)}>
            영상
          </Button>
          <Button size="tiny" onClick={() => appendModule(2)}>
            이미지
          </Button>
          <Button size="tiny" onClick={() => appendModule(3)}>
            이미지 (갭없음)
          </Button>
          <Button size="tiny" onClick={() => appendModule(4)}>
            골 목록
          </Button>
          <Button size="tiny" onClick={() => appendModule(5)}>
            인터뷰
          </Button>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={closeModal}>
            취소
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ContentModal;

type ContentModalProps = {
  contentModalOpen: boolean;
  modalType: ModalType;
  content: ContentInfo | undefined;
  goalOptions: any;
  addContent: (content: ContentInfo) => void;
  editContent: (content: ContentInfo) => void;
  closeContentModal: () => void;
};
