import React from 'react';
import { Dropdown, Input } from 'semantic-ui-react';
import { UserFilterSelector } from '@component/UserFilterSelector';
import { userTypeOptions } from '@constant/benefitType';
import * as S from '../../Style';

const OfferWallUser: React.FC<OfferWallUserProps> = (props) => {
  const {
    offerWallInfo,
    onChangeValue,
    onChangeFilterInfoValue,
    onChangeUserFilterValue,
  } = props;

  const handleChangeUserFilter = (id: number | null) => {
    onChangeUserFilterValue(id);
  };

  return (
    <>
      <h2>유저 설정</h2>
      <S.RowContainer>
        <S.RowFormField>
          <label>공개방식</label>
          <Dropdown
            placeholder="공개방식"
            selection
            name="userType"
            options={userTypeOptions}
            value={offerWallInfo.filterInfo.userType}
            onChange={(_, { value }) =>
              onChangeFilterInfoValue('userType', value)
            }
          />
        </S.RowFormField>
        <S.RowFormField margin="0 0 0 10px">
          <label>유저 필터 목록</label>
          <UserFilterSelector
            filterId={offerWallInfo.userFilter?.id}
            onChange={handleChangeUserFilter}
          />
        </S.RowFormField>

        <div style={{ flex: 1, margin: '0 0 0 10px' }}>
          <S.RowFormField
            margin="0 0 0 0"
            label="참가자 수 제한없음"
            control="input"
            type="checkbox"
            value={offerWallInfo.maxRegisterCount === null}
            checked={offerWallInfo.maxRegisterCount === null}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeValue({
                name: 'maxRegisterCount',
                value: e.target.checked ? null : 0,
              });
            }}
          />
          {offerWallInfo.maxRegisterCount !== null && (
            <S.RowFormField>
              <Input
                type="numeric"
                name="maxRegisterCount"
                value={offerWallInfo.maxRegisterCount || 0}
                onChange={(e) => {
                  onChangeValue({
                    name: e.target.name,
                    value: /[0-9]+/.test(e.target.value)
                      ? Number(e.target.value)
                      : 0,
                  });
                }}
              />
            </S.RowFormField>
          )}
        </div>
      </S.RowContainer>
    </>
  );
};

type OfferWallUserProps = {
  onChangeValue: ({ name, value }: { name: string; value: any }) => void;
  onChangeFilterInfoValue: (name: string, value: any) => void;
  onChangeUserFilterValue: (id: number | null) => void;
  offerWallInfo: any;
};

export default OfferWallUser;
