import React, { useEffect } from 'react';

const useIntersectionCallbackRef = <T extends Element>({
  callback,
}: {
  callback: (entry: IntersectionObserverEntry) => void;
}): React.RefObject<T> => {
  const ref = React.useRef<T>(null);

  useEffect(() => {
    if (!ref.current) return;
    const io = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        callback(entry);
      });
    }, {});

    io.observe(ref.current);
    return () => io.disconnect();
  }, [ref.current]);

  return ref;
};

export default useIntersectionCallbackRef;
