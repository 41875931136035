import React from 'react';
import { Container, Responsive, Table, Image } from 'semantic-ui-react';
import _ from 'lodash';

const StickerCompanyTable = ({
  column,
  direction,
  stickerCompanies,
  handleSort,
}) => {
  if (!stickerCompanies) {
    return '';
  } else {
    return (
      <React.Fragment>
        <Container>
          <Container style={{ overflowX: 'auto', height: 700 }}>
            <Table basic="very" size="small" sortable structured>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={column === 'companyId' ? direction : null}
                    onClick={handleSort('companyId')}
                  >
                    회사id
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'companyName' ? direction : null}
                    onClick={handleSort('companyName')}
                  >
                    회사명
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'id' ? direction : null}
                    onClick={handleSort('id')}
                  >
                    스티커 목록
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {stickerCompanies.map((s) => {
                  const challenges = _.groupBy(
                    s.stickers,
                    (s) => s.challengeId,
                  );
                  return (
                    <Table.Row key={s.id}>
                      <Table.Cell collapsing>{s.id}</Table.Cell>
                      <Table.Cell collapsing>{s.name}</Table.Cell>
                      <Table.Cell collapsing>
                        {_.map(challenges, (value, key) => (
                          <div
                            key={key}
                            style={{ display: 'inlineBlock', margin: 10 }}
                          >
                            <p>
                              {key} / {value[0].challengeTitle}
                            </p>
                            <Image.Group size="tiny">
                              {_.map(value, (s) => (
                                <Image key={s.id} src={s.imageUrl} />
                              ))}
                            </Image.Group>
                          </div>
                        ))}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Container>
        </Container>
        <Container style={{ overflowX: 'auto', height: 700, marginTop: 50 }}>
          <Table basic="very" size="small" sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'companyId' ? direction : null}
                  onClick={handleSort('companyId')}
                >
                  회사id
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'companyName' ? direction : null}
                  onClick={handleSort('companyName')}
                >
                  회사명
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'challengeId' ? direction : null}
                  onClick={handleSort('challengeId')}
                >
                  챌린지id
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'challengeTitle' ? direction : null}
                  onClick={handleSort('challengeTitle')}
                >
                  챌린지명
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'id' ? direction : null}
                  onClick={handleSort('id')}
                >
                  스티커 목록
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {stickerCompanies.map((s) => {
                const challenges = _.groupBy(s.stickers, (s) => s.challengeId);
                return (
                  <Table.Row key={s.id}>
                    <Table.Cell collapsing>{s.id}</Table.Cell>
                    <Table.Cell collapsing>{s.name}</Table.Cell>
                    <Table.Cell collapsing>
                      {_.map(challenges, (value, key) => (
                        <div
                          key={key}
                          style={{ display: 'inlineBlock', margin: 10 }}
                        >
                          <p>
                            {key} / {value[0].challengeTitle}
                          </p>
                          <Image.Group size="tiny">
                            {_.map(value, (s) => (
                              <Image key={s.id} src={s.imageUrl} />
                            ))}
                          </Image.Group>
                        </div>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Container>
      </React.Fragment>
    );
  }
};

export default StickerCompanyTable;
