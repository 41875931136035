import React, { FC } from 'react';
import { Table } from 'semantic-ui-react';
import { UserSearchInfo } from 'src/types/user.types';
import UserItem from '../UserItem';

const UserList: FC<UserListProps> = ({ users, syncUsers }) => {
  const updateUser = (user: UserSearchInfo) => {
    const _users = users.map((u) => {
      if (u.id === user.id) return { ...u, ...user };
      return u;
    });
    syncUsers(_users);
  };

  const goUserDetail = (userId: number) => {
    window.open(`/users/${userId}`, '_blank');
  };

  const list = () => {
    return users.map((user) => (
      <UserItem
        key={user.id}
        user={user}
        goUserDetail={goUserDetail}
        updateUser={updateUser}
      />
    ));
  };

  return (
    <Table basic="very" textAlign="center" size="small" selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>id</Table.HeaderCell>
          <Table.HeaderCell>사진</Table.HeaderCell>
          <Table.HeaderCell>이름</Table.HeaderCell>
          <Table.HeaderCell>닉네임</Table.HeaderCell>
          <Table.HeaderCell>이메일</Table.HeaderCell>
          <Table.HeaderCell>핸드폰</Table.HeaderCell>
          <Table.HeaderCell>성별</Table.HeaderCell>
          <Table.HeaderCell>가입경로</Table.HeaderCell>
          <Table.HeaderCell>아이템수</Table.HeaderCell>
          <Table.HeaderCell>비밀번호</Table.HeaderCell>
          <Table.HeaderCell>캐시</Table.HeaderCell>
          <Table.HeaderCell>상금</Table.HeaderCell>
          <Table.HeaderCell>스토어</Table.HeaderCell>
          <Table.HeaderCell>챌린지 참가</Table.HeaderCell>
          <Table.HeaderCell>탈퇴여부</Table.HeaderCell>
          <Table.HeaderCell>금지종료일</Table.HeaderCell>
          <Table.HeaderCell>이동</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      {users.length > 0 && <Table.Body>{list()}</Table.Body>}
    </Table>
  );
};

export default UserList;

type UserListProps = {
  users: UserSearchInfo[];
  syncUsers: (users: UserSearchInfo[]) => void;
};
