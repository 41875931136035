import { useCallback, useEffect, useState } from 'react';
import { apis } from '@api/index';
import { Button, Dropdown, Form, Modal } from 'semantic-ui-react';
import { ReportedAchievement, ReviewResult } from 'src/types/achievement.types';
import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import { ReviewTemplate } from '../Achievement.types';
import { REVIEW_OPTIONS } from '../Achievement.constants';

const ReviewModal = ({
  achievement,
  reviewResult: propsReviewResult,
  modalOpen,
  templates,
  close,
  removeAchievement,
}: ReviewModalProps) => {
  const [reviewResult, setReviewResult] = useState<ReviewResult>();
  const [reviewComment, setReviewComment] = useState('');

  useEffect(() => {
    if (propsReviewResult) setReviewResult(propsReviewResult);
  }, [propsReviewResult]);

  const handleChangeTemplate = (idx: number) => {
    const { challenge, user } = achievement;
    let _reviewComment = templates[idx].message.replace(
      /\${nickname}/g,
      user.nickname,
    );
    let authenticationMethodText = `${challenge.authenticationMethod}\n`;
    if (challenge.authenticationChecklist) {
      authenticationMethodText += challenge.authenticationChecklist
        ?.map((item) => '* ' + item)
        .join('\n');
    }
    _reviewComment = _reviewComment.replace(
      /\${authenticationMethod}/g,
      authenticationMethodText,
    );
    setReviewComment(_reviewComment);
  };

  const handleReviewResultChange = useCallback(
    (_reviewResult: ReviewResult) => {
      setReviewResult(_reviewResult);
    },
    [],
  );

  const updateReviewResult = async (isPushNeeded: boolean) => {
    if (!reviewResult) return;
    LoadingIndicator.show();
    const res = await apis.reviewAchievementFile(
      achievement.achievementFile.id,
      {
        reviewResult,
        reviewComment,
        isPushNeeded,
      },
    );

    if ((res as any)?.response?.data && 'err' in (res as any)?.response?.data) {
      return;
    }

    removeAchievement({
      ...achievement,
      achievementFile: { ...achievement.achievementFile, reviewResult },
    });
    LoadingIndicator.hide();
    close();
  };

  return (
    <Modal size="small" open={modalOpen} onClose={close}>
      <Modal.Header>인증샷 리뷰</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>메세지 템플릿</label>
            <Dropdown
              placeholder="템플릿을 선택하세요."
              fluid
              search
              selection
              options={templates}
              onChange={(_, { value }) => {
                const idx = value as number;
                handleChangeTemplate(idx);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>리뷰 결과</label>
            <Dropdown
              placeholder="리뷰 결과를 선택하세요.."
              name="review"
              selection
              options={REVIEW_OPTIONS}
              value={reviewResult}
              onChange={(_, { value }) => {
                const _reviewResult = value as ReviewResult;
                handleReviewResultChange(_reviewResult);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>코멘트</label>
            <textarea
              rows={20}
              name="reviewComment"
              value={reviewComment}
              onChange={(e) => setReviewComment(e.target.value)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="blue"
          content="제출"
          onClick={() => updateReviewResult(false)}
        />
        <Button
          color="blue"
          content="제출(푸시)"
          onClick={() => updateReviewResult(true)}
        />
        <Button color="black" content="닫기" onClick={close} />
      </Modal.Actions>
    </Modal>
  );
};

export default ReviewModal;

type ReviewModalProps = {
  achievement: ReportedAchievement;
  reviewResult: ReviewResult | undefined;
  modalOpen: boolean;
  templates: ReviewTemplate[];
  close: () => void;
  removeAchievement: (achievement: ReportedAchievement) => void;
};
