import React from 'react';
import {
  Breadcrumb,
  Button,
  Checkbox,
  Dropdown,
  Grid,
} from 'semantic-ui-react';
import {
  AchievementChallengeType,
  AchievementCommentFilter,
  AchievementOrderBy,
  ReviewResult,
} from 'src/types/achievement.types';
import {
  CARD_SIZE,
  CHALLENGE_TYPE_LIST,
  COMMENT_FILTER_LIST,
  ORDER_BY_LIST,
  REVIEW_RESULT_LIST,
} from './AchievementControlPanel.constants';
import { DropdownOption } from 'src/types/common.types';

const AchievementControlPanel = ({
  selectedChallengeType,
  selectedReviewResult,
  selectedCommentFilter,
  challenges,
  selectedChallengeId,
  selectedOrderBy,
  selectedColumnCount,
  checked,
  authenticationChecked,
  reportReasonChecked,
  handleClickChallengeType,
  handleClickReviewResult,
  handleClickCommentFilter,
  handleClickChallengeFilter,
  handleClickOrderBy,
  handleClickColumnCount,
  handleClickCheckAll,
  handleClickAuthenticationCheck,
  handleClickReportReasonCheck,
  handleUpdateAchievementsReviewResult,
  handleDeleteAchievements,
  handleBulkUseItem,
  fetch,
  fetchMore,
}: AchievementControlPanelProps) => {
  return (
    <Grid>
      <Grid.Row columns="equal" verticalAlign="middle" divided>
        <Grid.Column width={2}>
          <h5>인증샷 조회</h5>
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            selection
            options={CHALLENGE_TYPE_LIST}
            value={selectedChallengeType}
            onChange={(_, { value }) => {
              const challengeType = value as AchievementChallengeType;
              handleClickChallengeType(challengeType);
            }}
          />
          <Dropdown
            selection
            options={REVIEW_RESULT_LIST}
            value={selectedReviewResult}
            onChange={(_, { value }) => {
              const reviewResult = value as ReviewResult;
              handleClickReviewResult(reviewResult);
            }}
          />
          <Dropdown
            selection
            options={ORDER_BY_LIST}
            value={selectedOrderBy}
            onChange={(_, { value }) => {
              const orderBy = value as AchievementOrderBy;
              handleClickOrderBy(orderBy);
            }}
          />
          <Button content="조회" onClick={fetch} />
          <Button content="300개 추가 조회" onClick={fetchMore} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="equal" verticalAlign="middle" divided>
        <Grid.Column width={2}>
          <h5>경고댓글 필터</h5>
        </Grid.Column>
        <Grid.Column>
          <Breadcrumb>
            {COMMENT_FILTER_LIST.map((o) => (
              <React.Fragment key={o.value}>
                <Breadcrumb.Section
                  link={false}
                  name={o.value}
                  active={selectedCommentFilter === o.value}
                  onClick={() => handleClickCommentFilter(o.value)}
                >
                  {` ${o.text}`}
                </Breadcrumb.Section>
                <Breadcrumb.Divider />
              </React.Fragment>
            ))}
          </Breadcrumb>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="equal" verticalAlign="middle" divided>
        <Grid.Column width={2}>
          <h5>챌린지 필터</h5>
        </Grid.Column>
        <Grid.Column>
          <div style={{ width: '70%' }}>
            <Dropdown
              fluid
              placeholder="챌린지를 선택하세요."
              clearable
              selection
              options={challenges}
              value={selectedChallengeId}
              onChange={(_, { value }) => {
                const challengeId = value as number;
                handleClickChallengeFilter(challengeId);
              }}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="equal" verticalAlign="middle" divided>
        <Grid.Column width={2}>
          <h5>인증샷 크기</h5>
        </Grid.Column>
        <Grid.Column>
          <Breadcrumb>
            {CARD_SIZE.map((o) => (
              <React.Fragment key={o.value}>
                <Breadcrumb.Section
                  name={o.value}
                  active={selectedColumnCount === o.value}
                  onClick={() => handleClickColumnCount(o.value)}
                >
                  {` ${o.text}`}
                </Breadcrumb.Section>
                <Breadcrumb.Divider />
              </React.Fragment>
            ))}
          </Breadcrumb>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="equal" verticalAlign="middle" divided>
        <Grid.Column width={2}>
          <h5>펼쳐 보기</h5>
        </Grid.Column>
        <Grid.Column>
          <Checkbox
            style={{ marginRight: 10 }}
            label="인증 규정"
            checked={authenticationChecked}
            onClick={handleClickAuthenticationCheck}
          />
          <Checkbox
            label="신고 사유"
            checked={reportReasonChecked}
            onClick={handleClickReportReasonCheck}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="equal" verticalAlign="middle" divided>
        <Grid.Column width={2}>
          <h5>인증샷 일괄 처리</h5>
        </Grid.Column>
        <Grid.Column verticalAlign="middle">
          <Checkbox
            style={{ marginRight: 10 }}
            label="전체 선택"
            checked={checked}
            onClick={handleClickCheckAll}
          />
          <Button
            size="tiny"
            color="blue"
            content="선택 일괄 성공 처리"
            onClick={() => handleUpdateAchievementsReviewResult('PASS')}
          />
          <Button
            size="tiny"
            color="violet"
            content="선택 일괄 삭제 & 인증 처리"
            onClick={() =>
              handleUpdateAchievementsReviewResult('DELETE_AND_PASS')
            }
          />
          <Button
            size="tiny"
            color="teal"
            content="선택 일괄 인증패스"
            onClick={handleBulkUseItem}
          />
          <Button
            size="tiny"
            color="black"
            content="선택 일괄 실패처리"
            onClick={handleDeleteAchievements}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AchievementControlPanel;

type AchievementControlPanelProps = {
  selectedChallengeType: AchievementChallengeType;
  selectedReviewResult: ReviewResult;
  selectedCommentFilter: AchievementCommentFilter;
  selectedOrderBy: AchievementOrderBy;
  selectedColumnCount: number;
  challenges: DropdownOption[];
  selectedChallengeId: number;
  checked: boolean;
  authenticationChecked: boolean;
  reportReasonChecked: boolean;
  handleClickChallengeType: (challengeType: AchievementChallengeType) => void;
  handleClickReviewResult: (reviewResult: ReviewResult) => void;
  handleClickCommentFilter: (commentFilter: AchievementCommentFilter) => void;
  handleClickChallengeFilter: (challengeId: number) => void;
  handleClickOrderBy: (orderBy: AchievementOrderBy) => void;
  handleClickColumnCount: (count: number) => void;
  handleClickCheckAll: () => void;
  handleClickAuthenticationCheck: () => void;
  handleClickReportReasonCheck: () => void;
  handleUpdateAchievementsReviewResult: (reviewResult: ReviewResult) => void;
  handleDeleteAchievements: () => void;
  handleBulkUseItem: () => void;
  fetch: () => void;
  fetchMore: () => void;
};
