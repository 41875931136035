import { useLottie } from 'lottie-react';
import { FC } from 'react';
import spinner from './lottie/spinner.json';

type Props = { size: number };
const Spinner: FC<Props> = ({ size }) => {
  const { View: LottieView } = useLottie({
    animationData: spinner,
    loop: true,
  });
  return <div style={{ width: size, height: size }}>{LottieView}</div>;
};

export default Spinner;
