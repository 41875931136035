const publicTypes = {
  ALL: '전체공개',
  FNP: '팔로워&참가자',
  PARTICIPANT: '참가자',
  FOLLOWER: '팔로워',
  SECRET: '나만보기',
  DELETE: '삭제 (취소불가)',
};

const publicTypeOptions = [
  { key: 1, text: '전체공개', value: 'ALL' },
  { key: 2, text: '팔로워&참가자', value: 'FNP' },
  { key: 3, text: '참가자', value: 'PARTICIPANT' },
  { key: 4, text: '팔로워', value: 'FOLLOWER' },
  { key: 5, text: '나만보기', value: 'SECRET' },
  { key: 6, text: '삭제 (취소불가)', value: 'DELETE' },
];

export { publicTypes, publicTypeOptions };
