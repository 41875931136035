import { useShallow } from 'zustand/react/shallow';
import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useOriginalPrice = () => {
  return useChallengeFormStore(
    useShallow((s) => ({
      originalPrice: s.challenge.originalPrice,
    })),
  );
};

export const useOriginalPriceActions = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const setOriginalPrice = (originalPrice: string) =>
    updateChallengeForm({
      originalPrice:
        !originalPrice.length || Number.isNaN(Number(originalPrice))
          ? null
          : Number(originalPrice),
    });

  return { setOriginalPrice };
};
