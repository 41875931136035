import FlexBox from '@component/FlexBox/FlexBox';
import NumberInput from '@component/NumberInput';
import Spacing from '@component/Spacing';
import { Header } from 'semantic-ui-react';
import { PreAlramForm, RecommentEditType } from '../PreAlarmForm.types';

const INFINITE_REGISTER_COUNT = 99999;

const AlarmRegisterSetting = ({
  maxRegisterCount,
  handleChange,
  triggerEdited,
  getBorder,
  disabled,
}: Props) => {
  return (
    <>
      <FlexBox.Column gap={5}>
        <Header as="h3">챌린지 모집 정보</Header>
        <strong style={{ color: '#ff8000' }}>
          📣 챌린지 모집 정보를 정확하게 입력해주세요.
        </strong>
      </FlexBox.Column>

      <Spacing vertical={10} />

      <NumberInput
        label={`모집최대인원 (${INFINITE_REGISTER_COUNT}로 입력하면 인원 제한 없음)`}
        name="maxRegisterCount"
        value={maxRegisterCount}
        onChange={(value) => {
          triggerEdited('maxRegisterCount');
          handleChange('maxRegisterCount', value ?? undefined);
        }}
        style={{ border: getBorder('maxRegisterCount'), width: '400px' }}
        disabled={disabled}
      />
    </>
  );
};

type Props = {
  maxRegisterCount: number;
  handleChange: <T extends keyof PreAlramForm>(
    key: T,
    value?: PreAlramForm[T],
  ) => void;
  triggerEdited: (key: keyof RecommentEditType) => void;
  getBorder: (key: keyof RecommentEditType) => string;
  disabled: boolean;
};

export default AlarmRegisterSetting;
