import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import {
  ChallengeFormType,
  ConverterData2Form,
  ConverterForm2Data,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2Data<
  Pick<ChallengeFormType, 'authenticationBadExamples'>,
  Pick<ReqCreateOrUpdateChallenge, 'authenticationBadExampleUrls'>
> = ({ authenticationBadExamples }) => {
  if (!authenticationBadExamples)
    throw Error("[AuthenticationBadExamplesForm] this shouldn't be happened");

  return {
    authenticationBadExampleUrls: authenticationBadExamples,
  };
};

export const convertData2Form: ConverterData2Form<
  Pick<ChallengeInfoForAdmin, 'authenticationBadExamples'>,
  Pick<ChallengeFormType, 'authenticationBadExamples'>
> = ({ authenticationBadExamples }) => ({
  authenticationBadExamples,
});
