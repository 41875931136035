import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<
  ChallengeFormType,
  'minRegisterDeposit' | 'maxRegisterDeposit'
>;

export const validate: FormValidator<Params> = ({
  minRegisterDeposit,
  maxRegisterDeposit,
}) => {
  if (minRegisterDeposit > maxRegisterDeposit) {
    return {
      isValid: false,
      message: '최소 참가비는 최대 참가비보다 클 수 없습니다.',
    };
  }

  if (minRegisterDeposit < 0) {
    return {
      isValid: false,
      message: '최소 참가비는 음수가 될 수 없습니다.',
    };
  }

  return { isValid: true, message: '' };
};
