import DateTimeInput from '@component/DateTimeInput';
import { useChallengeEditedStore } from '@container/Challenge/NewChallengeForm/NewChallengeForm.store';
import { AchievementInfoItem } from '@container/Challenge/NewChallengeForm/NewChallengeForm.types';

type Props = {
  index: number;
  value: string | null;
  onChange: <T extends keyof AchievementInfoItem>(
    name: T,
    value: AchievementInfoItem[T],
  ) => void;
};

function DueEndDate({ index, value, onChange }: Props) {
  const { recommendedUpdates, updateEdited } = useChallengeEditedStore((s) => ({
    recommendedUpdates: s.recommendedUpdates,
    updateEdited: s.updateEdited,
  }));
  const achievementInfoListEdited = recommendedUpdates.achievementInfoList;
  const edited = achievementInfoListEdited[index] as
    | {
        dueStartDate: boolean;
        dueStartTime: boolean;
        dueEndDate: boolean;
        dueEndTime: boolean;
      }
    | undefined;

  const border = edited?.dueEndDate
    ? '1px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133))'
    : `3px solid rgb(255, 128, 0)`;

  const handleChange = (v: string) => {
    onChange('dueEndDate', v);
    updateEdited({
      achievementInfoList: achievementInfoListEdited.map((s, idx) =>
        idx === index ? { ...s, dueEndDate: true } : s,
      ),
    });
  };

  return (
    <DateTimeInput
      value={value || undefined}
      dateOnly
      onChange={handleChange}
      style={{ border }}
    />
  );
}

export default DueEndDate;
