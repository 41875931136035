import { apis } from '@api/index';
import { useQuery } from '@tanstack/react-query';
import getQueryKey from './getQueryKey';

export const useUserFilterById = (filterId?: number) => {
  return useQuery({
    queryKey: getQueryKey('USER_FILTER', { id: filterId || 0 }),
    queryFn: () => apis.getUserFilterById(filterId || 0),
    enabled: !!filterId,
    select: (res) => res.userFilter,
  });
};
