import { isNull } from '@utils/js.utils';
import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

type Params = Pick<ChallengeFormType, 'creator'>;

/**
 * validation 항목
 */
export const validate: FormValidator<Params> = ({ creator }) => {
  if (!isNull(creator.type) && isNull(creator.defaultCommercePrice)) {
    return { isValid: false, message: '참가비를 설정해주세요.' };
  }
  return { isValid: true };
};
