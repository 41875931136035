import { CheerRaceParameter } from 'src/types/race.types';
import Preview from './Preview/Preview';
import * as S from './Style';

const CheerSettingField = (props: CheerSettingFieldProps) => {
  const { cheerRaceParameter, handleChange } = props;

  return (
    <>
      <h3>응원 대회 세팅</h3>
      <Preview />
      <S.RowContainer>
        <S.RowFormField>
          <label>응원 대회 종류</label>
          <input
            value={cheerRaceParameter?.cheerType}
            placeholder="응원 대회 종류를 입력하세요"
            onChange={(e) => handleChange('cheerType', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField>
          <label>응원 대회 extra 기록 텍스트 세팅</label>
          <input
            value={cheerRaceParameter?.cheerRecordText}
            placeholder="응원 대회 기록 제목을 입력하세요"
            onChange={(e) => handleChange('cheerRecordText', e.target.value)}
          />
        </S.RowFormField>
        <S.RowFormField>
          <label>응원 대회 extra 기록 단위 세팅</label>
          <input
            value={cheerRaceParameter?.cheerRecordUnit}
            placeholder="응원 대회 기록 단위를 입력하세요"
            onChange={(e) => handleChange('cheerRecordUnit', e.target.value)}
          />
        </S.RowFormField>
      </S.RowContainer>
    </>
  );
};

type CheerSettingFieldProps = {
  cheerRaceParameter?: CheerRaceParameter;
  handleChange: <K extends keyof CheerRaceParameter>(
    key: K,
    value: CheerRaceParameter[K],
  ) => void;
};

export default CheerSettingField;
