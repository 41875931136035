import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-weight: bold;
`;

export const Input = styled.input<{
  placeholderTextColor?: React.CSSProperties['color'];
}>`
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  ::placeholder {
    ${({ placeholderTextColor }) =>
      placeholderTextColor ? { color: placeholderTextColor } : {}};
  }
`;
