import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useIsRegisterDepositFixed = () =>
  useChallengeFormStore((s) => s.challenge.isRegisterDepositFixed);

export const useUpdateIsRegisterDepositFixed = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const update = (isRegisterDepositFixed: boolean) =>
    updateChallengeForm({ isRegisterDepositFixed });

  return { update };
};
