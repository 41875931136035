import React, { forwardRef, useMemo } from 'react';
import ErrorMsg from '@component/ErrorMsg';
import format from 'date-fns/format';
import * as S from './DateTimeInput.style';

type DateTimeInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type' | 'placeholder' | 'onChange' | 'value'
> & {
  label?: string;
  value?: string | Date;
  containerStyle?: React.CSSProperties;
  dateOnly?: boolean;
  errorMsg?: string;
  onChange?: (value: string) => void;
};

export type DateTimeInputRef = HTMLInputElement;

const DateTimeInput = forwardRef<DateTimeInputRef, DateTimeInputProps>(
  (props, ref) => {
    const {
      containerStyle,
      label,
      dateOnly = false,
      errorMsg,
      onChange,
      onClick,
      value: valueProp,
      ...restProps
    } = props;
    const type = dateOnly ? 'date' : 'datetime-local';
    const value = useMemo(() => {
      if (!valueProp) return undefined;
      if (type === 'datetime-local')
        return format(new Date(valueProp), "yyyy-MM-dd'T'HH:mm");
      return format(new Date(valueProp), 'yyyy-MM-dd');
    }, [valueProp, type]);

    const handleDateTimeChange: React.ChangeEventHandler<HTMLInputElement> = (
      e,
    ) => {
      onChange?.(e.target.value);
    };

    return (
      <S.Container style={containerStyle} onClick={onClick}>
        {label ? <S.Label>{label}</S.Label> : <></>}
        <S.Input
          ref={ref}
          type={type}
          value={value || ''}
          onChange={handleDateTimeChange}
          {...restProps}
        />
        <ErrorMsg text={errorMsg} />
      </S.Container>
    );
  },
);

export default DateTimeInput;
