import Label from '@component/Label';
import { ChallengeCustomField, CustomImageFilterInfoType } from '@types';
import Accordion from '@component/Accordion/Accordion';
import {
  ChallengeCustomFieldFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './ImageFilterForm.serializer';
import { validate } from './ImageFilterForm.validator';
import {
  useChallengeImageFilterActions,
  useChallengeImageFilterStore,
} from './ImageFilterForm.store';
import FlexBox from '@component/FlexBox/FlexBox';
import { Table } from 'semantic-ui-react';
import ErrorMsg from '@component/ErrorMsg';
import Button from '@component/Button';
import * as S from './ImageFilterForm.style';
import FilterByAchievement from './components/FilterByAchievement/FilterByAchievement';
import { ChallengeQueries } from '@queries/index';
import { useGetGoalDetail } from '@queries/Challenge/useGetGoalDetail';
import { useMemo } from 'react';
import {
  findTargetFilter,
  getInitialInfo,
  getInitialInfoItemByType,
} from './ImageFilterForm.utils';

export const ImageFilterForm: ChallengeCustomFieldFormComponent<
  Pick<ChallengeFormType, 'imageFilter'>,
  Extract<ChallengeCustomField, { name: 'imageFilter' }>
> = () => {
  const { imageFilter, goalId, startDate, endDate, distance } =
    useChallengeImageFilterStore();
  const validated = validate({
    imageFilter,
    distance,
  });

  const { data } = ChallengeQueries.useGetAchievementCardList(
    {
      goalId: goalId || undefined,
      startDate,
      endDate,
    },
    {
      enabled: !!goalId,
    },
  );

  const achievements = data?.achievements ?? [];

  const {
    setChallengeImageFilter,
    setAllSame,
    clear,
    clearByAchievementIndex,
  } = useChallengeImageFilterActions(achievements);

  const { data: goalDetail } = useGetGoalDetail(goalId || 0);

  const IMAGE_FILTER_TYPE_LIST: Option[] = useMemo(
    () => [
      { type: 'timestamp', label: '타임스탬프' },
      { type: 'image', label: '이미지' },
      { type: 'text', label: '문구' },
      ...(goalDetail?.goalType === 'DISTANCE'
        ? [{ type: 'accumulated_distance', label: '누적 거리' } as Option]
        : []),
      { type: 'challenge_name', label: '{챌린지명} 도전 중' },
      { type: 'empty', label: '선택안함' },
    ],
    [goalDetail?.goalType],
  );

  const handleOnChangeRadio = (
    option: Option,
    achievementIndex: number,
    filterOrder: number,
  ) => {
    const targetFilter = findTargetFilter(imageFilter, achievementIndex);

    const newInfo = targetFilter
      ? targetFilter.info.map((v, idx) =>
          idx === filterOrder ? getInitialInfoItemByType(option.type) : v,
        )
      : getInitialInfo(option.type, filterOrder);

    setChallengeImageFilter(achievementIndex, newInfo);
  };

  const handleAllSelect = () => {
    const targetFilter = imageFilter.find((v) => v.achievementIndex === 1);
    if (!targetFilter) {
      alert('1회차 커스텀 필터가 설정되어야 합니다.');
      return;
    }

    if (
      window.confirm('모든 회차 필터가 1회차 설정 값과 동일하게 설정됩니다.')
    ) {
      setAllSame();
    }
  };

  const handleClear = () => {
    if (window.confirm('모든 회차의 필터를 초기화 하시겠습니까?')) {
      clear();
    }
  };

  const handleClearByAchievement = (achievementIndex: number) => {
    clearByAchievementIndex(achievementIndex);
  };

  const handleUploadImage = (
    imageUrl: string | undefined,
    achievementIndex: number,
    filterOrder: number,
  ) => {
    const targetFilter = findTargetFilter(imageFilter, achievementIndex);

    const newInfo = targetFilter
      ? targetFilter.info.map((v, idx) =>
          idx === filterOrder && v.type === 'image'
            ? {
                ...v,
                data: {
                  width: '100%',
                  height: '100%',
                  imageUrl: imageUrl ?? '',
                },
              }
            : v,
        )
      : getInitialInfo('image', filterOrder);

    setChallengeImageFilter(achievementIndex, newInfo);
  };

  const handleTextChange = (
    text: string | undefined,
    achievementIndex: number,
    filterOrder: number,
  ) => {
    const targetFilter = findTargetFilter(imageFilter, achievementIndex);

    const newInfo = targetFilter
      ? targetFilter.info.map((v, idx) =>
          idx === filterOrder && v.type === 'text'
            ? {
                ...v,
                data: {
                  text: text ?? '',
                  padding: 20,
                },
              }
            : v,
        )
      : getInitialInfo('text', filterOrder);

    setChallengeImageFilter(achievementIndex, newInfo);
  };

  const handleColorChange = (
    color: string | undefined,
    achievementIndex: number,
    filterOrder: number,
  ) => {
    const targetFilter = findTargetFilter(imageFilter, achievementIndex);

    const newData = color
      ? {
          padding: 20,
          backgroundColor: color,
        }
      : {
          padding: 20,
        };

    const newInfo = targetFilter
      ? targetFilter.info.map((v, idx) =>
          idx === filterOrder && v.type === 'timestamp'
            ? {
                ...v,
                data: newData,
              }
            : v,
        )
      : getInitialInfo('timestamp', filterOrder);

    setChallengeImageFilter(achievementIndex, newInfo);
  };

  return (
    <Accordion title="커스텀 이미지 필터 설정" as="h5">
      {!achievements.length ? (
        <Label>
          회차별 이미지 필터 설정을 사용하지 않는 Goal입니다. Goal이 설정되지
          않았습니다.
        </Label>
      ) : (
        <FlexBox.Column gap={8} style={{ paddingBottom: 20 }}>
          <FlexBox.Row gap={12}>
            <Button onClick={handleAllSelect}>
              1회차 기준으로 전체 적용하기 (덮어쓰기)
            </Button>
            <Button onClick={handleClear}>모두 초기화</Button>
          </FlexBox.Row>

          <Table collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>인증 회차</Table.HeaderCell>
                <Table.HeaderCell>커스텀 필터 설정</Table.HeaderCell>
                <Table.HeaderCell>초기화</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {achievements.map((_, index) => {
                const achievementIndex = index + 1;
                return (
                  <Table.Row key={achievementIndex}>
                    <Table.Cell collapsing>
                      <strong>{achievementIndex}회차</strong>
                    </Table.Cell>

                    <Table.Cell collapsing>
                      <FlexBox.Column gap={18}>
                        <S.Grid>
                          {[
                            '↖️ 왼쪽 위',
                            '오른쪽 위 ↗️',
                            '↙️ 왼쪽 아래',
                            '오른쪽 아래 ↘️',
                          ].map((position, filterOrder) => {
                            const targetFilter = findTargetFilter(
                              imageFilter,
                              achievementIndex,
                            );
                            const targetFilterInfo =
                              targetFilter?.info[filterOrder];

                            const selectedOption = IMAGE_FILTER_TYPE_LIST.find(
                              ({ type }) => type === targetFilterInfo?.type,
                            );

                            return (
                              <FilterByAchievement
                                key={filterOrder}
                                label={position}
                                targetFilterInfo={targetFilterInfo}
                                filterOptions={IMAGE_FILTER_TYPE_LIST}
                                selectedOption={selectedOption}
                                onRadioChange={(option) =>
                                  handleOnChangeRadio(
                                    option,
                                    achievementIndex,
                                    filterOrder,
                                  )
                                }
                                onUploadImage={(value) =>
                                  handleUploadImage(
                                    value,
                                    achievementIndex,
                                    filterOrder,
                                  )
                                }
                                onTextChange={(text) =>
                                  handleTextChange(
                                    text,
                                    achievementIndex,
                                    filterOrder,
                                  )
                                }
                                onColorChange={(color) =>
                                  handleColorChange(
                                    color,
                                    achievementIndex,
                                    filterOrder,
                                  )
                                }
                              />
                            );
                          })}
                        </S.Grid>
                      </FlexBox.Column>
                    </Table.Cell>

                    <Table.Cell collapsing>
                      <Button
                        onClick={() => {
                          handleClearByAchievement(achievementIndex);
                        }}
                      >
                        초기화
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <ErrorMsg text={validated.isValid ? '' : validated.message} />
        </FlexBox.Column>
      )}
    </Accordion>
  );
};

ImageFilterForm.validate = validate;
ImageFilterForm.convertData2Form = convertData2Form;
ImageFilterForm.convertForm2Data = convertForm2Data;

export type Option =
  | {
      type: Exclude<
        Pick<CustomImageFilterInfoType, 'type'>['type'],
        'accumulated_distance'
      >;
      label: string;
    }
  | {
      type: 'accumulated_distance';
      label: string;
    };
