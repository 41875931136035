import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { SettlementFormState } from '../../container/Collabo/Settlement/Settlement.types';
import { apis } from '../../api';

export const useCollaboSettlementMutation = () => {
  const queryClient = useQueryClient();

  const editCollaboSettlement = useCallback(
    async (settlementId: number, settlementForm: SettlementFormState) => {
      await apis.editSettlement(settlementId, {
        ...settlementForm,
      });

      queryClient.invalidateQueries({
        queryKey: ['COLLABO_SETTLEMENT_LIST'],
      });
    },
    [queryClient],
  );

  return {
    editCollaboSettlement,
  };
};
