import { Button, Form, Table, Divider } from 'semantic-ui-react';
import * as S from '../../../CollaboForm/Style';

const BlacklistFromExcel = ({
  bannedUsers,
  alreadyDupUserIds,
  dupUserIds,
  readExcelFile,
  update,
}) => {
  return (
    <>
      <S.RowContainer>
        <Form>
          <Form.Field>
            <label>엑셀 파일을 업로드하세요.</label>
            <input type="file" id="input" onChange={readExcelFile} />
          </Form.Field>
        </Form>
        <a
          href="https://d1cu54ww3s1tp1.cloudfront.net/development/admin/230503_blacklist_format.xlsx"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            basic
            color="green"
            size="mini"
            content="블랙리스트 양식 다운로드"
          />
        </a>
      </S.RowContainer>

      <Divider hidden />

      {bannedUsers.length > 0 && (
        <div style={{ marginTop: 12 }}>
          <h5>총 {bannedUsers.length}건</h5>
          <Button onClick={update} content={'등록하기'} />

          <Table basic="very" size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>No</Table.HeaderCell>
                <Table.HeaderCell>유저id</Table.HeaderCell>
                <Table.HeaderCell>포인트 종류</Table.HeaderCell>
                <Table.HeaderCell>지급액</Table.HeaderCell>
                <Table.HeaderCell>지급사유</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {bannedUsers.map((user, idx) => (
                <Table.Row key={user.userId}>
                  <Table.Cell>{idx + 1}</Table.Cell>
                  <Table.Cell>{user.targetId}</Table.Cell>
                  <Table.Cell>
                    {user.banStartDate.format('YYYY-MM-DD HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>
                    {user.banEndDate.format('YYYY-MM-DD HH:mm:ss')}
                  </Table.Cell>
                  <Table.Cell>{user.reason}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}

      <Divider hidden />

      {alreadyDupUserIds.length > 0 && (
        <>
          <h5>이미 등록된 유저 id 목록</h5>
          <div>
            * 기존에 등록된 유저 아이디가 있습니다. 등록을 진행할 경우 기존에
            등록되어있던 정보는 삭제 후 새로 등록하게 됩니다.
          </div>
          <Table collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>No</Table.HeaderCell>
                <Table.HeaderCell>유저id</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {alreadyDupUserIds.map((userId, idx) => (
                <Table.Row key={userId}>
                  <Table.Cell>{idx + 1}</Table.Cell>
                  <Table.Cell>{userId}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )}

      {dupUserIds.length > 0 && (
        <>
          <h5>파일 내 중복된 유저 id 목록</h5>
          {dupUserIds.map((userId) => (
            <div key={userId}>{userId}</div>
          ))}
        </>
      )}
    </>
  );
};

export default BlacklistFromExcel;
