import { PurchaseInfoListItem } from '../../NewChallengeForm.types';

export const DEFAULT_PURCHASE_ITEM: PurchaseInfoListItem = {
  achievementIndex: [],
  type: 'PURCHASE',
  confirmTitle: `구매완료 내역을 캡처해\n인증해 주세요!`,
  checklist: [
    '구매날짜, 제품명, 받는사람, 연락처가 정확하게 나오도록 캡처하여 인증해주세요',
    `한장 캡처가 어려우신 경우 1)캡처본을 이어붙인 이미지 혹은 2)날짜와 주문번호가 나온 상단 이미지를 캡처하여 업로드해주세요.

마이페이지 > 주문/배송조회 > 결제완료`,
  ],
};

export const DEFAULT_REVIEW_ITEM: PurchaseInfoListItem = {
  achievementIndex: [],
  type: 'REVIEW',
  confirmTitle: `작성한 포토 리뷰를 캡처해\n인증해 주세요!`,
  checklist: [
    '아이디/닉네임이 보이도록 “포토 리뷰”를 캡처해주세요.',
    `등록한 프로필과 동일한 프로필의 리뷰만 인정돼요

마이페이지 > 리뷰 > 나의리뷰 > 리뷰보기`,
  ],
  requiredText: '',
};
