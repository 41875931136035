import React from 'react';
import { Container, Responsive, Table, Image, Button } from 'semantic-ui-react';

const ChallengeStickerTable = ({
  column,
  direction,
  challengeStickers,
  handleSort,
  showStickerEditModal,
  showStickerDeleteModal,
}) => {
  if (!challengeStickers) {
    return '';
  } else {
    return (
      <React.Fragment>
        <Container>
          <Container style={{ overflowX: 'auto', height: 700 }}>
            <Table basic="very" size="small" sortable structured>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={column === 'id' ? direction : null}
                    onClick={handleSort('id')}
                  >
                    스티커id
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'challengeId' ? direction : null}
                    onClick={handleSort('challengeId')}
                  >
                    챌린지id
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'challengeTitle' ? direction : null}
                    onClick={handleSort('challengeTitle')}
                  >
                    챌린지명
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'companyName' ? direction : null}
                    onClick={handleSort('companyName')}
                  >
                    회사명
                  </Table.HeaderCell>
                  <Table.HeaderCell>스티커</Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === 'order' ? direction : null}
                    onClick={handleSort('order')}
                  >
                    순서
                  </Table.HeaderCell>
                  <Table.HeaderCell>수정/삭제</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {challengeStickers.map((s) => {
                  return (
                    <Table.Row key={s.id}>
                      <Table.Cell collapsing>{s.id}</Table.Cell>
                      <Table.Cell collapsing>{s.challengeId}</Table.Cell>
                      <Table.Cell collapsing>{s.challengeTitle}</Table.Cell>
                      <Table.Cell collapsing>{s.companyName}</Table.Cell>
                      <Table.Cell collapsing>
                        <Image size="tiny" src={s.imageUrl} />
                      </Table.Cell>
                      <Table.Cell collapsing>{s.order}</Table.Cell>
                      <Table.Cell>
                        <Button
                          size="mini"
                          value={s}
                          onClick={showStickerEditModal}
                        >
                          수정
                        </Button>
                        <Button
                          size="mini"
                          value={s}
                          onClick={showStickerDeleteModal}
                        >
                          삭제
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Container>
        </Container>
        <Container style={{ overflowX: 'auto', height: 700, marginTop: 50 }}>
          <Table basic="very" size="small" sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === 'challengeId' ? direction : null}
                  onClick={handleSort('challengeId')}
                >
                  챌린지id
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'challengeTitle' ? direction : null}
                  onClick={handleSort('challengeTitle')}
                >
                  챌린지명
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'companyName' ? direction : null}
                  onClick={handleSort('companyName')}
                >
                  회사명
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'id' ? direction : null}
                  onClick={handleSort('id')}
                >
                  스티커id
                </Table.HeaderCell>
                <Table.HeaderCell>스티커</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'order' ? direction : null}
                  onClick={handleSort('order')}
                >
                  순서
                </Table.HeaderCell>
                <Table.HeaderCell>수정/삭제</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {challengeStickers.map((s) => {
                return (
                  <Table.Row key={s.id}>
                    <Table.Cell collapsing>{s.challengeId}</Table.Cell>
                    <Table.Cell collapsing>{s.challengeTitle}</Table.Cell>
                    <Table.Cell collapsing>{s.companyName}</Table.Cell>
                    <Table.Cell collapsing>{s.id}</Table.Cell>
                    <Table.Cell collapsing>
                      <Image size="tiny" src={s.imageUrl} />
                    </Table.Cell>
                    <Table.Cell collapsing>{s.order}</Table.Cell>
                    <Table.Cell>
                      <Button
                        size="mini"
                        value={s}
                        onClick={showStickerEditModal}
                      >
                        수정
                      </Button>
                      <Button
                        size="mini"
                        value={s}
                        onClick={showStickerDeleteModal}
                      >
                        삭제
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Container>
      </React.Fragment>
    );
  }
};

export default ChallengeStickerTable;
