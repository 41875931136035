import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Image,
  Button,
  Header,
  Loader,
} from 'semantic-ui-react';
import { apis } from '../../../api/index';
import { boardType } from '../../../constant/boardType';

class BoardItemDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      board: {},
      isMounted: false,
    };
  }

  async componentDidMount() {
    if (this.props.board) {
      this.setState({ board: this.props.board, isMounted: true });
    } else if (this.props.location.state) {
      this.setState({
        board: this.props.location.state.board,
        isMounted: true,
      });
    } else {
      const res = await apis.getBoard(this.props.match.params.id);
      if (res?.response?.data && 'err' in res?.response?.data) {
        return;
      }
      const { board } = res;
      this.setState({ board, isMounted: true });
    }
  }

  deleteBoardItem = async () => {
    const res = await apis.deleteBoard(this.state.board.id);
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    const { board } = res;
    this.setState({ board });
  };

  render() {
    if (!this.state.isMounted) {
      return <Loader active inline="centered" />;
    }
    const { board } = this.state;
    return (
      <Container style={styles.container}>
        <Grid columns={3}>
          <Grid.Column />
          <Grid.Column>
            <Header as="h3">제목</Header>
            <div>{board.title}</div>
            <Header as="h3">게시판 카테고리</Header>
            <div>{boardType[board.boardCategory.name]}</div>
            <Header as="h3">사진</Header>
            <div>
              {board.imageUrls.map((url, idx) => (
                <Image key={idx} src={url} size="small" />
              ))}
            </div>
            <Header as="h3">내용</Header>
            <div>
              {board.content.split('\n').map((line, idx) => {
                return (
                  <span key={idx}>
                    {line}
                    <br />
                  </span>
                );
              })}
            </div>
            <Header as="h3">공개여부</Header>
            <div>
              {board.isPublic && '공개'}
              {!board.isPublic && '미공개'}
            </div>
            <Header as="h3">삭제여부</Header>
            <div>
              {board.isDeleted && '삭제됨'}
              {!board.isDeleted && '삭제안됨'}
            </div>

            <div className="ui two buttons">
              <Button
                as={Link}
                to={{
                  pathname: '/boards/' + board.id + '/edit',
                  state: { board: board },
                }}
                basic
                color="green"
              >
                편집
              </Button>
              <Button basic color="red" onClick={this.deleteBoardItem}>
                삭제
              </Button>
            </div>
          </Grid.Column>
          <Grid.Column />
        </Grid>
      </Container>
    );
  }
}

export default BoardItemDetail;

const styles = {
  container: {
    marginTop: 20,
  },
};
