import { Image } from '@container/App/DataLabel/DataLabel.types';
import { Faq } from 'src/types/faq.types';
import { Bank } from 'src/types/bank.types';
import {
  TemporaryCashTarget,
  TemporaryCashTargetUserInfo,
  UserWithdrawInfo,
} from '@types';
import { ApiType } from '../constant/apiType';
import { _request } from './factory';

const uploadImages = async (data: FormData): Promise<{ url: string }> => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/images`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

type GetRestDaysResponse = {
  restDays: {
    id: number;
    date: string; // YYYY-MM-DD
    dateName: string; // 공휴일이름
  }[];
};

const getRestDays = async (): Promise<GetRestDaysResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/rest-days`,
  });
};

type GetMlAchievementData = {
  isEnd: boolean;
  dataList: Image[];
};

const getMlAchievementData = async (params: {
  challengeType: 'gym' | 'sky';
  offset: number;
  limit: number;
}): Promise<GetMlAchievementData> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/ml-achievement-data`,
    params,
  });
};

type UpdateMlAchievementData = {
  success: boolean;
};

const updateMlAchievementData = async (data: {
  id: number;
  result: string;
}): Promise<UpdateMlAchievementData> => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: `/admin/ml-achievement-data`,
    data,
  });
};

const getFaqList = async (): Promise<Faq[]> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/faqs`,
  });
};

const getFaqItem = async (id: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/faqs/${id}`,
  });
};

const deleteFaq = async (id: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'DELETE',
    url: `/admin/faqs/${id}`,
  });
};

const recoverFaq = async (id: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: `/admin/faqs/${id}/recover`,
  });
};

const updateFaqPriority = async (data: { id: number; priority: number }[]) => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: `/admin/faqs/priority`,
    data,
  });
};

const updateFaq = async (id: number, data: Omit<Faq, 'id'>) => {
  return _request({
    apiType: ApiType.go,
    method: 'PUT',
    url: `/admin/faqs/${id}`,
    data,
  });
};

const createFaq = async (data: Omit<Faq, 'id'>) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/faqs`,
    data,
  });
};

const getBanks = async (): Promise<Bank> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/banks`,
  });
};

type GetWithdrawTransactionsResponse = {
  withdraws: UserWithdrawInfo[];
};

const getWithdrawTransactions = async (params: {
  gteCreatedAt: string;
  ltCreatedAt: string;
}): Promise<GetWithdrawTransactionsResponse> => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/transaction-transfers/withdraw`,
    params,
  });
};

const updateTransactionTransferIsCompleted = async (id: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: `/admin/transaction-transfers/${id}/complete`,
  });
};

const getOldCardTransactions = async (params: {
  gteCreatedAt: string;
  ltCreatedAt: string;
}) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/transaction-transfers/old-card-cashes`,
    params,
  });
};

const updateOldCardTransactionTransferIsCompleted = async (data: {
  ids: number[];
}) => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: `/admin/transaction-transfers/old-card-cashes/complete`,
    data,
  });
};

const answerOldCardTransactions = async (data: {
  ids: number[];
  answer: string;
}) => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: `/admin/transaction-transfers/old-card-cashes/answer`,
    data,
  });
};

const giveTemporaryCash = async (data: {
  targetUsers: TemporaryCashTarget[];
}) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/event-cash/temporary`,
    data,
  });
};

type GetTemporaryCashTargetResponse = {
  users: TemporaryCashTargetUserInfo[];
};

const getTemporaryCashTarget =
  async (): Promise<GetTemporaryCashTargetResponse> => {
    return _request({
      apiType: ApiType.go,
      method: 'GET',
      url: `/event-cash/temporary`,
    });
  };

const addContent = async (data: any) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/contents`,
    data,
  });
};

const getContents = async (data: { offset: number; limit: number }) => {
  return _request({
    apiType: ApiType.go,
    method: 'GET',
    url: `/admin/contents`,
    data,
  });
};

const updateContent = async (id: number, data: any) => {
  return _request({
    apiType: ApiType.go,
    method: 'PATCH',
    url: `/admin/contents/${id}`,
    data,
  });
};

const deleteContent = async (id: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'DELETE',
    url: `/admin/contents/${id}`,
  });
};

const copyContent = async (id: number) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/contents/${id}/copy`,
  });
};

const updateContentPriority = async (data: { ids: number[] }) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/contents/priority`,
    data,
  });
};

const copyProducts = async (data: { ids: number[] }) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/products/copy`,
    data,
  });
};

const deleteProducts = async (data: { ids: number[] }) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/products/delete`,
    data,
  });
};

const syncRestDaysWithOpenData = (data: { year: number }) => {
  return _request({
    apiType: ApiType.go,
    method: 'POST',
    url: `/admin/rest-days/sync-open-data`,
    data,
  });
};

export default {
  uploadImages,
  getRestDays,
  getMlAchievementData,
  updateMlAchievementData,
  getFaqList,
  getFaqItem,
  deleteFaq,
  recoverFaq,
  updateFaqPriority,
  updateFaq,
  createFaq,
  getBanks,
  getWithdrawTransactions,
  updateTransactionTransferIsCompleted,
  getOldCardTransactions,
  updateOldCardTransactionTransferIsCompleted,
  answerOldCardTransactions,
  giveTemporaryCash,
  getTemporaryCashTarget,
  addContent,
  getContents,
  updateContent,
  deleteContent,
  copyContent,
  updateContentPriority,
  copyProducts,
  deleteProducts,
  syncRestDaysWithOpenData,
};
