import {
  endOfDay,
  isAfter,
  isBefore,
  startOfDay,
  startOfSecond,
} from 'date-fns';
import { ChallengeFormType, FormValidator } from '../../NewChallengeForm.types';

export const validate: FormValidator<
  Pick<ChallengeFormType, 'achievementInfoList' | 'startDate' | 'endDate'>
> = (params) => {
  const { achievementInfoList } = params;
  for (let i = 0; i < achievementInfoList.length; i++) {
    const info = achievementInfoList[i];
    if (
      !info.dueStartDate ||
      !info.dueStartTime ||
      !info.dueEndDate ||
      !info.dueEndTime
    ) {
      return {
        isValid: false,
        message: '[회차별인증] 날짜와 시간을 모두 입력해주세요.',
      };
    }

    const startDate = startOfSecond(startOfDay(new Date(params.startDate)));
    const endDate = startOfSecond(endOfDay(new Date(params.endDate)));
    const dueStartDateTime = startOfSecond(
      createDate(info.dueStartDate, info.dueStartTime),
    );
    const dueEndDateTime = startOfSecond(
      createDate(info.dueEndDate, info.dueEndTime),
    );

    if (!isBefore(dueStartDateTime, dueEndDateTime)) {
      return {
        isValid: false,
        message: `[회차별인증] ${
          i + 1
        }회차 인증 시작일시가 종료일시보다 빠를 수 없습니다.`,
      };
    }

    if (isAfter(new Date(startDate), dueStartDateTime)) {
      return {
        isValid: false,
        message: `[회차별인증] ${
          i + 1
        }회차 인증 시작일시가 챌린지 시작일시보다 빠를 수 없습니다.`,
      };
    }

    if (isAfter(dueEndDateTime, new Date(endDate))) {
      return {
        isValid: false,
        message: `[회차별인증] ${
          i + 1
        }회차 인증 종료일시가 챌린지 종료일시보다 늦을 수 없습니다.`,
      };
    }
  }

  return { isValid: true };
};

const createDate = (date: string, time: string) => new Date(`${date} ${time}`);
