import {
  ChallengeCustomField,
  ChallengeHideTimestampCustomField,
} from 'src/types/customField.types';
import {
  ChallengeFormType,
  ConverterCustomFieldData2Form,
  ConverterForm2CustomFieldData,
} from '../../NewChallengeForm.types';

export const convertForm2Data: ConverterForm2CustomFieldData<
  Pick<ChallengeFormType, 'timestamp'>,
  Extract<ChallengeCustomField, { name: 'hideTimestamp' }>
> = ({ timestamp }) => {
  if (!timestamp.hideTimeStamp) return [];

  return [
    {
      name: 'hideTimestamp',
      values: Boolean(timestamp.hideTimeStamp),
    },
  ];
};

export const convertData2Form: ConverterCustomFieldData2Form<
  Pick<ChallengeFormType, 'timestamp'>
> = (customFields) => {
  const timestamp = customFields.find(({ name }) => name === 'hideTimestamp');

  return {
    timestamp: {
      hideTimeStamp: Boolean(
        timestamp?.values as ChallengeHideTimestampCustomField | undefined,
      ),
    },
  };
};
