import DateTimeInput from '@component/DateTimeInput';
import { ChallengeInfoForAdmin, ReqCreateOrUpdateChallenge } from '@types';
import { formatLocalDateTime, formatTimeWithSeconds } from '@utils/date.utils';
import {
  ChallengeFormComponent,
  ChallengeFormType,
} from '../../NewChallengeForm.types';
import {
  convertData2Form,
  convertForm2Data,
} from './PurchaseStartDateForm.serializer';
import {
  usePurchaseDateTime,
  usePurchaseStartDateTimeActions,
} from './PurchaseStartDateForm.store';
import { validate } from './PurchaseStartDateForm.validator';
import { useEditCheck } from '../../hooks/useEditCheck';

const PurchaseStartDateForm: ChallengeFormComponent<
  Pick<ChallengeInfoForAdmin, 'purchaseStartDate'>,
  Pick<ChallengeFormType, 'purchaseStartDate'>,
  Pick<ReqCreateOrUpdateChallenge, 'purchaseStartDate'>
> = () => {
  const { purchaseStartDate, purchaseEndDate } = usePurchaseDateTime();

  const { setPurchaseStartDateTime } = usePurchaseStartDateTimeActions();

  const validated = validate({ purchaseStartDate, purchaseEndDate });

  const { getBorder, triggerEdited } = useEditCheck();

  const handleChange = (value: string) => {
    if (!value.length) return;

    triggerEdited('purchaseStartDate');
    setPurchaseStartDateTime(formatTimeWithSeconds(new Date(value)));
  };

  return (
    <DateTimeInput
      label="구매일시 (시작)"
      value={purchaseStartDate || formatLocalDateTime(new Date())}
      errorMsg={validated.isValid ? '' : validated.message}
      onChange={handleChange}
      style={{ border: getBorder('purchaseStartDate') }}
    />
  );
};

export default PurchaseStartDateForm;

PurchaseStartDateForm.validate = validate;
PurchaseStartDateForm.convertData2Form = convertData2Form;
PurchaseStartDateForm.convertForm2Data = convertForm2Data;
