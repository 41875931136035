import { useChallengeFormStore } from '../../NewChallengeForm.store';

export const useThumbnailImageUrl = () =>
  useChallengeFormStore((s) => s.challenge.thumbnailImageUrl);

export const useUpdateThumbnailImageUrl = () => {
  const updateChallengeForm = useChallengeFormStore(
    (s) => s.updateChallengeForm,
  );

  const update = (thumbnailImageUrl: string | null) =>
    updateChallengeForm({ thumbnailImageUrl });

  return { update };
};
