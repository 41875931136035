import FlexBox from '@component/FlexBox/FlexBox';
import React, { useEffect, useState } from 'react';
import { Image, ChallengeType } from '../../DataLabel.types';
import * as S from './ImageView.style';
import {
  getBackgroundColor,
  getReviewCategoryList,
} from '../../DataLabel.utils';

export const ImageView: React.FC<ImageViewProps> = (props) => {
  const {
    image,
    imageList,
    activeChallengeType,
    updateReviewResult,
    moveImage,
  } = props;

  const [imageIdx, setImageIdx] = useState(-1);

  const reviewCategoryList = getReviewCategoryList(activeChallengeType);

  const handleKeyPress = (
    id: number,
    e: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    switch (e.key) {
      case 'ArrowLeft':
        if (imageIdx === 0) alert('첫 사진입니다.');
        moveImage(-1);
        break;
      case 'ArrowRight':
        if (imageIdx === imageList.length - 1) alert('마지막 사진입니다.');
        moveImage(1);
        break;
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        updateReviewResult(id, reviewCategoryList[Number(e.key) - 1].value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    imageList.forEach((o, idx) => {
      if (o.id === image?.id) setImageIdx(idx);
    });
  }, [image, imageList]);

  const isSuccess = ['BEFORE', 'PASS', 'DELETE_AND_PASS'].includes(
    image?.originalResult ?? '',
  );

  return (
    <FlexBox.Column style={{ flex: 8 }}>
      <h3>이미지 뷰</h3>
      {image && (
        <div
          role="presentation"
          tabIndex={-1}
          onKeyDown={(e) => handleKeyPress(image.id, e)}
        >
          <FlexBox.Row gap={20}>
            <img src={image?.imageUrl} alt="이미지" style={{ flex: 1 }} />
            <FlexBox.Column style={{ width: 400 }}>
              <FlexBox.Row
                justifyContent="flex-end"
                style={{
                  margin: '0 0 8px 0',
                  padding: '8px',
                  backgroundColor: isSuccess ? 'green' : 'red',
                }}
              >
                <S.Span isActive>
                  (기존 : {isSuccess ? '성공처리' : '실패처리'})
                </S.Span>
              </FlexBox.Row>
              {reviewCategoryList.map((o, idx) => {
                const isOdd = idx % 2 === 1;
                const isActive = o.value === image.result;
                return (
                  <S.Wrapper
                    backgroundColor={getBackgroundColor(isOdd, isActive)}
                    onClick={() => updateReviewResult(image.id, o.value)}
                  >
                    <S.Span key={o.value} isActive={isActive}>
                      {`[${idx + 1}] ${o.text}`}
                    </S.Span>
                  </S.Wrapper>
                );
              })}
            </FlexBox.Column>
          </FlexBox.Row>
        </div>
      )}
    </FlexBox.Column>
  );
};

type ImageViewProps = {
  activeChallengeType: ChallengeType;
  imageList: Image[];
  image: Image | null;
  updateReviewResult: (id: number, category: string) => void;
  moveImage: (idx: number) => void;
};
