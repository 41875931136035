import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  Dropdown,
  Grid,
  Header,
  Input,
  List,
  Pagination,
  Table,
} from 'semantic-ui-react';
import moment from 'moment';
import {
  Settlement,
  SettlementFilter,
  SettlementListAction,
  SettlementListState,
  SettlementProgressiveOperatingFee,
  SettlementSearch,
} from '../../Settlement.types';
import {
  PAGE_LIMIT,
  PROGRESSIVE_OPERATING_FEE_LABEL,
  SEARCH_DROPDOWN_LABEL,
  SEARCH_DROPDOWN_OPTIONS,
  progressiveOperatingFeeKeys,
} from '../../Settlement.constants';
import FilterSection from '../FilterSection/FilterSection';
import { useCollaboSettlementList } from '../../../../../queries/Challenge/useCollaboSettlementList';
import { flatMapInfiniteData } from '../../../../../utils/query.utils';
import { apis } from '../../../../../api';
import SettlementEditModal from '../SettlementEditModal/SettlementEditModal';

type TableSectionProps = {
  status: 'DONE' | 'PENDING';
  tableState: SettlementListState;
  setTableState: React.Dispatch<SettlementListAction>;
};

const TableSection: React.FC<TableSectionProps> = ({
  status,
  tableState,
  setTableState,
}) => {
  const [selectedSettlement, setSelectedSettlement] =
    useState<Settlement | null>(null);

  const [editOpen, setEditOpen] = useState(false);

  const [searchKeywordType, setSearchKeywordType] = useState<
    keyof SettlementSearch
  >(SEARCH_DROPDOWN_OPTIONS[0].value);
  const [searchKeyword, setSearchKeyword] = useState('');

  const { data, fetchNextPage, refetch } = useCollaboSettlementList({
    gteChallengeResultDate: tableState.filter.gteChallengeResultDate,
    ltChallengeResultDate: moment(tableState.filter.ltChallengeResultDate)
      .add(1, 'days')
      .format('YYYY-MM-DD'),
    ...tableState.search,
    isConfirmed: status === 'DONE',
  });

  const settlementList = flatMapInfiniteData(data, 'settlements', 'id') || [];
  const settlementTotalCount = data?.pages[0]?.totalCount || 0;

  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const handleToggleAllItems = () => {
    setSelectedIds((ids) => {
      if (ids.length === settlementList.length) {
        return [];
      }
      return settlementList.map((s) => s.id);
    });
  };

  const handleSelectItem = (id: number) => {
    setSelectedIds((ids) => {
      if (ids.includes(id)) {
        return ids.filter((i) => i !== id);
      }
      return [...ids, id];
    });
  };

  // 엑셀 다운로드 (전체, 선택)
  const handleExcelDownload = async (ids?: number[]) => {
    const blob = await apis.downloadSettlementExcel({
      ...tableState.filter,
      isConfirmed: status === 'DONE',
      ...(ids ? { ids } : {}),
    });

    if (!blob) alert('엑셀 다운로드에 실패했습니다.');

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `정산_엑셀_파일_${moment().format(
      'YYYY-MM-DD_HH:mm:ss',
    )}.xlsx`;
    document.body.appendChild(link);
    link.click();
  };

  // 검색 버튼 눌렀을 때
  const handleSearch = () => {
    setTableState({
      type: 'SET_SEARCH',
      value: searchKeyword ? { [searchKeywordType]: searchKeyword } : {},
    });
  };

  const handleConfirmSettlement = async (settlementIds: number[]) => {
    if (window.confirm('정말 확정하시겠습니까?')) {
      await apis.confirmSettlement({
        ids: settlementIds,
      });
      setSelectedIds([]);
      refetch();
    }
  };

  useEffect(() => {
    setSelectedIds([]);
  }, [status]);

  return (
    <>
      <Grid.Row>
        <Header as="h2">
          정산 관리 [{status === 'DONE' ? '확정' : '대기'}]
        </Header>
        {/* 엑셀 다운로드 */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginBottom: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
            }}
          >
            {/* 검색 */}
            <Dropdown
              text={SEARCH_DROPDOWN_LABEL[searchKeywordType]}
              options={SEARCH_DROPDOWN_OPTIONS}
              simple
              defaultValue={searchKeywordType}
              onChange={(_, { value }) => {
                setSearchKeywordType(value as keyof SettlementSearch);
              }}
            />
            <Input
              icon
              placeholder={`${SEARCH_DROPDOWN_LABEL[searchKeywordType]} 검색`}
              onChange={(_, { value }) => {
                setSearchKeyword(String(value));
              }}
              value={searchKeyword}
            >
              <input />
              <Button type="submit" onClick={handleSearch}>
                검색
              </Button>
            </Input>
          </div>
          {/* 필터 */}
          <FilterSection
            filter={tableState.filter}
            onSelectFilter={(value: Partial<SettlementFilter>) => {
              setTableState({
                type: 'SET_FILTER',
                value: { ...value },
              });
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              color="green"
              size="large"
              disabled={selectedIds.length === 0}
              onClick={() => handleExcelDownload(selectedIds)}
            >
              선택 엑셀 다운로드 ({selectedIds.length})
            </Button>
            <Button
              basic
              color="green"
              size="large"
              disabled={settlementTotalCount === 0}
              onClick={() => handleExcelDownload()}
            >
              전체 엑셀 다운로드 ({settlementTotalCount})
            </Button>
            <Button
              basic
              color="orange"
              size="large"
              disabled={selectedIds.length === 0}
              onClick={() => handleConfirmSettlement(selectedIds)}
            >
              선택 확정 ({selectedIds.length})
            </Button>
          </div>
        </div>
      </Grid.Row>
      <Container fluid style={{ overflowX: 'scroll', width: '100%' }}>
        <Table basic="very">
          <Table.Header>
            <Table.Row
              style={{
                display: 'table',
                width: '100%',
                tableLayout: 'fixed',
              }}
            >
              <Table.HeaderCell>
                <Checkbox
                  checked={
                    selectedIds.length !== 0 &&
                    selectedIds.length === settlementList.length
                  }
                  onChange={handleToggleAllItems}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>챌린지</Table.HeaderCell>
              <Table.HeaderCell>결과 발표일</Table.HeaderCell>
              <Table.HeaderCell>광고상품유형</Table.HeaderCell>
              <Table.HeaderCell>태그값</Table.HeaderCell>
              <Table.HeaderCell>구매할 상품 가격</Table.HeaderCell>
              <Table.HeaderCell>인당 운영 수수료</Table.HeaderCell>
              <Table.HeaderCell>모집 목표 인원</Table.HeaderCell>
              <Table.HeaderCell>실 참가자 수</Table.HeaderCell>
              <Table.HeaderCell>100% 달성자 수</Table.HeaderCell>
              <Table.HeaderCell>인당 상금</Table.HeaderCell>
              <Table.HeaderCell>총 상금</Table.HeaderCell>
              <Table.HeaderCell>고정배너비</Table.HeaderCell>
              <Table.HeaderCell>인스타그램</Table.HeaderCell>
              <Table.HeaderCell>블로그</Table.HeaderCell>
              <Table.HeaderCell>네이버 한달리뷰</Table.HeaderCell>
              <Table.HeaderCell>기타</Table.HeaderCell>
              <Table.HeaderCell>총매출</Table.HeaderCell>
              <Table.HeaderCell>수정/확정</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body
            style={{
              display: 'block',
              height: '800px',
              width: '100%',
              overflow: 'auto',
            }}
          >
            {settlementList.length === 0 ? (
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.Cell>없음</Table.Cell>
                </Table.Row>
              </Table.Footer>
            ) : (
              settlementList.map((s) => {
                return (
                  <Table.Row
                    key={s.id}
                    style={{
                      display: 'table',
                      width: '100%',
                      tableLayout: 'fixed',
                    }}
                  >
                    <Table.Cell>
                      <Checkbox
                        checked={selectedIds.includes(s.id)}
                        value={s.id}
                        onChange={(e, { value }) => {
                          handleSelectItem(value as number);
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>{s.id}</Table.Cell>
                    <Table.Cell>
                      <List.Item key={s.id}>
                        <List.Content>
                          <a
                            href={`/challenges/${s.challengeId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >{`[${s.challengeId}] ${s.challengeTitle}`}</a>
                        </List.Content>
                      </List.Item>
                    </Table.Cell>
                    <Table.Cell>
                      {moment(s.challengeResultDate).format('YYYY-MM-DD')}
                    </Table.Cell>
                    <Table.Cell>{s.adsProductType}</Table.Cell>
                    <Table.Cell>{`[${s.tagId}] ${s.tagText}`}</Table.Cell>
                    <Table.Cell>
                      {s.productPriceToPurchase.toLocaleString()}원
                    </Table.Cell>
                    <Table.Cell>
                      {s.adsProductType === '서비스 체험' ? (
                        <>
                          <Table basic="very" celled collapsing>
                            <Table.Body>
                              {progressiveOperatingFeeKeys.map((key) => (
                                <Table.Row key={key}>
                                  <Table.Cell>
                                    {
                                      PROGRESSIVE_OPERATING_FEE_LABEL[
                                        key as keyof SettlementProgressiveOperatingFee
                                      ]
                                    }
                                  </Table.Cell>
                                  <Table.Cell>
                                    {(
                                      s[
                                        key as keyof SettlementProgressiveOperatingFee
                                      ] || 0
                                    ).toLocaleString()}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </>
                      ) : (
                        <>{s.operatingFeePerPerson.toLocaleString()}원</>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {s.targetRegisterCount.toLocaleString()}명
                    </Table.Cell>
                    <Table.Cell>
                      {s.actualRegisterCount.toLocaleString()}명
                    </Table.Cell>
                    <Table.Cell>
                      {s.overHundredCount.toLocaleString()}명
                    </Table.Cell>
                    <Table.Cell>
                      {s.rewardPerPerson.toLocaleString()}원
                    </Table.Cell>
                    <Table.Cell>
                      {s.rewardPerPerson.toLocaleString()}원
                    </Table.Cell>
                    <Table.Cell>{s.bannerPrice.toLocaleString()}원</Table.Cell>
                    <Table.Cell>
                      {s.instagramPrice.toLocaleString()}원
                    </Table.Cell>
                    <Table.Cell>
                      {s.naverBlogPrice.toLocaleString()}원
                    </Table.Cell>
                    <Table.Cell>
                      {s.naverAfterMonthReviewPrice.toLocaleString()}원
                    </Table.Cell>
                    <Table.Cell>{s.etcPrice.toLocaleString()}원</Table.Cell>
                    <Table.Cell>
                      <Header.Content as="h4">
                        {s.revenue.toLocaleString()}원
                      </Header.Content>
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        size="mini"
                        color="black"
                        content="수정"
                        disabled={status === 'DONE'}
                        value={s.id}
                        onClick={() => {
                          setSelectedSettlement(s);
                          setEditOpen(true);
                        }}
                      />
                      <Button
                        size="mini"
                        color="google plus"
                        content="확정"
                        disabled={status === 'DONE'}
                        value={s.id}
                        onClick={() => {
                          handleConfirmSettlement([s.id]);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })
            )}
          </Table.Body>
        </Table>
      </Container>
      <Pagination
        defaultActivePage={1}
        totalPages={Math.ceil(settlementTotalCount / PAGE_LIMIT)}
        onPageChange={(_, { value }) => {
          fetchNextPage();
        }}
      />
      {selectedSettlement && (
        <SettlementEditModal
          open={editOpen}
          setOpen={(open) => {
            // 닫힐 때 selectedSettlement 초기화
            if (!open) {
              setSelectedSettlement(null);
            }
            setEditOpen(open);
          }}
          settlement={selectedSettlement}
        />
      )}
    </>
  );
};

export default TableSection;
