import React, { useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Header,
  Form,
  TextArea,
  Dropdown,
} from 'semantic-ui-react';
import { apis } from '../../../../api';
import { useHistory } from 'react-router-dom';
import { categories, subCategoryObj } from '../../../../constant/faqCategories';
import _ from 'lodash';
import { serviceTypeOptions } from '../../../../utils/dropdownOptions';

const FaqCreate = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [subCategories, setSubCategories] = useState([]);

  const history = useHistory();

  const changeCategory = (_, { value }) => {
    setCategory(value);
    setSubCategories(subCategoryObj[value]);
  };

  const submit = async () => {
    await apis.common.createFaq({
      question,
      answer,
      category,
      subCategory,
      serviceType,
    });
    history.push('/faqs');
  };

  return (
    <Container>
      <Grid columns="equal">
        <Grid.Column />
        <Grid.Column width={8}>
          <Header as="h3" textAlign="center">
            FAQ 생성
          </Header>
          <Form onSubmit={submit}>
            <Form.Field>
              <label>질문</label>
              <input
                name="question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>답변</label>
              <TextArea
                name="answer"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>카테고리</label>
              <Dropdown
                placeholder="카테고리"
                search
                selection
                clearable
                options={categories}
                value={category}
                onChange={changeCategory}
              />
            </Form.Field>
            <Form.Field>
              <label>서브 카테고리</label>
              <Dropdown
                placeholder="서브 카테고리"
                search
                selection
                clearable
                options={subCategories}
                value={subCategory}
                onChange={(_, { value }) => setSubCategory(value)}
              />
            </Form.Field>
            <Form.Field>
              <label>서비스 타입</label>
              <Dropdown
                placeholder="서비스 타입"
                search
                selection
                clearable
                options={serviceTypeOptions}
                value={serviceType}
                onChange={(_, { value }) => setServiceType(value)}
              />
            </Form.Field>
            <Button type="submit" fluid>
              Submit
            </Button>
          </Form>
        </Grid.Column>
        <Grid.Column />
      </Grid>
    </Container>
  );
};

export default FaqCreate;
